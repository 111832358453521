/** @format */

import {
  Box,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

// import React from "react";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useState } from "react";
import { ResetListApi } from "../../../redux/actions/LmsAction";
import { useContext } from "react";
import { AuditorContext } from "../../../../context/auditorContext";

import * as React from 'react';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const EnrollNow = () => {
  const location = useLocation();
  const detailsDataVideoType = location?.state;
  const { storeId, companyId } = useContext(AuditorContext);
  const navigate = useNavigate();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  console.log("detailsDataVideoType:", detailsDataVideoType);

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevIsAccordionOpen) => !prevIsAccordionOpen);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const payload = {
    body: {
      //   progressId: items._id,
      //   stepId: chapter.id,
      //   trainingId: items.trainingId,
      //   trainingId: location?.state,
    },
  };

  //   const trainingAssignmentAPI = (items, chapter) => {
  //     const id = localStorage.getItem("companyId");
  //     payload.url = BASE_URL + `trainingAssignment?company_id=${id}`;
  //     dispatch(ResetListApi(payload));
  //   };

  return (
    <Box>
      
      {/* <Box className="header-card">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={2} sx={{ ml: -1 }}>
            <IconButton onClick={handleBackButtonClick}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
      </Box> */}
      <Box sx={{ marginTop: 2 }} className="header-card">
        <Accordion
          expanded={isAccordionOpen}
          sx={{
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            "& .MuiAccordionSummary-content": {
              margin: 0,
              alignItems: "center",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={handleAccordionToggle}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item xs={2} sx={{ ml: -1 }}>
              <IconButton onClick={handleBackButtonClick}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Typography align="center">
              {isAccordionOpen ? "Hide" : "Open"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Accordion content */}
            <Typography>Accordion Content</Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box>
        {/* <h1>This page under development!!</h1> */}

        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}
            marginTop={2}
      >
        
        <Grid item xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid item xs={8}>
          <Item>xs=8</Item>
        </Grid>
      </Grid>
    </Box>
      </Box>
    </Box>
  );
};

export default EnrollNow;
