import {
  Box,
  Grid,
  Stack,
  Button,
  Container,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import attachment from "../../images/attechment.png";

const ViewDetailsObsevation = () => {
  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/observation" className="submenu-item">
                <Button className="header-add cancle-hover">
                  <DoneIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          pl: 1,
          pr: 1,
          pt: 1,
          pb: 4,
          borderRadius: "4px",
          mt: 1,
        }}
      >
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card sx={{ p: 2, m: 1, height: "100%" }}>
                <Table className="priroty-table">
                  <TableBody>
                    <TableRow>
                      <TableCell className="header-table">ID:</TableCell>
                      <TableCell>
                        <b>4567890</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header-table">Token:</TableCell>
                      <TableCell>
                        <b>12345</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header-table">
                        Created by:
                      </TableCell>
                      <TableCell>
                        <b>Pallavi+66 Kumari</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header-table">
                        Created at:
                      </TableCell>
                      <TableCell>
                        <b>May 31, 2022, 3:46:02 PM</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card sx={{ p: 1, m: 1, height: "100%" }}>
                <Table className="priroty-table">
                  <TableBody>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">
                        Attachment:
                      </TableCell>
                      <TableCell>
                        <img
                          src={attachment}
                          zoom={true}
                          style={{ marginRight: "10px" }}
                          alt="attachment"
                        />
                        <img src={attachment} alt="attachment" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{ p: 1, mt: 2, mx: 1, height: "100%" }}>
                <Table className="priroty-table">
                  <TableBody>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">
                        Observation:
                      </TableCell>
                      <TableCell>
                        <b>
                          Neque porro quisquam est qui dolorem ipsum Neque porro
                          quisquam est qui dolorem ipsum Neque porNeque porro
                          quisquam est qui dolorem ipsum Neque porro quisquam
                          est qui dolorem ipsum Neque por
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ViewDetailsObsevation;
