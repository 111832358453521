/** @format */

import {
  LIST_STORE_REQUEST,
  LIST_STORE_SUCCESS,
  LIST_STORE_ERROR,
  ASSIGN_USER_REQUEST,
  ASSIGN_USER_SUCCESS,
  ASSIGN_USER_ERROR,
  CHECKLIST_BY_STORE_REQUEST,
  CHECKLIST_BY_STORE_SUCCESS,
  CHECKLIST_BY_STORE_ERROR,
  STORE_ENABLE_DISABLE_REQUEST,
  STORE_ENABLE_DISABLE_SUCCESS,
  STORE_ENABLE_DISABLE_ERROR,
  ADD_STORE_REQUEST,
  ADD_STORE_SUCCESS,
  ADD_STORE_ERROR,
  EDIT_STORE_REQUEST,
  EDIT_STORE_SUCCESS,
  EDIT_STORE_ERROR,
  STORE_BY_ID_EDIT_REQUEST,
  STORE_BY_ID_EDIT_SUCCESS,
  STORE_BY_ID_EDIT_ERROR,
  PENDING_CHECKLIST_REQUEST,
  PENDING_CHECKLIST_SUCCESS,
  PENDING_CHECKLIST_ERROR,
  STORE_DETAILS_REQUEST,
  STORE_DETAILS_SUCCESS,
  STORE_DETAILS_ERROR,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  data: [],
  addStore: [],
  updateStore: [],
  editStoreById: [],
  pendingChecklist: [],
  storeDetails: [],
  checklistByStoreReducer: [],
  assignUser: [],
  storeEnableDisableReducer: [],
  deleteStore: [],
};

const StoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_STORE_SUCCESS: {
      console.log(state, "state");
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }
    case LIST_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    // Sore view started
    case STORE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_DETAILS_SUCCESS: {
      return {
        ...state,
        storeDetails: action?.payload,
        loading: false,
      };
    }
    case STORE_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // Store view ended

    // Assign User started
    case ASSIGN_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ASSIGN_USER_SUCCESS: {
      return {
        ...state,
        assignUser: action?.payload,
        loading: false,
      };
    }
    case ASSIGN_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // Assign User ended

    // Sore view started
    case CHECKLIST_BY_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHECKLIST_BY_STORE_SUCCESS: {
      return {
        ...state,
        checklistByStoreReducer: action?.payload,
        loading: false,
      };
    }
    case CHECKLIST_BY_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // Store view ended

    // pendingChecklist
    case PENDING_CHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PENDING_CHECKLIST_SUCCESS: {
      return {
        ...state,
        pendingChecklist: action?.payload,
        loading: false,
      };
    }
    case PENDING_CHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    // Add Store started
    case ADD_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_STORE_SUCCESS: {
      return {
        ...state,
        addStore: action?.payload,
        loading: false,
      };
    }
    case ADD_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // Add store ended

    // Sore enable disable started
    case STORE_ENABLE_DISABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        storeEnableDisableReducer: [],
        error: "",
      };
    }
    case STORE_ENABLE_DISABLE_SUCCESS: {
      return {
        ...state,
        storeEnableDisableReducer: action?.payload,
        loading: false,
      };
    }
    case STORE_ENABLE_DISABLE_ERROR: {
      return {
        ...state,
        loading: false,
        storeEnableDisableReducer: [],
        error: action.error,
      };
    }
    // Store enable disable ended

    // Edit store started
    case EDIT_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_STORE_SUCCESS: {
      return {
        ...state,
        updateStore: action?.payload,
        loading: false,
      };
    }
    case EDIT_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // Edit store ended

    // editStoreById store started
    case STORE_BY_ID_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_BY_ID_EDIT_SUCCESS: {
      return {
        ...state,
        editStoreById: action?.payload,
        loading: false,
      };
    }
    case STORE_BY_ID_EDIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    // editStoreById store ended

    case DELETE_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_STORE_SUCCESS: {
      return {
        ...state,
        deleteStore: action?.payload,
        loading: false,
      };
    }
    case DELETE_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default StoresReducer;
