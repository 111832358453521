import JwtAuthService from "../apiConfig/ApiConfig";
import {
  PROFILE_DATA_REQUEST,
  PROFILE_DATA_SUCCESS,
  PROFILE_DATA_ERROR,
} from "../types/Types";

export const ProfileDetailRequest = (data) => ({
  type: PROFILE_DATA_REQUEST,
  payload: data,
});
export const ProfileDetailSuccess = (data) => ({
  type: PROFILE_DATA_SUCCESS,
  payload: data,
});
export const ProfileDetailFailed = (error) => ({
  type: PROFILE_DATA_ERROR,
  payload: error,
});

export const ProfileDetailApi = (data) => (dispatch) => {
  try {
    dispatch(ProfileDetailRequest());
    JwtAuthService.ProfileDetail(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ProfileDetailSuccess(response?.data));
      } else {
        dispatch(ProfileDetailFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(checklistReportFailed(error));
    console.error("Something went Wrong", error);
  }
};
