/** @format */

import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { IconButton, TableFooter } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AllFormHead from "./allForm/AllFormHeader";
import { useDispatch, useSelector } from "react-redux";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DeleteFormApi } from "../redux/actions/DeleteForm";
import { AllFormArchivedListApi } from "../redux/actions/Checklist";

import { useEffect } from "react";
import {
  AllFormListApi,
  DeleteFormListApi,
  DeleteFormListSuccess,
} from "../redux/actions/Checklist";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import View from "../images/View.svg";
import Delete from "../images/Delete.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import * as moment from "moment";
import Progressbar from "../GlobalProgress/Progressbar";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer } from "react-toastify";
import Highlighter from "react-highlight-words";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const datagridSx = {
  overflow: "hidden !important",
  borderRadius: 2,
  border: "1px solid #CBD4E1",
  // "& .MuiDataGrid-main": { borderRadius: 2 },
  // '& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
  //   color: "blue",
  //   fontSize: 18,
  //   //risky
  //   minHeight: "60px !important",
  //   height: 60,
  //   "& div": {
  //     minHeight: "60px !important",
  //     height: 60,
  //     lineHeight: "59px !important"
  //   }
  // },
  "& .MuiDataGrid-virtualScroller": {
    // "& .MuiDataGrid-row": {
    //   "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
    // }
    overflow: "hidden !important",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#ffffff",
    color: "000000",
    fontSize: 16,
    borderRadius: "8px",
    border: "1px solid #CBD4E1",
  },
  [`& .${gridClasses.row} `]: {
    bgcolor: (theme) =>
      theme.palette.mode === "light" ? "#FFFFFF" : "#FFFFFF",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none !important",
    // remove padding at beginning of column header container so that text is inline with column text
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      // set header font styling
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    // Datagrid Row Styling
    "& .paxton-table--row": {
      borderTop: "1px solid #CBD4E1",
      borderLeft: "1px solid #CBD4E1",
      borderRight: "1px solid #CBD4E1",
      "&:nth-child(1)": {
        borderTopLeftRadius: "8px !important",
        borderTopRightRadius: "8px !important",
      },
      // borderRadius: "8px",
      // marginBottom: theme.spacing(2),
      // backgroundColor: theme.palette.secondary.main,
    },
    // remove borders and separators
    "& .paxton-table--cell": {
      border: "none !important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none !important",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none !important",
    },
    // This is to fix the rows being cut off due to adding padding
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important", // <= manually setting the max height, but this is not responsive
    },
  },
}));
export const AllForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formListData, setFormListData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [localPageNo, setLocalPageNo] = useState(1);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Delete: false,
  });
  const [permission, setPermission] = useState({});
  const ArchivedListTotalItems = useSelector(
    (state) =>
      state.checklist &&
      state.checklist?.formListArchived &&
      state.checklist?.formListArchived?.data &&
      state.checklist?.formListArchived?.data?.data &&
      state.checklist?.formListArchived?.data?.data?.paginate
  );
  const ListData = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.forms
  );

  const ArchivedListData = useSelector(
    (state) =>
      state &&
      state.checklist &&
      state.checklist?.formListArchived &&
      state.checklist?.formListArchived?.data &&
      state.checklist?.formListArchived?.data?.data &&
      state.checklist?.formListArchived?.data?.data?.Form
  );
  const totalitems = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.paginate
  );
  const deleteFormRes = useSelector(
    (state) => state && state?.DeleteFormData && state?.DeleteFormData
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  const data = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
        sort_by: "desc",
      },
      archived: false,
      search: "",
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    var localPageno = localStorage.getItem("pageno");
    data.body.pagination.page = localPageno ? parseInt(localPageno) : 1;
    setLoader(true);
    FormList(data);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [checkedArchive]);

  useEffect(() => {
    localStorage.removeItem("questions");
    localStorage.removeItem("editdata");
    localStorage.removeItem("editFormDefaultValue");
    localStorage.removeItem("formRules");
    localStorage.removeItem("questiodata");
  }, []);

  // useEffect(() => {
  //   if (ListData) {
  //     setFormListData(ListData);
  //   }
  // }, [ListData]);
  const AllFromListArchivedApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `ListArchivedForms?company_id=${id}`;
    dispatch(AllFormArchivedListApi(data));
  };

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(ArchivedListTotalItems?.total_item);
    } else {
      setTotalCount(totalitems?.total_item);
    }
  }, [ArchivedListTotalItems, totalitems, checkedArchive]);

  useEffect(() => {
    if (checkedArchive) {
      setFormListData(ArchivedListData);
    } else {
      setFormListData(ListData);
    }
  }, [ListData, ArchivedListData, checkedArchive]);

  // useEffect(() => {
  //   if (deleteChecklistRes && deleteChecklistRes?.data) {
  //     FormList(data);
  //     dispatch(DeleteFormListSuccess(""));
  //   }
  //   // if (
  //   //   deleteChecklistRes &&
  //   //   deleteChecklistRes?.data &&
  //   //   deleteChecklistRes?.data?.status == 400
  //   // ) {
  //   //   toast.success(`${deleteChecklistRes?.data?.message}`);
  //   // }
  // }, [deleteChecklistRes]);

  const FormList = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `checklists/index?company_id=${id}`;
    dispatch(AllFormListApi(data));
  };

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = values?._id;
            const data = {
              url: BASE_URL + `checklists/${userRoleId}?company_id=${id}`,
            };
            setLoader(true);
            dispatch(DeleteFormApi(data));
            setTimeout(() => {
              // getApi(paginationRequest);
              setLoader(false);
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });

    // if (values) { deleteChecklistRes
    //   const id = localStorage.getItem("companyId");
    //   const userRoleId = values?._id;
    //   const data = {
    //     url: BASE_URL + `checklists/${userRoleId}?company_id=${id}`,
    //   };
    //   dispatch(DeleteFormListApi(data));
    // }
  };
  const handleViewDetails = (row) => {
    navigate("/checklist/all-form/view-form");
    localStorage.setItem("userId", JSON.stringify(row));
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage + 1);
  //   data.body.pagination.page = newPage + 1;
  //   FormList(data);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(1);
  //   data.body.pagination.per_page = parseInt(event.target.value, 10);
  //   FormList(data);
  // };
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoader(true);
      data.body.search = searchValue;
      data.body.pagination.page = 1;
      setPage(1);
      // FormList(data);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
      if (checkedArchive === false) {
        FormList(data);
      } else {
        AllFromListArchivedApi(data);
      }
    }
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
      // localStorage.removeItem("pageno");
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    // setPage(newPage + 1);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    data.body.pagination.page = newPage + 1;
    data.body.search = searchValue;
    // FormList(data);
    if (checkedArchive === false) {
      FormList(data);
    } else {
      AllFromListArchivedApi(data);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    data.body.search = searchValue;
    data.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    data.body.pagination.per_page = parseInt(event.target.value, 10);
    // FormList(data);
    if (checkedArchive === false) {
      FormList(data);
    } else {
      AllFromListArchivedApi(data);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 5 : 0,
      bottom: params.isLastVisible ? 0 : 0,
    };
  }, []);
  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      width: 40,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params?.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          formListData.indexOf(params?.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "40px",
            }}
          >
            <p
              className="text-short"
              style={{ margin: 0, fontWeight: "normal" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Forms",
      headerName: "Forms",
      flex: 2,
      maxWidth: 250,
      align: "left",
      headerAlign: "left",
      renderHeader: () => <strong>{"Forms"}</strong>,
      hideable: false,
      renderCell: (params) => {
        let result = params?.row?.title ? params?.row?.title : "Unknown";
        return (
          <LightTooltip title={result}>
            <p
              className="text-short"
              style={{
                marginLeft: "2px",
                textAlign: "start",
              }}
            >
              {result}
            </p>
          </LightTooltip>
        );
      },
    },

    {
      field: "Created",
      headerName: "Created",
      // col:2,
      renderHeader: () => <strong>{"Created"}</strong>,
      width: 130,
      flex: 1,
      align: "left",
      headerAlign: "center",
      // col: 2,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.createdAt) {
          let cratFormatDate = moment(params?.row?.createdAt).format("lll");
          result.push(cratFormatDate);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Modified",
      headerName: "Modified",
      renderHeader: () => <strong>{"Modified"}</strong>,
      width: 120,
      flex: 1,
      align: "left",
      headerAlign: "center",
      col: 2,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          let updtFormatDate = moment(params?.row?.updatedAt).format("lll");
          result.push(updtFormatDate);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    // {
    //   field: "Questions",
    //   headerName: "Questions",
    //   renderHeader: () => <strong>{"Questions"}</strong>,
    //   flex: 2,
    //   align: "center",
    //   headerAlign: "center",
    //   maxWidth: 180,
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.questions) {
    //       result.push(params?.row?.questions?.length);
    //     } else {
    //       result = ["Unknown"];
    //     }
    //     return result.join(", ");
    //   },
    // },
    {
      field: "Questions",
      headerName: "Questions",
      renderHeader: () => <strong>{"Questions"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      maxWidth: 180,
      valueGetter: (params) => {
        const questions = params.row?.questions;
        return questions ? questions.length : 0;
      },
    },
    // {
    //   field: "Reports",
    //   headerName: "Reports",
    //   renderHeader: () => <strong>{"Reports"}</strong>,
    //   flex: 2,
    //   align: "center",
    //   headerAlign: "center",
    //   maxWidth: 180,
    //   renderCell: (params) => {
    //     let result = [];
    //     if (params?.row?.kpis) {
    //       result = params?.row?.reports;
    //     } else {
    //       result = ["Unknown"];
    //     }
    //     let cellContent;
    //     if (result.length > 1) {
    //       const tooltipValues = result.slice(1).join(", ");
    //       cellContent = (
    //         <Tooltip title={tooltipValues} arrow>
    //           <span>{result[0]} ...</span>
    //         </Tooltip>
    //       );
    //     } else {
    //       cellContent = <span>{result[0]}</span>;
    //     }
    //     return cellContent;
    //   },
    // },
    {
      field: "Reports",
      headerName: "Reports",
      renderHeader: () => <strong>{"Reports"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      maxWidth: 180,
      renderCell: (params) => {
        const reports = params.row?.reports;
        if (reports !== undefined && reports.length > 0) {
          if (reports.length > 1) {
            const tooltipValues = reports.slice(1).join(", ");
            return (
              <Tooltip title={tooltipValues} arrow>
                <span>{reports[0]} ...</span>
              </Tooltip>
            );
          } else {
            return <span>{reports[0]}</span>;
          }
        } else {
          return "N/A";
        }
      },
    },
    // {
    //   field: "Rule",
    //   headerName: "Rule",
    //   renderHeader: () => <strong>{"Rule"}</strong>,
    //   flex: 2,
    //   align: "center",
    //   headerAlign: "center",
    //   maxWidth: 180,
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.rules) {
    //       result.push(params?.row?.rules?.length);
    //     } else {
    //       result = ["Unknown"];
    //     }
    //     return result.join(", ");
    //   },
    // },
    {
      field: "Rule",
      headerName: "Rule",
      renderHeader: () => <strong>{"Rule"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      maxWidth: 180,
      valueGetter: (params) => {
        const rules = params.row?.rules;
        return rules ? rules.length : 0;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 2,
      maxWidth: 180,
      align: "center",
      headerAlign: "center",
      backgroundColor: "none",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Tooltip title="View">
              <img
                onClick={() => handleViewDetails(params?.row)}
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  height: "40px",
                  cursor: "pointer",
                  margin: "0px 3px 3px 3px",
                }}
                onMouseOver={() => {
                  setHover({ View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ View: false });
                }}
              />
            </Tooltip>
            {permission?.checklist?.forms === true ? (
              <Tooltip title="Delete">
                <img
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  onClick={() => handleDelete(params?.row)}
                  alt="Delete"
                  style={{
                    height: "40px",
                    cursor: "pointer",
                    margin: "0px 3px 3px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <AllFormHead
        searchOnChange={handleSearch}
        searchValue={searchValue}
        handleKeyDown={handleKeyDown}
        setCheckedArchive={setCheckedArchive}
        checkedArchive={checkedArchive}
        totalitems={totalitems?.total_item}
        page={page}
        rowsPerPage={rowsPerPage}
        ArchivedListTotalItems={ArchivedListTotalItems?.total_item}
        setPage={setPage}
        permission={permission}
      />
      <SingleGlobalTable
        data={formListData}
        columns={columns}
        totalCount={totalCount}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "calc(100vh - 220px)",

            mt: 1,
            p: 1,
            borderRadius: 2,
            overflowX: "auto",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: 8,
            },
          }}
        > */}
      {/* <TableContainer component={Paper} className="table-container">
            <Table size="large" aria-label="simple table">
              <TableHead sx={{ m: 4 }}>
                <TableRow>
                  <TableCell align="center">Forms</TableCell>
                  <TableCell align="center">Created</TableCell>
                  <TableCell align="center">Modified</TableCell>
                  <TableCell align="center">Questions</TableCell>
                  <TableCell align="center">KPI</TableCell>
                  <TableCell align="center">Rule</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {formListData ? (
                <>
                  <TableBody>
                    {formListData &&
                      formListData?.length > 0 &&
                      formListData
                        .filter(
                          (row) =>
                            !searchValue.length ||
                            row.title
                              .toString()
                              .toLowerCase()
                              .includes(searchValue.toString().toLowerCase())
                        )
                        .map((item, i) => {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                <Box
                                  sx={{ fontWeight: "bold" }}
                                  className="text-short2"
                                >
                                  <Highlighter
                                    searchWords={[searchValue]}
                                    textToHighlight={item.title}
                                    highlightClassName="highlight"
                                    highlightStyle={{
                                      backgroundColor: "#ffff00",
                                    }}
                                    unhighlightClassName="unhighlight"
                                    autoEscape={true}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#27364B" }}
                              >
                                {moment(item.createdAt).format("lll")}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#27364B" }}
                              >
                                {moment(item.updatedAt).format("lll")}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#27364B" }}
                              >
                                {item?.questions.length}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#27364B" }}
                              >
                                {item?.kpis.length}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#27364B" }}
                              >
                                {item?.rules.length}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() => handleViewDetails(item)}
                                    src={
                                      hover.View && indexHover === i
                                        ? ViewHover
                                        : View
                                    }
                                    alt="VIEW"
                                    style={{
                                      height: "40px",
                                      cursor: "pointer",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ View: true });
                                      setIndexHover(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ View: false });
                                    }}
                                  />
                                  <img
                                    src={
                                      hover.Delete && indexHover === i
                                        ? DeleteHover
                                        : Delete
                                    }
                                    onClick={() => handleDelete(item)}
                                    alt="Delete"
                                    style={{
                                      height: "40px",
                                      cursor: "pointer",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Delete: true });
                                      setIndexHover(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Delete: false });
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        className="tablepagination"
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: totalitems?.total_item },
                        ]}
                        // colSpan={3}
                        count={totalitems?.total_item}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        SelectProps={{
                          inputProps: { "aria-label": "Items per Page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Items per Page"}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </>
              ) : (
                <p>No Data</p>
              )}
            </Table>
          </TableContainer> */}

      {/* {formListData && formListData?.length > 0 ? (
            <>
              <DataGrid
                style={{
                  height: "670px",
                  lineHeight: "21px",
                }}
                disableColumnMenu
                getRowSpacing={getRowSpacing}
                rows={formListData}
                columns={columns}
                sx={datagridSx}
                getRowId={(formListData) => Math.random()}
                getRowHeight={() => "auto"}
                // getRowClassName={() => "paxton-table--row"}
                hideFooter={true}
                hideFooterPagination={true}
                className="grid-table1 grid-table-scroll"
                getRowClassName={() => "paxton-table--row"}
                // rowHeight={55}
                // checkboxSelection
                // disableSelectionOnClick
                // experimentalFeatures={{ newEditingApi: true }}
              />
            </>
          ) : (
            "No data found"
          )}
        </Box> */}
      {/* )} */}
      {/* <div className="allforms-pagination">
        <TablePagination
          className="tablepagination"
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: "All", value: totalitems?.total_item },
          ]}
          // colSpan={3}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          SelectProps={{
            inputProps: { "aria-label": "Items per Page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Items per Page"}
          ActionsComponent={TablePaginationActions}
        />
      </div> */}
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </Box>
  );
};

export default AllForm;
