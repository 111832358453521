import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [textValue, setTextValue] = useState("");

  const onTextChange = (e) => setTextValue(e.target.value);

  const handleSubmit = (e) => {
    navigate("/dashboard");
  };

  return (
    <>
      <Box className="Login-form">
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card sx={{ p: 4 }}>
                <h1 style={{ textAlign: "center", color: "#64748B" }}>
                  <b className="label-name">Forgot Your Password?</b>
                </h1>
                <Box className="step1-box">
                  <Typography className="email-box">
                    We get it, stuff happens. Just enter your email address
                    below and we'll send you a link to reset your password!
                  </Typography>
                </Box>
                <FormControl sx={{ mb: 2 }} fullWidth>
                  <TextField
                    onChange={onTextChange}
                    value={textValue}
                    placeholder="Enter Email"
                    className="date-input"
                  />
                </FormControl>

                <Box>
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    className="login-button"
                  >
                    Submit
                  </Button>
                  <Box
                    className="login-futter"
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Box>
                      <Link to={"/"} className="forgot-link">
                        Already have an account? Login!
                      </Link>
                    </Box>
                    <Box>
                      <Link to={"/signUp"} className="forgot-link">
                        Create new account!
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
