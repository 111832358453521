/** @format */

import React, { useState } from "react";
import { Stack, Tabs, Tab, Box } from "@mui/material";
import { Button, Card, Grid } from "material-ui-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { TrainingArchivedApi } from "../../../redux/actions/LmsAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


const TPHeader = (props) => {
  const location = useLocation();
  const newpath = location?.pathname;
  const navigate = useNavigate();
  const traininglist = props?.training;
  const [value, setValue] = useState(0);
  const [permission, setPermission] = useState({});
  const dispatch = useDispatch();
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const data = {
    body: {
      pagination: {
        page: props.page,
        per_page: props.rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
        sort_by: "desc",
      },
      archived: false,
      search: "",
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  const TrainingListArchivedApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `Archivedtraining?company_id=${id}`;
    dispatch(TrainingArchivedApi(data));
  };
  useEffect(() => {
    if (props.checkedArchive === true) {
      data.body.pagination.page = 1;
      props.setPage(1);
      TrainingListArchivedApi(data);
    }
  }, [props.checkedArchive]);

  const handleAddTraining = () => {
    navigate("/training/add-training", {
      state: { trainingPath: traininglist },
    });
  };

  return (
    <>
      <Box>
        <Card className="header-card">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
              {newpath === "/alltraining-report" ? (
                ""
              ) : (
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                  className="tabs-content-parent deactive-bg"
                >
                  <Tab
                    label="Active"
                    style={{ marginLeft: 5 }}
                    icon={
                      <p className="active-tabs-content">{props.totalitems}</p>
                    }
                    iconPosition="end"
                    className={
                      props.checkedArchive === false
                        ? "active-bg"
                        : "active-tabs tabs-content"
                    }
                    onClick={() => props.setCheckedArchive(false)}
                  />
                  <Tab
                    label="Archived"
                    icon={
                      <p className="active-tabs-content">
                        {props.ArchivedListTotalItems}
                      </p>
                    }
                    iconPosition="end"
                    className={
                      props.checkedArchive === true
                        ? "active-bg"
                        : "Archived-tabs tabs-content"
                    }
                    onClick={() => props.setCheckedArchive(true)}
                  />
                </Tabs>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
              <Stack
                direction="row"
                sx={{ float: "right" }}
                className="Assign-Checklist"
              >
                <div style={{ marginRight: 5 }}>
                  <SearchAppBar
                    searchValue={props.searchValue}
                    searchOnChange={props.searchOnChange}
                    handleKeyDown={props.handleKeyDown}
                  />
                </div>
                <div style={{marginTop:"7px",marginRight:"10px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Training Progress:</strong>
                            <p>
                               While user is completing training they can view 
                               progress by clicking on progress button.
                               Here, lms, assessment status will be displayed
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
                {/* {permission?.lms?.manageLms === true ? (
                  <Button
                    style={{ marginRight: 5 }}
                    className="header-add cancle-hover"
                    onClick={handleAddTraining}
                  >
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Add New Training
                  </Button>
                ) : (
                  ""
                )} */}
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default TPHeader;
