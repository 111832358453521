import {
    UPDATE_EDITALLFORMS_REQUEST,
    UPDATE_EDITALLFORMS_SUCCESS,
    UPDATE_EDITALLFORMS_ERROR,
} from "../types/Types";


const initialState = {
    loading: false,
    EditAllForm: [],
    error: "",
    id: null
};

const EditAddGroupDataStore = (state = initialState, action) => {
    let todos = action.payload;
    switch (action.type) {
        case UPDATE_EDITALLFORMS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_EDITALLFORMS_SUCCESS:

            return {
                ...state,
                loading: false,
                EditAllForm: action.payload,
                error: "",
            };
        case UPDATE_EDITALLFORMS_ERROR:
            return {
                loader: false,
                EditAllForm: [],
                error: action.error,
            };

        default:
            return state;
    }
};



export default EditAddGroupDataStore;
