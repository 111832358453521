import React, { useState } from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import { Button, Card, Grid } from "material-ui-core";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const Header = () => {
  const [value, setValue] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={5} md={4} lg={4} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                icon={<p className="active-tabs-content">28</p>}
                iconPosition="end"
                className="active-tabs tabs-content"
              />
              <Tab
                label="Archived"
                icon={<p className="active-tabs-content">8</p>}
                iconPosition="end"
                className="Archived-tabs tabs-content"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={2} md={4} lg={4} className="colomn-padding">
            <Stack
              direction="row"
              spacing={1}
              sx={{ justifyContent: "center" }}
            >
              <Button className="header-add-filter bg">
                <FilterAltOutlinedIcon />
                Filter
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" }} className="search-float-bar">
              <SearchAppBar />
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Header;
