/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Tabs,
  Tab,
  Divider,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Container } from "@mui/system";
import AllchecklistHead from "./AllChecklistHead";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserSubRoleApi, ListUserApi } from "../../redux/actions/User";
import {
  AllFormListApi,
  CreateAllChecklistApi,
  UpdateAllchecklistApi,
  UpdateAllchecklistSuccess,
} from "../../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import monthsname from "./Months";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MenuProps = {
  disableScrollLock: true,
};

const EditForm = (props) => {
  const timeinterval = [
    { value: "00:00", name: "00:00" },
    { value: "01:00", name: "01:00" },
    { value: "02:00", name: "02:00" },
    { value: "03:00", name: "03:00" },
    { value: "04:00", name: "04:00" },
    { value: "05:00", name: "05:00" },
    { value: "06:00", name: "06:00" },
    { value: "07:00", name: "07:00" },
    { value: "08:00", name: "08:00" },
    { value: "09:00", name: "09:00" },
    { value: "10:00", name: "10:00" },
    { value: "11:00", name: "11:00" },
    { value: "12:00", name: "12:00" },
    { value: "13:00", name: "13:00" },
    { value: "14:00", name: "14:00" },
    { value: "15:00", name: "15:00" },
    { value: "16:00", name: "16:00" },
    { value: "17:00", name: "17:00" },
    { value: "18:00", name: "18:00" },
    { value: "19:00", name: "19:00" },
    { value: "20:00", name: "20:00" },
    { value: "21:00", name: "21:00" },
    { value: "22:00", name: "22:00" },
    { value: "23:00", name: "23:00" },
  ];

  const weeknames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [attachValue, setAttachValue] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [reportSelect, setReportSelect] = useState([]);
  const [selectedReport, setSelectedReport] = useState([]);
  const [reportSelectForNotification, setReportSelectForNotification] = useState([]);
  const [selectedReportForNotification, setSelectedReportForNotification] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const [dateValue, setDateValue] = useState([null, null]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);
  const [tableData, setTableData] = useState({});
  const [newFieldValue, setNewFieldValue] = useState();
  const [time, setTime] = useState("");
  const [secondTime, setSecondTime] = useState("");
  const [newStartTime, setNewStartTime] = useState();
  const [newEndTime, setNewEndTime] = useState("");
  const [frequencyValue, setFrequencyValue] = useState("");

  const isAllSelected =
    mapSelectData.length > 0 && selected.length === mapSelectData.length;
  const isAllSelectedRole =
    roleSelect.length > 0 && selectedRole.length === roleSelect.length;
  const isAllSelectedUser =
    userSelect.length > 0 && selectedUser.length === userSelect.length;
  const isAllSelectedReport =
    reportSelect.length > 0 && selectedReport.length === reportSelect.length;
  const isAllSelectedReportForNotification =
    reportSelectForNotification.length > 0 && selectedReportForNotification.length === reportSelectForNotification.length;

  const selectForm = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.forms
  );
  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );
  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );
  const editChecklistRes = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.updateResponse &&
      state.checklist.updateResponse.data
  );

  useEffect(() => {
    dispatch(UpdateAllchecklistSuccess(""));
    let rowdata = JSON.parse(localStorage.getItem("allchecklistData"));
    if (rowdata) {
      setTableData(rowdata);
    }
    if (editChecklistRes && editChecklistRes.status === 200) {
      // toast.success(editChecklistRes.message, { autoClose: 1000 });
      setTimeout(() => {
        navigate("/checklist/all-checklist");
      }, 1000);
    }
  }, [editChecklistRes]);

  useEffect(() => {
    if (tableData) {
      setNewStartTime(tableData?.startTime);
      setNewEndTime(tableData?.endTime);
      if (tableData && tableData?.forms) {
        setSelected(tableData?.forms);
      }
      if (tableData && tableData?.mailReport) {
        setSelectedReport(tableData?.mailReport);
      }
      if (tableData && tableData?.notificationReport) {
        setSelectedReportForNotification(tableData?.notificationReport);
      }
      if (tableData && tableData?.role) {
        setSelectedRole(tableData?.role);
      }
      if (tableData && tableData?.assignedTo?.name) {
        setSelectedUser(tableData?.assignedTo?.name);
      }
      if (tableData && tableData?.month_date) {
        setMonths(tableData?.month_date);
      }
      if (tableData.week_day) {
        setWeeks(tableData.week_day);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData) {
      let day = tableData?.starts_at?.slice(6, 8);
      let month = tableData?.starts_at?.slice(4, 6);
      let year = tableData?.starts_at?.slice(0, 4);
      let sortedDate = new Date(`${month}-${day}-${year}`);
      let sortedStartTime = new Date(
        `${year}-${month}-${day}T${tableData.startTime}`
      );
      let sortedEndTime = new Date(
        `${year}-${month}-${day}T${tableData.endTime}`
      );
      let day2 = tableData?.expires_at?.slice(6, 8);
      let month2 = tableData?.expires_at?.slice(4, 6);
      let year2 = tableData?.expires_at?.slice(0, 4);
      let sortedDate2 = new Date(`${month2}-${day2}-${year2}`);
      setDateValue([sortedDate, sortedDate2]);
      setTime(sortedStartTime);
      // dayjs('2014-08-18T21:11:54')
      setSecondTime(sortedEndTime);
      if (tableData.frequency === 1) {
        setFrequencyValue("No Frequency");
      } else if (tableData.frequency === 2) {
        setFrequencyValue("hourly");
      } else if (tableData.frequency === 3) {
        setFrequencyValue("daily");
      } else if (tableData.frequency === 4) {
        setFrequencyValue("weekly");
      } else if (tableData.frequency === 5) {
        setFrequencyValue("monthly");
      } else {
        setFrequencyValue("");
      }
      if (tableData.role == null && tableData.assignedTo) {
        setValue(1);
      }
      setNewFieldValue({
        image_category: tableData && tableData.imageCategory,
        daytimelimit: tableData && tableData.startTime,
        daytimelimitsec: tableData && tableData.endTime,
        roleselect: tableData && tableData.role,
        userselect:
          tableData && tableData.assignedTo
            ? tableData.assignedTo
            : tableData.assignedTo?.name,
        timeinterval: tableData && tableData.time_interval,
        frequency: frequencyValue,
        type: tableData && tableData.type,
        title: tableData && tableData.title,
        month_date: tableData && tableData.month_date,
        week_day: tableData && tableData.week_day,
        // starts_at:dateValue,
        // expires_at:dateValue
      });
    }
  }, [tableData, frequencyValue]);

  useEffect(() => {
    const category = JSON.parse(localStorage.getItem("categoryData"));
    setCategoryData(category);
  }, []);

  useEffect(() => {
    dispatch(UpdateAllchecklistSuccess(""));
    FormList();
    listUser();
    // eslint-disable-next-line
  }, []);

  //subrole lisiting api
  useEffect(() => {
    dispatch(UpdateAllchecklistSuccess(""));
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectForm) {
      let arr = [];
      for (let item of selectForm) {
        arr.push(item);
      }
      setMapSelectData(arr);
    }
  }, [selectForm]);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        arr.push(item);
      }
      setRoleSelect(arr);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        arr.push(item?.name);
      }
      setReportSelect(arr);
      setReportSelectForNotification(arr);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (userList) {
      let arr = [];
      for (let item of userList) {
        let obj = item;
        arr.push(obj);
      }
      setUserSelect(arr);
    }
  }, [userList]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeAttached = (event, newValue) => {
    setAttachValue(newValue);
  };

  const paginationRequest = {
    body: {
      pagination: {
        page: 1,
        per_page: 2000000,
      },
      filter: {
        active: true,
      },
    },
  };
  const FormList = () => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `checklists/index?company_id=${id}`;
    dispatch(AllFormListApi(paginationRequest));
  };

  const datapayload = {
    body: {
      pagination: {
        page: 1,
        per_page: 2000000000000,
      },
    },
  };
  const listUser = (data) => {
    const id = localStorage.getItem("companyId");
    datapayload.url = BASE_URL + `users/index?company_id=${id}`;
    dispatch(ListUserApi(datapayload));
  };

  const handleChange = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(
        selected.length === mapSelectData?.length ? [] : mapSelectData
      );
      return;
    }
    setSelected(value);
  };
  const handleChangeRole = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelectedRole(
        selectedRole.length === roleSelect?.length ? [] : roleSelect
      );
      return;
    }
    setSelectedRole(value);
  };

  // const handleChangeReport = (event) => {
  //   const value = event.target.value.filter((val) => !!val);
  //   const isClickAll = value[value.length - 1] === "all";
  //   if (isClickAll) {
  //     setSelectedReport(
  //       selectedReport.length === reportSelect?.length ? [] : selectedReport
  //     );
  //     return;
  //   }
  //   setSelectedReport(value);
  // };

  const handleChangeReport = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value.includes("all");
    if (isClickAll) {
      setSelectedReport(
        selectedReport.length === reportSelect?.length ? [] : reportSelect
      );
      return;
    }
    setSelectedReport(value);
  };

  const handleChangeReportForNotification = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value.includes("all");
    if (isClickAll) {
      setSelectedReportForNotification(
        selectedReportForNotification.length === reportSelectForNotification?.length ? [] : reportSelectForNotification
      );
      return;
    }
    setSelectedReportForNotification(value);
  };

  const handleChangeUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelectedUser(
        selectedUser.length === userSelect?.length ? [] : userSelect
      );
      return;
    }
    setSelectedUser(value);
  };

  const handleChangeweeks = (event) => {
    const {
      target: { value },
    } = event;
    setWeeks(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangemonths = (event) => {
    const {
      target: { value },
    } = event;
    setMonths(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const formik = useFormik({
    initialValues: newFieldValue || {
      image_category: "",
      // daytimelimit: "",
      // daytimelimitsec: "",
      roleselect: "",
      timeinterval: "",
      title: "",
      type: "",
      reportselect: "",
      userselect: "",
      frequency: "",
      // month_date: "",
      // week_day: "",
      // starts_at: "",
      // expires_at: ""
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const dateFormate0 = moment(dateValue[0])
        .format("YYYY/MM/DD")
        .split("/")
        .join("");
      const dateFormate1 = moment(dateValue[1])
        .format("YYYY/MM/DD")
        .split("/")
        .join("");
      const timeFormat = moment(time?.$d).format("HH:mm").split("/").join("");
      const timeFormatSecond = moment(secondTime?.$d)
        .format("HH:mm")
        .split("/")
        .join("");
      values.mailReport = selectedReport;
      values.notificationReport = selectedReportForNotification;
      values.forms = selected;
      values.title = tableData.title;
      values.type = tableData.type;
      values.frequency = tableData.frequency;
      values.starts_at = dateFormate0;
      values.expires_at = dateFormate1;
      values.isBatchActive = attachValue;
      values.isBatchActive = attachValue;
      values.daytimelimit = newStartTime;
      values.daytimelimitsec = newEndTime;
      const allpayload = {
        title: tableData.title,
        endTime: values.daytimelimitsec,
        frequency: values.frequency,
        image_category: values.image_category,
        isBatchActive: values.isBatchActive,
        mailReport: values.mailReport,
        notificationReport: values.notificationReport,
        startTime: values.daytimelimit,
        type: values.type,
        forms: values.forms,
        starts_at: values.starts_at,
        expires_at: values.expires_at,
        _id: tableData._id,
      };
      if (values.roleselect) {
        allpayload.role = values.roleselect;
      }
      if (values.timeinterval) {
        allpayload.time_interval = values.timeinterval;
      }
      if (values.userselect) {
        allpayload.assignedTo = values.userselect;
      }
      if (months) {
        allpayload.month_date = months.length ? months : null;
      }
      if (weeks) {
        allpayload.week_day = weeks.length ? weeks : null;
      }
      const formData = {
        body: allpayload,
      };
      if (formData) {
        const id = localStorage.getItem("companyId");
        formData.url = BASE_URL + `formsAssignmentUpdate?company_id=${id}`;
        dispatch(UpdateAllchecklistApi(formData));
      }
    },
  });

  const handleDayLimit = (value) => {
    const timeFormat = moment(value?.$d).format("HH:mm");
    setNewStartTime(timeFormat ? timeFormat : tableData.startTime);
    setTime(value);
  };
  const handleDayLimitSecond = (value) => {
    const timeFormat = moment(value?.$d).format("HH:mm");
    setNewEndTime(timeFormat ? timeFormat : tableData.endTime);
    setSecondTime(value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <ToastContainer />
        <AllchecklistHead />
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Category</label>
              <Select
                disabled
                sx={{ borderRadius: "8px" }}
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                renderValue={(selected) => {
                  var type = typeof selected;
                  return selected && type == "string"
                    ? selected
                    : selected.title;
                }}
                helperText={formik.touched.title && formik.errors.title}
                size="small"
              >
                {categoryData &&
                  categoryData?.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option}>
                        {option.title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Type</label>
              <Select
                disabled
                sx={{ borderRadius: "8px" }}
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                size="small"
              >
                <MenuItem value={1}>Checklist</MenuItem>
                <MenuItem value={2}>Audit</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Forms</label>
              <Select
                sx={{ borderRadius: "8px" }}
                disabled
                labelId="mutiple-select-label"
                multiple
                name="selectform"
                size="small"
                value={selected}
                onChange={handleChange}
                MenuProps={{
                  disableScrollLock: true,
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "180px",
                    },
                  },
                }}
                renderValue={(selected) =>
                  selected.map((item) => item.title)?.join(",")
                }
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      //   checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < mapSelectData.length
                      }
                      checked={
                        mapSelectData?.length > 0 &&
                        selected.length === mapSelectData?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {mapSelectData &&
                  mapSelectData.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.title} />
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Image Category</label>
              <Select
                name="image_category"
                value={formik.values.image_category}
                onChange={formik.handleChange}
                renderValue={(selected) => selected}
                error={
                  formik.touched.image_category &&
                  Boolean(formik.errors.image_category)
                }
                helperText={
                  formik.touched.image_category && formik.errors.image_category
                }
                size="small"
                sx={{ borderRadius: "8px" }}
              >
                <MenuItem value="both">Both</MenuItem>
                <MenuItem value="photo">Photo</MenuItem>
                <MenuItem value="gallery">Gallery</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <label>Assigned to</label>
              <Grid container spacing={1}>
                <Grid item md={8} lg={8} xs={12} sm={12}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    className="tabs-content-parent"
                  >
                    <Tab
                      label="Role"
                      iconPosition="end"
                      {...a11yProps(0)}
                      className="active-tabs button-tab cancle-hover"
                    />
                    <Tab
                      label="User"
                      iconPosition="end"
                      {...a11yProps(1)}
                      className="Archived-tabs button-tab cancle-hover"
                    />
                  </Tabs>
                </Grid>
              </Grid>
            </FormControl>
            <TabPanel value={value} index={0} className="role-user-tab">
              <FormControl fullWidth sx={{ mb: 2, p: 0 }}>
                <label>Select Role</label>
                <Select
                  disabled
                  name="roleselect"
                  value={formik.values.roleselect}
                  onChange={formik.handleChange}
                  renderValue={(selected) => {
                    const valueType = typeof selected;
                    return selected && valueType == "string"
                      ? selected
                      : selected.name;
                  }}
                  sx={{ borderRadius: "8px" }}
                  error={
                    formik.touched.roleselect &&
                    Boolean(formik.errors.roleselect)
                  }
                  helperText={
                    formik.touched.roleselect && formik.errors.roleselect
                  }
                  size="small"
                  MenuProps={{
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "180px",
                      },
                    },
                  }}
                >
                  {roleSelect &&
                    roleSelect?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.name}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </TabPanel>
            <TabPanel value={value} index={1} className="role-user-tab">
              <FormControl fullWidth sx={{ mb: 2, p: 0 }}>
                <label>Select User</label>
                <Select
                  disabled
                  name="userselect"
                  value={
                    formik.values.userselect && formik.values.userselect?.name
                      ? formik.values.userselect?.name
                      : ""
                  }
                  onChange={formik.handleChange}
                  renderValue={(selected) => {
                    var valueType = typeof selected;
                    return selected && valueType == "string"
                      ? selected
                      : selected.userselect;
                  }}
                  sx={{ borderRadius: "8px" }}
                  error={
                    formik.touched.userselect &&
                    Boolean(formik.errors.userselect)
                  }
                  helperText={
                    formik.touched.userselect && formik.errors.userselect
                  }
                  size="small"
                  MenuProps={{
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "180px",
                      },
                    },
                  }}
                >
                  {userSelect &&
                    userSelect?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.name}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {/* <Select
                  labelId="mutiple-select-label"
                  multiple
                  name="userselect"
                  sx={{ borderRadius: "8px" }}
                  size="small"
                  value={selectedUser}
                  onChange={handleChangeUser}
                  MenuProps={{
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "300px",
                      },
                    },
                  }}
                  renderValue={(selectedUser) =>
                    selectedUser.map((item) => item)?.join(",")
                  }
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedUser ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedUser}
                        indeterminate={
                          selectedUser.length > 0 &&
                          selectedUser.length < userSelect.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {userSelect &&
                    userSelect.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedUser.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                </Select> */}
              </FormControl>
            </TabPanel>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Report</label>
              <Select
                labelId="mutiple-select-label"
                multiple
                sx={{ borderRadius: "8px" }}
                name="reportselect"
                size="small"
                value={selectedReport}
                onChange={handleChangeReport}
                renderValue={(selectedReport) => selectedReport.join(", ")}
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "300px",
                    },
                  },
                }}
              >
                <MenuItem
                  classes={{
                    root: isAllSelectedReport ? classes.selectedAll : "",
                  }}
                  value="all"
                  name="all"
                  data-button-all
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{
                        indeterminate: classes.indeterminateColor,
                      }}
                      checked={isAllSelectedReport}
                      indeterminate={
                        selectedReport.length > 0 &&
                        selectedReport.length < reportSelect?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {reportSelect &&
                  reportSelect.map((option, i) => (
                    <MenuItem key={i} value={option} name={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedReport.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {console.log("selectedReport", selectedReport)}
            {console.log("reportSelect", reportSelect)}
            {console.log("selectedReportForNotification", selectedReportForNotification)}
            {console.log("reportSelectForNotification", reportSelectForNotification)}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Report for push notification</label>
              <Select
                labelId="mutiple-select-label"
                multiple
                sx={{ borderRadius: "8px" }}
                name="reportselectpushnnotification"
                size="small"
                value={selectedReportForNotification}
                onChange={handleChangeReportForNotification}
                renderValue={(selectedReport) => selectedReport.join(", ")}
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "300px",
                    },
                  },
                }}
              >
                <MenuItem
                  classes={{
                    root: isAllSelectedReportForNotification ? classes.selectedAll : "",
                  }}
                  value="all"
                  name="all"
                  data-button-all
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{
                        indeterminate: classes.indeterminateColor,
                      }}
                      checked={isAllSelectedReportForNotification}
                      indeterminate={
                        selectedReportForNotification.length > 0 &&
                        selectedReportForNotification.length < reportSelectForNotification?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {reportSelectForNotification &&
                  reportSelectForNotification.map((option, i) => (
                    <MenuItem key={i} value={option} name={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedReportForNotification.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Frequency</label>
              <Select
                disabled
                name="frequency"
                value={formik.values.frequency}
                onChange={formik.handleChange}
                sx={{ borderRadius: "8px" }}
                renderValue={(selected) => selected}
                error={
                  formik.touched.frequency && Boolean(formik.errors.frequency)
                }
                helperText={formik.touched.frequency && formik.errors.frequency}
                size="small"
              >
                <MenuItem value={1}> No Frequency</MenuItem>
                <MenuItem value={2}>Hourly</MenuItem>
                <MenuItem value={3}>Daily</MenuItem>
                <MenuItem value={4}>Weekly</MenuItem>
                <MenuItem value={5}>Monthly </MenuItem>
              </Select>
            </FormControl>
            {formik.values.frequency === 2 || tableData.frequency === 2 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Time Interval</label>
                <Select
                  name="timeinterval"
                  value={formik.values.timeinterval}
                  renderValue={(selected) => selected}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.timeinterval &&
                    Boolean(formik.errors.timeinterval)
                  }
                  sx={{ borderRadius: "8px" }}
                  helperText={
                    formik.touched.timeinterval && formik.errors.timeinterval
                  }
                  size="small"
                >
                  {timeinterval &&
                    timeinterval?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {formik.values.frequency === 5 || tableData.frequency === 5 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Month Date</label>
                <Select
                  name="timeinterval"
                  size="small"
                  multiple
                  displayEmpty
                  value={months}
                  onChange={handleChangemonths}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    return selected.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ borderRadius: "8px" }}
                  MenuProps={{
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        height: "200px",
                      },
                    },
                  }}
                >
                  {monthsname.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {formik.values.frequency === 4 || tableData.frequency === 4 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Week Day</label>
                <Select
                  name="timeinterval"
                  size="small"
                  multiple
                  displayEmpty
                  value={weeks}
                  onChange={handleChangeweeks}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    const dayNames = selected.map((dayIndex) => {
                      if (dayIndex === 1) {
                        const dayIndex = "sunday";
                        return dayIndex;
                      }
                      if (dayIndex === 2) {
                        const dayIndex = "monday";
                        return dayIndex;
                      }
                      if (dayIndex === 3) {
                        const dayIndex = "tuesday";
                        return dayIndex;
                      }
                      if (dayIndex === 4) {
                        const dayIndex = "Wednesday";
                        return dayIndex;
                      }
                      if (dayIndex === 5) {
                        const dayIndex = "thursday";
                        return dayIndex;
                      }
                      if (dayIndex === 6) {
                        const dayIndex = "friday";
                        return dayIndex;
                      }
                      if (dayIndex === 7) {
                        const dayIndex = "saturday";
                        return dayIndex;
                      }
                    });
                    return dayNames.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ borderRadius: "8px" }}
                >
                  {weeknames.map((name, index) => (
                    <MenuItem
                      key={index}
                      value={
                        (name == "Sunday" && 1) ||
                        (name == "Monday" && 2) ||
                        (name == "Tuesday" && 3) ||
                        (name == "Wednesday" && 4) ||
                        (name == "Thursday" && 5) ||
                        (name == "Friday" && 6) ||
                        (name == "Saturday" && 7)
                      }
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {formik.values.frequency === 3 ||
            tableData.frequency === 3 ||
            // added new for no freq
            formik.values.frequency === 1 ||
            tableData.frequency === 1 ||
            //
            formik.values.frequency === 2 ||
            tableData.frequency === 2 ||
            formik.values.frequency === 4 ||
            tableData.frequency === 4 ||
            formik.values.frequency === 5 ||
            tableData.frequency === 5 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Day Time Limit</label>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        // ampm={false}
                        clearable
                        // name="daytimelimit"
                        value={time}
                        onChange={(newValue) => {
                          handleDayLimit(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                        className="date-input"
                      />
                    </LocalizationProvider>
                    {/* <Select
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="daytimelimit"
                      value={formik.values.daytimelimit}
                      sx={{ borderRadius: "8px" }}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.daytimelimit &&
                        Boolean(formik.errors.daytimelimit)
                      }
                      helperText={
                        formik.touched.daytimelimit &&
                        formik.errors.daytimelimit
                      }
                      MenuProps={{
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        PaperProps: {
                          sx: {
                            bgcolor: "#FFFFFF",
                            "& .MuiMenuItem-root": {
                              padding: 1,
                            },
                            height: "180px",
                          },
                        },
                      }}
                    >
                      {timeinterval &&
                        timeinterval?.map((option, i) => {
                          return (
                            <MenuItem key={i} value={option.value}>
                              {option.name}
                            </MenuItem>
                          );
                        })}
                    </Select> */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className="input-divider"
                  >
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        // ampm={false}
                        clearable
                        // name="daytimelimit"
                        value={secondTime}
                        onChange={(newValue) => {
                          handleDayLimitSecond(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                        className="date-input"
                      />
                    </LocalizationProvider>
                    {/* <Select
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ borderRadius: "8px" }}
                      name="daytimelimitsec"
                      value={formik.values.daytimelimitsec}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.daytimelimitsec &&
                        Boolean(formik.errors.daytimelimitsec)
                      }
                      helperText={
                        formik.touched.daytimelimitsec &&
                        formik.errors.daytimelimitsec
                      }
                      MenuProps={{
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        PaperProps: {
                          sx: {
                            bgcolor: "#FFFFFF",
                            "& .MuiMenuItem-root": {
                              padding: 1,
                            },
                            height: "180px",
                          },
                        },
                      }}
                    >
                      {timeinterval &&
                        timeinterval?.map((option, i) => {
                          return (
                            <MenuItem key={i} value={option.value}>
                              {option.name}
                            </MenuItem>
                          );
                        })}
                    </Select> */}
                  </Grid>
                </Grid>
              </FormControl>
            ) : (
              ""
            )}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label style={{ marginBottom: "5px" }}>Survey period</label>
              {/* <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  start: "Select start date",
                  end: "Select end date",
                }}
              >
                <DateRangePicker
                  disablePast
                  value={[formik.values.starts_at, formik.values.expires_at]}
                  onChange={(newValue) => {
                    setDateValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} fullWidth size="small" />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} fullWidth size="small" />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider> */}
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  start: "",
                  end: "",
                }}
              >
                {/* <DateRangePicker
                  className="survey-date-div"
                  inputFormat="MM/DD/YYYY"
                  disablePast
                  value={dateValue}
                  onChange={(newValue) => {
                    let dateSort = newValue.map((val) =>
                      moment(val?.$d).format("YYYY/MM/DD").split("/").join("")
                    );
                    setDateValue(dateSort);
                  }}
                  error={formik.touched.starts_at && formik.touched.expires_at}
                  helperText={
                    formik.touched.starts_at &&
                    formik.touched.expires_at &&
                    formik.errors.starts_at &&
                    formik.errors.expires_at
                  }
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        fullWidth
                        size="small"
                        className="date-input"
                      />
                      <Box sx={{ mx: 2 }} className="to-box">
                        {" "}
                        to{" "}
                      </Box>
                      <TextField
                        {...endProps}
                        fullWidth
                        size="small"
                        className="date-input"
                      />
                    </React.Fragment>
                  )}
                /> */}

                {/* disabled the first box in below code  */}
                <DateRangePicker
                  className="survey-date-div"
                  inputFormat="MM/DD/YYYY"
                  disablePast
                  value={dateValue}
                  onChange={(newValue) => {
                    let dateSort = newValue.map((val) =>
                      moment(val?.$d).format("YYYY/MM/DD").split("/").join("")
                    );
                    setDateValue(dateSort);
                  }}
                  error={formik.touched.starts_at && formik.touched.expires_at}
                  helperText={
                    formik.touched.starts_at &&
                    formik.touched.expires_at &&
                    formik.errors.starts_at &&
                    formik.errors.expires_at
                  }
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        fullWidth
                        size="small"
                        className="date-input"
                        onClick={() => {}}
                        onKeyDown={(e) => {
                          if (e.key === "Tab") {
                            e.preventDefault();
                          }
                        }}
                        inputProps={{ tabIndex: -1 }}
                        value={
                          dateValue[0]
                            ? moment(dateValue[0], "YYYYMMDD").format(
                                "MM/DD/YYYY"
                              )
                            : ""
                        }
                      />
                      <Box sx={{ mx: 2 }} className="to-box">
                        {" "}
                        to{" "}
                      </Box>
                      <TextField
                        {...endProps}
                        fullWidth
                        size="small"
                        className="date-input"
                      />
                    </React.Fragment>
                  )}
                  PopoverProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    disableRestoreFocus: true,
                  }}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <label>Attach Batch</label>
              <Tabs
                value={attachValue}
                onChange={handleChangeAttached}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
                className="tabs-content-parent"
              >
                <Tab
                  label="Yes"
                  value={true}
                  className="active-tabs button-tab cancle-hover"
                />
                <Tab
                  label="No"
                  value={false}
                  className="Archived-tabs button-tab cancle-hover"
                />
              </Tabs>
            </FormControl>
          </Container>
        </Box>
      </form>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </>
  );
};

export default EditForm;
