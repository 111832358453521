/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Tabs,
  Tab,
  Divider,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Container } from "@mui/system";
import AllchecklistHead from "./AllChecklistHead";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { UserSubRoleApi, ListUserApi } from "../../redux/actions/User";
import {
  AllFormListApi,
  CategoryListApi,
  CreateAllChecklistApi,
  CreateAllChecklistSuccess,
} from "../../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import monthsname from "./Months";
import { useLocation, useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import dayjs from "dayjs";
import * as moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const MenuProps = {
  disableScrollLock: true,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddForm = (props) => {
  const timeinterval = [
    { value: "00:00", name: "00:00" },
    { value: "01:00", name: "01:00" },
    { value: "02:00", name: "02:00" },
    { value: "03:00", name: "03:00" },
    { value: "04:00", name: "04:00" },
    { value: "05:00", name: "05:00" },
    { value: "06:00", name: "06:00" },
    { value: "07:00", name: "07:00" },
    { value: "08:00", name: "08:00" },
    { value: "09:00", name: "09:00" },
    { value: "10:00", name: "10:00" },
    { value: "11:00", name: "11:00" },
    { value: "12:00", name: "12:00" },
    { value: "13:00", name: "13:00" },
    { value: "14:00", name: "14:00" },
    { value: "15:00", name: "15:00" },
    { value: "16:00", name: "16:00" },
    { value: "17:00", name: "17:00" },
    { value: "18:00", name: "18:00" },
    { value: "19:00", name: "19:00" },
    { value: "20:00", name: "20:00" },
    { value: "21:00", name: "21:00" },
    { value: "22:00", name: "22:00" },
    { value: "23:00", name: "23:00" },
  ];

  const weeknames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const CanclePath = location?.state;
  const [value, setValue] = useState(0);
  const [attachValue, setAttachValue] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [reportSelect, setReportSelect] = useState([]);
  const [selectedReport, setSelectedReport] = useState([]);
  const [reportSelectForNotification, setReportSelectForNotification] = useState([]);
  const [selectedReportForNotification, setSelectedReportForNotification] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const [dateValue, setDateValue] = useState([null, null]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);
  const [formError, setFormError] = useState(false);
  const [time, setTime] = useState(null);
  const [secondTime, setSecondTime] = useState(null);
  const [firstSubmit, setFirstSubmit] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(mapSelectData || []);
  const [searchTerm, setSearchTerm] = useState("");

  const isAllSelected =
    mapSelectData.length > 0 && selected.length === mapSelectData.length;
  const isAllSelectedRole =
    roleSelect.length > 0 && selectedRole.length === roleSelect.length;
  const isAllSelectedUser =
    userSelect.length > 0 && selectedUser.length === userSelect.length;
  const isAllSelectedReport =
    reportSelect.length > 0 && selectedReport.length === reportSelect.length;
  const isAllSelectedReportForNotification =
    reportSelectForNotification.length > 0 && selectedReportForNotification.length === reportSelectForNotification.length;
  
  const selectForm = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.forms
  );
  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );
  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  const assignChecklistRes = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.assignChecklist &&
      state.checklist.assignChecklist.data &&
      state.checklist.assignChecklist.data.data
  );

  const category = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.category &&
      state.checklist.category.data &&
      state.checklist.category.data.data
  );

  const paginationRequest = {
    body: {
      pagination: {
        page: 1,
        per_page: 2000000,
      },
      filter: {
        active: true,
      },
    },
  };

  useEffect(() => {
    if (category) {
      localStorage.setItem("categoryData", JSON.stringify(category));
      const categorynew = JSON.parse(localStorage.getItem("categoryData"));
      setCategoryData(categorynew);
    }
  }, [category]);

  useEffect(() => {
    dispatch(CreateAllChecklistSuccess(""));
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
    FormList();
    listUser();
  }, []);

  useEffect(() => {
    if (assignChecklistRes) {
      dispatch(CreateAllChecklistSuccess(""));
      setTimeout(() => {
        navigate("/checklist/all-checklist");
      }, 1000);
    }
  }, [assignChecklistRes]);

  useEffect(() => {
    if (selectForm) {
      let arr = [];
      for (let item of selectForm) {
        let obj = item;
        arr.push(obj);
      }
      setMapSelectData(arr);
    }
  }, [selectForm]);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setRoleSelect(arr);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setReportSelect(arr);
      setReportSelectForNotification(arr);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (userList) {
      let arr = [];
      for (let item of userList) {
        let obj = item;
        arr.push(obj);
      }
      setUserSelect(arr);
    }
  }, [userList]);

  useEffect(() => {
    ListCatagory();
  }, []);

  const ListCatagory = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `categories?company_id=${id}` };
    dispatch(CategoryListApi(data));
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeAttached = (event, newValue) => {
    setAttachValue(newValue);
  };

  const FormList = () => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `checklists/index?company_id=${id}`;
    dispatch(AllFormListApi(paginationRequest));
  };

  const datapayload = {
    body: {
      page: 1,
      per_page: 2000000000000,
    },
  };
  const listUser = (data) => {
    const id = localStorage.getItem("companyId");
    datapayload.url = BASE_URL + `users/index?company_id=${id}`;
    dispatch(ListUserApi(datapayload));
  };

  const handleChangeforms = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(
        selected.length === mapSelectData?.length ? [] : mapSelectData
      );
      return;
    }
    setSelected(value);
  };
  const handleChangeRole = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelectedRole(
        selectedRole.length === roleSelect?.length ? [] : roleSelect
      );
      return;
    }
    setSelectedRole(value);
  };

  const handleChangeReport = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value.includes("all");
    if (isClickAll) {
      setSelectedReport(
        selectedReport.length === reportSelect?.length ? [] : reportSelect
      );
      return;
    }
    setSelectedReport(value);
  };

  const handleChangeReportForNotification = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value.includes("all");
    if (isClickAll) {
      setSelectedReportForNotification(
        selectedReportForNotification.length === reportSelectForNotification?.length ? [] : reportSelectForNotification
      );
      return;
    }
    setSelectedReportForNotification(value);
  };

  const handleChangeUser = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelectedUser(
        selectedUser.length === userSelect?.length ? [] : userSelect
      );
      return;
    }
    setSelectedUser(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filteredOptions = mapSelectData.filter((option) =>
      option.title.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const filteredOptions = categoryData.filter((option) =>
    option.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleChangeweeks = (event) => {
    const {
      target: { value },
    } = event;
    setWeeks(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangemonths = (event) => {
    const {
      target: { value },
    } = event;
    setMonths(typeof value === "string" ? value.split(",") : value);
  };

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      category: "",
      type: "",
      imagecategory: "",
      frequency: "",
      timeinterval: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFirstSubmit(firstSubmit + 1);
      const timeFormat = moment(time ? time.$d : null)
        .format("HH:mm")
        .split("/")
        .join("");
      const timeFormatSecond = moment(secondTime ? secondTime.$d : null)
        .format("HH:mm")
        .split("/")
        .join("");
      values.notificationReport = selectedReportForNotification;
      values.mailReport = selectedReport;
      values.forms = selected;

      const startDate = parseInt(dateValue[0], 10);
      const expireDate = parseInt(dateValue[1], 10);
      values.starts_at = startDate;
      values.expires_at = expireDate;
      values.isBatchActive = attachValue;
      if (timeFormat !== "Invalid date") {
        values.daytimelimit = timeFormat;
      }
      if (timeFormatSecond !== "Invalid date") {
        values.daytimelimitsec = timeFormatSecond;
      }
      const allpayload = {
        category: values.category,
        frequency: values.frequency,
        image_category: values.imagecategory,
        isBatchActive: values.isBatchActive,
        mailReport: values.mailReport,
        notificationReport: values.notificationReport,
        type: values.type,
        forms: values.forms,
        starts_at: values.starts_at,
        expires_at: values.expires_at,
      };
      if (values.daytimelimitsec !== null) {
        allpayload.endTime = values.daytimelimitsec;
      }
      if (values.daytimelimit !== null) {
        allpayload.startTime = values.daytimelimit;
      }
      if (values.frequency == 2 && values.timeinterval) {
        allpayload.time_interval = values.timeinterval;
      }
      if (value === 0) {
        allpayload.role = selectedRole;
      }
      if (value === 1) {
        allpayload.assignedTo = selectedUser;
      }
      if (months.length > 0) {
        allpayload.month_date = months;
      }
      if (weeks.length > 0) {
        allpayload.week_day = weeks;
      }
      const formData = {
        body: allpayload,
      };
      const id = localStorage.getItem("companyId");
      formData.url = BASE_URL + `formsAssignment?company_id=${id}`;
      dispatch(CreateAllChecklistApi(formData));
    },
  });

  useEffect(() => {
    const startTime = moment(time?.$d).format("HH:mm").split("/").join("");
    const endTime = moment(secondTime?.$d).format("HH:mm").split("/").join("");
    if (startTime && endTime) {
      let result = moment(endTime).diff(startTime);
    }
  }, [time, secondTime, formik.values.timeinterval]);

  const handleDayLimit = (value) => {
    setTime(value);
  };
  const handleDayLimitSecond = (value) => {
    setSecondTime(value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <ToastContainer autoClose={3000} />
        <AllchecklistHead CanclePath={CanclePath} />
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            {/* category with search  */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Category</label>
              <Autocomplete
                name="category"
                value={formik.values.category || null}
                onChange={(event, value) =>
                  formik.setFieldValue("category", value)
                }
                options={categoryData || []}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ borderRadius: "8px" }}
                    placeholder="Search category"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.title}</li>
                )}
                renderMenu={(props) => (
                  <Paper
                    square
                    sx={{
                      bgcolor: "#FFFFFF",
                      height: "180px",
                    }}
                    {...props}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& ul": {
                      padding: 1,
                    },
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Type</label>
              <Select
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                size="small"
                MenuProps={MenuProps}
                sx={{ borderRadius: "8px" }}
              >
                <MenuItem value={1}>Checklist</MenuItem>
                <MenuItem value={2}>Audit</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              sx={{ mb: 2 }}
              error={formik.touched.forms && formik.errors.forms}
            >
              <label>Select Forms</label>
              <Autocomplete
                multiple
                name="forms"
                size="small"
                value={selected}
                onChange={(e, newValue) => {
                  setSelected(newValue);
                  formik.setFieldValue("forms", newValue);
                }}
                options={mapSelectData}
                getOptionLabel={(option) => option.title}
                sx={{ borderRadius: "8px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label="Select Forms"
                    placeholder="Search forms..."
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      checked={selected ? selected.includes(option) : false}
                      sx={{ mr: 1 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        const newSelected = selected ? [...selected] : [];
                        const selectedIndex = newSelected.indexOf(option);

                        if (selectedIndex === -1) {
                          newSelected.push(option);
                        } else {
                          newSelected.splice(selectedIndex, 1);
                        }

                        setSelected(newSelected); // <-- add this line to update the selected variable
                        formik.setFieldValue("forms", newSelected);
                      }}
                    />
                    {option.title}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.title}
                      label={option.title}
                      {...getTagProps({ index })}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))
                }
                disableCloseOnSelect
              />
              {formik.touched.forms && formik.errors.forms && (
                <FormHelperText error>{formik.errors.forms}</FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Image Category</label>
              <Select
                name="imagecategory"
                sx={{ borderRadius: "8px" }}
                value={formik.values.imagecategory}
                onChange={formik.handleChange}
                error={
                  formik.touched.imagecategory &&
                  Boolean(formik.errors.imagecategory)
                }
                helperText={
                  formik.touched.imagecategory && formik.errors.imagecategory
                }
                size="small"
                MenuProps={MenuProps}
              >
                <MenuItem value="Both">Both</MenuItem>
                <MenuItem value="Photo">Photo</MenuItem>
                <MenuItem value="Gallery">Gallery</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <label>Assigned to</label>
              <Grid container spacing={1}>
                <Grid item md={8} lg={8} xs={12} sm={12}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    className="tabs-content-parent"
                  >
                    <Tab
                      label="Role"
                      iconPosition="end"
                      {...a11yProps(0)}
                      className="active-tabs button-tab cancle-hover"
                    />
                    <Tab
                      label="User"
                      iconPosition="end"
                      {...a11yProps(1)}
                      className="Archived-tabs button-tab cancle-hover"
                    />
                  </Tabs>
                </Grid>
              </Grid>
            </FormControl>
            <TabPanel value={value} index={0} className="role-user-tab">
              <FormControl fullWidth sx={{ mb: 2, p: 0 }}>
                <label>Select Role</label>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  name="role"
                  size="small"
                  value={selectedRole}
                  sx={{ borderRadius: "8px" }}
                  // onChange={handleChangeRole}
                  onChange={(e) => {
                    handleChangeRole(e);
                    formik.handleChange(e);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 0,
                        },
                        height: "180px",
                      },
                    },
                  }}
                  renderValue={(selectedRole) =>
                    selectedRole.map((item) => item)?.join(",")
                  }
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedRole ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedRole}
                        indeterminate={
                          selectedRole.length > 0 &&
                          selectedRole.length < roleSelect.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {roleSelect &&
                    roleSelect.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedRole.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </TabPanel>
            <TabPanel value={value} index={1} className="role-user-tab">
              <FormControl fullWidth sx={{ mb: 2, p: 0 }}>
                <label>Select User</label>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  name="roleselect"
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  value={selectedUser}
                  onChange={handleChangeUser}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 0,
                        },
                        height: "180px",
                      },
                    },
                  }}
                  renderValue={(selectedUser) =>
                    selectedUser.map((item) => item.name)?.join(",")
                  }
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelectedUser ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelectedUser}
                        indeterminate={
                          selectedUser.length > 0 &&
                          selectedUser.length < userSelect.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {userSelect &&
                    userSelect.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option}
                          sx={{ margin: "0px 0px 10px 0px" }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedUser.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.name}
                            secondary={option.email}
                            className="user-list-all"
                          />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </TabPanel>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Report for push notification</label>
              <Select
                labelId="mutiple-select-label"
                sx={{ borderRadius: "8px" }}
                multiple
                name="reportselectpushnnotification"
                size="small"
                value={selectedReportForNotification}
                onChange={handleChangeReportForNotification}
                renderValue={(selectedReport) =>
                  selectedReport.map((item) => item)?.join(",")
                }
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "180px",
                    },
                  },
                }}
              >
                <MenuItem
                  classes={{
                    root: isAllSelectedReportForNotification ? classes.selectedAll : "",
                  }}
                  value="all"
                  name="all"
                  data-button-all
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{
                        indeterminate: classes.indeterminateColor,
                      }}
                      checked={isAllSelectedReportForNotification}
                      indeterminate={
                        selectedReportForNotification.length > 0 &&
                        selectedReportForNotification.length < reportSelectForNotification?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {reportSelectForNotification &&
                  reportSelectForNotification.map((option, i) => (
                    <MenuItem key={i} value={option} name={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedReportForNotification.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Report</label>
              <Select
                labelId="mutiple-select-label"
                sx={{ borderRadius: "8px" }}
                multiple
                name="reportselect"
                size="small"
                value={selectedReport}
                onChange={handleChangeReport}
                renderValue={(selectedReport) =>
                  selectedReport.map((item) => item)?.join(",")
                }
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "180px",
                    },
                  },
                }}
              >
                <MenuItem
                  classes={{
                    root: isAllSelectedReport ? classes.selectedAll : "",
                  }}
                  value="all"
                  name="all"
                  data-button-all
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{
                        indeterminate: classes.indeterminateColor,
                      }}
                      checked={isAllSelectedReport}
                      indeterminate={
                        selectedReport.length > 0 &&
                        selectedReport.length < reportSelect?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {reportSelect &&
                  reportSelect.map((option, i) => (
                    <MenuItem key={i} value={option} name={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedReport.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Frequency</label>
              <Select
                name="frequency"
                sx={{ borderRadius: "8px" }}
                value={formik.values.frequency}
                onChange={formik.handleChange}
                error={
                  formik.touched.frequency && Boolean(formik.errors.frequency)
                }
                helperText={formik.touched.frequency && formik.errors.frequency}
                size="small"
                MenuProps={MenuProps}
              >
                <MenuItem value={1}> No Frequency</MenuItem>
                <MenuItem value={2}>Hourly</MenuItem>
                <MenuItem value={3}>Daily</MenuItem>
                <MenuItem value={4}>Weekly</MenuItem>
                <MenuItem value={5}>Monthly </MenuItem>
              </Select>
            </FormControl>
            {formik.values.frequency === 2 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Time Interval</label>
                <Select
                  name="timeinterval"
                  value={formik.values.timeinterval}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.timeinterval &&
                    Boolean(formik.errors.timeinterval)
                  }
                  helperText={
                    formik.touched.timeinterval && formik.errors.timeinterval
                  }
                  size="small"
                >
                  {timeinterval &&
                    timeinterval?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {formik.values.frequency === 5 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Month Date</label>
                <Select
                  name="timeinterval"
                  sx={{ borderRadius: "8px" }}
                  size="small"
                  multiple
                  displayEmpty
                  value={months}
                  onChange={handleChangemonths}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    return selected.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {monthsname.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {formik.values.frequency === 4 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Week Day</label>

                <Select
                  name="timeinterval"
                  size="small"
                  multiple
                  sx={{ borderRadius: "8px" }}
                  displayEmpty
                  value={weeks}
                  onChange={handleChangeweeks}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    const dayNames = selected.map((dayIndex) => {
                      if (dayIndex === 1) {
                        const dayIndex = "sunday";
                        return dayIndex;
                      }
                      if (dayIndex === 2) {
                        const dayIndex = "monday";
                        return dayIndex;
                      }
                      if (dayIndex === 3) {
                        const dayIndex = "tuesday";
                        return dayIndex;
                      }
                      if (dayIndex === 4) {
                        const dayIndex = "Wednesday";
                        return dayIndex;
                      }
                      if (dayIndex === 5) {
                        const dayIndex = "thursday";
                        return dayIndex;
                      }
                      if (dayIndex === 6) {
                        const dayIndex = "friday";
                        return dayIndex;
                      }
                      if (dayIndex === 7) {
                        const dayIndex = "saturday";
                        return dayIndex;
                      }
                    });
                    return dayNames.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {weeknames.map((name) => (
                    <MenuItem
                      key={name}
                      value={
                        (name == "Sunday" && 1) ||
                        (name == "Monday" && 2) ||
                        (name == "Tuesday" && 3) ||
                        (name == "Wednesday" && 4) ||
                        (name == "Thursday" && 5) ||
                        (name == "Friday" && 6) ||
                        (name == "Saturday" && 7)
                      }
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Day Time Limit</label>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      clearable
                      clearText="Clear"
                      value={time}
                      onChange={handleDayLimit}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          defaultValue={null}
                          size="small"
                          {...params}
                        />
                      )}
                      className="date-input"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  className="input-divider"
                >
                  <Divider className="divider-line" />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      defaultValue={null}
                      clearable
                      clearText="Clear"
                      value={secondTime}
                      onChange={handleDayLimitSecond}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          defaultValue={null}
                          size="small"
                          className="date-input"
                          {...params}
                        />
                      )}
                      className="date-input"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              {formik.values.frequency === 2}
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Survey period</label>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  start: "",
                  end: "",
                }}
              >
                <DateRangePicker
                  className="survey-date-div"
                  inputFormat="MM/DD/YYYY"
                  disablePast
                  value={dateValue}
                  onChange={(newValue) => {
                    let dateSort = newValue.map((val) => {
                      let newdate = moment(val?.$d)
                        .format("YYYY/MM/DD")
                        .split("/")
                        .join("");
                      return newdate;
                    });
                    setDateValue(dateSort);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        fullWidth
                        size="small"
                        className="date-input"
                      />
                      <Box sx={{ mx: 2 }} className="to-box">
                        {" "}
                        to{" "}
                      </Box>
                      <TextField
                        {...endProps}
                        fullWidth
                        size="small"
                        className="date-input"
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <label>Attach Batch</label>
              <Tabs
                value={attachValue}
                onChange={handleChangeAttached}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
                className="tabs-content-parent"
              >
                <Tab
                  label="Yes"
                  value={true}
                  className="active-tabs button-tab cancle-hover"
                />
                <Tab
                  label="No"
                  value={false}
                  className="Archived-tabs button-tab cancle-hover"
                />
              </Tabs>
            </FormControl>
          </Container>
        </Box>
      </form>
    </>
  );
};

export default AddForm;
