import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Avatar,
} from "@mui/material";
import User from "../../../../assets/icons/svg-icons/User.svg";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const TopPerformer = () => {
  const topperformerlist = useSelector(
    (state) =>
      state &&
      state.user &&
      state.user.topformer &&
      state.user.topformer.data &&
      state.user.topformer.data.data &&
      state.user.topformer.data.data.Topperformer
  );
  return (
    <Card className="card" sx={{ borderRadius: "8px" }}>
      <CardContent>
      <div style={{marginRight:"8px",float:"right"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
        <Typography variant="p" component="h2" className="top-heading">
          Top Performer
        </Typography>
        <TableContainer
          component={Paper}
          className="card table-scroll"
          sx={{ boxShadow: "none" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Image
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Employee
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Role
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Points
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Compliance Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topperformerlist &&
                topperformerlist.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">
                      <Stack direction="row" spacing={2}>
                        <Avatar className="message-avatar">
                          <img src={User} alt="avatar" />
                        </Avatar>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">{row.Name}</TableCell>
                    <TableCell align="center">{row.SubRole}</TableCell>
                    <TableCell align="center">{row.SubmitCount}</TableCell>
                    <TableCell align="center">{row.ComplianceScore}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default TopPerformer;
