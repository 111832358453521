/** @format */
import React, { createContext, useContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { SOCKET_BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useState } from "react";
import { useNotification } from "../../BroadcastMessage/pages/NotificationContext";

const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
  const socketRef = useRef();
  const [socket, setSocket] = useState(null);
  const token = localStorage.getItem("token");
  const showNotification = useNotification();

  useEffect(() => {
    const initSocket = () => {
      const newSocket = io(SOCKET_BASE_URL, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      newSocket.on("connect", () => {
        console.log("Socket connected");
      });

      newSocket.on("disconnect", (reason) => {
        console.log("Socket disconnected:", reason);
      });

      newSocket.on("connect_error", (error) => {
        console.error("Connection error:", error.message);
      });

      newSocket.on("get-notification", (data) => {
        if (data?.data?.notifications) {
          // console.log("get-notification-1", data);
          data?.data?.notifications.forEach((notification) => {
            const { title, content } = notification;
            showNotification(`${title}: ${content}`);
          });
        } else {
          // console.log("get-notification-2", data);
          const { title, content, roomId } = data;
          if (roomId?.name) {
            showNotification(`${title} to ${roomId?.name}: ${content}`);
          } else {
            showNotification(`${title} to you: ${content}`);
          }
        }
      });

      socketRef.current = newSocket;
      setSocket(newSocket);
    };

    if (token) {
      initSocket();

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
          console.log("Socket disconnected");
        }
      };
    } else {
      console.error("Token not found in localStorage");
    }
  }, [token, showNotification]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
