/** @format */

import {
  LIST_USER_REQUEST,
  LIST_USER_SUCCESS,
  LIST_USER_ERROR,
  SUBROLE_USER_REQUEST,
  SUBROLE_USER_SUCCESS,
  SUBROLE_USER_ERROR,
  INSPECTION_REQUEST,
  INSPECTION_SUCCESS,
  INSPECTION_ERROR,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
  STORE_USER_REQUEST,
  STORE_USER_SUCCESS,
  STORE_USER_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_ERROR,
  USER_ACTIVITY_REQUEST,
  USER_ACTIVITY_SUCCESS,
  USER_ACTIVITY_ERROR,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE_SUCCESS,
  ADD_IMAGE_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  APPROVE_USER_REQUEST,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_ERROR,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  Top_PERFORMER_REQUEST,
  Top_PERFORMER_SUCCESS,
  Top_PERFORMER_ERROR,
  RECENTLY_DATA_REQUEST,
  RECENTLY_DATA_SUCCESS,
  RECENTLY_DATA_ERROR,
  USER_PERMISSION_UPDATE_REQUEST,
  USER_PERMISSION_UPDATE_SUCCESS,
  USER_PERMISSION_UPDATE_ERROR,
  LIST_USER_CHECKLIST_REQUEST,
  LIST_USER_CHECKLIST_SUCCESS,
  LIST_USER_CHECKLIST_ERROR,
  LIST_USER_CHECKLIST_CLEAR,
  ADD_DOC_IMAGE_REQUEST,
  ADD_DOC_IMAGE_SUCCESS,
  ADD_DOC_IMAGE_ERROR,
  ADD_DOC_IMAGE_DEFAULT,
  DELETE_DOC_IMAGE,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  data: [],
  subRole: "",
  store: [],
  inspection: [],
  changePwd: "",
  details: "",
  activity: "",
  addUser: "",
  editUser: "",
  imgId: "",
  approval: "",
  userDelete: "",
  updateUser: "",
  topformer: "",
  recently: "",
  updatePermission: [],
  userChecklistData: [],
  proofImg: [],
  proofImgError: [],
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action?.payload,
      };
    }
    case LIST_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case LIST_USER_CHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_USER_CHECKLIST_SUCCESS: {
      if (action.payload.key === "user") {
        return {
          ...state,
          loading: false,
          userChecklistData: action.payload.data,
        };
      } else {
        return {
          ...state,
          loading: false,
          userChecklistData: {
            ...state.userChecklistData,
            data: {
              ...state.userChecklistData?.data,
              data: {
                ...state.userChecklistData?.data?.data,
                checklistIds: action.payload.data.data.data.checklistIds,
              },
            },
          },
        };
      }
    }
    case LIST_USER_CHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: "",
      };
    }
    case LIST_USER_CHECKLIST_CLEAR: {
      return {
        ...state,
        loading: false,
        userChecklistData: [],
        error: action.error,
      };
    }
    case SUBROLE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case SUBROLE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        subRole: action?.payload,
      };
    }
    case SUBROLE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case INSPECTION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case INSPECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        inspection: action?.payload,
      };
    }
    case INSPECTION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDelete: action?.payload,
      };
    }
    case DELETE_USERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case STORE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        store: action?.payload,
      };
    }
    case STORE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case STORE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case STORE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        store: action?.payload,
      };
    }
    case STORE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        changePwd: action?.payload,
      };
    }
    case CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case USER_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: action?.payload,
      };
    }
    case USER_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }
    case USER_ACTIVITY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case USER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        activity: action?.payload,
      };
    }
    case USER_ACTIVITY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ADD_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        addUser: action?.payload,
      };
    }
    case ADD_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case UPDATE_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPDATE_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateUser: action?.payload,
      };
    }
    case UPDATE_USERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case ADD_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        imgId: action?.payload,
      };
    }
    case ADD_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case ADD_DOC_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_DOC_IMAGE_SUCCESS: {
      const target = state.proofImg ? [...state.proofImg] : [];
      const targetErrors = state.proofImgError ? [...state.proofImgError] : [];

      if (target.length) {
        const modifiedProofImagesIndex = target.findIndex(
          (item) => item.type === action?.payload?.type
        );
        if(targetErrors[modifiedProofImagesIndex]?.[action?.payload?.key]){
          delete targetErrors[modifiedProofImagesIndex][action?.payload?.key];
        }
          
        target[modifiedProofImagesIndex] = {
          ...target[modifiedProofImagesIndex],
          type: action?.payload?.type,
          [action?.payload?.key]: action?.payload?.data,
        };

        return {
          ...state,
          loading: false,
          proofImg: target,
          proofImgError: targetErrors,
        };
      } else {
        const obj = {
          type: action?.payload?.type,
          [action?.payload?.key]: action?.payload?.data,
        };
        return {
          ...state,
          loading: false,
          proofImg: [obj],
          proofImgError: [],
        };
      }
    }
    case ADD_DOC_IMAGE_DEFAULT: {
      return {
        ...state,
        loading: true,
        error: "",
        proofImgError: [],
        proofImg: action.payload,
      };
    }
    case DELETE_DOC_IMAGE: {
      return {
        ...state,
        loading: true,
        error: "",
        proofImg: action.payload,
      };
    }
    case ADD_DOC_IMAGE_ERROR: {
      const target = state.proofImgError ? [...state.proofImgError] : [];
      if (target.length) {
        // const modifiedProofImagesIndex = target.findIndex(
        //   (item) => item.type === action?.payload?.type
        // );

        target[0] = {
          ...target[0],
          [action?.payload?.key]: action?.payload?.data,
        };

        return {
          ...state,
          loading: false,
          proofImgError: target,
        };
      } else {
        const obj = {
          [action?.payload?.key]: action?.payload?.data,
        };
        return {
          ...state,
          loading: false,
          proofImgError: [obj],
        };
      }
    }
    case EDIT_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        editUser: action?.payload,
      };
    }
    case EDIT_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    }
    case APPROVE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case APPROVE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        approval: action?.payload,
      };
    }
    case APPROVE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case Top_PERFORMER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case Top_PERFORMER_SUCCESS: {
      return {
        ...state,
        loading: false,
        topformer: action?.payload,
      };
    }
    case Top_PERFORMER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case RECENTLY_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case RECENTLY_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        recently: action?.payload,
      };
    }
    case RECENTLY_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case USER_PERMISSION_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case USER_PERMISSION_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        updatePermission: action?.payload,
      };
    }
    case USER_PERMISSION_UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        updatePermission: [],
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default UserReducer;
