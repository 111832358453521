import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { toast, ToastContainer } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import {
  CreateAttendanceApi,
  CreateAttendanceSuccess,
} from "../../redux/actions/AttendanceAction";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const NewAttendance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const addAttendance = useSelector(
    (state) =>
      state.attendanceLog &&
      state.attendanceLog.createData &&
      state.attendanceLog.createData.data
  );

  useEffect(() => {
    dispatch(CreateAttendanceSuccess(""));
  }, []);

  useEffect(() => {
    if (addAttendance) {
      setTimeout(() => {
        navigate("/attendance-log-config");
      }, 1000);
    }
  }, [addAttendance]);

  const validationSchema = Yup.object({
    checkInTime: Yup.string()
      .required("CheckIn Time is required")
      .max(25, "Enter maximum length of 25 characters"),

    checkOutTime: Yup.string()
      .required("CheckOut Time is required")
      .max(25, "Enter maximum length of 25 characters"),

    workingHours: Yup.number()
      .required("Working hours is required")
      .min(8, "Working hours should not be less than 8 hours")
      .max(24, "Working hours cannot exceed 24 hours")
      .typeError("Please enter a valid number"),

    status: Yup.boolean().oneOf([true], "Status disable not allowed"),

    selfieRequire: Yup.boolean().default(true),
  });

  const formik = useFormik({
    initialValues: {
      checkInTime: "",
      checkOutTime: "",
      workingHours: 0,
      selfieRequire: true,
      // status: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        checkInTime: values.checkInTime,
        checkOutTime: values.checkOutTime,
        workingHours: values.workingHours,
        selfieRequire: values.selfieRequire,
        // status: values.status,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        setLoader(true);
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `createAttendanceConfig?company_id=${id}`;
        dispatch(CreateAttendanceApi(allData));
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
            className="header-card"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/attendance-log-config" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Create
                  </Button>
                  <div style={{ marginRight: "8px", marginTop: "7px" }}>
                    <Tooltip
                      title={
                        <div style={{ maxWidth: "300px" }}>
                          <strong>New Attendance:</strong>
                          <p>Creating the new Attendance</p>
                        </div>
                      }
                      arrow
                      interactive
                    >
                      <InfoIcon
                        style={{
                          marginLeft: "2px",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          color: "#D76C00",
                        }}
                      />
                    </Tooltip>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>CheckIn Time</label>
                  <TimePicker
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="issueinput-fields"
                        size="small"
                        error={
                          formik.touched.checkInTime &&
                          Boolean(formik.errors.checkInTime)
                        }
                      />
                    )}
                    value={
                      formik.values.checkInTime
                        ? dayjs(formik.values.checkInTime, "HH:mm")
                        : null
                    }
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "checkInTime",
                        newValue ? newValue.format("HH:mm") : ""
                      );
                    }}
                    ampm
                  />
                  <small className="error">
                    {formik.errors.checkInTime && formik.touched.checkInTime
                      ? formik.errors.checkInTime
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>CheckOut Time</label>
                  <TimePicker
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="issueinput-fields"
                        size="small"
                        error={
                          formik.touched.checkOutTime &&
                          Boolean(formik.errors.checkOutTime)
                        }
                      />
                    )}
                    value={
                      formik.values.checkOutTime
                        ? dayjs(formik.values.checkOutTime, "HH:mm")
                        : null
                    }
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "checkOutTime",
                        newValue ? newValue.format("HH:mm") : ""
                      );
                    }}
                    ampm
                  />
                  <small className="error">
                    {formik.errors.checkOutTime && formik.touched.checkOutTime
                      ? formik.errors.checkOutTime
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Working Hours</label>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    className="issueinput-fields"
                    variant="outlined"
                    size="small"
                    name="workingHours"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.workingHours}
                    error={
                      formik.touched.workingHours &&
                      Boolean(formik.errors.workingHours)
                    }
                  />
                  <small className="error">
                    {formik.errors.workingHours && formik.touched.workingHours
                      ? formik.errors.workingHours
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.selfieRequire}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "selfieRequire",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Selfie Required"
                  />
                  <small className="error">
                    {formik.errors.selfieRequire && formik.touched.selfieRequire
                      ? formik.errors.selfieRequire
                      : null}
                  </small>
                </FormControl>

                {/* <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.status}
                        onChange={(e) =>
                          formik.setFieldValue("status", e.target.checked)
                        }
                        disabled
                      />
                    }
                    label="Status"
                  />
                  <small className="error">
                    {formik.errors.status && formik.touched.status
                      ? formik.errors.status
                      : null}
                  </small>
                </FormControl> */}
              </LocalizationProvider>
            </Container>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NewAttendance;
