/** @format */

import React, { useEffect, useState } from "react";
import AssignHeader from "./tableHeader/AssignHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Grid,
  Stack,
  Typography,
  Avatar,
  Chip,
  TableFooter,
  circularProgressClasses,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import User from "../../assets/icons/svg-icons/User.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {
  StorewiseChecklistReportApi,
  SubmissionListApi,
} from "../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Header from "./submissionTabs/Header";
import Progressbar from "../GlobalProgress/Progressbar";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import Highlighter from "react-highlight-words";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Download, PaddingRounded } from "@mui/icons-material";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import axios from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DownloadListHeader from "./tableHeader/DownloadListHeader";
import TableContainer from "@mui/material/TableContainer";
import { ToastContainer } from "react-toastify";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const DownloadList = () => {
  return (
    <Box>
      <ToastContainer autoClose={3000} />

      <DownloadListHeader />
      {/* <p>
        Select dates within 7 days and click on Submission List to download
        submission report.
      </p> */}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default DownloadList;
