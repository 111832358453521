/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Stack,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Chart from "react-apexcharts";
import ChecklistGraph from "./ChecklistGraph";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  checklistChartApi,
  dueScheduleApi,
  upcomingScheduleApi,
} from "../../redux/actions/dashboardChartAction";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { LoaderComponent } from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";

var options = {
  labels: ["Created", "Submitted", "InProgress", "Remaining", "Expired"],
  colors: ["#F39A4C", "#039855", "#BB251A", "#D35400", "#5DADE2"],
  stroke: {
    width: 0,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: "50px",
      },
    },
  },
};
const ChecklistGraphCard = ({ companyId, frequency }) => {
  const dispatch = useDispatch();

  const DueChecklistRes = useSelector(
    (state) =>
      state &&
      state?.dashboardReducer &&
      state?.dashboardReducer?.dueSchedule &&
      state?.dashboardReducer?.dueSchedule?.data &&
      state?.dashboardReducer?.dueSchedule?.data?.data?.assignments
  );

  const pieChartRes = useSelector(
    (state) =>
      state &&
      state?.dashboardReducer &&
      state?.dashboardReducer?.checklistChart &&
      state?.dashboardReducer?.checklistChart?.data &&
      state?.dashboardReducer?.checklistChart?.data?.data &&
      state?.dashboardReducer?.checklistChart?.data?.data?.CompanyData
  );
  const [dueChecklist, setDueChecklist] = useState("");
  const [pieChartValue, setPieChartValue] = useState([]);

  const payloadRequest1 = {
    body: {
      pagination: {
        page: 1,
        per_page: 5,
      },
      sort: {
        sort_as: "updatedAt",
      },
      FilterChecklist: "Active",
      // FilterChecklist: "",
      search: "",
      archived: false,
    },
  };

  useEffect(() => {
    dueChecklistData(companyId);
    pieChartData(companyId);
  }, [frequency]);
  
  useEffect(() => {
    if (DueChecklistRes) {
      setDueChecklist(DueChecklistRes);
    }
  }, [DueChecklistRes]);

  useEffect(() => {
    if (pieChartRes) {
      let pieData = [];
      pieData.push(pieChartRes.assigned);
      pieData.push(pieChartRes.completed);
      pieData.push(pieChartRes.partiallyFilled);
      pieData.push(pieChartRes.remaining);
      pieData.push(pieChartRes.Expired);
      setPieChartValue(pieData);
    } else {
      let pieData = [];
      pieData.push(0,0,0,0,0);
      setPieChartValue(pieData);
    }
  }, [pieChartRes]);

  // useEffect(() => {
  //     if (checklsitChartChecklistRes) {
  //         setChecklsitChart(checklsitChartChecklistRes);
  //     }
  // }, [checklsitChartChecklistRes]);

  // useEffect(() => {
  //     if (checklsitChartUserDataRes) {
  //         setChecklsitChartUserData(checklsitChartUserDataRes);
  //     }
  // }, [checklsitChartUserDataRes]);

  const dueChecklistData = (id) => {
    payloadRequest1.url = BASE_URL + `formsAssignment/admin?company_id=${id}&frequency=${frequency ? frequency : ""}`;
    dispatch(dueScheduleApi(payloadRequest1));
  };

  const pieChartData = (id) => {
    const data = {
      url: BASE_URL + `dashboard/CompanyPieChart?company_id=${id}&frequency=${frequency ? frequency : ""}`,
    };
    dispatch(checklistChartApi(data));
  };

  const text = {
    color: "red",
  };
console.log("pieChartValue", pieChartValue);
  return (
    <Grid container spacing={3} sx={{ mt: 0 }}>
      <Grid item xs={12} md={8} sm={12} lg={8}>
        <ChecklistGraph frequency={frequency}/>
      </Grid>
      <Grid item xs={12} md={4} sm={12} lg={4}>
        <Card className="card dash-piechart">
          <CardContent style={{ paddingBottom: "0", height: !pieChartValue.length ? "400px" : "auto" }}>
          <div align="right" style={{right:"0",position:"absolute",marginRight:"33px"}}>
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Current Day Checklist Status:</strong>
                            <p>Current Day Checklist Status section displays the status of 
                              all the checklists assigned to all the roles for the day
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            <div className="heading" style={{ marginBottom: "20px" }}>
              Current Day checklist status
            </div>
            {!pieChartValue.length ? (
              <div
                style={{
                  height: "inherit",
                  width: "inherit",
                  position: "relative",
                }}
              >
                <LoaderComponent play />
              </div>
            ) : (
              <>
                <Chart
                  options={options}
                  series={pieChartValue}
                  type="donut"
                  width="350"
                  height={300}
                  className="donut-chart"
                />
                <div
                  className="heading"
                  style={{ marginBottom: "7px", marginTop: "10px", color: "black" }}
                >
                  Status as per all users in company
                </div>
              </>
            )}
          </CardContent>
        </Card>
        {/* Due Checklists For Today */}
        <Card sx={{ mt: 2 }} className="card">
          <CardContent>
          <div align="right">
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Due Checklists For Today: </strong>
                            <p>Due checklists for today section displays
                              the checklists that are due for today's submission
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            <Typography variant="p" component="div" className="heading">
              Due Checklists For Today
            </Typography>
            <Stack
              direction="column"
              spacing={1}
              sx={{ mt: 2, px: 3 }}
              className="due-check"
            >
              {/* <Typography variant="p" component="b" sx={{ display: "flex" }}>
                                <Typography variant="p" component="b" sx={{ display: "flex" }}>
                                    Store wise module
                                    <ChevronRightIcon className="arrow-icon" />
                                </Typography>
                            </Typography>
                            <Divider variant="inset" /> */}
              {dueChecklist &&
                dueChecklist.length > 0 &&
                dueChecklist.map((item) => {
                  return (
                    <List
                      className={
                        (item?.frequency === 3 && "list-data daily-hover") ||
                        (item?.frequency === 2 && "list-data hourly-hover") ||
                        (item?.frequency === 5 && "list-data monthly-hover") ||
                        (item?.frequency === 1 && "list-data nofreq-hover") ||
                        (item?.frequency === 4 && "list-data weekly-hover")
                      }
                    >
                      <Tooltip
                        placement="right"
                        title={
                          (item?.frequency === 3 && "Daily") ||
                          (item?.frequency === 2 && "Hourly") ||
                          (item?.frequency === 5 && "Monthly") ||
                          (item?.frequency === 1 && "No frequency") ||
                          (item?.frequency === 4 && "Weekly")
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: `${
                                (item?.frequency === 3 && "lightgreen") ||
                                (item?.frequency === 2 && "rgb(190, 26, 26)") ||
                                (item?.frequency === 5 && "lightskyblue") ||
                                (item?.frequency === 1 &&
                                  "rgb(255, 120, 120)") ||
                                (item?.frequency === 4 && "lightsalmon")
                              }`,
                              "& .MuiTooltip-arrow": {
                                color: "white",
                              },
                            },
                          },
                        }}
                      >
                        <ListItem
                          style={{ padding: "0px" }}
                          // secondaryAction={
                          //   <IconButton edge="end" aria-label="delete">
                          //     <ChevronRightIcon />
                          //   </IconButton>
                          // }
                        >
                          {/* <ListItemText
                            primary={item?.title}
                            // secondary={moment(item.starts_at).format('lll')}
                          /> */}
                          <ListItemText
                            primary={item?.title}
                            secondary={
                              <p style={{ margin: "0px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Start:
                                </span>
                                {moment(item.starts_at).format("ll")}{" "}
                                {item?.startTime}{" "}
                                <span style={{ fontWeight: "bold" }}>End:</span>
                                {moment(item.expires_at).format("ll")}{" "}
                                {item?.endTime}
                              </p>
                            }
                          />
                        </ListItem>
                      </Tooltip>
                    </List>
                  );
                }) || "No Data Found!"}
              <Link to="/checklist/all-checklist" className="message-link-2">
                View all
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ChecklistGraphCard;
