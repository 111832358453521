import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Stack,
  Avatar,
} from "@mui/material";
import User from "../../../../assets/icons/svg-icons/User.svg";
import { useEffect } from "react";
import { RecentlyApi } from "../../../redux/actions/User";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
function createData(Store, Number, Channel) {
  return { Store, Number, Channel };
}

const rows = [
  createData("Alisign", "1234567890", "Panipat", "190"),
  createData("Alisign", "1234567890", "Panipat", "190"),
  createData("Alisign", "1234567890", "Panipat", "190"),
  createData("Alisign", "1234567890", "Panipat", "190"),
  createData("Alisign", "1234567890", "Panipat", "190"),
  createData("Alisign", "1234567890", "Panipat", "190"),
];
const data = {
  paramKey: "monthly",
};

const RecentAdded = () => {
  const dispatch = useDispatch();
  const recentlyList = useSelector(
    (state) =>
      state &&
      state.user &&
      state.user.recently &&
      state.user.recently.data &&
      state.user.recently.data.data &&
      state.user.recently.data.data.users
  );
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {
      data.url = BASE_URL + `users/Recently?company_id=${companyId}`;
      dispatch(RecentlyApi(data));
    }
  }, []);
  return (
    <Card className="card" sx={{ borderRadius: "8px" }}>
      <CardContent>
      <div style={{marginRight:"8px",float:"right"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
        <Typography variant="p" component="h2" className="top-heading">
          Recently Added
        </Typography>
        <TableContainer
          component={Paper}
          className="card table-scroll"
          sx={{ boxShadow: "none" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              {recentlyList &&
                recentlyList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">
                      <Stack direction="row" spacing={2}>
                        <Avatar className="message-avatar">
                          <img src={User} alt="avatar" />
                        </Avatar>
                        <Stack direction="column">
                          <Typography variant="p" component="b">
                            {row.first_name}
                          </Typography>
                          <Typography variant="p" component="p">
                            {row.contact}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">{row.subRole}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default RecentAdded;
