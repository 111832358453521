import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { Typography, Grid, TablePagination } from "@mui/material";
import Delete from "../../assets/icons/svg-icons/Delete.svg";
import View from "../../assets/icons/svg-icons/View.svg";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import User from "../../assets/icons/svg-icons/User.svg";
import Header from "./pages/Header";

function createData(
  CustomerName,
  Number,
  ContactEmail,
  ContactNumber,
  Category,
  CreatedBy,
  CreatedDate,
  CreatedTime
) {
  return {
    CustomerName,
    Number,
    ContactEmail,
    ContactNumber,
    Category,
    CreatedBy,
    CreatedDate,
    CreatedTime,
  };
}

const rows = [
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
  createData(
    "Skonge",
    "1234567890",
    "skonge@hrgdev.com",
    "7795817096",
    "Office Cleaning",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM"
  ),
];

const Customer = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = () => {
    navigate("/customer/customer-details");
  };

  return (
    <Box>
      {/* Header of the page */}
      <Header />

      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "5px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <TableContainer component={Paper} className="table-container">
          <Table size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Customer Name
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Contact
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Category
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Created by
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Created at
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Status
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    <Stack direction="row" spacing={2}>
                      <Avatar className="message-avatar">
                        <img src={User} alt="avatar" />
                      </Avatar>
                      <Stack direction="column">
                        <Typography variant="p" component="b">
                          {row.CustomerName}
                        </Typography>
                        <Typography variant="p" component="p">
                          {row.Number}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="column">
                      <Typography variant="p" component="p">
                        {row.ContactEmail}
                      </Typography>
                      <Typography variant="p" component="p">
                        {row.ContactNumber}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">{row.Category}</TableCell>
                  <TableCell align="center">{row.CreatedBy}</TableCell>
                  <TableCell align="center">
                    <Stack direction="column">
                      <Typography variant="p" component="p">
                        {row.CreatedDate}
                      </Typography>
                      <Typography variant="p" component="p">
                        {row.CreatedTime}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Box className="dropdown-chip">
                      <select className="select2 border-none">
                        <option>SCHEDULE</option>
                        <option>a</option>
                        <option>b</option>
                      </select>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      avatar={<img src={View} alt="View" />}
                      onClick={handleClick}
                      variant="outlined"
                      className="iconBox"
                    />
                    <Chip
                      avatar={<img src={Delete} alt="Delete" />}
                      variant="outlined"
                      className="iconBox"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 1, borderRadius: 1 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              showFirstButton={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Customer;
