/** @format */

import React, { useEffect, useState } from "react";
import AssignHeader from "./tableHeader/AssignHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Grid, Stack, Typography } from "@mui/material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
// import { useDemoData } from "@mui/x-data-grid-generator";
import {
  Avatar,
  IconButton,
  makeStyles,
  TableFooter,
  useTheme,
} from "material-ui-core";
import TablePagination from "@mui/material/TablePagination";
import User from "../../assets/icons/svg-icons/User.svg";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { ListUserChecklistApi } from "../redux/actions/Checklist";
import PropTypes from "prop-types";
import Progressbar from "../GlobalProgress/Progressbar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Highlighter from "react-highlight-words";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const UserChecklist = (title) => {
  const dispatch = useDispatch();

  const userCheckList = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.userCheckList &&
      state?.checklist?.userCheckList?.data &&
      state?.checklist?.userCheckList?.data?.data &&
      state?.checklist?.userCheckList?.data?.data?.UserData
  );
  const totalitems = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.userCheckList &&
      state?.checklist?.userCheckList?.data &&
      state?.checklist?.userCheckList?.data.data &&
      state?.checklist?.userCheckList?.data?.data?.paginate
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  const [userChecklistData, setUserChecklistData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectRole, setSelectRole] = useState([]);

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
      Role: [],
    },
  };

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  useEffect(() => {
    setUserChecklistData(userCheckList);
  }, [userCheckList]);
  useEffect(() => {
    if (searchValue == "") {
      listUserChecklist(payloadRequest);
    }
  }, [searchValue]);

  useEffect(() => {
    if (selectedSubRole) {
      setSelectRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    if (selectRole) {
      payloadRequest.body.Role = selectRole;
      listUserChecklist(payloadRequest);
    }
  }, [selectRole]);

  useEffect(() => {
    if (selectedSubRole) {
      payloadRequest.body.Role = selectedSubRole;
      listUserChecklist(payloadRequest);
    }
  }, [selectedSubRole]);

  const listUserChecklist = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `Userlist?company_id=${id}`;
    dispatch(ListUserChecklistApi(payloadRequest));
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    payloadRequest.body.pagination.page = newPage + 1;
    payloadRequest.body.Role = selectedSubRole;
    listUserChecklist(payloadRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.Role = selectedSubRole;
    listUserChecklist(payloadRequest);
  };

  // pagination handle for DataGrid

  // const handleChangePage = (event, newPage) => {
  //   setPage(event + 1);
  //   payloadRequest.body.pagination.page = event + 1;
  //   payloadRequest.body.Role = selectedSubRole;
  //   listUserChecklist(payloadRequest);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event, 10));
  //   setPage(1);
  //   payloadRequest.body.pagination.per_page = parseInt(event, 10);
  //   payloadRequest.body.Role = selectedSubRole;
  //   listUserChecklist(payloadRequest);
  // };

  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      payloadRequest.body.search = searchValue;
      payloadRequest.body.pagination.page = 1;
      setPage(1);
      listUserChecklist(payloadRequest);
    }
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          userChecklistData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "User",
      headerName: "User",
      renderHeader: () => <strong>{"User"}</strong>,
      flex: 1,
      align: "left",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.UserName) {
          result.push(params?.row?.UserName);
        } else {
          result = ["Unknown"];
        }
        let results = result.join(", ");
        return (
          <div style={{ display: "flex" }}>
            {/* <Avatar className="message-avatar" style={{ alignSelf: "center" }}>
              <img src={User} alt="avatar" />
            </Avatar> */}
            <LightTooltip title={results}>
              <p
                className="text-short"
                style={{
                  marginLeft: "10px",
                  textAlign: "start",
                }}
              >
                {results}
              </p>
            </LightTooltip>
          </div>
        );
      },
    },
    {
      field: "Role",
      headerName: "Role",
      renderHeader: () => <strong>{"Role"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.Role) {
          result.push(params?.row?.Role);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Created	",
      headerName: "Created",
      renderHeader: () => <strong>{"Created"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.Assigned) {
          result.push(params?.row?.Assigned);
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Submitted",
      headerName: "Submitted",
      renderHeader: () => <strong>{"Submitted"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params.row.Submitted) {
          result.push(params.row.Submitted !== 0 ? params.row.Submitted : "");
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },

    {
      field: "In Progress",
      headerName: "In Progress",
      renderHeader: () => <strong>{"In Progress"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.InProgress) {
          result.push(params?.row?.InProgress);
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Progress",
      headerName: "Progress",
      renderHeader: () => <strong>{"Progress"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.Progress) {
          result.push(`${params?.row?.Progress}%`);
        } else {
          result = ["0%"];
        }
        return result.join(", ");
      },
    },
  ];

  return (
    <Box>
      <AssignHeader
        searchOnChange={handleSearch}
        searchValue={searchValue}
        handleKeyDown={handleKeyDown}
        userCancle={"userCancle"}
      />
      {Loader ? (
        <Progressbar />
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            borderRadius: 1,
            overflowX: "auto",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: 8,
            },
          }}
        >
          {/* <TableContainer component={Paper} className="table-container">
            <Table size="large" aria-label="simple table">
              <TableHead sx={{ m: 4 }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "300px" }}>
                    User
                  </TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Created</TableCell>
                  <TableCell align="center">Submitted</TableCell>
                  <TableCell align="center">In Progress</TableCell>
                  <TableCell align="center">Progress</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {userChecklistData &&
                  userChecklistData.length > 0 &&
                  userChecklistData.map((row, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="left">
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{ justifyContent: "center" }}
                          >
                            <Avatar className="message-avatar">
                              <img src={User} alt="avatar" />
                            </Avatar>
                            <Stack direction="column">
                              <Typography
                                variant="p"
                                component="b"
                                className="text-short2"
                              >
                                {row?.UserName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          {row?.Role}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          {row?.Assigned}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          {row?.Submitted}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          {row?.InProgress}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          {row?.Progress}%
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className="tablepagination"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: totalitems?.total_item },
                    ]}
                    // colSpan={3}
                    count={totalitems?.total_item}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "Items per Page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Items per Page"}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer> */}
          {userChecklistData ? (
            <>
              <DataGrid
                disableColumnMenu
                rows={userChecklistData}
                columns={columns}
                getRowId={(userChecklistData) => userChecklistData.UserName}
                hideFooterPagination={true}
                hideFooter={true}
                className="grid-table"
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalitems?.total_item },
                ]}
                // colSpan={3}
                count={totalitems?.total_item}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            ""
          )}
        </Box>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default UserChecklist;
