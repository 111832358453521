/** @format */
import React, { useContext, useState, useCallback } from "react";
import { AuditorContext } from "../../../../context/auditorContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import {
  AddAssessmentImageAnswerApi,
  AddAssessmentImageClear,
  DeleteAssessmentImage,
  TDRVideoTypeApi,
} from "../../../redux/actions/LmsAction";
import { useSelector } from "react-redux";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import { confirmAlert } from "react-confirm-alert";
import ImageViewer from "react-simple-image-viewer";

import DeleteHover from "../../../images/DeleteHover.svg";
import Delete from "../../../images/Delete.svg";

const SubmitAssessment = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const from = location?.state?.from;
  const attempt = localStorage.getItem("attempt");
  const currentAttempt = localStorage.getItem("currentAttempt");
  const repeatedAssessment = localStorage.getItem("repeatedAssessment");
  // const allTrainingData = location?.state;
  const navigate = useNavigate();
  const [isImages, setIsImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index, url) => {
    let imageUrl = [];
    imageUrl.push(url);
    setIsImages(imageUrl);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [hover, setHover] = useState({});

  const [trainingId, setAssessmentId] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMEssage, setErrorMEssage] = useState({});

  const [answers, setAnswers] = useState({});
  const [assessmentDetailsData, setAssessmentDetailsData] = useState({});

  const getImageKeys = useSelector(
    (state) =>
      state.lms && state.lms.assesmentImages && state.lms.assesmentImages
  );

  let {
    setCompanyId,
    setStoreId,
    setStoreTitle,
    currentAssessment,
    setCurrentAssessment,
    storeTitle,
    storeId,
    companyId,
  } = useContext(AuditorContext);

  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  // useEffect(() => {
  //   const res = localStorage.getItem("selectedAssessment") || location?.state;
  //   if (res) {
  //     const parsedData = JSON.parse(res);
  //     setAssessmentDetailsData(parsedData);
  //   }
  // }, []);

  // useEffect(() => {
  //   const res = localStorage.getItem("selectedAssessment") || location?.state;
  //   if (res) {
  //     const parsedData = JSON.parse(res);
  //     setAssessmentDetailsData(parsedData);
  //   }
  // }, []);

  useEffect(() => {
    const res = localStorage.getItem("selectedAssessment") || location?.state;
    if (typeof res === "string") {
      const parsedData = JSON.parse(res);
      setAssessmentDetailsData(parsedData);
      console.log({ parsedData: res, location });
    } else {
      setAssessmentDetailsData(res);
    }
  }, []);

  useEffect(() => {
    setAnswers({});
  }, [currentAttempt]);

  const [questionArr, setQuestionArr] = useState([
    {
      title: "q1",
      desc: "f",
      question_score: 1,
      type: {
        validations: [],
        option_score: {
          acceptable: 1,
          discarded: 0,
          no: 0,
          notAcceptable: 0,
          notApplicable: 0,
          returned: 0,
          yes: 1,
        },
        key: "boolean",
        title: "Boolean",
        title_to_show: "Boolean",
        id: 21,
      },
      rules: [],
      order: 2,
      id: 5,
      answer: {
        value: true,
        title: "Yes",
      },
      attached_list: null,
      titleImages: [],
    },
  ]);

  const [assessmentData, setAssessmentData] = useState({
    _id: assessmentDetailsData?._id,
    summary: assessmentDetailsData?.summary,
    questions: questionArr,
    passing_score: assessmentDetailsData?.passing_score,
    deleted: assessmentDetailsData?.deleted,
    showScore: assessmentDetailsData?.showScore,
    title: assessmentDetailsData?.title,
    duration: assessmentDetailsData?.duration,
    createdAt: assessmentDetailsData?.createdAt,
    updatedAt: assessmentDetailsData?.updatedAt,
    isTaken: assessmentDetailsData?.isTaken,
    score: assessmentDetailsData?.score,
    pass: true,
    submitTime: new Date().getTime(),
    training_id: assessmentDetailsData?.training?._id,
    store: {
      _id: storeId,
      title: storeTitle,
    },
  });

  const handleAnswerChange = (
    questionTitle,
    answer,
    question,
    qType = "other"
  ) => {
    let booleanAnswer = {
      value: true,
      title: "Yes",
    };
    if (qType === "boolean") {
      booleanAnswer =
        answer == "Yes"
          ? {
              value: true,
              title: "Yes",
            }
          : {
              value: false,
              title: "No",
            };
    }
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionTitle]: qType === "boolean" ? booleanAnswer : answer,
    }));
  };

  const getConfirmationClose = () => {
    // attempt
    const message =
      repeatedAssessment === "false"
        ? "Do you want to cancel assessment submission?"
        : `Do you want to cancel assessment submission for remaining ${
            attempt - currentAttempt
          } users?`;

    confirmAlert({
      title: "Exit",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setTimeout(() => {
              navigate("/auditor-home/my-assessments", {
                state: {},
              });
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getConfirmation = (progressId) => {
    confirmAlert({
      title: "Complete Training",
      message: "Do you want to complete assessment and training?",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            const token = localStorage.getItem("token");
            const response = await axios.put(
              `${BASE_URL}trainingAssignment/complete?company_id=${companyId}`,
              {
                progressId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.data.message) {
              toast.success(response.data.message);
              setTimeout(() => {
                if (from !== "DESCLAIMER") {
                  navigate("/auditor-home/my-assessments", {
                    state: {},
                  });
                } else {
                  navigate("/auditor-home/my-assessments", {
                    state: {},
                  });
                }
              }, 2000);
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setTimeout(() => {
              if (from !== "DESCLAIMER") {
                navigate("/auditor-home/my-assessments", {
                  state: {},
                });
              } else {
                navigate("/auditor-home/my-assessments", {
                  state: {},
                });
              }
            }, 500);
          },
        },
      ],
    });
  };

  const removeImage = (id, key) => {
    const target = imageKey[id].filter((item) => item.key !== key);
    const modifiedData = { ...imageKey, [id]: target };

    dispatch(DeleteAssessmentImage(modifiedData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let currentAssessmentData = assessmentDetailsData;
    const errorList = {};
    const QAnswer = assessmentDetailsData.questions.map((sData) => {
      if (sData.type.key === "date") {
        sData.answer =
          sData?.answer && Object.keys(sData.answer)?.length
            ? sData.answer
            : answers[sData.id];
      }
      if (sData.type.key === "datetime" && answers[sData.id]) {
        let timeSort =
          answers[sData.id].date +
          " " +
          moment(answers[sData.id]?.time?.$d).format("hh:mm:ss A");
        sData.answer =
          sData?.answer && Object.keys(sData.answer)?.length
            ? sData.answer
            : timeSort;
        sData.userAnswer = timeSort;
        const currentAnswer = {
          answer: timeSort,
        };
        sData.currentAnswer = currentAnswer;
      } else if (
        sData.type.key === "file" &&
        imageKey[sData.id] &&
        imageKey[sData.id].length
      ) {
        const imgKeys = imageKey[sData.id].map((item) => {
          return item.key;
        });

        sData.answer =
          sData?.answer && Object.keys(sData.answer).length
            ? sData.answer
            : imgKeys;
        sData.userAnswer = imgKeys;
        const currentAnswer = {
          answer: imgKeys,
        };
        sData.currentAnswer = currentAnswer;
      } else {
        sData.userAnswer = answers[sData.id];
        const currentAnswer = {
          answer: answers[sData.id],
        };
        sData.currentAnswer = currentAnswer;
      }

      if (sData.type.key === "file") {
        errorList[sData.id] =
          imageKey[sData.id] && imageKey[sData.id]?.length
            ? ""
            : "Image is required.";
      }
      if (sData.type.key === "date") {
        errorList[sData.id] = answers[sData.id] ? "" : "Date is required.";
      }
      if (sData.type.key === "datetime") {
        errorList[sData.id] =
          answers[sData.id] && answers[sData.id].date
            ? answers[sData.id]?.time
              ? ""
              : "Time is required."
            : "Date is required.";
      }
      if (sData.type.key === "text") {
        errorList[sData.id] = answers[sData.id]
          ? ""
          : "Text input is required.";
      }
      if (sData.type.key === "number") {
        errorList[sData.id] = answers[sData.id]
          ? ""
          : "Number input is required.";
      }
      if (sData.type.key === "multi_select") {
        errorList[sData.id] =
          answers[sData.id] && answers[sData.id].length
            ? ""
            : "Options is required.";
      }
      if (sData.type.key === "single_select" || sData.type.key === "boolean") {
        errorList[sData.id] =
          answers[sData.id] && answers[sData.id].title
            ? ""
            : "Option is required.";
      }
      sData.correctAnswer = sData.answer;
      return sData;
    });
    currentAssessmentData.questions = QAnswer;
    // Set the store manually in the payload
    currentAssessmentData.store = {
      _id: storeId,
      title: storeTitle,
    };
    setErrorMEssage(errorList);
    if (Object.values(errorList).filter((item) => item.length).length) {
      toast.error("Enter missing input to complete assessment.");
      setLoader(false);
      return;
    }
    if (currentAssessmentData.training && currentAssessmentData.training._id) {
      currentAssessmentData.training_id = currentAssessmentData.training._id;
    } else {
      toast.error(
        "Assigned training not found, contact your admin for more details."
      );
      setLoader(false);
      return;
    }
    // return;
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}submitAssessment?company_id=${companyId}`,
        currentAssessmentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.message) {
        toast.success(response.data.message);
        dispatch(AddAssessmentImageClear({}));
        setLoader(false);
        if (repeatedAssessment === "true") {
          if (currentAttempt === attempt) {
            getConfirmation(response.data.data.progressId);
          } else {
            localStorage.setItem(
              "currentAttempt",
              parseInt(currentAttempt) + 1
            );
          }
        } else {
          setTimeout(async () => {
            if (allTrainingDataStage.assessment.length > 1) {
              // navigate("/auditor-home/my-assessments", {
              //   state: { },
              // });
              const paginationRequest = {
                body: {
                  trainingId: currentAssessmentData.training_id,
                  storeId: storeId,
                },
              };
              paginationRequest.url =
                BASE_URL + `training/details?company_id=${companyId}`;
              await dispatch(TDRVideoTypeApi(paginationRequest));
              setAnswers({});
              localStorage.setItem("selectedAssessment", "");
            } else {
              navigate("/auditor-home/my-assessments", {
                state: {},
              });
            }
          }, 500);
        }
      } else {
        setLoader(false);
        toast.error("Unknown error occurred");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occurred while submitting assessment");
      }
      setLoader(false);
      console.error(error);
    }
  };

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const [image, setImage] = useState();
  const [imageKey, setImageKey] = useState();

  const handleImageUpload = (e, id) => {
    if (getImageKeys[id]?.length === 5) {
      toast.error("Maximum 5 Images Allowed.");
      return;
    }
    const selectedImage = e.target.files[0];
    setImage({ img: selectedImage, qId: id });
  };

  useEffect(() => {
    if (image) {
      const formData = new FormData();
      let oldImage = [];
      formData.append("File", {
        name: image?.img?.name,
        type: image?.img?.type,
        size: image?.img?.size,
      });

      const imagefile = {
        files: image?.img,
      };

      const imageData = {
        body: imagefile,
      };

      if (imageKey[image?.qId]) {
        oldImage = [...imageKey[image?.qId]];
      }

      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      dispatch(AddAssessmentImageAnswerApi(imageData, image?.qId, oldImage));
    }
  }, [image]);

  useEffect(() => {
    setImageKey(getImageKeys);
  }, [getImageKeys]);
  const allTrainingData = location?.state?.allTrainingData
    ? location?.state?.allTrainingData
    : location?.state;
  const [allTrainingDataStage, setAllTrainingData] = useState(allTrainingData);

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevIsAccordionOpen) => !prevIsAccordionOpen);
  };

  const handleBackButtonClick = () => {
    // navigate("/auditor-home/my-assessments");
    getConfirmationClose();
  };

  return (
    <>
      {from !== "DESCLAIMER" && (
        <Box sx={{ marginTop: 2, pl: 1, pr: 1 }} className="header-card">
          <Accordion
            expanded={isAccordionOpen}
            className="custom-accordion"
            sx={{
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              "& .MuiAccordionSummary-content": {
                margin: 0,
                alignItems: "center",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ justifyContent: "space-between" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    left: "0px",
                  }}
                >
                  <Box className="header-cardNew">
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        <IconButton
                          onClick={handleBackButtonClick}
                          sx={{
                            fontSize: "1.25rem",
                          }}
                        >
                          <ArrowBackIcon /> BACK
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}></Grid>
                    </Grid>
                  </Box>
                </div>
                <Box
                  onClick={handleAccordionToggle}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div></div>
                  <Typography align="center" variant="h6" component="div">
                    <strong>
                      {isAccordionOpen
                        ? allTrainingData?.name
                        : allTrainingData?.name}
                    </strong>
                  </Typography>
                  <div>
                    <Typography align="center">
                      <strong>{storeTitle || "test"}</strong>
                    </Typography>
                  </div>
                </Box>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  border: "1px solid",
                  borderRadius: "4px",
                  padding: "10px",
                  background: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 20%",
                  }}
                >
                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <p>
                      <strong>Name:</strong> {allTrainingData?.name}
                    </p>
                  </Typography>

                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <p>
                      <strong>Summary:</strong> {allTrainingData?.summary}
                    </p>
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 20%",
                  }}
                >
                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    {allTrainingData?.duration ? (
                      <div style={{ marginLeft: "1px", textAlign: "center" }}>
                        <AccessTimeIcon />
                        <span>
                          <strong>Duration:</strong>
                        </span>
                        {allTrainingData?.duration}
                      </div>
                    ) : (
                      <div>
                        {allTrainingData?.duration ? (
                          <span>
                            <strong>Duration:</strong>{" "}
                            {allTrainingData?.duration}
                          </span>
                        ) : (
                          <span>
                            <strong>Duration:</strong> 0
                          </span>
                        )}
                      </div>
                    )}
                  </Typography>

                  <Typography sx={{ mt: 1 }}>
                    {allTrainingData?.updatedAt ? (
                      <React.Fragment>
                        <span>
                          <strong>Last updated:</strong>{" "}
                        </span>
                        {moment(allTrainingData?.updatedAt).format(
                          "YYYY/MM/DD HH:mm:ss"
                        ) || <span>"N/A"</span>}
                      </React.Fragment>
                    ) : (
                      // <React.Fragment>
                      //   <span>
                      //     <strong>Last updated:</strong>{" "}
                      //   </span>
                      //   {(() => {
                      //     const now = new Date();
                      //     const updatedAt = new Date(allTrainingData?.updatedAt);
                      //     const diffInMs = now - updatedAt;

                      //     const seconds = Math.floor(diffInMs / 1000);
                      //     const minutes = Math.floor(seconds / 60);
                      //     const hours = Math.floor(minutes / 60);
                      //     const days = Math.floor(hours / 24);
                      //     const months = Math.floor(days / 30);
                      //     const years = Math.floor(months / 12);

                      //     if (years > 0) {
                      //       return <span>{years} year(s) ago</span>;
                      //     } else if (months > 0) {
                      //       return <span>{months} month(s) ago</span>;
                      //     } else if (days > 0) {
                      //       return <span>{days} day(s) ago</span>;
                      //     } else {
                      //       return <span>{hours} hour(s) ago</span>;
                      //     }
                      //   })()}
                      // </React.Fragment>
                      <span>
                        <strong>Last updated:</strong> N/A
                      </span>
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 20%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <p>
                      <strong>Completion Progress:</strong>(
                      {allTrainingDataStage?.completedPercentage?.toFixed(
                        allTrainingDataStage?.completedPercentage ? 2 : 0
                      )}
                      %)
                    </p>
                  </Typography>
                  <div style={{ width: "250px" }}>
                    <LinearProgress
                      variant="determinate"
                      value={allTrainingDataStage?.completedPercentage}
                      sx={{ height: 10 }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: `${allTrainingDataStage?.completedPercentage}%`,
                          backgroundColor:
                            allTrainingDataStage?.completedPercentage === 100
                              ? "green"
                              : "red",
                        }}
                      />
                    </LinearProgress>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 20%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <p>
                      <strong>Assessment Progress: </strong>(
                      {allTrainingData?.assessmentProgress?.toFixed(
                        allTrainingData?.assessmentProgress ? 2 : 0
                      )}
                      %)
                    </p>
                  </Typography>
                  <div style={{ width: "250px" }}>
                    <LinearProgress
                      variant="determinate"
                      value={allTrainingData?.assessmentProgress}
                      sx={{ height: 10 }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: `${allTrainingData?.assessmentProgress}%`,
                          backgroundColor:
                            allTrainingData?.assessmentProgress === 100
                              ? "green"
                              : "red",
                        }}
                      />
                    </LinearProgress>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 20%",
                  }}
                >
                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <p>
                      <strong>Assessment Status:</strong>{" "}
                      {"(" +
                        allTrainingData?.assessmentProgress?.toFixed(
                          allTrainingData?.assessmentProgress ? 2 : 0
                        ) +
                        "%)"}
                    </p>
                  </Typography>

                  <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "16%",
                        background:
                          allTrainingData?.assessmentProgress >= 66
                            ? "green"
                            : allTrainingData?.assessmentProgress >= 33
                            ? "gray"
                            : "red",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {allTrainingData?.assessmentProgress}%
                    </div>
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <ToastContainer />
      {loader && <ProgressbarTransparent play />}
      <div>
        Assessment <b>{assessmentDetailsData?.title}</b> for training{" "}
        <b>{assessmentDetailsData?.training?.title}</b> at{" "}
        <b>{storeTitle} store.</b>
      </div>
      {parseInt(attempt) ? (
        <div>
          Submit Assessment for <b>{ordinal_suffix_of(currentAttempt)}</b> user
        </div>
      ) : (
        ""
      )}
      {
        <form onSubmit={handleSubmit}>
          <div style={{ height: "600px", overflow: "auto" }}>
            {assessmentDetailsData?.questions?.map((question, index) => (
              <div
                key={question.id}
                style={{
                  marginLeft: "10%",
                  marginTop: 5,
                  padding: "0.5rem",
                  marginRight: "10%",
                  background: "#ffffff",
                  borderRadius: "15px",
                  padding: "24px",
                  marginTop: "16px",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    columnGap: "10px",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "bolder" }}>Q{index + 1})</span>
                  </div>
                  <div>
                    <div style={{ fontWeight: "bolder" }}>{question.title}</div>
                    <div style={{ color: "#214b67", fontSize: "12px" }}>
                      {question.desc}
                    </div>
                  </div>
                </div>
                {question.type.key === "multi_select" && (
                  <FormControl
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      marginTop: 1,
                      textAlign: "left",
                    }}
                  >
                    <Select
                      id={question.id}
                      multiple
                      value={answers[question.id] || []}
                      renderValue={(answers) => {
                        if (answers.length === 0) {
                          return <en>Select multi choice</en>;
                        }
                        return answers
                          .map((answerValue) => {
                            return answerValue.title;
                          })
                          .join(", ");
                      }}
                      onChange={(e) =>
                        handleAnswerChange(
                          question.id,
                          e.target.value,
                          question
                        )
                      }
                      displayEmpty
                      style={{ color: "grey", background: "white" }}
                      inputProps={{ placeholder: "Select multi choice" }}
                    >
                      <MenuItem disabled value="">
                        <en>Select multi choice</en>
                      </MenuItem>
                      {question.attached_list &&
                        question.attached_list.items &&
                        Array.isArray(question.attached_list.items) &&
                        question.attached_list.items.map((option) => (
                          <MenuItem key={option.title} value={option}>
                            {/* <input
                            type="checkbox"
                            checked={
                              answers[question.title] &&
                              answers[question.title]?.includes(option.title)
                            }
                            onChange={() => null}
                          /> */}
                            {option.title}
                          </MenuItem>
                        ))}
                    </Select>
                    {errorMEssage[question.id] && (
                      <span style={{ color: "orangered", fontSize: "12px" }}>
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </FormControl>
                )}
                {question.type.key === "single_select" && (
                  <FormControl
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      marginTop: 1,
                      textAlign: "left",
                    }}
                  >
                    <Select
                      id={question.id}
                      value={answers[question.id] || ""}
                      onChange={(e) =>
                        handleAnswerChange(
                          question.id,
                          e.target.value,
                          question
                        )
                      }
                      displayEmpty
                      style={{ color: "grey", background: "white" }}
                      inputProps={{
                        placeholder: "Select single choice",
                      }}
                    >
                      <MenuItem disabled value="">
                        <en>Select single choice</en>
                      </MenuItem>
                      {question.attached_list &&
                        question.attached_list.items &&
                        Array.isArray(question.attached_list.items) &&
                        question.attached_list.items.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.title}
                          </MenuItem>
                        ))}
                    </Select>
                    {errorMEssage[question.id] && (
                      <span style={{ color: "orangered", fontSize: "12px" }}>
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </FormControl>
                )}
                {question.type.key === "boolean" && (
                  <FormControl
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: 1,
                      marginTop: 1,
                    }}
                    component="fieldset"
                  >
                    <RadioGroup
                      row
                      id={question.id}
                      value={
                        answers[question.id]
                          ? answers[question.id].title
                          : "" || ""
                      }
                      onChange={(e) =>
                        handleAnswerChange(
                          question.id,
                          e.target.value,
                          question,
                          question.type.key
                        )
                      }
                      sx={{
                        width: "auto",
                        display: "flex",
                        flexDirection: "right",
                        paddingLeft: 2,
                      }}
                    >
                      <FormControlLabel
                        value={"Yes"}
                        control={
                          <Radio
                            sx={{
                              p: "3px",
                              background: "#f9f9f9",
                              fontSize: "8px",
                              padding: "2px",
                            }}
                          />
                        }
                        label={
                          <Typography variant="" style={{ fontSize: "12px" }}>
                            Yes
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value={"No"}
                        control={
                          <Radio
                            sx={{
                              p: "3px",
                              background: "#f9f9f9",
                              fontSize: "8px",
                              padding: "2px",
                            }}
                          />
                        }
                        label={
                          <Typography variant="" style={{ fontSize: "12px" }}>
                            No
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    {errorMEssage[question.id] && (
                      <span style={{ color: "orangered", fontSize: "12px" }}>
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </FormControl>
                )}
                {question.type.key === "number" && (
                  <FormControl
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: 1,
                      marginTop: 1,
                    }}
                    component="fieldset"
                  >
                    <TextField
                      // className="center-placeholder"
                      sx={{
                        color: "grey",
                        textAlign: "left",
                        background: "#f9f9f9",
                      }}
                      type="number"
                      id={question.id}
                      value={answers[question.id] || ""}
                      placeholder="Enter answer for numeric"
                      onChange={(e) =>
                        handleAnswerChange(
                          question.id,
                          e.target.value,
                          question
                        )
                      }
                    />
                    {errorMEssage[question.id] && (
                      <div
                        style={{
                          color: "orangered",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        {errorMEssage[question.id]}
                      </div>
                    )}
                  </FormControl>
                )}
                {question.type.key === "text" && (
                  <FormControl
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: 1,
                      marginTop: 1,
                    }}
                    component="fieldset"
                  >
                    <TextField
                      // className="center-placeholder"
                      sx={{
                        color: "grey",
                        textAlign: "left",
                        background: "#f9f9f9",
                      }}
                      type="text"
                      id={question.id}
                      value={answers[question.id] || ""}
                      placeholder="Enter answer for text box"
                      onChange={(e) =>
                        handleAnswerChange(
                          question.id,
                          e.target.value,
                          question
                        )
                      }
                    />
                    {errorMEssage[question.id] && (
                      <span style={{ color: "orangered", fontSize: "12px" }}>
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </FormControl>
                )}
                {question.type.key === "date" && (
                  <FormControl
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: 1,
                      marginTop: 1,
                    }}
                    component="fieldset"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        key={Math.random()}
                        className="center-placeholder"
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={answers[question.id] || ""}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          handleAnswerChange(question.id, dateSort, question);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              color: "grey",
                              background: "#f9f9f9",
                              borderRadius: "4px",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errorMEssage[question.id] && (
                      <span style={{ color: "orangered", fontSize: "12px" }}>
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </FormControl>
                )}
                {question.type.key === "datetime" && (
                  <FormControl
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      marginBottom: 1,
                      marginTop: 1,
                    }}
                    component="fieldset"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignIetms: "center",
                        }}
                      >
                        <DatePicker
                          key={Math.random()}
                          inputFormat="DD/MM/YYYY"
                          value={answers[question.id]?.date || ""}
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("/")
                              .join("");

                            handleAnswerChange(
                              question.id,
                              {
                                ...answers[question.id],
                                date: dateSort,
                                time: new Date(),
                              },
                              question
                            );
                          }}
                          sx={{ background: "white" }}
                          renderInput={(params) => (
                            <TextField
                              inputProps={{
                                value: answers[question.id]?.date || "",
                              }}
                              sx={{
                                width: "47%",
                                background: "#f9f9f9",
                                borderRadius: "4px",
                              }}
                              {...params}
                            />
                          )}
                        />
                        <TimePicker
                          // key={Math.random()}
                          clearable
                          value={answers[question.id]?.time || new Date()}
                          onChange={(newValue) => {
                            console.log("newvalue", newValue);
                            handleAnswerChange(
                              question.id,
                              { ...answers[question.id], time: newValue },
                              question
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                width: "47%",
                                background: "#f9f9f9",
                                borderRadius: "4px",
                              }}
                              {...params}
                            />
                          )}
                          className=""
                        />
                      </Box>
                    </LocalizationProvider>
                    {errorMEssage[question.id] && (
                      <span style={{ color: "orangered", fontSize: "12px" }}>
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </FormControl>
                )}
                {question.type.key === "file" && (
                  <Grid
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      marginTop: 1,
                      textAlign: "left",
                    }}
                  >
                    <Grid
                      spacing={1}
                      sx={{ background: "#f9f9f9", borderRadius: "8px" }}
                    >
                      <Box>
                        <input
                          name="image"
                          accept="image/*"
                          id={"contained-button-file" + question.id}
                          type="file"
                          hidden
                          value={""}
                          onChange={(e) => handleImageUpload(e, question.id)}
                        />
                        <label
                          htmlFor={"contained-button-file" + question.id}
                          className="add-button assessment"
                        >
                          <AddOutlinedIcon className="icon" />
                          Add media
                        </label>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        columnGap: "15px",
                      }}
                    >
                      {getImageKeys[question.id]?.length &&
                        getImageKeys[question.id].map((imgData) => (
                          <Box
                            className="image-box"
                            style={{
                              width: "max-content",
                              overflow: "visible",
                            }}
                          >
                            <Stack
                              direction="row"
                              sx={{
                                position: "relative",
                                width: "max-content",
                              }}
                            >
                              <img
                                src={imgData?.location}
                                alt="attachment"
                                height={70}
                                width={70}
                                onClick={() =>
                                  openImageViewer(0, imgData?.location)
                                }
                              />
                              <img
                                onClick={() =>
                                  removeImage(question.id, imgData.key)
                                }
                                src={hover[imgData.key] ? DeleteHover : Delete}
                                alt="Delete"
                                className="delete-image"
                                onMouseOver={() => {
                                  setHover({ ...hover, [imgData.key]: true });
                                }}
                                onMouseLeave={() => {
                                  setHover({ ...hover, [imgData.key]: false });
                                }}
                                height={20}
                                width={20}
                                style={{
                                  position: "absolute",
                                  right: "-10px",
                                  top: "-20px",
                                }}
                              />
                            </Stack>
                          </Box>
                        ))}
                    </Grid>
                    {errorMEssage[question.id] && (
                      <span
                        style={{
                          color: "orangered",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        {errorMEssage[question.id]}
                      </span>
                    )}
                  </Grid>
                )}
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            type="submit"
            sx={{
              textAlign: "left",
              marginTop: 2,
              mb: 2,
            }}
          >
            Submit
          </Button>
          {isViewerOpen && (
            <ImageViewer
              src={isImages}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
        </form>
      }
    </>
  );
};

export default SubmitAssessment;
