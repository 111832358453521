/** @format */

import React, { useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Card,
  Stack,
  FormControlLabel,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Fab from "@material-ui/core/Fab";
import Blank from "../../../images/Blank.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import questiontype from "../createFormTabs/QuestionType";
import SelectRuleIssueModal from "../createFormTabs/SelectRuleIssueModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  RulesAddedApi,
  UploadImageAddQuesApi,
  UploadImgAddQSuccess,
} from "../../../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CompanyGetApi } from "../../../redux/actions/CreateCompany";
import { Delete as DeleteIcon } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  select: {
    "& li": {
      display: "block",
    },
  },
});

const AddQuestionEditForm = () => {
  const classes = useStyles();
  const optionsAnswer = [
    { title: "Yes", value: "Yes" },
    { title: "No", value: "No" },
    { title: "Acceptable", value: "accetable" },
    { title: "Not Acceptable", value: "notAcceptable" },
    { title: "Discarded", value: "discarded" },
    { title: "Returned", value: "returned" },
    { title: "Not Applicable", value: "notApplicable" },
  ];
  const optionsAns = [
    { title: "Yes", value: false },
    { title: "No", value: false },
    { title: "Acceptable", value: false },
    { title: "Not Acceptable", value: false },
    { title: "Discarded", value: false },
    { title: "Returned", value: false },
    { title: "Not Applicable", value: false },
  ];
  const numericCondition = [
    {
      id: 1,
      title: "=",
      key: "=",
    },
    {
      id: 2,
      title: ">",
      key: ">",
    },
    {
      id: 3,
      title: ">=",
      key: ">=",
    },
    {
      id: 4,
      title: "<",
      key: "<",
    },
    {
      id: 5,
      title: "<=",
      key: "<=",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname;
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [value, setValue] = useState(0);
  const [val, setVal] = useState("");
  const [firstData, setFirstData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [require, setRequire] = useState(optionsAns);
  const [RecordAudio, setRecordAudio] = useState("");
  const [takeImage, setTakeImage] = useState("");
  const [takeVideo, setTakeVideo] = useState("");
  const [takeVideoData, setTakeVideoData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [allowComment, setAllowComment] = useState("");
  const [imageOptional, setImageOptional] = useState("");
  const [videoOptional, setVideoOptional] = useState("");
  const [videoOptionalData, setVideoOptionalData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);

  const [requiredComment, setRequiredComment] = useState("");
  const [validatarr, setValidatarr] = useState([]);
  const [ruleList, setRuleList] = useState([]);
  const [uiRender, setUiRender] = useState(false);
  const [weightScore, setWeightScore] = useState({});
  const [singleandmultichoice, setSingleAndMultiChoice] = useState({});
  const [dynamicweightagscore, setDynamicweightageScore] = useState({});
  const [newFieldValue, setNewFieldValue] = useState({});
  const maxImageSize = 5 * 1024 * 1024;
  const maxImagesAllowed = 2;
  const [multiimageUrl, setMultiimageUrl] = useState([]);
  const [RecordAudioField, setRecordAudioField] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [takeImageData, setTakeImageData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [allowCommentData, setAllowCommentData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [imageOptionalData, setImageOptionalData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [requiredCommentData, setRequiredCommentData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);

  const rulesdata = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.ruleslist &&
      state.checklist.ruleslist.data &&
      state.checklist.ruleslist.data.data
  );
  const companyData = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data.list
  );

  const getImageKey = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.uploadImgAddQ &&
      state.checklist.uploadImgAddQ.data &&
      state.checklist.uploadImgAddQ.data.data
  );

  useEffect(() => {
    getCompanyData();
  }, []);
  useEffect(() => {
    setRuleList(rulesdata);
  }, [rulesdata]);
  localStorage.setItem("editFormDefaultValue", true);
  const DefultValue = localStorage.getItem("editFormDefaultValue");
  useEffect(() => {
    if (DefultValue) {
      setNewFieldValue({
        acceptable: 1,
        discarded: 0,
        no: 0,
        notAcceptable: 0,
        notApplicable: 0,
        returned: 0,
        yes: 1,
      });
    }
  }, [DefultValue]);
  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `rule/template?type=2&company_id=${id}` };
    dispatch(RulesAddedApi(data));
  }, []);

  useEffect(() => {
    const validations = [
      {
        id: 1,
        key: "required",
        title: "Is Required?",
        type: "radio",
        answer: [...require],
      },
      {
        id: 2,
        key: "audio",
        title: "Record Audio",
        type: "radio",
        answer: [...RecordAudioField],
      },
      {
        id: 3,
        key: "image",
        title: "Take Image",
        type: "radio",
        answer: [...takeImageData],
      },
      {
        id: 4,
        key: "video",
        title: "Take Video",
        type: "radio",
        answer: [...takeVideoData],
      },
      {
        id: 5,
        key: "comment",
        title: "Always allow Comment",
        type: "radio",
        answer: [...allowCommentData],
      },
      {
        id: 6,
        key: "image_optional",
        title: "Is image optional?",
        type: "radio",
        answer: [...imageOptionalData],
      },
      {
        id: 7,
        key: "video_optional",
        title: "Is video optional?",
        type: "radio",
        answer: [...videoOptionalData],
      },
      {
        id: 8,
        key: "comment_required",
        title: "Is comment required?",
        type: "radio",
        answer: [...requiredCommentData],
      },
    ];
    setValidatarr(validations);
  }, [
    require,
    RecordAudio,
    takeImage,
    takeVideo,
    allowComment,
    imageOptional,
    videoOptional,
    requiredComment,
  ]);

  useEffect(() => {
    localStorage.removeItem("editdata");
    localStorage.removeItem("questiodata");
  }, []);
  const handleRequires = (event) => {
    let events = event.target.value;
    setVal(events);
    for (let item of require) {
      if (events == "yes") {
        require[0].value = true;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "no") {
        require[0].value = false;
        require[1].value = true;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = true;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Not Acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = true;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "discarded") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = true;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "returned") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = true;
        require[6].value = false;
      } else if (events == "not applicable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = true;
      }
    }
    let options = require;
    setRequire(options);
    // setRequire(event.target.value);
  };
  const handleRecordaudio = (event) => {
    setRecordAudio(event.target.value);
    let events = event.target.value;
    for (let item of RecordAudioField) {
      if (events == "yes") {
        RecordAudioField[0].value = true;
        RecordAudioField[1].value = false;
      } else if (events == "no") {
        RecordAudioField[0].value = false;
        RecordAudioField[1].value = true;
      }
    }
    const options = RecordAudioField;
    setRecordAudioField(options);
  };

  const handleDelete = (index) => {
    setFirstData(firstData.filter((item, i) => i !== index));
  };
  const listData = (values) => {
    setFormValues(values);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRequire = (event) => {
    setRequire(event.target.value);
  };
  // const handleRecordaudio = (event) => {
  //   setRecordAudio(event.target.value);
  // };
  const handleTakeImage = (event) => {
    setTakeImage(event.target.value);
    let events = event.target.value;
    for (let item of takeImageData) {
      if (events == "yes") {
        takeImageData[0].value = true;
        takeImageData[1].value = false;
      } else if (events == "no") {
        takeImageData[0].value = false;
        takeImageData[1].value = true;
      }
    }
    let options = takeImageData;
    setTakeImageData(options);
  };

  const handleTakeVideo = (event) => {
    setTakeVideo(event.target.value);
    let events = event.target.value;
    for (let item of takeVideoData) {
      if (events == "yes") {
        takeVideoData[0].value = true;
        takeVideoData[1].value = false;
      } else if (events == "no") {
        takeVideoData[0].value = false;
        takeVideoData[1].value = true;
      }
    }
    let options = takeVideoData;
    setTakeVideoData(options);
  };

  const handleAllowComment = (event) => {
    setAllowComment(event.target.value);
    let events = event.target.value;
    for (let item of allowCommentData) {
      if (events == "yes") {
        allowCommentData[0].value = true;
        allowCommentData[1].value = false;
      } else if (events == "no") {
        allowCommentData[0].value = false;
        allowCommentData[1].value = true;
      }
    }
    let options = allowCommentData;
    setAllowCommentData(options);
  };
  const handleImageOptional = (event) => {
    setImageOptional(event.target.value);
    let events = event.target.value;
    for (let item of imageOptionalData) {
      if (events == "yes") {
        imageOptionalData[0].value = true;
        imageOptionalData[1].value = false;
      } else if (events == "no") {
        imageOptionalData[0].value = false;
        imageOptionalData[1].value = true;
      }
    }
    let options = imageOptionalData;
    setImageOptionalData(options);
  };

  const handleVideoOptional = (event) => {
    setVideoOptional(event.target.value);
    let events = event.target.value;
    for (let item of videoOptionalData) {
      if (events == "yes") {
        videoOptionalData[0].value = true;
        videoOptionalData[1].value = false;
      } else if (events == "no") {
        videoOptionalData[0].value = false;
        videoOptionalData[1].value = true;
      }
    }
    let options = videoOptionalData;
    setVideoOptionalData(options);
  };

  const handleRequiredComment = (event) => {
    setRequiredComment(event.target.value);
    let events = event.target.value;
    for (let item of requiredCommentData) {
      if (events == "yes") {
        requiredCommentData[0].value = true;
        requiredCommentData[1].value = false;
      } else if (events == "no") {
        requiredCommentData[0].value = false;
        requiredCommentData[1].value = true;
      }
    }
    let options = requiredCommentData;
    setRequiredCommentData(options);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigatioToQuestion = () => {
    navigate("checklist/allform/editforms");
  };

  // let multiimageUrl =
  //   images &&
  //   images?.map((item) => {
  //     return item?.url;
  //   });

  // const handleImageUpload = (e) => {
  //   const tempArr = [];

  //   [...e.target.files].forEach((file) => {
  //     tempArr.push({
  //       data: file,
  //       url: URL.createObjectURL(file),
  //     });
  //   });
  //   setImages(tempArr);
  // };

  // const handleImageUpload = (e) => {
  //   const tempArr = [];

  //   [...e.target.files].forEach((file) => {
  //     tempArr.push({
  //       contentType: file?.type,
  //       fileType: "IMAGE",
  //       key: "",
  //       name: file?.name,
  //       url: URL.createObjectURL(file),
  //     });
  //   });
  //   setImages((prevImages) => [...prevImages, ...tempArr]);
  //   e.target.value = [];
  // };

  const handleImageUpload = async (e) => {
    const files = e.target.files;

    for (const file of files) {
      var idxDot = file?.name?.lastIndexOf(".") + 1;
      var extFile = file?.name
        ?.substr(idxDot, file?.name?.length)
        .toLowerCase();

      if (extFile != "jpg" && extFile != "jpeg" && extFile != "png") {
        alert("Only jpg/jpeg and png files are allowed!");
        e.target.value = [];
        return;
      }

      if (file.size > maxImageSize) {
        toast.error("Only up to 5 MB images are allowed.");
        e.target.value = [];
        return;
      }

      if (files.length + multiimageUrl.length > maxImagesAllowed) {
        toast.error("Only two images upload are allowed.");
        e.target.value = [];
        return;
      }

      const newImages = [...multiimageUrl];
      const formData = new FormData();

      try {
        for (const file of files) {
          if (newImages.length < maxImagesAllowed) {
            formData.append("files", file);
            newImages.push(URL.createObjectURL(file));
          }
        }

        setMultiimageUrl(newImages);
        e.target.value = [];
        if (formData.has("files")) {
          const imageData = {
            body: formData,
          };

          imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
          await dispatch(UploadImageAddQuesApi(imageData));
        }
      } catch (error) {
        e.target.value = [];
        toast.error("Error uploading images: " + error.message);
      }
    }
  };
  const handleChangeRules = (event, items, index) => {
    let allRules = ruleList;
    if (event.target.name === "condition") {
      allRules[index].condition = event.target.value;
      // items.condition = event.target.value
    } else {
      allRules[index].answer = event.target.value;
      // items.answer = event.target.value
    }
    setRuleList(allRules);
    setUiRender(!uiRender);
  };

  const handleWeightScore = (e) => {
    const value = e.target.value;
    setWeightScore({
      ...weightScore,
      [e.target.name]: value,
    });
  };

  const handleSingleAndMultiChoice = (e) => {
    var key = e.target.name;

    const value = e.target.value;

    var obj = {};
    setSingleAndMultiChoice({
      ...singleandmultichoice,
      [e.target.name]: Number(value),
    });
    setDynamicweightageScore([e.target.name]);
  };

  const handleDone = () => {
    let validatorData = [];
    if (require) {
      validatorData.push({
        value: require,
        title: " Is Required? :",
      });
    }
    if (RecordAudio) {
      validatorData.push({
        value: RecordAudio,
        title: "Record Audio? :",
      });
    }
    if (takeImage) {
      validatorData.push({ value: takeImage, title: " Take Image? :" });
    }
    if (takeVideo) {
      validatorData.push({ value: takeVideo, title: " Take Video? :" });
    }
    if (videoOptional) {
      validatorData.push({
        value: videoOptional,
        title: "videoOptional :",
      });
    }
    if (imageOptional) {
      validatorData.push({
        value: imageOptional,
        title: "imageOptional :",
      });
    }
    if (allowComment) {
      validatorData.push({
        value: allowComment,
        title: "allowComment :",
      });
    }
    if (requiredComment) {
      validatorData.push({
        value: requiredComment,
        title: "requiredComment :",
      });
    }
    // if (validatorData) {
    //   localStorage.setItem("validatorData", JSON.stringify(validatorData));
    // }
  };
  const validationSchema = Yup.object({
    title: Yup.string("Enter your title").required("Title is required"),
    description: Yup.string("Enter your Description").required(
      "Description is required"
    ),
    questionscore: Yup.string("Enter your Question Score")
      .required("Question Score is required")
      .min(1, "Question Score must be 1 characters at minimum")
      .max(3, "Question Score must be 3 characters at maximum"),
    questiontype: Yup.string("Select your type").required("Type is required"),
  });

  const isValidationFieldSelected =
    val !== "" ||
    RecordAudio !== "" ||
    takeImage !== "" ||
    takeVideo !== "" ||
    allowComment !== "" ||
    imageOptional !== "" ||
    videoOptional !== "" ||
    requiredComment !== "";

  const formik = useFormik({
    initialValues: {
      title: "",
      isQuestionToInspection: false,
      description: "",
      questionscore: "",
      questiontype: "",
      attachedlist: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!isValidationFieldSelected) {
        toast.error("Validation fields are required");
        return;
      }
      const listDataid = localStorage.getItem("listDataid");
      values.images = images?.map((index) => ({
        key: index?.key,
        name: index?.name,
        url: index?.location || index?.url,
      }));
      const types = {
        validations: validatarr,
        option_score:
          Object.keys(singleandmultichoice).length === 0
            ? {
                acceptable: weightScore.acceptable ? weightScore.acceptable : 1,
                discarded: weightScore.discarded ? weightScore.discarded : 0,
                no: weightScore.no ? weightScore.no : 0,
                notAcceptable: weightScore.notAcceptable
                  ? weightScore.notAcceptable
                  : 0,
                notApplicable: weightScore.notApplicable
                  ? weightScore.notApplicable
                  : 0,
                returned: weightScore.returned ? weightScore.returned : 0,
                yes: weightScore.yes ? weightScore.yes : 1,
              }
            : singleandmultichoice,
        key:
          values?.questiontype?.toLowerCase() === "singlechoice"
            ? "single_select"
            : values?.questiontype?.toLowerCase() === "multiplechoice"
            ? "multi_select"
            : values?.questiontype?.toLowerCase() === "numeric"
            ? "number"
            : values?.questiontype?.toLowerCase() === "media"
            ? "file"
            : values?.questiontype?.toLowerCase(),
        title:
          values?.questiontype?.toLowerCase() === "singlechoice"
            ? "Single Choice"
            : values?.questiontype?.toLowerCase() === "multiplechoice"
            ? "Multiple Choice"
            : values?.questiontype?.charAt(0)?.toUpperCase() +
              values?.questiontype?.slice(1),
        title_to_show:
          values?.questiontype?.toLowerCase() === "singlechoice"
            ? "Single Choice"
            : values?.questiontype?.toLowerCase() === "multiplechoice"
            ? "Multiple Choice"
            : values?.questiontype?.charAt(0)?.toUpperCase() +
              values?.questiontype?.slice(1),
        id: Number(listDataid + 1),
      };
      console.log("typessm...", singleandmultichoice);
      // types.title = values.questiontype;
      const data = {
        title: values.title,
        titleImages: values.images,
        desc: values.description,
        question_score: values.questionscore,
        type: types,
        attached_list: values.attachedlist,
        rules: firstData,
        // attached_list: null,
        order: Number(listDataid),
        id: Number(listDataid + 1),
      };
      if(pathName == "/checklist/all-form/add-form/edit-addquestion"){
        data.isQuestionToInspection = values.isQuestionToInspection
      }
      if (data) {
        let oldQues = localStorage.getItem("questions");
        if (oldQues) {
          let oldD1 = JSON.parse(oldQues);
          oldD1.push(data);
          localStorage.setItem("questions", JSON.stringify(oldD1));
        } else {
          let qArray = [];
          qArray.push(data);
          localStorage.setItem("questions", JSON.stringify(qArray));
        }
        navigate("/checklist/all-form/edit-forms");
        dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
      } else {
        toast.error("All fields are required");
      }
    },
  });
  // const handleReset = () => {
  //   setVal("");
  //   setRecordAudio("");
  //   setTakeImage("");
  //   setAllowComment("");
  //   setImageOptional(0);
  //   setRequiredComment(0);
  // };

  const handleReset = () => {
    require[0].value = false;
    require[1].value = false;
    require[2].value = false;
    require[3].value = false;
    require[4].value = false;
    require[5].value = false;
    require[6].value = false;
    takeImageData[0].value = false;
    takeImageData[1].value = false;
    takeVideoData[0].value = false;
    takeVideoData[1].value = false;
    RecordAudioField[0].value = false;
    RecordAudioField[1].value = false;
    allowCommentData[0].value = false;
    allowCommentData[1].value = false;
    imageOptionalData[0].value = false;
    imageOptionalData[1].value = false;
    videoOptionalData[0].value = false;
    videoOptionalData[1].value = false;
    requiredCommentData[0].value = false;
    requiredCommentData[1].value = false;
    setVal("");
    setRecordAudio("");
    setTakeImage("");
    setTakeVideo("");
    setAllowComment("");
    setImageOptional("");
    setVideoOptional("");
    setRequiredComment("");
  };

  const handleFirst = (event, index, formValues) => {
    let item = event;
    if (event.condition && event.answer && formValues.observation) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: event.answer,
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice"
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setFormValues("");
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "numeric"
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },

          answer: Number(event?.answer),
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          issueTemplate: formValues ? formValues : "",
        };
        const requiresBooleand = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        newData1.requires = requiresBooleand;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else if (
      event.condition &&
      event.answer &&
      event.subType &&
      event.subType.title !== "QuestionAutoIssue"
    ) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: [event.answer],
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice"
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            // issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "numeric"
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },
          answer: Number(event?.answer),
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer}</b>\"  then allow comment."`);
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          // issueTemplate: formValues ? formValues : "",
        };
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else {
      toast.error("All fields are required");
    }
  };
  const getCompanyData = () => {
    const cid = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${cid}` };
    dispatch(CompanyGetApi(data));
  };
  useEffect(() => {
    if (
      (formik?.values?.questiontype === "text" || "media", "multiplechoice")
    ) {
      setValue(0);
    }
  }, [formik.values.questiontype]);

  // const handleDeleteImage = (item) => {
  //   const newAllKey = [...images].filter((img) => img.key !== item);
  //   setImages(newAllKey);
  // };

  const handleDeleteImage = (index) => {
    const newImages = [...multiimageUrl];
    const newAllKey = [...images];

    newImages.splice(index, 1);
    newAllKey.splice(index, 1);

    setMultiimageUrl(newImages);
    setImages(newAllKey);
  };

  useEffect(() => {
    if (getImageKey && getImageKey.length > 0) {
      setImages([...images, ...getImageKey]);
    }
  }, [getImageKey]);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  className="cancle-button cancle-hover"
                  onClick={() => {
                    dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
                    navigate(-1);
                  }}
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="header-add cancle-hover"
                  onClick={handleDone}
                >
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="lg"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title Images</label>
              <Box className="attachment-file">
                <input
                  accept="image/*"
                  className="file-upload"
                  id="contained-button-file"
                  multiple
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" className="store-file cancle-hover">
                    <AddOutlinedIcon />
                  </Fab>
                </label>
                <Box>
                  {images.length > 0 ? (
                    images.map((item, index) => {
                      return (
                        <div key={index} className="image-container">
                          <div className="image-wrapper">
                            <img
                              key={index}
                              className="file-preview"
                              src={item.url}
                              alt={`attachment-${index}`}
                            />
                            <div className="delete-container">
                              <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDeleteImage(item.key)}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "#ff0000",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Box>
                      <img
                        className="file-preview"
                        src={Blank}
                        alt="attachment"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormControl> */}

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title Images</label>
              <Box className="attachment-file">
                <input
                  accept="image/png, image/jpeg"
                  className="file-upload"
                  id="contained-button-file"
                  multiple
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" className="store-file cancle-hover">
                    <AddOutlinedIcon />
                  </Fab>
                </label>
                <Box display="flex" flexDirection="row">
                  {multiimageUrl && multiimageUrl.length > 0 ? (
                    multiimageUrl.map((url, index) => {
                      return (
                        <div key={index} className="image-container">
                          <div className="image-wrapper">
                            <img
                              className="file-preview"
                              src={url}
                              alt={`attachment-${index}`}
                            />
                            <div className="delete-container">
                              <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDeleteImage(index)}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Box>
                      <img
                        className="file-preview"
                        src={Blank}
                        alt="attachment"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormControl>
            {pathName == "/checklist/all-form/add-form/edit-addquestion" && <FormControl fullWidth sx={{ mb: 2 }}>
              <FormControlLabel
                className="label-name"
                control={
                  <Checkbox
                    type="checkbox"
                    name="isQuestionToInspection"
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ color: "#196773" }}
                    checked={formik?.values?.isQuestionToInspection}
                  />
                }
                label="Add Question to Inspection"
              />
            </FormControl>}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title</label>
              <TextField
                name="title"
                value={formik?.values?.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                id="outlined-basic"
                variant="outlined"
                size="small"
                className="date-input"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Description</label>
              <TextField
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                id="outlined-basic"
                variant="outlined"
                size="small"
                multiline
                className="date-input"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Question Score</label>
              <TextField
                name="questionscore"
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                value={formik?.values?.questionscore}
                onChange={formik.handleChange}
                error={
                  formik.touched.questionscore &&
                  Boolean(formik.errors.questionscore)
                }
                helperText={
                  formik.touched.questionscore && formik.errors.questionscore
                }
                id="filled-number"
                variant="outlined"
                size="small"
                className="date-input"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Question Type</label>
              <Select
                size="small"
                name="questiontype"
                value={formik?.values?.questiontype}
                onChange={formik.handleChange}
                error={
                  formik.touched.questiontype &&
                  Boolean(formik.errors.questiontype)
                }
                helperText={
                  formik.touched.questiontype && formik.errors.questiontype
                }
                MenuProps={{
                  disableScrollLock: true,
                  classes: { paper: classes.select },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  sx: {
                    height: "400px",
                    display: "block",
                  },
                }}
                sx={{ borderRadius: "8px" }}
              >
                {questiontype?.map((option, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={option.key}
                      className="select-item"
                    >
                      {option?.title?.charAt(0)?.toUpperCase() +
                        option?.title?.slice(1) ?? option.id}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formik &&
              formik?.values &&
              formik?.values?.questiontype === "singlechoice" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Main List</label>
                  <Select
                    size="small"
                    name="attachedlist"
                    value={formik?.values?.mainlist}
                    onChange={(event) => {
                      formik.handleChange(event);

                      let singleChoicesss = {};
                      event.target.value?.items?.map(
                        (data) => (singleChoicesss[data.title] = 1)
                      );
                      // formik?.values?.attachedlist?.items?.forEach((data)=> {
                      //   return singleChoicesss[data.title] = '1'
                      // })
                      setSingleAndMultiChoice(singleChoicesss);
                    }}
                    error={
                      formik.touched.mainlist && Boolean(formik.errors.mainlist)
                    }
                    helperText={
                      formik.touched.mainlist && formik.errors.mainlist
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      classes: { paper: classes.select },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      sx: {
                        height: "400px",
                        display: "block",
                      },
                    }}
                    sx={{ borderRadius: "8px", display: "block" }}
                  >
                    <MenuItem disabled value="">
                      Type
                    </MenuItem>
                    {companyData &&
                      companyData?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option}>
                            {option.title ?? option.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
            {formik &&
              formik?.values &&
              formik?.values?.questiontype === "multiplechoice" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Main List</label>
                  <Select
                    size="small"
                    name="attachedlist"
                    value={formik?.values?.mainlist}
                    onChange={(event) => {
                      formik.handleChange(event);

                      let singleChoicesss = {};
                      event.target.value?.items?.map(
                        (data) => (singleChoicesss[data.title] = 1)
                      );
                      // formik?.values?.attachedlist?.items?.forEach((data)=> {
                      //   return singleChoicesss[data.title] = '1'
                      // })
                      setSingleAndMultiChoice(singleChoicesss);
                    }}
                    error={
                      formik.touched.mainlist && Boolean(formik.errors.mainlist)
                    }
                    helperText={
                      formik.touched.mainlist && formik.errors.mainlist
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      classes: { paper: classes.select },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      sx: {
                        height: "400px",
                        display: "block",
                      },
                    }}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem disabled value="">
                      Type
                    </MenuItem>
                    {companyData &&
                      companyData?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option}>
                            {option.title ?? option.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
            {formik.values.questiontype ? (
              <Box className="tab-box">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    background: "#F6F8FC",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Validation"
                      {...a11yProps(0)}
                      className="tab-item"
                    />
                    {formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "text" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "media" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "date" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Time" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "DateTime" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Timer" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "multiplechoice" ? (
                      ""
                    ) : (
                      <Tab
                        label="Rules"
                        {...a11yProps(1)}
                        className="tab-item"
                      />
                    )}
                    {formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "text" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "media" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "date" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Time" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "DateTime" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Timer" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "multiplechoice" ? (
                      ""
                    ) : (
                      <Tab
                        label="Weightage Score"
                        {...a11yProps(2)}
                        className="tab-item"
                      />
                    )}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <FormControl fullWidth>
                    <label>1. Is Required?</label>
                    <RadioGroup
                      onChange={handleRequires}
                      value={val ? val : ""}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                      {/* <FormControlLabel
                        value="acceptable"
                        control={<Radio size="small" />}
                        label="Acceptable"
                      />
                      <FormControlLabel
                        value="Not Acceptable"
                        control={<Radio size="small" />}
                        label="Not Acceptable"
                      />
                      <FormControlLabel
                        value="discarded"
                        control={<Radio size="small" />}
                        label="Discarded"
                      />
                      <FormControlLabel
                        value="returned"
                        control={<Radio size="small" />}
                        label="Returned"
                      />
                      <FormControlLabel
                        value="not applicable"
                        control={<Radio size="small" />}
                        label="Not Applicable"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>2. Record Audio</label>
                    <RadioGroup
                      value={RecordAudio}
                      onChange={handleRecordaudio}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>3. Take Image</label>
                    <RadioGroup
                      value={takeImage}
                      onChange={handleTakeImage}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>4. Take Video</label>
                    <RadioGroup
                      value={takeVideo}
                      onChange={handleTakeVideo}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>5. Always allow Comment</label>
                    <RadioGroup
                      value={allowComment ? allowComment : ""}
                      onChange={handleAllowComment}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>6. Is image optional?</label>
                    <RadioGroup
                      value={imageOptional}
                      onChange={handleImageOptional}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>7. Is video optional?</label>
                    <RadioGroup
                      value={videoOptional}
                      onChange={handleVideoOptional}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>8. Is comment required?</label>
                    <RadioGroup
                      value={requiredComment ? requiredComment : ""}
                      onChange={handleRequiredComment}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Button
                    // type="submit"
                    className="header-add cancle-hover"
                    onClick={handleReset}
                  >
                    Reset All
                  </Button>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {formik &&
                  formik?.values &&
                  formik?.values?.questiontype === "text" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "media" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "numeric" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "date" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "Time" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "DateTime" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "Timer" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "multiplechoice" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <Grid container spacing={1} sx={{ textAlign: "left" }}>
                        <Grid item xs={12}>
                          <Box sx={{ fontWeight: "bold" }}>Rules</Box>
                          <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                            Rules Preset
                          </Box>
                          {ruleList &&
                            ruleList.map((items, i) => {
                              return (
                                <Card sx={{ p: 3, mb: 2 }} key={i}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      textAlign: "left",
                                      padding: "20px 0px 20px 0px",
                                    }}
                                  >
                                    <Grid item xs={12} sm={12} md={12} lg={11}>
                                      <Stack
                                        direction="row"
                                        spacing={1}
                                        className="list-btn third-list"
                                      >
                                        <Typography>
                                          {items?.template?.split(/[{,}]/)[0]}
                                        </Typography>
                                        {items?.template?.split(/[{,}]/)[1] ===
                                        "condition" ? (
                                          <FormControl
                                            className="Rules-preset-form"
                                            sx={{
                                              width: { sm: 80, md: 150 },
                                              "& .MuiInputBase-root": {
                                                height: 35,
                                              },
                                            }}
                                          >
                                            {formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "numeric" ? (
                                              <Select
                                                labelId="demo-simple-select-label"
                                                size="small"
                                                id="demo-simple-select"
                                                name="condition"
                                                defaultValue="select"
                                                value={
                                                  items && items.condition
                                                    ? items.condition.title
                                                    : "Select Condition"
                                                }
                                                renderValue={(selectvalue) =>
                                                  selectvalue
                                                }
                                                onChange={(e) => {
                                                  handleChangeRules(
                                                    e,
                                                    items,
                                                    i
                                                  );
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      fontSize: "12px",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Condition
                                                  </em>
                                                </MenuItem>
                                                {numericCondition &&
                                                  numericCondition.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                          className="select-item"
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            ) : (
                                              <Select
                                                labelId="demo-simple-select-label"
                                                size="small"
                                                id="demo-simple-select"
                                                name="condition"
                                                defaultValue="select"
                                                value={
                                                  items && items.condition
                                                    ? items.condition
                                                    : "select"
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  value="="
                                                  className="select-item"
                                                >
                                                  =
                                                </MenuItem>
                                              </Select>
                                            )}
                                          </FormControl>
                                        ) : (
                                          ""
                                        )}
                                        <Typography>
                                          {items?.template?.split(/[{,}]/)[2]}
                                        </Typography>
                                        {items?.template?.split(/[{,}]/)[3] ===
                                        "answer" ? (
                                          formik &&
                                          formik?.values &&
                                          formik?.values?.questiontype ===
                                            "singlechoice" &&
                                          formik?.values &&
                                          formik?.values?.attachedlist ? (
                                            <FormControl className="Rules-preset-form">
                                              <Select
                                                size="small"
                                                defaultValue="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="answer"
                                                value={
                                                  items && items.answer
                                                    ? items?.answer
                                                    : "select"
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                  },
                                                  PaperProps: {
                                                    sx: {
                                                      height: "150px",
                                                    },
                                                  },
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Answer
                                                  </em>
                                                </MenuItem>
                                                {formik &&
                                                  formik?.values &&
                                                  formik?.values
                                                    ?.attachedlist &&
                                                  formik?.values?.attachedlist?.items.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          ) : formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "multiplechoice" &&
                                            formik?.values &&
                                            formik?.values?.attachedlist ? (
                                            <FormControl className="Rules-preset-form">
                                              <Select
                                                size="small"
                                                defaultValue="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="answer"
                                                value={
                                                  items && items.answer
                                                    ? items?.answer
                                                    : "select"
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                  },
                                                  PaperProps: {
                                                    sx: {
                                                      height: "150px",
                                                    },
                                                  },
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Answer
                                                  </em>
                                                </MenuItem>
                                                {formik &&
                                                  formik?.values &&
                                                  formik?.values
                                                    ?.attachedlist &&
                                                  formik?.values?.attachedlist?.items.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          ) : formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "numeric" ? (
                                            <FormControl
                                              hiddenLabel
                                              sx={{ mb: 2 }}
                                            >
                                              {/* {items && items.answer ? "" : <InputLabel id="formsid" disableAnimation>Select Item</InputLabel>} */}
                                              <TextField
                                                name="answer"
                                                // InputLabelProps={{
                                                //   shrink: true,
                                                // }}
                                                autoComplete="off"
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                                // label={<Input id="formsid"/>}
                                                variant="outlined"
                                                disableUnderline={true}
                                                InputProps={{
                                                  inputProps: { min: 1 },
                                                }}
                                                type="number"
                                                // value={formik?.values?.questionscore}
                                                value={
                                                  items && items.answer
                                                    ? items.answer
                                                    : ""
                                                }
                                                // onChange={formik.handleChange}
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                error={
                                                  formik.touched
                                                    .questionscore &&
                                                  Boolean(
                                                    formik.errors.questionscore
                                                  )
                                                }
                                                helperText={
                                                  formik.touched
                                                    .questionscore &&
                                                  formik.errors.questionscore
                                                }
                                                id="filled-number"
                                                size="md"
                                                className="date-input"
                                              />
                                            </FormControl>
                                          ) : (
                                            <FormControl
                                              className="Rules-preset-form"
                                              sx={{
                                                width: { sm: 80, md: 150 },
                                                "& .MuiInputBase-root": {
                                                  height: 35,
                                                },
                                              }}
                                            >
                                              <Select
                                                size="small"
                                                defaultValue="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="answer"
                                                value={
                                                  items && items.answer
                                                    ? items.answer.title
                                                    : "Select Answer"
                                                }
                                                renderValue={(selectvalue) =>
                                                  selectvalue
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                  },
                                                  PaperProps: {
                                                    sx: {
                                                      height: "150px",
                                                    },
                                                  },
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      color: "darkgrey",
                                                      display: "flex",
                                                    }}
                                                  >
                                                    Select Answer
                                                  </em>
                                                </MenuItem>
                                                {optionsAnswer &&
                                                  optionsAnswer.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                          className="select-item"
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          )
                                        ) : (
                                          ""
                                        )}
                                        <Typography>
                                          {items?.template?.split(/[{,}]/)[4]}
                                        </Typography>
                                        {items?.template?.split(/[{,}]/)[5] ===
                                        "issueTemplate" ? (
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            className="list-btn"
                                            sx={{
                                              alignItems: "center",
                                              margin: "5px 0 0 0",
                                            }}
                                          >
                                            <Button
                                              onClick={handleClickOpen}
                                              className="select-issues cancle-hover"
                                            >
                                              Select Issue Template
                                            </Button>
                                          </Stack>
                                        ) : (
                                          ""
                                        )}
                                        {items?.template?.split(/[{,}]/)[6]}
                                      </Stack>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={1}
                                      className="plus-icon-grid"
                                    >
                                      <Box
                                        sx={{ float: "right" }}
                                        className="plus-icon"
                                      >
                                        <AddOutlinedIcon
                                          onClick={(e) =>
                                            handleFirst(items, i, formValues)
                                          }
                                          sx={{ mr: 1 }}
                                          className="add-icon"
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Card>
                              );
                            })}
                        </Grid>
                      </Grid>
                      <Card sx={{ p: 3, mb: 1 }} className="card">
                        <h3>Your Rules</h3>
                        {firstData &&
                          firstData.map((items, i) => (
                            <Grid
                              container
                              spacing={1}
                              sx={{
                                textAlign: "left",
                                margin: "5px",
                                padding: "5px",
                              }}
                            >
                              <Grid item xs={12} sm={12} md={12} lg={11}>
                                <Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Typography>
                                      {items?.template?.split(/[{,}]/)[0]}
                                    </Typography>
                                    <Typography>
                                      &nbsp; "
                                      <b>
                                        {items?.template?.split(/[{,}]/)[1] ===
                                        "condition"
                                          ? items.data?.condition?.title
                                          : ""}
                                      </b>
                                      " &nbsp;{" "}
                                    </Typography>

                                    <Typography>
                                      {items?.template?.split(/[{,}]/)[2]}
                                    </Typography>
                                    <Typography>
                                      {/* &nbsp; "
                                        <b>
                                          {items?.template?.split(/[{,}]/)[3] ===
                                            "answer"
                                            ? items.data?.answer?.map((data)=>(
                                              <>{data.title}</>
                                            ))
                                            : ""}
                                        </b>
                                        " &nbsp; */}
                                      {
                                        <>
                                          {" "}
                                          &nbsp; "{" "}
                                          <b>
                                            {items &&
                                            items?.template?.split(
                                              /[{,}]/
                                            )[3] === "answer" ? (
                                              <>
                                                {items &&
                                                  items.data &&
                                                  Array.isArray(
                                                    items?.data?.answer
                                                  ) &&
                                                  items.data?.answer?.map(
                                                    (data) => <>{data.title}</>
                                                  )}

                                                {
                                                  <>
                                                    {" "}
                                                    <b>
                                                      {items &&
                                                      items?.template?.split(
                                                        /[{,}]/
                                                      )[3] === "answer"
                                                        ? items &&
                                                          items.data &&
                                                          items.data?.answer &&
                                                          items.data?.answer
                                                            ?.title
                                                          ? items.data?.answer
                                                              ?.title
                                                          : items.data?.answer
                                                        : ""}
                                                    </b>
                                                  </>
                                                }
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </b>
                                          " &nbsp;
                                        </>

                                        // : formik?.values?.questiontype ===
                                        //   "numeric" ? (
                                        //   <>
                                        //     {" "}
                                        //     &nbsp; "{" "}
                                        //     <b>
                                        //       {items &&
                                        //       items?.template?.split(
                                        //         /[{,}]/
                                        //       )[3] === "answer"
                                        //         ? items &&
                                        //           items.data &&
                                        //           items.data?.answer
                                        //         : ""}
                                        //     </b>
                                        //     " &nbsp;
                                        //   </>
                                        // ) : (
                                        //   <>
                                        //     {" "}
                                        //     &nbsp; "{" "}
                                        //     <b>
                                        //       {items &&
                                        //       items?.template?.split(
                                        //         /[{,}]/
                                        //       )[3] === "answer"
                                        //         ? items &&
                                        //           items.data &&
                                        //           items.data?.answer?.title
                                        //         : ""}
                                        //     </b>
                                        //     " &nbsp;
                                        //   </>
                                        // )
                                      }
                                    </Typography>
                                    <Typography>
                                      {items?.template?.split(/[{,}]/)[4]}
                                    </Typography>
                                    <Divider />

                                    <Divider />
                                  </Box>
                                  {items?.template?.split(/[{,}]/)[5] ===
                                  "issueTemplate" ? (
                                    <Box>
                                      <h4>
                                        <b>
                                          Observation :{" "}
                                          {
                                            items?.data?.issueTemplate
                                              ?.observation
                                          }
                                        </b>
                                      </h4>
                                      <p>
                                        {items?.data?.issueTemplate?.category}{" "}
                                        {"=>"}{" "}
                                        {
                                          items?.data?.issueTemplate
                                            ?.subCategory
                                        }
                                      </p>
                                      <p>
                                        IssuePriority :{" "}
                                        <b>
                                          {items?.data?.issueTemplate?.severity}
                                        </b>
                                      </p>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                  <Typography>
                                    {" "}
                                    {items?.template?.split(/[{,}]/)[6]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleDelete(i)}>
                                  <DeleteOutlineIcon
                                    sx={{ color: "#e74a3b" }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                      </Card>
                    </>
                  )}
                </TabPanel>
                {/* "multiplechoice" && */}
                <TabPanel value={value} index={2}>
                  {formik &&
                  formik?.values &&
                  formik?.values?.questiontype === "text" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "media" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "date" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "time" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "datetime" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "timer" ? (
                    ""
                  ) : formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "multiplechoice" ? (
                    ""
                  ) : (
                    <>
                      {formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "singlechoice" &&
                      formik?.values &&
                      formik?.values?.attachedlist ? (
                        <>
                          {formik &&
                            formik?.values &&
                            formik?.values?.attachedlist &&
                            formik?.values?.attachedlist?.items.map(
                              (data, i) => {
                                return (
                                  <Grid
                                    container
                                    spacing={1}
                                    className="marginbottom"
                                  >
                                    <Grid item md={6} xs={12} sm={12} lg={2}>
                                      <Typography>{data.title}</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12} sm={12} lg={6}>
                                      <TextField
                                        id="outlined-basic"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        // ref={ref}
                                        name={data.title}
                                        defaultValue={1}
                                        value={singleandmultichoice.value}
                                        onChange={(e) => {
                                          handleSingleAndMultiChoice(e, i);
                                        }}
                                        className="date-input"
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}{" "}
                        </>
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "multiplechoice" &&
                        formik?.values &&
                        formik?.values?.attachedlist ? (
                        <>
                          {formik &&
                            formik?.values &&
                            formik?.values?.attachedlist &&
                            formik?.values?.attachedlist?.items.map(
                              (data, i) => {
                                return (
                                  <Grid
                                    container
                                    spacing={1}
                                    className="marginbottom"
                                  >
                                    <Grid item md={6} xs={12} sm={12} lg={2}>
                                      <Typography>{data.title}</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12} sm={12} lg={6}>
                                      <TextField
                                        id="outlined-basic"
                                        type="number"
                                        // ref={ref}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name={data.title}
                                        defaultValue={1}
                                        value={singleandmultichoice.value}
                                        onChange={(e) => {
                                          handleSingleAndMultiChoice(e, i);
                                        }}
                                        className="date-input"
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}{" "}
                          {/* formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice" && */}
                        </>
                      ) : (
                        <>
                          {" "}
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Yes</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="yes"
                                defaultValue="1"
                                value={weightScore.yes}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>No</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="no"
                                value={weightScore.no}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Acceptable</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="1"
                                name="acceptable"
                                value={weightScore.acceptable}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Not Acceptable</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="notAcceptable"
                                value={weightScore.notAcceptable}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Discarded</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="discarded"
                                value={weightScore.discarded}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Returned</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="returned"
                                value={weightScore.returned}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Not Applicable</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="notApplicable"
                                value={weightScore.notApplicable}
                                onChange={handleWeightScore}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </TabPanel>
              </Box>
            ) : (
              ""
            )}
          </Container>
        </Box>
      </form>
      <SelectRuleIssueModal
        open={open}
        handleClose={handleClose}
        listData={listData}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default AddQuestionEditForm;
