import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import VerifCode from "react-verification-code-input";

const SecondSteps = (props) => {

  const [verifycode, setVerifycode] = useState("");
  const [error, setError] = useState(null);

  const handleVerifycode = (value) => {
    setVerifycode(value);
    setError(false);
  };

  const handleNext = (verifycode) => {
    if (verifycode === "") {
      setError(true);
    }
    if (verifycode) {
      props.onSubmitVerify(verifycode)
      // props?.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <>
      <Box className="step1-box">
        <Typography className="email-box">
          You have received a email from us which contains 6-digit verification
          code. Also check your spam folder.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <VerifCode
            fields={6}
            type="text"
            autoFocus={true}
            className="verif-code"
            value={verifycode}
            name="verifycode"
            onChange={handleVerifycode}
          />
        </Box>
        <p className="error-msg">{error === true ? "Please enter the code first" : ""}</p>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={props?.activeStep === 0 || props?.activeStep === 1}
            onClick={props?.handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            type="submit"
            onClick={() => handleNext(verifycode)}
          >
            {props?.activeStep === props?.steps?.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SecondSteps;
