import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
} from "@mui/material";
import User from "../../../../assets/icons/svg-icons/User.svg";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ChecklistPerformance = () => {
  const topperformerlist = useSelector(
    (state) =>
      state &&
      state.user &&
      state.user.topformer &&
      state.user.topformer.data &&
      state.user.topformer.data.data &&
      state.user.topformer.data.data.Topperformer
  );
  return (
    <Card className="card">
      <CardContent>
      <div style={{marginRight:"8px",float:"right"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
        <Typography variant="p" component="h2" className="top-heading">
          Checklist Performance
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 2,
            minHeight: "203px",
            maxHeight: "331px",
            overflowY: "auto",
            paddingRight: "5px",
          }}
        >
          {topperformerlist &&
            topperformerlist.map((row, i) => (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Card className="usercard">
                  <CardContent>
                    <Avatar className="avatar-image">
                      <img src={User} alt="avatar" width="30px" />
                    </Avatar>
                    <Box>
                      <Typography
                        variant="p"
                        component="b"
                        className="user-performance-name"
                      >
                        {row.Name}
                      </Typography>
                      {/* <Stack direction="row" spacing={1}>
                                        <Typography variant="p" component="p">ID: </Typography>
                                        <Typography variant="p" component="p">1234567890</Typography>
                                    </Stack> */}
                      {/* <Stack direction="row" spacing={1}>
                                        <Typography variant="p" component="p">Rank: </Typography>
                                        <Typography variant="p" component="b">1st</Typography>
                                    </Stack> */}
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ justifyContent: "start" }}
                      >
                        <Typography variant="p" component="p">
                          Points:{" "}
                        </Typography>
                        <Typography variant="p" component="b">
                          {row.SubmitCount}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}

          {/* <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card className="card">
              <CardContent>
                <Avatar className="avatar-image">
                  <img src={User} alt="avatar" width="30px" />
                </Avatar>
                <Box>
                  <Typography variant="p" component="b">
                    Pallavi+66 Kumari
                  </Typography>
                  <Stack direction="row">
                    <Typography variant="p" component="p">
                      ID:{" "}
                    </Typography>
                    <Typography variant="p" component="p">
                      1234567890
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="p" component="p">
                      Rank:{" "}
                    </Typography>
                    <Typography variant="p" component="p">
                      2st
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="p" component="p">
                      Points:{" "}
                    </Typography>
                    <Typography variant="p" component="p">
                      435
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card className="card">
              <CardContent>
                <Avatar className="avatar-image">
                  <img src={User} alt="avatar" width="30px" />
                </Avatar>
                <Box>
                  <Typography variant="p" component="b">
                    Pallavi+66 Kumari
                  </Typography>
                  <Stack direction="row">
                    <Typography variant="p" component="p">
                      ID:{" "}
                    </Typography>
                    <Typography variant="p" component="p">
                      1234567890
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="p" component="p">
                      Rank:{" "}
                    </Typography>
                    <Typography variant="p" component="p">
                      3st
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="p" component="p">
                      Points:{" "}
                    </Typography>
                    <Typography variant="p" component="p">
                      435
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChecklistPerformance;
