/** @format */

import React, { useState, useEffect } from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import { Button, Card, Grid } from "material-ui-core";
import { Link, useNavigate } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const TableHeader = (props) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [permission, setPermission] = useState({});
  const traininglist = props?.training;

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleAddTraining = () => {
    navigate("/training/add-training", {
      state: { trainingPath: traininglist },
    });
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            {/* <Tabs
                            value={value}
                            onChange={handleChangeTabs}
                            variant="scrollable"
                            scrollButtons={false}
                            aria-label="scrollable prevent tabs example"
                            className="tabs-content-parent"
                        >
                            <Tab label="Active" icon={<p className="active-tabs-content">28</p>} iconPosition="end" className="active-tabs tabs-content" />
                            <Tab label="Archived" icon={<p className="active-tabs-content">8</p>} iconPosition="end" className="Archived-tabs tabs-content" />
                        </Tabs> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <SearchAppBar
                searchValue={props.searchValue}
                searchOnChange={props.searchOnChange}
                handleKeyDown={props.handleKeyDown}
              />
              {/* <Link to="/training/add-training" className="submenu-item"> */}
              {permission?.lms?.manageLms === true ? (
                <Button
                  onClick={handleAddTraining}
                  className="header-add cancle-hover"
                  
                >
                  <AddOutlinedIcon sx={{ mr: 1 ,height:"42px"}} />
                  Add New Training
                </Button>
              ) : (
                ""
              )}

              {/* </Link> */}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default TableHeader;
