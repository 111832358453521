/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Box,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Card, CardContent, Chip, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Edit from "../../../../component/images/Edit.svg";
import Delete from "../../../../component/images/Delete.svg";
import DehazeIcon from "@mui/icons-material/Dehaze";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Divider } from "@mui/material";
import {
  AddAssessmentApi,
  UpdateAssesmentApi,
} from "../../../redux/actions/LmsAction";
import { useFormik } from "formik";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import DeleteHover from "../../../../component/images/DeleteHover.svg";
import EditHover from "../../../../component/images/EditHover.svg";
import { useDispatch, useSelector } from "react-redux";
import { assessmentQustionEditData } from "../../../redux/actions/AllformStore";
import { toast, ToastContainer } from "react-toastify";
import SearchAppBar from "./SearchButton";
import Switch from "@mui/material/Switch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AssessmentHeader from "./AssessmentHeader";
import * as moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "12px !important",

    height: "18px !important",
    width: "150px !important",
    //   borderRadius: "9999px"
  },
}));

const AddAssessment = (props) => {
  const classes = useStyles();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const assesKey = location?.state?.assesmentKey;
  let assesmentFields = JSON.parse(localStorage.getItem("addAssesmentFields"));
  const addNewAssesment = location?.state?.addNewAssesment;
  const { assessmentUpdateData, UpdateAssessment, id } = props;
  const [isFixed, setIsFixed] = useState(true);
  const [assesstquestiondata, setAssesstQuestionData] = useState();
  const [AssesNewQuestion, setAssesNewQuestion] = useState([]);
  const [newfield, setNewField] = useState({});
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [indexHover, setIndexHover] = useState(0);
  const AssesstQustionEditData = useSelector(
    (state) => state && state.allForm && state.allForm.assesstqustionedit
  );
  useEffect(() => {
    setNewField({
      title: assesmentFields?.title,
      summary: assesmentFields?.summary,
      time: assesmentFields?.time,
      score: assesmentFields?.score,
    });
  }, []);
  useEffect(() => {
    if (assessmentUpdateData && assesKey === "assesmentKey") {
      setNewField({
        title: assessmentUpdateData?.title,
        summary: assessmentUpdateData?.summary,
        time: Math.floor(assessmentUpdateData?.duration / 60),
        score: assessmentUpdateData?.passing_score,
      });
    }
    if (assessmentUpdateData && addNewAssesment === undefined) {
      const qDataFromApi =
        assessmentUpdateData && assessmentUpdateData?.questions;
      localStorage.setItem("editquestionasses", JSON.stringify(qDataFromApi));
      const editassesquestion = localStorage.getItem("editquestionasses");
      if (editassesquestion) {
        let oldD1 = JSON.parse(editassesquestion);
        setAssesstQuestionData(oldD1);
      }
    }
  }, [assessmentUpdateData, assesKey]);

  useEffect(() => {
    if (addNewAssesment) {
      const editassesquestion = localStorage.getItem("editquestionasses");
      console.log("editassesquestion", JSON.parse(editassesquestion));
      if (editassesquestion) {
        let oldD1 = JSON.parse(editassesquestion);
        setAssesstQuestionData(oldD1);
      }
    }
  }, [addNewAssesment]);

  useEffect(() => {
    if (addNewAssesment === "newaddNewAssesment") {
      const qDataFromApi =
        assessmentUpdateData && assessmentUpdateData?.questions;
      for (let i = 0; i < AssesstQustionEditData?.length; i++) {
        qDataFromApi && qDataFromApi.push(AssesstQustionEditData[i]);
        setAssesstQuestionData(qDataFromApi);
      }
      if (AssesstQustionEditData) {
        setAssesstQuestionData(AssesstQustionEditData);
      }
    }
  }, [assessmentUpdateData, addNewAssesment, AssesstQustionEditData]);

  const handleQuestion = (fields) => {
    localStorage.setItem("default", true);
    localStorage.setItem("addAssesmentFields", JSON.stringify(fields));
    navigate("/assessment/create-assessment/assessment-question", {
      state: {
        addNewAssesment:
          addNewAssesment === "newaddNewAssesment"
            ? "newaddNewAssesment"
            : UpdateAssessment,
        id: id,
      },
    });
  };
  const assessmentreq = (res) => {
    if (res && res.data && res.data.status == 200) {
      localStorage.removeItem("formRules");
      navigate("/assessment");
      toast.success(res && res.data && res.data.message);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string("Required").required("Required"),
    summary: Yup.string("Required").required("Required"),
    time: Yup.string("Required").required("Required"),
    score: Yup.string("Required")
      .max(3, "Number should be 3 digits")
      .test(
        "max",
        "Percentage should be less than or equal to 100",
        (value) => {
          return parseInt(value) <= 100;
        }
      ),
  });

  const formik = useFormik({
    initialValues: newfield || {
      title: "",
      summary: "",
      time: "",
      score: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values) {
        const data = {};
        const body = {
          title: values?.title,
          duration: values.time * 60,
          showScore: isFixed,
          passing_score: values.score,
          questions: assesstquestiondata,
          summary: values?.summary,
          _id: id,
        };
        data.body = body;
        const companyid = localStorage.getItem("companyId");
        data.url = BASE_URL + `assessment?company_id=${companyid}`;
        if (
          addNewAssesment === "newaddNewAssesment" &&
          assesstquestiondata?.length > 0 &&
          values &&
          values?.score !== undefined
        ) {
          dispatch(AddAssessmentApi(data, assessmentreq));
        } else if (addNewAssesment === "newaddNewAssesment") {
          if (!assesstquestiondata?.length > 0) {
            toast.error("Add Questions First");
          } else if (
            assesstquestiondata?.length > 0 &&
            values?.score === undefined
          ) {
            toast.error("Fill score First");
          }

          // dispatch(UpdateAssesmentApi(data, assessmentreq));
        }
        if (
          addNewAssesment !== "newaddNewAssesment" &&
          assesstquestiondata?.length > 0
        ) {
          dispatch(UpdateAssesmentApi(data, assessmentreq));
        }
      }
    },
  });
  useEffect(() => {
    const assessmentquestionData = localStorage.getItem("assestsQustions");
    console.log("assessmentquestionData", JSON.parse(assessmentquestionData));
    if (assessmentquestionData) {
      const newQuesAssesst = JSON.parse(assessmentquestionData);
      if (newQuesAssesst) {
        dispatch(assessmentQustionEditData(newQuesAssesst));
      }
    }
  }, [assesstquestiondata]);
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const handleClickEditform = (item, i, fields) => {
    localStorage.setItem("addAssesmentFields", JSON.stringify(fields));
    navigate("/assessment/edit-assessment", {
      state: {
        UpdateAssessment:
          addNewAssesment === "newaddNewAssesment"
            ? "newaddNewAssesment"
            : UpdateAssessment,
        id: id,
      },
    });
    localStorage.setItem("assesstQustionEditData", JSON.stringify(item));
    // localStorage.setItem("editquestionasses", JSON.stringify(assesstquestiondata));
    localStorage.setItem("assestqustioneditid", i);
    // if (title) {
    //   localStorage.setItem("addQustionTitleName", title);
    // }
  };

  const handleDelete = (index) => {
    const localdata = assesstquestiondata.filter((item, i) => i !== index);
    setAssesstQuestionData(localdata);
    localStorage.setItem("editquestionasses", JSON.stringify(localdata));
    localStorage.setItem("assestsQustions", JSON.stringify(localdata));
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    const copyListItems = [...assesstquestiondata];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setAssesstQuestionData(copyListItems);
  };
  useEffect(() => {
    localStorage.removeItem("assesstQustionEditData");
    localStorage.removeItem("assestqustioneditid");
  }, []);

  const trainingLocalClear = () => {
    localStorage.removeItem("addAssesmentFields");
    navigate("/assessment");
  };
  return (
    <>
      {props.type === "auditorAssessments" ? (
        <Box>
          <ToastContainer />
          <form onSubmit={formik.handleSubmit}>
            <Box className="header-card">
              <Grid container spacing={1} sx={{ alignItems: "center" }}>
                <Grid item xs={12} md={8} sm={12} lg={8}></Grid>
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "end", width: "100%" }}
                  >
                    <AssessmentHeader />
                    <div className="create-assessment-header">
                      <Button
                        className="cancle-button cancle-hover"
                        onClick={trainingLocalClear}
                      >
                        <CloseOutlinedIcon sx={{ mr: 1 }} />
                        Cancel
                      </Button>
                      {/* <Link to="/assessment" className="submenu-item"> */}
                      <Button
                        type="submit"
                        // onClick={trainingLocalClear}
                        className="header-add cancle-hover"
                      >
                        <AddOutlinedIcon sx={{ mr: 1 }} />
                        {addNewAssesment === "newaddNewAssesment"
                          ? "Create"
                          : "Update"}
                      </Button>
                      {/* </Link> */}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                bgcolor: "#F6F8FC",
                mt: 1,
                p: 2,
                overflowX: "hidden",
                overflowY: "auto",
                borderRadius: "8px",
                "&::-webkit-scrollbar": {
                  width: 15,
                },
                // paddingTop:'0',
                // paddingBottom:'0',
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              <Grid
                container
                spacing={1}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item md={4} sm={12} xs={12} lg={4}>
                  <Box
                    maxWidth="sm"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      p: 3,
                      textAlign: "left",
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    {UpdateAssessment && assessmentUpdateData ? (
                      <>
                        {" "}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Title</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            className="date-input"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Summary</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="summary"
                            onChange={formik.handleChange}
                            value={formik.values.summary}
                            className="date-input"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Grid item md={7}>
                              {/* <label>Assessment Time</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          select
                          fullWidth
                          name="time"
                          onChange={formik.handleChange}
                          value={formik.values.time}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </TextField> */}
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Assessment Time in min</label>
                                <TextField
                                  name="time"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  type="number"
                                  value={formik.values.time}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.questionscore &&
                                    Boolean(formik.errors.questionscore)
                                  }
                                  helperText={
                                    formik.touched.questionscore &&
                                    formik.errors.questionscore
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={7}>
                              {/* <label>Passing Score</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          select
                          fullWidth
                          name="score"
                          onChange={formik.handleChange}
                          value={formik.values.score}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </TextField> */}

                              {/* Validation removed in below code  */}
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Passing Score in %</label>
                                <TextField
                                  name="score"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  type="number"
                                  value={formik.values.score}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.score &&
                                    Boolean(formik.errors.score)
                                  }
                                  helperText={
                                    formik.touched.score && formik.errors.score
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </FormControl>{" "}
                      </>
                    ) : (
                      <>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Title</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            error={
                              formik.touched.title &&
                              Boolean(formik.errors.title)
                            }
                            helperText={
                              formik.touched.title && formik.errors.title
                            }
                            className="date-input"
                          />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Summary</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="summary"
                            onChange={formik.handleChange}
                            value={formik.values.summary}
                            error={
                              formik.touched.summary &&
                              Boolean(formik.errors.summary)
                            }
                            helperText={
                              formik.touched.summary && formik.errors.summary
                            }
                            className="date-input"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Grid item md={7}>
                              {/* <label>Assessment Time</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          select
                          fullWidth
                          name="time"
                          onChange={formik.handleChange}
                          value={formik.values.time}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </TextField> */}
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Assessment Time in min </label>
                                <TextField
                                  name="time"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  type="number"
                                  value={formik.values.time}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.time &&
                                    Boolean(formik.errors.time)
                                  }
                                  helperText={
                                    formik.touched.time && formik.errors.time
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={6}>
                              {assesstquestiondata &&
                              assesstquestiondata.length !== 0 ? (
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                  <label>Passing Score in %</label>
                                  <TextField
                                    name="score"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    type="number"
                                    value={formik.values.score}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.score &&
                                      Boolean(formik.errors.score)
                                    }
                                    helperText={
                                      formik.touched.score &&
                                      formik.errors.score
                                    }
                                    id="filled-number"
                                    variant="outlined"
                                    size="small"
                                    className="date-input"
                                  />
                                </FormControl>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isFixed}
                                onChange={(e) => setIsFixed(e.target.checked)}
                                size="small"
                              />
                            }
                            label="Show Score"
                            sx={{ mb: 2 }}
                          />
                        </FormGroup>
                      </>
                    )}
                  </Box>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  md={7.7}
                  sm={12}
                  xs={12}
                  lg={7.7}
                  className="Assessment-Ques-grid"
                  style={{ paddingLeft: "0px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography variant="p" component="b">
                        Assessment Question
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleQuestion(formik.values);
                        }}
                        className="add-button cancle-hover"
                        sx={{ width: "100%", mt: 1 }}
                      >
                        <AddIcon sx={{ mx: 1 }} />
                        Add New Question
                      </Button>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ overflowX: "auto", maxHeight: "520px" }}
                    >
                      {assesstquestiondata &&
                        assesstquestiondata?.map((items, i) => {
                          const type = (
                            <p>
                              Type : <b>{items?.type?.title}</b>
                            </p>
                          );
                          return (
                            <Card sx={{ mb: 2, borderRadius: "8px" }} key={i}>
                              <div
                                style={{ cursor: "grab", textAlign: "center" }}
                                onDragStart={(e) => dragStart(e, i)}
                                onDragEnter={(e) => dragEnter(e, i)}
                                onDragEnd={drop}
                                draggable
                              >
                                <DehazeIcon />
                              </div>
                              <CardContent sx={{ color: "#27364b" }}>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={11}
                                      style={{
                                        padding: "0",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="p"
                                            component="b"
                                            sx={{ float: "left" }}
                                          >
                                            Q. {i + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={12}>
                                          <div
                                            style={{
                                              width: "93%",
                                              borderRadius: "8px",
                                              padding: "9px",
                                              fontSize: "14px",
                                              border: "1px solid #CBD4E1",
                                            }}
                                          >
                                            <p style={{ margin: "0" }}>
                                              {items?.title}
                                            </p>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={1} style={{ padding: "0" }}>
                                      <Box>
                                        <img
                                          src={
                                            hover.Edit && indexHover === i
                                              ? EditHover
                                              : Edit
                                          }
                                          alt="Edit"
                                          onClick={(e) => {
                                            handleClickEditform(
                                              items,
                                              i,
                                              formik.values
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                          onMouseOver={() => {
                                            setHover({ Edit: true });
                                            setIndexHover(i);
                                          }}
                                          onMouseLeave={() => {
                                            setHover({ Edit: false });
                                          }}
                                        />
                                        <img
                                          src={
                                            hover.Delete && indexHover === i
                                              ? DeleteHover
                                              : Delete
                                          }
                                          alt="Delete"
                                          onClick={() => handleDelete(i)}
                                          onMouseOver={() => {
                                            setHover({ Delete: true });
                                            setIndexHover(i);
                                          }}
                                          onMouseLeave={() => {
                                            setHover({ Delete: false });
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Stack>
                                <Stack
                                  direction="row"
                                  sx={{
                                    justifyContent: "space-between",
                                    alignItems: "baseline",
                                    textAlign: "center",
                                  }}
                                  spacing={1}
                                  className="validation-div"
                                >
                                  <Typography
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    variant="p"
                                    component="b"
                                    // sx={{ whiteSpace: "nowrap" }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        textAlign: "left",
                                      }}
                                    >
                                      {" "}
                                      Answer : &nbsp;
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        display: "inline-table",
                                      }}
                                    >
                                      {items?.type?.key === "multi_select"
                                        ? items &&
                                          items?.answer?.length > 0 &&
                                          items?.answer?.map((item, i) => {
                                            return (
                                              <ul
                                                key={i}
                                                style={{ textAlign: "left" }}
                                              >
                                                <li
                                                  style={{
                                                    listStyle: "initial",
                                                  }}
                                                >
                                                  {item?.title
                                                    ? item?.title
                                                    : ""}
                                                </li>
                                              </ul>
                                            );
                                          })
                                        : items?.type?.key ===
                                            "single_select" ||
                                          items?.type?.key === "boolean"
                                        ? items?.answer?.title
                                        : items?.type?.key === "text" ||
                                          items?.type?.key === "number"
                                        ? items?.answer
                                        : ""}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    <Chip
                                      label={type}
                                      variant="outlined"
                                      className={classes.root}
                                    />
                                  </Typography>
                                  {/* <Typography>
                                    <Tooltip
                                      title={items?.question_score}
                                      arrow
                                      placement="right-end"
                                    >
                                      <p className="signout-btn">
                                        Question Score
                                      </p>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      arrow
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit">
                                            Acceptable :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.acceptable
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Discarded :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.discarded
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            No : {items?.type?.option_score?.no}
                                          </Typography>
                                          <Typography color="inherit">
                                            Not Acceptable :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.notAcceptable
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Not Applicable :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.notApplicable
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Returned :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.returned
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Yes :{" "}
                                            {items?.type?.option_score?.yes}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <p className="signout-btn">
                                        Option Score
                                      </p>
                                    </Tooltip>
                                  </Typography> */}
                                </Stack>
                              </CardContent>
                            </Card>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      ) : (
        <Box>
          <ToastContainer />
          <form onSubmit={formik.handleSubmit}>
            <Box className="header-card">
              <Grid container spacing={1} sx={{ alignItems: "center" }}>
                <Grid item xs={12} md={8} sm={12} lg={8}>
                  <Box className="allform-head-time">
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <small
                        style={{
                          marginRight: "5px",
                          fontSize: "15px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Created :{" "}
                      </small>

                      <b>
                        {moment(assessmentUpdateData?.createdAt).format("lll")}{" "}
                        &nbsp;{" "}
                      </b>
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <small
                        style={{
                          marginRight: "5px",
                          fontSize: "15px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Modified :{" "}
                      </small>
                      <b>
                        {moment(assessmentUpdateData?.updatedAt).format("lll")}
                      </b>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "end", width: "100%" }}
                  >
                    <AssessmentHeader />
                    <div className="create-assessment-header">
                      <Button
                        className="cancle-button cancle-hover"
                        onClick={trainingLocalClear}
                      >
                        <CloseOutlinedIcon sx={{ mr: 1 }} />
                        Cancel
                      </Button>
                      {/* <Link to="/assessment" className="submenu-item"> */}
                      <Button
                        type="submit"
                        // onClick={trainingLocalClear}
                        className="header-add cancle-hover"
                      >
                        <AddOutlinedIcon sx={{ mr: 1 }} />
                        {addNewAssesment === "newaddNewAssesment"
                          ? "Create"
                          : "Update"}
                      </Button>
                      {/* </Link> */}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                bgcolor: "#F6F8FC",
                mt: 1,
                p: 2,
                overflowX: "hidden",
                overflowY: "auto",
                borderRadius: "8px",
                "&::-webkit-scrollbar": {
                  width: 15,
                },
                // paddingTop:'0',
                // paddingBottom:'0',
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              <Grid
                container
                spacing={1}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item md={4} sm={12} xs={12} lg={4}>
                  <Box
                    maxWidth="sm"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      p: 3,
                      textAlign: "left",
                      width: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    {UpdateAssessment && assessmentUpdateData ? (
                      <>
                        {" "}
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Title</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            className="date-input"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Summary</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="summary"
                            onChange={formik.handleChange}
                            value={formik.values.summary}
                            className="date-input"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Grid item md={7}>
                              {/* <label>Assessment Time</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        select
                        fullWidth
                        name="time"
                        onChange={formik.handleChange}
                        value={formik.values.time}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </TextField> */}
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Assessment Time in min</label>
                                <TextField
                                  name="time"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  type="number"
                                  value={formik.values.time}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.questionscore &&
                                    Boolean(formik.errors.questionscore)
                                  }
                                  helperText={
                                    formik.touched.questionscore &&
                                    formik.errors.questionscore
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={7}>
                              {/* <label>Passing Score</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        select
                        fullWidth
                        name="score"
                        onChange={formik.handleChange}
                        value={formik.values.score}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </TextField> */}

                              {/* <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Passing Score in %</label>
                                <TextField
                                  name="score"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  type="number"
                                  value={formik.values.score}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.score &&
                                    Boolean(formik.errors.score)
                                  }
                                  helperText={
                                    formik.touched.score && formik.errors.score
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl> */}

                              {/* Validation removed for 0 in the case of edit */}
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Passing Score in %</label>
                                <TextField
                                  name="score"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="number"
                                  value={formik.values.score}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.score &&
                                    Boolean(formik.errors.score)
                                  }
                                  helperText={
                                    formik.touched.score && formik.errors.score
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </FormControl>{" "}
                      </>
                    ) : (
                      <>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Title</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            error={
                              formik.touched.title &&
                              Boolean(formik.errors.title)
                            }
                            helperText={
                              formik.touched.title && formik.errors.title
                            }
                            className="date-input"
                          />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label>Assessment Summary</label>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="summary"
                            onChange={formik.handleChange}
                            value={formik.values.summary}
                            error={
                              formik.touched.summary &&
                              Boolean(formik.errors.summary)
                            }
                            helperText={
                              formik.touched.summary && formik.errors.summary
                            }
                            className="date-input"
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Grid item md={7}>
                              {/* <label>Assessment Time</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        select
                        fullWidth
                        name="time"
                        onChange={formik.handleChange}
                        value={formik.values.time}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </TextField> */}
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Assessment Time in min </label>
                                <TextField
                                  name="time"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  type="number"
                                  value={formik.values.time}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.time &&
                                    Boolean(formik.errors.time)
                                  }
                                  helperText={
                                    formik.touched.time && formik.errors.time
                                  }
                                  id="filled-number"
                                  variant="outlined"
                                  size="small"
                                  className="date-input"
                                />
                              </FormControl>
                            </Grid>
                            {/* <Grid item md={6}>
                        {assesstquestiondata &&
                          assesstquestiondata.length !== 0 ? (
                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Passing Score in %</label>
                            <TextField
                              name="score"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{ inputProps: { min: 1 } }}
                              type="number"
                              value={formik.values.score}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.score &&
                                Boolean(formik.errors.score)
                              }
                              helperText={
                                formik.touched.score && formik.errors.score
                              }
                              id="filled-number"
                              variant="outlined"
                              size="small"
                              className="date-input"
                            />
                          </FormControl>
                        ) : (
                          ""
                        )}
                      </Grid> */}
                            <Grid item md={6}>
                              {assesstquestiondata &&
                              assesstquestiondata.length !== 0 ? (
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                  <label>Passing Score in %</label>
                                  <TextField
                                    name="score"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // Remove the min attribute from inputProps
                                    value={formik.values.score}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.score &&
                                      Boolean(formik.errors.score)
                                    }
                                    helperText={
                                      formik.touched.score &&
                                      formik.errors.score
                                    }
                                    id="filled-number"
                                    variant="outlined"
                                    size="small"
                                    className="date-input"
                                  />
                                </FormControl>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isFixed}
                                onChange={(e) => setIsFixed(e.target.checked)}
                                size="small"
                              />
                            }
                            label="Show Score"
                            sx={{ mb: 2 }}
                          />
                        </FormGroup>
                      </>
                    )}
                  </Box>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  md={7.7}
                  sm={12}
                  xs={12}
                  lg={7.7}
                  className="Assessment-Ques-grid"
                  style={{ paddingLeft: "0px" }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography variant="p" component="b">
                        Assessment Question
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleQuestion(formik.values);
                        }}
                        className="add-button cancle-hover"
                        sx={{ width: "100%", mt: 1 }}
                      >
                        <AddIcon sx={{ mx: 1 }} />
                        Add New Question
                      </Button>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ overflowX: "auto", maxHeight: "520px" }}
                    >
                      {assesstquestiondata &&
                        assesstquestiondata?.map((items, i) => {
                          const type = (
                            <p>
                              Type : <b>{items?.type?.title}</b>
                            </p>
                          );
                          return (
                            <Card sx={{ mb: 2, borderRadius: "8px" }} key={i}>
                              <div
                                style={{ cursor: "grab", textAlign: "center" }}
                                onDragStart={(e) => dragStart(e, i)}
                                onDragEnter={(e) => dragEnter(e, i)}
                                onDragEnd={drop}
                                draggable
                              >
                                <DehazeIcon />
                              </div>
                              <CardContent sx={{ color: "#27364b" }}>
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={11}
                                      style={{
                                        padding: "0",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="p"
                                            component="b"
                                            sx={{ float: "left" }}
                                          >
                                            Q. {i + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={12}>
                                          <div
                                            style={{
                                              width: "93%",
                                              borderRadius: "8px",
                                              padding: "9px",
                                              fontSize: "14px",
                                              border: "1px solid #CBD4E1",
                                            }}
                                          >
                                            <p style={{ margin: "0" }}>
                                              {items?.title}
                                            </p>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={1} style={{ padding: "0" }}>
                                      <Box>
                                        <img
                                          src={
                                            hover.Edit && indexHover === i
                                              ? EditHover
                                              : Edit
                                          }
                                          alt="Edit"
                                          onClick={(e) => {
                                            handleClickEditform(
                                              items,
                                              i,
                                              formik.values
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                          onMouseOver={() => {
                                            setHover({ Edit: true });
                                            setIndexHover(i);
                                          }}
                                          onMouseLeave={() => {
                                            setHover({ Edit: false });
                                          }}
                                        />
                                        <img
                                          src={
                                            hover.Delete && indexHover === i
                                              ? DeleteHover
                                              : Delete
                                          }
                                          alt="Delete"
                                          onClick={() => handleDelete(i)}
                                          onMouseOver={() => {
                                            setHover({ Delete: true });
                                            setIndexHover(i);
                                          }}
                                          onMouseLeave={() => {
                                            setHover({ Delete: false });
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Stack>
                                <Stack
                                  direction="row"
                                  sx={{
                                    justifyContent: "space-between",
                                    alignItems: "baseline",
                                    textAlign: "center",
                                  }}
                                  spacing={1}
                                  className="validation-div"
                                >
                                  <Typography
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    variant="p"
                                    component="b"
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        textAlign: "left",
                                      }}
                                    >
                                      Answer:{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        display: "inline-table",
                                      }}
                                    >
                                      {items?.type?.key === "multi_select"
                                        ? items &&
                                          items?.answer?.length > 0 &&
                                          items?.answer?.map((item, i) => {
                                            return (
                                              <ul
                                                key={i}
                                                style={{ textAlign: "left" }}
                                              >
                                                <li
                                                  style={{
                                                    listStyle: "initial",
                                                  }}
                                                >
                                                  {item?.title
                                                    ? item?.title
                                                    : ""}
                                                </li>
                                              </ul>
                                            );
                                          })
                                        : items?.type?.key ===
                                            "single_select" ||
                                          items?.type?.key === "boolean"
                                        ? items?.answer?.title
                                        : items?.type?.key === "text" ||
                                          items?.type?.key === "number"
                                        ? items?.answer
                                        : ""}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    <Chip
                                      label={type}
                                      variant="outlined"
                                      className={classes.root}
                                    />
                                  </Typography>
                                  {/* <Typography>
                                    <Tooltip
                                      title={items?.question_score}
                                      arrow
                                      placement="right-end"
                                    >
                                      <p className="signout-btn">
                                        Question Score
                                      </p>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      arrow
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit">
                                            Acceptable :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.acceptable
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Discarded :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.discarded
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            No : {items?.type?.option_score?.no}
                                          </Typography>
                                          <Typography color="inherit">
                                            Not Acceptable :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.notAcceptable
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Not Applicable :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.notApplicable
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Returned :{" "}
                                            {
                                              items?.type?.option_score
                                                ?.returned
                                            }
                                          </Typography>
                                          <Typography color="inherit">
                                            Yes :{" "}
                                            {items?.type?.option_score?.yes}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <p className="signout-btn">
                                        Option Score
                                      </p>
                                    </Tooltip>
                                  </Typography> */}
                                </Stack>
                              </CardContent>
                            </Card>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};

export default AddAssessment;
