import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Card, CardContent } from "@mui/material";
import Stack from "@mui/material/Stack";
import { ViewHeader } from "./Header";
import { Typography, Grid, TablePagination } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import User from "../../../assets/icons/svg-icons/User.svg";
import UserPro from "../../images/UserPro.png";
import { ViewWasteApi } from "../../redux/actions/WasteManagement";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";

function createData(
  LogID,
  Number,
  User,
  CreatedDate,
  CreatedTime,
  UpdatedDate,
  UpdatedTime,
  Store,
  Note,
  Products
) {
  return {
    LogID,
    Number,
    User,
    CreatedDate,
    CreatedTime,
    UpdatedDate,
    UpdatedTime,
    Store,
    Note,
    Products,
  };
}

const rows = [
  createData(
    "1234567890",
    "1234567890",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM",
    "May 31, 2022",
    "3:46:02 PM",
    "Alisign",
    "Neque porro quisquam est qui",
    "5"
  ),
  createData(
    "1234567890",
    "1234567890",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM",
    "May 31, 2022",
    "3:46:02 PM",
    "Alisign",
    "Neque porro quisquam est qui",
    "5"
  ),
  createData(
    "1234567890",
    "1234567890",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM",
    "May 31, 2022",
    "3:46:02 PM",
    "Alisign",
    "Neque porro quisquam est qui",
    "5"
  ),
  createData(
    "1234567890",
    "1234567890",
    "Pallavi+66 Kumari",
    "May 31, 2022",
    "3:46:02 PM",
    "May 31, 2022",
    "3:46:02 PM",
    "Alisign",
    "Neque porro quisquam est qui",
    "5"
  ),
];

const WasteLogDetails = () => {
  const detailsData = useSelector(
    (state) =>
      state &&
      state.wastemng &&
      state.wastemng.viewData &&
      state.wastemng.viewData.data &&
      state.wastemng.viewData.data.data
  );

  const dispatch = useDispatch();
  const userid = localStorage.getItem("wastelogid");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [viewData, setViewData] = useState({});
  useEffect(() => {
    listWastage(userid);
  }, [userid]);
  useEffect(() => {
    setViewData(detailsData);
  }, [detailsData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const listWastage = (userid) => {
    const id = localStorage.getItem("companyId");
    const userId = userid;
    const data = {
      url: BASE_URL + `inventory/wastage/${userId}?company_id=${id}`,
    };
    dispatch(ViewWasteApi(data));
  };

  return (
    <Box>
      <ViewHeader viewData={viewData} />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Card sx={{ px: 1, mb: 2, borderRadius: "8px" }}>
          <CardContent>
            <Grid container spacing={1} sx={{ p: 1 }}>
              <Grid item xs={12} lg={3} sm={12} md={3}>
                <div>
                  <img
                    src={
                      viewData?.addedBy?.avatar?.url
                        ? viewData?.addedBy?.avatar?.url
                        : UserPro
                    }
                    alt="userimage"
                    align="center"
                    width={150}
                    height={150}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={9} sm={12} md={9}>
                <Table aria-label="simple table" className="details-table">
                  <TableBody>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          User:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        {viewData?.addedBy?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          ID:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        {viewData?._id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Role:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">Auditor</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Store:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        {viewData?.store?.name ? viewData?.store?.name : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Updated at:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        {moment(viewData?.updatedAt).format(
                          "MMMM D, YYYY hh:mm:ss"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <TableContainer component={Paper}>
          <Table size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  S. No.
                </TableCell>
                <TableCell align="left" className="tableHead">
                  Product Name
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Category
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Description
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Quantity
                </TableCell>
                <TableCell align="center" className="tableHead">
                  UOM
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Price
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Total Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData &&
                viewData?.products?.length > 0 &&
                viewData?.products?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="left">
                      {/* <Avatar className='message-avatar'>
                                                <img src={User} alt="avatar" />
                                                </Avatar> */}
                      <Typography variant="p" component="b">
                        {row?.product?.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="p" component="p">
                        {row?.product?.category?.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="p" component="p">
                        {row?.product?.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{row?.quantity}</TableCell>
                    <TableCell align="center">
                      {row?.product?.uom?.title}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="p" component="p">
                        {row?.product?.price}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {row?.product?.price * row?.quantity}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Table Pagination */}
      {/* <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 1, borderRadius: 1 }}>
                <Grid container spacing={1} sx={{ alignItems: "center" }}>
                    <Grid item xs={12}>
                        <TablePagination
                            className="tablepagination"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={"Items per Page"}
                            showFirstButton={false}
                        />
                    </Grid>
                </Grid>
            </Box> */}
    </Box>
  );
};

export default WasteLogDetails;
