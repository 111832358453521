/** @format */

import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import Notification from "./Common/Notificatiob";
import ProfileModal from "./Common/ProfileModal";
import { Link, useNavigate } from "react-router-dom";
// import company from "../images/_Button-H.png";
import userprofile from "../images/_Button-H (1).png";
// import company1 from "../images/_Button-H (2).png";
import company2 from "../images/_Button-H (3).png";
import ProfileMenu from "./ProfileMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { NotificationApi } from "../redux/actions/manageNotification";
import { ProfileDetailApi } from "../redux/actions/profileDetail";
import { useDispatch, useSelector } from "react-redux";
import { SearchSelectApi, SelectSubroleApi } from "../redux/actions/Checklist";
import { SubmissionListApi } from "../redux/actions/Checklist";
import { SubRoleApi } from "../redux/actions/subRoleSelected";

const Header = (props) => {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const [notification, setNotification] = useState(false);
  const [help, setHelp] = useState(false);
  const [user, setUser] = useState(false);
  const [logout, setLogout] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [openProfileDetail, setOpenProfileDetail] = useState(false);
  const [selectSubroles, setSelectSubroles] = useState([]);
  const [subRoles, setSubRoles] = useState([]);
  const [subRole, setSubRole] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const notificationListData = useSelector(
    (state) =>
      state &&
      state?.NotiFicationReducer &&
      state?.NotiFicationReducer?.NotificationData &&
      state?.NotiFicationReducer?.NotificationData?.data &&
      state?.NotiFicationReducer?.NotificationData?.data
  );

  const selectSearchData = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.selectSearch &&
      state.checklist.selectSearch.data &&
      state.checklist.selectSearch.data.mapRolelist &&
      state.checklist.selectSearch.data.mapRolelist[0]?.role_map
  );
  const payloadRequest = {
    body: {
      page: page,
      per_page: rowsPerPage,
      sort_as: "updatedAt",
      sort_by: "desc",
      Role: [],
      q: "",
    },
  };

  const profileMeGetRecords = useSelector(
    (state) =>
      state?.MyProfileDetails &&
      state?.MyProfileDetails?.progileMeGetApiRes &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data?.data
  );

  // const checklistProgressPostAPI = (data) => {
  //   const id = localStorage.getItem("companyId");
  //   data.url = BASE_URL + `checklists/progress?company_id=${id}`;
  //   dispatch(SubmissionListApi(data));
  // };
  const comapnyId = localStorage.getItem("companyId");
  useEffect(() => {
    if (comapnyId) {
      const data = {
        url: BASE_URL + `profile/MappedRoles?company_id=${comapnyId}`,
      };
      dispatch(SearchSelectApi(data));
    }
  }, [comapnyId]);

  useEffect(() => {
    setSelectSubroles([]);
  }, [comapnyId]);

  useEffect(() => {
    if (subRole) {
      payloadRequest.body.Role = subRole;
      // checklistProgressPostAPI(payloadRequest);
    }
  }, [subRole]);

  useEffect(() => {
    setSubRoles(selectSearchData);
  }, [selectSearchData]);

  const id = localStorage.getItem("companyId");
  useEffect(() => {
    if (id) {
      let data = {
        url: BASE_URL + `notification/index?company_id=${id}`,
      };
      dispatch(NotificationApi(data));
      dispatch(ProfileDetailApi());
    }
  }, [id]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleNotificationOpen = () => setOpenNotification(true);
  const handleProfileDetailOpen = () => setOpenProfileDetail(true);

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleSubrolesSelect = (values) => {
    setSubRole(values);
  };

  const handleNotiOver = () => {
    setNotification(true);
  };

  const handleNotiOut = () => {
    setNotification(false);
  };
  const handleHelpOver = () => {
    setHelp(true);
  };

  const handleHelpOut = () => {
    setHelp(false);
  };
  const handleUserOver = () => {
    setUser(true);
  };

  const handleUserOut = () => {
    setUser(false);
  };
  const handleLogoutOver = () => {
    setLogout(true);

    // removing current user logged in details if logout
    localStorage.removeItem("CurrentUserDetails");
  };

  const handleLogoutOut = () => {
    setLogout(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    handleSubrolesSelect(selectSubroles);
  }, [selectSubroles]);

  const handleSubroles = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelectSubroles(
        selectSubroles.length === subRoles?.length ? [] : selectSubroles
      );
      return;
    }
    setSelectSubroles(value);
    dispatch(SubRoleApi(value));
  };

  return (
    <>
      <div>
        <div className="profile-menu">
          <Notification
            openNotification={openNotification}
            setOpenNotification={setOpenNotification}
          />
          {/* <ProfileModal
          openProfileDetail={openProfileDetail}
          setOpenProfileDetail={setOpenProfileDetail}
        /> */}
          <ProfileMenu
            handleOpenNavMenu={handleOpenNavMenu}
            handleOpenUserMenu={handleOpenUserMenu}
            handleCloseNavMenu={handleCloseNavMenu}
            handleCloseUserMenu={handleCloseUserMenu}
            anchorElNav={anchorElNav}
            anchorElUser={anchorElUser}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
            handleNotiOver={handleNotiOver}
            handleNotiOut={handleNotiOut}
            handleHelpOver={handleHelpOver}
            handleUserOver={handleUserOver}
            handleUserOut={handleUserOut}
            handleHelpOut={handleHelpOut}
            isHovering={isHovering}
            notification={notification}
            help={help}
            user={user}
          />
        </div>
        <div className="subRole">
          {comapnyId ? (
            <FormControl sx={{ display: "grid" }}>
              <Select
                labelId="mutiple-select-label"
                multiple
                name="roleselect"
                size="small"
                sx={{ borderRadius: "8px" }}
                className="Sub-Role-Select"
                displayEmpty
                value={selectSubroles}
                onChange={handleSubroles}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  disableScrollLock: true,
                  PaperProps: {
                    sx: {
                      height: "180px",
                    },
                  },
                }}
                renderValue={(selectSubroles) => {
                  if (selectSubroles?.length === 0) {
                    return (
                      <em
                        style={{
                          color: "#b9b9b9",
                          fontFamily: "Sans-serif",
                          fontWeight: "normal",
                        }}
                      >
                        Select Sub Role
                      </em>
                    );
                  }
                  return selectSubroles.map((item) => item)?.join(",");
                }}
              >
                {subRoles &&
                  subRoles.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option} className="select-item">
                        <ListItemIcon>
                          <Checkbox
                            checked={selectSubroles.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="header-manage">
        <Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            {comapnyId && !profileMeGetRecords?.preferredCompany?.isAuditor ? (
              <FormControl
                sx={{ display: "grid", marginTop: "5px", marginRight: "6px" }}
              >
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  name="roleselect"
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  className="Sub-Role-Select"
                  displayEmpty
                  value={selectSubroles}
                  onChange={handleSubroles}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        // bgcolor: "#FFFFFF",
                        // "& .MuiMenuItem-root": {
                        //   padding: 0,
                        // },
                        height: "180px",
                      },
                    },
                  }}
                  renderValue={(selectSubroles) => {
                    if (selectSubroles?.length === 0) {
                      return (
                        <em
                          style={{
                            color: "#b9b9b9",
                            fontFamily: "Sans-serif",
                            fontWeight: "normal",
                          }}
                        >
                          Select Sub Role
                        </em>
                      );
                    }
                    return selectSubroles.map((item) => item)?.join(",");
                  }}
                  // renderValue={(selectSubroles) =>
                  //   selectSubroles.map((item) => item)?.join(",")
                  // }
                >
                  {/* <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          isAllSelected
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem> */}
                  {subRoles &&
                    subRoles.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option}
                          className="select-item"
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={selectSubroles.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : (
              ""
            )}

            {/* <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              // onClick={handleClickmenu}
            >
              <img src={company} />
            </Button> */}

            <Link className="header-item" title="Notification">
              <h5
                className={
                  window.location.pathname === "/company-list"
                    ? "notify-text-company"
                    : "notify-text"
                }
              >
                {notificationListData
                  ? notificationListData &&
                    notificationListData?.notifications &&
                    notificationListData?.notifications?.length
                  : ""}
              </h5>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="company2"
                onMouseOver={handleNotiOver}
                onMouseOut={handleNotiOut}
                onClick={handleNotificationOpen}
              >
                {/* <img
                  src={company2}
                  alt="notification"
                  className="header-images"
                /> */}
                <NotificationsIcon className="header-images" />
                {/* <p style={{ margin: 0, fontSize: "7px" }} className="">

                  Notification
                </p> */}
                {/* {notification ? (
                  <p className="header-item">Notification</p>
                ) : (
                  ""
                )} */}
              </Button>
            </Link>
            {/* <Link className="header-item"> */}
            {/* <Button aria-controls="simple-menu" aria-haspopup="true" className="company2" onMouseOver={handleHelpOver} onMouseOut={handleHelpOut}> */}
            {/* <img src={company1} alt='help' className="header-images" /> */}
            {/* <HelpOutlineIcon className="header-images" /> */}
            {/* <p style={{margin: 0, fontSize: "7px"}} className="">Help</p> */}
            {/* {help ? <p className="header-item">Help</p> : ''} */}
            {/* </Button> */}
            {/* </Link> */}

            {/* Profile icon */}
            {/* <Link className="header-item"> */}
            <Link
              to="my-profile-details"
              className="header-item"
              title="Profile Details"
            >
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="company2"
                onMouseOver={handleUserOver}
                onMouseOut={handleUserOut}
                onClick={handleProfileDetailOpen}
              >
                {/* <img
                  src={userprofile}
                  alt="userprofile"
                  className="header-images"
                /> */}
                <PersonOutlineIcon className="header-images" />
                {/* <p style={{ margin: 0, fontSize: "7px" }} className="">
                  User Profile
                </p> */}
                {/* {user ? <p className="header-item">User Profile</p> : ""} */}
              </Button>
            </Link>

            {/* Company icon  */}
            <Link
              to="/company-list"
              className="header-item"
              title="Company List"
            >
              {/* <h5 className="notify-text">5</h5> */}
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="company2"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                {/* <img src={company} alt='company' className="header-images" /> */}
                <WorkOutlineIcon className="header-images" />
                {/* <p style={{margin: 0, fontSize: "7px"}} className="">User Profile</p> */}
                {/* {isHovering ? <p className="header-item">Company</p> : ''} */}
              </Button>
            </Link>
            <div onClick={handleLogout} className="header-item" title="Logout">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="company2"
                onMouseOver={handleLogoutOver}
                onMouseOut={handleLogoutOut}
                onClick={handleLogout}
              >
                {/* <img src={company} alt='company' className="header-images" /> */}
                <LogoutIcon className="header-images" />
                {/* <p style={{margin: 0, fontSize: "7px"}} className="">User Profile</p> */}
                {/* {logout ? <p className="header-item">Logout</p> : ''} */}
              </Button>
            </div>
          </div>
        </Typography>
      </div>
    </>
  );
};

export default Header;
