import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import company from "../images/_Button-H (3).svg";
import userprofile from "../images/_Button-H (2).svg";
import company1 from "../images/_Button-H (1).svg";
import company2 from "../images/_Button-H.svg";

const ProfileMenu = (props) => {
  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={props.handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar src="/static/images/avatar/2.jpg" className="avtar-icon" />
          </IconButton>
        </Tooltip>
        <Menu
          className="menubar"
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={props.anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(props.anchorElUser)}
          onClose={props.handleCloseUserMenu}
        >
          <MenuItem className="menuItems">
            <Typography>
              <div style={{ display: "flex" }}>
                {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickmenu}>
                    <img src={company} />
                  </Button> */}
                <Link className="header-item">
                  <h5 className="notify-text">21</h5>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="company1"
                    onMouseOver={props.handleNotiOver}
                    onMouseOut={props.handleNotiOut}
                  >
                    <img
                      src={company2}
                      alt="notification"
                      className="header-images"
                    />
                    {props.notification ? (
                      <p className="header-item">Notification</p>
                    ) : (
                      ""
                    )}
                  </Button>
                </Link>
                <Link className="header-item">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="company1"
                    onMouseOver={props.handleHelpOver}
                    onMouseOut={props.handleHelpOut}
                  >
                    <img src={company1} alt="help" className="header-images" />
                    {props.help ? <p className="header-item">Help</p> : ""}
                  </Button>
                </Link>
                <Link className="header-item">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="company1"
                    onMouseOver={props.handleUserOver}
                    onMouseOut={props.handleUserOut}
                  >
                    <img
                      src={userprofile}
                      alt="userprofile"
                      className="header-images"
                    />
                    {props.user ? (
                      <p className="header-item">User Profile</p>
                    ) : (
                      ""
                    )}
                  </Button>
                </Link>

                <Link to="/company-list" className="header-item">
                  <h5 className="notify-text">5</h5>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="company1"
                    onMouseOver={props.handleMouseOver}
                    onMouseOut={props.handleMouseOut}
                  >
                    <img
                      src={company}
                      alt="company"
                      className="header-images"
                    />
                    {props.isHovering ? (
                      <p className="header-item">Company</p>
                    ) : (
                      ""
                    )}
                  </Button>
                </Link>
              </div>
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default ProfileMenu;
