import React from "react";
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import TableHeader from "./pages/Header";
import Chart from "react-apexcharts";
import ToggleSwitch from "../../../helpers/ToggleSwitch";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const AuditOverview = () => {
  var chart = {
    series: [
      {
        name: "series1",
        data: [40, 27, 51, 42, 10, 2, 49, 90],
      },
      {
        name: "series2",
        data: [30, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      colors: ["#F39A4C", "#CBD4E1"],
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
        tickAmount: ["none"],
      },
      yaxis: {
        tickAmount: 10,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
          tickAmount: 10,
        },
      },
      legend: {
        show: false,
      },
    },
  };

  return (
    <Box>
      <TableHeader />
      <Container
        maxWidth="xl"
        sx={{
          bgcolor: "#F6F8FC",
          p: 1,
          pb: 5,
          mt: 1,
          borderRadius: "8px",
          height: "80vh",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={3}>
            <Card sx={{ px: 1 }} className="card">
              <CardContent>
                <Typography
                  variant="p"
                  component="div"
                  sx={{ pt: 1, textAlign: "center", fontWeight: 600 }}
                >
                  Total
                </Typography>
                <Typography variant="p" component="div">
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: "#F39A4C", my: 1, fontWeight: 600 }}
                  >
                    36.1
                  </Typography>
                  vsPP:{" "}
                  <Typography
                    variant="p"
                    component="b"
                    sx={{ color: "#039855" }}
                  >
                    36.14
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ height: "100%" }} className="card">
              <CardContent>
                <Typography
                  variant="p"
                  component="div"
                  sx={{ pt: 1, textAlign: "center", fontWeight: 600 }}
                >
                  Tradational
                </Typography>
                <Typography variant="p" component="div">
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: "#27364B", my: 1, fontWeight: 600 }}
                  >
                    38.5
                  </Typography>
                  vsPP:{" "}
                  <Typography
                    variant="p"
                    component="b"
                    sx={{ color: "#039855" }}
                  >
                    38.46
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ height: "100%" }} className="card">
              <CardContent>
                <Typography
                  variant="p"
                  component="div"
                  sx={{ pt: 1, textAlign: "center", fontWeight: 600 }}
                >
                  On Premise
                </Typography>
                <Typography variant="p" component="div">
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: "#27364B", my: 1, fontWeight: 600 }}
                  >
                    2.1
                  </Typography>
                  vsPP:{" "}
                  <Typography
                    variant="p"
                    component="b"
                    sx={{ color: "#BB251A" }}
                  >
                    (6.3)
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ height: "100%" }} className="card">
              <CardContent>
                <Typography
                  variant="p"
                  component="div"
                  sx={{ pt: 1, textAlign: "center", fontWeight: 600 }}
                >
                  Bonus
                </Typography>
                <Typography variant="p" component="div">
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ color: "#1465B4", my: 1, fontWeight: 600 }}
                  >
                    0.5
                  </Typography>
                  vsPP:{" "}
                  <Typography
                    variant="p"
                    component="b"
                    sx={{ color: "#039855" }}
                  >
                    0.2
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={2}>
            <div className="pointer">
              <div>
                <select className="select-opt select2">
                  <option>Caribbean</option>
                  <option>a</option>
                  <option>b</option>
                </select>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div className="point">
              <div className="text-content">
                <select className="select-opt select2">
                  <option>Total</option>
                  <option>a</option>
                  <option>b</option>
                </select>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <Card sx={{ minWidth: 275, px: 1 }} className="card">
              <CardContent>
                <Box>
                  <Stack direction="row" sx={{ display: "inline-flex" }}>
                    <Typography
                      variant="p"
                      component="div"
                      sx={{ fontSize: "24px", px: 2 }}
                    >
                      Caribbean:
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{ color: "#F39A4C", fontWeight: 600 }}
                    >
                      36.1
                    </Typography>
                  </Stack>
                  <Stack direction="row" sx={{ float: "right", mr: 2 }}>
                    <FileDownloadOutlinedIcon />
                  </Stack>
                </Box>
                <Typography
                  variant="p"
                  component="div"
                  sx={{
                    textAlign: "center",
                    color: "#64748B",
                    fontWeight: 600,
                  }}
                >
                  Total
                </Typography>

                <Chart
                  options={chart.options}
                  series={chart.series}
                  type="area"
                  height="350"
                />
                <Box direction="row" className="line-chart-box">
                  <Box className="line-chart-btns1">
                    <Box sx={{ mx: 2 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="stack"
                        sx={{
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          variant="p"
                          component="div"
                          className="graph-bottom"
                        >
                          Total
                        </Typography>
                        <ToggleSwitch />
                      </Stack>
                      <Divider
                        sx={{
                          backgroundColor: "#64748B",
                          height: "4px",
                          borderRadius: "50px",
                          borderStyle: "dotted",
                        }}
                      />
                    </Box>
                    <Box sx={{ mx: 2 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="stack"
                        sx={{
                          alignItems: "center",
                          //   borderBottom: "5px solid #F39A4C",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          variant="p"
                          component="div"
                          className="graph-bottom"
                        >
                          Previous Month
                        </Typography>
                        <ToggleSwitch />
                      </Stack>
                      <Divider
                        sx={{
                          backgroundColor: "#CBD4E1",
                          height: "4px",
                          borderRadius: "50px",
                        }}
                      />
                    </Box>
                    <Box sx={{ mx: 2 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="stack"
                        sx={{
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          variant="p"
                          component="div"
                          className="graph-bottom"
                        >
                          January 2021
                        </Typography>

                        <ToggleSwitch />
                      </Stack>
                      <Divider
                        sx={{
                          backgroundColor: "#F39A4C",
                          height: "4px",
                          borderRadius: "50px",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Stack
                      direction="row"
                      className="stack"
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        pl: 1,
                        justifyContent: "right",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Value
                      </Typography>
                      <ToggleSwitch />
                    </Stack>
                    <Button
                      variant="outlined"
                      size="small"
                      className="graph-button"
                    >
                      Show Table
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AuditOverview;
