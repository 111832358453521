const storemap = [
    {
      id: 1,
      title: "Channel",
      key: "channel",
      title_to_show: "Channel",
    },
    {
      id: 2,
      title: "Sub Channel",
      key: "sub_channel",
      title_to_show: "Sub Channel",
    },
    {
      id: 3,
      title: "Name",
      key: "name",
      title_to_show: "Name",
    },
    {
      id: 4,
      title: "GPS",
      key: "gps",
      title_to_show: "GPS",
    },
    {
      id: 5,
      title: "Image",
      key: "image",
      title_to_show: "Image",
    },
    {
      id: 6,
      title: "Address",
      key: "address",
      title_to_show: "Address",
    },
    {
      id: 7,
      title: "Region",
      key: "region",
      title_to_show: "Region",
    },
    {
      id: 8,
      title: "Sub Region",
      key: "sub_region",
      title_to_show: "Sub Region",
    },
    {
      id: 9,
      title: "Contact Number",
      key: "contact_number",
      title_to_show: "Contact Number",
    },
    {
      id: 9,
      title: "Email Address",
      key: "email_address",
      title_to_show: "Email Address",
    },
  ];
  
  export default storemap;