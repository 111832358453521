import { EDIT_ALL_FORM_ERROR, EDIT_ALL_FORM_REQUEST, EDIT_ALL_FORM_SUCCESS, LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS } from "../types/Types";

const initialState = {
    loading: false,
    error: '',
    data: '',
 };
 
 const EditAllFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_ALL_FORM_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case EDIT_ALL_FORM_SUCCESS: {
            return {
                ...state,
                data: action?.payload,
                loading: false
            }
        }
        case EDIT_ALL_FORM_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
 }

 export default EditAllFormReducer;