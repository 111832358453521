/** @format */

import React from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import DetailsChat from "./DetailsChat";
import { useState, useEffect } from "react";
import { IssueDetailsApi } from "../../../redux/actions/Issue";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import moment from "moment";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const ViewDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [issueDetails, setIssueDetails] = useState();
  const [mediaDetails, setmediaDetails] = useState();
  const [loader, setLoader] = useState(true);

  const issueDetailData = useSelector(
    (state) =>
      state.issue &&
      state.issue.details &&
      state.issue.details.data &&
      state.issue.details.data.data
  );

  const issueDetail = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state?._id;
    const data = { url: BASE_URL + `issue/${userId}?company_id=${_id}` };
    dispatch(IssueDetailsApi(data));
  };

  useEffect(() => {
    issueDetail();
  }, []);

  useEffect(() => {
    // setLoader(true);
    setIssueDetails(issueDetailData);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [issueDetailData]);

  //MeidaDetials Code Here
  const openFile = (fileData) => {
    const { url } = fileData;
    window.open(url, "_blank");
  };
  const mediaDetailData = useSelector(
    (state) =>
      state.issue &&
      state.issue.details &&
      state.issue.details.data &&
      state.issue.details.data.data
  );

  const mediaDetail = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state?._id;
    const data = { url: BASE_URL + `issue/${userId}?company_id=${_id}` };
    dispatch(IssueDetailsApi(data));
  };

  useEffect(() => {
    mediaDetail();
  }, []);

  useEffect(() => {
    // setLoader(true);
    setmediaDetails(mediaDetailData);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [mediaDetailData]);
  //MediaDetials Code End
  return (
    <>
      <Box>
        <Box className="header-card">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/issues/list" className="submenu-item">
                  <Button className="header-add cancle-hover">
                    <DoneIcon sx={{ mr: 1 }} />
                    Done
                  </Button>
                </Link>
                <div style={{ marginTop: "9px", marginRight: "10px" }}>
                  <Tooltip
                    title={
                      <div style={{ maxWidth: "300px" }}>
                        <strong>Advance Checklist Wise Report:</strong>
                        <p>
                          Status: Display's selected checklist completion
                          status, completion percentage & average compliance
                          scores for the selected user and stores.
                        </p>
                        <p>
                          Percentage: Display's selected checklist completion
                          percentage & average compliance scores for the
                          selected user and stores.
                        </p>
                      </div>
                    }
                    arrow
                    interactive
                  >
                    <InfoIcon
                      style={{
                        verticalAlign: "middle",
                        cursor: "pointer",
                        color: "#D76C00",
                      }}
                    />
                  </Tooltip>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
            <Grid>
              <Box sx={{ mb: 1, mt: 1 }}>
                <Stack direction="row">
                  <Typography
                    className={
                      issueDetails?.severity === "High"
                        ? "Priority bg"
                        : issueDetails?.severity === "Medium"
                        ? "Medium bg"
                        : issueDetails?.severity === "Low"
                        ? "low bg"
                        : issueDetails?.severity === "Non-Critical"
                        ? "NonCritical bg"
                        : issueDetails?.severity === "Critical"
                        ? "Critical bg"
                        : ""
                    }
                  >
                    Priority: {issueDetails?.severity}
                  </Typography>
                  <Typography
                    className={
                      issueDetails?.issueStatus === "Open" ||
                      issueDetails?.issueStatus === "open"
                        ? "Open bg"
                        : issueDetails?.issueStatus === "In-progress"
                        ? "InProgress bg"
                        : issueDetails?.issueStatus === "resolved" ||
                          issueDetails?.issueStatus === "Resolved"
                        ? "Resolved bg"
                        : issueDetails?.issueStatus === "closed" ||
                          issueDetails?.issueStatus === "Closed"
                        ? "Closed bg"
                        : ""
                    }
                  >
                    Status: {issueDetails?.issueStatus}
                  </Typography>
                </Stack>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card sx={{ p: 2, height: "100%" }} className="card">
                    <Table className="priroty-table">
                      <TableBody>
                        <TableRow>
                          {/* <TableCell className="header-table">ID:</TableCell>
                        <TableCell>
                          <b>{issueDetails?._id}</b>
                        </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell className="header-table">Token:</TableCell>
                          <TableCell>
                            <b>
                              {issueDetails?.tokenNumber
                                ? issueDetails?.tokenNumber
                                : "NA"}
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="header-table">
                            Created by:
                          </TableCell>
                          <TableCell>
                            <b>{issueDetails?.loggedBy?.name}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="header-table">
                            Created at:
                          </TableCell>
                          <TableCell>
                            <b>
                              {moment(issueDetails?.createdAt).format(
                                "MMMM D, YYYY, LTS"
                              )}
                            </b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ display: "flex", width: "100%" }}
                >
                  <Card
                    sx={{ p: 2, height: "100%", width: "100%" }}
                    className="card"
                  >
                    <Table className="priroty-table">
                      {issueDetails && issueDetails.departments ? (
                        issueDetails.departments.map((item, index) => {
                          return (
                            <TableBody key={index}>
                              <TableRow>
                                <TableCell className="header-table">
                                  Department:
                                </TableCell>
                                <TableCell>
                                  <b>{item?.deptName}</b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Manager:
                                </TableCell>
                                <TableCell>
                                  <b>{item?.deptManager}</b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Mobile:
                                </TableCell>
                                <TableCell>
                                  <b>{item?.managerMobileNumber}</b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Email:
                                </TableCell>
                                <TableCell>
                                  <b>{item?.managerEmail}</b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })
                      ) : (
                        <b>No Data Found</b>
                      )}
                    </Table>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card sx={{ p: 2, height: "100%" }} className="card">
                    <Table className="priroty-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="header-table">
                            Category:
                          </TableCell>
                          <TableCell>
                            <b>{issueDetails?.category}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="header-table">
                            Sub-Category:
                          </TableCell>
                          <TableCell>
                            <b>{issueDetails?.subCategory}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="header-table">Store:</TableCell>
                          <TableCell>
                            <b>{issueDetails?.store?.title}</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Card sx={{ p: 1, height: "100%" }} className="card">
                    <Table className="priroty-table">
                      <TableBody>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            Observation:
                          </TableCell>
                          <TableCell>
                            <b>{issueDetails?.observation}</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card sx={{ p: 1, height: "100%" }} className="card">
                    <Table className="priroty-table">
                      <TableBody>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            Attachment:
                          </TableCell>
                          <TableCell>
                            {issueDetails &&
                            issueDetails?.attachment &&
                            issueDetails?.attachment?.url ? (
                              <img
                                src={issueDetails?.attachment?.url}
                                alt="attachment"
                                zoom={true}
                                width="100"
                                height="100"
                                style={{ marginRight: "10px" }}
                              />
                            ) : (
                              <b>No Attachment</b>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card sx={{ p: 1 }} className="card">
                    <Table className="priroty-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="header-table">
                            Media Files:
                          </TableCell>
                          <TableCell>
                            {mediaDetails.mediaFiles &&
                            mediaDetails.mediaFiles.length ? (
                              mediaDetails.mediaFiles.map((mFiles, keys) => (
                                <Tooltip title={mFiles.name}>
                                  <Button
                                    variant="outlined"
                                    className="attach-button cancle-hover"
                                    sx={{
                                      marginLeft: "5px",
                                      marginRight: "5px",
                                      marginBottom: "2px",
                                      padding: "1px",
                                    }}
                                    onClick={() => openFile(mFiles)}
                                  >
                                    File {keys + 1}
                                  </Button>
                                </Tooltip>
                              ))
                            ) : (
                              <Typography variant="p" component="b">
                                No Media Files
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      {/* static data is was showing in DetailsChat component so commenting it as of now */}
      {/* <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px", mt: 1 }}>
        <DetailsChat />
      </Box> */}
    </>
  );
};

export default ViewDetails;
