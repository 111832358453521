import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  Grid,
  Select,
  MenuItem,
  FormControl,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { MapRolesApi } from "../../redux/actions/Roles";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const MappingModal = (props) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 300,
    },
    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  }));

  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const selectRole = props?.mapValue;
  const selectListData = props?.roleData;
  const userRoleId = props?.mapValue?._id;

  useEffect(() => {
    if (selectRole) {
      if (
        props?.mapValue &&
        props?.mapValue?.role_map &&
        props?.mapValue?.role_map?.MapId
      ) {
        setSelected(props?.mapValue?.role_map?.MapId);
      }
    }
    if (selectListData) {
      let arr = [];
      for (let item of selectListData) {
        arr.push(item?.name);
      }
      setMapSelectData(arr);
    }
    // setMapSelectData(selectListData)

    // eslint-disable-next-line
  }, [selectRole, selectListData]);

  const isAllSelected =
    mapSelectData?.length > 0 && selected.length === mapSelectData?.length;

  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   if (value[value.length - 1] === "all") {
  //     setSelected(
  //       selected.length === mapSelectData?.length ? [] : mapSelectData
  //     );
  //     return;
  //   }
  //   setSelected(value);
  // };
  const handleChange = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(
        selected.length === mapSelectData?.length ? [] : mapSelectData
      );
      return;
    }
    setSelected(value);
  };
  const hendleCancle = () => {
    setSelected([]);
    props.handleClose();
  };

  const handleMapping = () => {
    const id = localStorage.getItem("companyId");

    const formData = {
      MapId: selected,
    };
    const allMapedData = {
      body: formData,
    };
    allMapedData.url = BASE_URL + `Maprole/${userRoleId}?company_id=${id}`;
    dispatch(MapRolesApi(allMapedData));
    props.handleClose();
    // dispatch(ListRolesApi());
  };
  return (
    <div>
      <BootstrapDialog
        onClose={hendleCancle}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={hendleCancle}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Role Mapping
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ mb: 2 }} fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <label className="rolemodel-lebel">
                  <b>Select Sub Role</b>
                </label>
                <Select
                  className="multiselect-map"
                  multiple
                  value={selected}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={{
                    disableScrollLock: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "300px",
                      },
                    },
                  }}
                >
                  <MenuItem
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                    value="all"
                    name="all"
                    data-button-all
                    className="select-item"
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{
                          indeterminate: classes.indeterminateColor,
                        }}
                        checked={
                          mapSelectData?.length > 0 &&
                          selected.length === mapSelectData?.length
                        }
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < mapSelectData?.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {mapSelectData &&
                    mapSelectData.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option}
                        name={option}
                        className="select-item"
                      >
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="issue-modal-savebtn"
            autoFocus
            type="submit"
            onClick={handleMapping}
            color="success"
          >
            Save
          </Button>
          <Button
            variant="contained"
            className="issue-modal-cancelbtn"
            onClick={hendleCancle}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default MappingModal;
