import {
  SUBROLE_FORM_REQUEST,
  SUBROLE_FORM_SUCCESS,
  SUBROLE_FORM_ERROR,
} from "../types/Types";

const initialState = {
  error: "",
  subrloeSelectedData: "",
};

const subroleSelecetedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBROLE_FORM_REQUEST: {
      return {
        ...state,
        subrloeSelectedData: action?.payload,
        error: "",
      };
    }
    case SUBROLE_FORM_SUCCESS: {
      return {
        ...state,
        subrloeSelectedData: action?.payload,
      };
    }
    case SUBROLE_FORM_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default subroleSelecetedReducer;
