/** @format */

import { Card, Typography } from "@mui/material";
import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ViewHeader = () => {
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <>
      <Card
        className="header-card"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{ fontSize: "20px", color: "#333" }}
        >
          <ArrowBackIcon sx={{ fontSize: "24px" }} />
          <Typography variant="button" sx={{ marginLeft: "8px" }}>
            Back
          </Typography>
        </IconButton>
        <Typography
          variant="h5"
          component="h1"
          align="left"
          sx={{
            marginLeft: "1%",
            fontWeight: "bold",
            letterSpacing: "0.03em",
          }}
        >
          Broadcast Messages Details
        </Typography>
      </Card>
    </>
  );
};

export default ViewHeader;
