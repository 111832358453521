/** @format */

import React from "react";
import {
  ATTENDENCE_CONFIGLIST_ERROR,
  ATTENDENCE_CONFIGLIST_REQUEST,
  ATTENDENCE_CONFIGLIST_SUCCESS,
  ATTENDENCE_CREATE_ERROR,
  ATTENDENCE_CREATE_REQUEST,
  ATTENDENCE_CREATE_SUCCESS,
  ATTENDENCE_DELETE_ERROR,
  ATTENDENCE_DELETE_REQUEST,
  ATTENDENCE_DELETE_SUCCESS,
  ATTENDENCE_EDIT_ERROR,
  ATTENDENCE_EDIT_REQUEST,
  ATTENDENCE_EDIT_SUCCESS,
  ATTENDENCE_EXPORT_ERROR,
  ATTENDENCE_EXPORT_REQUEST,
  ATTENDENCE_EXPORT_SUCCESS,
  ATTENDENCE_SUBMISSIONLIST_ERROR,
  ATTENDENCE_SUBMISSIONLIST_REQUEST,
  ATTENDENCE_SUBMISSIONLIST_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  createData: [],
  configList: [],
  submissionList: [],
  submissionListExport: [],
  deleteAttendance: [],
  editLog: [],
};

const AttendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTENDENCE_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ATTENDENCE_CREATE_SUCCESS: {
      return {
        ...state,
        createData: action?.payload,
        loading: false,
      };
    }
    case ATTENDENCE_CREATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ATTENDENCE_CONFIGLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ATTENDENCE_CONFIGLIST_SUCCESS: {
      return {
        ...state,
        configList: action?.payload,
        error: "",
        loading: false,
      };
    }
    case ATTENDENCE_CONFIGLIST_ERROR: {
      return {
        ...state,
        loading: false,
        configList: [],
        error: action.error,
      };
    }
    case ATTENDENCE_SUBMISSIONLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ATTENDENCE_SUBMISSIONLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        submissionList: action?.payload,
      };
    }
    case ATTENDENCE_SUBMISSIONLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    //
    case ATTENDENCE_EXPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ATTENDENCE_EXPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        submissionListExport: action?.payload,
      };
    }
    case ATTENDENCE_EXPORT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }


    case ATTENDENCE_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ATTENDENCE_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        deleteAttendance: action?.payload,
      };
    }
    case ATTENDENCE_DELETE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ATTENDENCE_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ATTENDENCE_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        editLog: action?.payload,
      };
    }
    case ATTENDENCE_EDIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default AttendanceReducer;
