/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Button,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { Container } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Fab from "@material-ui/core/Fab";
import Blank from "../../../images/Blank.png";
import { useFormik } from "formik";
import * as yup from "yup";
import Country from "../../../Authentication/SighUp/StepsForm/Country";
import { useDispatch, useSelector } from "react-redux";
import {
  UserSubRoleApi,
  UserStoreApi,
  UpdateUserApi,
  ImageAddApi,
  ImageDocAddApi,
  AddDefaultDocImage,
  DeletDocImage,
} from "../../../redux/actions/User";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import AadharNumber from "./AadharNumber";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const EditUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const userID = localStorage.getItem("userEditData");
  const userIdx = JSON.parse(userID);

  const [images, setImages] = useState({ preview: "", raw: "" });
  const [loader, setLoader] = useState(false);
  const [subrole, setSubrole] = useState([]);
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const [aadharNumber, setAadharNumber] = React.useState(
    (userIdx &&
      userIdx?.identity_proof?.length &&
      userIdx?.identity_proof?.[0].maskedAadharNumber) ||
      ""
  );
  const [selectedError, setSelectedError] = useState(false);
  const [confirmAadharNumber, setConfirmAadharNumber] = React.useState(
    (userIdx &&
      userIdx?.identity_proof?.length &&
      userIdx?.identity_proof?.[0].maskedAadharNumber) ||
      ""
  );
  const [idProofImages, setIdProofImages] = useState([]);
  const [aadharNumberError, setAadharNumberError] = React.useState("");
  const [confirmAadharNumberError, setConfirmAadharNumberError] =
    React.useState("");
  const [aadharImgError, setAadharImgError] = React.useState("");
  const [aadharImgEdited, setAadharImgEdited] = React.useState(false);

  const isAllSelected =
    mapSelectData?.length > 0 && selected?.length === mapSelectData?.length;

  const phoneExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const payloadStore = {
    body: {
      per_page: 2000000,
      page: 1,
      sort_as: "desc",
      sort_by: "title",
      approved: true,
    },
  };

  const storeDataList = useSelector(
    (state) =>
      state.user &&
      state.user.store &&
      state.user.store.data &&
      state.user.store.data.data &&
      state.user.store.data.data.stores
  );

  const userError = useSelector(
    (state) => state.user && state.user.proofImgError
  );

  const imagegetid = useSelector(
    (state) => state.user && state.user.imgId && state.user.imgId.data
  );

  const proofImg = useSelector((state) => state.user && state.user.proofImg);

  const errorImg = useSelector(
    (state) => state.user && state.user.error && state.user.error.data
  );

  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );
  const updateData = useSelector(
    (state) => state.user && state.user.updateUser && state.user.updateUser.data
  );

  useEffect(() => {
    if (
      userError[0]?.front_image ||
      userError[0]?.back_image ||
      userError[0]?.qr_code
    ) {
      setLoader(false);
    }
  }, [userError]);

  console.log("userError", userError);

  useEffect(() => {
    if (userIdx) {
      dispatch(AddDefaultDocImage(userIdx?.identity_proof || []));
      setIdProofImages(userIdx?.identity_proof || []);
    }
  }, []);

  useEffect(() => {
    if (updateData && updateData.error === false) {
      toast.success(updateData.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/user-list");
      }, 2000);
    }

    if (errorImg && errorImg.status !== 200) {
      // setTimeout(() => {
      //   navigate("/user-list");
      // }, 3000);
    }
  }, [updateData, errorImg]);

  //store lisiting api
  useEffect(() => {
    const id = localStorage.getItem("companyId");
    payloadStore.url = BASE_URL + `stores/index?company_id=${id}`;
    dispatch(UserStoreApi(payloadStore));
  }, []);

  //subrole lisiting api
  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
  }, []);

  //get image data
  useEffect(() => {
    if (imagegetid && imagegetid.data && imagegetid.data.id) {
      editAvatar(formik.values, imagegetid?.data?.id);
    }
  }, [imagegetid]);

  useEffect(() => {
    if (storeDataList) {
      let arr = [];
      for (let item of storeDataList) {
        arr.push({
          _id: item?._id,
          title: item?.name,
        });
      }
      setMapSelectData(arr);
    }
  }, [storeDataList]);

  useEffect(() => {
    if (subRoleList) {
      let subrols = [];
      for (let item of subRoleList) {
        let obj = item?.name;
        subrols.push(obj);
      }
      setSubrole(subrols);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (userIdx && mapSelectData) {
      if (userIdx && userIdx.store) {
        setSelected(
          mapSelectData.filter((store) =>
            userIdx.store.some((obj) => obj._id === store._id)
          )
        );
      }
    }
  }, [mapSelectData]);

  useEffect(() => {
    if (aadharNumberError === "Aadhaar number should contain 12 character") {
      if (aadharNumber.length === 12) {
        setAadharNumberError("");
      }
    }
    if (aadharNumberError === "Aadhaar number is mandatory to enter") {
      if (aadharNumber.length) {
        setAadharNumberError("");
      }
    }
    if (
      confirmAadharNumberError ===
      "Confirm Aadhaar number is mandatory to enter"
    ) {
      if (confirmAadharNumber.length) {
        setConfirmAadharNumberError("");
      }
    }
    if (
      confirmAadharNumberError ===
      "Cofirm Aadhaar number should contain 12 character"
    ) {
      if (confirmAadharNumber.length === 12) {
        setConfirmAadharNumberError("");
      }
    }
    if (
      confirmAadharNumberError ===
      "Aadhaar number and Confirm Aadhaar number should not be mismatch"
    ) {
      if (confirmAadharNumber === aadharNumber) {
        setConfirmAadharNumberError("");
      }
    }
  }, [aadharNumber, confirmAadharNumber]);

  const handleChangeStore = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(
        selected.length === mapSelectData?.length ? [] : mapSelectData
      );
      return;
    }
    setSelected(value);
  };

  const handleBlurStore = () => {
    if (!selected.length) {
      setSelectedError(true);
    } else {
      setSelectedError(false);
    }
  };

  // const handleChangeStore = (event) => {
  //   const value = event.target.value.filter((val) => !!val);
  //   const isClickAll = value[value.length - 1] === "all";
  //   if (isClickAll) {
  //     setSelected(
  //       selected.length === mapSelectData?.length ? [] : mapSelectData
  //     );
  //     return;
  //   }
  //   let fIndex =
  //     selected &&
  //     selected?.length > 0 &&
  //     selected.findIndex(
  //       (item) =>
  //         item._id === event.target.value[event.target.value.length - 1]._id
  //     );
  //   if (fIndex !== -1) {
  //     let selVal =
  //       selected && selected?.length > 0 && selected.slice(fIndex, 1);
  //     setSelected(selected);
  //   } else {
  //     setSelected(value);
  //   }
  // };

  // select image code
  const handleImageUpload = (e) => {
    if (e.target.files.length) {
      var reader = new FileReader();
      var file = e.target.files[0];

      reader.onload = function (upload) {
        setImages({
          preview: upload.target.result,
          raw: e.target.files[0],
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const usernameRegex = /^[a-zA-Z0-9\s]+$/;
  //form validations
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required("First Name is required")
      .max(50, "Max length must be of 50 characters")
      .matches(usernameRegex, "Special characters not allowed for first name"),
    lastName: yup.string().required("Last Name is required"),
    contact: yup
      .string()
      .required("Phone number is required")
      .matches(phoneExp, "Enter a valid phone number")
      .max(10, "Phone number must be of 10 digit")
      .min(10, "Phone number must be of 10 digit")
      .nullable(),
    country_code: yup.string().required("Country Code is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email must be a valid email address."),
  });
  console.log("userIdx", userIdx);
  console.log("proofImg", proofImg);

  useEffect(() => {
    if (formik.values.idproof === "aadhar_card" && aadharImgEdited) {
      if (
        proofImg &&
        proofImg[0]?.back_image &&
        proofImg[0]?.front_image &&
        proofImg[0]?.qr_code
      ) {
        editAvatar(formik.values, imagegetid?.data?.id || "");
      }
    }
  }, [proofImg]);

  const handleValidation = () => {
    if (!selected.length) {
      setSelectedError(true);
    }
    if (formik.values.idproof === "aadhar_card") {
      if (aadharNumber == "") {
        setAadharNumberError("Aadhaar number is mandatory to enter");
      }
      if (confirmAadharNumber == "") {
        setConfirmAadharNumberError(
          "Confirm Aadhaar number is mandatory to enter"
        );
      }
      if (aadharNumber && aadharNumber.length !== 12) {
        setAadharNumberError("Aadhaar number should contain 12 character");
      }
      if (confirmAadharNumber && confirmAadharNumber.length !== 12) {
        setConfirmAadharNumberError(
          "Cofirm Aadhaar number should contain 12 character"
        );
      }
      if (
        aadharNumber &&
        confirmAadharNumber &&
        aadharNumber !== confirmAadharNumber
      ) {
        setConfirmAadharNumberError(
          "Aadhaar number and Confirm Aadhaar number should not be mismatch"
        );
      }
      if (
        !idProofImages ||
        !idProofImages[0]?.back_image ||
        !idProofImages[0]?.front_image ||
        !idProofImages[0]?.qr_code
      ) {
        setAadharImgError("Please attach required Aadhar images");
      }
    }
  };
  console.log("eeeeeeeeee", userIdx);
  const formik = useFormik({
    initialValues: {
      firstName: userIdx && userIdx?.first_name,
      lastName: userIdx && userIdx?.last_name,
      country_code: userIdx && userIdx?.country_code,
      contact: userIdx && userIdx?.contact,
      email: userIdx && userIdx?.email,
      country: userIdx && userIdx?.country,
      city: userIdx && userIdx?.city,
      zipCode: userIdx && userIdx?.zip_code,
      address: userIdx && userIdx?.city,
      store: selected && selected,
      role: (userIdx?.role + "s").replace(/s+$/, "s"),
      // role: userIdx?.role?.title,
      subRole: userIdx && userIdx?.subRole,
      idproof:
        userIdx &&
        userIdx?.identity_proof?.length &&
        userIdx?.identity_proof?.[0].type,
      notifyUser: true,
    } || {
      firstName: "",
      lastName: "",
      country_code: "",
      store: selected,
      contact: "",
      email: "",
      country: "",
      city: "",
      zipCode: "",
      address: "",
      role: "",
      subRole: "",
      idproof: "",
      notifyUser: true,
      approved: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const imagefile = {
        file: images.preview,
        name: images.raw.name,
      };
      const imageData = {
        body: imagefile,
      };
      if (imageData.body.file != "") {
        imageData.url = BASE_URL + `uploads/base64`;
        dispatch(ImageAddApi(imageData));
      } else {
        let imgId = "";
        handleClickEdit(values, imgId);
        // editAvatar(values, imgId);
      }
    },
  });

  const editAvatar = (values, image) => {
    const formData = {
      city: values.city,
      contact: values.contact,
      country: values.country,
      country_code: values.country_code,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      store: selected && selected,
      street: values.address,
      subRole: values.subRole,
      userType: values.role,
      zip_code: values.zipCode,
      notifyUser: values.notifyUser,
      approved: true,
      identity_proof: userIdx?.identity_proof,
    };
    if (image) {
      formData.avatar = image;
    }
    let aadharError = false;

    if (formik.values.idproof === "aadhar_card") {
      var proofObj = {
        ...proofImg[0],
      };

      if (aadharNumber.includes("*")) {
        if (userIdx?.identity_proof?.[0]?.maskedAadharNumber !== aadharNumber) {
          toast.error("Please enter valid Aadhaar number", {
            autoClose: 3000,
          });
          aadharError = true;
          setLoader(false);
          return;
        } else {
          proofObj.aadhar_number = proofImg[0].maskedAadharNumber;
        }
      } else {
        proofObj.aadhar_number = btoa(aadharNumber);
      }
      formData.identity_proof = [proofObj];
    }

    const allData = {
      body: formData,
    };

    if (formData.store == "") {
      toast.error("Please select store before submitting", { autoClose: 3000 });
      setSelectedError(true);
      setLoader(false);
      return;
    }
    if (formData.contact == "" && formData.email == "") {
      toast.error("Please enter either email or contact before submitting", {
        autoClose: 3000,
      });
      setLoader(false);
      return;
    }
    if (formData.contact != "") {
      if (formData.country_code == "") {
        toast.error("Please select country code before submitting.", {
          autoClose: 3000,
        });
        setLoader(false);
        return;
      }
    }

    if (
      selected != "" &&
      !aadharError &&
      allData &&
      ((formData.contact != "" && formData.country_code != "") ||
        formData.email != "")
    ) {
      const usersID = userIdx && userIdx._id;
      const id = localStorage.getItem("companyId");
      allData.url = BASE_URL + `users/${usersID}?company_id=${id}`;
      dispatch(UpdateUserApi(allData));
      setTimeout(() => {
        setLoader(false);
      }, [2000]);
    } else {
      setLoader(false);
    }
  };

  const uploadImgApi = (key, name, file) => {
    const data = {};
    const uploadProfPayload = {
      file: file,
      name: name,
      isUnique: true,
    };
    data.body = uploadProfPayload;
    data.url = BASE_URL + `uploads/base64`;
    dispatch(ImageDocAddApi(data, key, formik.values.idproof));
  };

  const validateAndUpload = () => {
    var aadharError = false;
    if (aadharNumber == "") {
      toast.error("Aadhaar number is mandatory to enter", {
        autoClose: 3000,
      });
      aadharError = true;
      setAadharNumberError("Aadhaar number is mandatory to enter");
    }
    if (confirmAadharNumber == "") {
      toast.error("Confirm Aadhaar number is mandatory to enter", {
        autoClose: 3000,
      });
      setConfirmAadharNumberError(
        "Confirm Aadhaar number is mandatory to enter"
      );
      aadharError = true;
    }
    if (aadharNumber && aadharNumber.length !== 12) {
      toast.error("Aadhaar number should contain 12 character", {
        autoClose: 3000,
      });
      setAadharNumberError("Aadhaar number should contain 12 character");
      aadharError = true;
    }
    if (confirmAadharNumber && confirmAadharNumber.length !== 12) {
      toast.error("Cofirm Aadhaar number should contain 12 character", {
        autoClose: 3000,
      });
      setConfirmAadharNumberError(
        "Cofirm Aadhaar number should contain 12 character"
      );
      aadharError = true;
    }
    if (
      aadharNumber &&
      confirmAadharNumber &&
      aadharNumber !== confirmAadharNumber
    ) {
      toast.error(
        "Aadhaar number and Confirm Aadhaar number should not be mismatch",
        {
          autoClose: 3000,
        }
      );
      setConfirmAadharNumberError(
        "Aadhaar number and Confirm Aadhaar number should not be mismatch"
      );
      aadharError = true;
    }
    if (
      !idProofImages ||
      !idProofImages[0]?.back_image ||
      !idProofImages[0]?.front_image ||
      !idProofImages[0]?.qr_code
    ) {
      toast.error("Please attach required Aadhar images", {
        autoClose: 3000,
      });
      setAadharImgError("Please attach required Aadhar images");
      aadharError = true;
    }

    if (aadharError) {
      setLoader(false);
      return;
    }

    if (
      proofImg &&
      proofImg[0] &&
      proofImg[0].back_image &&
      proofImg[0].front_image &&
      proofImg[0].qr_code
    ) {
      editAvatar(formik.values, imagegetid?.data?.id);
    }

    Promise.all([
      Object.keys(idProofImages[0]).map((item) => {
        if (item !== "type") {
          if (!(proofImg && proofImg[0] && proofImg[0][item])) {
            const i = idProofImages[0][item];
            uploadImgApi(i.key, i.name, i.url);
          }
        }
      }),
    ]);
  };

  console.log("idProofImages", idProofImages);

  const handleClickEdit = (values, imgKey) => {
    confirmAlert({
      title: "Confirm Edit",
      message:
        "Changes in roles, subroles, and stores might impact previous submissions graphs, counts and reports. Latest changes will be applied in graphs, counts and reports.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoader(true);
            if (formik.values.idproof === "aadhar_card") {
              validateAndUpload();
            } else {
              editAvatar(values, imgKey);
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleIdUpload = (e, key) => {
    if (e.target.files.length) {
      var reader = new FileReader();
      var file = e.target.files[0];

      reader.onload = function (upload) {
        const target = [...idProofImages];
        if (target.length) {
          const modifiedProofImagesIndex = target.findIndex(
            (item) => item.type === formik.values.idproof
          );

          target[modifiedProofImagesIndex] = {
            ...target[modifiedProofImagesIndex],
            type: formik.values.idproof,
            [key]: {
              url: upload.target.result,
              key: key,
              name: e.target.files[0]?.name,
            },
          };

          if (
            target[modifiedProofImagesIndex].back_image ||
            target[modifiedProofImagesIndex].front_image ||
            target[modifiedProofImagesIndex].qr_code
          ) {
            setAadharImgError("");
          }

          setIdProofImages(target);
        } else {
          const obj = {
            type: formik.values.idproof,
            [key]: {
              url: upload.target.result,
              key: key,
              name: e.target.files[0]?.name,
            },
          };

          setIdProofImages([obj]);
        }
        // const data = {};
        // const uploadProfPayload = {
        //   file: upload.target.result,
        //   name: e.target.files[0]?.name,
        // };
        // data.body = uploadProfPayload;
        // data.url = BASE_URL + `uploads/base64`;
        // dispatch(ImageDocAddApi(data, key, formik.values.idproof));
      };
      setAadharImgEdited(true);
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (key) => {
    const modifiedProof = JSON.parse(JSON.stringify(idProofImages));
    delete modifiedProof[0][key];
    setAadharImgEdited(true);
    setIdProofImages(modifiedProof);

    if (proofImg[0]) {
      const modifiedProofImg = JSON.parse(JSON.stringify(proofImg));
      if (modifiedProofImg[0][key]) {
        delete modifiedProofImg[0][key];
      }

      dispatch(DeletDocImage(modifiedProofImg));
    }
  };

  return (
    <Box>
      <ToastContainer />
      {loader && <ProgressbarTransparent play />}
      <form
        onSubmit={(values) => {
          handleValidation();
          formik.handleSubmit(values);
        }}
      >
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/user-list" className="submenu-item">
                  <Button
                    className="cancle-button cancle-hover bg"
                    onClick={() => dispatch(AddDefaultDocImage([]))}
                  >
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Confirm
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Edit User Image</label>
              <Box className="attachment-file">
                <input
                  name="image"
                  accept="image/*"
                  className="file-upload"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" className="store-file cancle-hover">
                    <AddOutlinedIcon />
                  </Fab>
                </label>
                <Box>
                  {images.preview ? (
                    <img
                      className="file-preview"
                      src={images.preview}
                      alt="attachment"
                    />
                  ) : (
                    <Box>
                      {userIdx?.avatar ? (
                        <img
                          className="file-preview"
                          src={userIdx?.avatar}
                          alt="attachment"
                        />
                      ) : (
                        <img
                          className="file-preview"
                          src={Blank}
                          alt="attachment"
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>First Name</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                className="issueinput-fields"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              />
              <small className="error">
                {formik.errors.firstName && formik.touched.firstName
                  ? formik.errors.firstName
                  : null}
              </small>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Last Name</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="issueinput-fields"
                size="small"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              />
              <small className="error">
                {formik.errors.lastName && formik.touched.lastName
                  ? formik.errors.lastName
                  : null}
              </small>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Contact</label>
              <Grid container spacing={1} className="display">
                <Grid item md={3} xs={12} sm={12} lg={3}>
                  <Select
                    size="small"
                    fullWidth
                    sx={{ borderRadius: "8px" }}
                    name="country_code"
                    onChange={formik.handleChange}
                    value={formik.values.country_code}
                    error={
                      formik.touched.country_code &&
                      Boolean(formik.errors.country_code)
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "300px",
                          width: "300px",
                        },
                      },
                    }}
                  >
                    {Country?.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option.mobileCode}
                          className="select-item"
                        >
                          {option.name ?? option.mobileCode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item md={9} xs={12} sm={12} lg={9}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="issueinput-fields"
                    name="contact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact}
                    error={
                      formik.touched.contact && Boolean(formik.errors.contact)
                    }
                  />
                  <small className="error">
                    {formik.errors.contact && formik.touched.contact
                      ? formik.errors.contact
                      : null}
                  </small>
                </Grid>
              </Grid>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Email</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="issueinput-fields"
                size="small"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <small className="error">
                {formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null}
              </small>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Country</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                className="issueinput-fields"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>City</label>
              <TextField
                id="outlined-basic"
                className="issueinput-fields"
                variant="outlined"
                size="small"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Zip Code</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="issueinput-fields"
                size="small"
                name="zipCode"
                onChange={formik.handleChange}
                value={formik.values.zipCode}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Address</label>
              <TextField
                id="outlined-basic"
                className="issueinput-fields"
                variant="outlined"
                size="small"
                multiline
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>ID proof</label>
              <Select
                size="small"
                name="idproof"
                sx={{
                  borderRadius: "8px",
                  marginBottom:
                    formik?.values?.idproof === "aadhar_card" ? "12px" : "",
                }}
                onChange={formik.handleChange}
                value={formik.values.idproof}
                error={formik.touched.idproof && Boolean(formik.errors.idproof)}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="aadhar_card" className="select-item">
                  Aadhaar Card
                </MenuItem>
              </Select>
            </FormControl>
            {formik?.values?.idproof === "aadhar_card" ? (
              <Box
                sx={{
                  border: "1px solid #cbd4e1 !important",
                  borderRadius: "8px !important",
                  padding: "8px",
                  marginBottom: "16px",
                }}
              >
                {formik.values.idproof === "aadhar_card" && (
                  <>
                    <Box fullWidth sx={{ mb: 2 }}>
                      <label>Aadhaar Number</label>
                      <AadharNumber
                        separator={<span></span>}
                        value={aadharNumber}
                        onChange={setAadharNumber}
                        length={12}
                      />
                      <small className="error">
                        {aadharNumberError ? aadharNumberError : null}
                      </small>
                    </Box>
                    <Box fullWidth sx={{ mb: 2 }}>
                      <label>Confirm Aadhaar Number</label>
                      <AadharNumber
                        separator={<span></span>}
                        value={confirmAadharNumber}
                        onChange={setConfirmAadharNumber}
                        length={12}
                      />
                      <small className="error">
                        {confirmAadharNumber &&
                        aadharNumber !== confirmAadharNumber
                          ? "Aadhaar number and Confirm Aadhaar number should not be mismatch"
                          : confirmAadharNumberError
                          ? confirmAadharNumberError
                          : ""}
                      </small>
                    </Box>
                  </>
                )}
                {formik.values.idproof === "aadhar_card" && (
                  <>
                    <label>Upload Aadhar Photo</label>
                    <Box
                      className="attachment-file"
                      sx={{
                        justifyContent: "space-around",
                        borderColor:
                          proofImg?.[0]?.front_image &&
                          proofImg?.[0]?.back_image &&
                          proofImg?.[0]?.qr_code
                            ? ""
                            : aadharImgError
                            ? "red !important"
                            : "",
                      }}
                    >
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Front Image of Aadhar
                          </p>
                          {idProofImages[0]?.front_image?.url && (
                            <div className="kyc-delete-container">
                              <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDeleteImage("front_image")}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          )}
                        </Box>
                        {!idProofImages[0]?.front_image?.url ? (
                          <input
                            name="id-image-1"
                            accept="image/*"
                            className="file-upload"
                            id="contained-button-proof-front"
                            type="file"
                            hidden
                            onChange={(e) => handleIdUpload(e, "front_image")}
                          />
                        ) : (
                          ""
                        )}
                        <label htmlFor="contained-button-proof-front">
                          <Box
                            style={{
                              border: userError[0]?.["front_image"]
                                ? "1px solid red"
                                : "",
                            }}
                          >
                            {/* <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Front Image of Aadhar
                            </p> */}
                            {idProofImages?.[0]?.front_image?.url ? (
                              <img
                                className="file-preview-id-proof"
                                src={idProofImages?.[0]?.front_image?.url}
                                alt="attachment"
                              />
                            ) : (
                              <img
                                className="file-preview-id-proof"
                                src={Blank}
                                alt="attachment"
                              />
                            )}
                            {/* <small className="error">
                              {userError
                                ? userError[0]?.["front_image"]
                                  ? "Invalid Front Image"
                                  : ""
                                : null}
                            </small> */}
                          </Box>
                        </label>
                      </Box>
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            Back Image of Aadhar
                          </p>
                          {idProofImages[0]?.back_image?.url && (
                            <div className="kyc-delete-container">
                              <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDeleteImage("back_image")}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          )}
                        </Box>
                        {!idProofImages[0]?.back_image?.url ? (
                          <input
                            name="id-image-2"
                            accept="image/*"
                            className="file-upload"
                            id="contained-button-proof-back"
                            type="file"
                            hidden
                            onChange={(e) => handleIdUpload(e, "back_image")}
                          />
                        ) : (
                          ""
                        )}
                        <label htmlFor="contained-button-proof-back">
                          <Box
                            style={{
                              border: userError[0]?.["back_image"]
                                ? "1px solid red"
                                : "",
                            }}
                          >
                            {/* <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Back Image of Aadhar
                            </p> */}
                            {idProofImages?.[0]?.back_image?.url ? (
                              <img
                                className="file-preview-id-proof"
                                src={idProofImages?.[0]?.back_image?.url}
                                alt="attachment"
                              />
                            ) : (
                              <img
                                className="file-preview-id-proof"
                                src={Blank}
                                alt="attachment"
                              />
                            )}
                            {/* <small className="error">
                              {userError
                                ? userError[0]?.["back_image"]
                                  ? "Invalid Back Image"
                                  : ""
                                : null}
                            </small> */}
                          </Box>
                        </label>
                      </Box>
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            QR Code of Aadhar
                          </p>
                          {idProofImages[0]?.qr_code?.url && (
                            <div className="kyc-delete-container">
                              <DeleteIcon
                                className="delete-icon"
                                onClick={() => handleDeleteImage("qr_code")}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          )}
                        </Box>
                        {!idProofImages[0]?.qr_code?.url ? (
                          <input
                            name="id-image-3"
                            accept="image/*"
                            className="file-upload"
                            id="contained-button-proof-qr"
                            type="file"
                            hidden
                            onChange={(e) => handleIdUpload(e, "qr_code")}
                          />
                        ) : (
                          ""
                        )}
                        <label htmlFor="contained-button-proof-qr">
                          <Box
                            style={{
                              border: userError[0]?.["qr_code"]
                                ? "1px solid red"
                                : "",
                            }}
                          >
                            {/* <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              QR Code Image of Aadhar
                            </p> */}
                            {idProofImages?.[0]?.qr_code?.url ? (
                              <img
                                className="file-preview-id-proof"
                                src={idProofImages?.[0]?.qr_code?.url}
                                alt="attachment"
                              />
                            ) : (
                              <img
                                className="file-preview-id-proof"
                                src={Blank}
                                alt="attachment"
                              />
                            )}
                            {/* <small className="error">
                              {userError
                                ? userError[0]?.["qr_code"]
                                  ? "Invalid Qr Code"
                                  : ""
                                : null}
                            </small> */}
                          </Box>
                        </label>
                      </Box>
                    </Box>
                    <small className="error">
                      {idProofImages?.[0]?.front_image &&
                      idProofImages?.[0]?.back_image &&
                      idProofImages?.[0]?.qr_code
                        ? ""
                        : aadharImgError
                        ? aadharImgError
                        : null}
                    </small>
                    <small className="error">
                      {userError
                        ? userError[0]?.["front_image"] ||
                          userError[0]?.["back_image"] ||
                          userError[0]?.["qr_code"]
                          ? "The image already exists. Please rename it and upload it again."
                          : null
                        : ""}
                    </small>
                  </>
                )}
              </Box>
            ) : (
              ""
            )}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Store</label>
              <Select
                labelId="mutiple-select-label"
                multiple
                name="store"
                size="small"
                value={selected}
                onChange={handleChangeStore}
                onClose={handleBlurStore}
                error={selectedError}
                sx={{ borderRadius: "8px" }}
                MenuProps={{
                  disableScrollLock: true,
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "180px",
                    },
                  },
                }}
                renderValue={(itemselected) => {
                  if (itemselected?.length === 0) {
                    return (
                      <em
                        style={{
                          color: "#b9b9b9",
                          fontFamily: "Sans-serif",
                          fontWeight: "normal",
                        }}
                      >
                        Select Training
                      </em>
                    );
                  }
                  return itemselected.map((item) => item?.title)?.join(",");
                }}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{
                        indeterminate: classes.indeterminateColor,
                      }}
                      checked={isAllSelected}
                      indeterminate={
                        selected?.length > 0 &&
                        selected?.length < mapSelectData?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {mapSelectData &&
                  mapSelectData.map((option, i) => {
                    return (
                      <MenuItem key={option?._id} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            // checked={selected.indexOf(option) > -1}
                            checked={
                              selected.findIndex(
                                (item) => item?._id == option?._id
                              ) > -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={option?.title} />
                      </MenuItem>
                    );
                  })}
              </Select>
              <small className="error">
                {selectedError ? "Store is required" : null}
              </small>
              {/* <Select
                sx={{ borderRadius: "8px" }}
                labelId="mutiple-select-label"
                multiple
                name="selectform"
                size="small"
                value={selected}
                onChange={handleChangeStore}
                MenuProps={{
                  disableScrollLock: true,
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "180px",
                      width: "180px",
                    },
                  },
                }}
                renderValue={(selected) =>
                  selected?.map((item) => item?.title)?.join(",")
                }
              >
                <MenuItem
                  className="select-item"
                  value="all"
                  classes={{
                    indeterminate: classes.indeterminateColor,
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected?.length > 0 &&
                        selected?.length < mapSelectData?.length
                      }
                      // indeterminate={
                      //   selected.length > 0 &&
                      //   selected.length < mapSelectData.length
                      // }
                      // checked={
                      //   mapSelectData?.length > 0 &&
                      //   selected.length === mapSelectData?.length
                      // }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {mapSelectData &&
                  mapSelectData.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option} className="select-item">
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              selected.findIndex(
                                (item) => item?._id === option?._id
                              ) > -1
                            }
                            // checked={
                            //   selected &&
                            //   selected?.length > 0 &&
                            //   selected?.findIndex(
                            //     (item) => item._id === option._id
                            //   ) > -1
                            // }
                            // checked={selected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.title} />
                      </MenuItem>
                    );
                  })}
              </Select> */}
            </FormControl>
            {console.log("===========>", formik.values)}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Role</label>
              <Select
                size="small"
                sx={{ borderRadius: "8px" }}
                name="role"
                onChange={formik.handleChange}
                renderValue={(selected) => selected}
                value={formik.values.role}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="directors" className="select-item">
                  Director
                </MenuItem>
                <MenuItem value="superAdmins" className="select-item">
                  Super Admin
                </MenuItem>
                <MenuItem value="admins" className="select-item">
                  Admin
                </MenuItem>
                <MenuItem value="viewers" className="select-item">
                  Viewer
                </MenuItem>
                <MenuItem value="auditors" className="select-item">
                  Auditor
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Sub-Role</label>
              <Select
                size="small"
                name="subRole"
                sx={{ borderRadius: "8px" }}
                onChange={formik.handleChange}
                value={formik.values.subRole}
                MenuProps={{
                  disableScrollLock: true,
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 1,
                      },
                      height: "200px",
                    },
                  },
                }}
                renderValue={(selected) => selected}
              >
                {subrole &&
                  subrole?.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option} className="select-item">
                        {option}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {/* <FormGroup>
              <FormControlLabel
                name="notifyUser"
                onChange={formik.handleChange}
                value={formik.values.notifyUser}
                control={<Switch defaultChecked color="success" />}
                label="Notify the user"
              />
            </FormGroup> */}
          </Container>
        </Box>
      </form>
    </Box>
  );
};

export default EditUser;
