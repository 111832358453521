/** @format */

import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import TablePagination from "@mui/material/TablePagination";
import { IconButton, Stack, TableFooter, Typography } from "@mui/material";
import TableHeader from "./tableHeader/TableHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AllChecklistListApi,
  DeleteFormListApi,
  AllChecklistListArchivedApi,
  UpdateAllchecklistSuccess,
  DeleteAllChecklistNewApi,
} from "../redux/actions/Checklist";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import * as moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import ToggleSwitch from "../../helpers/ToggleSwitch";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import RenewChecklist from "./allCheckList/RenewChecklist";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import { ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import InfoIcon from "@mui/icons-material/Info";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const AllCheckList = () => {
  const tableData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allchecklist &&
      state?.checklist?.allchecklist?.data &&
      state?.checklist?.allchecklist?.data?.data &&
      state?.checklist?.allchecklist?.data?.data?.assignments
  );

  const submissionArray = Array.from(tableData);
  const assignmentIds = submissionArray.map((submission) => submission._id);

  const archivedListDatas = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.archivedAllCheckList &&
      state?.checklist?.archivedAllCheckList?.data &&
      state?.checklist?.archivedAllCheckList?.data?.data &&
      state?.checklist?.archivedAllCheckList?.data?.data?.assignment
  );

  const archivedListDatasPaginate = useSelector(
    (state) =>
      state &&
      state &&
      state?.checklist &&
      state?.checklist?.archivedAllCheckList &&
      state?.checklist?.archivedAllCheckList?.data &&
      state?.checklist?.archivedAllCheckList?.data?.data &&
      state?.checklist?.archivedAllCheckList?.data?.data?.paginate
  );
  const totalpage = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allchecklist &&
      state?.checklist?.allchecklist?.data &&
      state?.checklist?.allchecklist?.data?.data &&
      state?.checklist?.allchecklist?.data?.data?.paginate
  );
  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  const deleteResponse = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist.deleteChecklistRes &&
      state?.checklist &&
      state?.checklist.deleteChecklistRes.data &&
      state?.checklist.deleteChecklistRes.data.data
  );

  const deleteResponseNew = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist.deleteChecklistResNew &&
      state?.checklist &&
      state?.checklist.deleteChecklistResNew.data &&
      state?.checklist.deleteChecklistResNew.data.data
  );
  // console.log("deleteResponseNew", deleteResponseNew);

  const renewChecklistRes = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.updateResponse &&
      state.checklist.updateResponse.data
  );

  const allCHecklistFilter =
    localStorage.getItem("allCHecklistFilterData") || "{}";
  const allCHecklistFilterData = JSON.parse(allCHecklistFilter);
  var pageno = localStorage.getItem("checklistPageno");
  var rowPerPage = localStorage.getItem("checklistRowPerPage");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [allChecklistData, setAllChecklistData] = useState([]);
  const [page, setPage] = useState((pageno && parseInt(pageno)) || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    (rowPerPage && parseInt(rowPerPage)) || 10
  );
  const [editvalue, setEditvalue] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    allCHecklistFilterData.status || ""
  );
  const [searchValue, setSearchValue] = useState(
    allCHecklistFilterData.search || ""
  );
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [indexHover, setIndexHover] = useState(0);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });
  const [permission, setPermission] = useState({});

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      FilterChecklist: allCHecklistFilterData.status || "",
      search: allCHecklistFilterData.search || "",
      checklistType: allCHecklistFilterData.checklistType || "",
      sort: {
        sort_as: "updatedAt",
      },
      archived: false,
      type: "all",
      frequency: allCHecklistFilterData.frequency || 0,
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  const ArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url =
      BASE_URL + `ListArchivedFormsAssignment?company_id=${id}`;
    dispatch(AllChecklistListArchivedApi(paginationRequest));
  };

  useEffect(() => {
    dispatch(UpdateAllchecklistSuccess(""));
  }, [renewChecklistRes]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(archivedListDatasPaginate?.total_item);
    } else {
      setTotalCount(totalpage?.total_item);
    }
  }, [archivedListDatasPaginate, totalpage, checkedArchive]);

  useEffect(() => {
    if (location?.state?.Pending == "Pending") {
      setSelectedValue("Due");
    }
    if (location?.state?.InProgress == "InProgress") {
      setSelectedValue("inProgrees");
    }
  }, [location]);

  // useEffect(() => {
  //   if (searchValue == "" && selectedValue != "") {
  //     paginationRequest.body.FilterChecklist = selectedValue;
  //     paginationRequest.body.search = searchValue;
  //     getApi(paginationRequest);
  //   } else if (searchValue == "" && selectedValue == "") {
  //     getApi(paginationRequest);
  //   }
  // }, [searchValue, selectedValue]);

  useEffect(() => {
    // if (selectedValue != "") {
    //   paginationRequest.body.FilterChecklist = selectedValue;
    //   paginationRequest.body.search = searchValue;
    //   getApi(paginationRequest);
    // } else {
    // }
    var localPageno = localStorage.getItem("checklistPageno");
    var localRowPerPage = localStorage.getItem("checklistRowPerPage");

    paginationRequest.body.pagination.page = localPageno
      ? parseInt(localPageno)
      : 1;
    paginationRequest.body.pagination.per_page = localRowPerPage
      ? parseInt(localRowPerPage)
      : 10;
    setLoader(true);
    getApi(paginationRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [deleteResponse, deleteResponseNew, renewChecklistRes]);

  useEffect(() => {
    if (checkedArchive) {
      setAllChecklistData(archivedListDatas);
    } else {
      if (tableData) {
        setAllChecklistData(tableData);
      }
    }
  }, [tableData, archivedListDatas, checkedArchive]);

  const handleEdit = (row) => {
    setEditvalue(row);
    setOpen(true);
    // archiveedPaginationRequest;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  const getApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `formsAssignment/admin?company_id=${id}`;
    dispatch(AllChecklistListApi(paginationRequest));
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // paginationRequest.body.FilterChecklist = event.target.value;
    // paginationRequest.body.search = searchValue;
    // if (selectedValue != "") {
    //   paginationRequest.body.FilterChecklist = selectedValue;
    //   paginationRequest.body.search = searchValue;
    // }
    // if (checkedArchive === false) {
    //   setLoader(true);
    //   getApi(paginationRequest);
    //   setTimeout(() => {
    //     setLoader(false);
    //   }, 2000);
    // } else {
    //   setLoader(true);
    //   ArchivedApi(paginationRequest);
    //   setTimeout(() => {
    //     setLoader(false);
    //   }, 2000);
    // }
  };

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message:
        "Are you sure to delete it?. If you delete it, all the submission for this checklist will be deleted.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const checklistId = values?._id;
            const firstApi = {
              url:
                BASE_URL +
                `deletesubmission?company_id=${id}&checklistId=${checklistId}`,
            };
            const secondApi = {
              url: BASE_URL + `formsAssignment/${checklistId}?company_id=${id}`,
            };
            const callBuck = () => {
              dispatch(DeleteFormListApi(secondApi));
            };
            setLoader(true);
            dispatch(DeleteAllChecklistNewApi(firstApi, callBuck));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  // const handleViewDetails = (row) => {
  //   localStorage.setItem("allchecklistData", JSON.stringify(row));
  //   let rowdata = JSON.parse(localStorage.getItem("allchecklistData"));
  //   if (rowdata) {
  //     navigate("/checklist/all-checklist/view");
  //   }
  // };

  const handleViewDetails = (row) => {
    localStorage.setItem("allchecklistData", JSON.stringify(row));
    let rowdata = JSON.parse(localStorage.getItem("allchecklistData"));
    if (rowdata) {
      navigate(`/checklist/all-checklist/view`, { state: { id: row?._id } });
    }
  };

  const handleImageGallery = (row) => {
    localStorage.setItem("checkListId", row?._id);
    localStorage.setItem("imageLogChecklistDetail", JSON.stringify(row));
    navigate("/checklist/all-checklist/images");
  };

  const handleImageGalleryInspection = (row) => {
    localStorage.setItem("checkListId", row?._id);
    localStorage.setItem("imageLogChecklistDetail", JSON.stringify(row));
    navigate("/checklist/all-checklist/imagesInspection");
  };

  const handleEditForm = (row) => {
    localStorage.setItem("allchecklistData", JSON.stringify(row));
    let rowdata = JSON.parse(localStorage.getItem("allchecklistData"));
    if (rowdata) {
      navigate("/checklist/all-checklist/edit");
    }
  };
  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (checkedArchive === false) {
        if (selectedValue !== "") {
          setLoader(true);
          paginationRequest.body.FilterChecklist = selectedValue;
          paginationRequest.body.search = searchValue;
          paginationRequest.body.pagination.page = 1;
          setPage(1);
          getApi(paginationRequest);
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        } else {
          paginationRequest.body.pagination.page = 1;
          paginationRequest.body.search = searchValue;
          getApi(paginationRequest);
          setPage(1);
        }
      } else {
        paginationRequest.body.search = searchValue;
        paginationRequest.body.pagination.page = 1;
        ArchivedApi(paginationRequest);
        setPage(1);
      }
    }
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => (
        <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
      ),
      flex: 0.7,
      maxWidth: 100,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          allChecklistData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;

        const expiresAt = params?.row?.expires_at;
        const currentDate = new Date()
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, "");
        const isExpired =
          expiresAt && parseInt(expiresAt) < parseInt(currentDate);
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minWidth: "50px",
            }}
          >
            <div
              className={
                selectedValue === "Due"
                  ? "duedata blinking"
                  : isExpired
                  ? "table-left-border-danger"
                  : "table-left-border-success"
              }
              style={{
                width: "5px",
                marginRight: "0px",
                height: "100%",
                fontSize: "12px",
              }}
              title="Green for Active checklist"
            />
            <div
              className={
                params.row.type === 1
                  ? "table-left-border-grey"
                  : "table-left-border-darkgrey"
              }
              style={{
                width: "5px",
                marginLeft: "-10px",
                height: "100%",
                fontSize: "12px",
              }}
              title="Grey for Checklist, Darkgrey for Audit"
            />
            <p
              className="text-short"
              style={{
                margin: "0 auto",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      renderHeader: () => <strong>{"Category"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      marginLeft: "-2%",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <div className="text-short3" style={{ textAlign: "left" }}>
              {results}
            </div>
          </LightTooltip>
        );
      },
    },
    // {
    //   field: "Checklist Type",
    //   headerName: "Checklist Type",
    //   renderHeader: () => <strong>{"Checklist Type"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   maxWidth: 95,
    //   headerAlign: "left",
    //   marginLeft: "-2%",
    //   renderCell: (params) => {
    //     let result = "";
    //     if (params?.row?.type === 1) {
    //       result = "Checklist";
    //     } else {
    //       result = "Audit";
    //     }

    //     return (
    //       <LightTooltip title={result}>
    //         <div className="text-short3" style={{ textAlign: "left", color: params?.row?.type === 1 ? "#F39A4C" : "green" }}>
    //           {result}
    //         </div>
    //       </LightTooltip>
    //     );
    //   },
    // },

    {
      field: "Forms",
      headerName: "Forms",
      renderHeader: () => <strong>{"Forms"}</strong>,
      maxWidth: 95,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => {
        let result = [];
        if (params?.row?.forms) {
          for (let item of params?.row?.forms) {
            result.push(item?.title);
          }
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return (
          <LightTooltip title={formData}>
            <p className="text-short" style={{ textAlign: "center" }}>
              {formData}
            </p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Assignee",
      headerName: "Assignee",
      renderHeader: () => <strong>{"Assignee"}</strong>,
      maxWidth: 100,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        let result = [];
        if (
          params?.row?.role ||
          params?.row?.assignedTo?.name ||
          params?.row?.assignedTo
        ) {
          result.push(
            params?.row?.role
              ? params?.row?.role
              : "" || params?.row?.assignedTo?.name
              ? params?.row?.assignedTo.name
              : params?.row?.assignedTo
          );
        } else {
          result = ["N/A"];
        }
        let Assignee = result.join(", ");
        return (
          <LightTooltip title={Assignee}>
            <p className="text-short">{Assignee}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Start Date",
      headerName: "Start Date",
      renderHeader: () => <strong>{"Start Date"}</strong>,
      maxWidth: 95,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.starts_at).format("ll");
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Due Date",
      headerName: "Due Date",
      renderHeader: () => <strong>{"Due Date"}</strong>,
      maxWidth: 95,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.expires_at).format("ll");
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    // {
    //   field: "Reports",
    //   headerName: "Reports",
    //   align: "center",
    //   renderHeader: () => <strong>{"Reports"}</strong>,
    //   maxWidth: 72,
    //   flex: 1,
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     const mailReport = params?.row?.mailReport;
    //     if (!mailReport || mailReport.length === 0) {
    //       return "N/A";
    //     }
    //     const firstMailReport = mailReport[0];

    //     const truncatedMailReport =
    //       firstMailReport.length > 4
    //         ? firstMailReport.substring(0, 4) + "..."
    //         : firstMailReport;

    //     return (
    //       <Tooltip title={firstMailReport}>
    //         <span style={{ cursor: "pointer" }}>{truncatedMailReport}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "Reports",
      headerName: "Reports",
      align: "center",
      renderHeader: () => <strong>{"Reports"}</strong>,
      maxWidth: 72,
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        const mailReport = params?.row?.mailReport;
        if (!mailReport || mailReport.length === 0) {
          return "N/A";
        }

        // Join the mail reports with a comma and a line break for each entry
        const formattedMailReports = mailReport
          .map((report) =>
            report.length > 8 ? report.substring(0, 8) + "..." : report
          )
          .join(", ");

        // Display the first mail report in the tooltip
        const tooltipContent = mailReport.join(", ");

        return (
          <Tooltip title={tooltipContent}>
            <span style={{ cursor: "pointer", whiteSpace: "pre-line" }}>
              {formattedMailReports}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "Frequency",
      headerName: "Frequency",
      renderHeader: () => <strong>{"Frequency"}</strong>,
      maxWidth: 92,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.frequency) {
          result.push(
            params?.row?.frequency === 3
              ? "Daily"
              : params?.row?.frequency === 2
              ? " Hourly"
              : params?.row?.frequency === 5
              ? "Monthly"
              : params?.row?.frequency === 1
              ? "No frequency"
              : params?.row?.frequency === 4
              ? "Weekly"
              : "N/A"
          );
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let count = 1;
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>
              {" "}
              {params?.row?.frequency === 3
                ? "Daily"
                : params?.row?.frequency === 2
                ? " Hourly"
                : params?.row?.frequency === 5
                ? "Monthly"
                : params?.row?.frequency === 1
                ? "No frequency"
                : params?.row?.frequency === 4
                ? "Weekly"
                : "N/A"}
            </p>{" "}
            {params?.row?.week_day === 1 ? "Sunday" : ""}
            {params?.row?.week_day === 2 ? "Monday" : ""}
            {params?.row?.week_day === 3 ? "Tuesday" : ""}
            {params?.row?.week_day === 4 ? "Wednesday" : ""}
            {params?.row?.week_day === 5 ? "Thursday" : ""}
            {params?.row?.week_day === 6 ? "Friday" : ""}
            {params?.row?.week_day === 7 ? "Saturday" : ""}
            {params?.row &&
              params?.row?.week_day?.length > 0 &&
              params?.row?.week_day.map((item) => {
                return item === 1 ? (
                  <div>Sunday</div>
                ) : "" || item === 2 ? (
                  <div>Monday</div>
                ) : "" || item === 3 ? (
                  <div>Tuesday</div>
                ) : "" || item === 4 ? (
                  <div>Wednesday</div>
                ) : "" || item === 5 ? (
                  <div>Thursday</div>
                ) : "" || item === 6 ? (
                  <div>Friday</div>
                ) : "" || item === 7 ? (
                  <div>Saturday</div>
                ) : (
                  ""
                );
              })}
            <div className="monthDate">
              {params?.row &&
                params?.row?.month_date?.length > 0 &&
                params?.row?.month_date?.slice(0, 3)?.map((item, i) => {
                  {
                    return (
                      <div>
                        <LightTooltip
                          title={params?.row?.month_date?.map((items, i) => {
                            return <>{(i ? ", " : "") + items}</>;
                          })}
                        >
                          <li key={i}>{(i ? ", " : "") + item}</li>
                        </LightTooltip>
                        {/* <div>{item},</div> */}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        );
      },
    },
    {
      field: "Time",
      headerName: "Time",
      renderHeader: () => <strong>{"Time"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.startTime || params?.row?.endTime) {
          result.push(`${params?.row?.startTime}-${params?.row?.endTime}`);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div style={{ marginLeft: "30px" }}>
            {permission?.checklist?.manageChecklist === true ? (
              <ToggleSwitch
                {...label}
                row={params?.row?._id}
                check={params?.row?.isActive}
              />
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    },
    {
      field: "Image gallery",
      headerName: "Image gallery",
      renderHeader: () => <strong>{"ImageLog"}</strong>,
      maxWidth: 97,
      align: "center",
      headerAlign: "center",
      flex: 1,
      width: 5,
      renderCell: (params) => {
        return (
          <Tooltip title="image gallery">
            <div>
              <CollectionsBookmarkOutlinedIcon
                fontSize="large"
                className="cursor"
                onClick={() => handleImageGallery(params?.row)}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "Inspection",
      headerName: "Inspection",
      renderHeader: () => <strong>{"Inspection"}</strong>,
      maxWidth: 97,
      align: "center",
      headerAlign: "center",
      flex: 1,
      width: 5,
      renderCell: (params) => {
        return (
          <Tooltip title="Inspection">
            <div>
              <CollectionsBookmarkOutlinedIcon
                fontSize="large"
                className="cursor"
                onClick={() => handleImageGalleryInspection(params?.row)}
              />
            </div>
          </Tooltip>
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1.5,
      maxWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const expiresAt = params?.row?.expires_at;
        const currentDate = new Date()
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, "");
        const isExpired =
          expiresAt && parseInt(expiresAt) < parseInt(currentDate);
        return (
          <div style={{ display: "flex" }}>
            <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onClick={() => handleViewDetails(params?.row)}
                onMouseOver={() => {
                  setHover({ View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ View: false });
                }}
              />
            </Tooltip>
            {isExpired ? (
              <Tooltip title="renew">
                <Box
                  onClick={() => handleEdit(params?.row)}
                  className="iconBox"
                >
                  <AutorenewIcon />
                </Box>
              </Tooltip>
            ) : (
              <>
                {permission?.checklist?.manageChecklist === true ? (
                  <Tooltip title="Edit">
                    <img
                      onClick={() => handleEditForm(params?.row)}
                      src={
                        hover.Edit && indexHover === params?.row?._id
                          ? EditHover
                          : Edit
                      }
                      alt="Edit"
                      style={{
                        height: "35px",
                        cursor: "pointer",
                        margin: "0px 3px 0px 3px",
                      }}
                      onMouseOver={() => {
                        setHover({ Edit: true });
                        setIndexHover(params?.row?._id);
                      }}
                      onMouseLeave={() => {
                        setHover({ Edit: false });
                      }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            )}

            {permission?.checklist?.manageChecklist === true ? (
              <Tooltip title="Delete">
                <img
                  onClick={() => handleDelete(params?.row)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  var pageno = localStorage.getItem("checklistPageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("checklistPageno", newPage + 1);

    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    paginationRequest.body.pagination.page = newPage + 1;
    paginationRequest.body.search = searchValue;
    if (selectedValue !== "") {
      paginationRequest.body.FilterChecklist = selectedValue;
    } else {
      paginationRequest.body.FilterChecklist = selectedValue;
    }
    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    localStorage.setItem(
      "checklistRowPerPage",
      parseInt(event.target.value, 10)
    );

    paginationRequest.body.pagination.page = 1;
    localStorage.removeItem("checklistPageno");
    localStorage.setItem("checklistPageno", 1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    if (selectedValue !== "") {
      paginationRequest.body.FilterChecklist = selectedValue;
    } else {
      paginationRequest.body.FilterChecklist = selectedValue;
    }
    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleFilter = (filterObject) => {
    paginationRequest.body.checklistType = filterObject.checklistType;
    paginationRequest.body.FilterChecklist = selectedValue;
    paginationRequest.body.search = searchValue;
    paginationRequest.body.frequency = filterObject.frequency;
    paginationRequest.body.pagination.page = 1;
    paginationRequest.body.pagination.per_page =
      (rowPerPage && parseInt(rowPerPage)) || 10;

    localStorage.setItem("checklistPageno", 1);

    setLoader(true);
    getApi(paginationRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <TableHeader
        handleChange={handleChange}
        selectedValue={selectedValue}
        handleKeyDown={handleKeyDown}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        // ArchivedApi={ArchivedApi}
        rowsPerPage={rowsPerPage}
        page={page}
        setCheckedArchive={setCheckedArchive}
        checkedArchive={checkedArchive}
        totalpage={totalpage}
        ActivedData={archivedListDatas}
        archivedListDatasPaginate={archivedListDatasPaginate}
        setPage={setPage}
        permission={permission}
        handleFilter={handleFilter}
      />
      <SingleGlobalTable
        data={allChecklistData}
        columns={columns}
        totalCount={totalCount}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <RenewChecklist
        open={open}
        handleClose={handleClose}
        editvalue={editvalue}
      />
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </Box>
  );
};

export default AllCheckList;
