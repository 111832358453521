/** @format */

import React from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SocketStatusModal = ({ open, handleRefresh, handleRefreshClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleRefreshClose}
      aria-labelledby="socket-error-title"
      aria-describedby="socket-error-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleRefreshClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="socket-error-title" variant="h6" component="h2">
          Facing server connection error.
        </Typography>
        <Typography id="socket-error-description" sx={{ mt: 2 }}>
          Please click on <strong>Refresh</strong> button or contact the
          administrator.
        </Typography>
        <Button
          onClick={handleRefresh}
          variant="contained"
          sx={{
            mt: 4,
            backgroundColor: "#27364b",
            "&:hover": { backgroundColor: "#1f2a3d" },
          }}
        >
          Refresh
        </Button>
      </Box>
    </Modal>
  );
};

export default SocketStatusModal;
