import JwtAuthService from "../apiConfig/ApiConfig";
// import LoginApiCAll from "../apiConfig/ApiConfig";
import {
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_ERROR,
} from "../types/Types";
import { toast } from "react-toastify";

export const DeleteFormRequest = (data) => ({
  type: DELETE_FORM_REQUEST,
  payload: data,
});
export const DeleteFormSuccess = (data) => ({
  type: DELETE_FORM_SUCCESS,
  payload: data,
});
export const DeleteFormFailed = (error) => ({
  type: DELETE_FORM_ERROR,
  payload: error,
});

export const DeleteFormApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteFormRequest(data?.url));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        toast.success(`${response?.data?.message}`);
        dispatch(DeleteFormSuccess(response?.data));
      } else {
        const errorMessage = response?.data?.message || 'Delete all checklist with the same form';
        dispatch(DeleteFormFailed(response));
        toast.error(errorMessage);
      }
    });
  } catch (error) {
    dispatch(DeleteFormFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
