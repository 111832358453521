/** @format */

import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
  Divider,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import {
  ImageAddApi,
  ListUserApi,
  UserSubRoleApi,
} from "../../../redux/actions/User";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@mui/icons-material/Clear";
import ReactPlayer from "react-player";
import {
  AddCertificateImageApi,
  AssessmentListApi,
  CertificateListApi,
  UpdateTrainingApi,
  UpdateTrainingSuccess,
  TrainingDetailsViewApi,
  AddCertificateImageReset,
} from "../../../redux/actions/LmsAction";
import AssesmentModal from "./AssesmentModal";
import CertificateModal from "./CertificateModal";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditHover from "../../../images/EditHover.svg";
import Edit from "../../../images/Edit.svg";
import DeleteHover from "../../../images/DeleteHover.svg";
import Delete from "../../../images/Delete.svg";
import * as Yup from "yup";
import Progressbar from "../../../GlobalProgress/Progressbar";
import parse from "html-react-parser";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import { confirmAlert } from "react-confirm-alert";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const EditTraining = () => {
  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data
  );

  const trainingSuccessRes = useSelector(
    (state) =>
      state.lms && state.lms.updateTraining && state.lms.updateTraining.data
  );
  const getImageKey = useSelector(
    (state) =>
      state.lms &&
      state.lms.addImage
  );

  const assesmentList = useSelector(
    (state) =>
      state.lms &&
      state.lms.assessmentlist &&
      state.lms.assessmentlist.data &&
      state.lms.assessmentlist.data.data &&
      state.lms.assessmentlist.data.data.assessments
  );

  const certificateList = useSelector(
    (state) =>
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.certificates
  );
  const editData = useSelector(
    (state) =>
      state.lms &&
      state.lms.lmsEditTraining &&
      state.lms.lmsEditTraining.data &&
      state.lms.lmsEditTraining.data.data
  );
  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );

  const datapayload = {
    body: {
      page: 1,
      per_page: 2000000000000,
    },
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const idData = location?.state?.rows;
  const fieldsData = location?.state?.editeddata;
  const cancleChapter = location?.state?.canclee;
  const cancleTrainingPath = location?.state?.training;
  // let LocalData = localStorage.getItem("editChapters");
  let addedAssesment = localStorage.getItem("assesmentData");
  let editTrainingData = JSON.parse(localStorage.getItem("editTrainingData"));

  // console.log("editTrainingData", editTrainingData);
  let addedCertificate = localStorage.getItem("certificateData");
  const classes = useStyles();
  const [categoryData, setCategoryData] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [pyaloadChapters, setPyaloadChapters] = useState([]);
  const [newChapters, setNewChapters] = useState([]);
  const [certificateIdList, setCertificateIdList] = useState([]);
  const [deletedCertificate, setDeletedCertificate] = useState({});
  const [certificatePayloadId, setCertificatePayloadId] = useState([]);
  const [assesmentIdList, setAssesmentIdList] = useState([]);
  const [deletedAssesment, setDeletedAssesment] = useState({});
  const [assesmentPayloadId, setAssesmentPayloadId] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCerti, setOpenCerti] = useState(false);
  const [authorId, setAuthorId] = useState([]);
  const [bodyImage, setBodyImage] = useState([]);
  const [newvalues, setNewvalues] = useState([]);
  const [image, setImage] = useState([]);
  const [assesmentData, setAssesmentData] = useState([]);
  const [idAsses, setIdAsses] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [idCerti, setIdCerti] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);
  const [newValues, setNewValues] = useState();
  const [arraylength, setArraylength] = useState([]);

  useEffect(() => {
    if (idData !== "" && idData !== undefined) {
      localStorage.setItem("edittrainingid", idData);
    }
  }, [idData]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `assessment/index?company_id=${id}` };
    dispatch(AssessmentListApi(data));
  }, []);

  useEffect(() => {
    editTrainingDetail();
  }, []);

  useEffect(() => {
    dispatch(CertificateListApi());
  }, []);

  useEffect(() => {
    if (assesmentList) {
      setAssesmentData(assesmentList);
    }
  }, [assesmentList]);

  useEffect(() => {
    if (certificateList) {
      setCertificateData(certificateList);
    }
  }, [certificateList]);

  useEffect(() => {
    for (let item of chapters) {
      if (item?.type !== 1) {
        if (item?.content?.size) {
          setImage((image) => [...image, { content: item?.content, item }]);
        }
      }
    }
    setBodyImage(chapters);
  }, [chapters]);

  useEffect(() => {
    if (chapters?.length > 0) {
      const newChapter =
        chapters &&
        chapters &&
        chapters?.map((val, i) => {
          if (val?.type) {
            let myObj = {
              id: val?.id || i,
              name: val?.name,
              summary: val?.summary,
              transcript: val?.transcript,
              type: val?.type,
              content: val?.content?.key ? val?.content?.key : val?.content,
            };
            return myObj;
          }
          return val;
        });
      setPyaloadChapters(newChapter);
    }
  }, [chapters]);

  useEffect(() => {
    let newValues =
      bodyImage &&
      bodyImage?.map((item, i) => {
        if (item?.type !== 1) {
          let file = getImageKey.filter(
            (item1) => item?.name === item1?.chapterData?.name
          );
          let fileName = file[0]?.imageData[0]?.filename;
          let itemName = item?.content?.name;
          let itemKey = file[0]?.imageData[0].key;
          if (fileName === itemName) {
            item.content = itemKey;
            setArraylength((arraylength) => [...arraylength, itemKey]);
          }
          return (bodyImage[i] = item);
        }
        return item;
      });
    setNewvalues(newValues);
  }, [getImageKey, dispatch]);

  useEffect(() => {
    if (trainingSuccessRes && trainingSuccessRes.status === 200) {
      dispatch(UpdateTrainingSuccess(""));
      setTimeout(() => {
        navigate("/training");
      }, 1000);
    }
  }, [trainingSuccessRes]);

  useEffect(() => {
    // console.log("arraylength: ", arraylength, image);
    if (arraylength?.length && arraylength?.length === image?.length) {
      if (newvalues?.length > 0) {
        let isAllFileUploaded = true;
        const newChapter =
          newvalues &&
          newvalues &&
          newvalues?.map((val, i) => {
            if (val?.type) {
              if (!val || !val.content || !val.content.key) {
                // console.log(val);
                isAllFileUploaded = false;
              }
              let myObj = {
                id: val?.id,
                name: val?.name,
                summary: val?.summary,
                transcript: val?.transcript,
                type: val?.type,
                content: val?.content?.key ? val?.content?.key : val?.content,
              };
              return myObj;
            }
            return val;
          });

        handleClickEdit(formik.values, newChapter);
      }
    }
  }, [newvalues, arraylength]);

  const isAllSelected =
    userSelect.length > 0 && selected.length === userSelect.length;

  useEffect(() => {
    if (assesmentIdList) {
      let idList = assesmentIdList.map((ids, i) => {
        return ids?._id;
      });
      setAssesmentPayloadId(idList);
    }
    if (certificateIdList) {
      let idList = certificateIdList.map((ids, i) => {
        return ids._id;
      });
      setCertificatePayloadId(idList);
    }
  }, [assesmentIdList, certificateIdList]);
  useEffect(() => {
    if (newChapters.length > 0) {
      setChapters((chapters) => [...chapters, ...newChapters]);
    }
  }, [newChapters]);

  useEffect(() => {
    if (fieldsData) {
      let editChapter = fieldsData?.steps;
      if (editChapter) {
        setChapters(editChapter);
      }
    } else {
      let editChapter = editData?.steps;
      if (editChapter) {
        setChapters(editChapter);
      }
    }
    // console.log("fieldsData", fieldsData);
  }, [editData, fieldsData]);

  useEffect(() => {
    if (addedAssesment) {
      let chapterList = JSON.parse(addedAssesment);
      setAssesmentIdList(chapterList);
    } else {
      if (fieldsData !== undefined) {
        const newassess = assesmentData.reduce((newarr, assess) => {
          if (idAsses.includes(assess._id)) {
            newarr.push(assess);
          }
          return newarr;
        }, []);
        setAssesmentIdList(newassess);
      }
    }
  }, [addedAssesment, fieldsData, idAsses]);

  useEffect(() => {
    if (editData) {
      let editAssessment = editData?.assessment;
      let editcertificate = editData?.certificate;
      if (editcertificate && editcertificate.length) {
        const editCertificate = localStorage.getItem("editCertificate");
        if (!editCertificate) {
          localStorage.setItem("editCertificate", JSON.stringify(editcertificate))
        }
      }
      if (editAssessment && editAssessment.length) {
        const editAssessmentData = localStorage.getItem("editAssessment");
        if (!editAssessmentData) {
          localStorage.setItem("editAssessment", JSON.stringify(editAssessment))
        }
      }
      let editAuthor = editData?.authors;
      let day = (editTrainingData ? editTrainingData : editData)?.starts_at
        ?.toString()
        .slice(6, 8);
      let month = (editTrainingData ? editTrainingData : editData)?.starts_at
        ?.toString()
        .slice(4, 6);
      let year = (editTrainingData ? editTrainingData : editData)?.starts_at
        ?.toString()
        .slice(0, 4);
      let sortedDate = new Date(`${month}-${day}-${year}`);
      setStartDate(sortedDate);
      let daysecond = (
        editTrainingData ? editTrainingData : editData
      )?.expires_at
        ?.toString()
        .slice(6, 8);
      let monthsecond = (
        editTrainingData ? editTrainingData : editData
      )?.expires_at
        ?.toString()
        .slice(4, 6);
      let yearsecond = (
        editTrainingData ? editTrainingData : editData
      )?.expires_at
        ?.toString()
        .slice(0, 4);
      let sortedDatesecond = new Date(
        `${monthsecond}-${daysecond}-${yearsecond}`
      );
      setEndDate(sortedDatesecond);
      let endDate = editData?.expires_at;
      if (editAssessment?.length > 0) {
        let assessss = editAssessment.filter((ids, i) => {
          if (ids) {
            return ids;
          }
        });
        setIdAsses(assessss);
      }
      if (editcertificate?.length > 0) {
        let certificate = editcertificate.filter((ids, i) => {
          if (ids) {
            return ids;
          }
        });
        setIdCerti(certificate);
      }
      setSelected(editAuthor);
      setNewValues({
        name: editTrainingData
          ? editTrainingData.name
          : editData && editData?.name,
        summary: editTrainingData
          ? editTrainingData.summary
          : editData && editData?.summary,
        role: editTrainingData
          ? editTrainingData.role
          : editData && editData?.role,
        repeated: editData && editData?.repeated,
        category: editTrainingData
          ? editTrainingData.category
          : editData && editData?.category,
        subCategory: editTrainingData
          ? editTrainingData.subCategory
          : editData && editData?.subCategory,
        authors: editTrainingData
          ? editTrainingData.authors
          : editData && editData?.authors,
      });
    }
  }, [editData]);

  useEffect(() => {
    if (fieldsData === undefined && cancleChapter !== "cancle") {
      const newassess = assesmentData.reduce((newarr, assess) => {
        if (idAsses.includes(assess._id)) {
          newarr.push(assess);
        }
        return newarr;
      }, []);
      setAssesmentIdList(newassess);
    }
  }, [idAsses]);

  useEffect(() => {
    if (fieldsData === undefined && cancleChapter !== "cancle") {
      const newcerti = certificateData.reduce((newarr, certi) => {
        if (idCerti.includes(certi._id)) {
          newarr.push(certi);
        }
        return newarr;
      }, []);
      setCertificateIdList(newcerti);
    }
  }, [idCerti]);

  useEffect(() => {
    if (addedCertificate) {
      let chapterList = JSON.parse(addedCertificate);
      setCertificateIdList(chapterList);
    } else {
      if (fieldsData !== undefined) {
        const newcerti = certificateData.reduce((newarr, certi) => {
          if (idCerti.includes(certi._id)) {
            newarr.push(certi);
          }
          return newarr;
        }, []);
        setCertificateIdList(newcerti);
      }
    }
  }, [addedCertificate, fieldsData, idCerti]);

  useEffect(() => {
    if (userList?.length > 0) {
      let arr = [];
      for (let item of userList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setUserSelect(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (subRoleList) {
      setRoleSelect(subRoleList);
    }
  }, [subRoleList]);

  useEffect(() => {
    dispatch(UpdateTrainingSuccess(""));
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
  }, []);

  useEffect(() => {
    const category = JSON.parse(localStorage.getItem("categoryData"));
    setCategoryData(category);
  }, []);

  useEffect(() => {
    if (selected?.length) {
      let authorid = [];
      for (let item of selected) {
        authorid.push(item?._id);
      }
      setAuthorId(authorid);
    }
  }, [selected]);

  const listUser = (authers) => {
    const id = localStorage.getItem("companyId");
    const datapayload = {
      url: BASE_URL + `MapUsers?company_id=${id}&subRole=${authers}`,
    };
    dispatch(ListUserApi(datapayload));
  };

  const handleAuther = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(selected.length === userSelect?.length ? [] : userSelect);
      return;
    }
    setSelected(value);
  };

  const formik = useFormik({
    initialValues: newValues || {
      name: "",
      summary: "",
      role: "",
      category: "",
      subCategory: "",
      authors: [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(AddCertificateImageReset());
      if (image?.length > 0) {
        for (let item of image) {
          const imagefile = {
            files: item.content,
          };

          const imageData = {
            body: imagefile,
          };

          if (imageData.body.files != "" && imageData.body.files != undefined) {
            imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
            dispatch(AddCertificateImageApi(imageData, item.item));
          }
        }
      } else {
        let imgKey = "";
        handleClickEdit(values, imgKey);
      }
    },
  });

  const formSubmit = (values, newchapters) => {
    if (newchapters !== "") {
      let isFileUpload = false;
      let newValues =
        newchapters &&
        newchapters?.map((item) => {
          if (item?.type !== 1) {
            // console.log(typeof item?.content, typeof item?.content);
            isFileUpload =
              typeof item?.content?.key === "string" ||
                item?.content === "string"
                ? true
                : false;
            let myonj = { ...item };
            myonj.content =
              item?.content && item?.content?.key
                ? item?.content?.key
                : item?.content;
            return myonj;
          }
          return item;
        });
      values.steps = newValues;
      if (!isFileUpload) {
        // console.log("Validation Error: ", newchapters);
      }
    } else {
      values.steps = pyaloadChapters;
    }
    let new_startDate = moment(startDate)
      .format("YYYY-MM-DD")
      .split("-")
      .join("");
    let new_endDate = moment(endDate).format("YYYY-MM-DD").split("-").join("");
    values.starts_at = parseInt(new_startDate);
    values.expires_at = parseInt(new_endDate);
    values.certificate = certificatePayloadId;
    values.assessment = assesmentPayloadId;
    values.duration = null;
    values.authors = authorId;
    values._id = editData._id;
    if (
      editData?.repeated &&
      (!values.assessment || values.assessment.length == 0)
    ) {
      toast.error("One assessment is required for dynamic submission.");
      return;
    }
    // if (
    //   editData?.repeated &&
    //   values.assessment &&
    //   values.assessment.length > 1
    // ) {
    //   toast.error("Only one assessment is allowed for dynamic submission.");
    //   return;
    // }
    const payloadData = {
      body: values,
    };
    if (payloadData) {
      const id = localStorage.getItem("companyId");
      payloadData.url = BASE_URL + `training?company_id=${id}`;
      dispatch(UpdateTrainingApi(payloadData));
      localStorage.removeItem("editTrainingData");
      localStorage.removeItem("editCertificate");
      localStorage.removeItem("editAssessment");
    }
  };

  const handleClickEdit = (values, imgKey) => {
    confirmAlert({
      title: "Confirm Edit",
      message:
        "Changes in chapters, assessments will erase all old submissions, still want to edit training?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            formSubmit(values, imgKey);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  function getFilteredList() {
    if (!formik.values.category) {
      return [];
    }
    return categoryData.filter((item) => item.title === formik.values.category);
  }

  const filteredList = useMemo(getFilteredList, [
    formik.values.category,
    categoryData,
  ]);

  useEffect(() => {
    let subcategoryList =
      filteredList &&
      filteredList?.map((list, i) => {
        setSubCategoryList(list?.subCategories);
      });
  }, [filteredList]);

  useEffect(() => {
    const subRoleUser = formik.values.role
      ? formik.values.role
      : editData?.role;
    if (subRoleUser !== "") {
      listUser(subRoleUser);
    }
  }, [editData?.role, formik.values.role]);

  const handleDelete = (list) => {
    setDeletedAssesment(list);
    const target = assesmentIdList.filter((item, i) => item._id !== list._id);
    setAssesmentIdList(target);
    localStorage.setItem('assesmentData', JSON.stringify(target));
    localStorage.setItem('editAssessment', JSON.stringify(target.map(item => item._id)));
  };
  const handleCertificateDelete = (list) => {
    setDeletedCertificate(list);
    const target = certificateIdList.filter((item, i) => item._id !== list._id);
    setCertificateIdList(target);
    localStorage.setItem('certificateData', JSON.stringify(target));
    localStorage.setItem('editCertificate', JSON.stringify(target.map(item => item._id)));
  };

  const handleChapterDelete = (index) => {
    let newcustom = chapters.filter((item, i) => i !== index);
    let newArray = [...newcustom];
    setChapters(newArray);
    let local = localStorage.setItem(
      "trainingChapters",
      JSON.stringify(newArray)
    );
  };
  // console.log("=================>", chapters);
  const handleChapter = () => {
    let new_startDate = moment(startDate)
      .format("YYYY-MM-DD")
      .split("-")
      .join("");
    let new_endDate = moment(endDate).format("YYYY-MM-DD").split("-").join("");
    const trainingFields = {
      name: formik.values.name,
      summary: formik.values?.summary,
      role: formik.values?.role,
      repeated: editData?.repeated,
      category: formik.values?.category,
      subCategory: formik.values?.subCategory,
      authors: selected,
      starts_at: new_startDate,
      expires_at: new_endDate,
      certificate: certificateIdList,
      assessment: assesmentIdList,
    };
    localStorage.setItem("editTrainingData", JSON.stringify(trainingFields));
    let trainingdata = JSON.parse(localStorage.getItem("editTrainingData"));

    if (trainingdata) {
      navigate("/training/add-training/add-chapter", {
        state: {
          path: "addchapter",
          row: fieldsData ? fieldsData : editData,
        },
      });
    }
  };
  const handleChapterEdit = (items, index) => {
    navigate("/training/add-training/edit-chapter", {
      state: {
        items,
        path: "editchapter",
        row: fieldsData ? fieldsData : editData,
      },
    });
  };

  const handleAssesment = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAssesmentIdList([]);
    setOpen(false);
  };

  const handleCertificate = () => {
    setOpenCerti(true);
  };
  const handleCloseCerti = () => {
    setCertificateIdList([]);
    setOpenCerti(false);
  };
  const editTrainingDetail = () => {
    const id = localStorage.getItem("companyId");
    const idData = localStorage.getItem("edittrainingid");
    if (idData !== "" && idData !== undefined) {
      const data = {
        url: BASE_URL + `training/${idData}?company_id=${id}`,
      };
      dispatch(TrainingDetailsViewApi(data));
    } else if (fieldsData?._id !== "" && fieldsData?._id !== undefined) {
      const data = {
        url: BASE_URL + `training/${fieldsData?._id}?company_id=${id}`,
      };
      dispatch(TrainingDetailsViewApi(data));
    }
  };

  const handleVideoPress = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  const trainingLocalClear = () => {
    localStorage.removeItem("editTrainingData");
    localStorage.removeItem("editCertificate");
    localStorage.removeItem("editAssessment");
    if (cancleTrainingPath === "alltrainingreport") {
      navigate("/training");
    } else {
      navigate("/alltrainingreport");
    }
  };
  // console.log("editData", editData);
  return (
    <Box>
      <ToastContainer limit={1} />
      {Loader && <ProgressbarTransparent play />}
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  onClick={trainingLocalClear}
                  className="cancle-button cancle-hover"
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
                <Button
                  className="header-add cancle-hover"
                  type="submit"
                  style={{ marginRight: 5 }}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Update
                </Button>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>Advance Checklist Wise Report:</strong>
                      <p>
                        Status: Display's selected checklist completion
                        status, completion percentage & average compliance
                        scores for the selected user and stores.
                      </p>
                      <p>
                        Percentage: Display's selected checklist
                        completion percentage & average compliance scores
                        for the selected user and stores.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      marginLeft: "8px",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              // height: "80vh",
              height: "calc(100vh - 220px)",
              mt: 1,
              p: 3,
              overflowX: "hidden",
              overflowY: "scroll",
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                md={6}
                xs={12}
                sm={12}
                lg={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  maxWidth="sm"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    p: 3,
                    textAlign: "left",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Title</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          defaultValue={editData?.name}
                          className="date-input"
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Role</label>
                        <Select
                          size="small"
                          name="role"
                          onChange={(e) => {
                            formik.handleChange(e);
                            setSelected([]);
                          }}
                          value={formik.values.role}
                          sx={{ borderRadius: "8px" }}
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 1,
                                },
                                height: "180px",
                              },
                            },
                          }}
                          defaultValue={editData?.role}
                          disabled={true}
                        >
                          {roleSelect &&
                            roleSelect?.map((option, i) => (
                              <MenuItem key={i} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Category</label>
                        <Select
                          size="small"
                          name="category"
                          sx={{ borderRadius: "8px" }}
                          onChange={formik.handleChange}
                          value={formik.values.category}
                          MenuProps={{
                            disableScrollLock: true,
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 1,
                                },
                                height: "200px",
                              },
                            },
                          }}
                          renderValue={(selected) => selected}
                        >
                          {categoryData &&
                            categoryData?.map((option, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={option.title}
                                  className="select-item"
                                >
                                  {option.title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Summary</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          name="summary"
                          onChange={formik.handleChange}
                          value={formik.values.summary}
                          className="date-input"
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Select Author</label>
                        <Select
                          labelId="mutiple-select-label"
                          multiple
                          name="authors"
                          size="small"
                          value={selected}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleAuther(e);
                          }}
                          sx={{ borderRadius: "8px" }}
                          MenuProps={{
                            disableScrollLock: true,
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 0,
                                },
                                height: "180px",
                              },
                            },
                          }}
                          renderValue={(selected) =>
                            selected.map((item) => item?.name)?.join(",")
                          }
                          disabled={true}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root: isAllSelected ? classes.selectedAll : "",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                classes={{
                                  indeterminate: classes.indeterminateColor,
                                }}
                                checked={isAllSelected}
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < userSelect.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.selectAllText }}
                              primary="Select All"
                            />
                          </MenuItem>
                          {userSelect &&
                            userSelect.map((option, i) => (
                              <MenuItem key={i} value={option}>
                                <ListItemIcon>
                                  <Checkbox
                                    checked={
                                      selected.findIndex(
                                        (item) => item?._id === option?._id
                                      ) > -1
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText primary={option?.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Sub-Category</label>
                        <Select
                          size="small"
                          name="subCategory"
                          onChange={formik.handleChange}
                          value={formik.values.subCategory}
                          sx={{ borderRadius: "8px" }}
                          renderValue={(selected) => selected}
                        >
                          {subCategoryList &&
                            subCategoryList?.map((option, i) => {
                              return (
                                <MenuItem key={i} value={option.title}>
                                  {option.title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Training Period</label>
                    <Stack direction="row" className="stack">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          inputFormat="DD/MM/YYYY"
                          size="small"
                          value={startDate}
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("-")
                              .join("");
                            setStartDate(dateSort);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          className="training-date"
                          minDate={new Date()}
                        />
                      </LocalizationProvider>

                      <Typography className="date-to">to</Typography>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          inputFormat="DD/MM/YYYY"
                          size="small"
                          value={endDate}
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("-")
                              .join("");
                            setEndDate(dateSort);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          className="training-date"
                          minDate={new Date()}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        className="label-name"
                        control={
                          <Checkbox
                            type="checkbox"
                            name="repeated"
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={true}
                            // sx={{ color: "#196773" }}
                            value={editData?.repeated}
                            checked={editData?.repeated}
                          />
                        }
                        label="Dynamic Assessment Submission"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={6} xs={12} sm={12} lg={6}>
                <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                  <Grid item md={6}>
                    <Typography variant="p" component="b">
                      Assessment
                    </Typography>
                    <Button
                      variant="outlined"
                      className="add-button cancle-hover"
                      sx={{
                        width: "90%",
                        mb: 1,
                        mt: 1,
                        whiteSpace: "nowrap",
                        marginLeft: 5,
                      }}
                      onClick={handleAssesment}
                    >
                      <AddIcon sx={{ mx: 1 }} />
                      Add Assessment
                    </Button>
                    <Box sx={{ mb: 1 }}>
                      {assesmentIdList &&
                        assesmentIdList.length > 0 &&
                        assesmentIdList?.map((list, i) => {
                          return (
                            <Box
                              key={i}
                              sx={{ width: "70%", display: "inline-block" }}
                            >
                              <Typography className="assesment-list">
                                <LightTooltip title={list?.title}>
                                  <Typography className="text-short-assesment">
                                    {list?.title}
                                  </Typography>
                                </LightTooltip>
                                <Typography>
                                  <ClearIcon
                                    onClick={() => handleDelete(list)}
                                  />
                                </Typography>
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="p" component="b">
                      Certificate
                    </Typography>
                    <Button
                      variant="outlined"
                      className="add-button cancle-hover"
                      sx={{
                        width: "90%",
                        mb: 1,
                        mt: 1,
                        whiteSpace: "nowrap",
                      }}
                      onClick={handleCertificate}
                    >
                      <AddIcon sx={{ mx: 1 }} />
                      Add Certificate
                    </Button>
                    <Box>
                      {certificateIdList &&
                        certificateIdList.length > 0 &&
                        certificateIdList?.map((list, i) => {
                          return (
                            <Box
                              key={i}
                              sx={{ width: "70%", display: "inline-block" }}
                            >
                              <Typography className="assesment-list">
                                <LightTooltip title={list.title}>
                                  <Typography className="text-short-assesment">
                                    {list.title}
                                  </Typography>
                                </LightTooltip>
                                <Typography>
                                  <ClearIcon
                                    onClick={() =>
                                      handleCertificateDelete(list)
                                    }
                                  />
                                </Typography>
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{ justifyContent: "center", margin: "0 auto" }}
                >
                  <Grid item md={6}>
                    <Typography variant="p" component="b" sx={{ mt: 2 }}>
                      Chapter
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={handleChapter}
                      className="add-button cancle-hover"
                      sx={{ width: "95%", mt: 1, whiteSpace: "nowrap" }}
                    >
                      <AddIcon sx={{ mx: 1 }} />
                      Add New Chapter
                    </Button>
                  </Grid>
                </Grid>
                {chapters &&
                  chapters.length > 0 &&
                  chapters?.map((items, i) => {
                    let contentNew =
                      items?.type === 1
                        ? items && items?.content
                          ? JSON.parse(items?.content)
                          : ""
                        : "";
                    return (
                      <Card key={i} sx={{ my: 2 }}>
                        <Grid container spacing={1} sx={{ overflowX: "auto" }}>
                          <Grid item md={10}>
                            <Box sx={{ px: 2 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h3>
                                  {i + 1}. &nbsp;&nbsp;{items.name}
                                </h3>
                                <p>
                                  type:{" "}
                                  <b>
                                    {items.type === 1
                                      ? "Text"
                                      : items.type === 2
                                        ? "Video"
                                        : items.type === 3
                                          ? "Audio"
                                          : items.type === 4
                                            ? "Image"
                                            : items.type === 5
                                              ? "Doc"
                                              : ""}
                                  </b>
                                </p>
                              </Box>
                              <h5 style={{ textAlign: "left", margin: "0" }}>
                                Summary of Chapter
                              </h5>
                              <Card
                                sx={{
                                  p: 2,
                                  my: 1,
                                  border: "1px solid #CBD4E1",
                                  borderRadius: "8px",
                                  background: "#F6F8FC",
                                  textAlign: "left",
                                }}
                              >
                                <p>{items.summary}</p>
                              </Card>
                              <h5
                                style={{
                                  textAlign: "left",
                                  margin: "0",
                                  paddingTop: "20px",
                                }}
                              >
                                Content
                              </h5>
                              <Card
                                sx={{
                                  p: 2,
                                  my: 1,
                                  mb: 1,
                                  border: "1px solid #CBD4E1",
                                  borderRadius: "8px",
                                  background: "#F6F8FC",
                                  textAlign: "left",
                                }}
                              >
                                {items?.type === 2 ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      paddingBottom: "30%",
                                    }}
                                  >
                                    <ReactPlayer
                                      url={items?.content?.url}
                                      width="100%"
                                      height="100%"
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                      }}
                                      controls={true}
                                      playing={playing}
                                      loop={true}
                                    />
                                  </div>
                                ) : items?.type === 3 ||
                                  items?.type === 4 ||
                                  items?.type === 5 ? (
                                  <>
                                    <p>
                                      {items?.content?.name
                                        ? items?.content?.name
                                        : items?.content}
                                    </p>
                                    <p>{items?.content?.size}</p>
                                  </>
                                ) : items?.type === 4 ? (
                                  <>
                                    <img src={items?.content?.url} />
                                  </>
                                ) : (
                                  ""
                                )}

                                {items?.type === 1 ? (
                                  <>
                                    <h4>
                                      {typeof contentNew === "string" ? (
                                        <p className="content-list">
                                          {parse(contentNew)}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </h4>
                                    <h4>
                                      {contentNew?.name ? contentNew?.name : ""}
                                    </h4>
                                    <p>
                                      {contentNew?.blocks &&
                                        contentNew?.blocks?.length > 0
                                        ? contentNew?.blocks &&
                                        contentNew?.blocks?.length > 0 &&
                                        contentNew?.blocks?.map(
                                          (conten, i) => {
                                            return <h4>{conten.text}</h4>;
                                          }
                                        )
                                        : ""}
                                    </p>
                                    <p>
                                      {contentNew?.ops &&
                                        contentNew?.ops?.length > 0
                                        ? contentNew?.ops &&
                                        contentNew?.ops?.length > 0 &&
                                        contentNew?.ops?.map((conten, i) => {
                                          return <h4>{conten.insert}</h4>;
                                        })
                                        : ""}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item md={2}>
                            <Box className="box-icons">
                              <h3>
                                <img
                                  onClick={() => handleChapterDelete(i)}
                                  src={
                                    hover.Delete && indexHover === i
                                      ? DeleteHover
                                      : Delete
                                  }
                                  alt="Delete"
                                  style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    margin: "0px 3px 0px 3px",
                                  }}
                                  onMouseOver={() => {
                                    setHover({ Delete: true });
                                    setIndexHover(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ Delete: false });
                                  }}
                                />
                              </h3>
                              <h3>
                                <img
                                  onClick={() => handleChapterEdit(items, i)}
                                  src={
                                    hover.Edit && indexHover === i
                                      ? EditHover
                                      : Edit
                                  }
                                  alt="Edit"
                                  style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    margin: "0px 3px 0px 3px",
                                  }}
                                  onMouseOver={() => {
                                    setHover({ Edit: true });
                                    setIndexHover(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ Edit: false });
                                  }}
                                />
                              </h3>
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
              </Grid>
            </Grid>
          </Box>
        }
      </form>
      <AssesmentModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        assesmentIdList={assesmentIdList}
        deletedAssesment={deletedAssesment}
      />
      <CertificateModal
        open={openCerti}
        setOpen={setOpenCerti}
        handleClose={handleCloseCerti}
        certificateIdList={certificateIdList}
        deletedCertificate={deletedCertificate}
      />
    </Box>
  );
};

export default EditTraining;
