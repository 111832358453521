import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportProductApi } from "../../../../redux/actions/WasteManagement";
import { BASE_URL } from "../../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import moment from "moment";

const ExportProduct = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const exportData = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.exportData &&
      state?.wastemng?.exportData?.data &&
      state?.wastemng?.exportData?.data?.data &&
      state?.wastemng?.exportData?.data?.data?.products
  );

  useEffect(() => {
    handleExportCsv();
  }, []);

  useEffect(() => {
    if (exportData) {
      const tableData =
        exportData &&
        exportData?.length > 0 &&
        exportData?.map((item, i) => {
          return {
            title: item?.title,
            description: item?.description,
            price: item?.price,
            CreatedOn: `${moment(item?.createdAt).format("lll")}`,
            category: item?.category?.title,
            uom: item?.uom?.title,
          };
        });
      setData(tableData);
    }
  }, [exportData]);

  const handleExportCsv = () => {
    const id = localStorage.getItem("companyId");
    const payload = { url: BASE_URL + `inventory/products?company_id=${id}` };
    dispatch(ExportProductApi(payload));
  };

  return (
    <Box>
      <CSVLink
        data={data ? data : ""}
        style={{ color: "white", textDecoration: "none" }}
        filename="CustomerLogData"
      >
        <Button className="header-add cancle-hover">
          <FileDownloadIcon sx={{ mr: 1 }} />
          Export
        </Button>
      </CSVLink>
    </Box>
  );
};

export default ExportProduct;
