/** @format */

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Header from "./pages/Header";
import { Typography, Grid, TablePagination } from "@mui/material";
import Edit from "../../assets/icons/svg-icons/View.svg";
import { useNavigate } from "react-router-dom";
import User from "../../assets/icons/svg-icons/User.svg";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ListWasteApi } from "../redux/actions/WasteManagement";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import PropTypes from "prop-types";
import Progressbar from "../GlobalProgress/Progressbar";
import { confirmAlert } from "react-confirm-alert";
import { DataGrid } from "@mui/x-data-grid";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import Delete from "../images/Delete.svg";
import moment from "moment";
import { UserDeleteApi } from "../redux/actions/User";
import { ToastContainer } from "react-toastify";
import { AssessmentArchivedApi } from "../redux/actions/LmsAction";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export const WasteManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
    Delete: false,
  });
  const [wasteData, setWasteData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [localPageNo, setLocalPageNo] = useState(1);
  const [uiRender, setUiRender] = useState(false);
  const [expandedData, setExpandedData] = useState({});
  const [open, setOpen] = useState(false);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [activeChecked, setActiveChecked] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [permission, setPermission] = useState({});
  const [loader, setLoader] = useState(true);

  const wasteList = useSelector(
    (state) =>
      state.wastemng &&
      state.wastemng.data &&
      state.wastemng.data.data &&
      state.wastemng.data.data.data &&
      state.wastemng.data.data.data.Wastages
  );

  const Loader = useSelector(
    (state) => state.wastemng && state.wastemng.loading
  );
  const totalitems = useSelector(
    (state) =>
      state.wastemng &&
      state.wastemng.data &&
      state.wastemng.data.data &&
      state.wastemng.data.data.data &&
      state.wastemng.data.data.data.paginate
  );
  const deleteRes = useSelector(
    (state) =>
      state.user &&
      state.user.userDelete &&
      state.user.userDelete.data &&
      state.user.userDelete.data.data
  );

  const ArchivedListData = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms?.assessmentListArchived &&
      state.lms?.assessmentListArchived?.data &&
      state.lms?.assessmentListArchived?.data?.data &&
      state.lms?.assessmentListArchived?.data?.data?.WastageLog
  );

  const ArchivedListTotalItems = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms?.assessmentListArchived &&
      state.lms?.assessmentListArchived?.data &&
      state.lms?.assessmentListArchived?.data?.data &&
      state.lms?.assessmentListArchived?.data?.data?.paginate
  );

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  useEffect(() => {
    handleDeleteRes();
  }, [deleteRes]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(ArchivedListTotalItems?.total_item);
    } else {
      setTotalCount(totalitems?.total_item);
    }
  }, [ArchivedListTotalItems, totalitems, checkedArchive]);

  // useEffect(() => {
  //   setTotalCount(totalitems?.total_item);
  // }, [totalitems]);

  useEffect(() => {
    if (ArchivedListData) {
      // setLoader(true);
      setWasteData(ArchivedListData);
      // setTimeout(() => {
      //   setLoader(false);
      // }, 2000);
    }
  }, [ArchivedListData]);

  useEffect(() => {
    if (searchValue == "") {
      setSearchData("");
    }
  }, [searchValue]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch(e);
      setSearchData(searchValue);
      const searchedData = wasteData.filter((item) => {
        return item.addedBy.name.includes(searchValue);
      });
      setSearchList(searchedData);
    }
  };

  useEffect(() => {
    // setLoader(true);
    setWasteData(wasteList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [wasteList]);

  // Table Pagination
  const data = {
    page: page,
    per_page: rowsPerPage,
  };

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  useEffect(() => {
    listWastage(data);
    localStorage.removeItem("wastelogid");
  }, []);

  useEffect(() => {
    if (checkedArchive === true) {
      ArchivedApi(paginationRequest);
    }
    if (activeChecked === true) {
      listWastage(data);
    }
  }, [checkedArchive, activeChecked]);

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    // setPage(newPage + 1);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    data.page = newPage + 1;
    paginationRequest.body.pagination.page = newPage + 1;

    if (checkedArchive === false) {
      listWastage(data);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    data.per_page = parseInt(event.target.value, 10);
    data.page = 1;
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    if (checkedArchive === false) {
      listWastage(data);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleExpand = (row) => {
    setExpandedData(row);
    setOpen(!open);
  };
  const handleDelete = (userid) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = userid;
            const data = {
              url:
                BASE_URL + `inventory/wastage/${userRoleId}?company_id=${id}`,
            };
            setLoader(true);
            dispatch(UserDeleteApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 500);
            setUiRender(!uiRender);
            handleDeleteRes();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDeleteRes = () => {
    const data = {
      page: page,
      per_page: rowsPerPage,
    };
    const id = localStorage.getItem("companyId");
    data.url =
      BASE_URL +
      `inventory/wastages?pagination={"page":${data.page},"per_page":${data.per_page}}&company_id=${id}`;
    dispatch(ListWasteApi(data));
  };

  const listWastage = (data) => {
    const id = localStorage.getItem("companyId");
    data.url =
      BASE_URL +
      `inventory/wastages?pagination={"page":${data.page},"per_page":${data.per_page}}&company_id=${id}`;
    dispatch(ListWasteApi(data));
  };

  const handleChangeView = (row) => {
    localStorage.setItem("wastelogid", row);
    const idwaste = localStorage.getItem("wastelogid");
    if (idwaste !== "") {
      navigate("/waste-management/view-details");
    }
  };

  const ArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url =
      BASE_URL + `inventory/wastages/ListArchivedWastageLog?company_id=${id}`;
    dispatch(AssessmentArchivedApi(paginationRequest));
  };

  // const columns = [
  //   {
  //     field: "Log ID",
  //     headerName: "Log ID",
  //     renderHeader: () => <strong>{"Log ID"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row || params?.row) {
  //         result.push(
  //           params?.row?._id
  //         );
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "User",
  //     headerName: "User",
  //     renderHeader: () => <strong>{"User"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row || params?.row) {
  //         result.push(
  //           params?.row?.addedBy?.email
  //         );
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "Created at",
  //     headerName: "Created at",
  //     renderHeader: () => <strong>{"Created at"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let timeFormate = moment(params?.row?.createdAt).format("LTS");
  //       let result = [];
  //       if (timeFormate) {
  //         result.push(timeFormate);
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "Updated at",
  //     headerName: "Updated at",
  //     renderHeader: () => <strong>{"Updated at"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
  //       let result = [];
  //       if (dateFormate) {
  //         result.push(dateFormate);
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "Store",
  //     headerName: "Store",
  //     renderHeader: () => <strong>{"Store"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row || params?.row) {
  //         result.push(
  //           params?.row?.store?.name
  //         );
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "Note",
  //     headerName: "Note",
  //     renderHeader: () => <strong>{"Note"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row || params?.row) {
  //         result.push(
  //           params?.row?.note !== "" ? params?.row?.note : "N/A"
  //         );
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "Products",
  //     headerName: "Products",
  //     renderHeader: () => <strong>{"Products"}</strong>,
  //     align: "center",
  //     headerAlign: "center",
  //     flex: 1,
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row || params?.row) {
  //         result.push(
  //           params?.row?.products?.length
  //         );
  //       } else {
  //         result = ["N/A"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "Action",
  //     headerName: "Action",
  //     renderHeader: () => <strong>{"Action"}</strong>,
  //     flex: 1,
  //     minWidth: 310,
  //     align: "center",
  //     headerAlign: "center",
  //     renderCell: (params) => {
  //       return (
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "space-evenly",
  //           }}
  //         >
  //           <img
  //             src={
  //               hover.View && indexHover === params?.row?._id ? ViewHover : View
  //             }
  //             alt="VIEW"
  //             style={{
  //               height: "35px",
  //               cursor: "pointer",
  //               margin: "0px 3px 0px 3px",
  //             }}
  //             onMouseOver={() => {
  //               setHover({ View: true });
  //               setIndexHover(params?.row?._id);
  //             }}
  //             onMouseLeave={() => {
  //               setHover({ View: false });
  //             }}
  //           />
  //           <img
  //             onClick={() => handleDelete(params?.row?._id)}
  //             src={
  //               hover.Delete && indexHover === params?.row?._id
  //                 ? DeleteHover
  //                 : Delete
  //             }
  //             alt="Delete"
  //             style={{
  //               height: "35px",
  //               cursor: "pointer",
  //               margin: "0px 3px 0px 3px",
  //             }}
  //             onMouseOver={() => {
  //               setHover({ Delete: true });
  //               setIndexHover(params?.row?._id);
  //             }}
  //             onMouseLeave={() => {
  //               setHover({ Delete: false });
  //             }}
  //           />
  //           <IconButton
  //             aria-label="expand row"
  //             size="small"
  //             onClick={() => handleExpand(params?.row)}
  //           >
  //             {params?.row?._id === expandedData?._id && open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  //           </IconButton>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Header
        ArchivedListTotalItems={ArchivedListTotalItems?.total_item}
        totalitems={totalitems?.total_item}
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        setCheckedArchive={setCheckedArchive}
        setActiveChecked={setActiveChecked}
        checkedArchive={checkedArchive}
      />
      {/* {Loader ? (
        <Progressbar /> */}
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            // height: "80vh",
            height: "calc(100vh - 220px)",
            mt: 1,
            p: 1,
            overflowX: "auto",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          {/* {wasteData && wasteData?.length > 0 ? (
            <>
              <DataGrid
                disableColumnMenu
                rows={wasteData}
                columns={columns}
                getRowId={(wasteData) => wasteData._id}
                getRowHeight={() => "auto"}
                hideFooter={true}
                hideFooterPagination={true}
              />
              <Row wasteData={wasteData} open={open} />
            </>
          ) : (
            " No Data Found"
          )} */}
          {/* <Row wasteData={wasteData} /> */}
          <TableContainer className="grid-table">
            <Table>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Log ID
                </TableCell>
                <TableCell align="center" className="tableHead">
                  User
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Created at
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Updated at
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Store
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Note
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Products
                </TableCell>
                {activeChecked == true && (
                  <TableCell align="center" className="tableHead">
                    Action
                  </TableCell>
                )}
              </TableRow>
              {searchData !== "" && searchList !== "" && searchList?.length > 0
                ? searchList?.map((row, i) => {
                    return (
                      <>
                        <TableRow key={i}>
                          <TableCell align="center">{row?._id}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" spacing={2}>
                              <Stack direction="column">
                                <Typography variant="p" component="b">
                                  {row?.addedBy?.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            <Stack direction="column">
                              <Typography variant="p" component="p">
                                {moment(row?.createdAt).format("LTS")}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            <Stack direction="column">
                              <Typography variant="p" component="p">
                                {moment(row?.updatedAt).format("MMMM D, YYYY")}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            {row?.store?.name ? row?.store?.name : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.note ? row?.note : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.products?.length}
                          </TableCell>
                          {activeChecked == true && (
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    hover.View && indexHover === row?._id
                                      ? ViewHover
                                      : View
                                  }
                                  alt="VIEW"
                                  style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    margin: "0px 3px 0px 3px",
                                  }}
                                  onClick={() => handleChangeView(row?._id)}
                                  onMouseOver={() => {
                                    setHover({ View: true });
                                    setIndexHover(row?._id);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ View: false });
                                  }}
                                />
                                <img
                                  onClick={() => handleDelete(row?._id)}
                                  src={
                                    hover.Delete && indexHover === row?._id
                                      ? DeleteHover
                                      : Delete
                                  }
                                  alt="Delete"
                                  style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    margin: "0px 3px 0px 3px",
                                  }}
                                  onMouseOver={() => {
                                    setHover({ Delete: true });
                                    setIndexHover(row?._id);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ Delete: false });
                                  }}
                                />
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleExpand(row)}
                                >
                                  {row?._id === expandedData?._id && open ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                        {activeChecked == true && (
                          <>
                            {row?._id === expandedData?._id ? (
                              <TableRow>
                                <TableCell
                                  style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    backgroundColor: "#E2E8F0",
                                    borderRadius: "8px",
                                  }}
                                  colSpan={8}
                                >
                                  <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box sx={{ margin: 2 }}>
                                      <TableContainer
                                        size="large"
                                        component={Paper}
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Log ID
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                User
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Store
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Note
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Products
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Created at
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Updated at
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell align="center">
                                                {expandedData?._id}
                                              </TableCell>
                                              <TableCell align="left">
                                                <Stack
                                                  direction="row"
                                                  spacing={2}
                                                >
                                                  <Stack direction="column">
                                                    <Typography
                                                      variant="p"
                                                      component="b"
                                                    >
                                                      {
                                                        expandedData?.addedBy
                                                          ?.name
                                                      }
                                                    </Typography>
                                                  </Stack>
                                                </Stack>
                                              </TableCell>
                                              <TableCell align="center">
                                                {expandedData?.store?.name
                                                  ? expandedData?.store?.name
                                                  : "N/A"}
                                              </TableCell>
                                              <TableCell align="center">
                                                {expandedData?.note
                                                  ? expandedData?.note
                                                  : "N/A"}
                                              </TableCell>
                                              <TableCell align="center">
                                                {expandedData?.products?.length}
                                              </TableCell>
                                              <TableCell align="center">
                                                <Stack direction="column">
                                                  <Typography
                                                    variant="p"
                                                    component="p"
                                                  >
                                                    {moment(
                                                      expandedData?.createdAt
                                                    ).format("LTS")}
                                                  </Typography>
                                                </Stack>
                                              </TableCell>
                                              <TableCell align="center">
                                                <Stack direction="column">
                                                  <Typography
                                                    variant="p"
                                                    component="p"
                                                  >
                                                    {moment(
                                                      expandedData?.updatedAt
                                                    ).format("MMMM D, YYYY")}
                                                  </Typography>
                                                </Stack>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    );
                  })
                : searchData === "" && wasteData !== "" && wasteData?.length > 0
                ? wasteData?.map((row, i) => {
                    return (
                      <>
                        <TableRow key={i}>
                          <TableCell align="center">{row?._id}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" spacing={2}>
                              <Stack direction="column">
                                <Typography variant="p" component="b">
                                  {row?.addedBy?.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            <Stack direction="column">
                              <Typography variant="p" component="p">
                                {moment(row?.createdAt).format("LTS")}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            <Stack direction="column">
                              <Typography variant="p" component="p">
                                {moment(row?.updatedAt).format("MMMM D, YYYY")}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            {row?.store?.name ? row?.store?.name : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.note ? row?.note : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.products?.length}
                          </TableCell>
                          {activeChecked == true && (
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    hover.View && indexHover === row?._id
                                      ? ViewHover
                                      : View
                                  }
                                  alt="VIEW"
                                  style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    margin: "0px 3px 0px 3px",
                                  }}
                                  onClick={() => handleChangeView(row?._id)}
                                  onMouseOver={() => {
                                    setHover({ View: true });
                                    setIndexHover(row?._id);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ View: false });
                                  }}
                                />
                                {permission?.wastageLog?.manageWastageLog ===
                                true ? (
                                  <img
                                    onClick={() => handleDelete(row?._id)}
                                    src={
                                      hover.Delete && indexHover === row?._id
                                        ? DeleteHover
                                        : Delete
                                    }
                                    alt="Delete"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                      margin: "0px 3px 0px 3px",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Delete: true });
                                      setIndexHover(row?._id);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Delete: false });
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleExpand(row)}
                                >
                                  {row?._id === expandedData?._id && open ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                        {activeChecked == true && (
                          <>
                            {" "}
                            {row?._id === expandedData?._id ? (
                              <TableRow>
                                <TableCell
                                  style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    backgroundColor: "#E2E8F0",
                                    borderRadius: "8px",
                                  }}
                                  colSpan={8}
                                >
                                  <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box sx={{ margin: 2 }}>
                                      <TableContainer
                                        size="large"
                                        component={Paper}
                                        sx={{ borderRadius: "8px" }}
                                      >
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                S. No.
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Product Name
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Category
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Description
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Quantity
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                UOM
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Price
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                className="tableHead"
                                              >
                                                Total Price
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {row?.products &&
                                              row?.products?.length > 0 &&
                                              row?.products?.map(
                                                (productitem, i) => {
                                                  return (
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        {i + 1}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {
                                                          productitem?.product
                                                            ?.title
                                                        }
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {
                                                          productitem?.product
                                                            ?.category
                                                            ?.description
                                                        }
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {
                                                          productitem?.product
                                                            ?.description
                                                        }
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {productitem?.quantity}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {
                                                          productitem?.product
                                                            ?.uom?.title
                                                        }
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {
                                                          productitem?.product
                                                            ?.price
                                                        }
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {productitem?.product
                                                          ?.price *
                                                          productitem?.quantity}
                                                      </TableCell>
                                                    </TableRow>
                                                  );
                                                }
                                              )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    );
                  })
                : ""}
            </Table>
          </TableContainer>
          <TablePagination
            className="tablepagination"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalitems?.total_item },
            ]}
            // colSpan={3}
            count={totalCount}
            colSpan={7}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            SelectProps={{
              inputProps: { "aria-label": "Items per Page" },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Items per Page"}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </>
  );
  
};

export default WasteManagement;
