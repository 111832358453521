import React, { useEffect, useState, useCallback, memo } from "react";
import { Box, Grid, Stack, Button, Card, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import StarIcon from "@mui/icons-material/Star";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import {
  AddChecklistImageAnswerApi,
  CreateImageLogApi,
} from "../redux/actions/Checklist";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import Modal from "@mui/material/Modal";
import { ChecklistImageApi } from "../redux/actions/Checklist";
import ReactImageZoom from "react-image-zoom";
import ImgEditor from "./ImgEditor";
import Pdf from "react-to-pdf";
import { createRef } from "react";
import YellowStar from "../../assets/icons/png-icons/yellow-star.png";
import ThumbupIcon from "../../assets/icons/png-icons/thumbup.png";
import ThumbdownIcon from "../../assets/icons/png-icons/thumbdown.png";
import CommentIcon from "../../assets/icons/png-icons/comment.png";
import jsPDF from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import moment from "moment";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const downloadstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 400,
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const editStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "95%",
  width: "95%",
  bgcolor: "background.paper",
  border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const options = {
  format: "a4",
  orientation: "p",
  unit: "mm",
  userUnit: 300,
};

const ImageViewerModal = ({
  isQusImg,
  updateLike,
  updateComment,
  updateDislike,
  getImageId,
  setModalOpens,
  setOpenChild,
  openChild,
  setComment,
  tags,
  setTags,
  comment,
  setImageId,
  setRating,
  rating,
  like,
  setLike,
  disLike,
  setDisLike,
  selectedImageIndex,
  imageLogApiData,
  uniqueUserId,
  ImageFirstkey,
  commentFirst,
  setUiRender,
  selectedImage,
  openImgformIndex,
  openImgTitle,
}) => {
  const dispatch = useDispatch();
  const [imageKey, setImagesKey] = useState();
  const [imageComment, setImageComment] = useState();
  const [newComment, setNewComment] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openInspection, setOpenInspection] = useState(false);
  const [promptQuery, setPromptQuery] = useState('');
  const [lodingPrompt, setLodingPrompt] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [editImg, setEditImg] = useState("");
  const [inspectionResp, setInspectionResp] = useState("");
  const starData = [1, 2, 3, 4, 5];
  var ref = createRef();

  const createImagePayload = {
    body: {
      imageKey: imageKey ? imageKey : ImageFirstkey,
      comment: comment,
      rating: rating,
      like: like,
      dislike: disLike,
    },
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  // useEffect(() => {
  //   if (imageLogApiData) {
  //     let idList = imageLogApiData.map((ids, i) => ids.Forms);

  //     let result = [];
  //     for (let i = 0; i < idList.length; i++) {
  //       let newArray = idList[i];
  //       let filteredArray = newArray.filter((newItem) => {
  //         let userId = newItem?.userImages?.ImageId;
  //         if (userId == uniqueUserId) {
  //           return newItem?.questionImages;
  //         }
  //       });
  //       result.push(filteredArray);
  //     }

  //     let questionList;
  //     for (let i = 0; i < result.length; i++) {
  //       let newArray = result[i];
  //       if (newArray.length > 0) {
  //         questionList = newArray[0]?.questionImages;
  //         break;
  //       }
  //     }

  //     setNewComment(questionList);
  //   }
  // }, [imageLogApiData, uniqueUserId]);

  function BootstrapDialogTitle(props) {
    const { children, onClose } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const handleClose = () => setOpen(false);

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCloseInspection = () => {
    setOpenInspection(false);
  };

  const handleSave = (fileData, designState) => {
    // const imagefile = {
    //   file: fileData.imageBase64,
    //   name: fileData.name,
    // };

    const linkSource = `${fileData.imageBase64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileData.name;
    downloadLink.click();
    // return 

    // const createLog = {
    //   imageKey: selectedImage.key,
    //   editImageKey: "",
    // };

    // const imageData = {
    //   body: imagefile,
    // };

    // const imageCreateLogData = {
    //   body: createLog,
    // };
    // const id = localStorage.getItem("companyId");

    // imageCreateLogData.url = BASE_URL + `CreateImagelog?company_id=${id}`;
    // imageData.url = BASE_URL + "uploads/base64";

    // dispatch(AddChecklistImageAnswerApi(imageData, imageCreateLogData));
  };

  const handleCloseChild = () => {
    setOpenChild(false);
  };

  const handleOpenChild = () => {
    setOpenChild(true);
    setComment(imageComment ? imageComment : comment);
    setTags(tags)
    setRating(rating);
    setLike(like);
    setDisLike(disLike);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleOpenInspection = () => {
    setOpenInspection(true);
  };

  const handleSubmit = () => {
    const id = localStorage.getItem("companyId");
    createImagePayload.url = BASE_URL + `CreateImagelog?company_id=${id}`;
    dispatch(CreateImageLogApi(createImagePayload));
    setUiRender("postapires");
    setOpenChild(false);
  };

  const handledata = (data) => {
    setEditImg(data.url);
    setImageComment(data?.comment);
    setRating(data?.rating);
    if (data && data.name) {
      setImagesKey(data.name);
    }
    if (data) {
      setImageId(data?.ImageId);
    }
    let id = data?.ImageId;
    let imageKey = data?.name;
    if (id) {
      localStorage.setItem("localImageId", id);
    }
    if (imageKey) {
      localStorage.setItem("imageKey", imageKey);
    }
  };

  const handleCommentRating = () => {
    updateComment(selectedImage);
  };

  const handleGenerateInspection = async () => {
    if(!promptQuery)
    return;
    setLodingPrompt(true);
    const body = {
      "model": "gpt-4-turbo",
      "messages": [
        {
          "role": "user",
          "content": [
            {
              "type": "text",
              "text": promptQuery
            },
            {
              "type": "image_url",
              "image_url": {
                "url": selectedImage.url
                // "url": 'https://popprobe-saas.s3.us-west-2.amazonaws.com/IMGPOPPROBE_Sto_Com_12_Man_2024-05-13-13-45-12-1715588111948?AWSAccessKeyId=AKIAIAWD4CEY36LXX6AQ&Expires=1718203099&Signature=RMqsZEoC6VrUm%2BOL6HBua4pr0n0%3D'
              }
            }
          ]
        }
      ],
      "max_tokens": 300
    }
    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
      }).then((response) => {
        console.log('response', response, response?.data?.choices[0]?.message?.content)
        if(response?.data?.choices[0]?.message?.content){
          setInspectionResp(response?.data?.choices[0]?.message?.content);
        }else{
          setInspectionResp('Responce Not Found');
        }
        setLodingPrompt(false);
      })
    } catch (error) {
       console.log(error);
       setLodingPrompt(false);
       setInspectionResp('Responce Not Found');
    }
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "rgb(12 36 49)",
            color: "#ffffff",
            padding: "12px",
            alignItems: "center",
          }}
        >
          Question Image
          <div style={{ display: "flex" }}>
            <Button
              style={{
                color: "white",
                backgroundColor: "#33B248",
                paddingLeft: "20px",
                paddingRight: "20px",
                textAlign: "center",
                fontSize: "10px",
                letterSpacing: "1px",
                marginRight: "20px",
              }}
              onClick={handleOpenInspection}
            >
              Popprobe Inspection
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#f26539",
                paddingLeft: "20px",
                paddingRight: "20px",
                textAlign: "center",
                fontSize: "10px",
                letterSpacing: "1px",
                marginRight: "20px",
              }}
              onClick={handleOpenEdit}
            >
              Edit
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#f26539",
                paddingLeft: "20px",
                paddingRight: "20px",
                textAlign: "center",
                fontSize: "10px",
                letterSpacing: "1px",
                marginRight: "20px",
              }}
              onClick={handleOpenChild}
            >
              Add comment/rating
            </Button>
            <Button
              style={{
                color: "white",
                backgroundColor: "#f26539",
                paddingLeft: "20px",
                paddingRight: "20px",
                textAlign: "center",
                fontSize: "10px",
                letterSpacing: "1px",
              }}
              onClick={() => setOpenDownload(true)}
            >
              Download
            </Button>
            <Button
              id="modal-modal-title"
              onClick={() => setModalOpens(false)}
              sx={{
                pt: 1,
                marginLeft: "15px",
                cursor: "pointer",
                color: "#ffffff",
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div
          className="Img-container"
          style={{
            borderRadius: "3px",
            height: "auto",
          }}
          // onMouseEnter={(e) => {
          //   handledata(qusImgs);
          // }}
          // onMouseLeave={(e) => {
          //   handledata(qusImgs);
          // }}
        >
          <div style={{ width: "520px", margin: "0 auto" }}>
            <ReactImageZoom
              width={520}
              height={500}
              img={selectedImage.url}
              zoomPosition={"original"}
            />
          </div>
          <div
            className="content"
            style={{
              padding: "0px",
              bottom: "35px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="imagview-image-log"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                // gap: "10px",
              }}
            >
              <div
                className="rating-contaier"
                style={{
                  width: "8%",
                }}
              >
                <Typography
                  sx={{
                    marginLeft: "10px",
                    display: "flex",
                  }}
                >
                  {selectedImage?.rating ? selectedImage?.rating : 0}
                  <StarIcon
                    onClick={() => getImageId(selectedImage, index)}
                    style={{
                      marginBottom: "-5px",
                      marginLeft: "10px",
                      color: "#f26539",
                      cursor: "pointer",
                    }}
                  ></StarIcon>
                </Typography>
              </div>
              <div
                className="comment-contaier"
                style={{
                  width: "92%",
                  paddingRight: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    cursor: "pointer",
                    columnGap: "20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      alignItems: "center",
                      maxWidth: "94%",
                    }}
                  >
                    {selectedImage?.comment && (
                      <>
                        <ChatBubbleOutlineIcon
                          style={{
                            color: "#FFF",
                          }}
                        />
                        <p
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {selectedImage?.comment}
                        </p>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "flex-end",
                      width: "6%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <ThumbUpIcon
                        onClick={() => updateLike(selectedImage)}
                        style={{
                          color: "#33b249",
                        }}
                      ></ThumbUpIcon>
                      {selectedImage?.like ? selectedImage?.like : 0}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <ThumbDownIcon
                        onClick={() => updateDislike(selectedImage)}
                        style={{
                          color: "#ff0000",
                        }}
                      ></ThumbDownIcon>
                      {selectedImage?.dislike ? selectedImage?.dislike : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={openInspection}
          onClose={handleCloseInspection}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={editStyle}>
            <TextField
                id="outlined-basic"
                label="promptQuery"
                variant="outlined"
                value={promptQuery}
                sx={{ width: "100%" }}
                fullWidth
                onChange={(e) => setPromptQuery(e.target.value)}
              />
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#33B248",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  textAlign: "center",
                  fontSize: "10px",
                  letterSpacing: "1px",
                  marginRight: "20px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={handleGenerateInspection}
              >
                Generate Inspection
              </Button>
              {lodingPrompt ? "Please wait while we generate query responce..." : null}
              {
                inspectionResp ?
                <Markdown remarkPlugins={[remarkGfm]}>{inspectionResp}</Markdown> : null 
              }
              
          </Box>
        </Modal>
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={editStyle}>
            <ImgEditor
              handleSave={handleSave}
              onClose={handleCloseEdit}
              imgsrc={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${selectedImage.key}`}
            />
          </Box>
        </Modal>
        <Modal
          open={openChild}
          onClose={handleCloseChild}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Comment/Rating
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { marginTop: "15px", width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Comment"
                variant="outlined"
                value={comment}
                sx={{ width: "100%" }}
                fullWidth
                onChange={(e) => setComment(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Tags"
                variant="outlined"
                value={tags}
                sx={{ width: "100%" }}
                fullWidth
                onChange={(e) => setTags(e.target.value)}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label for="cars">Select Rating</label>
                <div style={{ display: "flex" }}>
                  {starData.map((item) => {
                    return (
                      <Typography
                        sx={{
                          marginLeft: "10px",
                        }}
                      >
                        <StarIcon
                          onClick={() => setRating(item)}
                          style={{
                            marginBottom: "-5px",
                            marginLeft: "10px",
                            color: rating >= item && "#f26539",
                            cursor: "pointer",
                          }}
                        ></StarIcon>
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </Box>
            <Button
              onClick={(e) => {
                imageComment === null || commentFirst === null
                  ? handleSubmit(e)
                  : handleCommentRating(e);
                setOpenChild(false);
              }}
              disabled={!comment && !tags && !rating}
              className="comment-button"
              sx={{
                marginTop: "30px",
                padding: "6px 12px 6px 12px",
                background: "rgb(12 36 49)",
                color: "#ffffff",
              }}
            >
              Update
            </Button>
            <Button
              onClick={handleCloseChild}
              className="cancel-button"
              sx={{
                backgroundColor: "#ec3901",
                color: "#ffffff",
                marginTop: "30px",
                marginLeft: "30px",
                padding: "6px 12px 6px 12px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
        <Modal
          open={openDownload}
          onClose={() => setOpenDownload(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={downloadstyle}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div ref={(el) => (ref = el)}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "60px 0",
                  }}
                >
                  <img
                    src="https://client.popprobe.com/assets/icons/newLogo.png"
                    alt=""
                    data-default="placeholder"
                    data-max-width="560"
                    height="80"
                  />
                </div>
                <Grid item xs={2} sm={4} md={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "64px",
                    }}
                  >
                    {imageLogApiData?.map((item, index) => {
                      return (
                        index === openImgformIndex &&
                        item.Forms.map((formdata) => {
                          return (
                            formdata.title === openImgTitle && (
                              <>
                                <Typography
                                  align="left"
                                  style={{
                                    fontSize: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                  }}
                                >
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    <strong>Checklist Name:</strong>
                                  </span>
                                  <span style={{ wordBreak: "break-alls" }}>
                                    {item.category}
                                  </span>
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{
                                    fontSize: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                  }}
                                >
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    <strong>User name:</strong>
                                  </span>
                                  <span style={{ wordBreak: "break-alls" }}>
                                    {item.userName}
                                  </span>
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{
                                    fontSize: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                  }}
                                >
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    <strong>Store Location:</strong>
                                  </span>
                                  <span style={{ wordBreak: "break-alls" }}>
                                    -
                                  </span>
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{
                                    fontSize: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                  }}
                                >
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    <strong>Submit Date:</strong>
                                  </span>
                                  <span style={{ wordBreak: "break-alls" }}>
                                    {moment(formdata.submittedTime).format(
                                      "lll"
                                    )}
                                  </span>
                                </Typography>
                              </>
                            )
                          );
                        })
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "16px",
                      color: "black",
                    }}
                  >
                    <>
                      <Typography
                        align="left"
                        style={{
                          fontSize: "15px",
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px",
                        }}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <strong>Question:</strong>
                        </span>
                        <span
                          style={{
                            wordBreak: "break-alls",
                          }}
                        >
                          {selectedImage.question.title}
                        </span>
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          fontSize: "15px",
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px",
                        }}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <strong>Answer Rank:</strong>
                        </span>
                        <span
                          style={{
                            wordBreak: "break-alls",
                          }}
                        >
                          {selectedImage.question.question_score}
                        </span>
                      </Typography>
                    </>
                  </div>
                  <div className="dowImg-container">
                    <img
                      src={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${selectedImage.key}`}
                      loading={true}
                      className="captured-img"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="rating-contaier">
                          {" "}
                          <Typography
                            sx={{
                              padding: "4px 12px",
                              fontSize: "15px",
                            }}
                          >
                            {selectedImage?.rating ? selectedImage?.rating : 0}{" "}
                            <img
                              src={YellowStar}
                              style={{
                                height: "17px",
                                width: "17px",
                                cursor: "pointer",
                                marginBottom: "-3px",
                              }}
                            />
                          </Typography>
                        </div>
                        <div className="like-dislike-container">
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              cursor: "pointer",
                              fontSize: "15px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={ThumbupIcon}
                              alt=""
                              style={{
                                height: "17px",
                                width: "17px",
                                cursor: "pointer",
                              }}
                            />
                            {selectedImage?.like ? selectedImage?.like : 0}
                          </p>
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: "15px",
                              cursor: "pointer",
                              fontSize: "15px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={ThumbdownIcon}
                              alt=""
                              style={{
                                height: "17px",
                                width: "17px",
                                cursor: "pointer",
                              }}
                            />
                            {selectedImage?.dislike
                              ? selectedImage?.dislike
                              : 0}
                          </p>
                        </div>
                      </div>
                      {selectedImage?.comment && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            alignItems: "center",
                            width: "100%",
                            backgroundColor: "rgb(0, 0, 0, 0.5)",
                            padding: "4px 12px",
                            borderRadius: "3px",
                            alignContent: "center",
                          }}
                        >
                          <>
                            <img
                              src={CommentIcon}
                              alt=""
                              style={{
                                height: "17px",
                                width: "17px",
                                cursor: "pointer",
                              }}
                            />
                            <p
                              style={{
                                width: "90%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                margin: "0px",
                                fontSize: "16px",
                                textAlign: "start",
                                fontWeight: "500",
                              }}
                            >
                              {selectedImage?.comment}
                            </p>
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </div>
              <div style={{ marginTop: "10px" }}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: "#337ab7",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        textAlign: "center",
                        fontSize: "12px",
                        letterSpacing: "1px",
                        width: "100%",
                      }}
                    >
                      Download
                    </Button>
                  )}
                  content={() => ref}
                  documentTitle={selectedImage.question.title}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default memo(ImageViewerModal);
