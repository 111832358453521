/** @format */

import { toast } from "react-toastify";
import {
  CHAT_ATTACHMENT_ERROR,
  CHAT_ATTACHMENT_REQUEST,
  CHAT_ATTACHMENT_SUCCESS,
  CHAT_HISTORY_ERROR,
  CHAT_HISTORY_REQUEST,
  CHAT_HISTORY_SUCCESS,
  CHAT_USER_ERROR,
  CHAT_USER_REQUEST,
  CHAT_USER_SUCCESS,
  DELETE_CHAT_NOTIFICATION_ERROR,
  DELETE_CHAT_NOTIFICATION_REQUEST,
  DELETE_CHAT_NOTIFICATION_SUCCESS,
  GET_CHAT_NOTIFICATION_ERROR,
  GET_CHAT_NOTIFICATION_REQUEST,
  GET_CHAT_NOTIFICATION_SUCCESS,
  GROUP_CHAT_EDIT_ERROR,
  GROUP_CHAT_EDIT_REQUEST,
  GROUP_CHAT_EDIT_SUCCESS,
  GROUP_CHAT_ERROR,
  GROUP_CHAT_REQUEST,
  GROUP_CHAT_SUCCESS,
} from "../types/Types";
import JwtAuthService from "../apiConfig/ApiConfig";

export const ChatAttachmentRequest = (companyid) => ({
  type: CHAT_ATTACHMENT_REQUEST,
  payload: companyid,
});

export const ChatAttachmentSuccess = (companyid) => ({
  type: CHAT_ATTACHMENT_SUCCESS,
  payload: companyid,
});

export const ChatAttachmentFailed = (error) => ({
  type: CHAT_ATTACHMENT_ERROR,
  payload: error,
});

export const ChatAttachmentApi = (data) => (dispatch) => {
  try {
    dispatch(ChatAttachmentRequest(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ChatAttachmentSuccess(data));
        toast.success(`${data?.data?.message}`);
      } else {
        dispatch(ChatAttachmentFailed(response?.response?.statusText));
        toast.error(`${data?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChatAttachmentFailed(error));
  }
};

export const ChatUserListRequest = () => ({
  type: CHAT_USER_REQUEST,
});
export const ChatUserListSuccess = (list) => ({
  type: CHAT_USER_SUCCESS,
  payload: list,
});
export const ChatUserListFailed = (error) => ({
  type: CHAT_USER_ERROR,
  payload: error,
});

export const ChatUserListApi = (data) => (dispatch) => {
  try {
    dispatch(ChatUserListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ChatUserListSuccess(response));
      } else {
        dispatch(ChatUserListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChatUserListFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const ChatHistoryRequest = () => ({
  type: CHAT_HISTORY_REQUEST,
});
export const ChatHistorySuccess = (list) => ({
  type: CHAT_HISTORY_SUCCESS,
  payload: list,
});
export const ChatHistoryFailed = (error) => ({
  type: CHAT_HISTORY_ERROR,
  payload: error,
});

export const ChatHistoryApi = (data) => (dispatch) => {
  try {
    dispatch(ChatHistoryRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ChatHistorySuccess(response));
      } else {
        dispatch(ChatHistoryFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChatHistoryFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const GroupChatUserListRequest = () => ({
  type: GROUP_CHAT_REQUEST,
});
export const GroupChatUserListSuccess = (list) => ({
  type: GROUP_CHAT_SUCCESS,
  payload: list,
});
export const GroupChatUserListFailed = (error) => ({
  type: GROUP_CHAT_ERROR,
  payload: error,
});

export const GroupChatUserListApi = (data) => (dispatch) => {
  try {
    dispatch(GroupChatUserListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(GroupChatUserListSuccess(response));
      } else {
        dispatch(GroupChatUserListFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(GroupChatUserListFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const GroupChatUserEditRequest = () => ({
  type: GROUP_CHAT_EDIT_REQUEST,
});
export const GroupChatUserEditSuccess = (list) => ({
  type: GROUP_CHAT_EDIT_SUCCESS,
  payload: list,
});
export const GroupChatUserEditFailed = (error) => ({
  type: GROUP_CHAT_EDIT_ERROR,
  payload: error,
});

export const GroupChatUserEditApi = (data) => (dispatch) => {
  try {
    dispatch(GroupChatUserEditRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        toast.success(`${response?.data?.message}`);
        dispatch(GroupChatUserEditSuccess(response));
        console.log("resp", response);
      } else {
        dispatch(
          GroupChatUserEditFailed(`${response?.response?.data?.message}`)
        );
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(GroupChatUserEditFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const GetChatNotificationRequest = () => ({
  type: GET_CHAT_NOTIFICATION_REQUEST,
});
export const GetChatNotificationSuccess = (list) => ({
  type: GET_CHAT_NOTIFICATION_SUCCESS,
  payload: list,
});
export const GetChatNotificationFailed = (error) => ({
  type: GET_CHAT_NOTIFICATION_ERROR,
  payload: error,
});

export const GetChatNotificationApi = (data) => (dispatch) => {
  try {
    dispatch(GetChatNotificationRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(GetChatNotificationSuccess(response));
      } else {
        dispatch(GetChatNotificationFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(GetChatNotificationFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const DeleteChatNotificationRequest = () => ({
  type: DELETE_CHAT_NOTIFICATION_REQUEST,
});
export const DeleteChatNotificationSuccess = (list) => ({
  type: DELETE_CHAT_NOTIFICATION_SUCCESS,
  payload: list,
});
export const DeleteChatNotificationFailed = (error) => ({
  type: DELETE_CHAT_NOTIFICATION_ERROR,
  payload: error,
});

export const DeleteChatNotificationApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteChatNotificationRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteChatNotificationSuccess(response));
      } else {
        dispatch(DeleteChatNotificationFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(DeleteChatNotificationFailed(error));
    console.error("Something went Wrong", error);
  }
};
