import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TableHeader from "./TableHeader";
import { Typography, Grid, TablePagination, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Edit from "../../../../assets/icons/svg-icons/Edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { IndividualStoreTariningViewListApi } from "../../../redux/actions/LmsAction";

const IndividualStoreTraining = () => {
  const IndividualStoreTrainingList = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.IndividualStoreTraining &&
      state?.lms?.IndividualStoreTraining?.data &&
      state?.lms?.IndividualStoreTraining?.data?.data &&
      state?.lms?.IndividualStoreTraining?.data?.data?.Responses
  );
  const totalpage = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.IndividualStoreTraining &&
      state?.lms?.IndividualStoreTraining?.data &&
      state?.lms?.IndividualStoreTraining?.data?.data &&
      state?.lms?.IndividualStoreTraining?.data?.data?.paginate
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  useEffect(() => {
    if (searchValue === "") {
      paginationRequest.body.search = searchValue;
      AllTrainingList(paginationRequest);
    }
  }, [searchValue]);

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    paginationRequest.body.pagination.page = newPage + 1;
    paginationRequest.body.search = "";
    AllTrainingList(paginationRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    paginationRequest.body.search = "";
    AllTrainingList(paginationRequest);
  };

  const handleChange = (event) => {
    paginationRequest.body.search = searchValue;
    if (searchValue != "") {
      paginationRequest.body.search = searchValue;
    }
    AllTrainingList(paginationRequest);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchValue !== "") {
        paginationRequest.body.pagination.page = 1;
        paginationRequest.body.search = searchValue;
        AllTrainingList(paginationRequest);
        setPage(1);
      }
    }
  };

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setTotalCount(totalpage?.total_item);
  }, [totalpage]);

  const AllTrainingList = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    const StoreId = localStorage.getItem("StoreId");
    const UserId = location?.state?.UserId?._id;
    paginationRequest.url =
      BASE_URL +
      `trainingByUserUnderStore?company_id=${id}&storeId=${StoreId}&UserId=${UserId}`;
    dispatch(IndividualStoreTariningViewListApi(paginationRequest));
  };

  const handleClick = () => {
    navigate("/training/edit-training");
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      renderHeader: () => <strong>{"Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.Name);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Role",
      headerName: "Role",
      renderHeader: () => <strong>{"Role"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.SubRole);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Training Completed",
      headerName: "Training Completed",
      renderHeader: () => <strong>{"Training Completed"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.Submitted);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Training In-Progress",
      headerName: "Training In-Progress",
      renderHeader: () => <strong>{"Training In-Progress"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.TrainingInProgress);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Training Created",
      headerName: "Training Created",
      renderHeader: () => <strong>{"Training Created"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.TrainingCreated);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
  ];

  return (
    <Box>
      <TableHeader
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        // ArchivedApi={ArchivedApi}
        rowsPerPage={rowsPerPage}
        page={page}
        totalpage={totalpage}
        setPage={setPage}
      />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "5px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        {/* <TableContainer component={Paper}>
          <Table size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Store
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Role
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Training
                  <br /> Completed
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Training
                  <br /> In-Progress
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Training Created
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    <Typography variant="p" component="b">
                      {row.Store}
                    </Typography>
                    <Typography variant="p" component="p">
                      {row.Number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{row.Role}</TableCell>
                  <TableCell align="center">{row.TrainingCompleted}</TableCell>
                  <TableCell align="center">{row.TrainingInProgress}</TableCell>
                  <TableCell align="center">{row.TrainingCreated}</TableCell>
                  <TableCell align="center">
                    <Chip
                      avatar={<img src={Edit} alt="Edit" />}
                      onClick={handleClick}
                      variant="outlined"
                      className="iconBox"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        {IndividualStoreTrainingList ? (
          <>
            <DataGrid
              disableColumnMenu
              rows={IndividualStoreTrainingList}
              columns={columns}
              getRowId={(IndividualStoreTrainingList) =>
                IndividualStoreTrainingList._id
              }
              getRowHeight={() => "auto"}
              hideFooter={true}
              hideFooterPagination={true}
              className="grid-table"
            />
          </>
        ) : (
          "No Data Found"
        )}
        <TablePagination
          className="tablepagination"
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: "All", value: totalpage?.total_item },
          ]}
          // colSpan={3}
          count={totalCount}
          colSpan={7}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          SelectProps={{
            inputProps: { "aria-label": "Items per Page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Items per Page"}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      {/* <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 1, borderRadius: 1 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              showFirstButton={false}
            />
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
};

export default IndividualStoreTraining;
