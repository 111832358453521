/** @format */

import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import Fab from "@material-ui/core/Fab";
import attechment from "../../../images/attechment.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  AddIssueApi,
  AddIssueImageApi,
  AddIssueMediaApi,
  AddIssueMediaSuccess,
} from "../../../redux/actions/Issue";

import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import * as yup from "yup";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
const MenuProps = {
  disableScrollLock: true,
};

const NewIssueCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [categoryList, setCategoryList] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [storeList, setStoreList] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const [file, setFile] = useState([]);
  const [mediaKeys, setMediaKeys] = useState([]);
  const [error, setError] = useState("");

  const [loader, setLoader] = useState(false);
  const getImageKey = useSelector(
    (state) =>
      state.issue &&
      state.issue.addImage &&
      state.issue.addImage.data &&
      state.issue.addImage.data.data
  );
  const getMediaKey = useSelector(
    (state) =>
      state.issue &&
      state.issue.addMedia &&
      state.issue.addMedia.data &&
      state.issue.addMedia.data.data || []
  );
  console.log("getMediaKey", getMediaKey, mediaKeys);

  const newIssue = useSelector(
    (state) => state.issue && state.issue.addIssue && state.issue.addIssue.data
  );

  const errorImg = useSelector(
    (state) => state.issue && state.issue.error && state.issue.error.data
  );

  useEffect(() => {
    if (newIssue && newIssue.status === 200) {
      setLoader(false);
      toast.success(newIssue.message, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/issues/list");
      }, 2000);
    }

    if (errorImg && errorImg.status !== 200) {
      setLoader(false);
      setTimeout(() => {
        navigate("/issues/list");
      }, 2000);
    }
  }, [newIssue, errorImg]);

  useEffect(() => {
    if (image) {
      setPreview(URL.createObjectURL(image));
    }
  }, [image]);

  function getFilteredList() {
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return [];
    }
    return categoryData.filter((item) => item.title === selectedCategory);
  }

  const filteredList = useMemo(getFilteredList, [
    selectedCategory,
    categoryData,
  ]);

  useEffect(() => {
    if (categoryList) {
      setCategoryData(categoryList);
    }
  }, [categoryList]);

  useEffect(() => {
    const issueDepartmentList = JSON.parse(
      localStorage.getItem("departmentData")
    );
    const issueCategoryList = JSON.parse(localStorage.getItem("categoryData"));
    const issueStoreList = JSON.parse(localStorage.getItem("storeData"));
    setDepartmentList(issueDepartmentList);
    setCategoryList(issueCategoryList);
    setStoreList(issueStoreList);

    if (issueStoreList) {
      let arr = [];
      for (let item of issueStoreList) {
        let obj = { _id: item?._id, title: item?.name };
        arr.push(obj);
      }
      setMapSelectData(arr);
    }
  }, []);

  // select image code
  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
    if (preview !== "") {
      const formData = new FormData();
      formData.append("File", {
        name: image?.name,
        type: image?.type,
        size: image?.size,
      });
    }
  };

  // useEffect(() => {
  //   if (getImageKey && getImageKey[0].fileKey) {
  //     addAttachment(formik.values, getImageKey[0].fileKey);
  //   }
  // }, [getImageKey]);
  // console.log("=========>", getImageKey);

  const usernameRegex = /^[a-zA-Z0-9\s]+$/;
  const validationSchema = yup.object().shape({
    issue: yup
      .string()
      .required("Issue is required")
      .max(50, "Max length must be of 50 characters"),
      // .matches(usernameRegex, "Special characters not allowed for first name"),
    severity: yup
      .string()
      .required("Severity is required")
      .max(50, "Max length must be of 50 characters"),
    // .matches(usernameRegex, "Special characters not allowed for first name"),

    category: yup
      .string()
      .required("Category is required")
      .max(50, "Max length must be of 50 characters"),
      // .matches(usernameRegex, "Special characters not allowed for first name"),
    subCategory: yup
      .string()
      .required("Sub-Category is required")
      .max(50, "Max length must be of 50 characters"),
      // .matches(usernameRegex, "Special characters not allowed for first name"),
    status: yup
      .string()
      .required("Status is required")
      .max(50, "Max length must be of 50 characters"),
      // .matches(usernameRegex, "Special characters not allowed for first name"),
    department: yup.object().required("department is required"),
    store: yup.object().required("store is required"),
    action: yup
      .string()
      .required("action is required")
      .max(50, "Max length must be of 50 characters"),
      // .matches(usernameRegex, "Special characters not allowed for first name"),
  });
  useEffect(() => {
    if (image && image.name && file && file.length) {
      if (
        getImageKey &&
        getImageKey[0].key &&
        mediaKeys.length === file.length
      ) {
        addAttachment(formik.values, getImageKey[0].key, mediaKeys);
      }
    } else if (file && file.length) {
      if (mediaKeys.length === file.length) {
        addAttachment(formik.values, "", mediaKeys);
      }
    } else if (image && image.name) {
      if (getImageKey && getImageKey[0].key) {
        addAttachment(formik.values, getImageKey[0].key, mediaKeys);
      }
    }
  }, [getImageKey, mediaKeys]);
  //Media Code is Here
  useEffect(() => {
    if (getMediaKey && getMediaKey.length && getMediaKey[0].key) {
      setMediaKeys((prev) => [...prev, getMediaKey[0].key]);
    }
  }, [getMediaKey]);
  //Media Code end

  const formik = useFormik({
    initialValues: {
      issue: "",
      severity: "",
      category: "",
      subCategory: "",
      status: "",
      department: "",
      store: "",
      action: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // add confirmation box here.
      const handleAddIssue = () => {
        confirmAlert({
          title: "Confirm to create",
          message: "Are you sure you want to create it?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                uploadAttachmentandMedia();
              },
            },
            {
              label: "No",
              // Do nothing if 'No' is clicked
            },
          ],
        });
      };
      handleAddIssue();
    },
  });
  const uploadAttachmentandMedia = () => {
    setLoader(true);
    const imagefile = {
      files: image,
    };
    const imageData = {
      body: imagefile,
    };
    if (image && image.name && file && file.length) {
      // to do
      file.forEach((fileData) => {
        const mediafile = {
          files: fileData,
        };
        const mediaData = {
          body: mediafile,
        };
        mediaData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
        dispatch(AddIssueMediaApi(mediaData));
      });
      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      dispatch(AddIssueImageApi(imageData));
    } else if (file && file.length) {
      file.forEach((fileData) => {
        const mediafile = {
          files: fileData,
        };
        const mediaData = {
          body: mediafile,
        };
        mediaData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
        dispatch(AddIssueMediaApi(mediaData));
      });
    } else if (image && image.name) {
      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      dispatch(AddIssueImageApi(imageData));
    } else {
      let imgKey = "";
      addAttachment(formik.values, imgKey, mediaKeys);
    }
  };
  const addAttachment = (values, image, mediaKeys = []) => {
    const formData = {
      observation: values.issue,
      severity: values.severity,
      category: values.category,
      subCategory: values.subCategory,
      issueStatus: values.status,
      departments: [values.department],
      store: values.store,
      action: values.action,
    };
    if (image == "") {
      formData.attachment = null;
    } else {
      formData.attachment = image;
    }
    if (file && file.length) {
      formData.mediaFiles = mediaKeys;
    } else {
      formData.mediaFiles = [];
    }
    const allData = {
      body: formData,
    };

    //Media Files Code Ended

    // if (allData) {
    //   const id = localStorage.getItem("companyId");
    //   allData.url = BASE_URL + `issue?company_id=${id}`;
    //   dispatch(AddIssueApi(allData));
    // }

    if (allData) {
      const id = localStorage.getItem("companyId");
      allData.url = BASE_URL + `issue?company_id=${id}`;
      dispatch(AddIssueApi(allData));
      dispatch(AddIssueMediaSuccess([]));
    }
  };

  useEffect(() => {
    setSelectedCategory(formik.values.category);
  }, [formik.values.category]);

  // add all media files code here

  //Media code here
  const handleRemoveFile = (indexToRemove) => {
    setFile(file.filter((_, index) => index !== indexToRemove));
  };
  //Media code end

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files;
    const selectedFiles = Array.from(event.target.files);
    const totalFiles = (file ? file.length : 0) + selectedFiles.length;

    // Check if the total number of files exceeds the limit
    console.log(selectedFile);
    [...event.target.files].forEach((file) => {
      setFile((prev) => [...prev, file]);
    });
  };
  //in between code media files code all
  useEffect(() => {
    if (file.length > 5) {
      setError("You can only upload up to 5 files.");
    } else {
      setError("");
    }
  }, [file]);

  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : null}
      <Box>
        <ToastContainer />
        <form onSubmit={formik.handleSubmit}>
          <Box className="header-card">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/issues/list" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Create
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                p: 1,
                textAlign: "left",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Issue</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="issue"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.issue}
                  MenuProps={MenuProps}
                  sx={{ borderRadius: "8px" }}
                />
                <small className="error">
                  {formik.errors.severity && formik.touched.issue
                    ? formik.errors.issue
                    : null}
                </small>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Severity</label>
                <Select
                  size="small"
                  name="severity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.severity}
                  MenuProps={MenuProps}
                  sx={{ borderRadius: "8px" }}
                >
                  <MenuItem value="Critical">Critical</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Non-Critical">Non-Critical</MenuItem>
                </Select>
                <small className="error">
                  {formik.errors.severity && formik.touched.severity
                    ? formik.errors.severity
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Category</label>
                <Select
                  size="small"
                  name="category"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                  sx={{ borderRadius: "8px" }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "200px",
                      },
                    },
                  }}
                >
                  {categoryList &&
                    categoryList?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.title}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>
                <small className="error">
                  {formik.errors.category && formik.touched.category
                    ? formik.errors.category
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Sub-Category</label>
                <Select
                  size="small"
                  name="subCategory"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{ borderRadius: "8px" }}
                  value={formik.values.subCategory}
                  MenuProps={MenuProps}
                >
                  {filteredList &&
                    filteredList[0]?.subCategories &&
                    filteredList[0]?.subCategories.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.title}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>
                <small className="error">
                  {formik.errors.subCategory && formik.touched.subCategory
                    ? formik.errors.subCategory
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Status</label>
                <Select
                  size="small"
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{ borderRadius: "8px" }}
                  value={formik.values.status}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="Closed" disabled>
                    Closed
                  </MenuItem>
                  <MenuItem value="In-progress" disabled>
                    In-progress
                  </MenuItem>
                  <MenuItem value="Resolved" disabled>
                    Resolved
                  </MenuItem>
                  <MenuItem value="Open">Open</MenuItem>
                </Select>
                <small className="error">
                  {formik.errors.status && formik.touched.status
                    ? formik.errors.status
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Department</label>
                <Select
                  size="small"
                  name="department"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{ borderRadius: "8px" }}
                  value={formik.values.department}
                  MenuProps={MenuProps}
                >
                  {departmentList &&
                    departmentList?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option}>
                          {option.deptName}
                        </MenuItem>
                      );
                    })}
                </Select>
                <small className="error">
                  {formik.errors.department && formik.touched.department
                    ? formik.errors.department
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Store</label>

                <Select
                  size="small"
                  name="store"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{ borderRadius: "8px" }}
                  value={formik.values.store}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "200px",
                      },
                    },
                  }}
                >
                  {mapSelectData &&
                    mapSelectData.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option}>
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>

                <small className="error">
                  {Boolean(formik.errors.store) && formik.touched.store
                    ? formik.errors.store
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Action</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="action"
                  multiline
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.action}
                  className="input-fields"
                />
                <small className="error">
                  {formik.errors.action && formik.touched.action
                    ? formik.errors.action
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Attatchment</label>
                <Box className="attachment-file">
                  <input
                    name="image"
                    accept="image/*"
                    id="contained-button-image"
                    type="file"
                    hidden
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="contained-button-image">
                    <Fab component="span" className="icon-upload">
                      <AddOutlinedIcon />
                    </Fab>
                  </label>
                  <Box>
                    {preview ? (
                      <img
                        className="image-multi"
                        src={preview}
                        alt="attachment"
                      />
                    ) : (
                      <img
                        className="image-multi"
                        src={attechment}
                        alt="attachment"
                      />
                    )}
                  </Box>
                </Box>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Media Files</label>
                <Box className="attachment-file">
                  <input
                    name="file"
                    accept="*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab component="span" className="icon-upload">
                      <AddOutlinedIcon />
                    </Fab>
                  </label>
                  {/* <Box>
                    {file && file.length ? (
                      <>
                        <p>Files ({file.length}): </p>
                        {file.map((fileData, key) => fileData.name + ", ")}
                      </>
                    ) : (
                      <p>No file selected</p>
                    )}
                  </Box> */}
                  {/* <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    {file && file.length ? (
                      file.map((fileData, index) => (
                        <div
                          key={index}
                          style={{ marginRight: "10px", marginBottom: "10px" }}
                        >
                          <div
                            className="file-box"
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: fileData.type.startsWith(
                                "image/"
                              )
                                ? "#cce5ff"
                                : "#f0f0f0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>{fileData.name.split(".").pop()}</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No file selected</p>
                    )}
                  </Box> */}
                  <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    {file && file.length ? (
                      file.map((fileData, index) => (
                        <div
                          key={index}
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            className="file-box"
                            style={{
                              width: "50px",
                              height: "55px",
                              backgroundColor: fileData.type.startsWith(
                                "image/"
                              )
                                ? "#cce5ff"
                                : "#f0f0f0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative", // Position for the remove button
                            }}
                          >
                            <span>{fileData.name.split(".").pop()}</span>
                            <button
                              onClick={() => handleRemoveFile(index)}
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                                padding: "5px",
                                marginLeft: "10px",
                              }}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p style={{ marginLeft: "10px" }}>No file selected</p>
                    )}
                  </Box>
                </Box>
                {error && (
                  <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
                )}
              </FormControl>
            </Container>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NewIssueCreate;
