import React from "react";
import { ViewHeader } from "./Header";
import { Container } from "@mui/system";
import {
  Typography,
  Grid,
  Divider,
  FormControl,
  TextField,
  Box,
  Card,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const ListDetails = () => {
  const location = useLocation();
  const listDetails = location?.state;

  return (
    <Box>
      {/* Header of the page */}
      <ViewHeader />

      {/* Store Training List Table */}
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <Typography
                variant="p"
                component="h3"
                className="top-heading"
                align="left"
              >
                Region List
              </Typography>
              <Divider />
              <FormControl fullWidth sx={{ my: 2 }}>
                <label>Description</label>
                <TextField
                  disabled
                  className="issueinput-fields"
                  id="outlined-basic"
                  value={
                    listDetails?.description ? listDetails?.description : "N/A"
                  }
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                />
              </FormControl>
              <FormControl fullWidth sx={{ my: 2 }}>
                <label>List Items</label>
                <Card sx={{ borderRadius: "8px" }}>
                  {listDetails &&
                    listDetails.items &&
                    listDetails.items.map((items, i) => {
                      return (
                        <Card key={i} sx={{ m: 2, borderRadius: "8px" }}>
                          <Box className="list-items">
                            <Typography>{items?.title}</Typography>
                            <Typography>
                              {items?.is_fixed === true ? (
                                <>
                                  <p style={{ display: "flex" }}>
                                    <CheckIcon color="success" /> Fixed
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p style={{ display: "flex" }}>
                                    <ClearIcon color="error" /> Fixed
                                  </p>
                                </>
                              )}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: "center", p: 2 }}>
                            <Typography>
                              {items?.customFields?.length > 0 ? (
                                <div>
                                  {items?.customFields?.length > 0 &&
                                    items?.customFields.map((list, i) => {
                                      return (
                                        <Typography
                                          key={i}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <p>{i + 1}</p>
                                          <p>Key: {list.key}</p>
                                          <p>Value: {list.value}</p>
                                        </Typography>
                                      );
                                    })}
                                </div>
                              ) : (
                                "No Custom Fields"
                              )}
                            </Typography>
                          </Box>
                          <Divider />
                        </Card>
                      );
                    })}
                </Card>

                {/* <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size='small'
                                    multiline
                                    rows={2} /> */}
              </FormControl>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListDetails;
