import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Country from "./Country";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ThirdForm = (props) => {
  const [showPasswordEye, setShowPasswordEye] = useState(false);
  const [showPasswordEyeConfirm, setShowPasswordEyeConfirm] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    first_name: Yup.string("Enter your First Name").required(
      "First Name is required"
    ),
    last_name: Yup.string("Enter your Last Name").required(
      "Last Name is required"
    ),
    country_code: Yup.string("Select Country code").required(
      "Select Country Code"
    ),
    mobile_number: Yup.string("Enter your Mobile Number")
      .required("Mobile Number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Number should be 10 digits")
      .min(10, "Number should be 10 digits"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$",
        "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase"
      ),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      country_code: "",
      mobile_number: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onFinalSubmit(values);
      // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });

  const handleClickShowPassword = () => {
    setShowPasswordEye(!showPasswordEye);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordEyeConfirm(!showPasswordEyeConfirm);
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ mb: 2 }} fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                id="first_name"
                name="first_name"
                placeholder="Fist Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                id="last_name"
                name="last_name"
                placeholder="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl sx={{ mb: 2 }} fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Select
                fullWidth
                labelId="countrySelectLabel"
                displayEmpty
                sx={{ width: "100%", textAlign: "left" }}
                id="country_code"
                name="country_code"
                placeholder="Country Code"
                value={formik.values.country_code}
                onChange={formik.handleChange}
                error={
                  formik.touched.country_code &&
                  Boolean(formik.errors.country_code)
                }
                helperText={
                  formik.touched.country_code && formik.errors.country_code
                }

                // value={selectedYear}
                // onChange={handleMuiSelectOnChange}
              >
                <MenuItem disabled value="">
                  <em style={{ fontStyle: "unset", color: "darkgrey" }}>
                    Select Country
                  </em>
                </MenuItem>
                {Country?.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option.mobileCode}>
                      {option.name ?? option.mobileCode}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                id="mobile_number"
                name="mobile_number"
                autoComplete="nope"
                placeholder="Enter contact number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                error={
                  formik.touched.mobile_number &&
                  Boolean(formik.errors.mobile_number)
                }
                helperText={
                  formik.touched.mobile_number && formik.errors.mobile_number
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl sx={{ mb: 2 }} fullWidth>
          <TextField
            id="password"
            name="password"
            // type="password"
            type={showPasswordEye ? "text" : "password"}
            autoComplete="off"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswordEye ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ mb: 2 }} fullWidth>
          <TextField
            id="retype_password"
            name="confirmPassword"
            type={showPasswordEyeConfirm ? "text" : "password"}
            autoComplete="off"
            placeholder="Retype password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPasswordConfirm}
                  >
                    {showPasswordEye ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={
              props?.activeStep === 0 ||
              props?.activeStep === 1 ||
              props?.activeStep === 2
            }
            onClick={props?.handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button type="submit">
            {props?.activeStep === props?.steps?.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ThirdForm;
