/** @format */

import { Card, Typography } from "@mui/material";
import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const AddMessageHeader = () => {
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <>
      <Card
        className="header-card"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{ fontSize: "20px", color: "#333" }}
        >
          <ArrowBackIcon sx={{ fontSize: "24px" }} />
          <Typography variant="button" sx={{ marginLeft: "8px" }}>
            Back
          </Typography>
        </IconButton>
        <Typography
          variant="h5"
          component="h1"
          align="left"
          sx={{
            marginLeft: "1%",
            fontWeight: "bold",
            letterSpacing: "0.03em",
          }}
        >
          Add Broadcast Messages
        </Typography>
        <div style={{marginRight:"10px",marginLeft:"auto"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                </div>
      </Card>
    </>
  );
};

export default AddMessageHeader;
