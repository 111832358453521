/** @format */

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TextField,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import POHeader from "./Pages/POHeader";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Progressbar from "../../GlobalProgress/Progressbar";
import { ActiveLMSReportGetApi } from "../../redux/actions/LmsAction";
import Tooltip from "@mui/material/Tooltip";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft as KeyboardArrowLeft,
  KeyboardArrowRight as KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@material-ui/icons";
import {
  CR_Graph_Api,
  CR_LeftGraph_Api,
  StorePerformanceCountAction,
  UserPerformanceCountAction,
} from "../../redux/actions/Checklist";
import { ListSubroleApi } from "../../redux/actions/Roles";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import {
  Avatar,
  IconButton,
  makeStyles,
  TableFooter,
  useTheme,
} from "material-ui-core";
import PropTypes from "prop-types";

const PerformanceOverview = () => {
  const dispatch = useDispatch();
  const [monthRecord, setmonthRecord] = React.useState("");
  const navigate = useNavigate();
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({ View: false });
  const [LeftGraphDynamicData, setLeftGraphDynamicData] = React.useState([]);
  const [GraphDynamicData, setGraphDynamicData] = React.useState([]);
  const [DateData, setDateData] = React.useState([]);
  const [SeriesData, setSeriesData] = React.useState([]);
  const [totalSubmissions, setTotalSubmissions] = React.useState(0);
  const [showTable, setShowTable] = useState(false);
  const [showRighGraph, setShowRighGraph] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [subRole, setSubRole] = useState([]);
  const [storeColumns, setStoreColumns] = useState([]);
  const [ActiveLMSReportData, setActiveLMSReportData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [csvLMSReportData, setCSVLMSReportData] = React.useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 20, 30];
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  console.log({ ActiveLMSReportData }, "COLUMSNSNS");
  // const defaultColumns = [
  //   {
  //     field: "S.NO",
  //     headerName: "S.NO",
  //     renderHeader: () => <strong>{"S.NO"}</strong>,
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     renderCell: (params) => {
  //       const index =
  //         ActiveLMSReportData.response.findIndex(
  //           (item) => item.TrainingId === params.row.TrainingId
  //         ) + 1;
  //       return (
  //         // <div
  //         //   style={{
  //         //     display: "flex",
  //         //     width: "133px",
  //         //   }}
  //         // >
  //         //   <p
  //         //     className="store-text"
  //         //     style={{ marginLeft: "20px", color: "black", fontSize: 15 }}
  //         //   >

  //         //     {index}
  //         //   </p>
  //         // </div>
  //         <Tooltip>
  //           <span>{index}</span>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let result = "";
  //       if (params?.row?.trainingTitle) {
  //         const trainingTitle = params?.row?.trainingTitle;
  //         let titleString = "";
  //         if (typeof trainingTitle === "object") {
  //           titleString = JSON.stringify(trainingTitle);
  //         } else {
  //           titleString = trainingTitle.toString();
  //         }
  //         if (titleString.length > 15) {
  //           result = titleString.substring(0, 15) + "...";
  //         } else {
  //           result = titleString;
  //         }
  //       } else {
  //         result = "N/A";
  //       }
  //       return result;
  //     },
  //     renderHeader: () => (
  //       <Tooltip title="NAME">
  //         <strong>{"NAME"}</strong>
  //       </Tooltip>
  //     ),
  //     renderCell: (params) => (
  //       <Tooltip title={params.row.trainingTitle}>
  //         <span>{params.value}</span>
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     field: "TOTAL CHAPTERS",
  //     headerName: "TOTAL CHAPTERS",
  //     renderHeader: () => (
  //       <Tooltip title="TOTAL CHAPTERS">
  //         <strong>{"TOTAL CHAPTERS"}</strong>
  //       </Tooltip>
  //     ),
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row?.totalChapters) {
  //         result.push(
  //           params?.row?.totalChapters ? params?.row?.totalChapters : 0
  //         );
  //       } else {
  //         result = ["0"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "TOTAL ASSESSMENTS",
  //     headerName: "TOTAL ASSESSMENTS",
  //     renderHeader: () => (
  //       <Tooltip title="TOTAL ASSESSMENTS">
  //         <strong>{"TOTAL ASSESSMENTS"}</strong>
  //       </Tooltip>
  //     ),
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row?.totalAssessents) {
  //         result.push(
  //           params?.row?.totalAssessents ? params?.row?.totalAssessents : 0
  //         );
  //       } else {
  //         result = ["0"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "ASSIGNED",
  //     headerName: "ASSIGNED",
  //     renderHeader: () => (
  //       <Tooltip title="ASSIGNED">
  //         <strong>{"ASSIGNED"}</strong>
  //       </Tooltip>
  //     ),
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row?.assigned) {
  //         result.push(params?.row?.assigned ? params?.row?.assigned : 0);
  //       } else {
  //         result = ["0"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "PENDING",
  //     headerName: "PENDING",
  //     renderHeader: () => (
  //       <Tooltip title="PENDING">
  //         <strong>{"PENDING"}</strong>
  //       </Tooltip>
  //     ),
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row?.pending) {
  //         result.push(params?.row?.pending ? params?.row?.pending : 0);
  //       } else {
  //         result = ["0"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  //   {
  //     field: "COMPLETED",
  //     headerName: "COMPLETED",
  //     renderHeader: () => (
  //       <Tooltip title="COMPLETED">
  //         <strong>{"COMPLETED"}</strong>
  //       </Tooltip>
  //     ),
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let totalCompletedCount = 0;
  //       if (params?.row?.completed) {
  //         params.row.completed.forEach((completedItem) => {
  //           totalCompletedCount += completedItem.completeCount || 0;
  //         });
  //       }
  //       return totalCompletedCount;
  //     },
  //   },
  //   {
  //     field: "TOTAL COMPLETED",
  //     headerName: "TOTAL COMPLETED",
  //     renderHeader: () => (
  //       <Tooltip title="TOTAL COMPLETED">
  //         <strong>{"TOTAL COMPLETED"}</strong>
  //       </Tooltip>
  //     ),
  //     flex: 1,
  //     align: "center",
  //     headerAlign: "center",
  //     valueGetter: (params) => {
  //       let result = [];
  //       if (params?.row?.totalCompleted) {
  //         result.push(
  //           params?.row?.totalCompleted ? params?.row?.totalCompleted : 0
  //         );
  //       } else {
  //         result = ["0"];
  //       }
  //       return result.join(", ");
  //     },
  //   },
  // ];
  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
      Role: [],
    },
  };
  const defaultColumns = [
    {
      field: "S.NO",
      headerName: "S.NO",
      renderHeader: () => <strong>{"S.NO"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const index =
          ActiveLMSReportData.response.findIndex(
            (item) => item.TrainingId === params.row.TrainingId
          ) + 1;
        return (
          <Tooltip title={index}>
            <span>{index}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "NAME",
      headerName: "NAME",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = params?.row?.trainingTitle || "N/A";
        if (result.length > 15) {
          result = result.substring(0, 15) + "...";
        }
        return result;
      },
      renderHeader: () => (
        <Tooltip title="NAME">
          <strong>{"NAME"}</strong>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Tooltip title={params.row.trainingTitle}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "TOTAL CHAPTERS",
      headerName: "TOTAL CHAPTERS",
      renderHeader: () => (
        <Tooltip title="TOTAL CHAPTERS">
          <strong>{"TOTAL CHAPTERS"}</strong>
        </Tooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params?.row?.totalChapters || "0",
    },
    {
      field: "TOTAL ASSESSMENTS",
      headerName: "TOTAL ASSESSMENTS",
      renderHeader: () => (
        <Tooltip title="TOTAL ASSESSMENTS">
          <strong>{"TOTAL ASSESSMENTS"}</strong>
        </Tooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params?.row?.totalAssessents || "0",
    },
    {
      field: "ASSIGNED",
      headerName: "ASSIGNED",
      renderHeader: () => (
        <Tooltip title="ASSIGNED">
          <strong>{"ASSIGNED"}</strong>
        </Tooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params?.row?.assigned || "0",
    },
    {
      field: "PENDING",
      headerName: "PENDING",
      renderHeader: () => (
        <Tooltip title="PENDING">
          <strong>{"PENDING"}</strong>
        </Tooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params?.row?.pending || "0",
    },
    {
      field: "COMPLETED",
      headerName: "COMPLETED",
      renderHeader: () => (
        <Tooltip title="COMPLETED">
          <strong>{"COMPLETED"}</strong>
        </Tooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let totalCompletedCount = 0;
        if (params?.row?.completed) {
          params.row.completed.forEach((completedItem) => {
            totalCompletedCount += completedItem.completeCount || 0;
          });
        }
        return totalCompletedCount;
      },
    },
    {
      field: "TOTAL COMPLETED",
      headerName: "TOTAL COMPLETED",
      renderHeader: () => (
        <Tooltip title="TOTAL COMPLETED">
          <strong>{"TOTAL COMPLETED"}</strong>
        </Tooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params?.row?.totalCompleted || "0",
    },
  ];

  useEffect(() => {
    if (
      ActiveLMSReportData &&
      ActiveLMSReportData.response &&
      ActiveLMSReportData.response.length > 0
    ) {
      let totalColumns = [...defaultColumns];
      const actionColumn = {
        field: "ACTION",
        headerName: "ACTION",
        renderHeader: () => (
          <Tooltip title="ACTION">
            <strong>{"ACTION"}</strong>
          </Tooltip>
        ),
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div style={{ display: "flex", gap: "5px" }}>
              <Tooltip title="View">
                <img
                  src={
                    hover.View && indexHover === params?.row?._id
                      ? ViewHover
                      : View
                  }
                  alt="VIEW"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onClick={() => handleClickStoreView(params?.row)}
                  onMouseOver={() => {
                    setHover({ ...hover, View: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ ...hover, View: false });
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      };
      totalColumns.push(actionColumn);
      setStoreColumns(totalColumns);
    }
  }, [ActiveLMSReportData]);

  const handleShowTableClick = () => {
    setShowTable(!showTable);
    setShowRighGraph(!showRighGraph);
  };

  const handleClickStoreView = (row) => {
    navigate("/lms/lms-report/lms-report-view", {
      state: row,
    });
  };

  var chart = {
    series: [
      {
        name: "Total submitted checklist",
        data: SeriesData,
      },
    ],

    options: {
      colors: ["#039855"],
      chart: {
        toolbar: {
          show: false,
        },
      },
      theme: { mode: "light" },

      xaxis: {
        // grid: true,
        tickPlacement: "on",
        categories: DateData,
        title: {
          style: { color: "#27364B", fontSize: 15 },
        },
      },

      yaxis: {
        labels: {
          formatter: (val) => {
            return `${val}`;
          },
          style: { fontSize: "15", colors: ["#5A5A5A"] },
        },
        title: {
          style: { color: "#5A5A5A", fontSize: 15 },
        },
      },

      legend: {
        show: true,
        position: "right",
      },

      dataLabels: {
        formatter: (val) => {
          return `${val}`;
        },
        style: {
          colors: ["#f4f4f4"],
          fontSize: 15,
        },
      },
    },
  };

  const GraphData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkRepGraph &&
      state?.checklist?.checkRepGraph?.data
  );
  const UrcLoading = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.urcLoading
  );

  const CscLoading = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.cscLoading
  );

  const GrapLoading = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.grapLoading
  );
  const activeLoading = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.loading
  );

  // urcLoading, cscLoading, grapLoading


  const ActiveLMSReport = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.activeLmsReport &&
      state?.lms?.activeLmsReport?.data &&
      state?.lms?.activeLmsReport?.data?.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );
  const totalitems = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.storeCheckList &&
      state.checklist.storeCheckList.data &&
      state.checklist.storeCheckList.data.data &&
      state.checklist.storeCheckList.data.data.paginate
  );
  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  useEffect(() => {
    const storedMonthRecord = localStorage.getItem("monthRecord");
    if (storedMonthRecord) {
      setmonthRecord(JSON.parse(storedMonthRecord));
    } else {
      setmonthRecord("OneDay");
    }
  }, []);

  // useEffect(() => {
  //   if (ActiveLMSReport) {
  //     setLoader(true);
  //     setActiveLMSReportData(ActiveLMSReport);
  //     setTimeout(() => {
  //       setLoader(false);
  //     }, 2000);
  //   }
  // }, [ActiveLMSReport]);
  console.log("ActiveLMSReport", ActiveLMSReport);

  useEffect(() => {
    if (ActiveLMSReport) {
      setLoader(true);
      const sortedResponse = ActiveLMSReport.response.sort((a, b) => {
        return a.fieldToSortBy - b.fieldToSortBy;
      });
      setActiveLMSReportData({ ...ActiveLMSReport, response: sortedResponse });
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [ActiveLMSReport]);

  // console.log("ActiveLMSReportData", ActiveLMSReportData);

  useEffect(() => {
    const storedSearchText = localStorage.getItem("searchText");
  }, []);

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    if (subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `CompanyCount?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(CR_Graph_Api(data));
    }
  }, [subRole]);

  useEffect(() => {
    if (monthRecord && subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `StoreChecklistReport?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(StorePerformanceCountAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  }, []);

  // User Assigned
  useEffect(() => {
    if (monthRecord && subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `UserChecklistCount?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(UserPerformanceCountAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    if (monthRecord) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `CompanyCount?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(CR_Graph_Api(data));
    }
  }, [monthRecord]);

  // Prepare chart data
  useEffect(() => {
    if (SeriesData && SeriesData.length) {
      var chart = {
        series: [
          {
            name: "Total submitted checklist",
            data: SeriesData,
            // data: monthWiseShowPercentage
          },
        ],
        options: {
          colors: [
            function ({ value, seriesIndex, w }) {
              if (value > parseInt(totalSubmissions)) {
                return "#039855";
              } else if (value == parseInt(totalSubmissions)) {
                return "#F39A4C";
              } else {
                return "#BB251A";
              }
            },
          ],
          theme: { mode: "light" },
          xaxis: {
            // grid: true,
            tickPlacement: "on",
            categories: DateData,
            title: {
              style: { color: "#27364B", fontSize: 15 },
            },
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: { fontSize: "15", colors: ["#5A5A5A"] },
            },
            title: {
              style: { color: "#5A5A5A", fontSize: 15 },
            },
          },
          legend: {
            show: true,
            position: "right",
          },
          dataLabels: {
            formatter: (val) => {
              return `${val}`;
            },
            style: {
              colors: ["#f4f4f4"],
              fontSize: 15,
            },
          },
        },
      };
      setChartData(chart);
    }
  }, [SeriesData]);

  useEffect(() => {
    if (GraphData) {
      setGraphDynamicData(GraphData);
    }
  }, [GraphData]);

  useEffect(() => {
    if (ActiveLMSReport) {
      setLoader(true);
      const sortedResponse = ActiveLMSReport.response.sort((a, b) => {
        return a.trainingTitle.localeCompare(b.trainingTitle);
      });
      setActiveLMSReportData({ ...ActiveLMSReport, response: sortedResponse });
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [ActiveLMSReport]);

  useEffect(() => {
    let graphArray = [];
    if (GraphDynamicData && GraphDynamicData?.data) {
      for (let item of GraphDynamicData?.data) {
        graphArray.push(moment(item?.Date).format("ll"));
      }
      setDateData(graphArray);
    }
  }, [GraphDynamicData?.data]);

  useEffect(() => {
    let graphSeriesArray = [];
    var sum1 = 0;
    if (GraphDynamicData && GraphDynamicData.data) {
      for (let item of GraphDynamicData?.data) {
        graphSeriesArray.push(item?.Submitted);
        sum1 += item?.Submitted;
      }
      setSeriesData(graphSeriesArray);
      const chartAvg = Math.round(
        sum1 ? sum1 / GraphDynamicData?.data?.length : 0
      );
      setTotalSubmissions(chartAvg);
    }
  }, [GraphDynamicData]);

  // LMS report api integration
  useEffect(() => {
    if (monthRecord && subRole) {
      activeTrainingReport();
    }
  }, [monthRecord, subRole]);

  const activeTrainingReport = (search = "") => {
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `trainingReport/active?company_id=${id}&subRole=[${subRole}]&filter=${monthRecord}&StoreTitle=${search}&trainingTitle=${search}`,
    };
    dispatch(ActiveLMSReportGetApi(data));
  };

  const handleChangeSelect = (event) => {
    const selectedValue = event.target.value;
    setmonthRecord(selectedValue);
    localStorage.setItem("filterSavedData", JSON.stringify(selectedValue));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchValue);
    }
  };

  useEffect(() => {
    activeTrainingReport(searchValue);
  }, [searchValue]);

  // const handleChangePage = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const handleChangeItemsPerPage = (event) => {
  //   setItemsPerPage(parseInt(event.target.value));
  //   setCurrentPage(1);
  // };

  const totalItems =
    (ActiveLMSReportData.response && ActiveLMSReportData.response.length) || 0;
  const startRow = (page - 1) * rowsPerPage;
  const endRow = Math.min(page * rowsPerPage, totalItems);
  const totalPages = Math.ceil(totalItems / rowsPerPage);
  const paginatedData =
    (ActiveLMSReportData.response &&
      ActiveLMSReportData.response.slice(startRow, endRow)) ||
    [];

  useEffect(() => {
    if (ActiveLMSReport) {
      setLoader(true);
      const sortedResponse = ActiveLMSReport.response.sort((a, b) => {
        return a.trainingTitle.localeCompare(b.trainingTitle);
      });
      setActiveLMSReportData({ ...ActiveLMSReport, response: sortedResponse });
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [ActiveLMSReport]);
  const listUserChecklist = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `Userlist?company_id=${id}`;
    // dispatch(ListUserChecklistApi(payloadRequest));
  };
  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    payloadRequest.body.pagination.page = newPage + 1;
    payloadRequest.body.Role = selectedSubRole;
    // listUserChecklist(payloadRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.Role = selectedSubRole;
    // listUserChecklist(payloadRequest);
  };
  console.log("totalPages",totalPages);
  console.log("currentPage",currentPage);
  console.log("rowsPerPage",rowsPerPage);
  console.log("totalItems",totalItems);
  console.log("page",page);
  return (
    <div>
      <POHeader
        handleChangeSelect={handleChangeSelect}
        monthRecord={monthRecord}
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        searchValue={searchValue}
        ActiveLMSReportData={ActiveLMSReportData}
        csvData={csvLMSReportData}
      />
      {/* <Card sx={{ height: "50%", marginTop: "1%" }} className="card">
        <CardContent sx={{ p: 4 }} className="check-graph">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sm={12}
              lg={4}
              style={{ marginTop: "-100px" }}
            >
              <Table aria-label="simple table" className="details-table-po">
                <TableBody style={{ overflowX: "auto" }}>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Client Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {ActiveLMSReportData?.companyDetails?.ClientName || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Total Stores:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {ActiveLMSReportData?.companyDetails?.StoreCount || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Total Users:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {ActiveLMSReportData?.companyDetails?.UserCount || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Total Trainings:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {ActiveLMSReportData?.companyDetails?.TrainingCount ||
                        "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Sub-Roles:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {ActiveLMSReportData?.companyDetails?.SubRole
                        ? ActiveLMSReportData.companyDetails.SubRole.join(", ")
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} md={8} sm={12} lg={8}>
              {showTable && (
                <TableContainer component={Paper} style={{ maxHeight: 100 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#D6F3E9" }}>
                        <TableCell
                          align="left"
                          className="tableHead"
                          sx={{ minWidth: 150 }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          align="center"
                          className="tableHead"
                          sx={{ minWidth: 150 }}
                        >
                          Submitted On
                        </TableCell>
                        <TableCell
                          align="center"
                          className="tableHead"
                          sx={{ minWidth: 200 }}
                        >
                          Total Submissions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {GraphDynamicData &&
                        GraphDynamicData?.data.length > 0 &&
                        GraphDynamicData?.data.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell align="center">
                              {moment(row?.Date).format("lll") || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {row?.Submitted || "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {showRighGraph && (
                <Card
                  sx={{ minWidth: 275, px: 1, overflowX: "auto" }}
                  className="card"
                >
                  <CardContent>
                    <Box>
                      <Stack direction="row" sx={{ display: "inline-flex" }}>
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ fontSize: "24px", px: 2 }}
                        >
                          Overall Performance:
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ color: "#F39A4C", fontWeight: 600 }}
                        >
                          {totalSubmissions}
                        </Typography>
                      </Stack>
                    </Box>
                    {chartData ? (
                      <Chart
                        type="bar"
                        height={250}
                        options={chartData && chartData.options}
                        series={chartData && chartData.series}
                      />
                    ) : (
                      "Loading Graph"
                    )}

                    <Box direction="row" className="line-chart-box">
                      <Box className="line-chart-btns1">
                        <span>0</span>
                        <span
                          style={{
                            borderRadius: "5px",
                            width: "150px",
                            height: "5px",
                            background: "#BB251A",
                          }}
                        ></span>
                        <span>
                          {Math.round(
                            totalSubmissions > 2 ? totalSubmissions - 2 : 1
                          )}
                        </span>
                        <span
                          style={{
                            borderRadius: "5px",
                            width: "150px",
                            height: "5px",
                            background: "#F39A4C",
                          }}
                        ></span>
                        <span>
                          {Math.round(
                            totalSubmissions > 4 ? totalSubmissions + 2 : 3
                          )}
                        </span>
                        <span
                          style={{
                            borderRadius: "5px",
                            width: "150px",
                            height: "5px",
                            background: "#039855",
                          }}
                        ></span>
                        <span>
                          {Math.round(
                            totalSubmissions
                              ? totalSubmissions *
                                  GraphDynamicData?.data?.length
                              : 4
                          )}
                        </span>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
             
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}

      <div>
        <Card className="card">
          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography
              align="left"
              variant="h6"
              component="h4"
              style={{
                marginTop: "0.5%",
                marginBottom: "-1%",
                marginRight: "1.5%",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                startIcon={<GetAppIcon />}
                onClick={exportToExcel}
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  width: "140px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                  "&:active": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
              >
                Export Excel
              </Button>
            </Typography>
          </div> */}

          <CardContent>
            <Box
              sx={{
                height: "65vh",
                mt: 1,
                p: 1,
                overflowX: "auto",
                overflowY: "auto",
                borderRadius: "8px",
                px: 1,
                "&::-webkit-scrollbar": {
                  width: 15,
                },
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              {/* {Loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-45px",
                  }}
                >
                  <Progressbar />
                </div> */}
              {UrcLoading || CscLoading || GrapLoading || activeLoading? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <ProgressbarTransparent play />
                </div>
              ) : ActiveLMSReportData ? (
                <>
                  <DataGrid
                    rows={paginatedData}
                    //rows={filteredData}
                    columns={storeColumns}
                    getRowId={(row) => row?.TrainingId}
                    // hideFooterPagination={true}
                    // className="check-report-grid-table"
                    disableColumnMenu
                    disableSelectionOnClick={true}
                    hideFooter={true}
                  />
                </>
              ) : (
                "No Data Found"
              )}
            </Box>
          </CardContent>
          <Grid container justifyContent="center">
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[
                5,
                10,
                25,
              ]}
              // colSpan={3}
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: { "aria-label": "Items per Page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
          {/* <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={2}
            marginBottom={1}
          >
            <Typography variant="subtitle2" style={{ marginRight: "10px" }}>
              Items per Page:
            </Typography>
            <TextField
              select
              variant="outlined"
              size="small"
              value={itemsPerPage}
              onChange={handleChangeItemsPerPage}
            >
              {itemsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Typography variant="subtitle2" style={{ marginLeft: "10px" }}>
              {startRow + 1}-{Math.min(endRow, totalItems)} of {totalItems}
            </Typography>
            <Box marginLeft={2}>
              <Button
                variant="text"
                size="small"
                style={{ marginRight: 5 }}
                disabled={currentPage === 1}
                onClick={() => handleChangePage(1)}
              >
                <FirstPageIcon />
              </Button>
              <Button
                variant="outlined"
                size="small"
                disabled={currentPage === 1}
                onClick={() => handleChangePage(currentPage - 1)}
              >
                <KeyboardArrowLeftIcon />
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ marginLeft: 2 }}
                disabled={currentPage === totalPages}
                onClick={() => handleChangePage(currentPage + 1)}
              >
                <KeyboardArrowRightIcon />
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ marginLeft: 5 }}
                disabled={currentPage === totalPages}
                onClick={() => handleChangePage(totalPages)}
              >
                <LastPageIcon />
              </Button>
            </Box>
          </Box> */}
        </Card>
      </div>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </div>
  );
};

export default PerformanceOverview;
