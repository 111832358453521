import JwtAuthService from "../apiConfig/ApiConfig";
// import LoginApiCAll from "../apiConfig/ApiConfig";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
} from "../types/Types";
import { toast } from "react-toastify";

export const NotificationRequest = (login) => ({
  type: NOTIFICATION_REQUEST,
  payload: login,
});
export const NotificationSuccess = (data) => ({
  type: NOTIFICATION_SUCCESS,
  payload: data,
});
export const NotificationFailed = (error) => ({
  type: NOTIFICATION_ERROR,
  payload: error,
});

export const NotificationApi = (data) => (dispatch) => {
  try {
    dispatch(NotificationRequest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        // toast.success(`${response?.data?.message}`);
        dispatch(NotificationSuccess(response?.data));
      } else {
        dispatch(NotificationFailed(response?.statusText));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(NotificationFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
