/** @format */

import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import userImage from "../../../images/User Image.png";
import React, { useEffect, useState } from "react";
import {
  ChangePreferedCompanyApiAction,
  ProfileMeGetApiAction,
  UpdateMyProfileApi,
  UpdateMyProfilePictureApiAction,
} from "../../../redux/actions/MyProfileDetailsAction";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { CompanyListApi } from "../../../redux/actions/CreateCompany";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { toast, ToastContainer } from "react-toastify";
import Progressbar from "../../../GlobalProgress/Progressbar";
import Blank from "../../../images/Blank.png";
import EditIcon from "@mui/icons-material/Edit";
import Country from "../../../Authentication/SighUp/StepsForm/Country";
import { useNavigate } from "react-router-dom";
import AccountSetting from "./AccountSetting";
import Preferences from "./Preferences";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

export const ProfileDetails = () => {
  const [newFieldValue, setNewFieldValue] = React.useState({});
  const [value, setValue] = React.useState("personalInformation");
  const [tabDisable, setTabDisable] = React.useState("");
  const dispatch = useDispatch();
  const [profileMeData, setProfileMeData] = React.useState([]);
  const [images, setImages] = useState({ preview: "", raw: "" });
  const [readerImage, setReaderImage] = React.useState("");
  const [disabled, setDisabled] = useState(true);
  const [imageId, setImageId] = React.useState(null);
  const navigate = useNavigate();

  const [preferedCompany, setPreferedCompany] = useState([]);
  const [updatedGetCompany, setUpdatedGetCompany] = useState("");
  const [updatedGetAvatar, setUpdatedGetAvatar] = useState("");
  const [loader, setLoader] = useState(true);

  const profileMeGetRecords = useSelector(
    (state) =>
      state?.MyProfileDetails &&
      state?.MyProfileDetails?.progileMeGetApiRes &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data &&
      state?.MyProfileDetails?.progileMeGetApiRes?.data?.data
  );

  const companyList = useSelector(
    (state) =>
      state.company &&
      state.company.companylist &&
      state.company.companylist.data &&
      state.company.companylist.data.data
  );

  const Loader = useSelector((state) => state.company && state.company.loading);

  const AvtarLoader = useSelector(
    (state) => state.MyProfileDetails && state.MyProfileDetails.loading
  );

  const EditMyProfile = useSelector(
    (state) =>
      state.MyProfileDetails &&
      state.MyProfileDetails.myProfileUpdate &&
      state.MyProfileDetails.myProfileUpdate.data
  );

  const UpdateMyProfilePictureSelector = useSelector(
    (state) =>
      state.MyProfileDetails &&
      state.MyProfileDetails.myProfilePictureUpdate &&
      state.MyProfileDetails.myProfilePictureUpdate?.data
  );

  const uploadProfileSelector = useSelector(
    (state) =>
      state &&
      state?.MyProfileDetails &&
      state?.MyProfileDetails?.myProfileUpdate &&
      state?.MyProfileDetails?.myProfileUpdate?.data
  );

  const ChangePreferedCompanySelector = useSelector(
    (state) =>
      state?.MyProfileDetails &&
      state?.MyProfileDetails?.updatePreferedCompany &&
      state?.MyProfileDetails?.updatePreferedCompany?.data
  );

  // console.log('profileMeData?.country_code... ', profileMeData?.country_code);
  useEffect(() => {
    ProfileMeGetAPI();
    ListCompany();
  }, []);

  useEffect(() => {
    if (ChangePreferedCompanySelector) {
      setTimeout(() => {
        navigate("/company-list");
      }, 1000);
    }
  }, [ChangePreferedCompanySelector]);

  useEffect(() => {
    if (
      UpdateMyProfilePictureSelector &&
      UpdateMyProfilePictureSelector?.data
    ) {
      setImageId(UpdateMyProfilePictureSelector);
      toast.success(
        "Profile image uploaded successfully, now click on save to submit!!",
        { autoClose: 5000 }
      );
      setDisabled(false);
    }
  }, [
    UpdateMyProfilePictureSelector &&
      UpdateMyProfilePictureSelector !== "undefined",
  ]);

  useEffect(() => {
    if (images && images.preview !== "") {
      const data = {};
      const uploadProfPayload = {
        file: images?.preview,
        name: images?.raw?.name,
      };
      data.body = uploadProfPayload;
      data.url = BASE_URL + `uploads/base64`;
      dispatch(UpdateMyProfilePictureApiAction(data));
    }
  }, [images]);

  useEffect(() => {
    if (uploadProfileSelector && uploadProfileSelector.status == 200) {
      ProfileMeGetAPI();
      setDisabled(true);
    }
  }, [uploadProfileSelector]);

  useEffect(() => {
    if (EditMyProfile && EditMyProfile.status === 200) {
      toast.success(EditMyProfile.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/my-profile-details");
      }, 2000);
    }
  }, [EditMyProfile]);

  useEffect(() => {
    setPreferedCompany(companyList);
  }, [companyList]);

  useEffect(() => {
    if (profileMeGetRecords) {
      setUpdatedGetCompany(profileMeGetRecords?.preferredCompany?._id);
      setUpdatedGetAvatar(profileMeGetRecords?.avatar);
      setProfileMeData(profileMeGetRecords);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [profileMeGetRecords]);

  useEffect(() => {
    setProfileMeData(profileMeData);
    if (profileMeGetRecords) {
      setNewFieldValue({
        first_name: profileMeGetRecords?.first_name,
        last_name: profileMeGetRecords?.last_name,
        email: profileMeGetRecords?.email,
        country_code: profileMeGetRecords?.country_code,
        country: profileMeGetRecords?.country,
        city: profileMeGetRecords?.city,
        street: profileMeGetRecords?.street,
        zip_code: profileMeGetRecords?.zip_code,
        contact: profileMeGetRecords?.contact,
      });
    }
  }, [profileMeGetRecords]);

  useEffect(() => {
    formik.setFieldValue("country_code", Country[0].mobileCode);
  }, []);

  // select image code
  const handleImageUpload = (e) => {
    if (e.target.files.length) {
      var reader = new FileReader();
      var file = e.target.files[0];

      reader.onload = function (upload) {
        setImages({
          preview: upload.target.result,
          raw: e.target.files[0],
        });
        setReaderImage(({ images }) => reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
    setTabDisable(newValue);
  };

  const EditInfoHandler = (e) => {
    setDisabled(false);
    // setDisabled(!disabled);
  };
  const cancelClickHandler = (e) => {
    if(disabled) {
      navigate("/dashboard");
    } else {
      setDisabled(true);
    }

  };

  const HandledPreferredCompany = (values) => {
    const data = {};
    const uploadProfPayload = {
      company_id: values?.target?.value,
    };
    data.body = uploadProfPayload;
    data.url = BASE_URL + `profile/me/preferred-company`;
    dispatch(ChangePreferedCompanyApiAction(data));
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().max(25, "Enter maximum length of 25 characters"),
    last_name: Yup.string().max(25, "Enter maximum length of 25 characters"),
    email: Yup.string().max(45, "Enter maximum length of 25 characters"),
    country_code: Yup.string(),
    country: Yup.string().max(25, "Enter maximum length of 25 characters"),
  });

  const formik = useFormik({
    initialValues: newFieldValue || {
      first_name: "",
      last_name: "",
      country: "",
      city: "",
      street: "",
      zip_code: "",
      country_code: "",
      contact: "",
      avatar: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      let formData = {
        first_name: values.first_name,
        last_name: values.last_name,
        country: values.country,
        city: values?.city,
        street: values?.street,
        zip_code: values?.zip_code,
        country_code: values.country_code,
        contact: values?.contact,
      };
      if (imageId && imageId.data) {
        formData.avatar = imageId?.data?.id;
      }

      const allData = {
        body: formData,
      };

      if (allData) {
        allData.url = BASE_URL + `profile/me`;
        dispatch(UpdateMyProfileApi(allData));
      }
    },
  });

  const ProfileMeGetAPI = () => {
    const data = { url: BASE_URL + `profile/me` };
    dispatch(ProfileMeGetApiAction(data));
  };

  const ListCompany = () => {
    const data = { url: BASE_URL + `profile/me/companies` };
    dispatch(CompanyListApi(data));
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        {/* {Loader ? (
          <Progressbar /> */}
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              overflowX: "hidden",
              overflowY: "scroll",
              borderRadius: "5px",
              px: 1,
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                typography: "body1",
                bgcolor: "background.paper",
              }}
            >
              <Avatar
                sx={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#F6F8FC",
                  border: "2px solid #CBD4E1",
                  margin: "auto",
                }}
              >
                {images.preview && images.preview !== "" ? (
                  <img
                    src={images.preview}
                    alt="attachment"
                    width="150px"
                    align="center"
                  />
                ) : (
                  <img
                    src={
                      updatedGetAvatar ? updatedGetAvatar : Blank
                      // profileMeData?.avatar
                      //     ? profileMeData?.avatar
                      //     : Blank
                    }
                    align="center"
                    alt="attachment"
                    width="150px"
                  />
                )}
              </Avatar>
              <Button variant="contained" component="label">
                Change Image
                <input
                  name="image"
                  accept="image/*"
                  className="file-upload"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
              </Button>

              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    tabDisable={value}
                    centered
                    onChange={tabHandleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Personal Information"
                      value="personalInformation"
                    />
                    <Tab label="Account Settings" value="accountSettings" />
                    <Tab label="Preferences" value="preferences" disabled />
                  </TabList>
                </Box>
                {/* Personal Information */}
                <TabPanel value="personalInformation">
                  <Button
                    variant="contained"
                    style={{ marginLeft: "36%", marginBottom: "2%" }}
                    onClick={EditInfoHandler}
                  >
                    Edit Information <EditIcon />
                  </Button>
                  <Grid disabled={disabled}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            First Name
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter First Name"
                            size="small"
                            name="first_name"
                            onChange={formik.handleChange}
                            value={formik?.values?.first_name}
                            error={
                              formik.touched.first_name &&
                              Boolean(formik.errors.first_name)
                            }
                          />
                          <small className="error">
                            {formik.errors.first_name &&
                            formik.touched.first_name
                              ? formik.errors.first_name
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Last Name
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Last Name"
                            size="small"
                            name="Cannot read properties of undefined (reading 'type')"
                            onChange={formik.handleChange}
                            value={formik?.values?.last_name}
                            error={
                              formik.touched.last_name &&
                              Boolean(formik.errors.last_name)
                            }
                          />
                          <small className="error">
                            {formik.errors.last_name && formik.touched.last_name
                              ? formik.errors.last_name
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Email
                          </label>
                          <TextField
                            disabled
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Email"
                            size="small"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik?.values?.email}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                          />
                          <small className="error">
                            {formik.errors.email && formik.touched.email
                              ? formik.errors.email
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Country
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Country"
                            size="small"
                            name="country"
                            onChange={formik.handleChange}
                            value={formik?.values?.country}
                            error={
                              formik.touched.country &&
                              Boolean(formik.errors.country)
                            }
                          />
                          <small className="error">
                            {formik.errors.country && formik.touched.country
                              ? formik.errors.country
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            City
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter City"
                            size="small"
                            name="city"
                            onChange={formik.handleChange}
                            value={formik?.values?.city}
                            error={
                              formik.touched.city && Boolean(formik.errors.city)
                            }
                          />
                          <small className="error">
                            {formik.errors.city && formik.touched.city
                              ? formik.errors.city
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Street Name
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Street"
                            size="small"
                            name="street"
                            onChange={formik.handleChange}
                            value={formik?.values?.street}
                            error={
                              formik.touched.street &&
                              Boolean(formik.errors.street)
                            }
                          />
                          <small className="error">
                            {formik.errors.street && formik.touched.street
                              ? formik.errors.street
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Zipcode
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Zipcode"
                            size="small"
                            name="zip_code"
                            onChange={formik.handleChange}
                            value={formik?.values?.zip_code}
                            error={
                              formik.touched.zip_code &&
                              Boolean(formik.errors.zip_code)
                            }
                          />
                          <small className="error">
                            {formik.errors.zip_code && formik.touched.zip_code
                              ? formik.errors.zip_code
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth sx={{ mb: 2 }}>
                                                <label className="label-name" style={{ textAlign: "left" }}>Country Code</label>
                                                <PhoneInput
                                                    name="country_code"
                                                    onChange={formik.handleChange}
                                                    value={formik?.values?.country_code}
                                                    error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                                                />
                                                <small className='error'>{formik.errors.country_code && formik.touched.country_code ? formik.errors.country_code : null}</small>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth sx={{ mb: 1 }}>
                                                <label className="label-name" style={{ textAlign: "left" }}>Contact</label>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    placeholder='Enter Contact'
                                                    size="small"
                                                    name="contact"
                                                    onChange={formik.handleChange}
                                                    value={formik?.values?.contact}
                                                    error={formik.touched.contact && Boolean(formik.errors.contact)}
                                                />
                                                <small className='error'>{formik.errors.contact && formik.touched.contact ? formik.errors.contact : null}</small>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth sx={{ mb: 2 }}>
                                                    <label className="label-name" style={{ textAlign: "left" }}>Country Code</label>
                                                    <Select disabled={disabled}
                                                        size="small"
                                                        fullWidth
                                                        name="country_code"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.country_code}
                                                        error={
                                                            formik.touched.country_code &&
                                                            Boolean(formik.errors.country_code)
                                                        }
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "right",
                                                            },
                                                            PaperProps: {
                                                                sx: {
                                                                    bgcolor: "#FFFFFF",
                                                                    "& .MuiMenuItem-root": {
                                                                        padding: 1,
                                                                    },
                                                                    height: "300px",
                                                                    width: "300px",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {Country?.map((option, i) => {
                                                            return (
                                                                <MenuItem key={i} value={option.mobileCode} className="select-item">
                                                                    {option.name ?? option.mobileCode}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth sx={{ mb: 1 }}>
                                                    <label className="label-name" style={{ textAlign: "left" }}>Contact</label>
                                                    <TextField disabled={disabled}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        placeholder='Enter Contact'
                                                        size="small"
                                                        name="contact"
                                                        onChange={formik.handleChange}
                                                        value={formik?.values?.contact}
                                                        error={formik.touched.contact && Boolean(formik.errors.contact)}
                                                    />
                                                    <small className='error'>{formik.errors.contact && formik.touched.contact ? formik.errors.contact : null}</small>
                                                </FormControl>
                                            </Grid>
                                        </Grid> */}

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Country Code
                          </label>
                          <Select
                            disabled={disabled}
                            size="small"
                            fullWidth
                            name="country_code"
                            onChange={formik.handleChange}
                            value={formik.values.country_code}
                            error={
                              formik.touched.country_code &&
                              Boolean(formik.errors.country_code)
                            }
                            MenuProps={{
                              disableScrollLock: true,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                              },
                              PaperProps: {
                                sx: {
                                  bgcolor: "#FFFFFF",
                                  "& .MuiMenuItem-root": {
                                    padding: 1,
                                  },
                                  height: "300px",
                                  width: "300px",
                                },
                              },
                            }}
                          >
                            {Country?.map((option, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={option.mobileCode}
                                  className="select-item"
                                >
                                  {option.name ?? option.mobileCode}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <label
                            className="label-name"
                            style={{ textAlign: "left" }}
                          >
                            Contact
                          </label>
                          <TextField
                            disabled={disabled}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Contact"
                            size="small"
                            name="contact"
                            onChange={formik.handleChange}
                            value={formik?.values?.contact}
                            error={
                              formik.touched.contact &&
                              Boolean(formik.errors.contact)
                            }
                          />
                          <small className="error">
                            {formik.errors.contact && formik.touched.contact
                              ? formik.errors.contact
                              : null}
                          </small>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={1}>
                                                <Button className="header-add cancle-hover" type="submit" disabled={disabled}>
                                                    <TaskAltIcon sx={{ mr: 1 }} />
                                                    Save
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Link to="/my-profile-details" className="submenu-item" disabled={disabled}>
                                                    <Button className="cancle-button cancle-hover" onClick={cancelClickHandler}>
                                                        <CloseOutlinedIcon sx={{ mr: 1 }} />
                                                        Cancel
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid> */}

                    <Grid
                      style={{ marginTop: "1%" }}
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={6}></Grid>
                      <Button
                        className="header-add cancle-hover"
                        type="submit"
                        disabled={disabled}
                      >
                        <TaskAltIcon sx={{ mr: 1 }} />
                        Save
                      </Button>
                      <Button
                        style={{ marginLeft: "2%" }}
                        className="cancle-button cancle-hover"
                        onClick={cancelClickHandler}
                      >
                        <CloseOutlinedIcon sx={{ mr: 1 }} />
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>

                {/* Account Settings */}
                <TabPanel value="accountSettings">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label
                          className="label-name"
                          style={{ textAlign: "left" }}
                        >
                          Preferred Company Settings
                        </label>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <Select
                          size="small"
                          name="select_company"
                          labelId="select_company_id"
                          MenuProps={{
                            disableScrollLock: true,
                            PaperProps: {
                              sx: {
                                bgcolor: "#FFFFFF",
                                "& .MuiMenuItem-root": {
                                  padding: 1,
                                },
                                height: "200px",
                              },
                            },
                          }}
                          onChange={HandledPreferredCompany}
                          defaultValue={updatedGetCompany}
                        >
                          {preferedCompany &&
                            preferedCompany?.map((option, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={option._id}
                                  className="select-item"
                                >
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <small className="error">
                          {formik.errors.select_company &&
                          formik.touched.select_company
                            ? formik.errors.select_company
                            : null}
                        </small>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* model change password  */}
                  <AccountSetting />
                </TabPanel>

                {/* Preferences started */}
                <TabPanel value="preferences" disabled>
                  <Preferences />
                </TabPanel>
                {/* Preferences started */}
              </TabContext>
            </Box>
          </Box>
        )}
      </form>
    </>
  );
};
export default ProfileDetails;
