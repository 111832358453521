import {
  CSV_NAM_SUBMISSION_ERROR,
  CSV_NAM_SUBMISSION_REQUEST,
  CSV_NAM_SUBMISSION_SUCCESS,
  CSV_USER_SUBMISSION_ERROR,
  CSV_USER_SUBMISSION_REQUEST,
  CSV_USER_SUBMISSION_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  csvNamSubmission: "",
  csvUserWiseSubmission: "",
};

const CsvNamSubmissionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CSV_NAM_SUBMISSION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CSV_NAM_SUBMISSION_SUCCESS: {
      return {
        ...state,
        csvNamSubmission: action?.payload,
        loading: false,
      };
    }
    case CSV_NAM_SUBMISSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CSV_USER_SUBMISSION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CSV_USER_SUBMISSION_SUCCESS: {
      return {
        ...state,
        csvUserWiseSubmission: action?.payload,
        loading: false,
      };
    }
    case CSV_USER_SUBMISSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default CsvNamSubmissionDataReducer;
