import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

const Notification = ({ openNotification, setOpenNotification }) => {
  const handleClose = () => setOpenNotification(false);

  const notificationListData = useSelector(
    (state) =>
      state &&
      state?.NotiFicationReducer &&
      state?.NotiFicationReducer?.NotificationData &&
      state?.NotiFicationReducer?.NotificationData?.data &&
      state?.NotiFicationReducer?.NotificationData?.data
  );
  return (
    <>
      <Modal
        open={openNotification}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="notificationmodal">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="notification-head">Notifications</div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Notification;
