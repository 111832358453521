import React from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const AddAssignment = () => {
  return (
    <>
      <Box sx={{ mb: 1 }} className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/all-assignment" className="submenu-item">
                <Button className="cancle-button cancle-hover">
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
              </Link>
              <Link to="/all-assignment" className="submenu-item">
                <Button className="header-add cancle-hover">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Assign
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", height: "74vh" }}
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            p: 1,
            textAlign: "left",
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Select User</label>
            <Select size="small" sx={{ borderRadius: "8px" }}>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Survey period</label>
            <Grid container spacing={1}>
              <Grid item md={6} sx={{ width: "50%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  select
                  fullWidth
                  className="issueinput-fields"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </TextField>
              </Grid>
              <Grid item md={6} sx={{ width: "50%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  select
                  fullWidth
                  className="issueinput-fields"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Select Store</label>
            <Select size="small" sx={{ borderRadius: "8px" }}>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Select Question</label>
            <Select size="small" sx={{ borderRadius: "8px" }}>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Container>
      </Box>
    </>
  );
};

export default AddAssignment;
