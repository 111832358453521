/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import {
  SCHEDULAR_ADD_ERROR,
  SCHEDULAR_ADD_REQUEST,
  SCHEDULAR_ADD_SUCCESS,
  SCHEDULAR_DELETE_ERROR,
  SCHEDULAR_DELETE_REQUEST,
  SCHEDULAR_DELETE_SUCCESS,
  SCHEDULAR_EDIT_ERROR,
  SCHEDULAR_EDIT_REQUEST,
  SCHEDULAR_EDIT_SUCCESS,
  SCHEDULAR_LIST_ERROR,
  SCHEDULAR_LIST_REQUEST,
  SCHEDULAR_LIST_SUCCESS,
} from "../types/Types";
import { toast } from "react-toastify";

export const ListSchedular = () => ({
  type: SCHEDULAR_LIST_REQUEST,
});
export const ListSchedularSuccess = (companyid) => ({
  type: SCHEDULAR_LIST_SUCCESS,
  payload: companyid,
});
export const ListSchedularFailed = (error) => ({
  type: SCHEDULAR_LIST_ERROR,
  payload: error,
});

export const ListSchedularApi = (data) => (dispatch) => {
  try {
    dispatch(ListSchedular(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListSchedularSuccess(data));
      } else {
        dispatch(ListSchedularFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListSchedularFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const AddSchedular = () => ({
  type: SCHEDULAR_ADD_REQUEST,
});
export const AddSchedularSuccess = (companyid) => ({
  type: SCHEDULAR_ADD_SUCCESS,
  payload: companyid,
});
export const AddSchedularFailed = (error) => ({
  type: SCHEDULAR_ADD_ERROR,
  payload: error,
});

export const AddSchedularApi = (data) => (dispatch) => {
  try {
    dispatch(AddSchedular());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AddSchedularSuccess(response));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(AddSchedularFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddSchedularFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const EditSchedular = (data) => ({
  type: SCHEDULAR_EDIT_REQUEST,
  payload: data,
});
export const EditSchedularSuccess = (data) => ({
  type: SCHEDULAR_EDIT_SUCCESS,
  payload: data,
});
export const EditSchedularFailed = (error) => ({
  type: SCHEDULAR_EDIT_ERROR,
  payload: error,
});

export const EditSchedularApi = (data) => (dispatch) => {
  try {
    dispatch(EditSchedular());
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(EditSchedularSuccess(response));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(EditSchedularFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditSchedularFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const DeleteSchedular = () => ({
  type: SCHEDULAR_DELETE_REQUEST,
});
export const DeleteSchedularSuccess = () => ({
  type: SCHEDULAR_DELETE_SUCCESS,
});
export const DeleteSchedularFailed = (error) => ({
  type: SCHEDULAR_DELETE_ERROR,
  payload: error,
});

export const DeleteSchedularApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteSchedular(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteSchedularSuccess(response));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(DeleteSchedularFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteSchedularFailed(error));
    console.error("Something went Wrong", error);
  }
};
