import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AssessmentListApi } from "../../../redux/actions/LmsAction";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AssesmentModal = (props) => {
  const assesmentList = useSelector(
    (state) =>
      state.lms &&
      state.lms.assessmentlist &&
      state.lms.assessmentlist.data &&
      state.lms.assessmentlist.data.data &&
      state.lms.assessmentlist.data.data.assessments
  );

  const list = props?.deletedAssesment;
  const dispatch = useDispatch();
  const editAssessment = localStorage.getItem("editAssessment");

  const [assesmentData, setAssesmentData] = useState([]);
  const [assesmentIdListData, setAssesmentIdListData] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState([]);
  let count = 0;

  useEffect(() => {
    count = 0;
    setAssesmentIdListData(props.assesmentIdList);
  }, [props.assesmentIdList]);
  useEffect(() => {
    if(editAssessment && JSON.parse(editAssessment).length){
      setSelectedAssessment(JSON.parse(editAssessment));
    }
  }, [editAssessment]);
  useEffect(() => {
    setAssesmentData((assesmentList) => [...assesmentList, list]);
  }, [list]);

  useEffect(() => {
    if (assesmentList) {
      setAssesmentData(assesmentList);
    }
  }, [assesmentList]);

  // useEffect(()=>{
  //     let localData = localStorage.getItem("assesmentData");
  // let localParse = JSON.parse(localData);
  // let add = localParse;
  // if (list) {
  //   add.push(list);
  //   localStorage.setItem("assesmentData", JSON.stringify(add));
  // }
  // }, [list])

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `assessment/index?company_id=${id}` };
    dispatch(AssessmentListApi(data));
  }, []);

  const handleAssesment = (item) => {
    if (selectedAssessment.includes(item._id)) {
      setSelectedAssessment(selectedAssessment.filter(cert => cert !== item._id));
      setAssesmentIdListData(assesmentIdListData.filter(cert => cert._id !== item._id));

    } else {
      setSelectedAssessment([...selectedAssessment, item._id])
      setAssesmentIdListData((assesmentIdListData) => [
        ...assesmentIdListData,
        item,
      ]);
    }
  };

  const handleSaveClose = () => {
    if (assesmentIdListData) {
      localStorage.setItem(
        "assesmentData",
        JSON.stringify(assesmentIdListData)
      );
      localStorage.setItem("editAssessment", JSON.stringify(selectedAssessment));
    }
    props.setOpen(false);
  };
  const handleCancle = () => {
    // let newAssesment = assesmentData;
    // newAssesment.push(assesmentIdListData)
    // let newArray = [...assesmentData, ...assesmentIdListData];
    // setAssesmentData(newArray);
    // setAssesmentIdListData([]);
    setSelectedAssessment(props.assesmentIdList.map(item => item._id));
    setAssesmentIdListData(props.assesmentIdList);
    props.setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        // onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size-subcategory"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
        //   onClose={props.handleClose}
        >
          <Typography variant="p" component="div" className="heading">
            Assesment List
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box className="">
                <Link to="/assessment" className="submenu-item">
                  <Button
                    variant="outlined"
                    className="add-button cancle-hover"
                    sx={{ mb: 1 }}
                  >
                    <AddIcon sx={{ mx: 1 }} />
                    Add New Assesment
                  </Button>
                </Link>
                {assesmentData &&
                  assesmentData.length > 0 &&
                  assesmentData?.map((items, i) => {
                    return (
                      <Box className={selectedAssessment.includes(items._id) ? "assesment-list-hover-selected" : "assesment-list-hover"} key={items._id}>
                        <ul>
                          <li onClick={() => handleAssesment(items)}>
                            {i + 1}&nbsp;&nbsp;{items?.title}
                          </li>
                          {/* <Divider /> */}
                        </ul>
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            type="submit"
            onClick={handleSaveClose}
            color="success"
          >
            Save
          </Button>
          <Button variant="contained" onClick={handleCancle}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AssesmentModal;
