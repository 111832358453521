/** @format */

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import TableHeader from "./pages/TableHeader";
import { Typography, Grid, TablePagination, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import User from "../../../assets/icons/svg-icons/User.svg";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import { useDispatch, useSelector } from "react-redux";
import { StoreTariningListApi } from "../../redux/actions/LmsAction";
import { useEffect } from "react";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { DataGrid } from "@mui/x-data-grid";

export const StoreTraining = () => {
  const storeList = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.storeTraining &&
      state?.lms?.storeTraining?.data &&
      state?.lms?.storeTraining?.data?.data &&
      state?.lms?.storeTraining?.data?.data?.Responses
  );
  const totalpage = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.storeTraining &&
      state?.lms?.storeTraining?.data &&
      state?.lms?.storeTraining?.data?.data &&
      state?.lms?.storeTraining?.data?.data?.paginate
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [indexHover, setIndexHover] = React.useState(0);
  const [hover, setHover] = React.useState({
    View: false,
  });
  const [totalCount, setTotalCount] = useState();
  const [storeListing, setStoreListing] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  React.useEffect(() => {
    setStoreListing(storeList);
  }, [storeList]);

  useEffect(() => {
    setTotalCount(totalpage?.total_item);
  }, [totalpage]);

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  useEffect(() => {
    if (searchValue === "") {
      paginationRequest.body.search = searchValue;
      AllTrainingList(paginationRequest);
    }
  }, [searchValue]);
  const handleChangePage = (event, newPage) => {
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    paginationRequest.body.pagination.page = newPage + 1;
    paginationRequest.body.search = "";
    AllTrainingList(paginationRequest);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    paginationRequest.body.search = "";
    AllTrainingList(paginationRequest);
  };

  const AllTrainingList = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `StoreTraining?company_id=${id}`;
    dispatch(StoreTariningListApi(paginationRequest));
  };

  const handleClick = () => {
    navigate("/store-training/view-details");
  };

  const handleChange = (event) => {
    paginationRequest.body.search = searchValue;
    if (searchValue != "") {
      paginationRequest.body.search = searchValue;
    }
    AllTrainingList(paginationRequest);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchValue !== "") {
        paginationRequest.body.pagination.page = 1;
        localStorage.removeItem("pageno");
        localStorage.setItem("pageno", 1);
        paginationRequest.body.search = searchValue;
        AllTrainingList(paginationRequest);
        setPage(1);
      }
    }
  };

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          storeListing.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Store",
      headerName: "Store",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.Store);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Region",
      headerName: "Region",
      renderHeader: () => <strong>{"Region"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.Region);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Sub-Region",
      headerName: "Sub-Region",
      renderHeader: () => <strong>{"Sub-Region"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.SubRegion);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Training Completed",
      headerName: "Training Completed",
      renderHeader: () => <strong>{"Training Completed"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.Submitted);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Training In-Progress",
      headerName: "Training In-Progress",
      renderHeader: () => <strong>{"Training In-Progress"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.TrainingInProgress);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Training Created",
      headerName: "Training Created",
      renderHeader: () => <strong>{"Training Created"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.TrainingCreated);
        } else {
          result = ["N/A"];
        }
        let formData = result.join(", ");
        return <p className="text-short">{formData}</p>;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <img
            onClick={() => {
              handleClick(params?.row);
              localStorage.setItem("StoreId", params?.row?._id);
            }}
            src={
              hover.View && indexHover === params?.row?._id ? ViewHover : View
            }
            alt="view"
            style={{
              height: "35px",
              cursor: "pointer",
              margin: "0px 3px 0px 3px",
            }}
            onMouseOver={() => {
              setHover({ View: true });
              setIndexHover(params?.row?._id);
            }}
            onMouseLeave={() => {
              setHover({ View: false });
            }}
          />
        );
      },
    },
  ];

  return (
    <Box>
      {/* Header of the page */}
      <TableHeader
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        // ArchivedApi={ArchivedApi}
        rowsPerPage={rowsPerPage}
        page={page}
        totalpage={totalpage}
        setPage={setPage}
        training={"storetraining"}
      />

      {/* Store Training List Table */}
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        {/* <TableContainer component={Paper}>
          <Table size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Store
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Region
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Sub-Region
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Training
                  <br /> Completed
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Training
                  <br /> In-Progress
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Training Created
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ justifyContent: "center" }}
                    >
                      <Avatar className="message-avatar">
                        <img src={User} alt="avatar" />
                      </Avatar>
                      <Stack direction="column">
                        <Typography variant="p" component="b">
                          {row.Store}
                        </Typography>
                        <Typography variant="p" component="p">
                          {row.Number}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">{row.Region}</TableCell>
                  <TableCell align="center">{row.SubRegion}</TableCell>
                  <TableCell align="center">{row.TrainingCompleted}</TableCell>
                  <TableCell align="center">{row.TrainingInProgress}</TableCell>
                  <TableCell align="center">{row.TrainingCreated}</TableCell>
                  <TableCell align="center">
                    <img
                      src={hover.View && indexHover === i ? ViewHover : View}
                      alt="view"
                      onClick={handleClick}
                      onMouseOver={() => {
                        setHover({ View: true });
                        setIndexHover(i);
                      }}
                      onMouseLeave={() => {
                        setHover({ View: false });
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        {storeListing ? (
          <>
            <DataGrid
              disableColumnMenu
              rows={storeListing}
              columns={columns}
              getRowId={(storeListing) => storeListing._id}
              getRowHeight={() => "auto"}
              hideFooter={true}
              hideFooterPagination={true}
              className="grid-table"
            />
          </>
        ) : (
          "No Data Found"
        )}
        <TablePagination
          className="tablepagination"
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: "All", value: totalpage?.total_item },
          ]}
          // colSpan={3}
          count={totalCount}
          colSpan={7}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          SelectProps={{
            inputProps: { "aria-label": "Items per Page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Items per Page"}
          ActionsComponent={TablePaginationActions}
        />
      </Box>

      {/* Table Pagination */}
      {/* <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 1, borderRadius: 1 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              showFirstButton={false}
            />
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
};

export default StoreTraining;
