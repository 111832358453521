/** @format */

import React, { useState, useEffect } from "react";
import {
  Grid,
  TablePagination,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Chip,
  IconButton,
} from "@mui/material";
import View from "../../images/View.svg";
import Delete from "../../images/Delete.svg";
import ViewHover from "../../images/ViewHover.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AllTrainingReportApi,
  TrainingArchivedApi,
} from "../../redux/actions/LmsAction";
import * as moment from "moment";
import Highlighter from "react-highlight-words";
import Progressbar from "../../GlobalProgress/Progressbar";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TableHeader from "../allTrainingReport/pages/Header";
import { confirmAlert } from "react-confirm-alert";
import { DeleteFormListApi } from "../../redux/actions/Checklist";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import { ToastContainer } from "react-toastify";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const TrainingReport = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
    Delete: false,
  });
  const [allTrainingData, setAllTrainingData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [permission, setPermission] = useState({});
  const [loader, setLoader] = useState(true);

  const allTrainingList = useSelector(
    (state) =>
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.training
  );

  const totalpage = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.paginate
  );

  const ArchivedListTotalItems = useSelector(
    (state) =>
      state.lms &&
      state.lms?.trainingListArchived &&
      state.lms?.trainingListArchived?.data &&
      state.lms?.trainingListArchived?.data?.data &&
      state.lms?.trainingListArchived?.data?.data?.paginate
  );

  const ArchivedListData = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms?.trainingListArchived &&
      state.lms?.trainingListArchived?.data &&
      state.lms?.trainingListArchived?.data?.data &&
      state.lms?.trainingListArchived?.data?.data?.training
  );
  const ArchivedListDataLoading = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms?.loading
  );
  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      archived: false,
      search: "",
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("trainingChapters");
    localStorage.removeItem("assesmentData");
    localStorage.removeItem("certificateData");
    localStorage.removeItem("editChapters");
    localStorage.removeItem("addTrainingData");
    localStorage.removeItem("edittrainingid");
  }, []);

  const ArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `Archivedtraining?company_id=${id}`;
    dispatch(TrainingArchivedApi(paginationRequest));
  };

  useEffect(() => {
    if (searchValue == "") {
      paginationRequest.body.search = searchValue;
      ArchivedApi(paginationRequest);
      getApi(paginationRequest);
    }
  }, [searchValue]);

  useEffect(() => {
    var localPageno = localStorage.getItem("pageno");
    paginationRequest.body.pagination.page = localPageno
      ? parseInt(localPageno)
      : 1;
    // setLoader(true);
    getApi(paginationRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (totalpage) {
      setTotalCount(totalpage?.total_item);
    }
  }, [totalpage]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setAllTrainingData(allTrainingList);
  }, [allTrainingList]);

  useEffect(() => {
    getApi(paginationRequest);
  }, []);

  const getApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `training/index?company_id=${id}`;
    dispatch(AllTrainingReportApi(paginationRequest));
  };

  useEffect(() => {
    if (checkedArchive) {
      setAllTrainingData(ArchivedListData);
    } else {
      setAllTrainingData(allTrainingList);
    }
  }, [allTrainingList, ArchivedListData, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(ArchivedListTotalItems?.total_item);
    } else {
      setTotalCount(totalpage?.total_item);
    }
  }, [ArchivedListTotalItems, totalpage, checkedArchive]);

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message: 'Are you sure to delete "' + values.name + '" Training',
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = values?._id;
            const data = {
              url:
                BASE_URL +
                `training/${userRoleId}?force=false&company_id=${id}`,
            };
            setLoader(true);
            dispatch(DeleteFormListApi(data));
            setTimeout(() => {
              getApi(paginationRequest);
              setLoader(false);
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleChange = (event) => {
    paginationRequest.body.search = searchValue;
    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (checkedArchive === false) {
        setLoader(true);
        paginationRequest.body.pagination.page = 1;
        paginationRequest.body.search = searchValue;
        getApi(paginationRequest);
        setPage(1);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      } else {
        paginationRequest.body.search = searchValue;
        paginationRequest.body.pagination.page = 1;
        ArchivedApi(paginationRequest);
        setPage(1);
      }
    }
  };

  const handleEditForm = (row) => {
    navigate(`/training/edit-training`, {
      state: { rows: row?._id, training: "alltrainingreport" },
    });
  };

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    paginationRequest.body.pagination.page = newPage + 1;
    paginationRequest.body.search = searchValue;

    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    paginationRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 0,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        let Data = checkedArchive ? ArchivedListData : allTrainingList
        const index =
        Data.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "0px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Training List",
      headerName: "Training List",
      renderHeader: () => <strong>{"Training List"}</strong>,
      flex: 1,
      align: "left",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.name) {
          result.push(params?.row?.name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.name) {
          result.push(params?.row?.name);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <Typography variant="p" component="p" className="text-short3">
              {results}
            </Typography>
          </LightTooltip>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      renderHeader: () => <strong>{"Category"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.category) {
          result.push(params?.row?.category);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.category) {
          result.push(params?.row?.category);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return <div>{results}</div>;
      },
    },
    {
      field: "Sub-Category",
      headerName: "Sub-Category",
      renderHeader: () => <strong>{"Sub-Category"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.subCategory) {
          result.push(params?.row?.subCategory);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.subCategory) {
          result.push(params?.row?.subCategory);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return <div>{results}</div>;
      },
    },
    // {
    //   field: "Created",
    //   headerName: "Created",
    //   renderHeader: () => <strong>{"Created"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.createdAt) {
    //       result.push(params?.row?.createdAt);
    //     } else {
    //       result = ["Unknown"];
    //     }
    //     return result.join(", ");
    //   },
    //   renderCell: (params) => {
    //     let result = [];
    //     if (params?.row?.createdAt) {
    //       result.push(params?.row?.createdAt);
    //     } else {
    //       result = ["N/A"];
    //     }
    //     let results = result.join(", ");
    //     return <div>{moment(results).format("lll")}</div>;
    //   },
    // },

    {
      // Increase the width of each property for better readability
      field: "Time Period",
      headerName: "Time Period",
      renderHeader: () => <strong>{"Time Period"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let startsAt = params?.row?.starts_at
          ? moment(params.row.starts_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "Unknown";
        let expiresAt = params?.row?.expires_at
          ? moment(params.row.expires_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "Unknown";
        return startsAt + " To " + expiresAt;
      },
      renderCell: (params) => {
        let startsAt = params?.row?.starts_at
          ? moment(params.row.starts_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "N/A";
        let expiresAt = params?.row?.expires_at
          ? moment(params.row.expires_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "N/A";
        let results = startsAt + " to " + expiresAt;
        return <div>{results}</div>;
      },
    },

    {
      field: "Updated",
      headerName: "Updated",
      renderHeader: () => <strong>{"Updated"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          result.push(params?.row?.updatedAt);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          result.push(params?.row?.updatedAt);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return <div>{moment(results).format("MMMM D, YYYY")}</div>;
      },
    },
    {
      field: "Assigned Users",
      headerName: "Assigned Users",
      renderHeader: () => <strong>{"Assigned Users"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.role) {
          result.push(params?.row?.role);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.role) {
          result.push(params?.row?.role);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <div
            style={{ height: "45px", display: "flex", alignItems: "center" }}
          >
            {results}
          </div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              paddingLeft: 0,
            }}
          >
            {permission?.lms?.manageLms === true ? (
              <Tooltip title="Edit">
              <img
                onClick={() => handleEditForm(params?.row)}
                src={
                  hover.Edit && indexHover === params?.row?._id
                    ? EditHover
                    : Edit
                }
                alt="Edit"
                style={{
                  height: "40px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
              </Tooltip>
            ) : (
              ""
            )}

          <Tooltip title="View">
            <img
              onClick={() => handleClick(params?.row)}
              src={
                hover.View && indexHover === params?.row?._id ? ViewHover : View
              }
              alt="VIEW"
              style={{
                height: "40px",
                cursor: "pointer",
              }}
              onMouseOver={() => {
                setHover({ View: true });
                setIndexHover(params?.row?._id);
              }}
              onMouseLeave={() => {
                setHover({ View: false });
              }}
            />
            </Tooltip>

            {permission?.lms?.manageLms === true ? (
              <Tooltip title="Delete">
                <img
                src={
                  hover.Delete && indexHover === params?.row?._id
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{
                  height: "40px",
                  cursor: "pointer",
                }}
                onClick={() => handleDelete(params?.row)}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const handleClick = (row) => {
    navigate("/alltraining-report/individual-training", { state: row });
  };

  // const bodyObj = {
  //   pagination: {
  //     page: 1,
  //     per_page: 10,
  //   },
  //   archived: false,
  // };

  return (
    <Box>
      <ToastContainer autoClose={3000} />

      <TableHeader
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        ArchivedListTotalItems={ArchivedListTotalItems?.total_item}
        totalitems={totalpage?.total_item}
        setCheckedArchive={setCheckedArchive}
        totalCount={totalCount}
        checkedArchive={checkedArchive}
        // ArchivedApi={ArchivedApi}
        rowsPerPage={rowsPerPage}
        page={page}
        totalpage={totalpage}
        setPage={setPage}
        training={"alltrainingreport"}
      />
      <SingleGlobalTable
        data={checkedArchive ? ArchivedListData : allTrainingList}
        columns={columns}
        totalCount={totalCount}
        loading={ArchivedListDataLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      {/* {Loader || !allTrainingData ? (
        <Progressbar /> */}
      {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : ( */}
      {/* <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "calc(100vh - 220px)",
            mt: 1,
            p: 1,
            overflowX: "auto",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              margin: "5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        > */}
      {/* <TableContainer component={Paper}>
          <Table size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Training List
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Category
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Sub-Category
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Created
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Updated
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Assigned Users
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTrainingData &&
                allTrainingData.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      align="left"
                      sx={{ color: "#27364B", whiteSpace: "nowrap" }}
                    >
                      <Typography variant="p" component="b">
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#27364B", whiteSpace: "nowrap" }}
                    >
                      <Typography variant="p" component="p">
                        {row.category}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#27364B", whiteSpace: "nowrap" }}
                    >
                      {row.subCategory}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#27364B", whiteSpace: "nowrap" }}
                    >
                      <Typography variant="p" component="p">
                        {moment(row.createdAt).format("lll")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "#27364B", whiteSpace: "nowrap" }}
                    >
                      <Typography variant="p" component="p">
                        {moment(row.updatedAt).format("lll")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#27364B" }}>
                      {row.AssignedUsers}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          onClick={()=>handleClick(row)}
                          src={
                            hover.View && indexHover === i ? ViewHover : View
                          }
                          alt="VIEW"
                          style={{
                            height: "40px",
                            cursor: "pointer",
                          }}
                          onMouseOver={() => {
                            setHover({ View: true });
                            setIndexHover(i);
                          }}
                          onMouseLeave={() => {
                            setHover({ View: false });
                          }}
                        />
                        <img
                          src={
                            hover.Delete && indexHover === i
                              ? DeleteHover
                              : Delete
                          }
                          alt="Delete"
                          style={{
                            height: "40px",
                            cursor: "pointer",
                          }}
                          onMouseOver={() => {
                            setHover({ Delete: true });
                            setIndexHover(i);
                          }}
                          onMouseLeave={() => {
                            setHover({ Delete: false });
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      {/* {allTrainingData && allTrainingData.length > 0 ? (
            <>
              <DataGrid
                disableColumnMenu
                rows={allTrainingData}
                columns={columns}
                getRowId={(allTrainingData) => allTrainingData._id}
                paginationMode="server"
                pageSize={rowsPerPage}
                pagination={true}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={totalCount}
                page={page - 1}
                getRowHeight={() => "auto"}
                hideFooter={true}
                hideFooterPagination={true}
                className="grid-table"
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalpage?.total_item },
                ]}
                count={totalCount}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              No Trainings Created
            </div>
          )} */}
      {/* </Box> */}
      {/* )} */}
      {/* Table Pagination */}
      {/* <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 1, borderRadius: 1 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              showFirstButton={false}
            />
          </Grid>
        </Grid>
      </Box> */}
    <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default TrainingReport;
