/** @format */

import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  Container,
  Box,
  FormControl,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";

const Company = () => {
  const [companyName, setCompanyName] = useState("");
  // const [error, setError] = useState(null);
  const [error, setError] = useState(false);

  // const handleCompanyName = (e) => {
  //   setCompanyName(e.target.value);
  //   setError(false);
  // };
  const handleCompanyName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Regular expression to allow only letters, numbers and spaces

    if (regex.test(value)) {
      setCompanyName(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  // const handleNavigate = () => {
  //   setError(true);
  // };
  const handleNavigate = () => {
    if (!companyName) {
      setError(true);
    }
  };

  return (
    <Box>
      <Container
        maxWidth="xl"
        sx={{ bgcolor: "#F6F8FC", p: 4, borderRadius: 2 }}
        className="box-space"
      >
        <Card
          sx={{ p: 20, pl: 8, textAlign: "start" }}
          className="card zero-space"
        >
          {/* <CardContent>
            <FormControl margin="normal" required>
              <label style={{ fontWeight: "bolder", marginBottom: "20px" }}>
                What do people call your Company?
              </label>
              <TextField
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                placeholder="Company Name"
                size="small"
                value={companyName}
                name="companyname"
                onChange={(e) => handleCompanyName(e)}
              />
              <p className="error-msg">{error === true ? "Company Name Required" : ""}</p>
            </FormControl>
          </CardContent>
          <CardActions>
            <Link
              to={companyName ? "/company/create" : '/company'}
              state={companyName}
              className="submenu-item">
              <Button
                onClick={handleNavigate}
                size="small"
                variant="contained"
                className="company-name-btn"
              >
                OK
              </Button>
            </Link>
          </CardActions> */}

          <CardContent>
            <FormControl margin="normal" required>
              <label style={{ fontWeight: "bolder", marginBottom: "20px" }}>
                What do people call your Company?
              </label>
              <TextField
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                placeholder="Company Name"
                size="small"
                value={companyName}
                name="companyname"
                onChange={(e) => handleCompanyName(e)}
              />
              {error && (
                <p className="error-msg">
                  Company Name Required and Special Characters are not allowed
                </p>
              )}
            </FormControl>
          </CardContent>
          <CardActions>
            <Link
              to={companyName ? "/company/create" : "/company"}
              state={companyName}
              className="submenu-item"
            >
              <Button
                onClick={handleNavigate}
                size="small"
                variant="contained"
                className="company-name-btn"
              >
                OK
              </Button>
            </Link>
          </CardActions>
        </Card>

        <Card
          sx={{
            minWidth: 275,
            mt: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="card back-btn"
        >
          <p
            style={{
              color: "#858796",
              padding: "10px 20px",
              fontWeight: "600",
            }}
          >
            Already have Companies
          </p>
          <Link to={"/company-list"} className="submenu-item">
            <Button size="small" variant="contained" className="company-btn">
              <KeyboardBackspaceIcon sx={{ px: 1, fontSize: "35px" }} />
              My Companies
            </Button>
          </Link>
        </Card>
      </Container>
    </Box>
  );
};

export default Company;
