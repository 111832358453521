import {
  ADD_ALLFORM_REQUEST,
  ADD_ALLFORM_SUCCESS,
  ADD_ALLFORM_ERROR,
  ADD_ALLFORMEDIT_ERROR,
  ADD_ALLFORMEDIT_REQUEST,
  ADD_ALLFORMEDIT_SUCCESS,
  ADD_ASSESSTMENTEDIT_SUCCESS,
  ADD_ASSESSTMENTEDIT_REQUEST,
  ALLFORM_UPDATE_REQUEST,
  ALLFORM_UPDATE_SUCCESS,
  ALLFORM_UPDATE_ERROR,
  ADDALLFORMS_UPDATE_REQUEST,
  ADDALLFORMS_UPDATE_SUCCESS,
  ADDALLFORMS_UPDATE_ERROR,
  ADDALLFORMS_RESET_SUCCESS,
  ALLFORM_DELETE_REQUEST,
  ALLFORM_DELETE_SUCCESS,
  ALLFORM_DELETE_ERROR,
  ALLFORM_EDITSTORES_UPDATE_REQUEST,
  ALLFORM_EDITSTORES_UPDATE_SUCCESS,
  ALLFORM_EDITSTORES_UPDATE_ERROR,
  ASSESSMENT_EDITSTORES_UPDATE_REQUEST,
  ASSESSMENT_EDITSTORES_UPDATE_SUCCESS,
  ASSESSMENT_EDITSTORES_UPDATE_ERROR,
  ALLFORM_UPDATE_QUESTION_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  allForm: [],
  error: "",
  id: null,
  addqustionedit: [],
  assesstqustionedit: [],
};

const AllFormDataStore = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALLFORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ALLFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        allForm: action.payload,
        error: "",
      };
    case ADD_ALLFORM_ERROR:
      return {
        loader: false,
        allForm: [],
        error: action.error,
      };
    case ADD_ALLFORMEDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ALLFORMEDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        addqustionedit: action.payload,
        error: "",
      };
    case ADD_ASSESSTMENTEDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ASSESSTMENTEDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        assesstqustionedit: action.payload,
        error: "",
      };
    case ADD_ALLFORMEDIT_ERROR:
      return {
        loader: false,
        addqustionedit: [],
        error: action.error,
      };
    case ALLFORM_EDITSTORES_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALLFORM_EDITSTORES_UPDATE_SUCCESS:
      // let index = state?.allForm?.questions?.findIndex(
      //   (todo) => (todo && todo.id) === (action.payload && action.payload.id)
      // ); //finding index of the item
      let editnewArray = state.addqustionedit; //making a new array
      if (editnewArray) {
        editnewArray[action.id] = action.payload; //changing value in the new array
        // newArray.questions[index].desc = action.payload.desc; //changing value in the new array
        // newArray.questions[index].question_score =
        //   action.payload.question_score;
      }
      // localStorage.removeItem('iduser');
      localStorage.setItem("questions", JSON.stringify(editnewArray));
      return {
        ...state, //copying the orignal state
        addqustionedit: editnewArray, //reassingning todos to new array
      };

    case ALLFORM_EDITSTORES_UPDATE_ERROR:
      return {
        ...state,
        loader: false,
        addqustionedit: [],
        // error: action.error,
      };

    case ASSESSMENT_EDITSTORES_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSESSMENT_EDITSTORES_UPDATE_SUCCESS:
      // let index = state?.allForm?.questions?.findIndex(
      //   (todo) => (todo && todo.id) === (action.payload && action.payload.id)
      // ); //finding index of the item
      let editnewArrays = state.assesstqustionedit; //making a new array
      if (editnewArrays) {
        editnewArrays[action.id] = action.payload; //changing value in the new array
        // newArray.questions[index].desc = action.payload.desc; //changing value in the new array
        // newArray.questions[index].question_score =
        //   action.payload.question_score;
      }
      // localStorage.removeItem('iduser');
      localStorage.setItem("assestsQustions", JSON.stringify(editnewArrays));
      return {
        ...state, //copying the orignal state
        assesstqustionedit: editnewArrays, //reassingning todos to new array
      };

    case ASSESSMENT_EDITSTORES_UPDATE_ERROR:
      return {
        ...state,
        loader: false,
        assesstqustionedit: [],
        // error: action.error,
      };

    case ADDALLFORMS_RESET_SUCCESS:
      return {
        ...state,
        loader: false,
        addqustionedit: [],
        // error: action.error,
      };
    case ALLFORM_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALLFORM_UPDATE_QUESTION_SUCCESS:
      let newArray1 = state.allForm; //making a new array
      if (newArray1) {
        newArray1.questions = action?.payload; //changing value in the new array
      }
      localStorage.removeItem("iduser");
      return {
        ...state, //copying the orignal state
        allForm: newArray1, //reassingning todos to new array
      };
    case ALLFORM_UPDATE_SUCCESS:
      // let index = state?.allForm?.questions?.findIndex(
      //   (todo) => (todo && todo.id) === (action.payload && action.payload.id)
      // ); //finding index of the item
      let newArray = state.allForm; //making a new array
      if (newArray) {
        const getIndex = newArray.questions.findIndex(item => item.id === action?.payload?.id);
        newArray.questions[getIndex] = action?.payload; //changing value in the new array
        // newArray.questions[index].desc = action.payload.desc; //changing value in the new array
        // newArray.questions[index].question_score =
        //   action.payload.question_score;
      }
      localStorage.removeItem("iduser");
      console.log("newArray", newArray);
      return {
        ...state, //copying the orignal state
        allForm: newArray, //reassingning todos to new array
      };

    case ALLFORM_UPDATE_ERROR:
      return {
        loader: false,
        allForm: [],
        error: action.error,
      };

    case ADDALLFORMS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADDALLFORMS_UPDATE_SUCCESS:
      const scores = state.allForm;
      let mainindex = Object.values(state?.allForm).findIndex(
        (todo) => todo._id === action.payload.id
      );
      // let mainindex = scores.filter(scores => scores._id === action.payload.id); //finding index of the item
      let newArrays = state.allForm; //making a new array
      if (newArrays) {
        newArrays[mainindex].title = action.payload.title; //changing value in the new array
      }

      return {
        ...state, //copying the orignal state
        allForm: newArrays, //reassingning todos to new array
      };

    case ADDALLFORMS_UPDATE_ERROR:
      return {
        loader: false,
        allForm: [],
        error: action.error,
      };

    case ALLFORM_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALLFORM_DELETE_SUCCESS:
      const newdata =
        state &&
        state.allForm &&
        state.allForm.questions.filter((data) => data.id !== action.payload);
      let newArrays1 = state.allForm; //making a new array
      if (newArrays1) {
        newArrays1.questions = newdata; //changing value in the new array
      }
      return {
        loading: false,
        ...state,
        allForm: newArrays1,
        // allForm: Object.values(state?.allForm).filter(
        //   (item, i) =>
        //     item && item.questions && item.questions[i].id !== action.payload
        // ),
      };

    case ALLFORM_DELETE_ERROR:
      return {
        loader: false,
        allForm: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default AllFormDataStore;
