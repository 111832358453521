/** @format */

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Blank from "../../images/Blank.png";
import {
  Typography,
  Grid,
  TablePagination,
  IconButton,
  TableFooter,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import User from "../../../assets/icons/svg-icons/User.svg";
import TableHeader from "./pages/TableHeader";
import attechment from "../../images/attechment.png";
import { useDispatch, useSelector } from "react-redux";
import {
  CertificateListApi,
  AddCertificateSuccess,
  AddCertificateImageSuccess,
  EditCertificateSuccess,
  DeleteCertificateApi,
  CertificateArchivedApi,
  AddCertificateImageReset,
} from "../../redux/actions/LmsAction";
import * as moment from "moment";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { confirmAlert } from "react-confirm-alert";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { toast, ToastContainer } from "react-toastify";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const CertificateList = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [certificateData, setCertificateData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [indexHover, setIndexHover] = useState(0);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [searchValue, setSearchValue] = useState("");
  const [permission, setPermission] = useState({});
  const [loader, setLoader] = useState(true);

  const certificateList = useSelector(
    (state) =>
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.certificates
  );

  const totalpage = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.paginate
  );

  const addCertificate = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.addCertificate &&
      state.lms.addCertificate.data
  );

  const ArchivedListTotalItems = useSelector(
    (state) =>
      state.lms &&
      state.lms?.certificateListArchived &&
      state.lms?.certificateListArchived?.data &&
      state.lms?.certificateListArchived?.data?.data &&
      state.lms?.certificateListArchived?.data?.data?.paginate
  );

  const ArchivedListData = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms?.certificateListArchived &&
      state.lms?.certificateListArchived?.data &&
      state.lms?.certificateListArchived?.data?.data &&
      state.lms?.certificateListArchived?.data?.data?.certificates
  );

  const getImageKey = useSelector(
    (state) =>
      state.lms &&
      state.lms.addImage &&
      state.lms.addImage.length &&
      state.lms.addImage[0]?.imageData?.[0].key
  );

  const EditCertificate = useSelector(
    (state) =>
      state.lms && state.lms.editCertificate && state.lms.editCertificate.data
  );

  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
      archived: false,
    },
  };

  const ActivepaginationRequest = {
    pagination: {
      page: page,
      per_page: rowsPerPage,
    },
    search: "",
    archived: false,
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  const ArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `Archivedcertificate?company_id=${id}`;
    dispatch(CertificateArchivedApi(paginationRequest));
  };

  useEffect(() => {
    if (searchValue == "") {
      paginationRequest.body.search = searchValue;
      ActivepaginationRequest.search = searchValue;
      ArchivedApi(paginationRequest);
      getApi(ActivepaginationRequest);
    }
  }, [searchValue]);

  useEffect(() => {
    var localPageno = localStorage.getItem("pageno");
    ActivepaginationRequest.pagination.page = localPageno
      ? parseInt(localPageno)
      : 1;
    // setLoader(true);
    getApi(ActivepaginationRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const getApi = (ActivepaginationRequest) => {
    dispatch(CertificateListApi(ActivepaginationRequest));
  };

  useEffect(() => {
    setCertificateData(certificateList);
    dispatch(AddCertificateSuccess(""));
    dispatch(AddCertificateImageSuccess(""));
    dispatch(EditCertificateSuccess(""));
  }, [certificateList, addCertificate, getImageKey]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setTotalCount(totalpage?.total_item);
  }, [totalpage]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (checkedArchive) {
      setCertificateData(ArchivedListData);
    } else if (certificateList) {
      setCertificateData(certificateList);
    }
  }, [certificateList, ArchivedListData, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(ArchivedListTotalItems?.total_item);
    } else {
      setTotalCount(totalpage?.total_item);
    }
  }, [ArchivedListTotalItems, totalpage, checkedArchive]);

  //table pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChange = (event) => {
    paginationRequest.body.search = searchValue;
    ActivepaginationRequest.search = searchValue;

    if (checkedArchive === false) {
      getApi(ActivepaginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (checkedArchive === false) {
        setLoader(true);
        ActivepaginationRequest.pagination.page = 1;
        ActivepaginationRequest.search = searchValue;
        getApi(ActivepaginationRequest);
        setPage(1);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        // }
      } else {
        paginationRequest.body.search = searchValue;
        paginationRequest.body.pagination.page = 1;
        ArchivedApi(paginationRequest);
        setPage(1);
      }
    }
  };

  const handleEditForm = (row) => {
    dispatch(AddCertificateImageReset());
    navigate(`/certificate/edit-certificate`, { state: row });
  };

  const handleDelete = (certiId) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const certificateId = certiId;
            const data = {
              url:
                BASE_URL +
                `certificate/${certificateId}?force=false&company_id=${id}`,
            };
            setLoader(true);
            dispatch(DeleteCertificateApi(data));
            dispatch(CertificateListApi(paginationRequest));
            toast.success("Certificate Deleted Successfully", {
              autoClose: 2000,
            });
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          certificateData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Title",
      headerName: "Title",
      renderHeader: () => <strong>{"Title"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short">{results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => <strong>{"Description"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        let result = [];
        if (params?.row?.summary) {
          result.push(params?.row?.summary);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short">{results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Certificate Image",
      headerName: "Certificate Image",
      renderHeader: () => <strong>{"Certificate Image"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        let result = [];
        if (params?.row?.key?.url) {
          result.push(params?.row?.key?.url);
        } else {
          result.push(Blank);
        }
        let results = result.join(", ");
        return <img className="image-multi" src={results} />;
      },
    },
    {
      field: "Last Modified",
      headerName: "Last Modified",
      renderHeader: () => <strong>{"Last Modified"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Assigned To",
      headerName: "Assigned To",
      renderHeader: () => <strong>{"Assigned To"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.AssignedTo).format(
          "MMMM D, YYYY"
        );
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            {permission?.lms?.certificate === true ? (
              <>
              <Tooltip title="Edit">
                <img
                  onClick={() => handleEditForm(params?.row)}
                  src={
                    hover.Edit && indexHover === params?.row?._id
                      ? EditHover
                      : Edit
                  }
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Edit: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Edit: false });
                  }}
                />
                </Tooltip>
                <Tooltip title="Delete">
                <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
                </Tooltip>
              </>
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    },
  ];

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    paginationRequest.body.pagination.page = newPage + 1;
    paginationRequest.body.search = searchValue;
    ActivepaginationRequest.pagination.page = newPage + 1;
    ActivepaginationRequest.search = searchValue;

    if (checkedArchive === false) {
      getApi(ActivepaginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    paginationRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    ActivepaginationRequest.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    if (checkedArchive === false) {
      getApi(ActivepaginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  return (
    <Box>
      <TableHeader
        handleChange={handleChange}
        totalitems={totalpage?.total_item}
        setCheckedArchive={setCheckedArchive}
        checkedArchive={checkedArchive}
        handleKeyDown={handleKeyDown}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        // ArchivedApi={ArchivedApi}
        rowsPerPage={rowsPerPage}
        page={page}
        totalCount={totalCount}
        totalpage={totalpage}
        ArchivedListTotalItems={ArchivedListTotalItems?.total_item}
        setPage={setPage}
        permission={permission}
      />
      <SingleGlobalTable
        data={certificateData}
        columns={columns}
        totalCount={totalCount}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      {/* {Loader ? (
        <Progressbar /> */}
      {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (        */}

      {/* <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "calc(100vh - 220px)",
            mt: 1,
            p: 1,
            overflowX: "auto",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          {certificateData ? (
            <>
              <ToastContainer />
              <DataGrid
                disableColumnMenu
                rows={certificateData}
                columns={columns}
                getRowId={(certificateData) => certificateData._id}
                paginationMode="server"
                pageSize={rowsPerPage}
                pagination={true}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={totalCount}
                page={page - 1}
                getRowHeight={() => "auto"}
                className="grid-table"
                hideFooter={true}
                hideFooterPagination={true}
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalpage?.total_item },
                ]}
                count={totalCount}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            "No Data Found"
          )}
        </Box> */}
      {/* )} */}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default CertificateList;
