/** @format */

import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import CLHeaderView from "./CLHeaderView";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ViewCustomerLogApi } from "../../redux/actions/CustomerLogAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CallIcon from "@mui/icons-material/Call";
import moment from "moment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Blink from "react-blink-text";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function CustomerLogView() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [detailedData, setDetailedData] = React.useState([]);
  const [assignedToDetails, setAssignedToDetails] = React.useState([]);
  const [activityDetails, setActivityDetails] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  const getCustomerLogDetails = useSelector(
    (state) =>
      state.CustomerLogs &&
      state.CustomerLogs.details &&
      state.CustomerLogs.details.data &&
      state.CustomerLogs.details.data.data
  );
  // console.log("Customer details:", getCustomerLogDetails);

  const getAssignedToDetails = useSelector(
    (state) =>
      state.CustomerLogs &&
      state.CustomerLogs.details &&
      state.CustomerLogs.details.data &&
      state.CustomerLogs.details.data.data?.assignedTo
  );
  // console.log("Assigned To:", getAssignedToDetails);

  const activitiesDetails = useSelector(
    (state) =>
      state.CustomerLogs &&
      state.CustomerLogs.details &&
      state.CustomerLogs.details.data &&
      state.CustomerLogs.details.data.data?.activities
  );
  // console.log("activitiesDetails:", activitiesDetails);

  useEffect(() => {
    // setLoader(true);
    setDetailedData(getCustomerLogDetails);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [getCustomerLogDetails]);

  useEffect(() => {
    setAssignedToDetails(getAssignedToDetails);
  }, [getAssignedToDetails]);

  useEffect(() => {
    setActivityDetails(activitiesDetails);
  }, [activitiesDetails]);

  useEffect(() => {
    customerLogDetails();
  }, []);

  const customerLogDetails = () => {
    const _id = localStorage.getItem("companyId");
    const customerLogId = location?.state?._id;
    const data = {
      url: BASE_URL + `customerLog/${customerLogId}?company_id=${_id}`,
    };
    dispatch(ViewCustomerLogApi(data));
  };

  return (
    <Box>
      <CLHeaderView />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
          <Grid>
            <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card sx={{ p: 1, m: 1, height: "100%", borderRadius: "8px" }}>
                  <TableContainer
                    component={Paper}
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      align="left"
                      sx={{ ml: 1, my: 2, fontWeight: "bold" }}
                    >
                      CUSTOMER DETAILS
                    </Typography>
                    <Table className="priroty-table">
                      <TableBody>
                        {/* <TableRow sx={{ display: "flex" }}>
                         <TableCell className="header-table">TITLE:</TableCell>
                         <TableCell>
                           <Typography variant="p" component="b">
                             {detailedData?.title}
                             {console.log(detailedData,"id")}
                           </Typography>
                         </TableCell>
                       </TableRow> */}
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            <AccountCircleIcon>NAME:</AccountCircleIcon>
                          </TableCell>
                          <TableCell>
                            <Typography variant="p" component="b">
                              {detailedData?.custName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            <MailOutlineIcon>EMAIL:</MailOutlineIcon>
                          </TableCell>
                          <TableCell>
                            <Typography variant="p" component="b">
                              {detailedData?.custEmail}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            CREATED ON:
                          </TableCell>
                          <TableCell>
                            <Typography variant="p" component="b">
                              {moment(detailedData?.createdAt).format("lll")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            UPDATED ON:
                          </TableCell>
                          <TableCell>
                            <Typography variant="p" component="b">
                              {moment(detailedData?.updatedAt).format("lll")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            CATEGORY:
                          </TableCell>
                          <TableCell>
                            <Typography variant="p" component="b">
                              {detailedData?.category?.title}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            STATUS:
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="p"
                              component="b"
                              className="blink"
                            >
                              {detailedData?.status === "closed-sold" ? (
                                <Blink
                                  color="green"
                                  text="closed-sold"
                                  fontSize="50"
                                  fontWeight="bold"
                                ></Blink>
                              ) : (
                                detailedData?.status || "N/A"
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ display: "flex" }}>
                          <TableCell className="header-table">
                            DESCRIPTION:
                          </TableCell>
                          <TableCell>
                            <Typography variant="p" component="b">
                              {detailedData?.description}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <hr />
                        <TableContainer>
                          <Typography
                            variant="h6"
                            component="p"
                            align="left"
                            sx={{ ml: 1, my: 2, fontWeight: "bold" }}
                          >
                            CREATED BY
                          </Typography>
                          <Table className="priroty-table">
                            <TableBody>
                              <TableRow sx={{ display: "flex" }}>
                                <TableCell className="header-table">
                                  <AccountCircleIcon>NAME:</AccountCircleIcon>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p" component="b">
                                    {detailedData?.createdBy?.name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow sx={{ display: "flex" }}>
                                <TableCell className="header-table">
                                  <MailOutlineIcon>EMAIL:</MailOutlineIcon>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p" component="b">
                                    {detailedData?.createdBy?.email}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow sx={{ display: "flex" }}>
                                <TableCell className="header-table">
                                  <CallIcon>MOBILE:</CallIcon>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p" component="b">
                                    {detailedData?.custContactNumber}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow sx={{ display: "flex" }}>
                                <TableCell className="header-table">
                                  <div>
                                    <b> Employee Code:</b>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p" component="b">
                                    {detailedData?.employeeCode}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              {/* <hr /> */}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>

              {/* Second right part  */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Card sx={{ p: 1, m: 1, height: "100%", borderRadius: "8px" }}>
                  <Typography
                    variant="h6"
                    component="p"
                    align="left"
                    sx={{ ml: 1, my: 2, fontWeight: "bold" }}
                  >
                    ASSIGNED TO DETAILS:
                  </Typography>
                  <TableContainer
                    component={Paper}
                    sx={{ borderRadius: "8px" }}
                  >
                    <Table size="large" aria-label="simple table">
                      <TableBody>
                        {assignedToDetails &&
                          assignedToDetails.length > 0 &&
                          assignedToDetails.map((row, i) => {
                            return (
                              <Box key={i}>
                                {/* <TableRow >
                                 <TableCell className="header-table">ASSIGNED BY:</TableCell>
                                 <TableCell>ASSIGNED BY:<Typography variant="p" component="b">{row?.user?.name || 'N/A'}</Typography></TableCell>
                               </TableRow>
                               <TableRow >
                                 <TableCell className="header-table" align="left">EMAIL:</TableCell>
                                 <TableCell>EMAIL:<Typography variant="p" component="b">{row?.user?.email || 'N/A'}</Typography></TableCell>
                               </TableRow> */}

                                <TableRow>
                                  {(() => {
                                    switch (row?.type) {
                                      case "self":
                                        return (
                                          <>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                ASSIGNED BY:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.user?.name || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell
                                                className="header-table"
                                                align="left"
                                              >
                                                EMAIL:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.user?.email || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                ASSIGNED TO:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.user?.name || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                ASSIGNED FOR:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.type || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                ASSIGNED ON:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {moment(
                                                    row?.time || "N/A"
                                                  ).format("lll")}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );
                                      case "delegated":
                                        return (
                                          <>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                DELEGATED TO:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.user?.name || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                DELEGATED FOR:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.type || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                DELEGATED ON:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {moment(
                                                    row?.time || "N/A"
                                                  ).format("lll")}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );

                                      default:
                                        return (
                                          <>
                                            <TableRow>
                                              <TableCell className="header-table">
                                                ASSIGNED BY:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.user?.name || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell
                                                className="header-table"
                                                align="left"
                                              >
                                                EMAIL:
                                              </TableCell>
                                              <TableCell>
                                                <Typography
                                                  variant="p"
                                                  component="b"
                                                >
                                                  {row?.user?.email || "N/A"}
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );
                                    }
                                  })()}
                                </TableRow>
                              </Box>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* <hr /> */}

                  {/* Brand details  */}
                  <TableContainer>
                    <Typography
                      variant="h6"
                      component="p"
                      align="left"
                      sx={{ ml: 1, my: 2, fontWeight: "bold" }}
                    >
                      BRAND DETAILS
                    </Typography>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">BRAND:</TableCell>
                      <TableCell>
                        <Typography variant="p" component="b">
                          {detailedData?.Brand || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">
                        MODEL NUMBER:
                      </TableCell>
                      <TableCell>
                        <Typography variant="p" component="b">
                          {detailedData?.ModelNumber || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">
                        TYPE OF ENQUIRY:
                      </TableCell>
                      <TableCell>
                        <Typography variant="p" component="b">
                          {detailedData?.TypeOfEnquiry || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">
                        HANDLED BY:
                      </TableCell>
                      <TableCell>
                        <Typography variant="p" component="b">
                          {detailedData?.HandledBy || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell className="header-table">
                        VALUE OF ENQUIRY:
                      </TableCell>
                      <TableCell>
                        <Typography variant="p" component="b">
                          {detailedData?.valueofEnquiry || "N/A"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableContainer>
                </Card>
              </Grid>

              {/* Last part  */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card sx={{ p: 1, m: 1, height: "100%", borderRadius: "8px" }}>
                < div style={{float:"right",marginRight:"10px",right:"0",marginTop:"4px"}}>
                    <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
                  <Typography
                    variant="h6"
                    component="p"
                    align="left"
                    sx={{ ml: 1, my: 2, fontWeight: "bold" }}
                  >
                    ACTIVITY
                  </Typography>

                  <Box
                    sx={{
                      bgcolor: "#F6F8FC",
                      height: "80vh",
                      mt: 1,
                      p: 1,
                      overflowX: "hidden",
                      overflowY: "scroll",
                      borderRadius: "8px",
                      px: 1,
                      "&::-webkit-scrollbar": {
                        width: 15,
                      },
                      "&::-webkit-scrollbar-track": {
                        padding: "12px 5px",
                        backgroundColor: "#CBD4E1",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#64748B",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      sx={{ borderRadius: "8px" }}
                    >
                      <Table size="large" aria-label="simple table">
                        <TableBody>
                          {activitiesDetails &&
                            activitiesDetails.length > 0 &&
                            activitiesDetails.map((row, i) => {
                              return (
                                <Box key={i}>
                                  {/* <TableRow>
                                   <TableCell>
                                     <Typography variant="p" component="b">
                                       {moment(row?.time).format("LL")}
                                     </Typography>
                                   </TableCell>
                                 </TableRow> */}
                                  <div>
                                    {(() => {
                                      switch (row?.type) {
                                        case "created":
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell className="header-table">
                                                  Log was created for:
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="b"
                                                  >
                                                    {row?.data?.current
                                                      ?.custName || "N/A"}
                                                  </Typography>
                                                </TableCell>
                                                {/* <TableCell className="header-table">
                                                 labeled with
                                               </TableCell> */}
                                                {/* <TableCell>
                                                 <Typography
                                                   variant="p"
                                                   component="b"
                                                 >
                                                   {row?.data?.current?.title ||
                                                     "N/A"}
                                                 </Typography>
                                               </TableCell> */}
                                              </TableRow>
                                              <TableRow>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="p"
                                                  >
                                                    {
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {moment(
                                                          row?.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        ) || "N/A"}
                                                      </span>
                                                    }
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        case "assignedTo":
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell className="header-table">
                                                  Log was assigned on
                                                </TableCell>
                                                {/* it will loop through */}
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="b"
                                                  >
                                                    {
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {moment(
                                                          row?.data?.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        ) || "N/A"}
                                                      </span>
                                                    }
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="p"
                                                  >
                                                    {moment(
                                                      row?.current?.Date
                                                    ).format("lll") || "N/A"}
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        case "scheduled":
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell className="header-table">
                                                  Next appointment is scheduled
                                                  on
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="b"
                                                  >
                                                    {
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {moment(
                                                          row?.data?.current
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        ) || "N/A"}
                                                      </span>
                                                    }
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                              {/* <TableRow>
                                               <TableCell>
                                                 <Typography
                                                   variant="p"
                                                   component="p"
                                                 >
                                                   {moment(
                                                     row?.current?.Date
                                                   ).format("lll") || "N/A"}
                                                 </Typography>
                                               </TableCell>
                                             </TableRow> */}
                                            </>
                                          );
                                        case "statusChanged":
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell className="header-table">
                                                  Status changed from
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="b"
                                                  >
                                                    {row?.data?.previous ||
                                                      "N/A"}
                                                  </Typography>
                                                </TableCell>
                                                <TableCell className="header-table">
                                                  to
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="b"
                                                  >
                                                    {row?.data?.current ||
                                                      "N/A"}
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="p"
                                                  >
                                                    {
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {moment(
                                                          row?.time
                                                        ).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        ) || "N/A"}
                                                      </span>
                                                    }
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        case "dataChanged":
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell className="header-table">
                                                  Log details updated
                                                </TableCell>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="b"
                                                  >
                                                    {moment(
                                                      row?.data?.time
                                                    ).format("lll") || "N/A"}
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell>
                                                  <Typography
                                                    variant="p"
                                                    component="p"
                                                  >
                                                    {moment(row?.time).format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ) || "N/A"}
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        default:
                                          return (
                                            <TableRow>
                                              <TableCell className="header-table">
                                                N/A
                                              </TableCell>
                                            </TableRow>
                                          );
                                      }
                                    })()}
                                  </div>
                                </Box>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
}

export default CustomerLogView;
