/** @format */

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import GraphCards from "./pages/GraphCards";
import MessageScheduleChart from "./pages/MessageScheduleChart";
import ChecklistGraphCard from "./pages/ChecklistGraphCard";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { MissedCheckLIstApi } from "../redux/actions/MissedCheckLIst";
import {
  CategoryListApi,
  DepartmentListApi,
  StoreListApi,
} from "../redux/actions/Checklist";
import {
  checklistCompletedApi,
  checklistCompletedSuccess,
  checklistReportApi,
  issueRaisedApi,
  issueRaisedSuccess,
} from "../redux/actions/dashboardChartAction";
import Progressbar from "../GlobalProgress/Progressbar";
import { DataListApi } from "../redux/actions/DataManager";
import { PrivateRouteApi } from "../redux/actions/LogInAction";
import { ProfileDetailSuccess } from "../redux/actions/profileDetail";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import ChecklistSubmit from "./ChecklistSubmit";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import { ProfileMeGetApiAction } from "../redux/actions/MyProfileDetailsAction";

const MenuProps = {
  disableScrollLock: true,
};

const Dashboard = ({ title }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const companyId = location?.state || localStorage.getItem("companyId");

  const [departmentData, setDepartmentData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [frequency, setFrequency] = useState("");
  const [companyMetrics, setCompanyMetrics] = useState({
    excel: 0,
    mail: 0,
    pdfs: 0,
  });
  const [planMetrics, setPlanMetrics] = useState({
    excel: 0,
    mail: 0,
    pdfs: 0,
  });

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  // console.log("Loader on dashboard:", Loader);

  const department = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.department &&
      state.checklist.department.data &&
      state.checklist.department.data.data &&
      state.checklist.department.data.data.departments
  );
  const category = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.category &&
      state.checklist.category.data &&
      state.checklist.category.data.data
  );
  const store = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.store &&
      state.checklist.store.data &&
      state.checklist.store.data.data &&
      state.checklist.store.data.data.stores
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const ListData = useSelector(
    (state) =>
      state &&
      state.dataManager &&
      state.dataManager.dataList &&
      state.dataManager.dataList.data &&
      state.dataManager.dataList.data.data &&
      state.dataManager.dataList.data.data.list
  );

  const questionData = useSelector(
    (state) =>
      state &&
      state.dataManager &&
      state.dataManager.dataList &&
      state.dataManager.dataList.data &&
      state.dataManager.dataList.data.data &&
      state.dataManager.dataList.data.data.questions
  );
  const questionGroupData = useSelector(
    (state) =>
      state &&
      state.dataManager &&
      state.dataManager.dataList &&
      state.dataManager.dataList.data &&
      state.dataManager.dataList.data.data &&
      state.dataManager.dataList.data.data.question_groups
  );
  const permissionDataApi = useSelector(
    (state) =>
      state?.logIn &&
      state?.logIn?.routingData &&
      state?.logIn?.routingData?.data &&
      state?.logIn?.routingData?.data?.data
  );

  const getDataProfileMe = useSelector(
    (state) =>
      state &&
      state.MyProfileDetails &&
      state.MyProfileDetails.progileMeGetApiRes &&
      state.MyProfileDetails.progileMeGetApiRes.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data.preferredCompany
  );
  // console.log("getDataProfileMe", getDataProfileMe);

  useEffect(() => {
    const ProfileDetail = async function () {
      const data = { url: BASE_URL + `profile/me` };
      dispatch(ProfileMeGetApiAction(data));
      // const token = localStorage.getItem("token");

      // try {
      //   const response = await axios.get(BASE_URL + `profile/me`, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   });
      //   // console.log(response, "response")
      // } catch (error) {
      //   console.error(error);
      // }
    };

    // setLoader(true);

    ProfileDetail();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const fetchAnalyticsData = async () => {
    const token = localStorage.getItem("token");
    const apiEndpoint = `https://hope.popprobe.com/api/analyticsApi?company_id=${companyId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(apiEndpoint, { headers });
      console.log("response", response);
      const { companyGenratedMatrics, data } = response.data;

      setCompanyMetrics({
        excel: companyGenratedMatrics.Excel || 0,
        mail: companyGenratedMatrics.Mail || 0,
        pdfs: companyGenratedMatrics.Pdfs || 0,
      });

      setPlanMetrics({
        excel: data.Excel - companyGenratedMatrics.Excel || 0,
        mail: data.Mail - companyGenratedMatrics.Mail || 0,
        pdfs: data.Pdfs - companyGenratedMatrics.Pdfs || 0,
      });
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };

  useEffect(() => {
    const local = localStorage.getItem("companyId");
    localStorage.setItem("recent1", companyId);
    if (!local) {
      localStorage.setItem("companyId", companyId);
    }
    ChecklistReportData(companyId, selectedSubRole);
    ListDepartment(companyId);
    ListStore(companyId);
    ListCatagory(companyId);
    DataApiList(companyId);
    const id = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `useradminpermission/permissions?company_id=${id}`,
    };
    dispatch(PrivateRouteApi(data));
    if (permissionDataApi) {
      localStorage.setItem("permissionData", JSON.stringify(permissionDataApi));
    }
    fetchAnalyticsData();
  }, []);

  useEffect(() => {
    if (permissionDataApi) {
      localStorage.setItem("permissionData", JSON.stringify(permissionDataApi));
    }
  }, [permissionDataApi]);

  useEffect(() => {
    if (ListData) {
      localStorage.setItem("dataListOfdata", JSON.stringify(ListData));
    }
  }, [ListData]);

  useEffect(() => {
    if (questionData) {
      localStorage.setItem(
        "questionsOfDatamanger",
        JSON.stringify(questionData)
      );
    }
  }, [questionData]);

  useEffect(() => {
    if (questionGroupData) {
      localStorage.setItem(
        "questionsGroupOfDatamanager",
        JSON.stringify(questionGroupData)
      );
    }
  }, [questionGroupData]);

  useEffect(() => {
    if (department) {
      // setDepartmentData(department);
      localStorage.setItem("departmentData", JSON.stringify(department));
    }
  }, [department]);

  useEffect(() => {
    if (category) {
      // setCategoryData(category);
      localStorage.setItem("categoryData", JSON.stringify(category));
    }
  }, [category]);

  useEffect(() => {
    if (store) {
      // setStoreData(store);
      localStorage.setItem("storeData", JSON.stringify(store));
    }
  }, [store]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const ListDepartment = (id) => {
    const data = { url: BASE_URL + `department/index?company_id=${id}` };
    dispatch(DepartmentListApi(data));
  };
  const ListStore = (id) => {
    const data = {
      body: {
        sort_as: "desc",
        sort_by: "title",
      },
    };
    data.url = BASE_URL + `stores/index?company_id=${id}`;
    dispatch(StoreListApi(data));
  };
  const ListCatagory = (id) => {
    const data = { url: BASE_URL + `categories?company_id=${id}` };
    dispatch(CategoryListApi(data));
  };
  const DataApiList = (id) => {
    // const id = localStorage.getItem('companyId')
    const data = { url: BASE_URL + `companies/${id}` };
    dispatch(DataListApi(data));
  };

  const ChecklistReportData = (id, selectedSubRole) => {
    if (selectedSubRole) {
      const data = {
        url:
          BASE_URL +
          `dashboard/ChecklistReport?company_id=${id}&Role=["${selectedSubRole}"]&frequency=${
            frequency ? frequency : ""
          }`,
      };
      dispatch(checklistReportApi(data));
    } else {
      const data = {
        url:
          BASE_URL +
          `dashboard/ChecklistReport?company_id=${id}&Role=&frequency=${
            frequency ? frequency : ""
          }`,
      };
      dispatch(checklistReportApi(data));
    }
  };

  useEffect(() => {
    if (selectedSubRole) {
      const data = {
        url:
          BASE_URL +
          `dashboard/ChecklistReport?company_id=${companyId}&Role=["${selectedSubRole}"]&frequency=${
            frequency ? frequency : ""
          }`,
      };
      dispatch(checklistReportApi(data));
    } else {
      const data = {
        url:
          BASE_URL +
          `dashboard/ChecklistReport?company_id=${companyId}&Role=&frequency=${
            frequency ? frequency : ""
          }`,
      };
      dispatch(checklistReportApi(data));
    }
  }, [selectedSubRole, frequency]);

  const MissedCheckListData = (id) => {
    const data = {
      url:
        BASE_URL +
        `dashboard/MissedChecklist?company_id=${id}&frequency=${
          frequency ? frequency : ""
        }`,
    };
    dispatch(MissedCheckLIstApi(data));
  };

  useEffect(() => {
    MissedCheckListData(companyId);
  }, [frequency]);

  const handleFreqChange = (e) => {
    setFrequency(e.target.value);
  };

  return (
    <>
      <Box>
        {/* Loading will be start here */}
        {/* {Loader ? (
          <Progressbar /> */}

        <Box
          sx={{
            bgcolor: "#F6F8FC",
            borderRadius: "4px",
            mb: 1,
            display: "flex",
            justifyContent: "end",
          }}
          className="header-card"
        >
          <Grid
            sx={{
              width: "auto",
            }}
          >
            <FormControl fullWidth sx={{ pr: 1 }}>
              <Select
                name="frequency"
                sx={{ borderRadius: "8px", textAlign: "left" }}
                value={frequency || 0}
                onChange={handleFreqChange}
                helperText="select frequency"
                size="small"
                MenuProps={MenuProps}
              >
                <MenuItem value={0}>
                  <em>All Frequency</em>
                </MenuItem>
                <MenuItem value={1}>No Frequency</MenuItem>
                <MenuItem value={2}>Hourly</MenuItem>
                <MenuItem value={3}>Daily</MenuItem>
                <MenuItem value={4}>Weekly</MenuItem>
                <MenuItem value={5}>Monthly </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <div style={{ marginTop: "7px", marginRight: "10px" }}>
            <Tooltip
              title={
                <div style={{ maxWidth: "600px" }}>
                  <strong>Dashboard:</strong>
                  <p>
                    View the details of the checklist, Issue log and broadcast
                    messages for the selected company.
                  </p>

                  <strong>Issue Raised:</strong>
                  <p>
                    To get the graphs of issues raised, from the drop down from
                    Checklist Completion as “issues raised” to view the status
                    of Issue logs for last five days.
                  </p>

                  <strong> note:</strong>
                  <p>
                    This section does not change and shows the data for all the
                    roles irrespective of the roles select in the “Select sub
                    role” drop down.
                  </p>
                </div>
              }
              arrow
              interactive
            >
              <InfoIcon
                style={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: "#D76C00",
                }}
              />
            </Tooltip>
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {loader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
                width: "100%",
              }}
            >
              <ProgressbarTransparent play />
            </div>
          ) : (
            <Box
              maxWidth="xl"
              sx={{
                overflow: "hidden",
                bgcolor: "#F6F8FC",
                width: "100%",
                p: 1,
                pb: 10,
                borderRadius: 2,
              }}
              className="header-card"
            >
              {/* {(getDataProfileMe?.isDirector === true ||
                getDataProfileMe?.isSuperAdmin === true ||
                getDataProfileMe?.role?.key === "directors" ||
                getDataProfileMe?.role?.key === "superAdmins") && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{ pt: 2, height: "100%", borderRadius: "8px" }}
                      className="card"
                    >
                      <div align="right" style={{ marginRight: "15px" }}>
                        <Tooltip
                          title={
                            <div style={{ maxWidth: "300px" }}>
                              <strong>Total PDFs & Remaining PDFs:</strong>
                              <p>
                                This section displays the count of all the
                                generated PDFs & Remainings.
                              </p>
                            </div>
                          }
                          arrow
                          interactive
                        >
                          <InfoIcon
                            style={{
                              marginLeft: "8px",
                              verticalAlign: "middle",
                              cursor: "pointer",
                              color: "#D76C00",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          variant="p"
                          component="div"
                          style={{ marginBottom: "16px" }}
                          className="heading"
                        >
                          Total PDFs:&nbsp;{companyMetrics.pdfs}
                        </Typography>
                      </CardContent>
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          variant="p"
                          component="div"
                          style={{ marginBottom: "16px" }}
                          className="heading"
                        >
                          Remaining PDFs:&nbsp;{planMetrics.pdfs}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{ pt: 2, height: "100%", borderRadius: "8px" }}
                      className="card"
                    >
                      <div align="right" style={{ marginRight: "15px" }}>
                        <Tooltip
                          title={
                            <div style={{ maxWidth: "300px" }}>
                              <strong>Total Excel & Remaining Excel:</strong>
                              <p>
                                This section displays the count of all the
                                generated Excel & Remainings.
                              </p>
                            </div>
                          }
                          arrow
                          interactive
                        >
                          <InfoIcon
                            style={{
                              marginLeft: "8px",
                              verticalAlign: "middle",
                              cursor: "pointer",
                              color: "#D76C00",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          variant="p"
                          component="div"
                          style={{ marginBottom: "16px" }}
                          className="heading"
                        >
                          Total Excel:&nbsp;{companyMetrics.excel}
                        </Typography>
                      </CardContent>
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          variant="p"
                          component="div"
                          style={{ marginBottom: "16px" }}
                          className="heading"
                        >
                          Remaining Excel :&nbsp;{planMetrics.excel}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{ pt: 2, height: "100%", borderRadius: "8px" }}
                      className="card"
                    >
                      <div align="right" style={{ marginRight: "15px" }}>
                        <Tooltip
                          title={
                            <div style={{ maxWidth: "300px" }}>
                              <strong>Total Emails & Remaining Emails:</strong>
                              <p>
                                This section displays the count of all the sent
                                & Remainings Emails.
                              </p>
                            </div>
                          }
                          arrow
                          interactive
                        >
                          <InfoIcon
                            style={{
                              marginLeft: "8px",
                              verticalAlign: "middle",
                              cursor: "pointer",
                              color: "#D76C00",
                            }}
                          />
                        </Tooltip>
                      </div>
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          variant="p"
                          component="div"
                          style={{ marginBottom: "16px" }}
                          className="heading"
                        >
                          Total Emails:&nbsp;{companyMetrics.mail}
                        </Typography>
                      </CardContent>
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography
                          variant="p"
                          component="div"
                          style={{ marginBottom: "16px" }}
                          className="heading"
                        >
                          Remaining Emails :&nbsp;{planMetrics.mail}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )} */}

              {/* <ChecklistSubmit /> */}
              <GraphCards companyId={companyId} frequency={frequency} />
              <MessageScheduleChart
                companyId={companyId}
                frequency={frequency}
              />
              <ChecklistGraphCard companyId={companyId} frequency={frequency} />
            </Box>
          )}
        </div>
      </Box>
      <p style={{ fontSize: "1rem" }}>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default Dashboard;
