/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import {
  LIST_DEPARTMENT_REQUEST,
  LIST_DEPARTMENT_SUCCESS,
  LIST_DEPARTMENT_ERROR,
  DEPARTMENT_DETAILS_REQUEST,
  DEPARTMENT_DETAILS_SUCCESS,
  DEPARTMENT_DETAILS_ERROR,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_ERROR,
  EDIT_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_ERROR,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  DEPARTMENT_ARCHIVED_REQUEST,
  DEPARTMENT_ARCHIVED_SUCCESS,
  DEPARTMENT_ARCHIVED_ERROR,
} from "../types/Types";
import { toast } from "react-toastify";

export const ListDepartment = () => ({
  type: LIST_DEPARTMENT_REQUEST,
});

export const ListDepartmentSuccess = (companyid) => ({
  type: LIST_DEPARTMENT_SUCCESS,
  payload: companyid,
});

export const ListDepartmentFailed = (error) => ({
  type: LIST_DEPARTMENT_ERROR,
  payload: error,
});

export const ListDepartmentApi = (data) => (dispatch) => {
  try {
    dispatch(ListDepartment(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListDepartmentSuccess(data));
      } else {
        dispatch(ListDepartmentFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListDepartmentFailed(error));
  }
};

export const ArchivedListDepartmentRequest = () => ({
  type: DEPARTMENT_ARCHIVED_REQUEST,
});

export const ArchivedListDepartmentSuccess = (companyid) => ({
  type: DEPARTMENT_ARCHIVED_SUCCESS,
  payload: companyid,
});

export const ArchivedListDepartmentFailed = (error) => ({
  type: DEPARTMENT_ARCHIVED_ERROR,
  payload: error,
});

export const ArchivedListDepartmentApi = (data) => (dispatch) => {
  try {
    dispatch(ArchivedListDepartmentRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ArchivedListDepartmentSuccess(data));
      } else {
        dispatch(ListDepartmentFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ArchivedListDepartmentFailed(error));
  }
};

export const DepartmentDetails = (data) => ({
  type: DEPARTMENT_DETAILS_REQUEST,
  payload: data,
});

export const DepartmentDetailsSuccess = (data) => ({
  type: DEPARTMENT_DETAILS_SUCCESS,
  payload: data,
});

export const DepartmentDetailsFailed = (error) => ({
  type: DEPARTMENT_DETAILS_ERROR,
  payload: error,
});

export const DepartmentDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(DepartmentDetails(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DepartmentDetailsSuccess(response));
      } else {
        dispatch(DepartmentDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DepartmentDetailsFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const CreateDepartment = (companyid) => ({
  type: CREATE_DEPARTMENT_REQUEST,
  payload: companyid,
});

export const CreateDepartmentSuccess = (companyid) => ({
  type: CREATE_DEPARTMENT_SUCCESS,
  payload: companyid,
});

export const CreateDepartmentFailed = (error) => ({
  type: CREATE_DEPARTMENT_ERROR,
  payload: error,
});

export const CreateDepartmentApi = (data) => (dispatch) => {
  try {
    dispatch(CreateDepartment());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CreateDepartmentSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        // dispatch(CreateDepartmentFailed(response.statusText));
        dispatch(CreateDepartmentFailed(`${response.response?.data?.message}`));
        toast.error(`${response.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CreateDepartmentFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const EditDepartment = (companyid) => ({
  type: EDIT_DEPARTMENT_REQUEST,
  payload: companyid,
});

export const EditDepartmentSuccess = (companyid) => ({
  type: EDIT_DEPARTMENT_SUCCESS,
  payload: companyid,
});

export const EditDepartmentFailed = (error) => ({
  type: EDIT_DEPARTMENT_ERROR,
  payload: error,
});

export const EditDepartmentApi = (data) => (dispatch) => {
  try {
    dispatch(EditDepartment());
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(EditDepartmentSuccess(response));
        toast.success(response.data.message);
      } else {
        dispatch(EditDepartmentFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditDepartmentFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const GetDepartment = (data) => ({
  type: GET_DEPARTMENT_REQUEST,
  payload: data,
});

export const GetDepartmentSuccess = (data) => ({
  type: GET_DEPARTMENT_SUCCESS,
  payload: data,
});

export const GetDepartmentFailed = (error) => ({
  type: GET_DEPARTMENT_ERROR,
  payload: error,
});

export const GetDepartmentApi = (data) => (dispatch) => {
  try {
    dispatch(GetDepartment());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(GetDepartmentSuccess(response));
      } else {
        dispatch(GetDepartmentFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(GetDepartmentFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const DeleteDepartment = () => ({
  type: DELETE_DEPARTMENT_REQUEST,
});

export const DeleteDepartmentSuccess = (data) => ({
  type: DELETE_DEPARTMENT_SUCCESS,
  payload: data,
});

export const DeleteDepartmentFailed = (error) => ({
  type: DELETE_DEPARTMENT_ERROR,
  payload: error,
});

export const DeleteDepartmentApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteDepartment());
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteDepartmentSuccess(response));
      } else {
        dispatch(DeleteDepartmentFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteDepartmentFailed(error));
    console.error("Something went Wrong", error);
  }
};
