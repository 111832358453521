/** @format */

import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ProductHeader } from "../../pages/Header";
import { DataGrid } from "@mui/x-data-grid";
import TablePagination from "@mui/material/TablePagination";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { WasteUomListApi } from "../../../redux/actions/WasteManagement";
import { useSelector } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Edit from "../../../images/Edit.svg";
import Delete from "../../../images/Delete.svg";
import DeleteHover from "../../../images/DeleteHover.svg";
import EditHover from "../../../images/EditHover.svg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";
import { UserDeleteApi } from "../../../redux/actions/User";
import AddCategoryModal from "../categoryTab/pages/AddCategory";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../../../GlobalComponent/SingleGlobalTable";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const UomDetail = ({ title }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [uomList, setUomList] = useState([]);
  const [permission, setPermission] = useState({});
  const [openCat, setOpenCat] = useState(false);
  const [editvalue, setEditvalue] = useState({});
  const [indexHover, setIndexHover] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [localPageNo, setLocalPageNo] = useState(1);
  const dispatch = useDispatch();
  const [editStr, setEditStr] = useState("");
  const [loader, setLoader] = useState(true);

  const Loader = useSelector(
    (state) => state.wastemng && state.wastemng.loading
  );
  const uomListDataList = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.uomList &&
      state?.wastemng?.uomList?.data &&
      state?.wastemng?.uomList?.data?.data &&
      state?.wastemng?.uomList?.data?.data?.uoms
  );
  const totalitems = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.uomList &&
      state?.wastemng?.uomList?.data &&
      state?.wastemng?.uomList?.data?.data &&
      state?.wastemng?.uomList?.data?.data?.paginate
  );

  const deleteResponse = useSelector(
    (state) =>
      state.user &&
      state.user.userDelete &&
      state.user.userDelete.data &&
      state.user.userDelete.data.data
  );

  const data = {
    page: page,
    per_page: rowsPerPage,
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    UomListApi(data);
  }, [deleteResponse]);

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    setTotalCount(totalitems?.total_item);
  }, [totalitems]);

  useEffect(() => {
    // setLoader(true);
    setUomList(uomListDataList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [uomListDataList]);

  useEffect(() => {
    UomListApi(data);
  }, []);

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const addResData = (uomres) => {
    if (uomres) {
      setTimeout(() => {
        UomListApi(data);
      }, 500);
    }
  };

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    data.page = newPage + 1;
    UomListApi(data);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    data.per_page = parseInt(event.target.value, 10);
    data.page = 1;
    UomListApi(data);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const UomListApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url =
      BASE_URL +
      `inventory/uoms?pagination={"page":${data?.page},"per_page":${data?.per_page}}&company_id=${id}`;
    dispatch(WasteUomListApi(data));
  };

  const handleDelete = (userid) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this role.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = userid;
            const data = {
              url: BASE_URL + `inventory/uom/${userRoleId}?company_id=${id}`,
            };
            setLoader(true);
            dispatch(UserDeleteApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleEdit = (row) => {
    setEditStr("uomEdit");
    setEditvalue(row);
    setOpenCat(true);
  };

  const handleClose = () => {
    setOpenCat(false);
  };

  function getIndex(params) {
    return Number(params?.row?.index) + 1;
  }

  const columns = [
    // {
    //   field: "S. No.",
    //   headerName: "S. No.",
    //   renderHeader: () => <strong>{"S. No."}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let count = 1;
    //     let result = [];
    //     if (params?.row) {
    //       result.push(params?.row);
    //     } else {
    //       result = ["N/A"];
    //     }
    //     console.log("result", result);
    //     return (
    //       <>
    //         {result?.map((item, index) => (
    //           <p key={index}>{index + 1}</p>
    //         ))}
    //       </>
    //     );
    //   },
    // },
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          uomList.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Unit Name",
      headerName: "Unit Name",
      renderHeader: () => <strong>{"Unit Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => <strong>{"Description"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.description) {
          result.push(params?.row?.description);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      renderHeader: () => <strong>{"Created At"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let timeFormate = moment(params?.row?.createdAt).format("LTS");
        let dateFormate = moment(params?.row?.createdAt).format("MMMM D, YYYY");
        let result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {permission?.wastageLog?.manageWastageLog === true ? (
              <>
                <img
                  onClick={() => handleEdit(params?.row)}
                  src={
                    hover.Edit && indexHover === params?.row?._id
                      ? EditHover
                      : Edit
                  }
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Edit: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Edit: false });
                  }}
                />
                <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </>
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      <Box>
        <ToastContainer autoClose={3000} />
        <ProductHeader title={title} setOpenCat={setOpenCat} />
        {/* <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            overflowX: "auto",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              margin: "5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          {loader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <ProgressbarTransparent play />
            </div>
          ) : uomList && uomList?.length > 0 ? (
            <>
              <DataGrid
                style={{ height: "675px" }}
                disableColumnMenu
                rows={uomList}
                columns={columns}
                getRowId={(uomList) => Math.random()}
                getRowHeight={() => "auto"}
                hideFooter={true}
                paginationMode="server"
                hideFooterPagination={true}
                className="grid-table"
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalitems?.total_item },
                ]}
                // colSpan={3}
                count={totalCount}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            "No Data"
          )}
        </Box> */}
        <SingleGlobalTable
          data={uomList}
          columns={columns}
          totalCount={totalCount}
          loading={loader}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <AddCategoryModal
        open={openCat}
        handleClose={handleClose}
        addResData={addResData}
        editvalue={editvalue}
        editStr={editStr}
        setEditStr={setEditStr}
        title={title}
      />
    </Box>
  );
};

export default UomDetail;
