/** @format */

import {
  Box,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
  Container,
  TextField,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEffect } from "react";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import ArticleIcon from "@mui/icons-material/Article";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import ReactImageZoom from "react-image-zoom";
import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import {
  ResetListApi,
  TrainingDetailsApi,
  TrainingDetailsApi1,
} from "../../../../redux/actions/LmsAction";
import { BASE_URL } from "../../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { AuditorContext } from "../../../../../context/auditorContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import SendIcon from "@mui/icons-material/Send";

const TextStepComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const allTrainingData = location?.state;
  const navigate = useNavigate();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredSteps, setFilteredSteps] = useState([]);
  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [ProgressData, setProgressData] = useState([]);
  let { storeId, storeTitle, companyId } = useContext(AuditorContext);
  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  const allTrainingProgress = useSelector(
    (state) =>
      state.lms &&
      state.lms.detailsTrainee1 &&
      state.lms.detailsTrainee1.data &&
      state.lms.detailsTrainee1.data.data
  );
  console.log("ProgressData", ProgressData);

  useEffect(() => {
    setProgressData(allTrainingProgress);
  }, [allTrainingProgress]);

  console.log("allTrainingData:", allTrainingData);

  useEffect(() => {
    const storedSelectedStep = localStorage.getItem("selectedStep");
    if (storedSelectedStep) {
      setSelectedStep(storedSelectedStep);
    }
  }, []);

  useEffect(() => {
    if (filteredSteps?.length > 0 && selectedStep === "") {
      setSelectedStep(filteredSteps[0]?.content?.url);
    } else if (filteredSteps?.length === 0) {
      setSelectedStep("");
    }
  }, [filteredSteps, selectedStep]);

  useEffect(() => {
    localStorage.setItem("selectedStep", selectedStep);
  }, [selectedStep]);

  useEffect(() => {
    const filtered = allTrainingData?.steps?.filter((step) =>
      step?.name?.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredSteps(filtered);
  }, [searchText, allTrainingData?.steps]);
  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevIsAccordionOpen) => !prevIsAccordionOpen);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleButtonClick = (id) => {
    const selectedStep = allTrainingData.steps.find((step) => step.id === id);
    setSelectedStep(selectedStep?.content?.url);
    if (companyId && storeId && storeTitle) {
      const stepIds = localStorage.getItem("totalSteps");
      const totalSteps = stepIds
        .split(",")
        .map((id) => parseInt(id.trim(), 10));
      const trainingId = localStorage.getItem("trainingId");

      const payload = {
        body: {
          trainingId: trainingId,
          store: {
            _id: storeId,
            title: storeTitle,
          },
          stepIds: [id],
        },
      };
      payload.url = BASE_URL + `trainingAssignment?company_id=${companyId}`;
      dispatch(TrainingDetailsApi1(payload));
    }
  };

  const handleSubmitAll = () => {
    if (companyId && storeId && storeTitle) {
      const stepIds = localStorage.getItem("totalSteps");
      const totalSteps = stepIds
        .split(",")
        .map((id) => parseInt(id.trim(), 10));
      const trainingId = localStorage.getItem("trainingId");

      const payload = {
        body: {
          trainingId: trainingId,
          store: {
            _id: storeId,
            title: storeTitle,
          },
          stepIds: totalSteps,
        },
      };
      payload.url = BASE_URL + `trainingAssignment?company_id=${companyId}`;

      dispatch(TrainingDetailsApi1(payload)).then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/auditor-home/my-trainings");
          }, 6000);
        }
      });
    }
  };

  const handleButtonAssesmentClick = (id) => {
    const selectedStep = allTrainingData.steps.find((step) => step.id === id);
    setSelectedStep(selectedStep?.content?.url);

    // Call the TrainingAssignment API here
    if (companyId && storeId && storeTitle) {
      const totalSteps = localStorage.getItem("totalSteps");
      const trainingId = localStorage.getItem("trainingId");

      const payload = {
        body: {
          trainingId: trainingId,
          store: {
            _id: storeId,
            title: storeTitle,
          },
          stepIds: [totalSteps],
        },
      };
      payload.url = BASE_URL + `trainingAssignment?company_id=${companyId}`;
      dispatch(TrainingDetailsApi1(payload));
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleToggleLeftSide = () => {
    setIsLeftSideOpen(!isLeftSideOpen);
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <Box sx={{ marginTop: 2 }} className="header-card">
        <Accordion
          expanded={isAccordionOpen}
          sx={{
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            "& .MuiAccordionSummary-content": {
              margin: 0,
              alignItems: "center",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={handleAccordionToggle}
            sx={{ justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography align="center">
                {isAccordionOpen ? "Hide" : allTrainingData?.name}
              </Typography>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <Typography align="center">
                <strong>{storeTitle}</strong>
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            {/* Accordion content */}
            <div
              style={{
                border: "1px solid",
                borderRadius: "4px",
                padding: "10px",
                background: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Name:</strong> {allTrainingData?.name}
                  </p>
                </Typography>

                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Summary:</strong> {allTrainingData?.summary}
                  </p>
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  {allTrainingData?.duration ? (
                    <div style={{ marginLeft: "1px", textAlign: "center" }}>
                      <AccessTimeIcon />
                      <span>
                        <strong>Duration:</strong>
                      </span>
                      {allTrainingData?.duration}
                    </div>
                  ) : (
                    <div>
                      {allTrainingData?.duration ? (
                        <span>
                          <strong>Duration:</strong> {allTrainingData?.duration}
                        </span>
                      ) : (
                        <span>
                          <strong>Duration:</strong> N/A
                        </span>
                      )}
                    </div>
                  )}
                </Typography>

                <Typography sx={{ mt: 1 }}>
                  {allTrainingData?.updatedAt ? (
                    <React.Fragment>
                      <span>
                        <strong>Last updated:</strong>{" "}
                      </span>
                      {(() => {
                        const now = new Date();
                        const updatedAt = new Date(allTrainingData?.updatedAt);
                        const diffInMs = now - updatedAt;

                        const seconds = Math.floor(diffInMs / 1000);
                        const minutes = Math.floor(seconds / 60);
                        const hours = Math.floor(minutes / 60);
                        const days = Math.floor(hours / 24);
                        const months = Math.floor(days / 30);
                        const years = Math.floor(months / 12);

                        if (years > 0) {
                          return <span>{years} year(s) ago</span>;
                        } else if (months > 0) {
                          return <span>{months} month(s) ago</span>;
                        } else if (days > 0) {
                          return <span>{days} day(s) ago</span>;
                        } else {
                          return <span>{hours} hour(s) ago</span>;
                        }
                      })()}
                    </React.Fragment>
                  ) : (
                    <span>
                      <strong>Last updated:</strong> N/A
                    </span>
                  )}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Completion Progress:</strong>(
                    {allTrainingData?.completedPercentage}
                    %)
                  </p>
                </Typography>
                <div style={{ width: "250px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={allTrainingData?.completedPercentage}
                    sx={{ height: 10 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${allTrainingData?.completedPercentage}%`,
                        backgroundColor:
                          allTrainingData?.completedPercentage === 100
                            ? "green"
                            : "red",
                      }}
                    />
                  </LinearProgress>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Assessment Progress: </strong>(
                    {allTrainingData?.assessmentProgress}%)
                  </p>
                </Typography>
                <div style={{ width: "250px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={allTrainingData?.assessmentProgress}
                    sx={{ height: 10 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${allTrainingData?.assessmentProgress}%`,
                        backgroundColor:
                          allTrainingData?.assessmentProgress === 100
                            ? "green"
                            : "red",
                      }}
                    />
                  </LinearProgress>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Assessment Status:</strong>{" "}
                    {"(" + allTrainingData?.assessmentProgress + "%)"}
                  </p>
                </Typography>

                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      marginRight: "16%",
                      background:
                        allTrainingData?.assessmentProgress >= 66
                          ? "green"
                          : allTrainingData?.assessmentProgress >= 33
                          ? "gray"
                          : "red",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {allTrainingData?.assessmentProgress}%
                  </div>
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* main content  */}
      <Grid container>
        {/* Left Side */}
        <Grid item xs={12} md={3}>
          <Box height="100%" bgcolor="#f5f5f5" textAlign="center">
            {isLeftSideOpen ? (
              <div sx={{ padding: "10px", textAlign: "left", marginLeft: 0 }}>
                {/* Toggle Button */}
                <IconButton
                  onClick={handleToggleLeftSide}
                  sx={{ marginLeft: "-25px" }}
                >
                  <ChevronLeftIcon />
                  <Typography
                    variant="body2"
                    sx={{ lineHeight: "1.2", marginLeft: "4px" }}
                  >
                    Close Outline
                  </Typography>
                </IconButton>

                {/* Chapter */}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ mt: 1, display: "flex", alignItems: "center" }}
                >
                  <BookmarksIcon sx={{ marginRight: "5px" }} />
                  <Box
                    component="span"
                    sx={{ marginLeft: "2px", marginBottom: "2px" }}
                  >
                    Chapter
                  </Box>
                </Typography>

                {/* Search Bar */}
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  value={searchText}
                  onChange={handleSearchChange}
                />

                {/*  Buttons */}
                {filteredSteps &&
                  filteredSteps.map((step) => (
                    <div key={step.id} style={{ marginTop: "10px" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleButtonClick(step.id)}
                        style={{
                          backgroundColor:
                            selectedStep === step.content?.url
                              ? "#e1f5fe"
                              : "transparent",
                        }}
                      >
                        {step.name}
                        {step.finished && (
                          <CheckIcon style={{ color: "green" }} />
                        )}
                      </Button>
                    </div>
                  ))}

                <Link to="/auditor-home/my-trainings">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmitAll}
                    style={{
                      marginTop: "10px",
                      backgroundColor: "green",
                      color: "white",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                  >
                    FINISH TRAINING
                    <SendIcon />
                  </Button>
                </Link>

                {/* Assessments */}
                {/* <Typography
                  variant="h6"
                  component="div"
                  sx={{ mt: 1, display: "flex", alignItems: "center" }}
                >
                  <ArticleIcon sx={{ marginRight: "5px" }} />
                  <Box component="span" sx={{ marginLeft: "2px" }}>
                    Assessments
                  </Box>
                </Typography> */}
                {/* {allTrainingData &&
                  allTrainingData?.assessments?.map((step) => (
                    <div key={step.id} style={{ marginTop: "10px" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleButtonAssesmentClick(step.id)}
                        style={{
                          backgroundColor:
                            selectedStep === step.content?.url
                              ? "#e1f5fe"
                              : "transparent",
                        }}
                        disabled={!step.finished}
                      >
                        {step?.title}{" "}
                        {step.finished ? (
                          <CheckIcon style={{ color: "green" }} />
                        ) : (
                          <LockIcon style={{ color: "gray" }} />
                        )}
                      </Button>
                    </div>
                  ))} */}
              </div>
            ) : (
              <IconButton
                onClick={handleToggleLeftSide}
                sx={{ marginLeft: "-25px" }}
              >
                <ChevronRightIcon />
                <Typography
                  variant="body2"
                  sx={{ lineHeight: "1.2", marginLeft: "4px" }}
                >
                  Open Outline
                </Typography>
              </IconButton>
            )}
          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={isLeftSideOpen ? 9 : 12}>
          <Box
            height="100%"
            bgcolor="white"
            padding="3px"
            sx={{ marginLeft: "10px" }}
          >
            <Box>
              {allTrainingData?.steps &&
                allTrainingData.steps.map((step, index) => {
                  if (selectedStep === "" && index === 0) {
                    // Set the default selection to the first step if no step is selected
                    setSelectedStep(step?.content?.url);
                  }
                  if (step?.content?.url === selectedStep) {
                    return (
                      <div key={index}>
                        <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                          <p>
                            <strong>{step?.name}</strong>
                          </p>
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ mt: 1 }}
                        >
                          <p>{step?.summary}</p>
                        </Typography>

                        <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                          {step?.type === 1 && (
                            <div
                              style={{ marginTop: "10px", marginLeft: "10px" }}
                            >
                              {step?.content && (
                                <Typography
                                  variant="body1"
                                  component="div"
                                  sx={{ mt: 1 }}
                                >
                                  {step?.content
                                    .replace(/<\/?[^>]+(>|$)/g, "")
                                    .replace(/"/g, "")}
                                </Typography>
                              )}
                            </div>
                          )}
                          {step?.type === 2 && (
                            <div
                              style={{ marginTop: "10px", marginLeft: "10px" }}
                            >
                              <video
                                controls
                                style={{
                                  maxWidth: "500px",
                                  height: "auto",
                                  border: "1px solid #ccc",
                                  maxHeight: "500px",
                                }}
                              >
                                <source
                                  src={step?.content?.url}
                                  type="video/mp4"
                                />
                                Your browser does not support the video.
                              </video>
                            </div>
                          )}
                          {step?.type === 3 && (
                            <audio
                              controls
                              style={{ maxWidth: "100%", height: "auto" }}
                            >
                              <source
                                src={step?.content?.url}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio.
                            </audio>
                          )}
                          {step?.type === 4 && (
                            <div
                              style={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ReactImageZoom
                                width={600}
                                height={600}
                                zoomWidth={600}
                                img={step?.content?.url}
                                zoomPosition="original"
                                zoomStyle={{
                                  maxHeight: "600px",
                                }}
                              />
                            </div>
                          )}

                          {step?.type === 5 && (
                            <div>
                              {step?.content?.url
                                .toLowerCase()
                                .endsWith(".pdf") && (
                                <embed
                                  src={step?.content?.url}
                                  width="100%"
                                  height="500px"
                                  type="application/pdf"
                                />
                              )}

                              {step?.content?.url
                                .toLowerCase()
                                .endsWith(".ppt") && (
                                <iframe
                                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                    step?.content?.url
                                  )}&embedded=true`}
                                  width="100%"
                                  height="500px"
                                  title="Embedded Document"
                                ></iframe>
                              )}

                              {step?.content?.url
                                .toLowerCase()
                                .endsWith(".doc") && (
                                <iframe
                                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                    step?.content?.url
                                  )}&embedded=true`}
                                  width="100%"
                                  height="500px"
                                  title="Embedded Document"
                                ></iframe>
                              )}

                              {!(
                                step?.content?.url
                                  .toLowerCase()
                                  .endsWith(".pdf") ||
                                step?.content?.url
                                  .toLowerCase()
                                  .endsWith(".ppt") ||
                                step?.content?.url
                                  .toLowerCase()
                                  .endsWith(".doc")
                              ) &&
                                !step?.content?.url && (
                                  <p>Unsupported document format.</p>
                                )}

                              <Button
                                variant="contained"
                                startIcon={<DescriptionIcon />}
                                onClick={() =>
                                  window.open(step?.content?.url, "_blank")
                                }
                                disabled={!step?.content?.url}
                              >
                                Download
                              </Button>
                            </div>
                          )}

                          {step?.type === 6 && (
                            <div>
                              <p>URL Details:</p>
                              <p>URL: {step?.content?.url}</p>
                              <iframe
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  step?.content?.url
                                )}&embedded=true`}
                                width="100%"
                                height="500px"
                                title="Embedded Content"
                              ></iframe>
                              <Button
                                variant="contained"
                                startIcon={<GetAppIcon />}
                                href={step?.content?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                Download
                              </Button>
                            </div>
                          )}
                        </div>

                        {step?.content ? (
                          <Typography
                            variant="body1"
                            component="div"
                            sx={{ mt: 1 }}
                          ></Typography>
                        ) : (
                          <p>No data available.</p>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TextStepComponent;
