/** @format */

import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  Modal,
  Button,
  Paper,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import ChatSideBar from "./pages/ChatSideBar";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import { useSocket } from "./pages/SocketProvider";
import {
  ChatHistoryApi,
  ChatUserListApi,
  GroupChatUserEditApi,
} from "../redux/actions/ChatAction";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { ProfileMeGetApiAction } from "../redux/actions/MyProfileDetailsAction";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import SocketStatusModal from "./pages/SocketStatusModal";

const Chat = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [currentChat, setCurrentChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const chatBoxRef = useRef(null);
  const socket = useSocket();
  const [loggedInUser, setloggedInUser] = useState([]);
  const [messages, setMessages] = useState([]);
  const [roomId, setRoomId] = useState();
  const [companyId, setCompanyId] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(300);
  const [ChatSenderId, setChatSenderId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [attachments, setAttachments] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [chatUsersFromSidebar, setChatUsersFromSidebar] = useState([]);
  const [socketError, setSocketError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(() => {
    return sessionStorage.getItem("isModalOpen") !== "false";
  });
  const handleUserUpdate = async (updateData) => {
    if (!BASE_URL || !roomId || !companyId) {
      console.error("BASE_URL, roomId, or companyId is undefined");
      return;
    }
    const payload = {
      name: updateData.name,
      participants: updateData.participants,
      removedUser: updateData.removedUser,
      addedUser: updateData.addedUser,
    };
    const apiData = {
      url: `${BASE_URL}update-group?roomId=${roomId}&company_id=${companyId}`,
      body: payload,
    };
    // console.log("Update Group Payload", payload);
    try {
      await dispatch(GroupChatUserEditApi(apiData));
      userListAPI();
      ChatHistoryAPICall();
    } catch (error) {
      console.error("Error updating group:", error);
    }
  };

  const handleProfileUpdate = (updatedData) => {
    handleUserUpdate(updatedData);
  };

  const userListAPI = useCallback(() => {
    const companyId = localStorage.getItem("companyId");
    const data = {
      url: `${BASE_URL}chat-user?company_id=${companyId}&per_page=${perPage}&page=${page}&search=${searchQuery}`,
    };
    dispatch(ChatUserListApi(data));
  }, [dispatch, page, perPage, searchQuery]);

  const handleChatUserFromSidebar = (users) => {
    setChatUsersFromSidebar(users);
  };

  const createGroupCallback = (groupName, selectedUsers) => {
    setGroupName(groupName);
    setSelectedUsers(selectedUsers);

    const usersWithoutSubRole = selectedUsers.filter(
      (participant) => !participant.subRole
    );

    if (usersWithoutSubRole.length > 0) {
      usersWithoutSubRole.forEach((user) => {
        toast.error(
          `User "${user.name}" does not have a subRole. Please add a subRole first or do not select this user to create group.`
        );
      });
      return;
    }

    const data = {
      roomData: {
        participants: selectedUsers.map((participant) => ({
          userId: participant._id,
          name: participant.name,
          subRole: participant.subRole,
        })),
        type: "group",
        name: groupName,
      },
      company_id: companyId,
    };

    socket.emit("create-room", data, (response) => {
      if (response && response.success) {
        // console.log("create-room done:", response.data);
      } else {
        toast.error("Please first click on any user or group then try again!");
        console.error("create-room failed:", response.error);
      }
    });
  };

  const getDataProfileMe = useSelector(
    (state) =>
      state &&
      state.MyProfileDetails &&
      state.MyProfileDetails.progileMeGetApiRes &&
      state.MyProfileDetails.progileMeGetApiRes.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data
  );

  const chatHistorySelector = useSelector(
    (state) =>
      state &&
      state.chat &&
      state.chat.chatHistory &&
      state.chat.chatHistory?.data &&
      state.chat.chatHistory?.data?.data
  );

  useEffect(() => {
    if (chatHistorySelector) {
      setChatHistory(chatHistorySelector);
    }
  }, [chatHistorySelector]);

  useEffect(() => {
    if (getDataProfileMe) {
      setLoader(true);
      setloggedInUser(getDataProfileMe);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [getDataProfileMe]);

  useEffect(() => {
    const ProfileDetail = async function () {
      const data = { url: BASE_URL + `profile/me` };
      dispatch(ProfileMeGetApiAction(data));
    };
    ProfileDetail();
  }, []);

  const ChatHistoryAPICall = () => {
    const data = {
      url: BASE_URL + `chat-history?company_id=${companyId}&roomId=${roomId}`,
    };
    dispatch(ChatHistoryApi(data));
  };
  useEffect(() => {
    if (companyId && roomId) {
      // setLoader(true);
      ChatHistoryAPICall();
      // setTimeout(() => {
      //   setLoader(false);
      // }, 2000);
    }
  }, [companyId, roomId]);
  console.log("Compamy Id", companyId, "Room Id", roomId);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    if (id) {
      setCompanyId(id);
    }
  }, []);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  // const handleSendMessage = (message, attachments) => {
  //   console.log("attachments", attachments);
  //   const hasAttachments = attachments && attachments.length > 0;
  //   const attachment = hasAttachments ? attachments[0] : null;
  //   let messageType = hasAttachments ? getAttachmentType(attachment) : 0;
  //   const content = hasAttachments && !message ? attachment.location : message;
  //   if (!hasAttachments && isUrl(content)) {
  //     messageType = 6;
  //   }
  //   console.log("messageType", messageType);
  //   const data = {
  //     roomId,
  //     messageDetail: {
  //       content: content,
  //       messageType: messageType,
  //     },
  //     company_id: companyId,
  //     textMessage: "Attachment  and text",
  //   };
  //   socket.emit("create-message", { ...data });
  //   // ChatHistoryAPICall();
  // };
  const handleSendMessage = (message, attachments) => {
    const hasAttachments = attachments && attachments.length > 0;
    const attachment = hasAttachments ? attachments[0] : null;
    let messageType = hasAttachments ? getAttachmentType(attachment) : 0;

    let content = hasAttachments ? attachment.location : null;
    let textMessage =
      message && typeof message === "string" && message.trim().length > 0
        ? message
        : null;
    if (!hasAttachments && textMessage) {
      content = textMessage;
      messageType = 0;
    }
    if (!hasAttachments && isUrl(message)) {
      messageType = 6;
    }
    const data = {
      roomId,
      messageDetail: {
        content: content,
        messageType: messageType,
        textMessage: textMessage,
      },
      company_id: companyId,
    };
    socket.emit("create-message", { ...data });
    setAttachments([]);
    // ChatHistoryAPICall();
  };

  const handleRefresh = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("isModalOpen", "false");
    window.location.reload();
  };

  const handleRefreshClose = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("isModalOpen", "false");
  };

  const isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getAttachmentType = (attachment) => {
    if (!attachment) {
      return 0;
    }

    const { fileType, name, mimetype } = attachment;
    const types = {
      image: 1,
      pdf: 2,
      doc: 3,
      audio: 4,
      video: 5,
      link: 6,
      broadcast: 7,
    };

    if (mimetype) {
      const mimeTypeMap = {
        "image/jpeg": "image",
        "image/png": "image",
        "image/gif": "image",
        "image/bmp": "image",
        "application/pdf": "pdf",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "doc",
        "application/vnd.ms-excel": "doc",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "doc",
        "audio/mpeg": "audio",
        "audio/wav": "audio",
        "video/mp4": "video",
        "video/x-matroska": "video",
        "video/webm": "video",
      };

      const mappedType = mimeTypeMap[mimetype.toLowerCase()];

      if (mappedType) {
        return types[mappedType];
      }
    }

    if (fileType) {
      const extension = fileType.split(".").pop().toLowerCase();
      const extensionMap = {
        jpg: "image",
        jpeg: "image",
        png: "image",
        gif: "image",
        bmp: "image",
        pdf: "pdf",
        doc: "doc",
        docx: "doc",
        xls: "doc",
        xlsx: "doc",
        excel: "doc",
        mp3: "audio",
        wav: "audio",
        mp4: "video",
        mkv: "video",
        webm: "video",
      };

      const mappedType = extensionMap[extension];

      if (mappedType) {
        return types[mappedType];
      }
    }

    if (name) {
      if (/^(http|https):\/\/[^ "]+$/.test(name)) {
        return types.link;
      }
    }

    return 0;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage(input);
      setInput("");
    }
  };
  const handleSelectUser = (user, users) => {
    setAttachments([]);
    setMessages([]);
    if (!socket) {
      toast.error("Socket is not connected, Refresh and try again.");
      setIsModalOpen(true);
      return;
    }
    if (user) {
      setCurrentChat(user);
      if (users?.ChatRoom === false && user?.type === "room") {
        const data = {
          // roomData: {
          //   participants: {
          //     userId: user?.participants[0]?._id,
          //     name: user?.participants[0]?.name,
          //     subRole: user?.participants[0]?.subRole,
          //   },
          //   type: "room",
          roomData: {
            participants: {
              userId: user?._id,
              // userId: user?.roomData?._id,
              name: user?.name,
              subRole: user?.subRole,
            },
            type: "room",
          },
          company_id: companyId,
        };
        socket.emit("create-room", { ...data });
      } else if (users?.ChatRoom === true) {
        const data = {
          company_id: companyId,
          roomId: user?._id,
        };
        socket.emit("join-room", data, (response) => {
          if (response && response.success) {
            console.log("join-room done:", roomId);
          } else {
            console.error("join-room failed:", response.error);
          }
        });
      } else if (user?.type === "group") {
        const data = {
          company_id: companyId,
          roomId: user?._id,
        };
        socket.emit("join-room", data, (response) => {
          if (response && response.success) {
            console.log("join-room done:", roomId);
          } else {
            console.error("join-room failed:", response.error);
          }
        });
      } else {
        const data = {
          roomData: {
            participants: {
              userId: user?._id,
              // userId: user?.roomData?._id,
              name: user?.name,
              subRole: user?.subRole,
            },
            type: "room",
          },
          company_id: companyId,
        };
        socket.emit("create-room", { ...data });
      }
    }
  };

  useEffect(() => {
    if (!socket) return;

    const handleJoinedRoom = (args) => {
      console.log("joinedRoom args", args);
      setRoomId(args?.data?._id);
    };

    socket.on("joinedRoom", handleJoinedRoom);

    return () => {
      socket.off("joinedRoom", handleJoinedRoom);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (args) => {
      // console.log("args?.data-->", args?.data);
      args?.data?.forEach((message) => {
        let chatSenderId =
          message?.chatSenderId?._id || localStorage.getItem("chatSenderId");
        // console.log("chatSenderId", chatSenderId);
        if (message?.chatSenderId) {
          localStorage.setItem("chatSenderId", message.chatSenderId?._id);
          chatSenderId = message.chatSenderId?._id;
          setChatSenderId(chatSenderId);
        } else {
          localStorage.removeItem("chatSenderId");
        }

        const newMessage = {
          _id: message?._id,
          text: message?.content,
          createdAt: message?.createdAt,
          chatSenderId: chatSenderId,
          name: message?.chatSenderId?.name,
          avatar: "https://placeimg.com/140/140/any",
          messageType: message?.messageType,
          attachmentUrl: message?.attachmentUrl || message?.text,
          textMessage: message?.textMessage,
          // textMessage: "test message text",
        };

        setMessages((oldArray) => [...oldArray, newMessage]);
      });
    };

    socket.on("new-message", handleNewMessage);

    return () => {
      socket.off("new-message", handleNewMessage);
    };
  }, [socket, currentChat, getDataProfileMe]);
  useEffect(() => {
    const storedChatSenderId = localStorage.getItem("chatSenderId");
    if (storedChatSenderId) {
      setChatSenderId(storedChatSenderId);
    }
  }, []);

  // additional
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Socket connected");
        setIsModalOpen(false);
        sessionStorage.setItem("isModalOpen", "false");
      });

      socket.on("disconnect", (reason) => {
        setIsModalOpen(true);
        sessionStorage.setItem("isModalOpen", "true");
        console.log("Socket disconnected:", reason);
      });

      socket.on("connect_error", (error) => {
        setIsModalOpen(true);
        sessionStorage.setItem("isModalOpen", "true");
        console.error("Connection error:", error.message);
      });

      socket.on("receive-message", (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });
    }

    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("connect_error");
        socket.off("receive-message");
      }
    };
  }, [socket]);

  const isNewDay = (messageDate, prevMessageDate) => {
    if (!prevMessageDate) return true;
    const currentDay = moment(messageDate).format("D");
    const prevDay = moment(prevMessageDate).format("D");
    return currentDay !== prevDay;
  };

  const renderDateSeparator = (date) => {
    const parsedDate = moment(date);
    if (!parsedDate.isValid()) {
      console.error("Invalid date:", date);
      return null;
    }
    return (
      <div
        className="date-separator"
        style={{ textAlign: "center", margin: "20px 0" }}
      >
        {parsedDate.format("dddd, MMMM D, YYYY")}
      </div>
    );
  };

  const mergedMessages = [...(chatHistory?.messages || []), ...messages];

  const sortedMessages = mergedMessages.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  useEffect(() => {
    scrollToBottom();
  }, [sortedMessages]);
  // console.log("chatHistory", chatHistory);
  const renderMessage = (message) => {
    // console.log("renderMessage message", message);
    const isSender = message.chatSenderId === loggedInUser?._id;
    const senderProfile = `${getDataProfileMe?.first_name?.[0]?.toUpperCase()}${getDataProfileMe?.last_name?.[0]?.toUpperCase()}`;
    // const receiverProfile = `${currentChat?.participants[0]?.name
    //   .split(" ")[0][0]
    //   ?.toUpperCase()}${currentChat?.participants[0]?.name
    //   .split(" ")[1][0]
    //   ?.toUpperCase()}`;
    let receiverProfileName =
      currentChat?.participants?.[0]?.name ?? currentChat?.name;
    const receiverProfile = receiverProfileName
      ? receiverProfileName
          .split(" ")
          .map((name) => name[0]?.toUpperCase())
          .join("")
      : "";

    const senderName = getDataProfileMe?.name;
    const receiverName = (() => {
      if (!currentChat) {
        return "";
      }
      // if (chatUsersFromSidebar?.ChatRoom) {
      if (currentChat.type === "room") {
        return currentChat.participants[0]?.name || "";
      } else if (currentChat.type === "group") {
        const matchingParticipant = currentChat.participants.find(
          (participant) => participant?.userId === message.chatSenderId
        );
        return matchingParticipant?.name || currentChat?.name || "";
      }
      // }
      return currentChat?.name || "";
    })();
    const userAvatar = isSender ? senderProfile : receiverProfile;
    const userName = isSender ? senderName : receiverName;

    const handleDownload = (url) => {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", true);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleImageDownload = async (url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Image download failed:", error);
      }
    };

    const isTooLargeFile = async (fileUrl) => {
      try {
        const response = await fetch(fileUrl, { method: "HEAD" });
        const contentLength = response.headers.get("content-length");
        if (contentLength && parseInt(contentLength, 10) > 5 * 1024 * 1024) {
          toast.error("Only up to 5 MB files are allowed");
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error checking file size:", error);
        return false;
      }
    };

    const handleOpen = async (url, type) => {
      const tooLarge = await isTooLargeFile(url);
      if (!tooLarge) {
        setOpen({ type, url });
      }
    };

    const handleClose = () => {
      setOpen(false);
    };

    const renderContent = () => {
      switch (message.messageType) {
        case 0: // Text
          return <Typography>{message.text || message.content}</Typography>;

        case 1: // Image
          const imageUrl =
            message.attachmentUrl || message.text || message.content;
          const textMessageImage = message.textMessage;

          return (
            <div>
              {imageUrl && (
                <div style={{ marginBottom: "10px" }}>
                  <img
                    src={imageUrl}
                    alt="Image"
                    style={{
                      maxWidth: "600px",
                      maxHeight: "300px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpen(imageUrl, "image")}
                  />
                  <Modal open={open?.type === "image"} onClose={handleClose}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        height: "80%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 2,
                        overflow: "auto",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <IconButton onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleImageDownload(open.url)}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <img
                          src={open.url}
                          alt="Image"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </Box>
                    </Box>
                  </Modal>
                </div>
              )}
              {textMessageImage && <Typography>{textMessageImage}</Typography>}
            </div>
          );
        case 2: // PDF
          const pdfUrl =
            message.attachmentUrl || message.text || message.content;
          const textMessagePdf = message.textMessage;

          return (
            <>
              {pdfUrl && (
                <div style={{ marginBottom: "10px" }}>
                  <iframe
                    src={pdfUrl}
                    width="600px"
                    height="300px"
                    frameBorder="0"
                    title="PDF Viewer"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpen(pdfUrl, "pdf")}
                  ></iframe>
                  <Modal open={open?.type === "pdf"} onClose={handleClose}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        height: "80%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 2,
                        overflow: "auto",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <IconButton onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDownload(pdfUrl)}>
                          <GetAppIcon />
                        </IconButton>
                      </Box>
                      <iframe
                        src={pdfUrl}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        title="PDF Viewer"
                      ></iframe>
                    </Box>
                  </Modal>
                  {textMessagePdf && (
                    <Typography style={{ marginTop: "10px" }}>
                      {textMessagePdf}
                    </Typography>
                  )}
                </div>
              )}
            </>
          );

        case 3: // DOC
          const docUrl =
            message.attachmentUrl || message.text || message.content;
          const textMessageDoc = message.textMessage;

          return (
            <>
              <div style={{ marginBottom: "10px" }}>
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                    docUrl
                  )}`}
                  width="600px"
                  height="300px"
                  frameBorder="0"
                  title="Document Viewer"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen(docUrl, "doc")}
                ></iframe>
                <Modal open={open?.type === "doc"} onClose={handleClose}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "80%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 2,
                      overflow: "auto",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDownload(docUrl)}>
                        <GetAppIcon />
                      </IconButton>
                    </Box>
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                        docUrl
                      )}`}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      title="Document Viewer"
                    ></iframe>
                  </Box>
                </Modal>
                {textMessageDoc && <Typography>{textMessageDoc}</Typography>}
              </div>
            </>
          );

        case 4: // Audio
          const audioUrl =
            message.attachmentUrl || message.text || message.content;
          const textMessageAudio = message.textMessage;

          return (
            <>
              <div>
                <audio controls style={{ width: "600px" }}>
                  <source src={audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                {textMessageAudio && (
                  <Typography>{textMessageAudio}</Typography>
                )}
              </div>
            </>
          );

        case 5: // Video
          const videoUrl =
            message.attachmentUrl || message.text || message.content;
          const textMessageVideo = message.textMessage;

          return (
            <>
              {videoUrl && (
                <div style={{ marginBottom: "10px" }}>
                  <video
                    controls
                    style={{
                      maxWidth: "100%",
                      cursor: "pointer",
                      height: "300px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpen(videoUrl, "video")}
                  >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video element.
                  </video>
                  <Modal open={open?.type === "video"} onClose={handleClose}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        height: "80%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 2,
                        overflow: "auto",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <IconButton onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDownload(open.url)}>
                          <GetAppIcon />
                        </IconButton>
                      </Box>
                      <video controls style={{ width: "100%", height: "auto" }}>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video element.
                      </video>
                    </Box>
                  </Modal>
                  {textMessageVideo && (
                    <Typography style={{ marginTop: "10px" }}>
                      {textMessageVideo}
                    </Typography>
                  )}
                </div>
              )}
            </>
          );

        case 6: // Link
          const linkUrl =
            message.attachmentUrl || message.text || message.content;
          return (
            <div>
              <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ wordBreak: "break-all" }}
              >
                {linkUrl}
              </a>
            </div>
          );

        case 7: // Broadcast message
          const broadcastText = message.content;
          return (
            <Typography
              variant="body1"
              style={{
                color: "#00796b",
                // fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {broadcastText}
            </Typography>
          );

        default:
          return (
            <>
              <a
                href={message.attachmentUrl || message.text || message.content}
                target="_blank"
                rel="noopener noreferrer"
              >
                {message.attachmentUrl || message.text || message.content}
              </a>
              <Typography>{message.text || message.content}</Typography>
            </>
          );
      }
    };

    return (
      <Box
        className={`message-container ${isSender ? "sent" : "received"}`}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isSender ? "flex-end" : "flex-start",
          maxWidth: "70%",
          margin: "10px 0",
        }}
      >
        <ToastContainer autoClose={3000} />
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <ListItemAvatar style={{ minWidth: 0 }}>
            <Avatar>{userAvatar}</Avatar>
          </ListItemAvatar>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", marginLeft: "8px" }}
          >
            {userName}, {moment(message.createdAt).format("h:mm A")}
          </Typography>
        </Box>
        <Box
          className={`message-text ${isSender ? "sent" : "received"}`}
          style={{
            backgroundColor: isSender ? "#DCF8C6" : "#f0f0f0",
            textAlign: "left",
            borderRadius: "10px",
            padding: "10px",
            marginTop: "5px",
            alignSelf: "stretch",
            maxWidth: "100%",
            wordBreak: "break-word",
          }}
        >
          {renderContent()}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box className="chat-container">
          <ChatSideBar
            getDataProfileMe={getDataProfileMe}
            handleSelectUser={handleSelectUser}
            currentUser={currentChat}
            onUserSelect={handleUserSelect}
            createGroupCallback={createGroupCallback}
            getUserChatFromSidebar={handleChatUserFromSidebar}
            userListAPI={userListAPI}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <Box className="main-chat">
            <Header
              currentUser={currentChat}
              setCurrentUser={setCurrentChat}
              handleSelectUser={handleSelectUser}
              chatUsersFromSidebar={chatUsersFromSidebar}
              handleUserUpdate={handleUserUpdate}
              userListAPI={userListAPI}
            />
            {/* <Typography variant="h1" className="watermark">
              Popprobe chat...
            </Typography> */}
            {currentChat ? (
              <>
                <Box className="chat-box" ref={chatBoxRef}>
                  <List>
                    {sortedMessages.map((message, index) => {
                      const isSender =
                        message.chatSenderId === loggedInUser?._id;
                      const prevMessage = sortedMessages[index - 1];
                      const showDateSeparator = isNewDay(
                        message.createdAt,
                        prevMessage?.createdAt
                      );

                      return (
                        <React.Fragment key={`${message._id}-${index}`}>
                          {showDateSeparator &&
                            renderDateSeparator(message.createdAt)}
                          <ListItem
                            alignItems="flex-start"
                            style={{
                              flexDirection: isSender ? "row-reverse" : "row",
                              textAlign: isSender ? "right" : "left",
                            }}
                          >
                            {renderMessage(message, isSender)}
                          </ListItem>
                        </React.Fragment>
                      );
                    })}
                  </List>
                </Box>
                <Footer
                  input={input}
                  setInput={setInput}
                  attachments={attachments}
                  handleKeyPress={handleKeyPress}
                  handleSendMessage={handleSendMessage}
                  currentUser={currentChat}
                />
              </>
            ) : (
              <Box className="no-chat-selected">
                <Typography variant="h6" className="no-chat-message">
                  Select a user or group to chat with them.
                </Typography>
                {/* <Typography variant="h1" className="watermark">
                  Popprobe chat module...
                </Typography> */}
              </Box>
            )}
          </Box>
          <SocketStatusModal
            open={isModalOpen}
            handleRefresh={handleRefresh}
            handleRefreshClose={handleRefreshClose}
          />
        </Box>
      )}
    </>
  );
};

export default Chat;
