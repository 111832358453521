import { CircularProgress } from "material-ui-core";
import React from "react";

function Progressbar() {
  return (
    // <div>
    //   <CircularProgress color="inherit" />
    // </div>

    <div
      class="text-center"
      style={{
        position: "relative",
        top: "250px",
        height: "100%",
      }}
    >
      <div class="spinner-border" role="status">
        <CircularProgress
          color="inherit"
          size="3rem"
          style={{ color: "#f26539" }}
        />
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Progressbar;
