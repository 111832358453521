import React from "react";
import { Stack, Box, Button, Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

const TableHeader = () => {
  return (
    <>
      <Box>
        <Card className="header-card">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={9}>
              <Stack direction="row" spacing={1} className="audit-header">
                <Typography variant="p">Sort by</Typography>
                <Stack className="header-select-stack">
                  <select className="check-select select2 bg">
                    <option>Year-Month</option>
                    <option>a</option>
                    <option>b</option>
                  </select>
                </Stack>
                <Stack className="header-select-stack">
                  <select className="check-select select2 bg">
                    <option>Countries</option>
                    <option>a</option>
                    <option>b</option>
                  </select>
                </Stack>
                <Stack className="header-select-stack">
                  <select className="check-select select2 bg">
                    <option>Cities</option>
                    <option>a</option>
                    <option>b</option>
                  </select>
                </Stack>
                <Stack className="header-select-stack">
                  <select className="check-select select2 bg">
                    <option>Channels</option>
                    <option>a</option>
                    <option>b</option>
                  </select>
                </Stack>
                <Stack className="header-select-stack">
                  <select className="check-select select2 bg">
                    <option>Sub-Channels</option>
                    <option>a</option>
                    <option>b</option>
                  </select>
                </Stack>
                <Link to="/training/add-training" className="submenu-item">
                  <Button className="header-add cancle-hover">
                    <CheckIcon sx={{ mr: 1 }} />
                    Apply
                  </Button>
                </Link>
                <ReplayRoundedIcon className="refresh" />
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default TableHeader;
