/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Select,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Autocomplete,
  Chip,
  Checkbox,
  Paper,
  FormControlLabel,
  Divider,
  Modal,
} from "@mui/material";
import { Button, Typography } from "material-ui-core";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import ChartSection from "./overviewTabs/ChartSection";
import ListSection from "./overviewTabs/ListSection";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CategoryData from "./overviewTabs/CategoryData";
import * as moment from "moment";
import CheckListLocation from "./overviewTabs/ChecklistLocation";
import StoreData from "./overviewTabs/StoreData";
import { useSelector } from "react-redux";
import { AllActiveChecklistListApi } from "../redux/actions/Checklist";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 500,
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

const Overview = () => {
  const storeDataString = localStorage.getItem("overviewDateData") || "{}";
  const storedDateObject = JSON.parse(storeDataString);
  const overviewChecklist =
    localStorage.getItem("overviewChecklistData") || "{}";
  const overviewChecklistData = JSON.parse(overviewChecklist);
  const overviewUser = localStorage.getItem("overviewUserData");
  const location = useLocation();
  const issuesScreenFilter = location.state;
  const [startDate, setstartDate] = useState(
    moment(
      issuesScreenFilter?.startDate || moment().subtract(1, "months")
    ).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(issuesScreenFilter?.endDate || new Date()).format("YYYY-MM-DD")
  );
  const [newDate, setNewDate] = useState({ startDate, endDate });
  const [valueDropdown, setValueDropdown] = React.useState("Userdata");
  const [filterValueDropdown, setFilterValueDropdown] = React.useState(
    overviewUser || "Userdata"
  );
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [selectedChecklist, setSelectedChecklist] = React.useState(
    overviewChecklistData.selectedChecklist || []
  );
  const [selectedChecklistIds, setSelectedChecklistIds] = React.useState(
    overviewChecklistData.checklistIds || []
  );
  const dispatch = useDispatch();

  const checklistsLoading = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  const ListLoading = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  const loadingOverList = useSelector(
    (state) => state.checklist && state.checklist.loadingOverList
  );
  const loadingRecentStoreSubmission = useSelector(
    (state) => state.checklist && state.checklist.loadingRecentStoreSubmission
  );
  const categoryLoading = useSelector(
    (state) => state.checklist && state.checklist.categoryLoading
  );

  const activeCheckListData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allactivechecklist &&
      state?.checklist?.allactivechecklist?.data &&
      state?.checklist?.allactivechecklist?.data?.data
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeDropdown = (event) => {
    setValueDropdown(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleDateClick = (e) => {
    const dates = {
      firstdate: startDate,
      seconddate: endDate,
    };
    setNewDate(dates);
  };

  const handleCheckChange = (event, newValue) => {
    if (newValue) {
      setSelectedChecklist(newValue);
    }
  };

  const handleCheckClose = () => {
    // if(selectedChecklist.length !== selectedChecklistIds.length){
    //   const checklistIds = selectedChecklist.map(item=> item._id)
    //   setSelectedChecklistIds(checklistIds);
    // }
  };

  const getApi = () => {
    const id = localStorage.getItem("companyId");
    const paginationRequest = {
      url: BASE_URL + `activechecklistdropdown?company_id=${id}`,
    };
    dispatch(AllActiveChecklistListApi(paginationRequest));
  };

  useEffect(() => {
    !selectedChecklist.length &&
      activeCheckListData &&
      activeCheckListData.length &&
      setSelectedChecklist([activeCheckListData[0]]);
    !selectedChecklistIds.length &&
      activeCheckListData &&
      activeCheckListData.length &&
      setSelectedChecklistIds([activeCheckListData[0]._id]);
  }, [activeCheckListData]);

  useEffect(() => {
    getApi();
  }, []);

  const modalopenFilterhandleClose = () => {
    const array1 = selectedChecklist.map((item) => item._id);
    var findDiff = array1.filter((obj) => {
      return selectedChecklistIds.indexOf(obj) == -1;
    });

    if (!selectedChecklist.length) {
      toast.error("No training selected! Please select Atleast one Checklist.");
      return;
    }

    if (findDiff.length) {
      const checklistIds = selectedChecklist.map((item) => item._id);
      setSelectedChecklistIds(checklistIds);
      const data = {
        selectedChecklist: selectedChecklist,
        checklistIds: checklistIds,
      };
      localStorage.setItem("overviewChecklistData", JSON.stringify(data));
    }

    if (startDate !== newDate.firstdate || endDate !== newDate.seconddate) {
      const dates = {
        firstdate: startDate,
        seconddate: endDate,
      };
      localStorage.setItem("overviewDateData", JSON.stringify(dates));
      setNewDate(dates);
    }

    if (filterValueDropdown !== valueDropdown) {
      setFilterValueDropdown(valueDropdown);
      localStorage.setItem("overviewUserData", valueDropdown);
    }

    setOpenFilterModal(false);
  };

  return (
    <>
      <Box>
        <ToastContainer />
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12} md={12} sm={12} justifyContent="right">
              <Stack direction="row" className="stack" justifyContent="right">
                <Button
                  className="header-add cancle-hover"
                  style={{
                    height: "42px",
                    borderRadius: "8px",
                    marginTop: "3px",
                    padding: "12px 16px",
                  }}
                  onClick={(e) => setOpenFilterModal(true)}
                >
                  <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                  Filter
                </Button>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="Start Date"
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={startDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setStartDate(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
                <Typography className="date-to">to</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="End Date"
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={endDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setEndDate(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick"
                    maxDate={new Date()}
                    minDate={startDate}
                  />
                </LocalizationProvider>
                <Button
                  className="header-add cancle-hover"
                  style={{ height: "42px", borderRadius:"8px", padding: "12px 16px" }}
                  onClick={(e) => handleDateClick(e)}
                >
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Apply
                </Button> */}
                <div style={{ marginTop: "4px", marginRight: "10px" }}>
                  <Tooltip
                    title={
                      <div style={{ maxWidth: "300px" }}>
                        <strong>Checklist overview page:</strong>
                        <p>
                          Displays the overview of all the Checklist and audits
                          submissions
                        </p>
                      </div>
                    }
                    arrow
                    interactive
                  >
                    <InfoIcon
                      style={{
                        marginLeft: "8px",
                        verticalAlign: "middle",
                        cursor: "pointer",
                        color: "#D76C00",
                      }}
                    />
                  </Tooltip>
                </div>
              </Stack>
            </Grid>
            {/* <Grid
              item
              xs={12}
              lg={7}
              md={6}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight:"4px",
                paddingRight:"4px"
              }}
            > */}
            {/* <Box
                sx={{
                  pr: 2,
                  width: '450px'
                }}
              >
                <FormControl fullWidth >
                  <Autocomplete
                    // options={[]}
                    options={activeCheckListData && activeCheckListData.length > 0 ? activeCheckListData : []}
                    getOptionLabel={(option) => option?.title || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        size="small"
                        placeholder="Select Checklist"
                        sx={{ borderRadius: "8px" }}
                      />
                    )}
                    className="checklist-filter"
                    value={selectedChecklist}
                    onChange={handleCheckChange}
                    onClose={handleCheckClose}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          color: option.expired ? "#eb4f4f" : "",
                        }}
                        key={option._id}
                      >
                        <Checkbox
                          checked={
                            selectedChecklist
                              ? selectedChecklist.includes(option)
                              : false
                          }
                          sx={{ mr: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newSelected = selectedChecklist
                              ? [...selectedChecklist]
                              : [];
                            const selectedIndex = newSelected.indexOf(option);

                            if (selectedIndex === -1) {
                              newSelected.push(option);
                            } else {
                              newSelected.splice(selectedIndex, 1);
                            }

                            setSelectedChecklist(newSelected);
                          }}
                        />
                        {option.title}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <Chip
                                key={option.title}
                                label={option.title}
                                {...getTagProps({ index })}
                                color="primary"
                                sx={{ mr: 1, mb: 1, width: "150px", textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap"}}
                              />
                              {value.length > 1 ? (
                                <Chip
                                  key={`${value.length - 1} more selected`}
                                  label={`${value.length - 1} more selected`}
                                  color="primary"
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      })
                    }
                    clearable
                    disableCloseOnSelect
                    multiple
                    PaperComponent={(paperProps) => {
                      const { children, ...restPaperProps } = paperProps;
                      return (
                        <Paper {...restPaperProps}>
                          <Box
                          style={{marginRight:"4px"}}
                            onMouseDown={(e) => e.preventDefault()}
                            pl={3}
                            py={0.5}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                activeCheckListData &&
                                selectedChecklist.length > 0 &&
                                selectedChecklist.length === activeCheckListData.length
                              ) {
                                handleCheckChange("", []);
                              } else {
                                handleCheckChange("", activeCheckListData);
                              }
                            }}
                          >
                            <FormControlLabel
                              label="Select all"
                              control={
                                <Checkbox
                                  sx={{ mr: 1 }}
                                  id="select-all-checkbox"
                                  checked={
                                    activeCheckListData &&
                                    selectedChecklist.length > 0 &&
                                    selectedChecklist.length ===
                                    activeCheckListData.length
                                  }
                                />
                              }
                            />
                          </Box>
                          <Divider />
                          {children}
                        </Paper>
                      );
                    }}
                  />
                </FormControl>
              </Box> */}
            {/* <select
                className="overview-select dash-select-overview"
                onChange={handleChangeDropdown}
                value={valueDropdown}
              >
                <option value="Userdata">User/subrole</option>
                <option value="Storedata">Storedata</option>
                <option value="checklistData">ChecklistData</option>
              </select> */}
            {/* {/ <FormControl fullWidth> /} */}
            {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={valueDropdown}
                  placeholder="select here"
                  onChange={handleChangeDropdown}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="Userdata">Userdata</MenuItem>
                  <MenuItem value="Storedata">Storedata</MenuItem>
                  <MenuItem value="checklistData">ChecklistData</MenuItem>
                </Select> */}
            {/* {/ </FormControl> /} */}
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </Box>
        {/* checklistsLoading || ListLoading || categoryLoading */}
        {ListLoading || loadingOverList || loadingRecentStoreSubmission ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          ""
        )}
        <Box sx={{ bgcolor: "#F6F8FC", p: 3, borderRadius: "4px" }}>
          <ChartSection
            valueDropdown={filterValueDropdown}
            newDate={newDate}
            selectedChecklist={selectedChecklistIds}
          />
          <ListSection selectedChecklist={selectedChecklistIds} />
          {/* <CheckListLocation /> */}
          <CategoryData selectedChecklist={selectedChecklistIds} />
          {/* <StoreData /> */}
        </Box>
        <p>
          Copyright ©{" "}
          <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
          All Right Reserved.
        </p>
        <Modal
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEscapeKeyDown={false}
        >
          <Box sx={{ ...downloadAllstyle, p: 0 }}>
            <Box
              sx={{
                position: "sticky",
                right: "15px",
                top: "15px",
                cursor: "pointer",
                textAlign: "right",
                padding: "0px 10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box onClick={() => setOpenFilterModal(false)}>
                <CloseIcon />
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  pt: 2,
                  px: 4,
                }}
              >
                <label>Date</label>
                <Box
                  sx={{ mb: 2 }}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      size="small"
                      value={startDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYY-MM-DD")
                          .split("/")
                          .join("");
                        setstartDate(dateSort);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      className="date-pick date-pick-submision"
                      maxDate={new Date()}
                    />
                  </LocalizationProvider>
                  <Typography className="date-to">to</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label="End Date"
                      inputFormat="DD/MM/YYYY"
                      size="small"
                      value={endDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYY-MM-DD")
                          .split("/")
                          .join("");
                        setendDate(dateSort);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      className="date-pick date-pick-submision"
                      maxDate={new Date()}
                      minDate={startDate}
                    />
                  </LocalizationProvider>
                </Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>User/subrole</label>

                  <Select
                    name="user"
                    value={valueDropdown}
                    onChange={handleChangeDropdown}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px", marginTop: "10px" }}
                    placeholder="Select User"
                  >
                    <MenuItem value={"Userdata"}>User/subrole</MenuItem>
                    <MenuItem value={"Storedata"}>Storedata</MenuItem>
                    <MenuItem value={"checklistData"}>ChecklistData</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <label>Active Checklist</label>

                  <Autocomplete
                    // options={[]}
                    options={
                      activeCheckListData && activeCheckListData.length > 0
                        ? activeCheckListData
                        : []
                    }
                    getOptionLabel={(option) => option?.title || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        size="small"
                        placeholder="Select Checklist"
                        sx={{ borderRadius: "8px", marginTop: "10px" }}
                      />
                    )}
                    className="checklist-filter"
                    value={selectedChecklist}
                    onChange={handleCheckChange}
                    onClose={handleCheckClose}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          color: option.expired ? "#eb4f4f" : "",
                        }}
                        key={option._id}
                      >
                        <Checkbox
                          checked={
                            selectedChecklist
                              ? selectedChecklist.includes(option)
                              : false
                          }
                          sx={{ mr: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newSelected = selectedChecklist
                              ? [...selectedChecklist]
                              : [];
                            const selectedIndex = newSelected.indexOf(option);

                            if (selectedIndex === -1) {
                              newSelected.push(option);
                            } else {
                              newSelected.splice(selectedIndex, 1);
                            }

                            setSelectedChecklist(newSelected);
                          }}
                        />
                        {option.title}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <Chip
                                key={option.title}
                                label={option.title}
                                {...getTagProps({ index })}
                                color="primary"
                                sx={{
                                  mr: 1,
                                  mb: 1,
                                  width: "150px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              />
                              {value.length > 1 ? (
                                <Chip
                                  key={`${value.length - 1} more selected`}
                                  label={`${value.length - 1} more selected`}
                                  color="primary"
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      })
                    }
                    clearable
                    disableCloseOnSelect
                    multiple
                    PaperComponent={(paperProps) => {
                      const { children, ...restPaperProps } = paperProps;
                      return (
                        <Paper {...restPaperProps}>
                          <Box
                            onMouseDown={(e) => e.preventDefault()}
                            pl={3}
                            py={0.5}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                activeCheckListData &&
                                selectedChecklist.length > 0 &&
                                selectedChecklist.length ===
                                  activeCheckListData.length
                              ) {
                                handleCheckChange("", []);
                              } else {
                                handleCheckChange("", activeCheckListData);
                              }
                            }}
                          >
                            <FormControlLabel
                              label="Select all"
                              control={
                                <Checkbox
                                  sx={{ mr: 1 }}
                                  id="select-all-checkbox"
                                  checked={
                                    activeCheckListData &&
                                    selectedChecklist.length > 0 &&
                                    selectedChecklist.length ===
                                      activeCheckListData.length
                                  }
                                />
                              }
                            />
                          </Box>
                          <Divider />
                          {children}
                        </Paper>
                      );
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "fixed",
                bottom: 0,
                width: "100%",
                gap: "30px",
                px: 3,
                pt: 1,
                background: "#fff",
              }}
            >
              <Button
                className="cancle-button cancle-hover"
                style={{
                  height: "46px",
                  padding: "12px 16px",
                  width: "47%",
                  background: "#fff",
                }}
                onClick={() => {
                  const dates = {
                    startDate: moment(new Date()).format("YYYY-MM-DD"),
                    endDate: moment(new Date()).format("YYYY-MM-DD"),
                  };
                  setstartDate(dates.startDate);
                  setendDate(dates.endDate);
                  setNewDate(dates);
                  activeCheckListData &&
                    activeCheckListData.length &&
                    setSelectedChecklist([activeCheckListData[0]]);
                  setValueDropdown("Userdata");
                }}
              >
                Clear
              </Button>
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "46px",
                  padding: "12px 16px",
                  width: "47%",
                }}
                onClick={(e) => {
                  modalopenFilterhandleClose();
                  // setOpenFilterModal(false);
                  // handleFilter();
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Overview;
