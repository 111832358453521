import React from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { ListUserApi, UserStoreApi } from "../../redux/actions/User";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import * as moment from "moment";
import { AssignUserApi, ListStoreApi } from "../../redux/actions/Store";
import { FormikConsumer, useFormik } from "formik";
import * as yup from "yup";
import Progressbar from "../../GlobalProgress/Progressbar";
import { toast, ToastContainer } from "react-toastify";

const MenuProps = {
  disableScrollLock: true,
};

const AssignUser = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState();
  const classes = useStyles();

  // user
  const [userSelected, setUserSelected] = useState("");

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const isAllSelectedUser =
    userSelect.length > 0 && selectedUser.length === userSelect.length;

  // store
  const [mapSelectData, setMapSelectData] = useState([]);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    mapSelectData.length > 0 && selected.length === mapSelectData.length;

  // date picker
  const [dateValue, setDateValue] = useState([null, null]);

  const Loader = useSelector((state) => state.user && state.user.loading);

  const validationSchema = yup.object().shape({
    store: yup.string().required("This is required"),
    user: yup.string().required("This is required"),
  });

  // Assign store integration started
  const formik = useFormik({
    initialValues: {
      user_id: "",
      store_id: "",
      starts_at: "",
      expires_at: "",
      is_editable: false,
      autoAssign: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const surveys = [
        {
          user_id: values?.user_id,
          store_id: values?.store_id,
          starts_at: values?.starts_at,
          expires_at: values?.expires_at,
          is_editable: false,
          autoAssign: false,
        },
      ];
      // if (months.length > 0) {
      //   surveys.month_date = months;
      // }
      const formData = {
        body: surveys,
      };
      console.log("formData", formData);
      if (formData) {
        const id = localStorage.getItem("companyId");
        const assignUserData = {
          url: BASE_URL + `assignments?company_id=${id}`,
        };
        dispatch(AssignUserApi(assignUserData));
      }
    },
  });
  console.log("formik", formik);

  // Assign store integration ended

  // user select started
  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  const assignUserData = useSelector(
    (state) =>
      state?.store && state?.store?.assignUser && state?.store?.assignUser.data
  );
  console.log("assignUserData", assignUserData);

  useEffect(() => {
    if (assignUserData && assignUserData.status === 200) {
      toast.success(assignUserData.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/store/store-details");
      }, 2000);
    }
  });

  const handleChangeUser = (e) => {
    setUserSelected(e.target.value);
  };

  useEffect(() => {
    if (userList) {
      let arr = [];
      for (let item of userList) {
        let obj = item;
        arr.push(obj);
      }
      setUserSelect(arr);
    }
  }, [userList]);

  useEffect(() => {
    setUsers(userList);
  }, [userList]);

  useEffect(() => {
    listUser();
  }, [dispatch]);

  const listUser = () => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `users/index?company_id=${id}`;
    dispatch(ListUserApi(data));
  };

  // select user ended

  // select store started
  const storeDataList = useSelector(
    (state) =>
      state?.store &&
      state?.store?.data &&
      state?.store?.data?.data &&
      state?.store?.data?.data?.data &&
      state?.store?.data?.data?.data?.stores
  );

  const handleChangeStore = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelected(
        selected.length === mapSelectData?.length ? [] : mapSelectData
      );
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    if (storeDataList) {
      let arr = [];
      for (let item of storeDataList) {
        let obj = { _id: item?._id, title: item?.name };
        arr.push(obj);
      }
      setMapSelectData(arr);
    }
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const allMapedData = { url: BASE_URL + `stores/index/?company_id=${id}` };
    dispatch(ListStoreApi(allMapedData));
  }, []);
  // select store ended

  const data = {
    body: {
      page: page,
      per_page: rowsPerPage,
    },
  };

  return (
    <>
      <ToastContainer limit={1} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/store" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Link to="/store" className="submenu-item">
                  <Button className="header-add cancle-hover">
                    <CheckOutlinedIcon sx={{ mr: 1 }} />
                    Confirm
                  </Button>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {/* Loading will be start here */}
        {Loader ? (
          <Progressbar />
        ) : (
          <Box
            sx={{
              height: "80vh",
              bgcolor: "#F6F8FC",
              p: 1,
              borderRadius: "4px",
            }}
          >
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select User</label>
                <Select
                  size="small"
                  name="user"
                  id=" user_id"
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "200px",
                      },
                    },
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.user}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                >
                  {userSelect &&
                    userSelect?.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option.name}
                          className="select-item"
                        >
                          {option.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <small className="error">
                  {formik.errors.user && formik.touched.user
                    ? formik.errors.user
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Servey Period</label>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{
                    start: "",
                    end: "",
                  }}
                >
                  <DateRangePicker
                    inputFormat="MM/DD/YYYY"
                    disablePast
                    value={dateValue}
                    onChange={(newValue) => {
                      let dateSort = newValue.map((val) =>
                        moment(val?.$d).format("YYYY/MM/DD").split("/").join("")
                      );
                      setDateValue(dateSort);
                    }}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField
                          {...startProps}
                          fullWidth
                          size="small"
                          className="date-input"
                          name="starts_at"
                        />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField
                          {...endProps}
                          fullWidth
                          size="small"
                          className="date-input"
                          name="starts_at"
                        />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Store</label>
                <Select
                  labelId="store_id"
                  id="store_id"
                  name="store"
                  multiple
                  size="small"
                  value={selected}
                  onChange={handleChangeStore}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 0,
                        },
                        height: "180px",
                      },
                    },
                  }}
                  renderValue={(selected) =>
                    selected.map((item) => item.title)?.join(",")
                  }
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < mapSelectData.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {mapSelectData &&
                    mapSelectData.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option}>
                          <ListItemIcon>
                            <Checkbox checked={selected.indexOf(option) > -1} />
                          </ListItemIcon>
                          <ListItemText primary={option.title} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Container>
          </Box>
        )}
      </form>
    </>
  );
};

export default AssignUser;
