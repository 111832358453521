import React, { useState } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid, Radio } from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const TableHeader = () => {
  const [selectedValue, setSelectedValue] = useState("a");
  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                icon={<p className="active-tabs-content">28</p>}
                iconPosition="end"
                className="active-tabs tabs-content"
              />
              <Tab
                label="Archived"
                icon={<p className="active-tabs-content">8</p>}
                iconPosition="end"
                className="Archived-tabs tabs-content"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Stack
              direction="row"
              className="checkbox"
              sx={{ alignItems: "center", gap: "5px" }}
            >
              <Radio
                {...controlProps("a")}
                sx={{
                  color: "#F39A4C",
                  "&.Mui-checked": {
                    color: "#F39A4C",
                  },
                  justifyContent: "center",
                }}
              />
              <span className="negative-margin">Due</span>
              <Radio
                {...controlProps("b")}
                sx={{
                  color: "#039855",
                  "&.Mui-checked": {
                    color: "#039855",
                  },
                  justifyContent: "center",
                }}
                color="error"
              />
              <span className="radio-text negative-margin">Completed</span>
              <Radio
                {...controlProps("c")}
                sx={{
                  color: "#BB251A",
                  "&.Mui-checked": {
                    color: "#BB251A",
                  },
                  justifyContent: "center",
                }}
                color="error"
              />
              <span className="radio-text negative-margin">Expired</span>
              <Button className="header-add-filter cancle-hover bg">
                <FilterAltOutlinedIcon />
                Filter
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Stack direction="row" spacing={1} className="Assign-Checklist">
              <SearchAppBar />
              <Link
                to="/all-assignment/add-assignment"
                className="submenu-item"
              >
                <Button className="header-add cancle-hover">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  New Assignment
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default TableHeader;
