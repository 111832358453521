import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const AddEntryHeader = (props) => {
    const isEdit = localStorage.getItem('userId');

    return (
        <>
            <Box
                sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
                className="header-card"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} sx={{ float: "right", height: "42px", borderRadius: "8px" }}>
                            <Link
                                to={
                                    "/log-entry"
                                }
                                className="submenu-item"
                            >
                                <Button className="cancle-button cancle-hover" onClick={() => props.handleCancel()}>
                                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                                    Cancel
                                </Button>
                            </Link>
                            {/* <Link to="/checklist/all-checklist" className="submenu-item"> */}
                            <Button
                                className="header-add cancle-hover"
                                type="submit"
                                style={{ marginRight: 5, height: "42px", borderRadius: "8px" }}
                            // onClick={handleAssign}
                            >
                                <AddOutlinedIcon sx={{ mr: 1 }} />
                                {isEdit ? "Update" : "Create"}
                            </Button>
                            {/* </Link> */}
                            <div style={{ marginTop: "8px", marginRight: "8px" }}>
                                <Tooltip
                                    title={
                                        <div style={{ maxWidth: "300px" }}>
                                            <strong>Assign Checklist Page:</strong>
                                            <p>1.You can Either select user role or users to assign checklist or Audits
                                                Selecting Role - Activates the current assignment for all users under that role.
                                                Selecting Users - Activates the current assignment for specific users only.
                                            </p>

                                            <p>2.select reports:  Select Here To Send Report.</p>

                                            <p>Showing sub role list - Select only if you need to send email report else leave it</p>

                                            <p>3.Frequency defines the rate of occurrence.</p>

                                            <p>For example:
                                                Weeky Frequency enables you to filter week day.
                                                Monthly frequency enables the month day filter.
                                            </p>
                                        </div>
                                    }
                                    arrow
                                    interactive
                                >
                                    <InfoIcon
                                        style={{
                                            verticalAlign: "middle",
                                            cursor: "pointer",
                                            color: "#D76C00",
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddEntryHeader;