/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
  Checkbox,
  MenuItem,
  Select,
  FormControlLabel,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CompanyGetApi, EditCompanyApi } from "../redux/actions/CreateCompany";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { ImageAddApi } from "../redux/actions/User";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState([]);
  const [newFieldValue, setNewFieldValue] = useState();
  const [images, setImages] = useState({ preview: "", raw: "" });

  const companyData = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data
  );

  const imagegetid = useSelector((state) => state.user && state.user.imgId);
  const editCompanyres = useSelector(
    (state) =>
      state?.company && state?.company?.update && state?.company?.update.data
  );

  useEffect(() => {
    setInputValue(companyData);
  }, [companyData]);

  useEffect(() => {
    if (editCompanyres && editCompanyres.status === 200) {
      toast.success(editCompanyres.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/company-list");
      }, 2000);
    }
  }, [editCompanyres]);

  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    setInputValue(companyData);
    setNewFieldValue({
      companyName: companyData?.name,
      ra: companyData?.is_ra,
      re: companyData?.is_re,
      image_category: companyData?.image_category,
    });
  }, [companyData]);

  useEffect(() => {
    if (
      imagegetid &&
      imagegetid?.data &&
      imagegetid?.data?.data &&
      imagegetid?.data?.data?.id
    ) {
      updateCompany(formik.values, imagegetid?.data?.data?.id);
    }
  }, [imagegetid]);

  const getCompanyData = () => {
    const cid = location?.state?._id;
    const data = { url: BASE_URL + `companies/${cid}` };
    dispatch(CompanyGetApi(data));
  };

  const formik = useFormik({
    initialValues: newFieldValue || {
      companyName: "",
      ra: "",
      re: "",
      image_category: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const imagefile = {
        file: images.preview,
        name: images.raw.name,
      };
      const imageData = {
        body: imagefile,
      };

      if (imageData.body.file != "") {
        imageData.url = BASE_URL + `uploads/base64`;
        dispatch(ImageAddApi(imageData));
      } else {
        let imgId = "";
        updateCompany(values, imgId);
      }
      //   if (allData) {
      //     allData.url = BASE_URL + `companies/${id}`;
      //     dispatch(EditCompanyApi(allData));
      //     navigate("/company-list");
      //   }
    },
  });
  const updateCompany = (values, image) => {
    const formData = {
      image_category: values?.image_category,
      name: values?.companyName,
      is_ra: values?.ra,
      is_re: values?.re,
      _id: inputValue?._id,
    };
    if (image) {
      formData.logo = image;
    }

    const allData = {
      body: formData,
    };
    if (allData) {
      const id = location?.state?._id;
      allData.url = BASE_URL + `companies/${id}`;
      dispatch(EditCompanyApi(allData));
    }
  };

  const handleImageUpload = (e) => {
    if (e.target.files.length) {
      var reader = new FileReader();
      var file = e.target.files[0];

      reader.onload = function (upload) {
        setImages({
          preview: upload.target.result,
          raw: e.target.files[0],
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Box>
        <ToastContainer />
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
            className="header-card"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/company-list" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <TaskAltIcon sx={{ mr: 1 }} />
                    Update
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <Box>
                {images?.preview ? (
                  <img
                    className="logo-select logo-img"
                    src={images.preview}
                    alt="attachment"
                  />
                ) : (
                  <Box>
                    <img
                      className="logo-select logo-img"
                      src={inputValue?.logo?.url}
                      alt="attachment"
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file" className="modal-btn">
                  Change Logo
                </label>
              </Box>
              <FormControl fullWidth>
                <label style={{ marginTop: "20px" }}>Company Name</label>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="companyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                />
              </FormControl>

              <FormControlLabel
                value="true"
                name="ra"
                control={<Checkbox checked={formik?.values?.ra} />}
                onChange={formik.handleChange}
                label="Retail Audit"
                labelPlacement="end"
              />
              <br />
              <FormControlLabel
                value="true"
                name="re"
                control={<Checkbox checked={formik?.values?.re} />}
                onChange={formik.handleChange}
                label="Ice"
                labelPlacement="endd"
              />
              <FormControl fullWidth>
                <label style={{ marginTop: "20px" }}>Image Category</label>
                <Select
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="image_category"
                  onChange={formik.handleChange}
                  value={formik?.values?.image_category}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {formik?.values?.image_category == "photo" ? (
                    <MenuItem value="">
                      <em>{formik?.values?.image_category}</em>
                    </MenuItem>
                  ) : (
                    <MenuItem value={"Photo"}>Photo</MenuItem>
                  )}
                  {formik?.values?.image_category == "both" ? (
                    <MenuItem value={formik?.values?.image_category}>
                      <em>{formik?.values?.image_category}</em>
                    </MenuItem>
                  ) : (
                    <MenuItem value={"Both"}>Both</MenuItem>
                  )}

                  {formik?.values?.image_category == "gallery" ? (
                    <MenuItem value="">
                      <em>{formik?.values?.image_category}</em>
                    </MenuItem>
                  ) : (
                    <MenuItem value={"Gallery"}>Gallery</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Container>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditCompany;
