import React, { useState, useEffect } from "react";
import { Stack, Button, Card, Grid, Tabs, Tab } from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const AssignHeader = ({
  searchOnChange,
  searchValue,
  handleKeyDown,
  userCancle,
}) => {
  const [value, setValue] = useState(0);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            {/* <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="User"
                icon={<p className="active-tabs-content">28</p>}
                iconPosition="end"
                className="active-tabs tabs-content"
              />
              <Tab
                label="Role"
                icon={<p className="active-tabs-content">8</p>}
                iconPosition="end"
                className="Archived-tabs tabs-content"
              />
            </Tabs> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Stack direction="row" className="Assign-Checklist">
              {/* <SearchAppBar /> */}
              <SearchAppBar
                searchValue={searchValue}
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
              />
              {permission?.checklist?.manageChecklist === true ? (
                <Link
                  to="/checklist/all-checklist/add"
                  state={userCancle}
                  className="submenu-item"
                >
                  <Button className="header-add cancle-hover">
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Assign Checklist
                  </Button>
                </Link>
              ) : (
                ""
              )}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AssignHeader;
