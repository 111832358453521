/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Avatar,
} from "@mui/material";
import { ViewHeader } from "./Header";
// import UserPro from "../../../images/UserPro.svg";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { UserDetailsApi, UserActivityApi } from "../../../redux/actions/User";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Progressbar from "../../../GlobalProgress/Progressbar";
import User from "../../../../assets/icons/svg-icons/User.svg";
import { ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const UserDetails = () => {
  const userDetailData = useSelector(
    (state) =>
      state.user &&
      state.user.details &&
      state.user.details.data &&
      state.user.details.data.data
  );
  const getActivityDetails = useSelector(
    (state) =>
      state.user &&
      state.user.activity &&
      state.user.activity.data &&
      state.user.activity.data.data &&
      state.user.activity.data.data.data
  );
  const Loader = useSelector((state) => state.user && state.user.loading);
  const location = useLocation();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState([]);
  const [activityData, setActivityData] = React.useState([]);
  const [permission, setPermission] = useState({});
  const [uiRender, setUiRender] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
      setUiRender(!uiRender);
    }
  }, []);

  useEffect(() => {
    // setLoader(true);
    setUserDetails(userDetailData);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [userDetailData]);

  useEffect(() => {
    setActivityData(getActivityDetails);
  }, [getActivityDetails]);

  useEffect(() => {
    listDetails();
    activityDetails();
  }, []);

  //user details api
  const listDetails = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state?._id;
    const data = { url: BASE_URL + `users/${userId}?company_id=${_id}` };
    dispatch(UserDetailsApi(data));
  };

  //user activity details api
  const activityDetails = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state?._id;
    const data = {
      url: BASE_URL + `misc/user/activity/index?company_id=${_id}`,
      body: {
        filter: {
          user_ids: [userId],
        },
        page: 1,
        per_page: 20,
        sort_as: -1,
        sort_by: "time",
      },
    };
    dispatch(UserActivityApi(data));
  };

  return (
    <Box>
      <ViewHeader userDetails={userDetails} permission={permission} />
      <ToastContainer />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            overflowX: "hidden",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <Card
                sx={{
                  mb: 2,
                  borderRadius: "8px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={1} sx={{ p: 1 }}>
                  <Grid item xs={12} lg={5} sm={12} md={5}>
                    <Avatar
                      sx={{
                        width: "150px",
                        height: "150px",
                        backgroundColor: "#F6F8FC",
                        border: "2px solid #CBD4E1",
                        margin: "auto",
                      }}
                    >
                      <img
                        src={
                          userDetails?.avatar !== null
                            ? userDetails?.avatar
                            : User
                        }
                        alt="userimage"
                        align="center"
                        width="150px"
                      />
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={7}
                    sm={12}
                    md={7}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          {/* <TableCell className="detail-key">
                          <Typography variant="p">ID:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {userDetails._id}
                        </TableCell> */}
                        </TableRow>
                        <TableRow>
                          <TableCell className="detail-key">
                            <Typography variant="p">User Name:</Typography>
                          </TableCell>
                          <TableCell className="detail-value capitalize">
                            {userDetails.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="detail-key">
                            <Typography variant="p">Email ID:</Typography>
                          </TableCell>
                          <TableCell className="detail-value">
                            {userDetails.email}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="detail-key">
                            <Typography variant="p">User Role:</Typography>
                          </TableCell>
                          <TableCell className="detail-value">
                            {userDetails.role}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="detail-key">
                            <Typography variant="p">Store:</Typography>
                          </TableCell>
                          {userDetails &&
                          userDetails.store &&
                          userDetails.store !== "undefined" &&
                          userDetails.store.length > 0 ? (
                            <TableCell className="detail-value">
                              {userDetails?.store?.map((item, i) => {
                                return (
                                  <Typography variant="p" key={i}>
                                    {item && item.title ? item.title : ""},{" "}
                                  </Typography>
                                );
                              })}
                            </TableCell>
                          ) : (
                            <TableCell className="detail-value">
                              {userDetails?.store?.title}
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell className="detail-key">
                            <Typography variant="p">City:</Typography>
                          </TableCell>
                          <TableCell className="detail-value">
                            {userDetails.city}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="detail-key">
                            <Typography variant="p">Aadhaar Card:</Typography>
                          </TableCell>
                          <TableCell className="detail-value">
                            {userDetails?.identity_proof?.length &&
                              userDetails?.identity_proof?.[0]
                                .maskedAadharNumber}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <Card
                sx={{
                  px: 1,
                  mb: 2,
                  py: 1,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <CardContent>
                  <Table aria-label="simple table" className="details-table">
                    <TableBody>
                      {/* <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p">City:</Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        {userDetails.city}
                      </TableCell>
                    </TableRow> */}
                      <TableRow>
                        <TableCell className="detail-key">
                          <Typography variant="p">Phone:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {userDetails.contact}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="detail-key">
                          <Typography variant="p">Address:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {userDetails.street}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="detail-key">
                          <Typography variant="p">Sub Role:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {userDetails.subRole}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="detail-key">
                          <Typography variant="p">Created at:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {moment(userDetails.createdAt).format(
                            "MMMM D, YYYY, LTS"
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="detail-key">
                          <Typography variant="p">Updated at:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {moment(userDetails.updatedAt).format(
                            "MMMM D, YYYY, LTS"
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="detail-key">
                          <Typography variant="p">Comment:</Typography>
                        </TableCell>
                        <TableCell className="detail-value">
                          {userDetails.comment}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography variant="p" component="h3" align="left">
            Recent Activity
          </Typography>
          <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
            <Table
              size="large"
              aria-label="simple table"
              className="grid-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="tableHead">
                    Time
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Action
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Details
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Media
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityData && activityData.length > 0 ? (
                  activityData &&
                  activityData.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {moment(row.time).format("MMMM D, YYYY, LTS")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.label}</TableCell>
                      <TableCell align="center">{row.app}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      <SentimentVeryDissatisfiedIcon fontSize="large" />
                      <Typography variant="p" component="h2">
                        No Data
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default UserDetails;
