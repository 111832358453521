import {
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
    ChangePasswordApiAction,
    ProfileMeGetApiAction,
} from "../../../redux/actions/MyProfileDetailsAction";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const AccountSetting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newFieldValue, setNewFieldValue] = useState({});
    const [openChangePasswordModel, setOpenChangePasswordModel] = useState(false);
    const [showPasswordEye, setShowPasswordEye] = useState(false);
    const [profileMeData, setProfileMeData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const profileMeGetRecords = useSelector(
        (state) =>
            state?.MyProfileDetails &&
            state?.MyProfileDetails?.progileMeGetApiRes &&
            state?.MyProfileDetails?.progileMeGetApiRes?.data &&
            state?.MyProfileDetails?.progileMeGetApiRes?.data?.data
    );

    const ChangePasswordSelector = useSelector(
        (state) =>
            state?.MyProfileDetails &&
            state?.MyProfileDetails?.changeMyProfilePassword &&
            state?.MyProfileDetails?.changeMyProfilePassword?.data
    );
    console.log("ChangePasswordSelector", ChangePasswordSelector);

    useEffect(() => {
        ProfileMeGetAPI();
        setProfileMeData(profileMeGetRecords);
    }, []);

    useEffect(() => {
        if (ChangePasswordSelector?.status == 200) {
            location.reload();
            console.log("hhhhhh")
        }
    }, []);

    function handleChangePasswordSaveClick(event) {
        console.log("save button clicked !!", event);
    }

    const handleClickShowPassword = () => {
        setShowPasswordEye(!showPasswordEye);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validationSchema = Yup.object({
        old_password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
        password_confirmation: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
    });
    const formik = useFormik({
        initialValues: {
            old_password: "",
            password: "",
            password_confirmation: "",
            checkbox: true,
        },
        // enableReinitialize: true,
        // validationSchema: validationSchema,

        onSubmit: (values) => {
            console.log("values 123456789", values);
            let formData = {
                old_password: values?.old_password,
                password: values?.password,
                password_confirmation: values?.password_confirmation,
                // checkbox: values?.checkbox,
            };
            if(values?.password_confirmation.toLowerCase() !== values?.password.toLowerCase()){
                return toast.error(`Password and Confirm Password should be same`);
            }
            const allData = {
                body: formData,
            };
            if (allData) {
                allData.url = BASE_URL + `sessions/changepassword`;
                dispatch(ChangePasswordApiAction(allData));
                setOpen(false);
            }
        },
    });

    const ProfileMeGetAPI = () => {
        const data = { url: BASE_URL + `profile/me` };
        dispatch(ProfileMeGetApiAction(data));
    };

    return (
        <div>
            <ToastContainer />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormControl fullWidth sx={{ ml: 2 }}>
                        <h2 className="label-name">Change your Password</h2>
                    </FormControl>
                    <form onSubmit={formik.handleSubmit}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <label style={{ color: "gold" }}>
                                    * This action is irreversible.
                                </label>
                            </FormControl>

                            <FormControl sx={{ mb: 2 }} fullWidth>
                                <label className="label-name">Old Password</label>
                                <TextField
                                    fullWidth
                                    id="old_password"
                                    name="old_password"
                                    type={showPasswordEye ? "text" : "old_password"}
                                    placeholder="Password123@"
                                    value={formik.values.old_password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.old_password &&
                                        Boolean(formik.errors.old_password)
                                    }
                                    helperText={
                                        formik.touched.old_password && formik.errors.old_password
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPasswordEye ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="date-input"
                                />
                            </FormControl>

                            <FormControl sx={{ mb: 2 }} fullWidth>
                                <label className="label-name">New Password</label>
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    type={showPasswordEye ? "text" : "password"}
                                    placeholder="password123@"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password && Boolean(formik.errors.password)
                                    }
                                    helperText={formik.touched.password && formik.errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPasswordEye ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="date-input"
                                />
                            </FormControl>

                            <FormControl sx={{ mb: 2 }} fullWidth>
                                <label className="label-name">Confirm Password</label>
                                <TextField
                                    fullWidth
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type={showPasswordEye ? "text" : "password_confirmation"}
                                    placeholder="password_confirmation123@"
                                    value={formik.values.password_confirmation}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password_confirmation &&
                                        Boolean(formik.errors.password_confirmation)
                                    }
                                    helperText={
                                        formik.touched.password_confirmation &&
                                        formik.errors.password_confirmation
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPasswordEye ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="date-input"
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormControlLabel
                                    className="label-name"
                                    control={
                                        <Checkbox
                                            type="checkbox"
                                            name="checkbox"
                                            value={formik.values.checkbox}
                                            onChange={formik.handleChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                            sx={{ color: "#196773" }}
                                        />
                                    }
                                    label="Logout From All Devices"
                                />
                            </FormControl>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}></Grid>
                                <Button color="secondary"  onClick={() => { setOpen(false) }}>Cancel</Button>
                                <Button type="submit" size="small" color="primary">SAVE</Button>
                                {/* <Button type="submit" size="small" color="primary" onClick={() => { setOpen(false) }}>SAVE</Button> */}
                            </Grid>
                        </Typography>
                    </form>
                </Box>
            </Modal>
            <Grid item md={6} sx={12} sm={12} lg={6}></Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label className="label-name" style={{ textAlign: "left" }}>
                            Password Settings
                        </label>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <Button variant="contained" onClick={handleOpen}>
                            Change password
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountSetting;
