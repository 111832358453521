import * as React from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Chip,
  Card,
  CardContent,
  Table,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { Link } from "react-router-dom";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ImageUser from "../../../images/User Image1.png";
import Map from "./Map";
import PropTypes from "prop-types";
import { useState } from "react";
import TableData from "./TableData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SurveyDetails = () => {
  const [value, setValue] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          borderRadius: "4px",
        }}
        className="header-card"
      >
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <Chip
                  label="Edited"
                  variant="outlined"
                  className="header-chip"
                />
                <Chip
                  label="Approved"
                  variant="outlined"
                  className="header-chip"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/all-survey" className="submenu-item">
                <Button variant="outlined" className="done-btn cancle-hover">
                  <DoneOutlinedIcon /> Done
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          borderRadius: 2,
          height: "80vh",
          mt: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card sx={{ px: 1, height: "100%", borderRadius: "8px" }}>
              <CardContent>
                <Table aria-label="simple table" className="details-table">
                  <TableBody>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Surveyor:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">skonge +12</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          User ID:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">4567890</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Role:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">Auditor</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Surveyed On:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        3/19/20, 2:27 PM
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Updated On:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        3/19/20, 5:53 PM
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ px: 1, height: "100%", borderRadius: "8px" }}>
              <CardContent>
                <Table aria-label="simple table" className="details-table">
                  <TableBody>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Surveyor:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">Alisign</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          User ID:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">4567890</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Role:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">Admin</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Assigned On:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        3/19/20, 2:27 PM
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Expiry Date:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        3/19/20, 5:53 PM
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ px: 1, height: "100%", borderRadius: "8px" }}>
              <CardContent>
                <Table aria-label="simple table" className="details-table">
                  <img src={ImageUser} alt="userimage" />
                  <TableBody>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Store:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">
                        Digital Retail Ltd
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          ID:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">4567890</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Region:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">India</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="detail-key">
                        <Typography variant="p" className="detail-value">
                          Sub-Region:
                        </Typography>
                      </TableCell>
                      <TableCell className="detail-value">Delhi</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs={12} md={8}>
            <Card sx={{ borderRadius: "8px", px: 1, height: "100%" }}>
              <CardContent>
                <Typography variant="p" component="h3" align="left">
                  Timeline for Questions
                </Typography>
                <Stack
                  direction="row"
                  sx={{ justifyContent: "space-between", my: 4 }}
                >
                  <Typography variant="p" component="p" align="left">
                    Aug 11, 2020 <br />
                    8:02:29 PM
                  </Typography>
                  <Typography variant="p" component="p" align="left">
                    Aug 11, 2020 <br />
                    8:02:29 PM
                  </Typography>
                </Stack>
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  sx={{ alignItems: "end", textAlign: "start" }}
                >
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor: "#27364B",
                        width: "8px",
                        height: "90px",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Chip
                      label="Q1"
                      variant="outlined"
                      className="question-chip"
                    />
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor: "#64748B",
                        width: "8px",
                        height: "36px",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Chip
                      label="Q1"
                      variant="outlined"
                      className="question-chip"
                    />
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor: "#64748B",
                        width: "8px",
                        height: "36px",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Chip
                      label="Q1"
                      variant="outlined"
                      className="question-chip"
                    />
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor: "#64748B",
                        width: "8px",
                        height: "36px",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Chip
                      label="Q1"
                      variant="outlined"
                      className="question-chip"
                    />
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor: "#64748B",
                        width: "8px",
                        height: "36px",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Chip
                      label="Q1"
                      variant="outlined"
                      className="question-chip"
                    />
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor: "#27364B",
                        width: "8px",
                        height: "90px",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: "100%", p: 1, borderRadius: "8px" }}>
              <div
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  width: "100%",
                }}
              >
                <Map />
              </div>
              <Stack direction="row" spacing={2} sx={{ ml: 8, mt: 1 }}>
                <Typography variant="p" component="p">
                  La:{" "}
                  <Typography variant="p" component="b" align="left">
                    12.9891583
                  </Typography>
                </Typography>
                <Typography variant="p" component="p">
                  Lo:{" "}
                  <Typography variant="p" component="b" align="left">
                    77.508009
                  </Typography>
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card sx={{ borderRadius: "8px", p: 3, mt: 1 }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
                className="tabs-content-parent"
              >
                <Tab
                  label="Questions"
                  className="active-tabs Questions-tabs"
                  {...a11yProps(0)}
                />
                <Tab
                  label="User / Outlet Image"
                  className="Archived-tabs Questions-tabs"
                  {...a11yProps(1)}
                />
                <Tab
                  label="Form KPI Score"
                  className="Archived-tabs Questions-tabs"
                  {...a11yProps(2)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <TableData />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableData />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableData />
              </TabPanel>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SurveyDetails;
