/** @format */

import { Grid, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IssueCountApi } from "../../../redux/actions/Issue";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { useNavigate } from "react-router-dom";

const ChartNumber = ({ startDate, endDate, loader }) => {
  const ListCount = useSelector(
    (state) =>
      state.issue &&
      state.issue.issueCount &&
      state.issue.issueCount.data &&
      state.issue.issueCount.data.data
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [countData, setCountData] = useState([]);

  useEffect(() => {
    setCountData(ListCount);
  }, [ListCount]);

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      listCount(startDate, endDate);
    }
  }, [startDate, endDate]);

  const listCount = (startDate, endDate) => {
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `IssueOverAll?company_id=${id}&issueStartTime=${startDate}&issueEndTime=${endDate}`,
    };
    dispatch(IssueCountApi(data));
  };

  const handleIssueNavigate = (status) => {
    navigate("/issues/list", { state: { startDate, endDate, status } });
    // localStorage.setItem("issuesScreenFilter", JSON.stringify({ startDate, endDate, status }))
  }

  return (
    <>
      <Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Card sx={{ p: 2, m: 1, borderRadius: "8px" }} onClick={() => handleIssueNavigate("")}>
              <Typography className="total-issue">Total Issues</Typography>
              <h1 style={{ color: "#27364B" }}>{countData?.TotalIssue}</h1>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            {/* {loader ? (
              <Progressbar />
            ) : ( */}
            <Card
              sx={{
                p: 2,
                m: 1,
                backgroundColor: "#D6F3E9",
                borderRadius: "8px",
              }}
              onClick={() => handleIssueNavigate("Resolved")}
            >
              <Typography className="total-issue">Resolved</Typography>
              <h1 style={{ color: "#039855" }}>{countData?.Resolved}</h1>
            </Card>
            {/* )} */}
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Card
              sx={{
                p: 2,
                m: 1,
                backgroundColor: "#FCF0DB",
                borderRadius: "8px",
              }}
              onClick={() => handleIssueNavigate("Open")}
            >
              <Typography className="total-issue">Open</Typography>
              <h1 style={{ color: "#F39A4C" }}>{countData?.Open}</h1>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Card
              sx={{
                p: 2,
                m: 1,
                backgroundColor: "#DCEDFC",
                borderRadius: "8px",
              }}
              onClick={() => handleIssueNavigate("In-progress")}
            >
              <Typography className="total-issue">In-progress</Typography>
              <h1 style={{ color: "#1465B4" }}>{countData?.Inprogress}</h1>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartNumber;
