/** @format */

import React, { useState, useEffect } from "react";
import { TablePagination, Typography, Box, IconButton } from "@mui/material";
import TableHeader from "./pages/Header";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ProgressListApi,
  ResetListSuccess,
} from "../../redux/actions/LmsAction";
import * as moment from "moment";
import Progressbar from "../../GlobalProgress/Progressbar";
import { DataGrid } from "@mui/x-data-grid";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TPHeader from "./pages/TPHeader";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import InfoIcon from '@mui/icons-material/Info';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const AllTrainingReport = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [indexHover, setIndexHover] = useState(0);
  const [allTrainingData, setAllTrainingData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Delete: false,
  });

  const allTrainingList = useSelector(
    (state) =>
      state.lms &&
      state.lms.progressTraining &&
      state.lms.progressTraining.data &&
      state.lms.progressTraining.data.data
  );
  const totalpage = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.paginate
  );
  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );
  const updateReset = useSelector(
    (state) => state.lms && state.lms.resetData && state.lms.resetData.data
  );

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      archived: false,
    },
  };
  useEffect(() => {
    dispatch(ResetListSuccess(""));
  }, [updateReset]);

  useEffect(() => {
    localStorage.removeItem("trainingChapters");
    localStorage.removeItem("assesmentData");
    localStorage.removeItem("certificateData");
    localStorage.removeItem("editChapters");
    localStorage.removeItem("addTrainingData");
  }, []);

  useEffect(() => {
    if (totalpage) {
      setTotalCount(totalpage?.total_item);
    }
  }, [totalpage]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    // setLoader(true);
    setAllTrainingData(allTrainingList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [allTrainingList]);

  useEffect(() => {
    if (searchValue === "") {
      setSearchData("");
    }
  }, [searchValue]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setLoader(true);
      const value = e.target.value;
      handleSearch(value);
      setSearchData(value);
      setPage(1);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    // Filter the allTrainingList based on search value
    const searchedData = allTrainingList.filter((item) => {
      return item.name.includes(value) || item.role.includes(value);
    });
    setSearchList(searchedData);
  };

  useEffect(() => {
    AllTrainingList();
  }, []);

  const AllTrainingList = () => {
    let role = [];
    const id = localStorage.getItem("companyId");
    const payloadRequest = {
      url:
        BASE_URL + `ongoingTraining?Roles=${role.join(",")}&company_id=${id}`,
    };
    dispatch(ProgressListApi(payloadRequest));
  };

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    setPage(newPage + 1);
    paginationRequest.body.pagination.page = newPage + 1;
    AllTrainingList(paginationRequest);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
    paginationRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    paginationRequest.body.pagination.per_page = newRowsPerPage;
    AllTrainingList(paginationRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 0,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index = allTrainingData.indexOf(params.row) + 1;
        // const index =
        //   allTrainingData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        //   console.log({index, rowsPerPage, page, params: allTrainingData.indexOf(params.row)})
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Training",
      headerName: "Training",
      renderHeader: () => <strong>{"Training"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.name) {
          result.push(params?.row?.name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.name) {
          result.push(params?.row?.name);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <Typography variant="p" component="p" className="text-short3">
              {results}
            </Typography>
          </LightTooltip>
        );
      },
    },
    {
      field: "Sub Role",
      headerName: "Sub Role",
      renderHeader: () => <strong>{"Sub Role"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.role) {
          result.push(params?.row?.role);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.role) {
          result.push(params?.row?.role);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return <div>{results}</div>;
      },
    },
    // {
    //   field: "Created",
    //   headerName: "Created",
    //   renderHeader: () => <strong>{"Created"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.createdAt) {
    //       result.push(params?.row?.createdAt);
    //     } else {
    //       result = ["Unknown"];
    //     }
    //     return result.join(", ");
    //   },
    //   renderCell: (params) => {
    //     let result = [];
    //     if (params?.row?.createdAt) {
    //       result.push(params?.row?.createdAt);
    //     } else {
    //       result = ["N/A"];
    //     }
    //     let results = result.join(", ");
    //     return <div>{moment(results).format("lll")}</div>;
    //   },
    // },

    {
      field: "Time Period",
      headerName: "Time Period",
      renderHeader: () => <strong>{"Time Period"}</strong>,
      flex: 2, // Increase the flex value to allocate more width
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let startsAt = params?.row?.starts_at
          ? moment(params.row.starts_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "Unknown";
        let expiresAt = params?.row?.expires_at
          ? moment(params.row.expires_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "Unknown";
        return startsAt + " To " + expiresAt;
      },
      renderCell: (params) => {
        let startsAt = params?.row?.starts_at
          ? moment(params.row.starts_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "N/A";
        let expiresAt = params?.row?.expires_at
          ? moment(params.row.expires_at, "YYYYMMDD").format("MMMM D, YYYY")
          : "N/A";
        let results = startsAt + " to " + expiresAt;
        return <div>{results}</div>;
      },
    },

    {
      field: "Updated",
      headerName: "Updated",
      renderHeader: () => <strong>{"Updated"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          result.push(params?.row?.updatedAt);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          result.push(params?.row?.updatedAt);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return <div>{moment(results).format("MMMM D, YYYY")}</div>;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <Tooltip title="View">
            <img
              onClick={() => handleClick(params?.row)}
              src={
                hover.View && indexHover === params?.row?._id ? ViewHover : View
              }
              alt="VIEW"
              style={{
                height: "40px",
                cursor: "pointer",
              }}
              onMouseOver={() => {
                setHover({ View: true });
                setIndexHover(params?.row?._id);
              }}
              onMouseLeave={() => {
                setHover({ View: false });
              }}
            />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleClick = (row) => {
    navigate("/alltraining-report/training-report-details", { state: row });
  };

  return (
    <Box>
      <TPHeader
        handleKeyDown={handleKeyDown}
        searchOnChange={handleSearch}
        searchValue={searchValue}
        rowsPerPage={rowsPerPage}
        page={page}
        totalpage={totalpage}
        setPage={setPage}
        training={"trainingprogress"}
      />

      <SingleGlobalTable
      sx={{
        bgcolor: "#F6F8FC",
        height: "55px",
        mb:"0px",
      }}

        data={
          searchData !== "" && searchList?.length > 0
            ? searchList
            : allTrainingData
        }
        columns={columns}
        totalCount={
          searchData !== "" && searchList?.length > 0
            ? searchList.length
            : allTrainingData?.length
        }
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageSizeChange={(e) => handleChangeRowsPerPage(e)}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        pagination={true}
        pageSize={rowsPerPage}
        ActionsComponent={TablePaginationActions}
      />

      {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "calc(100vh - 220px)",
            mt: 1,
            p: 1,
            overflowX: "auto",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              margin: "5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          {searchData !== "" && searchList !== "" && searchList?.length > 0 ? (
            <DataGrid
              disableColumnMenu
              rows={searchList}
              columns={columns}
              getRowId={(allTrainingData) => Math.random()}
              pageSize={rowsPerPage}
              onPageSizeChange={(e) => handleChangeRowsPerPage(e)}
              pagination={true}
              onPageChange={(e) => handleChangePage(e)}
              rowsPerPageOptions={[5, 10, 20]}
              page={page - 1}
              getRowHeight={() => "auto"}
              hideFooterPagination={true}
              className="grid-table"
              hideFooter={true}
            />
          ) : (
            ""
          )}
          {searchData === "" &&
          allTrainingData &&
          allTrainingData?.length > 0 ? (
            <>
              <DataGrid
                disableColumnMenu
                rows={allTrainingData}
                columns={columns}
                getRowId={(allTrainingData) => allTrainingData._id}
                pageSize={rowsPerPage}
                pagination={true}
                onPageSizeChange={(e) => handleChangeRowsPerPage(e)}
                onPageChange={(e) => handleChangePage(e)}
                rowsPerPageOptions={[5, 10, 20]}
                page={page - 1}
                getRowHeight={() => "auto"}
                hideFooterPagination={true}
                className="grid-table"
                hideFooter={true}
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: allTrainingData.length },
                ]}
                count={allTrainingData.length}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            ""
          )}
        </Box>
      )} */}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default AllTrainingReport;
