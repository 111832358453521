import {
  CSV_NAM_SUBMISSION_USER_WISE_ERROR,
  CSV_NAM_SUBMISSION_USER_WISE_REQUEST,
  CSV_NAM_SUBMISSION_USER_WISE_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  CSVNamSubmissionUserWise: "",
};

const CsvNamSubmissionDataUserWiseReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case CSV_NAM_SUBMISSION_USER_WISE_REQUEST: {
      return {
        ...state,
        loading: true,
        CSVNamSubmissionUserWise: [],
        error: "",
      };
    }
    case CSV_NAM_SUBMISSION_USER_WISE_SUCCESS: {
      return {
        ...state,
        CSVNamSubmissionUserWise: action?.payload,
        loading: false,
      };
    }
    case CSV_NAM_SUBMISSION_USER_WISE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        CSVNamSubmissionUserWise: []
      };
    }
    default: {
      return state;
    }
  }
};

export default CsvNamSubmissionDataUserWiseReducer;
