/** @format */

import { Box, Divider, Stack, Typography, Grid } from "@mui/material";
import axios from "axios";
import { Button, Card, CardContent, Link } from "material-ui-core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuditorContext } from "../../../../context/auditorContext";
import { useContext } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import RepeatIcon from "@mui/icons-material/Repeat";
import { useSelector } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ForwardIcon from "@mui/icons-material/Forward";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",

    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 1 }}>
          {/* <Typography style={{color:"gray",textAlign: "left", marginLeft:"37px"}}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyAssessmentIndex = () => {
  const [assessmentType, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const navigate = useNavigate();
  let {
    setcompanyId,
    setstoreId,
    storeId,
    companyId,
    currentAssessment,
    setcurrentAssessment,
    selectedStore,
    storeTitle,
  } = useContext(AuditorContext);

  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const [assessments, setAssessments] = useState([]);
  const [failedAssessments, setFailedAssessments] = useState([]);
  const [passedAssessments, setPassedAssessments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [completedLoader, setCompletedLoader] = useState(false);
  const [remainingLoader, setRemainingLoader] = useState(false);
  const [failledLoader, setFailledLoader] = useState(false);
  const itemsPerPage = 10;

  const handleSelectedAssessment = (item) => {
    localStorage.setItem("selectedAssessment", JSON.stringify(item));
    setcurrentAssessment(item);
    navigate("/auditor-home/my-assessments/disclaimer-page", {
      // state: item?._id,
      state: item,
    });
  };

  const handleLinkClick = (trainingItemId) => {
    // navigate(`/auditor-home/my-assessments/disclaimer-page`, {
    //   state: trainingItemId,
    // });
  };

  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("selectedAssessment");
  }, []);

  useEffect(() => {
    console.log(currentAssessment, "currentAssessmentFromContext");
  }, [handleSelectedAssessment]);

  console.log(selectedStore, "selectedStore");

  useEffect(() => {
    if (storeId) {
      setRemainingLoader(false);
      const token = localStorage.getItem("token");
      const nottaken = "nottaken";
      const url = `${BASE_URL}getAssessment?status=${nottaken}&company_id=${companyId}&storeId=${storeId}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAssessments(response.data.data.assessments);
          setRemainingLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setRemainingLoader(false);
        });
    }
  }, [storeId]);

  // Completed Assessment
  useEffect(() => {
    if (storeId) {
      setCompletedLoader(false);
      const token = localStorage.getItem("token");
      const passed = "passed";
      const url = `${BASE_URL}getAssessment?status=${passed}&company_id=${companyId}&storeId=${storeId}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPassedAssessments(response.data.data.assessments);
          setCompletedLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setCompletedLoader(false);
        });
    }
  }, [storeId]);

  // Failed Assessment
  useEffect(() => {
    if (storeId) {
      setFailledLoader(true);
      const token = localStorage.getItem("token");
      const failed = "failed";
      const url = `${BASE_URL}getAssessment?status=${failed}&company_id=${companyId}&storeId=${storeId}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setFailedAssessments(response.data.data.assessments);
          setFailledLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setFailledLoader(false);
        });
    }
  }, [storeId]);
  return (
    <>
      {(completedLoader || failledLoader || remainingLoader) && (
        <ProgressbarTransparent play />
      )}
      {
        <Box sx={{ width: "100w" }}>
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              borderRadius: 1,
              overflowX: "auto",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: 1,
                  borderColor: "divider",
                  justifyContent: "space-between",
                }}
              >
                <Tabs
                  value={assessmentType}
                  onChange={handleChange}
                  textColor="#214d69"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "#ef6b3b",
                      color: "#214d69",
                      textColor: "#214d69",
                    },
                  }}
                >
                  <Tab label="Not Taken" {...a11yProps(0)} />
                  <Tab
                    style={{ marginLeft: ".5rem" }}
                    label="Certified"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ marginLeft: ".5rem" }}
                    label="Not Certified"
                    {...a11yProps(2)}
                  />
                </Tabs>

                <Tabs textColor="#214d69">
                  <Tab label={storeTitle} />
                </Tabs>
              </Box>
              <TabPanel value={assessmentType} index={0}>
                Not Taken
              </TabPanel>
              <TabPanel value={assessmentType} index={1}>
                Certified
              </TabPanel>
              <TabPanel value={assessmentType} index={2}>
                Not Certified
              </TabPanel>
            </Box>
            {/* New assessment */}

            <div className="container">
              <>
                <Box
                  sx={{
                    mt: 0,
                    ml: 5,
                    mr: 5,
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    gap: "2rem",
                  }}
                >
                  {assessmentType === 0 && (
                    <Typography
                      variant="h5"
                      component="div"
                      align="left"
                      style={{ fontWeight: 400 }}
                    >
                      {/* <div>Assessments Not Taken for {storeTitle}</div> */}
                    </Typography>
                  )}
                  {assessmentType === 0 && (
                    <Grid container spacing={5}>
                      {assessments
                        ?.slice(
                          (currentPage - 1) * itemsPerPage,
                          currentPage * itemsPerPage
                        )
                        .map((trainingItem, index) => (
                          <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                            <div className="contentAssessment">
                              <Card
                                variant="outlined"
                                sx={{
                                  mt: 0,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleSelectedAssessment(trainingItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "170px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#ef6b3b",
                                  }}
                                >
                                  {trainingItem.training.title}
                                  {trainingItem?.training?.repeated && (
                                    <span>
                                      {"\u00A0"}(
                                      <RepeatIcon
                                        style={{ fontSize: "14px" }}
                                      />
                                      ){" "}
                                    </span>
                                  )}
                                </div>
                                <CardContent sx={{ width: "500px" }}>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(trainingItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      {trainingItem.title}
                                    </Link>
                                  </Typography>
                                  <Divider sx={{ mt: 1 }} />

                                  {/* <div>{trainingItem.training.title}</div> */}

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{ pt: 2 }}
                                  >
                                    <span>
                                      <strong>Summary: </strong>
                                      <Tooltip
                                        title={trainingItem.summary}
                                        placement="top"
                                      >
                                        <span>
                                          {trainingItem.summary.length > 47
                                            ? `${trainingItem.summary.substring(
                                                0,
                                                47
                                              )}...`
                                            : trainingItem.summary}
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </Typography>

                                  {/* <Typography
                            align="left"
                            variant="body1"
                            
                            sx={{ mt: 0 }}
                          >
                            <span>
                              <strong>Linked Training: </strong>{trainingItem.training.title}
                            </span>
                          </Typography> */}

                                  <Stack direction={"row"} spacing={0} mt={1}>
                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0 }}
                                    >
                                      <div>
                                        {trainingItem?.duration ? (
                                          <div
                                            style={{
                                              marginLeft: "1px",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>
                                              <AccessAlarmIcon
                                                style={{ color: "#f55105" }}
                                              />
                                            </span>
                                            <span
                                              style={{
                                                paddingBottom: 5,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              {Math.round(
                                                trainingItem?.duration / 60
                                              )}{" "}
                                              Mins
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              marginLeft: "1%",
                                              alignItems: "center",
                                            }}
                                          >
                                            {trainingItem?.duration ? (
                                              <div>
                                                <span>
                                                  <AccessAlarmIcon
                                                    style={{ color: "#f55105" }}
                                                  />{" "}
                                                </span>
                                                <span>
                                                  {Math.round(
                                                    trainingItem?.duration / 60
                                                  )}
                                                </span>
                                              </div>
                                            ) : (
                                              <span>
                                                <AccessAlarmIcon
                                                  style={{ color: "#f55105" }}
                                                />
                                                N/A
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Typography>

                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{
                                        mt: 0,
                                        pl: 2,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {trainingItem?.createdAt ? (
                                        <React.Fragment>
                                          <span
                                            style={{
                                              paddingBottom: 4,
                                              paddingLeft: 2,
                                            }}
                                          >
                                            <AccessTimeSharpIcon
                                              style={{ color: "#f55105" }}
                                            />{" "}
                                          </span>
                                          {moment(
                                            trainingItem?.createdAt
                                          ).format("YYYY/MM/DD HH:mm:ss") || (
                                            <span
                                              style={{
                                                paddingBottom: 4,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              <AccessTimeSharpIcon
                                                style={{ color: "#f55105" }}
                                              />{" "}
                                              N/A
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        // <React.Fragment>
                                        //   <span>
                                        //     <AccessTimeSharpIcon
                                        //       style={{ color: "#f55105" }}
                                        //     />{" "}
                                        //   </span>
                                        //   {(() => {
                                        //     const now = new Date();
                                        //     const updatedAt = new Date(
                                        //       trainingItem.updatedAt
                                        //     );
                                        //     const diffInMs = now - updatedAt;

                                        //     const seconds = Math.floor(
                                        //       diffInMs / 1000
                                        //     );
                                        //     const minutes = Math.floor(
                                        //       seconds / 60
                                        //     );
                                        //     const hours = Math.floor(
                                        //       minutes / 60
                                        //     );
                                        //     const days = Math.floor(hours / 24);
                                        //     const months = Math.floor(
                                        //       days / 30
                                        //     );
                                        //     const years = Math.floor(
                                        //       months / 12
                                        //     );

                                        //     if (years > 0) {
                                        //       return (
                                        //         <span
                                        //           style={{
                                        //             paddingBottom: 5,
                                        //             paddingLeft: 2,
                                        //           }}
                                        //         >
                                        //           {years} year(s) ago
                                        //         </span>
                                        //       );
                                        //     } else if (months > 0) {
                                        //       return (
                                        //         <span
                                        //           style={{
                                        //             paddingBottom: 5,
                                        //             paddingLeft: 2,
                                        //           }}
                                        //         >
                                        //           {months} month(s) ago
                                        //         </span>
                                        //       );
                                        //     } else if (days > 0) {
                                        //       return (
                                        //         <span
                                        //           style={{
                                        //             paddingBottom: 5,
                                        //             paddingLeft: 2,
                                        //           }}
                                        //         >
                                        //           {days} day(s) ago
                                        //         </span>
                                        //       );
                                        //     } else {
                                        //       return (
                                        //         <span
                                        //           style={{
                                        //             paddingBottom: 5,
                                        //             paddingLeft: 2,
                                        //           }}
                                        //         >
                                        //           {hours} hour(s) ago
                                        //         </span>
                                        //       );
                                        //     }
                                        //   })()}
                                        // </React.Fragment>
                                        <span>
                                          <AccessTimeSharpIcon
                                            style={{ color: "#f55105" }}
                                          />{" "}
                                          N/A
                                        </span>
                                      )}
                                    </Typography>
                                  </Stack>
                                </CardContent>
                              </Card>
                            </div>
                          </Grid>
                        ))}
                      {assessments && assessments.length === 0 && (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                            marginLeft: "37px",
                          }}
                        >
                          No new assessment available. Please check later...
                        </Typography>
                      )}
                    </Grid>
                  )}

                  {/* Pagination for new assessment #ef6b3b */}
                  {assessmentType === 0 && (
                    <div className="pagination">
                      <div
                        style={{
                          textAlign: "center",
                          background: "#214d69",
                          color: "white",
                          borderRadius: "0px",
                          marginTop: "",
                        }}
                      >
                        <Button
                          disabled={currentPage === 1}
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          <span style={{ color: "white" }}>Previous Page</span>
                        </Button>
                        <span style={{ margin: "0 10px", fontSize: "14px" }}>
                          Page {currentPage} of{" "}
                          {Math.ceil(assessments.length / itemsPerPage)}
                        </span>
                        <Button
                          disabled={
                            currentPage ===
                            Math.ceil(assessments.length / itemsPerPage)
                          }
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          <span style={{ color: "white" }}>Next Page</span>
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* { assessmentType === 0 &&(
              <Divider sx={{ mt: 1 }} />
              )} */}

                  {/* Passed Assessment  */}
                  {assessmentType === 1 && (
                    <>
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      >
                        {/* <div>Passed Assessments for {storeTitle}</div> */}
                      </Typography>
                      <Grid container spacing={5}>
                        {passedAssessments
                          ?.slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .map((trainingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <div className="contentAssessment">
                                <Card
                                  variant="outlined"
                                  sx={{
                                    mt: 2,
                                    mr: 3,
                                    borderRadius: 4,
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                    mb: 5,
                                  }}
                                  // onClick={() => handleSelectedAssessment(trainingItem)}
                                  style={{
                                    cursor: "pointer",
                                    height: "170px",
                                    borderRadius: "40px",
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px",
                                      borderRadius: "0px",
                                      color: "white",
                                      backgroundColor: "#ef6b3b",
                                    }}
                                  >
                                    {trainingItem.training.title}
                                    {trainingItem?.training?.repeated && (
                                      <span>
                                        {"\u00A0"}(
                                        <RepeatIcon
                                          style={{ fontSize: "14px" }}
                                        />
                                        ){" "}
                                      </span>
                                    )}
                                  </div>
                                  <CardContent sx={{ width: "500px" }}>
                                    <Typography
                                      variant="h6"
                                      component="div"
                                      align="left"
                                    >
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(trainingItem._id)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "#214d69",
                                        }}
                                      >
                                        {trainingItem.title}
                                      </Link>
                                    </Typography>
                                    <Divider sx={{ mt: 1 }} />

                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ pt: 2 }}
                                    >
                                      <span>
                                        <strong>Summary: </strong>
                                        <Tooltip
                                          title={trainingItem.summary}
                                          placement="top"
                                        >
                                          <span>
                                            {trainingItem.summary.length > 47
                                              ? `${trainingItem.summary.substring(
                                                  0,
                                                  47
                                                )}...`
                                              : trainingItem.summary}
                                          </span>
                                        </Tooltip>
                                      </span>
                                    </Typography>

                                    {/* <Typography
                              align="left"
                              variant="body1"
                            
                              sx={{ mt: 0 }}
                            >
                              <span>
                                <strong>Linked Training: </strong>{trainingItem.training.title}
                                        trainingItem?.training?.repeated && <span>{'\u00A0'}(<RepeatIcon style={{fontSize: '14px'}}/>) </span>

                              </span>
                            </Typography> */}

                                    <Stack direction={"row"} spacing={0} mt={1}>
                                      <Typography
                                        align="left"
                                        variant="body1"
                                        sx={{ mt: 0 }}
                                      >
                                        {trainingItem?.duration ? (
                                          <div
                                            style={{
                                              marginLeft: "1px",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>
                                              <AccessAlarmIcon
                                                style={{ color: "#f55105" }}
                                              />
                                            </span>
                                            <snap
                                              style={{
                                                paddingBottom: 5,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              {Math.round(
                                                trainingItem?.duration / 60
                                              )}{" "}
                                              Mins
                                            </snap>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              marginLeft: "1%",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {trainingItem?.duration ? (
                                              <span>
                                                <AccessAlarmIcon
                                                  style={{ color: "#f55105" }}
                                                />{" "}
                                                {trainingItem.duration}
                                              </span>
                                            ) : (
                                              <span>
                                                <AccessAlarmIcon
                                                  style={{ color: "#f55105" }}
                                                />
                                                N/A
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Typography>

                                      <Typography
                                        align="left"
                                        variant="body1"
                                        sx={{
                                          mt: 0,
                                          pl: 2,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {trainingItem?.createdAt ? (
                                          <React.Fragment>
                                            <span
                                              style={{
                                                paddingBottom: 4,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              <AccessTimeSharpIcon
                                                style={{ color: "#f55105" }}
                                              />{" "}
                                            </span>
                                            {moment(
                                              trainingItem?.createdAt
                                            ).format("YYYY/MM/DD HH:mm:ss") || (
                                              <span
                                                style={{
                                                  paddingBottom: 4,
                                                  paddingLeft: 2,
                                                }}
                                              >
                                                <AccessTimeSharpIcon
                                                  style={{ color: "#f55105" }}
                                                />{" "}
                                                N/A
                                              </span>
                                            )}
                                          </React.Fragment>
                                        ) : (
                                          // <React.Fragment>
                                          //   <span>
                                          //     <AccessTimeSharpIcon
                                          //       style={{ color: "#f55105" }}
                                          //     />{" "}
                                          //   </span>
                                          //   {(() => {
                                          //     const now = new Date();
                                          //     const updatedAt = new Date(
                                          //       trainingItem.updatedAt
                                          //     );
                                          //     const diffInMs = now - updatedAt;

                                          //     const seconds = Math.floor(
                                          //       diffInMs / 1000
                                          //     );
                                          //     const minutes = Math.floor(
                                          //       seconds / 60
                                          //     );
                                          //     const hours = Math.floor(
                                          //       minutes / 60
                                          //     );
                                          //     const days = Math.floor(
                                          //       hours / 24
                                          //     );
                                          //     const months = Math.floor(
                                          //       days / 30
                                          //     );
                                          //     const years = Math.floor(
                                          //       months / 12
                                          //     );

                                          //     if (years > 0) {
                                          //       return (
                                          //         <span
                                          //           style={{
                                          //             paddingBottom: 5,
                                          //             paddingLeft: 2,
                                          //           }}
                                          //         >
                                          //           {years} year(s) ago
                                          //         </span>
                                          //       );
                                          //     } else if (months > 0) {
                                          //       return (
                                          //         <span
                                          //           style={{
                                          //             paddingBottom: 5,
                                          //             paddingLeft: 2,
                                          //           }}
                                          //         >
                                          //           {months} month(s) ago
                                          //         </span>
                                          //       );
                                          //     } else if (days > 0) {
                                          //       return (
                                          //         <span
                                          //           style={{
                                          //             paddingBottom: 5,
                                          //             paddingLeft: 2,
                                          //           }}
                                          //         >
                                          //           {days} day(s) ago
                                          //         </span>
                                          //       );
                                          //     } else {
                                          //       return (
                                          //         <span
                                          //           style={{
                                          //             paddingBottom: 5,
                                          //             paddingLeft: 2,
                                          //           }}
                                          //         >
                                          //           {hours} hour(s) ago
                                          //         </span>
                                          //       );
                                          //     }
                                          //   })()}
                                          // </React.Fragment>
                                          <span>
                                            <AccessTimeSharpIcon
                                              style={{ color: "#f55105" }}
                                            />{" "}
                                            N/A
                                          </span>
                                        )}
                                      </Typography>
                                    </Stack>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          ))}
                        {passedAssessments &&
                          passedAssessments.length === 0 && (
                            <Typography
                              align="center"
                              style={{
                                color: "#888",
                                fontWeight: 300,
                                marginTop: "20px",
                                marginLeft: "37px",
                              }}
                            >
                              No passed assessments available, please check
                              later...
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  {/* Pagination for passed assessment  */}
                  {assessmentType === 1 && (
                    <div className="pagination">
                      <div
                        style={{
                          textAlign: "center",
                          background: "#214d69",
                          color: "white",
                          borderRadius: "0px",
                          marginTop: "",
                        }}
                      >
                        <Button
                          disabled={currentPage === 1}
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          <span style={{ color: "white" }}>Previous Page</span>
                        </Button>
                        <span style={{ margin: "0 10px", fontSize: "14px" }}>
                          Page {currentPage} of{" "}
                          {Math.ceil(passedAssessments.length / itemsPerPage)}
                        </span>
                        <Button
                          disabled={
                            currentPage ===
                            Math.ceil(passedAssessments.length / itemsPerPage)
                          }
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          <span style={{ color: "white" }}>Next Page</span>
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* { assessmentType === 1 &&(
              <Divider sx={{ mt: 1 }} />
              )} */}

                  {/* Failed Assessment  */}

                  {assessmentType === 2 && (
                    <>
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      >
                        {/* <div>Failed Assessments for {storeTitle}</div> */}
                      </Typography>
                      <Grid container spacing={5}>
                        {/* {failedAssessments?.map((trainingItem, index) => ( */}
                        {failedAssessments
                          ?.slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .map((trainingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <div className="contentAssessment">
                                <Card
                                  variant="outlined"
                                  sx={{
                                    mt: 2,
                                    mr: 3,
                                    borderRadius: 4,
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                    mb: 5,
                                  }}
                                  // onClick={() => handleSelectedAssessment(trainingItem)}
                                  style={{
                                    cursor: "pointer",
                                    height: "170px",
                                    borderRadius: "40px",
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px",
                                      borderRadius: "0px",
                                      color: "white",
                                      backgroundColor: "#ef6b3b",
                                    }}
                                  >
                                    {trainingItem.training.title}
                                    {trainingItem?.training?.repeated && (
                                      <span>
                                        {"\u00A0"}(
                                        <RepeatIcon
                                          style={{ fontSize: "14px" }}
                                        />
                                        ){" "}
                                      </span>
                                    )}
                                  </div>
                                  <CardContent sx={{ width: "500px" }}>
                                    <Typography
                                      variant="h6"
                                      component="div"
                                      align="left"
                                    >
                                      <Link
                                        onClick={() =>
                                          handleLinkClick(trainingItem._id)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "#214d69",
                                        }}
                                      >
                                        {trainingItem.title}
                                        {/* #ef6b3b */}
                                      </Link>
                                    </Typography>
                                    <Divider sx={{ mt: 1 }} />

                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ pt: 2 }}
                                    >
                                      <span>
                                        <strong>Summary: </strong>
                                        <Tooltip
                                          title={trainingItem.summary}
                                          placement="top"
                                        >
                                          <span>
                                            {trainingItem.summary.length > 47
                                              ? `${trainingItem.summary.substring(
                                                  0,
                                                  47
                                                )}...`
                                              : trainingItem.summary}
                                          </span>
                                        </Tooltip>
                                      </span>
                                    </Typography>

                                    {/* <Typography
                              align="left"
                              variant="body1"
                            
                              sx={{ mt: 0 }}
                            >
                              <span>
                                <strong>Linked Training: </strong>{trainingItem.training.title}
                              </span>
                            </Typography> */}

                                    <Stack direction={"row"} spacing={0} mt={1}>
                                      <Typography
                                        align="left"
                                        variant="body1"
                                        sx={{ mt: 0 }}
                                      >
                                        {trainingItem?.duration ? (
                                          <div
                                            style={{
                                              marginLeft: "1px",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>
                                              <AccessAlarmIcon
                                                style={{ color: "#f55105" }}
                                              />
                                            </span>
                                            <span
                                              style={{
                                                paddingBottom: 5,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              {Math.round(
                                                trainingItem?.duration / 60
                                              )}{" "}
                                              Mins
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              marginLeft: "1%",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {trainingItem?.duration ? (
                                              <span>
                                                <AccessAlarmIcon
                                                  style={{ color: "#f55105" }}
                                                />{" "}
                                                {trainingItem.duration}
                                              </span>
                                            ) : (
                                              <span>
                                                <AccessAlarmIcon
                                                  style={{ color: "#f55105" }}
                                                />
                                                N/A
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Typography>

                                      <Typography
                                        align="left"
                                        variant="body1"
                                        sx={{
                                          mt: 0,
                                          pl: 2,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {trainingItem?.createdAt ? (
                                          <React.Fragment>
                                            <span
                                              style={{
                                                paddingBottom: 4,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              <AccessTimeSharpIcon
                                                style={{ color: "#f55105" }}
                                              />{" "}
                                            </span>
                                            {moment(
                                              trainingItem?.createdAt
                                            ).format("YYYY/MM/DD HH:mm:ss") || (
                                              <span
                                                style={{
                                                  paddingBottom: 4,
                                                  paddingLeft: 2,
                                                }}
                                              >
                                                <AccessTimeSharpIcon
                                                  style={{ color: "#f55105" }}
                                                />{" "}
                                                N/A
                                              </span>
                                            )}
                                          </React.Fragment>
                                        ) : (
                                          // <React.Fragment>
                                          //   <span>
                                          //   <AccessTimeSharpIcon style={{ color: '#f55105' }} />{" "}
                                          //   </span>
                                          //   {(() => {
                                          //     const now = new Date();
                                          //     const updatedAt = new Date(
                                          //       trainingItem.updatedAt
                                          //     );
                                          //     const diffInMs = now - updatedAt;

                                          //     const seconds = Math.floor(diffInMs / 1000);
                                          //     const minutes = Math.floor(seconds / 60);
                                          //     const hours = Math.floor(minutes / 60);
                                          //     const days = Math.floor(hours / 24);
                                          //     const months = Math.floor(days / 30);
                                          //     const years = Math.floor(months / 12);

                                          //     if (years > 0) {
                                          //       return <span style={{paddingBottom:5, paddingLeft:2}}>{years} year(s) ago</span>;
                                          //     } else if (months > 0) {
                                          //       return <span style={{paddingBottom:5, paddingLeft:2}}>{months} month(s) ago</span>;
                                          //     } else if (days > 0) {
                                          //       return <span style={{paddingBottom:5, paddingLeft:2}}>{days} day(s) ago</span>;
                                          //     } else {
                                          //       return <span style={{paddingBottom:5, paddingLeft:2}}>{hours} hour(s) ago</span>;
                                          //     }
                                          //   })()}
                                          // </React.Fragment>
                                          <span>
                                            <AccessTimeSharpIcon
                                              style={{ color: "#f55105" }}
                                            />{" "}
                                            N/A
                                          </span>
                                        )}
                                      </Typography>
                                    </Stack>
                                  </CardContent>
                                </Card>
                              </div>
                            </Grid>
                          ))}
                        {failedAssessments &&
                          failedAssessments.length === 0 && (
                            <Typography
                              align="center"
                              style={{
                                color: "#888",
                                fontWeight: 300,
                                marginTop: "20px",
                                marginLeft: "37px",
                              }}
                            >
                              No failed assessments available, please check
                              later...
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                </Box>
                <span>
                  {assessmentType === 2 && (
                    <div className="pagination">
                      <div
                        style={{
                          textAlign: "center",
                          background: "#214d69",
                          color: "white",
                          borderRadius: "0px",
                          marginTop: "",
                        }}
                      >
                        <Button
                          disabled={currentPage === 1}
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          <span style={{ color: "white" }}>Previous Page</span>
                        </Button>
                        <span style={{ margin: "0 10px", fontSize: "14px" }}>
                          Page {currentPage} of{" "}
                          {Math.ceil(failedAssessments.length / itemsPerPage)}
                        </span>
                        <Button
                          disabled={
                            currentPage ===
                            Math.ceil(failedAssessments.length / itemsPerPage)
                          }
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          <span style={{ color: "white" }}>Next Page</span>
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* { assessmentType === 2 &&(
               <Divider sx={{ mt: 1 }} />
               )} */}
                </span>
              </>

              {/* pagination failed  */}

              {/* footer area started here */}
              {assessmentType !== "" && (
                <div className="footer">
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    style={{
                      marginTop: "0%",
                    }}
                  >
                    Copyright ©{" "}
                    <Link
                      href="https://www.hoperesearchgroup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      HOPE RESEARCH GROUP
                    </Link>
                    . All rights reserved.
                  </Typography>
                </div>
              )}
              {/* footer area ended here  */}
            </div>
          </Box>
        </Box>
      }
    </>
  );
};

export default MyAssessmentIndex;
