import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Select, MenuItem, Stack } from "@mui/material";
import { Container, Box, Chip } from "@mui/material";
import { FormControl } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyTypeApi,
  CreateCompanyApi,
} from "../redux/actions/CreateCompany";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";

const CreateCompany = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [companyTypeValues, setCompanyTypeValues] = useState([]);
  const companyname = location.state;

  const companyTypes = useSelector(
    (state) => state && state.company && state.company.data
  );
  const createCompanyRes = useSelector(
    (state) =>
      state && state.company && state.company.res && state.company.res.status
  );

  useEffect(() => {
    setCompanyTypeValues(companyTypes);
  }, [companyTypes]);

  useEffect(() => {
    if (createCompanyRes === 200) {
      navigate("/company-list");
    }
    // eslint-disable-next-line
  }, [createCompanyRes]);

  useEffect(() => {
    typeList()
  }, [dispatch]);

  const validationSchema = Yup.object({
    select_type: Yup.string("Select Type").required("Select Type"),
    select_image: Yup.string("Select image type").required("Select image type"),
  });

  const formik = useFormik({
    initialValues: {
      select_image: "",
      select_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        name: companyname,
        type: values?.select_type,
        image_category: values?.select_image,
      };
      const allCompanyData = {
        body: formData,
      }
      if (allCompanyData) {
        allCompanyData.url = BASE_URL + `companies`
        dispatch(CreateCompanyApi(allCompanyData));
      }
    },
  });


  const typeList = () => {
    const data = { url: BASE_URL + `companies/types` }
    dispatch(CompanyTypeApi(data));
  }

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <Container
        maxWidth="xl"
        sx={{ bgcolor: "#F6F8FC", p: 3, pb: 10, borderRadius: 2 }}
      >
        <Card
          sx={{ minWidth: 275, textAlign: "start", p: 2, pb: 8 }}
          className="card"
        >
          <Link to={"/company"} className="submenu-item">
            <Chip
              icon={<KeyboardBackspaceIcon />}
              sx={{ py: 2 }}
              label="Back"
              variant="outlined"
            />
          </Link>
          <CardContent
            sx={{ justifyItems: "self-end", display: "grid", p: 12, pl: 8 }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Stack direction="column">
                <FormControl
                  sx={{ mb: 2, width: "250px", minWidth: 120 }}
                  size="small"
                >
                  <label style={{ fontWeight: "bolder", marginBottom: "20px" }}>
                    What does your Company do?
                  </label>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    name="select_type"
                    value={formik.values.select_type}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.select_type &&
                      Boolean(formik.errors.select_type)
                    }
                    helperText={
                      formik.touched.select_type && formik.errors.select_type
                    }
                  >
                    <MenuItem disabled value="">
                      Type
                    </MenuItem>
                    {companyTypeValues?.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option.title}>
                          {option.title ?? option.id}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ mb: 2, width: "250px" }}>
                  <label style={{ fontWeight: "bolder", marginBottom: "20px" }}>
                    Select Image Category
                  </label>
                  <Select
                    size="small"
                    labelId="demo-select-small"
                    id="demo-select-small"
                    name="select_image"
                    value={formik.values.select_image}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.select_image &&
                      Boolean(formik.errors.select_image)
                    }
                    helperText={
                      formik.touched.select_image && formik.errors.select_image
                    }
                  >
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="camera">Photo</MenuItem>
                    <MenuItem value="gallery">Gallery</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <CardActions>
                {/* <Link to="/company-list" className="submenu-item"> */}
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  className="company-create-btn"
                >
                  CREATE
                </Button>
                {/* </Link> */}
              </CardActions>
            </form>
          </CardContent>
        </Card>

        <Card
          sx={{
            minWidth: 275,
            mt: 3,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="card"
        >
          <p
            style={{
              color: "#858796",
              padding: "10px 20px",
              fontWeight: "600",
            }}
          >
            Already have Companies
          </p>
          <Link to={"/company-list"} className="submenu-item">
            <Button size="small" variant="contained" className="company-btn">
              <KeyboardBackspaceIcon sx={{ px: 1, fontSize: "35px" }} />
              My Companies
            </Button>
          </Link>
        </Card>
      </Container>
    </Box>
  );
};

export default CreateCompany;
