import React from 'react'
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Stack, Avatar } from '@mui/material';
import User from '../../../../assets/icons/svg-icons/User.svg';

function createData(Store, Number, Channel) {
    return { Store, Number, Channel };
}

const rows = [
    createData('Alisign', '1234567890', 'Panipat', '190'),
    createData('Alisign', '1234567890', 'Panipat', '190'),
    createData('Alisign', '1234567890', 'Panipat', '190'),
    createData('Alisign', '1234567890', 'Panipat', '190'),
    createData('Alisign', '1234567890', 'Panipat', '190'),
    createData('Alisign', '1234567890', 'Panipat', '190'),
];

const UpcomingBirthday = () => {
    return (
        <Card className="card">
            <CardContent>
                <Typography variant="p" component="h2" className='top-heading'>Upcoming Birthday</Typography>
                <TableContainer component={Paper} className="card table-scroll">
                    <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell align="left">
                                        <Stack direction="row" spacing={2}>
                                            <Avatar className='message-avatar'><img src={User} alt="avatar" /></Avatar>
                                            <Stack direction="column">
                                                <Typography variant="p" component="b">{row.Store}</Typography>
                                                <Typography variant="p" component="p">{row.Number}</Typography>
                                            </Stack>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">{row.Channel}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

export default UpcomingBirthday