/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Stack,
  Grid,
  Divider,
  Link,
  Button,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { TraningByUserApi } from "../../../redux/actions/LmsAction";
import { TraningByStoreApi } from "../../../redux/actions/LmsAction";
import { LmsRecentCourseApi } from "../../../redux/actions/LmsAction";
import axios from "axios";

const CoursesUser = ({ value, trainings, subRole }) => {
  const dispatch = useDispatch();

  const [traningByUser, setTraningByUser] = useState([]);
  const [traningByStore, setTraningByStore] = useState([]);
  const [recentCourse, setRecentCourse] = useState([]);
  const [trainingsId, setTrainingsId] = useState([]);
  useEffect(() => {
    const newIdvalue = trainings.map((item, i) => {
      return item?._id;
    });
    setTrainingsId(newIdvalue);
  }, [trainings]);

  const trainingByUserData = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.traningByUserData &&
      state?.lms?.traningByUserData?.data &&
      state?.lms?.traningByUserData?.data?.data
  );

  const trainingByStoreData = useSelector(
    (state) =>
      state &&
      state?.lms?.traningByStoreData &&
      state?.lms?.traningByStoreData?.data &&
      state?.lms?.traningByStoreData?.data?.data
  );

  const recentCourseData = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.lmsRecentCourseData?.data &&
      state?.lms?.lmsRecentCourseData?.data?.data
  );
  const traningByUSerPayload = {
    body: {
      TrainingId: trainingsId,
    },
  };
  const traningByStorePayload = {
    body: {
      TrainingId: trainingsId,
    },
  };
  const recentCoursePayload = {
    body: {
      TrainingIds: trainingsId,
    },
  };

  useEffect(() => {
    if (trainingByUserData || trainingByStoreData || recentCourseData) {
      setTraningByUser(trainingByUserData);
      setTraningByStore(trainingByStoreData);
      setRecentCourse(recentCourseData);
    }
  }, [trainingByUserData, trainingByStoreData, recentCourseData]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    traningByUSerPayload.url = BASE_URL + `TrainingbyUser?company_id=${id}&subRole=[${subRole}]`;
    dispatch(TraningByUserApi(traningByUSerPayload));

    traningByStorePayload.url = BASE_URL + `TrainingbyStore?company_id=${id}&subRole=[${subRole}]`;
    dispatch(TraningByStoreApi(traningByStorePayload));
  }, [trainingsId, subRole]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    recentCoursePayload.url =
      BASE_URL + `training/indexRecent?company_id=${id}&subRole=[${subRole}]`;
    dispatch(LmsRecentCourseApi(recentCoursePayload));
  }, [trainingsId, subRole]);

  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
      <Grid item xs={12} md={8} sm={12} lg={8}>
        <Card sx={{ px: 3 }} className="card">
          <CardContent>
            <Typography
              variant="p"
              component="div"
              className="Lms-Overview-heading"
            >
              Recent submissions
            </Typography>
            <Stack
              direction="column"
              spacing={1}
              sx={{ mt: 2, pr: 5 }}
              className="course-area"
            >
              {recentCourse && recentCourse?.length > 0
                ? recentCourse?.map((item, index) => {
                    return (
                      <>
                        <Typography variant="p" component="b">
                          <Typography
                            variant="p"
                            component="b"
                            className="courses-data"
                          >
                            {item?.user?.name}
                            {/* <ChevronRightIcon /> */}
                          </Typography>
                        </Typography>
                        <Divider variant="inset" />
                      </>
                    );
                  })
                : "No submissions"}
            </Stack>
            <Link href="/training" className="message-link-4">
              View all
            </Link>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} sm={12} lg={4}>
        <Card sx={{ px: 5, p: 1, height: "48%" }} className="card">
          <CardContent>
            <Typography
              variant="p"
              component="div"
              className="Lms-Overview-heading"
            >
              LMS completion status
            </Typography>
            {traningByStore &&
              traningByStore?.length > 0 &&
              traningByStore?.map((storeData, index) => {
                return (
                  <div>
                    <Typography
                      variant="p"
                      component="h1"
                      sx={{
                        textAlign: "center",
                        color: "#64748B",
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Store name: {storeData?.Store}
                    </Typography>
                    <Typography
                      variant="p"
                      component="h1"
                      sx={{
                        textAlign: "center",
                        color: "#64748B",
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Completion status: {storeData?.Submitted}
                    </Typography>
                  </div>
                );
              })}
          </CardContent>
        </Card>
        <Card sx={{ px: 5, mt: 2, p: 1, height: "47%" }} className="card">
          {/* <CardContent>
            <Typography
              variant="p"
              component="div"
              className="Lms-Overview-heading"
            >
              Most Completed by User
            </Typography>

            {traningByUser &&
              traningByUser?.length > 0 &&
              traningByUser?.map((item, index) => {
                return (
                  <>
                    <Typography
                      variant="p"
                      component="h1"
                      sx={{
                        pt: 1,
                        textAlign: "center",
                        color: "#64748B",
                        fontSize: "24px",
                      }}
                    >
                      {item?.UserName} {item?.Submitted}
                    </Typography>
                  </>
                );
              })}
          </CardContent> */}
          <CardContent>
            <Typography
              variant="p"
              component="div"
              className="Lms-Overview-heading"
            >
              Most Completed by User
            </Typography>

            {traningByUser &&
              traningByUser?.length > 0 &&
              traningByUser?.map((item, index) => (
                <div key={index}>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#64748B",
                      fontSize: "14px",
                      fontWeight: "normal",
                      marginBottom: 1,
                    }}
                  >
                    User name: {item?.UserName}
                  </Typography>
                  <Typography
                    variant="p"
                    component="div"
                    sx={{
                      textAlign: "center",
                      color: "#64748B",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    Completion status: {item?.Submitted}
                  </Typography>
                </div>
              ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CoursesUser;
