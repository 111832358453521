/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tab,
  Avatar,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import userImage from "../../images/User Image.png";
import userImage1 from "../../images/User Image1.png";
import ChartSection from "./Chart";
import { Main } from "./Map";
import FormDataTable from "./FormDataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  ChecklkistApprovelApi,
  disapprovedFormApi,
  GenerateAuditPdfApi,
  SubmissionViewApi,
} from "../../redux/actions/Checklist";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import UserOutlet from "./UserOutlet";
import User from "../../../assets/icons/svg-icons/User.svg";
import Store from "../../../assets/icons/svg-icons/Store.svg";
import ChartBar from "./ChartBar";
import Geocode from "react-geocode";
import moment from "moment";
import Chart from "react-apexcharts";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { DialogTitle, Hidden, InputLabel } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import { confirmAlert } from "react-confirm-alert";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Select } from "material-ui-core";
import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import color from "material-ui-core/colors/amber";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { key: "checklist-approved", value: "Checklist Approved" },
  { key: "checklist-disapproved", value: "Checklist Disapproved" },
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewDetailsSubmission = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [buttonsvalues, setButtonValues] = useState(0);
  const [value, setValue] = useState(0);
  const [storeLocation, setStoreLocation] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [detailedData, setDetailedData] = React.useState();
  const [disapprovedForms, setDisapprovedForms] = React.useState();
  const [downloadData, setDownloadData] = React.useState();
  const [pdfBtnClick, setPdfBtnClick] = React.useState(false);
  const frequency = detailedData?.assignment?.frequency;
  const [active, setActive] = useState(0);
  const [val, setVal] = useState(null);
  const [chartReady, setChartReady] = useState(false);
  const [downloadAuditPdfData, setdownloadAuditPdfData] = React.useState();
  const checklistId = location?.state?.id;
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedForms, setSelectedForms] = useState([]);
  const [description, setDescription] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [step, setStep] = useState(1);
  const handleFirstConfirm = () => {
    setStep(2);
  };
  const handleSecondConfirm = () => {
    handleConfirm();
    handleCloseDialog(false);
    setStep(1);
  };
  const handleCancel = () => {
    handleCloseDialog(false);
    setStep(1);
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === "Checklist Approved") {
      setConfirmationMessage("Are you sure you want to Approve it?");
    } else if (value === "Checklist Disapproved") {
      setConfirmationMessage("Are you sure you want to Disapprove it?");
    }
    setOpenDialog(true);
    setSelectedForms([]);
    setDescription("");
  };

  const handleConfirm = () => {
    let payload;
    if (selectedValue === "Checklist Disapproved") {
      payload = {
        approvalStatus: "disapproved",
        description,
        formData: selectedForms.map((formId) => {
          return detailedData?.forms.find((f) => f._id === formId);
        }),
      };
      payload.formData.map(item => {
        item.approvalStatus = payload.approvalStatus;
      })
    } else if (selectedValue === "Checklist Approved") {
      payload = {
        approvalStatus: "approved",
        description,
      };
    }

    if (payload) {
      ChecklistStatusApi(payload);
    }

    handleCloseDialog(false);
  };

  const handleCloseDialog = (confirm) => {
    if (confirm) {
      handleConfirm();
    } else {
      setOpenDialog(false);
      setSelectedValue("");
    }
  };

  const ChecklistStatusApi = (payload) => {
    const _id = localStorage.getItem("companyId");
    const submissionId = location?.state?.id;
    const data = {
      url: `${BASE_URL}approveDisapproveCheklist/${submissionId}/?company_id=${_id}`,
      body: JSON.stringify(payload),
    };
    dispatch(ChecklkistApprovelApi(data, submissionDetails));
  };

  useEffect(() => {
    setDescription("");
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue === "Checklist Disapproved") {
      setIsConfirmEnabled(
        selectedForms.length > 0 && description.trim() !== ""
      );
    } else {
      setIsConfirmEnabled(true);
    }
  }, [selectedForms, description, selectedValue]);

  const getChecklistDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionView &&
      state.checklist.submissionView.data &&
      state.checklist.submissionView.data.data
  );

  const disapprovedFormsList = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.disapprovedFormsList &&
      state.checklist.disapprovedFormsList.data &&
      state.checklist.disapprovedFormsList.data.data
  );

  const PDFLoader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  const getAuditPdfDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.downloadPdf &&
      state.checklist.downloadPdf.data &&
      state.checklist.downloadPdf.data.data
  );

  const handleGenerateAuditPDFClick = () => {
    confirmAlert({
      title: "Generate Audit PDF",
      message:
        "Audit PDF will replace submission details pdf. After downloading, it will be open in new tab for view. Do you want to continue ?",
      buttons: [
        {
          label: "Confirm",
          onClick: () => {
            getApiGeneratePdf();
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  useEffect(() => {
    if (detailedData?.storeLocation && detailedData?.userLocation) {
      Geocode.setApiKey("AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA");
      Geocode.setLanguage("en");
      Geocode.setRegion("es");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();

      const fetchGeocodeData = async () => {
        try {
          const storeResponse = await Geocode.fromLatLng(
            detailedData.storeLocation.lat,
            detailedData.storeLocation.lng
          );
          const storeAddress = storeResponse.results[0].formatted_address;
          setStoreLocation(storeAddress);

          const userResponse = await Geocode.fromLatLng(
            detailedData.userLocation.lat,
            detailedData.userLocation.lng
          );
          const userAddress = userResponse.results[0].formatted_address;
          setUserLocation(userAddress);
        } catch (error) {
          console.error(error);
        }
      };

      fetchGeocodeData();
    }
  }, [detailedData]);

  useEffect(() => {
    if (checklistId !== "" && checklistId !== undefined) {
      dispatch(SubmissionViewApi(checklistId));
    }
  }, [dispatch, checklistId]);

  useEffect(() => {
    if (getAuditPdfDetails && getAuditPdfDetails.pdfReport && pdfBtnClick) {
      const link = document.createElement("a");
      link.href = getAuditPdfDetails.pdfReport;
      link.target = "_blank";
      link.download = "AuditReport_" + Date.now() + ".pdf";
      link.click();
    }
  }, [getAuditPdfDetails, pdfBtnClick]);

  useEffect(() => {
    submissionDetails();
  }, []);

  useEffect(() => {
    if (detailedData?.assignment?._id) {
      disapprovedFormDetails();
    }
    setSelectedValue(detailedData?.approvalStatus === "approved" ? "Checklist Approved" : detailedData?.approvalStatus === "disapproved" ? "Checklist Disapproved" : "");
  }, [detailedData]);

  useEffect(() => {
    if (getChecklistDetails) {
      setDetailedData(getChecklistDetails);
    }
  }, [getChecklistDetails]);

  useEffect(() => {
    setDisapprovedForms(disapprovedFormsList);
  }, [disapprovedFormsList]);

  useEffect(() => {
    if (getChecklistDetails?.pdfReport) {
      setDownloadData(getChecklistDetails?.pdfReport);
    }
  }, [getChecklistDetails]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const getApiGeneratePdf = () => {
    const _id = localStorage.getItem("companyId");
    const submissionId = location?.state?.id;
    const data = {
      url:
        BASE_URL + `generatePdf?company_id=${_id}&submissionId=${submissionId}`,
    };
    dispatch(GenerateAuditPdfApi(data));
    setPdfBtnClick(true);
  };

  const submissionDetails = () => {
    const _id = localStorage.getItem("companyId");
    const checklistId = location?.state?.id;
    if (checklistId !== "" && checklistId !== undefined) {
      const data = {
        url: BASE_URL + `checklists/progress/${checklistId}?company_id=${_id}`,
      };
      dispatch(SubmissionViewApi(data));
    }
  };

  const disapprovedFormDetails = () => {
    const _id = localStorage.getItem("companyId");
    const checklistId = location?.state?.id;
    console.log("detailedData", detailedData);
    if (checklistId !== "" && checklistId !== undefined) {
      const data = {
        url: BASE_URL + `checklist-history-by-submission/${checklistId}?company_id=${_id}`,
      };
      dispatch(disapprovedFormApi(data));
    }
  };

  const HandleValues = (id) => {
    setButtonValues(id);
    setActive(id);
  };

  // Geocode.setApiKey("AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA");
  // Geocode.setLanguage("en");
  // Geocode.setRegion("es");
  // Geocode.setLocationType("ROOFTOP");
  // Geocode.enableDebug();
  // Geocode.fromLatLng(
  //   detailedData?.storeLocation?.lat,
  //   detailedData?.storeLocation?.lng
  // ).then(
  //   (response) => {
  //     const address = response.results[0].formatted_address;
  //     setStoreLocation(address);
  //   },
  //   (error) => {
  //     console.error(error);
  //   }
  // );
  // Geocode.fromLatLng(
  //   detailedData?.userLocation?.lat,
  //   detailedData?.userLocation?.lng
  // ).then(
  //   (response) => {
  //     const address = response.results[0].formatted_address;
  //     setUserLocation(address);
  //   },
  //   (error) => {
  //     console.error(error);
  //   }
  // );

  const lat2 =
    getChecklistDetails &&
    getChecklistDetails.storeLocation &&
    getChecklistDetails.storeLocation.lat;
  const lon2 =
    getChecklistDetails &&
    getChecklistDetails.storeLocation &&
    getChecklistDetails.storeLocation.lng;
  const lat1 =
    getChecklistDetails &&
    getChecklistDetails.userLocation &&
    getChecklistDetails.userLocation.lat;
  const lon1 =
    getChecklistDetails &&
    getChecklistDetails.userLocation &&
    getChecklistDetails.userLocation.lng;

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    if (!lat1 || !lon1 || !lat2 || !lon2) {
      return NaN;
    }
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = parseFloat(R * c).toFixed(3); // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
    setVal(distance);
  }, [[lat1, lon1, lat2, lon2]]);

  useEffect(() => {
    if (val !== null) {
      setChartReady(true);
    }
  }, [val]);

  const series = [val];
  const options = {
    colors: ["#F39A4C"],
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
          background: "none",
          position: "front",
        },
        track: {
          show: true,
          background: "#F39A4C",
          strokeWidth: "97%",
          opacity: 1,
          margin: 3,
        },
        dataLabels: {
          total: {
            show: true,
            label: "Meter",
            color: "#F39A4C",
            formatter: function (w) {
              return w.globals.seriesTotals
                .reduce((a, b) => {
                  return a + b;
                }, 0)
                .slice(2, 9);
            },
          },
          value: {
            show: true,
          },
        },
      },
    },
    labels: ["METER"],
    stroke: {
      lineCap: "round",
    },
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card1"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <FormControl
                sx={{ m: 1, width: 250, marginBottom: "3px" }}
              >
                <Select
                  labelId="demo-single-name-label"
                  id="demo-single-name"
                  value={selectedValue || ""}
                  onChange={handleChange}
                  input={<OutlinedInput label="Approved Or Disapproved" />}
                  displayEmpty
                  sx={{
                    background: "#196773",
                    color: "white",
                    '.MuiSelect-root ': {
                      borderRadius: '8px',
                      padding: "11px 0",
                      color: "#fff",
                    },
                    '.Mui-disabled': {
                      color: "#fff",
                      opacity: "0.6",
                      "-webkit-text-fill-color": "#fff !important",
                    },
                    '.MuiInputBase-root': {
                      borderRadius: '8px'
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent !important',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                      right: "10px",
                    }
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Select Forms Status";
                    }
                    return selected;
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  disabled={detailedData?.approvalStatus === "disapproved" || detailedData?.approvalStatus === "approved"}
                >
                  <MenuItem disabled value={0}>
                    Select Forms Status
                  </MenuItem>
                  {names.map(({ key, value }) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Dialog open={openDialog} onClose={handleCancel}>
                {step === 1 ? (
                  <>
                    <DialogTitle>
                      {selectedValue === "Checklist Approved"
                        ? "Approve Checklist"
                        : "Disapprove Checklist"}
                    </DialogTitle>
                    <DialogContent sx={{ width: "600px" }}>
                      {selectedValue === "Checklist Disapproved" && (
                        <>
                          <FormControl sx={{ mb: 2, width: "100%" }}>
                            <InputLabel id="multi-select-label" sx={{ mb: 1 }}>
                              Select Forms
                            </InputLabel>
                            <Select
                              labelId="multi-select-label"
                              id="multi-select"
                              multiple
                              value={selectedForms}
                              onChange={(e) => setSelectedForms(e.target.value)}
                              input={<OutlinedInput label="Select Forms" />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        color: "#888",
                                      }}
                                    >
                                      Select Forms
                                    </span>
                                  );
                                }
                                const selectedTitles = selected.map((id) => {
                                  const form = detailedData?.forms.find(
                                    (f) => f._id === id
                                  );
                                  return form ? form.title : "";
                                });
                                return selectedTitles.join(", ");
                              }}
                              displayEmpty
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    "& .MuiMenuItem-root": {
                                      "&:hover": {
                                        backgroundColor: "#ddd",
                                      },
                                      "&.Mui-disabled": {
                                        color: "#888",
                                      },
                                    },
                                  },
                                },
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null
                              }}
                              sx={{
                                width: "100%",
                                maxWidth: 700,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "4px",
                                  "& fieldset": {
                                    borderColor: "#ccc",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#bbb",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#aaa",
                                  },
                                },
                                "& .MuiSelect-select": {
                                  padding: "12px 14px",
                                  color: "#000",
                                },
                                "& .MuiSelect-icon": {
                                  color: "#000",
                                },
                              }}
                            >
                              <MenuItem
                                disabled
                                value=""
                                sx={{ fontWeight: "normal" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    color: "#888",
                                  }}
                                >
                                  Select Forms
                                </span>{" "}
                              </MenuItem>
                              {detailedData?.forms.map(({ _id, title }) => (
                                <MenuItem key={_id} value={_id}>
                                  {title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="Enter Description"
                              variant="outlined"
                              placeholder="Enter a description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "4px",
                                  "& fieldset": {
                                    borderColor: "#ccc",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#bbb",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#aaa",
                                  },
                                },
                              }}
                            />
                          </FormControl>
                        </>
                      )}

                      {selectedValue === "Checklist Approved" && (
                        <TextField
                          fullWidth
                          margin="normal"
                          label="Enter Description (Optional)"
                          variant="outlined"
                          placeholder="Enter a description (optional)"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          sx={{
                            width: "100%",
                            maxWidth: 700,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              "& fieldset": {
                                borderColor: "#ccc",
                              },
                              "&:hover fieldset": {
                                borderColor: "#bbb",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#aaa",
                              },
                            },
                          }}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancel} color="secondary">
                        Cancel
                      </Button>
                      <Button
                        onClick={handleFirstConfirm}
                        color="primary"
                        disabled={!isConfirmEnabled}
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </>
                ) : (
                  <>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent sx={{ width: "600px" }}>
                      <Box sx={{ mb: 2 }}>
                        {selectedValue === "Checklist Approved"
                          ? `Are you sure you want to approve ${selectedForms
                            .map((id) => {
                              const form = detailedData?.forms.find(
                                (f) => f._id === id
                              );
                              return form ? form.title : "";
                            })
                            .join(", ")}?`
                          : `Are you sure you want to disapprove ${selectedForms
                            .map((id) => {
                              const form = detailedData?.forms.find(
                                (f) => f._id === id
                              );
                              return form ? form.title : "";
                            })
                            .join(", ")}?`}
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancel} color="secondary">
                        Cancel
                      </Button>
                      <Button onClick={handleSecondConfirm} color="primary">
                        Yes
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>

              <Button
                className="header-add cancle-hover"
                style={{ marginBottom: "3px" }}
                onClick={handleGenerateAuditPDFClick}
                disabled={PDFLoader}
              >
                <CloudDownloadIcon sx={{ mr: 1 }} />
                Generate Audit PDF
              </Button>
              <Link to="/checklist/submission" className="submenu-item">
                <Button
                  className="header-add cancle-hover"
                  style={{ marginBottom: "3px" }}
                >
                  <DoneIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Link>
              <div style={{ marginTop: "4px", marginRight: "10px" }}>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "600px" }}>
                      <strong>Dashboard:</strong>
                      <p>
                        View the details of the checklist, Issue log and
                        broadcast messages for the selected company.
                      </p>

                      <strong>Issue Raised:</strong>
                      <p>
                        To get the graphs of issues raised, from the drop down
                        from Checklist Completion as “issues raised” to view the
                        status of Issue logs for last five days.
                      </p>

                      <strong> note:</strong>
                      <p>
                        This section does not change and shows the data for all
                        the roles irrespective of the roles select in the
                        “Select sub role” drop down.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
        <Typography
          variant="h5"
          component="h4"
          sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
        >
          User Information
        </Typography>
        <Card className="user-information">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={User} alt="avatar" className="submission-avatar" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Table aria-label="simple table" className="details-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        User Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {detailedData?.user?.userName
                          ? detailedData?.user?.userName
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        User Role:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {detailedData?.role ? detailedData?.role : "--"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Submitted On:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {moment(detailedData?.createdAt).format("lll")}
                      </Typography>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Updated On:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {moment(detailedData?.updatedAt).format("lll")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Category:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {detailedData?.category?.categoryTitle}
                      </Typography>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Frequency:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {(() => {
                          if (frequency === 3) {
                            return "Daily";
                          } else if (frequency === 2) {
                            return "Hourly";
                          } else if (frequency === 5) {
                            return "Monthly";
                          } else if (frequency === 1) {
                            return "No frequency";
                          } else if (frequency === 4) {
                            return "Weekly";
                          }
                          return "N/A";
                        })()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Latitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.userLocation?.lat}
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Longitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.userLocation?.lng}
                        </Typography>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography
                        variant="p"
                        className="detail-head"
                        paddingLeft={3}
                      >
                        User Location:
                      </Typography>
                    </TableCell>
                    <TableCell colspan="3" className="detail-value capitalize">
                      {userLocation}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Version:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.otherInfo?.appVersion || "N/A"}
                    </TableCell>
                    <TableCell>
                      <Typography>Media Status:</Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.imageStatus === true
                        ? "Uploaded"
                        : "Uploading...."}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography sx={{ lineHeight: "0px" }}>
                    Checklist Progress
                  </Typography>
                  <ChartSection data={detailedData} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography sx={{ lineHeight: "0px" }}>
                    Location Progress
                  </Typography>
                  {chartReady && (
                    <Box>
                      <Chart
                        options={options}
                        series={series}
                        type="radialBar"
                        height={200}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Typography
          variant="h5"
          component="h4"
          sx={{ textAlign: "left", p: 1, mt: 2, fontWeight: "bold" }}
        >
          Store Information
        </Typography>
        <Card className="user-information">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={Store} alt="avatar" className="submission-avatar" />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={7} lg={7}>
              <Table aria-label="simple table" className="details-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Store Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.store?.title
                        ? detailedData?.store?.title
                        : "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Latitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.storeLocation?.lat}
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Longitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.storeLocation?.lng}
                        </Typography>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography
                        variant="p"
                        className="detail-head"
                        paddingLeft={5}
                      >
                        Store Location:
                      </Typography>
                    </TableCell>
                    <TableCell colspan="3" className="detail-value capitalize">
                      {storeLocation}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid> */}
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Table aria-label="simple table" className="details-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="body1" className="detail-head">
                        Store Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.store?.title || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="body1" className="detail-head">
                          Latitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="body1" component="b">
                          {detailedData?.storeLocation?.lat}
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="body1" className="detail-head">
                          Longitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="body1" component="b">
                          {detailedData?.storeLocation?.lng}
                        </Typography>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography
                        variant="body1"
                        className="detail-head"
                        paddingLeft={5}
                      >
                        Store Location:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan="3" className="detail-value capitalize">
                      {storeLocation}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box className="map-box">
                <Main
                  storeLocation={storeLocation}
                  userLocation={userLocation}
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box className="map-box">
                <Main
                  storeLocation={storeLocation}
                  userLocation={userLocation}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
        {(detailedData?.approvalStatus === "disapproved" || detailedData?.approvalStatus === "approved") &&
          <>
            <Typography
              variant="h5"
              component="h4"
              sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
            >
              Approval/Disapproval status
            </Typography>
            <Card className="user-information">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  {detailedData?.approvalStatus === "approved" ? <CheckCircleOutlineOutlinedIcon
                    color="success"
                    className="cursor"
                    style={{
                      fontSize: "50px"
                    }}
                  /> : detailedData?.approvalStatus === "disapproved" ?
                    <CancelOutlinedIcon
                      color="error"
                      style={{
                        fontSize: "100px"
                      }}
                    />
                    :
                    <PendingActionsOutlinedIcon
                      color="warning"
                      style={{
                        fontSize: "50px"
                      }}
                    />}
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                  <Table aria-label="simple table" className="" style={{
                    justifyContent: "start",
                    display: 'flex',
                    width: "100%"
                  }}>
                    <TableBody>
                      <TableRow>
                        <TableCell className="list-value user-location">
                          <Typography variant="p" className="detail-head">
                            Approval Status:
                          </Typography>
                        </TableCell>
                        <TableCell className="user-location detail-value">
                          <Typography variant="p" component="b">
                            {detailedData?.approvalStatus
                              ? capitalizeFirstLetter(detailedData?.approvalStatus)
                              : "--"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {detailedData?.approvalStatus === "disapproved" &&
                        <TableRow>
                          <TableCell className="list-value user-location">
                            <Typography variant="p" className="detail-head">
                              Disapproved Forms:
                            </Typography>
                          </TableCell>
                          <TableCell className="user-location detail-value">
                            <Typography variant="p" component="b">
                              <ul style={{
                                margin: "0",
                                padding: "0"
                              }}>
                                {disapprovedForms?.forms ? disapprovedForms?.forms.map(item => item?.approvalStatus === "disapproved" && item.title || "").filter(item => item).join(", ") : "--"}
                              </ul>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      }
                      <TableRow>
                        <TableCell className="list-value user-location">
                          <Typography variant="p" className="detail-head">
                            Description:
                          </Typography>
                        </TableCell>
                        <TableCell className="user-location detail-value">
                          <Typography variant="p" component="b">
                            {detailedData?.approvalStatus === "approved" ? capitalizeFirstLetter(detailedData?.description) : disapprovedForms?.description ? capitalizeFirstLetter(disapprovedForms?.description) : "--"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Card>
          </>}
        {detailedData?.forms?.length > 0 &&
          <>
            <Typography
              variant="h5"
              component="h4"
              sx={{ textAlign: "left", p: 1, mt: 2, fontWeight: "bold" }}
            >
              Forms Data
            </Typography>
            <Card className="user-information" sx={{ textAlign: "center" }}>
              <Grid
                container
                spacing={1}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} sm={12} md={4} lg={2} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      maxHeight: "185px",
                      overflowX: "hidden",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      "&::-webkit-scrollbar": {
                        width: 7,
                      },
                      "&::-webkit-scrollbar-track": {
                        padding: "12px 5px",
                        backgroundColor: "#CBD4E1",
                        borderRadius: "5px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#64748B",
                        borderRadius: 8,
                      },
                    }}
                    className="button-tabs"
                    style={{ paddingLeft: 10, rowGap: "5px" }}
                  >
                    {detailedData?.forms?.map((row, i) => {
                      return (
                        <Button
                          className={
                            active === i
                              ? "Correction cancle-hover"
                              : "Correction-second cancle-hover"
                          }
                          onClick={() => {
                            HandleValues(i);
                          }}
                          sty
                        >
                          <b>{row.title}</b>
                          <br />
                          <span>Scored:</span>
                          {/* <b>
                          {(
                            (row.calculatedOptionScore /
                              row.calculatedQuestionScore) *
                              100 +
                            ""
                          )
                            .slice(".", 5)
                            .concat("%")}
                        </b> */}
                          <b>
                            {row.calculatedQuestionScore !== 0
                              ? (
                                (row.calculatedOptionScore /
                                  row.calculatedQuestionScore) *
                                100
                              )
                                .toFixed(2)
                                .concat("%")
                              : "0.00"}
                          </b>
                        </Button>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={10}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    className="tabs-content-parent"
                  >
                    <Tab
                      label="Questions"
                      className="active-tabs Questions-tabs cancle-hover"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="User / Outlet Image"
                      className="Archived Questions-tabs cancle-hover"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <FormDataTable buttonsvalues={buttonsvalues} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <UserOutlet buttonsvalues={buttonsvalues} />
                  </TabPanel>
                </Grid>
              </Grid>
            </Card>
          </>
        }
      </Box>
      <p style={{ fontSize: "1rem" }}>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default ViewDetailsSubmission;
