import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";
import { FormControl, Box, TextField, Grid, Stack } from "@mui/material";
import { addGroupDataUpdate } from "../../../redux/actions/AddGroup";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { addGroupData } from "../../../redux/actions/AddGroup";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
const Modal = ({ open, setOpen, editData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [card, setCart] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState();
  const AddGroupData = useSelector((state) => state.addgroup);
  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("List item is required"),
  });
  // const formik = useFormik({
  //     initialValues:{
  //         title:editData && editData.title || "",
  //         description:"",
  //         id:"",
  //     },
  //     validationSchema: validationSchema,
  //     onSubmit: (values) => {;
  //         if(values){
  //             navigate('/questions');
  //             dispatch(addGroupDataUpdate(values))

  //         }
  //     },
  // })
  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      title: title,
      description: description,
      id: id,
    };
    if (values) {
      navigate("/questions");
      dispatch(addGroupDataUpdate(values));
    }
  };
  const getData = () => {
    if (editData) {
      setTitle(editData.title);
      setDescription(editData.description);
      setId(editData.id);
    }
  };

  useEffect(() => {
    getData();
  }, [editData]);
  const openDialogclose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* <Button onClick={openDialog}>Open dialog</Button> */}
      <Dialog open={open}>
        <CloseIcon onClick={openDialogclose} className="cross-icon" />
        <DialogTitle>Update Group</DialogTitle>
        <DialogContent>
          {" "}
          <form onSubmit={handleSubmit}>
            <Box>
              <Container
                maxWidth="sm"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  p: 1,
                  textAlign: "left",
                }}
              >
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    name="title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    value={title}
                  />
                  {/* <small className='error'>{formik.errors.title && formik.touched.title ? formik.errors.title : null}</small> */}
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Description</label>
                  <TextField
                    id="outlined-basic"
                    name="description"
                    className="issueinput-fields"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    value={description}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                  />
                </FormControl>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                      <Button type="submit" className="header-add cancle-hover">
                        <CheckOutlinedIcon sx={{ mr: 1 }} />
                        Done
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
