/** @format */

import { Stack } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuditorContext } from "../../context/auditorContext";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";

const ChecklistIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 24 24"
  >
    <path
      fill="white"
      d="M15.68 7.68l2.83 2.83L12 15.17l-5.51-5.51 2.83-2.83L12 9.5l3.68-3.68zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);
const AuditorHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countLoader, setCountLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const id = localStorage.getItem("companyIdselect");
  const { setcompanyId } = useContext(AuditorContext);

  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    setcompanyId(id);
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleNavigation = (name) => {
    console.log("Navigating to:", name);

    switch (name) {
      case "CHECKLIST":
        navigate(`/checklist-home`);
        break;
      case "LMS":
        navigate(`/lms-home`);
        break;
      default:
        // navigate("/my-certificates");
        break;
    }
  };

  return (
    <Fragment>
      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            height: "42px",
            borderRadius: "8px",
          }}
        >
          {["CHECKLIST", "LMS"].map((elem, index) => (
            <div
              key={index}
              _ngcontent-fjg-c313=""
              className="card-body"
              onClick={() => handleNavigation(elem)}
              style={{
                backgroundColor: elem === "CHECKLIST" ? "#225B6E" : "#113644",
                width: "35%",
                height: "260px",
                justifyContent: "center",
                borderRadius: "20px",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <div
                _ngcontent-fjg-c313=""
                width="80"
                className="mx-auto mb-2"
                style={{ marginTop: "25px" }}
              >
                {elem === "CHECKLIST" ? (
                  <ChecklistIcon />
                ) : (
                  <svg
                    _ngcontent-fjg-c313=""
                    enable-background="new 0 0 60 58"
                    height="80"
                    viewBox="0 0 60 58"
                    width="80"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                  >
                    <path
                      _ngcontent-fjg-c313=""
                      d="m30 26c-.146 0-.291-.032-.426-.095l-17-8c-.35-.165-.574-.518-.574-.905s.224-.74.574-.905l17-8c.27-.127.582-.127.852 0l17 8c.35.165.574.518.574.905s-.224.74-.574.905l-17 8c-.135.063-.28.095-.426.095zm-14.651-9 14.651 6.895 14.651-6.895-14.651-6.895z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m30 34c-.153 0-.307-.035-.447-.105l-12-6c-.339-.17-.553-.516-.553-.895v-7.647c0-.342.175-.661.464-.844.289-.184.652-.205.961-.061l11.575 5.447 11.574-5.447c.31-.145.673-.123.961.061.29.183.465.502.465.844v7.647c0 .379-.214.725-.553.895l-12 6c-.14.07-.294.105-.447.105zm-11-7.618 11 5.5 11-5.5v-5.453l-10.574 4.976c-.27.127-.582.127-.852 0l-10.574-4.976z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m47 18h-17c-.552 0-1-.448-1-1s.448-1 1-1h17c.552 0 1 .448 1 1s-.448 1-1 1z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m47 27c-.552 0-1-.448-1-1v-9c0-.552.448-1 1-1s1 .448 1 1v9c0 .552-.448 1-1 1z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m30 20c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4c0 .552-.448 1-1 1z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m44.999 30c-.19 0-.383-.054-.554-.168-.459-.306-.583-.927-.277-1.387l2-3c.306-.46.926-.585 1.387-.277.459.306.583.927.277 1.387l-2 3c-.193.289-.51.445-.833.445z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m49.001 30c-.323 0-.64-.156-.833-.445l-2-3c-.306-.459-.182-1.081.277-1.387.459-.308 1.08-.183 1.387.277l2 3c.306.459.182 1.081-.277 1.387-.171.114-.364.168-.554.168z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m30 29c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1s1 .448 1 1v3c0 .552-.448 1-1 1z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m59 38h-58c-.552 0-1-.448-1-1v-32.999c0-2.206 1.795-4.001 4.001-4.001h51.998c2.206 0 4.001 1.795 4.001 4.001v32.999c0 .552-.448 1-1 1zm-57-2h56v-31.999c0-1.104-.897-2.001-2.001-2.001h-51.998c-1.104 0-2.001.897-2.001 2.001z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m55.999 46h-51.998c-2.206 0-4.001-1.795-4.001-4.001v-4.999c0-.552.448-1 1-1h58c.552 0 1 .448 1 1v4.999c0 2.206-1.795 4.001-4.001 4.001zm-53.999-8v3.999c0 1.104.897 2.001 2.001 2.001h51.998c1.104 0 2.001-.897 2.001-2.001v-3.999z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m33 42h-6c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1s-.448 1-1 1z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m55 38h-50c-.552 0-1-.448-1-1v-32c0-.552.448-1 1-1h50c.552 0 1 .448 1 1v32c0 .552-.448 1-1 1zm-49-2h48v-30h-48z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m43 58h-26c-1.654 0-3-1.346-3-3s1.346-3 3-3h26c1.654 0 3 1.346 3 3s-1.346 3-3 3zm-26-4c-.551 0-1 .449-1 1s.449 1 1 1h26c.551 0 1-.449 1-1s-.449-1-1-1z"
                    ></path>
                    <path
                      _ngcontent-fjg-c313=""
                      d="m41 54h-22c-.552 0-1-.448-1-1s.448-1 1-1c3.318 0 5-2.355 5-7 0-.552.448-1 1-1h10c.552 0 1 .448 1 1 0 4.645 1.682 7 5 7 .552 0 1 .448 1 1s-.448 1-1 1zm-17.129-2h12.259c-1.232-1.364-1.957-3.395-2.102-6h-8.055c-.146 2.605-.87 4.636-2.102 6z"
                    ></path>{" "}
                  </svg>
                )}
              </div>
              <h5
                _ngcontent-fjg-c313=""
                className="card-title mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                {elem}
              </h5>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1rem",
                  color: "white",
                }}
              ></div>
            </div>
          ))}
        </Stack>
      )}
    </Fragment>
  );
};

export default AuditorHome;
