/** @format */

import {
  LOGGEDIN_USER_ERROR,
  LOGGEDIN_USER_REQUEST,
  LOGGEDIN_USER_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PRIVATE_ROUTE_REQUEST,
  PRIVATE_ROUTE_REQUEST_ERROR,
  PRIVATE_ROUTE_REQUEST_SUCCESS,
  PRIVATE_USER_REQUEST,
  PRIVATE_USER_REQUEST_ERROR,
  PRIVATE_USER_REQUEST_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  token: "",
  routingData: [],
  userPrivate: [],
  currentUserDetails: [],
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action?.payload,
        loading: false,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case PRIVATE_ROUTE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PRIVATE_ROUTE_REQUEST_SUCCESS: {
      return {
        ...state,
        routingData: action?.payload,
        loading: false,
      };
    }
    case PRIVATE_ROUTE_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        routingData: [],
      };
    }
    case PRIVATE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PRIVATE_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        userPrivate: action?.payload,
        loading: false,
      };
    }
    case PRIVATE_USER_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        userPrivate: [],
      };
    }

    case LOGGEDIN_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LOGGEDIN_USER_SUCCESS: {
      return {
        ...state,
        currentUserDetails: action?.payload,
        loading: false,
      };
    }
    case LOGGEDIN_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        userPrivate: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
