import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import {
  CHANGE_MY_PROFILE_PASSWORD_ERROR,
  CHANGE_MY_PROFILE_PASSWORD_REQUEST,
  CHANGE_MY_PROFILE_PASSWORD_SUCCESS,
  PROFILE_ME_ERROR,
  PROFILE_ME_REQUEST,
  PROFILE_ME_SUCCESS,
  UPDATE_MY_PROFILE_ERROR,
  UPDATE_MY_PROFILE_PICTURE_ERROR,
  UPDATE_MY_PROFILE_PICTURE_REQUEST,
  UPDATE_MY_PROFILE_PICTURE_SUCCESS,
  UPDATE_MY_PROFILE_REQUEST,
  UPDATE_MY_PROFILE_SUCCESS,
  UPDATE_PREFERED_COMPANY_ERROR,
  UPDATE_PREFERED_COMPANY_REQUEST,
  UPDATE_PREFERED_COMPANY_SUCCESS,
} from "../types/Types";

export const ProfileMe = () => ({
  type: PROFILE_ME_REQUEST,
});

export const ProfileMeSuccess = (companyid) => ({
  type: PROFILE_ME_SUCCESS,
  payload: companyid,
});

export const ProfileMeFailed = (error) => ({
  type: PROFILE_ME_ERROR,
  payload: error,
});

export const ProfileMeGetApiAction = (data) => (dispatch) => {
  try {
    dispatch(ProfileMe(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const profileMeAction = response;
        dispatch(ProfileMeSuccess(profileMeAction));
      } else {
        dispatch(ProfileMeFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ProfileMeFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const UpdateMyProfile = (companyid) => ({
  type: UPDATE_MY_PROFILE_REQUEST,
  payload: companyid,
});

export const UpdateMyProfileSuccess = (companyid) => ({
  type: UPDATE_MY_PROFILE_SUCCESS,
  payload: companyid,
});

export const UpdateMyProfileFailed = (error) => ({
  type: UPDATE_MY_PROFILE_ERROR,
  payload: error,
});

export const UpdateMyProfileApi = (data) => (dispatch) => {
  try {
    dispatch(UpdateMyProfile(data));
    JwtAuthService.PutApiService(data).then((resp) => {
      if (resp?.status == 200) {
        const myProfileUpdate = resp;
        dispatch(UpdateMyProfileSuccess(myProfileUpdate));
      } else {
        dispatch(UpdateMyProfileFailed(resp?.response));
      }
    });
  } catch (error) {
    dispatch(UpdateMyProfileFailed(error));
  }
};

export const UploadMyProfilePicture = (companyid) => ({
  type: UPDATE_MY_PROFILE_PICTURE_REQUEST,
  payload: companyid,
});

export const UploadMyProfilePictureSuccess = (companyid) => ({
  type: UPDATE_MY_PROFILE_PICTURE_SUCCESS,
  payload: companyid,
});

export const UploadMyProfilePictureFailed = (error) => ({
  type: UPDATE_MY_PROFILE_PICTURE_ERROR,
  payload: error,
});

export const UpdateMyProfilePictureApiAction = (data) => (dispatch) => {
  try {
    dispatch(UploadMyProfilePicture(data));
    JwtAuthService.PostApiService(data).then((resp) => {
      if (resp?.status == 200) {
        const myProfileUpdatePic = resp;
        dispatch(UploadMyProfilePictureSuccess(myProfileUpdatePic));
        // toast.success(`${myProfileUpdatePic?.data?.message}`);
      } else {
        dispatch(UploadMyProfilePictureFailed(resp?.response));
        toast.error(`${resp?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UploadMyProfilePictureFailed(error));
    toast.error(error);
  }
};

export const ChangePreferedCompany = (companyid) => ({
  type: UPDATE_PREFERED_COMPANY_REQUEST,
  payload: companyid,
});

export const ChangePreferedCompanySuccess = (companyid) => ({
  type: UPDATE_PREFERED_COMPANY_SUCCESS,
  payload: companyid,
});

export const ChangePreferedCompanyFailed = (error) => ({
  type: UPDATE_PREFERED_COMPANY_ERROR,
  payload: error,
});

export const ChangePreferedCompanyApiAction = (data) => (dispatch) => {
  try {
    dispatch(ChangePreferedCompany(data));
    JwtAuthService.PutApiService(data).then((resp) => {
      if (resp?.status == 200) {
        const preferedCompChange = resp;
        dispatch(ChangePreferedCompanySuccess(preferedCompChange));
        toast.success(`${preferedCompChange?.data?.message}`);
      } else {
        dispatch(ChangePreferedCompanyFailed(resp?.response));
        toast.error(`${resp?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChangePreferedCompanyFailed(error));
    toast.error(error);
  }
};

export const ChangePsswordRequest = (companyid) => ({
  type: CHANGE_MY_PROFILE_PASSWORD_REQUEST,
  payload: companyid,
});

export const ChangePsswordSuccess = (companyid) => ({
  type: CHANGE_MY_PROFILE_PASSWORD_SUCCESS,
  payload: companyid,
});

export const ChangePsswordFailed = (error) => ({
  type: CHANGE_MY_PROFILE_PASSWORD_ERROR,
  payload: error,
});

export const ChangePasswordApiAction = (data) => (dispatch) => {
  try {
    dispatch(ChangePsswordRequest(data));
    JwtAuthService.PostApiService(data).then((resp) => {
      if (resp?.status == 200) {
        const changePassRes = resp;
        dispatch(ChangePsswordSuccess(changePassRes));
        toast.success(`${changePassRes?.data?.message}`);
      } else {
        dispatch(ChangePsswordFailed(resp?.response));
        resp?.response?.data?.errors.length ? toast.error(`${resp?.response?.data?.errors[0]?.message}`) : toast.error(`${resp?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChangePsswordFailed(error));
    toast.error(error);
  }
};
