import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  CardContent,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import Chart from "react-apexcharts";
import ToggleSwitch from "../../../../helpers/ToggleSwitch";
import { Stack, Box } from "@mui/system";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { LmsTrainingLineChartApi } from "../../../redux/actions/LmsAction";
import * as moment from "moment";
import { LoaderComponent } from "../../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// let options = {
//   colors: ["#039855", "#F39A4C", "#1465B4"],
//   plotOptions: {
//     bar: {
//       horizontal: true,
//       dataLabels: {
//         position: "top",
//       },
//       borderRadius: 4,
//     },
//   },
//   chart: {
//     toolbar: {
//       show: false,
//     },
//   },
//   dataLabels: {
//     enabled: false,
//     offsetX: -6,
//     style: {
//       fontSize: "10px",
//       colors: ["none"],
//     },
//   },
//   stroke: {
//     show: true,
//     width: 3,
//     colors: ["#fff"],
//   },
//   tooltip: {
//     shared: false,
//     intersect: true,
//   },
//   xaxis: {
//     categories: [],
//     lines: {
//       show: true,
//     },
//     tickAmount: 10,
//     axisBorder: {
//       show: false,
//     },
//   },
//   grid: {
//     strokeDashArray: 0,
//     position: "front",
//     row: {
//       opacity: 0.5,
//     },
//     column: {
//       opacity: 0.5,
//     },
//     xaxis: {
//       lines: {
//         show: true,
//       },
//     },
//     yaxis: {
//       lines: {
//         show: false,
//       },
//     },
//     padding: {
//       top: 0,
//       right: 0,
//       bottom: 0,
//       left: 0,
//     },
//   },
//   legend: {
//     show: false,
//   },
// };

let options = {
  colors: ["#F39A4C", "#039855", "#BB251A", "#D35400", "#5DADE2"],
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: "15%",
      barHeight: "15%",
      dataLabels: {
        position: "top",
      },
      borderRadius: 4,
    },
  },
  chart: {
    height: 100,
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
    offsetX: -6,
    style: {
      fontSize: "10px",
      colors: ["none"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: false,
    intersect: true,
  },
  xaxis: {
    // categories: ['20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022'],
    lines: {
      show: true,
    },
    tickAmount: 10,
    axisBorder: {
      show: false,
    },
  },
  grid: {
    strokeDashArray: 0,
    position: "front",
    row: {
      opacity: 0.5,
    },
    column: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  legend: {
    show: false,
  },
};

const LineChart = ({ trainings, subRole }) => {
  const dispatch = useDispatch();
  const [dateData, setDateData] = useState([]);
  const [selectValue, setSelectValue] = useState("days");
  const [categoryData, setCategoryData] = useState([]);
  const [optionOne, setOptionOne] = useState(options);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectMonth, setSelectMonth] = useState("");

  const [trainingsId, setTrainingsId] = useState([]);
  useEffect(() => {
    const newIdvalue = trainings.map((item, i) => {
      return item?._id;
    });
    setTrainingsId(newIdvalue);
  }, [trainings]);

  const lmsTrainingChartData = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.lmsTrainingLineChartData &&
      state?.lms?.lmsTrainingLineChartData?.data &&
      state?.lms?.lmsTrainingLineChartData?.data?.data
  );
  const traningLineChartPayload = {
    body: { TrainingIds: trainingsId },
  };
  useEffect(() => {
    if (lmsTrainingChartData) {
      let series = [
        {
          name: "Submission",
          data: [],
        },
        // {
        //   name: "Submitted",
        //   data: [],
        // },
        // {
        //   name: "InProgress",
        //   data: [],
        // },
        // {
        //   name: "Pending",
        //   data: [],
        // },
        // {
        //   name: "Expired",
        //   data: [],
        // },
      ];
      let CategoryData = [];
      for (let item of lmsTrainingChartData) {
        if (item?.submissionDate) {
          CategoryData.push(
            moment(`${item?.submissionDate}`).utc().format("MMM D, h:mm")
          );
        }
        series[0].data.push(item.submissionCount);
        // series[0].data.push(item.Created);
        // series[1].data.push(item.Submitted);
        // series[2].data.push(item.InProgress);
        // series[3].data.push(item.Pending);
        // series[4].data.push(item.Expired);
      }
      setOptionOne((options) => ({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: CategoryData,
        },
        chart: {
          events: {
            xAxisLabelClick: function (event) {
              const str = `${event?.path[0]?.innerHTML}`;
              const first = str.split(" ")[0];
              setSelectMonth(first);
            },
          },
        },
      }));
      setCategoryData(series);
    }
  }, [lmsTrainingChartData]);
  useEffect(() => {
    const id = localStorage.getItem("companyId");
    traningLineChartPayload.url =
      BASE_URL + `trainingLineChartSubmissions?company_id=${id}&subRole=[${subRole}]`;
    dispatch(LmsTrainingLineChartApi(traningLineChartPayload));
  }, [trainingsId, subRole]);

  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };

  return (
    <Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card sx={{ p: 2 }} className="card">
            <div style={{float:"right",marginTop:"3px",}}>
                 <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
            <CardContent sx={{ p: 4 }} className="zero-space">
              <Typography variant="p" component="div" className="heading">
                LMS completion status
              </Typography>
              {/* <div style={{ display: "flex", justifyContent: "end" }}>
                <select
                  className="check-select select1"
                  onChange={(e) => handleSelectChange(e)}
                  value={selectValue}
                >
                  <option value="days">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div> */}
              {!categoryData.length ? (
                <div
                  style={{
                    height: "500px",
                    width: "inherit",
                    position: "relative",
                  }}
                >
                  <LoaderComponent play />
                </div>
              ) : (
                <Chart
                  options={optionOne}
                  series={categoryData}
                  type="bar"
                  height="100%"
                />
              )}
              <Box direction="row" className="line-chart-box">
                <Box className="line-chart-btns1">
                  <Box sx={{ mx: 2 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="stack"
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Created
                      </Typography>
                      {/* <ToggleSwitch /> */}
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#F39A4C",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  <Box sx={{ mx: 2 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="stack"
                      sx={{
                        alignItems: "center",
                        //   borderBottom: "5px solid #F39A4C",
                        borderRadius: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Submitted
                      </Typography>
                      {/* <ToggleSwitch /> */}
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#039855",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  {/* <Box sx={{ mx: 2 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="stack"
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "right",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        In-Progress
                      </Typography>
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#BB251A",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  <Box sx={{ mx: 2 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="stack"
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "right",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Pending
                      </Typography>
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#D35400",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  <Box sx={{ mx: 2 }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="stack"
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "right",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Expired
                      </Typography>
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#5DADE2",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box> */}
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    className="stack"
                    sx={{
                      alignItems: "center",
                      pl: 1,
                      justifyContent: "right",
                    }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Value
                    </Typography>
                    {/* <ToggleSwitch /> */}
                  </Stack>
                  <Button
                    variant="outlined"
                    size="small"
                    className="graph-button"
                  >
                    Show Table
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LineChart;
