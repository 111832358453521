import React from "react";
import Header from "./pages/Header";
import { Box, Grid, Chip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Edit from '../../assets/icons/svg-icons/Edit.svg';
import Delete from '../../assets/icons/svg-icons/Delete.svg';
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";

function createData(
  Id,
  CreatedBy,
  Number,
  createdAt,
  Update,
  Note,
  Attachment,
  Action
) {
  return {
    Id,
    CreatedBy,
    Number,
    createdAt,
    Update,
    Note,
    Attachment,
    Action,
  };
}

const rows = [
  createData(
    "1234567890",
    "Pallavi+66 Kumari",
    "1234567890",
    "May 31, 2022, 3:46:02 PM",
    "May 31, 2022, 3:46:02 PM",
    "Neque porro quisquam est qui dolorem ipsum Neque porro quisquam est qui dolorem ipsum Neque porro...",
    "2"
  ),
  createData(
    "1234567890",
    "Pallavi+66 Kumari",
    "1234567890",
    "May 31, 2022, 3:46:02 PM",
    "May 31, 2022, 3:46:02 PM",
    "Neque porro quisquam est qui dolorem ipsum Neque porro quisquam est qui dolorem ipsum Neque porro...",
    "2"
  ),
  createData(
    "1234567890",
    "Pallavi+66 Kumari",
    "1234567890",
    "May 31, 2022, 3:46:02 PM",
    "May 31, 2022, 3:46:02 PM",
    "Neque porro quisquam est qui dolorem ipsum Neque porro quisquam est qui dolorem ipsum Neque porro...",
    "2"
  ),
  createData(
    "1234567890",
    "Pallavi+66 Kumari",
    "1234567890",
    "May 31, 2022, 3:46:02 PM",
    "May 31, 2022, 3:46:02 PM",
    "Neque porro quisquam est qui dolorem ipsum Neque porro quisquam est qui dolorem ipsum Neque porro...",
    "2"
  ),
];

const ObservationList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          borderRadius: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        <TableContainer component={Paper} className="table-container">
          <Table size="large" aria-label="simple table">
            <TableHead sx={{ m: 4 }}>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Created by</TableCell>
                <TableCell align="center">Created at</TableCell>
                <TableCell align="center">Updated at</TableCell>
                <TableCell align="center">Note</TableCell>
                <TableCell align="center">Attachment</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.Id}</TableCell>
                  <TableCell component="th" scope="row">
                    <Box sx={{ fontWeight: "bold" }}>{row.CreatedBy}</Box>
                    <Box>{row.Number}</Box>
                  </TableCell>
                  <TableCell align="center">{row.createdAt}</TableCell>
                  <TableCell align="center">{row.Update}</TableCell>
                  <TableCell align="center">{row.Note}</TableCell>
                  <TableCell align="center">{row.Attachment}</TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex" }}>
                      <Link to="/observation/details">
                        <Chip avatar={<img src={Edit} alt="Edit" />} variant="outlined" className='iconBox' />
                      </Link>
                      <Chip avatar={<img src={Delete} alt="Delete" />} variant="outlined" className='iconBox' />
                    </Box>
                  </TableCell>
                  {/* <TableCell align="center">
                    <Chip avatar={<img src={Edit} alt="Edit" />} onClick={handleClick} variant="outlined" className='iconBox' />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 2 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              showFirstButton={false}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ObservationList;
