import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

function CustomChip(props) {
  const { size = 1, ...restProps } = props;
  const classes = useStyles({ size });

  return (
    <Chip
      className={classes.root}
      classes={{ avatar: classes.avatar, deleteIcon: classes.deleteIcon }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => `${props.size * 8}px`,
    // height: (props) => `${props.size * 32}px`,
    borderRadius: "4px",
    marginRight:" 12px",
    backgroundColor:"#196773",
    height:'20px',
    // width:'20px',
    color:'#ffffff',
    cursor: "pointer",
    "&:hover": {
        backgroundColor: '#F26539'
      }
  },

}));

export default CustomChip;
