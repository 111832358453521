import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

function createData(POCTitle, SKUTitle, Fronts, StorePrice, Doespricelabelmatch, ExpiryDate) {
    return { POCTitle, SKUTitle, Fronts, StorePrice, Doespricelabelmatch, ExpiryDate };
}

const rows = [
    createData('Customer rack', "LG HD LED TV 32 inch", 4, 588, "True", "Aug 30, 2020"),
    createData('', "LG Ultra HD 4K LED TV 43 inch", 98, 528, "True", "Aug 30, 2020"),
    createData('', 'LG Ultra HD 4K LED TV 43 inch', 87, 988, "True", "Aug 30, 2020"),
];

export const TableData = () => {

    return (
        <Box>
            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>POC Title</TableCell>
                                <TableCell align="center">SKU Title</TableCell>
                                <TableCell align="center">Fronts</TableCell>
                                <TableCell align="center">Store Price</TableCell>
                                <TableCell align="center">Does price label match?</TableCell>
                                <TableCell align="center">Expiry Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">{row.POCTitle}</TableCell>
                                    <TableCell align="left">{row.SKUTitle}</TableCell>
                                    <TableCell align="center">{row.Fronts}</TableCell>
                                    <TableCell align="center">{row.StorePrice}</TableCell>
                                    <TableCell align="center">{row.Doespricelabelmatch}</TableCell>
                                    <TableCell align="center">{row.ExpiryDate}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default TableData;
