import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import {
  AccountDetailsApi,
  PlanDetailsApi,
  submitAccountDetailsApi,
} from "../redux/actions/AccountAction";
import { useDispatch, useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import Lottie from "react-lottie-player";
import lottieJson from "./LottieLogo.json";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { ListUserApi } from "../redux/actions/User";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddOn from "./AddOn";

function ProgressbarTransparent(props) {
  return (
    <div id="divLoading" className="transparentLoader">
      <span className="centerLoaderText">
        <Lottie
          animationData={lottieJson}
          style={{ width: 150, height: 150 }}
          loop
          speed={2}
          {...props}
        ></Lottie>
      </span>
    </div>
  );
}

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "90%",
  overflow: "scroll",
  bgcolor: "#fff",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

function AccountDetails() {
  const planDetails = useSelector((state) => state.account.planList);
  const companyInfo = useSelector((state) => state.account.companyInfo);
  const companyLoading = useSelector((state) => state.account.companyLoading);
  const submitLoading = useSelector((state) => state.account.submitLoading);

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.userList &&
      state.user.userList.data &&
      state.user.userList.data.data
  );

  // console.log("companyInfo", companyInfo);

  const countryCodeData = Country.getAllCountries();
  const stateCodeData = State.getAllStates();
  const [cardDetails, setCardDetails] = useState([
    {
      id: 0,
      number: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
    },
  ]);
  const [addonModalOpen, setAddonModalOpen] = useState(false);
  const [uniqueSlag, setUniqueSlag] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [gstin, setGstin] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();

  // console.log("cardDetails", cardDetails);

  const MenuProps = {
    disableScrollLock: true,
  };
  const handleEditClick = () => {
    window.location.href = "/edit-account-details";
  };
  const [editableSections, setEditableSections] = useState({
    companyDetails: false,
  });

  //   const handlePinCodeChange = (event) => {
  //     const inputValue = event.target.value;
  //     if (inputValue.length <= 6) {
  //       setPinCode(inputValue);
  //     }
  //   };
  const isValidGSTIN = (gstin) => {
    const gstinPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    return gstin.match(gstinPattern);
  };

  const handleGstinChange = (event) => {
    const inputValue = event.target.value.toUpperCase().replace(/\s/g, ""); // Remove whitespace
    setGstin(inputValue);
    if (inputValue.length !== 15) {
      setErrorMessage("GSTIN must be exactly 15 characters long");
    } else if (!isValidGSTIN(inputValue)) {
      setErrorMessage("Invalid GSTIN");
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    getPlanDetails();
    listUsers();
    getCompanyInfo();
  }, []);

  // console.log("planDetails", planDetails);

  const getPlanDetails = () => {
    const data = {
      url: BASE_URL + `company/plan/getAll`,
    };
    dispatch(PlanDetailsApi(data));
  };
  const listUsers = () => {
    const companyId = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `users/index?company_id=${companyId}` };
    dispatch(ListUserApi(data));
  };

  const getCompanyInfo = () => {
    const companyId = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `company-info?company_id=${companyId}` };
    dispatch(AccountDetailsApi(data));
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required("Company Name is required"),
    // Address: yup.string().required("Address is required"),
    // State: yup.string().required("State is required"),
    // pinCode: yup.string().max(6, "Pin code must be exactly 6 digits long"),
    // gstIn: yup.string().max(15, "GSTIN must be exactly 15 characters long"),
    // paymentType: yup.string().required("Payment Type is required"),
  });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      uniqueSlag: "",
      line1: "",
      city: "",
      State: "",
      pinCode: "",
      gstIn: "",
      paymentType: 0,
      planType: 0,
      processFeeStatus: true,
      processFee: 0,
      discount: 0,
      countryCode: "",
      stateCode: "",
      admins: [],
      planName: "",
      planDescription: "",
      paymentMethod: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // setLoader(true);
      // console.log("called", values);
      const companyId = localStorage.getItem("companyId");

      const payload = {
        companyName: values.companyName,
        address: {
          line1: values.line1,
          postal_code: values.pinCode,
          city: values.city,
          stateCode: values.stateCode.isoCode,
          countryCode: values.countryCode.isoCode,
        },
        state: values.State,
        companyAdmins: values.admins.map((item) => item.email),
        planDetails: {
          planId:
            planDetails.filter((item) => item.planType === values.planType)[0]
              ?._id || "",
        },
        gst: values.gstIn,
        paymentStatus: values.paymentType,
        // processFee: values.processFee,
        // processFeeStatus: values.processFeeStatus,
      };

      if (values.paymentType === "auto") {
        payload.paymentDetails = {
          cards: cardDetails,
          customerEmail: values.customerEmail,
        };
      }
      const data = {
        url: BASE_URL + `company-info?company_id=${companyId}`,
        body: payload,
      };
      // console.log("payload", data);
      dispatch(submitAccountDetailsApi(data));
    },
  });

  const handleAdminChange = (e, newValue) => {
    if (newValue) {
      const event = {
        ...e,
        target: {
          ...e.target,
          name: "admins",
          value: newValue,
        },
      };
      formik.handleChange(event);
    }
  };

  const handleAddOn = () => {
    setAddonModalOpen(true);
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      {companyLoading || (submitLoading && <ProgressbarTransparent play />)}
      {!companyLoading && (
        <Container
          maxWidth="xl"
          sx={{
            bgcolor: "#F6F8FC",
            p: 1,
            pb: 10,
            borderRadius: 2,
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              py: 2,
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
            }}
          >
            <Typography variant="h6">Company Details</Typography>
            <Box sx={{ display: "flex", columnGap: "10px" }}>
              {companyInfo.companyName && (
                <button
                  className="header-add cancle-hover"
                  onClick={() => handleAddOn()}
                >
                  Add On
                </button>
              )}
              {companyInfo.companyName && (
                <Link
                  to="/edit-account-details"
                  state={{
                    companyInfo,
                  }}
                >
                  <button className="header-add cancle-hover">edit</button>
                </Link>
              )}
            </Box>
            {editableSections.companyDetails && (
              <EditCompanyDetailsForm
                currentValues={formik.values.companyDetails}
                onUpdate={handleUpdate}
              />
            )}
          </Box>
          {companyInfo.companyName ? (
            <Box
              sx={{
                bgcolor: "#F6F8FC",
                height: "80vh",
                mt: 1,
                p: 1,
                overflowX: "hidden",
                // overflowY: "scroll",
                borderRadius: "8px",
                px: 1,
                "&::-webkit-scrollbar": {
                  width: 15,
                },
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                  <Card
                    sx={{
                      mb: 2,
                      borderRadius: "8px",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={1} sx={{ p: 1 }}>
                      <CardContent>
                        <Table
                          aria-label="simple table"
                          className="details-table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Company Name:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value capitalize">
                                {companyInfo.companyName}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">GST IN:</Typography>
                              </TableCell>
                              <TableCell className="detail-value">
                                {companyInfo.gst}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Unique slug:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value">
                                {companyInfo.uniqueslag}
                              </TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Company Admins:
                                </Typography>
                              </TableCell>
                              {companyInfo?.companyAdmins &&
                              companyInfo.companyAdmins.length > 0 ? (
                                <TableCell className="detail-value">
                                  {companyInfo.companyAdmins?.map((item, i) => {
                                    return (
                                      <li>
                                        <Typography variant="p" key={i}>
                                          {item ? item : ""}
                                        </Typography>
                                      </li>
                                    );
                                  })}
                                </TableCell>
                              ) : (
                                <TableCell className="detail-value">
                                  -
                                </TableCell>
                              )}
                            </TableRow> */}
                            {/* <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Process Fee:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value">
                                {companyInfo.processFee || "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Process Fee Status:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value">
                                {companyInfo.processFeeStatus
                                  ? "True"
                                  : "False"}
                              </TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">Created At:</Typography>
                              </TableCell>
                              <TableCell className="detail-value">
                                {moment(companyInfo.createdAt).format(
                                  "MMMM D, YYYY, LTS"
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">Updated At:</Typography>
                              </TableCell>
                              <TableCell className="detail-value">
                                {moment(companyInfo.updatedAt).format(
                                  "MMMM D, YYYY, LTS"
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                  <Card
                    sx={{
                      px: 1,
                      mb: 2,
                      py: 1,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <CardContent>
                      <Table
                        aria-label="simple table"
                        className="details-table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell className="detail-key">
                              <Typography variant="p">Address:</Typography>
                            </TableCell>
                            <TableCell className="detail-value">
                              {companyInfo.address.line1 || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-key">
                              <Typography variant="p">city:</Typography>
                            </TableCell>
                            <TableCell className="detail-value">
                              {companyInfo.address.city || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-key">
                              <Typography variant="p">State:</Typography>
                            </TableCell>
                            <TableCell className="detail-value">
                              {companyInfo.state || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-key">
                              <Typography variant="p">State Code:</Typography>
                            </TableCell>
                            <TableCell className="detail-value">
                              {companyInfo.address.stateCode || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-key">
                              <Typography variant="p">Country Code:</Typography>
                            </TableCell>
                            <TableCell className="detail-value">
                              {companyInfo.address.countryCode || "-"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-key">
                              <Typography variant="p">Pin Code:</Typography>
                            </TableCell>
                            <TableCell className="detail-value">
                              {companyInfo.address.postal_code || "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Box sx={{ py: 2, fontWeight: 700 }}>
                <Typography variant="h6">Plan Details</Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <Card
                    sx={{
                      mb: 2,
                      borderRadius: "8px",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={1} sx={{ p: 1 }}>
                      <CardContent>
                        <Table
                          aria-label="simple table"
                          className="details-table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">Plan name:</Typography>
                              </TableCell>
                              <TableCell className="detail-value capitalize">
                                {
                                  planDetails.filter(
                                    (item) =>
                                      item._id ===
                                      companyInfo.planDetails.planId
                                  )?.[0]?.title
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Plan description:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value capitalize">
                                {
                                  planDetails.filter(
                                    (item) =>
                                      item._id ===
                                      companyInfo.planDetails.planId
                                  )?.[0]?.description
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Payment method:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value capitalize">
                                {companyInfo.paymentStatus}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Grid>
                  </Card>
                </Grid>
                {/* <Grid item xs={12} lg={6} sm={12} md={6}>
                  <Card
                    sx={{
                      mb: 2,
                      borderRadius: "8px",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={1} sx={{ p: 1 }}>
                      <CardContent>
                        <Table
                          aria-label="simple table"
                          className="details-table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell className="detail-key">
                                <Typography variant="p">
                                  Card Details:
                                </Typography>
                              </TableCell>
                              <TableCell className="detail-value capitalize">
                                {companyInfo.cardDetails.data.map((card) => {
                                  return (
                                    <li>
                                      **** **** **** {card.card.last4} |{" "}
                                      {card.card.brand} | ({card.card.exp_month}
                                      /{card.card.exp_year})
                                    </li>
                                  );
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CardContent>
                    </Grid>
                  </Card>
                </Grid> */}
              </Grid>
            </Box>
          ) : (
            <form
              onSubmit={(values) => {
                formik.handleSubmit(values);
              }}
            >
              <FormControl
                sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth>
                  <label>Company Name</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Company Name"
                    name="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                  />
                  <small className="error">
                    {formik.errors.companyName && formik.touched.companyName
                      ? formik.errors.companyName
                      : null}
                  </small>
                </FormControl>
                <FormControl fullWidth>
                  <label>GstIn</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="GstIn"
                    name="gstIn"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gstIn}
                    error={formik.touched.gstIn && Boolean(formik.errors.gstIn)}
                  />
                  <small className="error">
                    {formik.errors.gstIn && formik.touched.gstIn
                      ? formik.errors.gstIn
                      : null}
                  </small>
                </FormControl>
              </FormControl>
              <FormControl
                sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth>
                  <label>Address Line 1</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Address Line 1"
                    name="line1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.line1}
                    error={formik.touched.line1 && Boolean(formik.errors.line1)}
                  />
                  <small className="error">
                    {formik.errors.line1 && formik.touched.line1
                      ? formik.errors.line1
                      : null}
                  </small>
                </FormControl>
                <FormControl fullWidth>
                  <label>City</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="City"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                  />
                  <small className="error">
                    {formik.errors.city && formik.touched.city
                      ? formik.errors.city
                      : null}
                  </small>
                </FormControl>
              </FormControl>
              <FormControl
                sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth>
                  <label>Pin Code</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Pin Code"
                    name="pinCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pinCode}
                    error={
                      formik.touched.pinCode && Boolean(formik.errors.pinCode)
                    }
                  />
                  <small className="error">
                    {formik.errors.pinCode && formik.touched.pinCode
                      ? formik.errors.pinCode
                      : null}
                  </small>
                </FormControl>
                <FormControl fullWidth>
                  <label>State</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="State"
                    name="State"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.State}
                    error={formik.touched.State && Boolean(formik.errors.State)}
                  />
                  <small className="error">
                    {formik.errors.State && formik.touched.State
                      ? formik.errors.State
                      : null}
                  </small>
                </FormControl>
              </FormControl>
              <FormControl
                sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
              >
                <FormControl fullWidth>
                  <label style={{ textAlign: "left" }}>Country Code</label>
                  <Autocomplete
                    options={countryCodeData.length ? countryCodeData : []}
                    getOptionLabel={(option) => option?.isoCode || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        size="small"
                        name="countryCode"
                        placeholder="Country Code"
                        sx={{ borderRadius: "8px" }}
                      />
                    )}
                    value={formik.values.countryCode}
                    onChange={(e, value) => {
                      const event = {
                        ...e,
                        target: {
                          ...e.target,
                          name: "countryCode",
                          value: value,
                        },
                      };
                      formik.handleChange(event);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.isoCode}>
                        {option.isoCode}
                      </li>
                    )}
                    clearable
                  />
                </FormControl>
                <FormControl fullWidth>
                  <label style={{ textAlign: "left" }}>State Code</label>
                  <Autocomplete
                    options={
                      stateCodeData.length
                        ? stateCodeData.filter(
                            (item) => item.countryCode === "IN"
                          )
                        : []
                    }
                    getOptionLabel={(option) => option?.isoCode || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        size="small"
                        placeholder="State Code"
                        sx={{ borderRadius: "8px" }}
                      />
                    )}
                    value={formik.values.stateCode}
                    onChange={(e, value) => {
                      const event = {
                        ...e,
                        target: {
                          ...e.target,
                          name: "stateCode",
                          value: value,
                        },
                      };
                      formik.handleChange(event);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.isoCode}>
                        {option.isoCode}
                      </li>
                    )}
                    clearable
                  />
                </FormControl>
              </FormControl>
              <FormControl
                sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
              >
                <FormControl fullWidth>
                  <label>Payment Type</label>
                  <Select
                    name="paymentType"
                    //   value={paymentType}
                    //   onChange={(e) => setPaymentType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Payment type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.paymentType}
                    error={
                      formik.touched.paymentType &&
                      Boolean(formik.errors.paymentType)
                    }
                    fullWidth
                  >
                    <MenuItem value={0}>Select type</MenuItem>
                    <MenuItem value={"auto"}>Auto</MenuItem>
                    <MenuItem value={"Manual"}>Manual</MenuItem>
                  </Select>
                  <small className="error">
                    {formik.errors.paymentType && formik.touched.paymentType
                      ? formik.errors.paymentType
                      : null}
                  </small>
                </FormControl>
                {/* <FormControl fullWidth>
                  <label>Admins</label>

                  <Autocomplete
                    options={
                      userList && userList.users.length > 0
                        ? userList.users.filter(
                          (item) => item?.role === "admin"
                        )
                        : []
                    }
                    getOptionLabel={(option) => option?.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        size="small"
                        placeholder="Select User"
                        sx={{ borderRadius: "8px" }}
                      />
                    )}
                    value={formik.values.admins}
                    onChange={handleAdminChange}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <Checkbox
                          checked={
                            formik.values.admins
                              ? formik.values.admins.filter(
                                (seUser) => option._id === seUser._id
                              ).length
                              : false
                          }
                          sx={{ mr: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newSelected = formik.values.admins
                              ? [...formik.values.admins]
                              : [];
                            const selectedIndex = newSelected.indexOf(option);

                            if (selectedIndex === -1) {
                              newSelected.push(option);
                            } else {
                              newSelected.splice(selectedIndex, 1);
                            }

                            const event = {
                              ...e,
                              target: {
                                ...e.target,
                                name: "admins",
                                value: newSelected,
                              },
                            };
                            formik.handleChange(event);
                          }}
                        />
                        {option.name}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <Chip
                                key={option.name}
                                label={option.name}
                                {...getTagProps({ index })}
                                color="primary"
                                sx={{ mr: 1, mb: 1 }}
                              />
                              {value.length > 1 ? (
                                <Chip
                                  key={`${value.length - 1} more selected`}
                                  label={`${value.length - 1} more selected`}
                                  color="primary"
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      })
                    }
                    clearable
                    // onClear={handleClearUsers}
                    multiple
                    disableCloseOnSelect
                    PaperComponent={(paperProps) => {
                      const { children, ...restPaperProps } = paperProps;
                      return (
                        <Paper {...restPaperProps}>
                          <Box
                            onMouseDown={(e) => e.preventDefault()}
                            pl={3}
                            py={0.5}
                          >
                            <FormControlLabel
                              onClick={(e) => {
                                e.preventDefault();
                                // if (
                                //   userList &&
                                //   selectedUsers.length > 0 &&
                                //   selectedUsers.length === userList.length
                                // ) {
                                //   handleAdminChange("", []);
                                // } else {
                                //   handleAdminChange("", userList);
                                // }
                              }}
                              label="Select all"
                              control={
                                <Checkbox
                                  sx={{ mr: 1 }}
                                  id="select-all-checkbox"
                                // checked={
                                //   userList &&
                                //   selectedUsers.length > 0 &&
                                //   selectedUsers.length === userList.length
                                // }
                                />
                              }
                            />
                          </Box>
                          <Divider />
                          {children}
                        </Paper>
                      );
                    }}
                  />
                  <small className="error">
                    {formik.errors.admins && formik.touched.admins
                      ? formik.errors.admins
                      : null}
                  </small>
                </FormControl>*/}
              </FormControl>

              {formik.values.paymentType === "auto" ? (
                <FormControl
                  sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <FormControl fullWidth>
                    <label>Customer Email</label>
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-start-adornment"
                      placeholder="Customer Email"
                      name="customerEmail"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.customerEmail}
                      error={
                        formik.touched.customerEmail &&
                        Boolean(formik.errors.customerEmail)
                      }
                    />
                    <small className="error">
                      {formik.errors.customerEmail &&
                      formik.touched.customerEmail
                        ? formik.errors.customerEmail
                        : null}
                    </small>
                  </FormControl>
                </FormControl>
              ) : (
                ""
              )}

              {formik.values.paymentType === "auto"
                ? cardDetails.map((item) => (
                    <>
                      {" "}
                      <FormControl
                        sx={{
                          mb: 2,
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          border: "1px solid grey",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <FormControl fullWidth>
                          <label>Card Number</label>
                          <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            placeholder="Card Number"
                            name="cardNumber"
                            onChange={(e) => {
                              const modifieddetails = [...cardDetails];
                              cardDetails.map((card) => {
                                if (card.id === item.id) {
                                  card.number = e.target.value;
                                }
                              });
                              setCardDetails(modifieddetails);
                            }}
                            // onBlur={formik.handleBlur}
                            value={item.number}
                            error={
                              formik.touched.cardNumber &&
                              Boolean(formik.errors.cardNumber)
                            }
                          />
                          <small className="error">
                            {formik.errors.cardNumber &&
                            formik.touched.cardNumber
                              ? formik.errors.cardNumber
                              : null}
                          </small>
                        </FormControl>
                        <FormControl fullWidth>
                          <label>Exp Month</label>
                          <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            placeholder="Exp Month"
                            name="exp_monnth"
                            onChange={(e) => {
                              const modifieddetails = [...cardDetails];
                              cardDetails.map((card) => {
                                if (card.id === item.id) {
                                  card.exp_month = e.target.value;
                                }
                              });
                              setCardDetails(modifieddetails);
                            }}
                            // onBlur={formik.handleBlur}
                            value={item.exp_month}
                            error={
                              formik.touched.gstIn &&
                              Boolean(formik.errors.gstIn)
                            }
                          />
                          <small className="error">
                            {formik.errors.gstIn && formik.touched.gstIn
                              ? formik.errors.gstIn
                              : null}
                          </small>
                        </FormControl>
                        <FormControl fullWidth>
                          <label>Exp Year</label>
                          <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            placeholder="Exp Year"
                            name="exp_year"
                            onChange={(e) => {
                              const modifieddetails = [...cardDetails];
                              cardDetails.map((card) => {
                                if (card.id === item.id) {
                                  card.exp_year = e.target.value;
                                }
                              });
                              setCardDetails(modifieddetails);
                            }}
                            // onBlur={formik.handleBlur}
                            value={item.exp_year}
                            error={
                              formik.touched.gstIn &&
                              Boolean(formik.errors.gstIn)
                            }
                          />
                          <small className="error">
                            {formik.errors.gstIn && formik.touched.gstIn
                              ? formik.errors.gstIn
                              : null}
                          </small>
                        </FormControl>
                        <FormControl fullWidth>
                          <label>CVV</label>
                          <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            placeholder="CVV"
                            name="cvv"
                            onChange={(e) => {
                              const modifieddetails = [...cardDetails];
                              cardDetails.map((card) => {
                                if (card.id === item.id) {
                                  card.cvc = e.target.value;
                                }
                              });
                              setCardDetails(modifieddetails);
                            }}
                            // onBlur={formik.handleBlur}
                            value={item.cvc}
                            error={
                              formik.touched.gstIn &&
                              Boolean(formik.errors.gstIn)
                            }
                          />
                          <small className="error">
                            {formik.errors.gstIn && formik.touched.gstIn
                              ? formik.errors.gstIn
                              : null}
                          </small>
                        </FormControl>
                      </FormControl>
                    </>
                  ))
                : ""}
              {formik.values.paymentType === "auto" ? (
                <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setCardDetails([
                        ...cardDetails,
                        {
                          id: cardDetails.length + 1,
                          number: "",
                          exp_month: "",
                          exp_year: "",
                          cvc: "",
                        },
                      ])
                    }
                  >
                    Add New Card
                  </Button>
                </Box>
              ) : (
                ""
              )}

              {/* <FormControl
                sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
              >
                <FormControl fullWidth>
                  <label>Process Fee</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Process Fee"
                    name="processFee"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.processFee}
                    error={
                      formik.touched.processFee &&
                      Boolean(formik.errors.processFee)
                    }
                  />
                  <small className="error">
                    {formik.errors.processFee && formik.touched.processFee
                      ? formik.errors.processFee
                      : null}
                  </small>
                </FormControl>
                <FormControl fullWidth>
                  <label>Process Fee Status</label>
                  <Select
                    name="processFeeStatus"
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Process Fee Status"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.processFeeStatus}
                    error={
                      formik.touched.processFeeStatus &&
                      Boolean(formik.errors.processFeeStatus)
                    }
                    fullWidth
                  >
                    <MenuItem value={0}>Select Status</MenuItem>
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                  <small className="error">
                    {formik.errors.processFeeStatus &&
                    formik.touched.processFeeStatus
                      ? formik.errors.processFeeStatus
                      : null}
                  </small>
                </FormControl>
              </FormControl> */}
              <FormControl
                sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
              >
                <FormControl fullWidth>
                  <label>Plan Type</label>
                  <Select
                    name="planType"
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Plan type"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.planType}
                    error={
                      formik.touched.planType && Boolean(formik.errors.planType)
                    }
                    fullWidth
                  >
                    <MenuItem value={0}>Select plan</MenuItem>
                    {planDetails.map((item) => (
                      <MenuItem value={item.planType}>{item.title}</MenuItem>
                    ))}
                  </Select>
                  <small className="error">
                    {formik.errors.planType && formik.touched.planType
                      ? formik.errors.planType
                      : null}
                  </small>
                </FormControl>
                {/* <FormControl fullWidth>
                  <label>Discount</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Discount"
                    name="discount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.discount}
                    error={
                      formik.touched.discount && Boolean(formik.errors.discount)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                  <small className="error">
                    {formik.errors.discount && formik.touched.discount
                      ? formik.errors.discount
                      : null}
                  </small>
                </FormControl> */}
              </FormControl>

              <Button variant="contained" type="submit">
                Submit
              </Button>
              {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
            </form>
          )}
        </Container>
      )}
      <Modal
        open={addonModalOpen}
        onClose={() => setAddonModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...downloadAllstyle, p: 0 }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setAddonModalOpen(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box sx={{ pt: 2, px: 4, height: "100%" }}>
            <AddOn setAddonModalOpen={setAddonModalOpen} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default AccountDetails;
