import JwtAuthService from "../apiConfig/ApiConfig";
import { toast } from "react-toastify";
import {
  CREATE_ROLES_ERROR,
  CREATE_ROLES_REQUEST,
  CREATE_ROLES_SUCCESS,
  EDIT_ROLES_ERROR,
  EDIT_ROLES_REQUEST,
  EDIT_ROLES_SUCCESS,
  LIST_ROLES_ERROR,
  LIST_ROLES_REQUEST,
  LIST_ROLES_SUCCESS,
  LIST_SUBROLE_ERROR,
  LIST_SUBROLE_REQUEST,
  LIST_SUBROLE_SUCCESS,
  MAP_ROLES_ERROR,
  MAP_ROLES_REQUEST,
  MAP_ROLES_SUCCESS,
  ROLE_DETAILS_ERROR,
  ROLE_DETAILS_REQUEST,
  ROLE_DETAILS_SUCCESS,
  DELETE_ROLES_REQUEST,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_ERROR
} from "../types/Types";

export const ListRoles = () => ({
  type: LIST_ROLES_REQUEST,
});
export const ListRolesSuccess = (companyid) => ({
  type: LIST_ROLES_SUCCESS,
  payload: companyid,
});
export const ListRolesFailed = (error) => ({
  type: LIST_ROLES_ERROR,
  payload: error,
});

export const ListRolesApi = (data) => (dispatch) => {
  try {
    dispatch(ListRoles(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListRolesSuccess(data));
      } else {
        dispatch(ListRolesFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListRolesFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const CreateRoles = (companyid) => ({
  type: CREATE_ROLES_REQUEST,
  payload: companyid,
});
export const CreateRolesSuccess = (companyid) => ({
  type: CREATE_ROLES_SUCCESS,
  payload: companyid,
});
export const CreateRolesFailed = (error) => ({
  type: CREATE_ROLES_ERROR,
  payload: error,
});

export const CreateRolesApi = (data, callback) => (dispatch) => {
  try {
    dispatch(CreateRoles());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CreateRolesSuccess(response));
        callback();
        toast.success(`${response?.data?.message}`);
      } else {
        toast.error(`${response?.response?.data?.message}`);
        dispatch(CreateRolesFailed(response?.response?.data?.message));
        console.log("response", response?.response?.data?.message);
      }
    });
  } catch (error) {
    dispatch(CreateRolesFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const EditRoles = (data) => ({
  type: EDIT_ROLES_REQUEST,
  payload: data,
});
export const EditRolesSuccess = (data) => ({
  type: EDIT_ROLES_SUCCESS,
  payload: data,
});
export const EditRolesFailed = (error) => ({
  type: EDIT_ROLES_ERROR,
  payload: error,
});

export const EditRolesApi = (data) => (dispatch) => {

  
  try {
    dispatch(EditRoles());
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(EditRolesSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(EditRolesFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditRolesFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const RoleDetails = (data) => ({
  type: ROLE_DETAILS_REQUEST,
  payload: data,
});
export const RoleDetailsSuccess = (data) => ({
  type: ROLE_DETAILS_SUCCESS,
  payload: data,
});
export const RoleDetailsFailed = (error) => ({
  type: ROLE_DETAILS_ERROR,
  payload: error,
});

export const RoleDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(RoleDetails(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(RoleDetailsSuccess(response));
      } else {
        dispatch(RoleDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(RoleDetailsFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const ListSubrole = () => ({
  type: LIST_SUBROLE_REQUEST
});
export const ListSubroleSuccess = (data) => ({
  type: LIST_SUBROLE_SUCCESS,
  payload: data,
});
export const ListSubroleFailed = (error) => ({
  type: LIST_SUBROLE_ERROR,
  payload: error,
});

export const ListSubroleApi = (data) => (dispatch) => {
  try {
    dispatch(ListSubrole());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ListSubroleSuccess(response));
      } else {
        dispatch(ListSubroleFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListSubroleFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const MapRoles = (data) => ({
  type: MAP_ROLES_REQUEST,
  payload: data,
});
export const MapRolesSuccess = (data) => ({
  type: MAP_ROLES_SUCCESS,
  payload: data,
});
export const MapRolesFailed = (error) => ({
  type: MAP_ROLES_ERROR,
  payload: error,
});

export const MapRolesApi = (data) => (dispatch) => {
  try {
    dispatch(MapRoles(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(MapRolesSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(MapRolesFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(MapRolesFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const DeleteRoles = () => ({
  type: DELETE_ROLES_REQUEST,
});
export const DeleteRolesSuccess = () => ({
  type: DELETE_ROLES_SUCCESS,
});
export const DeleteRolesFailed = (error) => ({
  type: DELETE_ROLES_ERROR,
  payload: error,
});

export const DeleteRolesApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteRoles(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteRolesSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(DeleteRolesFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteRolesFailed(error));
    console.error("Something went Wrong", error);
  }
};