import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./BaseUrl";

const JwtAuthService = {};

// SIGNUP API START //

// JwtAuthService.SignUpApiCall = async function (email) {
//   try {
//     const response = await axios.post(BASE_URL + `auth/send/email/verification`, email);
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//   }
// }

// JwtAuthService.VerifyApiCall = async function (code) {
//   try {
//     const response = await axios.post(BASE_URL + `auth/verify/email/verification`, code);
//     return response;
//   } catch (error) {
//     toast.error(`${error.response.data.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.SignuoFormApiCall = async function (data) {
//   try {
//     const response = await axios.post(BASE_URL + `sessions/new`, data);
//     return response;
//   } catch (error) {
//     toast.error(`${error.response.data.message}`)
//     console.error(error);
//   }
// }
// SIGNUP API END //

// LOGIN API START //

// JwtAuthService.LoginApiCAll = async function (data) {
//   try {
//     const response = await axios.post(BASE_URL + `sessions/create`, data);
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }
// LOGIN API END //

// COMPANIES API START //

// JwtAuthService.ComapanyTypeApiCAll = async function () {
//   const token = localStorage.getItem("token")
//   try {
//     const response = await axios.get(BASE_URL + `companies/types`, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization' : `Bearer ${token}`
//       },
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.CreateCompanyApiCAll = async function (data) {
//   const token = localStorage.getItem("token")
//   try {
//     const response = await axios.post(BASE_URL + `companies`,data, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.CompanyListApiCAll = async function () {
//   const token = localStorage.getItem("token")
//   try {
//     const response = await axios.get(BASE_URL + `profile/me/companies`, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }
// COMPANIES API END //

// ROLES API START //

// JwtAuthService.ListRolesApiCAll = async function (data) {
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')
//   try {
//     const response = await axios.post(BASE_URL + `ShowSubrole?company_id=${id}`,data.body, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.CreateRolesApiCAll = async function (data) {
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')
//   try {
//     const response = await axios.post(BASE_URL + `createSubrole?company_id=${id}`,data, {
//       headers: {
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.EditRolesApiCAll = async function (data, valuesEdit) {
//   const userRoleId = valuesEdit?._id;
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')
//   try {
//     const response = await axios.put(BASE_URL + `updateSubrole/${userRoleId}?company_id=${id}`,data, {
//       headers: {
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.RoleDetailsApiCAll = async function (userRoleId) {
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')
//   try {
//     const response = await axios.get(BASE_URL + `detailsubrole/${userRoleId}?company_id=${id}`, {
//       headers: {
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.ListSubroleApiCAll = async function () {
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')
//   try {
//     const response = await axios.get(BASE_URL + `Listsubrole?company_id=${id}`, {
//       headers: {
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

// JwtAuthService.MapRolesApiCAll = async function (data, userRoleId) {
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')
//   try {
//     const response = await axios.put(BASE_URL + `Maprole/${userRoleId}?company_id=${id}`,data, {
//       headers: {
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }
// ROLES API END //

// CHECKLISTS API START //

JwtAuthService.BinaryImageApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(data?.url, data?.body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

JwtAuthService.PostApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(data?.url, data?.body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

JwtAuthService.ImageApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(data?.url, data?.body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error, "errorAppi");
    return error;
  }
};

JwtAuthService.GetApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(data.url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    // toast.error(`${error?.response?.data?.message}`);
    console.error(error);
    return error;
  }
};

JwtAuthService.PutApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(data.url, data.body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

JwtAuthService.DeleteApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(data.url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    // toast.error(`${error?.response?.response?.data?.message}`);
    console.error(error);
    return error;
  }
};

// CHECKLISTS API END //

// LMS API START
JwtAuthService.LmsAllTrainigReportApiCAll = async function (data) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("companyId");

  try {
    const response = await axios.post(
      BASE_URL + `training/index?company_id=${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

JwtAuthService.LmsCertificateListApiCAll = async function (data) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("companyId");

  try {
    const response = await axios.post(
      BASE_URL + `certificate/index?company_id=${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

// JwtAuthService.LmsCertificateEditApiCAll = async function (data) {
//   const token = localStorage.getItem("token")
//   const id = localStorage.getItem('companyId')

//   try {
//     const response = await axios.get(BASE_URL + `certificate/62dfd90eee19ce18d4eb4c8b?company_id=${id}`,data, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization' : `Bearer ${token}`
//       }
//     });
//     return response;
//   } catch (error) {
//     toast.error(`${error?.response?.data?.message}`)
//     console.error(error);
//   }
// }

JwtAuthService.GetApiService = async function (data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(data.url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// LMS API END

// LMS API END

JwtAuthService.ProfileDetail = async function () {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(BASE_URL + `profile/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};


////new ////////////////////////////////////////////////////////////////

JwtAuthService.StorewiseChecklistReportApi = async function () {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(BASE_URL + `StorewiseChecklistReport?company_id=63d9f962dc6d8b15a2e18d0f&startDate=2023-04-27&endDate=2023-04-27`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

///////////////////////////////////////////////////////////////////

export default JwtAuthService;



