export const getFileType = (fileType) => {
    let type = "docx";
    switch(fileType){
        case "DOC":
            type = "docx"
            break;
        case "PPT":
            type = "ppt"
            break;
        case "PDF":
            type = "pdf"
            break;
        case "EXCEL":
            type = "xls"
            break;
    }
    return type;
}