/** @format */

import {
  LIST_BROADCAST_REQUEST,
  LIST_BROADCAST_SUCCESS,
  LIST_BROADCAST_ERROR,
  ADD_BROADCAST_REQUEST,
  ADD_BROADCAST_SUCCESS,
  ADD_BROADCAST_ERROR,
  DELETE_BROADCAST_REQUEST,
  DELETE_BROADCAST_SUCCESS,
  DELETE_BROADCAST_ERROR,
  VIEW_BROADCAST_REQUEST,
  VIEW_BROADCAST_SUCCESS,
  VIEW_BROADCAST_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  data: [],
  addMsg: [],
  deleteMsg: [],
  details: [],
};

const BroadcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_BROADCAST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_BROADCAST_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        loading: false,
      };
    }
    case LIST_BROADCAST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ADD_BROADCAST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_BROADCAST_SUCCESS: {
      return {
        ...state,
        addMsg: action?.payload,
        loading: false,
      };
    }
    case ADD_BROADCAST_ERROR: {
      return {
        ...state,
        addMsg: [],
        loading: false,
        error: action.error,
      };
    }

    case DELETE_BROADCAST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_BROADCAST_SUCCESS: {
      return {
        ...state,
        deleteMsg: action?.payload,
        loading: false,
      };
    }
    case DELETE_BROADCAST_ERROR: {
      return {
        ...state,
        deleteMsg: [],
        loading: false,
        error: action.error,
      };
    }

    case VIEW_BROADCAST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case VIEW_BROADCAST_SUCCESS: {
      return {
        ...state,
        details: action?.payload,
        loading: false,
      };
    }
    case VIEW_BROADCAST_ERROR: {
      return {
        ...state,
        details: [],
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default BroadcastReducer;
