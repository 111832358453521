/** @format */

import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  CardContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import Chart from "react-apexcharts";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { LmsReportApi } from "../../../redux/actions/LmsAction";
import { LoaderComponent } from "../../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const GraphCards = ({ chooseValue, trainings, subRole }) => {
  const dispatch = useDispatch();
  var options = {
    series: [],
    series2: [],
    series3: [],
    options: {
      labels: [],
      // labels: ["Expired", "Pending", "InProgress", "Submitted", "Created"],
      colors: ["#5DADE2", "#D35400", "#BB251A", "#039855", "#F39A4C"],
      chart: {
        height: 350,
        type: "radialBar",
      },
      legend: {
        show: true,
        position: "right",
        fontSize: "14px",
        fontWeight: 600,
        inverseOrder: true,
        horizontalAlign: "left",
        offsetX: -9,
        offsetY: -12,
        // colors: ["#5DADE2", "#D35400", "#BB251A", "#039855", "#F39A4C"],
        customLegendItems: [],
        labels: {
          useSeriesColors: [
            "#5DADE2",
            "#D35400",
            "#BB251A",
            "#039855",
            "#F39A4C",
          ],
        },
        itemMargin: {
          vertical: 3,
        },
        markers: {
          width: 0,
          height: 0,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "35%",
            background: "none",
            position: "front",
          },
          track: {
            show: true,
            background: "#CBD4E1",
            strokeWidth: "97%",
            opacity: 1,
            margin: 3,
          },
          dataLabels: {
            total: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
    options2: {
      labels: [],
      // labels: ["Expired", "Pending", "InProgress", "Submitted", "Created"],
      colors: ["#5DADE2", "#D35400", "#BB251A", "#039855", "#F39A4C"],
      chart: {
        height: 350,
        type: "radialBar",
      },
      legend: {
        show: true,
        position: "right",
        fontSize: "14px",
        fontWeight: 600,
        inverseOrder: true,
        horizontalAlign: "left",
        offsetX: -9,
        offsetY: -12,
        // colors: ["#5DADE2", "#D35400", "#BB251A", "#039855", "#F39A4C"],
        customLegendItems: [],
        labels: {
          useSeriesColors: [
            "#5DADE2",
            "#D35400",
            "#BB251A",
            "#039855",
            "#F39A4C",
          ],
        },
        itemMargin: {
          vertical: 3,
        },
        markers: {
          width: 0,
          height: 0,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "30%",
            background: "none",
            position: "front",
          },
          track: {
            show: true,
            background: "#CBD4E1",
            strokeWidth: "97%",
            opacity: 1,
            margin: 3,
          },
          dataLabels: {
            total: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
    options3: {
      labels: [],
      // labels: ["Expired", "Pending", "InProgress", "Submitted", "Created"],
      colors: ["#5DADE2", "#D35400", "#BB251A", "#039855", "#F39A4C"],
      chart: {
        height: 350,
        type: "radialBar",
      },
      legend: {
        show: true,
        position: "right",
        fontSize: "14px",
        fontWeight: 600,
        inverseOrder: true,
        horizontalAlign: "left",
        offsetX: -9,
        offsetY: -12,
        // colors: ["#5DADE2", "#D35400", "#BB251A", "#039855", "#F39A4C"],
        customLegendItems: [],
        labels: {
          useSeriesColors: [
            "#5DADE2",
            "#D35400",
            "#BB251A",
            "#039855",
            "#F39A4C",
          ],
        },
        itemMargin: {
          vertical: 3,
        },
        markers: {
          width: 0,
          height: 0,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "30%",
            background: "none",
            position: "front",
          },
          track: {
            show: true,
            background: "#CBD4E1",
            strokeWidth: "97%",
            opacity: 1,
            margin: 3,
          },
          dataLabels: {
            total: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  };

  const [selectValue, setSelectValue] = useState("days");
  const [pieChart, setPieChart] = useState(options);
  const [trainingsId, setTrainingsId] = useState([]);
  useEffect(() => {
    const newIdvalue = trainings.map((item, i) => {
      return item?._id;
    });
    setTrainingsId(newIdvalue);
  }, [trainings]);
  const lmsOverviewReportData = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.lmsReportData &&
      state?.lms?.lmsReportData?.data &&
      state?.lms?.lmsReportData?.data?.data
  );

  const lmsReportPayload = {
    body: { TrainingId: trainingsId },
  };

  if (lmsOverviewReportData) {
    let count = [];
    options.series[0] = lmsOverviewReportData?.Training?.Assigned;
    options.series[1] = lmsOverviewReportData?.Training?.Pending;
    options.series[2] = lmsOverviewReportData?.Training?.InProgress;
    options.series[3] = lmsOverviewReportData?.Training?.Submitted;
    // options.series[4] = lmsOverviewReportData?.Training?.Created;
    options.options.labels[0] = `${lmsOverviewReportData?.Training?.Assigned}`;
    options.options.labels[1] = `${lmsOverviewReportData?.Training?.Pending}`;
    options.options.labels[2] = `${lmsOverviewReportData?.Training?.InProgress}`;
    options.options.labels[3] = `${lmsOverviewReportData?.Training?.Submitted}`;
    // options.options.labels[4] = `${lmsOverviewReportData?.Training?.Created}`;
    options.options.legend.customLegendItems[0] = `Assigned - ${lmsOverviewReportData?.Training?.Assigned}`;
    options.options.legend.customLegendItems[1] = `Pending - ${lmsOverviewReportData?.Training?.Pending}`;
    options.options.legend.customLegendItems[2] = `In-Progress - ${lmsOverviewReportData?.Training?.InProgress}`;
    options.options.legend.customLegendItems[3] = `Submitted - ${lmsOverviewReportData?.Training?.Submitted}`;
    // options.options.legend.customLegendItems[4] = `Created - ${lmsOverviewReportData?.Training?.Created}`;

    options.series2[0] = lmsOverviewReportData?.Assessment?.Assigned;
    options.series2[1] = lmsOverviewReportData?.Assessment?.Pending;
    options.series2[2] = lmsOverviewReportData?.Assessment?.InProgress;
    options.series2[3] = lmsOverviewReportData?.Assessment?.Submitted;
    // options.series2[4] = lmsOverviewReportData?.Assessment?.Created;
    options.options2.labels[0] = `${lmsOverviewReportData?.Assessment?.Assigned}`;
    options.options2.labels[1] = `${lmsOverviewReportData?.Assessment?.Pending}`;
    options.options2.labels[2] = `${lmsOverviewReportData?.Assessment?.InProgress}`;
    options.options2.labels[3] = `${lmsOverviewReportData?.Assessment?.Submitted}`;
    // options.options2.labels[4] = `${lmsOverviewReportData?.Assessment?.Created}`;
    options.options2.legend.customLegendItems[0] = `Assigned - ${lmsOverviewReportData?.Assessment?.Assigned}`;
    options.options2.legend.customLegendItems[1] = `Pending - ${lmsOverviewReportData?.Assessment?.Pending}`;
    options.options2.legend.customLegendItems[2] = `In-Progress - ${lmsOverviewReportData?.Assessment?.InProgress}`;
    options.options2.legend.customLegendItems[3] = `Submitted - ${lmsOverviewReportData?.Assessment?.Submitted}`;
    // options.options2.legend.customLegendItems[4] = `Created - ${lmsOverviewReportData?.Assessment?.Created}`;

    options.series3[0] = lmsOverviewReportData?.Certificate?.Assigned;
    options.series3[1] = lmsOverviewReportData?.Certificate?.Awarded;
    options.series3[2] = lmsOverviewReportData?.Certificate?.Pending;
    // options.series3[3] = lmsOverviewReportData?.Certificate?.Submitted;
    // options.series3[4] = lmsOverviewReportData?.Certificate?.Created;
    options.options3.labels[0] = `${lmsOverviewReportData?.Certificate?.Assigned}`;
    options.options3.labels[1] = `${lmsOverviewReportData?.Certificate?.Awarded}`;
    options.options3.labels[2] = `${lmsOverviewReportData?.Certificate?.Pending}`;
    // options.options3.labels[3] = `${lmsOverviewReportData?.Certificate?.Submitted}`;
    // options.options3.labels[4] = `${lmsOverviewReportData?.Certificate?.Created}`;
    options.options3.legend.customLegendItems[0] = `Assigned - ${lmsOverviewReportData?.Certificate?.Assigned}`;
    options.options3.legend.customLegendItems[1] = `Awarded - ${lmsOverviewReportData?.Certificate?.Awarded}`;
    options.options3.legend.customLegendItems[2] = `Pending - ${lmsOverviewReportData?.Certificate?.Pending}`;
    // options.options3.legend.customLegendItems[3] = `Submitted - ${lmsOverviewReportData?.Certificate?.Submitted}`;
    // options.options3.legend.customLegendItems[4] = `Created - ${lmsOverviewReportData?.Certificate?.Created}`;
  }

  const handleSelectChange = (e) => {
    setSelectValue(e.target.value);
  };

  useEffect(() => {
    if (selectValue) {
      chooseValue(selectValue);
    }
  }, [selectValue]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    lmsReportPayload.url =
      BASE_URL + `LmsReport?company_id=${id}&TrainingFilter=${selectValue}&subRole=[${subRole}]`;
    dispatch(LmsReportApi(lmsReportPayload));
  }, [trainingsId, subRole]);

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <div style={{ position: "relative" }}>
          <div align="right" style={{position:"absolute",right:"0",top:"0",marginTop:"13px",marginRight:"13px"}}>
                 <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
        <Card className="card lms-report-card">
          {/* <div style={{ position: "absolute" }}>
            <select
              className="check-select2 lms-report-select"
              onChange={(e) => handleSelectChange(e)}
              value={selectValue}
            >
              <option value="days">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div> */}
          <Grid xs={12} md={12} sm={12} lg={12} className="lms-report-card">
            <CardContent sx={{ width: "400px" }}>
              <Typography
                variant="p"
                component="div"
                className="Lms-Overview-heading"
              >
                Trainings
              </Typography>
              {!options.series.length ? (
                <div class="spinner-border" role="status">
                  {/* <CircularProgress
                    color="inherit"
                    size="2rem"
                    style={{ color: "#f26539" }}
                  />
                  <span class="sr-only">Loading...</span> */}
                  <p>Trainings Data Not Found</p>
                </div>
              ) : (
                <Chart
                  options={options.options}
                  series={options.series}
                  type="radialBar"
                  width="400"
                  height={300}
                  className="lms-chart"
                />
              )}
            </CardContent>
            <CardContent sx={{ width: "400px" }}>
              <Typography
                variant="p"
                component="div"
                className="Lms-Overview-heading"
              >
                Assessments
              </Typography>
              {!options.series2.length ? (
                <div class="spinner-border" role="status">
                  {/* <CircularProgress
                    color="inherit"
                    size="2rem"
                    style={{ color: "#f26539" }}
                  />
                  <span class="sr-only">Loading...</span> */}
                  <p>Assessments Data Not Found</p>
                </div>
              ) : (
                <Chart
                  options={options.options2}
                  series={options.series2}
                  type="radialBar"
                  width="400"
                  height="300"
                  className="lms-chart"
                />
              )}
            </CardContent>
            <CardContent sx={{ width: "400px" }}>
              <Typography
                variant="p"
                component="div"
                className="Lms-Overview-heading"
              >
                Certificates
              </Typography>
              {!options.series3.length ? (
                <div className="spinner-border" role="status">
                  {/* <CircularProgress
                    color="inherit"
                    size="2rem"
                    style={{ color: "#f26539" }}
                  />
                  <span class="sr-only">Loading...</span> */}
                  <p> Certificates Data Not Found</p>
                </div>
              ) : (
                <Chart
                  options={options.options3}
                  series={options.series3}
                  type="radialBar"
                  width="400"
                  height="300"
                  className="lms-chart"
                />
              )}
            </CardContent>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GraphCards;
