/** @format */

import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Card,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";
import LogoImage from "../../assets/images/logo-image.svg";
import AddIcon from "@mui/icons-material/Add";
import Edit from "../../assets/icons/svg-icons/Edit.svg";
import CompanyModal from "./CompanyModal";
import { Link, useNavigate } from "react-router-dom";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyListApi,
  EditCompanySuccess,
} from "../redux/actions/CreateCompany";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import moment from "moment";
import { AddImageSuccess } from "../redux/actions/User";
import Progressbar from "../GlobalProgress/Progressbar";
import { ChangePreferedCompanySuccess } from "../redux/actions/MyProfileDetailsAction";
import { AuditorContext } from "../../context/auditorContext";
import axios from "axios";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CompanyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCompanyList, setShowCompanyList] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const currentUserDetails = JSON.parse(
    localStorage.getItem("CurrentUserDetails")
  );

  useEffect(() => {
    if (currentUserDetails?._id === "64044d79a2dee13363197eaa") {
      setShowCompanyList(false);
    } else {
      setShowCompanyList(true);
    }
  }, [currentUserDetails]);

  // const isAuditor = useSelector(
  //   state => state.ProfileDetailData?.ProfileData?.data?.preferredCompany?.isAuditor
  // );
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === "") {
      navigate("/");
    }
    localStorage.removeItem("allCHecklistFilterData");
    localStorage.removeItem("searchedData");
    localStorage.removeItem("storedDateData");
    localStorage.removeItem("submissionFilterData");
    localStorage.removeItem("overviewChecklistData");
    localStorage.removeItem("checklistRowPerPage");
    localStorage.removeItem("checklistPageno");
    localStorage.removeItem("submissionRowPerPage");
    localStorage.removeItem("submissionPageno");
  }, []);

  const companyList = useSelector(
    (state) =>
      state.company &&
      state.company.companylist &&
      state.company.companylist.data &&
      state.company.companylist.data.data
  );

  const Loader = useSelector((state) => state.company && state.company.loading);

  const {
    setcompanyId,
    setstoreId,
    storeId,
    companyId,
    currentAssessment,
    setcurrentAssessment,
    selectedStore,
    isAuditor,
    setisAuditor,
  } = useContext(AuditorContext);

  const [open, setOpen] = useState(false);
  const [companyListData, setCompanyListData] = useState([]);
  const [editCompany, setEditComapny] = useState({});
  const [recent1, setRecent1] = useState("");

  useEffect(() => {
    dispatch(ChangePreferedCompanySuccess(null));
    ListCompany();
  }, []);

  useEffect(() => {
    localStorage.removeItem("companyId");
    localStorage.removeItem("compId");
    dispatch(EditCompanySuccess(null));
    dispatch(AddImageSuccess(null));
  }, []);

  useEffect(() => {
    recentFnc(companyList);
    setCompanyListData(companyList);
  }, [companyList]);

  const handleClickOpen = (row) => {
    navigate(`/company-edit`, { state: row });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const recentFnc = (item) => {
    // const recentCompanyGet1 = localStorage.getItem("recent1");
    //  item?.filter((val) => {
    //     if (val._id == recentCompanyGet1) {
    //       setRecent1(val);
    //     }
    //   });
  };

  const ListCompany = () => {
    const data = { url: BASE_URL + `profile/me/companies` };
    dispatch(CompanyListApi(data));
  };

  // const handleClick = (item) => {
  //   navigate("/dashboard")
  // }

  useEffect(() => {
    const ProfileDetail = async function () {
      setLoading(true);
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(BASE_URL + `profile/me`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setLoading(false);
        const dynamicRole = "superAdmins";

        if (response.data.data.preferredCompany.isAuditor) {
          setisAuditor(true);
          localStorage.setItem("role", "auditor");
        }

        if (
          response.data.data.preferredCompany.isAdmin ||
          response.data.data.role?.key === "admins"
        ) {
          localStorage.setItem("role", "admin");
        }

        if (
          response.data.data.role?.key === dynamicRole ||
          response.data.data.preferredCompany.isSuperAdmin
        ) {
          setIsSuperAdmin(true);
          localStorage.setItem("role", "superAdmins");
        }

        console.log(isAuditor, "isAuditorFromContext");
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    ProfileDetail();
  }, []);

  useEffect(() => {
    console.log(isAuditor, "isAuditorFromKontext");
  }, []);
  console.log(Loader);
  return (
    <Box>
      {Loader || (loading && <ProgressbarTransparent />)}
      {
        <Container
          maxWidth="xl"
          sx={{ bgcolor: "#F6F8FC", p: 1, pb: 10, borderRadius: 2 }}
        >
          <h2 className="company-head">Recent</h2>
          <div style={{ float: "right" }}>
            <Tooltip
              title={
                <div style={{ maxWidth: "300px" }}>
                  <strong>Company listing Page:</strong>
                  <p>
                    Shows the list of companies listed for the logged in Admin
                  </p>
                </div>
              }
              arrow
              interactive
            >
              <InfoIcon
                style={{
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: "#D76C00",
                }}
              />
            </Tooltip>
          </div>
          {recent1 && recent1 !== undefined && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card sx={{ height: "100%" }} className="card card-list" s>
                  <CardContent className="zero-space">
                    <img
                      onClick={() => handleClickOpen(row)}
                      src={Edit}
                      className="company-edit"
                      alt="edit"
                    />
                    {isAuditor ? (
                      <Link
                        to={"/auditor-home"}
                        state={{ companyId: recent1._id }}
                        onClick={() => {
                          localStorage.setItem("companyId", recent1._id);
                        }}
                        className="company-list-link"
                      >
                        <Grid container spacing={0} className="max-width">
                          <Grid item xs={6}>
                            <div className="company-image-div">
                              <img
                                src={
                                  recent1?.logo?.url
                                    ? recent1?.logo?.url
                                    : LogoImage
                                }
                                alt="logo"
                                className="company-image-logo"
                                style={{ marginTop: "10px" }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} className="text-content">
                            <Typography
                              variant="p"
                              component="div"
                              sx={{
                                pt: 1,
                                textAlign: "start",
                                fontWeight: "bold",
                              }}
                            >
                              {recent1.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ pt: 8, textAlign: "start" }}
                            >
                              {moment(recent1.createdAt).format("lll")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    ) : (
                      <Link
                        to={"/dashboard"}
                        state={recent1._id}
                        className="company-list-link"
                      >
                        <Grid container spacing={0} className="max-width">
                          <Grid item xs={6}>
                            <div className="company-image-div">
                              <img
                                src={
                                  recent1?.logo?.url
                                    ? recent1?.logo?.url
                                    : LogoImage
                                }
                                alt="logo"
                                className="company-image-logo"
                                style={{ marginTop: "10px" }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} className="text-content">
                            <Typography
                              variant="p"
                              component="div"
                              sx={{
                                pt: 1,
                                textAlign: "start",
                                fontWeight: "bold",
                              }}
                            >
                              {recent1.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ pt: 8, textAlign: "start" }}
                            >
                              {moment(recent1.createdAt).format("lll")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          {/* <h2 className="company-head">All Company List</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card sx={{ height: "100%" }} className="card card-list">
                <CardContent sx={{ color: "#F26539" }} className="zero-space">
                  <AccountBalanceOutlinedIcon sx={{ fontSize: 100 }} />
                  <Link to={"/company"} className="submenu-item">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <AddIcon />
                      Add New
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>

            {companyListData &&
              companyListData.length > 0 &&
              companyListData.map((item, i) => {
                return (
                  <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                    <Card
                      sx={{ height: "100%" }}
                      className="card card-list"
                      key={i}
                    >
                      <CardContent className="zero-space">
                        <img
                          onClick={() => handleClickOpen(item)}
                          src={Edit}
                          className="company-edit"
                          alt="edit"
                        />
                        <Link
                          to={"/dashboard"}
                          state={item._id}
                          // onClick={handleClick(item)}
                          className="company-list-link"
                        >
                          <Grid container spacing={0} className="max-width">
                            <Grid item xs={6}>
                              <div className="company-image-div">
                                <img
                                  src={
                                    item?.logo?.url
                                      ? item?.logo?.url
                                      : LogoImage
                                  }
                                  alt="logo"
                                  className="company-image-logo"
                                  style={{ marginTop: "10px" }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={4} className="text-content">
                              <Typography
                                variant="p"
                                component="div"
                                sx={{
                                  pt: 1,
                                  textAlign: "start",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ pt: 8, textAlign: "start" }}
                              >
                                {moment(item.createdAt).format("lll")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid> */}

          <h2 className="company-head">All Company List</h2>
          {/* <Grid container spacing={2}>
            {showCompanyList && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card sx={{ height: "100%" }} className="card card-list">
                  <CardContent sx={{ color: "#F26539" }} className="zero-space">
                    <AccountBalanceOutlinedIcon sx={{ fontSize: 100 }} />
                    <Link to={"/company"} className="submenu-item">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <AddIcon />
                        Add New
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {companyListData &&
              companyListData.length > 0 &&
              companyListData.map((item, i) => {
                return (
                  <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                    <Card
                      sx={{ height: "100%" }}
                      className="card card-list"
                      key={i}
                    >
                      <CardContent className="zero-space">
                        <img
                          onClick={() => handleClickOpen(item)}
                          src={Edit}
                          className="company-edit"
                          alt="edit"
                        />
                        {isAuditor ? (
                          <>
                            <Link
                              to={"/auditor-home"}
                              state={{ companyId: item._id }}
                              onClick={() => {
                                localStorage.setItem("companyId", item._id);
                              }}
                              className="company-list-link"
                            >
                             
                              <Grid container spacing={0} className="max-width">
                                <Grid item xs={6}>
                                  <div className="company-image-div">
                                    <img
                                      src={
                                        item?.logo?.url
                                          ? item?.logo?.url
                                          : LogoImage
                                      }
                                      alt="logo"
                                      className="company-image-logo"
                                      style={{ marginTop: "10px" }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={4} className="text-content">
                                  <Typography
                                    variant="p"
                                    component="div"
                                    sx={{
                                      pt: 1,
                                      textAlign: "start",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ pt: 8, textAlign: "start" }}
                                  >
                                    {moment(item.createdAt).format("lll")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              to={"/dashboard"}
                              state={item._id}
                              className="company-list-link"
                            >
                              <Grid container spacing={0} className="max-width">
                                <Grid item xs={6}>
                                  <div className="company-image-div">
                                    <img
                                      src={
                                        item?.logo?.url
                                          ? item?.logo?.url
                                          : LogoImage
                                      }
                                      alt="logo"
                                      className="company-image-logo"
                                      style={{ marginTop: "10px" }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={4} className="text-content">
                                  <Typography
                                    variant="p"
                                    component="div"
                                    sx={{
                                      pt: 1,
                                      textAlign: "start",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ pt: 8, textAlign: "start" }}
                                  >
                                    {moment(item.createdAt).format("lll")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Link>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid> */}
          <Grid container spacing={2}>
            {showCompanyList && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card sx={{ height: "100%" }} className="card card-list">
                  <CardContent sx={{ color: "#F26539" }} className="zero-space">
                    <AccountBalanceOutlinedIcon sx={{ fontSize: 100 }} />
                    <Link
                      to={isSuperAdmin ? "/company" : "#"}
                      className={`submenu-item ${
                        !isSuperAdmin ? "disabled" : ""
                      }`}
                      title={
                        !isSuperAdmin
                          ? "You cannot create a new company. Please login with super admin."
                          : ""
                      }
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <AddIcon />
                        Add New
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {companyListData &&
              companyListData.length > 0 &&
              companyListData.map((item, i) => {
                return (
                  <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                    <Card
                      sx={{ height: "100%" }}
                      className="card card-list"
                      key={i}
                    >
                      <CardContent className="zero-space">
                        <img
                          onClick={() => handleClickOpen(item)}
                          src={Edit}
                          className="company-edit"
                          alt="edit"
                        />
                        {isAuditor ? (
                          <>
                            <Link
                              to={"/auditor-home"}
                              state={{ companyId: item._id }}
                              onClick={() => {
                                localStorage.setItem("companyId", item._id);
                              }}
                              className="company-list-link"
                            >
                              <Grid container spacing={0} className="max-width">
                                <Grid item xs={6}>
                                  <div className="company-image-div">
                                    <img
                                      src={
                                        item?.logo?.url
                                          ? item?.logo?.url
                                          : LogoImage
                                      }
                                      alt="logo"
                                      className="company-image-logo"
                                      style={{ marginTop: "10px" }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={4} className="text-content">
                                  <Typography
                                    variant="p"
                                    component="div"
                                    sx={{
                                      pt: 1,
                                      textAlign: "start",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ pt: 8, textAlign: "start" }}
                                  >
                                    {moment(item.createdAt).format("lll")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              to={"/dashboard"}
                              state={item._id}
                              className="company-list-link"
                            >
                              <Grid container spacing={0} className="max-width">
                                <Grid item xs={6}>
                                  <div className="company-image-div">
                                    <img
                                      src={
                                        item?.logo?.url
                                          ? item?.logo?.url
                                          : LogoImage
                                      }
                                      alt="logo"
                                      className="company-image-logo"
                                      style={{ marginTop: "10px" }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={4} className="text-content">
                                  <Typography
                                    variant="p"
                                    component="div"
                                    sx={{
                                      pt: 1,
                                      textAlign: "start",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ pt: 8, textAlign: "start" }}
                                  >
                                    {moment(item.createdAt).format("lll")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Link>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>

          <CompanyModal
            open={open}
            handleClose={handleClose}
            editCompany={editCompany}
          />
        </Container>
      }
    </Box>
  );
};

export default CompanyList;
