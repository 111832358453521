import JwtAuthService from "../apiConfig/ApiConfig";
// import SignUpApiCall from "../apiConfig/ApiConfig";
import {
  SIGN_UP_ERROR,
  SIGN_UP_FORM_ERROR,
  SIGN_UP_FORM_REQUEST,
  SIGN_UP_FORM_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  VERIFICATION_ERROR,
  VERIFICATION_REQUEST,
  VERIFICATION_SUCCESS,
} from "../types/Types";
import { toast } from "react-toastify";

export const SignupRequest = (signup) => ({
  type: SIGN_UP_REQUEST,
  payload: signup,
});
export const SignupSuccess = (signup) => ({
  type: SIGN_UP_SUCCESS,
  payload: signup,
});
export const SignupFailed = (error) => ({
  type: SIGN_UP_ERROR,
  payload: error,
});

export const SignupApi = (email) => (dispatch) => {
  try {
    dispatch(SignupRequest(email));
    JwtAuthService.PostApiService(email).then((response) => {
      if (response?.status === 200) {
        const data = response?.data;
        dispatch(SignupSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(SignupFailed(response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(SignupFailed(error));
    // toast.error(`${error}`);
  }
};

/* VERIFICATION CODE API */

export const VerifyCodeRequest = (code) => ({
  type: VERIFICATION_REQUEST,
  payload: code,
});
export const VerifyCodeSuccess = (code) => ({
  type: VERIFICATION_SUCCESS,
  payload: code,
});
export const VerifyCodeFailed = (error) => ({
  type: VERIFICATION_ERROR,
  payload: error,
});

export const VerifyCodeApi = (code) => (dispatch) => {
  try {
    dispatch(VerifyCodeRequest(code));

    JwtAuthService.PostApiService(code).then((response) => {
      if (response?.status === 200) {
        const data = response?.data;
        dispatch(VerifyCodeSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(VerifyCodeFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(VerifyCodeFailed(error));
    toast.error(`${error}`);
    console.error(error);
  }
};

/* SIGNUPFORM API */

export const SignupFormRequest = (formvalue) => ({
  type: SIGN_UP_FORM_REQUEST,
  payload: formvalue,
});
export const SignupFormSuccess = (formvalue) => ({
  type: SIGN_UP_FORM_SUCCESS,
  payload: formvalue,
});
export const SignupFormFailed = (error) => ({
  type: SIGN_UP_FORM_ERROR,
  payload: error,
});

export const SignupFormApi = (data) => (dispatch) => {
  try {
    dispatch(SignupFormRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response?.data;
        dispatch(SignupFormSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(SignupFormFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(SignupFormFailed(error));
    console.error(error);
  }
};
