import React, { useState } from "react";
import { useEffect } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

function ImgEditor({ imgsrc, onClose, handleSave }) {
  const [imgBase, setImgBase] = useState("");
  const [error, setError] = useState("");

  function download(url, filename) {
    fetch(imgsrc)
      .then((response) => response.blob())
      .then((b) => {
        var blob = new Blob([b], { type: "image/png" });
        var reader = new FileReader();

        reader.onload = function (event) {
          var base64String = event.target.result;

          setImgBase(base64String);
        };

        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        setError("Something went wrong!");
      });
  }

  useEffect(() => {
    download();
  }, []);

  return (
    // <div>
    //   <div>
        <div style={{
          height: "100%"
        }}>
          {error ? <div style={{
            textAlign: "center"
          }}>{error}</div> : ""}
          {imgBase && (
            <FilerobotImageEditor
              source={imgBase}
              onSave={(editedImageObject, designState) =>
                handleSave(editedImageObject, designState)
              }
              onClose={onClose}
              annotationsCommon={{
                fill: "#000000",
              }}
              Text={{ text: "Write here..." }}
              Rotate={{ angle: 90, componentType: "slider" }}
              tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
              defaultTabId={TABS.ANNOTATE}
              defaultToolId={TOOLS.TEXT}
              disableZooming={true}
              Crop={{
                autoResize: false,
              }}
            />
          )}
        </div>
    //   </div>
    // </div>
  );
}

export default ImgEditor;
