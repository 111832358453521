import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { RulesAddedApi } from "../../../redux/actions/Checklist";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SelectRuleIssueModal from "./SelectRuleIssueModal";

const MenuProps = {
  disableScrollLock: true,
};

const RulesSelectionQustion = ({ ...props }) => {
  const optionsAnswer = [
    { id: 1, value: "=", title: "=", title_to_show: "=" },
    { id: 2, value: ">", title: ">", title_to_show: ">" },
    { id: 3, value: ">=", title: ">=", title_to_show: ">=" },
    { id: 4, value: "<", title: "<", title_to_show: "<" },
    { id: 5, value: "<=", title: "<=", title_to_show: "<=" },
  ];

  const rules = localStorage.getItem("rules");
  const dispatch = useDispatch();
  const [ruleList, setRuleList] = useState([]);
  const [firstData, setFirstData] = useState(JSON.parse(rules) || []);
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [uiRender, setUiRender] = useState(false);
  const [skitqustions,setSkipQustions] = useState([])
  const questionTitle = props.questionData;
  const listDatas = props && props.listData;
  const rulesdata = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.ruleslist &&
      state.checklist.ruleslist.data &&
      state.checklist.ruleslist.data.data
  );

  useEffect(() => {
    setRuleList(rulesdata);
  }, [rulesdata]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `rule/template?type=1&company_id=${id}` };
    dispatch(RulesAddedApi(data));
  }, []);

  useEffect(() => {
    props.setRulesData(firstData);
    localStorage.setItem("rules", JSON.stringify(firstData))
  }, [firstData]);

  const handleDelete = (index) => {
    setFirstData(firstData.filter((item, i) => i !== index));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const listData = (values) => {
    setFormValues(values);
  };

  const handleChangeRules = (event, items, index) => {
    let allRules = ruleList;

    if (event.target.name === "category") {
      allRules[index].category = event.target.value;
      // items.condition = event.target.value
    } else if (event.target.name === "questions") {
      allRules[index].questions = event.target.value;
      // items.answer = event.target.value
    } else if (event.target.name === "question") {
      allRules[index].question = event.target.value;
    } else if (event.target.name === "condition") {
      allRules[index].condition = event.target.value;
    } else if (event.target.name === "questionssec") {
      allRules[index].questionssec = event.target.value;
    } else if (event.target.name === "answer") {
      allRules[index].answer = event.target.value;
    } else if (event.target.name === "conditionthird") {
      allRules[index].conditionthird = event.target.value;
    } else {
      allRules[index].answerthird = event.target.value;
    }
    setRuleList(allRules);
    setUiRender(!uiRender);
    if(items?.question?.title){
      let arr = [];
      for (let item of questionTitle) {
        if(items?.question?.title !== item.title){
          let obj1 = item;
          arr.push(obj1);
       
        }
       
      }
      setSkipQustions(arr)
    }
  };

  const handleFirst = (event, index, formValues) => {
    let item = event;
  
    if (event.category && event.questions) {
      let data = {
        category:{id:index,title:event.category,title_to_show:event.category},
        questions: event.questions,
        
      };
      const require = {}
      const newData = JSON.stringify(item);
      let newData1 = JSON.parse(newData);
      newData1.data = data;
      newData1.requires = require
      delete newData1.questions;
      delete newData1.category;
      setFirstData([...firstData, newData1]);
      setTimeout(() => {
        let allRules = ruleList;
        allRules[index].category = "";
        allRules[index].questions = "";
        setRuleList(allRules);
        setUiRender(!uiRender);
      }, 500);
    } else if (
      event.question &&
      event.condition &&
      event.answer &&
      event.questionssec
    ) {
      
      let data = {
        question: event.question,
        condition:{id: index, title:event.condition, key: event.condition, title_to_show: event.condition} ,
        answer:{title: event.answer, value: false, title_to_show:  event.answer},
        questions: [event.questionssec],
      };
   const requiresec = {};
      const newData = JSON.stringify(item);
      let newData1 = JSON.parse(newData);
      newData1.data = data;
         newData1.requires = requiresec
 delete newData1.questionssec;
 delete newData1.question;
 delete newData1.answer;
 delete newData1.condition;
      setFirstData([...firstData, newData1]);
      setTimeout(() => {
        let allRules = ruleList;
        allRules[index].question = "";
        allRules[index].condition = "";
        allRules[index].answer = "";
        allRules[index].questionssec = "";
        setRuleList(allRules);
        setUiRender(!uiRender);
      }, 500);
    } else if (event.conditionthird && event.answerthird && formValues) {
      let data = {
        answer: event.answerthird,
        condition:{id: index, title: event.conditionthird, key:event.conditionthird, title_to_show:event.conditionthird},
        issueTemplate: formValues,
      };
      const requirethird = {}
      console.log("item", item);

      const newData = JSON.stringify(item);
      let newData1 = JSON.parse(newData);
      newData1.data = data;
      newData1.requires = requirethird
      delete newData1.answerthird;
      delete newData1.conditionthird;
      setFirstData([...firstData, newData1]);
      setTimeout(() => {
        let allRules = ruleList;
        allRules[index].answerthird = "";
        allRules[index].conditionthird = "";
        allRules[index].issueTemplate = "";
        setRuleList(allRules);
        setUiRender(!uiRender);
      }, 500);
    } else {
      toast.error("All fields are required");
    }
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      <Grid
        container
        spacing={1}
        sx={{ textAlign: "left", display: "flex", justifyContent: "center" }}
        mt={2}
      >
        <Grid
          item
          xs={12}
          style={{ maxWidth: "1200px", padding: "0px 24px", width: "100%" }}
        >
          <Box sx={{ fontWeight: "bold" }} className="form-head">
            Rules
          </Box>
          <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>Rules Preset</Box>
          {ruleList &&
            ruleList.map((items, i) => {
              return (
                <Card
                  sx={{ p: 3, mb: 2, borderRadius: "8px", color: "#27364b" }}
                  key={i}
                >
                  <Grid
                    container
                    spacing={1}
                    sx={{ textAlign: "left", padding: "20px 0px 20px 0px" }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={11}>
                      <Stack
                        direction="row"
                        spacing={1}
                        className="list-btn third-list"
                      >
                        <Typography>
                          {items?.template?.split(/[{,}]/)[0]}
                        </Typography>
                        {items?.template?.split(/[{,}]/)[1] === "category" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="category"
                              value={
                                items && items.category
                                  ? items.category
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={MenuProps}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              {items?.variables?.map((select, i) =>
                                select?.options?.map((item, i) => {
                                  return (
                                    <MenuItem key={i} value={item.title}>
                                      {item.title}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                        <Typography>
                          {items?.template?.split(/[{,}]/)[2] ===
                            " then ask only questions "
                            ? items?.template?.split(/[{,}]/)[2]
                            : ""}
                        </Typography>
                        {items?.template?.split(/[{,}]/)[3] === "questions" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="questions"
                              value={
                                items && items.questions
                                  ? items.questions
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={MenuProps}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              {questionTitle &&
                                questionTitle.map((select, i) => {
                                  return (
                                    <MenuItem key={i} value={select}>
                                      {select?.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                        {items?.template?.split(/[{,}]/)[1] === "question" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="question"
                              value={
                                items && items.question
                                  ? items.question
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={MenuProps}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              {questionTitle &&
                                questionTitle.map((select, i) => {
                                  return (
                                    <MenuItem key={i} value={select}>
                                      {select?.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                        <Typography>
                          {items?.template?.split(/[{,}]/)[2] === " is "
                            ? items?.template?.split(/[{,}]/)[2]
                            : ""}
                        </Typography>
                        {items?.template?.split(/[{,}]/)[1] === "condition" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="conditionthird"
                              value={
                                items && items.conditionthird
                                  ? items.conditionthird
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={{
                                disableScrollLock: true,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "center",
                                },
                                PaperProps: {
                                  sx: {
                                    height: "150px",
                                  },
                                },
                              }}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              {optionsAnswer &&
                                optionsAnswer.map((select, i) => {
                                  return (
                                    <MenuItem key={i} value={select?.value}>
                                      {select?.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                        <Typography>
                          {items?.template?.split(/[{,}]/)[2] === " to "
                            ? items?.template?.split(/[{,}]/)[2]
                            : ""}
                        </Typography>
                        {items?.template?.split(/[{,}]/)[3] === "condition" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="condition"
                              value={
                                items && items.condition
                                  ? items.condition
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={MenuProps}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              <MenuItem key={i} value="=">
                                =
                              </MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                        {items?.template?.split(/[{,}]/)[3] === "answer" ? (
                          <FormControl className="Rules-preset-form">
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                              placeholder="Enter weightage score"
                              type="number"
                              name="answerthird"
                              value={
                                items && items.answerthird
                                  ? items.answerthird
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              className="date-input"
                            />
                          </FormControl>
                        ) : (
                          ""
                        )}
                        <Typography>
                          {items?.template?.split(/[{,}]/)[4]}
                        </Typography>
                        {items?.template?.split(/[{,}]/)[5] === "answer" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="answer"
                              value={
                                items && items.answer ? items.answer : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={MenuProps}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              <MenuItem value="yes">yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                        {items?.template?.split(/[{,}]/)[5] ===
                          "issueTemplate" ? (
                          <Stack
                            direction="row"
                            spacing={1}
                            className="list-btn"
                            sx={{ alignItems: "center", margin: "5px 0 0 0" }}
                          >
                            <Button
                              onClick={handleClickOpen}
                              className="select-issues cancle-hover"
                            >
                              Select Issue Template
                            </Button>
                          </Stack>
                        ) : (
                          ""
                        )}
                        <br />
                        <Typography>
                          {items?.template?.split(/[{,}]/)[6]}
                        </Typography>
                        {items?.template?.split(/[{,}]/)[7] === "questions" ? (
                          <FormControl className="Rules-preset-form">
                            <Select
                              labelId="demo-simple-select-label"
                              size="small"
                              id="demo-simple-select"
                              defaultValue="select"
                              name="questionssec"
                              value={
                                items && items.questionssec
                                  ? items.questionssec
                                  : "select"
                              }
                              onChange={(e) => handleChangeRules(e, items, i)}
                              MenuProps={MenuProps}
                              sx={{ borderRadius: "8px" }}
                            >
                              <MenuItem disabled value="select">
                                <em
                                  style={{
                                    fontStyle: "unset",
                                    color: "darkgrey",
                                  }}
                                >
                                  Select Condition
                                </em>
                              </MenuItem>
                              {skitqustions &&
                                skitqustions.map((select, i) => {
                                  return (
                                    <MenuItem key={i} value={select}>
                                      {select?.title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={1}
                      className="plus-icon-grid"
                    >
                      <Box sx={{ float: "right" }} className="plus-icon">
                        <AddOutlinedIcon
                          onClick={(e) => handleFirst(items, i, formValues)}
                          sx={{ mr: 1 }}
                          className="add-icon"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
          {/* {firstData &&
            firstData.map((item, i) => {
              return (
                <Box
                  sx={{ display: "flex" }}
                  className="delete-icons list-view"
                >
                  <p>
                    When category of store is "<b>{item?.data?.category?.title}</b>" to
                    <Divider sx={{ mt: 1, mb: 2 }} />"
                    {item?.data?.questions?.title}" then allow comment.
                  </p>
                  <Divider />
                  <Box>
                    <IconButton onClick={() => handleDelete(i)}>
                      <DeleteOutlineIcon sx={{ color: "#e74a3b" }} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })} */}
          <Card sx={{ p: 3, mb: 1, borderRadius: "8px" }} className="card">
            <h3>Your Rules</h3>
            {/* {firstData &&
              firstData.length > 0 &&
              firstData.rules &&
              firstData.map((items, i) => {
                return (
                  <Card sx={{ mb: 2, pt: 2, pb: 2 }}>
                    <Container>
                      <Box sx={{ display: "flex" }}>
                        <Typography>
                          {items?.template?.split(/[{,}]/)[0]}
                        </Typography>
                        <Typography>
                          &nbsp; "
                          <b>
                            {items?.template?.split(/[{,}]/)[1] === "category"
                              ? items.data?.category?.title
                              : ""}
                          </b>
                          " &nbsp;{" "}
                        </Typography>
                        <Typography>
                          {items?.template?.split(/[{,}]/)[2]} &nbsp;
                        </Typography>
                        <Typography>
                          {items?.template?.split(/[{,}]/)[4]}
                        </Typography>
                      </Box>
                      <Divider sx={{ mb: 2, mt: 2 }} />
                      <ol>
                        <li>{items?.questions?.title}</li>
                      </ol>
                    </Container>
                  </Card>
                );
              })} */}
            {firstData &&
              firstData.map((items, i) => (
                <Grid
                  container
                  spacing={1}
                  sx={{ textAlign: "left", margin: "5px", padding: "5px" }}
                >
                  <Grid item xs={11}>
                    <Box>
                      <Box sx={{ display: "flex" }}>
                        {/* <Typography>
                          {items?.template?.split(/[{,}]/)[0]}
                        </Typography>
                        <Typography>
                          &nbsp; "
                          <b>
                            {items?.template?.split(/[{,}]/)[1] === "condition"
                              ? items.data?.question
                              : ""}
                          </b>
                          " &nbsp;{" "}

                        </Typography> */}
                         <Typography>
                          {items?.template?.split(/[{,}]/)[0]}
                        </Typography>
                        <Typography>
                          &nbsp; "
                          <b>
                            {items?.template?.split(/[{,}]/)[1] === "category"
                              ? items.data?.category?.title
                              :  items?.template?.split(/[{,}]/)[1] === "question" ? items.data?.question?.title : items?.data?.answer ? items?.data?.condition?.title : ''}
                          </b> 
                          " &nbsp;{" "}
                        </Typography>
                        <Typography>
                          {items?.template?.split(/[{,}]/)[2]}  
                        </Typography>
                       {items?.data?.answer && <><Typography>&nbsp; "<b>{ items?.data?.answer.title || items?.data?.answer }</b>" &nbsp;</Typography></>}
                        <Typography>
                          {items?.template?.split(/[{,}]/)[4]} &nbsp;
                        </Typography>
                        <Typography>
                          {items?.template?.split(/[{,}]/)[6]} &nbsp;
                        </Typography>                    
                        {/* {items?.questionssec && <> <Typography>&nbsp; "<b>{ items?.questionssec?.title }</b>" &nbsp;</Typography></>} */}
                      </Box>
                      <Divider sx={{ mb: 2, mt: 2 }} />
                      <ul style={{paddingLeft:'0'}}>
                      <li> {items?.data?.questions?.title && i + 1} {items?.data?.questionssec && i + 1} {items?.data?.questions?.title }    {items?.data?.questionssec && items?.data?.questionssec?.title } </li>
                      </ul>
                      <Divider />
                      {items?.template?.split(/[{,}]/)[5] ===
                        "issueTemplate" ? (
                        <Box>
                              {/* <Typography>
                          {items?.template?.split(/[{,}]/)[0]}
                        </Typography> */}
                        {items?.conditionthird && <><Typography>&nbsp; "<b>{ items?.conditionthird }</b>" &nbsp;</Typography></>}
                          <h4>
                            <b>
                              Observation : {items?.data?.issueTemplate?.issue}
                            </b>
                          </h4>
                          <p>
                            {items?.data?.issueTemplate?.category} {"=>"}{" "}
                            {items?.data?.issueTemplate?.subcategory}
                          </p>
                          <p>
                            IssuePriority :{" "}
                            <b>{items?.data?.issueTemplate?.severity}</b>
                          </p>
                        </Box>
                      ) : (
                        ""
                      )}
                      {/* <Typography>
                        {" "}
                        {items?.template?.split(/[{,}]/)[6]}
                      </Typography> */}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <IconButton
                      onClick={() => handleDelete(i)}
                      className="fit-content"
                    >
                      <DeleteOutlineIcon sx={{ color: "#e74a3b" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
          </Card>
        </Grid>
      </Grid>
      <SelectRuleIssueModal
        open={open}
        handleClose={handleClose}
        listData={listData}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default RulesSelectionQustion;
