import {
  LIST_DEPARTMENT_REQUEST,
  LIST_DEPARTMENT_SUCCESS,
  LIST_DEPARTMENT_ERROR,
  DEPARTMENT_DETAILS_REQUEST,
  DEPARTMENT_DETAILS_SUCCESS,
  DEPARTMENT_DETAILS_ERROR,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_ERROR,
  EDIT_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_ERROR,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  DEPARTMENT_ARCHIVED_REQUEST,
  DEPARTMENT_ARCHIVED_SUCCESS,
  DEPARTMENT_ARCHIVED_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  data: [],
  archivedDepartmrentData: [],
  dept: "",
  res: "",
  update: "",
  delete: [],
};

const DepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DEPARTMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        loading: false,
      };
    }
    case LIST_DEPARTMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DEPARTMENT_ARCHIVED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DEPARTMENT_ARCHIVED_SUCCESS: {
      return {
        ...state,
        archivedDepartmrentData: action?.payload,
        loading: false,
      };
    }
    case DEPARTMENT_ARCHIVED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DEPARTMENT_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DEPARTMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: action?.payload,
      };
    }
    case DEPARTMENT_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CREATE_DEPARTMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CREATE_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        dept: action?.payload,
        loading: false,
      };
    }
    case CREATE_DEPARTMENT_ERROR: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case GET_DEPARTMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GET_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        res: action?.payload,
      };
    }
    case GET_DEPARTMENT_ERROR: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case EDIT_DEPARTMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        update: action?.payload,
      };
    }
    case EDIT_DEPARTMENT_ERROR: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    case DELETE_DEPARTMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        delete: action?.payload,
      };
    }
    case DELETE_DEPARTMENT_ERROR: {
      return {
        ...state,
        loading: true,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default DepartmentReducer;
