/** @format */

import {
  Box,
  Card,
  CardContent,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import POHeader from "../Pages/POHeader";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { StorePerformanceCountAction } from "../../../redux/actions/Checklist";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Progressbar from "../../../GlobalProgress/Progressbar";
import View from "../../../images/View.svg";
import { useNavigate } from "react-router-dom";
import ViewHover from "../../../images/ViewHover.svg";
import { ListSubroleApi } from "../../../redux/actions/Roles";

const defaultColumns = [
  {
    field: "NAME",
    headerName: "NAME",
    renderHeader: () => (
      <Tooltip title="NAME">
        <strong>{"NAME"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Name) {
        result.push(params?.row?.Name);
      } else {
        result = ["N/A"];
      }
      return result.join(", ");
    },
  },
  {
    field: "ASSIGNED",
    headerName: "ASSIGNED",
    renderHeader: () => (
      <Tooltip title="ASSIGNED">
        <strong>{"ASSIGNED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalcreated) {
        result.push(params?.row?.Totalcreated ? params?.row?.Totalcreated : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "COMPLETED",
    headerName: "COMPLETED",
    renderHeader: () => (
      <Tooltip title="COMPLETED">
        <strong>{"COMPLETED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalsubmit) {
        result.push(params?.row?.Totalsubmit ? params?.row?.Totalsubmit : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "MISSED",
    headerName: "MISSED",
    renderHeader: () => (
      <Tooltip title="MISSED">
        <strong>{"MISSED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalmissed) {
        result.push(params?.row?.Totalmissed ? params?.row?.Totalmissed : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "LOCATION",
    headerName: "LOCATION",
    renderHeader: () => (
      <Tooltip title="LOCATION">
        <strong>{"LOCATION"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Location) {
        result.push(params?.row?.Location ? params?.row?.Location : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
];

const ViewAllStoreList = () => {
  const dispatch = useDispatch();
  const storedMonthRecord = JSON.parse(localStorage.getItem("monthRecord"));
  const [monthRecord, setmonthRecord] = React.useState(storedMonthRecord);
  const [DynamicAllStore, setDynamicAllStore] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [hover, setHover] = React.useState({ View: false });
  const [indexHover, setIndexHover] = React.useState(0);
  const navigate = useNavigate();
  const [subRole, setSubRole] = React.useState([]);

  // client side pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickUserView = (row) => {
    localStorage.removeItem("UserIdFromFromDashBoard");
    localStorage.removeItem("UserIdFromViewAllUserList");
    localStorage.removeItem("UserIdFromSingleStoreViewPage");

    localStorage.removeItem("UserNameFromFromDashBoard");
    localStorage.removeItem("UserNameFromViewAllUserList");
    localStorage.removeItem("UserNameFromSingleStoreViewPage");

    localStorage.setItem("StoreIdFromViewAllStoreList", row?._id);
    localStorage.setItem("StoreNameFromViewAllStoreList", row?.Name);

    navigate(
      "/checklist/checklist-report/view-all-store-list/store-performance-view",
      {
        state: row,
      }
    );
  };

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  const StorePerformanceCountData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkRepStorePerfCount &&
      state?.checklist?.checkRepStorePerfCount?.data &&
      state?.checklist?.checkRepStorePerfCount?.data?.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  useEffect(() => {
    if (StorePerformanceCountData && StorePerformanceCountData.length > 0) {
      setDynamicAllStore(StorePerformanceCountData);
      let totlaColumns = [...defaultColumns];
      let index = 0;
      if (monthRecord == "OneDay") {
        const dateVal =
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length &&
          StorePerformanceCountData[0].Submission[0].Date
            ? new Date(StorePerformanceCountData[0].Submission[0].Date)
            : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();
        const dayColumn = {
          field: "Submission",
          headerName: "Submission",
          renderHeader: () => (
            <Tooltip title={dateRes}>
              <strong>{dateRes}</strong>
            </Tooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let result = [];
            const dateValCount =
              params.row &&
              params.row.Submission &&
              params.row.Submission[0] &&
              params.row.Submission[0].submissionCount
                ? (params.row.Submission[index].submissionCount /
                    params.row.Submission[index].checklistCount) *
                  100
                : 0;
            result.push(Math.round(dateValCount) + "%");
            return result.join(", ");
          },
        };
        totlaColumns.push(dayColumn);
      } else if (monthRecord == "OneWeek") {
        if (
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length
        ) {
          StorePerformanceCountData[0].Submission &&
            StorePerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes =
                dateVal
                  .toLocaleString("en-us", { weekday: "short" })
                  .toUpperCase() +
                " " +
                (dateVal.getDate() < 10
                  ? "0" + dateVal.getDate()
                  : dateVal.getDate()) +
                " " +
                dateVal
                  .toLocaleString("en-us", { month: "short" })
                  .toUpperCase();

              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[index].submissionCount
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;
                  result.push(Math.round(dateValCount) + "%");
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      } else {
        if (
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length
        ) {
          StorePerformanceCountData[0].Submission &&
            StorePerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes = dateVal
                .toLocaleString("en-us", {
                  month: "short",
                  year: "numeric",
                })
                .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[index].submissionCount
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;
                  result.push(Math.round(dateValCount) + "%");
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      }
      const actionColumn = {
        field: "ACTION",
        headerName: "ACTION",
        renderHeader: () => (
          <Tooltip title="ACTION">
            <strong>{"ACTION"}</strong>
          </Tooltip>
        ),
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onClick={() => handleClickUserView(params?.row)}
                onMouseOver={() => {
                  setHover({ View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ View: false });
                }}
              />
            </div>
          );
        },
      };
      totlaColumns.push(actionColumn);
      setColumns([...totlaColumns]);
    }
  }, [StorePerformanceCountData]);

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  // Store Checklist count
  useEffect(() => {
    if (monthRecord && subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `StoreChecklistReport?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(StorePerformanceCountAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  }, []);

  const handleChangeSelect = (event) => {
    setmonthRecord(event.target.value);
  };

  return (
    <div>
      <POHeader
        handleChangeSelect={handleChangeSelect}
        monthRecord={monthRecord}
      />

      <Card sx={{ marginTop: "1%" }} className="card">
        <CardContent>
          <Box
            sx={{
              marginBottom: "2%",
              // bgcolor: "#F6F8FC",
              height: "63vh",
              mt: 1,
              p: 1,
              borderRadius: "8px",
              px: 1,
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
            }}
          >
            {Loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Progressbar />
              </div>
            ) : DynamicAllStore ? (
              <>
                <DataGrid
                  // rows={DynamicAllStore}
                  rows={DynamicAllStore.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  columns={columns}
                  getRowId={(DynamicAllStore) => DynamicAllStore?._id}
                  hideFooterPagination={true}
                  className="grid-table"
                  disableColumnMenu
                  disableSelectionOnClick={true}
                />
                <Box
                  mb={5}
                  sx={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={DynamicAllStore?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ pb: "20px" }}
                  />
                </Box>
              </>
            ) : (
              "No Data Found"
            )}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewAllStoreList;
