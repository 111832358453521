import {  CHECKLIST_COMPLETED_REQUEST, CHECKLIST_COMPLETED_SUCCESS, CHECKLIST_COMPLETED_ERROR, ISSUE_RAISED_REQUEST, ISSUE_RAISED_SUCCESS, ISSUE_RAISED_ERROR, BROADCAST_MESSAGE_REQUEST, BROADCAST_MESSAGE_SUCCESS, BROADCAST_MESSAGE_ERROR, UPCOMING_SCHEDULES_SUCCESS, UPCOMING_SCHEDULES_REQUEST, UPCOMING_SCHEDULES_ERROR, CHECKLIST_REPORTDATA_REQUEST, CHECKLIST_REPORTDATA_SUCCESS, CHECKLIST_REPORTDATA_ERROR, CHECKLIST_CHART_REQUEST, CHECKLIST_CHART_SUCCESS, DUE_SCHEDULES_REQUEST, DUE_SCHEDULES_SUCCESS, DUE_SCHEDULES_ERROR} from "../types/Types";

const initialState = {
    loading: false,
    error: '',
    data: [],
    details:[],
    message:[],
    schedule:[],
    dueSchedule:[],
    checklistData:[],
    checklistChart:[]
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKLIST_COMPLETED_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }   
        }
        case CHECKLIST_COMPLETED_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action?.payload
            }
        }
        case CHECKLIST_COMPLETED_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case ISSUE_RAISED_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case ISSUE_RAISED_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action?.payload
            }
        }
        case ISSUE_RAISED_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case BROADCAST_MESSAGE_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case BROADCAST_MESSAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                message: action?.payload
            }
        }
        case BROADCAST_MESSAGE_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case UPCOMING_SCHEDULES_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case UPCOMING_SCHEDULES_SUCCESS: {
            return {
                ...state,
                loading: false,
                schedule: action?.payload
            }
        }
        case UPCOMING_SCHEDULES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case DUE_SCHEDULES_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case DUE_SCHEDULES_SUCCESS: {
            return {
                ...state,
                loading: false,
                dueSchedule: action?.payload
            }
        }
        case DUE_SCHEDULES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case CHECKLIST_REPORTDATA_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case CHECKLIST_REPORTDATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                checklistData: action?.payload
            }
        }
        case CHECKLIST_REPORTDATA_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        case CHECKLIST_CHART_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case CHECKLIST_CHART_SUCCESS: {
            return {
                ...state,
                loading: false,
                checklistChart: action?.payload
            }
        }
        case CHECKLIST_COMPLETED_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        default:
            return state;
    }
}

export default dashboardReducer;