import React, { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Box,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Switch from "@mui/material/Switch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const AssessmentHeader = () => {
  const [checked, setChecked] = React.useState(true);
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Box sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{ float: "right" }}
              className="create-assessment-header"
            >
              {/* <Button
                variant="outlined"
                className="search-bar"
                sx={{ borderRadius: "8px" }}
              >
                Enable{" "}
                <Switch {...label} checked={checked} onChange={handleChange} />
              </Button>
              <Button
                variant="outlined"
                color="error"
                className="Delete-Error-btn cancle-hover"
              >
                <DeleteOutlineIcon sx={{ mr: 1 }} /> Delete
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AssessmentHeader;
