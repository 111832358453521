import axios from "axios";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import './ChatgptFunction.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChatgptFunction({ open, setOpen }) {
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(-1);
  const [chathistory, setChathistory] = useState([]);
  const [category, setCategory] = useState("");

  const handleChange = (e) => setQuery(e.target.value);

  const handleChatgpt = async () => {
    if (!query) return;
    try {
      if (category) {
        setLoading(true);
        const prompt = `if the query is somehow related to category then Response the query else return "Sorry Don't have Information".Here is query=${query} and Here is list of Category=${category}.Notes:- only if User ask to create/make a form then create html form. and consider next prompt is generate question related query like F&Q and provide html input text field for every question without any label`;
        const response = await axios.post(
          "https://api.openai.com/v1/engines/text-davinci-003/completions",
          {
            prompt: prompt,
            temperature: 0.1,
            max_tokens: 2000,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer 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`,
            },
          }
        );
        const generatedText = response?.data?.choices[0].text;
        if (generatedText) {
          const newEntry = { question: query, response: generatedText };
          setChathistory([...chathistory, newEntry]);
          setError("");
          setQuery("");
          return;
        }
      }
      return alert("Please Select the Category first");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyCode = (index) => {
    navigator?.clipboard?.writeText(chathistory[index].response);
    setIsCopied(index);
  };

  function handleCategory(e) {
    setCategory(e.target.value);
  }
  const handleChecklist = () => {
    toast.success("Chaecklist Created");
  };

  function handleCategoryField() {
    if (!category) {
      return toast.error("Add Categories First");
    }
    toast.success("Categoy Added Successfully, You can start chat Now!!");
  }

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        sx={{
          "& .MuiPaper-root":{
            maxWidth:"100%",
            height: "100vh"
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Chat GPT
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <div style={{ display: "flex", alignItems: "center",height:"100%" }}>
      <div
        className="input_submit_container"
        style={{
          height: "fit-content",
          margin: "0 auto",
          flexDirection: "column",
        }}
      >
        <textarea
          style={{
            borderRadius: "0.5rem 0.5rem 0 0",
            flex: "1",
            padding: "0.75rem 1rem",
            border: " 2px solid lightgrey",
            outline: "none",
            fontSize: "1.5em",
          }}
          rows={7}
          value={category}
          onChange={(e) => handleCategory(e)}
          placeholder="Please Add categories"
        />
        <button
          style={{ borderRadius: "0 0 0.5rem 0.5rem", padding: "0.75rem" }}
          onClick={handleCategoryField}
        >
          Apply
        </button>
      </div>

      <div className="chat_container">
        <div className="chat_view_container">
          {error && <p>Error: {error}</p>}
          {chathistory?.map(({ question, response }, index) => (
            <div key={index}>
              <span className="chat_query">{question}</span>
              <span className="chat_response">
                <span dangerouslySetInnerHTML={{ __html: response }} />
                <br />
                <br />
                <span>
                  Are you satisfied with Response
                  <br />
                  {!isCopied && (
                    <span
                      className="copy_tab"
                      onClick={handleChecklist}
                      style={{ marginTop: "0.5rem", display: "inline-block" }}
                    >
                      Create Checklist
                    </span>
                  )}
                </span>
                <br />
                <span className="yes_no_container">
                  
                  <span
                    className="copy_tab"
                    style={{
                      backgroundColor: index === isCopied ? "darkgray" : "",
                    }}
                    disabled={index === isCopied}
                    onClick={() => index !== isCopied && handleCopyCode(index)}
                  >
                    {index === isCopied ? "Thanks for Response" : "Yes"}
                  </span>
                </span>
              </span>
            </div>
          ))}
        </div>
        <div className="input_submit_container">
          <input
            onChange={handleChange}
            type="text"
            name="question"
            placeholder="Enter Query"
            value={query}
          />
          <button onClick={handleChatgpt} disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ChatgptFunction;
