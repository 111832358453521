import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EditDepartmentApi,
  GetDepartmentApi,
} from "../../redux/actions/Department";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const EditDepartment = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState([]);
  const [newFieldValue, setNewFieldValue] = useState({});

  const FormData = useSelector(
    (state) =>
      state.department &&
      state.department.res &&
      state.department.res.data &&
      state.department.res.data.data
  );
  const EditDepartment = useSelector(
    (state) =>
      state.department &&
      state.department.update &&
      state.department.update.data
  );

  useEffect(() => {
    if (EditDepartment && EditDepartment.status === 200) {
      // toast.success(EditDepartment.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/department");
      }, 2000);
    }
  }, [EditDepartment]);

  useEffect(() => {
    setInputValue(FormData);
  }, [FormData]);

  useEffect(() => {
    getDataDetails();
  }, [dispatch]);

  useEffect(() => {
    setInputValue(inputValue);
    setNewFieldValue({
      deptName: inputValue?.deptName,
      deptManager: inputValue?.deptManager,
      managerEmail: inputValue?.managerEmail,
      managerMobileNumber: inputValue?.managerMobileNumber,
      deptAddr: inputValue?.deptAddr,
      deptState: inputValue?.deptState,
      deptCity: inputValue?.deptCity,
      deptCountry: inputValue?.deptCountry,
      deptPinCode: inputValue?.deptPinCode,
    });
  }, [inputValue]);

  const getDataDetails = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state;
    const data = { url: BASE_URL + `department/${userId}?company_id=${_id}` };
    dispatch(GetDepartmentApi(data));
  };

  const validationSchema = Yup.object({
    deptName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    managerName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    managerEmail: Yup.string().email("Please enter valid email"),
    managerMobile: Yup.string().matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Please enter valid number"
    ),
    stateName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    cityName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    countryName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    pinCode: Yup.string().max(6, "Enter maximum length of 6 characters"),
  });

  const formik = useFormik({
    initialValues: newFieldValue || {
      deptName: "",
      deptManager: "",
      managerEmail: "",
      managerMobileNumber: "",
      deptAddr: "",
      deptState: "",
      deptCity: "",
      deptCountry: "",
      deptPinCode: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        deptName: values.deptName,
        deptManager: values.managerName
          ? values.managerName
          : values.deptManager,
        managerEmail: values.managerEmail,
        managerMobileNumber: values.managerMobile
          ? values.managerMobile
          : values.managerMobileNumber,
        deptAddr: values.deptAddress ? values.deptAddress : values.deptAddr,
        deptState: values.stateName ? values.stateName : values.deptState,
        deptCity: values.cityName ? values.cityName : values.deptCity,
        deptCountry: values.countryName
          ? values.countryName
          : values.deptCountry,
        deptPinCode: values.pinCode ? values.pinCode : values.deptPinCode,
        _id: inputValue._id,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `department?company_id=${id}`;
        dispatch(EditDepartmentApi(allData));
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box className="header-card">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/department" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <TaskAltIcon sx={{ mr: 1 }} />
                    Update
                  </Button>
                  <div style={{marginRight:"8px",marginTop:"6px"}}>
                      <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                p: 1,
                textAlign: "left",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Department Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptName"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.deptName}
                  error={
                    formik.touched.deptName && Boolean(formik.errors.deptName)
                  }
                />
                <small className="error">
                  {formik.errors.deptName && formik.touched.deptName
                    ? formik.errors.deptName
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Manager Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptManager"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.deptManager}
                  error={
                    formik.touched.deptManager &&
                    Boolean(formik.errors.deptManager)
                  }
                />
                <small className="error">
                  {formik.errors.deptManager && formik.touched.deptManager
                    ? formik.errors.deptManager
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Manager Email</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="managerEmail"
                  className="issueinput-fields"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.managerEmail}
                  error={
                    formik.touched.managerEmail &&
                    Boolean(formik.errors.managerEmail)
                  }
                />
                <small className="error">
                  {formik.errors.managerEmail && formik.touched.managerEmail
                    ? formik.errors.managerEmail
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Manager Mobile</label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  size="small"
                  name="managerMobileNumber"
                  className="issueinput-fields"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.managerMobileNumber}
                  error={
                    formik.touched.managerMobileNumber &&
                    Boolean(formik.errors.managerMobileNumber)
                  }
                />
                <small className="error">
                  {formik.errors.managerMobileNumber &&
                  formik.touched.managerMobileNumber
                    ? formik.errors.managerMobileNumber
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Department Address</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptAddr"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="issueinput-fields"
                  value={formik?.values?.deptAddr}
                  error={
                    formik.touched.deptAddr && Boolean(formik.errors.deptAddr)
                  }
                />
                <small className="error">
                  {formik.errors.deptAddr && formik.touched.deptAddr
                    ? formik.errors.deptAddr
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>State Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptState"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.deptState}
                  error={
                    formik.touched.deptState && Boolean(formik.errors.deptState)
                  }
                />
                <small className="error">
                  {formik.errors.deptState && formik.touched.deptState
                    ? formik.errors.deptState
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>City Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptCity"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.deptCity}
                  error={
                    formik.touched.deptCity && Boolean(formik.errors.deptCity)
                  }
                />
                <small className="error">
                  {formik.errors.deptCity && formik.touched.deptCity
                    ? formik.errors.deptCity
                    : null}
                </small>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Country Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptCountry"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  onBlur={formik.handleBlur}
                  value={formik?.values?.deptCountry}
                  error={
                    formik.touched.deptCountry &&
                    Boolean(formik.errors.deptCountry)
                  }
                />
                <small className="error">
                  {formik.errors.deptCountry && formik.touched.deptCountry
                    ? formik.errors.deptCountry
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Pin Code</label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  size="small"
                  name="deptPinCode"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.deptPinCode
                      ? formik.values.deptPinCode
                      : inputValue?.deptdeptPinCode
                  }
                  error={
                    formik.touched.deptPinCode &&
                    Boolean(formik.errors.deptPinCode)
                  }
                />
                <small className="error">
                  {formik.errors.deptPinCode && formik.touched.deptPinCode
                    ? formik.errors.deptPinCode
                    : null}
                </small>
              </FormControl>
            </Container>
          </Box>
        </form>
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </>
  );
};

export default EditDepartment;
