/** @format */

import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CustomerLogNoPaginationApi } from "../../redux/actions/CustomerLogAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "material-ui-core";

const CLExportCsv = () => {
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState([]);

  const tableData = useSelector(
    (state) =>
      state &&
      state.CustomerLogs &&
      state.CustomerLogs.updateData &&
      state.CustomerLogs.updateData.data &&
      state.CustomerLogs.updateData.data.data &&
      state.CustomerLogs.updateData.data.data.customerLog
  );
  console.log("export csv customer log:", csvData);

  useEffect(() => {
    if (tableData) {
      setCsvData(tableData);
    }
  }, [tableData]);

  let data = [];
  csvData &&
    csvData?.length > 0 &&
    csvData?.map((item, index) => {
      data?.push({
        EmployeeCode: item?.employeeCode || "NA",
        CreatedBy: item?.createdBy?.name || "NA",
        CreatedOn: item?.createdAt
          ? moment(item.createdAt).format("lll")
          : "NA",
        Store: item?.store?.title || "NA",
        Customer: item?.custName || "NA",
        Phone: item?.custContactNumber || "NA",
        Email: item?.custEmail || "NA",
        Brand: item?.Brand || "NA",
        ModelNumber: item?.ModelNumber || "NA",
        TypeOfEnquiry: item?.TypeOfEnquiry || "NA",
        HandledBy: item?.HandledBy || "NA",
        ValueOfEnquiry: item?.valueofEnquiry || "NA",
        Status: item?.status || "NA",
      });
    });

  console.log("datadata", data);

  const customerLogWithoutPaginationAPI = () => {
    const id = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `customerLog/index?company_id=${id}&noPagination=true`,
    };
    console.log("data paginatin ", data);
    dispatch(CustomerLogNoPaginationApi(data));
  };

  useEffect(() => {
    customerLogWithoutPaginationAPI();
  }, []);

  return (
    <div>
      <CSVLink
        data={data}
        style={{ color: "white", textDecoration: "none" }}
        filename="CustomerLogData"
      >
        <Button className="header-add cancle-hover"
        style={{height:"42px",marginRight:"3px",marginTop:"1px"}}
        >
          <FileDownloadIcon sx={{ mr: 1 }} />
          Export CSV
        </Button>
      </CSVLink>
    </div>
  );
};
export default CLExportCsv;
