/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import {
  LIST_BROADCAST_REQUEST,
  LIST_BROADCAST_SUCCESS,
  LIST_BROADCAST_ERROR,
  ADD_BROADCAST_REQUEST,
  ADD_BROADCAST_SUCCESS,
  ADD_BROADCAST_ERROR,
  DELETE_BROADCAST_REQUEST,
  DELETE_BROADCAST_SUCCESS,
  DELETE_BROADCAST_ERROR,
  VIEW_BROADCAST_REQUEST,
  VIEW_BROADCAST_SUCCESS,
  VIEW_BROADCAST_ERROR,
} from "../types/Types";

export const ListBroadcast = () => ({
  type: LIST_BROADCAST_REQUEST,
});

export const ListBroadcastSuccess = (companyid) => ({
  type: LIST_BROADCAST_SUCCESS,
  payload: companyid,
});

export const ListBroadcastFailed = (error) => ({
  type: LIST_BROADCAST_ERROR,
  payload: error,
});

export const ListBroadcastApi = (data) => (dispatch) => {
  try {
    dispatch(ListBroadcast(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ListBroadcastSuccess(data));
      } else {
        dispatch(ListBroadcastFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListBroadcastFailed(error));
  }
};

export const AddBroadcastRequest = () => ({
  type: ADD_BROADCAST_REQUEST,
});

export const AddBroadcastSuccess = (data) => ({
  type: ADD_BROADCAST_SUCCESS,
  payload: data,
});

export const AddBroadcastFailed = (error) => ({
  type: ADD_BROADCAST_ERROR,
  payload: error,
});

export const AddBroadcastApi = (data) => (dispatch) => {
  try {
    dispatch(AddBroadcastRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddBroadcastSuccess(data));
      } else {
        dispatch(AddBroadcastFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddBroadcastFailed(error));
  }
};

export const DeleteBroadcastRequest = () => ({
  type: DELETE_BROADCAST_REQUEST,
});

export const DeleteBroadcastSuccess = (data) => ({
  type: DELETE_BROADCAST_SUCCESS,
  payload: data,
});

export const DeleteBroadcastFailed = (error) => ({
  type: DELETE_BROADCAST_ERROR,
  payload: error,
});

export const DeleteBroadcastApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteBroadcastRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(DeleteBroadcastSuccess(data));
      } else {
        dispatch(DeleteBroadcastFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteBroadcastFailed(error));
  }
};

export const ViewBroadcastRequest = () => ({
  type: VIEW_BROADCAST_REQUEST,
});

export const ViewBroadcastSuccess = (data) => ({
  type: VIEW_BROADCAST_SUCCESS,
  payload: data,
});

export const ViewBroadcastFailed = (error) => ({
  type: VIEW_BROADCAST_ERROR,
  payload: error,
});

export const ViewBroadcastApi = (data) => (dispatch) => {
  try {
    dispatch(ViewBroadcastRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ViewBroadcastSuccess(data));
      } else {
        dispatch(ViewBroadcastFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ViewBroadcastFailed(error));
  }
};
