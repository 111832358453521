import {
    ADD_DATA_LIST_REQUEST,
    ADD_DATA_LIST_SUCCESS,
    ADD_DATA_LIST_ERROR,
    DATA_LIST_REQUEST,
    DATA_LIST_SUCCESS,
    DATA_LIST_ERROR
} from "../types/Types";


const initialState = {
    loading: false,
    AddDataList: [],
    error: "",
    id: null,
    dataList: []
};

const DataManagerReduceer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DATA_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_DATA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                AddDataList: action.payload,
                error: "",
            };
        case ADD_DATA_LIST_ERROR:
            return {
                loader: false,
                AddDataList: [],
                error: action.error,
            };
            case DATA_LIST_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
            case DATA_LIST_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    dataList: action.payload,
                    error: "",
                };
            case DATA_LIST_ERROR:
                return {
                    loader: false,
                    dataList: [],
                    error: action.error,
                };
        // case ADD_GROUP_DELETE_REQUEST:
        //     return {
        //         ...state,
        //         loading: true,
        //     };
        // case ADD_GROUP_DELETE_SUCCESS:
        //     return {
        //         loading: false,
        //         ...state,
        //         AddGroup: state.AddGroup.filter((item, index) => item.id !== action.payload),
        //     };

        // case ADD_GROUP_DELETE_ERROR:
        //     return {
        //         loader: false,
        //         AddGroup: [],
        //         error: action.error,
        //     };
        // case ADD_GROUP_UPDATE_REQUEST:
        //     return {
        //         ...state,
        //         loading: true,
        //     };
        // case ADD_GROUP_UPDATE_SUCCESS:
        //   
        //     let index = state.AddGroup.findIndex(todo => todo.id === action.payload.id); //finding index of the item
        //     
        //     let newArray = [...state.AddGroup]; //making a new array
        //    
        //     newArray[index].title = action.payload.title//changing value in the new array
        //     newArray[index].description = action.payload.description//changing value in the new array
        //     return {
        //         ...state, //copying the orignal state
        //         AddGroup: newArray, //reassingning todos to new array
        //     };

        // case ADD_GROUP_UPDATE_ERROR:
        //     return {
        //         loader: false,
        //         AddGroup: [],
        //         error: action.error,
        //     };
        default:
            return state;
    }
};



export default DataManagerReduceer;
