import {
  Box,
  Button,
  Stack,
  Container,
  FormControl,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordUserApi } from "../../../redux/actions/User";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  const [showPasswordEye, setShowPasswordEye] = useState(false);

  const changePwd = useSelector(
    (state) => state.user && state.user.changePwd && state.user.changePwd.data
  );

  useEffect(() => {
    if (changePwd && changePwd.status === 200) {
      // toast.success(changePwd.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/user-list");
      }, 3000);
    }
  }, [changePwd]);

  const handleClickShowPassword = () => {
    setShowPasswordEye(!showPasswordEye);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      notifyUser: false,
      sendPassword: false,
    },
    onSubmit: (values) => {
      const formData = {
        password: values.newPassword,
        confirmation_password: values.confirmPassword,
        notify_user: values.notifyUser,
        send_password: values.sendPassword,
        type: data.role?.key ? data.role?.key : data.role + "s",
        _id: data._id,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `reset/password?company_id=${id}`;
        dispatch(ChangePasswordUserApi(allData));
      }
    },
  });

  return (
    <Box>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/user-list" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Confirm
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>User</label>
              <TextField
                variant="outlined"
                size="small"
                name="user"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={data.name}
                className="issueinput-fields"
                disabled
                error={formik.touched.user && Boolean(formik.errors.user)}
              />
              <small className="error">
                {formik.errors.user && formik.touched.user
                  ? formik.errors.user
                  : null}
              </small>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <label>New Password</label>
              <TextField
                fullWidth
                id="password"
                className="issueinput-fields"
                name="newPassword"
                size="small"
                type={showPasswordEye ? "text" : "password"}
                placeholder="Password123@"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPasswordEye ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Confirm Password</label>
              <TextField
                variant="outlined"
                size="small"
                className="issueinput-fields"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
              />
              <small className="error">
                {formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? formik.errors.confirmPassword
                  : null}
              </small>
            </FormControl>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Notify User"
                name="notifyUser"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.notifyUser}
              />

              <FormControlLabel
                control={<Checkbox />}
                label="Send password to user"
                name="sendPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sendPassword}
              />
            </FormGroup>
          </Container>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePassword;
