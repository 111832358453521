import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Chip, Button, Stack } from "@mui/material";
import View from "../../../images/View.svg";
import ViewHover from "../../../images/ViewHover.svg";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function createData(
  Number,
  Store,
  AssignedTo,
  LocationA,
  LocationB,
  SurveyedOn,
  ApprovalStatus,
  Action
) {
  return {
    Number,
    Store,
    AssignedTo,
    LocationA,
    LocationB,
    SurveyedOn,
    ApprovalStatus,
    Action,
  };
}

const rows = [
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "pending",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "sent",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "pending",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "pending",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "sent",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "pending",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "sent",
    "3:10:41 PM"
  ),
  createData(
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "LA : 30.0574053",
    "Lo : 31.2145402",
    "Jun 3, 2022",
    "pending",
    "3:10:41 PM"
  ),
];

export const PendingList = () => {
  const navigate = useNavigate();
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
  });
  const handleClick = () => {
    navigate("/all-survey/survey-details");
  };
  return (
    <Box>
      <TableContainer component={Paper} className="table-container">
        <Table size="large" aria-label="simple table" className="grid-table">
          <TableHead sx={{ m: 4 }}>
            <TableRow>
              <TableCell align="center">Survey ID</TableCell>
              <TableCell align="center">Store</TableCell>
              <TableCell align="center">Surveyor</TableCell>
              <TableCell align="center">Location</TableCell>
              <TableCell align="center">Surveyed On</TableCell>
              <TableCell align="center">Approval Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.Number}</TableCell>
                <TableCell align="center">
                  <Typography variant="p" component="b">
                    {row.Store}
                  </Typography>
                  <Typography variant="p" component="p">
                    {row.Number}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="p" component="b">
                    {row.AssignedTo}
                  </Typography>
                  <Typography variant="p" component="p">
                    {row.Number}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="p" component="p">
                    {row.LocationA}
                  </Typography>
                  <Typography variant="p" component="p">
                    {row.LocationB}
                  </Typography>
                </TableCell>
                <TableCell align="center">{row.SurveyedOn}</TableCell>
                <TableCell align="center">
                  {row?.ApprovalStatus === "pending" ? (
                    <Stack direction="row" spacing={1} sx={{ ml: 10 }}>
                      <Button
                        variant="outlined"
                        color="success"
                        className="pending-success-btn"
                      >
                        Success
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        className="pending-success-btn"
                      >
                        Error
                      </Button>
                    </Stack>
                  ) : (
                    "Waiting for User Response"
                  )}
                </TableCell>

                <TableCell align="center">
                  <img
                    src={hover.View && indexHover === i ? ViewHover : View}
                    alt="VIEW"
                    style={{
                      height: "35px",
                      cursor: "pointer",
                      margin: "0px 3px 0px 3px",
                    }}
                    onClick={handleClick}
                    onMouseOver={() => {
                      setHover({ View: true });
                      setIndexHover(i);
                    }}
                    onMouseLeave={() => {
                      setHover({ View: false });
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PendingList;
