/** @format */

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "material-ui-core";
import { Grid, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BASE_URL } from "../../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import {
  AddProductApi,
  AddProductSuccess,
  EditProductApi,
  WasteCategoryApi,
  WasteUomListApi,
} from "../../../../redux/actions/WasteManagement";
import { useDispatch } from "react-redux";
// import TextareaAutosize from "@mui/base/TextareaAutosize";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddProductModal = (props) => {
  const dispatch = useDispatch();
  const [categoryListData, setCategoryListData] = useState([]);
  const [uomListData, setUomListData] = useState([]);
  const [newFieldValue, setNewFieldValue] = useState({});
  const valuesEdit = props?.editvalue;
  const editProductapi = props?.editStr;

  const categoryList = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.categoryList &&
      state?.wastemng?.categoryList?.data &&
      state?.wastemng?.categoryList?.data?.data &&
      state?.wastemng?.categoryList?.data?.data?.categories
  );

  const uomListDataList = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.uomList &&
      state?.wastemng?.uomList?.data &&
      state?.wastemng?.uomList?.data?.data &&
      state?.wastemng?.uomList?.data?.data?.uoms
  );
  const addProductRes = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.categoryList &&
      state?.wastemng?.categoryList?.data &&
      state?.wastemng?.categoryList?.data?.data &&
      state?.wastemng?.categoryList?.data?.data?.categories
  );
  const editProductRes = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.categoryList &&
      state?.wastemng?.categoryList?.data &&
      state?.wastemng?.categoryList?.data?.data &&
      state?.wastemng?.categoryList?.data?.data?.categories
  );
  const data = {
    page: 1,
    per_page: 200000,
  };

  useEffect(() => {
    setCategoryListData(categoryList);
    setUomListData(uomListDataList);
  }, [categoryList, uomListDataList]);

  useEffect(() => {
    CategortListApi(data);
    UomListApi(data);
  }, []);

  const CategortListApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url =
      BASE_URL +
      `inventory/categories?pagination={"page":${data?.page},"per_page":${data?.per_page}}&company_id=${id}`;
    dispatch(WasteCategoryApi(data));
  };

  const UomListApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url =
      BASE_URL +
      `inventory/uoms?pagination={"page":${data?.page},"per_page":${data?.per_page}}&company_id=${id}`;
    dispatch(WasteUomListApi(data));
  };

  useEffect(() => {
    if (valuesEdit?._id && editProductapi === "editProduct") {
      setNewFieldValue({
        title: valuesEdit?.title,
        price: valuesEdit?.price,
        description: valuesEdit?.description,
        category: valuesEdit?.category,
        uom: valuesEdit?.uom,
      });
    }
  }, [valuesEdit]);

  const validationSchema = Yup.object({
    title: Yup.string("Required").required("Required"),
    // category: Yup.object("Required").required("Required"),
    // uom: Yup.string("Required").required("Required"),
  });

  const formik = useFormik({
    initialValues: newFieldValue || {
      title: "",
      price: "",
      description: "",
      category: "",
      uom: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (values) {
        const newValues = {
          body: {
            title: values?.title,
            price: values?.price,
            description: values?.description,
            categoryId: values?.category?._id,
            uomId: values?.uom?._id,
          },
        };
        onFinalSubmit(newValues);
      }
      //   props.onFinalSubmit(values);
      // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });

  const onFinalSubmit = (newValues) => {
    if (editProductapi === "editProduct") {
      const id = localStorage.getItem("companyId");
      newValues.url =
        BASE_URL + `inventory/product/${valuesEdit?._id}?company_id=${id}`;
      dispatch(EditProductApi(newValues));
      setNewFieldValue({
        title: "",
        price: "",
        description: "",
        category: "",
        uom: "",
      });
      props.addResData(editProductRes);
      props.handleClose();
    } else {
      if (
        newValues?.body &&
        newValues?.body?.categoryId !== undefined &&
        newValues?.body?.uomId !== undefined
      ) {
        const id = localStorage.getItem("companyId");
        newValues.url = BASE_URL + `inventory/product?company_id=${id}`;
        dispatch(AddProductApi(newValues));
        props.addResData(addProductRes);
        formik.resetForm();
        props.handleClose();
      } else {
        toast.error("All Fields are Required");
      }
    }
  };

  const handleCalcel = () => {
    setNewFieldValue({
      title: "",
      price: "",
      description: "",
      category: "",
      uom: "",
    });
    props.handleClose();
  };

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Edit Category
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Title*</label>
                  <TextField
                    size="small"
                    id="name"
                    variant="outlined"
                    name="title"
                    placeholder="Enter product title"
                    value={formik?.values?.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    fullWidth
                    className="date-input"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Price</label>
                  <TextField
                    size="small"
                    className="input-fields"
                    id="price"
                    variant="outlined"
                    name="price"
                    type="number"
                    placeholder="Enter product price"
                    value={formik?.values?.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Description</label>
                  <TextareaAutosize
                    size="small"
                    // className="input-fields"
                    id="description"
                    variant="outlined"
                    name="description"
                    aria-label="minimum height"
                    className="product-des"
                    minRows={5}
                    style={{
                      width: "100%",
                      padding: "18.5px 14px",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                    value={formik?.values?.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Category*</label>
                  <Select
                    size="small"
                    fullWidth
                    labelId="countrySelectLabel"
                    displayEmpty
                    style={{ borderRadius: "8px", height: "40px" }}
                    id="category"
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                    renderValue={(selected) => selected?.title}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "300px",
                          width: "168px",
                          overflow: "auto",
                        },
                      },
                    }}
                  >
                    {categoryListData &&
                      categoryListData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">UOM*</label>
                  <Select
                    size="small"
                    fullWidth
                    labelId="countrySelectLabel"
                    displayEmpty
                    style={{ borderRadius: "8px", height: "40px" }}
                    id="uom"
                    name="uom"
                    value={formik.values.uom}
                    onChange={formik.handleChange}
                    error={formik.touched.uom && Boolean(formik.errors.uom)}
                    helperText={formik.touched.uom && formik.errors.uom}
                    renderValue={(selected) => selected?.title}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "300px",
                          width: "168px",
                          overflow: "auto",
                        },
                      },
                    }}
                  >
                    {uomListData &&
                      uomListData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              // onClick={props.handleClose}
              color="success"
              className="issue-modal-savebtn"
            >
              {editProductapi === "editProduct" ? "Save" : "Save"}
            </Button>
            <Button
              variant="contained"
              onClick={handleCalcel}
              className="issue-modal-cancelbtn"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default AddProductModal;
