import JwtAuthService from "../apiConfig/ApiConfig";
import {
  CSV_SUBMISSION_REQUEST,
  CSV_SUBMISSION_SUCCESS,
  CSV_SUBMISSION_ERROR,
  EXCEL_CHECKLIST_DETAIL_REQUEST,
  EXCEL_CHECKLIST_DETAIL_SUCCESS,
  EXCEL_CHECKLIST_DETAIL_ERROR
} from "../types/Types";

export const CsvSubmissionListRequest = () => ({
  type: CSV_SUBMISSION_REQUEST,
});
export const CsvSubmissionListSuccess = (list) => ({
  type: CSV_SUBMISSION_SUCCESS,
  payload: list,
});
export const CsvSubmissionListFailed = (error) => ({
  type: CSV_SUBMISSION_ERROR,
  payload: error,
});

export const ExcelChecklistDetailsRequest = () => ({
  type: EXCEL_CHECKLIST_DETAIL_REQUEST,
});
export const ExcelChecklistDetailsSuccess = (list) => ({
  type: EXCEL_CHECKLIST_DETAIL_SUCCESS,
  payload: list,
});
export const ExcelChecklistDetailsFailed = (error) => ({
  type: EXCEL_CHECKLIST_DETAIL_ERROR,
  payload: error,
});

export const CsvSubmissionListApi = (data) => (dispatch) => {
  try {
    dispatch(CsvSubmissionListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CsvSubmissionListSuccess(response));
      } else {
        dispatch(CsvSubmissionListFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CsvSubmissionListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
export const ExcelChecklistDetailApi = (data) => (dispatch) => {
  try {
    dispatch(ExcelChecklistDetailsRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ExcelChecklistDetailsSuccess(response));
      } else {
        dispatch(ExcelChecklistDetailsFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ExcelChecklistDetailsFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
