import {
    COMPANY_CATEGORY_LIST_REQUEST,
    COMPANY_CATEGORY_LIST_SUCCESS,
    COMPANY_CATEGORY_LIST_ERROR,
    CREATE_COMPANY_CATEGORY_REQUEST,
    CREATE_COMPANY_CATEGORY_SUCCESS,
    CREATE_COMPANY_CATEGORY_ERROR,
    ADD_DATA_LIST_REQUEST,
    ADD_DATA_LIST_SUCCESS,
    ADD_DATA_LIST_ERROR,
    ADD_COMPANY_SUBCATEGORY_REQUEST,
    ADD_COMPANY_SUBCATEGORY_SUCCESS,
    ADD_COMPANY_SUBCATEGORY_ERROR
} from "../types/Types";

const initialState = {
    loading: false,
    error: '',
    companylist: '',
    companyData: '',
    AddDataList: [],
    id: null,
    subCategoryList: []
};

const ApidataManagerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DATA_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_DATA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                AddDataList: [...state.AddDataList, action.payload],
                error: "",
            };
        case ADD_DATA_LIST_ERROR:
            return {
                loader: false,
                AddDataList: [],
                error: action.error,
            };
        case COMPANY_CATEGORY_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case COMPANY_CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                companylist: action.payload,
                loading: false
            }
        }
        case COMPANY_CATEGORY_LIST_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case CREATE_COMPANY_CATEGORY_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case CREATE_COMPANY_CATEGORY_SUCCESS: {
            return {
                ...state,
                companyData: action.payload,
                loading: false
            }
        }
        case CREATE_COMPANY_CATEGORY_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ADD_COMPANY_SUBCATEGORY_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ADD_COMPANY_SUBCATEGORY_SUCCESS: {
            return {
                ...state,
                subCategoryList: action.payload,
                loading: false
            }
        }
        case ADD_COMPANY_SUBCATEGORY_ERROR: {
            return {
                ...state,
                loading: false,
                subCategoryList: []
            };
        }
        default: {
            return state;
        }
    }
}

export default ApidataManagerReducer;