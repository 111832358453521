/** @format */

import { combineReducers } from "redux";
import BroadcastReducer from "./BroadcastReducer";
import checklistReducer from "./CheckList";
import companyReducer from "./CreateCompany";
import ApidataManagerReducer from "./DataManagerReducer";
import dashboardReducer from "./dashboardChartReducer";
import DepartmentReducer from "./DepartmentReducer";
import EditAllFormReducer from "./EditAllFormReducer";
import IssueReducer from "./IssueReducer";
import LmsReducer from "./LmsReducer";
import loginReducer from "./LoginReducer";
import RolesReducer from "./RolesReducer";
import signUpReducer from "./SignUp";
import StoresReducer from "./StoreReducer";
import UserReducer from "./UserReducer";
import WasteReducer from "./WasteReducer";
import AddGroupDataStore from "./AddGroupReducer";
import AllFormDataStore from "./AllFormReducer";
import EditAddGroupDataStore from "./EditformsReducer";
import NotiFicationReducer from "./manageNotification";
import ProfileDetailReducer from "./profileDetail";
import DeleteFormReducer from "./DEleteForm";
import subroleSelecetedReducer from "./subRoleSelected";
import DataManagerReduceer from "./Datamanager";
import MissedCheckLIstReducer from "./MissedCheckList";
import MonthWiseDataReducer from "./monthWiseData";
import CustomerLogsReducer from "./CustomerLogsReducer";
import CsvSubmissionDataReducer from "./csvSubmission";
import IntentCsvNamSubmissionDataReducer from "./IntentcsvNamdhariSubmission";
import ExcelChecklistDetailReducer from "./ExcelChecklistDetail";
import CsvNamdhariSubmissionReducer from "./csvNamdhariSubmission";
import MyProfileDetailsReducer from "./MyProfileDetailsReducer";
import MobileAccessDataDataReducer from "./mobileAccewss";
import CsvNamSubmissionDataUserWiseReducer from "./csvNamSubmissionWise";
import SchedularReducer from "./SchedularReducer";
import PaymentHistoryReducer from "./PaymentHistory";
import accountReducer from "./AccountReducer";
import ChatReducer from "./ChatReducer";
import logEntryReducer from "./LogEntry";
import AttendanceReducer from "./AttendanceReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  signUp: signUpReducer,
  logIn: loginReducer,
  company: companyReducer,
  roles: RolesReducer,
  lms: LmsReducer,
  checklist: checklistReducer,
  store: StoresReducer,
  user: UserReducer,
  broadcast: BroadcastReducer,
  wastemng: WasteReducer,
  department: DepartmentReducer,
  issue: IssueReducer,
  addgroup: AddGroupDataStore,
  editAllFormData: EditAllFormReducer,
  apidataManager: ApidataManagerReducer,
  allForm: AllFormDataStore,
  editAllform: EditAddGroupDataStore,
  dashboardReducer: dashboardReducer,
  NotiFicationReducer: NotiFicationReducer,
  ProfileDetailData: ProfileDetailReducer,
  DeleteFormData: DeleteFormReducer,
  subroleSelecetedReducer: subroleSelecetedReducer,
  dataManager: DataManagerReduceer,
  MissedCheckLIstData: MissedCheckLIstReducer,
  MonthWiseData: MonthWiseDataReducer,
  CustomerLogs: CustomerLogsReducer,
  CsvSubmissionData: CsvSubmissionDataReducer,
  IntentCsvNamSubmissionData: IntentCsvNamSubmissionDataReducer,
  CsvNamdhariSubmission: CsvNamdhariSubmissionReducer,
  CSVNamSubmissionUserWise: CsvNamSubmissionDataUserWiseReducer,
  ExcelChecklistDetail: ExcelChecklistDetailReducer,
  MyProfileDetails: MyProfileDetailsReducer,
  MobileAccessData: MobileAccessDataDataReducer,
  scheduler: SchedularReducer,
  paymentHistory: PaymentHistoryReducer,
  chat: ChatReducer,
  logEntry: logEntryReducer,
  attendanceLog: AttendanceReducer,
});
export default rootReducer;
