import JwtAuthService from "../apiConfig/ApiConfig";
import {
  SUBROLE_FORM_REQUEST,
  SUBROLE_FORM_SUCCESS,
  SUBROLE_FORM_ERROR,
} from "../types/Types";

export const SubRoleRequest = (data) => ({
  type: SUBROLE_FORM_REQUEST,
  payload: data,
});
export const SubRoleSuccess = (data) => ({
  type: SUBROLE_FORM_SUCCESS,
  payload: data,
});
export const SubRoleFailed = (error) => ({
  type: SUBROLE_FORM_ERROR,
  payload: error,
});

export const SubRoleApi = (data) => (dispatch) => {
  try {
    dispatch(SubRoleRequest(data));
  } catch (error) {
    dispatch(SubRoleFailed(error));
    console.log("Something went Wrong", error);
  }
};
