/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, TextField, Grid, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { EditSchedularApi } from "../../redux/actions/SchedularAction";
import * as moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

// Styled BootstrapDialog component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Custom BootstrapDialogTitle component
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

const EditSchedular = (props) => {
  const dispatch = useDispatch();

  // State variables
  const [formData, setFormData] = useState({
    title: "",
    jobType: "",
    jobDescription: "",
    frequency: 1,
    starts_at: null,
    expires_at: null,
    time: null,
  });

  const [fieldValue, setFieldValue] = useState({});
  const [newFieldValue, setNewFieldValue] = useState({});
  const [time, setTime] = useState(null);

  // Initialize formData using valuesEdit when component mounts
  const valuesEdit = props?.editvalue;

  useEffect(() => {
    // Update formData when valuesEdit prop changes
    if (valuesEdit) {
      setFormData({
        title: valuesEdit?.title || "",
        jobType: valuesEdit?.jobType || "",
        jobDescription: valuesEdit?.jobDescription || "",
        frequency: valuesEdit?.frequency || 1,
        starts_at: valuesEdit?.starts_at
          ? new Date(valuesEdit.starts_at)
          : null,
        expires_at: valuesEdit?.expires_at
          ? new Date(valuesEdit.expires_at)
          : null,
        time: valuesEdit?.time
          ? moment(valuesEdit.time, "hh:mm").toDate()
          : null,
      });

      setFieldValue(valuesEdit);
      setNewFieldValue({
        jobType: valuesEdit?.jobType,
        frequency: valuesEdit?.frequency,
      });
    }
  }, [valuesEdit]);

  // Frequency options
  const frequencyOptions = [
    { value: 1, label: "No frequency" },
    { value: 2, label: "Daily" },
    // Add other frequency options here
  ];

  // Handle form input changes
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle date picker changes
  const handleDatePickerChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  // Handle day limit
  const handleDayLimit = (value) => {
    setTime(value);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationSchema1 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      jobType: Yup.string().required("Job Type is required"),
      jobDescription: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      starts_at: Yup.date().required("Start Date is required"),
      expires_at: Yup.date()
        .required("Expire Date is required"),
      time: Yup.string().required("Time is required"),
    });

    const validationSchema2 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      jobType: Yup.string().required("Job Type is required"),
      jobDescription: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      starts_at: Yup.date().required("Start Date is required"),
      expires_at: Yup.date()
        .required("Expire Date is required")
        .min(
          Yup.ref("starts_at"),
          "Expire Date must be greater than or equal to Start Date"
        ),
      time: Yup.string().required("Time is required"),
    });

    try {
      if(formData.frequency === 2) {
        await validationSchema2.validate(formData, { abortEarly: false });
      } else {
        await validationSchema1.validate(formData, { abortEarly: false });
      }

      // Prepare payload
      let payload = {
        title: formData.title,
        jobType: formData.jobType,
        jobDescription: formData.jobDescription,
        time: convertTime12to24(moment(formData.time.$d).format("hh:mm A")),
        starts_at: moment(formData.starts_at.$d ?? formData.starts_at).format("YYYY-MM-DD"),
      };

      if (formData.frequency === 2) {
        payload = {
          ...payload,
          frequency: 2,
          expires_at: moment(formData.expires_at.$d  ?? formData.starts_at).format("YYYY-MM-DD"),
        };
      } else if (formData.frequency === 1) {
        payload = {
          ...payload,
          frequency: 1,
          date: moment(formData.expires_at.$d ?? formData.starts_at).format("YYYY-MM-DD"),
        };

        if (formData.expires_at) {
          payload.expires_at = moment(formData.expires_at.$d ?? formData.starts_at).format("YYYY-MM-DD");
        }
      }

      const allData = {
        body: payload,
      };

      if (allData) {
        const id = localStorage.getItem("companyId");
        const schedularId = valuesEdit?._id;
        allData.url =
          BASE_URL +
          `schedulerTasks/update?company_id=${id}&taskId=${schedularId}`;
        dispatch(EditSchedularApi(allData));
        props.handleClose();
      }
    } catch (error) {
      error.inner ? error.inner?.forEach((err) => {
        toast.error(err.message);
      }) : toast.error(error.message);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Edit Scheduler
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit}>
          <ToastContainer autoClose={3000} />
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rolemodel-lebel">
                    <b>Title</b>
                  </label>
                  <TextField
                    className="input-fields"
                    id="title"
                    variant="outlined"
                    name="title"
                    placeholder="Enter Title"
                    value={formData.title}
                    onChange={handleFormChange}
                    error={false}
                    helperText={""}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <label className="rolemodel-lebel">
                  <b>Frequency</b>
                </label>
                <Select
                  id="frequency"
                  name="frequency"
                  variant="outlined"
                  value={formData.frequency}
                  onChange={handleFormChange}
                  sx={{ width: "100%" }}
                  disabled // Add the 'disabled' attribute to disable the Select
                >
                  {frequencyOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <label className="rolemodel-lebel">
                  <b>Job Description</b>
                </label>
                <TextField
                  className="input-fields"
                  id="jobDescription"
                  variant="outlined"
                  name="jobDescription"
                  placeholder="Enter Job Type"
                  value={formData.jobDescription}
                  onChange={handleFormChange}
                  error={false}
                  helperText={""}
                  fullWidth
                />
              </Grid>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <label className="rolemodel-lebel">
                    <b>Start Date</b>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      value={formData.starts_at}
                      onChange={(date) =>
                        handleDatePickerChange("starts_at", date)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      name="starts_at"
                      disabled
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <label className="rolemodel-lebel">
                    <b>End Date</b>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      value={formData.expires_at}
                      onChange={(date) =>
                        handleDatePickerChange("expires_at", date)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      name="expires_at"
                      minDate={new Date()}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid item xs={12}>
                <label className="rolemodel-lebel">
                  <b>Time</b>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    clearable
                    clearText="Clear"
                    value={formData.time}
                    onChange={(value) => {
                      handleDayLimit(value);
                      handleFormChange({
                        target: {
                          name: "time",
                          value,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        placeholder={formData.time ? undefined : "Select Time"}
                      />
                    )}
                    className="date-input"
                  />
                </LocalizationProvider>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              color="success"
              className="issue-modal-savebtn"
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default EditSchedular;
