
import React from "react";
import { Card, Grid, Stack } from "@mui/material";
import { Typography } from "material-ui-core";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";


const CLHeaderView = (props) => {
    console.log("CLHeaderView Props:", props);
    return (
        <Card className="header-card">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="colomn-padding">
                    <Typography
                        align="left"
                        variant="h5"
                        component="h4"
                        sx={{ textAlign: "left", padding: 1, mt: 0, fontWeight: "bold" }}
                        style={{ padding: "4px",marginLeft:"2%" }}
                    >
                        Customer Log Details
                        <Stack
                            direction="row"
                            sx={{ float: "right" }}
                            className="Assign-Checklist"
                        >
                        <div style={{marginTop:"2px",marginRight:"8px"}}>
                            <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
                        </Stack>
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};


export default CLHeaderView;
