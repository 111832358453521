/** @format */
import React, { createContext, useContext, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const showNotification = useCallback((message) => {
    toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }, []);

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <ToastContainer />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
