import React, { useState } from "react";
import { Button, Card, Grid, Stack } from "@mui/material";
import { Link, Typography } from "material-ui-core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CLExportCsv from "./CLExportCsv";

const CLHeader = (props) => {
  // console.log("CLHeader Props:", props);
  return (
    <>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5" component="h4" align="left"   style={{ padding: "4px",ml:"4%" }}>
              Customer Log List
              <Stack direction="row" className="customer-log-header">
                <SearchAppBar
                  searchOnChange={props.searchOnChange}
                  handleKeyDown={props.handleKeyDown}
                  searchValue={props.searchValue}
                />
                <CLExportCsv style={{marginTop:"4px"}} customerLogData={props.customerLogData} />
                <div style={{marginRight:"5px",marginTop:"4px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Customer log:</strong>
                            <p>Shows all the customer logs entries created by the user via app</p>
                             <strong>Export CSV: </strong>
                             <p>All the logs listed can be exported in the excel format</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
              </Stack>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CLHeader;
