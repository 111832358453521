/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Card,
  Grid,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import SocketStatusModal from "./SocketStatusModal";

// Utility function to get initials from a name
const getInitials = (name) => {
  const nameParts = name?.split(" ");
  const initials = nameParts
    ?.map((part) => part[0])
    ?.join("")
    ?.toUpperCase();
  return initials;
};

const filterUniqueUsers = (users) => {
  const uniqueUserIds = new Set();
  return users.filter((user) => {
    if (uniqueUserIds.has(user._id)) {
      return false;
    } else {
      uniqueUserIds.add(user._id);
      return true;
    }
  });
};

const Header = ({
  currentUser,
  setCurrentUser,
  handleSelectUser,
  searchQuery,
  setSearchQuery,
  chatUsersFromSidebar,
  handleUserUpdate,
  userListAPI,
}) => {
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [inputValue, setInputValue] = useState(""); // State for input value
  const [socketConnected, setSocketConnected] = useState(true); // State to track socket connection
  const [addedUser, setAddedUser] = useState([]);
  const [removedUser, setRemovedUser] = useState([]);

  useEffect(() => {
    if (isEditProfileOpen && currentUser) {
      if (currentUser.type === "group") {
        const selected = chatUsersFromSidebar.filter((user) =>
          currentUser.participants.some(
            (participant) => participant.userId === user._id
          )
        );
        setSelectedUsers(filterUniqueUsers(selected));
      } else if (currentUser.type === "room") {
        const selected = chatUsersFromSidebar.filter(
          (user) => user._id === currentUser.createdBy._id
        );
        setSelectedUsers(filterUniqueUsers(selected));
      }
    }
  }, [isEditProfileOpen, currentUser, chatUsersFromSidebar]);

  const handleEditProfileClick = () => {
    setIsEditProfileOpen(true);
  };

  const handleProfileUpdate = () => {
    const addedUsers = selectedUsers.filter(
      (user) =>
        !currentUser.participants.some(
          (participant) => participant.userId === user._id
        )
    );
    const removedUsers = currentUser.participants.filter(
      (participant) =>
        !selectedUsers.some((user) => user._id === participant.userId)
    );

    handleUserUpdate({
      name: currentUser?.name,
      participants: selectedUsers.map((user) => ({
        userId: user._id,
        name: user.name,
        subRole: user.subRole,
      })),
      addedUser: addedUsers.map((user) => ({
        name: user.name,
        subRole: user.subRole,
        userId: user._id,
      })),
      removedUser: removedUsers.map((participant) => ({
        name: participant.name,
        subRole: participant.subRole,
        userId: participant.userId,
      })),
    }).then(() => {
      setCurrentUser((prevUser) => ({
        ...prevUser,
        participants: selectedUsers.map((user) => ({
          userId: user._id,
          name: user.name,
          subRole: user.subRole,
        })),
      }));

      if (userListAPI) {
        userListAPI();
      }
    });

    setIsEditProfileOpen(false);
  };

  const userName =
    currentUser?.type === "group"
      ? currentUser?.name
      : currentUser?.type === "room"
      ? currentUser?.participants?.[0]?.name
      : currentUser?.name;

  const handleUserCheck = (user, isSelected) => {
    const newSelectedUsers = isSelected
      ? selectedUsers.filter((selected) => selected._id !== user._id)
      : [...selectedUsers, user];

    setSelectedUsers(filterUniqueUsers(newSelectedUsers));

    if (isSelected) {
      // Add to removedUser
      setRemovedUser([...removedUser, user]);
    } else {
      // Add to addedUser
      setAddedUser([...addedUser, user]);
    }
  };

  const handleSelectAllUsers = (event) => {
    const checked = event.target.checked;
    const newSelectedUsers = checked
      ? filterUniqueUsers(chatUsersFromSidebar)
      : [];
    setSelectedUsers(newSelectedUsers);
    setAllUsersSelected(checked);
  };

  // Apply search query filter
  const searchedUsers = filterUniqueUsers(chatUsersFromSidebar).filter((user) =>
    user.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  const extendedUserList = [
    { type: "selectAll", name: "Select All Users", _id: "selectAll" },
    ...searchedUsers,
  ];

  return (
    <>
      <Card className="header-card-chat">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              className="header-left-chat"
              display="flex"
              alignItems="center"
            >
              {currentUser ? (
                <Avatar>{getInitials(userName)}</Avatar>
              ) : (
                <PersonIcon />
              )}
              <Typography variant="h6" sx={{ marginLeft: "16px" }}>
                {userName || "No User Selected"}
              </Typography>
            </Box>
          </Grid>
          {currentUser?.type === "group" && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box
                className="header-right-chat"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  position: "relative",
                }}
              >
                <Button
                  className="header-add cancle-hover"
                  onClick={handleEditProfileClick}
                  color="primary"
                >
                  <GroupIcon sx={{ mr: 1 }} />
                  Update Users
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Card>
      <SocketStatusModal open={!socketConnected} />{" "}
      {/* Add socket status feedback */}
      <Dialog
        open={isEditProfileOpen}
        onClose={() => setIsEditProfileOpen(false)}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: "800px",
            height: "400px",
          },
        }}
      >
        <DialogTitle>Update Users</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <FormControl fullWidth>
              <FormLabel component="legend">
                Enter Name <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                label="Enter name..."
                value={
                  currentUser?.type === "group"
                    ? currentUser?.name
                    : currentUser?.type === "room"
                    ? currentUser?.participants?.[0]?.name
                    : ""
                }
                onChange={(e) =>
                  setCurrentUser((prevUser) => ({
                    ...prevUser,
                    name: e.target.value,
                  }))
                }
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <FormLabel
                component="legend"
                sx={{ marginBottom: 2 }} // Add space below the label
              >
                Select Users <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Autocomplete
                multiple
                options={extendedUserList}
                getOptionLabel={(user) =>
                  user.type === "selectAll" ? user.name : user?.name || ""
                }
                value={selectedUsers}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                  const selectAllIndex = newValue.findIndex(
                    (user) => user.type === "selectAll"
                  );

                  if (selectAllIndex !== -1) {
                    const allSelected =
                      newValue.length - 1 === searchedUsers.length;
                    setSelectedUsers(allSelected ? [] : searchedUsers);
                    setAllUsersSelected(!allSelected);
                  } else {
                    setSelectedUsers(filterUniqueUsers(newValue));
                  }
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option._id}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Users"
                    variant="outlined"
                    placeholder="Search..."
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                sx={{ marginBottom: 2 }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsEditProfileOpen(false)}
            style={{ backgroundColor: "#e74c3c", color: "#fff" }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleProfileUpdate}
            style={{ backgroundColor: "#196773", color: "#fff" }}
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
