/** @format */

import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import GraphCards from "./pages/GraphCards";
import CoursesUser from "./pages/CoursesUser";
import LineChart from "./pages/LineChart";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { TrainingListApi } from "../../redux/actions/LmsAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";                            

const OverviewLMS = () => {
  const [value, setValue] = useState("");
  const [trainings, setTrainings] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [subRole, setSubRole] = useState([]);

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );
  const trainingDataList = useSelector(
    (state) =>
      state &&
      state?.lms &&
      state?.lms?.trainingData &&
      state?.lms?.trainingData?.data
  );
  useEffect(() => {
    if (trainingDataList?.length > 0) {
      let newArr = [];
      newArr.push(trainingDataList[0]);
      setTrainings(newArr);
    }
  }, [trainingDataList]);

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  const chooseValue = (selectValue) => {
    setValue(selectValue);
  };

  const handleTrainingList = (e) => {
    setTrainings(e.target.value);
  };

  useEffect(() => {
    setLoader(true);
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `TraingList?company_id=${id}&subRole=[${subRole}]` };
    dispatch(TrainingListApi(data));
    setLoader(false);
  }, [subRole]);

  return (
    <Box>
      <Container
        maxWidth="xl"
        sx={{
          overflow: "hidden",
          bgcolor: "#F6F8FC",
          p: 1,
          pb: 10,
          borderRadius: 2,
        }}
      >
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Box className="training-list">
            <h5>Training List</h5>
            <Box>
              <div style={{float:"right",marginTop:"3px",}}>
                 <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
              <FormControl sx={{ display: "grid" }}>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  name="roleselect"
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  className="Sub-Role-Select"
                  displayEmpty
                  value={trainings}
                  onChange={handleTrainingList}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 0,
                        },
                        height: "180px",
                      },
                    },
                  }}
                  renderValue={(trainings) => {
                    if (trainings?.length === 0) {
                      return (
                        <em
                          style={{
                            color: "#b9b9b9",
                            fontFamily: "Sans-serif",
                            fontWeight: "normal",
                          }}
                        >
                          Select Training
                        </em>
                      );
                    }
                    return trainings.map((item) => item?.name)?.join(",");
                  }}
                >
                  {trainingDataList &&
                    trainingDataList.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option}
                          className="select-item"
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={trainings.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option?.name} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        <GraphCards chooseValue={chooseValue} trainings={trainings} subRole={subRole} />
        <CoursesUser value={value} trainings={trainings} subRole={subRole} />
        <LineChart trainings={trainings} subRole={subRole} />
      </Container>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default OverviewLMS;
