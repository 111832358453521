import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

const booleanOptions = [
    "Yes",
    "No",
];

const EditLogEntrySubmission = () => {
    const logEntrySubmissionData = localStorage.getItem("logEntrySubmissionData")
    const parsedLogEntrySubmissionData = JSON.parse(logEntrySubmissionData)
    const companyId = localStorage.getItem("companyId");

    const [isSubmissionInitiated, setIsSubmissionInitiated] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [formData, setFormData] = useState({});
    const [questionData, setQuestionData] = useState([]);
    const [timerValue, setTimerValue] = useState(0);
    const [timerRunning, setTimerRunning] = useState(false);
    
    const ProfileDetailData = useSelector(
        (state) =>
          state.ProfileDetailData &&
          state.ProfileDetailData.ProfileData &&
          state.ProfileDetailData.ProfileData.data &&
          state.ProfileDetailData.ProfileData.data
      );
    
    useEffect(() => {
        setSubmissionData(parsedLogEntrySubmissionData);
        setQuestionData(parsedLogEntrySubmissionData.questions)
    }, [])

    console.log("submissionData", submissionData);
    const handleFormChange = (e, questionIndex) => {
        console.log("e.target.value", e.target.value);
        const fieldName = e.target.name;
        const updatedFormData = {
            ...formData,
            [fieldName]: e.target.value,
        };
        const modifiedQuestionData = [...questionData];
        if (modifiedQuestionData[questionIndex]?.type?.key === "single_select") {
            const answer = modifiedQuestionData[
                questionIndex
            ]?.attached_list?.items.filter((ans) => ans.title === e.target.value);

            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                currentAnswer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        title: e.target.value,
                        id: answer[0] && answer[0].id,
                        option_score: modifiedQuestionData[questionIndex]?.question_score,
                    },
                    startTime: startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "datetime") {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                value: e.target.value,
                currentAnswer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        date: moment(e.target.value?.$d).format("YYYY-MM-DD"),
                        time: moment(e.target.value?.$d).format("HH:mm:ss"),
                        option_score: modifiedQuestionData[questionIndex]?.question_score,
                    },
                    startTime: startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "time") {
            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                value: e.target.value,
                currentAnswer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        title: modifiedQuestionData[questionIndex]?.type?.title,
                        value: moment(e.target.value?.$d).format("HH:mm:ss"),
                        option_score: modifiedQuestionData[questionIndex]?.question_score,
                    },
                    startTime: startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else if (modifiedQuestionData[questionIndex]?.type?.key === "file") {
            var filename = e.target.value.name.substring(
                0,
                e.target.value.name.lastIndexOf(".")
            );

            const pos = e.target.value.name.lastIndexOf(".");

            var modifiedFilename = `${filename}-${Date.now()}.${e.target.value.name.slice(
                pos + 1
            )}`;

            const file = e.target.value;
            const medias = [];

            // if (
            //     auditorChecklistSubmissionData[questionIndex]?.media?.[0]?.path?.name
            // ) {
            //     medias.push(...auditorChecklistSubmissionData[questionIndex]?.media);
            // }

            medias.push({
                path: e.target.value,
                fileName: modifiedFilename,
                originalname: modifiedFilename,
                key: modifiedFilename,
                name: modifiedFilename,
                fileType: e.target.value.name.slice(pos + 1),
                contentType: file.type,
                addedBy: ProfileDetailData._id,
                company: companyId,
                size: file.size,
            });

            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                value: [file],
                media: medias,
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        answer: [modifiedFilename],
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        } else {
            // This else condition is for boolean and few others
            const selectedAnswer = e.target.value;
            const optionScore =
                selectedAnswer === "Yes" || selectedAnswer === "Acceptable" ? 1 : 0;

            modifiedQuestionData[questionIndex] = {
                ...modifiedQuestionData[questionIndex],
                answer: {
                    type: modifiedQuestionData[questionIndex]?.type?.key,
                    answer: {
                        title: e.target.value,
                        value: true,
                    },
                    startTime: modifiedQuestionData[questionIndex]?.answer?.startTime,
                    endTime: moment().format(),
                    gps: null,
                },
            };
        }
        // console.log("updatedFormData", updatedFormData);
        // if (selectedRemainingData && selectedRemainingData?._id) {
        //     localStorage.setItem(
        //         "answeredQuestions_" + selectedRemainingData?._id,
        //         JSON.stringify(modifiedQuestionData)
        //     );
        //     dispatch(AuditorChecklistSubmissionDataSuccess(modifiedQuestionData));
        // }
        setFormData(updatedFormData);
        // updateAnsweredQuestions(fieldName, e.target.value, questionIndex);

        setQuestionData(modifiedQuestionData);
    };

    console.log("questionData", questionData);

    const renderBooleanField = (question, questionIndex) => {
        const fieldName = `question_${questionIndex + 1}_answer`;
        const selectedAnswer = question?.answer?.answer?.title || "";
        // const optionScore =
        //     selectedAnswer === "Yes" ? 1 : 0;
        const isAnswerGivenBoolean =
            typeof selectedAnswer === "string" && selectedAnswer !== "";

        return (
            <FormControl component="fieldset">
                <RadioGroup
                    style={{ textAlign: "left", marginLeft: 0 }}
                    row
                    aria-label={`Answer for Question ${questionIndex + 1}`}
                    name={fieldName}
                    value={selectedAnswer}
                    onChange={(e) => handleFormChange(e, questionIndex)}
                >
                    {booleanOptions?.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            value={option}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
                {isSubmissionInitiated && !isAnswerGivenBoolean && (
                    <FormHelperText error>
                        This field is required, Please select a answer first.
                    </FormHelperText>
                )}
                {/* <Typography variant="body2" style={{ marginTop: "8px" }}>
                    <strong>Option Score:</strong> {optionScore}
                </Typography> */}
            </FormControl>
        );
    };

    const renderAdditionalFields = (
        question,
        questionIndex,
        handleFormChange
    ) => {

        const typeKey = question.type?.key;


        const qAnswer =
            question?.answer?.answer?.value ||
            question?.answer?.answer?.title ||
            question?.answer?.answer?.answer ||
            question?.answer?.answer ||
            "";

        switch (typeKey) {
            case "number":
                const numericQAnswer = parseFloat(qAnswer);
                const isAnswerGiven = !isNaN(numericQAnswer) && numericQAnswer >= 0;
                return (
                    <div style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label={`Numeric Answer for Question ${questionIndex + 1}`}
                            name={`question_${questionIndex + 1}_numeric_answer`}
                            variant="outlined"
                            fullWidth
                            value={question?.answer?.answer?.value}
                            onChange={(e) => handleFormChange(e, questionIndex)}
                            margin="normal"
                            type="number"
                            required
                            error={isSubmissionInitiated && !isAnswerGiven}
                            helperText={
                                isSubmissionInitiated && !isAnswerGiven
                                    ? "This field is required, Please provide number as an answer first."
                                    : ""
                            }
                        />
                    </div>
                );

            case "text":
                const isAnswerGivenText =
                    typeof qAnswer === "string" && qAnswer.trim() !== "";
                return (
                    <div style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label={`Text Answer for Question ${questionIndex + 1}`}
                            name={`question_${questionIndex + 1}_text_answer`}
                            variant="outlined"
                            fullWidth
                            value={question?.answer?.answer?.value}
                            onChange={(e) => handleFormChange(e, questionIndex)}
                            margin="normal"
                            required
                            error={isSubmissionInitiated && !isAnswerGivenText}
                            helperText={
                                isSubmissionInitiated && !isAnswerGivenText
                                    ? "This field is required, Please provide text as an answer first."
                                    : ""
                            }
                        />
                    </div>
                );

            case "single choice":
                const singleSelectOptions = question?.attachedlist?.items;
                const isAnswerGivenSingleSelect =
                    typeof qAnswer === "string" && qAnswer.trim() !== "";
                const isInvalidSingleSelect =
                    isSubmissionInitiated && !isAnswerGivenSingleSelect;

                return (
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label={`Answer for Question ${questionIndex + 1}`}
                            name={`question_${questionIndex + 1}_answer`}
                            value={question?.answer?.answer?.title}
                            onChange={(e) => handleFormChange(e, questionIndex)}
                        >
                            {singleSelectOptions?.map((option) => {
                                return (
                                    <FormControlLabel
                                        key={option.title}
                                        value={option.title}
                                        control={<Radio />}
                                        label={`${option.title}`}
                                    />
                                );
                            })}
                        </RadioGroup>
                        {isInvalidSingleSelect && (
                            <FormHelperText error>
                                This field is required, Please select a answer first.
                            </FormHelperText>
                        )}
                    </FormControl>
                );

            case "multiple choice":
                const isAnswerGivenMultiSelect =
                    Array.isArray(qAnswer) && qAnswer.length > 0;

                const multiSelectOptions = question?.attachedlist?.items;
                const selectedOptions = question?.answer?.answer || [];
                const handleMultiSelectChange = (e) => {
                    const selectedOption = e.target.value;
                    const modifiedQuestionData = [...questionData];

                    const answer = modifiedQuestionData[
                        questionIndex
                    ]?.attachedlist?.items.filter((ans) => ans.title === e.target.value);

                    let updatedOptions = [...selectedOptions];

                    if (e.target.checked) {
                        // updatedOptions.push({
                        //     id: answer[0] && answer[0].id,
                        //     title: selectedOption,
                        // });
                        updatedOptions.push(answer[0]);
                    } else {
                        updatedOptions = updatedOptions.filter(
                            (upOp) => upOp.title !== selectedOption
                        );
                    }

                    const fieldName = `question_${questionIndex + 1}_answer`;
                    const updatedFormData = {
                        ...formData,
                        [fieldName]: updatedOptions,
                    };
                    modifiedQuestionData[questionIndex] = {
                        ...modifiedQuestionData[questionIndex],
                        answer: {
                            type: modifiedQuestionData[questionIndex]?.type?.key,
                            answer: updatedOptions,
                            startTime: modifiedQuestionData[questionIndex]?.startTime,
                            endTime: moment().format(),
                        },
                    };

                    // if (selectedRemainingData && selectedRemainingData?._id) {
                    //     localStorage.setItem(
                    //         "answeredQuestions_" + selectedRemainingData?._id,
                    //         JSON.stringify(modifiedQuestionData)
                    //     );
                    //     dispatch(
                    //         AuditorChecklistSubmissionDataSuccess(modifiedQuestionData)
                    //     );
                    // }
                    setQuestionData(modifiedQuestionData);
                    setFormData(updatedFormData);
                    // updateAnsweredQuestions(fieldName, updatedOptions, questionIndex);
                };

                const optionsAnswer = selectedOptions.map((option) => option.title);
                const isInvalidMultiSelect =
                    isSubmissionInitiated && !isAnswerGivenMultiSelect;

                return (
                    <div>
                        {multiSelectOptions?.map((option) => (
                            <FormControlLabel
                                key={option.title}
                                control={<Checkbox />}
                                label={`${option.title}`}
                                checked={optionsAnswer.includes(option.title)}
                                onChange={handleMultiSelectChange}
                                value={option.title}
                            />
                        ))}
                        {isInvalidMultiSelect && (
                            <FormHelperText error>
                                This field is required, Please select one or more answer first.
                            </FormHelperText>
                        )}
                    </div>
                );

            case "boolean":
                return renderBooleanField(question, questionIndex, handleFormChange);

            case "file":
                const fileInputId = `fileInput-${questionIndex}`;

                const handleRemoveFile = (file) => {
                    // const modifiedQuestionData = [...auditorChecklistSubmissionData];
                    // const medias = modifiedQuestionData[questionIndex]?.media.filter(
                    //     (uploadedFile) => uploadedFile.fileName !== file
                    // );
                    // const mediaAnswer = modifiedQuestionData[
                    //     questionIndex
                    // ]?.currentAnswer?.answer?.answer.filter(
                    //     (uploadedFile) => uploadedFile !== file
                    // );

                    // modifiedQuestionData[questionIndex] = {
                    //     ...modifiedQuestionData[questionIndex],
                    //     media: medias,
                    //     currentAnswer: {
                    //         ...modifiedQuestionData[questionIndex].currentAnswer,
                    //         answer: {
                    //             ...modifiedQuestionData[questionIndex].currentAnswer.answer,
                    //             answer: mediaAnswer,
                    //         },
                    //     },
                    // };

                    // if (selectedRemainingData && selectedRemainingData?._id) {
                    //     localStorage.setItem(
                    //         "answeredQuestions_" + selectedRemainingData?._id,
                    //         JSON.stringify(modifiedQuestionData)
                    //     );
                    //     dispatch(
                    //         AuditorChecklistSubmissionDataSuccess(modifiedQuestionData)
                    //     );
                    // }
                };

                const storedFile = qAnswer;
                // (auditorChecklistSubmissionData?.[questionIndex]?.media?.[0]?.path
                //     ?.name &&
                //     auditorChecklistSubmissionData?.[questionIndex]?.answer?.answer) ||
                // [];

                const isAnswerGivenFile = qAnswer;

                return (
                    <Card>
                        <CardContent>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            e.target.value = null;
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                    // const imageSrc = e.target.result;
                                                    // setCapturedImage(imageSrc);
                                                };
                                                reader.readAsDataURL(file);
                                                const fieldName = `question_${questionIndex + 1
                                                    }_file_answer`;
                                                handleFormChange(
                                                    {
                                                        target: {
                                                            name: fieldName,
                                                            value: file,
                                                        },
                                                    },
                                                    questionIndex
                                                );
                                            }
                                        }}
                                        style={{ display: "none" }}
                                        id={`fileInput-${questionIndex}`}
                                        multiple
                                    />
                                    <label htmlFor={`fileInput-${questionIndex}`}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            component="span"
                                            startIcon={<AttachFileIcon />}
                                        >
                                            Select File
                                        </Button>
                                    </label>
                                </div>
                            </div>
                            {storedFile?.length
                                ? storedFile.map((file) => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <InsertDriveFileIcon
                                            color="primary"
                                            style={{ marginRight: "8px" }}
                                        />
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            style={{ marginRight: "8px" }}
                                        >
                                            <a href={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${file}`} target="_blank">
                                                {`${file}`}
                                            </a>
                                        </Typography>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleRemoveFile(file)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))
                                : ""}
                            {isSubmissionInitiated && !isAnswerGivenFile && (
                                <FormHelperText error>
                                    This field is required, Please attach a file as an answer
                                    first.
                                </FormHelperText>
                            )}
                        </CardContent>
                    </Card>
                );

            case "date":
                const isAnswerGivenDate =
                    question?.answer?.answer?.value !== null &&
                    question?.answer?.answer?.value !== undefined &&
                    question?.answer?.answer?.value !== "";

                return (
                    <div style={{ marginBottom: "1rem" }}>
                        <FormControl component="fieldset">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date"
                                    size="small"
                                    value={question?.answer?.answer?.title}
                                    onChange={(newValue) => {
                                        if (newValue && newValue.$d) {
                                            let dateSort = moment(newValue.$d).format("YYYY-MM-DD");
                                            // setStartDate(dateSort);
                                            // setEndDate(dateSort);
                                            handleFormChange(
                                                {
                                                    target: {
                                                        name: `question_${questionIndex + 1}_date_answer`,
                                                        value: dateSort,
                                                    },
                                                },
                                                questionIndex
                                            );
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    PopperProps={{
                                        popperOptions: {
                                            modifiers: [
                                                {
                                                    name: "preventOverflow",
                                                    options: {
                                                        padding: 8,
                                                    },
                                                },
                                                {
                                                    name: "flip",
                                                    options: {
                                                        padding: 8,
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        {isSubmissionInitiated && !isAnswerGivenDate && (
                            <FormHelperText error>
                                This field is required, Please select a date as an answer.
                            </FormHelperText>
                        )}
                    </div>
                );

            case "datetime":
                const isAnswerGivenDatetime =
                    question?.value !== null && question?.value !== undefined;

                const handleDateTimeChange = (newDateTime) => {
                    if (newDateTime) {
                        const formattedDateTime = moment(newDateTime).format(
                            "YYYY-MM-DDThh:mm:ss"
                        );
                        handleFormChange(
                            {
                                target: {
                                    name: `question_${questionIndex + 1}_datetime_answer`,
                                    value: newDateTime,
                                },
                            },
                            questionIndex
                        );
                    }
                };

                return (
                    <div>
                        <div style={{ marginBottom: "1rem" }}>
                            <FormControl component="fieldset">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date"
                                        value={
                                            (qAnswer?.date ? qAnswer?.date : null)
                                        }
                                        onChange={(newValue) => {
                                            if (newValue && newValue.$d) {
                                                let dateSort = moment(newValue.$d).format("YYYY-MM-DD");
                                                // setStartDateAsDateTime(dateSort);
                                                // setEndDateAsDateTime(dateSort);
                                                handleDateTimeChange(newValue);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <FormControl component="fieldset">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Time"
                                        value={new Date(qAnswer?.time) || null}
                                        onChange={(newValue) => {
                                            // setSelectedTimeForTime(newValue);
                                            handleDateTimeChange(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        views={["hours", "minutes", "seconds"]}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                        {isSubmissionInitiated && !isAnswerGivenDatetime && (
                            <FormHelperText error>This field is required</FormHelperText>
                        )}
                    </div>
                );

            case "time":
                const isAnswerGivenTime =
                    question?.value !== null && question?.value !== undefined;

                const handleTimeChange = (newTime) => {
                    if (newTime) {
                        setSelectedTime(newTime);
                        const formattedTime = newTime
                            ? moment(newTime?.$d).format("hh:mm:ss A")
                            : "";
                        handleFormChange(
                            {
                                target: {
                                    name: `question_${questionIndex + 1}_time_answer`,
                                    value: newTime,
                                },
                            },
                            questionIndex
                        );
                    }
                };

                return (
                    <div style={{ marginBottom: "1rem" }}>
                        <FormControl component="fieldset">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="Time"
                                    value={new Date(qAnswer?.time) || null}
                                    onChange={(newValue) => {
                                        // setSelectedTime(newValue);
                                        handleTimeChange(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    views={["hours", "minutes", "seconds"]}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        {isSubmissionInitiated && !isAnswerGivenTime && (
                            <FormHelperText error>Time answer is required</FormHelperText>
                        )}
                    </div>
                );

            case "timer":
                const isTimerStarted = timerValue !== null && timerValue !== undefined;

                return (
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton color="primary" onClick={startTimer}>
                                <PlayArrowIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => pauseTimer(questionIndex)}
                            >
                                <PauseIcon fontSize="small" />
                            </IconButton>
                            <IconButton color="secondary" onClick={resetTimer}>
                                <ReplayIcon fontSize="small" />
                            </IconButton>
                        </div>
                        {isSubmissionInitiated && !isTimerStarted && (
                            <FormHelperText error>
                                This field is required, Please start timer and stop it.
                            </FormHelperText>
                        )}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "8px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "8px",
                                    }}
                                >
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Days:{" "}
                                        {Math.floor(
                                            (timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) /
                                            (24 * 60 * 60)
                                        )}
                                    </Typography>
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Hrs:{" "}
                                        {Math.floor(
                                            ((timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) %
                                                (24 * 60 * 60)) /
                                            (60 * 60)
                                        )}
                                    </Typography>
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Mins:{" "}
                                        {Math.floor(
                                            (timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) / 60
                                        )}
                                    </Typography>
                                    <Typography variant="body2" style={{ marginRight: "8px" }}>
                                        Sec:{" "}
                                        {(timerValue ||
                                            question?.answer?.answer?.value ||
                                            0) %
                                            60 ===
                                            0
                                            ? 0
                                            : (timerValue ||
                                                question?.answer?.answer?.value ||
                                                0) % 60}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const startTimer = () => {
        if (!timerRunning) {
            setTimerRunning(true);
            setTimerInterval(
                setInterval(() => {
                    setTimerValue((prevValue) => prevValue + 1);
                }, 1000)
            );
        }
    };

    const pauseTimer = (questionIndex) => {
        setTimerRunning(false);
        clearInterval(timerInterval);
        handleFormChange(
            {
                target: {
                    name: `question_${questionIndex + 1}_timer_answer`,
                    value: timerValue,
                },
            },
            questionIndex
        );
    };

    const resetTimer = () => {
        setTimerValue(0);
        setTimerRunning(false);
        clearInterval(timerInterval);
        setTimerInterval(null);
    };

    return (
        <Box>
            <Card className="header-card">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{
                                padding: "4px",
                                marginLeft: "10px",
                                marginBottom: "0",
                                fontSize: "1.2rem",
                            }}
                        >
                            {submissionData?.logEntryObject?.logEntryTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{
                                textTransform: "uppercase",
                                padding: "4px",
                                marginRight: "10px",
                                marginBottom: "0",
                                fontSize: "1.2rem",
                            }}
                        >
                            {submissionData?.store?.title}
                        </Typography>
                    </Grid>
                </Grid>
                <ToastContainer />
                {false ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <ProgressbarTransparent play />
                    </div>
                ) : (
                    ""
                )}
                <Box
                    sx={{
                        bgcolor: "#F6F8FC",
                        height: "80vh",
                        mt: 1,
                        p: 1,
                        borderRadius: 1,
                        overflowX: "auto",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: 0,
                        },
                        "&::-webkit-scrollbar-track": {
                            padding: "12px 5px",
                            backgroundColor: "#CBD4E1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#64748B",
                            borderRadius: 8,
                        },
                    }}
                >
                    <form>
                        {questionData?.map((question, questionIndex) => (
                            <Card
                                key={questionIndex}
                                sx={{
                                    p: 2,
                                    mt: 2,
                                    textAlign: "left",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    style={{ whiteSpace: "pre-line" }}
                                >
                                    <Box
                                        style={{
                                            background: "#f26539",
                                            padding: "10px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box style={{ color: "white", fontWeight: "bold" }}>
                                                Question. {questionIndex + 1}
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <span>
                                        <span style={{ color: "red" }}>*</span>
                                        <strong>{question.title}</strong>{" "}
                                    </span>
                                    <Tooltip
                                        title={question.desc}
                                        arrow
                                        placement="right"
                                        style={{
                                            backgroundColor: "#f26539",
                                            marginLeft: "0.5em",
                                        }}
                                    >
                                        <span
                                            style={{
                                                cursor: "help",
                                                color: "#f26539",
                                            }}
                                        >
                                            <InfoIcon fontSize="small" />
                                        </span>
                                    </Tooltip>
                                </Typography>
                                {question.type?.key === "boolean"
                                    ? renderBooleanField(question, questionIndex)
                                    : renderAdditionalFields(
                                        question,
                                        questionIndex,
                                        handleFormChange
                                    )}
                            </Card>
                        ))}
                    </form>
                </Box>
            </Card>
        </Box>
    )
};

export default EditLogEntrySubmission;