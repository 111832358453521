import JwtAuthService from "../apiConfig/ApiConfig";
import {
  MOBILE_ACCESS_REQUEST,
  MOBILE_ACCESS_SUCCESS,
  MOBILE_ACCESS_ERROR,
} from "../types/Types";

export const MobileAccessRequest = () => ({
  type: MOBILE_ACCESS_REQUEST,
});
export const MobileAccessSuccess = (list) => ({
  type: MOBILE_ACCESS_SUCCESS,
  payload: list,
});
export const MobileAccessFailed = (error) => ({
  type: MOBILE_ACCESS_ERROR,
  payload: error,
});

export const MobileAccessApi = (data) => (dispatch) => {
  try {
    dispatch(MobileAccessRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(MobileAccessSuccess(response));
      } else {
        dispatch(MobileAccessFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(MobileAccessFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
