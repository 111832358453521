import React, { createContext, useState } from "react";
import { useEffect } from "react";

export const AuditorContext = createContext();

export const AuditorContextProvider = ({ children }) => {
  const [companyId, setcompanyId] = useState(null);
  const [storeId, setstoreId] = useState(null);
  const [ storeTitle , setstoreTitle] = useState(null);
  const [currentAssessment, setcurrentAssessment] = useState(null);
  const [isAuditor, setisAuditor] = useState(false);
  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    const companyId = localStorage.getItem("companyId");
    if(companyId) setcompanyId(companyId);
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      setstoreId(parseselectedStore._id);
      setstoreTitle(parseselectedStore.title);
    }
  }, []);





  const contextValue = {
    companyId,
    setcompanyId,
    storeId,
    setstoreId,
    currentAssessment,
    setcurrentAssessment,
    storeTitle,
    setstoreTitle,
    setisAuditor,
    isAuditor
  };

  return (
    <AuditorContext.Provider value={contextValue}>{children}</AuditorContext.Provider>
  );
};
