/** @format */

import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import {
  LIST_STORE_REQUEST,
  LIST_STORE_SUCCESS,
  LIST_STORE_ERROR,
  ASSIGN_USER_REQUEST,
  ASSIGN_USER_SUCCESS,
  ASSIGN_USER_ERROR,
  CHECKLIST_BY_STORE_REQUEST,
  CHECKLIST_BY_STORE_SUCCESS,
  CHECKLIST_BY_STORE_ERROR,
  STORE_ENABLE_DISABLE_REQUEST,
  STORE_ENABLE_DISABLE_SUCCESS,
  STORE_ENABLE_DISABLE_ERROR,
  ADD_STORE_REQUEST,
  ADD_STORE_SUCCESS,
  ADD_STORE_ERROR,
  EDIT_STORE_REQUEST,
  EDIT_STORE_SUCCESS,
  EDIT_STORE_ERROR,
  STORE_BY_ID_EDIT_REQUEST,
  STORE_BY_ID_EDIT_SUCCESS,
  STORE_BY_ID_EDIT_ERROR,
  PENDING_CHECKLIST_REQUEST,
  PENDING_CHECKLIST_SUCCESS,
  PENDING_CHECKLIST_ERROR,
  STORE_DETAILS_REQUEST,
  STORE_DETAILS_SUCCESS,
  STORE_DETAILS_ERROR,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_ERROR,
} from "../types/Types";

export const ListStores = () => ({
  type: LIST_STORE_REQUEST,
});

export const ListStoresSuccess = (companyid) => ({
  type: LIST_STORE_SUCCESS,
  payload: companyid,
});

export const ListStoresFailed = (error) => ({
  type: LIST_STORE_ERROR,
  payload: error,
});

export const ListStoreApi = (data) => (dispatch) => {
  try {
    dispatch(ListStores(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ListStoresSuccess(data));
      } else {
        dispatch(ListStoresFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListStoresFailed(error));
  }
};

// store details
// View Store started
export const StoreDetails = () => ({
  type: STORE_DETAILS_REQUEST,
});

export const StoreDetailsSuccess = (companyid) => ({
  type: STORE_DETAILS_SUCCESS,
  payload: companyid,
});

export const StoreDetailsFailed = (error) => ({
  type: STORE_DETAILS_ERROR,
  payload: error,
});
// View store ended
export const DetailsStoreApi = (data) => (dispatch) => {
  try {
    dispatch(StoreDetails(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const storeView = response;
        dispatch(StoreDetailsSuccess(storeView));
      } else {
        dispatch(StoreDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(StoreDetailsFailed(error));
  }
};

// Assign User in store
export const AssignUser = () => ({
  type: ASSIGN_USER_REQUEST,
});

export const AssignUserSuccess = (companyid) => ({
  type: ASSIGN_USER_SUCCESS,
  payload: companyid,
});

export const AssignUserFailed = (error) => ({
  type: ASSIGN_USER_ERROR,
  payload: error,
});
export const AssignUserApi = (data) => (dispatch) => {
  try {
    dispatch(AssignUser(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const assignUserResponce = response;
        dispatch(AssignUserSuccess(assignUserResponce));
      } else {
        dispatch(AssignUserFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AssignUserFailed(error));
  }
};

// Checklist by store
export const ChecklistStore = () => ({
  type: CHECKLIST_BY_STORE_REQUEST,
});

export const ChecklistStoreSuccess = (companyid) => ({
  type: CHECKLIST_BY_STORE_SUCCESS,
  payload: companyid,
});

export const ChecklistStoreFailed = (error) => ({
  type: CHECKLIST_BY_STORE_ERROR,
  payload: error,
});
export const ChecklistStoreApi = (data) => (dispatch) => {
  try {
    dispatch(ChecklistStore(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const checklistByStoreResponce = response;
        dispatch(ChecklistStoreSuccess(checklistByStoreResponce));
      } else {
        dispatch(ChecklistStoreFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ChecklistStoreFailed(error));
  }
};

// Checklist pending
export const PendingChecklist = () => ({
  type: PENDING_CHECKLIST_REQUEST,
});

export const PendingChecklistSuccess = (companyid) => ({
  type: PENDING_CHECKLIST_SUCCESS,
  payload: companyid,
});

export const PendingChecklistFailed = (error) => ({
  type: PENDING_CHECKLIST_ERROR,
  payload: error,
});
export const PendingChecklistApi = (data) => (dispatch) => {
  try {
    dispatch(PendingChecklist(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const pendingchecklist = response;
        dispatch(PendingChecklistSuccess(pendingchecklist));
      } else {
        dispatch(PendingChecklistFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(PendingChecklistFailed(error));
  }
};

//   Add store action
export const AddStoreAction = () => ({
  type: ADD_STORE_REQUEST,
});
export const AddStoreActionSuccess = (companyid) => ({
  type: ADD_STORE_SUCCESS,
  payload: companyid,
});
export const AddStoreActionFailed = (error) => ({
  type: ADD_STORE_ERROR,
  payload: error,
});
export const AddStoreActionApi = (data) => (dispatch) => {
  try {
    dispatch(AddStoreAction(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const AddStoreActionResponce = response;
        dispatch(AddStoreActionSuccess(AddStoreActionResponce));
      } else {
        dispatch(AddStoreActionFailed(response?.response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddStoreActionFailed(error));
  }
};

//   Store Enable disable
export const StoreEnableDisable = () => ({
  type: STORE_ENABLE_DISABLE_REQUEST,
});
export const StoreEnableDisableSuccess = (companyid) => ({
  type: STORE_ENABLE_DISABLE_SUCCESS,
  payload: companyid,
});
export const StoreEnableDisableFailed = (error) => ({
  type: STORE_ENABLE_DISABLE_ERROR,
  payload: error,
});
export const StoreEnableDisableApi = (data) => (dispatch) => {
  try {
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const storeEnableDisableResponce = response;
        dispatch(StoreEnableDisableSuccess(storeEnableDisableResponce));
      } else {
        dispatch(StoreEnableDisableFailed(response?.response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StoreEnableDisableFailed(error));
  }
};

// edit store started
export const EditStoreAction = () => ({
  type: EDIT_STORE_REQUEST,
});

export const EditStoreActionSuccess = (companyid) => ({
  type: EDIT_STORE_SUCCESS,
  payload: companyid,
});

export const EditStoreActionFailed = (error) => ({
  type: EDIT_STORE_ERROR,
  payload: error,
});

export const EditStoreActionApi = (data) => (dispatch) => {
  try {
    dispatch(EditStoreAction(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const updateStore = response;
        dispatch(EditStoreActionSuccess(updateStore));
      } else {
        dispatch(EditStoreActionFailed(response?.response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(EditStoreActionFailed(error));
  }
};

// edit store ended

// Get store by id started
export const StoreByIdAction = () => ({
  type: STORE_BY_ID_EDIT_REQUEST,
});

export const StoreByIdActionSuccess = (companyid) => ({
  type: STORE_BY_ID_EDIT_SUCCESS,
  payload: companyid,
});

export const StoreByIdActionFailed = (error) => ({
  type: STORE_BY_ID_EDIT_ERROR,
  payload: error,
});

export const StoreByIdApi = (data) => (dispatch) => {
  try {
    dispatch(StoreByIdAction(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const storeById = response;
        dispatch(StoreByIdActionSuccess(storeById));
      } else {
        dispatch(StoreByIdActionFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(StoreByIdActionFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const DeleteStore = () => ({
  type: DELETE_STORE_REQUEST,
});

export const DeleteStoreSuccess = (data) => ({
  type: DELETE_STORE_SUCCESS,
  payload: data,
});

export const DeleteStoreFailed = (error) => ({
  type: DELETE_STORE_ERROR,
  payload: error,
});

export const DeleteStoreApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteStore());
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteStoreSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(DeleteStoreFailed(response?.statusText));
        toast.error(`${response.response?.data?.errors[0]?.message}`);
      }
    });
  } catch (error) {
    dispatch(DeleteStoreFailed(error));
    console.error("Something went Wrong", error);
  }
};

// Get store by id ended
