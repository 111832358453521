import React, { useEffect, useState } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Switch from "@mui/material/Switch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { AssessmentArchivedApi } from "../../../redux/actions/LmsAction";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const TableHeader = (props) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handlechange = async (e) => {
    navigate("/assessment/create-assessment", {
      state: { addNewAssesment: "newaddNewAssesment" },
    });
  };

  const data = {
    body: {
      pagination: {
        page: props.page,
        per_page: props.rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
        sort_by: "desc",
      },
      archived: false,
      search: "",
    },
  };

  const AssessmentListArchivedApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `Archivedassessment?company_id=${id}`;
    dispatch(AssessmentArchivedApi(data));
  };
  useEffect(() => {
    if (props.checkedArchive === true) {
      data.body.pagination.page = 1;
      props.setPage(1);
      AssessmentListArchivedApi(data);
    }
  }, [props.checkedArchive]);

  return (
    <>
      <Box>
        <Card className="header-card">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
                className="tabs-content-parent deactive-bg"
              >
                <Tab
                  label="Active"
                  style={{marginLeft: 5}}
                  icon={
                    <p className="active-tabs-content">{props.totalitems}</p>
                  }
                  iconPosition="end"
                  className={
                    props.checkedArchive === false
                      ? "active-bg"
                      : "active-tabs tabs-content"
                  }
                  onClick={() => props.setCheckedArchive(false)}
                />
                <Tab
                  label="Archived"
                  icon={
                    <p className="active-tabs-content">
                      {props.ArchivedListTotalItems}
                    </p>
                  }
                  iconPosition="end"
                  className={
                    props.checkedArchive === true
                      ? "active-bg"
                      : "Archived-tabs tabs-content"
                  }
                  onClick={() => props.setCheckedArchive(true)}
                />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
              <Stack
                direction="row"
                sx={{ float: "right",marginTop:"4px" }}
                className="Assign-Checklist"
              >
                <SearchAppBar
                  searchValue={props.searchValue}
                  searchOnChange={props.searchOnChange}
                  handleKeyDown={props.handleKeyDown}
                />
                {/* <Link
                to=""
                className="submenu-item"
              > */}
                {props?.permission?.lms?.assgiments === true ? (
                  <Button
                    className="header-add cancle-hover"
                    style={{marginRight: 5, height:"42px",borderRadius:"8px"}}
                    onClick={(e) => handlechange(e)}
                  >
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    New Assessment
                  </Button>
                ) : (
                  ""
                )}
                {/* </Link> */}
                <div style={{marginTop:"7px",marginRight:"10px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                  </div>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default TableHeader;

export const ViewHeader = () => {
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: "#F6F8FC",
          width: "100%",
          borderRadius: "8px",
          mt: 1,
          p: 1,
        }}
        alignItems="center"
      >
        <Grid item md={6}>
          <Stack direction="row" spacing={1} sx={{ pl: 2 }}>
            <div>Created :</div>
            <strong>10th January 2020</strong>
            <div>Last Modified :</div>
            <strong>10th January 2020</strong>
          </Stack>
        </Grid>

        <Grid item md={6}>
          <Stack direction="row" spacing={1} sx={{ float: "right", mr: 2 }}>
            <Button variant="outlined" className="search-bar">
              Enable <Switch {...label} />
            </Button>
            <Button variant="outlined" color="error">
              <DeleteOutlineIcon sx={{ mr: 1 }} /> Delete
            </Button>
            <Link to="/assessment" className="submenu-item">
              <Button className="cancle-button cancle-hover">
                <CloseOutlinedIcon sx={{ mr: 1 }} />
                Cancel
              </Button>
            </Link>
            <Link to="/assessment" className="submenu-item">
              <Button variant="outlined" className="done-btn">
                <TaskAltIcon sx={{ mr: 1 }} /> Update
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
