import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Avatar,
} from "@mui/material";
import User from "../../../../assets/icons/svg-icons/User.svg";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function createData(Rank, Employee, Number, Location, Points) {
  return { Rank, Employee, Number, Location, Points };
}

const rows = [
  createData("1", "Alisign", "1234567890", "Panipat", "190"),
  createData("2", "Alisign", "1234567890", "Panipat", "190"),
  createData("3", "Alisign", "1234567890", "Panipat", "190"),
  createData("4", "Alisign", "1234567890", "Panipat", "190"),
  createData("5", "Alisign", "1234567890", "Panipat", "190"),
  createData("6", "Alisign", "1234567890", "Panipat", "190"),
];
const CommonTable = () => {
  const topperformerlist = useSelector(
    (state) =>
      state &&
      state.user &&
      state.user.topformer &&
      state.user.topformer.data &&
      state.user.topformer.data.data &&
      state.user.topformer.data.data.Topperformer
  );
  return (
    <Card className="card" sx={{ height: "100%" }}>
      <CardContent>
        <TableContainer component={Paper} className="card common-table-scroll">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  Compliance Score
                </TableCell>
                <TableCell align="center" className="tableHead">
                  SubRole
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Location
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Points
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topperformerlist &&
                topperformerlist.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">{row.ComplianceScore}</TableCell>
                    <TableCell align="left">
                      <Stack direction="row" spacing={2}>
                        <Avatar className="message-avatar">
                          <img src={User} alt="avatar" />
                        </Avatar>
                        <Stack direction="column">
                          <Typography variant="p" component="b">
                            {row.Name}
                          </Typography>
                          {/* <Typography variant="p" component="p">
                            {row.contact}
                          </Typography> */}
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">{row.SubRole}</TableCell>
                    <TableCell align="center">{row.SubmitCount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default CommonTable;
