import React, { useState, useEffect } from "react";
import { Grid, Tooltip } from "@mui/material";
import { Card, Divider, Typography, Chip } from "material-ui-core";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { OverviewListApi, recentStoreListApi } from "../../redux/actions/Checklist";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";

const ListSection = ({selectedChecklist}) => {
  const dispatch = useDispatch();
  const [overviewData, setOverviewData] = useState();
  const [recentStoreData, setRecentStoreData] = useState();
  const [selectedRole, setSelectedRole] = useState([]);

  const activeCheckListData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allactivechecklist &&
      state?.checklist?.allactivechecklist?.data &&
      state?.checklist?.allactivechecklist?.data?.data
  );
  const ListData = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.overList &&
      state.checklist.overList.data &&
      state.checklist.overList.data.data
  );
  const recentStoreSubmission = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.recentStoreSubmission &&
      state.checklist.recentStoreSubmission.data &&
      state.checklist.recentStoreSubmission.data.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );
  useEffect(() => {
    if (selectedSubRole) {
      setSelectedRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    setOverviewData(ListData);
  }, [ListData]);

  useEffect(() => {
    setRecentStoreData(recentStoreSubmission);
  }, [recentStoreSubmission]);

  // useEffect(() => {
  //   overviewList();
  // }, [dispatch]);

  const overviewList = () => {
    const id = localStorage.getItem("companyId");
    if (selectedSubRole) {
      const data = {
        url:
          BASE_URL +
          `ChecklistOverview?company_id=${id}&Role=["${selectedSubRole}"]`,
          body: {
            checklistIds: selectedChecklist
          }
      };
      dispatch(OverviewListApi(data));
    } else {
      const data = {
        url: BASE_URL + `ChecklistOverview?company_id=${id}&Role=`,
        body: {
          checklistIds: selectedChecklist
        }
      };
      dispatch(OverviewListApi(data));
    }
  };

  const recentStoreList = () => {
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `storewise/recent?company_id=${id}`,
        body: {
          checklistIds: selectedChecklist,
          Role: selectedSubRole || []
        }
    };
    dispatch(recentStoreListApi(data));
    
  };

  useEffect(()=> {
    if(activeCheckListData?.length && selectedChecklist.length){
      recentStoreList();
      overviewList();
    }
  }, [selectedSubRole, selectedChecklist, activeCheckListData])

  // useEffect(() => {
  //   const id = localStorage.getItem("companyId");
  //   if (selectedSubRole) {
  //     const data = {
  //       url:
  //         BASE_URL +
  //         `ChecklistOverview?company_id=${id}&Role=["${selectedSubRole}"]`,
  //       body: {
  //         checklistIds: selectedChecklist
  //       }
  //     };
  //     dispatch(OverviewListApi(data));
  //   } else {
  //     const data = {
  //       url: BASE_URL + `ChecklistOverview?company_id=${id}&Role=`,
  //       body: {
  //         checklistIds: selectedChecklist
  //       }
  //     };
  //     dispatch(OverviewListApi(data));
  //   }
  // }, [selectedSubRole, selectedChecklist]);

  const navigate = useNavigate();

  const handleViewDetails = (row) => {
    localStorage.setItem("allchecklistData", JSON.stringify(row));
    let rowdata = JSON.parse(localStorage.getItem("allchecklistData"));
    if (rowdata) {
      navigate("/checklist/all-checklist/view", { state: { id: row?._id } });
    }
  };

  const handleViewSubmission = (row) => {
    navigate(`/checklist/submission/view-form`, { state: { id: row?._id } });
  };

  const handleStoreView = (id) => {
    navigate(`/store/store-details`, { state: { id: id } });
  };

  return (
    <Grid container spacing={1} className="content-card">
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Card className="overview-list">
        <div style={{float:"right"}}>
          <Tooltip
                  title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>2.Recent Submissions:</strong>
                            <p>This section displays the checklists that was submitted recently.</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          <Typography className="view-all">Recent Submissions</Typography>
          <ul className="overview-ul">
            {overviewData &&
              overviewData.RecentSubmissions &&
              overviewData.RecentSubmissions.map((item, i) => {
                return (
                  <li
                    key={i}
                    onClick={()=>handleViewSubmission(item)}
                  >
                    {" "}
                    <Tooltip
                      placement="right"
                      title={
                        (item?.assignment?.frequency === 3 && "Daily") ||
                        (item?.assignment?.frequency === 2 && "Hourly") ||
                        (item?.assignment?.frequency === 5 && "Monthly") ||
                        (item?.assignment?.frequency === 1 && "No frequency") ||
                        (item?.assignment?.frequency === 4 && "Weekly")
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: `${(item?.assignment?.frequency === 3 && "lightgreen") ||
                              (item?.assignment?.frequency === 2 && "rgb(190, 26, 26)") ||
                              (item?.assignment?.frequency === 5 && "lightskyblue") ||
                              (item?.assignment?.frequency === 1 && "rgb(255, 120, 120)") ||
                              (item?.assignment?.frequency === 4 && "lightsalmon")
                              }`,
                            "& .MuiTooltip-arrow": {
                              color: "white",
                            },
                          },
                        },
                      }}
                    >
                      <Chip
                        label={item?.category?.categoryTitle + " | " + item.userName}
                        variant="outlined"
                        style={{
                          padding: "2px 0px"
                        }}
                        className={
                          (item?.assignment?.frequency === 3 && "list-section daily-hover") ||
                          (item?.assignment?.frequency === 2 && "list-section hourly-hover") ||
                          (item?.assignment?.frequency === 5 && "list-section monthly-hover") ||
                          (item?.assignment?.frequency === 1 && "list-section nofreq-hover") ||
                          (item?.assignment?.frequency === 4 && "list-section weekly-hover")
                        }
                      />
                    </Tooltip>
                  </li>
                );
              })}
          </ul>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Card className="overview-list">
        <div style={{float:"right"}}>
          <Tooltip
                  title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Due Checklist:</strong> 
                            <p>This section displays the due checklist for today</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "5px",
                            top: "8px",        
                            right: "8px",      
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          <Typography className=" view-all">Due Checklist</Typography>
          <ul className="overview-ul">
            {overviewData &&
              overviewData.Due &&
              overviewData.Due.map((item, i) => {
                return (
                  <li key={i} onClick={()=>handleViewDetails(item)}>
                    <Tooltip
                      placement="right"
                      title={
                        (item?.frequency === 3 && "Daily") ||
                        (item?.frequency === 2 && "Hourly") ||
                        (item?.frequency === 5 && "Monthly") ||
                        (item?.frequency === 1 && "No frequency") ||
                        (item?.frequency === 4 && "Weekly")
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: `${(item?.frequency === 3 && "lightgreen") ||
                              (item?.frequency === 2 && "rgb(190, 26, 26)") ||
                              (item?.frequency === 5 && "lightskyblue") ||
                              (item?.frequency === 1 && "rgb(255, 120, 120)") ||
                              (item?.frequency === 4 && "lightsalmon")
                              }`,
                            "& .MuiTooltip-arrow": {
                              color: "white",
                            },
                          },
                        },
                      }}
                    >
                      <Chip
                        label={item?.title  + " | " + (item?.assignedTo?.name || item.role)}
                        variant="outlined"
                        className={
                          (item?.frequency === 3 &&
                            "list-section daily-hover") ||
                          (item?.frequency === 2 &&
                            "list-section hourly-hover") ||
                          (item?.frequency === 5 &&
                            "list-section monthly-hover") ||
                          (item?.frequency === 1 &&
                            "list-section nofreq-hover") ||
                          (item?.frequency === 4 && "list-section weekly-hover")
                        }
                      />
                    </Tooltip>
                  </li>
                );
              })}
          </ul>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Card className="overview-list">
        <div style={{float:"right",}}>
          <Tooltip
                  title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Recent Store Submissions:</strong>
                            <p> Displays the store list  that have recent submissions.</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          <Typography className=" view-all">Recent Store Submissions</Typography>
          <ul className="overview-ul">
            {recentStoreData &&
              recentStoreData.map((item, i) => {
                return (
                  <li key={i} onClick={()=>handleStoreView(item._id)}>
                    <Tooltip
                      placement="right"
                      title={item?.Name + " | " + item.Location + " | Assigned : " + item.Counts.checklistCount + " | Submitted : " + item.Counts.submissionCount}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: `${(item?.frequency === 3 && "lightgreen") ||
                              (item?.frequency === 2 && "rgb(190, 26, 26)") ||
                              (item?.frequency === 5 && "lightskyblue") ||
                              (item?.frequency === 1 && "rgb(255, 120, 120)") ||
                              (item?.frequency === 4 && "lightsalmon")
                              }`,
                            "& .MuiTooltip-arrow": {
                              color: "white",
                            },
                          },
                        },
                      }}
                    >
                      <Chip
                        label={item?.Name + " | " + item.Location + " | Assigned : " + item.Counts.checklistCount + " | Submitted : " + item.Counts.submissionCount}
                        variant="outlined"
                        className={"list-section"
                          // (item?.frequency === 3 &&
                          //   "list-section daily-hover") ||
                          // (item?.frequency === 2 &&
                          //   "list-section hourly-hover") ||
                          // (item?.frequency === 5 &&
                          //   "list-section monthly-hover") ||
                          // (item?.frequency === 1 &&
                          //   "list-section nofreq-hover") ||
                          // (item?.frequency === 4 && "list-section weekly-hover")
                        }
                      />
                    </Tooltip>
                  </li>
                );
              })}
          </ul>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ListSection;
