import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Grid,
} from "@mui/material";
import {
  AllFormListApi,
  CreateAllChecklistApi,
  UpdateAllchecklistApi,
  UpdateAllchecklistSuccess,
} from "../../redux/actions/Checklist";
import { UserSubRoleApi, ListUserApi } from "../../redux/actions/User";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { CreateRolesApi } from "../../redux/actions/Roles";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RenewChecklist = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [attachValue, setAttachValue] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [mapSelectData, setMapSelectData] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [reportSelect, setReportSelect] = useState([]);
  const [selectedReport, setSelectedReport] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newDate, setNewDate] = useState({});
  const [frequencyValue, setFrequencyValue] = useState("");
  const [fieldValue, setFieldValue] = useState({});
  const [newFieldValue, setNewFieldValue] = useState({});
  // console.log("startDate", startDate)
  const valuesEdit = props?.editvalue;
  // console.log("valuesEdit", valuesEdit)

  useEffect(() => {
    setFieldValue(valuesEdit);
  }, [valuesEdit]);

  const isAllSelectedReport =
    reportSelect.length > 0 && selectedReport.length === reportSelect.length;

  const selectForm = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.allform &&
      state.checklist.allform.data &&
      state.checklist.allform.data.data &&
      state.checklist.allform.data.data.forms
  );
  const subRoleList = useSelector(
    (state) =>
      state.user &&
      state.user.subRole &&
      state.user.subRole.data &&
      state.user.subRole.data.data &&
      state.user.subRole.data.data
  );
  const renewChecklistRes = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.updateResponse &&
      state.checklist.updateResponse.data
  );

  useEffect(() => {
    if (fieldValue) {
      if (fieldValue && fieldValue?.mailReport) {
        setSelectedReport(fieldValue?.mailReport);
      }
    }
  }, [fieldValue]);

  useEffect(() => {
    const category = JSON.parse(localStorage.getItem("categoryData"));
    setCategoryData(category);
  }, []);

  // subrole lisiting api
  useEffect(() => {
    // dispatch(UpdateAllchecklistSuccess(""));
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(UserSubRoleApi(data));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectForm) {
      let arr = [];
      for (let item of selectForm) {
        let obj = item;
        arr.push(obj);
      }
      setMapSelectData(arr);
    }
  }, [selectForm]);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        let obj = item;
        arr.push(obj);
      }
      setRoleSelect(arr);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (subRoleList) {
      let arr = [];
      for (let item of subRoleList) {
        let obj = item?.name;
        arr.push(obj);
      }
      setReportSelect(arr);
    }
  }, [subRoleList]);

  useEffect(() => {
    if (renewChecklistRes && renewChecklistRes.status === 200) {
      // toast.success(renewChecklistRes.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/checklist/all-checklist");
      }, 1000);
    }
  }, [renewChecklistRes]);

  const handleChangeReport = (event) => {
    const value = event.target.value.filter((val) => !!val);
    const isClickAll = value[value.length - 1] === "all";
    if (isClickAll) {
      setSelectedReport(
        selectedReport.length === reportSelect?.length ? [] : selectedReport
      );
      return;
    }
    setSelectedReport(value);
  };

  const formik = useFormik({
    initialValues: newFieldValue || {
      reportselect: "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      values.mailReport = selectedReport;
      values.forms = selected;
      values.starts_at = startDate;
      values.expires_at = endDate;

      const allpayload = {
        assignedTo: fieldValue && fieldValue.assignedTo,
        endTime: fieldValue && fieldValue.endTime,
        expires_at: values.expires_at,
        forms: fieldValue && fieldValue.forms,
        frequency: fieldValue && fieldValue.frequency,
        image_category: fieldValue && fieldValue.imageCategory,
        isBatchActive: fieldValue && fieldValue.isBatchActive,
        mailReport: values.mailReport,
        month_date: fieldValue && fieldValue.month_date,
        role: fieldValue && fieldValue.role,
        startTime: fieldValue && fieldValue.startTime,
        starts_at: values.starts_at,
        time_interval: fieldValue && fieldValue.startTime,
        title: fieldValue && fieldValue.title,
        type: fieldValue && fieldValue.type,
        week_day: fieldValue && fieldValue.week_day,
        _id: fieldValue._id,
      };
      const formData = {
        body: allpayload,
      };
      if (formData) {
        const id = localStorage.getItem("companyId");
        formData.url = BASE_URL + `formsAssignmentUpdate?company_id=${id}`;
        dispatch(UpdateAllchecklistApi(formData));
      }
      // resetForm({ values: '' })
      setStartDate("");
      setEndDate("");
      props.handleClose();
    },
  });

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
        >
          <Typography variant="p" component="div" className="heading">
            Edit Assignment
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl fullWidth>
              <label style={{ marginBottom: "5px" }}>Survey period</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={5}>
                    <DatePicker
                      label="Start Date"
                      size="small"
                      disablePast
                      value={startDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYYMMDD")
                          .split("/")
                          .join("");
                        setStartDate(dateSort);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      minDate={new Date()}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Box sx={{ mx: 2 }}> To </Box>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <DatePicker
                      label="Expire Date"
                      size="small"
                      disablePast
                      value={endDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYYMMDD")
                          .split("/")
                          .join("");
                        setEndDate(dateSort);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      minDate={startDate}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <label>Send Report</label>
              <Select
                labelId="mutiple-select-label"
                multiple
                sx={{ borderRadius: "8px" }}
                name="reportselect"
                size="small"
                value={selectedReport}
                onChange={handleChangeReport}
                renderValue={(selectedReport) => selectedReport.join(", ")}
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: "#FFFFFF",
                      "& .MuiMenuItem-root": {
                        padding: 0,
                      },
                      height: "300px",
                    },
                  },
                }}
              >
                <MenuItem
                  classes={{
                    root: isAllSelectedReport ? classes.selectedAll : "",
                  }}
                  value="all"
                  name="all"
                  data-button-all
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{
                        indeterminate: classes.indeterminateColor,
                      }}
                      checked={isAllSelectedReport}
                      indeterminate={
                        selectedReport.length > 0 &&
                        selectedReport.length < reportSelect?.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {reportSelect &&
                  reportSelect.map((option, i) => (
                    <MenuItem key={i} value={option} name={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedReport.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              // onClick={props.handleClose}
              color="success"
            >
              Save
            </Button>
            <Button
              variant="contained"
              type="reset"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default RenewChecklist;
