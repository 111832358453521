import AddEntryHeader from "./AddEntryHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { CreateLogEntryApi, EditLogEntryApi } from "../redux/actions/LogEntry";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";

const AddNewLogEntry = () => {
  let logEntrydata = localStorage.getItem("addLogEntry") && JSON.parse(localStorage.getItem("addLogEntry")) || localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));
  let addQustionEditData = localStorage.getItem("questions") && JSON.parse(localStorage.getItem("questions")) || localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId")).questions;
  // let editLogEntrydata = JSON.parse(localStorage.getItem("userId"));
  const validationSchema = Yup.object().shape({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [indexHover, setIndexHover] = useState(0);
  const dragOverItem = useRef();
  const dragItem = useRef();

  const logEntryLoader = useSelector(
    (state) =>
      state.logEntry &&
      state.logEntry.loading
  );

  const [questionData, setQuestionData] = useState([]);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    const copyListItems = [...questionData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setQuestionData(copyListItems);
  };

  const handleDelete = (index) => {
    const localdata = questionData.filter((item, i) => i !== index);
    setQuestionData(localdata);
    localStorage.setItem("questions", JSON.stringify(localdata));
  };

  useEffect(() => {
    if (questionData)
      localStorage.setItem("qustionDataid", questionData.length);
  }, [questionData]);

  // const addQustionEditData = useSelector(
  //     (state) => state && state.allForm && state.allForm.addqustionedit
  //   );
  const formik = useFormik({
    initialValues: {
      logEntryTitle: logEntrydata?.logEntryTitle || "",
      // logEntrySlug: logEntrydata?.logEntrySlug || "",
      description: logEntrydata?.description || ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFinalSubmit(values);
    },
  });

  const handleFinalSubmit = (values) => {
    const isEdit = localStorage.getItem('userId');

    if(!values.logEntryTitle){
      return toast.error("Title is required!");
    }
    // if(!values.logEntrySlug){
    //   toast.error("Unique Slug is required!");
    // }
    if(!values.description){
      return toast.error("Description is required!");
    }
    if(!questionData.length){
      return toast.error("Questions required!");
    }

    if (values) {
      const formquestionValue = {
        questions: questionData,
        logEntryTitle: values.logEntryTitle,
        // logEntrySlug: values.logEntrySlug,
        description: values.description,
      };
      const allData = {
        body: formquestionValue,
      };
      const id = localStorage.getItem("companyId");
      if (isEdit && JSON.parse(isEdit)) {
        allData.url = BASE_URL + `LogEntry/${JSON.parse(isEdit)?._id}?company_id=${id}`;
        dispatch(EditLogEntryApi(allData, navigatioToList));
      } else {
        allData.url = BASE_URL + `LogEntry?company_id=${id}`;
        dispatch(CreateLogEntryApi(allData, navigatioToList));
      }
      // localStorage.setItem("rules", "[]")
      // dispatch(aEditAllFormListApi(allData))
      // navigate("/checklist/all-form")
    }
  };

  const navigatioToList = (data) => {
    if (data === 200) {
      setTimeout(() => {
        navigate({ pathname: "/log-entry" });
        localStorage.removeItem("addLogEntry")
        localStorage.removeItem("questions")
      }, 2000);
    }
  };

  const handleClick = (item) => {
    let oldQues = localStorage.getItem("questions");
    if (oldQues) {
      let oldD1 = JSON.parse(oldQues);
      const fetchFilterableQues = oldD1.filter(ques => ques.allowFilter);
      if (fetchFilterableQues.length >= 15) {
        toast.warn("Max 15 Question you can add!")
        return;
      }
    }
    localStorage.setItem("addLogEntry", JSON.stringify(formik.values));
    localStorage.setItem("default", true);
    localStorage.setItem("questions", JSON.stringify(questionData));
    navigate("/log-entry/create/add-log-question");
  };
  const handleCancel = () => {
    localStorage.removeItem("addLogEntry")
    localStorage.removeItem("questions")
    localStorage.removeItem("userId")
  };

  useEffect(() => {
    if (addQustionEditData) {
      setQuestionData(addQustionEditData);
    }
  }, []);

  const handleClickEditform = (item, i) => {
    navigate("/log-entry/add/edit-log-question");
    localStorage.setItem("addQustionEditData", JSON.stringify(item));
    localStorage.setItem("qustioneditid", i);
    localStorage.setItem("questions", JSON.stringify(questionData));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <ToastContainer autoClose={3000} />
      {logEntryLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>)
      }

      <AddEntryHeader handleFinalSubmit={handleFinalSubmit} handleCancel={handleCancel} values={formik} />
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            p: 1,
            textAlign: "left",
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Title</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="logEntryTitle"
              onChange={formik.handleChange}
              // defaultValue={trainingdata?.name}
              value={formik.values.logEntryTitle}
              className="date-input"
            />
          </FormControl>
          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Unique Slug</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="logEntrySlug"
              onChange={formik.handleChange}
              // defaultValue={trainingdata?.name}
              value={formik.values.logEntrySlug}
              className="date-input"
            />
          </FormControl> */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Description</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="description"
              onChange={formik.handleChange}
              // defaultValue={trainingdata?.name}
              value={formik.values.description}
              className="date-input"
            />
          </FormControl>
          <Grid item md={12} xs={12} sm={12} lg={12}>
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: "center", margin: "0 0 20px" }}
            >
              <Grid item md={12} sx={12} sm={12}>
                <Typography variant="p" component="b" sx={{ mt: 2 }}>
                  Question
                </Typography>
                <Button
                  variant="outlined"
                  className="add-button cancle-hover"
                  sx={{ width: "99%", mt: 1 }}
                  onClick={handleClick}
                >
                  <AddIcon sx={{ mx: 1 }} />
                  Add Question
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {console.log("questionData", questionData)}
          {questionData &&
            questionData?.map((items, i) => {
              return (
                <Card sx={{ mb: 2, borderRadius: "8px" }} key={i}>
                  <div
                    style={{ cursor: "grab", textAlign: "center", marginTop: "5px" }}
                    onDragStart={(e) => dragStart(e, i)}
                    onDragEnter={(e) => dragEnter(e, i)}
                    onDragEnd={drop}
                    draggable
                  >
                    <DehazeIcon />
                  </div>
                  <Box className="align-icon">
                    <Typography
                      variant="p"
                      component="h2"
                      className="top-heading"
                      align="left"
                    >
                      Available Question
                    </Typography>
                  </Box>
                  <CardContent sx={{ color: "#27364b" }}>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Q {i + 1}.
                      </Typography>
                      <Typography
                        variant="p"
                        component="b"
                        style={{ textAlign: "left" }}
                      >
                        {items?.title}
                      </Typography>
                    </Stack>

                    <Box className="align-icon" sx={{ margin: "10px 0px" }}>
                      <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                        <Typography variant="p" component="p">
                          Type :
                        </Typography>
                        <Typography variant="p" component="b">
                          {items?.type?.title}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <img
                          src={
                            hover.Edit && indexHover === i ? EditHover : Edit
                          }
                          alt="Edit"
                          onClick={(e) => handleClickEditform(items, i)}
                          onMouseOver={() => {
                            setHover({ Edit: true });
                            setIndexHover(i);
                          }}
                          onMouseLeave={() => {
                            setHover({ Edit: false });
                          }}
                          style={{
                            cursor: "pointer",
                            width: "33px",
                            height: "33px",
                          }}
                        />
                        <img
                          src={
                            hover.Delete && indexHover === i
                              ? DeleteHover
                              : Delete
                          }
                          alt="Delete"
                          onClick={() => handleDelete(i)}
                          onMouseOver={() => {
                            setHover({ Delete: true });
                            setIndexHover(i);
                          }}
                          onMouseLeave={() => {
                            setHover({ Delete: false });
                          }}
                          style={{
                            cursor: "pointer",
                            width: "33px",
                            height: "33px",
                          }}
                        />
                      </Stack>
                    </Box>

                    <Stack
                      direction="row"
                      spacing={1}
                      className="validation-div"
                    >
                      <Typography
                        variant="p"
                        component="b"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        Validation :
                      </Typography>
                      {items?.type?.validations?.map((items, i) => {
                        return (
                          <li key={i}>
                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "required"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "required" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b>{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}

                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "audio"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "audio" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b>{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}

                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "allowFutureDates"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "allowFutureDates" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b>{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}

                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "allowPastDates"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "allowPastDates" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b className="">{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}

                            <div style={{
                              display: 'flex'
                            }}>
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "mediaFileType"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "mediaFileType" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                            </div>

                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "comment_required"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "comment_required" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b>{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}
                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "image_optional"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "image_optional" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b>{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}
                            {items?.answer
                              ?.filter((data) => data?.value === true)
                              ?.map((datas, i) => {
                                const answer = datas?.value === true;
                                return (
                                  <>
                                    {answer ? (
                                      <Typography
                                        className={
                                          items.key === "video_optional"
                                            ? "Validations-box validation-font"
                                            : ""
                                        }
                                      >
                                        {items.key === "video_optional" ? (
                                          <>
                                            {items?.title} :{" "}
                                            <b>
                                              <b className="video-validation">{datas?.title}</b>
                                            </b>{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}{" "}
                                  </>
                                );
                              })}
                          </li>
                        );
                      })}
                    </Stack>
                  </CardContent>
                </Card>
              );
            })}
        </Container>
      </Box>
    </form>
  )
};

export default AddNewLogEntry