/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import {
  LIST_CERTIFICATE_ERROR,
  LIST_CERTIFICATE_REQUEST,
  IMAGE_CERTIFICATE_REQUEST,
  IMAGE_CERTIFICATE_SUCCESS,
  IMAGE_CERTIFICATE_ERROR,
  LIST_CERTIFICATE_SUCCESS,
  LIST_TRAINING_ERROR,
  LIST_TRAINING_REQUEST,
  LIST_TRAINING_SUCCESS,
  LMS_LISTING_DETAILS_REQUEST,
  LMS_LISTING_DETAILS_SUCCESS,
  LMS_LISTING_DETAILS_ERROR,
  ADD_CERTIFICATE_REQUEST,
  ADD_CERTIFICATE_SUCCESS,
  ADD_CERTIFICATE_ERROR,
  ADD_CERTIFICATE_IMAGE_REQUEST,
  ADD_CERTIFICATE_IMAGE_SUCCESS,
  ADD_CERTIFICATE_IMAGE_ERROR,
  GET_CERTIFICATE_DATA_REQUEST,
  GET_CERTIFICATE_DATA_SUCCESS,
  GET_CERTIFICATE_DATA_ERROR,
  EDIT_CERTIFICATE_REQUEST,
  EDIT_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_ERROR,
  DELETE_CERTIFICATE_REQUEST,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_ERROR,
  PROGRESS_LIST_SUCCESS,
  PROGRESS_LIST_ERROR,
  PROGRESS_LIST_REQUEST,
  RESET_LIST_REQUEST,
  RESET_LIST_SUCCESS,
  RESET_LIST_ERROR,
  CREATE_NEW_TRAINING_REQUEST,
  CREATE_NEW_TRAINING_SUCCESS,
  CREATE_NEW_TRAINING_ERROR,
  ASSESSMENT_LIST_REQUEST,
  ASSESSMENT_LIST_SUCCESS,
  ASSESSMENT_LIST_ERROR,
  ADD_ASSESSMENT_REQUEST,
  ADD_ASSESSMENT_SUCCESS,
  ADD_ASSESSMENT_ERROR,
  ADD_TRAINING_IMAGE_REQUEST,
  ADD_TRAINING_IMAGE_SUCCESS,
  ADD_TRAINING_IMAGE_ERROR,
  ASSESSMENT_DELETE_SUCCESS,
  ASSESSMENT_DELETE_ERROR,
  ASSESSMENT_DELETE_REQUEST,
  UPDATE_TRAINING_REQUEST,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_TRAINING_ERROR,
  USER_TRAINING_LIST_REQUEST,
  USER_TRAINING_LIST_SUCCESS,
  USER_TRAINING_LIST_ERROR,
  CERTIFICATE_ARCHIVED_REQUEST,
  CERTIFICATE_ARCHIVED_SUCCESS,
  CERTIFICATE_ARCHIVED_ERROR,
  STORE_TRAINING_LIST_REQUEST,
  STORE_TRAINING_LIST_SUCCESS,
  STORE_TRAINING_LIST_ERROR,
  STORE_TRAINING_VIEW_LIST_ERROR,
  STORE_TRAINING_VIEW_LIST_SUCCESS,
  STORE_TRAINING_VIEW_LIST_REQUEST,
  INDIVIDUAL_STORE_TRAINING_VIEW_LIST_REQUEST,
  INDIVIDUAL_STORE_TRAINING_VIEW_LIST_SUCCESS,
  INDIVIDUAL_STORE_TRAINING_VIEW_LIST_ERROR,
  LMS_REPORT_REQUEST,
  LMS_REPORT_SUCCESS,
  LMS_REPORT_ERROR,
  TRAINING_BY_USER_REQUEST,
  TRAINING_BY_USER_SUCCESS,
  TRAINING_BY_USER_ERROR,
  ASSESSMENTLIST_UPDATE_REQUEST,
  ASSESSMENTLIST_UPDATE_SUCCESS,
  ASSESSMENTLIST_UPDATE_ERROR,
  ASSESSMENT_ARCHIVED_REQUEST,
  ASSESSMENT_ARCHIVED_SUCCESS,
  ASSESSMENT_ARCHIVED_ERROR,
  TRAINING_BY_STORE_REQUEST,
  TRAINING_BY_STORE_SUCCESS,
  TRAINING_BY_STORE_ERROR,
  LMS_TRAINING_CHART_REQUEST,
  LMS_TRAINING_CHART_SUCCESS,
  LMS_TRAINING_CHART_ERROR,
  TRAINING_ARCHIVED_ERROR,
  TRAINING_ARCHIVED_REQUEST,
  TRAINING_ARCHIVED_SUCCESS,
  LMS_RECENT_COURSE_REQUEST,
  LMS_RECENT_COURSE_SUCCESS,
  LMS_RECENT_COURSE_ERROR,
  LMS_DETAIL_VIEW_REQUEST,
  LMS_DETAIL_VIEW_SUCCESS,
  LMS_DETAIL_VIEW_ERROR,
  UPDATE_ASSESSMENT_REQUEST,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_ERROR,
  LMS_TRAINING_SUCCESS,
  LMS_TRAINING_REQUEST,
  LMS_TRAINING_FAILED,
  TDR_VIDEO_TYPE_REQUEST,
  TDR_VIDEO_TYPE_SUCCESS,
  TDR_VIDEO_TYPE_ERROR,
  ON_GOING_REQUEST,
  ON_GOING_SUCCESS,
  ON_GOING_ERROR,
  COMPLETED_COURSE_REQUEST,
  COMPLETED_COURSE_SUCCESS,
  COMPLETED_COURSE_ERROR,
  LMS_LISTING_DETAILS_REQUEST1,
  LMS_LISTING_DETAILS_SUCCESS1,
  LMS_LISTING_DETAILS_ERROR1,
  TDR_VIDEO_TYPE_REQUEST1,
  TDR_VIDEO_TYPE_SUCCESS1,
  TDR_VIDEO_TYPE_ERROR1,
  ADD_ASSESSMENT_IMAGE_REQUEST,
  ADD_ASSESSMENT_IMAGE_SUCCESS,
  ADD_ASSESSMENT_IMAGE_ERROR,
  ADD_ASSESSMENT_IMAGE_CLEAR,
  DELETE_ASSESSMENT_IMAGE,
  ACTIVELMSREPORT_REQUEST,
  ACTIVELMSREPORT_SUCCESS,
  ACTIVELMSREPORT_ERROR,
  ADD_CERTIFICATE_IMAGE_REQUEST_RESET,
} from "../types/Types";
import { toast } from "react-toastify";

// All training started
export const ListTrainings = () => ({
  type: LIST_TRAINING_REQUEST,
});
export const ListTrainingsSuccess = (companyid) => ({
  type: LIST_TRAINING_SUCCESS,
  payload: companyid,
});
export const ListTrainingsFailed = (error) => ({
  type: LIST_TRAINING_ERROR,
  payload: error,
});
// All training ended

// Certificate list started
export const ListCertificate = () => ({
  type: LIST_CERTIFICATE_REQUEST,
});
export const ListCertificateSuccess = (companyid) => ({
  type: LIST_CERTIFICATE_SUCCESS,
  payload: companyid,
});
export const ListCertificateFailed = (error) => ({
  type: LIST_CERTIFICATE_ERROR,
  payload: error,
});
// Certificate list ended

// Certificate Edit ended

export const AssessmentUpdateReq = () => ({
  type: ASSESSMENTLIST_UPDATE_REQUEST,
});
export const AssessmentUpdateSuccess = (companyid) => ({
  type: ASSESSMENTLIST_UPDATE_SUCCESS,
  payload: companyid,
});
export const AssessmentUpdateFailed = (error) => ({
  type: ASSESSMENTLIST_UPDATE_ERROR,
  payload: error,
});

export const AddAssessmentImage = (companyid) => ({
  type: ADD_ASSESSMENT_IMAGE_REQUEST,
  payload: companyid,
});

export const AddAssessmentImageClear = (companyid) => ({
  type: ADD_ASSESSMENT_IMAGE_CLEAR,
  payload: companyid,
});

export const AddAssessmentImageSuccess = (companyid) => ({
  type: ADD_ASSESSMENT_IMAGE_SUCCESS,
  payload: companyid,
});

export const DeleteAssessmentImage = (companyid) => ({
  type: DELETE_ASSESSMENT_IMAGE,
  payload: companyid,
});

export const AddAssessmentImageFailed = (error) => ({
  type: ADD_ASSESSMENT_IMAGE_ERROR,
  payload: error,
});

export const AllTrainingReportApi = (data) => (dispatch) => {
  try {
    dispatch(ListTrainings(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListTrainingsSuccess(data));
      } else {
        dispatch(ListTrainingsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListTrainingsFailed(error));
    console.error("Something went Wrong", error);
  }
};

// on going course
export const OnGoingReq = () => ({
  type: ON_GOING_REQUEST,
});
export const OnGoingSuccess = (companyid) => ({
  type: ON_GOING_SUCCESS,
  payload: companyid,
});
export const OnGoingFailed = (error) => ({
  type: ON_GOING_ERROR,
  payload: error,
});

export const OnGoingApi = (data) => (dispatch) => {
  try {
    dispatch(OnGoingReq(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(OnGoingSuccess(data));
      } else {
        dispatch(OnGoingFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(OnGoingFailed(error));
    console.error("Something went Wrong", error);
  }
};

//completed course
export const CompletedCourseReq = () => ({
  type: COMPLETED_COURSE_REQUEST,
});
export const CompletedCourseSuccess = (companyid) => ({
  type: COMPLETED_COURSE_SUCCESS,
  payload: companyid,
});
export const CompletedCourseFailed = (error) => ({
  type: COMPLETED_COURSE_ERROR,
  payload: error,
});

export const CompletedCourseApi = (data) => (dispatch) => {
  try {
    dispatch(CompletedCourseReq(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CompletedCourseSuccess(data));
      } else {
        dispatch(CompletedCourseFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(CompletedCourseFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const CertificateListApi = (data) => (dispatch) => {
  try {
    dispatch(ListCertificate(data));
    JwtAuthService.LmsCertificateListApiCAll(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListCertificateSuccess(data));
      } else {
        dispatch(ListCertificateFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListCertificateFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const AssessmentUpdateListApi = (data) => (dispatch) => {
  try {
    dispatch(AssessmentUpdateReq(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(AssessmentUpdateSuccess(data));
      } else {
        dispatch(AssessmentUpdateFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListCertificateFailed(error));
    console.error("Something went Wrong", error);
  }
};

// export const CertificateImageApi = (data) => (dispatch) => {
//   try {
//     dispatch(ImageCertificate(data));
//     JwtAuthService.PostApiService(data).then((response) => {
//       if (response?.status === 200) {
//         const data = response;
//         dispatch(ImageCertificateSuccess(data));
//       } else {
//         dispatch(ImageCertificateFailed(response.statusText));
//       }
//     });
//   } catch (error) {
//     dispatch(ImageCertificateFailed(error));
//     console.error("Something went Wrong", error);
//   }
// };

export const AddCertificateImageReset = (companyid) => ({
  type: ADD_CERTIFICATE_IMAGE_REQUEST_RESET,
  payload: companyid,
});

export const AddCertificateImage = (companyid) => ({
  type: ADD_CERTIFICATE_IMAGE_REQUEST,
  payload: companyid,
});

export const AddCertificateImageSuccess = (companyid) => ({
  type: ADD_CERTIFICATE_IMAGE_SUCCESS,
  payload: companyid,
});

export const AddCertificateImageFailed = (error) => ({
  type: ADD_CERTIFICATE_IMAGE_ERROR,
  payload: error,
});

export const AddCertificateImageApi = (data, item) => (dispatch) => {
  try {
    dispatch(AddCertificateImage(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        // console.log("success", item);
        dispatch(AddCertificateImageSuccess({imageData: data.data.data, chapterData: item}));
      } else {
        dispatch(AddCertificateImageFailed(response?.response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddCertificateImageFailed(error));
  }
};

export const AddTrainingImage = (companyid) => ({
  type: ADD_TRAINING_IMAGE_REQUEST,
  payload: companyid,
});

export const AddTrainingImageSuccess = (companyid) => ({
  type: ADD_TRAINING_IMAGE_SUCCESS,
  payload: companyid,
});

export const AddTrainingImageFailed = (error) => ({
  type: ADD_TRAINING_IMAGE_ERROR,
  payload: error,
});

export const AddTrainingImageApi = (data) => (dispatch) => {
  try {
    dispatch(AddTrainingImage(data));
    JwtAuthService.BinaryImageApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddTrainingImageSuccess(data));
      } else {
        dispatch(AddTrainingImageFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddTrainingImageFailed(error));
  }
};

export const AddCertificate = (companyid) => ({
  type: ADD_CERTIFICATE_REQUEST,
  payload: companyid,
});

export const AddCertificateSuccess = (companyid) => ({
  type: ADD_CERTIFICATE_SUCCESS,
  payload: companyid,
});

export const AddCertificateFailed = (error) => ({
  type: ADD_CERTIFICATE_ERROR,
  payload: error,
});

export const AddCertificateApi = (data) => (dispatch) => {
  try {
    dispatch(AddCertificate(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddCertificateSuccess(data));
        toast.success(`${response?.data?.message}`, { autoClose: 3000 });
      } else {
        dispatch(AddCertificateFailed(response?.statusText));
        toast.error(
          `${
            response.response?.data?.message?.message
              ? response.response?.data?.message?.message
              : response.response?.data?.message
          }`,
          { autoClose: 3000 }
        );
      }
    });
  } catch (error) {
    dispatch(AddCertificateFailed(error));
  }
};
// add assessment start

export const AddAssessmentImageAnswerApi =
  (data, qId, oldData) => (dispatch) => {
    try {
      dispatch(AddAssessmentImage(data));
      JwtAuthService.BinaryImageApiService(data).then((response) => {
        if (response?.status == 200) {
          const data = response;
          dispatch(
            AddAssessmentImageSuccess({
              [qId]: [...oldData, data.data.data[0]],
            })
          );
        } else {
          dispatch(AddAssessmentImageSuccess(response?.response?.statusText));
          toast.error(`${response?.response?.data?.message}`);
        }
      });
    } catch (error) {
      dispatch(AddAssessmentImageFailed(error));
    }
  };

export const AddAssessmentRequest = (companyid) => ({
  type: ADD_ASSESSMENT_REQUEST,
  payload: companyid,
});

export const AddAssessmentSuccess = (companyid) => ({
  type: ADD_ASSESSMENT_SUCCESS,
  payload: companyid,
});

export const AddAssessmentFailed = (error) => ({
  type: ADD_ASSESSMENT_ERROR,
  payload: error,
});

export const AddAssessmentApi = (data, history) => (dispatch) => {
  try {
    dispatch(AddAssessmentRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        history(data);
        dispatch(AddAssessmentSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        history(data);
        dispatch(AddAssessmentFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddAssessmentFailed(error));
  }
};

export const UpdateAssesmentRequest = (companyid) => ({
  type: UPDATE_ASSESSMENT_REQUEST,
  payload: companyid,
});

export const UpdateAssesmentSuccess = (companyid) => ({
  type: UPDATE_ASSESSMENT_SUCCESS,
  payload: companyid,
});

export const UpdateAssesmentFailed = (error) => ({
  type: UPDATE_ASSESSMENT_ERROR,
  payload: error,
});

export const UpdateAssesmentApi = (data, history) => (dispatch) => {
  try {
    dispatch(UpdateAssesmentRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        history(data);
        dispatch(UpdateAssesmentSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        history(data);
        dispatch(UpdateAssesmentFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UpdateAssesmentFailed(error));
  }
};

// add assessment end

export const LmsTrainingLineChartRequest = (companyid) => ({
  type: LMS_TRAINING_CHART_REQUEST,
  payload: companyid,
});

export const LmsTrainingLineChartSuccess = (data) => ({
  type: LMS_TRAINING_CHART_SUCCESS,
  payload: data,
});

export const LmsTrainingLineChartFailed = (error) => ({
  type: LMS_TRAINING_CHART_ERROR,
  payload: error,
});

export const LmsTrainingLineChartApi = (data, history) => (dispatch) => {
  try {
    dispatch(LmsTrainingLineChartRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        // history(data);
        dispatch(LmsTrainingLineChartSuccess(data));
      } else {
        history(data);
        dispatch(LmsTrainingLineChartFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(LmsTrainingLineChartFailed(error));
  }
};

// LMS RECENT COURSE

export const LmsRecentCourseRequest = (companyid) => ({
  type: LMS_RECENT_COURSE_REQUEST,
  payload: companyid,
});

export const LmsRecentCourseSuccess = (data) => ({
  type: LMS_RECENT_COURSE_SUCCESS,
  payload: data,
});

export const LmsRecentCourseFailed = (error) => ({
  type: LMS_RECENT_COURSE_ERROR,
  payload: error,
});

export const LmsRecentCourseApi = (data, history) => (dispatch) => {
  try {
    dispatch(LmsRecentCourseRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        // history(data);
        dispatch(LmsRecentCourseSuccess(data));
      } else {
        history(data);
        dispatch(LmsRecentCourseFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(LmsTrainingLineChartFailed(error));
  }
};

export const GetCertificateData = (companyid) => ({
  type: GET_CERTIFICATE_DATA_REQUEST,
  payload: companyid,
});

export const GetCertificateDataSuccess = (companyid) => ({
  type: GET_CERTIFICATE_DATA_SUCCESS,
  payload: companyid,
});

export const GetCertificateDataFailed = (error) => ({
  type: GET_CERTIFICATE_DATA_ERROR,
  payload: error,
});

export const GetCertificateDataApi = (data) => (dispatch) => {
  try {
    dispatch(GetCertificateData(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(GetCertificateDataSuccess(data));
      } else {
        dispatch(GetCertificateDataFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(GetCertificateDataFailed(error));
  }
};

export const EditCertificate = () => ({
  type: EDIT_CERTIFICATE_REQUEST,
});
export const EditCertificateSuccess = (companyid) => ({
  type: EDIT_CERTIFICATE_SUCCESS,
  payload: companyid,
});
export const EditCertificateFailed = (error) => ({
  type: EDIT_CERTIFICATE_ERROR,
  payload: error,
});

export const EditCertificateApi = (data) => (dispatch) => {
  try {
    dispatch(EditCertificate(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(EditCertificateSuccess(data));
        toast.success(`${response?.data?.message}`, { autoClose: 3000 });
      } else {
        dispatch(EditCertificateFailed(response?.statusText));
        toast.error(
          `${
            response.response?.data?.message?.message
              ? response.response?.data?.message?.message
              : response.response?.data?.message
          }`,
          { autoClose: 3000 }
        );
      }
    });
  } catch (error) {
    dispatch(EditCertificateFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const DeleteCertificate = () => ({
  type: DELETE_CERTIFICATE_REQUEST,
});
export const DeleteCertificateSuccess = (companyid) => ({
  type: DELETE_CERTIFICATE_SUCCESS,
  payload: companyid,
});
export const DeleteCertificateFailed = (error) => ({
  type: DELETE_CERTIFICATE_ERROR,
  payload: error,
});

export const DeleteCertificateApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteCertificate(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(DeleteCertificateSuccess(data));
      } else {
        dispatch(DeleteCertificateFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteCertificateFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const TrainingDetailsRequest = () => ({
  type: LMS_LISTING_DETAILS_REQUEST,
});
export const TrainingDetailsSuccess = (companyid) => ({
  type: LMS_LISTING_DETAILS_SUCCESS,
  payload: companyid,
});
export const TrainingDetailsFailed = (error) => ({
  type: LMS_LISTING_DETAILS_ERROR,
  payload: error,
});

export const TrainingDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(TrainingDetailsRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(TrainingDetailsSuccess(data));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(TrainingDetailsFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(TrainingDetailsFailed(error));
    console.error("Something went Wrong", error);
  }
};

// auditor chapter submission
export const TrainingDetailsRequest1 = () => ({
  type: LMS_LISTING_DETAILS_REQUEST1,
});
export const TrainingDetailsSuccess1 = (companyid) => ({
  type: LMS_LISTING_DETAILS_SUCCESS1,
  payload: companyid,
});
export const TrainingDetailsFailed1 = (error) => ({
  type: LMS_LISTING_DETAILS_ERROR1,
  payload: error,
});

export const TrainingDetailsApi1 = (data) => (dispatch) => {
  try {
    dispatch(TrainingDetailsRequest1(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(TrainingDetailsSuccess1(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(TrainingDetailsFailed1(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(TrainingDetailsFailed1(error));
    console.error("Something went Wrong", error);
  }
};

export const TDRVideoType = () => ({
  type: TDR_VIDEO_TYPE_REQUEST,
});
export const TDRVideoTypeSuccess = (companyid) => ({
  type: TDR_VIDEO_TYPE_SUCCESS,
  payload: companyid,
});
export const TDRVideoTypeFailed = (error) => ({
  type: TDR_VIDEO_TYPE_ERROR,
  payload: error,
});

export const TDRVideoTypeApi = (data) => (dispatch) => {
  try {
    dispatch(TDRVideoType(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(TDRVideoTypeSuccess(data));
      } else {
        dispatch(TDRVideoTypeFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(TDRVideoTypeFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const TDRVideoType1 = () => ({
  type: TDR_VIDEO_TYPE_REQUEST1,
});
export const TDRVideoTypeSuccess1 = (companyid) => ({
  type: TDR_VIDEO_TYPE_SUCCESS1,
  payload: companyid,
});
export const TDRVideoTypeFailed1 = (error) => ({
  type: TDR_VIDEO_TYPE_ERROR1,
  payload: error,
});

export const TDRVideoTypeApi1 = (data) => (dispatch) => {
  try {
    dispatch(TDRVideoType1(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(TDRVideoTypeSuccess1(data));
      } else {
        dispatch(TDRVideoTypeFailed1(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(TDRVideoTypeFailed1(error));
    console.error("Something went Wrong", error);
  }
};

export const ProgressListRequest = () => ({
  type: PROGRESS_LIST_REQUEST,
});
export const ProgressListSuccess = (companyid) => ({
  type: PROGRESS_LIST_SUCCESS,
  payload: companyid,
});
export const ProgressListFailed = (error) => ({
  type: PROGRESS_LIST_ERROR,
  payload: error,
});

export const ProgressListApi = (data) => (dispatch) => {
  try {
    dispatch(ProgressListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ProgressListSuccess(data));
      } else {
        dispatch(ProgressListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ProgressListFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const LmsReportRequest = () => ({
  type: LMS_REPORT_REQUEST,
});
export const LmsReportSuccess = (data) => ({
  type: LMS_REPORT_SUCCESS,
  payload: data,
});
export const LmsReportFailed = (error) => ({
  type: LMS_REPORT_ERROR,
  payload: error,
});

export const LmsReportApi = (data) => (dispatch) => {
  try {
    dispatch(LmsReportRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(LmsReportSuccess(data));
      } else {
        dispatch(LmsReportFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(LmsReportFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const TraningByUserRequest = () => ({
  type: TRAINING_BY_USER_REQUEST,
});
export const TraningByUserSuccess = (data) => ({
  type: TRAINING_BY_USER_SUCCESS,
  payload: data,
});
export const TraningByUserFailed = (error) => ({
  type: TRAINING_BY_USER_ERROR,
  payload: error,
});

export const TraningByUserApi = (data) => (dispatch) => {
  try {
    dispatch(TraningByUserRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(TraningByUserSuccess(data));
      } else {
        dispatch(TraningByUserFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(TraningByUserFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const TraningByStoreRequest = () => ({
  type: TRAINING_BY_STORE_REQUEST,
});
export const TraningByStoreSuccess = (data) => ({
  type: TRAINING_BY_STORE_SUCCESS,
  payload: data,
});
export const TraningByStoreFailed = (error) => ({
  type: TRAINING_BY_STORE_ERROR,
  payload: error,
});

export const TraningByStoreApi = (data) => (dispatch) => {
  try {
    dispatch(TraningByStoreRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(TraningByStoreSuccess(data));
      } else {
        dispatch(TraningByStoreFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(TraningByStoreFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const ResetListRequest = () => ({
  type: RESET_LIST_REQUEST,
});
export const ResetListSuccess = (companyid) => ({
  type: RESET_LIST_SUCCESS,
  payload: companyid,
});
export const ResetListFailed = (error) => ({
  type: RESET_LIST_ERROR,
  payload: error,
});

export const ResetListApi = (data) => (dispatch) => {
  try {
    dispatch(ResetListRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ResetListSuccess(data));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(ResetListFailed(response?.statusText));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ResetListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(`${response?.data?.message}`);
  }
};

export const CreateNewTrainingRequest = () => ({
  type: CREATE_NEW_TRAINING_REQUEST,
});
export const CreateNewTrainingSuccess = (companyid) => ({
  type: CREATE_NEW_TRAINING_SUCCESS,
  payload: companyid,
});
export const CreateNewTrainingFailed = (error) => ({
  type: CREATE_NEW_TRAINING_ERROR,
  payload: error,
});

export const CreateNewTrainingApi = (data) => (dispatch) => {
  try {
    dispatch(CreateNewTrainingRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CreateNewTrainingSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(CreateNewTrainingFailed(`${response?.data?.message}`));
        toast.error(
          `${
            response.response?.data?.message?.message
              ? response.response?.data?.message?.message
              : response.response?.data?.message
          }`
        );
      }
    });
  } catch (error) {
    dispatch(CreateNewTrainingFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(`${response?.data?.message}`);
  }
};

export const UpdateTrainingRequest = () => ({
  type: UPDATE_TRAINING_REQUEST,
});
export const UpdateTrainingSuccess = (companyid) => ({
  type: UPDATE_TRAINING_SUCCESS,
  payload: companyid,
});
export const UpdateTrainingFailed = (error) => ({
  type: UPDATE_TRAINING_ERROR,
  payload: error,
});

export const UpdateTrainingApi = (data) => (dispatch) => {
  try {
    dispatch(UpdateTrainingRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(UpdateTrainingSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(UpdateTrainingFailed(response.response?.data?.message));
        toast.error(
          `${
            response.response?.data?.message?.message
              ? response.response?.data?.message?.message
              : response.response?.data?.message
          }`
        );
      }
    });
  } catch (error) {
    dispatch(UpdateTrainingFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(`${response?.data?.message}`);
  }
};

export const AssessmentListRequest = () => ({
  type: ASSESSMENT_LIST_REQUEST,
});
export const AssessmentListSuccess = (companyid) => ({
  type: ASSESSMENT_LIST_SUCCESS,
  payload: companyid,
});
export const AssessmentListFailed = (error) => ({
  type: ASSESSMENT_LIST_ERROR,
  payload: error,
});

export const AssessmentListApi = (data) => (dispatch) => {
  try {
    dispatch(AssessmentListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(AssessmentListSuccess(data));
      } else {
        dispatch(AssessmentListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AssessmentListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(`${response?.data?.message}`);
  }
};

export const AssessmentDeleteRequest = () => ({
  type: ASSESSMENT_DELETE_REQUEST,
});
export const AssessmentDeleteSuccess = (companyid) => ({
  type: ASSESSMENT_DELETE_SUCCESS,
  payload: companyid,
});
export const AssessmentDeleteFailed = (error) => ({
  type: ASSESSMENT_DELETE_ERROR,
  payload: error,
});

export const AssessmentDeleteApi = (data) => (dispatch) => {
  try {
    dispatch(AssessmentDeleteRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AssessmentDeleteSuccess(response));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(AssessmentDeleteFailed(response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AssessmentDeleteFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const UserTariningListRequest = () => ({
  type: USER_TRAINING_LIST_REQUEST,
});
export const UserTariningListSuccess = (companyid) => ({
  type: USER_TRAINING_LIST_SUCCESS,
  payload: companyid,
});
export const UserTariningListFailed = (error) => ({
  type: USER_TRAINING_LIST_ERROR,
  payload: error,
});

export const UserTariningListApi = (data) => (dispatch) => {
  try {
    dispatch(UserTariningListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(UserTariningListSuccess(data));
      } else {
        dispatch(UserTariningListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(UserTariningListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(`${response?.data?.message}`);
  }
};

export const StoreTariningListRequest = () => ({
  type: STORE_TRAINING_LIST_REQUEST,
});
export const StoreTariningListSuccess = (companyid) => ({
  type: STORE_TRAINING_LIST_SUCCESS,
  payload: companyid,
});
export const StoreTariningListFailed = (error) => ({
  type: STORE_TRAINING_LIST_ERROR,
  payload: error,
});

export const StoreTariningListApi = (data) => (dispatch) => {
  try {
    dispatch(StoreTariningListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(StoreTariningListSuccess(data));
      } else {
        dispatch(StoreTariningListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(StoreTariningListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(`${response?.data?.message}`);
  }
};

export const StoreTariningViewListRequest = () => ({
  type: STORE_TRAINING_VIEW_LIST_REQUEST,
});
export const StoreTariningViewListSuccess = (companyid) => ({
  type: STORE_TRAINING_VIEW_LIST_SUCCESS,
  payload: companyid,
});
export const StoreTariningViewListFailed = (error) => ({
  type: STORE_TRAINING_VIEW_LIST_ERROR,
  payload: error,
});

export const StoreTariningViewListApi = (data) => (dispatch) => {
  try {
    dispatch(StoreTariningViewListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(StoreTariningViewListSuccess(data));
      } else {
        dispatch(StoreTariningViewListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(StoreTariningViewListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(`${response?.data?.message}`);
  }
};

export const IndividualStoreTariningViewListRequest = () => ({
  type: INDIVIDUAL_STORE_TRAINING_VIEW_LIST_REQUEST,
});
export const IndividualStoreTariningViewListSuccess = (companyid) => ({
  type: INDIVIDUAL_STORE_TRAINING_VIEW_LIST_SUCCESS,
  payload: companyid,
});
export const IndividualStoreTariningViewListFailed = (error) => ({
  type: INDIVIDUAL_STORE_TRAINING_VIEW_LIST_ERROR,
  payload: error,
});

export const IndividualStoreTariningViewListApi = (data) => (dispatch) => {
  try {
    dispatch(IndividualStoreTariningViewListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(IndividualStoreTariningViewListSuccess(data));
      } else {
        dispatch(IndividualStoreTariningViewListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(IndividualStoreTariningViewListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(`${response?.data?.message}`);
  }
};

export const CertificateListArchivedRequest = () => ({
  type: CERTIFICATE_ARCHIVED_REQUEST,
});
export const CertificateListArchivedSuccess = (list) => ({
  type: CERTIFICATE_ARCHIVED_SUCCESS,
  payload: list,
});
export const CertificateListArchivedFailed = (error) => ({
  type: CERTIFICATE_ARCHIVED_ERROR,
  payload: error,
});

export const CertificateArchivedApi = (data) => (dispatch) => {
  try {
    dispatch(CertificateListArchivedRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CertificateListArchivedSuccess(response));
      } else {
        dispatch(CertificateListArchivedFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CertificateListArchivedFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const AssessmentListArchivedRequest = () => ({
  type: ASSESSMENT_ARCHIVED_REQUEST,
});
export const AssessmentListArchivedSuccess = (list) => ({
  type: ASSESSMENT_ARCHIVED_SUCCESS,
  payload: list,
});
export const AssessmentListArchivedFailed = (error) => ({
  type: ASSESSMENT_ARCHIVED_ERROR,
  payload: error,
});

export const AssessmentArchivedApi = (data) => (dispatch) => {
  try {
    dispatch(AssessmentListArchivedRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(AssessmentListArchivedSuccess(response));
      } else {
        dispatch(AssessmentListArchivedFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AssessmentListArchivedFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const TrainingDetailsViewRequest = () => ({
  type: LMS_DETAIL_VIEW_REQUEST,
});
export const TrainingDetailsViewSuccess = (list) => ({
  type: LMS_DETAIL_VIEW_SUCCESS,
  payload: list,
});
export const TrainingDetailsViewFailed = (error) => ({
  type: LMS_DETAIL_VIEW_ERROR,
  payload: error,
});

export const TrainingDetailsViewApi = (data) => (dispatch) => {
  try {
    dispatch(TrainingDetailsViewRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(TrainingDetailsViewSuccess(response));
      } else {
        dispatch(TrainingDetailsViewFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(TrainingDetailsViewFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const TrainingListArchivedRequest = () => ({
  type: TRAINING_ARCHIVED_REQUEST,
});
export const TrainingListArchivedSuccess = (list) => ({
  type: TRAINING_ARCHIVED_SUCCESS,
  payload: list,
});
export const TrainingListArchivedFailed = (error) => ({
  type: TRAINING_ARCHIVED_ERROR,
  payload: error,
});

export const TrainingArchivedApi = (data) => (dispatch) => {
  try {
    dispatch(TrainingListArchivedRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(TrainingListArchivedSuccess(response));
      } else {
        dispatch(TrainingListArchivedFailed(`${response?.data?.message}`));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(TrainingListArchivedFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const TrainingListRequest = (list) => ({
  type: LMS_TRAINING_REQUEST,
  payload: list,
});
export const TrainingListSuccess = (list) => ({
  type: LMS_TRAINING_SUCCESS,
  payload: list,
});
export const TrainingListFailed = (error) => ({
  type: LMS_TRAINING_FAILED,
  payload: error,
});

export const TrainingListApi = (data) => (dispatch) => {
  try {
    dispatch(TrainingListRequest());
    JwtAuthService.GetApiService(data).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        const data = response?.data;
        dispatch(TrainingListSuccess(data));
      } else {
        dispatch(TrainingListFailed(response?.response?.data?.message));
      }
    });
  } catch (error) {
    dispatch(TrainingListFailed(error));
  }
};

// LMS Report
export const ActiveLMSReportRequest = (companyid) => ({
  type: ACTIVELMSREPORT_REQUEST,
  payload: companyid,
});

export const ActiveLMSReportSuccess = (companyid) => ({
  type: ACTIVELMSREPORT_SUCCESS,
  payload: companyid,
});

export const ActiveLMSReportFailed = (error) => ({
  type: ACTIVELMSREPORT_ERROR,
  payload: error,
});

export const ActiveLMSReportGetApi = (data) => (dispatch) => {
  try {
    dispatch(ActiveLMSReportRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ActiveLMSReportSuccess(response));
      } else {
        dispatch(ActiveLMSReportFailed(`${response?.data?.message}`));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ActiveLMSReportFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};
