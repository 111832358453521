import {
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_ERROR, ADD_GROUP_DELETE_REQUEST, ADD_GROUP_DELETE_SUCCESS, ADD_GROUP_DELETE_ERROR, ADD_GROUP_UPDATE_REQUEST, ADD_GROUP_UPDATE_SUCCESS, ADD_GROUP_UPDATE_ERROR
} from "../types/Types";


const initialState = {
    loading: false,
    AddGroup: [],
    error: "",
    id: null
};

const AddGroupDataStore = (state = initialState, action) => {
    let todos = action.payload;
    switch (action.type) {
        case ADD_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_GROUP_SUCCESS:

            return {
                ...state,
                loading: false,
                AddGroup: [...state.AddGroup, action.payload],
                error: "",
            };
        case ADD_GROUP_ERROR:
            return {
                loader: false,
                AddGroup: [],
                error: action.error,
            };
        case ADD_GROUP_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_GROUP_DELETE_SUCCESS:
            return {
                loading: false,
                ...state,
                AddGroup: state.AddGroup.filter((item, index) => item.id !== action.payload),
            };

        case ADD_GROUP_DELETE_ERROR:
            return {
                loader: false,
                AddGroup: [],
                error: action.error,
            };
        case ADD_GROUP_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_GROUP_UPDATE_SUCCESS:
            let index = state.AddGroup.findIndex(todo => todo.id === action.payload.id); //finding index of the item
            let newArray = [...state.AddGroup]; //making a new array
            newArray[index].title = action.payload.title//changing value in the new array
            newArray[index].description = action.payload.description//changing value in the new array
            return {
                ...state, //copying the orignal state
                AddGroup: newArray, //reassingning todos to new array
            };

        case ADD_GROUP_UPDATE_ERROR:
            return {
                loader: false,
                AddGroup: [],
                error: action.error,
            };
        default:
            return state;
    }
};



export default AddGroupDataStore;
