/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Switch,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Reset from "../../assets/icons/svg-icons/Reset.svg";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { ResetListApi, TrainingDetailsApi } from "../redux/actions/LmsAction";
import User from "../../assets/icons/svg-icons/User.svg";
import Progressbar from "../GlobalProgress/Progressbar";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { MobileAccessApi } from "../redux/actions/mobileAccess";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const MobileAccess = () => {
  const [companyPermissionDatas, setcompanyPermissionData] = useState();
  const [loader, setLoader] = useState(true);
  const Loader = useSelector(
    (state) =>
      state && state?.MobileAccessData && state?.MobileAccessData?.loading
  );
  const permissionData = useSelector(
    (state) =>
      state &&
      state?.MobileAccessData &&
      state?.MobileAccessData?.mobileAccedData &&
      state?.MobileAccessData?.mobileAccedData?.data &&
      state?.MobileAccessData?.mobileAccedData?.data?.data
  );
  useEffect(() => {
    if (permissionData) {
      // setLoader(true);
      setcompanyPermissionData(permissionData);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [permissionData]);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {};
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `companiespermission/permissions?company_id=${id}`;
    dispatch(MobileAccessApi(data));
  }, []);

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      {/* {Loader ? (
        <Progressbar /> */}
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <>
          <Box className="header-card">
          <div style={{float:"right"}}>
                      <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                    </Tooltip>
            </div>
            <Grid align="left">
              <Typography variant="p" component="b" sx={{ padding: "5px", marginLeft:"5px", marginTop:"2px"}}>
                Mobile Permission
              </Typography>
            </Grid>
          </Box>
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              overflowX: "hidden",
              overflowY: "scroll",
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <Card className="user-information" sx={{ mb: 2 }}>
              <Grid
                container
                spacing={0}
                sx={{ mt: 4, justifyContent: "center" }}
              >
                <Grid item md={6} lg={6} xs={12} sm={12}>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Checklist Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Checklist:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.checklist}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Share Prevent Checklist:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={
                                companyPermissionDatas?.scrSharePreventChecklist
                              }
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Checklist Options:
                          </TableCell>
                          <TableCell>
                            Total Options:{" "}
                            {companyPermissionDatas?.checkListOptions
                              ? Object.keys(
                                  companyPermissionDatas?.checkListOptions
                                ).length
                              : 0}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value"></TableCell>
                          <TableCell>
                            {companyPermissionDatas?.checkListOptions ? (
                              Object.keys(
                                companyPermissionDatas?.checkListOptions
                              ).map(function (key) {
                                return (
                                  <span>
                                    <li>{key}</li>
                                  </span>
                                );
                              })
                            ) : (
                              <ul>No optiopns added.</ul>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      LMS Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">Lms:</TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.LMS}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Prevent Screen sharing:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={
                                companyPermissionDatas?.scrSharePreventLMS
                              }
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Lms options:
                          </TableCell>
                          <TableCell className="">
                            Total Options:{" "}
                            {companyPermissionDatas?.lmsOptions
                              ? Object.keys(companyPermissionDatas?.lmsOptions)
                                  .length
                              : 0}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value"></TableCell>
                          <TableCell>
                            {companyPermissionDatas?.lmsOptions ? (
                              Object.keys(
                                companyPermissionDatas?.lmsOptions
                              ).map(function (key) {
                                return (
                                  <span>
                                    <li>{key}</li>
                                  </span>
                                );
                              })
                            ) : (
                              <ul>No optiopns added.</ul>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Geofencing Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Geofencing:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={
                                companyPermissionDatas?.geofencing[0]
                                  ?.geofencing
                              }
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Geo Distance:
                          </TableCell>
                          <TableCell className="list-key-value">
                            {companyPermissionDatas?.geofencing[0]?.geoDistance
                              ? companyPermissionDatas?.geofencing[0]
                                  ?.geoDistance + " Meter"
                              : "0 Meter"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      TimeZone Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            TimeZone:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={true}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Country:</TableCell>
                          <TableCell className="list-key-value">
                            {companyPermissionDatas?.timeZone[0]?.timeValue
                              ? companyPermissionDatas?.timeZone[0]?.timeValue
                              : "India"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Other Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow className="table-group">
                          <TableCell className="list-value">
                            Activities:
                          </TableCell>

                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.Activities}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Companies:
                          </TableCell>
                          <TableCell className="list-key-value">
                            {" "}
                            <Switch
                              checked={companyPermissionDatas?.Companies}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Customer Log:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.CustomerLog}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="list-value">
                            Leader board:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.Leaderboard}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Messages:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.Messages}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Observation audit:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.ObservationAudit}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Path travelled:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.PathTravelled}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Stores:</TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.Stores}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Sync:</TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.Sync}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Wastage logs:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.WastageLogs}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Audits:</TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.Audits}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Chats:</TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.chats}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Issue log:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.issuelog}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Reports:</TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={companyPermissionDatas?.reports}
                              color={"success"}
                              disabled={true}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default MobileAccess;
