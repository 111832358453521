import React, { useState, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Typography, Grid, TablePagination, Stack, Tooltip } from "@mui/material";
// import View from "../../../assets/icons/svg-icons/View.svg";
// import Edit from "../../../assets/icons/svg-icons/Edit.svg";
// import Delete from "../../../assets/icons/svg-icons/Delete.,svg";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./pages/Header";
// import { AddDataListData, AddDataListSuccess, DataListApi } from '../../redux/actions/DataManager';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CountButton from "../../../helpers/Counter";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";

export const DataList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let LocalData = localStorage.getItem("dataListOfdata");
  let dataList = JSON.parse(LocalData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [counter, setCounter] = useState(0);
  const [searchList, setSearchList] = useState([]);
  const [newAddList, setNewAddList] = useState([]);
  const [fixedList, setFixedList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [userDefinedList, setUserDefinedList] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });

  const adddataListData = useSelector(
    (state) => state && state.dataManager && state.dataManager.AddDataList
  );

  useEffect(() => {
    const scrollableElements = document.querySelectorAll("#scrollable");
    for (let i = 0; i < scrollableElements.length; i++) {
      scrollableElements[i].scrollTop = 0;
    }
  }, [searchData]);

  useEffect(() => {
    setLoader(true);
    let LocalData = localStorage.getItem("dataListOfdata");
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setRows(newQuest);
      if (newAddList.length > 0) {
        setRows((newQuest) => [...newQuest, ...newAddList]);
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setNewAddList((newData) => [...newData, adddataListData]);
  }, [adddataListData]);

  useEffect(() => {
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setRows(newQuest);
    }
  }, [LocalData]);

  useEffect(() => {
    setLoader(true);
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      let fixedListData = [];
      for (let data of newQuest) {
        data.index = data.id;
        if (data?.isDefault === true || data?.isFixed === true)
          fixedListData.push(data);
      }
      for (let index in fixedListData) {
        if (fixedListData[index].index !== index + 1) {
          fixedListData[index].index = index;
        }
      }
      setFixedList(fixedListData);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [LocalData]);

  useEffect(() => {
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      let userDefinedListListData = [];
      for (let data of newQuest) {
        data.index = data.id;
        if (data?.isFixed === false && data.isDefault !== true) {
          userDefinedListListData.push(data);
        }
      }
      for (let index in userDefinedListListData) {
        if (userDefinedListListData[index].index !== index + 1) {
          userDefinedListListData[index].index = index;
        }
      }
      setUserDefinedList(userDefinedListListData);
    }
  }, [LocalData]);

  useEffect(() => {
    if (searchValue == "") {
      setSearchData("");
    }
  }, [searchValue, searchData]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = (row) => {
    navigate("/data-list/list-details", { state: row });
  };

  const handleEdit = (row, i) => {
    navigate("/data-list/edit-list", { state: row });
  };

  const handleDeleteList = (id) => {
    
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoader(true);
            // setUserDefinedList(userDefinedList.filter((item, i) => item.id !== id));
            let newcustom = userDefinedList.filter((items) => items.id !== id);
            let newArray = [...newcustom, ...fixedList];
            let local = localStorage.setItem(
              "dataListOfdata",
              JSON.stringify(newArray)
            );
            setUserDefinedList(local);
            setTimeout(() => {
              setLoader(false);
            }, 500);
          },
        },
        
        {
          label: "No",
        },
      ],
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch(e);
      setSearchData(searchValue);
      const searchedData = dataList.filter((item) => {
        return (
          item.title.includes(searchValue) ||
          item.description.includes(searchValue)
        );
      });
      setSearchList(searchedData);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  function getIndex(params) {
    return Number(params?.row?.index) + 1;
  }

  // DataGrid Functionality start
  const columns = [
    {
      field: "Index",
      headerName: "Index",
      renderHeader: () => <strong>{"Index"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: getIndex,
    },
    {
      field: "Title	",
      headerName: "Title	",
      renderHeader: () => <strong>{"Title"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => <strong>{"Description"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.description) {
          result.push(params?.row?.description);
        } else {
          result = ["--"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Total Items	",
      headerName: "Total Items	",
      renderHeader: () => <strong>{"Total Items	"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.items?.length) {
          result.push(params?.row?.items?.length);
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          // <div style={{ display: "flex" }}>
          //   {
          //     <img
          //       src={View}
          //       alt="View"
          //       onClick={() => handleView(params?.row)}
          //       variant="outlined"
          //       className="iconBox"
          //     />
          //     // <Chip
          //     //   avatar={<img src={View} alt="View" />}
          //     //   onClick={() => handleView(params?.row)}
          //     //   variant="outlined"
          //     //   className="iconBox"
          //     // />
          //   }

          //   {
          //     <img
          //       src={Edit}
          //       alt="Edit"
          //       onClick={() => handleEdit(params?.row)}
          //       variant="outlined"
          //       className="iconBox"
          //     />
          //     // <Chip
          //     //   avatar={<img src={Edit} alt="Edit" />}
          //     //   onClick={() => handleEdit(params?.row)}
          //     //   variant="outlined"
          //     //   className="iconBox"
          //     // />
          //   }
          // </div>
          <div style={{ display: "flex" }}>
            {
              <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row ? ViewHover : View
                }
                alt="VIEW"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onClick={() => handleView(params?.row)}
                onMouseOver={() => {
                  setHover({ View: true });
                  setIndexHover(params?.row);
                }}
                onMouseLeave={() => {
                  setHover({ View: false });
                }}
              />
              </Tooltip>
            }

            {
              <Tooltip title="Edit">
              <img
                onClick={() => handleEdit(params?.row)}
                src={
                  hover.Edit && indexHover === params?.row ? EditHover : Edit
                }
                alt="Edit"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
              </Tooltip>
            }
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      field: "Index",
      headerName: "Index",
      renderHeader: () => <strong>{"Index"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: getIndex,
    },
    {
      field: "Title	",
      headerName: "Title	",
      renderHeader: () => <strong>{"Title"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => <strong>{"Description"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.description) {
          result.push(params?.row?.description);
        } else {
          result = ["--"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Total Items	",
      headerName: "Total Items	",
      renderHeader: () => <strong>{"Total Items	"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.items?.length) {
          result.push(params?.row?.items?.length);
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            {
              <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row ? ViewHover : View
                }
                alt="VIEW"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onClick={() => handleView(params?.row)}
                onMouseOver={() => {
                  setHover({ View: true });
                  setIndexHover(params?.row);
                }}
                onMouseLeave={() => {
                  setHover({ View: false });
                }}
              />
              </Tooltip>
            }

            {
              <Tooltip title="Edit">
                <img
                onClick={() => handleEdit(params?.row)}
                src={
                  hover.Edit && indexHover === params?.row ? EditHover : Edit
                }
                alt="Edit"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
              </Tooltip>
            }
            {
              <Tooltip title="Delete">
              <img
                onClick={() => handleDeleteList(params?.row?.id)}
                src={
                  hover.Delete && indexHover === params?.row
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
              </Tooltip>
            }
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      {/* Header of the page */}
      <Header
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        searchValue={searchValue}
      />

      {/* Store Training List Table */}
      <Box
        id="scrollable"
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
        }}
      >
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <>
            {searchData !== "" && searchList != "" && searchList?.length > 0 ? (
              <DataGrid
                disableColumnMenu
                rows={searchList}
                // className="grid-table"
                columns={columns}
                getRowId={(allChecklistData) => Math.random()}
                paginationMode="server"
                pageSize={rowsPerPage}
                onPageSizeChange={(e) => handleChangeRowsPerPage(e)}
                pagination={true}
                onPageChange={(e) => handleChangePage(e)}
                rowsPerPageOptions={[5, 10, 20]}
                // rowCount={totalpage?.total_item}
                page={page - 1}
                getRowHeight={() => "auto"}
                hideFooterPagination={true}
                hideFooter={true}
                // rowHeight={25}
                // checkboxSelection
                // disableSelectionOnClick
                // experimentalFeatures={{ newEditingApi: true }}
              />
            ) : (
              ""
            )}

            <Box sx={{ height: "50vh", mb: 7 }}>
              {searchData == "" && fixedList && fixedList?.length > 0 ? (
                <>
                  <Typography
                    variant="p"
                    component="h2"
                    className="top-heading"
                    align="left"
                  >
                    Fixed List
                  </Typography>
                  <DataGrid
                    disableColumnMenu
                    rows={fixedList}
                    columns={columns}
                    // className="grid-table"
                    getRowId={(allChecklistData) => Math.random()}
                    paginationMode="server"
                    pageSize={rowsPerPage}
                    onPageSizeChange={(e) => handleChangeRowsPerPage(e)}
                    pagination={true}
                    onPageChange={(e) => handleChangePage(e)}
                    rowsPerPageOptions={[5, 10, 20]}
                    // rowCount={totalpage?.total_item}
                    page={page - 1}
                    getRowHeight={() => "auto"}
                    hideFooterPagination={true}
                    hideFooter={true}
                    // rowHeight={25}
                    // checkboxSelection
                    // disableSelectionOnClick
                    // experimentalFeatures={{ newEditingApi: true }}
                  />
                </>
              ) : (
                ""
              )}
            </Box>
            <Box sx={{ height: "60vh" }}>
              {searchData == "" &&
              userDefinedList &&
              userDefinedList?.length > 0 ? (
                <>
                  <Typography
                    variant="p"
                    component="h2"
                    className="top-heading"
                    align="left"
                  >
                    User Defined List
                  </Typography>
                  <DataGrid
                    disableColumnMenu
                    rows={userDefinedList}
                    columns={columns2}
                    getRowId={(allChecklistData) => Math.random()}
                    paginationMode="server"
                    // className="grid-table"
                    pageSize={rowsPerPage}
                    onPageSizeChange={(e) => handleChangeRowsPerPage(e)}
                    pagination={true}
                    onPageChange={(e) => handleChangePage(e)}
                    rowsPerPageOptions={[5, 10, 20]}
                    // rowCount={totalpage?.total_item}
                    page={page - 1}
                    getRowHeight={() => "auto"}
                    hideFooter={true}
                    hideFooterPagination={true}
                    // rowHeight={25}
                    // checkboxSelection
                    // disableSelectionOnClick
                    // experimentalFeatures={{ newEditingApi: true }}
                  />
                </>
              ) : (
                ""
              )}
            </Box>
          </>
        )}
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default DataList;
