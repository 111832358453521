/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { ChatAttachmentApi } from "../../redux/actions/ChatAction";
import { toast } from "react-toastify";

const Footer = ({ input, setInput, handleSendMessage, currentUser }) => {
  const [attachments, setAttachments] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // Add a ref for the file input

  const imageResponse = useSelector(
    (state) =>
      state &&
      state.chat &&
      state.chat?.attachedFile &&
      state.chat?.attachedFile?.data &&
      state.chat?.attachedFile?.data?.data
  );

  useEffect(() => {
    setLoading(false);
    if (imageResponse) {
      setAttachments((prev) => [...prev, ...imageResponse]);
    }
  }, [imageResponse]);

  useEffect(() => {
    // Clear attachments when the currentUser changes
    setAttachments([]);
  }, [currentUser]);

  const handleFileChange = async (e) => {
    setLoading(true);
    const newFiles = Array.from(e.target.files);
    const validFiles = [];

    for (const file of newFiles) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Only up to 5 MB files are allowed");
      } else {
        validFiles.push(file);
      }
    }

    if (validFiles.length === 0) {
      setLoading(false);
      return;
    }

    const formData = new FormData();
    validFiles.forEach((file) => {
      formData.append("files", file);
    });

    const imageData = {
      body: formData,
      url: BASE_URL + `uploads/any?ngsw-bypass=true`,
    };

    dispatch(ChatAttachmentApi(imageData));

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const handleSend = async () => {
    if (input.trim() === "" && attachments.length === 0) {
      return;
    }
    setIsSending(true);

    try {
      await handleSendMessage(input, attachments);
      setInput("");
      setAttachments([]);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ marginBottom: "8px" }}>
        <Grid container spacing={2}>
          {attachments.map((file, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton
                  size="small"
                  aria-label="cancel"
                  onClick={() => handleRemoveAttachment(index)}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <input
                  accept="*"
                  style={{ display: "none" }}
                  id="file-input"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef} // Attach ref to the file input
                />
                <label htmlFor="file-input">
                  <IconButton color="primary" component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>
                {loading && <CircularProgress size={24} />}
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#196773",
            marginLeft: "8px",
            height: "56px",
            "&:hover": {
              backgroundColor: "#1f2a3a",
            },
          }}
          endIcon={<SendIcon />}
          onClick={handleSend}
          disabled={isSending || loading} // Disable the button when sending or loading
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
