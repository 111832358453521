/** @format */

import React, { useState, useEffect } from "react";
import { TablePagination, Box, IconButton } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { DataGrid } from "@mui/x-data-grid";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Editicon from "../../../assets/icons/png-icons/changepassword.png";
import permissionimg from "../../../assets/icons/png-icons/pesmission.png";
import Delete from "../../images/Delete.svg";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import Header from "./pages/Header";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserSuccess,
  AddUserFailed,
  ListUserApi,
  ChangePasswordUserSuccess,
  UserDeleteApi,
  AddImageSuccess,
  AddImageFailed,
  UpdateUsersFailed,
  UpdateUsersSuccess,
} from "../../redux/actions/User";
import { UserListArchivedApi } from "../../redux/actions/Checklist";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { UserPrivateApi } from "../../redux/actions/LogInAction";
import { ToastContainer } from "react-toastify";
import CircleIcon from "@mui/icons-material/Circle";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const UserList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const userList = useSelector(
    (state) =>
      state?.user &&
      state?.user?.data &&
      state?.user?.data?.data &&
      state?.user?.data?.data?.data &&
      state?.user?.data?.data?.data?.users
  );

  const archivedUserList = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.userListArchuved &&
      state?.checklist?.userListArchuved?.data &&
      state?.checklist?.userListArchuved?.data?.data &&
      state?.checklist?.userListArchuved?.data?.data?.Users
  );

  const totalitems = useSelector(
    (state) =>
      state?.user &&
      state?.user?.data &&
      state?.user?.data?.data &&
      state?.user?.data?.data?.data &&
      state?.user?.data?.data?.data?.paginate
  );
  var pageno = localStorage.getItem("pageno");
  var rowPage = localStorage.getItem("rowPerPage");

  const userListFilter = localStorage.getItem("userListFilterData") || "{}";
  const userListFilterData = JSON.parse(userListFilter);

  const totalUserItems = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.userListArchuved &&
      state?.checklist?.userListArchuved?.data &&
      state?.checklist?.userListArchuved?.data?.data &&
      state?.checklist?.userListArchuved?.data?.data?.paginate
  );

  const addUserData = useSelector(
    (state) => state.user && state.user.addUser && state.user.addUser.data
  );
  const imagegetid = useSelector(
    (state) => state.user && state.user.imgId && state.user.imgId.data
  );
  const errorImg = useSelector(
    (state) => state.user && state.user.error && state.user.error.data
  );
  const updateData = useSelector(
    (state) =>
      state && state.user && state.user.updateUser && state.user.updateUser.data
  );
  const changePwd = useSelector(
    (state) => state.user && state.user.changePwd && state.user.changePwd.data
  );
  const Loader = useSelector((state) => state.user && state.user.loading);

  const [page, setPage] = useState(parseInt(pageno) || 1);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(rowPage) || 10);
  const [userData, setUserData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [localPageNo, setLocalPageNo] = useState(1);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
    ChangePwd: false,
  });
  const [permission, setPermission] = useState({});
  const [uiRender, setUiRender] = useState(false);

  const payloadRequestAll = {
    body: {
      page: page,
      per_page: rowsPerPage,
      q: userListFilterData.searchValue || "",
      userType: userListFilterData.userType || "",
    },
  };

  const arcivedPayload = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
      },
      search: userListFilterData.searchValue || '',
      userType: userListFilterData.userType || '',
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
      setUiRender(!uiRender);
    }
  }, []);

  //get empty reducer
  useEffect(() => {
    // setUserData(userList);
    dispatch(AddUserSuccess(""));
    dispatch(AddUserFailed(""));
    dispatch(UpdateUsersSuccess(""));
    dispatch(ChangePasswordUserSuccess(""));
    dispatch(AddImageSuccess(""));
    dispatch(AddImageFailed(""));
    dispatch(UpdateUsersFailed(""));
  }, [
    addUserData,
    changePwd,
    updateData,
    imagegetid,
    errorImg,
  ]);

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
      // localStorage.removeItem("pageno");
    }
  }, [pageno]);

  useEffect(() => {
    if (checkedArchive) {
      setUserData(archivedUserList);
    } else {
      setUserData(userList);
    }
  }, [userList, archivedUserList, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(totalUserItems?.total_item);
    } else {
      setTotalCount(totalitems?.total_item);
    }
  }, [totalUserItems, totalitems, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === false) {
      listUser(payloadRequestAll);
    }
  }, []);

  const listUser = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `users/index?company_id=${id}`;
    setLoader(true);
    dispatch(ListUserApi(data));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const UserListArchivedApis = (arcivedPayload) => {
    const id = localStorage.getItem("companyId");
    arcivedPayload.url = BASE_URL + `users/ListArchivedUser?company_id=${id}`;
    dispatch(UserListArchivedApi(arcivedPayload));
  };

  // Table Pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  const handleFilterApplyApiCall = (payloadRequestAll) => {
    setPage(1);
    localStorage.setItem("pageno", 1);
    if (checkedArchive === false) {
      // payloadRequestAll.body.q = userListFilterData.searchValue;
      // payloadRequestAll.body.page = 1;
      // console.log("5555555555");
      listUser(payloadRequestAll);
    } else {
      // arcivedPayload.body.search = userListFilterData.searchValue;
      // arcivedPayload.body.pagination.page = 1;
      // setPage(1);
      // console.log("6666666666666");
      UserListArchivedApis(payloadRequestAll);
    }
  };

  const handleClickPwd = (row) => {
    navigate("/user-list/change-password", { state: { data: row } });
  };
  const handleClickPermission = (row) => {
    navigate("/user-list/user-permission", { state: { data: row } });
  };

  const handleClick = (row) => {
    navigate(`/user-list/user-details`, { state: row });
  };

  const handleClickEdit = (row) => {
    if (row) {
      const data = JSON.stringify(row);
      localStorage.setItem("userEditData", data);
      navigate("/user-list/edit-user", { state: { data: row } });
    }
  };

  const handleDelete = (userid) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = userid;
            const data = {
              url: BASE_URL + `users/${userRoleId}?company_id=${id}`,
            };
            setLoader(true);
            dispatch(UserDeleteApi(data));
            setTimeout(() => {
              listUser(payloadRequestAll);
              setLoader(false);
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  // DataGrid Functionality

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          userData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "User",
      headerName: "User",
      renderHeader: () => <strong>{"User"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip
                title={`${params?.row?.first_name} ${params?.row?.last_name}`}
              >
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.first_name} {params?.row?.last_name}
                </p>
              </LightTooltip>
              <LightTooltip title={params?.row?.email}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "small",
                    marginTop: "-10px",
                  }}
                >
                  {params?.row?.email}
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: "Role",
      headerName: "Role",
      renderHeader: () => <strong>{"Role"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.role || params?.row?.role?.title) {
          result.push(
            params?.row?.role?.title
              ? params?.row?.role?.title
              : params?.row?.role
          );
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Sub Role",
      headerName: "Sub Role",
      renderHeader: () => <strong>{"Sub Role"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.subRole || params?.row?.subRole?.title) {
          result.push(
            params?.row?.subRole?.title
              ? params?.row?.subRole?.title
              : params?.row?.subRole
          );
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Store Name",
      headerName: "Store Name",
      renderHeader: () => <strong>{"Store Name"}</strong>,
      align: "start",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) => {
        const stores = params?.row?.store;
        if (!stores) {
          return null;
        }
        return (
          <div style={{ maxHeight: 150, overflowY: "auto" }}>
            <ul style={{ paddingInlineStart: "0px" }}>
              {stores.map((store, index) => (
                <LightTooltip title={store.title} key={index}>
                  <li
                    className="my-list-w !important"
                    style={{ textAlign: "start" }}
                  >
                    <CircleIcon className="font" style={{ paddingRight: 4 }} />
                    {store.title}
                  </li>
                </LightTooltip>
              ))}
            </ul>
          </div>
        );
      },
    },
    {
      field: "Aadhaar",
      headerName: "Aadhaar Card",
      renderHeader: () => <strong>{"Aadhaar Card"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 160,
      renderCell: (params) => {
        const identity_proof = params?.row?.identity_proof;
        if (!identity_proof?.length) {
          return null;
        }
        return (
          <div style={{ maxHeight: 150, overflowY: "auto" }}>
            {identity_proof[0].maskedAadharNumber}
          </div>
        );
      },
    },
    {
      field: "Approved",
      headerName: "Approved",
      renderHeader: () => <strong>{"Approved"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 140,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params?.row.approved === true ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </div>
        );
      },
    },
    {
      field: "Created",
      headerName: "Created",
      align: "center",
      renderHeader: () => <strong>{"Created"}</strong>,

      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let timeFormate = moment(params?.row?.createdAt).format("LTS");
        let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
        let result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      minWidth: 310,
      align: "center",
      headerAlign: "center",
      hide: checkedArchive === true ? true : false,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {console.log("mm", params?.row?.role)}
            {(permission?.user?.managePermissions === true &&
              params?.row?.role === "admin") ||
            params?.row?.role === "director" ||
            params?.row?.role === "superAdmin" ? (
              <LightTooltip title={"Change Permission"}>
                <img
                  src={permissionimg}
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onClick={() => handleClickPermission(params?.row)}
                />
              </LightTooltip>
            ) : (
              ""
            )}
            {permission?.user?.manageUsers === true ? (
              <LightTooltip title={"Change Password"}>
                <img
                  src={Editicon}
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onClick={() => handleClickPwd(params?.row)}
                />
              </LightTooltip>
            ) : (
              ""
            )}
            <img
              src={
                hover.View && indexHover === params?.row?._id ? ViewHover : View
              }
              alt="VIEW"
              style={{
                height: "35px",
                cursor: "pointer",
                margin: "0px 3px 0px 3px",
              }}
              onClick={() => handleClick(params?.row)}
              onMouseOver={() => {
                setHover({ View: true });
                setIndexHover(params?.row?._id);
              }}
              onMouseLeave={() => {
                setHover({ View: false });
              }}
            />
            {permission?.user?.manageUsers === true ? (
              <>
                <img
                  onClick={() => handleClickEdit(params?.row)}
                  src={
                    hover.Edit && indexHover === params?.row?._id
                      ? EditHover
                      : Edit
                  }
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Edit: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Edit: false });
                  }}
                />
                <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const handleChangePage = (event, newPage) => {
    // setPage(newPage + 1);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    payloadRequestAll.body.page = newPage + 1;

    if (checkedArchive === false) {
      payloadRequestAll.body.q = userListFilterData.searchValue;
      listUser(payloadRequestAll);
    } else {
      arcivedPayload.body.pagination.page = newPage + 1;
      arcivedPayload.body.search = userListFilterData.searchValue;
      UserListArchivedApis(arcivedPayload);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);

    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    localStorage.setItem("rowPerPage", parseInt(event.target.value, 10));
    payloadRequestAll.body.per_page = parseInt(event.target.value, 10);
    payloadRequestAll.body.page = 1;

    if (checkedArchive === false) {
      listUser(payloadRequestAll);
    } else {
      UserListArchivedApis(arcivedPayload);
    }
  };

  return (
    <Box>
      <Header
        selectedValue={selectedValue}
        setCheckedArchive={setCheckedArchive}
        checkedArchive={checkedArchive}
        totalitems={totalitems?.total_item}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        totalUserArchived={totalUserItems?.total_item}
        setPage={setPage}
        permission={permission}
        handleFilterApplyApiCall={handleFilterApplyApiCall}
      />
      <ToastContainer />

      <SingleGlobalTable
        data={userData}
        columns={columns}
        totalCount={totalCount}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </Box>
  );
};

export default UserList;
