/** @format */

import { Grid, Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  IssuesAverageApi,
  LinechartIssueApi,
  MostIssueCategoryApi,
  MostIssueCreatedApi,
} from "../../../redux/actions/Issue";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";

const ChartTitle = ({ startDate, endDate }) => {
  const categoryList = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.mostIssueCategory &&
      state?.issue?.mostIssueCategory?.data &&
      state?.issue?.mostIssueCategory?.data?.data
  );
  const createdUserList = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.createdMostIssue &&
      state?.issue?.createdMostIssue?.data &&
      state?.issue?.createdMostIssue?.data?.data
  );
  const averageDataList = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.averageData &&
      state?.issue?.averageData?.data &&
      state?.issue?.averageData?.data?.data
  );

  const dispatch = useDispatch();
  const [categoryData, setCategoryData] = useState([]);
  const [createdData, setCreatedData] = useState([]);
  const [averageData, setAverageData] = useState([]);

  useEffect(() => {
    setCategoryData(categoryList);
    setCreatedData(createdUserList);
    setAverageData(averageDataList);
  }, [categoryList, createdUserList, averageDataList]);

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      issueCategoryList(startDate, endDate);
      issueCreatedList(startDate, endDate);
      issueAverageList(startDate, endDate);
    }
  }, [startDate, endDate]);

  // useEffect(() => {
  //   issueAverageList();
  // }, []);

  const issueCategoryList = (startDate, endDate) => {
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `MostIssuesCategory?company_id=${id}&issueStartTime=${startDate}&issueEndTime=${endDate}`,
    };
    dispatch(MostIssueCategoryApi(data));
  };

  const issueCreatedList = (startDate, endDate) => {
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `MostIssuesUser?company_id=${id}&issueStartTime=${startDate}&issueEndTime=${endDate}`,
    };
    dispatch(MostIssueCreatedApi(data));
  };

  const issueAverageList = () => {
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `IssuesAvg?company_id=${id}&issueStartTime=${startDate}&issueEndTime=${endDate}`,
    };
    dispatch(IssuesAverageApi(data));
  };
  return (
    <>
      <Grid sx={{ mb: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ p: 3, m: 1 }} className="card-height">
              <Typography className="total-issue">
                Most Issues in category
              </Typography>
              {categoryData &&
                categoryData?.length > 0 &&
                categoryData?.map((item, i) => {
                  if (item?.IssueCategoryCount > 0) {
                    return (
                      <h4 className="total-issue" key={i}>
                        {item.Name}
                      </h4>
                    );
                  }
                })}
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ p: 3, m: 1 }} className="card-height">
              <Typography className="total-issue">
                Most Issues created by
              </Typography>
              {createdData &&
                createdData?.length > 0 &&
                createdData?.map((item, i) => {
                  if (item?.IssueUserCount > 0) {
                    return (
                      <h4 className="total-issue" key={i}>
                        {item.Name}
                      </h4>
                    );
                  }
                })}
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ p: 3, m: 1 }} className="card-height">
              <Typography className="total-issue">
                Issues created per week in avg.
              </Typography>
              <h4 className="total-issue">{averageData}</h4>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartTitle;
