import { Box, Button, Card, Grid, Hidden, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/Info";
import User from "../../assets/icons/svg-icons/User.svg";
import { useEffect, useState } from "react";
import moment from "moment/moment";

const ViewLogEntrySubmission = () => {
    const logEntrySubmissionData = localStorage.getItem("logEntrySubmissionData")
    const parsedLogEntrySubmissionData = JSON.parse(logEntrySubmissionData)

    const [submissionData, setSubmissionData] = useState({});

    useEffect(() => {
        setSubmissionData(parsedLogEntrySubmissionData);
    }, [])
    console.log("submissionData", submissionData);

    return (
        <>
            <Box
                sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
                className="header-card1"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                            <Link to="/log-entry/view-log-entries-submissions" className="submenu-item">
                                <Button
                                    className="header-add cancle-hover"
                                    style={{ marginBottom: "3px" }}
                                >
                                    <DoneIcon sx={{ mr: 1 }} />
                                    Done
                                </Button>
                            </Link>
                            <div style={{ marginTop: "4px", marginRight: "10px" }}>
                                <Tooltip
                                    title={
                                        <div style={{ maxWidth: "600px" }}>
                                            <p>
                                                View the details of the log entry submision.
                                            </p>
                                        </div>
                                    }
                                    arrow
                                    interactive
                                >
                                    <InfoIcon
                                        style={{
                                            verticalAlign: "middle",
                                            cursor: "pointer",
                                            color: "#D76C00",
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
                <Typography
                    variant="h5"
                    component="h4"
                    sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
                >
                    User Information
                </Typography>
                <Card className="user-information">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <img src={User} alt="avatar" className="submission-avatar" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                            <Table aria-label="simple table" className="details-table">
                                <TableBody style={{
                                    width: "100%"
                                }}>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                User Name:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {submissionData?.user?.name
                                                    ? submissionData?.user?.name
                                                    : "--"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                User Role:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {submissionData?.user?.subRole ? submissionData?.user?.subRole : "--"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Submitted On:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {moment(submissionData?.createdAt).format("lll")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Updated On:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {moment(submissionData?.updatedAt).format("lll")}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Log Entry Title:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value" colspan="3">
                                            <Typography variant="p" component="b">
                                                {submissionData?.logEntryObject?.logEntryTitle}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Description:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value" colspan="3">
                                            <Typography variant="p" component="b">
                                                {submissionData?.logEntryObject?.description}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Token:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value" colspan="3">
                                            <Typography variant="p" component="b">
                                                {submissionData?.logEntryObject?.logEntrySlug}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            <div style={{ maxWidth: '100%', margin: 'auto' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" align="left">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.No.</TableCell>
                                <TableCell align="center" sx={{ width: "300px" }}>
                                    Question
                                </TableCell>
                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                    Question Type
                                </TableCell>
                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                    Start Time
                                </TableCell>
                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                    End Time
                                </TableCell>
                                <TableCell align="center">Answer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {submissionData &&
                                submissionData?.questions?.map((row, i) => {
                                    let result = "-"
                                    let mediaResult = [];
                                    if (row.answer?.answer?.title) {
                                        result = row.answer?.answer?.title
                                    } else if (row.answer?.answer?.value) {
                                        result = row.answer?.answer?.value;
                                    } else if (row?.answer?.answer?.length) {
                                        result = row.answer.answer.map(ans => ans.title);
                                    } else if (row?.answer?.answer.date && row?.answer?.answer?.time) {
                                        result = `${row.answer.answer.date} ${row.answer.answer.time}`
                                    } else if (row?.answer?.answer?.answer?.length) {
                                        mediaResult = row.answer.answer.answer.map(ans => `https://popprobe-saas.s3.us-west-2.amazonaws.com/${ans}`);
                                    }

                                    return (<TableRow>
                                        <TableCell align="center" sx={{ fontWeight: "600" }}>
                                            {i + 1}.
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="p">{row?.title}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="p">{row?.type?.title}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="p"
                                                component="p"
                                                sx={{ whiteSpace: "nowrap" }}
                                            >
                                                {moment(row?.answer?.startTime).format("LTS")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="p"
                                                component="p"
                                                sx={{ whiteSpace: "nowrap" }}
                                            >
                                                {moment(row?.answer?.endTime).format("LTS")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {result === "-" ?
                                                mediaResult.length && <ul style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    {mediaResult.map(item =>
                                                        <li style={{
                                                            whiteSpace: "nowrap",
                                                            width: "250px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}>
                                                            - <a href={item} target="_blank">{item}</a>
                                                        </li>
                                                    )}
                                                </ul> :
                                                Array.isArray(result) ?
                                                    <ul style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center"
                                                    }}>
                                                        {result.map(item =>
                                                            <li>- {item}</li>
                                                        )}
                                                    </ul>
                                                    : result}
                                        </TableCell>
                                    </TableRow>)
                                }
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
        </>
    )
}

export default ViewLogEntrySubmission;