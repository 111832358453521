import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Chip,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Modal from "./Modal";
import Delete from "../../../images/Delete.svg";
import DeleteHover from "../../../images/DeleteHover.svg";
import EditHover from "../../../images/EditHover.svg";
import Edit from "../../../images/Edit.svg";
import ViewHover from "../../../images/ViewHover.svg";
import View from "../../../images/View.svg";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import { useSelector, useDispatch } from "react-redux";
import {
  addGroupDataDelete,
  addGroupData,
} from "../../../redux/actions/AddGroup";
const Groups = () => {
  const dispatch = useDispatch();
  const [questionGroupList, setQuestionGroupList] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const addGroupDataAll = useSelector((state) => state.addgroup);
  const [editData, setEditData] = useState();
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
    View: false,
  });

  let LocalData = localStorage.getItem("questionsGroupOfDatamanager");
  function createData(Title, Description) {
    return { Title, Description };
  }

  // const rows = [
  //     createData('Store', 'Everything related store'),
  //     createData('text', 'test'),
  // ];

  useEffect(() => {
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setDataRows(newQuest);
    }
  }, [LocalData]);

  // useEffect(()=>{
  //     if(addGroupDataAll && addGroupDataAll.AddGroup){
  //         setDataRows(addGroupDataAll.AddGroup)
  //         // setCart({card: [...card, AddGroupData.AddGroup]})

  //     }
  // },[addGroupDataAll])

  const [open, setOpen] = useState(false);
  const openDialog = (row) => {
    setOpen(true);
    setEditData(row);
  };

  const handleDelete = (id) => {
    dispatch(addGroupDataDelete(id));
    dispatch(addGroupData());
  };
  useEffect(() => {
    dispatch(addGroupData());
  }, [dispatch]);
  return (
    <Box>
      <Modal open={open} setOpen={setOpen} editData={editData} />
      <Box className="align-icon available-head">
        <Typography
          variant="p"
          component="h2"
          className="top-heading"
          align="left"
        >
          Available Groups
        </Typography>
        <Stack direction="row" spacing={1}>
          <SearchAppBar />
          <Link to="/questions/add-group" className="submenu-item">
            <Button className="header-add cancle-hover">
              <AddOutlinedIcon sx={{ mr: 1 }} />
              Add Group
            </Button>
          </Link>
        </Stack>
      </Box>
      <TableContainer component={Paper} className="table-container">
        <Table size="medium" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className="tableHead">
                Title
              </TableCell>
              <TableCell align="center" className="tableHead">
                Description
              </TableCell>
              <TableCell align="center" className="tableHead">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows &&
              dataRows.map((row, id) => (
                <TableRow key={row.name}>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">
                    <img
                      src={hover.View && indexHover === id ? ViewHover : View}
                      alt="View"
                      style={{
                        height: "35px",
                        cursor: "pointer",
                        margin: "0px 3px 0px 3px",
                      }}
                      onMouseOver={() => {
                        setHover({ View: true });
                        setIndexHover(id);
                      }}
                      onMouseLeave={() => {
                        setHover({ View: false });
                      }}
                    />
                    <img
                      src={hover.Edit && indexHover === id ? EditHover : Edit}
                      alt="Edit"
                      style={{
                        height: "35px",
                        cursor: "pointer",
                        margin: "0px 3px 0px 3px",
                      }}
                      onMouseOver={() => {
                        setHover({ Edit: true });
                        setIndexHover(id);
                      }}
                      onMouseLeave={() => {
                        setHover({ Edit: false });
                      }}
                      onClick={() => {
                        openDialog(row);
                      }}
                    />
                    <img
                      src={
                        hover.Delete && indexHover === id ? DeleteHover : Delete
                      }
                      alt="Delete"
                      style={{
                        height: "35px",
                        cursor: "pointer",
                        margin: "0px 3px 0px 3px",
                      }}
                      onMouseOver={() => {
                        setHover({ Delete: true });
                        setIndexHover(id);
                      }}
                      onMouseLeave={() => {
                        setHover({ Delete: false });
                      }}
                      onClick={() => {
                        handleDelete(row.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Groups;
