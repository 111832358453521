/** @format */

import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import {
  INTENT_CSV_NAM_SUBMISSION_REQUEST,
  INTENT_CSV_NAM_SUBMISSION_SUCCESS,
  INTENT_CSV_NAM_SUBMISSION_ERROR,
} from "../types/Types";

export const IntentCsvNamSubmissionListRequest = () => ({
  type: INTENT_CSV_NAM_SUBMISSION_REQUEST,
});
export const IntentCsvNamSubmissionListSuccess = (list) => ({
  type: INTENT_CSV_NAM_SUBMISSION_SUCCESS,
  payload: list,
});
export const IntentCsvNamSubmissionListFailed = (error) => ({
  type: INTENT_CSV_NAM_SUBMISSION_ERROR,
  payload: error,
});

export const IntentCsvNamSubmissionListApi = (data) => (dispatch) => {
  try {
    dispatch(IntentCsvNamSubmissionListRequest(data));
    if(data.body){
      JwtAuthService.PostApiService(data).then((response) => {
        if (response?.status === 200) {
          dispatch(IntentCsvNamSubmissionListSuccess(response));
          toast.success(`${response?.data?.message}`);
        } else {
          dispatch(IntentCsvNamSubmissionListFailed(response?.data?.message));
          toast.error(`${response?.response?.data?.message}`);
        }
      });
    } else {
      JwtAuthService.GetApiService(data).then((response) => {
        if (response?.status === 200) {
          dispatch(IntentCsvNamSubmissionListSuccess(response));
          toast.success(`${response?.data?.message}`);
        } else {
          dispatch(IntentCsvNamSubmissionListFailed(response?.data?.message));
          toast.error(`${response?.response?.data?.message}`);
        }
      });
    }
  } catch (error) {
    dispatch(IntentCsvNamSubmissionListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
