import React, { useState } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { UserListArchivedApi } from "../../../redux/actions/Checklist";
import { useEffect } from "react";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";

const TableHeader = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const traininglist = props?.training;
  const [permission, setPermission] = useState({});

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  // const paginationRequest = {
  //   body: {
  //     pagination: {
  //       page: props.page,
  //       per_page: props.rowsPerPage,
  //     },
  //     search: "",
  //   },
  // };

  // const UserListArchivedApis = (paginationRequest) => {
  //   const id = localStorage.getItem("companyId");
  //   paginationRequest.url =
  //     BASE_URL + `users/ListArchivedUser?company_id=${id}`;
  //   dispatch(UserListArchivedApi(paginationRequest));
  // };

  // useEffect(() => {
  //   if (props.checkedArchive === true) {
  //     paginationRequest.body.pagination.page = 1;
  //     props.setPage(1);
  //     UserListArchivedApis(paginationRequest);
  //   }
  // }, [props.checkedArchive]);

  const handleAddTraining = () => {
    navigate("/training/add-training", {
      state: { trainingPath: traininglist },
    });
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            {/* <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                icon={
                  <p className="active-tabs-content">{props?.totalitems}</p>
                }
                iconPosition="end"
                className={
                  props.checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(false)}
              />
              <Tab
                label="Archived"
                icon={
                  <p className="active-tabs-content">
                    {props?.totalUserArchived}
                  </p>
                }
                iconPosition="end"
                className={
                  props?.checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(true)}
              />
            </Tabs> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <SearchAppBar
                searchValue={props.searchValue}
                searchOnChange={props.searchOnChange}
                handleKeyDown={props.handleKeyDown}
              />
              {/* <Link to="/training/add-training" className="submenu-item"> */}
              {permission?.lms?.manageLms === true ? (
                <Button
                  onClick={handleAddTraining}
                  className="header-add cancle-hover"
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add New Training
                </Button>
              ) : (
                ""
              )}

              {/* </Link> */}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default TableHeader;
