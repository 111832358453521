import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Reset from "../../../assets/icons/svg-icons/Reset.svg";
import TablePagination from "@mui/material/TablePagination";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import { Grid, Chip } from "@mui/material";
import TableHeader from "./pages/TableHeader";
import { Typography } from "@mui/material";
import { useState } from "react";

function createData(
  Status,
  Number,
  Store,
  AssignedTo,
  Period,
  StartDate,
  DueDate,
  CompletedDate,
  CompletedTime,
  Survey,
  Action
) {
  return {
    Status,
    Number,
    Store,
    AssignedTo,
    Period,
    StartDate,
    DueDate,
    CompletedDate,
    CompletedTime,
    Survey,
    Action,
  };
}

const rows = [
  createData(
    "Completed",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Due",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Expired",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Completed",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Completed",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Completed",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Due",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Due",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Expired",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
  createData(
    "Completed",
    "1234567890",
    "Digital Retail Ltd",
    "skonge +12",
    "Jul 30, 2022 to Jul 30, 2022",
    "Jun 3, 2022",
    "Jun 3, 2022",
    "Oct 28, 2020",
    "3:10:41 PM"
  ),
];

export const AllAssignment = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    Delete: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <TableHeader />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <TableContainer component={Paper} className="table-container">
          <Table size="large" aria-label="simple table" className="grid-table">
            <TableHead sx={{ m: 4 }}>
              <TableRow>
                <TableCell align="center">Assignment ID</TableCell>
                <TableCell align="center">Store</TableCell>
                <TableCell align="center">Assigned To</TableCell>
                <TableCell align="center">Period</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">Due Date</TableCell>
                <TableCell align="center">Completed</TableCell>
                <TableCell align="center">Survey</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box
                      className={
                        row?.Status === "Completed"
                          ? "table-left-border-success"
                          : "table-left-border-danger" && row?.Status === "Due"
                          ? "table-left-border-pending"
                          : "table-left-border-success" &&
                            row?.Status === "Expired"
                          ? "table-left-border-danger"
                          : "table-left-border-success"
                      }
                    >
                      {row.Forms}
                      <Typography variant="p" component="p">
                        {row.Number}
                      </Typography>
                    </Box>
                  </TableCell>
                  {/* <TableCell align="center">{row.Number}</TableCell> */}
                  <TableCell align="center">
                    <Typography variant="p" component="b">
                      {row.Store}
                    </Typography>
                    <Typography variant="p" component="p">
                      {row.Number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p" component="b">
                      {row.AssignedTo}
                    </Typography>
                    <Typography variant="p" component="p">
                      {row.Number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{row.Period}</TableCell>
                  <TableCell align="center">{row.StartDate}</TableCell>
                  <TableCell align="center">{row.DueDate}</TableCell>
                  <TableCell align="center">
                    <Typography variant="p" component="p">
                      {row.CompletedDate},
                    </Typography>
                    <Typography variant="p" component="p">
                      {row.CompletedTime}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="p" component="b">
                      {row.Number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <img src={Reset} alt="Reset" className="iconBox" />
                    <img
                      src={
                        hover.Delete && indexHover === i ? DeleteHover : Delete
                      }
                      alt="Delete"
                      style={{
                        height: "35px",
                        cursor: "pointer",
                        margin: "0px 3px 0px 3px",
                      }}
                      onMouseOver={() => {
                        setHover({ Delete: true });
                        setIndexHover(i);
                      }}
                      onMouseLeave={() => {
                        setHover({ Delete: false });
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 2, mt: 1, borderRadius: 1 }}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              showFirstButton={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AllAssignment;
