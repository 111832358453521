import JwtAuthService from "../apiConfig/ApiConfig";
import {
  BROADCAST_MESSAGE_ERROR,
  BROADCAST_MESSAGE_REQUEST,
  BROADCAST_MESSAGE_SUCCESS,
  CHECKLIST_CHART_ERROR,
  CHECKLIST_CHART_REQUEST,
  CHECKLIST_CHART_SUCCESS,
  CHECKLIST_COMPLETED_ERROR,
  CHECKLIST_COMPLETED_REQUEST,
  CHECKLIST_COMPLETED_SUCCESS,
  CHECKLIST_REPORTDATA_ERROR,
  CHECKLIST_REPORTDATA_REQUEST,
  CHECKLIST_REPORTDATA_SUCCESS,
  DUE_SCHEDULES_ERROR,
  DUE_SCHEDULES_REQUEST,
  DUE_SCHEDULES_SUCCESS,
  ISSUE_RAISED_ERROR,
  ISSUE_RAISED_REQUEST,
  ISSUE_RAISED_SUCCESS,
  UPCOMING_SCHEDULES_ERROR,
  UPCOMING_SCHEDULES_REQUEST,
  UPCOMING_SCHEDULES_SUCCESS,
} from "../types/Types";
import { toast } from "react-toastify";

export const checklistCompleted = () => ({
  type: CHECKLIST_COMPLETED_REQUEST,
});

export const checklistCompletedSuccess = (companyid) => ({
  type: CHECKLIST_COMPLETED_SUCCESS,
  payload: companyid,
});

export const checklistCompletedFailed = (error) => ({
  type: CHECKLIST_COMPLETED_ERROR,
  payload: error,
});

export const checklistCompletedApi = (data) => (dispatch) => {
  try {
    dispatch(checklistCompleted(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(checklistCompletedSuccess(data));
      } else {
        dispatch(checklistCompletedFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(checklistCompletedFailed(error));
  }
};

export const issueRaised = (data) => ({
  type: ISSUE_RAISED_REQUEST,
  payload: data,
});

export const issueRaisedSuccess = (data) => ({
  type: ISSUE_RAISED_SUCCESS,
  payload: data,
});

export const issueRaisedFailed = (error) => ({
  type: ISSUE_RAISED_ERROR,
  payload: error,
});

export const issueRaisedApi = (data) => (dispatch) => {
  try {
    dispatch(issueRaised(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(issueRaisedSuccess(response));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(issueRaisedFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(issueRaisedFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const broadcastMessage = (companyid) => ({
  type: BROADCAST_MESSAGE_REQUEST,
  payload: companyid,
});

export const broadcastMessageSuccess = (companyid) => ({
  type: BROADCAST_MESSAGE_SUCCESS,
  payload: companyid,
});

export const broadcastMessageFailed = (error) => ({
  type: BROADCAST_MESSAGE_ERROR,
  payload: error,
});

export const broadcastMessageApi = (data) => (dispatch) => {
  try {
    dispatch(broadcastMessage());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(broadcastMessageSuccess(response));
      } else {
        dispatch(broadcastMessageFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(broadcastMessageFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const upcomingSchedule = (companyid) => ({
  type: UPCOMING_SCHEDULES_REQUEST,
  payload: companyid,
});

export const upcomingScheduleSuccess = (companyid) => ({
  type: UPCOMING_SCHEDULES_SUCCESS,
  payload: companyid,
});

export const upcomingScheduleFailed = (error) => ({
  type: UPCOMING_SCHEDULES_ERROR,
  payload: error,
});

export const upcomingScheduleApi = (data) => (dispatch) => {
  try {
    dispatch(upcomingSchedule());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(upcomingScheduleSuccess(response));
      } else {
        dispatch(upcomingScheduleFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(upcomingScheduleFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const dueSchedule = (companyid) => ({
  type: DUE_SCHEDULES_REQUEST,
  payload: companyid,
});

export const dueScheduleSuccess = (companyid) => ({
  type: DUE_SCHEDULES_SUCCESS,
  payload: companyid,
});

export const dueScheduleFailed = (error) => ({
  type: DUE_SCHEDULES_ERROR,
  payload: error,
});

export const dueScheduleApi = (data) => (dispatch) => {
  try {
    dispatch(dueSchedule());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(dueScheduleSuccess(response));
      } else {
        dispatch(dueScheduleFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(dueScheduleFailed(error));
  }
};

export const checklistReport = (companyid) => ({
  type: CHECKLIST_REPORTDATA_REQUEST,
  payload: companyid,
});

export const checklistReportSuccess = (companyid) => ({
  type: CHECKLIST_REPORTDATA_SUCCESS,
  payload: companyid,
});

export const checklistReportFailed = (error) => ({
  type: CHECKLIST_REPORTDATA_ERROR,
  payload: error,
});

export const checklistReportApi = (data) => (dispatch) => {
  try {
    dispatch(checklistReport());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(checklistReportSuccess(response));
      } else {
        dispatch(checklistReportFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(checklistReportFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const checklistChart = (companyid) => ({
  type: CHECKLIST_CHART_REQUEST,
  payload: companyid,
});

export const checklistChartSuccess = (companyid) => ({
  type: CHECKLIST_CHART_SUCCESS,
  payload: companyid,
});

export const checklistChartFailed = (error) => ({
  type: CHECKLIST_CHART_ERROR,
  payload: error,
});

export const checklistChartApi = (data) => (dispatch) => {
  try {
    dispatch(checklistChart());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(checklistChartSuccess(response));
      } else {
        dispatch(checklistChartFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(checklistChartFailed(error));
    console.error("Something went Wrong", error);
  }
};
