import {
  CSV_SUBMISSION_ERROR,
  CSV_SUBMISSION_REQUEST,
  CSV_SUBMISSION_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  csvSubmission: "",
};

const CsvSubmissionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CSV_SUBMISSION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CSV_SUBMISSION_SUCCESS: {
      return {
        ...state,
        csvSubmission: action?.payload,
        loading: false,
      };
    }
    case CSV_SUBMISSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default CsvSubmissionDataReducer;
