import React from "react";
import {
  FormControl,
  Box,
  TextField,
  Grid,
  Container,
  Button,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import { useFormik } from "formik";

const AddStoreChapter = () => {
  const formik = useFormik({
    initialValues: {
      title: "",
      contentType: "",
      chapterSummary: "",
    },
    onSubmit: (values) => {},
  });
  return (
    <Box>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link
                to="/store-training/add-store-training"
                className="submenu-item"
              >
                <Button className="cancle-button cancle-hover">
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
              </Link>
              <Link
                to="/store-training/add-store-training"
                className="submenu-item"
              >
                <Button className="header-add cancle-hover">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Create
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          mt: 1,
          p: 2,
          borderRadius: "5px",
          pl: 3,
          height: "70vh",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            p: 1,
            textAlign: "left",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={8} sm={12} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Content Type</label>
                  <Select
                    size="small"
                    name="contentType"
                    onChange={formik.handleChange}
                    value={formik.values.contentType}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Chapter Summary</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="chapterSummary"
                onChange={formik.handleChange}
                value={formik.values.chapterSummary}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Content</label>
              {/* <TextField id="outlined-basic" variant="outlined" size='small' multiline rows={8} /> */}
              <ThemeProvider>
                <MUIRichTextEditor label="Start typing..." />
              </ThemeProvider>
            </FormControl>
          </form>
        </Container>
      </Box>
    </Box>
  );
};

export default AddStoreChapter;
