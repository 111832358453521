import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

const FirstForm = (props) => {
  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmitForm(values);
      // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box className="step1-box">
          <Typography className="email-box">
            We will send you a verification code to verify your identity. This
            code will expire soon. Please check you mailbox and don't forget to
            check spam.
          </Typography>
          <FormControl sx={{ mb: 2 }} fullWidth>
            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="Enter Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              className="date-input"
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={props?.activeStep === 0}
            onClick={props?.handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button type="submit">
            {props?.activeStep === props?.steps?.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default FirstForm;
