import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

function Map({ storeLocation, userLocation }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedLocationUser, setSelectedLocationUser] = useState(null);
  const [listData, setListData] = useState(null);

  const getChecklistDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionView &&
      state.checklist.submissionView.data &&
      state.checklist.submissionView.data.data
  );

  useEffect(() => {
    setListData(getChecklistDetails);
  }, [getChecklistDetails]);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        selectedLocation(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <Box>
      <GoogleMap
        defaultZoom={19}
        // defaultCenter={{ lat: 22.75263495290906, lng: 75.86549608204295 }}
        center={{
          lat: listData?.storeLocation?.lat,
          lng: listData?.storeLocation?.lng,
        }}
      >
        <Marker
          key={listData?.store?._id}
          position={{
            lat: listData?.storeLocation?.lat,
            lng: listData?.storeLocation?.lng,
          }}
          onClick={() => {
            setSelectedLocation(listData?.storeLocation);
          }}
          icon={{
            url: "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        />
        <Marker
          key={listData?.user?.userId}
          position={{
            lat: listData?.userLocation?.lat,
            lng: listData?.userLocation?.lng,
          }}
          onClick={() => {
            setSelectedLocationUser(listData?.userLocation);
          }}
          icon={{
            url: "https://img.icons8.com/color/48/000000/map-pin.png",
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        />

        {selectedLocation && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedLocation(null);
            }}
            position={{
              lat: selectedLocation?.lat,
              lng: selectedLocation?.lng,
            }}
          >
            <div>
              <h3>{storeLocation}</h3>
              {/* <h3>{userLocation}</h3> */}
            </div>
          </InfoWindow>
        )}
        {selectedLocationUser && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedLocationUser(null);
            }}
            position={{
              lat: selectedLocationUser?.lat,
              lng: selectedLocationUser?.lng,
            }}
          >
            <div>
              {/* <h3>{storeLocation}</h3> */}
              <h3>{userLocation}</h3>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </Box>
  );
}

export default Map;

const MapWrapped = withScriptjs(withGoogleMap(Map));

export function Main({ storeLocation, userLocation }) {
  return (
    <div
      style={{
        width: "22vw",
        height: "26vh",
        border: "2px solid #CBD4E1",
        borderRadius: "8px",
      }}
    >
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA&v=3.exp&libraries=geometry,drawing,places}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        storeLocation={storeLocation}
        userLocation={userLocation}
      />
    </div>
  );
}
