import {
  MISSEDCHECKLIST_ERROR,
  MISSEDCHECKLIST_FORM_REQUEST,
  MISSEDCHECKLIST_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  MissedChecklist: "",
};

const MissedCheckLIstReducer = (state = initialState, action) => {
  switch (action.type) {
    case MISSEDCHECKLIST_FORM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MISSEDCHECKLIST_SUCCESS: {
      return {
        ...state,
        MissedChecklist: action?.payload,
        loading: false,
      };
    }
    case MISSEDCHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default MissedCheckLIstReducer;
