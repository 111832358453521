import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Card,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import OfflinePinOutlinedIcon from "@mui/icons-material/OfflinePinOutlined";
import QuestionsList from "./Questions";
import Groups from "./Groups";
import { useDispatch } from "react-redux";
import {
  DataListApi,
  DataManagerApi,
} from "../../../redux/actions/DataManager";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { ToastContainer } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Header = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [uiRenders, setUiRenders] = useState("");
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const dataListData = localStorage.getItem("dataListOfdata");
  const questionGroup = localStorage.getItem("questionsGroupOfDatamanager");
  const questionData = localStorage.getItem("questionsOfDatamanger");
  let questionlist = JSON.parse(questionData);
  const id = localStorage.getItem("companyId");

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (uiRenders === "delete") {
      window.location.reload(false);
    }
  }, [uiRenders]);

  const handleUpdateCompany = () => {
    if (dataListData && questionData && questionGroup) {
      let datalist = JSON.parse(dataListData);
      let questionlist = JSON.parse(questionData);
      let questionGroupData = JSON.parse(questionGroup);
      const allPayload = {
        body: {
          kpis: [],
          list: datalist,
          question_groups: questionGroupData,
          questions: questionlist,
          _id: id,
        },
      };
      allPayload.url = BASE_URL + `companies/${id}`;
      dispatch(DataManagerApi(allPayload));
      DataApiList();
    }
  };

  const DataApiList = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${id}` };
    dispatch(DataListApi(data));
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Card className="header-card top-margin">
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                style={{marginLeft:"8px"}}
                label="Questions"
                iconPosition="end"
                className="active-tabs tabs-content"
                {...a11yProps(0)}
              />
              <Tab
                label="Groups"
                iconPosition="end"
                className="Archived-tabs tabs-content"
                {...a11yProps(1)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack
              direction="row"
              className="datalist-header-stack"
              sx={{ justifyContent: "end", width: "100%" }}
            >
              <Button
                variant="outlined"
                className="user-header"
                color="primary"
              >
                <ArchiveOutlinedIcon />
                Import Data{" "}
              </Button>
              <Button
                variant="outlined"
                className="user-header"
                color="success"
                onClick={handleUpdateCompany}
              >
                <OfflinePinOutlinedIcon />
                Update Company{" "}
              </Button>
              <div style={{marginRight:"10px",marginTop:"4px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TabPanel value={value} index={0}>
              <QuestionsList setUiRenders={setUiRenders} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Groups />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Header;

export const ViewHeader = () => {
  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: "#F6F8FC",
          width: "100%",
          borderRadius: "8px",
          mt: 1,
          p: 1,
        }}
        alignItems="center"
        className="top-margin"
      >
        <Grid item md={12} sx={12} sm={6} lg={12}>
          <Stack direction="row" spacing={1} sx={{ float: "right", mr: 2 }}>
            <Link to="/user-list" className="submenu-item">
              <Button variant="outlined" className="done-btn">
                <CheckIcon sx={{ mr: 1, color: "#27364b" }} /> Done
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
