import { CircularProgress } from "material-ui-core";
import React from "react";

function ProgressbarStatic() {
  return (
    // <div>
    //   <CircularProgress color="inherit" />
    // </div>

    <div
      class="text-center"
      style={{
        // position: "relative",
        // top: "250px",
        // height: "100%",
        // width: '500',
        // margin: '0 auto',
        // padding: '10px',
        textAlign: 'center'
      }}
    >
      <div class="spinner-border" role="status">
        <CircularProgress
          color="inherit"
          size="25px"
          style={{ color: "#f26539" }}
        />
      </div>
    </div>
  );
}

export default ProgressbarStatic;
