/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Divider } from "@mui/material";
import Blank from "../../../images/Blank.png";
import DeleteHover from "../../../images/DeleteHover.svg";
import Delete from "../../../images/Delete.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  GetCertificateDataApi,
  AddCertificateImageApi,
  EditCertificateApi,
} from "../../../redux/actions/LmsAction";
import { toast, ToastContainer } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const EditCertificate = ({ title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [editImage, setEditImage] = useState();
  const [inputValue, setInputValue] = useState([]);
  const [newField, setNewField] = useState();
  const [hover, setHover] = useState({
    Delete: false,
  });

  const GetCertificateFormData = useSelector(
    (state) =>
      state.lms &&
      state.lms.getCertificate &&
      state.lms.getCertificate.data &&
      state.lms.getCertificate.data.data
  );

  const EditCertificate = useSelector(
    (state) =>
      state.lms && state.lms.editCertificate && state.lms.editCertificate.data
  );

  const getImageKey = useSelector(
    (state) =>
      state.lms &&
      state.lms.addImage &&
      state.lms.addImage.length &&
      state.lms.addImage[0]?.imageData?.[0].key
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    GetCertificateData();
  }, []);

  useEffect(() => {
    setInputValue(GetCertificateFormData);
  }, [GetCertificateFormData]);

  useEffect(() => {
    setInputValue(inputValue);
    if (inputValue) {
      setNewField({
        title: inputValue && inputValue?.title,
        summary: inputValue && inputValue?.summary,
      });
      setEditImage(inputValue && inputValue.key);
    }
  }, [inputValue]);

  useEffect(() => {
    if (EditCertificate && EditCertificate.status === 200) {
      // toast.success(EditCertificate.message, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/certificate");
      }, 2000);
    }
  }, [EditCertificate]);

  useEffect(() => {
    if (image) {
      setPreview(URL.createObjectURL(image));
    }
  }, [image]);

  useEffect(() => {
    // console.log("getImageKey", getImageKey);
    if (getImageKey) {
      addImage(formik.values, getImageKey);
    }
  }, [getImageKey]);

  const GetCertificateData = () => {
    const _id = localStorage.getItem("companyId");
    const certiId = location?.state?._id;
    const data = { url: BASE_URL + `certificate/${certiId}?company_id=${_id}` };
    dispatch(GetCertificateDataApi(data));
  };

  // select image code
  // const handleImageUpload = (e) => {
  //   setImage(e.target.files[0]);
  //   if (preview !== "") {
  //     const formData = new FormData();
  //     formData.append("File", {
  //       name: image.name,
  //       type: image.type,
  //       size: image.size,
  //     });
  //   }
  // };

  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (selectedImage.size <= 5242880) {
        const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (allowedImageTypes.includes(selectedImage.type)) {
          setImage(selectedImage);
        } else {
          toast.error("We only allow image files (JPEG, PNG, GIF).");
        }
      } else {
        toast.error("Only files up to 5MB are allowed.");
      }
    }
  };

  const removeImage = () => {
    setPreview();
    if (image !== "") {
      setImage("");
    }
    if (editImage) {
      setEditImage("");
    }
  };

  const formik = useFormik({
    initialValues: newField || {
      title: "",
      summary: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const imagefile = {
        files: image,
      };
      const imageData = {
        body: imagefile,
      };
      if (imageData.body.files != "" && imageData.body.files != undefined) {
        imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
        dispatch(AddCertificateImageApi(imageData));
      } else {
        let imgKey = "";
        addImage(values, imgKey);
      }
    },
  });

  const addImage = (values, image) => {
    const formData = {
      title: values.title,
      summary: values.summary,
      _id: inputValue._id,
    };
    if (image) {
      formData.key = image;
    }
    const allData = {
      body: formData,
    };
    if ((allData && preview) || editImage) {
      const id = localStorage.getItem("companyId");
      allData.url = BASE_URL + `certificate?company_id=${id}`;
      dispatch(EditCertificateApi(allData));
    } else {
      toast.error("Please provide image", { autoClose: 3000 });
    }
  };

  return (
    <Box>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/certificate" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  className="header-add cancle-hover"
                  style={{ marginRight: 5 }}
                >
                  <TaskAltIcon sx={{ mr: 1 }} />
                  Update
                </Button>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            // height: "80vh",
            height: "calc(100vh - 220px)",
            mt: 1,
            p: 2,
            overflowX: "hidden",
            overflowY: "scroll",
            borderRadius: "8px",
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          <Grid container spacing={1} sx={{ justifyContent: "space-between" }}>
            <Grid item md={5.8} sm={12} xs={12} lg={5.8}>
              <Box
                maxWidth="sm"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  p: 3,
                  textAlign: "left",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Certificate Title</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      name="title"
                      onChange={formik.handleChange}
                      value={formik?.values?.title}
                      className="input-fields"
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Certificate Summary</label>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={8}
                      name="summary"
                      onChange={formik.handleChange}
                      value={formik?.values?.summary}
                      className="input-fields"
                    />
                  </FormControl>
                </form>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid
              item
              md={5.8}
              sm={12}
              xs={12}
              lg={5.8}
              sx={{ alignItems: "center", textAlign: "left" }}
              className="apply"
            >
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Typography variant="p" component="b">
                    Certificate Image
                  </Typography>
                  <Box>
                    <input
                      name="image"
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      hidden
                      value={""}
                      onChange={handleImageUpload}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="add-button"
                    >
                      <AddOutlinedIcon className="icon" />
                      Add New Certificate
                    </label>
                  </Box>
                </Grid>
              </Grid>
              <Box className="image-box">
                <Stack
                  direction="row"
                  sx={{ justifyContent: "space-around", display: "flex" }}
                >
                  {preview ? (
                    <img
                      className="image-dimension"
                      src={preview}
                      alt="attachment"
                    />
                  ) : editImage ? (
                    <img
                      className="image-dimension"
                      src={editImage?.url}
                      alt="attachment"
                    />
                  ) : (
                    <img
                      className="image-preview"
                      src={Blank}
                      alt="attachment"
                    />
                  )}
                  <img
                    onClick={removeImage}
                    src={hover.Delete ? DeleteHover : Delete}
                    alt="Delete"
                    className="delete-image"
                    onMouseOver={() => {
                      setHover({ Delete: true });
                    }}
                    onMouseLeave={() => {
                      setHover({ Delete: false });
                    }}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default EditCertificate;
