/** @format */

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TablePagination from "@mui/material/TablePagination";
import LightTooltip from "@mui/material/Tooltip";

import {
  Button,
  Card,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { FormControl } from "@mui/material";
import { ViewHeader } from "./AllChecklistHead";
import ViewSubmission from "./ViewSubmission";
import * as moment from "moment";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import {
  ActiveChecklistListApi,
  PendingChecklistListApi,
} from "../../redux/actions/Checklist";
import { useDispatch } from "react-redux";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import CircleIcon from "@mui/icons-material/Circle";

const ViewForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tableData, setTableData] = useState({});
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [newFieldValue, setNewFieldValue] = useState({});
  const [detailedData, setDetailedData] = useState([]);
  const [detailedPendingData, setDetailedPendingData] = useState([]);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(10);
  const [startPosition1, setStartPosition1] = useState(0);
  const [endPosition1, setEndPosition1] = useState(10);

  const activeData = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.activeChecklist &&
      state.checklist.activeChecklist.data
  );

  const pendingData = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.pendingChecklist &&
      state.checklist.pendingChecklist.data &&
      state.checklist.pendingChecklist.data.data
  );

  useEffect(() => {
    let rowdata = JSON.parse(localStorage.getItem("allchecklistData"));
    if (rowdata) {
      setTableData(rowdata);
    }
    dateRangeSubmit();
  }, []);

  useEffect(() => {
    setLoader(true);
    setRowsPerPage(10);
    setStartPosition(0);
    setEndPosition(10);
    if (pendingData) {
      setDetailedPendingData(pendingData);
    }
    if (activeData) {
      setDetailedData(activeData);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [pendingData, activeData]);

  const dateRangeSubmit = () => {
    setDetailedData([]);
    setDetailedPendingData([]);

    const _id = localStorage.getItem("companyId");
    const checklistId = location?.state?.id;

    if (!startDate || !endDate) {
      toast.error("Both start date and end date must be selected");
      return;
    }
    const formattedStartDate = moment(startDate, "YYYYMMDD").format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(endDate, "YYYYMMDD").format("YYYY-MM-DD");
    const data = {
      url:
        BASE_URL +
        `checklist/submit?company_id=${_id}&_id=${checklistId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
    };

    const pendingData = {
      url:
        BASE_URL +
        `checklist/pending?company_id=${_id}&_id=${checklistId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
    };

    dispatch(PendingChecklistListApi(pendingData));
    dispatch(ActiveChecklistListApi(data));
  };

  const formik = useFormik({
    initialValues: newFieldValue || {
      reportselect: "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      dateRangeSubmit();
      resetForm({ values: "" });
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const start = newPage !== 0 ? newPage * rowsPerPage : 0;
    const end = (newPage + 1) * rowsPerPage;
    setStartPosition(start);
    setEndPosition(end - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setStartPosition(0);
    setEndPosition(+event.target.value - 1);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
    const start = newPage !== 0 ? newPage * rowsPerPage1 : 0;
    const end = (newPage + 1) * rowsPerPage1;
    setStartPosition1(start);
    setEndPosition1(end - 1);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage(+event.target.value);
    setPage1(0);
    setStartPosition1(0);
    setEndPosition1(+event.target.value - 1);
  };

  return (
    <Container maxWidth="xl">
      <ToastContainer autoClose={3000} />
      <ViewHeader tableData={tableData} />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          mt: 1,
          p: 1,
          textAlign: "left",
          mb: 2,
          borderRadius: "8px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <form>
            <Grid direction="row" container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <label>Category</label>
                  <Box id="outlined-basic" className="viewchecklist">
                    {tableData?.title ? tableData?.title : "N/A"}
                  </Box>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <label>Form Type</label>
                  <Box id="outlined-basic" className="viewchecklist">
                    {tableData?.type === 1 ? "Checklist" : "Audit"}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <label>Frequency</label>
                  <Box id="outlined-basic" className="viewchecklist">
                    <Box>
                      {(() => {
                        if (tableData?.frequency === 3) {
                          return "Daily";
                        } else if (tableData?.frequency === 2) {
                          return "Hourly";
                        } else if (tableData?.frequency === 5) {
                          return "Monthly";
                        } else if (tableData?.frequency === 1) {
                          return "No frequency";
                        } else if (tableData?.frequency === 4) {
                          return "Weekly";
                        }
                        return "N/A";
                      })()}
                    </Box>
                    <Box sx={{ display: "inline-flex" }}>
                      {tableData &&
                        tableData?.month_date?.length > 0 &&
                        tableData?.month_date?.map((item, i) => {
                          {
                            return (
                              <div key={i}>
                                <div>{item},</div>
                              </div>
                            );
                          }
                        })}
                    </Box>
                    <Box sx={{ display: "inline-flex" }}>
                      {tableData &&
                        tableData?.week_day?.length > 0 &&
                        tableData?.week_day?.map((item, i) => {
                          {
                            return (
                              <div>
                                <div>{item === 1 ? "Sunday" : ""}</div>
                                <div>{item === 2 ? "Monday" : ""}</div>
                                <div>{item === 3 ? "Tuesday" : ""}</div>
                                <div>{item === 4 ? "Wednesday" : ""}</div>
                                <div>{item === 5 ? "Thursday" : ""}</div>
                                <div>{item === 6 ? "Friday" : ""}</div>
                                <div>{item === 7 ? "Saturday" : ""}</div>
                              </div>
                            );
                          }
                        })}
                      <div>{tableData?.week_day === 1 ? "Sunday" : ""}</div>
                      <div>{tableData?.week_day === 2 ? "Monday" : ""}</div>
                      <div>{tableData?.week_day === 3 ? "Tuesday" : ""}</div>
                      <div>{tableData?.week_day === 4 ? "Wednesday" : ""}</div>
                      <div>{tableData?.week_day === 5 ? "Thursday" : ""}</div>
                      <div>{tableData?.week_day === 6 ? "Friday" : ""}</div>
                      <div>{tableData?.week_day === 7 ? "Saturday" : ""}</div>
                    </Box>
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <label>Linked Forms</label>
                  <Box
                    id="outlined-basic"
                    className="viewchecklist"
                    sx={{ maxHeight: "132px", overflow: "auto" }}
                  >
                    {tableData?.forms?.map((items, i) => {
                      return (
                        <div>
                          <ul className="chat-list">
                            <li>
                              {i + 1}.{items?.title ? items?.title : "N/A"}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </Box>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <label>Send Report</label>
                  <Box
                    id="outlined-basic"
                    className="viewchecklist"
                    sx={{ maxHeight: "132px", overflow: "auto" }}
                  >
                    {tableData?.mailReport?.map((items, i) => {
                      return (
                        <div>
                          <ul className="chat-list">
                            <li>
                              {i + 1}.{items}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                    {tableData && tableData?.mailReport?.length == 0 && "N/A"}
                  </Box>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <label>Send Push Notification Report</label>
                  <Box
                    id="outlined-basic"
                    className="viewchecklist"
                    sx={{ maxHeight: "132px", overflow: "auto" }}
                  >
                    {tableData?.notificationReport?.map((items, i) => {
                      return (
                        <div>
                          <ul className="chat-list">
                            <li>
                              {i + 1}.{items}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                    {tableData &&
                      tableData?.notificationReport?.length == 0 &&
                      "N/A"}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormControl margin="normal" required fullWidth>
                      <label>Active Date</label>
                      <Box id="outlined-basic" className="viewchecklist">
                        {moment(tableData?.starts_at).format("MMMM D, YYYY")}
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormControl margin="normal" required fullWidth>
                      <label>Expire Date</label>
                      <Box id="outlined-basic" className="viewchecklist">
                        {moment(tableData?.expires_at).format("MMMM D, YYYY")}
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ pt: "10px" }}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormControl margin="normal" required fullWidth>
                      <label>Start Time</label>
                      <Box id="outlined-basic" className="viewchecklist">
                        {tableData?.startTime ? tableData?.startTime : "N/A"}
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <FormControl margin="normal" required fullWidth>
                      <label>End Time</label>
                      <Box id="outlined-basic" className="viewchecklist">
                        {tableData?.endTime ? tableData?.endTime : "N/A"}
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
                {tableData?.time_interval ? (
                  <Grid container spacing={3} sx={{ pt: "10px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <label>Time interval</label>
                        <Box id="outlined-basic" className="viewchecklist">
                          {tableData?.time_interval
                            ? tableData?.time_interval
                            : "N/A"}
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid direction="row" container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <label>Assigned To</label>
                  <Box id="outlined-basic" className="viewchecklist">
                    {tableData?.assignedTo?.name
                      ? tableData?.assignedTo?.name
                      : tableData.role}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <FormControl margin="normal" required fullWidth>
                  <label>Image Category</label>
                  <Box id="outlined-basic" className="viewchecklist">
                    {tableData?.imageCategory}
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant="h5"
              component="h4"
              align="left"
              style={{ padding: "4px" }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <FormControl
                      fullWidth
                      sx={{
                        maxWidth: "300px",
                        maxHeight: "53px",
                        marginLeft: "7px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          size="small"
                          value={startDate}
                          onChange={(newValue) => {
                            if (newValue && newValue.$d) {
                              let dateSort = moment(newValue.$d)
                                .format("YYYYMMDD")
                                .split("/")
                                .join("");
                              setStartDate(dateSort);
                              setEndDate(dateSort);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          maxDate={new Date()}
                          PopperProps={{
                            popperOptions: {
                              modifiers: [
                                {
                                  name: "preventOverflow",
                                  options: {
                                    padding: 8,
                                  },
                                },
                                {
                                  name: "flip",
                                  options: {
                                    padding: 8,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <Box sx={{ mx: 2, fontSize: "1.2rem", marginTop: "10px" }}>
                      <Button
                        variant="contained"
                        autoFocus
                        type="submit"
                        color="success"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          variant="h6"
          component="h4"
          className="storedetail-headings"
        >
          Today Checklist Submission Activity
        </Typography>
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
            <Table
              size="large"
              aria-label="simple table"
              className="grid-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="tableHead">
                    S. No.
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    User
                  </TableCell>

                  <TableCell align="center" className="tableHead">
                    Store
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Role
                  </TableCell>

                  <TableCell align="center" className="tableHead">
                    Updated At
                  </TableCell>
                  <TableCell align="left" className="tableHead">
                    Weightage Score
                  </TableCell>
                  <TableCell align="center" className="tableHead">
                    Progress
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailedData?.data && detailedData?.data.length > 0 ? (
                  detailedData?.data?.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {row?.userName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {row?.store?.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{row?.role}</TableCell>
                      <TableCell align="center">
                        {moment(row.updatedAt).format("lll")}
                      </TableCell>
                      <TableCell align="right">
                        <ul style={{ paddingInlineStart: "0px" }}>
                          {row?.forms?.map((data, index) => (
                            <li
                              className="my-list-w !important"
                              key={index}
                              style={{
                                textAlign: "start",
                              }}
                            >
                              <CircleIcon
                                className="font"
                                style={{ paddingRight: 4 }}
                              />
                              {(
                                (data?.calculatedOptionScore /
                                  data?.calculatedQuestionScore) *
                                100
                              ).toFixed(2)}
                              %
                            </li>
                          ))}
                        </ul>
                      </TableCell>

                      <TableCell align="center">
                        {row?.completePercentage
                          ? `${row?.completePercentage.toFixed(2)}%`
                          : "0%"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={50}>
                      <SentimentVeryDissatisfiedIcon fontSize="large" />
                      <Typography variant="p" component="h2">
                        No Data
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div align="center">
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={detailedData?.data ? detailedData?.data?.length : 0}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "16px",
                }}
              />
            </div>
          </TableContainer>
        )}
        <Typography
          variant="h6"
          component="h4"
          className="storedetail-headings"
        >
          Today Pending Checklist Activity
        </Typography>
        <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
          <Table size="large" aria-label="simple table" className="grid-table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="tableHead">
                  S. No.
                </TableCell>{" "}
                <TableCell align="center" className="tableHead">
                  User
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Email
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Sub-Role
                </TableCell>
                <TableCell align="center" className="tableHead">
                  Store
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailedPendingData && detailedPendingData.length > 0 ? (
                detailedPendingData.map((row, i) =>
                  row?.Pendinguser?.length ? (
                    row?.Pendinguser?.slice(startPosition, endPosition).map(
                      (user, j) => (
                        <TableRow key={`${i}-${j}`}>
                          <TableCell align="center">
                            {page * rowsPerPage +
                              i * row.Pendinguser.length +
                              j +
                              1}
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="p" component="p">
                              {user?.name || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="p" component="p">
                              {user?.email || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="p" component="p">
                              {user?.subRole || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="p" component="p">
                              {user?.store && user.store.length > 0
                                ? user.store[0]?.title || ""
                                : "N/A"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : detailedPendingData.length === 1 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={50}>
                        <SentimentVeryDissatisfiedIcon fontSize="large" />
                        <Typography variant="p" component="h2">
                          No Data
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={50}>
                    <SentimentVeryDissatisfiedIcon fontSize="large" />
                    <Typography variant="p" component="h2">
                      No Data
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={
              detailedPendingData
                ? detailedPendingData.reduce(
                    (totalCount, item) =>
                      totalCount +
                      (item.Pendinguser ? item.Pendinguser.length : 0),
                    0
                  )
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "16px",
            }}
          />
        </TableContainer>
      </Box>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </Container>
  );
};

export default ViewForm;
