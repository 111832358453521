const questiontype = [
  {
    id: 1,
    title: "Boolean",
    key: "boolean",
    title_to_show: "boolean",
  },

  {
    id: 2,
    title: "Numeric",
    key: "numeric",
    title_to_show: "Numeric",
  },
  {
    id: 3,
    title: "Text",
    key: "text",
    title_to_show: "Text",
  },
  {
    id: 4,
    title: "Single Choice",
    key: "singlechoice",
    title_to_show: "Single Choice",
  },
  {
    id: 5,
    title: "Multiple Choice",
    key: "multiplechoice",
    title_to_show: "Multiple Choice",
  },
  {
    id: 6,
    title: "Media",
    key: "media",
    title_to_show: "Media",
  },
  {
    id: 8,
    title: "Date",
    key: "date",
    title_to_show: "Date",
  },
  {
    id: 9,
    title: "DateTime",
    key: "DateTime",
    title_to_show: "DateTime",
  },
  {
    id: 10,
    title: "Time",
    key: "Time",
    title_to_show: "Time",
  },
  {
    id:11,
    title: "Timer",
    key: "Timer",
    title_to_show: "Timer",
  },
  
];

export const questiontypeassessment = [
  {
    id: 1,
    title: "Boolean",
    key: "boolean",
    title_to_show: "Boolean",
  },
  {
    id: 2,
    title: "Numeric",
    key: "numeric",
    title_to_show: "Numeric",
  },
  {
    id: 3,
    title: "Text",
    key: "text",
    title_to_show: "Text",
  },
  {
    id: 4,
    title: "Single Choice",
    key: "singlechoice",
    title_to_show: "Single Choice",
  },
  {
    id: 5,
    title: "Multiple Choice",
    key: "multiplechoice",
    title_to_show: "Multiple Choice",
  },
  {
    id: 6,
    title: "Media",
    key: "media",
    title_to_show: "Media",
  },
  {
    id: 8,
    title: "Date",
    key: "date",
    title_to_show: "Date",
  },
  {
    id: 9,
    title: "DateTime",
    key: "DateTime",
    title_to_show: "DateTime",
  },

 
];

export default questiontype;
