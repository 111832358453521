import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Select,
  IconButton,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Button,
  InputAdornment,
  ToggleButton,
} from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { AddDataListData } from "../../../redux/actions/DataManager";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { SettingsOutlined, TextFieldsRounded } from "@mui/icons-material";

const AddList = () => {
  const AddListData = useSelector(
    (state) => state && state.dataManager && state.dataManager.AddDataList
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [keyValues, setKeyValues] = useState("");
  const [valuesKey, setValuesKey] = useState("");
  const [value, setValue] = useState("");
  const [key, setKey] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [listItem, setListItem] = useState([]);
  const [listValue, setListValue] = useState([]);
  const [customSelect, setCustomSelect] = useState("");
  const [customFieldsss, setCustomFieldsss] = useState([]);
  const [iconChange, setIconChange] = useState(false);
  const [iconChangeCustom, setIconChangeCustom] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [customId, setCustomId] = useState("");
  const [ids, setIds] = useState("");
  const [newValues, setNewValues] = useState("");
  const [customIndex, setCustomIndex] = useState(0);

  const handleKeysValues = () => {
    let customValue = {
      key: key,
      value: selectValue,
      id: customIndex,
    };
    setCustomIndex(customIndex + 1);
    // setCustomFieldsss(oldArray => [...oldArray, { id: uuidv4(), key: key, value: selectValue}]);
    setCustomFieldsss(customValue);
    if (customValue) {
      const newState = listItem?.map((obj, i) => {
        if (obj.id === customSelect.id) {
          return {
            ...obj,
            id: obj.id,
            customFields: [...obj.customFields, customValue],
          };
          // let myObj = { ...obj };
          // myObj.id = uuidv4();
          // myObj.customFields = [customValue];
          // return myObj;
        }
        return obj;
      });
      // console.log("newState", newState)
      setListItem(newState);
    }
    setKey("");
    setSelectValue("");
    setCustomSelect("");
  };

  const handleClick = () => {
    setListItem((oldArray) => [
      ...oldArray,
      {
        id: oldArray?.length + 1,
        title: value,
        customFields: [],
        is_fixed: isTrue,
      },
    ]);
    setValue("");
  };

  const handleCustomSelect = (value) => {
    const newState = listItem?.map((item) => {
      if (item.id === value.id) {
        setCustomId(item.id);
        return value;
      }
    });
    setCustomSelect(value);
  };

  const handleDelete = (index) => {
    setListItem(listItem.filter((item, i) => i !== index));
  };

  const handleDeleteCustom = (id) => {
    const newState = listItem.map((custom, i) => {
      let newcustom = custom.customFields.filter((items) => items.id !== id);
      return {
        ...custom,
        customFields: newcustom,
      };
    });
    // console.log("newState", newState)
    setListItem(newState);
  };

  const handleEdit = (index) => {
    if (listItem.filter((item, i) => i === index)) {
      setIndexValue(index);
      setIconChange(!iconChange);
    }
  };

  const handleEditCustom = (item) => {
    const newState = listItem?.map((custom, i) => {
      let newcustom = custom.customFields.map((items, i) => {
        if (items.id === item) {
          setIndexValue(item);
          setIconChangeCustom(!iconChangeCustom);
        }
      });
      return newcustom;
    });
    return newState;
  };
  const handleEditedValues = (item) => {
    const newState = listItem?.map((custom, i) => {
      let newcustom = custom.customFields.map((items, i) => {
        if (items.id === item.id) {
          (items.key = keyValues),
            (items.value = valuesKey),
            // setKey(keyValues)
            // setSelectValue(keyValues)
            setIndexValue(item.id);
          setIconChangeCustom(!iconChangeCustom);
        }
      });
      return newcustom;
    });
    return newState;
  };
  const handleListValue = (e, index) => {
    if (listItem.filter((item, i) => i === index)) {
      const newUsers = [...listItem];
      newUsers[index].title = e.target.value;
      setListItem(newUsers);
    }
  };

  const handleCustomKey = (e, index, items) => {
    setKeyValues(e.target.value);
  };
  const handleCustomValue = (e, index, items) => {
    setValuesKey(e.target.value);
  };

  const handleListFixed = (event, id, index) => {
    const fixedData = listItem.find((item) => item.id === id);
    if (event.target.checked === true) {
      setIsTrue((fixedData["is_fixed"] = true));
    } else {
      setIsTrue((fixedData["is_fixed"] = false));
    }
    // let customValue = {
    //   key: key,
    //   value: selectValue,
    //   id: customSelect.id,
    // };
    // const newState = listItem?.map((obj, i) => {
    //   if (obj.id === items) {
    //     return {
    //       ...obj,
    //       id: uuidv4(),
    //       customFields: [...obj.customFields, customValue],
    //     };
    //   }
    //   return obj;
    // });
    // setListItem(newState);
  };

  useEffect(() => {
    let localData = localStorage.getItem("dataListOfdata");
    let localParse = JSON.parse(localData);
    let add = localParse;
    setIds(add?.length + 1);
    if (newValues !== "" && newValues !== null && newValues !== undefined) {
      add.push(newValues);
      localStorage.setItem("dataListOfdata", JSON.stringify(add));
      // console.log('add', add)
      setTimeout(() => {
        navigate("/data-list");
      }, 1000);
    }
  }, [newValues]);

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    // listItem: yup.string().required("List item is required"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.id = ids;
      values.items = listItem;
      values.isFixed = isFixed;
      // submitForm(values, newValues)

      let localData = localStorage.getItem("dataListOfdata");
      let localParse = JSON.parse(localData);
      let add = localParse;
      const newState = localParse?.filter(
        (obj) => obj.title === values.title
      ).length;
      if (newState > 0) {
        toast.error(
          `List title "${values.title}" already exist. Please change the title.`
        );
      } else {
        // const newId = localParse?.filter((obj) => obj.id === values.id);
        // console.log('newId', newId);
        let myObj = values;
        setNewValues(myObj);
      }
      // if(newValues){
      //     add.push(newValues)
      // }

      // localStorage.setItem("dataListOfdata", JSON.stringify(add));
      // navigate('/data-list');
      // if (localParse) {
      //     const method = localParse?.map((val, i) => {

      //         if (val?.title == allData?.title) {
      //             return localParse[i] = allData;
      //         }
      //         return val;
      //     })
      //     localStorage.setItem("dataListOfdata", JSON.stringify(method));
      // }
      // else{
      //     toast.error(`List title "${values.title}" already exist. Please change the title.`);
      // }
      // if (values) {
      //     dispatch(AddDataListData(values))
      //     // navigate('/data-list');
      // }
    },
  });

  const submitForm = (values, newValues) => {
    let localData = localStorage.getItem("dataListOfdata");
    let localParse = JSON.parse(localData);
    let add = localParse;
    const newState = localParse?.map((obj, i) => {
      if (obj.title !== values.title) {
        setNewValues(values);
      } else {
        toast.error(
          `List title "${values.title}" already exist. Please change the title.`
        );
      }
      return obj;
    });
    // return newState
    if (newValues) {
      add.push(newValues);
    }

    // console.log('add', add)
    localStorage.setItem("dataListOfdata", JSON.stringify(add));
    // navigate('/data-list');
    // if (localParse) {
    //     const method = localParse?.map((val, i) => {

    //         if (val?.title == allData?.title) {
    //             return localParse[i] = allData;
    //         }
    //         return val;
    //     })
    //     localStorage.setItem("dataListOfdata", JSON.stringify(method));
    // }
    // else{
    //     toast.error(`List title "${values.title}" already exist. Please change the title.`);
    // }
    // if (values) {
    //     dispatch(AddDataListData(values))
    //     // navigate('/data-list');
    // }
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "8px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/data-list" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title*</label>
              <TextField
                id="outlined-basic"
                className="issueinput-fields"
                variant="outlined"
                size="small"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
              />
              <small className="error">
                {formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : null}
              </small>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Description</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="issueinput-fields"
                size="small"
                multiline
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </FormControl>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFixed}
                    onChange={(e) => setIsFixed(e.target.checked)}
                    size="small"
                  />
                }
                label="Make List fixed"
                sx={{ mb: 2 }}
              />
            </FormGroup>

            <FormControl fullWidth sx={{ mb: 2, px: 0 }}>
              <label>List Items*</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="issueinput-fields"
                size="small"
                multiline
                name="listItems"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        color="primary"
                        sx={{ margin: "-22px" }}
                        onClick={handleClick}
                      >
                        <Button
                          variant="contained"
                          sx={{ height: "40px", borderRadius: "8px" }}
                        >
                          <AddIcon />
                          Add
                        </Button>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <small className="error">
                {formik.errors.listItems && formik.touched.listItems
                  ? formik.errors.listItems
                  : null}
              </small>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Custom Fields</label>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12} sm={12} lg={4}>
                  <Select
                    size="small"
                    fullWidth
                    sx={{ borderRadius: "8px" }}
                    value={customSelect}
                    onChange={(e) => handleCustomSelect(e.target.value)}
                  >
                    <MenuItem value="">Select List Item</MenuItem>
                    {listItem &&
                      listItem.length > 0 &&
                      listItem?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item?.title}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item md={4} xs={12} sm={12} lg={4}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    placeholder="Key"
                    fullWidth
                    name="listKey"
                    onChange={(e) => {
                      setKey(e.target.value);
                    }}
                    value={key}
                  />
                </Grid>
                <Grid item md={4} xs={12} sm={12} lg={4}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    placeholder="Value"
                    fullWidth
                    name="listValue"
                    onChange={(e) => {
                      setSelectValue(e.target.value);
                    }}
                    // onBlur={formik.handleBlur}
                    value={selectValue}
                  />
                </Grid>
                <Grid item md={4} sx={12} sm={12} lg={1}>
                  <Button
                    variant="contained"
                    sx={{ height: "40px", borderRadius: "8px" }}
                    onClick={handleKeysValues}
                  >
                    <AddIcon />
                    Add
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
            {listItem.length > 0
              ? listItem.map((items, index) => {
                  return (
                    <Box className="list-itemsss" key={index}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item md={8} xs={12} sm={12} lg={9}>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              size="small"
                              className="user-header list-items-fields issueinput-fields"
                              placeholder="Value"
                              fullWidth
                              name="customList"
                              onChange={(e) => handleListValue(e, index, items)}
                              // defaultValue={items?.title}
                              disabled={
                                !iconChange && indexValue === index
                                  ? false
                                  : true
                              }
                              value={items?.title}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    {!iconChange && indexValue === index ? (
                                      <InputAdornment
                                        position="end"
                                        sx={{ marginRight: "-14px" }}
                                      >
                                        <ToggleButton
                                          value="phone"
                                          aria-label="phone"
                                          size="small"
                                          onClick={() => handleEdit(index)}
                                        >
                                          <CheckIcon color="warning" />
                                        </ToggleButton>

                                        <ToggleButton
                                          value="phone"
                                          aria-label="phone"
                                          size="small"
                                          onClick={() => handleEdit(index)}
                                        >
                                          <ClearIcon color="error" />
                                        </ToggleButton>
                                      </InputAdornment>
                                    ) : (
                                      <InputAdornment
                                        position="end"
                                        sx={{ marginRight: "-14px" }}
                                      >
                                        <ToggleButton
                                          value="phone"
                                          aria-label="phone"
                                          size="small"
                                          onClick={() => handleEdit(index)}
                                        >
                                          <BorderColorOutlinedIcon color="warning" />
                                        </ToggleButton>
                                        {items.is_fixed === true ? (
                                          ""
                                        ) : (
                                          <ToggleButton
                                            value="phone"
                                            aria-label="phone"
                                            size="small"
                                            onClick={() => {
                                              handleDelete(index);
                                            }}
                                          >
                                            <DeleteOutlinedIcon color="error" />
                                          </ToggleButton>
                                        )}
                                      </InputAdornment>
                                    )}
                                  </>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            xs={12}
                            sm={12}
                            lg={3}
                            className="item-center"
                          >
                            <label>Fixed</label>
                            <input
                              type="checkbox"
                              // checked={items.is_fixed}
                              value={isTrue}
                              onChange={(event) =>
                                handleListFixed(event, items.id, index)
                              }
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                      <Grid container spacing={5}>
                        <Grid item md={12} sx={12} sm={12} lg={12}>
                          {items && items?.customFields?.length > 0 ? (
                            items &&
                            items?.customFields &&
                            items?.customFields.length > 0 &&
                            items?.customFields.map((item, i) => {
                              return (
                                <Box key={i}>
                                  <FormControl fullWidth sx={{ mb: 2 }}>
                                    <Grid container spacing={1}>
                                      <Grid item md={6} sx={12} sm={12} lg={6}>
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          size="small"
                                          className="issueinput-fields"
                                          placeholder="Key"
                                          fullWidth
                                          name="customkey"
                                          key={item.key}
                                          onChange={(e) =>
                                            handleCustomKey(e, index, item)
                                          }
                                          value={keyValues.value}
                                          defaultValue={item.key}
                                          disabled={
                                            iconChangeCustom &&
                                            indexValue === item.id
                                              ? false
                                              : true
                                          }
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                key
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                      <Grid item md={6} sx={12} sm={12} lg={6}>
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          size="small"
                                          className="user-header issueinput-fields"
                                          placeholder="Value"
                                          fullWidth
                                          name="customvalue"
                                          key={item.value}
                                          onChange={(e) =>
                                            handleCustomValue(e, index, item)
                                          }
                                          value={valuesKey.value}
                                          defaultValue={item.value}
                                          disabled={
                                            iconChangeCustom &&
                                            indexValue === item.id
                                              ? false
                                              : true
                                          }
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                Value
                                              </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <>
                                                {iconChangeCustom &&
                                                indexValue === item.id ? (
                                                  <InputAdornment
                                                    position="end"
                                                    sx={{
                                                      marginRight: "-14px",
                                                    }}
                                                  >
                                                    <ToggleButton
                                                      value="phone"
                                                      aria-label="phone"
                                                      size="small"
                                                      onClick={() =>
                                                        handleEditedValues(item)
                                                      }
                                                    >
                                                      <CheckIcon color="warning" />
                                                    </ToggleButton>

                                                    <ToggleButton
                                                      value="phone"
                                                      aria-label="phone"
                                                      size="small"
                                                      onClick={() =>
                                                        handleEdit(index)
                                                      }
                                                    >
                                                      <ClearIcon color="error" />
                                                    </ToggleButton>
                                                  </InputAdornment>
                                                ) : (
                                                  <InputAdornment
                                                    position="end"
                                                    sx={{
                                                      marginRight: "-14px",
                                                    }}
                                                  >
                                                    <ToggleButton
                                                      value="phone"
                                                      aria-label="phone"
                                                      size="small"
                                                      onClick={() =>
                                                        handleEditCustom(
                                                          item.id
                                                        )
                                                      }
                                                    >
                                                      <BorderColorOutlinedIcon color="warning" />
                                                    </ToggleButton>
                                                    {isTrue !== true ? (
                                                      <ToggleButton
                                                        value="phone"
                                                        aria-label="phone"
                                                        size="small"
                                                        onClick={() =>
                                                          handleDeleteCustom(
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        <DeleteOutlinedIcon color="error" />
                                                      </ToggleButton>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </InputAdornment>
                                                )}
                                              </>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </Box>
                              );
                            })
                          ) : (
                            <label>
                              <small style={{ color: "grey" }}>
                                No Custom Fields
                              </small>
                            </label>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              : "No List Item"}
          </Container>
        </Box>
      </form>
    </Box>
  );
};

export default AddList;
