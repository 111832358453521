import JwtAuthService from "../apiConfig/ApiConfig";
import {
  LIST_WASTE_MANAGEMENT_REQUEST,
  LIST_WASTE_MANAGEMENT_SUCCESS,
  LIST_WASTE_MANAGEMENT_ERROR,
  VIEW_WASTE_MANAGEMENT_REQUEST,
  VIEW_WASTE_MANAGEMENT_SUCCESS,
  VIEW_WASTE_MANAGEMENT_ERROR,
  WASTE_PRODUCT_LIST_REQUEST,
  WASTE_PRODUCT_LIST_SUCCESS,
  WASTE_PRODUCT_LIST_ERROR,
  WASTE_CATEGORY_LIST_REQUEST,
  WASTE_CATEGORY_LIST_SUCCESS,
  WASTE_CATEGORY_LIST_ERROR,
  WASTE_UOM_LIST_REQUEST,
  WASTE_UOM_LIST_SUCCESS,
  WASTE_UOM_LIST_ERROR,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_SUCCESS,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_REQUEST,
  EDIT_CATEGORY_ERROR,
  ADD_UOM_SUCCESS,
  ADD_UOM_ERROR,
  ADD_UOM_REQUEST,
  EDIT_UOM_REQUEST,
  EDIT_UOM_SUCCESS,
  EDIT_UOM_ERROR,
  EXPORT_PRODUCT_REQUEST,
  EXPORT_PRODUCT_SUCCESS,
  EXPORT_PRODUCT_ERROR,
} from "../types/Types";
import { toast } from "react-toastify";

export const ListWasteRequest = () => ({
  type: LIST_WASTE_MANAGEMENT_REQUEST,
});

export const ListWasteSuccess = (companyid) => ({
  type: LIST_WASTE_MANAGEMENT_SUCCESS,
  payload: companyid,
});

export const ListWasteFailed = (error) => ({
  type: LIST_WASTE_MANAGEMENT_ERROR,
  payload: error,
});

export const ListWasteApi = (data) => (dispatch) => {
  try {
    dispatch(ListWasteRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ListWasteSuccess(data));
      } else {
        dispatch(ListWasteFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListWasteFailed(error));
  }
};

export const ViewWasteRequest = () => ({
  type: VIEW_WASTE_MANAGEMENT_REQUEST,
});

export const ViewWasteSuccess = (companyid) => ({
  type: VIEW_WASTE_MANAGEMENT_SUCCESS,
  payload: companyid,
});

export const ViewWasteFailed = (error) => ({
  type: VIEW_WASTE_MANAGEMENT_ERROR,
  payload: error,
});

export const ViewWasteApi = (data) => (dispatch) => {
  try {
    dispatch(ViewWasteRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ViewWasteSuccess(data));
      } else {
        dispatch(ViewWasteFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ViewWasteFailed(error));
  }
};

export const WasteProductListRequest = () => ({
  type: WASTE_PRODUCT_LIST_REQUEST,
});

export const WasteProductListSuccess = (companyid) => ({
  type: WASTE_PRODUCT_LIST_SUCCESS,
  payload: companyid,
});

export const WasteProductListFailed = (error) => ({
  type: WASTE_PRODUCT_LIST_ERROR,
  payload: error,
});

export const WasteProductListApi = (data) => (dispatch) => {
  try {
    dispatch(WasteProductListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(WasteProductListSuccess(data));
      } else {
        dispatch(WasteProductListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(WasteProductListFailed(error));
  }
};

export const WasteCategoryRequest = () => ({
  type: WASTE_CATEGORY_LIST_REQUEST,
});

export const WasteCategorySuccess = (companyid) => ({
  type: WASTE_CATEGORY_LIST_SUCCESS,
  payload: companyid,
});

export const WasteCategoryFailed = (error) => ({
  type: WASTE_CATEGORY_LIST_ERROR,
  payload: error,
});

export const WasteCategoryApi = (data) => (dispatch) => {
  try {
    dispatch(WasteCategoryRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(WasteCategorySuccess(data));
      } else {
        dispatch(WasteCategoryFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(WasteCategoryFailed(error));
  }
};

export const WasteUomListRequest = () => ({
  type: WASTE_UOM_LIST_REQUEST,
});

export const WasteUomListSuccess = (companyid) => ({
  type: WASTE_UOM_LIST_SUCCESS,
  payload: companyid,
});

export const WasteUomListFailed = (error) => ({
  type: WASTE_UOM_LIST_ERROR,
  payload: error,
});

export const WasteUomListApi = (data) => (dispatch) => {
  try {
    dispatch(WasteUomListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(WasteUomListSuccess(data));
      } else {
        dispatch(WasteUomListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(WasteUomListFailed(error));
  }
};

export const AddProductRequest = () => ({
  type: ADD_PRODUCT_REQUEST,
});

export const AddProductSuccess = (data) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: data,
});

export const AddProductFailed = (error) => ({
  type: ADD_PRODUCT_ERROR,
  payload: error,
});

export const AddProductApi = (data) => (dispatch) => {
  try {
    dispatch(AddProductRequest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddProductSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(AddProductFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddProductFailed(error));
  }
};

export const EditProductRequest = () => ({
  type: EDIT_PRODUCT_REQUEST,
});

export const EditProductSuccess = (data) => ({
  type: EDIT_PRODUCT_SUCCESS,
  payload: data,
});

export const EditProductFailed = (error) => ({
  type: EDIT_PRODUCT_ERROR,
  payload: error,
});

export const EditProductApi = (data) => (dispatch) => {
  try {
    dispatch(EditProductRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(EditProductSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(EditProductFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(EditProductFailed(error));
  }
};

export const AddCategoryRequest = () => ({
  type: ADD_CATEGORY_REQUEST,
});

export const AddCategorySuccess = (data) => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const AddCategoryFailed = (error) => ({
  type: ADD_CATEGORY_ERROR,
  payload: error,
});

export const AddCategoryApi = (data) => (dispatch) => {
  try {
    dispatch(AddCategoryRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddCategorySuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(AddCategoryFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddCategoryFailed(error));
  }
};

export const EditCategoryRequest = () => ({
  type: EDIT_CATEGORY_REQUEST,
});

export const EditCategorySuccess = (data) => ({
  type: EDIT_CATEGORY_SUCCESS,
  payload: data,
});

export const EditCategoryFailed = (error) => ({
  type: EDIT_CATEGORY_ERROR,
  payload: error,
});

export const EditCategoryApi = (data) => (dispatch) => {
  try {
    dispatch(EditCategoryRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(EditCategorySuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(EditCategoryFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(EditCategoryFailed(error));
  }
};

export const AddUomRequest = () => ({
  type: ADD_UOM_REQUEST,
});

export const AddUomSuccess = (data) => ({
  type: ADD_UOM_SUCCESS,
  payload: data,
});

export const AddUomFailed = (error) => ({
  type: ADD_UOM_ERROR,
  payload: error,
});

export const AddUomApi = (data) => (dispatch) => {
  try {
    dispatch(AddUomRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(AddUomSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(AddUomFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddUomFailed(error));
  }
};

export const EditUomRequest = () => ({
  type: EDIT_UOM_REQUEST,
});

export const EditUomSuccess = (data) => ({
  type: EDIT_UOM_SUCCESS,
  payload: data,
});

export const EditUomFailed = (error) => ({
  type: EDIT_UOM_ERROR,
  payload: error,
});

export const EditUomApi = (data) => (dispatch) => {
  try {
    dispatch(EditUomRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(EditUomSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(EditUomFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(EditUomFailed(error));
  }
};

export const ExportProductRequest = () => ({
  type: EXPORT_PRODUCT_REQUEST,
});

export const ExportProductSuccess = (data) => ({
  type: EXPORT_PRODUCT_SUCCESS,
  payload: data,
});

export const ExportProductFailed = (error) => ({
  type: EXPORT_PRODUCT_ERROR,
  payload: error,
});

export const ExportProductApi = (data) => (dispatch) => {
  try {
    dispatch(ExportProductRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ExportProductSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(ExportProductFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ExportProductFailed(error));
  }
};
