import { Card, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

function createData(
  Store,
  ChecklistCount,
) {
  return {
    Store,
    ChecklistCount,
  };
}

const rows1 = [
  createData(
    "Digital Retail Ltd",
    "810",
  ),
  createData(
    "Digital Retail Ltd",
    "810",
  ),
  createData(
    "Digital Retail Ltd",
    "810",
  ),
  createData(
    "Digital Retail Ltd",
    "810",
  ),
  createData(
    "Digital Retail Ltd",
    "810",
  )
];

const OPTable = (props) => {
  console.log("props", props);
  return (
    <div>
      <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
        <CardContent sx={{ p: 4 }} className="check-graph">
          <Typography variant="h6" component="p" align="left" sx={{ ml: 1, fontWeight: 'bold' }}>Checklist Submission Details</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <TableContainer component={Paper} className="table-container">
              <Table size="large" aria-label="simple table" className="grid-table">
                <TableHead sx={{ m: 4 }}>
                  <TableRow>
                    <TableCell align="center">Submission Date</TableCell>
                    <TableCell align="center">Submission Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows1.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {row.Store}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="p" component="p">
                          {row.ChecklistCount}
                        </Typography>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default OPTable
