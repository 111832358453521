import {
  INTENT_CSV_NAM_SUBMISSION_ERROR,
  INTENT_CSV_NAM_SUBMISSION_REQUEST,
  INTENT_CSV_NAM_SUBMISSION_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  intentCsvNamSubmission: "",
};

const IntentCsvNamSubmissionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTENT_CSV_NAM_SUBMISSION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case INTENT_CSV_NAM_SUBMISSION_SUCCESS: {
      return {
        ...state,
        intentCsvNamSubmission: action?.payload,
        loading: false,
      };
    }
    case INTENT_CSV_NAM_SUBMISSION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default IntentCsvNamSubmissionDataReducer;
