import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import { CUSTOMER_LOG_NO_PAGI_ERROR, CUSTOMER_LOG_NO_PAGI_REQUEST, CUSTOMER_LOG_NO_PAGI_SUCCESS, CUSTOMER_LOG_STATUS_ERROR, CUSTOMER_LOG_STATUS_REQUEST, CUSTOMER_LOG_STATUS_SUCCESS, CUSTOMER_LOG_VIEW_ERROR, CUSTOMER_LOG_VIEW_REQUEST, CUSTOMER_LOG_VIEW_SUCCESS, DELETE_CUSTOMER_LOG_ERROR, DELETE_CUSTOMER_LOG_REQUEST, DELETE_CUSTOMER_LOG_SUCCESS, LIST_CUSTOMER_LOG_ERROR, LIST_CUSTOMER_LOG_REQUEST, LIST_CUSTOMER_LOG_SUCCESS } from "../types/Types";


// customer log listing started 
export const ListCustomerLog = () => ({
    type: LIST_CUSTOMER_LOG_REQUEST,
});

export const ListCustomerLogSuccess = (companyid) => ({
    type: LIST_CUSTOMER_LOG_SUCCESS,
    payload: companyid
})

export const ListCustomerLogFailed = (error) => ({
    type: LIST_CUSTOMER_LOG_ERROR,
    payload: error
})

export const ListCustomerLogApi = (data) => (dispatch) => {
    try {
        dispatch(ListCustomerLog(data));
        JwtAuthService.PostApiService(data).then((response) => {
            if (response?.status === 200) {
                const customerLogActionRes = response;
                dispatch(ListCustomerLogSuccess(customerLogActionRes))
            } else {
                dispatch(ListCustomerLogFailed(response?.data?.message))
                toast.error(`${response?.data?.message}`);
            }
        });
    } catch (error) {
        dispatch(ListCustomerLogFailed(error))
        console.error("Something went Wrong", error);
        toast.error(error);
    }
}
// customer log listing ended 



// customer log view started
export const ViewCustomerLog = () => ({
    type: CUSTOMER_LOG_VIEW_REQUEST,
});

export const ViewCustomerLogSuccess = (companyid) => ({
    type: CUSTOMER_LOG_VIEW_SUCCESS,
    payload: companyid
})

export const ViewCustomerLogFailed = (error) => ({
    type: CUSTOMER_LOG_VIEW_ERROR,
    payload: error
})

export const ViewCustomerLogApi = (data) => (dispatch) => {
    try {
        dispatch(ViewCustomerLog(data));
        JwtAuthService.GetApiService(data).then((response) => {
            if (response?.status === 200) {
                const details = response;
                dispatch(ViewCustomerLogSuccess(details))
            } else {
                dispatch(ViewCustomerLogFailed(response?.statusText))
            }
        });
    } catch (error) {
        dispatch(ViewCustomerLogFailed(error))
    }
}
// customer log view ended


// customer log delete started 
export const DeleteCustomerLog = () => ({
    type: DELETE_CUSTOMER_LOG_REQUEST,
});

export const DeleteCustomerLogSuccess = (companyid) => ({
    type: DELETE_CUSTOMER_LOG_SUCCESS,
    payload: companyid
})

export const DeleteCustomerLogFailed = (error) => ({
    type: DELETE_CUSTOMER_LOG_ERROR,
    payload: error
})

export const DeleteCustomerLogApi = (data) => (dispatch) => {
    try {
        dispatch(DeleteCustomerLog(data));
        JwtAuthService.DeleteApiService(data).then((response) => {
            if (response?.status === 200) {
                const customerLogDeleteActionRes = response;
                dispatch(DeleteCustomerLogSuccess(customerLogDeleteActionRes));
                toast.success(`${response?.data?.message}`);
            } else {
                dispatch(DeleteCustomerLogFailed(response?.statusText));
                toast.error(`${response?.data?.message}`);
            }
        });
    } catch (error) {
        dispatch(DeleteCustomerLogFailed(error));
        console.error("Something went Wrong", error);
        toast.error(error);
    }
}
// customer log delete ended 

// customer log listing status dropdown started 
export const CustomerLogStatus = () => ({
    type: CUSTOMER_LOG_STATUS_REQUEST,
});

export const CustomerLogStatusSuccess = (companyid) => ({
    type: CUSTOMER_LOG_STATUS_SUCCESS,
    payload: companyid
})

export const CustomerLogStatusFailed = (error) => ({
    type: CUSTOMER_LOG_STATUS_ERROR,
    payload: error
})

export const CustomerLogStatusApi = (data) => (dispatch) => {
    try {
        dispatch(CustomerLogStatus(data));
        JwtAuthService.PutApiService(data).then((response) => {
            if (response?.status === 200) {
                const customerLogStatus = response;
                dispatch(CustomerLogStatusSuccess(customerLogStatus));
                toast.success(`${response?.data?.message}`);
            } else {
                dispatch(CustomerLogStatusFailed(response.response.data.message));
                toast.error(`${response.response.data.message}`);
            }
        });
    } catch (error) {
        dispatch(CustomerLogStatusFailed(error))
        console.error("Something went Wrong", error);
        toast.error(error);
    }
}
// customer log listing status dropdown ended 


// customer log listing without pagination api started 
export const CustomerLogNoPagination = () => ({
    type: CUSTOMER_LOG_NO_PAGI_REQUEST,
});

export const CustomerLogNoPaginationSuccess = (companyid) => ({
    type: CUSTOMER_LOG_NO_PAGI_SUCCESS,
    payload: companyid
})

export const CustomerLogNoPaginationFailed = (error) => ({
    type: CUSTOMER_LOG_NO_PAGI_ERROR,
    payload: error
})

export const CustomerLogNoPaginationApi = (data) => (dispatch) => {
    try {
        dispatch(CustomerLogNoPagination(data));
        JwtAuthService.PostApiService(data).then((response) => {
            if (response?.status === 200) {
                const customerLogNoPagiList = response;
                dispatch(CustomerLogNoPaginationSuccess(customerLogNoPagiList))
            } else {
                dispatch(CustomerLogNoPaginationFailed(response?.statusText))
            }
        });
    } catch (error) {
        dispatch(ListCustomerLogFailed(error))
    }
}
// customer log listing without pagination api ended 


