/** @format */

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import throttle from "lodash/throttle";
import {
  AddBroadcastApi,
  ListBroadcastApi,
} from "../../redux/actions/BroadcastMessage";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { ListSubroleApi } from "../../redux/actions/Roles";
import Header from "./Header";

import { Grid, Typography, Divider, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import AddMessageHeader from "./AddMessageHeader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddMessage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [broadcastData, setBroadcastData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [subRoleData, setsubRoleData] = useState([]);
  const [addMessageData, setAddMessageData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [editorState, setEditorState] = useState("");
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [errorShown, setErrorShown] = useState(false);
  const [messageLength, setMessageLength] = useState(0);

  // const formik = useFormik({
  //   initialValues: {
  //     subject: "",
  //     message: "",
  //     role: "",
  //   },
  //   onSubmit: (values) => {
  //     const payload = {
  //       role: selectedValues,
  //       subject: values.subject,
  //       message: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
  //     };
  //     const allData = {
  //       body: payload,
  //     };
  //     console.log("allData", allData);
  //     if (allData) {
  //       const id = localStorage.getItem("companyId");
  //       const apiData = {
  //         url: BASE_URL + `broadcast?company_id=${id}`,
  //         body: payload,
  //       };
  //       dispatch(AddBroadcastApi(apiData));
  //     }
  //   },
  // });

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
      role: "",
    },
    onSubmit: async (values) => {
      const contentState = ContentState.createFromText(editorState);
      const payload = {
        role: selectedValues,
        subject: values.subject,
        // message: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        message: JSON.stringify(
          convertToRaw(
            EditorState.createWithContent(contentState).getCurrentContent()
          )
        ),
      };
      const allData = {
        body: payload,
      };
      // return;
      const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
      if (unprivilegedEditor.getLength() > 201) {
        toast.error("Maximum character limit is 200");
        return;
      }
      if (allData) {
        const id = localStorage.getItem("companyId");
        const apiData = {
          url: BASE_URL + `broadcast?company_id=${id}`,
          body: payload,
        };
        try {
          await dispatch(AddBroadcastApi(apiData));
          navigate("/broadcastMessage");
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const addBroadCastSelector = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.addMsg &&
      state.broadcast.addMsg.data &&
      state.broadcast.addMsg.data.data
  );

  const broadcastList = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.data &&
      state.broadcast.data.data &&
      state.broadcast.data.data.data &&
      state.broadcast.data.data.data.broadcast
  );

  const subroleData = useSelector(
    (state) =>
      state.roles &&
      state.roles.subrole &&
      state.roles.subrole.data &&
      state.roles.subrole.data.data
  );

  useEffect(() => {
    if (addBroadCastSelector) {
      setAddMessageData(addBroadCastSelector);
    }
  }, [addBroadCastSelector]);

  useEffect(() => {
    if (subroleData) {
      setsubRoleData(subroleData);
    }
  }, [subroleData]);

  useEffect(() => {
    setBroadcastData(broadcastList);
  }, [broadcastList]);

  useEffect(() => {
    listBroadcast();
  }, [dispatch]);

  useEffect(() => {
    SubRoleListApi();
  }, []);

  const data = {
    pagination: {
      page: page,
      per_page: rowsPerPage,
    },
    // sort: {
    //   sort_as: "updatedAt",
    // },
    // archived: false,
  };

  const SubRoleListApi = () => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  };

  const listBroadcast = () => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `broadcast/index?company_id=${id}`;
    dispatch(ListBroadcastApi(data));
  };

  const handleDeleteSelected = (value) => {
    setSelectedValues((prevSelected) =>
      prevSelected.filter((selected) => selected !== value)
    );
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    // setIsSendDisabled(
    //   selectedValue.length === 0 || formik.values.subject.trim() === ""
    // );
    console.log("selectedValue", selectedValue);
    if (selectedValue[selectedValue.length - 1] === "select-all") {
      handleSelectAll();
      return;
    }
    setSelectedValues(selectedValue);
  };

  const handleDisable = () => {
    if (
      selectedValues.length === 0 ||
      formik.values.subject.trim() === "" ||
      formik.values.message.trim() === ""
    ) {
      return true;
    }
    return false;
  };

  const handleSelectAll = () => {
    if (selectedValues.length === subRoleData.length) {
      setSelectedValues([]);
    } else {
      const allValues = subRoleData.map((item) => item.name);
      setSelectedValues(allValues);
    }
  };
  const reactQuillRef = React.useRef();

  const handleQuillChange = (value) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    setMessageLength(unprivilegedEditor.getLength() - 1);
    // if (unprivilegedEditor.getLength() <= 201) {
    const contentState = ContentState.createFromText(value);
    setEditorState(value);
    formik.setFieldValue("message", value);
    // const contentState = ContentState.createFromText(value);
    // setEditorState(EditorState.createWithContent(contentState));
    // formik.setFieldValue(
    //   "message",
    //   JSON.stringify(convertToRaw(contentState))
    // );
    // setErrorShown(false);
    // } else {
    // if (!errorShown) {
    // toast.error("Maximum character limit is 200");
    // setErrorShown(true);
    // }

    // const truncatedContent = value.slice(0, 199);
    // setEditorState(
    //   EditorState.createWithContent(
    //     ContentState.createFromText(truncatedContent)
    //   )
    // );
    // }
  };

  return (
    <>
      <AddMessageHeader />
      <ToastContainer position="bottom-right" autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ height: "auto", mt: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                <span role="img" aria-label="speaker">
                  📢
                </span>{" "}
                Broadcast Message
              </Typography>
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="left">
                Select Sub-Role
              </Typography>
              <FormControl sx={{ minWidth: 300, marginTop: 2 }}>
                {/* <InputLabel shrink={false}>Select Sub-Role</InputLabel> */}
                <Select
                  multiple
                  value={selectedValues}
                  onChange={handleSelectChange}
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          style={{ margin: 2 }}
                          onDelete={() => handleDeleteSelected(value)}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    classes: {
                      paper: "custom-menu-paper",
                    },
                    MenuListProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  classes={{
                    select: "custom-select",
                  }}
                >
                  <MenuItem key="select-all" value="select-all">
                    <Checkbox
                      checked={selectedValues.length === subRoleData.length}
                      indeterminate={
                        selectedValues.length > 0 &&
                        selectedValues.length < subRoleData.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {subRoleData.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      <Checkbox
                        checked={selectedValues.indexOf(item.name) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} alignItems="flex-start">
              <Typography variant="subtitle1" align="left">
                Subject
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Subject"
                name="subject"
                value={formik.values.subject}
                // onChange={formik.handleChange}
                onChange={(event) => {
                  formik.handleChange(event);
                  // setIsSendDisabled(
                  //   selectedValues.length === 0 ||
                  //     event.target.value.trim() === ""
                  // );
                }}
              />
            </Grid>
            {/* <Grid item xs={12} alignItems="flex-start">
              <Typography variant="subtitle1" align="left">
                Message
              </Typography>
              <Editor
                editorStyle={{
                  border: "1px solid #ddd",
                  minHeight: "100px",
                  padding: "10px",
                }}
                placeholder="Enter content here..."
                name="message"
                value={formik.values.message}
                onChange={(event, editor) =>
                  editor && formik.setFieldValue("message", editor.getData())
                }
                editorState={editorState}
                onEditorStateChange={(editorState) => {
                  setEditorState(editorState);
                  formik.setFieldValue(
                    "message",
                    JSON.stringify(
                      convertToRaw(editorState.getCurrentContent())
                    )
                  );
                }}
              />
            </Grid> */}
            <Grid item xs={12} alignItems="flex-start">
              <Typography variant="subtitle1" align="left">
                Message
              </Typography>
              {/* <ReactQuill
                className="quill-editor" // Add any custom class for styling if needed
                value={
                  editorState && editorState.getCurrentContent().getPlainText()
                } // Get plain text from EditorState
                onChange={(value) => {
                  const contentState = ContentState.createFromText(value); // Create ContentState from plain text
                  setEditorState(EditorState.createWithContent(contentState)); // Convert ContentState to EditorState
                  formik.setFieldValue(
                    "message",
                    JSON.stringify(convertToRaw(contentState))
                  );
                }}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ color: [] }, { background: [] }],
                    [{ script: "sub" }, { script: "super" }],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "align",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "color",
                  "background",
                  "script",
                  "blockquote",
                  "code-block",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                ]}
                placeholder="Enter content here..."
              /> */}
              <ReactQuill
                className="quill-editor"
                value={
                  // editorState && editorState.getCurrentContent().getPlainText()
                  editorState
                }
                ref={reactQuillRef}
                onChange={(value) => handleQuillChange(value)}
                le
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ color: [] }, { background: [] }],
                    [{ script: "sub" }, { script: "super" }],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    ["link"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "font",
                  "align",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "color",
                  "background",
                  "script",
                  "blockquote",
                  "code-block",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                ]}
                placeholder="Enter content here..."
              />
              <Box
                sx={{
                  justifyContent: "right",
                  fontSize: "12px",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    color: messageLength > 200 ? "red" : "",
                    fontSize: "12px",
                  }}
                >
                  {messageLength}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} justifyContent="flex-start" align="left">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SendIcon />}
                disabled={handleDisable()}
                // disabled={isSendDisabled}
              >
                Send
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" align="center" color="textSecondary">
                Copyright © HOPE RESEARCH GROUP. All Right Reserved.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
};

export default AddMessage;
