/** @format */

import React, { useState, useEffect } from "react";
import Header from "./pages/Header";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  TableFooter,
  Card,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteRolesApi,
  ListRolesApi,
  ListSubroleApi,
  RoleDetailsApi,
} from "../redux/actions/Roles";
import EditRoleModel from "./pages/EditRoleModel";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import View from "../images/View.svg";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import MappingModal from "./pages/MappingModal";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Progressbar from "../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ToastContainer } from "react-toastify";

const Roles = ({ title }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roleData, setRoleData] = useState([]);
  const [subroleList, setSubroleList] = useState([]);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [editvalue, setEditvalue] = useState({});
  const [mapValue, setMapValue] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });
  const [permission, setPermission] = useState({});

  const roleList = useSelector(
    (state) =>
      state.roles &&
      state.roles.data &&
      state.roles.data.data &&
      state.roles.data.data.data &&
      state.roles.data.data.data.Role
  );

  const uirender = useSelector(
    (state) => state.roles && state.roles.res && state.roles.res.status
  );

  const totalitems = useSelector(
    (state) =>
      state.roles &&
      state.roles.data &&
      state.roles.data.data &&
      state.roles.data.data.data &&
      state.roles.data.data.data.paginate
  );

  const subroleData = useSelector(
    (state) =>
      state.roles &&
      state.roles.subrole &&
      state.roles.subrole.data &&
      state.roles.subrole.data.data
  );

  const createRolesRes = useSelector(
    (state) =>
      state?.roles &&
      state?.roles?.created &&
      state?.roles?.created?.data &&
      state?.roles?.created?.data?.data
  );

  const editRolesRes = useSelector(
    (state) =>
      state?.roles &&
      state?.roles?.res &&
      state?.roles?.res?.data &&
      state?.roles?.res?.data?.data
  );

  const deleteRolesRes = useSelector(
    (state) =>
      state?.roles &&
      state?.roles?.deleteRole &&
      state?.roles?.deleteRole?.data &&
      state?.roles?.deleteRole?.data?.data
  );

  const Loader = useSelector((state) => state.roles && state.roles.loading);

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);
  useEffect(() => {
    if (searchValue == "") {
      var localPageno = localStorage.getItem("pageno");
      payloadRequest.body.pagination.page = localPageno
        ? parseInt(localPageno)
        : 1;
      setLoader(true);
      listRole(payloadRequest);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [searchValue]);

  useEffect(() => {
    listRole(payloadRequest);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    listRole(payloadRequest);
  }, [createRolesRes, editRolesRes, deleteRolesRes]);

  useEffect(() => {
    setRoleData(roleList);
  }, [roleList]);

  useEffect(() => {
    setSubroleList(subroleData);
  }, [subroleData]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoader(true);
      payloadRequest.body.search = searchValue;
      payloadRequest.body.pagination.page = 1;
      setPage(1);
      listRole(payloadRequest);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
      // localStorage.removeItem("pageno");
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    // setPage(newPage + 1);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    payloadRequest.body.search = searchValue;
    payloadRequest.body.pagination.page = newPage + 1;
    listRole(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.search = searchValue;
    payloadRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.pagination.page = 1;
    listRole(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const listRole = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `ShowSubrole?company_id=${id}`;
    dispatch(ListRolesApi(payloadRequest));
  };

  const handleEdit = (row) => {
    setEditvalue(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMapping = (row) => {
    const id = localStorage.getItem("companyId");
    const userRoleId = row?._id;
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    const detailsData = {
      url: BASE_URL + `detailsubrole/${userRoleId}?company_id=${id}`,
    };
    dispatch(RoleDetailsApi(detailsData));
    dispatch(ListSubroleApi(data));
    setMapValue(row);
    setOpens(true);
  };

  const handleCloseMap = () => {
    setOpens(false);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this role.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const data = {
              url: BASE_URL + `Deletesubrole/${id}?company_id=${_id}`,
            };
            setLoader(true);
            dispatch(DeleteRolesApi(data));
            setTimeout(() => {
              listRole(payloadRequest);
              setLoader(false);
            }, 500);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  // DataGrid Functionality start

  const columns = [
  {
    field: "Sno",
    headerName: "S.no",
    renderHeader: () => <strong>{"S.no"}</strong>,
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      const index = roleData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
      return index;
    },
  },
  
    {
      field: "Name",
      headerName: "Name",
      renderHeader: () => <strong>{"Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.name) {
          result.push(params?.row?.name);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Rank",
      headerName: "Rank",
      renderHeader: () => <strong>{"Rank"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.rank) {
          result.push(params?.row?.rank);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Email",
      headerName: "Email",
      renderHeader: () => <strong>{"Email"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.email) {
          result.push(params?.row?.email);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      renderHeader: () => <strong>{"Mobile"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.mobile) {
          result.push(params?.row?.mobile);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      renderHeader: () => <strong>{"Created At"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let timeFormate = moment(params?.row?.createdAt).format("LTS");
        let dateFormate = moment(params?.row?.createdAt).format("MMMM D, YYYY");
        let result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Updated At",
      headerName: "Updated At",
      align: "center",
      renderHeader: () => <strong>{"Updated At"}</strong>,

      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let timeFormate = moment(params?.row?.createdAt).format("LTS");
        let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
        let result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {permission?.roles?.manageRole === true ? (
              <>
                <Tooltip title="View">
                  <img
                    src={
                      hover.View && indexHover === params?.row?._id
                        ? ViewHover
                        : View
                    }
                    alt="VIEW"
                    style={{
                      height: "35px",
                      cursor: "pointer",
                      margin: "0px 3px 0px 3px",
                    }}
                    onClick={() => handleMapping(params?.row)}
                    onMouseOver={() => {
                      setHover({ View: true });
                      setIndexHover(params?.row?._id);
                    }}
                    onMouseLeave={() => {
                      setHover({ View: false });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Edit">
                  <img
                    onClick={() => handleEdit(params?.row)}
                    src={
                      hover.Edit && indexHover === params?.row?._id
                        ? EditHover
                        : Edit
                    }
                    alt="Edit"
                    style={{
                      height: "35px",
                      cursor: "pointer",
                      margin: "0px 3px 0px 3px",
                    }}
                    onMouseOver={() => {
                      setHover({ Edit: true });
                      setIndexHover(params?.row?._id);
                    }}
                    onMouseLeave={() => {
                      setHover({ Edit: false });
                    }}
                  />
                </Tooltip>
                {/* <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                /> */}
              </>
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <ToastContainer autoClose={3000} />
        <Header
          data={payloadRequest}
          searchOnChange={handleSearch}
          searchValue={searchValue}
          handleKeyDown={handleKeyDown}
          permission={permission}
        />
        {/* <Box
        sx={{
          bgcolor: "#F6F8FC",
          mt: 1,
          p: 1,
          borderRadius: "8px",
          height: "690px",
          overflowX: "auto",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : roleData && roleData?.length > 0 ? (
          <>
            <DataGrid
              style={{ height: "675px" }}
              disableColumnMenu
              rows={roleData}
              columns={columns}
              getRowId={(roleData) => Math.random()}
              getRowHeight={() => "auto"}
              hideFooter={true}
              hideFooterPagination={true}
              className="grid-table"
            />
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: totalitems?.total_item },
              ]}
              count={totalitems?.total_item}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: { "aria-label": "Items per Page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              ActionsComponent={TablePaginationActions}
            />
          </>
        ) : (
          "No Data Found"
        )}
      </Box> */}

        <SingleGlobalTable
          data={roleData}
          columns={columns}
          totalCount={totalitems?.total_item}
          loading={loader}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />

        <EditRoleModel
          open={open}
          handleClose={handleClose}
          editvalue={editvalue}
        />
        <MappingModal
          open={opens}
          handleClose={handleCloseMap}
          mapValue={mapValue}
          roleData={subroleList}
        />
      </div>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default Roles;
