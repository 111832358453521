/** @format */

import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid, TablePagination, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import User from "../../../assets/icons/svg-icons/User.svg";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHeader from "./pages/TableHeader";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import { confirmAlert } from "react-confirm-alert";
import {
  AssessmentListApi,
  AssessmentDeleteApi,
  AssessmentArchivedApi,
} from "../../redux/actions/LmsAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { assessmentQustionEditData } from "../../redux/actions/AllformStore";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import { ToastContainer } from "react-toastify";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const AssessmentList = () => {
  const assessmentList = useSelector(
    (state) =>
      state.lms &&
      state.lms.assessmentlist &&
      state.lms.assessmentlist.data &&
      state.lms.assessmentlist.data.data &&
      state.lms.assessmentlist.data.data.assessments
  );

  const totalpage = useSelector(
    (state) =>
      state.lms &&
      state.lms.assessmentlist.data &&
      state.lms.assessmentlist.data.data &&
      state.lms.assessmentlist.data.data.paginate
  );

  const ArchivedListTotalItems = useSelector(
    (state) =>
      state.lms &&
      state.lms?.assessmentListArchived &&
      state.lms?.assessmentListArchived?.data &&
      state.lms?.assessmentListArchived?.data?.data &&
      state.lms?.assessmentListArchived?.data?.data?.paginate
  );

  const ArchivedListData = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms?.assessmentListArchived &&
      state.lms?.assessmentListArchived?.data &&
      state.lms?.assessmentListArchived?.data?.data &&
      state.lms?.assessmentListArchived?.data?.data?.assessment
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [assessmentData, setAssessmentData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [localPageNo, setLocalPageNo] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [permission, setPermission] = useState({});

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("assestsQustions");
    localStorage.removeItem("assesstQustionEditData");
    localStorage.removeItem("editquestionasses");
    localStorage.removeItem("addAssesmentFields");
    dispatch(assessmentQustionEditData(null, null));
    getApi(paginationRequest);
  }, []);
  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      archived: false,
      search: "",
    },
  };
  const handleEditForm = (id) => {
    if (id) {
      navigate(`/assessment/edit`, {
        state: { id: id, assesmentKey: "assesmentKey" },
      });
    }
  };

  const ArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `Archivedassessment?company_id=${id}`;
    dispatch(AssessmentArchivedApi(paginationRequest));
  };

  useEffect(() => {
    if (searchValue == "") {
      paginationRequest.body.search = searchValue;
      ArchivedApi(paginationRequest);
      getApi(paginationRequest);
    }
  }, [searchValue]);

  useEffect(() => {
    var localPageno = localStorage.getItem("pageno");
    paginationRequest.body.pagination.page = localPageno
      ? parseInt(localPageno)
      : 1;
    getApi(paginationRequest);
  }, []);

  useEffect(() => {
    // setLoader(true);
    setAssessmentData(assessmentList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [assessmentList]);

  useEffect(() => {
    setTotalCount(totalpage?.total_item);
  }, [totalpage]);

  const getApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `assessment/index?company_id=${id}`;
    dispatch(AssessmentListApi(paginationRequest));
  };

  useEffect(() => {
    if (checkedArchive) {
      setAssessmentData(ArchivedListData);
    } else if (assessmentList) {
      setAssessmentData(assessmentList);
    }
  }, [assessmentList, ArchivedListData, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(ArchivedListTotalItems?.total_item);
    } else {
      setTotalCount(totalpage?.total_item);
    }
  }, [ArchivedListTotalItems, totalpage, checkedArchive]);

  //table pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChange = (event) => {
    paginationRequest.body.search = searchValue;
    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (checkedArchive === false) {
        setLoader(true);
        paginationRequest.body.pagination.page = 1;
        paginationRequest.body.search = searchValue;
        getApi(paginationRequest);
        setPage(1);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      } else {
        paginationRequest.body.search = searchValue;
        paginationRequest.body.pagination.page = 1;
        ArchivedApi(paginationRequest);
        setPage(1);
      }
    }
  };

  const handleClick = () => {
    navigate("/assessment/edit-assessment");
  };

  const handleDelete = (assessid) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const assessId = assessid;
            const data = {
              url:
                BASE_URL +
                `assessment/${assessId}?force=false&company_id=${id}`,
            };
            setLoader(true);
            dispatch(AssessmentDeleteApi(data));
            setTimeout(() => {
              getApi(paginationRequest);
              setLoader(false);
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          assessmentData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Title",
      headerName: "Title",
      renderHeader: () => <strong>{"Title"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short">{results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => <strong>{"Description"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        let result = [];
        if (params?.row?.summary) {
          result.push(params?.row?.summary);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short">{results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Created",
      headerName: "Created",
      renderHeader: () => <strong>{"Created"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.createdAt).format("MMMM D, YYYY");
        let result = [];
        if (params?.row?.createdAt && dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Questions",
      headerName: "Questions",
      renderHeader: () => <strong>{"Questions"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.questions) {
          result.push(
            params?.row?.questions?.length > 0
              ? params?.row?.questions?.length
              : 0
          );
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            {permission?.lms?.assgiments === true ? (
              <>
              <Tooltip title="Edit">
                <img
                  onClick={(e) => handleEditForm(params?.row?._id)}
                  src={
                    hover.Edit && indexHover === params?.row?._id
                      ? EditHover
                      : Edit
                  }
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Edit: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Edit: false });
                  }}
                />
                </Tooltip>
                <Tooltip title="Delete">
                <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
                </Tooltip>
              </>
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    },
  ];

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    paginationRequest.body.pagination.page = newPage + 1;
    paginationRequest.body.search = searchValue;

    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    paginationRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    if (checkedArchive === false) {
      getApi(paginationRequest);
    } else {
      ArchivedApi(paginationRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  return (
    <Box>
      <ToastContainer />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <>
          <TableHeader
            handleChange={handleChange}
            totalitems={totalpage?.total_item}
            setCheckedArchive={setCheckedArchive}
            checkedArchive={checkedArchive}
            handleKeyDown={handleKeyDown}
            searchOnChange={searchOnChange}
            searchValue={searchValue}
            // ArchivedApi={ArchivedApi}
            rowsPerPage={rowsPerPage}
            page={page}
            totalCount={totalCount}
            totalpage={totalpage}
            ArchivedListTotalItems={ArchivedListTotalItems?.total_item}
            setPage={setPage}
            permission={permission}
          />
          <SingleGlobalTable
            data={assessmentData}
            columns={columns}
            totalCount={totalCount}
            loading={loader}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          {/* <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "calc(100vh - 220px)",
              mt: 1,
              p: 1,
              overflowX: "auto",
              overflowY: "scroll",
              borderRadius: "8px",
              px: 1,
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            {assessmentData ? (
              <>
                <DataGrid
                  disableColumnMenu
                  rows={assessmentData}
                  columns={columns}
                  getRowId={(assessmentData) => assessmentData._id}
                  paginationMode="server"
                  pageSize={rowsPerPage}
                  pagination={true}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowCount={totalCount}
                  page={page - 1}
                  getRowHeight={() => "auto"}
                  hideFooter={true}
                  hideFooterPagination={true}
                  className="grid-table"
                />
                <TablePagination
                  className="tablepagination"
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: totalpage?.total_item },
                  ]}
                  count={totalCount}
                  colSpan={7}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "Items per Page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Items per Page"}
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              "No Data Found"
            )}
          </Box> */}
        </>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default AssessmentList;
