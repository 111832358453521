import ApartmentIcon from '@mui/icons-material/Apartment';

const companies =[
    {
        id: 1,
        title: 'Companies',
        icon: <ApartmentIcon />,
        path: '/company-list',
      }
    ]

  export default companies;

