import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CreateDepartmentApi,
  CreateDepartmentSuccess,
} from "../../redux/actions/Department";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const AddDepartment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addDepartment = useSelector(
    (state) =>
      state.department && state.department.dept && state.department.dept.data
  );

  useEffect(() => {
    // CreateDepartmentSuccess
    dispatch(CreateDepartmentSuccess(""));
  }, []);

  useEffect(() => {
    if (addDepartment) {
      // toast.success(addDepartment.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/department");
      }, 1000);
    }
  }, [addDepartment]);

  const validationSchema = Yup.object({
    deptName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    managerName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    managerEmail: Yup.string().email("Please enter valid email"),
    managerMobile: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter valid number"
      )
      .max(10, "Enter length of 10 digits")
      .min(10, "Enter length of 10 digits"),
    stateName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    cityName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    countryName: Yup.string().max(25, "Enter maximum length of 25 characters"),
    pinCode: Yup.string().max(6, "Enter maximum length of 6 characters"),
  });

  const formik = useFormik({
    initialValues: {
      deptName: "",
      managerName: "",
      managerEmail: "",
      managerMobile: "",
      deptAddress: "",
      stateName: "",
      cityName: "",
      countryName: "",
      pinCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        deptName: values.deptName,
        deptManager: values.managerName,
        managerEmail: values.managerEmail,
        managerMobileNumber: values.managerMobile,
        deptAddr: values.deptAddress,
        deptState: values.stateName,
        deptCity: values.cityName,
        deptCountry: values.countryName,
        deptPinCode: values.pinCode,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `department?company_id=${id}`;
        dispatch(CreateDepartmentApi(allData));
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
            className="header-card"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/department" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Create
                  </Button>
                  <div style={{marginRight:"8px",marginTop:"7px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Add Department:</strong>
                            <p>Creating the new department</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Department Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="deptName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="issueinput-fields"
                  value={formik.values.deptName}
                  error={
                    formik.touched.deptName && Boolean(formik.errors.deptName)
                  }
                />
                <small className="error">
                  {formik.errors.deptName && formik.touched.deptName
                    ? formik.errors.deptName
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Manager Name</label>
                <TextField
                  id="outlined-basic"
                  className="issueinput-fields"
                  variant="outlined"
                  size="small"
                  name="managerName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.managerName}
                  error={
                    formik.touched.managerName &&
                    Boolean(formik.errors.managerName)
                  }
                />
                <small className="error">
                  {formik.errors.managerName && formik.touched.managerName
                    ? formik.errors.managerName
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Manager Email</label>
                <TextField
                  id="outlined-basic"
                  className="issueinput-fields"
                  variant="outlined"
                  size="small"
                  name="managerEmail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.managerEmail}
                  error={
                    formik.touched.managerEmail &&
                    Boolean(formik.errors.managerEmail)
                  }
                />
                <small className="error">
                  {formik.errors.managerEmail && formik.touched.managerEmail
                    ? formik.errors.managerEmail
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Manager Mobile</label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  className="issueinput-fields"
                  variant="outlined"
                  size="small"
                  name="managerMobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.managerMobile}
                  error={
                    formik.touched.managerMobile &&
                    Boolean(formik.errors.managerMobile)
                  }
                />
                <small className="error">
                  {formik.errors.managerMobile && formik.touched.managerMobile
                    ? formik.errors.managerMobile
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Department Address</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  className="issueinput-fields"
                  name="deptAddress"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.deptAddress}
                  error={
                    formik.touched.deptAddress &&
                    Boolean(formik.errors.deptAddress)
                  }
                />
                <small className="error">
                  {formik.errors.deptAddress && formik.touched.deptAddress
                    ? formik.errors.deptAddress
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>State Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="issueinput-fields"
                  size="small"
                  name="stateName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stateName}
                  error={
                    formik.touched.stateName && Boolean(formik.errors.stateName)
                  }
                />
                <small className="error">
                  {formik.errors.stateName && formik.touched.stateName
                    ? formik.errors.stateName
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>City Name</label>
                <TextField
                  id="outlined-basic"
                  className="issueinput-fields"
                  variant="outlined"
                  size="small"
                  name="cityName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cityName}
                  error={
                    formik.touched.cityName && Boolean(formik.errors.cityName)
                  }
                />
                <small className="error">
                  {formik.errors.cityName && formik.touched.cityName
                    ? formik.errors.cityName
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Country Name</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="issueinput-fields"
                  size="small"
                  name="countryName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.countryName}
                  error={
                    formik.touched.countryName &&
                    Boolean(formik.errors.countryName)
                  }
                />
                <small className="error">
                  {formik.errors.countryName && formik.touched.countryName
                    ? formik.errors.countryName
                    : null}
                </small>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Pin Code</label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  className="issueinput-fields"
                  variant="outlined"
                  size="small"
                  name="pinCode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pinCode}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                />
                <small className="error">
                  {formik.errors.pinCode && formik.touched.pinCode
                    ? formik.errors.pinCode
                    : null}
                </small>
              </FormControl>
            </Container>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddDepartment;
