import * as React from 'react';
import Box from '@mui/material/Box';
import Header from './pages/Header';

export const Questions = () => {

    return (
        <Box>
            <Header />
            <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
        </Box>
    );
}

export default Questions;
