import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "material-ui-core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageViewer from "react-simple-image-viewer";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

function ImagePreviewModal({
  isViewImageDialogOpen,
  closeViewImageDialog,
  captureImageIndex,
  removeFormImage
}) {
  const auditorChecklistSubmissionData = useSelector(
    (state) => state.checklist && state.checklist.auditorChecklistSubmissionData
  );
  const storedData = localStorage.getItem("IndivisualFormData");
  const selectedRemainingData = JSON.parse(storedData);
  const storedAnswers = localStorage.getItem(
    "answeredQuestions_" + selectedRemainingData?._id
  );
  const parsedAnswers = JSON.parse(storedAnswers);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isImages, setIsImages] = useState([]);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setIsImages([]);
  };

  return (
    <Box>
      <Dialog
        open={isViewImageDialogOpen}
        onClose={() => {
          closeViewImageDialog();
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            height: "75%",
            width: "90%",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Preview Screen</Typography>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeViewImageDialog()}
            />
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", overflow: "hidden" }}>
          <Typography
            style={{
              borderBottom: "2px solid #ffc107",
            }}
          >
            <strong>Images Upload for question {captureImageIndex + 1}</strong>
          </Typography>
          <Box
            style={{
              marginTop: "12px",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
            }}
          >
            {auditorChecklistSubmissionData[captureImageIndex]?.image?.map((item) => (
              <Box
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={item.path}
                  alt={item.fileName}
                  width={150}
                  height={120}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsImages([item.path]);
                    setIsViewerOpen(true);
                  }}
                />
                <Box
                  key={item.key}
                  style={{
                    position: "absolute",
                    display: "flex",
                    bottom: "10px",
                    right: "5px",
                    background: "#00000042",
                    borderRadius: "5px",
                    padding: "0px 3px",
                  }}
                  onClick={() => {
                    removeFormImage(item.fileName,true);
                  }}
                >
                  <DeleteIcon color="error" />
                </Box>
              </Box>
            ))}
            {!auditorChecklistSubmissionData[captureImageIndex]?.image || auditorChecklistSubmissionData[captureImageIndex]?.image?.length === 0 ? (
              <p>No images captured!</p>
            ) : (
              ""
            )}
          </Box>
          <br />
          <Typography
            style={{
              borderBottom: "2px solid #ffc107",
            }}
          >
            <strong>Videos Upload for question {captureImageIndex + 1}</strong>
          </Typography>
          <Box
            style={{
              marginTop: "12px",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
            }}
          >
            {auditorChecklistSubmissionData[captureImageIndex]?.video?.map((item) => (
              <Box
                style={{
                  position: "relative",
                }}
              >
                <video
                  src={item.path}
                  alt={item.fileName}
                  width={220}
                  height={150}
                  style={{
                    borderRadius: "12px",
                    cursor: "pointer",
                    width: "220px",
                    height: "150px",
                  }}
                  onClick={() => {
                    setIsImages([item.path]);
                    setIsViewerOpen(true);
                  }}
                  controls
                />
                <Box
                  style={{
                    position: "absolute",
                    display: "flex",
                    bottom: "10px",
                    right: "5px",
                    background: "#00000042",
                    borderRadius: "5px",
                    padding: "0px 3px",
                  }}
                  onClick={() => {
                    removeFormImage(item.fileName,false);
                  }}
                >
                  <DeleteIcon color="error" />
                </Box>
              </Box>
            ))}
            {!auditorChecklistSubmissionData[captureImageIndex]?.video || auditorChecklistSubmissionData[captureImageIndex]?.video?.length === 0 ? (
              <p>No videos captured!</p>
            ) : (
              ""
            )}
            {isViewerOpen && (
              <ImageViewer
                src={isImages}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgb(0 0 0 / 65%)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ImagePreviewModal;
