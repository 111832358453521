import { FormGroup, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwitchAllchecklistApi } from "../component/redux/actions/Checklist";
import { BASE_URL } from "../component/redux/apiConfig/BaseUrl";
import { EditLogEntryApi } from "../component/redux/actions/LogEntry";

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-switchBase": {
        padding: 9,
        marginLeft: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            "& .MuiSwitch-thumb:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.success.light
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            },
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#039855",
                opacity: 1,
                border: 0,
            },
        },
    },
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        backgroundColor: theme.palette.mode === "dark" ? "#E9E9EA" : "#CBD4E1",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
        "&:before": {
            content: '""',
            position: "absolute",
            top: "30%",
            width: 16,
            height: 16,
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.neutral
            )}" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" /></svg>')`,
            left: 12,
        },
    },
}));

const ToggleSwitchLogEntry = ({ row, check }) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(check);

    // useEffect(() => {
    //     if (checked !== null) {
    //         switchToggleApi(payloadRequest)
    //     }
    // }, [checked]);

    const checkApiRes = useSelector(
        (state) => state && state?.checklist && state?.checklist?.switchToggle
    );

    useEffect(() => {
        setChecked(check)
    }, [check]);
    
    useEffect(() => {
        if (checkApiRes) {
            window.location.reload();
        }
    }, []);

    const handleChange = (e) => {
        setChecked(e.target.checked);
        switchToggleApi(checked);
    };

    const switchToggleApi = (checked) => {
        // let payloadRequest = {
        //     body: {
        //         _id: row,
        //         isActive: !checked,
        //     },
        // };
        const allData = {
            body: {
                status: !checked,
            },
        };

        if (checked !== null) {
            if (allData) {
                const id = localStorage.getItem("companyId");
                allData.url = BASE_URL + `LogEntry/${row}?company_id=${id}`;
                dispatch(EditLogEntryApi(allData));
            }
        }
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={<Android12Switch onChange={handleChange} checked={checked} />}
            />
        </FormGroup>
    );
};

export default ToggleSwitchLogEntry;
