import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { AllPlansListApi, FeatureMapListApi } from "../../Store/Redux/Actions/FeatureMapAction";
// import { BASE_URL } from "../../Store/Redux/apiConfig/BaseUrl";
import Lottie from "react-lottie-player";
// import lottieJson from "./LottieLogo.json";
import lottieJson from "../Account/LottieLogo.json";
import { AccountDetailsApi } from "../redux/actions/AccountAction";
import {
  AllPlansListApi,
  GetActiveAddOnListApi,
} from "../redux/actions/FeatureMapAction";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";

function ProgressbarTransparent(props) {
  return (
    <div id="divLoading" className="transparentLoader">
      <span className="centerLoaderText">
        <Lottie
          animationData={lottieJson}
          style={{ width: 150, height: 150 }}
          loop
          speed={2}
          {...props}
        ></Lottie>
      </span>
    </div>
  );
}

function getTotalFeatures(modules) {
  let totalFeatures = [];
  modules &&
    modules.forEach((moduleData) => {
      moduleData.subModules.forEach((subModule) => {
        subModule.features.forEach((feature) => {
          if (feature.status) {
            totalFeatures.push(feature.price);
          }
        });
      });
    });

  return totalFeatures;
}

const AddOn = ({ setAddonModalOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeAddOnListData, setActiveAddOnListData] = useState();
  const [featureData, setFeatureData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});

  const GetActiveAddOn = useSelector(
    (state) => state.account && state.account.getActiveAddOn
  );
  console.log("GetActiveAddOn", GetActiveAddOn);
  const AllPlansList = useSelector(
    (state) => state.account && state.account.allPlans
  );
  console.log("AllPlansList", AllPlansList);
  const companyInfo = useSelector((state) => state.account.companyInfo);
  const companyInfoList = useSelector(
    (state) => state.account.companyInfo.companyAddOn
  );
  console.log("companyInfo", companyInfo);
  useEffect(() => {
    setActiveAddOnListData(GetActiveAddOn);
  }, [GetActiveAddOn]);

  useEffect(() => {
    getCompanyInfo();
    GetAllPlans();
    getActiveAddOn();
  }, []);

  const getCompanyInfo = () => {
    const companyId = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `company-info?company_id=${companyId}` };
    dispatch(AccountDetailsApi(data));
  };

  const GetAllPlans = () => {
    const data = { url: BASE_URL + `company/plan/getAll` };
    dispatch(AllPlansListApi(data));
  };

  const getActiveAddOn = () => {
    const data = { url: BASE_URL + `companySubModule/getActiveAddOn` };
    dispatch(GetActiveAddOnListApi(data));
  };

  const handleSave = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const companyId = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `addon/${companyInfo._id}?company_id=${companyId}`,
      body: { companyAddOn: activeAddOnListData },
    };

    try {
      const response = await axios.put(data?.url, data?.body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setAddonModalOpen(false);
      // setFeatureData()
      // navigate("/pricing");
      return response;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const handleCheckboxChange = (moduleId, subModuleId, featureId, checked) => {
    // Update featureData with new status values
    console.log("activeAddOnListData", activeAddOnListData);
    const updatedFeatureData =
      activeAddOnListData &&
      activeAddOnListData.map((module) => {
        if (module.moduleSlug === moduleId) {
          return {
            ...module,
            subModules: module.subModules.map((subModule) => {
              if (subModule.slug === subModuleId) {
                return {
                  ...subModule,
                  features: subModule.features.map((feature) => {
                    console.log("featurefeature", feature);
                    console.log("checked", checked);
                    if (feature._id === featureId) {
                      if (checked) {
                        setFeatureData([...featureData, feature._id]);
                      } else {
                        let updatedID = featureData.filter(
                          (item) => item !== feature._id
                        );
                        setFeatureData(updatedID);
                      }
                      return {
                        ...feature,
                        status: checked,
                      };
                    }
                    return feature;
                  }),
                };
              }
              return subModule;
            }),
          };
        }
      });
    console.log("updatedFeatureData", updatedFeatureData);
    setActiveAddOnListData(updatedFeatureData);
  };

  useEffect(() => {
    let data = [];
    companyInfoList &&
      companyInfoList.map((item) => {
        item.subModules.map((subModuleList) => {
          subModuleList.features.map((featuresList) => {
            if (featuresList.status) {
              data.push(featuresList._id);
            }
          });
        });
      });
    const modifiedFeatureMapList = JSON.parse(JSON.stringify(GetActiveAddOn));

    modifiedFeatureMapList.map((items) => {
      console.log("call");
      items.subModules.map((subModuleList) => {
        console.log("call");
        subModuleList.features.map((featuresList) => {
          console.log("call");
          console.log("featuresList", featuresList);
          if (data.includes(featuresList._id)) {
            featuresList.status = true;
          }
        });
      });
    });

    console.log("modifiedFeatureMapList", modifiedFeatureMapList);

    setFeatureData(data);
    setActiveAddOnListData(modifiedFeatureMapList);
  }, [companyInfoList, GetActiveAddOn]);

  useEffect(() => {
    const findPlan = AllPlansList.filter(
      (plan) => plan._id === companyInfo?.planDetails?.planId
    );

    setSelectedPlan(findPlan[0]);
  }, [companyInfo?.planDetails, AllPlansList]);

  console.log("activeAddOnListData", activeAddOnListData);

  return (
    <Box sx={{ position: "relative", height: "100%", overflow: "hidden" }}>
      {loading && <ProgressbarTransparent play />}
      <Container
        maxWidth="xl"
        sx={{
          bgcolor: "#F6F8FC",
          p: 1,
          borderRadius: 2,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            border: "1px solid #cbd4e1",
            borderRadius: "8px",
            background: "#ffff",
            p: 2,
            mb: 1,
            mt: 1,
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  fontWeight: "600",
                }}
              >
                AddOn Feature
              </Box>
              <Box sx={{ display: "flex", gap: "50px" }}>
                <Box sx={{ width: "400px" }}>
                  Selected Plan: {selectedPlan?.title}
                </Box>
                <Box sx={{ width: "400px" }}>Price: ₹{selectedPlan?.price}</Box>
              </Box>
              <Box sx={{ display: "flex", gap: "50px" }}>
                <Box sx={{ width: "400px" }}>
                  Total Selected Addon: &nbsp;
                  {activeAddOnListData &&
                    activeAddOnListData.length &&
                    getTotalFeatures(activeAddOnListData).length}
                </Box>
                <Box sx={{ width: "400px" }}>
                  Total Addon Amount : &nbsp;₹
                  {getTotalFeatures(activeAddOnListData).reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "50px" }}>
                <Box sx={{ width: "400px" }}>
                  Discription: {selectedPlan?.description}
                </Box>
                <Box sx={{ width: "400px" }}>
                  Total Amount : &nbsp;₹
                  {getTotalFeatures(activeAddOnListData).reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  ) + selectedPlan?.price}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "#f26539" }}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          bgcolor: "#F6F8FC",
          p: 2,
          mt: 4,
          borderRadius: 2,
          position: "absolute",
          top: "24%",
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: "auto",
        }}
      >
        {activeAddOnListData &&
          activeAddOnListData.map((model, index) => {
            return (
              <Box>
                <Box sx={{ pb: 2, fontSize: "20px", fontWeight: 600 }}>
                  {index + 1 + "." + model.moduleName}
                </Box>
                <Box>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      SubModule
                    </AccordionSummary>
                    <AccordionDetails>
                      {model.subModules.map((subModel, index) => {
                        return (
                          <Box sx={{ mb: 1 }}>
                            <Box sx={{ ml: 4, mb: 1 }}>
                              {index + 1 + "." + subModel.name}
                            </Box>
                            <Box sx={{ ml: 6 }}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  Features
                                </AccordionSummary>
                                <AccordionDetails>
                                  {subModel &&
                                    subModel.features.map((feature, index) => {
                                      return (
                                        <Box>
                                          <Box
                                            sx={{
                                              ml: 3,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Checkbox
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  model.moduleSlug,
                                                  subModel.slug,
                                                  feature._id,
                                                  e.target.checked
                                                )
                                              }
                                              checked={featureData.includes(
                                                feature._id
                                              )}
                                            />
                                            <Typography>
                                              {index + 1 + "."}{" "}
                                            </Typography>
                                            &nbsp;
                                            {feature.title}
                                          </Box>
                                          <Box sx={{ ml: 9, display: "flex" }}>
                                            <Typography
                                              sx={{ fontWeight: "bold" }}
                                            >
                                              Price:
                                            </Typography>
                                            &nbsp;₹{feature.price}
                                          </Box>
                                          <Box sx={{ ml: 9, display: "flex" }}>
                                            <Typography
                                              sx={{ fontWeight: "bold" }}
                                            >
                                              Description:
                                            </Typography>
                                            &nbsp;{feature.description}
                                          </Box>
                                        </Box>
                                      );
                                    })}
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            );
          })}
      </Container>
    </Box>
  );
};
export default AddOn;
