import JwtAuthService from "../apiConfig/ApiConfig";
import { toast } from "react-toastify";
import {
  ADD_ALLFORM_REQUEST,
  ADD_ALLFORM_SUCCESS,
  ADD_ALLFORM_ERROR,
  ADD_ALLFORMEDIT_REQUEST,
  ADD_ALLFORMEDIT_SUCCESS,
  ADD_ALLFORMEDIT_ERROR,
  ALLFORM_UPDATE_REQUEST,
  ALLFORM_UPDATE_SUCCESS,
  ALLFORM_UPDATE_QUESTION_SUCCESS,
  ALLFORM_UPDATE_ERROR,
  ADDALLFORMS_UPDATE_REQUEST,
  ADDALLFORMS_UPDATE_SUCCESS,
  ADDALLFORMS_RESET_SUCCESS,
  ADDALLFORMS_UPDATE_ERROR,
  ALLFORM_DELETE_REQUEST,
  ALLFORM_DELETE_SUCCESS,
  ALLFORM_DELETE_ERROR,
  ALLFORM_EDITSTORES_UPDATE_REQUEST,
  ALLFORM_EDITSTORES_UPDATE_SUCCESS,
  ALLFORM_EDITSTORES_UPDATE_ERROR,
  ASSESSMENT_EDITSTORES_UPDATE_REQUEST,
  ASSESSMENT_EDITSTORES_UPDATE_SUCCESS,
  ASSESSMENT_EDITSTORES_UPDATE_ERROR,
  ADD_ASSESSTMENTEDIT_REQUEST,
  ADD_ASSESSTMENTEDIT_SUCCESS,
  ADD_ASSESSTMENTEDIT_ERROR,
} from "../types/Types";
export const AllFormStores = () => ({
  type: ADD_ALLFORM_REQUEST,
});

export const AllFormStoresSuccess = (allForm) => {
  return {
    type: ADD_ALLFORM_SUCCESS,
    payload: allForm,
  };
};

export const AllFormStoresFailed = (error) => ({
  type: ADD_ALLFORM_ERROR,
  payload: error,
});

export const AllFormEditStores = () => ({
  type: ADD_ALLFORMEDIT_REQUEST,
});

export const AllFormEditStoresSuccess = (addqustionedit) => {
  return {
    type: ADD_ALLFORMEDIT_SUCCESS,
    payload: addqustionedit,
  };
};

export const AssessmentEditStores = () => ({
  type: ADD_ASSESSTMENTEDIT_REQUEST,
});

export const AssessmentEditStoresSuccess = (assesstqustionedit) => {
  return {
    type: ADD_ASSESSTMENTEDIT_SUCCESS,
    payload: assesstqustionedit,
  };
};
export const AssessmentStoresFailed = (error) => ({
  type: ADD_ASSESSTMENTEDIT_ERROR,
  payload: error,
});

export const AllFormEditStoresFailed = (error) => ({
  type: ADD_ALLFORMEDIT_ERROR,
  payload: error,
});

export const AllFormEditStoresUpdate = () => ({
  type: ALLFORM_EDITSTORES_UPDATE_REQUEST,
});

export const AllFormEditStoresUpdateSuccess = (data, id) => {
  return {
    type: ALLFORM_EDITSTORES_UPDATE_SUCCESS,
    payload: data,
    id: id,
  };
};

export const AllFormEditStoresUpdataFailed = (error) => ({
  type: ALLFORM_EDITSTORES_UPDATE_ERROR,
  payload: error,
});

export const AssessmentEditStoresUpdate = () => ({
  type: ASSESSMENT_EDITSTORES_UPDATE_REQUEST,
});

export const AssessmentEditStoresUpdateSuccess = (data, id) => {
  return {
    type: ASSESSMENT_EDITSTORES_UPDATE_SUCCESS,
    payload: data,
    id: id,
  };
};

export const AssessmentEditStoresUpdataFailed = (error) => ({
  type: ASSESSMENT_EDITSTORES_UPDATE_ERROR,
  payload: error,
});

export const AllFormUpdate = () => ({
  type: ALLFORM_UPDATE_REQUEST,
});

export const AllFormUpdateSuccess = (data, id) => {
  return {
    type: ALLFORM_UPDATE_SUCCESS,
    payload: data,
    id: id,
  };
};
export const AllFormUpdateQuestionSuccess = (data, id) => {
  return {
    type: ALLFORM_UPDATE_QUESTION_SUCCESS,
    payload: data,
  };
};

export const AllFormUpdataFailed = (error) => ({
  type: ALLFORM_UPDATE_ERROR,
  payload: error,
});

export const AllFormsUpdate = () => ({
  type: ADDALLFORMS_UPDATE_REQUEST,
});

export const AllFormsUpdateSuccess = (id) => {
  return {
    type: ADDALLFORMS_UPDATE_SUCCESS,
    payload: id,
  };
};
export const AllFormsResetSuccess = (id) => {
  return {
    type: ADDALLFORMS_RESET_SUCCESS,
    payload: [],
  };
};

export const AllFormsUpdataFailed = (error) => ({
  type: ADDALLFORMS_UPDATE_ERROR,
  payload: error,
});

export const AllFormDelete = () => ({
  type: ALLFORM_DELETE_REQUEST,
});

export const AllFormDeleteSuccess = (id) => {
  return {
    type: ALLFORM_DELETE_SUCCESS,
    payload: id,
  };
};

export const AllFormDeleteFailed = (error) => ({
  type: ALLFORM_DELETE_ERROR,
  payload: error,
});

export const allFormData = (data) => {
  return (dispatch) => {
    dispatch(AllFormStores());
    if (data) {
      const allForm = data;
      dispatch(AllFormStoresSuccess(allForm));
    }
  };
};

export const allFormQustionEditData = (data) => {
  return (dispatch) => {
    dispatch(AllFormEditStores());
    if (data) {
      const addqustionedit = data;
      dispatch(AllFormEditStoresSuccess(addqustionedit));
    }
  };
};

export const assessmentQustionEditData = (data) => {
  return (dispatch) => {
    dispatch(AssessmentEditStores());
    // if (data) {
    const assesstqustionedit = data;
    dispatch(AssessmentEditStoresSuccess(assesstqustionedit));
    // }
  };
};

export const allFormUpdateData = (data, i, done) => {
  return (dispatch) => {
    dispatch(AllFormUpdate());
    if (data) {
      done(data);
      const allformData = data;
      dispatch(AllFormUpdateSuccess(allformData, i));
    }
  };
};

export const allFormEditQustionUpdateData = (data, i) => {
  return (dispatch) => {
    dispatch(AllFormEditStoresUpdate());
    if (data) {
      const allformData = data;
      dispatch(AllFormEditStoresUpdateSuccess(allformData, i));
    }
  };
};

export const assessmentsEditQustionUpdateData = (data, i) => {
  return (dispatch) => {
    dispatch(AssessmentEditStoresUpdate());
    if (data) {
      const allformData = data;
      dispatch(AssessmentEditStoresUpdateSuccess(allformData, i));
    }
  };
};

export const allFormEditQustionResetData = (data, i) => {
  return (dispatch) => {
    dispatch(AllFormEditStoresUpdate());
    if (data) {
      const allformData = data;
      dispatch(AllFormEditStoresUpdataFailed(allformData, i));
    }
  };
};

export const allFormsUpdateData = (data) => {
  return (dispatch) => {
    dispatch(AllFormsUpdate());
    if (data) {
      const allformData = data;
      dispatch(AllFormsUpdateSuccess(allformData));
    }
  };
};

export const qustionResetData = (data) => {
  return (dispatch) => {
    dispatch(AllFormsResetSuccess(data));
  };
};
export const allformDeletes = (deleteId) => {
  return (dispatch) => {
    dispatch(AllFormDelete());
    if (deleteId) {
      const idx = deleteId;
      dispatch(AllFormDeleteSuccess(idx));
    }
  };
};
// export const allformDeletes = (id) => {
//   return (dispatch) => {
//     dispatch(AllFormDelete());
// if(id){
//   const id = id;
//   dispatch(AllFormDeleteSuccess(id));
// }
//   }
// }
