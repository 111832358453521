/** @format */

import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";

const NoPaginationGlobalTable = ({ data, columns, loading }) => {
  const tableHeight = 517;

  console.log("loading", loading);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            p: 1,
            borderRadius: "8px",
            height: `${tableHeight + 62}px`,
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
            marginBottom: "2px",
          }}
        >
          <div
            style={{
              height: `${tableHeight}px`,
              overflowY: "auto",
            }}
          >
            {data && data?.length > 0 ? (
              <DataGrid
                disableColumnMenu
                rows={data}
                columns={columns}
                getRowId={(data) => data._id}
                getRowHeight={() => "auto"}
                hideFooter
              />
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100% - 72px)",
                }}
              >
                No Data Found
              </Typography>
            )}
          </div>
        </Box>
      )}
    </Box>
  );
};

export default NoPaginationGlobalTable;
