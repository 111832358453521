/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tab,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateAuditPdfApi,
  SubmissionViewApi,
} from "../../../redux/actions/Checklist";
import User from "../../../../assets/icons/svg-icons/User.svg";
import Store from "../../../../assets/icons/svg-icons/Store.svg";
import Geocode from "react-geocode";
import moment from "moment";
import Chart from "react-apexcharts";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Hidden } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import UserOutlet from "../../../checklist/submissionTabs/UserOutlet";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import FormDataTable from "../../../checklist/submissionTabs/FormDataTable";
import { Main } from "../../../checklist/submissionTabs/Map";
import ChartSection from "../../../checklist/submissionTabs/Chart";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubmittedChecklistView = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [buttonsvalues, setButtonValues] = useState(0);
  const [value, setValue] = useState(0);
  const [storeLocation, setStoreLocation] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [detailedData, setDetailedData] = React.useState();
  const [downloadData, setDownloadData] = React.useState();
  const [pdfBtnClick, setPdfBtnClick] = React.useState(false);
  const frequency = detailedData?.assignment?.frequency;
  const [active, setActive] = useState(0);
  const [val, setVal] = useState(null);
  const [chartReady, setChartReady] = useState(false);
  const [downloadAuditPdfData, setdownloadAuditPdfData] = React.useState();
  const getChecklistDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionView &&
      state.checklist.submissionView.data &&
      state.checklist.submissionView.data.data
  );

  const PDFLoader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  const getAuditPdfDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.downloadPdf &&
      state.checklist.downloadPdf.data &&
      state.checklist.downloadPdf.data.data
  );

  const handleGenerateAuditPDFClick = () => {
    confirmAlert({
      title: "Generate Audit PDF",
      message:
        "Audit PDF will replace submission details pdf. After downloading, it will be open in new tab for view. Do you want to continue ?",
      buttons: [
        {
          label: "Confirm",
          onClick: () => {
            getApiGeneratePdf();
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  useEffect(() => {
    if (getAuditPdfDetails && getAuditPdfDetails.pdfReport && pdfBtnClick) {
      const link = document.createElement("a");
      link.href = getAuditPdfDetails.pdfReport;
      link.target = "_blank";
      link.download = "AuditReport_" + Date.now() + ".pdf";
      link.click();
    }
  }, [getAuditPdfDetails, pdfBtnClick]);

  useEffect(() => {
    submissionDetails();
  }, []);

  useEffect(() => {
    if (getChecklistDetails) {
      setDetailedData(getChecklistDetails);
    }
  }, [getChecklistDetails]);

  console.log("detailedData", detailedData);

  useEffect(() => {
    if (getChecklistDetails?.pdfReport) {
      setDownloadData(getChecklistDetails?.pdfReport);
    }
  }, [getChecklistDetails]);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const getApiGeneratePdf = () => {
    const _id = localStorage.getItem("companyId");
    const submissionId = location?.state;
    const data = {
      url:
        BASE_URL + `generatePdf?company_id=${_id}&submissionId=${submissionId}`,
    };
    dispatch(GenerateAuditPdfApi(data));
    setPdfBtnClick(true);
  };

  const submissionDetails = () => {
    const _id = localStorage.getItem("companyId");
    const checklistId = location?.state;
    if (checklistId !== "" && checklistId !== undefined) {
      const data = {
        url: BASE_URL + `checklists/progress/${checklistId}?company_id=${_id}`,
      };
      dispatch(SubmissionViewApi(data));
    }
  };
  const HandleValues = (id) => {
    setButtonValues(id);
    setActive(id);
  };

  Geocode.setApiKey("AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  Geocode.fromLatLng(
    detailedData?.storeLocation?.lat,
    detailedData?.storeLocation?.lng
  ).then(
    (response) => {
      const address = response.results[0].formatted_address;
      setStoreLocation(address);
    },
    (error) => {
      console.error(error);
    }
  );
  Geocode.fromLatLng(
    detailedData?.userLocation?.lat,
    detailedData?.userLocation?.lng
  ).then(
    (response) => {
      const address = response.results[0].formatted_address;
      setUserLocation(address);
    },
    (error) => {
      console.error(error);
    }
  );

  const lat2 =
    getChecklistDetails &&
    getChecklistDetails.storeLocation &&
    getChecklistDetails.storeLocation.lat;
  const lon2 =
    getChecklistDetails &&
    getChecklistDetails.storeLocation &&
    getChecklistDetails.storeLocation.lng;
  const lat1 =
    getChecklistDetails &&
    getChecklistDetails.userLocation &&
    getChecklistDetails.userLocation.lat;
  const lon1 =
    getChecklistDetails &&
    getChecklistDetails.userLocation &&
    getChecklistDetails.userLocation.lng;

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    if (!lat1 || !lon1 || !lat2 || !lon2) {
      return NaN;
    }
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = parseFloat(R * c).toFixed(3); // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
    setVal(distance);
  }, [[lat1, lon1, lat2, lon2]]);

  useEffect(() => {
    if (val !== null) {
      setChartReady(true);
    }
  }, [val]);

  const series = [val];
  const options = {
    colors: ["#F39A4C"],
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
          background: "none",
          position: "front",
        },
        track: {
          show: true,
          background: "#F39A4C",
          strokeWidth: "97%",
          opacity: 1,
          margin: 3,
        },
        dataLabels: {
          total: {
            show: true,
            label: "Meter",
            color: "#F39A4C",
            formatter: function (w) {
              return w.globals.seriesTotals
                .reduce((a, b) => {
                  return a + b;
                }, 0)
                .slice(2, 9);
            },
          },
          value: {
            show: true,
          },
        },
      },
    },
    labels: ["METER"],
    stroke: {
      lineCap: "round",
    },
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card1"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Button
                className="header-add cancle-hover"
                style={{ marginBottom: "3px" }}
                onClick={handleGenerateAuditPDFClick}
                disabled={PDFLoader}
              >
                <CloudDownloadIcon sx={{ mr: 1 }} />
                Generate Audit PDF
              </Button>
              <Link to="/checklist/submission" className="submenu-item">
                <Button
                  className="header-add cancle-hover"
                  style={{ marginBottom: "3px" }}
                >
                  <DoneIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Link>
              <div style={{ marginTop: "4px", marginRight: "10px" }}>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "600px" }}>
                      <strong>Dashboard:</strong>
                      <p>
                        View the details of the checklist, Issue log and
                        broadcast messages for the selected company.
                      </p>

                      <strong>Issue Raised:</strong>
                      <p>
                        To get the graphs of issues raised, from the drop down
                        from Checklist Completion as “issues raised” to view the
                        status of Issue logs for last five days.
                      </p>

                      <strong> note:</strong>
                      <p>
                        This section does not change and shows the data for all
                        the roles irrespective of the roles select in the
                        “Select sub role” drop down.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
        <Typography
          variant="h5"
          component="h4"
          sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
        >
          User Information
        </Typography>
        <Card className="user-information">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={User} alt="avatar" className="submission-avatar" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Table aria-label="simple table" className="details-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        User Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {detailedData?.user?.userName
                          ? detailedData?.user?.userName
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        User Role:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {detailedData?.role ? detailedData?.role : "--"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Submitted On:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {moment(detailedData?.createdAt).format("lll")}
                      </Typography>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Updated On:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {moment(detailedData?.updatedAt).format("lll")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Category:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {detailedData?.category?.categoryTitle}
                      </Typography>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Typography variant="p" className="detail-head">
                        Frequency:
                      </Typography>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Typography variant="p" component="b">
                        {(() => {
                          if (frequency === 3) {
                            return "Daily";
                          } else if (frequency === 2) {
                            return "Hourly";
                          } else if (frequency === 5) {
                            return "Monthly";
                          } else if (frequency === 1) {
                            return "No frequency";
                          } else if (frequency === 4) {
                            return "Weekly";
                          }
                          return "N/A";
                        })()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Latitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.userLocation?.lat}
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Longitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.userLocation?.lng}
                        </Typography>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography
                        variant="p"
                        className="detail-head"
                        paddingLeft={3}
                      >
                        User Location:
                      </Typography>
                    </TableCell>
                    <TableCell colspan="3" className="detail-value capitalize">
                      {userLocation}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Version:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.otherInfo?.appVersion || "N/A"}
                    </TableCell>
                    <TableCell>
                      <Typography>Media Status:</Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.imageStatus === true
                        ? "Uploaded"
                        : "Uploading...."}
                      {console.log(
                        "detailedData?.forms?.imageStatus",
                        detailedData?.imageStatus
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography sx={{ lineHeight: "0px" }}>
                    Checklist Progress
                  </Typography>
                  <ChartSection data={detailedData} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography sx={{ lineHeight: "0px" }}>
                    Location Progress
                  </Typography>
                  {chartReady && (
                    <Box>
                      <Chart
                        options={options}
                        series={series}
                        type="radialBar"
                        height={200}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Typography
          variant="h5"
          component="h4"
          sx={{ textAlign: "left", p: 1, mt: 2, fontWeight: "bold" }}
        >
          Store Information
        </Typography>
        <Card className="user-information">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={Store} alt="avatar" className="submission-avatar" />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Table aria-label="simple table" className="details-table">
                <TableBody>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Store Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {detailedData?.store?.title
                        ? detailedData?.store?.title
                        : "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Latitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.storeLocation?.lat}
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="list-value user-location">
                      <Hidden xsDown>
                        <Typography variant="p" className="detail-head">
                          Longitude:
                        </Typography>
                      </Hidden>
                    </TableCell>
                    <TableCell className="user-location detail-value">
                      <Hidden xsDown>
                        <Typography variant="p" component="b">
                          {detailedData?.storeLocation?.lng}
                        </Typography>
                      </Hidden>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography
                        variant="p"
                        className="detail-head"
                        paddingLeft={5}
                      >
                        Store Location:
                      </Typography>
                    </TableCell>
                    <TableCell colspan="3" className="detail-value capitalize">
                      {storeLocation}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box className="map-box">
                <Main
                  storeLocation={storeLocation}
                  userLocation={userLocation}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
        <Typography
          variant="h5"
          component="h4"
          sx={{ textAlign: "left", p: 1, mt: 2, fontWeight: "bold" }}
        >
          Forms Data
        </Typography>
        <Card className="user-information" sx={{ textAlign: "center" }}>
          <Grid
            container
            spacing={1}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={12} md={4} lg={2} sx={{ height: "100%" }}>
              <Box
                sx={{
                  borderRadius: "8px",
                  maxHeight: "185px",
                  overflowX: "hidden",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  "&::-webkit-scrollbar": {
                    width: 7,
                  },
                  "&::-webkit-scrollbar-track": {
                    padding: "12px 5px",
                    backgroundColor: "#CBD4E1",
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#64748B",
                    borderRadius: 8,
                  },
                }}
                className="button-tabs"
                style={{ paddingLeft: 10, rowGap: "5px" }}
              >
                {detailedData?.forms?.map((row, i) => {
                  return (
                    <Button
                      className={
                        active === i
                          ? "Correction cancle-hover"
                          : "Correction-second cancle-hover"
                      }
                      onClick={() => {
                        HandleValues(i);
                      }}
                      sty
                    >
                      <b>{row.title}</b>
                      <br />
                      <span>Scored:</span>
                      {/* <b>
                        {(
                          (row.calculatedOptionScore /
                            row.calculatedQuestionScore) *
                            100 +
                          ""
                        )
                          .slice(".", 5)
                          .concat("%")}
                      </b> */}
                      <b>
                        {row.calculatedQuestionScore !== 0
                          ? (
                              (row.calculatedOptionScore /
                                row.calculatedQuestionScore) *
                              100
                            )
                              .toFixed(2)
                              .concat("%")
                          : "0.00"}
                      </b>
                    </Button>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
                className="tabs-content-parent"
              >
                <Tab
                  label="Questions"
                  className="active-tabs Questions-tabs cancle-hover"
                  {...a11yProps(0)}
                />
                <Tab
                  label="User / Outlet Image"
                  className="Archived Questions-tabs cancle-hover"
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <FormDataTable buttonsvalues={buttonsvalues} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <UserOutlet buttonsvalues={buttonsvalues} />
              </TabPanel>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <p style={{ fontSize: "1rem" }}>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default SubmittedChecklistView;
