import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import {
  CSV_NAM_SUBMISSION_USER_WISE_REQUEST,
  CSV_NAM_SUBMISSION_USER_WISE_SUCCESS,
  CSV_NAM_SUBMISSION_USER_WISE_ERROR,
} from "../types/Types";

export const CsvNamSubmissionListUserWiseRequest = () => ({
  type: CSV_NAM_SUBMISSION_USER_WISE_REQUEST,
});
export const CsvNamSubmissionListUserWiseSuccess = (list) => ({
  type: CSV_NAM_SUBMISSION_USER_WISE_SUCCESS,
  payload: list,
});
export const CsvNamSubmissionListUserWiseFailed = (error) => ({
  type: CSV_NAM_SUBMISSION_USER_WISE_ERROR,
  payload: error,
});

export const CsvNamSubmissionListUserWiseApi = (data) => (dispatch) => {
  try {
    dispatch(CsvNamSubmissionListUserWiseRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        console.log("response", response);
        dispatch(CsvNamSubmissionListUserWiseSuccess(JSON.parse(response.data.data)));
      } else {
        dispatch(CsvNamSubmissionListUserWiseFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CsvNamSubmissionListUserWiseFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};
