/** @format */

import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TableFooter,
  DialogActions,
  Typography,
  Select,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  CustomerLogStatusApi,
  DeleteCustomerLogApi,
  DeleteCustomerLogSuccess,
  ListCustomerLogApi,
} from "../redux/actions/CustomerLogAction";
import CLHeader from "./pages/CLHeader";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import View from "../images/View.svg";
import Delete from "../images/Delete.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import { confirmAlert } from "react-confirm-alert";
import Progressbar from "../GlobalProgress/Progressbar";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import NoDataFound from "../NoDataFound";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import { columnGroupsStateInitializer } from "@mui/x-data-grid/internals";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
export const CustomerLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [customerLogData, setCustomerLogData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [rowId, setRowId] = React.useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });
  const utcStr = new Date().toUTCString();
  const isoStr = new Date().toISOString();
  const [value, setValue] = React.useState(moment(isoStr));

  const customerLogList = useSelector(
    (state) =>
      state.CustomerLogs &&
      state.CustomerLogs.data &&
      state.CustomerLogs.data.data &&
      state.CustomerLogs.data.data.data &&
      state.CustomerLogs.data.data.data.customerLog
  );
  const totalitems = useSelector(
    (state) =>
      state.CustomerLogs &&
      state.CustomerLogs.data &&
      state.CustomerLogs.data.data &&
      state.CustomerLogs.data.data.data &&
      state.CustomerLogs.data.data.data.paginate
  );

  const customerLogListDelete = useSelector(
    (state) => state && state?.CustomerLogs && state?.CustomerLogs?.deleteData
  );

  const customerLogStatusChange = useSelector(
    (state) =>
      state.CustomerLogs.statusChangeData &&
      state.CustomerLogs.statusChangeData.data
  );

  const Loader = useSelector(
    (state) => state.CustomerLogs && state.CustomerLogs.loading
  );

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  useEffect(() => {
    if (customerLogList) {
      setLoader(true);
      setCustomerLogData(customerLogList);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [customerLogList]);

  useEffect(() => {
    listCustomerLogs(payloadRequest);
  }, []);

  useEffect(() => {
    if (customerLogStatusChange) {
      listCustomerLogs(payloadRequest);
    }
  }, [customerLogStatusChange]);

  useEffect(() => {
    if (customerLogListDelete) {
      listCustomerLogs(payloadRequest);
      dispatch(DeleteCustomerLogSuccess(""));
    }
  }, [customerLogListDelete]);

  const listCustomerLogs = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `customerLog/index?company_id=${id}`;
    dispatch(ListCustomerLogApi(payloadRequest));
  };

  const handleClickDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this customer log?.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const data = {
              url: BASE_URL + `customerLog/${id}?company_id=${_id}`,
            };
            dispatch(DeleteCustomerLogApi(data));
            // listCustomerLogs(payloadRequest);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleClick = (row) => {
    navigate(`/customer-Log/customer-Log-View`, { state: row });
  };

  const statusDropdownHandler = (id, value) => {
    if ((value, id)) {
      const data = {
        body: {
          _id: id,
          status: value,
        },
      };
      const _id = localStorage.getItem("companyId");
      data.url = BASE_URL + `customerLog/${id}?company_id=${_id}`;
      dispatch(CustomerLogStatusApi(data));
    }
  };

  const handleChangeStatus = (e, id) => {
    e.preventDefault();
    setRowId(id);
    const value = e.target.value;
    setStatus(value);
    if ((value, id && value !== "scheduled")) {
      statusDropdownHandler(id, value);
    }
  };

  const statusScheduledDropdownHandler = (event, id) => {
    event.preventDefault();
    if ((rowId, status)) {
      const data = {
        body: {
          _id: rowId,
          status: status,
          scheduledAt: value,
        },
      };
      const _id = localStorage.getItem("companyId");
      data.url = BASE_URL + `customerLog/${rowId}?company_id=${_id}`;
      dispatch(CustomerLogStatusApi(data));
      setOpen(false);
      listCustomerLogs(payloadRequest);
    }
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    payloadRequest.body.pagination.page = newPage + 1;
    payloadRequest.body.search = searchValue;
    listCustomerLogs(payloadRequest);
  };
  const handleChangeRowsPerPage = (event, rowPerPage1) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.pagination.page = 1;

    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    listCustomerLogs(payloadRequest);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      payloadRequest.body.search = searchValue;
      listCustomerLogs(payloadRequest);
    }
  };

  useEffect(() => {
    if (searchValue == "") {
      listCustomerLogs(payloadRequest);
    }
  }, [searchValue]);

  const handleDateChange = (newValue) => {
    const currentDate = moment();
    const selectedDate = moment(newValue);
    if (selectedDate.isBefore(currentDate, "day")) {
      return;
    }
    setValue(newValue);
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          customerLogData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "100px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      renderHeader: () => <strong>{"Created By"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.createdBy?.name) {
          result.push(params?.row?.createdBy?.name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "createdAt",
      headerName: "Created On",
      renderHeader: () => <strong>{"Created On"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.createdAt) {
          result.push(moment(params?.row?.createdAt).format("lll"));
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "store",
      headerName: "Store",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.store?.title) {
          result.push(params?.row?.store?.title);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "custName",
      headerName: "Customer",
      renderHeader: () => <strong>{"Customer"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.custName) {
          result.push(params?.row?.custName);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "custContactNumber",
      headerName: "Phone",
      renderHeader: () => <strong>{"Phone"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.custContactNumber) {
          result.push(params?.row?.custContactNumber);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "custEmail",
      headerName: "Email",
      renderHeader: () => <strong>{"Email"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.custEmail) {
          result.push(params?.row?.custEmail);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.status) {
          result.push(params?.row?.status);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => (
        <Box align="center">
          <FormControl sx={{ minWidth: 133 }}>
            <Select
              labelId="statusId"
              id="demo-simple-select"
              value={params.row.status ? params.row.status : status}
              onChange={(event) => {
                handleChangeStatus(event, params.row._id);
              }}
              sx={{ height: "26px", borderRadius: "8px",width:"130px"}}
            >
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                  justifyContent: "center",
                }}
                value="closed-sold"
              >
                CLOSED-SOLD
              </MenuItem>
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                  justifyContent: "center",
                }}
                value="closed-unsold"
              >
                CLOSED-UNSOLD
              </MenuItem>
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                  justifyContent: "center",
                }}
                value="in-progress"
              >
                IN-PROGRESS
              </MenuItem>
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                  justifyContent: "center",
                }}
                value="open"
              >
                OPEN
              </MenuItem>
              <MenuItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                  justifyContent: "center",
                }}
                value="scheduled"
                onClick={(event) => {
                  setOpen(true);
                }}
              >
                SCHEDULED
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box
            align="center"
            sx={{
              minWidth: 140,
              gap: "7px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip title="View">
            <img
              src={
                hover.View && indexHover === params?.row?._id ? ViewHover : View
              }
              alt="VIEW"
              style={{
                height: "44px",
                cursor: "pointer",
                margin: "0px 3px 0px 3px",
              }}
              onClick={() => handleClick(params.row)}
              onMouseOver={() => {
                setHover({ View: true });
                setIndexHover(params?.row?._id);
              }}
              onMouseLeave={() => {
                setHover({ View: false });
              }}
            />
            </Tooltip>

              <Tooltip title="Delete">
              <img
                onClick={() => handleClickDelete(params.row._id)}
                src={
                  hover.Delete && indexHover === params?.row?._id
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{
                  height: "44px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
              </Tooltip>

            {/* <Tooltip title="Delete">
            <img
              onClick={() => handleClickDelete(params.row._id)}
              src={
                hover.Delete && indexHover === params.row.sno
                  ? DeleteHover
                  : Delete
              }
              alt="Delete"
              style={{ height: "44px", cursor: "pointer" }}
              onMouseOver={() => {
                setHover({ Delete: true });
                setIndexHover(params.row.sno);
              }}
              onMouseLeave={() => {
                setHover({ Delete: false });
              }}
            />
            </Tooltip> */}
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <CLHeader
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        searchValue={searchValue}
        customerLogData={customerLogData}
      />
      <ToastContainer />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <SingleGlobalTable
          data={customerLogData}
          columns={columns}
          totalCount={totalitems?.total_item}
          loading={Loader}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default CustomerLogs;
