import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Stack,
  Chip,
  Button,
  Tooltip,
} from "@mui/material";
// import Edit from "../../../../assets/icons/svg-icons/Edit.svg";
// import Delete from "../../../../assets/icons/svg-icons/Delete.svg";
import Delete from "../../../images/Delete.svg";
import DeleteHover from "../../../images/DeleteHover.svg";
import EditHover from "../../../images/EditHover.svg";
import Edit from "../../../images/Edit.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const QuestionsList = (props) => {
  const navigate = useNavigate();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [questionList, setQuestionList] = useState([]);
  const [uiRender, setUiRender] = useState(false);
  const [indexHover, setIndexHover] = useState(0);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  let LocalData = localStorage.getItem("questionsOfDatamanger");

  useEffect(() => {
    setLoader(true);
    let LocalData = localStorage.getItem("questionsOfDatamanger");
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setQuestionList(newQuest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [uiRender]);

  useEffect(() => {
    setLoader(true);
    let LocalData = localStorage.getItem("questionsOfDatamanger");
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setQuestionList(newQuest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [LocalData]);

  const handleClick = (items) => {
    navigate("/questions/edit-question", { state: items });
  };

  const handleDeleteList = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let newcustom = questionList.filter((items) => items.id !== id);
            let local = localStorage.setItem(
              "questionsOfDatamanger",
              JSON.stringify(newcustom)
            );
            setQuestionList(local);
            setUiRender(newcustom);
            props.setUiRenders("delete");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    const copyListItems = [...questionList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setQuestionList(copyListItems);
  };

  return (
    <Box>
      {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
        <>  
      <Box className="align-icon">
        <Typography
          variant="p"
          component="h2"
          className="top-heading"
          align="left"
        >
          Available Questions
        </Typography>
        <Link to="/questions/add-question" className="submenu-item">
          <Button className="header-add cancle-hover">
            <AddOutlinedIcon sx={{ mr: 1 }} />
            Add Question
          </Button>
          {/* change */}
        </Link>
      </Box>
      {questionList &&
        questionList.length > 0 &&
        questionList?.map((items, i) => {
          return (
            <Card
              sx={{ mb: 2, borderRadius: "8px" }}
              onDragStart={(e) => dragStart(e, i)}
              key={i}
              onDragEnter={(e) => dragEnter(e, i)}
              onDragEnd={drop}
              draggable
            >
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="p"
                    component="b"
                    sx={{ whiteSpace: "nowrap" }}
                    className="list-form"
                  >
                    Q {i + 1}.
                  </Typography>
                  <Typography variant="p" component="b" className="list-form">
                    {items?.title}
                  </Typography>
                </Stack>
                <Box className="align-icon">
                  <Stack direction="row" spacing={1} sx={{ my: 1 }}>
                    <Typography variant="p" component="p">
                      Type:
                    </Typography>
                    <Typography variant="p" component="b">
                      {items?.type?.title}
                    </Typography>
                  </Stack>
                  <Box>
                    {items?.is_store === true ? (
                      <p className="signout-btn">Store Question</p>
                    ) : (
                      ""
                    )}
                    {items?.is_kpi === true ? (
                      <p className="signout-btn">KPI Question</p>
                    ) : (
                      ""
                    )}
                    <div style={{ display: "flex" }}>
                      {
                        <Tooltip title="Edit">
                        <img
                          onClick={() => handleClick(items)}
                          src={
                            hover.Edit && indexHover === items
                              ? EditHover
                              : Edit
                          }
                          alt="Edit"
                          style={{
                            height: "35px",
                            cursor: "pointer",
                            margin: "0px 3px 0px 3px",
                          }}
                          onMouseOver={() => {
                            setHover({ Edit: true });
                            setIndexHover(items);
                          }}
                          onMouseLeave={() => {
                            setHover({ Edit: false });
                          }}
                        />
                        </Tooltip>
                      }
                      {
                        <Tooltip title="Delete">
                        <img
                          onClick={() => handleDeleteList(items.id)}
                          src={
                            hover.Delete && indexHover === items.id
                              ? DeleteHover
                              : Delete
                          }
                          alt="Delete"
                          style={{
                            height: "35px",
                            cursor: "pointer",
                            margin: "0px 3px 0px 3px",
                          }}
                          onMouseOver={() => {
                            setHover({ Delete: true });
                            setIndexHover(items.id);
                          }}
                          onMouseLeave={() => {
                            setHover({ Delete: false });
                          }}
                        />
                        </Tooltip>
                      }
                    </div>
                  </Box>
                </Box>
                <Stack direction="row" spacing={1} className="validation-div">
                  <Typography variant="p" component="b">
                    Validation:
                  </Typography>
                  {items?.type?.validations?.map((item, i) => {
                    return (
                      <Box
                        className={
                          item?.key === "required" || item?.key === "default"
                            ? "question-caption"
                            : ""
                        }
                        key={i}
                      >
                        <Typography variant="p" component="p">
                          {item && item?.key === "required" ? item?.title : ""}
                          &nbsp;&nbsp;
                          {item && item?.key === "default" ? item?.title : ""}
                          &nbsp;&nbsp;
                        </Typography>
                        <Typography variant="p" component="b">
                          {items?.type?.key == "text" ||
                          items?.type?.key == "numeric"
                            ? item && item?.key === "default"
                              ? item && item?.answer
                                ? item?.answer
                                : ""
                              : ""
                            : item && item?.key === "default"
                            ? item?.answer?.map((items) =>
                                items.value === true ? items.title : ""
                              )
                            : ""}

                          {/* {
                          item && item?.key === "default" ? item?.answer?.map(items => (
                            items.value === true ? items.title : ""
                          )) : ""
                        } */}
                          {item && item?.key === "required"
                            ? item &&
                              item?.answer?.length > 0 &&
                              item?.answer?.map((list, i) => {
                                return list?.value === true ? list?.title : "";
                              })
                            : ""}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              </CardContent>
            </Card>
          );
        })}
        </>
        )}
    </Box>
  );
};

export default QuestionsList;
// const DefaultValue = (item) => {
//   if (item && item?.answer?.length <= 0) {
//     <Typography>{item?.answer}</Typography>
//   } else if (Array.isArray(item.answer)) {
//     item?.answer?.filter(items => items.value === true
//     )
//   }
// }
