/** @format */

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "material-ui-core";
import { Grid, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BASE_URL } from "../../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
// import TextareaAutosize from "@mui/base/TextareaAutosize";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  AddCategoryApi,
  AddUomApi,
  EditCategoryApi,
  EditUomApi,
} from "../../../../redux/actions/WasteManagement";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddCategoryModal = (props) => {
  const dispatch = useDispatch();
  const [newFieldValue, setNewFieldValue] = useState({});
  const valuesEdit = props?.editvalue;
  const editProductapi = props?.editStr;

  const addCategoryRes = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.categoryList &&
      state?.wastemng?.categoryList?.data &&
      state?.wastemng?.categoryList?.data?.data &&
      state?.wastemng?.categoryList?.data?.data?.categories
  );

  const editProductRes = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.categoryList &&
      state?.wastemng?.categoryList?.data &&
      state?.wastemng?.categoryList?.data?.data &&
      state?.wastemng?.categoryList?.data?.data?.categories
  );
  const data = {
    page: 1,
    per_page: 200000,
  };

  useEffect(() => {
    if (valuesEdit?._id && editProductapi == "editCategory") {
      setNewFieldValue({
        title: valuesEdit?.title,
        description: valuesEdit?.description,
      });
    } else if (valuesEdit?._id && editProductapi == "uomEdit") {
      setNewFieldValue({
        title: valuesEdit?.title,
        description: valuesEdit?.description,
      });
    } else if (editProductapi == "") {
      setNewFieldValue({
        title: "",
        description: "",
      });
    }
  }, [valuesEdit, editProductapi]);

  const validationSchema = Yup.object({
    title: Yup.string("Required").required("Required"),
  });

  const formik = useFormik({
    initialValues: newFieldValue || {
      title: "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (values) {
        const newValues = {
          body: {
            title: values?.title,
            description: values?.description,
          },
        };
        onFinalSubmit(newValues);
      }
    },
  });

  const onFinalSubmit = (newValues) => {
    if (props.title == "categorylist") {
      if (editProductapi === "editCategory") {
        const id = localStorage.getItem("companyId");
        newValues.url =
          BASE_URL + `inventory/category/${valuesEdit?._id}?company_id=${id}`;
        dispatch(EditCategoryApi(newValues));
        setNewFieldValue({
          title: "",
          description: "",
        });
        props.addResData(editProductRes);
        props.handleClose();
      } else {
        if (newValues?.body) {
          const id = localStorage.getItem("companyId");
          newValues.url = BASE_URL + `inventory/category?company_id=${id}`;
          dispatch(AddCategoryApi(newValues));
          props.addResData(addCategoryRes);
          formik.resetForm();
          props.handleClose();
        } else {
          toast.error("All Fields are Required");
        }
      }
    } else if (props.title == "uomlist") {
      if (editProductapi == "uomEdit") {
        const id = localStorage.getItem("companyId");
        newValues.url =
          BASE_URL + `inventory/uom/${valuesEdit?._id}?company_id=${id}`;
        dispatch(EditUomApi(newValues));
        setNewFieldValue({
          title: "",
          description: "",
        });
        props.addResData(editProductRes);
        props.handleClose();
      } else {
        if (newValues?.body) {
          const id = localStorage.getItem("companyId");
          newValues.url = BASE_URL + `inventory/uom?company_id=${id}`;
          dispatch(AddUomApi(newValues));
          props.addResData(addCategoryRes);
          formik.resetForm();
          props.handleClose();
        } else {
          toast.error("All Fields are Required");
        }
      }
    }
  };

  const handleCancel = () => {
    setNewFieldValue({
      title: "",
      description: "",
    });
    props.handleClose();
    props.setEditStr("");
  };

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Add Category
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Title*</label>
                  <TextField
                    size="small"
                    id="name"
                    variant="outlined"
                    name="title"
                    placeholder="Enter product title"
                    value={formik?.values?.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    fullWidth
                    className="date-input"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Description</label>
                  <TextareaAutosize
                    size="small"
                    id="description"
                    variant="outlined"
                    name="description"
                    aria-label="minimum height"
                    minRows={5}
                    style={{
                      width: "100%",
                      padding: "18.5px 14px",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                    value={formik?.values?.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              // onClick={props.handleClose}
              color="success"
              className="issue-modal-savebtn"
            >
              {editProductapi === "editCategory" || editProductapi == "uomEdit"
                ? "Save"
                : "Save"}
            </Button>
            <Button
              variant="contained"
              onClick={handleCancel}
              className="issue-modal-cancelbtn"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default AddCategoryModal;
