import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(Store, Role, Stores, Category, FormsCompleted, Progress, Submittedon, InProgress, WeightScore) {
    return { Store, Role, Stores, Category, FormsCompleted, Progress, Submittedon, InProgress, WeightScore };
}

const rows = [
    createData('Pallavi+66 Kumari', '1234567890', 'Auditor', 'Digital Express', 'Guest Sitting Area Guest Sitting Area', '1. House Keeping Schedule', '100%', 'Oct 28, 2020, 3:10:41 PM', '13', '1. 100%'),
    createData('Pallavi+66 Kumari', '1234567890', 'Auditor', 'Digital Express', 'Guest Sitting Area Guest Sitting Area', '1. House Keeping Schedule', '100%', 'Oct 28, 2020, 3:10:41 PM', '13', '1. 100%'),
    createData('Pallavi+66 Kumari', '1234567890', 'Auditor', 'Digital Express', 'Guest Sitting Area Guest Sitting Area', '1. House Keeping Schedule', '100%', 'Oct 28, 2020, 3:10:41 PM', '13', '1. 100%')
];

const ViewSubmission = () => {

    return (
        <>
            <h3>Submissions</h3>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, textAlign: 'center' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Store</TableCell>
                            <TableCell align="right">Role</TableCell>
                            <TableCell align="right">Stores</TableCell>
                            <TableCell align="right">Category</TableCell>
                            <TableCell align="right">Forms Completed</TableCell>
                            <TableCell align="right">Progress</TableCell>
                            <TableCell align="right">Submitted on</TableCell>
                            <TableCell align="right">In Progress</TableCell>
                            <TableCell align="right">Weight Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.Store}
                                </TableCell>
                                <TableCell align="right">{row.Role}</TableCell>
                                <TableCell align="right">{row.Stores}</TableCell>
                                <TableCell align="right">{row.Category}</TableCell>
                                <TableCell align="right">{row.FormsCompleted}</TableCell>
                                <TableCell align="right">{row.Progress}</TableCell>
                                <TableCell align="right">{row.Submittedon}</TableCell>
                                <TableCell align="right">{row.InProgress}</TableCell>
                                <TableCell align="right">{row.WeightScore}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default ViewSubmission;