import React from "react";
import { Box, Grid, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Switch from "@mui/material/Switch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DoneIcon from "@mui/icons-material/Done";
import ToggleSwitch from "../../../helpers/ToggleSwitch";
import moment, { months } from "moment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"; 

const AllchecklistHead = (props) => {
  const cancleButtonPath = props?.CanclePath;
  //   const handleAssign = (values) => {
  //     console.log('valuesvalues', values);
  //     props.handleFinalSubmit(values);
  // };
  // {
  //   selectedValue === "Due"? "duedata blink" : selectedValue === "inProgrees" ? "table-left-border-success" : selectedValue === "Expire"
  //     ? "table-left-border-danger"
  //     : "table-left-border-success"
  // /checklist/all-checklist
  // }
  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right",height:"42px", borderRadius:"8px" }}>
              <Link
                to={
                  cancleButtonPath === "submissionCancle"
                    ? "/checklist/submission"
                    : cancleButtonPath === "userCancle"
                    ? "/checklist/user-checklist"
                    : cancleButtonPath === "storeCancle"
                    ? "/checklist/store-checklist"
                    : "/checklist/all-checklist"
                }
                className="submenu-item"
              >
                <Button className="cancle-button cancle-hover">
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
              </Link>
              {/* <Link to="/checklist/all-checklist" className="submenu-item"> */}
              <Button
                className="header-add cancle-hover"
                type="submit"
                style={{marginRight:5,height:"42px", borderRadius:"8px"}}
                // onClick={handleAssign}
              >
                <AddOutlinedIcon sx={{ mr: 1 }} />
                Assign
              </Button>
              {/* </Link> */}
              <div style={{marginTop:"8px",marginRight:"8px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Assign Checklist Page:</strong>
                            <p>1.You can Either select user role or users to assign checklist or Audits
                             Selecting Role - Activates the current assignment for all users under that role.
                             Selecting Users - Activates the current assignment for specific users only.
                             </p>

                          <p>2.select reports:  Select Here To Send Report.</p>

                          <p>Showing sub role list - Select only if you need to send email report else leave it</p>

                         <p>3.Frequency defines the rate of occurrence.</p>

                        <p>For example:
                            Weeky Frequency enables you to filter week day.
                            Monthly frequency enables the month day filter.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AllchecklistHead;

export const ViewHeader = ({ tableData }) => {
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: "#F6F8FC",
          width: "100%",
          borderRadius: "8px",
          mt: 5,
          p: 1,
        }}
        alignItems="center"
        className="header-card"
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Stack direction="row" spacing={1} className="date-content">
            <div className="nowrap-time">Created :</div>
            <strong className="nowrap-time">
             {moment(tableData?.createdAt).format("MMMM D, YYYY h:mm A")}
            </strong>
            <div className="nowrap-time">Last Modified :</div>
            <strong className="nowrap-time">
             {moment(tableData?.updatedAt).format("MMMM D, YYYY h:mm A")}
            </strong>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right", mr: 1}}
            className="date-content"
          >
            {/* <Button variant="outlined" className="search-bar button-responsive">
              Enable <ToggleSwitch {...label} />
            </Button>
            <Button
              variant="outlined"
              color="error"
              className="button-responsive"
            >
              <DeleteOutlineIcon sx={{ mr: 1 }} /> Delete
            </Button> */}
            {/* <Button
              variant="contained"
              color="warning"
              className="button-responsive"
            >
              <BorderColorIcon sx={{ mr: 1 }} /> Edit
            </Button> */}
            <Link
              to="/checklist/all-checklist"
              className="submenu-item button-responsive"
            >
              <Button
                variant="outlined"
                className="done-btn button-responsive cancle-hover"
              >
                <DoneIcon sx={{ mr: 1 }} /> Done
              </Button>
            </Link>
            <div style={{marginTop:"8px",marginRight:"-4px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
