/** @format */

import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  TablePagination,
  IconButton,
  TableFooter,
  Card,
  Typography,
  Tooltip,
} from "@mui/material";
import View from "../images/View.svg";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import { useNavigate } from "react-router-dom";
import TableHeader from "./pages/Header";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteDepartmentSuccess,
  EditDepartmentSuccess,
  ListDepartmentApi,
} from "../redux/actions/Department";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import PropTypes from "prop-types";
import { DeleteDepartmentApi } from "../redux/actions/Department";
import { ArchivedListDepartmentApi } from "../redux/actions/Department";
// import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Progressbar from "../GlobalProgress/Progressbar";
import Highlighter from "react-highlight-words";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";

export const Department = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [permission, setPermission] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [departmentData, setDepartmentData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [localPageNo, setLocalPageNo] = useState(1);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });

  const departmentList = useSelector(
    (state) =>
      state.department &&
      state.department.data &&
      state.department.data.data &&
      state.department.data.data.data &&
      state.department.data.data.data.departments
  );

  const archivedDepartmentList = useSelector(
    (state) =>
      state &&
      state.department &&
      state.department?.archivedDepartmrentData &&
      state.department?.archivedDepartmrentData?.data &&
      state.department?.archivedDepartmrentData?.data?.data &&
      state.department?.archivedDepartmrentData?.data?.data?.Departments
  );
  const departmentListDelete = useSelector(
    (state) => state && state?.department && state?.department?.delete
  );
  const totalitems = useSelector(
    (state) =>
      state.department &&
      state.department.data &&
      state.department.data.data &&
      state.department.data.data.data &&
      state.department.data.data.data.paginate
  );

  const archivedTotalItems = useSelector(
    (state) =>
      state &&
      state.department &&
      state.department?.archivedDepartmrentData &&
      state.department?.archivedDepartmrentData?.data &&
      state.department?.archivedDepartmrentData?.data?.data &&
      state.department?.archivedDepartmrentData?.data?.data?.paginate
  );

  const Loader = useSelector(
    (state) => state.department && state.department.loading
  );

  const EditDepartment = useSelector(
    (state) =>
      state.department &&
      state.department.update &&
      state.department.update.data
  );

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      archived: false,
    },
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(()=> {
    dispatch(EditDepartmentSuccess(""))
  }, [EditDepartment])

  useEffect(() => {
    if (departmentList) {
      localStorage.setItem("departmentData", JSON.stringify(departmentList));
    }
  }, [departmentList]);

  useEffect(() => {
    if (checkedArchive) {
      setDepartmentData(archivedDepartmentList);
    } else {
      setDepartmentData(departmentList);
    }
  }, [archivedDepartmentList, departmentList, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(archivedTotalItems?.total_item);
    } else {
      setTotalCount(totalitems?.total_item);
    }
  }, [archivedTotalItems, totalitems, checkedArchive]);

  useEffect(() => {
    if (departmentListDelete) {
      listDepartment(payloadRequest);
      dispatch(DeleteDepartmentSuccess(""));
    }
  }, [departmentListDelete]);

  useEffect(() => {
    if (searchValue == "") {
      var localPageno = localStorage.getItem("pageno");
      payloadRequest.body.pagination.page = localPageno
        ? parseInt(localPageno)
        : 1;
      setLoader(true);
      listDepartment(payloadRequest);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [searchValue]);

  const DepartmentListArchivedApi = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `ListArchivedDepartment?company_id=${id}`;
    dispatch(ArchivedListDepartmentApi(payloadRequest));
  };

  const listDepartment = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `department/index?company_id=${id}`;
    dispatch(ListDepartmentApi(data));
  };

  const handleClickDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this department.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const data = {
              url: BASE_URL + `department/${id}?company_id=${_id}`,
            };
            setLoader(true);
            dispatch(DeleteDepartmentApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleClick = (row) => {
    navigate(`/department/department-details`, { state: row });
  };

  const handleClickEdit = (row) => {
    navigate(`/department/edit-department`, { state: row });
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoader(true);
      payloadRequest.body.search = searchValue;
      payloadRequest.body.pagination.page = 1;
      setPage(1);
      if (checkedArchive === false) {
        listDepartment(payloadRequest);
      } else {
        DepartmentListArchivedApi(payloadRequest);
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const columns = [
    {
      field: "Dept. Name",
      headerName: "Dept. Name",
      renderHeader: () => <strong>{"Dept. Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.deptName) {
          result.push(params?.row?.deptName);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Dept. State",
      headerName: "Dept. State",
      renderHeader: () => <strong>{"Dept. State"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.deptState) {
          result.push(params?.row?.deptState);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Dept. City	",
      headerName: "Dept. City",
      renderHeader: () => <strong>{"Dept. City"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.deptCity) {
          result.push(params?.row?.deptCity);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Dept. Country	",
      headerName: "Dept. Country	",
      renderHeader: () => <strong>{"Dept. Country	"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.deptCountry) {
          result.push(params?.row?.deptCountry);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Manager Name",
      headerName: "Manager Name",
      renderHeader: () => <strong>{"Manager Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.deptManager) {
          result.push(params?.row?.deptManager);
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Manager Email",
      headerName: "Manager Email",
      renderHeader: () => <strong>{"Manager Email"}</strong>,

      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.managerEmail) {
          result.push(params?.row?.managerEmail);
        } else {
          result = ["0%"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Manager Mobile",
      headerName: "Manager Mobile	",
      renderHeader: () => <strong>{"Manager Mobile"}</strong>,

      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.managerMobileNumber) {
          result.push(params?.row?.managerMobileNumber);
        } else {
          result = ["0%"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      hide: checkedArchive === true ? true : false,
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            <Tooltip title="View">
            <img
              src={
                hover.View && indexHover === params?.row?._id ? ViewHover : View
              }
              alt="VIEW"
              style={{
                height: "35px",
                cursor: "pointer",
              }}
              onClick={() => handleClick(params?.row?._id)}
              onMouseOver={() => {
                setHover({ View: true });
                setIndexHover(params?.row?._id);
              }}
              onMouseLeave={() => {
                setHover({ View: false });
              }}
            />
            </Tooltip>
            {permission?.department?.manageDepartment === true ? (
              <Tooltip title="Edit">
              <img
                onClick={() => handleClickEdit(params?.row?._id)}
                src={
                  hover.Edit && indexHover === params?.row?._id
                    ? EditHover
                    : Edit
                }
                alt="Edit"
                style={{
                  height: "35px",
                  cursor: "pointer",
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
              </Tooltip>
            ) : (
              ""
            )}
            {permission?.department?.manageDepartment === true ? (
              <Tooltip title="Delete">
              <img
                onClick={() => handleClickDelete(params?.row?._id)}
                src={
                  hover.Delete && indexHover === params?.row?._id
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{ height: "35px", cursor: "pointer" }}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
      // localStorage.removeItem("pageno");
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    // setPage(newPage + 1);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    payloadRequest.body.pagination.page = newPage + 1;
    // listDepartment(payloadRequest);
    if (checkedArchive === false) {
      payloadRequest.body.search = searchValue;
      listDepartment(payloadRequest);
    } else {
      DepartmentListArchivedApi(payloadRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.pagination.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);

    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    if (checkedArchive === false) {
      listDepartment(payloadRequest);
    } else {
      DepartmentListArchivedApi(payloadRequest);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  return (
    <Box>
      {/* Header of the page */}
      <TableHeader
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        searchValue={searchValue}
        totalitems={totalitems?.total_item}
        checkedArchive={checkedArchive}
        setCheckedArchive={setCheckedArchive}
        page={page}
        per_page={rowsPerPage}
        archivedTotalItems={archivedTotalItems?.total_item}
        setPage={setPage}
        permission={permission}
      />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <SingleGlobalTable
          data={departmentData}
          columns={columns}
          totalCount={totalCount}
          loading={loader}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        {/* {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : departmentData ? (
          <>
            <DataGrid
              disableColumnMenu
              rows={departmentData}
              columns={columns}
              getRowId={(departmentData) => departmentData._id}
              hideFooter={true}
              hideFooterPagination={true}
              className="grid-table"
            />
            <TablePagination
              className="tablepagination"
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: totalitems?.total_item },
              ]}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: { "aria-label": "Items per Page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Items per Page"}
              ActionsComponent={TablePaginationActions}
            />
          </>
        ) : (
          "No Data Found"
        )} */}
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default Department;
