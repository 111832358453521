/** @format */

import React, { useEffect, useState } from "react";
import AssignHeader from "./tableHeader/AssignHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { Avatar, makeStyles, TableFooter, useTheme } from "material-ui-core";
import TablePagination from "@mui/material/TablePagination";
import Store from "../../assets/icons/svg-icons/Store.svg";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ListStoreChecklistApi } from "../redux/actions/Checklist";
import Progressbar from "../GlobalProgress/Progressbar";
import Highlighter from "react-highlight-words";

const StoreChecklist = (title) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [storeChecklistData, setStoreChecklistData] = useState([]);

  const storeCheckList = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.storeCheckList &&
      state.checklist.storeCheckList.data &&
      state.checklist.storeCheckList.data.data &&
      state.checklist.storeCheckList.data.data.Responses
  );

  const totalitems = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.storeCheckList &&
      state.checklist.storeCheckList.data &&
      state.checklist.storeCheckList.data.data &&
      state.checklist.storeCheckList.data.data.paginate
  );
  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  useEffect(() => {
    setStoreChecklistData(storeCheckList);
  }, [storeCheckList]);

  useEffect(() => {
    if (searchValue == "") {
      listStoreChecklist(payloadRequest);
    }
  }, [searchValue]);

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: "",
    },
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    payloadRequest.body.pagination.page = newPage + 1;
    payloadRequest.body.search = searchValue;
    listStoreChecklist(payloadRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.pagination.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.search = searchValue;
    listStoreChecklist(payloadRequest);
  };

  const listStoreChecklist = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `Storelist?company_id=${id}`;
    dispatch(ListStoreChecklistApi(payloadRequest));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      payloadRequest.body.search = searchValue;
      payloadRequest.body.pagination.page = 1;
      setPage(1);
      listStoreChecklist(payloadRequest);
    }
  };

  //  DataGrid Functionality

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          storeChecklistData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Store",
      headerName: "Store",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.Store) {
          result.push(params?.row?.Store);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {/* <Avatar className="message-avatar" style={{ alignSelf: "center" }}>
              <img src={Store} alt="avatar" />
            </Avatar> */}
            <p
              className="text-short"
              style={{
                marginLeft: "10px",
                textAlign: "start",
              }}
            >
              {params?.row?.Store}
            </p>
          </div>
        );
      },
    },
    {
      field: "Location",
      headerName: "Location",
      renderHeader: () => <strong>{"Location"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (typeof params?.row?.Location == "string") {
          result.push(params?.row?.Location);
        } else if (params?.row?.Location) {
          result.push(params?.row?.Location?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Assigned",
      headerName: "Assigned",
      renderHeader: () => <strong>{"Assigned"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (
          params?.row?.role ||
          params?.row?.assignedTo?.name ||
          params?.row?.assignedTo
        ) {
          result.push(
            params?.row?.role
              ? params?.row?.role
              : "" || params?.row?.assignedTo?.name
              ? params?.row?.assignedTo.name
              : params?.row?.assignedTo
          );
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Submitted",
      headerName: "Submitted",
      renderHeader: () => <strong>{"Submitted"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.Submitted) {
          result.push(params?.row?.Submitted);
        } else {
          result = ["0"];
        }
        return result.join(", ");
      },
    },
    {
      field: "In-Progress",
      headerName: "In-Progress",
      renderHeader: () => <strong>{"In-Progress"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (
          params?.row?.role ||
          params?.row?.assignedTo?.name ||
          params?.row?.assignedTo
        ) {
          result.push(
            params?.row?.role
              ? params?.row?.role
              : "" || params?.row?.assignedTo?.name
              ? params?.row?.assignedTo.name
              : params?.row?.assignedTo
          );
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
  ];

  // const handleChangePage = (event, newPage) => {
  //   setPage(event + 1);
  //   payloadRequest.body.pagination.page = event + 1;
  //   listStoreChecklist(payloadRequest);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event, 10));
  //   setPage(1);
  //   payloadRequest.body.pagination.per_page = parseInt(event, 10);
  //   listStoreChecklist(payloadRequest);
  // };

  return (
    <Box>
      <AssignHeader
        searchOnChange={handleSearch}
        searchValue={searchValue}
        handleKeyDown={handleKeyDown}
        userCancle={"storeCancle"}
      />
      {Loader ? (
        <Progressbar />
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            borderRadius: 1,
            overflowX: "auto",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: 8,
            },
          }}
        >
          {/* <TableContainer component={Paper} className="table-container">
            <Table size="large" aria-label="simple table">
              <TableHead sx={{ m: 4 }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "270px" }}>
                    Store
                  </TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">Assigned</TableCell>
                  <TableCell align="center">Submitted</TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    In-Progress
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {storeChecklistData &&
                  storeChecklistData.map((row, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Avatar className="message-avatar">
                              <img src={Store} alt="avatar" />
                            </Avatar>
                            <Stack direction="column">
                              <Typography
                                variant="p"
                                component="b"
                                className="text-short2"
                              >
                                <Highlighter
                                  searchWords={[searchValue]}
                                  textToHighlight={row?.Store}
                                  highlightClassName="highlight"
                                  highlightStyle={{
                                    backgroundColor: "#ffff00",
                                  }}
                                  unhighlightClassName="unhighlight"
                                  autoEscape={true}
                                />
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          <Highlighter
                            searchWords={[searchValue]}
                            textToHighlight={row?.Location}
                            highlightClassName="highlight"
                            highlightStyle={{
                              backgroundColor: "#ffff00",
                            }}
                            unhighlightClassName="unhighlight"
                            autoEscape={true}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          N/A
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          {row?.Submitted}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#27364b" }}>
                          N/A
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className="tablepagination"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: totalitems?.total_item },
                    ]}
                    count={totalitems?.total_item}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "Items per Page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Items per Page"}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer> */}
          {storeChecklistData && storeChecklistData?.length > 0 ? (
            <>
              <DataGrid
                disableColumnMenu
                rows={storeChecklistData}
                columns={columns}
                getRowId={(storeChecklistData) => Math.random()}
                getRowHeight={() => "auto"}
                hideFooterPagination={true}
                hideFooter={true}
                className="grid-table"
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalitems?.total_item },
                ]}
                count={totalitems?.total_item}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            <p>No data Found</p>
          )}
        </Box>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default StoreChecklist;
