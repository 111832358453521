import React, { useEffect, useState } from "react";
import { FormControl, Box, TextField, Grid, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { addGroupData } from "../../../redux/actions/AddGroup";
import * as yup from "yup";

const AddGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [card, setCart] = useState([]);
  const AddGroupData = useSelector((state) => state.addgroup);
  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string().required("List item is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      id: uuidv4(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values) {
        let localData = localStorage.getItem("questionsGroupOfDatamanager");
        let localParse = JSON.parse(localData);
        let add = localParse;
        add.push(values);
        localStorage.setItem(
          "questionsGroupOfDatamanager",
          JSON.stringify(add)
        );
        navigate("/questions");
        // dispatch(addGroupData(values))
      }
    },
  });

  // useEffect(()=>{
  //     if(AddGroupData && AddGroupData.AddGroup){
  //         // setCart({card: [...card, AddGroupData.AddGroup]})

  //     }
  // },[AddGroupData])
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/questions" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                {/* <Link to="/questions" className="submenu-item"> */}
                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
                {/* </Link> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px", height: "70vh" }}
        >
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="title"
                onChange={formik.handleChange}
                className="issueinput-fields"
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              <small className="error">
                {formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : null}
              </small>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Description</label>
              <TextField
                id="outlined-basic"
                name="description"
                onChange={formik.handleChange}
                className="issueinput-fields"
                onBlur={formik.handleBlur}
                value={formik.values.description}
                variant="outlined"
                size="small"
                multiline
                rows={4}
              />
            </FormControl>
          </Container>
        </Box>
      </form>
    </>
  );
};

export default AddGroup;
