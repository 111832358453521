import { COMPANY_LIST_ERROR, COMPANY_LIST_REQUEST, COMPANY_LIST_SUCCESS, COMPANY_TYPE_ERROR, COMPANY_TYPE_REQUEST, COMPANY_TYPE_SUCCESS, CREATE_COMPANY_ERROR, CREATE_COMPANY_REQUEST, CREATE_COMPANY_SUCCESS, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COMPANY_ERROR, EDIT_COMPANY_REQUEST, EDIT_COMPANY_SUCCESS, EDIT_COMPANY_ERROR, } from "../types/Types";

const initialState = {
    data: [],
    companylist: [],
    res: '',
    update: '',
    companyData: '',
    loading: false,
    error: '',
 };
 
 const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_TYPE_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case COMPANY_TYPE_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case COMPANY_TYPE_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case CREATE_COMPANY_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case CREATE_COMPANY_SUCCESS: {
            return {
                ...state,
                res: action.payload,
                loading: false
            }
        }
        case CREATE_COMPANY_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case COMPANY_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                companylist: action.payload,
                loading: false
            }
        }
        case COMPANY_LIST_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case GET_COMPANY_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case GET_COMPANY_SUCCESS: {
            return {
                ...state,
                companyData: action.payload,
                loading: false
            }
        }
        case GET_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case EDIT_COMPANY_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case EDIT_COMPANY_SUCCESS: {
            return {
                ...state,
                update: action.payload,
                loading: false
            }
        }
        case EDIT_COMPANY_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
 }

 export default companyReducer;