import * as React from "react";
import Box from "@mui/material/Box";
import TableHeader from './pages/TableHeader'

export const AllSurvey = () => {
    return (
        <Box>
            <TableHeader />
        </Box>
    );
};

export default AllSurvey;
