/** @format */

import { InputLabel, Stack } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ListStoreApi, ListStoresSuccess } from "../redux/actions/Store";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import axios from "axios";
// import { Box, FormControl, MenuItem, Select } from "material-ui-core";
import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuditorContext } from "../../context/auditorContext";
import Progressbar from "../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";

const LMSAuditorHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stores = useSelector((state) => state);
  const [storeList, setStoreList] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  const [selectStoreId, setselectStoreId] = useState(null);
  const [loader, setLoader] = useState(true);
  const [countLoader, setCountLoader] = useState(false);

  const [selectedStore, setSelectedStore] = useState(null);
  const id = localStorage.getItem("companyIdselect");
  const {
    setcompanyId,
    setstoreId,
    storeId,
    companyId,
    setstoreTitle,
    storeTitle,
  } = useContext(AuditorContext);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    setcompanyId(id);
  }, []);

  const handleNavigation = (name) => {
    if (name === "Assessment") {
      navigate(`/auditor-home/my-assessments`);
    } else if (name === "Training") {
      navigate(`/auditor-home/my-trainings`);
    } else {
      // navigate("/my-certificates");
    }
  };

  useEffect(() => {
    setLoader(true);
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}profile/me?company_id=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setStoreList(res.data.data.store);
        const selectedStore = localStorage.getItem("auditorSelectedStore");
        if (selectedStore) {
          const parseselectedStore = JSON.parse(selectedStore);
          setstoreId(parseselectedStore._id);
          setstoreTitle(parseselectedStore.title);
        } else {
          setstoreId(res.data.data.store[0]._id);
          setstoreTitle(res.data.data.store[0].title);
          localStorage.setItem(
            "auditorSelectedStore",
            JSON.stringify(res.data.data.store[0])
          );
        }
        setLoader(false);
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  const storeChangeApi = () => {
    setCountLoader(true);
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}lms/stats/all?storeId=${storeId}&company_id=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setScoreList(res?.data?.data);
        setCountLoader(false);
      } catch (error) {
        console.error(error);
        setCountLoader(false);
      }
    };
    fetchData();
  };

  useEffect(() => {
    if (storeId && storeList.length) {
      storeChangeApi();
    }
  }, [storeId, storeList]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedStore = storeList.find((elem) => elem._id === selectedValue);
    if (selectedStore) {
      localStorage.setItem(
        "auditorSelectedStore",
        JSON.stringify(selectedStore)
      );
      setstoreId(selectedValue);
      setstoreTitle(selectedStore.title);
    }
  };

  const textMap = {
    Assessment: [
      // `Total: ${scoreList?.assessment?.total || "0"}`,
      `Active: ${scoreList?.assessment?.pending || "0"}`,
      `Certified: ${scoreList?.assessment?.passed || "0"}`,
      `Not Certified: ${scoreList?.assessment?.failed || "0"}`,
    ],
    Training: [
      // `Total: ${scoreList?.training?.total || "0"}`,
      `Active: ${scoreList?.training?.pending || "0"}`,
      `InProgress: ${scoreList?.training?.inProgress || "0"}`,
      `Completed: ${scoreList?.training?.completed || "0"}`,
    ],
    Certificate: [
      // `Total: ${scoreList?.certificate?.total || "0"}`,
      `Active: ${scoreList?.certificate?.pending || "0"}`,
      `Awarded: ${scoreList?.certificate?.awarded || "0"}`,
    ],
  };

  return (
    <Fragment>
      {countLoader || (loader && <ProgressbarTransparent play />)}
      <InputLabel id="store-label" style={{ paddingTop: 1 }}>
        Select Store
      </InputLabel>
      <FormControl
        style={{
          margin: "1rem",
          minWidth: 170,
          borderRadius: "8px",
          height: "42px",
        }}
      >
        {/* <InputLabel id="demo-simple-select-helper-label">
          Select Store
        </InputLabel>
        {loader ? (
          <Progressbar />
        ) : (
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Age"
            onChange={(event) => handleChange(event.target.value)}
            value={storeId}
          >
            {storeList?.map((elem) => (
              <MenuItem key={elem._id} value={elem._id}>
                {elem.title}
              </MenuItem>
            ))}
          </Select>
        )} */}
        {/* <InputLabel id="store-label">Select Store</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={storeId || ""}
          onChange={handleChange}
        >
          {storeList.map((store) => (
            <MenuItem key={store._id} value={store._id}>
              {store.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {
        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            height: "42px",
            borderRadius: "8px",
          }}
        >
          {["Training", "Assessment", "Certificate"].map((elem, index) => {
            const additionalTexts = textMap[elem];
            return (
              <>
                <div
                  _ngcontent-fjg-c313=""
                  className="card-body"
                  onClick={() => handleNavigation(elem)}
                  style={{
                    backgroundColor: "#113644",
                    width: "30%",
                    height: "260px",
                    justifyContent: "center",
                    borderRadius: "20px",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <div
                    _ngcontent-fjg-c313=""
                    width="80"
                    className="mx-auto mb-2"
                    style={{ marginTop: "25px" }}
                  >
                    <svg
                      _ngcontent-fjg-c313=""
                      enable-background="new 0 0 60 58"
                      height="80"
                      viewBox="0 0 60 58"
                      width="80"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                    >
                      <path
                        _ngcontent-fjg-c313=""
                        d="m30 26c-.146 0-.291-.032-.426-.095l-17-8c-.35-.165-.574-.518-.574-.905s.224-.74.574-.905l17-8c.27-.127.582-.127.852 0l17 8c.35.165.574.518.574.905s-.224.74-.574.905l-17 8c-.135.063-.28.095-.426.095zm-14.651-9 14.651 6.895 14.651-6.895-14.651-6.895z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m30 34c-.153 0-.307-.035-.447-.105l-12-6c-.339-.17-.553-.516-.553-.895v-7.647c0-.342.175-.661.464-.844.289-.184.652-.205.961-.061l11.575 5.447 11.574-5.447c.31-.145.673-.123.961.061.29.183.465.502.465.844v7.647c0 .379-.214.725-.553.895l-12 6c-.14.07-.294.105-.447.105zm-11-7.618 11 5.5 11-5.5v-5.453l-10.574 4.976c-.27.127-.582.127-.852 0l-10.574-4.976z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m47 18h-17c-.552 0-1-.448-1-1s.448-1 1-1h17c.552 0 1 .448 1 1s-.448 1-1 1z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m47 27c-.552 0-1-.448-1-1v-9c0-.552.448-1 1-1s1 .448 1 1v9c0 .552-.448 1-1 1z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m30 20c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4c0 .552-.448 1-1 1z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m44.999 30c-.19 0-.383-.054-.554-.168-.459-.306-.583-.927-.277-1.387l2-3c.306-.46.926-.585 1.387-.277.459.306.583.927.277 1.387l-2 3c-.193.289-.51.445-.833.445z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m49.001 30c-.323 0-.64-.156-.833-.445l-2-3c-.306-.459-.182-1.081.277-1.387.459-.308 1.08-.183 1.387.277l2 3c.306.459.182 1.081-.277 1.387-.171.114-.364.168-.554.168z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m30 29c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1s1 .448 1 1v3c0 .552-.448 1-1 1z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m59 38h-58c-.552 0-1-.448-1-1v-32.999c0-2.206 1.795-4.001 4.001-4.001h51.998c2.206 0 4.001 1.795 4.001 4.001v32.999c0 .552-.448 1-1 1zm-57-2h56v-31.999c0-1.104-.897-2.001-2.001-2.001h-51.998c-1.104 0-2.001.897-2.001 2.001z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m55.999 46h-51.998c-2.206 0-4.001-1.795-4.001-4.001v-4.999c0-.552.448-1 1-1h58c.552 0 1 .448 1 1v4.999c0 2.206-1.795 4.001-4.001 4.001zm-53.999-8v3.999c0 1.104.897 2.001 2.001 2.001h51.998c1.104 0 2.001-.897 2.001-2.001v-3.999z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m33 42h-6c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1s-.448 1-1 1z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m55 38h-50c-.552 0-1-.448-1-1v-32c0-.552.448-1 1-1h50c.552 0 1 .448 1 1v32c0 .552-.448 1-1 1zm-49-2h48v-30h-48z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m43 58h-26c-1.654 0-3-1.346-3-3s1.346-3 3-3h26c1.654 0 3 1.346 3 3s-1.346 3-3 3zm-26-4c-.551 0-1 .449-1 1s.449 1 1 1h26c.551 0 1-.449 1-1s-.449-1-1-1z"
                      ></path>
                      <path
                        _ngcontent-fjg-c313=""
                        d="m41 54h-22c-.552 0-1-.448-1-1s.448-1 1-1c3.318 0 5-2.355 5-7 0-.552.448-1 1-1h10c.552 0 1 .448 1 1 0 4.645 1.682 7 5 7 .552 0 1 .448 1 1s-.448 1-1 1zm-17.129-2h12.259c-1.232-1.364-1.957-3.395-2.102-6h-8.055c-.146 2.605-.87 4.636-2.102 6z"
                      ></path>
                    </svg>
                  </div>
                  <h5
                    _ngcontent-fjg-c313=""
                    className="card-title mb-0"
                    style={{ color: "white", textAlign: "center" }}
                  >
                    {" "}
                    {elem}{" "}
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1rem",
                      color: "white",
                    }}
                  >
                    {additionalTexts.map((item) => (
                      <span>{item}</span>
                    ))}
                  </div>
                </div>
              </>
            );
          })}
        </Stack>
      }
    </Fragment>
  );
};

export default LMSAuditorHome;
