import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BASE_URL } from '../../../redux/apiConfig/BaseUrl';
import { ProfileMeGetApiAction, UpdateMyProfileApi } from '../../../redux/actions/MyProfileDetailsAction';

const Preferences = () => {

    const [newFieldValue, setNewFieldValue] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [profileMeData, setProfileMeData] = React.useState([]);
    const [disabled, setDisabled] = useState(true);

    const [listSelect, setListSelect] = React.useState("");
    const [singleSelect, setSingleSelect] = React.useState("");
    const [decimalPoint, setDecimalPoint] = React.useState("");


    useEffect(() => {
        setProfileMeData(profileMeData);
        setNewFieldValue({
            // lgview: listSelect,
            // dataview: singleSelect,
            // accuracy: decimalPoint
        })
    }, [profileMeData]);

    useEffect(() => {
        ProfileMeGetAPI();
    }, []);

    // console.log("lgview",listSelect);
    // console.log("dataview",singleSelect);
    // console.log("accuracy",decimalPoint);

    const HandledListGridView = (e) => {
        // console.log("eeeee", e.target.value);
        const val2 = e.target.value;
        setListSelect(e.target.value);

        const data = {
        }
        const preferences = {
            // lgview: listSelect,
            // dataview: singleSelect,
            // accuracy: decimalPoint,
            accuracy: val2,
            dataview: "single",
            lgview: "list",
        }
        data.body = preferences;
        data.url = BASE_URL + `profile/me`;
        dispatch(UpdateMyProfileApi(data));
    }

    const HandledSingleGridView = (e) => {
        const val = e.target.value;
        setSingleSelect(e.target.value);
        const data = {
        }
        const preferences = {
            // lgview: listSelect,
            // dataview: singleSelect,
            // accuracy: decimalPoint,
            accuracy: val,
            dataview: "single",
            lgview: "list",
        }
        data.body = preferences;
        data.url = BASE_URL + `profile/me`;
        dispatch(UpdateMyProfileApi(data));
    }
    
    const HandledDecimalPoint = (e) => {
        const decimalPoint = e.target.value;
        setDecimalPoint(e.target.value);
        const data = {
        }
        const preferences = {
            // accuracy: decimalPoint,
            // dataview: listSelect,
            // lgview: singleSelect,
            accuracy: decimalPoint,
            dataview: "single",
            lgview: "list",
        }
        data.body = preferences;
        data.url = BASE_URL + `profile/me`;
        dispatch(UpdateMyProfileApi(data));
    }

    const validationSchema = Yup.object({
        // lgview: Yup.string("Enter your password")
        // .min(8, "Password should be of minimum 8 characters length")
        // .required("Password is required"),
    });
    const formik = useFormik({
        initialValues: newFieldValue || {
            lgview: "",
            dataView: "",
            accuracy: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,

        onSubmit: (values) => {
            console.log("values", values);
            // let formData = {
            //     lgview: values.lgview,
            //     dataView: values?.dataView,
            //     accuracy: values?.accuracy,
            // }
            // const allData = {
            //     body: formData
            // };
            // if (allData) {
            //     allData.url = BASE_URL + `profile/me`;
            //     dispatch(UpdateMyProfileApi(allData));
            // }
        }
    });

    const ProfileMeGetAPI = () => {
        const data = { url: BASE_URL + `profile/me` };
        dispatch(ProfileMeGetApiAction(data));
    };

    return (
        <div>
            <form onSubmit={formik.handleSubmit} disabled={disabled}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label className="label-name" style={{ textAlign: "left" }}>List / Grid View</label>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Select
                                size="small"
                                name="lgview"
                                onChange={(e)=>HandledListGridView(e)}
                                // onChange={formik.handleChange}
                                value={formik.values.lgview}
                                error={formik.touched.lgview && Boolean(formik.errors.lgview)}
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                            >
                                <MenuItem value="list" className="select-item">
                                    List
                                </MenuItem>
                                <MenuItem value="Grid" className="select-item">
                                    Grid
                                </MenuItem>
                            </Select>
                            <small className="error">
                                {formik.errors.lgview && formik.touched.lgview
                                    ? formik.errors.lgview
                                    : null}
                            </small>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label className="label-name" style={{ textAlign: "left" }}>Single / Group View</label>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Select
                                size="small"
                                name="dataView"
                                onChange={HandledSingleGridView}
                                // onChange={formik.handleChange}
                                value={formik.values.dataView}
                                error={formik.touched.dataView && Boolean(formik.errors.dataView)}
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                            >
                                <MenuItem value="single" className="select-item">
                                    Single
                                </MenuItem>
                                <MenuItem value="group" className="select-item">
                                    Group
                                </MenuItem>
                            </Select>
                            <small className="error">
                                {formik.errors.dataView && formik.touched.dataView
                                    ? formik.errors.dataView
                                    : null}
                            </small>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label className="label-name" style={{ textAlign: "left" }}>Decimal Point</label>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                        <Select
                                size="small"
                                name="accuracy"
                                onChange={HandledDecimalPoint}
                                // onChange={formik.handleChange}
                                value={formik.values.accuracy}
                                error={formik.touched.accuracy && Boolean(formik.errors.accuracy)}
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                            >
                                <MenuItem value="1" className="select-item">1</MenuItem>
                                <MenuItem value="2" className="select-item">2</MenuItem>
                                <MenuItem value="3" className="select-item">3</MenuItem>
                                <MenuItem value="4" className="select-item">4</MenuItem>
                                <MenuItem value="5" className="select-item">5</MenuItem>
                            </Select>
                            <small className="error">
                                {formik.errors.accuracy && formik.touched.accuracy
                                    ? formik.errors.accuracy
                                    : null}
                            </small>
                        </FormControl>
                    </Grid>
                </Grid>

            </form>
        </div>
    )
}

export default Preferences
