/** @format */

import React from "react";
import { Menu, MenuItem, IconButton, Avatar } from "@mui/material";

const UserProfileMenu = ({ anchorEl, handleClose, handleLogout }) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );
};

export default UserProfileMenu;
