/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import { toast } from "react-toastify";
import {
  COMPANY_LIST_ERROR,
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_TYPE_REQUEST,
  COMPANY_TYPE_SUCCESS,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
} from "../types/Types";

export const ComapanyTypeRquest = () => ({
  type: COMPANY_TYPE_REQUEST,
});
export const ComapanyTypeSuccess = (type) => ({
  type: COMPANY_TYPE_SUCCESS,
  payload: type,
});
export const ComapanyTypeFailed = (error) => ({
  type: COMPANY_TYPE_SUCCESS,
  payload: error,
});

export const CompanyTypeApi = (data) => (dispatch) => {
  try {
    dispatch(ComapanyTypeRquest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(ComapanyTypeSuccess(data));
      } else {
        dispatch(ComapanyTypeFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ComapanyTypeFailed(error));
  }
};

export const CreateCompanyRquest = (company) => ({
  type: CREATE_COMPANY_REQUEST,
  payload: company,
});
export const CreateCompanySuccess = (company) => ({
  type: CREATE_COMPANY_SUCCESS,
  payload: company,
});
export const CreateCompanyFailed = (error) => ({
  type: CREATE_COMPANY_ERROR,
  payload: error,
});

export const CreateCompanyApi = (data) => (dispatch) => {
  try {
    dispatch(CreateCompanyRquest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CreateCompanySuccess(data));
      } else {
        dispatch(CreateCompanyFailed(response?.statusText));
        toast.error(`${response.response?.data?.errors[0]?.message}`);
        console.log("response", response);
      }
    });
  } catch (error) {
    dispatch(CreateCompanyFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const CompanyListRquest = () => ({
  type: COMPANY_LIST_REQUEST,
});
export const CompanyListSuccess = (list) => ({
  type: COMPANY_LIST_SUCCESS,
  payload: list,
});
export const CompanyListFailed = (error) => ({
  type: COMPANY_LIST_ERROR,
  payload: error,
});

export const CompanyListApi = (data) => (dispatch) => {
  try {
    dispatch(CompanyListRquest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CompanyListSuccess(data));
      } else {
        dispatch(CompanyListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(CompanyListFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const CompanyGetRequest = (data) => ({
  type: GET_COMPANY_REQUEST,
  payload: data,
});
export const CompanyGetSuccess = (data) => ({
  type: GET_COMPANY_SUCCESS,
  payload: data,
});
export const CompanyGetFailed = (error) => ({
  type: GET_COMPANY_ERROR,
  payload: error,
});

export const CompanyGetApi = (data) => (dispatch) => {
  try {
    dispatch(CompanyGetRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CompanyGetSuccess(data));
      } else {
        dispatch(CompanyGetFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(CompanyGetFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const EditCompanyRquest = (company) => ({
  type: EDIT_COMPANY_REQUEST,
  payload: company,
});
export const EditCompanySuccess = (company) => ({
  type: EDIT_COMPANY_SUCCESS,
  payload: company,
});
export const EditCompanyFailed = (error) => ({
  type: EDIT_COMPANY_ERROR,
  payload: error,
});

export const EditCompanyApi = (data) => (dispatch) => {
  try {
    dispatch(EditCompanyRquest());
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(EditCompanySuccess(data));
        toast.success(`${resp?.data?.data?.message}`);
        console.log("editComp", data);
      } else {
        dispatch(EditCompanyFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditCompanyFailed(error));
    console.error("Something went Wrong", error);
  }
};
