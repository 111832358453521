import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

const ChartSection = () => {

  const [progress, setProgress] = useState();
  const getChecklistDetails = useSelector(state => state.checklist && state.checklist.submissionView && state.checklist.submissionView.data
    && state.checklist.submissionView.data.data);

  useEffect(() => {
    if (getChecklistDetails && getChecklistDetails.completePercentage) {
      let val = getChecklistDetails && getChecklistDetails.completePercentage.toFixed(2);
      setProgress(val);
    }
  }, [getChecklistDetails]);

  const series = [progress];
  const options = {
    colors: ['#039855'],
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
          background: 'none',
          position: 'front',
        },
        track: {
          show: true,
          background: '#F6F8FC',
          strokeWidth: '97%',
          opacity: 1,
          margin: 3,
        },
        dataLabels: {
          total: {
            show: false,
          },
          value: {
            show: true,
          }
        },
      },
    },
    labels: ['Progress'],
    stroke: {
      lineCap: 'round'
    },
  }

  return (
    <Box>
      <Chart
        options={options}
        series={series}
        type="radialBar"
        height={200}
      />
    </Box>
  );
};

export default ChartSection;
