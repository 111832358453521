import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, FormControl, TextField, MenuItem } from 'material-ui-core';
import attechment from '../images/Blank.png';
import { Checkbox } from '@mui/material';
import { useState, useEffect } from 'react';
import { useFormik } from "formik";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(5),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CompanyModal = (props) => {

    const [images, setImages] = useState();
    const [companyValue, setCompanyValue] = useState({})

    //Get company data
    const companyData = props?.editCompany;
    useEffect(() => {
        setCompanyValue(companyData);
    }, [companyData]);

    const formik = useFormik({
        initialValues: {

        },
        onSubmit: (values) => {
            
        },
    });

    let multiimageUrl =
        images &&
        images?.map((item) => {
            return item?.url;
        });

    const handleImageUpload = (e) => {
        const tempArr = [];

        [...e.target.files].forEach((file) => {

            tempArr.push({
                data: file,
                url: URL.createObjectURL(file),
            });
        });

        setImages(tempArr);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    <Typography variant="p" component="div" className="heading">Vishnu</Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box>
                        {multiimageUrl?.length > 0 ? (
                            multiimageUrl &&
                            multiimageUrl?.map((item) => {
                                return (
                                    <img
                                        className='logo-select logo-img'
                                        src={item}
                                        alt="attachment"
                                    />

                                );
                            })
                        ) : (
                            <img
                                className='logo-select logo-img'
                                src={attechment}
                                alt="attachment"
                            />

                        )}
                    </Box>
                    <Box>
                        <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            hidden
                            onChange={handleImageUpload}
                        />
                        <label htmlFor="contained-button-file" className='modal-btn'>
                            Change Logo
                        </label>
                    </Box>

                    <FormControl fullWidth>
                        <label style={{ marginTop: '20px' }}>Company Name</label>
                        <TextField id="outlined-basic" size='small' variant="outlined"
                            name='companyName'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name ? formik.values.name : companyValue.name}
                            error={formik.touched.companyName && Boolean(formik.errors.companyName)} />
                    </FormControl>

                    <FormControl className='checks'>
                        <Checkbox size="medium" /><label>Retail Audit</label>
                    </FormControl>
                    <br />
                    <FormControl className='checks'>
                        <Checkbox size="medium" /><label>Ice</label>
                    </FormControl>

                    <FormControl fullWidth>
                        <label style={{ marginTop: '20px' }}>Image Category</label>
                        <TextField id="outlined-basic" variant="outlined" size='small' select
                            name='imgCategory'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.imgCategory ? formik.values.name : companyValue.imgCategory}
                            error={formik.touched.imgCategory && Boolean(formik.errors.imgCategory)}>
                            <MenuItem value="both">Both</MenuItem>
                            <MenuItem value="photo">Photo</MenuItem>
                            <MenuItem value="galary">Gallery</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' autoFocus onClick={props.handleClose} color="success">Update</Button>
                    <Button variant='contained' onClick={props.handleClose}>Cancel</Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default CompanyModal