import {
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_ERROR,
} from "../types/Types";

const initialState = {
  error: "",
  DeleteFormData: "",
};

const DeleteFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_FORM_REQUEST: {
      return {
        ...state,
        error: "",
      };
    }
    case DELETE_FORM_SUCCESS: {
      return {
        ...state,
        DeleteFormData: action?.payload,
      };
    }
    case DELETE_FORM_ERROR: {
      return {
        ...state,
        error: action?.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default DeleteFormReducer;
