/** @format */

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import POHeader from "./Pages/POHeader";
import SPRHeader from "./Pages/SPRHeader";
import UPRHeader from "./Pages/UPRHeader";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import {
  CR_Graph_Api,
  CR_LeftGraph_Api,
  StorePerformanceCountAction,
  UserPerformanceCountAction,
} from "../../redux/actions/Checklist";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Progressbar from "../../GlobalProgress/Progressbar";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ListSubroleApi } from "../../redux/actions/Roles";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";

const defaultColumns = [
  {
    field: "NAME",
    headerName: "NAME",
    renderHeader: () => (
      <Tooltip title="NAME">
        <strong>{"NAME"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Name) {
        result.push(params?.row?.Name);
      } else {
        result = ["N/A"];
      }
      return result.join(", ");
    },
  },
  {
    field: "ASSIGNED",
    headerName: "ASSIGNED",
    renderHeader: () => (
      <Tooltip title="ASSIGNED">
        <strong>{"ASSIGNED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalcreated) {
        result.push(params?.row?.Totalcreated ? params?.row?.Totalcreated : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "COMPLETED",
    headerName: "COMPLETED",
    renderHeader: () => (
      <Tooltip title="COMPLETED">
        <strong>{"COMPLETED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalsubmit) {
        result.push(params?.row?.Totalsubmit ? params?.row?.Totalsubmit : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "MISSED",
    headerName: "MISSED",
    renderHeader: () => (
      <Tooltip title="MISSED">
        <strong>{"MISSED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalmissed) {
        result.push(params?.row?.Totalmissed ? params?.row?.Totalmissed : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "LOCATION",
    headerName: "LOCATION",
    renderHeader: () => (
      <Tooltip title="LOCATION">
        <strong>{"LOCATION"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Location) {
        result.push(params?.row?.Location ? params?.row?.Location : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
];

const PerformanceOverview = () => {
  const dispatch = useDispatch();
  // const storedMonthRecord = JSON.parse(localStorage.getItem("monthRecord"));
  // const [monthRecord, setmonthRecord] = React.useState(storedMonthRecord);
  const [monthRecord, setmonthRecord] = React.useState("OneDay");
  const navigate = useNavigate();
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({ View: false });
  const [LeftGraphDynamicData, setLeftGraphDynamicData] = React.useState([]);
  const [GraphDynamicData, setGraphDynamicData] = React.useState([]);
  const [DynamicTop5User, setDynamicTop5User] = React.useState([]);
  const [DynamicBottom5User, setDynamicBottom5User] = React.useState([]);
  const [DynamicTop5Store, setDynamicTop5Store] = React.useState([]);
  const [DynamicBottom5Store, setDynamicBottom5Store] = React.useState([]);
  const [DateData, setDateData] = React.useState([]);
  const [SeriesData, setSeriesData] = React.useState([]);
  const [totalSubmissions, setTotalSubmissions] = React.useState(0);
  const [showTable, setShowTable] = useState(false);
  const [showRighGraph, setShowRighGraph] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [subRole, setSubRole] = useState([]);
  const [allSubRoleNames, setallSubRoleNames] = useState([]);
  const [loader, setLoader] = useState(true);

  const handleShowTableClick = () => {
    setShowTable(!showTable);
    setShowRighGraph(!showRighGraph);
  };

  const handleClickStoreView = (row) => {
    localStorage.removeItem("UserIdFromFromDashBoard");
    localStorage.removeItem("UserIdFromViewAllUserList");
    localStorage.removeItem("UserIdFromSingleStoreViewPage");
    localStorage.removeItem("StoreIdFromDashBoard");
    localStorage.removeItem("StoreIdFromViewAllStoreList");

    localStorage.removeItem("UserNameFromFromDashBoard");
    localStorage.removeItem("UserNameFromViewAllUserList");
    localStorage.removeItem("UserNameFromSingleStoreViewPage");
    localStorage.removeItem("StoreNameFromDashBoard");
    localStorage.removeItem("StoreNameFromViewAllStoreList");

    localStorage.setItem("StoreIdFromDashBoard", row?._id);
    localStorage.setItem("StoreNameFromDashBoard", row?.Name);

    navigate("/checklist/checklist-report/store-performance-view", {
      state: row,
    });
  };

  const handleClickUserView = (row) => {
    localStorage.removeItem("UserIdFromFromDashBoard");
    localStorage.removeItem("UserIdFromViewAllUserList");
    localStorage.removeItem("UserIdFromSingleStoreViewPage");
    localStorage.removeItem("StoreIdFromDashBoard");
    localStorage.removeItem("StoreIdFromViewAllStoreList");

    localStorage.removeItem("UserNameFromFromDashBoard");
    localStorage.removeItem("UserNameFromViewAllUserList");
    localStorage.removeItem("UserNameFromSingleStoreViewPage");
    localStorage.removeItem("StoreNameFromDashBoard");
    localStorage.removeItem("StoreNameFromViewAllStoreList");

    localStorage.setItem("UserIdFromFromDashBoard", row?._id);
    localStorage.setItem("UserNameFromFromDashBoard", row?.Name);
    navigate("/checklist/checklist-report/user-performance-view", {
      state: row,
    });
  };

  const [userColumns, setUserColumns] = useState([]);
  const [storeColumns, setStoreColumns] = useState([]);

  var chart = {
    series: [
      {
        name: "Total submitted checklist",
        data: SeriesData,
        // data: monthWiseShowPercentage
      },
    ],

    options: {
      colors: ["#039855"],
      chart: {
        toolbar: {
          show: false,
        },
      },
      theme: { mode: "light" },

      xaxis: {
        // grid: true,
        tickPlacement: "on",
        categories: DateData,
        title: {
          // text: "-0------------------0---------------------------0-------------------0--------------",
          style: { color: "#27364B", fontSize: 15 },
        },
      },

      yaxis: {
        labels: {
          formatter: (val) => {
            return `${val}`;
          },
          style: { fontSize: "15", colors: ["#5A5A5A"] },
        },
        title: {
          style: { color: "#5A5A5A", fontSize: 15 },
        },
      },

      legend: {
        show: true,
        position: "right",
      },

      dataLabels: {
        formatter: (val) => {
          return `${val}`;
        },
        style: {
          colors: ["#f4f4f4"],
          fontSize: 15,
        },
      },
    },
  };

  const grapLoading = useSelector(
    (state) => state && state?.checklist && state?.checklist?.grapLoading
  );
  const urcLoading = useSelector(
    (state) => state && state?.checklist && state?.checklist?.urcLoading
  );
  const cscLoading = useSelector(
    (state) => state && state?.checklist && state?.checklist?.cscLoading
  );

  const LeftGraphData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkRepLeftGraph &&
      state?.checklist?.checkRepLeftGraph?.data
  );

  const GraphData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkRepGraph &&
      state?.checklist?.checkRepGraph?.data
  );

  const UserPerformanceCountData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkRepUserPerfCount &&
      state?.checklist?.checkRepUserPerfCount?.data &&
      state?.checklist?.checkRepUserPerfCount?.data?.data
  );

  const StorePerformanceCountData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkRepStorePerfCount &&
      state?.checklist?.checkRepStorePerfCount?.data &&
      state?.checklist?.checkRepStorePerfCount?.data?.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const subroleData = useSelector(
    (state) =>
      state.roles &&
      state.roles.subrole &&
      state.roles.subrole.data &&
      state.roles.subrole.data.data
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  useEffect(() => {
    if (subroleData) {
      const nameList = subroleData.map((role) => role?.name);
      setallSubRoleNames(nameList);
    }
  }, [subroleData]);

  useEffect(() => {
    if (selectedSubRole) {
      // setLoader(true);
      setSubRole(selectedSubRole);
      // setTimeout(() => {
      //   setLoader(false);
      // }, 500);
    }
  }, [selectedSubRole]);

  // Prepare chart data
  useEffect(() => {
    if (SeriesData && SeriesData.length) {
      var chart = {
        series: [
          {
            name: "Total submitted checklist",
            data: SeriesData,
            // data: monthWiseShowPercentage
          },
        ],
        options: {
          colors: [
            function ({ value, seriesIndex, w }) {
              if (value > parseInt(totalSubmissions)) {
                return "#039855";
              } else if (value == parseInt(totalSubmissions)) {
                return "#F39A4C";
              } else {
                return "#BB251A";
              }
            },
          ],
          theme: { mode: "light" },
          xaxis: {
            // grid: true,
            tickPlacement: "on",
            categories: DateData,
            title: {
              // text: "-0------------------0---------------------------0-------------------0--------------",
              style: { color: "#27364B", fontSize: 15 },
            },
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: { fontSize: "15", colors: ["#5A5A5A"] },
            },
            title: {
              style: { color: "#5A5A5A", fontSize: 15 },
            },
          },
          legend: {
            show: true,
            position: "right",
          },
          dataLabels: {
            formatter: (val) => {
              return `${val}`;
            },
            style: {
              colors: ["#f4f4f4"],
              fontSize: 15,
            },
          },
        },
      };
      setLoader(true);
      setChartData(chart);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [SeriesData]);
  useEffect(() => {
    if (LeftGraphData) {
      setLeftGraphDynamicData(LeftGraphData);
    }
  }, [LeftGraphData]);
  // console.log("LeftGraphDynamicData", LeftGraphDynamicData);

  useEffect(() => {
    if (GraphData) {
      setGraphDynamicData(GraphData);
    }
  }, [GraphData]);

  useEffect(() => {
    if (StorePerformanceCountData && StorePerformanceCountData.length > 0) {
      const topFiveStores = StorePerformanceCountData.sort(
        (a, b) => b.Totalsubmit - a.Totalsubmit
      ).slice(0, 5);
      setDynamicTop5Store(topFiveStores);
      const bottomFiveStores = StorePerformanceCountData.sort(
        (a, b) => a.Totalsubmit - b.Totalsubmit
      ).slice(0, 5);
      setDynamicBottom5Store(bottomFiveStores);
      // passing data daily weekly monthly dynamic column & values for store checklist
      let totlaColumns = [...defaultColumns];
      const index = 0;
      if (monthRecord == "OneDay") {
        const dateVal =
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length &&
          StorePerformanceCountData[0].Submission[0].Date
            ? new Date(StorePerformanceCountData[0].Submission[0].Date)
            : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();
        const dayColumn = {
          field: "Submission",
          headerName: "Submission",
          renderHeader: () => (
            <Tooltip title={dateRes}>
              <strong>{dateRes}</strong>
            </Tooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let result = [];
            // const dateValCount =
            //   params.row &&
            //     params.row.Submission &&
            //     params.row.Submission[0] &&
            //     params.row.Submission[0].submissionCount
            //     ? (params.row.Submission[index].submissionCount /
            //       params.row.Submission[index].checklistCount) *
            //     100
            //     : 0;
            // result.push(Math.round(dateValCount) + "%");

            const dateValCount =
              params.row &&
              params.row.Submission &&
              params.row.Submission[0] &&
              params.row.Submission[0].submissionCount &&
              params.row.Submission[0].checklistCount &&
              params.row.Submission[0].checklistCount !== 0
                ? (params.row.Submission[index].submissionCount /
                    params.row.Submission[index].checklistCount) *
                  100
                : 0;

            const formattedDateValCount = isFinite(dateValCount)
              ? Math.round(dateValCount) + "%"
              : "0";

            result.push(formattedDateValCount);

            return result.join(", ");
          },
        };
        totlaColumns[3] = {
          ...totlaColumns[3],
          field: "REMAINING",
          headerName: "REMAINING",
          renderHeader: () => (
            <Tooltip title="REMAINING">
              <strong>{"REMAINING"}</strong>
            </Tooltip>
          )
        };
        totlaColumns.push(dayColumn);
      } else if (monthRecord == "OneWeek") {
        if (
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length
        ) {
          StorePerformanceCountData[0].Submission &&
            StorePerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes =
                dateVal
                  .toLocaleString("en-us", { weekday: "short" })
                  .toUpperCase() +
                " " +
                (dateVal.getDate() < 10
                  ? "0" + dateVal.getDate()
                  : dateVal.getDate()) +
                " " +
                dateVal
                  .toLocaleString("en-us", { month: "short" })
                  .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  // const dateValCount =
                  //   params.row &&
                  //   params.row.Submission &&
                  //   params.row.Submission[0] &&
                  //   params.row.Submission[index].submissionCount
                  //     ? (params.row.Submission[index].submissionCount /
                  //         params.row.Submission[index].checklistCount) *
                  //       100
                  //     : 0;
                  let result = [];
                  // result.push(Math.round(dateValCount) + "%");

                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[0].submissionCount &&
                    params.row.Submission[0].checklistCount &&
                    params.row.Submission[0].checklistCount !== 0
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;

                  const formattedDateValCount = isFinite(dateValCount)
                    ? Math.round(dateValCount) + "%"
                    : "0";

                  result.push(formattedDateValCount);
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      } else {
        if (
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length
        ) {
          StorePerformanceCountData[0].Submission &&
            StorePerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes = dateVal
                .toLocaleString("en-us", {
                  month: "short",
                  year: "numeric",
                })
                .toUpperCase();
              // const dateValCount = sDate.submissionCount
              //   ? (sDate.submissionCount / sDate.checklistCount) * 100
              //   : 0;
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  // const dateValCount =
                  //   params.row &&
                  //   params.row.Submission &&
                  //   params.row.Submission[0] &&
                  //   params.row.Submission[index].submissionCount
                  //     ? (params.row.Submission[index].submissionCount /
                  //         params.row.Submission[index].checklistCount) *
                  //       100
                  //     : 0;
                  // result.push(Math.round(dateValCount) + "%");

                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[0].submissionCount &&
                    params.row.Submission[0].checklistCount &&
                    params.row.Submission[0].checklistCount !== 0
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;

                  const formattedDateValCount = isFinite(dateValCount)
                    ? Math.round(dateValCount) + "%"
                    : "0";

                  result.push(formattedDateValCount);

                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      }
      const actionColumn = {
        field: "ACTION",
        headerName: "ACTION",
        renderHeader: () => (
          <Tooltip title="ACTION">
            <strong>{"ACTION"}</strong>
          </Tooltip>
        ),
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <Tooltip title="View">
                <img
                  src={
                    hover.View && indexHover === params?.row?._id
                      ? ViewHover
                      : View
                  }
                  alt="VIEW"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onClick={() => handleClickStoreView(params?.row)}
                  onMouseOver={() => {
                    setHover({ View: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ View: false });
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      };
      totlaColumns.push(actionColumn);
      setStoreColumns([...totlaColumns]);
    }
  }, [StorePerformanceCountData]);

  useEffect(() => {
    if (UserPerformanceCountData && UserPerformanceCountData.length > 0) {
      const topFiveUsers = UserPerformanceCountData.sort(
        (a, b) => b.Totalsubmit - a.Totalsubmit
      ).slice(0, 5);
      setDynamicTop5User(topFiveUsers);
      const bottomFiveUsers = UserPerformanceCountData.sort(
        (a, b) => a.Totalsubmit - b.Totalsubmit
      ).slice(0, 5);
      setDynamicBottom5User(bottomFiveUsers);

      // passing data daily weekly monthly dynamic column & values for user checklist
      let totlaColumns = [...defaultColumns];
      const index = 0;
      if (monthRecord == "OneDay") {
        const dateVal =
          UserPerformanceCountData[0].Submission &&
          UserPerformanceCountData[0].Submission.length &&
          UserPerformanceCountData[0].Submission[0].Date
            ? new Date(UserPerformanceCountData[0].Submission[0].Date)
            : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();
        const dayColumn = {
          field: "Submission",
          headerName: "Submission",
          renderHeader: () => (
            <Tooltip title={dateRes}>
              <strong>{dateRes}</strong>
            </Tooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let result = [];
            // const dateValCount =
            //   params.row &&
            //   params.row.Submission &&
            //   params.row.Submission[0] &&
            //   params.row.Submission[0].submissionCount
            //     ? (params.row.Submission[index].submissionCount /
            //         params.row.Submission[index].checklistCount) *
            //       100
            //     : 0;
            // result.push(Math.round(dateValCount) + "%");
            const dateValCount =
              params.row &&
              params.row.Submission &&
              params.row.Submission[0] &&
              params.row.Submission[0].submissionCount &&
              params.row.Submission[0].checklistCount &&
              params.row.Submission[0].checklistCount !== 0
                ? (params.row.Submission[index].submissionCount /
                    params.row.Submission[index].checklistCount) *
                  100
                : 0;

            const formattedDateValCount = isFinite(dateValCount)
              ? Math.round(dateValCount) + "%"
              : "0";

            result.push(formattedDateValCount);
            return result.join(", ");
          },
        };
        totlaColumns.push(dayColumn);
      } else if (monthRecord == "OneWeek") {
        if (
          UserPerformanceCountData[0].Submission &&
          UserPerformanceCountData[0].Submission.length
        ) {
          UserPerformanceCountData[0].Submission &&
            UserPerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes =
                dateVal
                  .toLocaleString("en-us", { weekday: "short" })
                  .toUpperCase() +
                " " +
                (dateVal.getDate() < 10
                  ? "0" + dateVal.getDate()
                  : dateVal.getDate()) +
                " " +
                dateVal
                  .toLocaleString("en-us", { month: "short" })
                  .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  // const dateValCount =
                  //   params.row &&
                  //   params.row.Submission &&
                  //   params.row.Submission[0] &&
                  //   params.row.Submission[index].submissionCount
                  //     ? (params.row.Submission[index].submissionCount /
                  //         params.row.Submission[index].checklistCount) *
                  //       100
                  //     : 0;
                  // result.push(Math.round(dateValCount) + "%");

                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[0].submissionCount &&
                    params.row.Submission[0].checklistCount &&
                    params.row.Submission[0].checklistCount !== 0
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;

                  const formattedDateValCount = isFinite(dateValCount)
                    ? Math.round(dateValCount) + "%"
                    : "0";

                  result.push(formattedDateValCount);
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      } else {
        if (
          UserPerformanceCountData[0].Submission &&
          UserPerformanceCountData[0].Submission.length
        ) {
          UserPerformanceCountData[0].Submission &&
            UserPerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes = dateVal
                .toLocaleString("en-us", {
                  month: "short",
                  year: "numeric",
                })
                .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  // const dateValCount =
                  //   params.row &&
                  //   params.row.Submission &&
                  //   params.row.Submission[0] &&
                  //   params.row.Submission[index].submissionCount
                  //     ? (params.row.Submission[index].submissionCount /
                  //         params.row.Submission[index].checklistCount) *
                  //       100
                  //     : 0;
                  // result.push(Math.round(dateValCount) + "%");

                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[0].submissionCount &&
                    params.row.Submission[0].checklistCount &&
                    params.row.Submission[0].checklistCount !== 0
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;

                  const formattedDateValCount = isFinite(dateValCount)
                    ? Math.round(dateValCount) + "%"
                    : "0";

                  result.push(formattedDateValCount);
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      }
      const actionColumn = {
        field: "ACTION",
        headerName: "ACTION",
        renderHeader: () => (
          <Tooltip title="ACTION">
            <strong>{"ACTION"}</strong>
          </Tooltip>
        ),
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <Tooltip title="View">
                <img
                  src={
                    hover.View && indexHover === params?.row?._id
                      ? ViewHover
                      : View
                  }
                  alt="VIEW"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onClick={() => handleClickUserView(params?.row)}
                  onMouseOver={() => {
                    setHover({ View: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ View: false });
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      };
      totlaColumns.push(actionColumn);
      // console.log(totlaColumns, monthRecord);
      setUserColumns([...totlaColumns]);
    }
  }, [UserPerformanceCountData]);

  // console.log("DynamicTop5User", DynamicTop5User);

  useEffect(() => {
    LeftGraphDataAPICall();
  }, []);

  useEffect(() => {
    let graphArray = [];
    if (GraphDynamicData && GraphDynamicData?.data) {
      for (let item of GraphDynamicData?.data) {
        graphArray.push(moment(item?.Date).format("ll"));
      }
      setDateData(graphArray);
    }
  }, [GraphDynamicData?.data]);

  useEffect(() => {
    let graphSeriesArray = [];
    let monthWisePercentage = 0;
    var sum1 = 0;
    if (GraphDynamicData && GraphDynamicData.data) {
      for (let item of GraphDynamicData?.data) {
        graphSeriesArray.push(item?.Submitted);
        // let oneColP = Math.round(totalSubmissions / item?.Submitted);
        // graphSeriesArray.push(oneColP);
        sum1 += item?.Submitted;
      }
      setSeriesData(graphSeriesArray);
      const chartAvg = Math.round(
        sum1 ? sum1 / GraphDynamicData?.data?.length : 0
      );
      setTotalSubmissions(chartAvg);
    }
  }, [GraphDynamicData]);

  useEffect(() => {
    if (monthRecord) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `CompanyCount?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(CR_Graph_Api(data));
    }
  }, [monthRecord]);

  const LeftGraphDataAPICall = () => {
    const id = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `CompanyInformation?company_id=${id}`,
    };
    dispatch(CR_LeftGraph_Api(data));
  };

  // User Assigned
  useEffect(() => {
    if (monthRecord && subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `UserChecklistCount?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
        // url: BASE_URL + `UserChecklistCount?company_id=${id}&Filter=OneDay`,
      };
      dispatch(UserPerformanceCountAction(data));
    }
  }, [monthRecord, subRole]);

  // Store Assigned
  useEffect(() => {
    if (monthRecord && subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `StoreChecklistReport?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(StorePerformanceCountAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    if (subRole) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `CompanyCount?company_id=${id}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(CR_Graph_Api(data));
    }
  }, [subRole]);

  // api call on sub role dropdown

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  }, []);

  const handleChangeSelect = (event) => {
    setmonthRecord(event.target.value);
  };

  return (
    <div>
      <POHeader
        handleChangeSelect={handleChangeSelect}
        monthRecord={monthRecord}
      />
      {(grapLoading || urcLoading || cscLoading) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}
      <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
        <CardContent sx={{ p: 4 }} className="check-graph">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={4} sm={12} lg={4}>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
              <Table aria-label="simple table" className="details-table-po">
                <TableBody style={{ overflowX: "auto" }}>
                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Client Name:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {LeftGraphDynamicData?.data?.CompanyName || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Total Stores:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {LeftGraphDynamicData?.data?.Totalstore || "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Total Users:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {LeftGraphDynamicData?.data?.Totaluser || "N/A"}
                    </TableCell>
                  </TableRow>

                  {/* <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Departments:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {LeftGraphDynamicData?.data?.Departments
                        ? LeftGraphDynamicData.data.Departments.join(", ")
                        : "N/A"}
                    </TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Sub-Roles:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      {allSubRoleNames ? allSubRoleNames.join(", ") : "N/A"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="detail-key">
                      <Typography variant="p" className="detail-head">
                        Total Checklists:
                      </Typography>
                    </TableCell>
                    <TableCell className="detail-value capitalize">
                      <label style={{ marginRight: "10px" }}>
                        NoFrequency:
                      </label>
                      {LeftGraphDynamicData?.data?.CheckList?.NoFrequency == 0
                        ? "0"
                        : LeftGraphDynamicData?.data?.CheckList?.NoFrequency}
                      <br />
                      <label style={{ marginRight: "10px" }}>Hourly:</label>
                      {LeftGraphDynamicData?.data?.CheckList?.Hourly == 0
                        ? "0"
                        : LeftGraphDynamicData?.data?.CheckList?.Hourly}
                      <br />
                      <label style={{ marginRight: "10px" }}>Daily:</label>
                      {LeftGraphDynamicData?.data?.CheckList?.Daily == 0
                        ? "0"
                        : LeftGraphDynamicData?.data?.CheckList?.Daily}
                      <br />
                      <label style={{ marginRight: "10px" }}>Weekly:</label>
                      {LeftGraphDynamicData?.data?.CheckList?.Weekly == 0
                        ? "0"
                        : LeftGraphDynamicData?.data?.CheckList?.Weekly}
                      <br />
                      <label style={{ marginRight: "10px" }}>Monthly:</label>
                      {LeftGraphDynamicData?.data?.CheckList?.Monthly == 0
                        ? "0"
                        : LeftGraphDynamicData?.data?.CheckList?.Monthly}
                      <br />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* </Grid> */}
            </Grid>

            {/* graph started */}
            <Grid item xs={12} md={8} sm={12} lg={8}>
              {/* show button table  started */}
              {showTable && (
                <TableContainer component={Paper} style={{ maxHeight: 200 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#D6F3E9" }}>
                        <TableCell
                          align="left"
                          className="tableHead"
                          sx={{ minWidth: 150 }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          align="center"
                          className="tableHead"
                          sx={{ minWidth: 150 }}
                        >
                          Submitted On
                        </TableCell>
                        <TableCell
                          align="center"
                          className="tableHead"
                          sx={{ minWidth: 200 }}
                        >
                          Total Submissions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {GraphDynamicData &&
                        GraphDynamicData?.data.length > 0 &&
                        GraphDynamicData?.data.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell align="center">
                              {moment(row?.Date).format("lll") || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {row?.Submitted || "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {/* show button table  ended  */}
              {showRighGraph && (
                <Card
                  sx={{ minWidth: 275, px: 1, overflowX: "auto" }}
                  className="card"
                >
                  <div align="right" style={{ marginTop: "10px" }}>
                    <Tooltip
                      title={
                        <div style={{ maxWidth: "300px" }}>
                          <strong>Advance Checklist Wise Report:</strong>
                          <p>
                            Status: Display's selected checklist completion
                            status, completion percentage & average compliance
                            scores for the selected user and stores.
                          </p>
                        </div>
                      }
                      arrow
                      interactive
                    >
                      <InfoIcon
                        style={{
                          verticalAlign: "middle",
                          cursor: "pointer",
                          color: "#D76C00",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <CardContent>
                    <Box>
                      <Stack direction="row" sx={{ display: "inline-flex" }}>
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ fontSize: "24px", px: 2 }}
                        >
                          Overall Performance:
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ color: "#F39A4C", fontWeight: 600 }}
                        >
                          {/* Below calculating average  */}
                          {/* {Math.round(
                            totalSubmissions
                              ? totalSubmissions /
                                  GraphDynamicData?.data?.length
                              : 0
                          )} */}
                          {totalSubmissions}
                        </Typography>
                      </Stack>
                    </Box>
                    {chartData ? (
                      <Chart
                        type="bar"
                        height={300}
                        options={chartData && chartData.options}
                        series={chartData && chartData.series}
                      />
                    ) : (
                      "Loading Graph"
                    )}

                    <Box direction="row" className="line-chart-box">
                      <Box className="line-chart-btns1">
                        {/* Line chart will come hare  */}
                        <span>0</span>
                        <span
                          style={{
                            borderRadius: "5px",
                            width: "150px",
                            height: "5px",
                            background: "#BB251A",
                          }}
                        ></span>
                        <span>
                          {Math.round(
                            totalSubmissions > 2 ? totalSubmissions - 2 : 1
                          )}
                        </span>
                        <span
                          style={{
                            borderRadius: "5px",
                            width: "150px",
                            height: "5px",
                            background: "#F39A4C",
                          }}
                        ></span>
                        <span>
                          {Math.round(
                            totalSubmissions > 4 ? totalSubmissions + 2 : 3
                          )}
                        </span>
                        <span
                          style={{
                            borderRadius: "5px",
                            width: "150px",
                            height: "5px",
                            background: "#039855",
                          }}
                        ></span>
                        <span>
                          {Math.round(
                            totalSubmissions
                              ? totalSubmissions *
                                  GraphDynamicData?.data?.length
                              : 4
                          )}
                        </span>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  float: "right",
                  m: 1,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  className="graph-button"
                  onClick={handleShowTableClick}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  {showTable ? "Show Graph" : "Show Table"}
                </Button>
              </Box>
            </Grid>
            {/* graph ended  */}
          </Grid>
        </CardContent>
      </Card>

      <div style={{ marginTop: "1%" }}>
        {/* STORE PERFORMANCE REPORT  */}
        {/* <SPRHeader /> */}
        <Card className="header-card">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
              <Typography
                align="left"
                variant="h5"
                component="h4"
                sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
              >
                Store Performance Report
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="colomn-padding"
              sx={{ mt: 0.5 }}
            >
              <Stack
                direction="row"
                sx={{ float: "right" }}
                className="checklist-report-header"
              >
                <Link
                  to="/checklist/checklist-report/view-all-store-list"
                  className="submenu-item-storeinfo"
                >
                  <Button
                    variant="outlined"
                    className="done-btn-storeinfo cancle-hover"
                    style={{ marginRight: 10 }}
                  >
                    <VisibilityIcon />
                    View all
                    {/* <ViewAllStoreList /> */}
                  </Button>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Card>

        {/* Top 5 Store Performance started  */}
        <Card sx={{ marginTop: "1%" }} className="card">
          <Typography
            align="left"
            variant="h6"
            component="h4"
            style={{ padding: "4px", marginLeft: "1%", marginTop: "1%" }}
          >
            Top 5 Store Performance
          </Typography>
          <CardContent>
            <Box
              sx={{
                // bgcolor: "#F6F8FC",
                height: "60vh",
                mt: 1,
                p: 1,
                overflowX: "auto",
                overflowY: "auto",
                borderRadius: "8px",
                // paddingBottom: "0px",
                px: 1,
                "&::-webkit-scrollbar": {
                  width: 15,
                },
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              {/* {Loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-45px",
                  }}
                >
                  <Progressbar />
                </div> */}
              {/* {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <ProgressbarTransparent play />
                </div>  
              ) :  */}
              {DynamicTop5Store ? (
                <>
                  <DataGrid
                    rows={DynamicTop5Store}
                    columns={storeColumns}
                    getRowId={(DynamicTop5Store) => DynamicTop5Store?._id}
                    hideFooterPagination={true}
                    className="check-report-grid-table"
                    hideFooter={true}
                    disableColumnMenu
                    disableSelectionOnClick={true}
                  />
                </>
              ) : (
                "No Data Found"
              )}
            </Box>
          </CardContent>
        </Card>
      </div>
      {/* Top 5 Store Performance Ended */}

      <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
        <Typography
          align="left"
          variant="h6"
          component="h4"
          style={{ padding: "4px", marginLeft: "1%", marginTop: "1%" }}
        >
          Bottom 5 Store Performance
        </Typography>
        <CardContent>
          <Box
            sx={{
              // bgcolor: "#F6F8FC",
              height: "60vh",
              mt: 1,
              p: 1,
              overflowX: "auto",
              overflowY: "auto",
              borderRadius: "8px",
              px: 1,
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            {Loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-45px",
                }}
              >
                <Progressbar />
              </div>
            ) : DynamicBottom5Store ? (
              <>
                <DataGrid
                  rows={DynamicBottom5Store}
                  columns={storeColumns}
                  getRowId={(DynamicBottom5Store) => DynamicBottom5Store?._id}
                  hideFooterPagination={true}
                  className="check-report-grid-table"
                  disableColumnMenu
                  disableSelectionOnClick={true}
                  hideFooter={true}
                />
              </>
            ) : (
              "No Data Found"
            )}
          </Box>
        </CardContent>
      </Card>
      {/* Bottom 5 Store Performance started  */}

      <div style={{ marginTop: "1%" }}>
        {/* USER PERFORMANCE REPORT  */}
        {/* <UPRHeader /> */}
        <Card className="header-card">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
              <Typography
                align="left"
                variant="h5"
                component="h4"
                sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
              >
                User Performance Report
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="colomn-padding"
              sx={{ mt: 0.5 }}
            >
              <Stack
                direction="row"
                sx={{ float: "right" }}
                className="checklist-report-header"
              >
                <Link
                  to="/checklist/checklist-report/view-all-user-list"
                  className="submenu-item-storeinfo"
                >
                  <Button
                    variant="outlined"
                    className="done-btn-storeinfo cancle-hover"
                    style={{ marginRight: 10 }}
                  >
                    <VisibilityIcon />
                    View all
                    {/* <ViewAllStoreList /> */}
                  </Button>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Card>

        {/* Top 5 User Performance started */}
        <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
          <Typography
            align="left"
            variant="h6"
            component="h4"
            style={{ padding: "4px", marginLeft: "1%", marginTop: "1%" }}
          >
            Top 5 User Performance
          </Typography>
          <CardContent>
            <Box
              sx={{
                // bgcolor: "#F6F8FC",
                height: "60vh",
                mt: 1,
                p: 1,
                overflowX: "auto",
                overflowY: "auto",
                borderRadius: "8px",
                px: 1,
                "&::-webkit-scrollbar": {
                  width: 15,
                },
                "&::-webkit-scrollbar-track": {
                  padding: "12px 5px",
                  backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#64748B",
                  borderRadius: "8px",
                },
              }}
            >
              {Loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-45px",
                  }}
                >
                  <Progressbar />
                </div>
              ) : DynamicTop5User ? (
                <>
                  <DataGrid
                    rows={DynamicTop5User}
                    columns={userColumns}
                    getRowId={(DynamicTop5User) => DynamicTop5User?._id}
                    hideFooterPagination={true}
                    className="check-report-grid-table"
                    disableColumnMenu
                    disableSelectionOnClick={true}
                    hideFooter={true}
                  />
                </>
              ) : (
                "No Data Found"
              )}
            </Box>
          </CardContent>
        </Card>
        {/* Top 5 user performance ended  */}
      </div>

      {/* Bottom 5 User Performance started */}
      <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
        <Typography
          align="left"
          variant="h6"
          component="h4"
          style={{ padding: "4px", marginLeft: "1%", marginTop: "1%" }}
        >
          Bottom 5 User Performance
        </Typography>
        <CardContent>
          <Box
            sx={{
              // bgcolor: "#F6F8FC",
              height: "60vh",
              mt: 1,
              p: 1,
              overflowX: "auto",
              overflowY: "auto",
              borderRadius: "8px",
              px: 1,
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            {Loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-45px",
                }}
              >
                <Progressbar />
              </div>
            ) : DynamicBottom5User ? (
              <>
                <DataGrid
                  rows={DynamicBottom5User}
                  columns={userColumns}
                  getRowId={(DynamicBottom5User) => DynamicBottom5User?._id}
                  hideFooterPagination={true}
                  className="check-report-grid-table"
                  disableColumnMenu
                  disableSelectionOnClick={true}
                  hideFooter={true}
                />
              </>
            ) : (
              "No Data Found"
            )}
          </Box>
        </CardContent>
      </Card>
      {/* Bottom 5 user performance ended  */}
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </div>
  );
};

export default PerformanceOverview;
