import React from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

const EditFormHead = () => {
  return (
    <Box
      sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
      className="header-card"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography sx={{ mr: 2 }}>
              <small>Created : </small>
              <b>Jun 3, 2022, 6:56:05 PM</b>
            </Typography>
            <Typography>
              <small>Modified : </small>
              <b>Jun 3, 2022, 6:56:05 PM</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right" }}
            className="create-new-form"
          >
            <Link to="/checklist/all-form/view-form" className="submenu-item">
              <Button className="cancle-button cancle-hover">
                <CloseOutlinedIcon sx={{ mr: 1 }} />
                Cancel
              </Button>
            </Link>
            <Link to="/checklist/all-form/view-form" className="submenu-item">
              <Button className="header-add cancle-hover">
                <TaskAltOutlinedIcon sx={{ mr: 1 }} />
                Update
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditFormHead;
