import { CHANGE_MY_PROFILE_PASSWORD_ERROR, CHANGE_MY_PROFILE_PASSWORD_REQUEST, CHANGE_MY_PROFILE_PASSWORD_SUCCESS, PROFILE_ME_ERROR, PROFILE_ME_REQUEST, PROFILE_ME_SUCCESS, UPDATE_MY_PROFILE_ERROR, UPDATE_MY_PROFILE_PICTURE_ERROR, UPDATE_MY_PROFILE_PICTURE_REQUEST, UPDATE_MY_PROFILE_PICTURE_SUCCESS, UPDATE_MY_PROFILE_REQUEST, UPDATE_MY_PROFILE_SUCCESS, UPDATE_PREFERED_COMPANY_ERROR, UPDATE_PREFERED_COMPANY_REQUEST, UPDATE_PREFERED_COMPANY_SUCCESS } from "../types/Types";


const initialState = {
    loading: false,
    error: "",
    progileMeGetApiRes: [],
    myProfileUpdate: [],
    myProfilePictureUpdate: [],
    updatePreferedCompany: [],
    changeMyProfilePassword: []
};


const MyProfileDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case PROFILE_ME_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case PROFILE_ME_SUCCESS: {
        return {
          ...state,
          loading: false,
          progileMeGetApiRes: action?.payload,
        };
      }
      case PROFILE_ME_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      case UPDATE_MY_PROFILE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case UPDATE_MY_PROFILE_SUCCESS: {
        return {
          ...state,
          loading: false,
          myProfileUpdate: action?.payload,
        };
      }
      case UPDATE_MY_PROFILE_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      case UPDATE_MY_PROFILE_PICTURE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case UPDATE_MY_PROFILE_PICTURE_SUCCESS: {
        return {
          ...state,
          loading: false,
          myProfilePictureUpdate: action?.payload,
        };
      }
      case UPDATE_MY_PROFILE_PICTURE_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      case UPDATE_PREFERED_COMPANY_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case UPDATE_PREFERED_COMPANY_SUCCESS: {
        return {
          ...state,
          loading: false,
          updatePreferedCompany: action?.payload,
        };
      }
      case UPDATE_PREFERED_COMPANY_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      case CHANGE_MY_PROFILE_PASSWORD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: "",
        };
      }
      case CHANGE_MY_PROFILE_PASSWORD_SUCCESS: {
        return {
          ...state,
          loading: false,
          changeMyProfilePassword: action?.payload,
        };
      }
      case CHANGE_MY_PROFILE_PASSWORD_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      default:
        return state;
    }
  };
  
  export default MyProfileDetailsReducer;
  