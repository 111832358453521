/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  TextField,
  Grid,
  Stack,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { CompanyGetApi } from "../../redux/actions/CreateCompany";
import { toast, ToastContainer } from "react-toastify";
import { EditStoreActionApi, StoreByIdApi } from "../../redux/actions/Store";

const steps = ["Step 1", "Step 2"];

const EditStore = () => {
  const questions = [
    {
      id: 1,
      title: "Store name?",
      answer: "",
      type: "text",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    // {
    //   id: 2,
    //   title: "Store unique id?",
    //   answer: "",
    //   type: "text",
    //   startTime: null,
    //   endTime: null,
    //   gps: null,
    //   validations: null,
    // },
    // {
    //   id: 3,
    //   title: "Store radius?",
    //   answer: "",
    //   type: "text",
    //   startTime: null,
    //   endTime: null,
    //   gps: null,
    //   validations: null,
    // },
    {
      id: 2,
      title: "Store gps location?",
      answer: {},
      type: "gps",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: 3,
      title: "Store region?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: 4,
      title: "Store sub region?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: 5,
      title: "Store channel?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: 6,
      title: "Store sub channel?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
  ];

  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState([]);
  const [newFieldValue, setNewFieldValue] = useState({});
  const [dynamicQuesId, setDynamicQuesId] = useState(questions);
  const [regionSelectedData, setRegionSelectedData] = useState([]);
  const [regionSelected, setRegionSelected] = useState("");
  const [subRegionSelectedData, setSubRegionSelectedData] = useState([]);
  const [subRegionSelected, setSubRegionSelected] = useState("");
  const [channelSelectedData, setChannelSelectedData] = useState([]);
  const [channelSelected, setChannelSelected] = useState("");
  const [subChannelSelectedData, setSubChannelSelectedData] = useState([]);
  const [subChannelSelected, setSubChannelSelected] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [questionSelected, setQuestionSelected] = useState({});
  const [newSelectedQue, setnewSelectedQue] = useState([]);
  const [enable, setDisabled] = useState(true);
  // const [uniqueId, setuniqueId] = useState("");
  // const [radius, setradius] = useState("");

  const validationSchema = yup.object().shape({
    store: yup.string().required("This is required"),
    latitude: yup.string().required("This is required"),
    longitude: yup.string().required("This is required"),
    // gpsLocation: yup.string().required("This is required"),
    region: yup.string().required("This is required"),
    sub_region: yup.string().required("This is required"),
    channel: yup.string().required("This is required"),
    sub_channel: yup.string().required("This is required"),
    uniqueId: yup.string().required("This is required"),
    radius: yup.string().required("This is required"),
  });

  // const formik = useFormik({
  //   initialValues: newFieldValue || {
  //     store: "",
  //     uniqueId: "",
  //     radius: radius ? Number(radius) : 0,
  //     latitude: "",
  //     longitude: "",
  //     region: "",
  //     sub_region: "",
  //     channel: "",
  //     sub_channel: "",
  //   },

  //   enableReinitialize: true,
  //   validationSchema: validationSchema,

  //   onSubmit: (values) => {
  //     console.log("values-->", values);
  //     dynamicQuesId[0].answer = values.store;
  //     dynamicQuesId[1].answer = {
  //       latitude: values?.latitude,
  //       longitude: values?.longitude,
  //     };
  //     // if(regionSelected == ""){
  //     //     dynamicQuesId[2].answer =  inputValue?.region;
  //     // }else if(subRegionSelected == ""){
  //     //     dynamicQuesId[3].answer =  inputValue?.sub_region;
  //     // }else if(channelSelected == ""){
  //     //     dynamicQuesId[4].answer =  inputValue?.channel;
  //     // }else if(subChannelSelected == ""){
  //     //     dynamicQuesId[5].answer =  inputValue?.sub_channel;
  //     // }
  //     const questions = {
  //       questions: [...dynamicQuesId],
  //     };

  //     const payloadRequest = {
  //       body: questions,
  //     };
  //     if (payloadRequest) {
  //       const _id = localStorage.getItem("companyId");
  //       payloadRequest.url =
  //         BASE_URL + `stores/${id}?store_id=${id}&company_id=${_id}`;
  //       dispatch(EditStoreActionApi(payloadRequest));
  //     }
  //   },
  // });

  const formik = useFormik({
    initialValues: newFieldValue || {
      store: "",
      uniqueId: "",
      radius: radius ? Number(radius) : 0,
      latitude: "",
      longitude: "",
      region: "",
      sub_region: "",
      channel: "",
      sub_channel: "",
    },

    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      dynamicQuesId[0].answer = values.store;
      dynamicQuesId[1].answer = {
        latitude: values?.latitude,
        longitude: values?.longitude,
      };
      const questions = {
        questions: [...dynamicQuesId],
      };

      const payloadRequest = {
        body: {
          ...questions,
          uniqueId: values.uniqueId,
          radius: values.radius,
        },
      };

      if (payloadRequest) {
        const _id = localStorage.getItem("companyId");
        payloadRequest.url =
          BASE_URL + `stores/${id}?store_id=${id}&company_id=${_id}`;
        dispatch(EditStoreActionApi(payloadRequest));
      }
    },
  });

  const EditStore = useSelector(
    (state) =>
      state.store && state.store.updateStore && state.store.updateStore.data
  );

  const companyQuesData = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data
  );

  const companyDataList = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data.list
  );

  const storeById = useSelector(
    (state) =>
      state.store &&
      state.store.editStoreById &&
      state.store.editStoreById.data &&
      state.store.editStoreById.data.data
  );

  useEffect(() => {
    if (companyQuesData && storeById) {
      setQuestionList(companyQuesData?.questions);
      for (let item of storeById?.questions) {
        if (item.id > 6 && item.is_store && item.title !== "hhhhh") {
          for (let items of questionList) {
            if (items.id > 6 && items.is_store && items.title !== "hhhhh") {
              items.answer = item && item?.answer;
            }
          }
        }
      }
    }
  }, [storeById, companyQuesData]);

  useEffect(() => {
    setInputValue(storeById);
  }, [storeById]);

  useEffect(() => {
    let newObj = questionSelected?.questionsname;
    if (newObj) {
      const newObjValue = {
        id: questionSelected?.items?.id,
        title: questionSelected?.items?.title,
        answer: newObj,
        type: questionSelected?.items?.type?.key,
        startTime: null,
        endTime: null,
        gps: null,
        validations: null,
      };
      setnewSelectedQue((oldData) => [...oldData, newObjValue]);
    }
  }, [questionSelected]);

  useEffect(() => {
    getCompanyData();
    getStoreById();
  }, []);

  useEffect(() => {
    if (EditStore && EditStore.status === 200) {
      toast.success(EditStore.message, { autoClose: 2000 });
      setTimeout(() => {
        navigate("/store");
      }, 2000);
    }
  }, [EditStore]);

  const handleChangeRegion = (e) => {
    setRegionSelected(e.target.value);
    dynamicQuesId[2].answer = e.target.value;
  };
  // useEffect(() => {
  //     if (companyQuesData) {
  //         for (let item of companyQuesData?.questions) {
  //             if (item?.title == "Store region?") {
  //                 setRegionSelectedData(item?.type?.validations[0]?.answer);
  //             }
  //         }
  //     }
  // }, [companyQuesData]);

  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData?.questions) {
        if (item?.is_store === true) {
          for (let items of companyDataList) {
            if (
              item?.attached_list === items?.id &&
              item?.title === "Store region?"
            ) {
              setRegionSelectedData(items?.items);
            }
          }
        }
      }
    }
  }, [companyQuesData, companyDataList]);
  useEffect(() => {
    setInputValue(inputValue);
    setNewFieldValue({
      store: inputValue && inputValue?.name,
      uniqueId: inputValue && inputValue?.uniqueId,
      radius: inputValue && inputValue?.radius,
      latitude: inputValue && inputValue?.gps && inputValue?.gps?.latitude,
      longitude: inputValue?.gps?.longitude,
      region: inputValue && inputValue?.region && inputValue?.region?.title,
      sub_region:
        inputValue && inputValue?.sub_region && inputValue?.sub_region?.title,
      channel: inputValue && inputValue?.channel && inputValue?.channel?.title,
      sub_channel:
        inputValue && inputValue?.sub_channel && inputValue?.sub_channel?.title,
    });
    dynamicQuesId[2].answer = inputValue?.region;
    dynamicQuesId[4].answer = inputValue?.channel;
    dynamicQuesId[3].answer = inputValue?.sub_region;
    dynamicQuesId[5].answer = inputValue?.sub_channel;
    let newQuesItem = inputValue?.questions;
    if (newQuesItem?.length > 0) {
      for (let item of newQuesItem) {
        if (item?.id === 3) {
          setRegionSelected(item?.answer);
        }
        if (item?.id === 4) {
          setSubRegionSelected(item?.answer);
        }
        if (item?.id === 5) {
          setChannelSelected(item?.answer);
        }
        if (item?.id === 6) {
          setSubChannelSelected(item?.answer);
        }
      }
    }
    // setRegionSelected(inputValue?.region)
    // setSubRegionSelected(inputValue?.sub_region);
    // setChannelSelected(inputValue?.channel);
    // setSubChannelSelected(inputValue?.sub_channel);
  }, [inputValue]);

  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true && item?.title === "Store sub region?") {
          let newlist = item?.dependency_list?.items;
          for (let list of newlist) {
            if (list?.title === regionSelected?.title) {
              for (let items of companyDataList) {
                if (items?.id === list?.selected_list) {
                  if (items?.items?.length > 0) {
                    setSubRegionSelectedData(items?.items);
                    setDisabled(false);
                  } else {
                    toast.error(
                      `No items are available for List ${regionSelected?.title}. Consider assigning a list for ${regionSelected?.title} by editing question Q.3 in Kpi Builder`
                    );
                    setDisabled(false);
                  }
                }
                if (
                  list?.selected_list === null &&
                  list?.selected_list === "" &&
                  list?.selected_list === undefined &&
                  !list?.selected_list
                ) {
                  setSubRegionSelectedData([]);
                  toast.error(
                    `No items are available for List ${regionSelected?.title}. Consider assigning a list for ${regionSelected?.title} by editing question Q.3 in Kpi Builder`
                  );
                }
              }
            }
          }
        }
      }
      // setDynamicQuesId(dynamicQuesId);
    }
  }, [companyQuesData, companyDataList, regionSelected]);

  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true) {
          for (let items of companyDataList) {
            if (
              item?.attached_list === items?.id &&
              item?.title === "Store channel?"
            ) {
              setChannelSelectedData(items?.items);
            }
          }
        }
        // if (item?.title == "Store region?") {
        //     setRegionSelectedData(item?.type?.validations[0]?.answer);
        // }
      }
    }
  }, [companyQuesData, companyDataList]);

  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true && item?.title === "Store sub channel?") {
          let newlist = item?.dependency_list?.items;
          for (let list of newlist) {
            if (list?.title === channelSelected?.title) {
              for (let items of companyDataList) {
                if (items?.id === list?.selected_list) {
                  if (items?.items?.length > 0) {
                    setSubChannelSelectedData(items?.items);
                  } else {
                    toast.error(
                      `No items are available for List ${channelSelected?.title}. Consider assigning a list for ${channelSelected?.title} by editing question Q.3 in Kpi Builder`
                    );
                  }
                }
                if (
                  list?.selected_list === null &&
                  list?.selected_list === ""
                ) {
                  setSubChannelSelectedData([]);
                  toast.error(
                    `No items are available for List ${channelSelected?.title}. Consider assigning a list for ${channelSelected?.title} by editing question Q.3 in Kpi Builder`
                  );
                  // setDisabled(true)
                }
              }
            }
          }
        }
      }
      // setDynamicQuesId(dynamicQuesId);
    }
  }, [companyQuesData, companyDataList, channelSelected]);

  // useEffect(() => {
  //     if (regionSelected && companyDataList) {
  //         let titles = `Regions of ${regionSelected.title}`
  //         let titles1 = `Sub Region of ${regionSelected.title}`
  //         let titles2 = `Sub Regions of ${regionSelected.title}`
  //         for (let item of companyDataList) {
  //             if (item.title.toUpperCase() === titles.toUpperCase() || item.title.toUpperCase() === titles1.toUpperCase() || item.title.toUpperCase() === titles2.toUpperCase()) {
  //                 setSubRegionSelectedData(item?.items);
  //             }
  //         }
  //     }
  // }, [regionSelected, subRegionSelectedData]);

  // useEffect(() => {
  //     if (subRegionSelectedData && companyDataList) {
  //         for (let item of companyDataList) {
  //             console.log('item.. ', item);
  //             if (item.title === "List of Channels" || item.title === "Channels") {
  //                 setChannelSelectedData(item?.items);
  //             } else if (item.title === "List of Sub channels" || item.title === "Sub Channel") {
  //                 setSubChannelSelectedData(item?.items)
  //             }
  //         }
  //     }
  // }, [subRegionSelectedData]);

  const handleChangeSubRegion = (e) => {
    setSubRegionSelected(e.target.value);
    dynamicQuesId[3].answer = e.target.value;
  };

  const handleChangeStoreChannel = (e) => {
    setChannelSelected(e.target.value);
    dynamicQuesId[4].answer = e.target.value;
  };

  const handleChangeStoreSubChannel = (e) => {
    setSubChannelSelected(e.target.value);
    dynamicQuesId[5].answer = e.target.value;
    let finalPayload = dynamicQuesId;
    setDynamicQuesId(finalPayload);
  };

  const getCompanyData = () => {
    const cid = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${cid}` };
    dispatch(CompanyGetApi(data));
  };

  const getStoreById = () => {
    const _id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `stores/${id}?company_id=${_id}` };
    dispatch(StoreByIdApi(data));
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleQuestionsname = (e, items) => {
    const value = e.target.value;
    setQuestionSelected({
      ...questionSelected,
      [e.target.name]: value,
      items,
    });

    // setQuestionSelected({[e.target.name]: e.target.value})
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <ToastContainer />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/store" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>

                <Button type="submit" className="header-add cancle-hover">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Confirm
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            {/* <Stepper activeStep={activeStep} sx={{ marginBottom: "10px" }}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //     labelProps.optional = (
                //         <Typography variant="caption">Optional</Typography>
                //     );
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    https://maps.googleapis.com/maps/api/js?key=AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA&v=3.exp&libraries=geometry,drawing,places}
                  </Step>
                );
              })}
            </Stepper> */}
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - Click on Confirm
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 ? (
                  <Box>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Name</label>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="issueinput-fields"
                        size="small"
                        name="store"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.store}
                        error={
                          formik.touched.store && Boolean(formik.errors.store)
                        }
                      />
                      <small className="error">
                        {formik.errors.store && formik.touched.store
                          ? formik.errors.store
                          : null}
                      </small>
                    </FormControl>

                    {/* -------------------------------------------------------------------------------------- */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Unique ID</label>
                      <TextField
                        id="unique-id"
                        variant="outlined"
                        size="small"
                        name="uniqueId"
                        placeholder="Enter Store ID"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.uniqueId}
                        error={
                          formik.touched.uniqueId &&
                          Boolean(formik.errors.uniqueId)
                        }
                        inputProps={{
                          pattern: "^[a-zA-Z0-9]*$",
                          title: "Store ID must be alphanumeric",
                        }}
                      />
                      <small className="error">
                        {formik.touched.uniqueId && formik.errors.uniqueId}
                      </small>
                    </FormControl>

                    {/* -------------------------------------------------------------------------------------- */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Radius</label>
                      <TextField
                        id="radius"
                        variant="outlined"
                        size="small"
                        name="radius" // Set the name to "radius"
                        placeholder="Enter Store Radius"
                        type="number"
                        step="any"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} // Bind the onBlur event handler
                        value={formik.values.radius}
                        error={
                          formik.touched.radius && Boolean(formik.errors.radius)
                        }
                      />
                      <small className="error">
                        {formik.touched.radius && formik.errors.radius}
                      </small>
                    </FormControl>

                    {/* -------------------------------------------------------------------------------------- */}

                    {/* edit lat and long value  */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Latitude</label>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        className="issueinput-fields"
                        name="latitude"
                        placeholder="Enter Latitude"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.latitude}
                        error={
                          formik.touched.latitude &&
                          Boolean(formik.errors.latitude)
                        }
                      />
                      <small className="error">
                        {formik.errors.latitude && formik.touched.latitude
                          ? formik.errors.latitude
                          : null}
                      </small>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Longitude</label>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="longitude"
                        className="issueinput-fields"
                        placeholder="Enter Longitude"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.longitude}
                        error={
                          formik.touched.longitude &&
                          Boolean(formik.errors.longitude)
                        }
                      />
                      <small className="error">
                        {formik.errors.longitude && formik.touched.longitude
                          ? formik.errors.longitude
                          : null}
                      </small>
                    </FormControl>
                    {/* edit lat and long value  */}

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Region</label>
                      <Select
                        size="small"
                        name="region"
                        onChange={handleChangeRegion}
                        sx={{ borderRadius: "8px" }}
                        value={regionSelected}
                        // value={formik?.values?.regionSelected}
                        renderValue={(data) => data?.title}
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            sx: {
                              bgcolor: "#FFFFFF",
                              "& .MuiMenuItem-root": {
                                padding: 1,
                              },
                              height: "200px",
                            },
                          },
                        }}
                      >
                        {regionSelectedData &&
                          regionSelectedData.length > 0 &&
                          regionSelectedData?.map((option, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={option}
                                className="select-item"
                              >
                                {option?.title}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Sub-Region</label>
                      <Select
                        size="small"
                        name="sub_region"
                        sx={{ borderRadius: "8px" }}
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            sx: {
                              bgcolor: "#FFFFFF",
                              "& .MuiMenuItem-root": {
                                padding: 1,
                              },
                              height: "200px",
                            },
                          },
                        }}
                        onChange={handleChangeSubRegion}
                        // value={formik.values.subRegionSelected}
                        value={subRegionSelected}
                        renderValue={(data) => data?.title}
                      >
                        {subRegionSelectedData &&
                          subRegionSelectedData.length > 0 &&
                          subRegionSelectedData?.map((option, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={option}
                                className="select-item"
                              >
                                {option.title}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Channel</label>
                      <Select
                        size="small"
                        name="channel"
                        sx={{ borderRadius: "8px" }}
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            sx: {
                              bgcolor: "#FFFFFF",
                              "& .MuiMenuItem-root": {
                                padding: 1,
                              },
                              height: "200px",
                            },
                          },
                        }}
                        onChange={handleChangeStoreChannel}
                        value={channelSelected}
                        renderValue={(data) => data?.title}
                      >
                        {channelSelectedData &&
                          channelSelectedData?.map((option, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={option}
                                className="select-item"
                              >
                                {option.title}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <small className="error">
                        {formik.errors.storeChannel &&
                        formik.touched.storeChannel
                          ? formik.errors.storeChannel
                          : null}
                      </small>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Store Sub-Channel</label>
                      <Select
                        size="small"
                        sx={{ borderRadius: "8px" }}
                        name="sub_channel"
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            sx: {
                              bgcolor: "#FFFFFF",
                              "& .MuiMenuItem-root": {
                                padding: 1,
                              },
                              height: "200px",
                            },
                          },
                        }}
                        onChange={handleChangeStoreSubChannel}
                        // value={formik.values.subChannelSelected}
                        value={subChannelSelected}
                        renderValue={(data) => data?.title}
                      >
                        {subChannelSelectedData &&
                          subChannelSelectedData.length > 0 &&
                          subChannelSelectedData?.map((option, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={option}
                                className="select-item"
                              >
                                {option?.title}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : activeStep === 1 ? (
                  <Box>
                    <Box>
                      {questionList &&
                        questionList.length > 0 &&
                        questionList?.map((items, i) => {
                          return (
                            <Box key={i}>
                              {items.is_store === true &&
                              items.id !== 1 &&
                              items.id !== 2 &&
                              items.id !== 3 &&
                              items.id !== 4 &&
                              items.id !== 5 &&
                              items.id !== 6 ? (
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                  <label>{items.title}</label>
                                  <Select
                                    size="small"
                                    sx={{ borderRadius: "8px" }}
                                    name="questionsname"
                                    MenuProps={{
                                      disableScrollLock: true,
                                      PaperProps: {
                                        sx: {
                                          bgcolor: "#FFFFFF",
                                          "& .MuiMenuItem-root": {
                                            padding: 1,
                                          },
                                          height: "200px",
                                        },
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleQuestionsname(e, items)
                                    }
                                    value={questionSelected?.questionsname}
                                    defaultValue={items?.answer?.title}
                                    renderValue={(data) => data?.title}
                                  >
                                    {items?.type &&
                                      items?.type.validations &&
                                      items?.type.validations[0]?.answer &&
                                      items?.type?.validations[0]?.answer
                                        ?.length > 0 &&
                                      items?.type?.validations[0]?.answer?.map(
                                        (option, i) => {
                                          return (
                                            <MenuItem
                                              key={i}
                                              value={option}
                                              className="select-item"
                                            >
                                              {option.title}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                </FormControl>
                              ) : (
                                ""
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Done" : "Next"}
                  </Button>
                </Box> */}
              </React.Fragment>
            )}
          </Container>
        </Box>
      </form>
    </>
  );
};

export default EditStore;
