/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import {
  ATTENDENCE_CONFIGLIST_ERROR,
  ATTENDENCE_CONFIGLIST_REQUEST,
  ATTENDENCE_CONFIGLIST_SUCCESS,
  ATTENDENCE_CREATE_ERROR,
  ATTENDENCE_CREATE_REQUEST,
  ATTENDENCE_CREATE_SUCCESS,
  ATTENDENCE_DELETE_ERROR,
  ATTENDENCE_DELETE_REQUEST,
  ATTENDENCE_DELETE_SUCCESS,
  ATTENDENCE_EDIT_ERROR,
  ATTENDENCE_EDIT_REQUEST,
  ATTENDENCE_EDIT_SUCCESS,
  ATTENDENCE_EXPORT_ERROR,
  ATTENDENCE_EXPORT_REQUEST,
  ATTENDENCE_EXPORT_SUCCESS,
  ATTENDENCE_SUBMISSIONLIST_ERROR,
  ATTENDENCE_SUBMISSIONLIST_REQUEST,
  ATTENDENCE_SUBMISSIONLIST_SUCCESS,
} from "../types/Types";
import { toast } from "react-toastify";

export const CreateAttendance = () => ({
  type: ATTENDENCE_CREATE_REQUEST,
});

export const CreateAttendanceSuccess = (companyid) => ({
  type: ATTENDENCE_CREATE_SUCCESS,
  payload: companyid,
});

export const CreateAttendanceFailed = (error) => ({
  type: ATTENDENCE_CREATE_ERROR,
  payload: error,
});

export const CreateAttendanceApi = (data) => (dispatch) => {
  try {
    dispatch(CreateAttendance(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CreateAttendanceSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        toast.error(`${response?.response?.data?.message}`);
        dispatch(CreateAttendanceFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(CreateAttendanceFailed(error));
  }
};

export const ListAttendance = () => ({
  type: ATTENDENCE_CONFIGLIST_REQUEST,
});

export const ListAttendanceSuccess = (companyid) => ({
  type: ATTENDENCE_CONFIGLIST_SUCCESS,
  payload: companyid,
});

export const ListAttendanceFailed = (error) => ({
  type: ATTENDENCE_CONFIGLIST_ERROR,
  payload: error,
});

export const ListAttendanceApi = (data) => (dispatch) => {
  try {
    dispatch(ListAttendance(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListAttendanceSuccess(data));
      } else {
        dispatch(ListAttendanceFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListAttendanceFailed(error));
  }
};

export const ListAttendanceSubmission = () => ({
  type: ATTENDENCE_SUBMISSIONLIST_REQUEST,
});

export const ListAttendanceSubmissionSuccess = (companyid) => ({
  type: ATTENDENCE_SUBMISSIONLIST_SUCCESS,
  payload: companyid,
});

export const ListAttendanceSubmissionFailed = (error) => ({
  type: ATTENDENCE_SUBMISSIONLIST_ERROR,
  payload: error,
});

export const ListAttendanceSubmissionApi = (data) => (dispatch) => {
  try {
    dispatch(ListAttendanceSubmission(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListAttendanceSubmissionSuccess(data));
      } else {
        dispatch(ListAttendanceSubmissionFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListAttendanceFailed(error));
  }
};

export const ListAttendanceSubmissionExport = () => ({
  type: ATTENDENCE_EXPORT_REQUEST,
});

export const ListAttendanceSubmissionSuccessExport = (companyid) => ({
  type: ATTENDENCE_EXPORT_SUCCESS,
  payload: companyid,
});

export const ListAttendanceSubmissionFailedExport = (error) => ({
  type: ATTENDENCE_EXPORT_ERROR,
  payload: error,
});

export const ListAttendanceSubmissionExportApi = (data) => (dispatch) => {
  try {
    dispatch(ListAttendanceSubmissionSuccessExport(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(ListAttendanceSubmissionSuccessExport(data));
      } else {
        dispatch(ListAttendanceSubmissionFailedExport(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListAttendanceFailedExport(error));
  }
};


export const DeleteConfig = () => ({
  type: ATTENDENCE_DELETE_REQUEST,
});

export const DeleteConfigSuccess = (data) => ({
  type: ATTENDENCE_DELETE_SUCCESS,
  payload: data,
});

export const DeleteConfigFailed = (error) => ({
  type: ATTENDENCE_DELETE_ERROR,
  payload: error,
});

export const DeleteConfigApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteConfig());
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteConfigSuccess(response));
        toast.success(`${response?.data?.message}`);
        console.log("response", response);
      } else {
        console.log("response", response);
        toast.error(`${response?.response?.data?.message}`);
        dispatch(DeleteConfigFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteConfigFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const EditConfig = () => ({
  type: ATTENDENCE_EDIT_REQUEST,
});

export const EditConfigSuccess = (data) => ({
  type: ATTENDENCE_EDIT_SUCCESS,
  payload: data,
});

export const EditConfigFailed = (error) => ({
  type: ATTENDENCE_EDIT_ERROR,
  payload: error,
});

export const EditConfigApi = (data) => (dispatch) => {
  try {
    dispatch(EditConfig());
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(EditConfigSuccess(response));
        toast.success(`${response?.data?.message}`);
        console.log("response", response);
      } else {
        console.log("response", response);
        toast.error(`${response?.response?.data?.message}`);
        dispatch(EditConfigFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditConfigFailed(error));
    console.error("Something went Wrong", error);
  }
};
