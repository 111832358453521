import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import {
  AccountDetailsApi,
  PlanDetailsApi,
  submitAccountDetailsApi,
} from "../redux/actions/AccountAction";
import { useDispatch, useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import Lottie from "react-lottie-player";
import lottieJson from "./LottieLogo.json";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountDetails from "./AccountDetails";
import { ListUserApi } from "../redux/actions/User";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ProgressbarTransparent(props) {
  return (
    <div id="divLoading" className="transparentLoader">
      <span className="centerLoaderText">
        <Lottie
          animationData={lottieJson}
          style={{ width: 150, height: 150 }}
          loop
          speed={2}
          {...props}
        ></Lottie>
      </span>
    </div>
  );
}

const EditAccountDetails = () => {
  const navigate = useNavigate();
  const planDetails = useSelector((state) => state.account.planList);
  const companyInfo = useSelector((state) => state.account.companyInfo);
  const companyLoading = useSelector((state) => state.account.companyLoading);
  const submitLoading = useSelector((state) => state.account.submitLoading);
  const location = useLocation();

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.userList &&
      state.user.userList.data &&
      state.user.userList.data.data
  );

  // console.log("companyInfo", companyInfo);

  const countryCodeData = Country.getAllCountries();
  const stateCodeData = State.getAllStates();
  const [cardDetails, setCardDetails] = useState([
    {
      id: 0,
      number: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
    },
  ]);
  const [companyName, setCompanyName] = useState("");
  const [uniqueSlag, setUniqueSlag] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [gstin, setGstin] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();

  // console.log("cardDetails", cardDetails);
  const companyDetails = location && location?.state?.companyInfo;

  const MenuProps = {
    disableScrollLock: true,
  };
  // const handleBackClick = () => {
  // window.location.href = "./account-details";
  // };
  const [editableSections, setEditableSections] = useState({
    companyDetails: false,
    // Add more sections as needed
  });

  const handleEdit = (section) => {
    setEditableSections({ ...editableSections, [section]: true });
  };

  const handleUpdate = (updatedValues) => {
    formik.setValues({ ...formik.values, ...updatedValues });
    setEditableSections({
      ...editableSections,
      companyDetails: false, // Reset the editable state after update
      // Reset other sections as needed
    });
  };
  //   const handlePinCodeChange = (event) => {
  //     const inputValue = event.target.value;
  //     if (inputValue.length <= 6) {
  //       setPinCode(inputValue);
  //     }
  //   };
  const isValidGSTIN = (gstin) => {
    const gstinPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    return gstin.match(gstinPattern);
  };

  const handleGstinChange = (event) => {
    const inputValue = event.target.value.toUpperCase().replace(/\s/g, ""); // Remove whitespace
    setGstin(inputValue);
    if (inputValue.length !== 15) {
      setErrorMessage("GSTIN must be exactly 15 characters long");
    } else if (!isValidGSTIN(inputValue)) {
      setErrorMessage("Invalid GSTIN");
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    getPlanDetails();
    listUsers();
    getCompanyInfo();
  }, []);

  console.log("planDetails", planDetails);

  const getPlanDetails = () => {
    const data = {
      url: BASE_URL + `company/plan/getAll`,
    };
    dispatch(PlanDetailsApi(data));
  };
  const listUsers = () => {
    const companyId = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `users/index?company_id=${companyId}` };
    dispatch(ListUserApi(data));
  };

  const getCompanyInfo = () => {
    const companyId = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `company-info?company_id=${companyId}` };
    dispatch(AccountDetailsApi(data));
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required("Company Name is required"),
    // Address: yup.string().required("Address is required"),
    // State: yup.string().required("State is required"),
    // pinCode: yup.string().max(6, "Pin code must be exactly 6 digits long"),
    // gstIn: yup.string().max(15, "GSTIN must be exactly 15 characters long"),
    // paymentType: yup.string().required("Payment Type is required"),
  });

  const formik = useFormik({
    initialValues: {
      companyName: companyDetails.companyName,
      uniqueSlag: companyDetails.uniqueslag,
      line1: companyDetails.address.line1,
      city: companyDetails.address.city,
      State: companyDetails.state,
      pinCode: companyDetails.address.postal_code,
      gstIn: companyDetails.gst,
      paymentType: 0,
      planType:
        planDetails?.filter(
          (item) => item._id === companyDetails?.planDetails?.planId
        )[0].planType || 0,
      // processFeeStatus: true,
      // processFee: 0,
      // discount: 0,
      countryCode:
        countryCodeData.filter(
          (country) => country.isoCode === companyDetails.address.countryCode
        )[0] || "",
      stateCode:
        stateCodeData.filter(
          (state) => state.isoCode === companyDetails.address.stateCode
        )[0] || "",
      // admins: [],
      planName: "",
      planDescription: "",
      paymentMethod: "",
      id: 1,
      number: "",
      exp_month: "",
      exp_year: "",
      cvv: "",
      customerEmail: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // setLoader(true);
      const callback = () => {
        navigate('/account-details');
      }

      const companyId = localStorage.getItem("companyId");

      const payload = {
        companyName: values.companyName,
        address: {
          line1: values.line1,
          postal_code: values.pinCode,
          city: values.city,
          stateCode: values.stateCode.isoCode,
          countryCode: values.countryCode.isoCode,
        },
        state: values.State,
        // companyAdmins: values.admins?.map((item) => item.email),
        planDetails: {
          planId:
            planDetails.filter((item) => item.planType === values.planType)[0]
              ?._id || "",
        },
        gst: values.gstIn,
        // paymentStatus: values.paymentType,
        // processFee: values.processFee,
        // processFeeStatus: values.processFeeStatus,
      };

      // if (values.paymentType === "auto") {
        // payload.paymentDetails = {
        //   cards: cardDetails,
        //   customerEmail: values.customerEmail,
        // };
      // }
      const data = {
        url: BASE_URL + `company-info?company_id=${companyId}`,
        body: payload,
      };
      // console.log("payload", data);
      dispatch(submitAccountDetailsApi(data, callback));
    },
  });

  // console.log("formik.values", formik.values);

  const handleAdminChange = (e, newValue) => {
    if (newValue) {
      const event = {
        ...e,
        target: {
          ...e.target,
          name: "admins",
          value: newValue,
        },
      };
      formik.handleChange(event);
    }
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      {companyLoading || (submitLoading && <ProgressbarTransparent play />)}
      {!companyLoading && (
        <Container
          maxWidth="xl"
          sx={{ bgcolor: "#F6F8FC", p: 1, pb: 10, borderRadius: 2 }}
        >
          <Box
            sx={{
              py: 2,
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
            }}
          >
            <Typography variant="h6">Edit company details</Typography>
            <Link to="/account-details">
              <button
                className="header-add cancle-hover"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
                // onClick={handleBackClick}
              >
                back
              </button>
            </Link>
          </Box>
          <div>
            <ToastContainer autoClose={3000} />
            {companyLoading || submitLoading ? (
              <div>Loading...</div>
            ) : formik.touched.companyName && formik.errors.companyName ? (
              <div>{formik.errors.companyName}</div>
            ) : (
              ""
            )}
          </div>

          <form
            onSubmit={(values) => {
              formik.handleSubmit(values);
            }}
            style={{ textAlign: "left" }}
          >
            <FormControl
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <label>Company Name</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="Company Name"
                  name="companyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                />
                <small className="error">
                  {formik.errors.companyName && formik.touched.companyName
                    ? formik.errors.companyName
                    : null}
                </small>
              </FormControl>
              <FormControl fullWidth>
                <label>GstIn</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="GstIn"
                  name="gstIn"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gstIn}
                  error={formik.touched.gstIn && Boolean(formik.errors.gstIn)}
                />
                <small className="error">
                  {formik.errors.gstIn && formik.touched.gstIn
                    ? formik.errors.gstIn
                    : null}
                </small>
              </FormControl>
            </FormControl>
            <FormControl
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <label>Address Line 1</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="Address Line 1"
                  name="line1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.line1}
                  error={formik.touched.line1 && Boolean(formik.errors.line1)}
                />
                <small className="error">
                  {formik.errors.line1 && formik.touched.line1
                    ? formik.errors.line1
                    : null}
                </small>
              </FormControl>
              <FormControl fullWidth>
                <label>City</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="City"
                  name="city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                />
                <small className="error">
                  {formik.errors.city && formik.touched.city
                    ? formik.errors.city
                    : null}
                </small>
              </FormControl>
            </FormControl>
            <FormControl
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <label>Pin Code</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="Pin Code"
                  name="pinCode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pinCode}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                />
                <small className="error">
                  {formik.errors.pinCode && formik.touched.pinCode
                    ? formik.errors.pinCode
                    : null}
                </small>
              </FormControl>
              <FormControl fullWidth>
                <label>State</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="State"
                  name="State"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.State}
                  error={formik.touched.State && Boolean(formik.errors.State)}
                />
                <small className="error">
                  {formik.errors.State && formik.touched.State
                    ? formik.errors.State
                    : null}
                </small>
              </FormControl>
            </FormControl>
            <FormControl
              sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
            >
              <FormControl fullWidth>
                <label style={{ textAlign: "left" }}>Country Code</label>
                <Autocomplete
                  options={countryCodeData.length ? countryCodeData : []}
                  getOptionLabel={(option) => option?.isoCode || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      name="countryCode"
                      placeholder="Country Code"
                      sx={{ borderRadius: "8px" }}
                    />
                  )}
                  value={formik.values.countryCode}
                  onChange={(e, value) => {
                    const event = {
                      ...e,
                      target: {
                        ...e.target,
                        name: "countryCode",
                        value: value,
                      },
                    };
                    formik.handleChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.isoCode}>
                      {option.isoCode}
                    </li>
                  )}
                  clearable
                />
              </FormControl>
              <FormControl fullWidth>
                <label style={{ textAlign: "left" }}>State Code</label>
                <Autocomplete
                  options={
                    stateCodeData.length
                      ? stateCodeData.filter(
                          (item) => item.countryCode === "IN"
                        )
                      : []
                  }
                  getOptionLabel={(option) => option?.isoCode || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="State Code"
                      sx={{ borderRadius: "8px" }}
                    />
                  )}
                  value={formik.values.stateCode}
                  onChange={(e, value) => {
                    const event = {
                      ...e,
                      target: {
                        ...e.target,
                        name: "stateCode",
                        value: value,
                      },
                    };
                    formik.handleChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.isoCode}>
                      {option.isoCode}
                    </li>
                  )}
                  clearable
                />
              </FormControl>
            </FormControl>
            {/* <FormControl
              sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
            >
              <FormControl fullWidth>
                <label>Payment Type</label>
                <Select
                  name="paymentType"
                  //   value={paymentType}
                  //   onChange={(e) => setPaymentType(e.target.value)}
                  size="small"
                  MenuProps={MenuProps}
                  sx={{ borderRadius: "8px" }}
                  placeholder="Payment type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.paymentType}
                  error={
                    formik.touched.paymentType &&
                    Boolean(formik.errors.paymentType)
                  }
                  fullWidth
                >
                  <MenuItem value={0}>Select type</MenuItem>
                  <MenuItem value={"auto"}>Auto</MenuItem>
                  <MenuItem value={"Manual"}>Manual</MenuItem>
                </Select>
                <small className="error">
                  {formik.errors.paymentType && formik.touched.paymentType
                    ? formik.errors.paymentType
                    : null}
                </small>
              </FormControl>
            </FormControl> */}
            {/* <FormControl fullWidth>
                  <label>Admins</label>

                  <Autocomplete
                    options={
                      userList && userList.users.length > 0
                        ? userList.users.filter(
                          (item) => item?.role === "admin"
                        )
                        : []
                    }
                    getOptionLabel={(option) => option?.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        size="small"
                        placeholder="Select User"
                        sx={{ borderRadius: "8px" }}
                      />
                    )}
                    value={formik.values.admins}
                    onChange={handleAdminChange}
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        <Checkbox
                          checked={
                            formik.values.admins
                              ? formik.values.admins.filter(
                                (seUser) => option._id === seUser._id
                              ).length
                              : false
                          }
                          sx={{ mr: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newSelected = formik.values.admins
                              ? [...formik.values.admins]
                              : [];
                            const selectedIndex = newSelected.indexOf(option);

                            if (selectedIndex === -1) {
                              newSelected.push(option);
                            } else {
                              newSelected.splice(selectedIndex, 1);
                            }

                            const event = {
                              ...e,
                              target: {
                                ...e.target,
                                name: "admins",
                                value: newSelected,
                              },
                            };
                            formik.handleChange(event);
                          }}
                        />
                        {option.name}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <Chip
                                key={option.name}
                                label={option.name}
                                {...getTagProps({ index })}
                                color="primary"
                                sx={{ mr: 1, mb: 1 }}
                              />
                              {value.length > 1 ? (
                                <Chip
                                  key={`${value.length - 1} more selected`}
                                  label={`${value.length - 1} more selected`}
                                  color="primary"
                                />
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      })
                    }
                    clearable
                    // onClear={handleClearUsers}
                    multiple
                    disableCloseOnSelect
                    PaperComponent={(paperProps) => {
                      const { children, ...restPaperProps } = paperProps;
                      return (
                        <Paper {...restPaperProps}>
                          <Box
                            onMouseDown={(e) => e.preventDefault()}
                            pl={3}
                            py={0.5}
                          >
                            <FormControlLabel
                              onClick={(e) => {
                                e.preventDefault();
                                // if (
                                //   userList &&
                                //   selectedUsers.length > 0 &&
                                //   selectedUsers.length === userList.length
                                // ) {
                                //   handleAdminChange("", []);
                                // } else {
                                //   handleAdminChange("", userList);
                                // }
                              }}
                              label="Select all"
                              control={
                                <Checkbox
                                  sx={{ mr: 1 }}
                                  id="select-all-checkbox"
                                // checked={
                                //   userList &&
                                //   selectedUsers.length > 0 &&
                                //   selectedUsers.length === userList.length
                                // }
                                />
                              }
                            />
                          </Box>
                          <Divider />
                          {children}
                        </Paper>
                      );
                    }}
                  />
                  <small className="error">
                    {formik.errors.admins && formik.touched.admins
                      ? formik.errors.admins
                      : null}
                  </small>
                </FormControl> */}

            {formik.values.paymentType === "auto" ? (
              <FormControl
                sx={{
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <FormControl fullWidth>
                  <label>Customer Email</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Customer Email"
                    name="customerEmail"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerEmail}
                    error={
                      formik.touched.customerEmail &&
                      Boolean(formik.errors.customerEmail)
                    }
                  />
                  <small className="error">
                    {formik.errors.customerEmail && formik.touched.customerEmail
                      ? formik.errors.customerEmail
                      : null}
                  </small>
                </FormControl>
              </FormControl>
            ) : (
              ""
            )}

            {formik.values.paymentType === "auto"
              ? cardDetails.map((item) => (
                  <>
                    {" "}
                    <FormControl
                      sx={{
                        mb: 2,
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        border: "1px solid grey",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <FormControl fullWidth>
                        <label>Card Number</label>
                        <TextField
                          size="small"
                          fullWidth
                          id="outlined-start-adornment"
                          placeholder="Card Number"
                          name="cardNumber"
                          onChange={(e) => {
                            const modifieddetails = [...cardDetails];
                            cardDetails.map((card) => {
                              if (card.id === item.id) {
                                card.number = e.target.value;
                              }
                            });
                            setCardDetails(modifieddetails);
                          }}
                          // onBlur={formik.handleBlur}
                          value={item.number}
                          error={
                            formik.touched.cardNumber &&
                            Boolean(formik.errors.cardNumber)
                          }
                        />
                        <small className="error">
                          {formik.errors.cardNumber && formik.touched.cardNumber
                            ? formik.errors.cardNumber
                            : null}
                        </small>
                      </FormControl>
                      <FormControl fullWidth>
                        <label>Exp Month</label>
                        <TextField
                          size="small"
                          fullWidth
                          id="outlined-start-adornment"
                          placeholder="Exp Month"
                          name="exp_monnth"
                          onChange={(e) => {
                            const modifieddetails = [...cardDetails];
                            cardDetails.map((card) => {
                              if (card.id === item.id) {
                                card.exp_month = e.target.value;
                              }
                            });
                            setCardDetails(modifieddetails);
                          }}
                          // onBlur={formik.handleBlur}
                          value={item.exp_month}
                          error={
                            formik.touched.gstIn && Boolean(formik.errors.gstIn)
                          }
                        />
                        <small className="error">
                          {formik.errors.gstIn && formik.touched.gstIn
                            ? formik.errors.gstIn
                            : null}
                        </small>
                      </FormControl>
                      <FormControl fullWidth>
                        <label>Exp Year</label>
                        <TextField
                          size="small"
                          fullWidth
                          id="outlined-start-adornment"
                          placeholder="Exp Year"
                          name="exp_year"
                          onChange={(e) => {
                            const modifieddetails = [...cardDetails];
                            cardDetails.map((card) => {
                              if (card.id === item.id) {
                                card.exp_year = e.target.value;
                              }
                            });
                            setCardDetails(modifieddetails);
                          }}
                          // onBlur={formik.handleBlur}
                          value={item.exp_year}
                          error={
                            formik.touched.gstIn && Boolean(formik.errors.gstIn)
                          }
                        />
                        <small className="error">
                          {formik.errors.gstIn && formik.touched.gstIn
                            ? formik.errors.gstIn
                            : null}
                        </small>
                      </FormControl>
                      <FormControl fullWidth>
                        <label>CVV</label>
                        <TextField
                          size="small"
                          fullWidth
                          id="outlined-start-adornment"
                          placeholder="CVV"
                          name="cvv"
                          onChange={(e) => {
                            const modifieddetails = [...cardDetails];
                            cardDetails.map((card) => {
                              if (card.id === item.id) {
                                card.cvc = e.target.value;
                              }
                            });
                            setCardDetails(modifieddetails);
                          }}
                          // onBlur={formik.handleBlur}
                          value={item.cvc}
                          error={
                            formik.touched.gstIn && Boolean(formik.errors.gstIn)
                          }
                        />
                        <small className="error">
                          {formik.errors.gstIn && formik.touched.gstIn
                            ? formik.errors.gstIn
                            : null}
                        </small>
                      </FormControl>
                    </FormControl>
                  </>
                ))
              : ""}
            {formik.values.paymentType === "auto" ? (
              <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setCardDetails([
                      ...cardDetails,
                      {
                        id: cardDetails.length + 1,
                        number: "",
                        exp_month: "",
                        exp_year: "",
                        cvc: "",
                      },
                    ])
                  }
                >
                  Add New Card
                </Button>
              </Box>
            ) : (
              ""
            )}

            {/* <FormControl
                sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
              >
                <FormControl fullWidth>
                  <label>Process Fee</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Process Fee"
                    name="processFee"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.processFee}
                    error={
                      formik.touched.processFee &&
                      Boolean(formik.errors.processFee)
                    }
                  />
                  <small className="error">
                    {formik.errors.processFee && formik.touched.processFee
                      ? formik.errors.processFee
                      : null}
                  </small>
                </FormControl>
                <FormControl fullWidth>
                  <label>Process Fee Status</label>
                  <Select
                    name="processFeeStatus"
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Process Fee Status"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.processFeeStatus}
                    error={
                      formik.touched.processFeeStatus &&
                      Boolean(formik.errors.processFeeStatus)
                    }
                    fullWidth
                  >
                    <MenuItem value={0}>Select Status</MenuItem>
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                  <small className="error">
                    {formik.errors.processFeeStatus &&
                      formik.touched.processFeeStatus
                      ? formik.errors.processFeeStatus
                      : null}
                  </small>
                </FormControl>
              </FormControl> */}
            <FormControl
              sx={{ mb: 2, flexDirection: "row", gap: "10px", width: "100%" }}
            >
              <FormControl fullWidth>
                <label>Plan Type</label>
                <Select
                  name="planType"
                  size="small"
                  MenuProps={MenuProps}
                  sx={{ borderRadius: "8px" }}
                  placeholder="Plan type"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.planType}
                  error={
                    formik.touched.planType && Boolean(formik.errors.planType)
                  }
                  fullWidth
                >
                  <MenuItem value={0}>Select plan</MenuItem>
                  {planDetails.map((item) => (
                    <MenuItem value={item.planType}>{item.title}</MenuItem>
                  ))}
                </Select>
                <small className="error">
                  {formik.errors.planType && formik.touched.planType
                    ? formik.errors.planType
                    : null}
                </small>
              </FormControl>
              {/* <FormControl fullWidth>
                  <label>Discount</label>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Discount"
                    name="discount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.discount}
                    error={
                      formik.touched.discount && Boolean(formik.errors.discount)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                  <small className="error">
                    {formik.errors.discount && formik.touched.discount
                      ? formik.errors.discount
                      : null}
                  </small>
                </FormControl> */}
            </FormControl>

            <Button variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </Container>
      )}
    </Box>
  );
};

export default EditAccountDetails;
