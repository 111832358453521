import React, { useState } from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import { Button, Card, Grid } from "material-ui-core";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { CertificateArchivedApi } from "../../../redux/actions/LmsAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const TableHeader = (props) => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const data = {
    body: {
      pagination: {
        page: props.page,
        per_page: props.rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
        sort_by: "desc",
      },
      archived: false,
      search: "",
    },
  };

  const CertificateListArchivedApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `Archivedcertificate?company_id=${id}`;
    dispatch(CertificateArchivedApi(data));
  };
  useEffect(() => {
    if (props.checkedArchive === true) {
      data.body.pagination.page = 1;
      props.setPage(1);
      CertificateListArchivedApi(data);
    }
  }, [props.checkedArchive]);

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent deactive-bg"
            >
              <Tab
                label="Active"
                style={{marginLeft: 5}}
                icon={<p className="active-tabs-content">{props.totalitems}</p>}
                iconPosition="end"
                className={
                  props.checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(false)}
              />
              <Tab
                label="Archived"
                style={{marginLeft: 5}}
                icon={
                  <p className="active-tabs-content">
                    {props.ArchivedListTotalItems}
                  </p>
                }
                iconPosition="end"
                className={
                  props.checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(true)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" ,marginTop:"4px"}}>
              <SearchAppBar
                searchValue={props.searchValue}
                searchOnChange={props.searchOnChange}
                handleKeyDown={props.handleKeyDown}
              />
        
             <Link to="/certificate/add-certificate" className="submenu-item" >
             <Button className="header-add cancle-hover"
                style={{marginRight: 5, height:"42px"}}>
               <AddOutlinedIcon sx={{ mr: 1 }} />
               New Certificate
             </Button>
           </Link>
           <div style={{marginTop:"6px",marginRight:"10px"}}>
           <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
            </Stack>
          </Grid>
        </Grid>
      
      </Card>
    </>
  );
};

export default TableHeader;
