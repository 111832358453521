/** @format */

import React from "react";
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Typography } from "material-ui-core";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

function UPRHeader() {
  const [monthRecord, setmonthRecord] = React.useState("");

  const handleChangeSelect = (event) => {
    setmonthRecord(event.target.value);
  };
  return (
    <div>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} sm={12} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                align="left"
                variant="h5"
                component="h4"
                style={{ padding: "4px" }}
              >
                User Performance Report
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} sm={12} lg={6}>
            <Stack
              direction="row"
              sx={{ float: "right" }}
              className="checklist-report-header"
            >
              <Link
                to="/checklist/checklist-report/view-all-user-list"
                className="submenu-item-storeinfo"
              >
                <Button
                  variant="outlined"
                  className="done-btn-storeinfo cancle-hover"
                >
                  <VisibilityIcon />
                  View all
                </Button>
              </Link>
              <FormControl
                sx={{ minWidth: 150 }}
                size="small"
                className="location-storeinfo"
              >
                <InputLabel id="demo-select-small">Location</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={monthRecord}
                  label="Month Records"
                  onChange={handleChangeSelect}
                  style={{ borderRadius: "8px" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="lastSevenMonths">Select One</MenuItem>
                  <MenuItem value="lastThreeMonths">Select Two</MenuItem>
                  <MenuItem value="lastOneMonthly">Select Three</MenuItem>
                </Select>
              </FormControl>
              <Link to="/all-survey" className="submenu-item-storeinfo">
                <Button
                  variant="outlined"
                  className="done-btn-storeinfo cancle-hover"
                >
                  <DoneOutlinedIcon /> Apply
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default UPRHeader;
