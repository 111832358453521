/** @format */

import { CSVLink } from "react-csv";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import {
  CsvSubmissionListApi,
  ExcelChecklistDetailApi,
} from "../../redux/actions/csvSubmission";

import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import ReactExport from "react-export-excel-xlsx-fix";
import { Button } from "@mui/material";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarStatic from "../../GlobalProgress/ProgressbarStatic";
import { Box } from "material-ui-core";
import { toast } from "react-toastify";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Csv = () => {
  const btnRef = useRef();
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState([]);
  const [autoDownloadBtn, setAutoDownloadBtn] = useState(false);
  const [submissionAutoDownload, setSubmissionAutoDownload] = useState(false);
  const [checklistDetailAutoDownload, setChecklistDetailAutoDownload] =
    useState(false);
  const [excelSubmissionFlag, setExcelSubmissionFlag] = useState(false);
  const [excelChecklistDetailFlag, setExcelChecklistDetailFlag] =
    useState(false);
  const [excelData, setExcelData] = useState([]);
  const [excelChecklistDetails, setExcelChecklistDetails] = useState([]);
  const [excelChecklistCounts, setExcelChecklistCounts] = useState([]);
  // getting dateRangePicker data from LocalHost
  const storedDateString = localStorage.getItem("storedDateData");
  const storedDateObject = JSON.parse(storedDateString);
  const Loader = useSelector(
    (state) => state.CsvSubmissionData && state.CsvSubmissionData.loading
  );
  const ExcelChecklistDetail = useSelector(
    (state) =>
      state &&
      state?.ExcelChecklistDetail &&
      state?.ExcelChecklistDetail?.ExcelChecklistDetailData &&
      state?.ExcelChecklistDetail?.ExcelChecklistDetailData?.data &&
      state?.ExcelChecklistDetail?.ExcelChecklistDetailData?.data?.finalData
  );
  console.log(
    ExcelChecklistDetail,
    useSelector((state) => state && state?.ExcelChecklistDetail)
  );
  const ExcelChecklistDetails = useSelector(
    (state) => state && state?.ExcelChecklistDetail
  );
  const tableData = useSelector(
    (state) =>
      state &&
      state?.CsvSubmissionData &&
      state?.CsvSubmissionData?.csvSubmission &&
      state?.CsvSubmissionData?.csvSubmission?.data &&
      state?.CsvSubmissionData?.csvSubmission?.data?.data &&
      state?.CsvSubmissionData?.csvSubmission?.data?.data?.formsData
  );
  let newData = [];

  const handleAttahment = (dataArr, titleName) => {
    let answers = "";
    dataArr &&
      dataArr.length &&
      dataArr.map((value, key) => {
        if (key === dataArr.length - 1) {
          answers =
            answers +
            "https://popprobe-saas.s3.us-west-2.amazonaws.com/" +
            (titleName && value[titleName] ? value[titleName] : value);
        } else {
          answers =
            answers +
            "https://popprobe-saas.s3.us-west-2.amazonaws.com/" +
            (titleName && value[titleName]
              ? value[titleName] + ", "
              : value + ", ");
        }
      });
    return answers;
  };

  const handleMultiOptions = (dataArr, titleName) => {
    let answers = "";
    dataArr &&
      dataArr.length &&
      dataArr.map((value, key) => {
        if (key === dataArr.length - 1) {
          answers =
            answers +
            (titleName && value[titleName] ? value[titleName] : value);
        } else {
          answers =
            answers +
            (titleName && value[titleName]
              ? value[titleName] + ", "
              : value + ", ");
        }
      });
    return answers;
  };
  const handleMultiOptionsScore = (dataArr, titleName) => {
    let answers = "";
    dataArr &&
      dataArr.length &&
      dataArr.map((value, key) => {
        if (key == dataArr.length - 1) {
          answers =
            answers +
            (titleName && (value[titleName] || value[titleName] == 0)
              ? value[titleName]
              : "");
        } else {
          answers =
            answers +
            (titleName && value[titleName]
              ? (value[titleName] || value[titleName] == 0) + ", "
              : "");
        }
      });
    return answers;
  };
  const calculateWeighateScore = (data) => {
    if (data.forms) {
      let calculatedQuestionScore = 0;
      let calculatedOptionScore = 0;
      data.forms.map((formDetail) => {
        calculatedQuestionScore =
          calculatedQuestionScore + formDetail.calculatedQuestionScore;
        calculatedOptionScore =
          calculatedOptionScore + formDetail.calculatedOptionScore;
      });
      return ((calculatedOptionScore / calculatedQuestionScore) * 100).toFixed(
        2
      );
    }
  };
  const formatStringDate = (stringDate) => {
    return stringDate
      ? stringDate.slice(6, 8) +
          "/" +
          stringDate.slice(4, 6) +
          "/" +
          stringDate.slice(0, 4)
      : "";
  };
  const getChecklistCounts = (data, param, subDate) => {
    let totalCount = 0;
    if (data && data.length) {
      data.map((cDetail) => {
        totalCount =
          totalCount +
          (cDetail.Submissionstatus == param &&
          cDetail.SubmissionDate == subDate
            ? 1
            : 0);
      });
    }
    return totalCount;
  };
  useEffect(() => {
    let excelDataRow = [];
    tableData &&
      tableData?.length > 0 &&
      tableData?.map((csvData, index) => {
        let formCount = 0;
        for (let formData of csvData?.forms) {
          console.log("==========>", formData);
          let obj = {
            Sno: index + 1,
            User: csvData?.user?.userName,
            Role: csvData?.role,
            Store: csvData?.store?.title,
            Category: csvData?.category?.categoryTitle,
            FormName: formData?.title,
            Progress: `${csvData?.completePercentage}%`,
            SubmittedAt: `${moment(csvData?.updatedAt).format("lll")}`,
            WeightageScore: `${(
              (formData?.calculatedOptionScore /
                formData?.calculatedQuestionScore) *
              100
            ).toFixed(2)}%`,
            UserImage: formData?.userImages,
            Question: "",
            Answer: "",
            QuistionScore: "",
            OptionScore: "",
            ImageForThisQuistion: "",
            Comments: "",
          };
          newData?.push(obj);
          const erxcelDataPrepare = [
            {
              value: "" + (index + 1),
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: formCount != 0 ? "" : csvData?.user?.userName,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: formCount != 0 ? "" : csvData?.role,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: formCount != 0 ? "" : csvData?.store?.title,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: formCount != 0 ? "" : csvData?.category?.categoryTitle,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: formData?.title,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: `${csvData?.completePercentage}%`,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: `${moment(formData?.submittedTime).format("lll")}`,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: `${(
                (formData?.calculatedOptionScore /
                  formData?.calculatedQuestionScore) *
                100
              ).toFixed(2)}%`,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value:
                "https://popprobe-saas.s3.us-west-2.amazonaws.com/" +
                formData?.userImages,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                alignment: { wrapText: true },
              },
            },
            {
              value: csvData?.pdfReport,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                alignment: { wrapText: true },
              },
            },
            {
              value: "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
            {
              value: "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
          ];
          // excelDataRow.push(erxcelDataPrepare);
          formCount = formCount + 1;
          for (let qusData of formData?.questions) {
            // console.log("qusData:: ", qusData);
            let obj1 = {
              Sno: "",
              User: "",
              Role: "",
              Store: "",
              Category: "",
              FormName: "",
              Progress: "",
              SubmittedAt: "",
              WeightageScore: "",
              UserImage: "",
              Question: qusData?.title,
              Answer:
                qusData?.answer?.type === "boolean"
                  ? qusData?.answer?.answer?.title
                  : qusData?.answer?.type === "number"
                  ? qusData?.answer?.answer
                  : qusData?.answer?.type === "single_select"
                  ? qusData?.answer?.answer?.title
                  : qusData?.answer?.type === "multi_select"
                  ? handleMultiOptions(qusData?.answer?.answer, "title")
                  : qusData?.answer?.type === "file"
                  ? handleMultiOptions(qusData?.answer?.answer, "")
                  : qusData?.answer?.type === "timer"
                  ? qusData?.answer?.answer
                  : qusData?.answer?.type === "date"
                  ? qusData?.answer?.answer
                  : qusData?.answer?.type === "time"
                  ? qusData?.answer?.answer
                  : qusData?.answer?.type === "datetime"
                  ? qusData?.answer?.answer?.date +
                    " " +
                    qusData?.answer?.answer?.time
                  : qusData?.answer?.type === "text"
                  ? qusData?.answer?.answer
                  : "",
              QuestionScore: qusData?.question_score,
              OptionScore:
                qusData?.answer?.type === "boolean"
                  ? qusData?.answer?.answer?.option_score
                  : qusData?.answer?.type === "multi_select"
                  ? qusData?.answer?.answer?.map((item, index) => {
                      return item.option_score;
                    })
                  : qusData?.answer?.type === "single_select"
                  ? qusData?.answer?.answer?.option_score
                  : "",
              ImageForThisQuistion:
                "https://popprobe-saas.s3.us-west-2.amazonaws.com/" +
                qusData?.images,
              Comments: qusData?.comment ? qusData?.comment : "",
            };
            const erxcelDataQPrepare = [
              {
                value: "" + (index + 1),
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: csvData?.user?.userName,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: csvData?.role,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: csvData?.store?.title,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: csvData?.category?.categoryTitle,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formData?.title,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: `${csvData?.completePercentage}%`,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: `${moment(formData?.submittedTime).format("lll")}`,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: `${(
                  (formData?.calculatedOptionScore /
                    formData?.calculatedQuestionScore) *
                  100
                ).toFixed(2)}%`,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value:
                  "https://popprobe-saas.s3.us-west-2.amazonaws.com/" +
                  formData?.userImages,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                  alignment: { wrapText: true },
                },
              },
              {
                value: csvData?.pdfReport,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                  alignment: { wrapText: true },
                },
              },
              { value: qusData?.title },
              {
                value:
                  qusData?.answer?.type === "boolean"
                    ? qusData?.answer?.answer?.title
                    : qusData?.answer?.type === "number"
                    ? "" +
                      (qusData?.answer?.answer.value
                        ? qusData?.answer?.answer.value
                        : qusData?.answer?.answer)
                    : qusData?.answer?.type === "single_select"
                    ? qusData?.answer?.answer?.title
                    : qusData?.answer?.type === "multi_select"
                    ? handleMultiOptions(qusData?.answer?.answer, "title")
                    : qusData?.answer?.type === "file"
                    ? handleMultiOptions(qusData?.answer?.answer, "")
                    : qusData?.answer?.type === "timer"
                    ? "" +
                      (qusData?.answer?.answer.value
                        ? qusData?.answer?.answer.value
                        : qusData?.answer?.answer)
                    : qusData?.answer?.type === "date"
                    ? qusData?.answer?.answer.value
                      ? qusData?.answer?.answer.value
                      : qusData?.answer?.answer
                    : qusData?.answer?.type === "time"
                    ? qusData?.answer?.answer.value
                      ? qusData?.answer?.answer.value
                      : qusData?.answer?.answer
                    : qusData?.answer?.type === "datetime"
                    ? qusData?.answer?.answer?.date +
                      " " +
                      qusData?.answer?.answer?.time
                    : qusData?.answer?.type === "text"
                    ? qusData?.answer?.answer?.value
                      ? "" + qusData?.answer?.answer.value
                      : "" + qusData?.answer?.answer
                    : "",
                style: { alignment: { wrapText: true } },
              },
              { value: "" + qusData?.question_score },
              {
                value:
                  qusData?.answer?.type === "boolean"
                    ? "" +
                      (qusData?.answer?.answer?.option_score ||
                      qusData?.answer?.answer?.option_score == 0
                        ? qusData?.answer?.answer?.option_score
                        : "")
                    : qusData?.answer?.type === "multi_select"
                    ? handleMultiOptionsScore(
                        qusData?.answer?.answer,
                        "option_score"
                      )
                    : qusData?.answer?.type === "single_select"
                    ? "" +
                      (qusData?.answer?.answer?.option_score ||
                      qusData?.answer?.answer?.option_score == 0
                        ? qusData?.answer?.answer?.option_score
                        : "")
                    : qusData?.answer?.answer.option_score &&
                      (qusData?.answer?.answer?.option_score ||
                        qusData?.answer?.answer?.option_score == 0)
                    ? "" + qusData?.answer?.answer?.option_score
                    : "",
              },
              { value: qusData?.comment ? qusData?.comment : "" },
              {
                value:
                  qusData?.images && qusData?.images.length
                    ? handleAttahment(
                        [...qusData?.images, ...(qusData?.videos || [])],
                        "url"
                      )
                    : "",
                style: { alignment: { wrapText: true } },
              },
            ];
            newData?.push(obj1);
            excelDataRow.push(erxcelDataQPrepare);
          }
        }
      });
    const dataSet = [
      {
        columns: [
          {
            value: "S.No",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "User",
            widthPx: 90,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
              fill: { patternType: "solid", bgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: "Role",
            widthPx: 70,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            }, // if no width set, default excel column width will be used ( 64px )
          },
          {
            value: "Store",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Checklist Name",
            widthPx: 200,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Form Name",
            widthPx: 200,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Progress(%)",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submission Time",
            widthPx: 120,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Weightage Score",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "User Image",
            widthPx: 250,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "PDF Report",
            widthPx: 250,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Question",
            widthPx: 250,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Answer",
            widthPx: 200,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Question Score",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Option Score",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Comments",
            widthPx: 150,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Attachments",
            widthPx: 350,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
        ],
        data: excelDataRow,
      },
    ];
    console.log("dataSet", dataSet);
    setExcelData(dataSet);
    if (
      tableData &&
      tableData.length &&
      excelDataRow &&
      excelDataRow.length &&
      excelSubmissionFlag
    ) {
      console.log("setting flag true setSubmissionAutoDownload");
      setSubmissionAutoDownload(true);
    }
  }, [tableData]);
  useEffect(() => {
    let excelDataRow = [];
    let excelRowChecklistCounts = [];
    ExcelChecklistDetail &&
      ExcelChecklistDetail?.length > 0 &&
      ExcelChecklistDetail?.map((csvData, index) => {
        // const erxcelDataPrepare = [
        //   {
        //     value: "" + (index + 1),
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: csvData?.title,
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: csvData?.frequency == 1 ? "No Frequency" : (csvData?.frequency == 1 ? "Hourly" : (csvData?.frequency == 3 ? "Daily" : (csvData?.frequency == 4 ? "Weekly" : "Monthly"))),
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: csvData.role ? csvData?.role : csvData?.assignedTo?.name,
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: formatStringDate(csvData.starts_at),
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: formatStringDate(csvData.expires_at),
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: csvData.startTime && csvData.endTime ? csvData.startTime +" - "+ csvData.endTime : (csvData.startTime ? csvData.startTime : '00:00 - ' + (csvData.endTime ? csvData.endTime : '24:00')),
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: "",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
        //     },
        //   },
        //   {
        //     value: csvData.isActive ? "Enable" : "Disable",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }, // `${csvData?.completePercentage}%`,
        //     },
        //   },
        //   {
        //     value: csvData.deleted ? "Yes" : "No",
        //     style: {
        //       fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }, // `${csvData?.completePercentage}%`,
        //     },
        //   },
        // ];
        const erxcelDataCountPrepare = [
          {
            value: "" + (index + 1),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: csvData?.title,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value:
              csvData?.frequency == 1
                ? "No Frequency"
                : csvData?.frequency == 2
                ? "Hourly"
                : csvData?.frequency == 3
                ? "Daily"
                : csvData?.frequency == 4
                ? "Weekly"
                : "Monthly",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: csvData.role ? csvData?.role : csvData?.assignedTo?.name,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: formatStringDate(csvData.starts_at),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: formatStringDate(csvData.expires_at),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value:
              csvData.startTime && csvData.endTime
                ? csvData.startTime + " - " + csvData.endTime
                : csvData.startTime
                ? csvData.startTime
                : "00:00 - " + (csvData.endTime ? csvData.endTime : "24:00"),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: "",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: "",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
          {
            value: "",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
        ];
        // excelDataRow.push(erxcelDataPrepare);
        // excelRowChecklistCounts.push(erxcelDataCountPrepare);
        const dateArr = [];
        if (csvData.submission && csvData.submission.length) {
          for (let formData of csvData?.submission) {
            const erxcelDataPrepare = [
              {
                value: "" + (index + 1),
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: csvData?.title,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value:
                  csvData?.frequency == 1
                    ? "No Frequency"
                    : csvData?.frequency == 2
                    ? "Hourly"
                    : csvData?.frequency == 3
                    ? "Daily"
                    : csvData?.frequency == 4
                    ? "Weekly"
                    : "Monthly",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: csvData.role ? csvData?.role : csvData?.assignedTo?.name,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formatStringDate(csvData.starts_at),
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formatStringDate(csvData.expires_at),
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value:
                  csvData.startTime && csvData.endTime
                    ? csvData.startTime + " - " + csvData.endTime
                    : csvData.startTime
                    ? csvData.startTime
                    : "00:00 - " +
                      (csvData.endTime ? csvData.endTime : "24:00"),
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formData?.Username,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formData?.Role,
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formData?.SubmissionDate
                  ? `${moment(formData?.SubmissionDate).format("DD-MM-YYYY")}`
                  : "N/A",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: formData.Submissionstatus
                  ? formData.Submissionstatus.charAt(0).toUpperCase() +
                    formData.Submissionstatus.slice(1)
                  : "N/A",
                // value:
                //   formData.ChecklistSubmit && formData.ChecklistSubmit.length
                //     ? "Submitted"
                //     : "Pending",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value:
                  formData.ChecklistSubmit &&
                  formData.ChecklistSubmit.length &&
                  formData.ChecklistSubmit[0]?.completePercentage
                    ? `${formData.ChecklistSubmit[0]?.completePercentage.toFixed(
                        2
                      )}%`
                    : "",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value:
                  formData.ChecklistSubmit && formData.ChecklistSubmit.length
                    ? calculateWeighateScore(formData.ChecklistSubmit[0])
                    : "",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              // working here
              // {
              //   value:
              //     formData.ChecklistSubmit && formData.ChecklistSubmit.length
              //       ? formData.ChecklistSubmit[0].store.title
              //       : "",
              //   style: {
              //     fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              //   },
              // },
              {
                value: formData?.StoreName || "NA",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value:
                  formData.ChecklistSubmit && formData.ChecklistSubmit.length
                    ? formData.ChecklistSubmit[0].pdfReport
                    : "",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: "",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
              {
                value: "",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                },
              },
            ];
            excelDataRow.push(erxcelDataPrepare);
            if (!dateArr.includes(formData?.SubmissionDate)) {
              const excelChecklistCountPrepare = [
                {
                  value: "" + (index + 1),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: csvData?.title,
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value:
                    csvData?.frequency == 1
                      ? "No Frequency"
                      : csvData?.frequency == 2
                      ? "Hourly"
                      : csvData?.frequency == 3
                      ? "Daily"
                      : csvData?.frequency == 4
                      ? "Weekly"
                      : "Monthly",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: csvData.role
                    ? csvData?.role
                    : csvData?.assignedTo?.name,
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: formatStringDate(csvData.starts_at),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: formatStringDate(csvData.expires_at),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value:
                    csvData.startTime && csvData.endTime
                      ? csvData.startTime + " - " + csvData.endTime
                      : csvData.startTime
                      ? csvData.startTime
                      : "00:00 - " +
                        (csvData.endTime ? csvData.endTime : "24:00"),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: formData?.SubmissionDate
                    ? `${moment(formData?.SubmissionDate).format("DD-MM-YYYY")}`
                    : "N/A",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value:
                    "" +
                    getChecklistCounts(
                      csvData?.submission,
                      "submitted",
                      formData?.SubmissionDate
                    ),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value:
                    "" +
                    getChecklistCounts(
                      csvData?.submission,
                      "pending",
                      formData?.SubmissionDate
                    ),
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
              ];
              dateArr.push(formData?.SubmissionDate);
              excelRowChecklistCounts.push(excelChecklistCountPrepare);
            }
          }
        }
      });
    const dataSet = [
      {
        columns: [
          {
            value: "S.No",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Checklist Name",
            widthPx: 200,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Frequency",
            widthPx: 80,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            }, // if no width set, default excel column width will be used ( 64px )
          },
          {
            value: "Assign To",
            widthPx: 80,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Start Date",
            widthPx: 90,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Expire Date",
            widthPx: 90,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Timing",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "User Name",
            widthPx: 120,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "User Role",
            widthPx: 120,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submission Date",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submission Status",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Progress",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Weightage Score",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submission Store",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submission Report",
            widthPx: 250,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Status",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Archived",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
        ],
        data: excelDataRow,
      },
    ];
    const dataCountSet = [
      {
        columns: [
          {
            value: "S.No",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Checklist Name",
            widthPx: 200,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Frequency",
            widthPx: 80,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            }, // if no width set, default excel column width will be used ( 64px )
          },
          {
            value: "Assign To",
            widthPx: 80,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Start Date",
            widthPx: 90,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Expire Date",
            widthPx: 90,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Timing",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submission Date",
            widthPx: 120,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submitted",
            widthPx: 120,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Pending",
            widthPx: 100,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
        ],
        data: excelRowChecklistCounts,
      },
    ];
    setExcelChecklistCounts(dataCountSet);
    setExcelChecklistDetails(dataSet);
    if (
      ExcelChecklistDetail &&
      ExcelChecklistDetail.length &&
      excelDataRow &&
      excelDataRow.length &&
      excelChecklistDetailFlag
    ) {
      setChecklistDetailAutoDownload(true);
    }
  }, [ExcelChecklistDetail]);
  console.log();
  useEffect(() => {
    console.log(
      submissionAutoDownload && checklistDetailAutoDownload && autoDownloadBtn
    );
    if (
      submissionAutoDownload &&
      checklistDetailAutoDownload &&
      autoDownloadBtn
    ) {
      btnRef.current.click();
      // setAutoDownload(false);
      // setExcelSubmissionFlag(false);
      setAutoDownloadBtn(false);
      setSubmissionAutoDownload(false);
      setChecklistDetailAutoDownload(false);
      setExcelChecklistDetailFlag(false);
      setExcelSubmissionFlag(false);
    }
  }, [submissionAutoDownload, checklistDetailAutoDownload, autoDownloadBtn]);

  // const checklistProgressPostAPI = (payloadRequest) => {
  //   const id = localStorage.getItem("companyId");
  //   payloadRequest.url =
  //     BASE_URL + `checklists/progress?company_id=${id}&noPagination=true`;
  //   dispatch(CsvSubmissionListApi(payloadRequest));
  //   setExcelSubmissionFlag(true);
  // };

  // console.log("Csv.js Data", storedDateObject);
  // const checklistProgressPostAPI = (payloadRequest) => {
  //   if (storedDateObject?.startDate && storedDateObject?.endDate) {
  //     const id = localStorage.getItem("companyId");
  //     payloadRequest.url =
  //       BASE_URL +
  //       `checklists/progress?company_id=${id}&StartDate=${storedDateObject?.startDate}&EndDate=${storedDateObject?.endDate}`;
  //   }
  //   dispatch(CsvSubmissionListApi(payloadRequest));
  //   setExcelSubmissionFlag(true);
  // };

  const checklistProgressPostAPI = (payloadRequest) => {
    const today = moment().format("YYYY-MM-DD");
    const startDate = storedDateObject?.startDate || today;
    const endDate = storedDateObject?.endDate || today;
    const id = localStorage.getItem("companyId");

    if (storedDateObject?.startDate && storedDateObject?.endDate) {
      payloadRequest.url =
        BASE_URL +
        `checklists/progress?company_id=${id}&StartDate=${storedDateObject?.startDate}&EndDate=${storedDateObject?.endDate}&noPagination=true`;
    } else {
      payloadRequest.url =
        BASE_URL +
        `checklists/progress?company_id=${id}&StartDate=${startDate}&EndDate=${endDate}&noPagination=true`;
    }

    dispatch(CsvSubmissionListApi(payloadRequest));
    setExcelSubmissionFlag(true);
  };

  // const checklistDetailsGetAPI = (payloadRequest) => {
  //   const id = localStorage.getItem("companyId");
  //   payloadRequest.url = BASE_URL + `Frequencysubmission?company_id=${id}`;
  //   dispatch(ExcelChecklistDetailApi(payloadRequest));
  //   setExcelChecklistDetailFlag(true);
  // };

  // const checklistDetailsGetAPI = (payloadRequest) => {
  //   if (storedDateObject?.startDate && storedDateObject?.endDate) {
  //     const id = localStorage.getItem("companyId");
  //     const url =
  //       BASE_URL +
  //       `Frequencysubmission?company_id=${id}&StartDate=${storedDateObject?.startDate}&EndDate=${storedDateObject?.endDate}`;

  //     payloadRequest = {
  //       ...payloadRequest,
  //       url: url,
  //     };

  //     dispatch(ExcelChecklistDetailApi(payloadRequest));
  //     setExcelChecklistDetailFlag(true);
  //   }
  // };

  const checklistDetailsGetAPI = (payloadRequest) => {
    const today = moment().format("YYYY-MM-DD");
    const startDate = storedDateObject?.startDate || today;
    const endDate = storedDateObject?.endDate || today;

    const id = localStorage.getItem("companyId");
    if (storedDateObject?.startDate && storedDateObject?.endDate) {
      // `Frequencysubmission?company_id=${id}&start_date=${storedDateObject?.startDate}&end_date=${storedDateObject?.endDate}`;
      const url =
        BASE_URL +
        `Frequencysubmission?company_id=${id}&start_date=${storedDateObject?.startDate}&end_date=${storedDateObject?.startDate}`;

      payloadRequest = {
        ...payloadRequest,
        url: url,
      };
    } else {
      // const url =
      //   BASE_URL +
      //   `Frequencysubmission?company_id=${id}&start_date=${startDate}&end_date=${endDate}`;
      const url =
        BASE_URL +
        `Frequencysubmission?company_id=${id}&start_date=${startDate}&end_date=${startDate}`;

      payloadRequest = {
        ...payloadRequest,
        url: url,
      };
    }

    dispatch(ExcelChecklistDetailApi(payloadRequest));
    setExcelChecklistDetailFlag(true);
  };

  const getExcelData = () => {
    toast.success("Preparing excel data", { autoClose: 2000 });
    // const id = localStorage.getItem("companyId");
    // payloadRequest.url =
    //   BASE_URL + `checklists/progress?company_id=${id}&noPagination=true`;
    // dispatch(CsvSubmissionListApi(payloadRequest));
    // toast.success("Preparing excel data", { autoClose: 2000 });
    // setExcelSubmissionFlag(true);
    const payloadRequest = {
      body: {
        sort_as: "updatedAt",
        sort_by: "desc",
        Role: [],
      },
    };
    checklistProgressPostAPI(payloadRequest);
    checklistDetailsGetAPI(payloadRequest);
    setAutoDownloadBtn(true);
  };
  console.log(
    excelData,
    excelChecklistDetails,
    excelChecklistCounts,
    submissionAutoDownload,
    excelChecklistDetailFlag,
    checklistDetailAutoDownload,
    autoDownloadBtn
  );
  return (
    <>
      {/* <Button
        className="header-add cancle-hover"
        style={{ height: "46px", padding: "12px 16px" }}
      >
      <div style={{ width: "100px" }}>
        <CSVLink
          newLineSeparator="\r\n"
          data={newData}
          headers={headers}
          style={{ color: "white", textDecoration: "none" }}
          filename="submissionData"
          separator={"\t"}
        >
          Export CSV
        </CSVLink>
      </div>
      </Button> */}
      <div>
        {Loader ? (
          <Button
            className="header-add"
            sx={{ whiteSpace: "nowrap", height: "42px" }}
            disabled={true}
          >
            Loading Excel...
            <ProgressbarStatic />
          </Button>
        ) : (
          <Button
            className="header-add cancle-hover"
            sx={{
              whiteSpace: "nowrap",
              height: "42px",
              marginRight: { lg: 1 },
            }}
            onClick={getExcelData}
          >
            Export Excel
          </Button>
        )}
        <Box>
          <ExcelFile
            element={
              <Button
                className="header-add cancle-hover"
                sx={{ whiteSpace: "nowrap", display: "none", height: "42px" }}
                ref={btnRef}
              >
                Export Excel
              </Button>
            }
            filename={"PopprobeChecklistRecords_" + Date.now()}
          >
            <ExcelSheet
              dataSet={excelChecklistCounts}
              name="Checklist Summary"
            />
            <ExcelSheet
              dataSet={excelChecklistDetails}
              name="Checklist Details"
            />
            <ExcelSheet dataSet={excelData} name="Submission Record" />
          </ExcelFile>
        </Box>
      </div>
    </>
  );
};

export default Csv;
