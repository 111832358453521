/** @format */

import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ProductHeader } from "../../pages/Header";
import { DataGrid } from "@mui/x-data-grid";
import TablePagination from "@mui/material/TablePagination";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { WasteProductListApi } from "../../../redux/actions/WasteManagement";
import { useSelector } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Edit from "../../../images/Edit.svg";
import Delete from "../../../images/Delete.svg";
import DeleteHover from "../../../images/DeleteHover.svg";
import EditHover from "../../../images/EditHover.svg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { UserDeleteApi } from "../../../redux/actions/User";
import { ToastContainer } from "react-toastify";
import AddProductModal from "./pages/AddProduct";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../../../GlobalComponent/SingleGlobalTable";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const ProductDetail = ({ title }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productListData, setProductListData] = useState([]);
  const [permission, setPermission] = useState({});
  const [open, setOpen] = useState(false);
  const [editStr, setEditStr] = useState("");
  const [editvalue, setEditvalue] = useState({});
  const [indexHover, setIndexHover] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const [localPageNo, setLocalPageNo] = useState(1);
  const [AddProductRes, setAddProductRes] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  const Loader = useSelector(
    (state) => state.wastemng && state.wastemng.loading
  );
  const productList = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.productListData &&
      state?.wastemng?.productListData?.data &&
      state?.wastemng?.productListData?.data?.data &&
      state?.wastemng?.productListData?.data?.data?.products
  );
  const totalitems = useSelector(
    (state) =>
      state &&
      state?.wastemng &&
      state?.wastemng?.productListData &&
      state?.wastemng?.productListData?.data &&
      state?.wastemng?.productListData?.data?.data &&
      state?.wastemng?.productListData?.data?.data?.paginate
  );

  const deleteResponse = useSelector(
    (state) =>
      state.user &&
      state.user.userDelete &&
      state.user.userDelete.data &&
      state.user.userDelete.data.data
  );

  const data = {
    page: page,
    per_page: rowsPerPage,
  };

  var pageno = localStorage.getItem("pageno");

  const addResData = (addProductRes) => {
    if (addProductRes) {
      setTimeout(() => {
        ProductList(data);
      }, 500);
    }
  };

  useEffect(() => {
    ProductList(data);
  }, [deleteResponse]);

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    setTotalCount(totalitems?.total_item);
  }, [totalitems]);

  useEffect(() => {
    // setLoader(true);
    setProductListData(productList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [productList]);

  useEffect(() => {
    ProductList(data);
  }, []);

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    data.page = newPage + 1;
    ProductList(data);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    data.per_page = parseInt(event.target.value, 10);
    data.page = 1;
    ProductList(data);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const ProductList = (data) => {
    const id = localStorage.getItem("companyId");
    data.url =
      BASE_URL +
      `inventory/products?pagination={"page":${data?.page},"per_page":${data?.per_page}}&company_id=${id}`;
    dispatch(WasteProductListApi(data));
  };

  const handleDelete = (userid) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this role.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const userRoleId = userid;
            const data = {
              url:
                BASE_URL + `inventory/product/${userRoleId}?company_id=${id}`,
            };
            setLoader(true);
            dispatch(UserDeleteApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleEdit = (row) => {
    setEditStr("editProduct");
    setEditvalue(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getIndex(params) {
    return Number(params?.row?.index) + 1;
  }

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          productListData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },
    {
      field: "Product Name",
      headerName: "Product Name",
      renderHeader: () => <strong>{"Product Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => <strong>{"Description"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.description) {
          result.push(params?.row?.description);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Price",
      headerName: "Price",
      renderHeader: () => <strong>{"Price"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.price) {
          result.push(params?.row?.price);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Category",
      headerName: "Category",
      renderHeader: () => <strong>{"Category"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.category?.title) {
          result.push(params?.row?.category?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "UOM",
      headerName: "UOM",
      renderHeader: () => <strong>{"UOM"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.uom?.title) {
          result.push(params?.row?.uom?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      renderHeader: () => <strong>{"Created At"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let timeFormate = moment(params?.row?.createdAt).format("LTS");
        let dateFormate = moment(params?.row?.createdAt).format("MMMM D, YYYY");
        let result = [];
        if (timeFormate && dateFormate) {
          result.push(dateFormate, timeFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {permission?.wastageLog?.manageWastageLog === true ? (
              <>
                <img
                  onClick={() => handleEdit(params?.row)}
                  src={
                    hover.Edit && indexHover === params?.row?._id
                      ? EditHover
                      : Edit
                  }
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Edit: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Edit: false });
                  }}
                />
                <img
                  onClick={() => handleDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                  }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </>
            ) : (
              <b>Access Denied</b>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <ProductHeader
        title={title}
        setOpen={setOpen}
        open={open}
        productListData={productListData}
      />
      <SingleGlobalTable
        data={productListData}
        columns={columns}
        totalCount={totalCount}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <AddProductModal
        open={open}
        handleClose={handleClose}
        addResData={addResData}
        editvalue={editvalue}
        editStr={editStr}
      />
    </Box>
  );
};

export default ProductDetail;
