/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Stack,
  Grid,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import Chart from "react-apexcharts";
import { checklistCompletedApi } from "../../redux/actions/dashboardChartAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { LoaderComponent } from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

let chartOne = {
  colors: ["#CBD4E1", "#64748B"],
  plotOptions: {
    bar: {
      horizontal: false,
      distributed: true,
      dataLabels: {
        position: "none",
      },
      borderRadius: 2,
    },
  },
  chart: {
    id: "basic-bar",
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    axisBorder: {
      show: false,
    },
    title: {
      text: "completed",
    },
  },
  grid: {
    row: {
      opacity: 0,
    },
    column: {
      opacity: 0,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  legend: {
    show: false,
  },
};

let chartTwo = {
  colors: ["#F39A4C", "#039855"],
  chart: {
    type: "area",
    height: 350,
    // stacked: true,
    // stackType: "100%",
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100],
    },
  },
  // responsive: [
  //   {
  //     breakpoint: 480,
  //     options: {
  //       legend: {
  //         position: "bottom",
  //         offsetX: -10,
  //         offsetY: 0,
  //       },
  //     },
  //   },
  // ],
  xaxis: {
    categories: [
      "2011 Q1",
      "2011 Q2",
      "2011 Q3",
      "2011 Q4",
      "2012 Q1",
      "2012 Q2",
      "2012 Q3",
      "2012 Q4",
    ],
  },
  // fill: {
  //   opacity: 1,
  // },
  legend: {
    position: "right",
    offsetX: 0,
    offsetY: 50,
  },
  dataLabels: {
    enabled: false,
  },
};

const GraphCards = ({ companyId, frequency }) => {
  const [selectedRole, setSelectedRole] = useState([]);
  const dispatch = useDispatch();

  const checklistCompletedRes = useSelector(
    (state) =>
      state &&
      state.dashboardReducer &&
      state.dashboardReducer.data &&
      state.dashboardReducer.data.data &&
      state.dashboardReducer.data?.data?.data?.CheckList
  );
  const issuesRaisedRes = useSelector(
    (state) =>
      state &&
      state.dashboardReducer &&
      state.dashboardReducer.data &&
      state.dashboardReducer.data.data &&
      state.dashboardReducer.data.data?.data?.Issues
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );
  useEffect(() => {
    if (selectedSubRole) {
      setSelectedRole(selectedSubRole);
    }
  }, [selectedSubRole]);
  const Loader = useSelector(
    (state) => state.dashboardReducer && state.dashboardReducer.loading
  );
  const [checklistValues, setChecklistValues] = useState("");
  const [issueRaisedValue, setIssueRaisedValue] = useState("");
  const [value, setValue] = useState("days");
  const [value1, setValue1] = useState("Checklist");
  const [uiRender, setUiRender] = useState(false);

  const [optionOne, setOptionOne] = useState(chartOne);

  const [optionTwo, setOptionTwo] = useState(chartTwo);

  const [chartDataOne, setChartDataOne] = useState([
    {
      name: "Submitted",
      data: [],
    },
  ]);
  const [chartDataTwo, setChartDataTwo] = useState([
    {
      name: "Issue Arised",
      data: [],
    },
  ]);
  const [chartTwoDataOne, setChartTwoDataOne] = useState([
    {
      name: "Created",
      data: [],
    },
    {
      name: "Submitted",
      data: [],
    },
  ]);
  const [chartTwoDataTwo, setChartTwoDataTwo] = useState([
    {
      name: "Created",
      data: [],
    },
    {
      name: "Resolved",
      data: [],
    },
  ]);

  useEffect(() => {
    checklistCompletedData(companyId, value, selectedRole);
  }, []);

  useEffect(() => {
    if (checklistCompletedRes !== undefined && issuesRaisedRes !== undefined) {
      setChecklistValues(checklistCompletedRes);
      setIssueRaisedValue(issuesRaisedRes);
    }
    const getYesterdays = getYesterdaysDate();
    let catArray = [];
    if (value && value == "days") {
      catArray = [getYesterdays, new Date().toLocaleDateString("en-GB")];
    } else if (value && value == "monthly") {
      catArray = ["Last Month", "Current Month"];
    } else if (value && value == "weekly") {
      catArray = ["Last Week", "Current Week"];
    }
    setOptionOne((options) => ({
      ...options,
      xaxis: {
        ...options.xaxis,
        categories: catArray,
      },
    }));
    let chartData = [
      {
        name: "Submitted",
        data: [],
      },
    ];
    let chartData2 = [
      {
        name: " Issue Arised",
        data: [],
      },
    ];
    if (checklistCompletedRes !== undefined && issuesRaisedRes !== undefined) {
      chartData[0].data.push(
        checklistCompletedRes[1]?.completed
          ? checklistCompletedRes[1]?.completed
          : 0
      );
      chartData[0].data.push(
        checklistCompletedRes[0]?.completed
          ? checklistCompletedRes[0]?.completed
          : 0
      );
      chartData2[0].data.push(
        issuesRaisedRes[1]?.Created ? issuesRaisedRes[1]?.Created : 0
      );
      chartData2[0].data.push(
        issuesRaisedRes[0]?.Created ? issuesRaisedRes[0]?.Created : 0
      );
    }
    setChartDataOne(chartData);
    setChartDataTwo(chartData2);
  }, [checklistCompletedRes, issuesRaisedRes, value]);

  useEffect(() => {
    if (checklistCompletedRes) {
      if (
        checklistCompletedRes !== undefined &&
        issuesRaisedRes !== undefined
      ) {
        setChecklistValues(checklistCompletedRes);
        setIssueRaisedValue(issuesRaisedRes);
      }
      let catArray1 = [];
      for (let item of checklistValues) {
        const getdates = moment(item?.Date).format("ll");
        catArray1.push(getdates);
      }
      if (value && value == "days") {
        catArray1;
      }
      if (value && value == "monthly") {
        catArray1;
      }
      if (value && value == "weekly") {
        catArray1;
      }
      setOptionTwo((options) => ({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: catArray1,
        },
      }));

      let chartData = [
        {
          name: "Created",
          data: [],
        },
        {
          name: "Submitted",
          data: [],
        },
      ];
      let chartData2 = [
        {
          name: "Created",
          data: [],
        },
        {
          name: "Resolved",
          data: [],
        },
      ];

      if (
        checklistCompletedRes !== undefined &&
        issuesRaisedRes !== undefined
      ) {
        chartData[0].data.push(
          checklistCompletedRes[0]?.assigned
            ? checklistCompletedRes[0]?.assigned
            : 0
        );
        chartData[0].data.push(
          checklistCompletedRes[1]?.assigned
            ? checklistCompletedRes[1]?.assigned
            : 0
        );
        chartData[0].data.push(
          checklistCompletedRes[2]?.assigned
            ? checklistCompletedRes[2]?.assigned
            : 0
        );
        chartData[0].data.push(
          checklistCompletedRes[3]?.assigned
            ? checklistCompletedRes[3]?.assigned
            : 0
        );
        chartData[0].data.push(
          checklistCompletedRes[4]?.assigned
            ? checklistCompletedRes[4]?.assigned
            : 0
        );
        chartData[1].data.push(
          checklistCompletedRes[0]?.completed
            ? checklistCompletedRes[0]?.completed
            : 0
        );
        chartData[1].data.push(
          checklistCompletedRes[1]?.completed
            ? checklistCompletedRes[1]?.completed
            : 0
        );
        chartData[1].data.push(
          checklistCompletedRes[2]?.completed
            ? checklistCompletedRes[2]?.completed
            : 0
        );
        chartData[1].data.push(
          checklistCompletedRes[3]?.completed
            ? checklistCompletedRes[3]?.completed
            : 0
        );
        chartData[1].data.push(
          checklistCompletedRes[4]?.completed
            ? checklistCompletedRes[4]?.completed
            : 0
        );

        chartData2[0].data.push(
          issuesRaisedRes[0]?.Created ? issuesRaisedRes[0]?.Created : 0
        );
        chartData2[0].data.push(
          issuesRaisedRes[1]?.Created ? issuesRaisedRes[1]?.Created : 0
        );
        chartData2[0].data.push(
          issuesRaisedRes[2]?.Created ? issuesRaisedRes[2]?.Created : 0
        );
        chartData2[0].data.push(
          issuesRaisedRes[3]?.Created ? issuesRaisedRes[3]?.Created : 0
        );
        chartData2[0].data.push(
          issuesRaisedRes[4]?.Created ? issuesRaisedRes[4]?.Created : 0
        );
        chartData2[1].data.push(
          issuesRaisedRes[0]?.Resolved ? issuesRaisedRes[0]?.Resolved : 0
        );
        chartData2[1].data.push(
          issuesRaisedRes[1]?.Resolved ? issuesRaisedRes[1]?.Resolved : 0
        );
        chartData2[1].data.push(
          issuesRaisedRes[2]?.Resolved ? issuesRaisedRes[2]?.Resolved : 0
        );
        chartData2[1].data.push(
          issuesRaisedRes[3]?.Resolved ? issuesRaisedRes[3]?.Resolved : 0
        );
        chartData2[1].data.push(
          issuesRaisedRes[4]?.Resolved ? issuesRaisedRes[4]?.Resolved : 0
        );
      }
      console.log({ chartData });
      setChartTwoDataOne(chartData);

      setChartTwoDataTwo(chartData2);
    }
  }, [
    checklistCompletedRes,
    issuesRaisedRes,
    checklistValues,
    issueRaisedValue,
    value,
    value1,
  ]);

  function getYesterdaysDate() {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  const checklistCompletedData = (id, val) => {
    if (selectedSubRole) {
      const url = {
        url:
          BASE_URL +
          `dashboard/ChecklistFiveDays?company_id=${id}&Checklist=${val}&Role=["${selectedSubRole}"]&frequency=${
            frequency ? frequency : ""
          }`,
      };
      dispatch(checklistCompletedApi(url));
    } else {
      const url = {
        url:
          BASE_URL +
          `dashboard/ChecklistFiveDays?company_id=${id}&Checklist=${val}&Role=&frequency=${
            frequency ? frequency : ""
          }`,
      };
      dispatch(checklistCompletedApi(url));
    }
  };
  useEffect(() => {
    checklistCompletedData(companyId, value);
  }, [selectedRole, frequency]);

  const handleChangeSelect = (val) => {
    setValue(val.target.value);
    checklistCompletedData(companyId, val.target.value);
  };
  const handleChangeSelect1 = (val) => {
    setValue1(val.target.value);
  };

  function diffPercent(a, b) {
    if (a == 0 && b == 0) {
      return 0;
    } else if (b == 0) {
      return 100;
    } else if (a == 0) {
      return 100;
    } else {
      return Math.abs(((b - a) * 100) / a && ((a - b) * 100) / b);
    }
  }

  return (
    <Grid container spacing={1} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          sx={{
            minWidth: "100%",
            px: 4,
            borderRadius: "8px",
            overflow: "auto",
          }}
          className="card graph-card zero-padding"
        >
          <div align="right" style={{ marginTop: "28px" }}>
            <Tooltip
              title={
                <div style={{ maxWidth: "300px" }}>
                  <strong>Checklist Completion:</strong>
                  <p>
                    This module gives the overview of all the checklist that has
                    been created and completed for last five days The range of
                    dates can be chosen by the user between 48 hours, weekly,
                    and monthly for both the graphs.
                  </p>
                </div>
              }
              arrow
              interactive
            >
              <InfoIcon
                style={{
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: "#D76C00",
                }}
              />
            </Tooltip>
          </div>
          <CardContent className="graph-card-content">
            {/* <div
              style={{
                height: 'inherit',
                width: 'inherit',
                position: "relative"
              }}
            >
              <LoaderComponent play />
            </div> */}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Stack direction="row" spacing={10} className="box-container">
                <select
                  className="dash-select select2"
                  onChange={handleChangeSelect1}
                >
                  <option value="Checklist">Checklist Completion</option>
                  <option value="Issue">Issue Arised</option>
                </select>
                <select
                  className="dash-select select2"
                  onChange={handleChangeSelect}
                >
                  <option value="days">Last 48h</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </Stack>
              {Loader ? (
                <div class="spinner-border" role="status">
                  <CircularProgress
                    color="inherit"
                    size="2rem"
                    style={{ color: "#f26539" }}
                  />
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <Stack direction="row" spacing={1} className="box-container1">
                  {value1 === "Checklist" ? (
                    <Chart
                      options={optionOne}
                      series={chartDataOne}
                      type="bar"
                      width="200"
                      height="170"
                    />
                  ) : (
                    <Chart
                      options={optionOne}
                      series={chartDataTwo}
                      type="bar"
                      width="200"
                      height="170"
                    />
                  )}
                  <Typography
                    variant="p"
                    component="div"
                    className="graph-font minus-margin"
                  >
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{
                        color:
                          value1 == "Checklist"
                            ? checklistValues[0]?.completed >
                              checklistValues[1]?.completed
                              ? "#039855"
                              : "#BB251A"
                            : issueRaisedValue[0]?.Created >
                              issueRaisedValue[1]?.Created
                            ? "#039855"
                            : "#BB251A",
                      }}
                      className="percent-rate"
                    >
                      {value1 == "Checklist"
                        ? checklistValues
                          ? `${Math.round(
                              parseFloat(
                                diffPercent(
                                  checklistValues[0]?.completed,
                                  checklistValues[1]?.completed
                                )
                              )
                            )}%`
                          : "0"
                        : issueRaisedValue
                        ? `${Math.round(
                            parseFloat(
                              diffPercent(
                                issueRaisedValue[0]?.Created,
                                issueRaisedValue[1]?.Created
                              )
                            )
                          )}%`
                        : "0"}
                    </Typography>
                    {/* {value1 == "Checklist"
                      ? checklistValues[0]?.Submitted >
                        checklistValues[1]?.Submitted
                        ? "increased rate"
                        : "decreased rate"
                      : issueRaisedValue[0].Created >
                        issueRaisedValue[1].Created
                      ? "increased rate"
                      : "decreased rate"} */}

                    {value1 === "Checklist"
                      ? checklistValues[0]?.completed >
                        checklistValues[1]?.completed
                        ? "increased rate"
                        : "decreased rate"
                      : issueRaisedValue &&
                        issueRaisedValue[0]?.Created >
                          issueRaisedValue[1]?.Created
                      ? "increased rate"
                      : "decreased rate"}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <div class="vl"></div>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <CardContent
                sx={{
                  height: "100%",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  className="box-container2"
                  sx={{ justifyContent: "center", height: "100%" }}
                >
                  {value1 === "Checklist" ? (
                    !chartTwoDataOne.length ? (
                      <div
                        style={{
                          height: "inherit",
                          width: "inherit",
                          position: "relative",
                        }}
                      >
                        <LoaderComponent play />
                      </div>
                    ) : (
                      <Chart
                        options={optionTwo}
                        series={chartTwoDataOne}
                        type="area"
                        height={230}
                        style={{ width: "100%" }}
                        className="chart"
                      />
                    )
                  ) : !chartTwoDataTwo.length ? (
                    <div
                      style={{
                        height: "inherit",
                        width: "inherit",
                        position: "relative",
                      }}
                    >
                      <LoaderComponent play />
                    </div>
                  ) : (
                    <Chart
                      options={optionTwo}
                      series={chartTwoDataTwo}
                      type="area"
                      height={230}
                      style={{ width: "100%" }}
                      className="chart"
                    />
                  )}
                </Stack>
              </CardContent>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* <Grid item xs={12} sm={12} md={8} lg={8}>
        <Card
          sx={{ px: 5, height: "250px" }}
          className="card graph-card zero-padding"
        >
          <CardContent>
            <Stack
              direction="row"
              spacing={1}
              className="box-container2"
              sx={{ justifyContent: "center" }}
            >
              {value1 === "Checklist" ? (
                <Chart
                  options={optionTwo}
                  series={chartTwoDataOne}
                  type="bar"
                  height={230}
                  style={{ width: "100%" }}
                />
              ) : (
                <Chart
                  options={optionTwo}
                  series={chartTwoDataTwo}
                  type="bar"
                  height={230}
                  style={{ width: "100%" }}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
  );
};

export default GraphCards;
