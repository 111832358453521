/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  TextField,
  Grid,
  Container,
  Button,
  Stack,
  Select,
  MenuItem,
  Fab,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import Blank from "../../../images/Blank.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditChapter = () => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });
  const newState = "<p>tgbhghththth</p><p>ujkiuyhjiuy7</p>";
  const navigate = useNavigate();
  const location = useLocation();
  const editData = location?.state?.items;
  const pathEdit = location?.state?.path;
  const allData = location?.state?.row;
  const [image, setImage] = useState(editData?.content);
  const [textValue, setTextValue] = useState("");
  const [preview, setPreview] = useState();
  const [contentState, setContentState] = useState();
  const [contentText, setContentText] = useState();
  const [textContentValue, setTextContentValue] = useState("");
  const reactQuillRef = React.useRef();

  useEffect(() => {
    if (editData?.type === 1 && editData?.content) {
      const newContent = JSON.parse(editData?.content);
      setTextContentValue(newContent);
      if (editData?.type === 1 && editData?.content) {
        const newContent = JSON.parse(editData?.content);
        if (newContent?.blocks) {
          const firstBlockText = newContent?.blocks[0]?.text;
          setTextContentValue(firstBlockText);
          const html = firstBlockText;
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setContentState(editorState);
          }
        }
      } else {
        let olduiblock = newContent?.ops;
        if (olduiblock?.length > 0) {
          let oldcontent = newContent?.ops[0]?.insert;
          const html = oldcontent;
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setContentState(editorState);
          }
        }
      }
    }
  }, []);

  const extractTextFromHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // const formik = useFormik({
  //   initialValues: {
  //     name: editData && editData.name,
  //     type: editData && editData.type,
  //     summary: editData && editData.summary,
  //     content: editData && editData.content,
  //     transcript: editData && editData.transcript,
  //   } || {
  //     name: "",
  //     type: "",
  //     summary: "",
  //   },

  //   onSubmit: (values) => {
  //     if (values.type === 1) {
  //       const jasontext = JSON.stringify(textContentValue);
  //       values.content = jasontext;
  //       newFunction(values);
  //     }

  //     if (values.type !== 1) {
  //       if (image !== "") {
  //         values.content = image;
  //       }
  //       newFunction(values);
  //     }
  //   },
  // });

  const formik = useFormik({
    initialValues: {
      name: editData && editData.name,
      type: editData && editData.type,
      summary: editData && editData.summary,
      content: editData && editData.content,
      transcript: editData && editData.transcript,
    } || {
      name: "",
      type: "",
      summary: "",
    },

    onSubmit: (values) => {
      if (values.type === 1) {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getLength() === 1) {
          toast.error("Please enter content and try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          const jasontext = JSON.stringify(textContentValue);
          values.content = jasontext;
          newFunction(values);
        }
      } else if (!image) {
        toast.error("Please attach a file and try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        values.content = image;
        newFunction(values);
      }
    },
  });

  const newFunction = (values) => {
    if (values.content !== "") {
      if (pathEdit === "newEditchapter") {
        if (values) {
          values.id = editData && editData.id;
          const method =
            allData &&
            allData &&
            allData?.map((val, i) => {
              if (val.type === values.type && values.id === val.id) {
                return (values = values);
              }
              return val;
            });
          allData.steps = method;
        }
        navigate("/training/add-training", {
          state: { steps: allData, trainingPath: "alltrainingreport" },
        });
      }
      if (pathEdit === "editchapter") {
        if (values) {
          values._id = editData && editData._id;
          const method =
            allData &&
            allData?.steps &&
            allData?.steps?.map((val, i) => {
              if (val._id === values._id) {
                return (values = values);
              }
              return val;
            });
          allData.steps = method;
        }
        navigate("/training/edit-training", {
          state: { editeddata: allData, training: "alltrainingreport" },
        });
      }
    }
  };
  const onEditorChange = (event) => {
    const plainText = event.getCurrentContent().getPlainText();
    const rteContent = convertToRaw(event.getCurrentContent());
    rteContent && setTextValue(JSON.stringify(rteContent));
  };
  // const addImageUpload = (e) => {
  //   setImage(e.target.files[0]);
  //   if (preview !== "") {
  //     const formData = new FormData();
  //     formData.append("File", {
  //       name: editData?.content?.name,
  //       type: image?.content?.type,
  //       size: image?.content?.size,
  //     });
  //   }
  // };

  const addImageUpload = (e) => {
    setContentState("");
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const maxSize = selectedFile.size;
      const fileName = selectedFile.name;

      switch (formik.values.type) {
        case 1: // Text
          // No restrictions for text content
          setImage(selectedFile);
          break;
        case 2: // Video
          if (!isVideoTypeAllowed(fileName)) {
            toast.error(
              "Please upload only MP4, FLV, 3GP, MKV, MOV, and WMV files.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else if (maxSize > 156 * 1024 * 1024) {
            // Allow video files up to 156 MB
            toast.error("We allow only up to 156MB video files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setImage(selectedFile);
          }
          break;

        case 3: // Audio
          if (!isAudioTypeAllowed(fileType)) {
            toast.error("Please upload only MP3 and recording files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 5 * 1024 * 1024) {
            toast.error("We allow only up to 5MB audio files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setImage(selectedFile);
          }
          break;
        case 4: // Image
          if (!isImageTypeAllowed(fileType)) {
            toast.error("Please attach only JPEG, JPG, and PNG files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 5 * 1024 * 1024) {
            toast.error("We allow only up to 5MB image files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setImage(selectedFile);
          }
          break;
        case 5: // Doc
          if (!isDocumentTypeAllowed(fileType)) {
            toast.error("Please upload only Doc, Excel, PPT, and PDF files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 25 * 1024 * 1024) {
            toast.error("We allow only up to 25MB document files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (/^[\'"$]/.test(fileName)) {
            toast.error(
              "File names starting with special characters are not allowed.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else {
            setImage(selectedFile);
          }
          break;
        default:
          toast.error("Unsupported content type.", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    }
  };

  const isVideoTypeAllowed = (fileName) => {
    const allowedVideoExtensions = [
      ".mp4",
      ".flv",
      ".3gp",
      ".mkv",
      ".mov",
      ".wmv",
    ];
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    return allowedVideoExtensions.includes("." + fileExtension.toLowerCase());
  };

  const isAudioTypeAllowed = (fileType) => {
    return [
      "audio/mpeg",
      "audio/mp3",
      "audio/wav",
      "audio/ogg",
      "audio/aac",
    ].includes(fileType);
  };

  const isImageTypeAllowed = (fileType) => {
    return ["image/jpeg", "image/jpg", "image/png"].includes(fileType);
  };

  const isDocumentTypeAllowed = (fileType) => {
    return [
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(fileType);
  };

  const handleCancle = () => {
    if (pathEdit === "editchapter") {
      navigate("/training/edit-training", {
        state: {
          editeddata: allData,
          canclee: "cancle",
          training: "alltrainingreport",
        },
      });
    } else {
      navigate("/training/add-training", {
        state: { steps: allData, trainingPath: "alltrainingreport" },
      });
    }
  };

  const handleDeleteImage = (img) => {
    setPreview();
    if (image !== "") {
      setImage("");
    }
  };

  const handleEditor = (content, delta, source, editor) => {
    setTextContentValue(content);
  };

  return (
    <Box>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Box className="header-card">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  onClick={handleCancle}
                  className="cancle-button cancle-hover"
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
                <Button className="header-add cancle-hover" type="submit">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Update
                </Button>
                {/* </Link> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>

        {/* Add Chapter Form */}
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            mt: 1,
            p: 2,
            borderRadius: "8px",
            pl: 3,
            height: "76vh",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
              minHeight: "350px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={8} sm={12} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="date-input"
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Content Type</label>
                  <Select
                    disabled
                    size="small"
                    name="type"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem value={3}>Audio</MenuItem>
                    <MenuItem value={5}>Doc</MenuItem>
                    <MenuItem value={4}>Image</MenuItem>
                    <MenuItem value={1}>Text</MenuItem>
                    <MenuItem value={2}>Video</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Chapter Summary</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="summary"
                onChange={formik.handleChange}
                value={formik.values.summary}
                className="date-input"
              />
            </FormControl>
            {formik.values.type === 5 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name ? image?.name : image}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <Box className="content-training-box">
                    <input
                      accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      className="label-upload"
                      htmlFor="contained-button-file"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon />
                      </Fab>
                      Browse Doc file
                    </label>
                  </Box>
                )}
              </FormControl>
            ) : formik.values.type === 3 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name ? image?.name : image}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <Box className="content-training-box">
                    <input
                      accept="audio/*"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      className="label-upload"
                      htmlFor="contained-button-file"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon />
                      </Fab>
                      Browse Audio file
                    </label>
                  </Box>
                )}
              </FormControl>
            ) : formik.values.type === 4 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name ? image?.name : image}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <Box className="content-training-box">
                    <input
                      accept=".jpg, .jpeg, .png, image"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      className="label-upload"
                      htmlFor="contained-button-file"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon />
                      </Fab>
                      Browse Image file
                    </label>
                  </Box>
                )}
              </FormControl>
            ) : formik.values.type === 2 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name ? image?.name : image}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <><Box className="content-training-box">
                    {/* <input
                      accept="video/*"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    /> */}
                    <input
                      accept=".mp4, .flv, .3gp, .mkv, video/*"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      className="label-upload"
                      htmlFor="contained-button-file"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon />
                      </Fab>
                      Browse Video file
                    </label>
                  </Box>
                    <label style={{ display: 'flex', justifyContent: 'center' }}>More than 25 MB videos cannot be uploaded</label>
                  </>
                )}
              </FormControl>
            ) : (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Content</label>
                <ReactQuill
                  height={200}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={textContentValue}
                  onChange={handleEditor}
                  ref={reactQuillRef}
                />
              </FormControl>
            )}
          </Container>
        </Box>
      </form>
    </Box>
  );
};

export default EditChapter;
