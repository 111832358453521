import { Container } from "@mui/system";
import React from "react";
import { ToastContainer } from "react-toastify";
import CheckoutForm from "./CheckoutForm";
import "react-toastify/dist/ReactToastify.css";

function Payment() {
  return (
    <>
      <ToastContainer autoClose={3000} />

      <Container
        maxWidth="xl"
        sx={{
          bgcolor: "#F6F8FC",
          p: 2,
          height: "100%",
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          height: "min-content",
          // justifyContent: "center",
        }}
      >
        <CheckoutForm />
      </Container>
    </>
  );
}

export default Payment;
