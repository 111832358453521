/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import userImage from "../../../images/User Image.png";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Reset from "../../../../assets/icons/svg-icons/Reset.svg";
import SearchAppBar from "./SearchButton";
import * as moment from "moment";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import {
  ResetListApi,
  TrainingDetailsApi,
} from "../../../redux/actions/LmsAction";
import User from "../../../../assets/icons/svg-icons/User.svg";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import View from "../../../images/View.svg";
import ViewHover from "../../../images/ViewHover.svg";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Progressbar from "../../../GlobalProgress/Progressbar";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { List, ListItem, ListItemText, makeStyles } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import ImageViewer from "react-simple-image-viewer";
import { IconButton } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  height: "80%",
  overflow: "scroll",
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === "light" ? "#039855" : "#308fe8",
  },
}));
const BorderLinearProgressNext = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === "light" ? "#F39A4C" : "#308fe8",
  },
}));

const IndividualTraining = () => {
  const allTrainingProgress = useSelector(
    (state) =>
      state.lms &&
      state.lms.detailsTrainee &&
      state.lms.detailsTrainee.data &&
      state.lms.detailsTrainee.data.data
  );
  const Loader = useSelector((state) => state.lms && state.lms.loading);
  const resetLoading = useSelector(
    (state) => state.lms && state.lms.resetLoading
  );
  const updateReset = useSelector(
    (state) => state.lms && state.lms.resetData && state.lms.resetData.data
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [detailsId, setDetailsId] = useState("");
  const [ProgressData, setProgressData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [modalopen, setModalOpen] = React.useState(false);
  const [permission, setPermission] = useState({});

  const [isImages, setIsImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [resetLoader, setResetLoader] = useState(true);

  const openImageViewer = (key, url) => {
    let imageUrl = [];
    imageUrl.push(url);
    setIsImages(imageUrl);
    setCurrentImage(key);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  const handleModalOpen = (items) => {
    setModalOpen(true);
    setModalData(items?.questions);
  };
  const handleModalClose = () => setModalOpen(false);
  const [hover, setHover] = useState({
    View: false,
  });

  useEffect(() => {
    // setLoader(true);
    setProgressData(allTrainingProgress);
    // setTimeout(() => {
    //   setLoader(false);
    // }, 2000);
  }, [allTrainingProgress]);

  useEffect(() => {
    setLoader(Loader);
    setResetLoader(resetLoading);
  }, [Loader, resetLoading]);

  useEffect(() => {
    if (updateReset && updateReset.status === 200) {
      toast.success(updateReset.message, { autoClose: 500 });
      setTimeout(() => {
        individualList();
      }, 1000);
    }
  }, [updateReset]);

  useEffect(() => {
    individualList();
  }, []);

  const individualList = () => {
    const id = localStorage.getItem("companyId");
    const userId = location && location?.state && location?.state?._id;
    const paginationRequest = {
      url: BASE_URL + `progress/${userId}?company_id=${id}`,
    };
    dispatch(TrainingDetailsApi(paginationRequest));
  };

  const handleOpen = (id, val) => {
    setDetailsId(id);
    setOpen(val);
  };

  const handleActive = (items, chapter) => {
    const payload = {
      body: {
        progressId: items._id,
        stepId: chapter.id,
        trainingId: items.trainingId,
      },
    };
    const id = localStorage.getItem("companyId");
    payload.url = BASE_URL + `trainingAssignment?company_id=${id}`;
    dispatch(ResetListApi(payload));
  };

  const handleResetAll = (items) => {
    const payload = {
      body: {
        progressId: items._id,
        stepId: 0,
        trainingId: items.trainingId,
        resetButton: "training",
      },
    };
    const id = localStorage.getItem("companyId");
    payload.url = BASE_URL + `trainingAssignment?company_id=${id}`;
    dispatch(ResetListApi(payload));
  };

  const handleResetAssessment = (items) => {
    const payload = {
      body: {
        progressId: items._id,
        stepId: 0,
        trainingId: items.trainingId,
        resetButton: "assessment",
      },
    };
    const id = localStorage.getItem("companyId");
    payload.url = BASE_URL + `trainingAssignment?company_id=${id}`;
    dispatch(ResetListApi(payload));
  };

  return (
    <Box>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        {/* <ToastContainer /> */}
        <Grid container spacing={1}>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: "0 auto" }}>
              Training : <b>{location?.state?.name}</b>
            </p>
          </Grid>
          <Grid item xs={10}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              {/* <div style={{ marginRight: 5 }}>
                <SearchAppBar />
              </div> */}

              {/* <Link
                to="/training/edit-training"
                className="submenu-item"
              >
                <Button
                  variant="contained"
                  color="warning"
                  className="cancle-hover lms-edit-btn"
                >
                  <BorderColorIcon sx={{ mr: 2, margin: "3px" }} /> Edit
                </Button>
              </Link> */}
              {permission?.lms?.manageLms === true ? (
                <Link to="/training/add-training" className="submenu-item">
                  <Button
                    className="header-add cancle-hover"
                    style={{ marginRight: 5 }}
                  >
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Assign Training
                  </Button>
                </Link>
              ) : (
                ""
              )}
              <div style={{marginTop:"8px",marginRight:"10px",marginLeft:"-4px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {/* {Loader ? (
        <Progressbar /> */}
      {loader || resetLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        ""
      )}
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          // height: "80vh",
          height: "calc(100vh - 220px)",
          mt: 1,
          p: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        {ProgressData ? (
          ProgressData &&
          ProgressData.length > 0 &&
          ProgressData?.map((items, i) => {
            return (
              <Card className="user-information" sx={{ mb: 2 }} key={i}>
                <Grid container spacing={0} sx={{ mt: 4 }}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <span>{i + 1}</span>
                    <Avatar
                      sx={{
                        width: "150px",
                        height: "150px",
                        backgroundColor: "#F6F8FC",
                        border: "2px solid #CBD4E1",
                        margin: "auto",
                      }}
                    >
                      <img
                        src={User}
                        alt="userimage"
                        align="center"
                        width="150px"
                      />
                    </Avatar>
                    {/* <img src={userImage} alt="userImage" /> */}
                  </Grid>
                  <Grid item md={5} lg={5} xs={12} sm={12}>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow className="table-group">
                          <TableCell className="list-value">
                            User Name:
                          </TableCell>

                          <TableCell className="list-key-value">
                            {items?.user?.name}
                          </TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow>
                          <TableCell className="list-value">Store:</TableCell>
                          <TableCell className="list-key-value">
                            {items?.store?.title}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">Role:</TableCell>
                          <TableCell className="list-key-value">
                            {items?.role}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Started On:
                          </TableCell>
                          <TableCell className="list-key-value">
                            {moment(items?.createdAt).format("lll")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Updated On:
                          </TableCell>
                          <TableCell className="list-key-value">
                            {moment(items?.updatedAt).format("lll")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className="progress-bar"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          textAlign: "start",
                          mb: 2,
                          ml: 2,
                          width: "100%",
                        }}
                      >
                        <Typography variant="p" component="b">
                          Training:{" "}
                        </Typography>
                        <Typography variant="p" component="span">
                          {items?.completedPercentage.toFixed(1).concat("%")}{" "}
                          out of 100
                          {/* {items &&
                            items?.steps &&
                            items?.steps?.length > 0 &&
                            items?.steps
                              ?.map((chapter, i) => {
                                return chapter;
                              })
                              .filter((chapter) => chapter.finished == true)
                              .length} */}
                          % Completed
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={items?.completedPercentage}
                        />
                      </Box>
                      <Box>
                        {permission?.lms?.lmsProgress === true ? (
                          <Tooltip
                            placement="left"
                            title="Reset chapter, assessent and certificate submission"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  "& .MuiTooltip-arrow": {
                                    color: "white",
                                  },
                                },
                              },
                            }}
                          >
                            <img
                              src={Reset}
                              onClick={() => handleResetAll(items)}
                              alt="Reset"
                              className="refresh-icon cancle-hover "
                            />
                          </Tooltip>
                        ) : (
                          <b>Access Denied</b>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          textAlign: "start",
                          my: 2,
                          ml: 2,
                          width: "100%",
                        }}
                      >
                        <Typography variant="p" component="b">
                          Assessment:{" "}
                        </Typography>
                        <Typography variant="p" component="span">
                          {items?.assessmentProgress.toFixed(1)} out of 100%
                          Completed
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={items?.assessmentProgress ? 100 : ""}
                        />
                      </Box>
                      <Box>
                        {permission?.lms?.lmsProgress === true ? (
                          <Tooltip
                            placement="left"
                            title="Reset Assessment & Certificate Submission"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  "& .MuiTooltip-arrow": {
                                    color: "white",
                                  },
                                },
                              },
                            }}
                          >
                            <img
                              src={Reset}
                              onClick={() => handleResetAssessment(items)}
                              alt="Reset"
                              className="refresh-icon cancle-hover"
                            />
                          </Tooltip>
                        ) : (
                          <b>Access Denied</b>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          textAlign: "start",
                          ml: 2,
                          my: 2,
                          width: "100%",
                        }}
                      >
                        <Typography variant="p" component="b">
                          Certificate:{" "}
                        </Typography>
                        <Typography variant="p" component="span">
                          {items.certificateAwarded === true
                            ? "certificate awarded"
                            : "No certificate awarded yet"}
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={items?.certificate?.length ? 100 : ""}
                        />
                      </Box>
                      <Box>
                        {permission?.lms?.lmsProgress === true ? (
                          <div
                            // src={Reset}
                            // onClick={() => handleResetCertificate(items)}
                            // alt="Reset"
                            className="refresh-black-area"
                          />
                        ) : (
                          <b>Access Denied</b>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {open && items._id === detailsId ? (
                  <Button
                    variant="outlined"
                    className="detailed-btn"
                    onClick={() => handleOpen(items._id, false)}
                  >
                    <KeyboardArrowUpIcon />
                    Hide Details
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    className="detailed-btn"
                    onClick={() => handleOpen(items._id, true)}
                  >
                    <KeyboardArrowDownIcon />
                    Show Details
                  </Button>
                )}
                {open && items._id === detailsId ? (
                  <Card
                    className="user-information"
                    sx={{
                      my: 2,
                      pb: 2,
                      bgcolor: "#F6F8FC",
                      textAlign: "left",
                      height: "50vh",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography variant="p" component="h3" paddingLeft={1}>
                          Chapter Progress
                        </Typography>
                        <Box
                          sx={{
                            overflowX: "hidden",
                            overflowY: "auto",
                            borderRadius: "5px",
                            "&::-webkit-scrollbar": {
                              width: 15,
                            },
                            "&::-webkit-scrollbar-track": {
                              padding: "12px 5px",
                              backgroundColor: "#CBD4E1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#64748B",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          {items &&
                            items?.steps &&
                            items?.steps?.length > 0 &&
                            items?.steps?.map((chapter, i) => {
                              return (
                                <Card
                                  key={i}
                                  sx={{
                                    color: "#64748b",
                                    m: "16px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    sx={{
                                      justifyContent: "space-between",
                                      p: 1,
                                    }}
                                  >
                                    <Stack direction="column">
                                      <Typography variant="p" component="b">
                                        {chapter?.name}
                                      </Typography>
                                      <Typography
                                        variant="p"
                                        component="span"
                                        sx={{ mt: 1 }}
                                      >
                                        Status:{" "}
                                        <Typography variant="p" component="b">
                                          {chapter?.finished === true
                                            ? "true"
                                            : "false"}
                                        </Typography>
                                      </Typography>
                                      <Typography
                                        variant="p"
                                        component="span"
                                        sx={{ mt: 1 }}
                                      >
                                        Submitted on:{" "}
                                        <Typography variant="p" component="b">
                                          {moment(chapter?.submitTime).format(
                                            "lll"
                                          )}
                                        </Typography>
                                      </Typography>
                                    </Stack>
                                    {chapter?.finished == true ? (
                                      <CheckCircleOutlineOutlinedIcon
                                        // onClick={() =>
                                        //   handleActive(items, chapter)
                                        // }
                                        color="success"
                                        className="cursor"
                                      />
                                    ) : (
                                      <Box className="Iconblink">
                                        <HelpOutlineOutlinedIcon
                                          // onClick={() =>
                                          //   handleActive(items, chapter)
                                          // }
                                          color="error"
                                        />
                                      </Box>
                                    )}

                                    {/* <img
                              //  onClick={()=>handleActive(items)}
                              src={Reset}
                              alt="Reset"
                              className="refresh-icon cancle-hover"
                            /> */}
                                  </Stack>
                                </Card>
                              );
                            })}
                          {/* <Card
                      sx={{
                        mt: 1,
                        color: "#64748b",
                        mr: "16px",
                        borderRadius: "8px",
                      }}
                    >
                      <Stack
                        direction="row"
                        sx={{ justifyContent: "space-between", p: 1 }}
                      >
                        <Stack direction="column">
                          <Typography variant="p" component="b">
                            1. Training Name{" "}
                          </Typography>
                          <Typography variant="p" component="span">
                            Status:{" "}
                            <Typography variant="p" component="b">
                              {" "}
                              Completed
                            </Typography>
                          </Typography>
                        </Stack>
                        <img
                          src={Reset}
                          alt="Reset"
                          className="refresh-icon cancle-hover"
                        />
                      </Stack>
                    </Card>
                    <Card
                      sx={{
                        mt: 1,
                        color: "#64748b",
                        mr: "16px",
                        borderRadius: "8px",
                      }}
                    >
                      <Stack
                        direction="row"
                        sx={{ justifyContent: "space-between", p: 1 }}
                      >
                        <Stack direction="column">
                          <Typography variant="p" component="b">
                            1. Training Name{" "}
                          </Typography>
                          <Typography variant="p" component="span">
                            Status:{" "}
                            <Typography variant="p" component="b">
                              {" "}
                              Completed
                            </Typography>
                          </Typography>
                        </Stack>
                        <img
                          src={Reset}
                          alt="Reset"
                          className="refresh-icon cancle-hover"
                        />
                      </Stack>
                    </Card> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Typography variant="p" component="h3">
                          Assessment Progress
                        </Typography>
                        {items &&
                        items?.assessment &&
                        items?.assessment?.length > 0 ? (
                          items &&
                          items?.assessment &&
                          items?.assessment?.length > 0 &&
                          items?.assessment?.map((assessment, i) => {
                            return (
                              <Card
                                sx={{
                                  mt: 1,
                                  textAlign: "left",
                                  p: 1,
                                  color: "#64748b",
                                  borderRadius: "8px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                key={i}
                              >
                                <Stack
                                  direction="column"
                                  sx={{ width: "fitcontent" }}
                                >
                                  <Typography variant="p" component="b">
                                    {assessment.title}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    component="span"
                                    sx={{ my: 1 }}
                                  >
                                    Submitted on:{" "}
                                    <Typography variant="p" component="b">
                                      {moment(assessment.submitTime).format(
                                        "lll"
                                      )}
                                    </Typography>
                                  </Typography>
                                  <Stack direction="row">
                                    <Typography variant="p" component="span">
                                      Score:{" "}
                                      <Typography variant="span" component="b">
                                        {assessment.score.toFixed(2)} % &nbsp;
                                      </Typography>
                                    </Typography>
                                    <Typography variant="p" component="div">
                                      &nbsp;Status:{" "}
                                      <Typography
                                        variant="span"
                                        component="b"
                                        sx={{ display: "inline-flex" }}
                                      >
                                        {assessment.pass === true ? (
                                          <>
                                            passed &nbsp;
                                            <CheckIcon color="success" />
                                          </>
                                        ) : (
                                          <>
                                            failed&nbsp;{" "}
                                            <CloseIcon color="error" />
                                          </>
                                        )}
                                      </Typography>
                                    </Typography>
                                  </Stack>
                                </Stack>
                                <img
                                  onClick={() => handleModalOpen(assessment)}
                                  src={
                                    hover.View && indexHover === i
                                      ? ViewHover
                                      : View
                                  }
                                  alt="VIEW"
                                  style={{
                                    height: "40px",
                                    cursor: "pointer",
                                  }}
                                  onMouseOver={() => {
                                    setHover({ View: true });
                                    setIndexHover(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ View: false });
                                  }}
                                />
                              </Card>
                            );
                          })
                        ) : (
                          <p>There is no assessment to show.</p>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Typography variant="p" component="h3">
                          Certificate
                        </Typography>
                        {items.certificateAwarded === true ? (
                          <>
                            {items &&
                              items?.certificate &&
                              items?.certificate?.length > 0 &&
                              items?.certificate?.map((image, i) => {
                                return (
                                  <div key={i}>
                                    <img
                                      src={image?.image?.url}
                                      alt="Certificate Awarded"
                                      style={{
                                        marginTop: "8px",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          <p>No certificate awarded yet.</p>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                ) : (
                  ""
                )}
              </Card>
            );
          })
        ) : (
          <p>Training not submitted</p>
        )}
      </Box>
      <Modal
        open={modalopen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="individual-modal"
          style={{ border: "3px solid #ef6b3b" }}
        >
          <div
            style={{
              backgroundColor: "#ef6b3b",
              color: "#FFFFFF",
              padding: 1,
              borderRadius: "40px",
              textAlign: "center",
            }}
          >
            User Response
          </div>
          {modalData?.map((items, i) => {
            console.log(modalData);
            return (
              <Box key={i} paddingTop={1}>
                <Typography
                  id="modal-modal-description"
                  component=""
                  fontSize={14}
                  variant="div"
                >
                  {i + 1}.Question:- {items?.title}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  fontSize={10}
                  sx={{ mb: 0, display: "", alignItems: "" }}
                >
                  answer:-{" "}
                  {items?.type?.key === "number" ? (
                    // ? items?.answer
                    parseInt(items?.userAnswer) === parseInt(items?.answer) ? (
                      <span style={{ pt: 0 }}>
                        <span>{items?.userAnswer}</span>
                        <div>
                          <Check style={{ color: "green", fontSize: 15 }} />
                        </div>
                      </span>
                    ) : (
                      <span style={{}}>
                        <span>{items?.userAnswer}</span>
                        <div>
                          <Close style={{ color: "red", fontSize: 15 }} />
                        </div>
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  {items?.type?.key === "multi_select" ? (
                    <>
                      <div style={{ maxHeight: 150, overflowY: "auto" }}>
                        <ul>
                          {items?.userAnswer?.map((newitem, i) => {
                            return (
                              <li
                                key={i}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CircleIcon
                                  style={{
                                    fontSize: "7px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                {newitem?.title}
                              </li>
                            );
                          })}
                          {items.answer.every((item) =>
                            items.userAnswer.some(
                              (ans) => ans.title === item.title
                            )
                          ) ? (
                            <Check style={{ color: "green", fontSize: 15 }} />
                          ) : (
                            <Close style={{ color: "red", fontSize: 15 }} />
                          )}
                        </ul>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* {items?.type?.key === "boolean"
                    ? items?.userAnswer?.title
                    : ""} */}
                  {/* {items?.type?.key === "boolean"
                    ? items?.userAnswer?.title
                    : ""} */}
                  {items?.type?.key === "boolean" ? (
                    items?.userAnswer?.title === items?.answer?.title ? (
                      <span style={{ pt: 0 }}>
                        <span style={{ pb: 0 }}>
                          {items?.userAnswer?.title}
                        </span>
                        <div>
                          <Check style={{ color: "green", fontSize: 15 }} />
                        </div>
                      </span>
                    ) : (
                      <span>
                        <span>{items?.userAnswer?.title}</span>
                        <div>
                          <Close style={{ color: "red", fontSize: 15 }} />
                        </div>
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  {/* {items?.type?.key === "text" ||
                    items?.type?.key === "single_select"
                    ? items?.userAnswer?.title
                      ? items?.userAnswer?.title
                      : items?.userAnswer
                    : ""} */}
                  {items?.type?.key === "text" ? (
                    // ? items?.userAnswer
                    items?.userAnswer === items?.answer ? (
                      <span style={{ pt: 0 }}>
                        <span style={{ pb: 0 }}>{items?.userAnswer}</span>
                        <div>
                          <Check style={{ color: "green", fontSize: 15 }} />
                        </div>
                      </span>
                    ) : (
                      <span>
                        {items?.userAnswer}
                        <div>
                          <Close style={{ color: "red", fontSize: 15 }} />
                        </div>
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  {items?.type?.key === "date" ? items?.userAnswer : ""}
                  {items?.type?.key === "datetime" ? items?.userAnswer : ""}
                  {items?.type?.key === "single_select" ? (
                    // ? items?.userAnswer?.title
                    items?.userAnswer?.title === items?.answer?.title ? (
                      <span style={{ pt: 0 }}>
                        <span style={{ pb: 0 }}>
                          {items?.userAnswer?.title}
                        </span>
                        <div>
                          <Check style={{ color: "green", fontSize: 15 }} />
                        </div>
                      </span>
                    ) : (
                      <span>
                        <span>{items?.userAnswer?.title}</span>
                        <div>
                          <Close style={{ color: "red", fontSize: 15 }} />
                        </div>
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  {/* {items?.type?.key === "text" ||
                  items?.type?.key === "date" ||
                  items?.type?.key === "datetime" ||
                  items?.type?.key === "single_select"
                    ? items?.userAnswer?.title
                      ? items?.userAnswer?.title
                      : items?.userAnswer
                    : ""} */}
                  {/* {items?.type?.key === "file" ? (
                    <div>
                      {items?.userAnswer && items?.userAnswer.length
                        ? items?.userAnswer.map((imageObj, key) => {
                            return (
                              <img key={key} src={imageObj?.url} width={200} />
                            );
                          })
                        : "No Image"}
                    </div>
                  ) : (
                    ""
                  )} */}
                  {items?.type?.key === "file" && (
                    <div>
                      {items?.userAnswer && items?.userAnswer.length
                        ? items.userAnswer.map((fileObj, key) => {
                            const {
                              contentType,
                              fileType,
                              id,
                              key: fileKey,
                              name,
                              url,
                            } = fileObj;

                            let content = null;

                            if (contentType?.includes("image")) {
                              content = (
                                <img
                                  key={key}
                                  src={url}
                                  width={200}
                                  onClick={() => openImageViewer(key, url)}
                                  alt={`Image ${key + 1}`}
                                />
                              );
                            } else if (contentType?.includes("audio")) {
                              content = (
                                <audio key={key} controls>
                                  <source src={url} type={contentType} />
                                  Your browser does not support the audio tag.
                                </audio>
                              );
                            } else if (contentType?.includes("pdf")) {
                              content = (
                                <a
                                  key={key}
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Open PDF
                                </a>
                              );
                            } else if (contentType?.includes("word")) {
                              content = (
                                <a
                                  key={key}
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Open Document
                                </a>
                              );
                            } else if (contentType?.includes("video")) {
                              content = (
                                <video key={key} width={200} controls>
                                  <source src={url} type={contentType} />
                                  Your browser does not support the video tag.
                                </video>
                              );
                            }

                            return (
                              <div key={key}>
                                <p>{`${key + 1}. ${fileType} - ${name}`}</p>
                                {content}
                              </div>
                            );
                          })
                        : "No Content"}
                    </div>
                  )}
                  {items?.userAnswer === null ? "N/A" : ""}
                  {/* {items?.type?.key === "file" && (
                    <div>
                      {items?.userAnswer && items?.userAnswer.length
                        ? items.userAnswer.map((imageObj, key) => (
                            <img
                              key={key}
                              src={imageObj?.url}
                              width={200}
                              onClick={() => openImageViewer(key, imageObj.url)}
                            />
                          ))
                        : "No Image"}
                    </div>
                  )}
                  {items?.userAnswer === null ? "N/A" : ""} */}
                </Typography>

                <hr
                  style={{
                    borderTop: "1.3px dashed",
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Modal>
      {isViewerOpen && (
        <ImageViewer
          src={isImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.2)",
          }}
        />
      )}
    </Box>
  );
};

export default IndividualTraining;
