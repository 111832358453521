/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
  Divider,
  LinearProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Blank from "../../../images/Blank.png";
import DeleteHover from "../../../images/DeleteHover.svg";
import Delete from "../../../images/Delete.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  AddCertificateImageApi,
  AddCertificateApi,
  AddCertificateSuccess,
  AddCertificateImageReset,
} from "../../../redux/actions/LmsAction";
import { toast, ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const NewCertificate = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [imageKey, setImageKey] = useState();
  const [preview, setPreview] = useState();
  const [hover, setHover] = useState({
    Delete: false,
  });

  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );

  const addCertificate = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.addCertificate &&
      state.lms.addCertificate.data
  );

  const getImageKey = useSelector(
    (state) =>
      state.lms &&
      state.lms.addImage &&
      state.lms.addImage.length &&
      state.lms.addImage?.[0]?.imageData?.[0].key
  );

  const getError = useSelector((state) => state.lms && state.lms.errorField);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    dispatch(AddCertificateImageReset());
  }, []);

  useEffect(() => {
    if (getImageKey && getImageKey?.[0]?.fileKey) {
      addImage(formik.values, getImageKey?.[0]?.fileKey);
    }
  }, [getImageKey]);

  useEffect(() => {
    if (addCertificate) {
      dispatch(AddCertificateSuccess(""));
      setTimeout(() => {
        navigate("/certificate");
      }, 500);
    }
  }, [addCertificate]);

  // const handleImageUpload = (e) => {
  //   const selectedImage = e.target.files[0];
  //   setImage(selectedImage);

  //   const formData = new FormData();
  //   formData.append("File", {
  //     name: selectedImage.name,
  //     type: selectedImage.type,
  //     size: selectedImage.size,
  //   });

  //   const imagefile = {
  //     files: selectedImage,
  //   };

  //   const imageData = {
  //     body: imagefile,
  //   };

  //   imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
  //   dispatch(AddCertificateImageApi(imageData));

  //   // Update imageKey state
  //   setImageKey(null); // Reset imageKey
  // };

  // const handleImageUpload = (e) => {
  //   const selectedImage = e.target.files[0];
  //   setImage(selectedImage);
  // };

  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      if (selectedImage.size <= 5242880) {
        const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (allowedImageTypes.includes(selectedImage.type)) {
          setImage(selectedImage);
        } else {
          toast.error("Only allow image files (JPEG, PNG, GIF ).");
        }
      } else {
        toast.error("Only files up to 5MB are allowed.");
      }
    }
  };

  useEffect(() => {
    if (image) {
      // console.log(image, "selected image");

      const formData = new FormData();
      formData.append("File", {
        name: image?.name,
        type: image?.type,
        size: image?.size,
      });

      const imagefile = {
        files: image,
      };

      const imageData = {
        body: imagefile,
      };

      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      dispatch(AddCertificateImageApi(imageData));
      // console.log(imageData, "imageData");
    }
  }, [image]);

  // ...

  useEffect(() => {
    setImageKey(getImageKey);
  }, [getImageKey]);

  // useEffect(() => {
  //   if (image) {
  //     const formData = new FormData();
  //     formData.append("File", {
  //       name: image.name,
  //       type: image.type,
  //       size: image.size,
  //     });

  //     const imagefile = {
  //       files: image,
  //     };

  //     const imageData = {
  //       body: imagefile,
  //     };

  //     imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
  //     dispatch(AddCertificateImageApi(imageData));
  //   }
  // }, [image]);

  const removeImage = () => {
    setPreview(null);
    if (image !== "") {
      setImage(null);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      summary: "",
    },
    onSubmit: async (values) => {
      try {
        let imgKey = imageKey;
        await addImage(values, imgKey);
        // if (addCertificate && addCertificate.status === 200) {
        //   toast.success(addCertificate.message, { autoClose: 3000 });
        //   setTimeout(() => {
        //     navigate("/certificate");
        //   }, 2000);
        // } else if (addCertificate && addCertificate.status !== 200) {
        //   if (addCertificate.message === "Certificate with title already exists") {
        //     toast.error(addCertificate.message, { autoClose: 3000 });
        //   } else {
        //     toast.error("Failed to create certificate", { autoClose: 3000 });
        //   }
        // } else {
        //   toast.error("Failed to create certificate", { autoClose: 3000 });
        // }
      } catch (error) {
        toast.error("Failed to create certificate. Please try again.", {
          autoClose: 3000,
        });
      }
    },
  });

  const addImage = (values, image) => {
    try {
      const formData = {
        title: values.title,
        summary: values.summary,
      };
      console.log("image || imageKey", image || imageKey);
      if (image || imageKey) {
        formData.key = image || imageKey;
      } else {
        formData.key = "";
      }
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `certificate?company_id=${id}`;
        dispatch(AddCertificateApi(allData));
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (image) {
      setPreview(URL.createObjectURL(image));
    }
  }, [image]);

  useEffect(() => {
    setImageKey(getImageKey);
    // console.log(imageKey, "imageKey");
  }, [handleImageUpload, dispatch]);

  return (
    <Box>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/certificate" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" className="header-add cancle-hover">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Create
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        {Loader && <ProgressbarTransparent play />}

        <Box
          sx={{
            bgcolor: "#F6F8FC",
            // height: "80vh",
            height: "calc(100vh - 220px)",
            mt: 1,
            p: 2,
            overflowX: "hidden",
            overflowY: "scroll",
            borderRadius: "8px",
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          <Grid container spacing={1} sx={{ justifyContent: "space-between" }}>
            <Grid item md={5.8} sm={12} xs={12} lg={5.8}>
              <Box
                maxWidth="sm"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  p: 3,
                  textAlign: "left",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Certificate Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    className="input-fields"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Certificate Summary</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={8}
                    name="summary"
                    onChange={formik.handleChange}
                    value={formik.values.summary}
                    className="input-fields"
                  />
                </FormControl>
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid
              item
              md={5.8}
              sm={12}
              xs={12}
              lg={5.8}
              sx={{ alignItems: "center", textAlign: "left" }}
              className="apply"
            >
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <Typography variant="p" component="b">
                    Certificate Image
                  </Typography>
                  <Box>
                    <input
                      name="image"
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      hidden
                      value={""}
                      onChange={handleImageUpload}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="add-button"
                    >
                      <AddOutlinedIcon className="icon" />
                      Add New Certificate
                    </label>
                  </Box>
                </Grid>
              </Grid>
              <Box className="image-box">
                <Stack
                  direction="row"
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  {preview ? (
                    <img
                      className="image-dimension"
                      src={preview}
                      alt="attachment"
                    />
                  ) : (
                    <img
                      className="image-preview"
                      src={Blank}
                      alt="attachment"
                    />
                  )}
                  <img
                    onClick={removeImage}
                    src={hover.Delete ? DeleteHover : Delete}
                    alt="Delete"
                    className="delete-image"
                    onMouseOver={() => {
                      setHover({ Delete: true });
                    }}
                    onMouseLeave={() => {
                      setHover({ Delete: false });
                    }}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default NewCertificate;
