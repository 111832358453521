/** @format */

import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ProductDetail from "./productTab/Index";
import CategoryDetail from "./categoryTab/Index";
import UomDetail from "./uomTab/Index";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Products = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box className="tab-box">
      <div style={{float:"right",marginTop:"24px",marginRight:"16px"}}>
               <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                  </div>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "#F6F8FC",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={<h4>Product</h4>} {...a11yProps(0)} />
            <Tab label={<h4>Category</h4>} {...a11yProps(1)} />
            <Tab label={<h4>UOM</h4>} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ProductDetail title="productlist" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CategoryDetail title="categorylist" />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UomDetail title="uomlist" />
        </TabPanel>
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default Products;
