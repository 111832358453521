/** @format */

import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import axios from "axios";
import { FormControl, Select, MenuItem, Box, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuditorContext } from "../../context/auditorContext";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import { Stack } from "@mui/system";

const UpcomingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 24 24"
  >
    <path
      fill="#3498db"
      d="M15.68 7.68l2.83 2.83L12 15.17l-5.51-5.51 2.83-2.83L12 9.5l3.68-3.68zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

const RemainingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 24 24"
  >
    <path
      fill="#2ecc71"
      d="M15.68 7.68l2.83 2.83L12 15.17l-5.51-5.51 2.83-2.83L12 9.5l3.68-3.68zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

const CompletedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 24 24"
  >
    <path
      fill="#4caf50"
      d="M15.68 7.68l2.83 2.83L12 15.17l-5.51-5.51 2.83-2.83L12 9.5l3.68-3.68zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

const MissedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 24 24"
  >
    <path
      fill="#e74c3c"
      d="M15.68 7.68l2.83 2.83L12 15.17l-5.51-5.51 2.83-2.83L12 9.5l3.68-3.68zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

const AuditorChecklistHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [storeList, setStoreList] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countLoader, setCountLoader] = useState(false);
  const id = localStorage.getItem("companyIdselect");
  const {
    setcompanyId,
    setstoreId,
    storeId,
    companyId,
    setstoreTitle,
    storeTitle,
  } = useContext(AuditorContext);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    setcompanyId(id);
  }, []);

  const handleNavigation = (name) => {
    localStorage.setItem("scoreList", JSON.stringify(scoreList));
    let checklistSection;
    switch (name) {
      case "Upcoming":
        checklistSection = "upcoming";
        break;
      case "Remaining":
        checklistSection = "remaining";
        break;
      case "Completed":
        checklistSection = "completed";
        break;
      case "Missed":
        checklistSection = "missed";
        break;
      case "Queue":
        checklistSection = "queue";
        break;
      default:
        break;
    }

    if (checklistSection) {
      let indexValue = 0;
      switch (checklistSection) {
        case "upcoming":
          localStorage.setItem("index", 0);
          indexValue = 0;
          break;
        case "remaining":
          localStorage.setItem("index", 1);
          indexValue = 1;
          break;
        case "completed":
          localStorage.setItem("index", 2);
          indexValue = 2;
          break;
        case "missed":
          localStorage.setItem("index", 3);
          indexValue = 3;
          break;
        default:
          indexValue = 0;
          break;
      }
      localStorage.setItem("index", indexValue);
      navigate(`/checklist-home/checklist-types`);
    }
  };

  useEffect(() => {
    setLoader(true);
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}profile/me?company_id=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setStoreList(res.data.data.store);
        const selectedStore = localStorage.getItem("auditorSelectedStore");
        if (selectedStore) {
          const parseselectedStore = JSON.parse(selectedStore);
          setstoreId(parseselectedStore._id);
          setstoreTitle(parseselectedStore.title);
        } else {
          setstoreId(res.data.data.store[0]._id);
          setstoreTitle(res.data.data.store[0].title);
          localStorage.setItem(
            "auditorSelectedStore",
            JSON.stringify(res.data.data.store[0])
          );
        }
        setLoader(false);
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  const storeChangeApi = () => {
    setLoader(true);
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const selectedStore = JSON.parse(
      localStorage.getItem("auditorSelectedStore")
    );

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${BASE_URL}formsAssignment/indexnew?timezone=india&company_id=${companyId}`,
          {
            storeId: {
              _id: selectedStore._id,
              title: selectedStore.title,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setScoreList(res?.data?.data);
        setLoader(false);
      } catch (error) {
        console.error(error);
        setCountLoader(false);
      }
    };

    fetchData();
  };

  useEffect(() => {
    if (storeId && storeList.length) {
      storeChangeApi();
    }
  }, [storeId, storeList]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedStore = storeList.find((elem) => elem._id === selectedValue);
    if (selectedStore) {
      localStorage.removeItem("scoreList");
      localStorage.setItem(
        "auditorSelectedStore",
        JSON.stringify(selectedStore)
      );
      setstoreId(selectedValue);
      setstoreTitle(selectedStore.title);
    }
  };

  const statusCounts = useMemo(() => {
    const initialCounts = {
      pending: 0,
      upcoming: 0,
      completed: 0,
      missed: 0,
    };

    scoreList.forEach((item) => {
      item.category.forEach((categoryItem) => {
        if (categoryItem.status) {
          initialCounts[categoryItem.status] += 1;
        }
      });
    });

    return initialCounts;
  }, [scoreList]);

  const checklistIcons = {
    Upcoming: <UpcomingIcon />,
    Remaining: <RemainingIcon />,
    Completed: <CompletedIcon />,
    Missed: <MissedIcon />,
  };

  return (
    <Fragment>
      {countLoader || (loader && <ProgressbarTransparent play />)}
      <InputLabel id="store-label" style={{ paddingTop: 1 }}>
        Select Store
      </InputLabel>
      <FormControl
        style={{
          margin: "1rem",
          minWidth: 170,
          borderRadius: "8px",
          height: "42px",
        }}
      >
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={storeId || ""}
          onChange={handleChange}
        >
          {storeList.map((store) => (
            <MenuItem key={store._id} value={store._id}>
              {store.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          height: "42px",
          borderRadius: "8px",
        }}
      >
        {["Upcoming", "Remaining", "Completed", "Missed"].map((elem, index) => {
          const boxColors = {
            Upcoming: "rgb(34, 91, 110)",
            Remaining: "rgb(34, 91, 110)",
            Completed: "rgb(34, 91, 110)",
            Missed: "rgb(34, 91, 110)",
          };

          const IconComponent = checklistIcons[elem];

          return (
            <div
              key={index}
              className="card-body"
              onClick={() => handleNavigation(elem)}
              style={{
                backgroundColor: boxColors[elem],
                width: "20%",
                height: "260px",
                justifyContent: "center",
                borderRadius: "20px",
                alignItems: "center",
                marginTop: "5%",
                cursor: "pointer",
              }}
            >
              <div
                width="80"
                className="mx-auto mb-2"
                style={{ marginTop: "25px" }}
              >
                {IconComponent}
              </div>
              <h5
                className="card-title mb-0"
                style={{ color: "white", textAlign: "center" }}
              >
                {elem}
                {elem === "Upcoming" && (
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    : {statusCounts?.upcoming}
                  </span>
                )}
                {elem === "Remaining" && (
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    : {statusCounts?.pending}
                  </span>
                )}
                {elem === "Completed" && (
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    : {statusCounts?.completed}
                  </span>
                )}
                {elem === "Missed" && (
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    : {statusCounts?.missed}
                  </span>
                )}
              </h5>
            </div>
          );
        })}
      </Stack>
    </Fragment>
  );
};

export default AuditorChecklistHome;
