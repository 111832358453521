import React from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Divider } from "@mui/material";
import { useFormik } from "formik";

const AddStoreTraining = () => {
  const navigate = useNavigate();

  const handleChapter = () => {
    navigate("/store-training/add-store-training/add-chapter");
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      summary: "",
      role: "",
      category: "",
      subCategory: "",
      author: "",
    },
    onSubmit: (values) => {},
  });

  return (
    <Box>
      <Box className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/store-training" className="submenu-item">
                <Button className="cancle-button cancle-hover">
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
              </Link>
              <Link to="/store-training" className="submenu-item">
                <Button className="header-add cancle-hover">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Create
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 2,
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: "8px",
          "&::-webkit-scrollbar": {
            width: 15,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Box
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 3,
                textAlign: "left",
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    className="date-input"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Summary</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="summary"
                    onChange={formik.handleChange}
                    value={formik.values.summary}
                    className="date-input"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Role</label>
                  <Select
                    size="small"
                    name="role"
                    onChange={formik.handleChange}
                    value={formik.values.role}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Category</label>
                  <Select
                    size="small"
                    name="category"
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Sub-Category</label>
                  <Select
                    size="small"
                    name="subCategory"
                    onChange={formik.handleChange}
                    value={formik.values.subCategory}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Training Period</label>
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={6} xs={6} lg={6}>
                      <Select
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        sx={{ borderRadius: "8px" }}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={6} sm={6} xs={6} lg={6}>
                      <Select
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="title"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        sx={{ borderRadius: "8px" }}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Author</label>
                  <Select
                    size="small"
                    name="author"
                    onChange={formik.handleChange}
                    value={formik.values.author}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid
            item
            md={5}
            sx={{ alignItems: "center", textAlign: "left", ml: 8 }}
            className="apply"
          >
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Typography variant="p" component="b">
                  Assessment
                </Typography>
                <Button
                  variant="outlined"
                  className="add-button cancle-hover"
                  sx={{ width: "90%", mb: 4, mt: 1 }}
                >
                  <AddIcon sx={{ mx: 1 }} />
                  Add Assessment
                </Button>
              </Grid>
              <Grid item md={6}>
                <Typography variant="p" component="b">
                  Certificate
                </Typography>
                <Button
                  variant="outlined"
                  className="add-button cancle-hover"
                  sx={{ width: "90%", mb: 4, mt: 1 }}
                >
                  <AddIcon sx={{ mx: 1 }} />
                  Add Certificate
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Typography variant="p" component="b">
                  Chapter
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleChapter}
                  className="add-button cancle-hover"
                  sx={{ width: "95%", mt: 1 }}
                >
                  <AddIcon sx={{ mx: 1 }} />
                  Add New Chapter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddStoreTraining;
