/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
// import LoginApiCAll from "../apiConfig/ApiConfig";
import {
  LOGGEDIN_USER_ERROR,
  LOGGEDIN_USER_REQUEST,
  LOGGEDIN_USER_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PRIVATE_ROUTE_REQUEST,
  PRIVATE_ROUTE_REQUEST_ERROR,
  PRIVATE_ROUTE_REQUEST_SUCCESS,
  PRIVATE_USER_REQUEST,
  PRIVATE_USER_REQUEST_ERROR,
  PRIVATE_USER_REQUEST_SUCCESS,
} from "../types/Types";
import { toast } from "react-toastify";

export const LoginRequest = (login) => ({
  type: LOGIN_REQUEST,
  payload: login,
});
export const LoginSuccess = (login) => ({
  type: LOGIN_SUCCESS,
  payload: login,
});
export const LoginFailed = (error) => ({
  type: LOGIN_ERROR,
  payload: error,
});

export const LoginApi = (data) => (dispatch) => {
  try {
    dispatch(LoginRequest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const token =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.token;
        toast.success(`${response?.data?.message}`);
        dispatch(LoginSuccess(token));
      } else {
        dispatch(LoginFailed(response?.statusText));
        if (
          response?.response?.data?.message === "Email/Phone Number not found."
        ) {
          toast.error("Email id not found");
        } else {
          toast.error(`${response?.response?.data?.message}`);
        }
      }
    });
  } catch (error) {
    dispatch(LoginFailed(error));
  }
};

export const PrivateRouteRequest = (data) => ({
  type: PRIVATE_ROUTE_REQUEST,
  payload: data,
});
export const PrivateRouteSuccess = (data) => ({
  type: PRIVATE_ROUTE_REQUEST_SUCCESS,
  payload: data,
});
export const PrivateRouteFailed = (error) => ({
  type: PRIVATE_ROUTE_REQUEST_ERROR,
  payload: error,
});

export const PrivateRouteApi = (data) => (dispatch) => {
  try {
    dispatch(PrivateRouteRequest());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        let data = response;
        dispatch(PrivateRouteSuccess(data));
      } else {
        dispatch(PrivateRouteFailed(response?.response?.data?.message));
      }
    });
  } catch (error) {
    dispatch(PrivateRouteFailed(error));
  }
};

export const UserPrivateRequest = (data) => ({
  type: PRIVATE_USER_REQUEST,
  payload: data,
});
export const UserPrivateSuccess = (data) => ({
  type: PRIVATE_USER_REQUEST_SUCCESS,
  payload: data,
});
export const UserPrivateFailed = (error) => ({
  type: PRIVATE_USER_REQUEST_ERROR,
  payload: error,
});

export const UserPrivateApi = (data) => (dispatch) => {
  try {
    dispatch(UserPrivateRequest());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        let data = response;
        dispatch(UserPrivateSuccess(data));
      } else {
        dispatch(UserPrivateFailed(response?.response?.data?.message));
      }
    });
  } catch (error) {
    dispatch(UserPrivateFailed(error));
  }
};

export const LoggedInUserDetailsRequest = (data) => ({
  type: LOGGEDIN_USER_REQUEST,
  payload: data,
});
export const LoggedInUserDetailsSuccess = (data) => ({
  type: LOGGEDIN_USER_SUCCESS,
  payload: data,
});
export const LoggedInUserDetailsFailed = (error) => ({
  type: LOGGEDIN_USER_ERROR,
  payload: error,
});

export const LoggedInUserDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(LoggedInUserDetailsRequest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        let data = response;
        dispatch(LoggedInUserDetailsSuccess(data));
      } else {
        dispatch(LoggedInUserDetailsFailed(response?.response?.data?.message));
      }
    });
  } catch (error) {
    dispatch(LoggedInUserDetailsFailed(error));
  }
};
