/** @format */

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import ViewHover from "../images/ViewHover.svg";
import EditHover from "../images/EditHover.svg";
import { TablePagination, IconButton } from "@mui/material";
import Edit from "../images/Edit.svg";
import View from "../images/View.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./pages/Header";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  AddStoreActionSuccess,
  DeleteStoreApi,
  DeleteStoreSuccess,
  EditStoreActionSuccess,
  ListStoreApi,
} from "../redux/actions/Store";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import PropTypes from "prop-types";
import moment from "moment";
import { useState } from "react";
import ToggleSwitchStore from "./pages/ToggleSwitchStore";
import { ToastContainer, toast } from "react-toastify";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import Tooltip from "@mui/material/Tooltip";
import Delete from "../images/Delete.svg";
import DeleteHover from "../images/DeleteHover.svg";
import { confirmAlert } from "react-confirm-alert";

const StoreList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detailedData, setDetailedData] = useState();
  const location = useLocation();
  const [loader, setLoader] = useState(true);

  const storeList = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.stores
  );
  const storeEnableDisableReducer = useSelector(
    (state) =>
      state.store &&
      state.store.storeEnableDisableReducer &&
      state.store.storeEnableDisableReducer.data &&
      state.store.storeEnableDisableReducer.data.data
  );
  const totalitems = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.paginate
  );

  const storeListDelete = useSelector(
    (state) => state && state?.store && state?.store?.deleteStore
  );

  const Loader = useSelector((state) => state.store && state.store.loading);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [storeData, setStoreData] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [localPageNo, setLocalPageNo] = useState(1);

  const payloadRequest = {
    body: {
      page: page,
      per_page: rowsPerPage,
      sort_order: -1,
      sort_by: "createdAt",
    },
  };

  const [hover, setHover] = useState({
    View: false,
    Edit: false,
  });
  const [permission, setPermission] = useState({});

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    if (storeListDelete) {
      listStore(payloadRequest);
      dispatch(DeleteStoreSuccess(""));
    }
  }, [storeListDelete]);

  useEffect(() => {
    dispatch(AddStoreActionSuccess(""));
    dispatch(EditStoreActionSuccess(""));
  }, []);

  useEffect(() => {
    if (storeEnableDisableReducer && storeEnableDisableReducer._id) {
      const target = [...storeData];
      target.map((item) => {
        if (item._id === storeEnableDisableReducer._id) {
          item.approved = storeEnableDisableReducer.approved;
        }
      });
      setStoreData(target);
    }
  }, [storeEnableDisableReducer]);

  useEffect(() => {
    if (storeList) {
      localStorage.setItem("storeData", JSON.stringify(storeList));
    }
  }, [storeList]);

  useEffect(() => {
    if (searchValue == "") {
      var localPageno = localStorage.getItem("pageno");
      payloadRequest.body.page = localPageno ? parseInt(localPageno) : 1;
      setLoader(true);
      listStore(payloadRequest);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [searchValue]);

  useEffect(() => {
    setStoreData(storeList);
  }, [storeList]);

  const listStore = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `stores/index?company_id=${id}`;
    dispatch(ListStoreApi(data));
  };

  const handleClickEdit = (id) => {
    navigate(`/store/edit-store`, { state: { id: id } });
  };

  const handleClick = (id) => {
    navigate(`/store/store-details`, { state: { id: id } });
  };

  const handleClickDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this store.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const data = {
              url: BASE_URL + `stores/${id}?company_id=${_id}`,
            };
            setLoader(true);
            dispatch(DeleteStoreApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { totalCount, count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoader(true);
      payloadRequest.body.q = searchValue;
      payloadRequest.body.page = 1;
      setPage(1);
      listStore(payloadRequest);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      field: "S.no",
      headerName: "S.no",
      renderHeader: () => <strong>{"S.no"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const index =
          storeData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="store-text"
              style={{ marginLeft: "20px", color: "black", fontSize: "medium" }}
            >
              {index}
            </p>
          </div>
        );
      },
    },

    {
      field: "Store",
      headerName: "Store",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="store-text"
              style={{ marginLeft: "10px", color: "black", fontSize: "medium" }}
            >
              {params?.row?.name}
            </p>
          </div>
        );
      },
    },
    {
      field: "Unique ID",
      headerName: "Unique ID",
      renderHeader: () => <strong>{"Unique ID"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.uniqueId) {
          result.push(params?.row?.uniqueId);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Radius",
      headerName: "Radius",
      renderHeader: () => <strong>{"Radius"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.radius) {
          result.push(params?.row?.radius);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Channel",
      headerName: "Channel",
      renderHeader: () => <strong>{"Channel"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.channel?.title) {
          result.push(params?.row?.channel?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Sub-Channel",
      headerName: "Sub-Channel",
      renderHeader: () => <strong>{"Sub-Channel"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.name) {
          result.push(params?.row?.name);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Region",
      headerName: "Region",
      renderHeader: () => <strong>{"Region"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.region?.title) {
          result.push(params?.row?.region?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },

    {
      field: "Sub-Region",
      headerName: "Sub-Region",
      renderHeader: () => <strong>{"Sub-Region"}</strong>,

      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.sub_region?.title) {
          result.push(params?.row?.sub_region?.title);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Created",
      headerName: "Created",
      align: "center",
      renderHeader: () => <strong>{"Created"}</strong>,

      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.createdAt).format("MMMM D, YYYY");
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Updated",
      headerName: "Updated",
      renderHeader: () => <strong>{"Updated"}</strong>,

      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,

      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {permission?.store?.manageStore === true ? (
              <ToggleSwitchStore
                {...label}
                row={params?.row?._id}
                check={params?.row?.approved}
              />
            ) : (
              <b>Access Denied</b>
            )}
          </>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "2px" }}>
            {
              <Tooltip title="View">
                <img
                  src={
                    hover.View && indexHover === params?.row?._id
                      ? ViewHover
                      : View
                  }
                  alt="VIEW"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(params?.row?._id)}
                  onMouseOver={() => {
                    setHover({ View: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ View: false });
                  }}
                />
              </Tooltip>
            }
            {permission?.store?.manageStore === true ? (
              <Tooltip title="Edit">
                <img
                  onClick={() => handleClickEdit(params?.row?._id)}
                  src={
                    hover.Edit && indexHover === params?.row?._id
                      ? EditHover
                      : Edit
                  }
                  alt="Edit"
                  style={{
                    height: "35px",
                    cursor: "pointer",
                  }}
                  onMouseOver={() => {
                    setHover({ Edit: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Edit: false });
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
            {permission?.store?.manageStore === true ? (
              <Tooltip title="Delete">
                <img
                  onClick={() => handleClickDelete(params?.row?._id)}
                  src={
                    hover.Delete && indexHover === params?.row?._id
                      ? DeleteHover
                      : Delete
                  }
                  alt="Delete"
                  style={{ height: "35px", cursor: "pointer" }}
                  onMouseOver={() => {
                    setHover({ Delete: true });
                    setIndexHover(params?.row?._id);
                  }}
                  onMouseLeave={() => {
                    setHover({ Delete: false });
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  var pageno = localStorage.getItem("pageno");

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    localStorage.setItem("pageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }
    payloadRequest.body.page = newPage + 1;
    payloadRequest.body.q = searchValue;
    listStore(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    payloadRequest.body.page = 1;
    localStorage.removeItem("pageno");
    localStorage.setItem("pageno", 1);
    payloadRequest.body.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.q = searchValue;
    listStore(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <Header
        searchOnChange={handleSearch}
        searchValue={searchValue}
        handleKeyDown={handleKeyDown}
        permission={permission}
      />
      <SingleGlobalTable
        data={storeData}
        columns={columns}
        totalCount={totalitems?.total_item}
        // count={totalitems?.total_item}
        loading={loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            overflowX: "auto",
            overflowY: "scroll",
            borderRadius: "8px",
            px: 1,
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          {storeData && storeData?.length > 0 ? (
            <>
              <DataGrid
                disableColumnMenu
                rows={storeData}
                columns={columns}
                getRowId={(storeData) => Math.random()}
                getRowHeight={() => "auto"}
                hideFooter={true}
                hideFooterPagination={true}
                className="grid-table"
              />
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalitems?.total_item },
                ]}
                count={totalitems?.total_item}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </>
          ) : (
            "No Data Found"
          )}
        </Box>
      )} */}
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </Box>
  );
};

export default StoreList;
