/** @format */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import Chart from "react-apexcharts";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { ChecklistChartApi } from "../../redux/actions/Checklist";
import { useSelector, useDispatch } from "react-redux";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarTransparent, {
  LoaderComponent,
} from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// | Count: " + opts.w.config.series[opts.seriesIndex]
const options = {
  // series: [44, 55, 13, 43, 22],
  options: {
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return  parseFloat(val).toFixed(2) + "%"; 
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
            horizontalAlign: "left",
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      y: {
        formatter: function(val, series) {
          return ":" + val + " || (" + (series.globals.seriesPercent[series.dataPointIndex][0]).toFixed(2) + "%)"
        },
        title: {
          formatter: function (seriesName) {
            return seriesName
          }
        }
      },
    },
  },
};

const ChartSection = ({ valueDropdown, newDate, selectedChecklist }) => {
  const dispatch = useDispatch();

  const activeCheckListData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allactivechecklist &&
      state?.checklist?.allactivechecklist?.data &&
      state?.checklist?.allactivechecklist?.data?.data
  );

  const checklistStoreDatares = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.checklistChart &&
      state.checklist.checklistChart.data &&
      state.checklist.checklistChart.data.data &&
      state.checklist.checklistChart.data.data.Storedata
  );

  const checklistUserDatares = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.checklistChart &&
      state.checklist.checklistChart.data &&
      state.checklist.checklistChart.data.data &&
      state.checklist.checklistChart.data.data.Userdata
  );

  const checklistsDatares = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.checklistChart &&
      state.checklist.checklistChart.data &&
      state.checklist.checklistChart.data.data &&
      state.checklist.checklistChart.data.data.checklistData
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );
  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const [checklistData, setChecklistData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [pieChartValue, setPieChartValue] = useState([]);
  const [pieChart, setPieChart] = useState(options);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRole, setSelectedRole] = useState([]);
  const [loader, setLoader] = useState(true);

  // useEffect(() => {
  //   ChecklistDataReport();
  // }, [])

  useEffect(() => {
    if (selectedSubRole) {
      setSelectedRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  // useEffect(() => {
  //   if (valueDropdown && !newDate) {
  //     ChecklistDataReport();
  //   }
  //   if (valueDropdown && newDate) {
  //     ChecklistDataReport(newDate.firstdate, newDate.seconddate);
  //   }
  // }, [valueDropdown, newDate, selectedChecklist]);

  // useEffect(() => {
  //   setStartDate(newDate.firstdate);
  //   setEndDate(newDate.seconddate);
  // }, [newDate])

  useEffect(() => {
    if (checklistsDatares) {
      // setLoader(true);
      setChecklistData(checklistsDatares);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
    if (checklistStoreDatares) {
      setStoreData(checklistStoreDatares);
    }
    if (checklistUserDatares) {
      setUserData(checklistUserDatares);
    }
  }, [checklistsDatares, checklistStoreDatares, checklistUserDatares]);

  useEffect(() => {
    if (valueDropdown == "checklistData") {
      if (checklistsDatares) {
        let pieData = [];
        let label = [];
        let color = [];
        for (let item of checklistsDatares) {
          pieData.push(item?.submissionCount);
          label.push(item?.title);
          color.push(item?.barColor);
        }
        setPieChartValue(pieData);
        setPieChart((options) => ({
          ...options,
          labels: label,
          colors: color,
        }));
      }
    }
  }, [checklistsDatares]);

  useEffect(() => {
    if (valueDropdown == "Storedata") {
      if (checklistStoreDatares) {
        let pieData = [];
        let label = [];
        let color = [];
        for (let item of checklistStoreDatares) {
          pieData.push(item?.submissionCount);
          label.push(item?.title);
          color.push(item?.barColor);
        }
        setPieChartValue(pieData);
        setPieChart((options) => ({
          ...options,
          labels: label,
          colors: color,
        }));
      }
    }
  }, [checklistStoreDatares]);

  useEffect(() => {
    if (valueDropdown == "Userdata") {
      if (checklistUserDatares) {
        let pieData = [];
        let label = [];
        let color = [];
        checklistUserDatares.sort((a,b) => a.title.localeCompare(b.title))
        for (let item of checklistUserDatares) {
          pieData.push(item?.submissionCount);
          label.push(item?.title);
          color.push(item?.barColor);
        }
        setPieChartValue(pieData);
        setPieChart((options) => ({
          ...options,
          labels: label,
          colors: color,
        }));
      }
    }
  }, [checklistUserDatares]);

  const ChecklistDataReport = (startDate, endDate) => {
    const id = localStorage.getItem("companyId");
    if (!startDate && !endDate) {
      if (selectedSubRole) {
        const data = {
          url:
            BASE_URL +
            `ChecklistChart?company_id=${id}&Role=["${selectedSubRole}"]`,
            body: {
              checklistIds: selectedChecklist
            }
        };
        dispatch(ChecklistChartApi(data));
      } else {
        const data = {
          url: BASE_URL + `ChecklistChart?company_id=${id}&Role=`,
          body: {
            checklistIds: selectedChecklist
          }
        };
        dispatch(ChecklistChartApi(data));
      }
    } else {
      if (selectedSubRole) {
        const data = {
          url:
            BASE_URL +
            `ChecklistChart?company_id=${id}&checklistEndTime=${endDate}&checklistStartTime=${startDate}&Role=["${selectedSubRole}"]`,
          body: {
            checklistIds: selectedChecklist
          }
        };
        dispatch(ChecklistChartApi(data));
      } else {
        const data = {
          url:
            BASE_URL +
            `ChecklistChart?company_id=${id}&checklistEndTime=${endDate}&checklistStartTime=${startDate}&Role=`,
          body: {
            checklistIds: selectedChecklist
          }
        };
        dispatch(ChecklistChartApi(data));
      }
    }
  };

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    // if (!startDate && !endDate) {
    //   if (selectedSubRole) {
    //     const data = {
    //       url:
    //         BASE_URL +
    //         `ChecklistChart?company_id=${id}&Role=["${selectedSubRole}"]`,
    //     };
    //     dispatch(ChecklistChartApi(data));
    //   } else {
    //     const data = {
    //       url: BASE_URL + `ChecklistChart?company_id=${id}&Role=`,
    //     };
    //     dispatch(ChecklistChartApi(data));
    //   }
    // } else {
    //   if (selectedSubRole) {
    //     const data = {
    //       url:
    //         BASE_URL +
    //         `ChecklistChart?company_id=${id}&checklistEndTime=${startDate}&checklistStartTime=${endDate}&Role=["${selectedSubRole}"]`,
    //     };
    //     dispatch(ChecklistChartApi(data));
    //   } else {
    //     const data = {
    //       url:
    //         BASE_URL +
    //         `ChecklistChart?company_id=${id}&checklistEndTime=${startDate}&checklistStartTime=${endDate}&Role=`,
    //     };
    //     dispatch(ChecklistChartApi(data));
    //   }
    // }

    if(activeCheckListData?.length && selectedChecklist.length){
      ChecklistDataReport(newDate?.firstdate, newDate?.seconddate);
    }
  }, [selectedRole, valueDropdown, newDate, selectedChecklist, activeCheckListData]);

  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/* Loading will be start here */}
        <Card className="complete-graph">
          {/* {Loader ? (
            <div>
              <div class="spinner-border" role="status">
                <CircularProgress
                  color="inherit"
                  size="3rem"
                  style={{ color: "#f26539" }}
                />
                <span class="sr-only">Loading...</span>
              </div>
            </div> */}
          {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
          ) : ( */}
          <CardContent style={{ height: "inherit" }}>
          <div style={{position:"absolute",right:"50px",}}>
          <Tooltip
                  title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>User Submission Data:</strong> 
                            <p>Its a Pie chart displaying Submission data as per User/Subrole wise or Store wise or Checklist wise</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            <Typography className="view-all">
              {valueDropdown === "checklistData"
                ? "Checklist Submission Data"
                : valueDropdown === "Storedata"
                ? "Store Submission Data"
                : valueDropdown === "Userdata"
                ? "User Submission Data"
                : null}
            </Typography>
            {!pieChartValue.length ? (
              <div
                style={{
                  height: "inherit",
                  width: "inherit",
                  position: "relative",
                }}
              >
                <LoaderComponent play />
              </div>
            ) : (
              <div style={{ textAlign: "left", width: "750px" }}>
                <Chart
                  options={{...pieChart.options, labels: pieChart?.labels }}
                  series={pieChartValue}
                  type="pie"
                  // width={370}
                  // height={250}
                  width={750}
                  height={400}
                />
              </div>
            )}
          </CardContent>
      {/* )}  */}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ChartSection;
