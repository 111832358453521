import React, { useEffect } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  InputAdornment,
  ToggleButton,
} from "@mui/material";
import { Container } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const EditList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const listDetails = location?.state;
  const [value, setValue] = useState("");
  const [keyValues, setKeyValues] = useState("");
  const [valuesKey, setValuesKey] = useState("");
  const [key, setKey] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [listItem, setListItem] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [customSelect, setCustomSelect] = useState("");
  const [checkedValue, setCheckedValue] = useState(listDetails.isFixed);
  const [iconChange, setIconChange] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [fixedValue, setFixedValue] = useState("");
  const [customId, setCustomId] = useState("");
  const [listValue, setListValue] = useState(listDetails?.items);
  const [allItems, setAllItems] = useState(null);
  const [isTrue, setIsTrue] = useState(null);

  useEffect(() => {
    setAllItems([...listItem, ...listValue]);
  }, []);

  const handleEditForm = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleChecked = () => {
    setCheckedValue(!checkedValue);
  };

  const handleClick = () => {
    setListValue((listValue) => [
      ...listValue,
      {
        id: listValue?.length + 1,
        title: value,
        customFields: [],
        is_fixed: false,
      },
    ]);
    setValue("");
  };

  const handleKeysValues = () => {
    let customValue = {
      key: key,
      value: selectValue,
      id: customSelect.id,
    };
    if (customValue) {
      const newState = listValue?.map((obj, i) => {
        if (obj.id === customSelect.id) {
          return {
            ...obj,
            id: obj.id,
            customFields: [...obj.customFields, customValue],
          };
        }
        return obj;
      });
      // console.log('newState', newState)
      setListItem(newState);
    }
    setKey("");
    setSelectValue("");
    setCustomSelect("");
  };

  const handleCustomValue = (e, index, items) => {
    setValuesKey(e.target.value);
  };

  const handleCustomKey = (e, index, items) => {
    setKeyValues(e.target.value);
  };

  const handleCustomSelect = (value) => {
    const newState = listValue?.map((item) => {
      if (item.id === value.id) {
        setCustomId(item.id);
        return value;
      }
    });
    setCustomSelect(value);
  };

  const handleDeleteCustom = (id) => {
    const newState = listValue.map((custom, i) => {
      let newcustom = custom.customFields.filter((items) => items.id !== id);
      return {
        ...custom,
        customFields: newcustom,
      };
    });
    // console.log("newState", newState)
    setListValue(newState);
  };

  const handleListValueEdit = (e, index, items) => {
    const newState = listItem?.map((obj, i) => {
      if (i === index) {
        let myObj = { ...obj };
        myObj.title = e.target.value;
        return myObj;
      }
      return obj;
    });
    setListValue(newState);
  };

  const handleEditedValues = (item) => {
    const newState = listValue?.map((custom, i) => {
      let newcustom = custom.customFields.map((items, i) => {
        if (items.id === item.id) {
          (items.key = keyValues),
            (items.value = valuesKey),
            setIndexValue(item.id);
          setIconChange(!iconChange);
        }
      });
      // return newcustom;
    });
    return newState;
  };

  const handleEditssss = (index) => {
    if (listValue.filter((item, i) => i === index)) {
      setIndexValue(index);
      setIconChange(!iconChange);
    }
  };

  const handleListValue = (e, index, items) => {
    if (listValue?.filter((item, i) => i === index)) {
      const newUsers = [...listValue];
      newUsers[index].title = e.target.value;
      setListItem(newUsers);
    }
    // return newState; // instead ou can use setSelected(newState)
  };

  const handleEdit = (index) => {
    if (listValue.filter((item, i) => i === index)) {
      setIndexValue(index);
      setIconChange(!iconChange);
    }
  };

  const handleDelete = (index) => {
    setListValue(listValue.filter((item, i) => i !== index));
  };

  const handleListFixed = (event, id, index) => {
    const fixedData = listValue.find((item) => item.id === id);
    if (event.target.checked === true) {
      setFixedValue(index);
      setIsTrue((fixedData["is_fixed"] = true));
    } else {
      setFixedValue(index);
      setIsTrue((fixedData["is_fixed"] = false));
    }
  };

  const handleEditCustom = (item) => {
    const newState = listValue?.map((custom, i) => {
      let newcustom = custom.customFields.map((items, i) => {
        if (items.id === item) {
          setIndexValue(item);
          setIconChange(!iconChange);
        }
      });
      return newcustom;
    });
    return newState;
  };

  const handleUpdate = () => {
    console.log("title", formValue);
    const allData = {
      title: formValue.title ? formValue.title : listDetails.title,
      description: formValue.description
        ? formValue.description
        : listDetails.description,
      items: listValue ? listValue : listDetails.items,
      column: listDetails.column,
      id: listDetails.id,
      index: listDetails.index,
      key: listDetails.key,
      isFixed: listDetails.isFixed,
      isDefault: listDetails.isDefault,
    };
    let localData = localStorage.getItem("dataListOfdata");
    let localParse = JSON.parse(localData);
    if (localParse) {
      const method = localParse?.map((val, i) => {
        if (val?.id == allData?.id) {
          return (localParse[i] = allData);
        }
        return val;
      });
      localStorage.setItem("dataListOfdata", JSON.stringify(method));
      navigate("/data-list");
    }

    // const allData = {
    //     title: listDetails.title,
    //     description: formValue.description ? formValue.description : listDetails.description,
    //     items: listValue ? listValue : listDetails.items,
    //     column: listDetails.column,
    //     id: listDetails.id,
    //     index: listDetails.index,
    //     key: listDetails.key,
    //     isFixed : listDetails.isFixed,
    //     isDefault : listDetails.isDefault
    // }
    // // console.log('allData', allData);
    // if (allData) {
    // }
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "8px", mb: 1 }}
        className="header-card"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/data-list" className="submenu-item">
                <Button className="cancle-button cancle-hover">
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
              </Link>
              {/* <Link to="/data-list" className="submenu-item"> */}
              <Button
                onClick={handleUpdate}
                className="header-add cancle-hover"
              >
                <CheckOutlinedIcon sx={{ mr: 1 }} />
                update
              </Button>
              {/* </Link> */}
              <div style={{ marginTop: "8px", marginRight: "8px" }}>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>Advance Checklist Wise Report:</strong>
                      <p>
                        Status: Display's selected checklist completion status,
                        completion percentage & average compliance scores for
                        the selected user and stores.
                      </p>
                      <p>
                        Percentage: Display's selected checklist completion
                        percentage & average compliance scores for the selected
                        user and stores.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      marginLeft: "2px",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px" }}>
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            p: 1,
            textAlign: "left",
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Title</label>
            <TextField
              name="title"
              onChange={handleEditForm}
              value={formValue.title}
              className="issueinput-fields"
              defaultValue={listDetails.title}
              id="outlined-basic"
              variant="outlined"
              size="small"
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Description</label>
            <TextField
              name="description"
              onChange={handleEditForm}
              className="issueinput-fields"
              value={formValue.value}
              defaultValue={listDetails.description}
              id="outlined-basic"
              variant="outlined"
              size="small"
              multiline
            />
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValue}
                  onChange={handleChecked}
                  size="small"
                />
              }
              label="Make List fixed"
              sx={{ mb: 2 }}
            />
          </FormGroup>

          <FormControl fullWidth sx={{ mb: 2, px: 0 }}>
            <label>List Items</label>
            <TextField
              id="outlined-basic"
              className="issueinput-fields"
              variant="outlined"
              size="small"
              multiline
              name="listItems"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      color="primary"
                      sx={{ margin: "-22px" }}
                    >
                      <Button
                        variant="contained"
                        sx={{ height: "40px", borderRadius: "8px" }}
                        onClick={handleClick}
                      >
                        <AddIcon />
                        Add
                      </Button>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField id="outlined-basic" variant="outlined" size='small' multiline
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" color="primary" sx={{ margin: '-22px' }}>
                                            <Button variant="contained" sx={{ height: '40px' }}><AddIcon />Add</Button>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} /> */}
          </FormControl>

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Custom Fields</label>
                        <Grid container spacing={1} className='display'>
                            <Grid item md={4} sx={12} sm={12} lg={4}>
                                <Select size='small' fullWidth>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item md={4} sx={12} sm={12} lg={4}>
                                <TextField id="outlined-basic" variant="outlined" size='small' placeholder='Key' fullWidth />
                            </Grid>
                            <Grid item md={4} sx={12} sm={12} lg={4}>
                                <TextField id="outlined-basic" variant="outlined" size='small' placeholder='Value' fullWidth />
                            </Grid>
                        </Grid>
                    </FormControl> */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Custom Fields</label>
            <Grid container spacing={1}>
              <Grid item md={4} xs={12} sm={12} lg={4}>
                <Select
                  size="small"
                  sx={{ borderRadius: "8px" }}
                  fullWidth
                  value={customSelect}
                  onChange={(e) => handleCustomSelect(e.target.value)}
                >
                  <MenuItem value="">Select List Item</MenuItem>
                  {listValue &&
                    listValue.length > 0 &&
                    listValue?.map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item?.title}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item md={4} xs={12} sm={12} lg={3}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  className="issueinput-fields"
                  placeholder="Key"
                  fullWidth
                  name="listKey"
                  onChange={(e) => {
                    setKey(e.target.value);
                  }}
                  value={key}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={12} lg={3}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Value"
                  className="issueinput-fields"
                  fullWidth
                  name="listValue"
                  onChange={(e) => {
                    setSelectValue(e.target.value);
                  }}
                  value={selectValue}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={12} lg={1}>
                <Button
                  variant="contained"
                  sx={{ height: "40px", borderRadius: "8px" }}
                  onClick={handleKeysValues}
                >
                  <AddIcon />
                  Add
                </Button>
              </Grid>
            </Grid>
          </FormControl>

          {listValue?.length > 0
            ? listValue?.map((items, index) => {
                return (
                  <>
                    <Card key={index} sx={{ m: 2 }}>
                      <Box className="list-items">
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <Grid container spacing={5} className="display">
                            <Grid item md={8} sx={12} sm={12} lg={9}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                className="user-header issueinput-fields"
                                placeholder="Value"
                                fullWidth
                                name="customList"
                                onChange={(e) =>
                                  handleListValue(e, index, items)
                                }
                                // defaultValue={items.title}
                                disabled={
                                  !iconChange && indexValue === index
                                    ? false
                                    : true
                                }
                                value={items?.title}
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      {!iconChange && indexValue === index ? (
                                        <InputAdornment
                                          position="end"
                                          sx={{ marginRight: "-14px" }}
                                        >
                                          <ToggleButton
                                            value="phone"
                                            aria-label="phone"
                                            size="small"
                                            onClick={() => handleEdit(index)}
                                          >
                                            <CheckIcon color="warning" />
                                          </ToggleButton>

                                          <ToggleButton
                                            value="phone"
                                            aria-label="phone"
                                            size="small"
                                            onClick={() => handleEdit(index)}
                                          >
                                            <ClearIcon color="error" />
                                          </ToggleButton>
                                        </InputAdornment>
                                      ) : (
                                        <InputAdornment
                                          position="end"
                                          sx={{ marginRight: "-14px" }}
                                        >
                                          <ToggleButton
                                            value="phone"
                                            aria-label="phone"
                                            size="small"
                                            onClick={() => handleEdit(index)}
                                          >
                                            <BorderColorOutlinedIcon color="warning" />
                                          </ToggleButton>
                                          {items.is_fixed !== true ? (
                                            <ToggleButton
                                              value="phone"
                                              aria-label="phone"
                                              size="small"
                                              onClick={() =>
                                                handleDelete(index)
                                              }
                                            >
                                              <DeleteOutlinedIcon color="error" />
                                            </ToggleButton>
                                          ) : (
                                            ""
                                          )}
                                        </InputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={4}
                              sx={12}
                              sm={12}
                              lg={3}
                              className="item-center"
                            >
                              <label>Fixed</label>
                              <input
                                value={isTrue}
                                type="checkbox"
                                onChange={(event) =>
                                  handleListFixed(event, items.id, index)
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Box>
                      <Box sx={{ textAlign: "center", p: 1 }}>
                        <Typography>
                          {items?.customFields?.length > 0 ? (
                            <div>
                              {items?.customFields?.length > 0 &&
                                items?.customFields.map((list, i) => {
                                  return (
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          md={6}
                                          sx={12}
                                          sm={12}
                                          lg={6}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            className="issueinput-fields"
                                            placeholder="Key"
                                            fullWidth
                                            name="customkey"
                                            key={list.key}
                                            onChange={(e) =>
                                              handleCustomKey(e, index, list)
                                            }
                                            value={keyValues.value}
                                            defaultValue={list.key}
                                            disabled={
                                              iconChange &&
                                              indexValue === list.id
                                                ? false
                                                : true
                                            }
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  key
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          md={6}
                                          sx={12}
                                          sm={12}
                                          lg={6}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            className="user-header issueinput-fields"
                                            placeholder="Value"
                                            fullWidth
                                            name="customvalue"
                                            key={list.value}
                                            onChange={(e) =>
                                              handleCustomValue(e, index, list)
                                            }
                                            value={valuesKey.value}
                                            defaultValue={list.value}
                                            disabled={
                                              iconChange &&
                                              indexValue === list.id
                                                ? false
                                                : true
                                            }
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  Value
                                                </InputAdornment>
                                              ),
                                              endAdornment: (
                                                <>
                                                  {iconChange &&
                                                  indexValue === list.id ? (
                                                    <InputAdornment
                                                      position="end"
                                                      sx={{
                                                        marginRight: "-14px",
                                                      }}
                                                    >
                                                      <ToggleButton
                                                        value="phone"
                                                        aria-label="phone"
                                                        size="small"
                                                        onClick={() =>
                                                          handleEditedValues(
                                                            list
                                                          )
                                                        }
                                                      >
                                                        <CheckIcon color="warning" />
                                                      </ToggleButton>

                                                      <ToggleButton
                                                        value="phone"
                                                        aria-label="phone"
                                                        size="small"
                                                        onClick={() =>
                                                          handleEdit(index)
                                                        }
                                                      >
                                                        <ClearIcon color="error" />
                                                      </ToggleButton>
                                                    </InputAdornment>
                                                  ) : (
                                                    <InputAdornment
                                                      position="end"
                                                      sx={{
                                                        marginRight: "-14px",
                                                      }}
                                                    >
                                                      <ToggleButton
                                                        value="phone"
                                                        aria-label="phone"
                                                        size="small"
                                                        onClick={() =>
                                                          handleEditCustom(
                                                            list.id
                                                          )
                                                        }
                                                      >
                                                        <BorderColorOutlinedIcon color="warning" />
                                                      </ToggleButton>
                                                      {isTrue !== true ? (
                                                        <ToggleButton
                                                          value="phone"
                                                          aria-label="phone"
                                                          size="small"
                                                          onClick={() =>
                                                            handleDeleteCustom(
                                                              list.id
                                                            )
                                                          }
                                                        >
                                                          <DeleteOutlinedIcon color="error" />
                                                        </ToggleButton>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </InputAdornment>
                                                  )}
                                                </>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  );
                                })}
                            </div>
                          ) : (
                            "No Custom Fields"
                          )}
                        </Typography>
                      </Box>
                      {/* <Divider /> */}
                    </Card>
                  </>
                );
              })
            : "No List Item"}

          {/* {
                            listItem.length > 0 ?
                                listItem.map((items, index) => {
                                    return <Box className='list-items' key={index}>
                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            <Grid container spacing={5} className='display'>
                                                <Grid item md={8} sx={12} sm={12} lg={9}>
                                                    <TextField id="outlined-basic" variant="outlined" size='small' className="user-header" placeholder='Value' fullWidth
                                                        name='customList'
                                                        onChange={(e) => handleListValueEdit(e, index, items)}
                                                        defaultValue={items.title}
                                                        disabled={!iconChange && indexValue === index ? false : true}
                                                        value={listValue.value}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <>
                                                                    {
                                                                        !iconChange && indexValue === index ?
                                                                        <InputAdornment position="end" sx={{ marginRight: '-14px' }}>
                                                                        <ToggleButton value="phone" aria-label="phone" size="small">
                                                                            <CheckIcon
                                                                                onClick={()=>handleEdit(index)} color='warning' />
                                                                        </ToggleButton>

                                                                        <ToggleButton value="phone" aria-label="phone" size="small">
                                                                            <ClearIcon 
                                                                            onClick={()=>handleEdit(index)} color='error' />
                                                                        </ToggleButton>
                                                                    </InputAdornment>
                                                                            :
                                                                            <InputAdornment position="end" sx={{ marginRight: '-14px' }}>
                                                                            <ToggleButton value="phone" aria-label="phone" size="small">
                                                                                <BorderColorOutlinedIcon
                                                                                    onClick={()=>handleEdit(index)} color='warning' />
                                                                            </ToggleButton>
                                                                            {
                                                                                isTrue !== true ?
                                                                                    <ToggleButton value="phone" aria-label="phone" size="small">
                                                                                        <DeleteOutlinedIcon onClick={()=>handleDelete(index)} color='error' />
                                                                                    </ToggleButton>
                                                                                    : ""
                                                                            }
                                                                        </InputAdornment>
                                                                           
                                                                    }
                                                                </>
                                                            ),
                                                        }} />
                                                </Grid>
                                                <Grid item md={4} sx={12} sm={12} lg={3}>
                                                    <label>Fixed</label>
                                                    <input value={isTrue} type="checkbox" onChange={()=>handleListFixed(event, index)} />
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    </Box>
                                }) :
                                "No List Item"
                        } */}

          {/* <Box className='list-items'>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Grid container spacing={5} className='display'>
                                <Grid item md={8} sx={12} sm={12} lg={9}>
                                    <TextField id="outlined-basic" variant="outlined" size='small' className="user-header" placeholder='Value' fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{ marginRight: '-14px' }}>
                                                    <ToggleButton value="phone" aria-label="phone" size="small">
                                                        <BorderColorOutlinedIcon />
                                                    </ToggleButton>

                                                    <ToggleButton value="phone" aria-label="phone" size="small">
                                                        <DeleteOutlinedIcon />
                                                    </ToggleButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item md={4} sx={12} sm={12} lg={3}>
                                    <FormControlLabel control={<Checkbox defaultChecked size="small" />} label="Fixed" sx={{ mt: 1 }} />
                                </Grid>
                            </Grid>
                        </FormControl>
                        <Box>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <Grid container spacing={0} className='display'>
                                    <Grid item md={6} sx={12} sm={12} lg={6}>
                                        <TextField id="outlined-basic" variant="outlined" className="user-header" size='small' placeholder='Key' fullWidth />
                                    </Grid>
                                    <Grid item md={6} sx={12} sm={12} lg={6}>
                                        <TextField id="outlined-basic" variant="outlined" size='small' className="user-header" placeholder='Value' fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" sx={{ marginRight: '-14px' }}>
                                                        <ToggleButton value="phone" aria-label="phone" size="small">
                                                            <BorderColorOutlinedIcon />
                                                        </ToggleButton>

                                                        <ToggleButton value="phone" aria-label="phone" size="small">
                                                            <DeleteOutlinedIcon />
                                                        </ToggleButton>
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                </Grid>


                            </FormControl>
                        </Box>
                    </Box> */}
        </Container>
      </Box>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default EditList;
