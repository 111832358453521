// import React, { useState, useEffect } from "react";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   SearchBox,
//   GeolocationPosition,
// } from "react-google-maps";
// import { Box } from "@mui/material";
// import { useSelector } from "react-redux";

// function Map({ latLng, handleMapClick, handleLoad, handleCenter, position, searchBoxRef }) {
//   // const [latLng, setLatLng] = useState({ lat: 20.5937, lng: 78.9629 });
//   // console.log('enter.... ', latLng);

//   // useEffect(() => {
//   //     navigator.geolocation.getCurrentPosition(
//   //       (position) => {
//   //         console.log('position... ', position.coords)
//   //         setLatLng({
//   //           lat: position.coords.latitude,
//   //           lng: position.coords.longitude,
//   //         });
//   //       },
//   //       (error) => console.error(error),
//   //       { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
//   //     );
//   //   }, []);

//   // function handleMapClick(event) {
//   //     setLatLng({ lat: event.latLng.lat(), lng: event.latLng.lng() });
//   // }

//   // function handleGeolocationSuccess(position) {
//   //     setLatLng({
//   //         lat: position.coords.latitude,
//   //         lng: position.coords.longitude,
//   //     });
//   // }

//   return (
//     <Box>
//       <GoogleMap
//         defaultZoom={19}
//         defaultCenter={{ lat: latLng.lat, lng: latLng.lng }}
//         // defaultCenter={{ lat: -34.397, lng: 150.644 }}
//         onClick={handleMapClick}
//       // onLoad={handleLoad}
//       // onDragEnd={handleCenter}
//       // center={position}
//       // center={{ lat: listData?.gps?.latitude, lng: listData?.gps?.longitude }}
//       >
//         {/* <GeolocationPosition
//                     onClick={handleGeolocationSuccess}
//                 /> */}
//         {latLng.lat && latLng.lng && (
//           <Marker
//             position={{ lat: latLng.lat, lng: latLng.lng }}
//             onClick={handleMapClick}
//             onLoad={handleLoad}
//             onDragEnd={handleCenter}
//             center={position}
//           />
//         )}
//       </GoogleMap>
//     </Box>
//   );
// }

// export default Map;

// const MapWrapped = withScriptjs(withGoogleMap(Map));
// export function MainMap({
//   latLng,
//   handleMapClick,
//   handleLoad,
//   handleCenter,
//   position,
// }) {
//   return (
//     <div
//       style={{
//         width: "30vw",
//         height: "35vh",
//         border: "2px solid #CBD4E1",
//         borderRadius: "8px",
//       }}
//     >
//       <MapWrapped
//         googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA&v=3.exp&libraries=geometry,drawing,places}`}
//         loadingElement={<div style={{ height: `100%` }} />}
//         containerElement={<div style={{ height: `100%` }} />}
//         mapElement={<div style={{ height: `100%` }} />}
//         handleMapClick={handleMapClick}
//         handleLoad={handleLoad}
//         handleCenter={handleCenter}
//         position={position}
//         latLng={latLng}
//       />
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Autocomplete } from "@react-google-maps/api";

function Map({ latLng, handleMapClick, handleLoad, handleCenter, position, onPlaceChanged }) {
  return (
    <Box>
      <GoogleMap
        defaultZoom={19}
        defaultCenter={{ lat: latLng.lat, lng: latLng.lng }}
        onClick={handleMapClick}
      >
        <Autocomplete
          onLoad={handleLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Search for location"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
              top: "10px",
            }}
          />
        </Autocomplete>

        {latLng.lat && latLng.lng && (
          <Marker
            position={{ lat: latLng.lat, lng: latLng.lng }}
            onClick={handleMapClick}
            onLoad={handleLoad}
            onDragEnd={handleCenter}
            center={position}
          />
        )}
      </GoogleMap>
    </Box>
  );
}

export default Map;

const MapWrapped = withScriptjs(withGoogleMap(Map));

export function MainMap({
  latLng,
  handleMapClick,
  handleLoad,
  handleCenter,
  position,
  onPlaceChanged,
}) {
  const [autocomplete, setAutocomplete] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", onPlaceChanged);
    }
  }, [autocomplete, onPlaceChanged]);

  useEffect(() => {
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        () => {
          console.log("Unable to retrieve your location");
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);


  return (
    <div
      style={{
        width: "30vw",
        height: "35vh",
        border: "2px solid #CBD4E1",
        borderRadius: "8px",
      }}
    >
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBffT7GX3BtDg1Y1DbWDuOWZtZlpozVNFA&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        handleMapClick={handleMapClick}
        handleLoad={handleLoad}
        handleCenter={handleCenter}
        position={position}
        latLng={latLng}
        onPlaceChanged={() => {
          const newPlace = autocomplete.getPlace();
          if (newPlace.geometry) {
            handleMapClick({
              lat: newPlace.geometry.location.lat(),
              lng: newPlace.geometry.location.lng(),
            });
          }
        }}
        onLoad={(autocomplete) => setAutocomplete(autocomplete)}
      />
    </div>
  );
}
