import JwtAuthService from "../apiConfig/ApiConfig";
import {
  ALL_PLANS_ERROR,
  ALL_PLANS_REQUEST,
  ALL_PLANS_SUCCESS,
  GET_ACTIVE_ADD_ON_REQUEST,
  GET_ACTIVE_ADD_ON_SUCCESS,
  GET_ACTIVE_ADD_ON_ERROR,
} from "../types/Types";

export const AllPlansRequest = () => ({
  type: ALL_PLANS_REQUEST,
});
export const AllPlansSuccess = (type) => ({
  type: ALL_PLANS_SUCCESS,
  payload: type,
});
export const AllPlansFailed = (error) => ({
  type: ALL_PLANS_ERROR,
  payload: error,
});

export const AllPlansListApi = (data) => (dispatch) => {
  try {
    dispatch(AllPlansRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(AllPlansSuccess(data));
      } else {
        dispatch(AllPlansFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AllPlansFailed(error));
  }
};

export const GetActiveAddOnRequest = () => ({
  type: GET_ACTIVE_ADD_ON_REQUEST,
});
export const GetActiveAddOnSuccess = (type) => ({
  type: GET_ACTIVE_ADD_ON_SUCCESS,
  payload: type,
});
export const GetActiveAddOnFailed = (error) => ({
  type: GET_ACTIVE_ADD_ON_ERROR,
  payload: error,
});

export const GetActiveAddOnListApi = (data) => (dispatch) => {
  try {
    dispatch(GetActiveAddOnRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(GetActiveAddOnSuccess(data));
      } else {
        dispatch(GetActiveAddOnFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(GetActiveAddOnFailed(error));
  }
};
