import React, { useState, useEffect } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link, useNavigate } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import questionType from "../../../checklist/allForm/createFormTabs/QuestionType";
import storemap from "./StoreMapping";
import { v4 as uuidv4 } from "uuid";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddQuestion = () => {
  const optionsAns = [
    { title: "Yes", value: false },
    { title: "No", value: false },
    { title: "Acceptable", value: false },
    { title: "Not Acceptable", value: false },
    { title: "Discarded", value: false },
    { title: "Returned", value: false },
    { title: "Not Applicable", value: true },
  ];
  const arrdefault = [
    { title: "Yes", value: false },
    { title: "No", value: false },
  ];

  const navigate = useNavigate();
  const [groupdata, setDataGroup] = useState([]);
  const [images, setImages] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  const [kpiValue, setKpiValue] = useState(false);
  const [value, setValue] = useState(0);
  const [newValues, setNewValues] = useState("");
  const [storeMap, setStoreMap] = useState({});
  const [val, setVal] = useState("");
  const [textDefault, setTextDefault] = useState("");
  const [require, setRequire] = useState(optionsAns);
  const [defaults, setDefaults] = useState("");
  const [defaultValue, setDefaultValue] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [validatarr, setValidatarr] = useState([]);
  const [dependencyValue, setDependencyValue] = useState(false);
  const [mainList, setMainList] = useState([]);
  const [mainListType, setMainListType] = useState("");
  const [questionDependencys, setQuestionDependencys] = useState("");
  const [dependencyList, setDependencyList] = useState([]);
  const [questionDependencyList, setQuestionDependencyList] = useState([]);
  const [dependencyListSelect, setDependencyListSelect] = useState([]);
  const [ids, setIds] = useState("");

  let LocalData = localStorage.getItem("questionsGroupOfDatamanager");
  let dataList = localStorage.getItem("dataListOfdata");
  let questionList = localStorage.getItem("questionsOfDatamanger");

  useEffect(() => {
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setDataGroup(newQuest);
    }
  }, [LocalData]);

  useEffect(() => {
    if (dataList) {
      let newQuest = JSON.parse(dataList);
      setMainList(newQuest);
    }
  }, [dataList]);

  useEffect(() => {
    const method = mainListType?.items?.map((val, i) => {
      if (val?.title == defaults) {
        let newObj = {
          title: defaults,
          value: val?.title === defaults ? true : false,
        };
        val = newObj;
      }
      return val;
    });
    // setDefaultValue(method)
  }, [mainListType, defaults]);

  useEffect(() => {
    if (dependencyValue === true) {
      // setDefaultValue([]);
    }
  }, [dependencyValue]);

  useEffect(() => {
    if (defaults) {
      const validations = [
        {
          id: 1,
          key: "default",
          title: "Default Value",
          type: "radio",
          answer: [...defaultValue],
        },
        {
          id: 2,
          key: "required",
          title: "Is Required?",
          type: "radio",
          answer: [...require],
        },
      ];
      setValidatarr(validations);
    } else {
      const validations = [
        {
          id: 1,
          key: "default",
          title: "Default Value",
          type: "text",
          answer: textDefault,
        },
        {
          id: 2,
          key: "required",
          title: "Is Required?",
          type: "radio",
          answer: [...require],
        },
      ];
      setValidatarr(validations);
    }
  }, [require, defaults, textDefault]);

  useEffect(() => {
    let localData = localStorage.getItem("questionsOfDatamanger");
    let localParse = JSON.parse(localData);
    let add = localParse;
    setIds(add?.length + 1);
    add.push(newValues);
    if (newValues !== "" && newValues !== null && newValues !== undefined) {
      try {
        if (add?.length > 0) {
          localStorage.setItem(
            "questionsOfDatamanger",
            JSON.stringify(add, getCircularReplacer())
          );
          setTimeout(() => {
            navigate("/questions");
          }, 500);
        }
      } catch (error) {
        console.error("consoleLogs.Utilities.XXX.YYY", error);
        throw error;
      }
    }
  }, [newValues]);

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMapStore = (e) => {
    setStoreMap(e.target.value);
  };

  let multiimageUrl =
    images &&
    images?.map((item) => {
      return item?.url;
    });

  const handleImageUpload = (e) => {
    const tempArr = [];

    [...e.target.files].forEach((file) => {
      tempArr.push({
        data: file,
        url: URL.createObjectURL(file),
      });
    });

    setImages(tempArr);
  };

  const handleRequire = (event) => {
    let events = event.target.value;
    setVal(events);
    for (let item of require) {
      if (events == "yes") {
        require[0].value = true;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "no") {
        require[0].value = false;
        require[1].value = true;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = true;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Not Acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = true;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "discarded") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = true;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "returned") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = true;
        require[6].value = false;
      } else if (events == "not applicable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = true;
      }
    }
    let options = require;
    setRequire(options);
  };

  const handleDefault = (event) => {
    setDefaults(event.target.value);
    let events = event.target.value;
    if (defaultValue) {
      for (let item of defaultValue) {
        if (events == "yes") {
          defaultValue[0].value = true;
          defaultValue[1].value = false;
        } else if (events == "no") {
          defaultValue[0].value = false;
          defaultValue[1].value = true;
        }
      }
    }
    let options = defaultValue;
    setDefaultValue(options);
  };
  const handleTextDefault = (event) => {
    setTextDefault(event.target.value);
    // const options = defaultValue;
    // if (textDefault !== "") {
    //   setDefaultValue(textDefault)
    // } else {
    //   setDefaultValue(options)
    // }
  };

  const handleDependencyChecked = () => {
    setDependencyValue(!dependencyValue);
    setMainListType("");
  };

  const handleMainList = (e) => {
    setMainListType(e.target.value);
  };

  const handleDependency = (e) => {
    const newList = mainList?.filter(
      (item, i) => item.id === e.target.value.id
    );
    let newStateupdate =
      newList &&
      newList.filter((items, i) => {
        setDependencyList(items);
        newFunction(items);
      });
    return newStateupdate;
  };
  const newFunction = (list) => {
    if (questionList) {
      let newQuestList = JSON.parse(questionList);
      const newData = newQuestList?.filter(
        (item, i) => item?.attached_list === list?.id
      );
      setQuestionDependencyList(newData);
    }
  };

  const questionDependency = (e) => {
    setQuestionDependencys(e.target.value);
  };

  const handleListSelect = (e, index) => {
    const newList = dependencyList?.items?.filter((item, i) => {
      if (i === index) {
        item.selected_list = e.target.value ? e.target.value : "";
        item.option = mainList ? mainList : [];
      }
      return { ...item };
    });
    setDependencyListSelect(newList);
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    question_score: yup
      .string()
      .required("Question Score positive number is required.")
      .max(3, "Enter max 3 digits number"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      desc: "",
      question_score: "",
      type: {},
      group: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // const types = {
      //   validations: validatarr,
      //   option_score: {},
      //   id: values.questionType.id,
      //   key: values.questionType.key,
      //   title: values.questionType.title,
      //   title_to_show: values.questionType.title_to_show
      // }
      // types = values.questionType;
      // dependencylistValue = dependencyList
      values.id = ids;
      dependencyList.items = dependencyListSelect;
      values.type.validations = validatarr;
      values.type.option_score = {};
      values.is_store = isTrue;
      values.is_kpi = kpiValue;
      values.mapped_to = storeMap;
      values.attached_list = mainListType?.id;
      values.dependency_list = dependencyList;
      values.dependency_list_question = null;
      values.has_dependency = false;
      values.has_list_dependency = false;
      // values.type = types;
      let localData = localStorage.getItem("questionsOfDatamanger");
      let localParse = JSON.parse(localData);
      let add = localParse;
      const newState = localParse?.filter((obj, i) => {
        if (obj?.title !== values?.title) {
          let myObj = values;
          setNewValues(myObj);
        } else {
          toast.error(
            `List title "${values.title}" already exist. Please change the title.`
          );
        }
        return obj;
      });
      return newState;
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mb: 1 }} className="header-card">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/questions" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                {/* <Link to="/questions" className="submenu-item"> */}
                <Button className="header-add cancle-hover" type="submit">
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
                {/* </Link> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px" }}>
          <Container
            maxWidth="lg"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              p: 1,
              textAlign: "left",
            }}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                className="date-input"
              />
              <small className="error">
                {formik.errors.title && formik.touched.title
                  ? formik.errors.title
                  : null}
              </small>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Description</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="desc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.desc}
                className="date-input"
                multiline
              />
              <small className="error">
                {formik.errors.desc && formik.touched.desc
                  ? formik.errors.desc
                  : null}
              </small>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Question Score</label>
              <TextField
                id="outlined-basic"
                type="number"
                variant="outlined"
                size="small"
                name="question_score"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.question_score}
                className="date-input"
              />
              <small className="error">
                {formik.errors.question_score && formik.touched.question_score
                  ? formik.errors.question_score
                  : null}
              </small>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Question Type</label>
              <Select
                size="small"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                sx={{ borderRadius: "8px" }}
              >
                <MenuItem disabled value="" className="select-item">
                  Type
                </MenuItem>
                {questionType?.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option} className="select-item">
                      {option.title ?? option.id}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formik.values.type.key === "singlechoice" ||
            formik.values.type.key === "multiplechoice" ? (
              <Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Select Relevant List</label>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dependencyValue}
                        onChange={handleDependencyChecked}
                      />
                    }
                    label="Has other list dependency?"
                  />
                </FormControl>
                {dependencyValue === true ? (
                  <Box>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>Dependency List</label>
                      <Select
                        size="small"
                        name="mainlist"
                        value={dependencyList}
                        onChange={handleDependency}
                        MenuProps={{
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          sx: {
                            height: "200px",
                          },
                        }}
                        sx={{ borderRadius: "8px" }}
                      >
                        <MenuItem disabled value="" className="select-item">
                          Type
                        </MenuItem>
                        {mainList?.map((option, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={option}
                              className="select-item"
                            >
                              {option.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {questionDependencyList.length > 0 ? (
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Question For Dependency List</label>
                        <Select
                          size="small"
                          name="dependency_list"
                          value={questionDependencys}
                          onChange={questionDependency}
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            sx: {
                              height: "200px",
                            },
                          }}
                          sx={{ borderRadius: "8px" }}
                        >
                          <MenuItem disabled value="" className="select-item">
                            Select Question
                          </MenuItem>
                          {questionDependencyList?.map((option, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={option.title}
                                className="select-item"
                              >
                                {option.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      "There is no question associated with this list. First, please create a question for this list."
                    )}
                    <Box>
                      {dependencyList &&
                        dependencyList?.items &&
                        dependencyList?.items?.length > 0 &&
                        dependencyList?.items?.map((item, i) => {
                          return (
                            <Grid container spacing={1}>
                              <Grid item xs={2}>
                                <h5>{item?.title} : </h5>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth>
                                  <Select
                                    size="small"
                                    name="mainlist"
                                    fullWidth
                                    value={item.selected_list}
                                    onChange={(e) => handleListSelect(e, i)}
                                    // renderValue={(selected) => {
                                    //   const valueType = typeof selected;
                                    //   return selected && valueType == "string"
                                    //     ? selected
                                    //     : selected.title;
                                    // }}
                                    MenuProps={{
                                      disableScrollLock: true,
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                      },
                                      sx: {
                                        height: "200px",
                                      },
                                    }}
                                    sx={{ borderRadius: "8px" }}
                                  >
                                    <MenuItem
                                      disabled
                                      value=""
                                      className="select-item"
                                    >
                                      Type
                                    </MenuItem>
                                    {mainList?.map((option, i) => {
                                      return (
                                        <MenuItem
                                          key={i}
                                          value={option}
                                          className="select-item"
                                        >
                                          {option.title}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Box>
                  </Box>
                ) : (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Main List</label>
                    <Select
                      size="small"
                      name="mainlist"
                      value={mainListType}
                      onChange={handleMainList}
                      MenuProps={{
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        sx: {
                          height: "200px",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {mainList?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Box>
            ) : (
              ""
            )}
            {formik.values.type.id ? (
              <Box className="tab-box">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    background: "#F6F8FC",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Validation"
                      {...a11yProps(0)}
                      className="tab-item"
                    />
                    {/* <Tab label="Rules" {...a11yProps(1)} className="tab-item" /> */}
                    {/* <Tab
                      label="Weightage Score"
                      {...a11yProps(2)}
                      className="tab-item"
                    /> */}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {/* <FormControl fullWidth>
                    <label>1. Is Required?</label>
                    <RadioGroup
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio size="small" />}
                        label="No"
                      />
                      <FormControlLabel
                        value="Acceptable"
                        control={<Radio size="small" />}
                        label="Acceptable"
                      />
                      <FormControlLabel
                        value="Not Acceptable"
                        control={<Radio size="small" />}
                        label="Not Acceptable"
                      />
                      <FormControlLabel
                        value="Discarded"
                        control={<Radio size="small" />}
                        label="Discarded"
                      />
                      <FormControlLabel
                        value="Returned"
                        control={<Radio size="small" />}
                        label="Returned"
                      />
                      <FormControlLabel
                        value="Not Applicable"
                        control={<Radio size="small" />}
                        label="Not Applicable"
                      />
                    </RadioGroup>
                  </FormControl> */}

                  {formik.values.type.key === "boolean" ? (
                    <FormControl fullWidth>
                      <label>1. Default Value</label>
                      <RadioGroup
                        onChange={handleDefault}
                        value={defaults ? defaults : arrdefault}
                        row
                        name="row-radio-buttons-group"
                        sx={{ p: 0 }}
                        className="radio-btn"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />

                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : formik.values.type.key === "numeric" ? (
                    <FormControl fullWidth>
                      <label>1. Default Value</label>
                      <TextField
                        onChange={handleTextDefault}
                        value={textDefault ? textDefault : ""}
                        fullWidth
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        name="numeric"
                        className="date-input"
                      />
                    </FormControl>
                  ) : formik.values.type.key === "text" ? (
                    <FormControl fullWidth>
                      <label>1. Default Value</label>
                      <TextField
                        fullWidth
                        onChange={handleTextDefault}
                        value={textDefault ? textDefault : ""}
                        id="outlined-basic"
                        type="text"
                        variant="outlined"
                        size="small"
                        name="text"
                        className="date-input"
                      />
                    </FormControl>
                  ) : formik.values.type.key === "singlechoice" ||
                    formik.values.type.key === "multiplechoice" ? (
                    <FormControl fullWidth>
                      <label>1. Default Value</label>
                      <RadioGroup
                        onChange={handleDefault}
                        value={defaults ? defaults : ""}
                        row
                        name="row-radio-buttons-group"
                        sx={{ p: 0 }}
                        className="radio-btn"
                      >
                        {mainListType &&
                          mainListType?.items?.length > 0 &&
                          mainListType?.items?.map((items, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={items.title}
                                control={<Radio size="small" />}
                                label={items.title}
                              />
                            );
                          })}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    "NO Value Added"
                  )}
                  <FormControl fullWidth>
                    <label>2. Is Required?</label>
                    <RadioGroup
                      onChange={handleRequire}
                      value={val ? val : ""}
                      row
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                      <FormControlLabel
                        value="acceptable"
                        control={<Radio size="small" />}
                        label="Acceptable"
                      />
                      <FormControlLabel
                        value="Not Acceptable"
                        control={<Radio size="small" />}
                        label="Not Acceptable"
                      />
                      <FormControlLabel
                        value="discarded"
                        control={<Radio size="small" />}
                        label="Discarded"
                      />
                      <FormControlLabel
                        value="returned"
                        control={<Radio size="small" />}
                        label="Returned"
                      />
                      <FormControlLabel
                        value="not applicable"
                        control={<Radio size="small" />}
                        label="Not Applicable"
                      />
                    </RadioGroup>
                  </FormControl>
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                  <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                    Rules Preset
                  </Box>
                  <Card sx={{ p: 1, mb: 1 }} className="card">
                    <Grid container spacing={1} sx={{ textAlign: "left" }}>
                      <Grid item xs={12} sm={12} md={12} lg={11}>
                        <Stack direction="row" spacing={1} className="list-btn">
                          <Typography>When Answer is</Typography>
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            className="select-btn"
                          >
                            Select Condition
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>
                              My account
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                          </Menu>
                          <Typography>to</Typography>
                          <Button
                            className="select-btn"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Select Answer
                          </Button>
                          <Typography>then allow comment.</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={1}>
                        <Box sx={{ float: "right" }} className="plus-icon">
                          <AddOutlinedIcon
                            sx={{ mr: 1 }}
                            className="add-icon"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                  <Card sx={{ p: 1, mb: 1 }} className="card">
                    <Grid container spacing={1} sx={{ textAlign: "left" }}>
                      <Grid item xs={12} sm={12} md={12} lg={11}>
                        <Stack direction="row" spacing={1} className="list-btn">
                          <Typography>When Answer is</Typography>
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            className="select-btn"
                          >
                            Select Condition
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>
                              My account
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                          </Menu>
                          <Typography>to</Typography>
                          <Button
                            className="select-btn"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Select Answer
                          </Button>
                          <Typography>
                            then create a issue with template
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={1}>
                        <Box sx={{ float: "right" }} className="plus-icon">
                          <AddOutlinedIcon
                            sx={{ mr: 1 }}
                            className="add-icon"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                  <Card sx={{ p: 1 }} className="card">
                    <Grid container spacing={1} sx={{ textAlign: "left" }}>
                      <Grid item xs={12} sm={12} md={12} lg={11}>
                        <Stack direction="row" spacing={1} className="list-btn">
                          <Typography>When Answer is</Typography>
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            className="select-btn"
                          >
                            Select Condition
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>
                              My account
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                          </Menu>
                          <Typography>to</Typography>
                          <Button
                            className="select-btn"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Select Answer
                          </Button>
                          <Typography>then allow image.</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={1}>
                        <Box sx={{ float: "right" }} className="plus-icon">
                          <AddOutlinedIcon
                            sx={{ mr: 1 }}
                            className="add-icon"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </TabPanel> */}
                {/* <TabPanel value={value} index={2}>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>Yes</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>No</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>Acceptable</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>Not Acceptable</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>Discarded</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>Returned</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="marginbottom">
                    <Grid item md={6} sx={12} sm={12} lg={2}>
                      <Typography>Not Applicable</Typography>
                    </Grid>
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <TextField
                        id="outlined-basic"
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        className="date-input"
                      />
                    </Grid>
                  </Grid>
                </TabPanel> */}
              </Box>
            ) : (
              ""
            )}

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Question Group</label>
              <Select
                size="small"
                label="Group"
                name="group"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.group}
                sx={{ borderRadius: "8px" }}
              >
                {groupdata &&
                  groupdata.map((data, i) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      className="select-item"
                    >
                      {data.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <Grid container spacing={1} className="display">
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={kpiValue}
                        onChange={(e) => {
                          setStoreMap({});
                          setIsTrue(false);
                          setKpiValue(e.target.checked);
                        }}
                      />
                    }
                    label="Is KPI Question?"
                  />
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isTrue}
                        onChange={(e) => {
                          setKpiValue(false);
                          setIsTrue(e.target.checked);
                        }}
                        value="checkedA"
                      />
                    }
                    label="Is Store Question?"
                  />
                </Grid>
              </Grid>
            </FormControl>
            {isTrue ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Map this Question to</label>
                <Select
                  size="small"
                  value={storeMap}
                  onChange={handleMapStore}
                  renderValue={(selected) => {
                    const valueType = typeof selected;
                    return selected && valueType == "string"
                      ? selected
                      : selected.title;
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {storemap?.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option} className="select-item">
                        {option.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
          </Container>
        </Box>
      </form>
    </>
  );
};

export default AddQuestion;
