/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import Chart from "react-apexcharts";
import { Grid } from "material-ui-core";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useSelector, useDispatch } from "react-redux";
import { CategoryDataApi } from "../../redux/actions/Checklist";
import { Link } from "react-router-dom";
import ToggleSwitch from "../../../helpers/ToggleSwitch";
import { LoaderComponent } from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

let options = {
  colors: ["#F39A4C", "#039855", "#BB251A"],
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: "top",
      },
      borderRadius: 4,
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
    offsetX: -6,
    style: {
      fontSize: "10px",
      colors: ["none"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: false,
    intersect: true,
  },
  xaxis: {
    // categories: ['20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022'],
    lines: {
      show: true,
    },
    tickAmount: 10,
    axisBorder: {
      show: false,
    },
  },
  grid: {
    strokeDashArray: 0,
    position: "front",
    row: {
      opacity: 0.5,
    },
    column: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  legend: {
    show: false,
  },
};

const CategoryData = ({selectedChecklist}) => {
  const dispatch = useDispatch();
  const [categoryData, setCategoryData] = useState([]);
  const [optionOne, setOptionOne] = useState(options);

  const categoryDataGraph = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.categoryData &&
      state.checklist.categoryData.data &&
      state.checklist.categoryData.data.data
  );

  const activeCheckListData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.allactivechecklist &&
      state?.checklist?.allactivechecklist?.data &&
      state?.checklist?.allactivechecklist?.data?.data
  );

  useEffect(() => {
    if(activeCheckListData?.length && selectedChecklist.length){
      CategoryDataReport();
    };
  }, [selectedChecklist, activeCheckListData]);

  useEffect(() => {
    if (categoryDataGraph) {
      let series = [
        {
          name: "Created",
          data: [],
        },
        {
          name: "Submitted",
          data: [],
        },
        {
          name: "InProgress",
          data: [],
        },
      ];
      let CategoryData = [];
      for (let item of categoryDataGraph) {
        const originalDate = new Date(item.Date);
        const day = originalDate.getUTCDate().toString().padStart(2, '0');
        const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1
        const year = originalDate.getUTCFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        CategoryData.push(formattedDate);
        series[0].data.push(item.assigned || 0);
        series[1].data.push(item.completed || 0);
        series[2].data.push(item.remaining || 0);
      }
      setOptionOne((options) => ({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: CategoryData,
        },
      }));
      setCategoryData(series);
    }
  }, [categoryDataGraph]);

  const CategoryDataReport = () => {
    const id = localStorage.getItem("companyId");
    const data = { 
      url: BASE_URL + `CategoryChart?company_id=${id}`, 
      body: {
        checklistIds: selectedChecklist
      } 
    };
    dispatch(CategoryDataApi(data));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card className="overview-list" sx={{ p: 0, mt: 2 }}>
        <div style={{position:"absolute",right:"50px",marginTop:"13px"}}>
          <Tooltip
                  title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Category Data:</strong> 
                            <p>its a graph that displays created, submitted and inprogress for everymonth</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          <CardContent>
            <Typography className="view-all">Category Data</Typography>
            {!categoryData.length ? (
              <div
                style={{
                  height: "300px",
                  width: "inherit",
                  position: "relative",
                }}
              >
                <LoaderComponent play />
              </div>
            ) : (
              <Chart
                options={optionOne}
                series={categoryData}
                type="bar"
                height={400}
              />
            )}
            <Box
              direction="row"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex" }} className="display">
                <Box sx={{ mx: 2 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      //   borderBottom: "5px solid #F39A4C",
                      borderRadius: "5px",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Created
                    </Typography>
                    {/* <ToggleSwitch /> */}
                  </Stack>
                  <Divider
                    sx={{
                      backgroundColor: "#F39A4C",
                      height: "4px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
                <Box sx={{ mx: 2 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      borderRadius: "5px",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Submitted
                    </Typography>
                    {/* <ToggleSwitch /> */}
                  </Stack>
                  <Divider
                    sx={{
                      backgroundColor: "#039855",
                      height: "4px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
                <Box sx={{ mx: 2 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      borderRadius: "5px",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      InProgress
                    </Typography>

                    {/* <ToggleSwitch /> */}
                  </Stack>
                  <Divider
                    sx={{
                      backgroundColor: "#BB251A",
                      height: "4px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="display"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    pl: 1,
                    mr: 5,
                    justifyContent: "right",
                  }}
                >
                  {/* <Typography
                                        variant="p"
                                        component="div"
                                        className="graph-bottom"
                                    >
                                        Value
                                    </Typography> */}
                  {/* <ToggleSwitch /> */}
                </Stack>
                {/* <Link
                                    to=""
                                    variant="outlined"
                                    size="small"
                                    className="graph-button"
                                >
                                    Show Table
                                </Link> */}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CategoryData;
