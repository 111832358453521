import JwtAuthService from "../apiConfig/ApiConfig";
import { toast } from "react-toastify";
import axios from 'axios'
import {
    UPDATE_EDITALLFORMS_REQUEST,
    UPDATE_EDITALLFORMS_SUCCESS,
    UPDATE_EDITALLFORMS_ERROR,

} from "../types/Types";

export const EditAllFormStores = () => ({
    type: UPDATE_EDITALLFORMS_REQUEST,
});

export const EditAllFormStoresSuccess = (allForm) => {
    return {
        type: UPDATE_EDITALLFORMS_SUCCESS,
        payload: allForm
    }
}

export const EditAllFormStoresFailed = (error) => ({
    type: UPDATE_EDITALLFORMS_ERROR,
    payload: error
})

export const EditallFormUpdateData = (data, history) => dispatch => {
    try {
        dispatch(EditAllFormStores());
        JwtAuthService.PutApiService(data).then(response => {
            if (response?.status === 200) {
                const data = response;
                dispatch(EditAllFormStoresSuccess(data));
                toast.success(response.data.message);
                history(data);
            }
            else {
                dispatch(EditAllFormStoresFailed(response?.statusText));
            }
        })
    } catch (error) {
        dispatch(EditAllFormStoresFailed(error))
        console.error('Something went Wrong', error);
    }
};

// export const EditallFormUpdateData = (data,history) => {
//     return (dispatch) => {
//       dispatch(EditAllFormStores());
//   const token = localStorage.getItem("token")
//   const companyId = localStorage.getItem("companyId")
//    axios.put(`https://hope.popprobe.com/api/checklists?company_id=${companyId}`,data,{ headers: {"Authorization" : `Bearer ${token}`} })
//         .then((response) => {
//           const Blogtext = response;
//           history(Blogtext);
//           dispatch(EditAllFormStoresSuccess(Blogtext));
//         })
//         .catch((err) => {
//           dispatch(EditAllFormStoresFailed(err.message));
//         });
//     };
//   };



