/** @format */

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import { Link } from "material-ui-core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment";
import PunchClockOutlinedIcon from "@mui/icons-material/PunchClockOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { AuditorContext } from "../../../../context/auditorContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Upcoming = () => {
  const location = useLocation();
  const { scoreList } = location.state;
  const [checklistType, setChecklistType] = useState(0);
  const [upcomingList, setUpcomingList] = useState([]);
  const [remainingList, setRemainingList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [missedList, setMissedList] = useState([]);
  const [openMissedPopup, setOpenMissedPopup] = useState(false);
  const [selectedMissedChecklist, setSelectedMissedChecklist] = useState(null);
  const [openCompletedPopup, setOpenCompletedPopup] = useState(false);
  const [selectedCompletedChecklist, setSelectedCompletedChecklist] =
    useState(null);
  const [openUpcomingPopup, setOpenUpcomingPopup] = useState(false);
  const [selectedUpcomingChecklist, setSelectedUpcomingChecklist] =
    useState(null);

  const handleChangeTab = (event, newValue) => {
    setChecklistType(newValue);
  };

  let { storeTitle, companyId, currentAssessment, setcurrentAssessment } =
    useContext(AuditorContext);

  useEffect(() => {
    if (scoreList) {
      const calculateCategoryLists = () => {
        const upcoming = [];
        const remaining = [];
        const completed = [];
        const missed = [];

        scoreList.forEach((item) => {
          item.category.forEach((categoryItem) => {
            switch (categoryItem.status) {
              case "upcoming":
                upcoming.push(categoryItem);
                break;
              case "pending":
                remaining.push(categoryItem);
                break;
              case "completed":
                completed.push(categoryItem);
                break;
              case "expired":
                missed.push(categoryItem);
                break;
              default:
                break;
            }
          });
        });
        setUpcomingList(upcoming);
        setRemainingList(remaining);
        setCompletedList(completed);
        setMissedList(missed);
      };
      calculateCategoryLists();
    }
  }, [scoreList]);

  const getFrequencyText = (frequency) => {
    switch (frequency) {
      case 1:
        return "No frequency";
      case 2:
        return "Hourly";
      case 3:
        return "Daily";
      case 4:
        return "Weekly";
      case 5:
        return "Monthly";
      default:
        return "Unknown frequency";
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const formatTime = (time) => {
    if (!time) return "";
    const formattedTime = moment(time, "HH:mm").format("hh:mm A");
    return formattedTime;
  };

  const handleSelectedRemaining = (item) => {
    console.log("selectedRemainingData", item);
    localStorage.setItem("selectedRemainingData", JSON.stringify(item));
    setcurrentAssessment(item);
    navigate(`/checklist-home/upcoming/submit`, {
      state: item,
    });
  };

  const handleLinkClick = (trainingItemId) => {
    // navigate(`/auditor-home/my-trainings/my-trainings-view`, {
    //   state: trainingItemId,
    // });
  };

  const handlePopupOpen = (checklist) => {
    setSelectedMissedChecklist(checklist);
    setOpenMissedPopup(true);
  };
  const handlePopupClose = () => {
    setOpenMissedPopup(false);
  };

  const handleCompletedPopupOpen = (checklist) => {
    setSelectedCompletedChecklist(checklist);
    setOpenCompletedPopup(true);
  };
  const handleCompletedPopupClose = () => {
    setOpenCompletedPopup(false);
  };

  const handleUpcomingPopupOpen = (checklist) => {
    setSelectedUpcomingChecklist(checklist);
    setOpenUpcomingPopup(true);
  };
  const handleUpcomingPopupClose = () => {
    setOpenUpcomingPopup(false);
  };

  return (
    <>
      {Loader ? (
        <Progressbar />
      ) : (
        <Box>
          <ToastContainer autoClose={3000} />

          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              borderRadius: 1,
              overflowX: "auto",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: 1,
                  borderColor: "divider",
                  justifyContent: "space-between",
                }}
              >
                <Tabs
                  value={checklistType}
                  onChange={handleChangeTab}
                  textColor="#214d69"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "#000000",
                      color: "#214d69",
                      textColor: "#214d69",
                    },
                  }}
                >
                  <Tab label="Upcoming" />
                  <Tab label="Remaining" />
                  <Tab label="Completed" />
                  <Tab label="Missed" />
                </Tabs>

                <Tabs textColor="#214d69">
                  <Tab label={storeTitle} />
                </Tabs>
              </Box>
            </Box>
            <div className="content">
              <Box
                sx={{
                  mt: 0,
                  ml: 5,
                  mr: 5,
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "2rem",
                }}
              >
                {checklistType === 0 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {upcomingList?.length > 0 ? (
                        <Grid container spacing={3}>
                          {upcomingList.map((upComingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleUpcomingPopupOpen(upComingItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "220px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#000000",
                                  }}
                                >
                                  Total Upcoming Checklist:{" "}
                                  {upcomingList?.length}
                                </div>
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(upComingItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      Title: {upComingItem?.title}
                                    </Link>
                                  </Typography>
                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PunchClockOutlinedIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Frequency:{" "}
                                    </strong>
                                    {getFrequencyText(upComingItem?.frequency)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Start Time:{" "}
                                    </strong>
                                    {formatTime(upComingItem?.startTime)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      End Time:{" "}
                                    </strong>
                                    {formatTime(upComingItem?.endTime)}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No upcoming checklist available. Please check later...
                        </Typography>
                      )}
                      <Dialog
                        open={openUpcomingPopup}
                        onClose={handleUpcomingPopupClose}
                      >
                        <DialogTitle>Upcoming Checklist</DialogTitle>
                        <DialogContent>
                          <Typography>
                            {setSelectedUpcomingChecklist
                              ? `Checklist "${setSelectedUpcomingChecklist.title}" is missed.`
                              : ""}
                          </Typography>
                        </DialogContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                          }}
                        >
                          <Button
                            onClick={handleUpcomingPopupClose}
                            color="primary"
                          >
                            OK
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </>
                )}
                {checklistType === 1 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {remainingList?.length > 0 ? (
                        <Grid container spacing={3}>
                          {remainingList.map((remainingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleSelectedRemaining(remainingItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "220px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#000000",
                                  }}
                                >
                                  Total Remaining Checklist:{" "}
                                  {remainingList?.length}
                                </div>
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(remainingItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      Title: {remainingItem?.title}
                                    </Link>
                                  </Typography>
                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PunchClockOutlinedIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Frequency:{" "}
                                    </strong>
                                    {getFrequencyText(remainingItem?.frequency)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Start Time:{" "}
                                    </strong>
                                    {formatTime(remainingItem?.startTime)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      End Time:{" "}
                                    </strong>
                                    {formatTime(remainingItem?.endTime)}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No remaining checklist available. Please check
                          later...
                        </Typography>
                      )}
                    </div>
                  </>
                )}
                {checklistType === 2 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {completedList?.length > 0 ? (
                        <Grid container spacing={3}>
                          {completedList.map((completedItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleCompletedPopupOpen(completedItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "220px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#000000",
                                  }}
                                >
                                  Total Completed Checklist:{" "}
                                  {completedList?.length}
                                </div>
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(completedItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      Title: {completedItem?.title}
                                    </Link>
                                  </Typography>
                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PunchClockOutlinedIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Frequency:{" "}
                                    </strong>
                                    {getFrequencyText(completedItem?.frequency)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Start Time:{" "}
                                    </strong>
                                    {formatTime(completedItem?.startTime)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      End Time:{" "}
                                    </strong>
                                    {formatTime(completedItem?.endTime)}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No Completed checklist available. Please check
                          later...
                        </Typography>
                      )}
                      <Dialog
                        open={openCompletedPopup}
                        onClose={handleCompletedPopupClose}
                      >
                        <DialogTitle>Completed Checklist</DialogTitle>
                        <DialogContent>
                          <Typography>
                            {setSelectedCompletedChecklist
                              ? `Checklist "${setSelectedCompletedChecklist.title}" is completed.`
                              : ""}
                          </Typography>
                        </DialogContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                          }}
                        >
                          <Button
                            onClick={handleCompletedPopupClose}
                            color="primary"
                          >
                            OK
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </>
                )}
                {checklistType === 3 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {missedList?.length > 0 ? (
                        <Grid container spacing={3}>
                          {missedList.map((missedItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() => handlePopupOpen(missedItem)}
                                style={{
                                  cursor: "pointer",
                                  height: "220px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#000000",
                                  }}
                                >
                                  Total Missed Checklist: {missedList?.length}
                                </div>
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(missedItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      Title: {missedItem?.title}
                                    </Link>
                                  </Typography>
                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PunchClockOutlinedIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Frequency:{" "}
                                    </strong>
                                    {getFrequencyText(missedItem?.frequency)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      Start Time:{" "}
                                    </strong>
                                    {formatTime(missedItem?.startTime)}
                                  </Typography>

                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{
                                      pt: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeIcon sx={{ mr: 1 }} />
                                    <strong style={{ marginRight: "8px" }}>
                                      End Time:{" "}
                                    </strong>
                                    {formatTime(missedItem?.endTime)}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No missed checklist available. Please check later...
                        </Typography>
                      )}

                      <Dialog open={openMissedPopup} onClose={handlePopupClose}>
                        <DialogTitle>Missed Checklist </DialogTitle>
                        <DialogContent>
                          <Typography>
                            {selectedMissedChecklist
                              ? `Checklist "${selectedMissedChecklist.title}" is missed.`
                              : ""}
                          </Typography>
                        </DialogContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                          }}
                        >
                          <Button onClick={handlePopupClose} color="primary">
                            OK
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </>
                )}
              </Box>
              <div className="footer">
                <Box sx={{ mt: 0, ml: 3, mr: 3 }}>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    style={{ marginTop: "10px" }}
                  >
                    Copyright ©{" "}
                    <Link
                      href="https://www.hoperesearchgroup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      HOPE RESEARCH GROUP
                    </Link>
                    . All rights reserved.
                  </Typography>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Upcoming;
