import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DoneIcon from "@mui/icons-material/Done";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ViewFormHead = (props) => {
  const navigate = useNavigate();
  const [permission, setPermission] = useState({});
  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);
  return (
    <Box
      sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
      className="header-card"
    >
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} sm={12} lg={8}>
          <Box className="allform-head-time">
            <Typography sx={{ display: "flex", alignItems: "center",paddingLeft:{lg:1} }}>
              <small
                style={{
                  marginRight: "5px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                Created :{" "}
              </small>

              <b>{moment(props?.listData?.createdAt).format("lll")} &nbsp; </b>
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <small
                style={{
                  marginRight: "5px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                Modified :{" "}
              </small>
              <b>{moment(props?.listData?.updatedAt).format("lll")}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right", paddingRight:{lg:1} }}
            className="plus-icon"
          >
            {permission?.checklist?.forms === true ? (
              <Link
                to="/checklist/all-form/edit-forms"
                className="submenu-item"
              >
                <Button
                  variant="contained"
                  // color="warning"
                  className="cancle-hover"
                  sx={{
                    height: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#F39A4C",
                    boxShadow: "none",
                  }}
                >
                  <BorderColorIcon sx={{ mr: 1 }} />
                  Edit
                </Button>
              </Link>
            ) : (
              ""
            )}

            {/* <Link to="/checklist/all-form" className="submenu-item"> */}
            <Button
              
              className="header-add cancle-hover"
              onClick={() => navigate(-1)}
            >
              <DoneIcon sx={{ mr: 1 }} />
              Back
            </Button>
            {/* </Link> */}
            <div style={{marginTop:"8px",marginRight:"5px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewFormHead;
