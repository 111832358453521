import {CREATE_ROLES_REQUEST, CREATE_ROLES_SUCCESS, CREATE_ROLES_ERROR, EDIT_ROLES_ERROR, EDIT_ROLES_REQUEST, EDIT_ROLES_SUCCESS, LIST_ROLES_ERROR, LIST_ROLES_REQUEST, LIST_ROLES_SUCCESS, LIST_SUBROLE_ERROR, LIST_SUBROLE_REQUEST, LIST_SUBROLE_SUCCESS, MAP_ROLES_ERROR, MAP_ROLES_REQUEST, MAP_ROLES_SUCCESS, ROLE_DETAILS_ERROR, ROLE_DETAILS_REQUEST, ROLE_DETAILS_SUCCESS, DELETE_ROLES_REQUEST, DELETE_ROLES_SUCCESS, DELETE_ROLES_ERROR } from "../types/Types";

const initialState = {
    loading: false,
    error: '',
    data: [],
    res: '',
    details: '',
    subrole: [],
    created:[],
    deleteRole:""

 };
 
 const RolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ROLES_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case CREATE_ROLES_SUCCESS: {
            return {
                ...state,
                created: action?.payload,
                loading: false
            }
        }
        case CREATE_ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case LIST_ROLES_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case LIST_ROLES_SUCCESS: {
            return {
                ...state,
                data: action?.payload,
                loading: false
            }
        }
        case LIST_ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case EDIT_ROLES_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case EDIT_ROLES_SUCCESS: {
            return {
                ...state,
                res: action?.payload,
                loading: false
            }
        }
        case EDIT_ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ROLE_DETAILS_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ROLE_DETAILS_SUCCESS: {
            return {
                ...state,
                details: action?.payload,
                loading: false
            }
        }
        case ROLE_DETAILS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case LIST_SUBROLE_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case LIST_SUBROLE_SUCCESS: {
            return {
                ...state,
                subrole: action?.payload,
                loading: false
            }
        }
        case LIST_SUBROLE_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case MAP_ROLES_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case MAP_ROLES_SUCCESS: {
            return {
                ...state,
                res: action?.payload,
                loading: false
            }
        }
        case MAP_ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case DELETE_ROLES_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case DELETE_ROLES_SUCCESS: {
            return {
                ...state,
                loading: false,
                deleteRole: action?.payload
            }
        }
        case DELETE_ROLES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
 }

 export default RolesReducer;