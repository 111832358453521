/** @format */

import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import { useDispatch } from "react-redux";
import { AuditorContext } from "../../../../context/auditorContext";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import axios from "axios";

const ChecklistForms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [reStoredRemainingData, setreStoredRemainingData] = useState(null);
  const [receivedItem, setReceivedItem] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [scoreList, setUpdatedScoreList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const {
    setcompanyId,
    setstoreId,
    storeId,
    companyId,
    setstoreTitle,
    storeTitle,
  } = useContext(AuditorContext);

  // API calling for fatching updated data
  useEffect(() => {
    setShowLoader(true);
    const fetchData = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
        const token = localStorage.getItem("token");
        const res = await axios.get(
          `${BASE_URL}profile/me?company_id=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setStoreList(res.data.data.store);
        const selectedStore = localStorage.getItem("auditorSelectedStore");
        if (selectedStore) {
          const parseselectedStore = JSON.parse(selectedStore);
          setstoreId(parseselectedStore._id);
          setstoreTitle(parseselectedStore.title);
        } else {
          setstoreId(res.data.data.store[0]._id);
          setstoreTitle(res.data.data.store[0].title);
          localStorage.setItem(
            "auditorSelectedStore",
            JSON.stringify(res.data.data.store[0])
          );
        }
        // setShowLoader(false);
        setInitialLoad(false);
      } catch (error) {
        console.error(error);
        setShowLoader(false);
      }
    };

    fetchData();
  }, [initialLoad]);

  const storeChangeApi = () => {
    setShowLoader(true);
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const selectedStore = JSON.parse(
      localStorage.getItem("auditorSelectedStore")
    );

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${BASE_URL}formsAssignment/indexnew?timezone=india&company_id=${companyId}`,
          {
            storeId: {
              _id: selectedStore._id,
              title: selectedStore.title,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUpdatedScoreList(res?.data?.data);
        setShowLoader(false);
      } catch (error) {
        console.error(error);
        setShowLoader(false);
      }
    };

    fetchData();
  };

  useEffect(() => {
    if (storeId && storeList.length) {
      storeChangeApi();
    }
  }, [storeId, storeList]);

  // Api calling
  // useEffect(() => {
  //   const storedData = localStorage.getItem("selectedRemainingData");
  //   const parsedData = JSON.parse(storedData);
  //   console.log("parsedData", parsedData?._id);
  //   if (scoreList && scoreList.length > 0) {
  //     console.log("scoreList", scoreList[0]?.category);
  //     scoreList?.scoreList[0]?.category?.forEach((item) => {
  //       if (item?._id === parsedData?._id) {
  //         parsedData = item;
  //       }
  //     });
  //   }
  //   // const parsedData = scoreList;
  //   if (parsedData && parsedData.forms && parsedData.forms.length > 0) {
  //     const formId = parsedData.forms[0]._id;
  //     setreStoredRemainingData(parsedData);
  //     const storedAnswers = localStorage.getItem("answeredQuestions_" + formId);
  //     const parsedAnswers = JSON.parse(storedAnswers);
  //     if (parsedAnswers) {
  //       setReceivedItem(parsedAnswers);
  //     }
  //   }
  // }, [scoreList]);
  // console.log("ChecklistForms", reStoredRemainingData);

  useEffect(() => {
    const storedData = localStorage.getItem("selectedRemainingData");
    const parsedData = JSON.parse(storedData);
    console.log("parsedData", parsedData?._id);

    if (scoreList && scoreList.length > 0) {
      console.log("scoreList", scoreList[0]?.category);
      scoreList.forEach((item) => {
        if (item?.category && item.category.length > 0) {
          item.category.forEach((categoryItem) => {
            if (categoryItem?._id === parsedData?._id) {
              parsedData.forms = categoryItem.forms;
            }
          });
        }
      });
    }

    if (parsedData && parsedData.forms && parsedData.forms.length > 0) {
      const formId = parsedData.forms[0]._id;
      setreStoredRemainingData(parsedData);
      const storedAnswers = localStorage.getItem("answeredQuestions_" + formId);
      const parsedAnswers = JSON.parse(storedAnswers);
      if (parsedAnswers) {
        setReceivedItem(parsedAnswers);
      }
    }
  }, [scoreList]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handlePopstate = () => {
      localStorage.setItem("IndivisualFormData", JSON.stringify(receivedItem));
      localStorage.removeItem("IndivisualFormData");
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const handleIndivisualFormClick = (item) => {
    const formId = item._id;
    const storedAnswers = localStorage.getItem("answeredQuestions_" + formId);
    const parsedAnswers = JSON.parse(storedAnswers);
    Object.keys(localStorage).forEach((key) => {
      if (
        key.startsWith("answeredQuestions_") &&
        key !== "answeredQuestions_" + formId
      ) {
        localStorage.removeItem(key);
      }
    });
    if (parsedAnswers) {
      setReceivedItem(parsedAnswers);
    }
    console.log("Itemeee", item);
    localStorage.setItem("IndivisualFormData", JSON.stringify(item));
    navigate(`/checklist-home/checklist-types/checklist-forms/submit`, {
      state: { formData: item, formId: formId },
    });
  };

  return (
    <Box>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              variant="h6"
              component="h6"
              align="left"
              style={{
                padding: "4px",
                marginLeft: "10px",
                marginBottom: "0",
                fontSize: "1.2rem",
              }}
            >
              <p style={{ margin: "0" }}>Checklist Forms</p>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              variant="h6"
              component="h6"
              align="right"
              style={{
                textTransform: "uppercase",
                padding: "4px",
                marginRight: "10px",
                marginBottom: "0",
                fontSize: "1.2rem",
              }}
            >
              {storeTitle}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box>
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "calc(100% - 62px)",
              mt: 1,
              p: 1,
              overflowX: "hidden",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
              },
            }}
          >
            {/* {reStoredRemainingData?.forms.map((form, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#FFFFFF",
                  padding: "10px",
                  marginBottom: "10px",
                  cursor: "pointer",
                  border: "1px solid #DADADA",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => handleIndivisualFormClick(form)}
              >
                <Typography
                  variant="body1"
                  component="div"
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    marginLeft: "2px",
                  }}
                >
                  {form.title}
                </Typography>
                <Typography variant="body2" style={{ color: "#555555" }}>
                  <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                    {[`Q: ${form.questions.length}`]}
                  </span>
                </Typography>
              </div>
            ))} */}
            {reStoredRemainingData?.forms.map((form, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#FFFFFF",
                  padding: "10px",
                  marginBottom: "10px",
                  cursor: form.Completed ? "default" : "pointer",
                  border: "1px solid #DADADA",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={
                  form.Completed ? null : () => handleIndivisualFormClick(form)
                }
              >
                <Typography
                  variant="body1"
                  component="div"
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    marginLeft: "2px",
                    color: form.Completed ? "inherit" : "inherit",
                  }}
                >
                  {form.title}
                </Typography>
                <Typography variant="body2" style={{ color: "#555555" }}>
                  <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                    {form.Completed ? (
                      <span style={{ color: "green" }}>Completed</span>
                    ) : (
                      `Q: ${form.questions.length}`
                    )}
                  </span>
                </Typography>
              </div>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChecklistForms;
