import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AssesmentListApi,
  CertificateListApi,
} from "../../../redux/actions/LmsAction";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CertificateModal = (props) => {
  const certificateList = useSelector(
    (state) =>
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.certificates
  );

  const list = props?.deletedCertificate;
  const dispatch = useDispatch();
  const editCertificate = localStorage.getItem("editCertificate");
  const [certificateData, setCertificateData] = useState([]);
  const [certificateIdListData, setCertificateIdListData] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState([]);


  useEffect(() => {
    setCertificateIdListData(props.certificateIdList);
  }, [props.certificateIdList]);

  useEffect(() => {
    setCertificateData((certificateData) => [...certificateData, list]);
  }, [list]);

  useEffect(() => {
    if(editCertificate && JSON.parse(editCertificate).length){
      setSelectedCertificate(JSON.parse(editCertificate));
    }
  }, [editCertificate]);

  useEffect(() => {
    if (certificateList) {
      setCertificateData(certificateList);
    }
  }, [certificateList]);


  // useEffect(()=>{
  //     let localData = localStorage.getItem("assesmentData");
  // let localParse = JSON.parse(localData);
  // let add = localParse;
  // if (list) {
  //   add.push(list);
  //   localStorage.setItem("assesmentData", JSON.stringify(add));
  // }
  // }, [list])
  useEffect(() => {
    dispatch(CertificateListApi());
  }, []);

  const handleCertificate = (item) => {
    if (selectedCertificate.includes(item._id)) {
      setSelectedCertificate(selectedCertificate.filter(cert => cert !== item._id));
      setCertificateIdListData(certificateIdListData.filter(cert => cert._id !== item._id));

    } else {
      setSelectedCertificate([...selectedCertificate, item._id])
      setCertificateIdListData((certificateIdListData) => [
        ...certificateIdListData,
        item,
      ]);
    }

  };


  const handleSaveClose = () => {
    if (certificateIdListData) {
      localStorage.setItem(
        "certificateData",
        JSON.stringify(certificateIdListData)
      );
      localStorage.setItem("editCertificate", JSON.stringify(selectedCertificate));
    }
    props.setOpen(false);
  };
  const handleCancle = () => {
    // let newArray = [...certificateData, ...certificateIdListData];
    // setCertificateData(newArray);
    setSelectedCertificate(props.certificateIdList.map(item => item._id));
    setCertificateIdListData(props.certificateIdList);
    props.setOpen(false);

  };

  return (
    <div>
      <BootstrapDialog
        // onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size-subcategory"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
        //   onClose={props.handleClose}
        >
          <Typography variant="p" component="div" className="heading">
            Certificate List
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box className="">
                <Link to="/certificate" className="submenu-item">
                  <Button
                    variant="outlined"
                    className="add-button cancle-hover"
                    sx={{ mb: 1 }}
                  >
                    <AddIcon sx={{ mx: 1 }} />
                    Add New Certificate
                  </Button>
                </Link>
                {certificateData &&
                  certificateData.length > 0 &&
                  certificateData?.map((items, i) => {
                    return (
                      <Box className={selectedCertificate.includes(items._id) ? "assesment-list-hover-selected" : "assesment-list-hover"} key={i} onClick={() => handleCertificate(items)}>
                        <ul>
                          <li>
                            {i + 1}&nbsp;&nbsp;{items?.title}
                          </li>
                          {/* <Divider /> */}
                        </ul>
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            type="submit"
            onClick={handleSaveClose}
            color="success"
          >
            Save
          </Button>
          <Button variant="contained" onClick={handleCancle}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CertificateModal;
