import JwtAuthService from "../apiConfig/ApiConfig";
import {
  MISSEDCHECKLIST_FORM_REQUEST,
  MISSEDCHECKLIST_SUCCESS,
  MISSEDCHECKLIST_ERROR,
} from "../types/Types";

export const MissedCheckListRequest = (data) => ({
  type: MISSEDCHECKLIST_FORM_REQUEST,
  payload: data,
});
export const MissedCheckListSuccess = (data) => ({
  type: MISSEDCHECKLIST_SUCCESS,
  payload: data,
});
export const MissedCheckListFailed = (error) => ({
  type: MISSEDCHECKLIST_ERROR,
  payload: error,
});

export const MissedCheckLIstApi = (data) => (dispatch) => {
  try {
    dispatch(MissedCheckListRequest());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(MissedCheckListSuccess(response?.data));
      } else {
        dispatch(MissedCheckListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(checklistReportFailed(error));
    console.error("Something went Wrong", error);
  }
};
