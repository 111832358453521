import {
  ACCOUNT_DETAILS_ERROR,
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_SUCCESS,
  ALL_PLANS_ERROR,
  ALL_PLANS_REQUEST,
  ALL_PLANS_SUCCESS,
  GET_ACTIVE_ADD_ON_ERROR,
  GET_ACTIVE_ADD_ON_REQUEST,
  GET_ACTIVE_ADD_ON_SUCCESS,
  PLAN_DETAILS_ERROR,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_SUCCESS,
  SUBMIT_ACCOUNT_DETAILS_ERROR,
  SUBMIT_ACCOUNT_DETAILS_REQUEST,
  SUBMIT_ACCOUNT_DETAILS_SUCCESS,
} from "../types/Types";

const initialState = {
  planList: [],
  getActiveAddOn: [],
  allPlans: [],
  companyInfo: {},
  loading: false,
  companyLoading: false,
  submitLoading: false,
  error: "",
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAN_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PLAN_DETAILS_SUCCESS: {
      return {
        ...state,
        planList: action.payload,
        loading: false,
      };
    }
    case PLAN_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ACCOUNT_DETAILS_REQUEST: {
      return {
        ...state,
        companyLoading: true,
        error: "",
      };
    }
    case ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        companyInfo: action.payload,
        companyLoading: false,
      };
    }
    case ACCOUNT_DETAILS_ERROR: {
      return {
        ...state,
        companyLoading: false,
        error: action.error,
      };
    }
    case SUBMIT_ACCOUNT_DETAILS_REQUEST: {
      return {
        ...state,
        submitLoading: true,
        error: "",
      };
    }
    case SUBMIT_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        // companyInfo: action.payload,
        submitLoading: false,
      };
    }
    case SUBMIT_ACCOUNT_DETAILS_ERROR: {
      return {
        ...state,
        submitLoading: false,
        error: action.error,
      };
    }
    case ALL_PLANS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ALL_PLANS_SUCCESS: {
      return {
        ...state,
        allPlans: action.payload,
        loading: false,
      };
    }
    case ALL_PLANS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case GET_ACTIVE_ADD_ON_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GET_ACTIVE_ADD_ON_SUCCESS: {
      return {
        ...state,
        getActiveAddOn: action.payload,
        loading: false,
      };
    }
    case GET_ACTIVE_ADD_ON_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default accountReducer;
