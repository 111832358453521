import { CircularProgress } from "material-ui-core";
import React from "react";
import Lottie from 'react-lottie-player'
import lottieJson from './LottieLogo.json'

function ProgressbarTransparent(props) {
  return (
    <div id="divLoading" className="transparentLoader">
      <span className="centerLoaderText">
        <Lottie
          animationData={lottieJson}
          style={{ width: 150, height: 150 }}
          loop
          speed={2}
          {...props}
        ></Lottie>
      </span>
    </div>
  );
}

export function LoaderComponent(props) {
  return (
    <div id="divLoading" className="transparentLoaderWrapper">
      <span className="centerLoaderTextWrapper">
        <span className="centerLoaderInnerWrapper">
          <Lottie
            animationData={lottieJson}
            style={{ width: 150, height: 150 }}
            loop
            speed={2}
            {...props}
          ></Lottie>
        </span>
      </span>
    </div>
  );
}

export default ProgressbarTransparent;
