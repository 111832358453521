import React from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Delete from "../../../images/Delete.svg";

const RuleSection = () => {
  const open = Boolean(anchorEl);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ textAlign: "left" }}>
        <Grid item xs={12}>
          <Box sx={{ fontWeight: "bold" }}>Rules</Box>
          <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>Rules Preset</Box>
          <Card sx={{ p: 3, mb: 2 }}>
            <Grid container spacing={1} sx={{ textAlign: "left" }}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} className="list-btn">
                  <Typography>When category of store is</Typography>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className="select-btn"
                  >
                    Select Category
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </Menu>
                  <Typography>then ask only questions</Typography>
                  <Button
                    className="select-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Select Questions
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ p: 3, mb: 2 }}>
            <Grid container spacing={1} sx={{ textAlign: "left" }}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} className="list-btn">
                  <Typography>When category of store is</Typography>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className="select-btn"
                  >
                    Select Category
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </Menu>
                  <Typography>then ask only questions</Typography>
                  <Button
                    className="select-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Select Questions
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
          <Box sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>Rules Added</Box>
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              pt: 2,
              pb: 2,
            }}
          >
            <Container>
              <Typography>
                When Category of store is "Dining" then ask only questions
              </Typography>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <ol>
                <li>Diamonds stock report tallied</li>
                <li>TEST Question</li>
              </ol>
            </Container>
            <Box>
              <img
                src={Delete}
                alt="Delete"
                style={{ padding: "0 5px", height: "30px" }}
              />
            </Box>
          </Card>
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              pt: 2,
              pb: 2,
            }}
          >
            <Container>
              <Typography>
                When Category of store is "Dining" then ask only questions
              </Typography>
              <Divider sx={{ mb: 2, mt: 2 }} />
              <ol>
                <li>Diamonds stock report tallied</li>
                <li>TEST Question</li>
              </ol>
            </Container>
            <Box>
              <img
                src={Delete}
                alt="Delete"
                style={{ padding: "0 5px", height: "30px" }}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default RuleSection;
