/** @format */

import React, { useEffect, useState } from "react";
import { red } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import {
  Stack,
  Chip,
  Button,
  Tabs,
  Tab,
  Card,
  Grid,
  Radio,
  Box,
  Modal,
  FormControl,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { ApproveUserApi } from "../../../redux/actions/User";
import { UserListArchivedApi } from "../../../redux/actions/Checklist";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import InfoIcon from "@mui/icons-material/Info";
import { pink } from "material-ui-core/colors";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

const Header = (props) => {
  const userListFilter = localStorage.getItem("userListFilterData") || "{}";
  const userListFilterData = JSON.parse(userListFilter);

  const dispatch = useDispatch();
  const totalitems = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.paginate
  );
  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );
  const [totalUser, setTotalUser] = useState();
  const [radioValue, setRadioValue] = useState(userListFilterData?.userType || "");
  const [userData, setUserData] = useState([]);
  const [activeUser, setActiveUser] = useState();
  const [value, setValue] = useState(0);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [searchValue, setSearchValue] = useState(userListFilterData.searchValue || '');

  const handleApplyFilter = () => {
    const payloadRequestArchive = {
      body: {
        pagination: {
          page: 1,
          per_page: props.rowsPerPage,
        },
        sort: {
          sort_as: "updatedAt",
        },
        userType: radioValue === "all" ? "" : radioValue,
        search: searchValue,
      },
    };

    const payloadRequestAll = {
      body: {
        page: 1,
        per_page: props.rowsPerPage,
        sort: {
          sort_as: "updatedAt",
        },
        userType: radioValue === "all" ? "" : radioValue,
        q: searchValue,
      },
    };

    localStorage.setItem("userListFilterData", JSON.stringify({
      userType: radioValue === "All" ? "" : radioValue,
      searchValue: searchValue,
    }));

    props.handleFilterApplyApiCall(!props.checkedArchive ? payloadRequestAll : payloadRequestArchive); // Call the function in the parent component to make the API call

    setOpenFilterModal(false);
  };



  const downloadAllstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 500,
    overflow: "scroll",
    bgcolor: "background.paper",
    // border: "2px solid rgb(12 36 49)",
    borderRadius: "6px",
    boxShadow: 24,
    p: 4,
  };
  const MenuProps = {
    disableScrollLock: true,
  };


  const handleFreqChange = (e) => {
    setFrequency(e.target.value);
  };

  let activeUserLength =
    userData &&
    userData.length > 0 &&
    userData.filter((item) => {
      return item.approved === true;
    }).length;

  const payloadRequestAll = {
    body: {
      pagination: {
        page: props.page,
        per_page: props.rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
      },
      search: searchValue,
    },
  };

  const UserListArchivedApis = (payloadRequestAll) => {
    const id = localStorage.getItem("companyId");
    const role = props.selectedValue === "all" ? "" : props.selectedValue;
    payloadRequestAll.url =
      BASE_URL + `users/ListArchivedUser?company_id=${id}&role=${role}`;
    dispatch(UserListArchivedApi(payloadRequestAll));
  };

  useEffect(() => {
    if (props.checkedArchive === true) {
      payloadRequestAll.body.pagination.page = 1;
      props.setPage(1);
      UserListArchivedApis(payloadRequestAll);
    }
  }, [props.checkedArchive]);

  useEffect(() => {
    if (userList) {
      setTotalUser(totalitems);
      setUserData(userList);
    }
  }, [totalitems, userList]);

  const handleRadioButton = (value) => {
    console.log(value)
    setRadioValue(value)
  }

  const handleChangeTabs = (newValue) => {
    props.setPage(1);
    setValue(newValue);

    if (newValue === 0) {
      props.setCheckedArchive(false);
    } else {
      props.setCheckedArchive(true);
    }
  };

  const handleSearch = (value) => {
    localStorage.setItem("searchUserValue", value);
    setSearchValue(value);
  };


  return (
    <>
      <Card className="header-card top-margin">
        <Grid
          container
          spacing={1}
          className="userlist-head-maingrid"
          display={"row"}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                style={{ marginLeft: "8px" }}
                label="Active"
                icon={
                  <p className="active-tabs-content">{props?.totalitems}</p>
                }
                iconPosition="end"
                className={
                  props.checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(false)}
              />
              <Tab
                label="Archived"
                icon={
                  <p className="active-tabs-content">
                    {props?.totalUserArchived}
                  </p>
                }
                iconPosition="end"
                className={
                  props?.checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                onClick={() => props.setCheckedArchive(true)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Stack direction="row" className="Assign-Checklist">
              {/* <SearchAppBar
                searchValue={props.searchValue}
                searchOnChange={props.searchOnChange}
                handleKeyDown={props.handleKeyDown}
                sx={{ height: "42px", borderRadius: "8px" }}
              /> */}
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "40px",
                  borderRadius: "8px",
                  marginTop: "3px",
                  padding: "12px 16px",
                  float: "right",
                }}
                onClick={(e) => setOpenFilterModal(true)}
              >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                Filter
              </Button>
              {props?.permission?.user?.manageUsers === true ? (
                <Link to="/user-list/add-user" className="submenu-item">
                  <Button
                    className="header-add cancle-hover"
                    style={{ height: "40px", marginTop: "2px" }}
                  >
                    <AddOutlinedIcon />
                    Add User
                  </Button>
                </Link>
              ) : (
                ""
              )}
              <div style={{ marginTop: "9px", marginRight: "10px" }}>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>User List:</strong>
                      <p>
                        List of all the users created in the company having user
                        email id , store name, roles, sub role details of the user
                      </p>
                      <strong>Change password: </strong>
                      <p>Users login password can be changed </p>
                      <strong>Change Permission:</strong>
                      <p>
                        It allows Admin to restrict the module access of the user
                        available on web to view when logged in via web Admin.
                      </p>
                      <strong>View: </strong>
                      <p>
                        Users details can be viewed, users can be approved and
                        disapproved to login in app{" "}
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
          <Modal
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown={false}
          >
            <Box sx={{ ...downloadAllstyle, p: 0 }}>
              <Box
                sx={{
                  position: "sticky",
                  right: "15px",
                  top: "15px",
                  cursor: "pointer",
                  textAlign: "right",
                  padding: "0px 10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box onClick={() => setOpenFilterModal(false)}>
                  <CloseIcon />
                </Box>
              </Box>
              <Box sx={{ pt: 2, px: 4 }}>
                {props?.checkedArchive !== true ? (
                  <Stack direction="row" className="stack">
                    <FormControl
                      fullWidth
                      sx={{ background: "#FFFFFF", borderRadius: "5px" }}
                    >
                      <label>Status</label>
                      <Stack
                        direction="row"
                        className="checkbox"
                        sx={{ alignItems: "center" }}
                      >
                        <Stack
                          direction="row"
                          className="checkbox userlist-header-btn"
                        >
                          <>
                            <Tooltip title="All">
                              <Box className="user-filter">
                                <Radio
                                  // {...controlProps("all")}
                                  sx={{
                                    color: red[800],
                                    "&.Mui-checked": {
                                      color: red[600],
                                    },
                                    justifyContent: "center",
                                  }}
                                  onChange={() => handleRadioButton("all")}
                                  checked={radioValue === 'all'}
                                  color="error"
                                />
                                {/* <Radio
                                  checked={selectedValue === 'a'}
                                  onChange={handleChange}
                                  value="a"
                                  name="radio-buttons"
                                  inputProps={{ 'aria-label': 'A' }}
                                /> */}
                                <span style={{ marginRight: "10px" }}>All</span>
                              </Box>
                            </Tooltip>
                            <Tooltip title="Admin">
                              <Box className="user-filter">
                                <Radio
                                  // {...controlProps("admin")}
                                  sx={{
                                    color: blue[800],
                                    "&.Mui-checked": {
                                      color: blue[600],
                                    },
                                    justifyContent: "center",
                                  }}
                                  checked={radioValue === 'admin'}
                                  onChange={() => handleRadioButton("admin")}
                                  color="secondary"
                                />
                                <span style={{ marginRight: "10px" }}>Admin</span>
                              </Box>
                            </Tooltip>
                            <Tooltip title="Auditor">
                              <Box className="user-filter">
                                <Radio
                                  // {...controlProps("auditor")}
                                  sx={{
                                    color: green[800],
                                    "&.Mui-checked": {
                                      color: green[600],
                                    },
                                    justifyContent: "center",
                                  }}
                                  onChange={() => handleRadioButton("auditor")}
                                  checked={radioValue === 'auditor'}
                                  color="error"
                                />
                                <span style={{ marginRight: "10px" }}>Auditor</span>
                              </Box>
                            </Tooltip>
                            <Tooltip title="Viewer">
                              <Box className="user-filter">
                                <Radio
                                  // {...controlProps("viewer")}
                                  sx={{
                                    color: yellow[800],
                                    "&.Mui-checked": {
                                      color: yellow[600],
                                    },
                                    justifyContent: "center",
                                  }}
                                  checked={radioValue === 'viewer'}
                                  onChange={() => handleRadioButton("viewer")}
                                  color="error"
                                />
                                <span style={{ marginRight: "10px" }}>Viewer</span>
                              </Box>
                            </Tooltip>
                          </>
                        </Stack>
                      </Stack>
                    </FormControl>
                  </Stack>
                ) : (
                  ""
                )}
                <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                  <Box className="user-count">
                    <Tooltip title="Active Users">
                      <Badge
                        badgeContent={activeUserLength > 0 ? activeUserLength : 0}
                        color="success"
                      >
                        <AccountCircleOutlinedIcon color="action" />
                      </Badge>
                      <span>Active users</span>
                    </Tooltip>
                  </Box>
                  <Box className="user-count" style={{ marginLeft: '20px' }}>
                    <Badge
                      badgeContent={totalUser?.showing}
                      color="primary"
                      fontSize={12}
                    >
                      <UpdateIcon color="action" />
                    </Badge>
                    <span>Total Count</span>
                  </Box>
                </div>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Search</label>
                  <SearchAppBar
                    searchValue={searchValue}
                    searchOnChange={handleSearch}
                    // handleKeyDown={props.handleKeyDown}
                    sx={{ height: "42px", borderRadius: "8px" }}
                    width="37ch"
                  />

                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                  gap: "30px",
                  px: 3,
                  pt: 1,
                  background: "#fff",
                }}
              >
                <Button
                  className="cancle-button cancle-hover"
                  style={{
                    height: "46px",
                    padding: "12px 16px",
                    width: "47%",
                    background: "#fff",
                  }}
                  onClick={() => {
                    // setSelectedRadio("");
                    setRadioValue("");
                    handleSearch("")
                  }}
                >
                  Clear
                </Button>
                <Button
                  className="header-add cancle-hover"
                  style={{
                    height: "46px",
                    padding: "12px 16px",
                    width: "47%",
                  }}
                  // onClick={(e) => {
                  //   modalopenFilterhandleClose();
                  //   // setOpenFilterModal(false);
                  //   // handleFilter();
                  // }}
                  onClick={handleApplyFilter}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Card>
    </>
  );
};

export default Header;

export const ViewHeader = (props) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [approve, setApprove] = useState();
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(false);

  const userDetailData = useSelector(
    (state) =>
      state.user &&
      state.user.details &&
      state.user.details.data &&
      state.user.details.data.data
  );
  const approveData = useSelector(
    (state) =>
      state.user &&
      state.user.approval &&
      state.user.approval.data &&
      state.user.approval.data
  );

  useEffect(() => {
    setUserData(userDetailData);
  }, [userDetailData]);

  useEffect(() => {
    if (userData?.approved === true) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  }, [userData]);

  useEffect(() => {
    if (approveData) {
      toast.success(approveData.message, { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [approveData]);

  const data = {
    body: {
      approved: approve,
    },
  };
  const handleClickEdit = (row) => {
    if (row) {
      const data = JSON.stringify(row);
      localStorage.setItem("userEditData", data);
      navigate("/user-list/edit-user", { state: { data: row } });
    }
    // if (row) {
    //   navigate("/user-list/edit-user", { state: { data: row } });
    // }
  };
  const handleApprove = () => {
    const _id = localStorage.getItem("companyId");
    // const userId = props?.props?.userDetails?._id;
    const userId = props?.userDetails?._id;
    data.body.comment = comment;
    data.url = BASE_URL + `users/${userId}?company_id=${_id}`;
    dispatch(ApproveUserApi(data));
  };
  console.log("handleApprove", handleApprove);

  return (
    <>
      <ToastContainer />
      {props?.permission?.user?.manageUsers === true ? (
        <Grid
          container
          sx={{
            bgcolor: "#F6F8FC",
            width: "100%",
            borderRadius: "8px",
            p: 1,
            justifyContent: "center",
          }}
          alignItems="center"
          className="header-card"
        >
          <Grid item md={6} sx={12} sm={12} lg={6}>
            {/* <Stack direction="row" spacing={1}>
                        <Chip icon={<CheckIcon />} label="Approved" color="success" variant="outlined" className="user-header" />
                        <Chip icon={<CloseIcon />} label="Varified" color="error" variant="outlined" className="user-header" />
                    </Stack> */}
            <Stack direction="row" spacing={1}>
              {userData?.verified === false ? (
                <Chip
                  icon={<CloseIcon />}
                  label="Not Verified"
                  color="error"
                  variant="outlined"
                  className="user-header"
                />
              ) : (
                <Chip
                  icon={<CheckIcon />}
                  label="Verified"
                  color="success"
                  variant="outlined"
                  className="user-header"
                />
              )}
              {userData?.approved === false ? (
                <Chip
                  icon={<CloseIcon />}
                  label="Disapproved"
                  color="error"
                  variant="outlined"
                  className="user-header"
                />
              ) : (
                <Chip
                  icon={<CheckIcon />}
                  label="Approved"
                  color="success"
                  variant="outlined"
                  className="user-header"
                />
              )}
            </Stack>
          </Grid>

          <Grid
            item
            md={6}
            sx={12}
            sm={12}
            lg={6}
            className="userdetail-header-grid"
          >
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Button
                variant="outlined"
                className="user-edit cancle-hover"
                onClick={() => {
                  // handleClickEdit(props?.props?.userDetails);
                  handleClickEdit(props?.userDetails);
                }}
              >
                <BorderColorIcon sx={{ mr: 1 }} /> Edit
              </Button>

              {/* <Button variant="outlined" className='user-cancel cancle-hover'>
                            <CloseIcon sx={{ mr: 1 }} /> Disapprove
                        </Button> */}
              {userData?.approved === true ? (
                <Button
                  variant="outlined"
                  className="user-cancel cancle-hover"
                  onClick={() => setOpen(true)}
                >
                  <CloseIcon sx={{ mr: 1 }} /> Disapprove
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className="user-success cancle-hover"
                  onClick={() => setOpen(true)}
                >
                  <CloseIcon sx={{ mr: 1 }} /> Approve
                </Button>
              )}

              <Link to="/user-list" className="submenu-item">
                <Button variant="outlined" className="done-btn cancle-hover">
                  <CheckIcon sx={{ mr: 1 }} /> Done
                </Button>
              </Link>
              <div>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>Advance Checklist Wise Report:</strong>
                      <p>
                        Status: Display's selected checklist completion status,
                        completion percentage & average compliance scores for
                        the selected user and stores.
                      </p>
                      <p>
                        Percentage: Display's selected checklist completion
                        percentage & average compliance scores for the selected
                        user and stores.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      marginTop: "8px",
                      marginRight: "8px",
                      marginLeft: "2px",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle
              sx={{
                fontSize: "30px",
              }}
            >{`Confirm ${approve ? "Approve" : "Disapprove"}`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Are you sure to ${approve ? "Approve" : "Disapprove"
                  } this user?`}
              </DialogContentText>
              <Box
                style={{
                  marginTop: "10px",
                }}
              >
                <DialogContentText>Comment :-</DialogContentText>
                <TextareaAutosize
                  size="small"
                  id="comment"
                  variant="outlined"
                  name="comment"
                  aria-label="minimum height"
                  minRows={5}
                  style={{
                    width: "100%",
                    padding: "18.5px 14px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                  sx={{
                    width: "100%",
                    padding: "18.5px 14px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                  maxLength={200}
                  fullwidth
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleApprove();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
