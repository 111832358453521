import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Card,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditFormHead from "./EditFormHead";
import RuleSection from "./RulesSection";
import Edit from "../../../images/Edit.svg";
import Delete from "../../../images/Delete.svg";

const EditAllForm = () => {
  const listData = [
    {
      key: "1",
      name: "Diamonds stock report tallied.",
      type: "Boolean",
      Validations: {
        RecordAudio: "Yes",
        TakeImage: "Yes",
        AlwaysallowComment: "Yes",
        Isimageoptional: "Yes",
      },
    },
    {
      key: "2",
      name: "Diamonds stock report tallied.",
      type: "Boolean",
      Validations: {
        RecordAudio: "Yes",
        TakeImage: "Yes",
        AlwaysallowComment: "Yes",
        Isimageoptional: "Yes",
      },
    },
  ];
  const kpiData = [
    {
      key: "1",
      name: "KPI Title",
      KPIChannel: "Traditional",
      Weight: "27",
      Dataused: "Questions",
    },
    {
      key: "2",
      name: "KPI Title",
      KPIChannel: "Traditional",
      Weight: "27",
      Dataused: "Questions",
    },
  ];

  return (
    <>
      <EditFormHead />
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card"
      >
        <Container>
          <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
            <Grid item xs={12}>
              <Typography>
                <b>Form Question</b>
              </Typography>
              <Button className="add-question">
                <AddOutlinedIcon sx={{ mr: 1 }} />
                Add Question
              </Button>
              <div>
                {listData.map((item, i) => (
                  <Card
                    key={i}
                    sx={{
                      mb: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ul style={{ listStyle: "none" }}>
                      <li className="list-form">
                        <b>
                          {item.key} {item.name}
                        </b>
                      </li>
                      <li className="list-form">
                        Type: <b>{item.type}</b>
                      </li>
                      <li className="list-form" style={{ display: "flex" }}>
                        Validations:
                        <Box className="Validations-box">
                          <Typography>Record Audio: </Typography>{" "}
                          <b>{item?.Validations?.RecordAudio}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography>Take Image: </Typography>{" "}
                          <b>{item?.Validations?.TakeImage}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography>Always allow Comment: </Typography>{" "}
                          <b>{item?.Validations?.AlwaysallowComment}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography>Is image optional?: </Typography>{" "}
                          <b>{item?.Validations?.Isimageoptional}</b>
                        </Box>
                      </li>
                    </ul>
                    <Box>
                      <Box>
                        <img
                          src={Edit}
                          alt="Edit"
                          style={{ padding: "0 5px", height: "30px" }}
                        />
                      </Box>
                      <Box>
                        <img
                          src={Delete}
                          alt="Delete"
                          style={{ padding: "0 5px", height: "30px" }}
                        />
                      </Box>
                    </Box>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ textAlign: "left" }}>
            <Grid item xs={12}>
              {/* <Typography>
                <b>KPIs</b>
              </Typography>
              <Stack direction="row" spacing={1} className="list-btn">
                <Button className="add-question">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Select KPI
                </Button>
                <Button className="add-question">Create New KPI</Button>
                <Button className="add-question">Manage All KPIs</Button>
              </Stack> */}
              <div>
                {kpiData.map((item, i) => (
                  <Card
                    sx={{
                      mb: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <ul style={{ listStyle: "none" }}>
                      <li className="list-form">
                        <b>
                          {item.key} {item.name}
                        </b>
                      </li>
                      <li className="list-form" style={{ display: "flex" }}>
                        <Box className="Validations-box">
                          <Typography>KPI Channel: </Typography>{" "}
                          <b>{item?.KPIChannel}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography>Weight / Points: </Typography>{" "}
                          <b>{item?.Weight}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography>Data used from: </Typography>{" "}
                          <b>{item?.Dataused}</b>
                        </Box>
                      </li>
                    </ul>
                    <Box>
                      <Box>
                        <img
                          src={Edit}
                          alt="Edit"
                          style={{ padding: "0 5px", height: "30px" }}
                        />
                      </Box>
                      <Box>
                        <img
                          src={Delete}
                          alt="Delete"
                          style={{ padding: "0 5px", height: "30px" }}
                        />
                      </Box>
                    </Box>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
          <RuleSection />
        </Container>
      </Box>
    </>
  );
};

export default EditAllForm;
