import {
  LIST_WASTE_MANAGEMENT_REQUEST,
  LIST_WASTE_MANAGEMENT_SUCCESS,
  LIST_WASTE_MANAGEMENT_ERROR,
  VIEW_WASTE_MANAGEMENT_REQUEST,
  VIEW_WASTE_MANAGEMENT_SUCCESS,
  VIEW_WASTE_MANAGEMENT_ERROR,
  WASTE_PRODUCT_LIST_REQUEST,
  WASTE_PRODUCT_LIST_SUCCESS,
  WASTE_PRODUCT_LIST_ERROR,
  WASTE_UOM_LIST_REQUEST,
  WASTE_UOM_LIST_SUCCESS,
  WASTE_UOM_LIST_ERROR,
  WASTE_CATEGORY_LIST_REQUEST,
  WASTE_CATEGORY_LIST_SUCCESS,
  WASTE_CATEGORY_LIST_ERROR,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  EDIT_CATEGORY_REQUEST,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_ERROR,
  ADD_UOM_REQUEST,
  ADD_UOM_SUCCESS,
  ADD_UOM_ERROR,
  EDIT_UOM_REQUEST,
  EDIT_UOM_SUCCESS,
  EDIT_UOM_ERROR,
  EXPORT_PRODUCT_REQUEST,
  EXPORT_PRODUCT_SUCCESS,
  EXPORT_PRODUCT_ERROR,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  data: [],
  viewData: [],
  productListData: [],
  categoryList: [],
  uomList: [],
  addProductData: [],
  editProductData: [],
  addCategoryData: [],
  editCategoryData: [],
  addUomData: [],
  editUomData: [],
  exportData: [],
};

const WasteReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_WASTE_MANAGEMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LIST_WASTE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        loading: false,
      };
    }
    case LIST_WASTE_MANAGEMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case VIEW_WASTE_MANAGEMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case VIEW_WASTE_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        viewData: action?.payload,
        loading: false,
      };
    }
    case VIEW_WASTE_MANAGEMENT_ERROR: {
      return {
        ...state,
        loading: false,
        viewData: [],
        error: action.error,
      };
    }
    case WASTE_PRODUCT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case WASTE_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productListData: action?.payload,
        loading: false,
      };
    }
    case WASTE_PRODUCT_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        productListData: [],
        error: action.error,
      };
    }
    case WASTE_CATEGORY_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case WASTE_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        categoryList: action?.payload,
        loading: false,
      };
    }
    case WASTE_CATEGORY_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        categoryList: [],
        error: action.error,
      };
    }
    case WASTE_UOM_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case WASTE_UOM_LIST_SUCCESS: {
      return {
        ...state,
        uomList: action?.payload,
        loading: false,
      };
    }
    case WASTE_UOM_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        uomList: [],
        error: action.error,
      };
    }
    case ADD_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_PRODUCT_SUCCESS: {
      return {
        ...state,
        addProductData: action?.payload,
        loading: false,
      };
    }
    case ADD_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        addProductData: [],
        error: action.error,
      };
    }
    case EDIT_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_PRODUCT_SUCCESS: {
      return {
        ...state,
        editProductData: action?.payload,
        loading: false,
      };
    }
    case EDIT_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        editProductData: [],
        error: action.error,
      };
    }
    case ADD_CATEGORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_CATEGORY_SUCCESS: {
      return {
        ...state,
        addCategoryData: action?.payload,
        loading: false,
      };
    }
    case ADD_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        addCategoryData: [],
        error: action.error,
      };
    }
    case EDIT_CATEGORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        editCategoryData: action?.payload,
        loading: false,
      };
    }
    case EDIT_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        editCategoryData: [],
        error: action.error,
      };
    }
    case ADD_UOM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ADD_UOM_SUCCESS: {
      return {
        ...state,
        addUomData: action?.payload,
        loading: false,
      };
    }
    case ADD_UOM_ERROR: {
      return {
        ...state,
        loading: false,
        addUomData: [],
        error: action.error,
      };
    }
    case EDIT_UOM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_UOM_SUCCESS: {
      return {
        ...state,
        editUomData: action?.payload,
        loading: false,
      };
    }
    case EDIT_UOM_ERROR: {
      return {
        ...state,
        loading: false,
        editUomData: [],
        error: action.error,
      };
    }
    case EXPORT_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EXPORT_PRODUCT_SUCCESS: {
      return {
        ...state,
        exportData: action?.payload,
        loading: false,
      };
    }
    case EXPORT_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        exportData: [],
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default WasteReducer;
