import JwtAuthService from "../apiConfig/ApiConfig";
import {
  CSV_NAM_SUBMISSION_REQUEST,
  CSV_NAM_SUBMISSION_SUCCESS,
  CSV_NAM_SUBMISSION_ERROR,
  CSV_USER_SUBMISSION_REQUEST,
  CSV_USER_SUBMISSION_SUCCESS,
  CSV_USER_SUBMISSION_ERROR
} from "../types/Types";

export const CsvNamSubmissionListRequest = () => ({
  type: CSV_NAM_SUBMISSION_REQUEST,
});
export const CsvNamSubmissionListSuccess = (list) => ({
  type: CSV_NAM_SUBMISSION_SUCCESS,
  payload: list,
});
export const CsvNamSubmissionListFailed = (error) => ({
  type: CSV_NAM_SUBMISSION_ERROR,
  payload: error,
});

export const CsvNamSubmissionListApi = (data) => (dispatch) => {
  try {
    dispatch(CsvNamSubmissionListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CsvNamSubmissionListSuccess(response));
      } else {
        dispatch(CsvNamSubmissionListFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CsvNamSubmissionListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};

export const CsvUserSubmissionListRequest = () => ({
  type: CSV_USER_SUBMISSION_REQUEST,
});
export const CsvUserSubmissionListSuccess = (list) => ({
  type: CSV_USER_SUBMISSION_SUCCESS,
  payload: list,
});
export const CsvUserSubmissionListFailed = (error) => ({
  type: CSV_USER_SUBMISSION_ERROR,
  payload: error,
});

export const CsvUserSubmissionListApi = (data) => (dispatch) => {
  try {
    dispatch(CsvUserSubmissionListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(CsvUserSubmissionListSuccess(response));
      } else {
        dispatch(CsvUserSubmissionListFailed(response?.data?.message));
        toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CsvUserSubmissionListFailed(error));
    console.error("Something went Wrong", error);
    toast.error(error);
  }
};