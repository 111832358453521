/** @format */

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ViewBroadcastApi } from "../../redux/actions/BroadcastMessage";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, TextField, Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import moment from "moment";
import ViewHeader from "./ViewHeader";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1450,
    margin: "auto",
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: "530px",
  },

  header: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  subject: {
    fontWeight: theme.typography.fontWeightBold,
    flexGrow: 1,
  },
  dateTime: {
    marginLeft: "auto",
    color: theme.palette.text.secondary,
  },
  manager: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  managerAvatar: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  copyright: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const BMView = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [detailedData, setDetailedData] = useState([]);
  const [content, setContent] = useState("");
  const [loader, setLoader] = useState(true);
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const plainTextContent = stripHtmlTags(content);

  // const message = detailedData?.message;
  // const content = message ? message.slice(19, -6) : "No message available";

  const getBroadCastDetails = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.details &&
      state.broadcast.details.data &&
      state.broadcast.details.data.data
  );

  const Loader = useSelector(
    (state) => state.broadcast && state.broadcast.loading
  );

  useEffect(() => {
    // setLoader(true);
    setDetailedData(getBroadCastDetails);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [getBroadCastDetails]);

  // useEffect(() => {
  //   if (detailedData && detailedData.message) {
  //     const parsedMessage = JSON.parse(detailedData.message);
  //     if (parsedMessage.blocks) {
  //       setContent(parsedMessage.blocks[0].text);
  //     } else if (parsedMessage.ops) {
  //       setContent(parsedMessage.ops[0].insert.replace("\\n", ""));
  //     }
  //   }
  // }, [detailedData]);

  useEffect(() => {
    if (detailedData && detailedData.message) {
      const parsedMessage = JSON.parse(detailedData.message);

      if (parsedMessage.blocks && parsedMessage.blocks.length > 0) {
        const concatenatedText = parsedMessage.blocks
          .map((block) => block.text)
          .join(" ");
        setContent(concatenatedText.replace(/<\/?p>/g, ""));
      } else if (parsedMessage.ops && parsedMessage.ops.length > 0) {
        const rawContent = parsedMessage.ops[0].insert.replace("\\n", "");
        const strippedContent = new DOMParser().parseFromString(
          rawContent,
          "text/html"
        ).body.textContent;
        setContent(strippedContent);
      } else {
        setContent("Content not available");
      }
    }
  }, [detailedData]);

  useEffect(() => {
    viewBroadcast();
  }, []);

  const viewBroadcast = () => {
    const _id = localStorage.getItem("companyId");
    const id = location?.state?._id;
    const data = {
      url: BASE_URL + `broadcast/${id}?company_id=${_id}`,
    };
    dispatch(ViewBroadcastApi(data));
  };

  return (
    <>
      <ViewHeader />
      {/* {Loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <Progressbar />
        </div> */}
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <div className={classes.header}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <EmailIcon className={classes.icon} />
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.subject}
                  style={{ marginLeft: "10px", textAlign: "left" }}
                >
                  {detailedData?.subject || "N/A"}
                </Typography>
              </div>

              <Typography
                variant="body2"
                component="p"
                className={classes.dateTime}
                style={{ display: "flex", alignItems: "center" }}
              >
                <WatchLaterIcon style={{ marginRight: "5px" }} />
                {moment(detailedData?.createdAt).format(
                  "MMM D, YYYY, h:mm:ss A"
                )}
              </Typography>
            </div>
            <div
              className={classes.manager}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography
                variant="h6"
                style={{
                  marginLeft: "5px",
                  marginTop: "3px",
                  marginRight: "0",
                  textAlign: "left",
                  text: "bold",
                }}
              >
                To:
              </Typography>
              <Box
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  flexGrow: 1,
                  marginLeft: "10px",
                }}
              >
                <Typography variant="body1" component="p">
                  {detailedData?.role?.map((role, index) => (
                    <Box
                      key={index}
                      className="Author"
                      style={{
                        backgroundColor: "skyblue",
                        color: "white",
                        marginBottom: "5px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      {role}
                    </Box>
                  ))}
                </Typography>
              </Box>
            </div>

            <TextField
              variant="outlined"
              placeholder="Enter content here"
              multiline
              rows={10}
              fullWidth
              className={classes.content}
              readOnly
              value={plainTextContent}
            />
          </CardContent>

          <Typography
            variant="body2"
            component="p"
            className={classes.copyright}
          >
            Copyright © HOPE RESEARCH GROUP. All Right Reserved.
          </Typography>
        </Card>
      )}
    </>
  );
};

export default BMView;
