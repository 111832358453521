import React, { useState } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Header = () => {
  const [value, setValue] = useState(0);

  const handleChangeTabs = ( newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card className="header-card top-margin">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={15} md={6} lg={6} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                icon={<p className="active-tabs-content">28</p>}
                iconPosition="end"
                className="active-tabs tabs-content"
              />
              <Tab
                label="Archived"
                icon={<p className="active-tabs-content">8</p>}
                iconPosition="end"
                className="Archived-tabs tabs-content"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack direction="row" className="Assign-Checklist" spacing={1} sx={{ float: "right" }}>
              <SearchAppBar />
              <Link to="/broadcastMessage" className="submenu-item">
                <Button className="header-add">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add Customer
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Header;

export const ViewHeader = () => {

  return (
      <>
          <Grid
              container
              sx={{
                  bgcolor: "#F6F8FC",
                  width: "100%",
                  borderRadius: "8px",
                  mt: 1,
                  p: 1,
              }}
              alignItems="center"
              className="top-margin"
          >
              <Grid item md={6} sx={12} sm={12} lg={6}>
                  <Stack direction="row" spacing={1} sx={{ pl: 2 }}>
                      <Typography>Created at :<strong>10th January 2020</strong></Typography>
                      <Typography>Updated at :<strong>10th January 2020</strong></Typography>
                  </Stack>
              </Grid>

              <Grid item md={6}>
                  <Stack direction="row" spacing={1} sx={{ float: "right", mr: 2 }}>
                      <Button variant="outlined" color="warning" className='cancle-hover'>
                          <BorderColorIcon sx={{ mr: 1 }} /> Edit
                      </Button>
                      <Button variant="outlined" color="error" className='cancle-hover'>
                          <DeleteOutlineIcon sx={{ mr: 1 }} /> Delete
                      </Button>
                      <Link to="" className="submenu-item">
                          <Button className='header-button cancle-hover'>
                              <AddIcon sx={{ mr: 1 }} />Assign User
                          </Button>
                      </Link>
                      <Link to="/customer" className="submenu-item">
                          <Button variant="outlined" className="done-btn">
                              <CheckIcon sx={{ mr: 1 }} /> Done
                          </Button>
                      </Link>
                  </Stack>
              </Grid>
          </Grid>
      </>
  );
};
