/** @format */

import React, { useEffect } from "react";
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Typography } from "material-ui-core";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";                            

const POHeader = ({ handleChangeSelect, monthRecord }) => {
  const handleBackClick = () => {
    window.history.back();
  };

  useEffect(() => {
    localStorage.setItem("monthRecord", JSON.stringify(monthRecord));
  }, [monthRecord]);

  return (
    <div>
      <Card className="header-card">
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <IconButton
              onClick={handleBackClick}
              sx={{ fontSize: "20px", color: "#333" }}
            >
              <ArrowBackIcon sx={{ fontSize: "24px" }} />
              <Typography variant="button" sx={{ marginLeft: "8px" }}>
                Back
              </Typography>
            </IconButton>
          </Grid>
          <Grid item xs={9} sm={10} md={10} lg={10}>
            <Typography
              align="left"
              variant="h5"
              component="h4"
              style={{ padding: "4px", marginLeft: "1%" }}
            >
              Company Information & Performance
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Stack direction="row" justifyContent="flex-end">
              <FormControl
                sx={{ minWidth: 150 }}
                size="small"
                style={{paddingRight:10}}
                className="location-storeinfo"
              >
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={monthRecord}
                  onChange={handleChangeSelect}
                  style={{ borderRadius: "8px" }}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="OneDay" defaultValue={"Last One Day"}>
                    Last One Day
                  </MenuItem>
                  <MenuItem value="OneWeek">Last One Week </MenuItem>
                  {/* <MenuItem disabled value="OneMonth">
              Last One Month
            </MenuItem> */}
                  <MenuItem value="ThreeMonth">Last 3 Month</MenuItem>
                  <MenuItem value="SixMonth">Last 6 Month</MenuItem>
                </Select>
              </FormControl>
              <div style={{marginTop:"7px",marginRight:"10px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default POHeader;
