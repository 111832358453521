import { CUSTOMER_LOG_NO_PAGI_ERROR, CUSTOMER_LOG_NO_PAGI_REQUEST, CUSTOMER_LOG_NO_PAGI_SUCCESS, CUSTOMER_LOG_STATUS_ERROR, CUSTOMER_LOG_STATUS_REQUEST, CUSTOMER_LOG_STATUS_SUCCESS, CUSTOMER_LOG_VIEW_ERROR, CUSTOMER_LOG_VIEW_REQUEST, CUSTOMER_LOG_VIEW_SUCCESS, DELETE_CUSTOMER_LOG_ERROR, DELETE_CUSTOMER_LOG_REQUEST, DELETE_CUSTOMER_LOG_SUCCESS, LIST_CUSTOMER_LOG_ERROR, LIST_CUSTOMER_LOG_REQUEST, LIST_CUSTOMER_LOG_SUCCESS } from "../types/Types";


const initialState = {
    loading: false,
    error: '',
    data: [],
    updateData:[],
    deleteData: '',
    statusChangeData: [],
};


const CustomerLogsReducer = (state = initialState, action) => {
    // console.log(action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.customerLog        ,'action set reducer')
    switch (action.type) {
        case LIST_CUSTOMER_LOG_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }   
        }
        case LIST_CUSTOMER_LOG_SUCCESS: {
            return {
                ...state,
                data: action?.payload,
                loading: false
            }
        }
        case LIST_CUSTOMER_LOG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }
        // customer log view 
        case CUSTOMER_LOG_VIEW_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }
        }
        case CUSTOMER_LOG_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action?.payload
            }
        }
        case CUSTOMER_LOG_VIEW_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }


        // customer log without pagination 
        case CUSTOMER_LOG_NO_PAGI_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }   
        }
        case CUSTOMER_LOG_NO_PAGI_SUCCESS: {
            return {
                ...state,
                // updateData: action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.customerLog,
                updateData: action?.payload,
                loading: false
            }
        }
        case CUSTOMER_LOG_NO_PAGI_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        // customer log delete
        case DELETE_CUSTOMER_LOG_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }   
        }
        case DELETE_CUSTOMER_LOG_SUCCESS: {
            return {
                ...state,
                deleteData: action?.payload,
                loading: false
            }
        }
        case DELETE_CUSTOMER_LOG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        // customer log status cgange
        case CUSTOMER_LOG_STATUS_REQUEST: {
            return {
                ...state,
                loading: true,
                error: ''
            }   
        }
        case CUSTOMER_LOG_STATUS_SUCCESS: {
            return {
                ...state,
                statusChangeData: action?.payload,
                loading: false
            }
        }
        case CUSTOMER_LOG_STATUS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            }
        }

        default:
            return state;
    }
}

export default CustomerLogsReducer;