/** @format */

import React from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DepartmentDetailsApi } from "../../redux/actions/Department";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useEffect } from "react";
// import { ToastContainer } from "react-toastify";
import DoneIcon from "@mui/icons-material/Done";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const DepartmentDetails = () => {
  const [loader, setLoader] = React.useState(true);

  const getDepartmentDetails = useSelector(
    (state) =>
      state.department &&
      state.department.details &&
      state.department.details.data &&
      state.department.details.data.data
  );

  // const Loader = useSelector(
  //   (state) => state.department && state.department.loading
  // );

  const dispatch = useDispatch();
  const location = useLocation();
  const [detailedData, setDetailedData] = React.useState([]);

  useEffect(() => {
    setDetailedData(getDepartmentDetails);
  }, [getDepartmentDetails]);

  useEffect(() => {
    // setLoader(true);
    departmentDetails();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [dispatch]);

  const departmentDetails = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state;
    const data = { url: BASE_URL + `department/${userId}?company_id=${_id}` };
    dispatch(DepartmentDetailsApi(data));
  };

  return (
    <Box>
      <Box className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/department" className="submenu-item">
                <Button className="header-add cancle-hover" style={{height:"40px"}}>
                  <DoneIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Link>
              <div style={{marginRight:"8px",marginTop:"5px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {/* <ToastContainer /> */}
      {/* Loading will be start here */}
      {/* {Loader ? ( */}
      {/* <Progressbar /> */}
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
          <Grid>
            <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card sx={{ p: 1, m: 1, height: "100%", borderRadius: "8px" }}>
                  <Typography
                    variant="h5"
                    component="p"
                    align="left"
                    sx={{ ml: 1, my: 2, fontWeight: "bold" }}
                  >
                    Department Details
                  </Typography>
                  <Table className="priroty-table">
                    <TableBody>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Department Name:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Manager's Name:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptManager}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Manager's Email Id:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.managerEmail}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Manager's Mobile No:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {" "}
                            {detailedData?.managerMobileNumber}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Department Address:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptAddr}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          State Name:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptState}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          City Name:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptCity}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Country Name:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptCountry}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ display: "flex" }}>
                        <TableCell className="header-table">
                          Pin Code:
                        </TableCell>
                        <TableCell>
                          <Typography variant="p" component="b">
                            {detailedData?.deptPinCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default DepartmentDetails;
