import React, { useEffect, useState } from "react";
import { Card, Typography, CardContent, Button, Divider } from "@mui/material";
import Chart from "react-apexcharts";
import ToggleSwitch from "../../../helpers/ToggleSwitch";
import { Stack, Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as moment from "moment";
import { LoaderComponent } from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

let options = {
  colors: ["#F39A4C", "#039855", "#BB251A", "#D35400"],
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 0.5,
    colors: ["black"],
  },
  xaxis: {
    //   categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
  },
  yaxis: {
    title: {
      text: "Checklist Last Seven Days",
    },
  },
  fill: {
    opacity: 1,
  },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return "$ " + val + " thousands"
  //         }
  //       }
  //   },
};

// const formateDate = (date) => {
//     let newDate = new Date(date);
//     newDate.setDate(newDate.getDate() - 1);
//     return `${newDate.getDay()}-${newDate.getMonth()}-${newDate.getFullYear()}`;
// }
const ChecklistGraph = () => {
  const checklistReportRes = useSelector(
    (state) =>
      state &&
      state?.dashboardReducer &&
      state?.dashboardReducer?.checklistData &&
      state?.dashboardReducer?.checklistData?.data &&
      state?.dashboardReducer?.checklistData?.data?.data?.ResponseData
  );
  const [optionOne, setOptionOne] = useState(options);
  const [optionTwo, setOptionTwo] = useState(options);
  const [chartData, setChartData] = useState([]);
  const [weitageChartData, setweitageChartData] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checklistReportRes) {
      let series = [
        {
          name: "Created",
          data: [],
        },
        {
          name: "Submitted",
          data: [],
        },
        {
          name: "InProgress",
          data: [],
        },
        {
          name: "Pending",
          data: [],
        },
      ];
      let categoryData = [];
      for (let item of checklistReportRes) {
        // let convertedDate = formateDate(item.Date);
        let split = item.Date.split("T");
        let date = split.shift();
        // let convertedDate = moment(item.Date).format("DD-MM-YY");
        categoryData.push(date);
        series[0].data.push(item.assigned || 0);
        series[1].data.push(item.completed || 0);
        series[2].data.push(item.partiallyFilled);
        series[3].data.push(item.remaining);
      }
      console.log("series", series);
      setOptionOne((options) => ({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: categoryData,
        },
      }));
      setChartData(series);
    } else {
      let series = [
        {
          name: "Created",
          data: [0,0,0],
        },
        {
          name: "Submitted",
          data: [0,0,0],
        },
        {
          name: "InProgress",
          data: [0,0,0],
        },
        {
          name: "Pending",
          data: [0,0,0],
        },
      ];
      setChartData(series);
    }
  }, [checklistReportRes]);

  // WEItageScre data Handle

  useEffect(() => {
    if (checklistReportRes) {
      let weitageSeries = [
        {
          name: "Overall Target score",
          data: [],
        },
        {
          name: "compliance Score",
          data: [],
        },
        {
          name: "Non-compliance score",
          data: [],
        },
      ];
      let weitageCategoryData = [];
      let colors = ["#D35400", "#039855", "#BB251A"];
      for (let item of checklistReportRes) {
        // let convertedDate = formateDate(item.Date);
        let split = item.Date.split("T");
        let date = split.shift();
        // let convertedDate = moment(item.Date).format("DD-MM-YY");
        weitageCategoryData.push(date);
        weitageSeries[0]?.data.push(item.TotalComplianceScore);
        weitageSeries[1]?.data.push(item.ComplianceScore);
        weitageSeries[2]?.data.push(item.RemaningComplianceScore);
      }
      setOptionTwo((options) => ({
        ...options,
        colors: colors,
        xaxis: {
          ...options.xaxis,
          categories: weitageCategoryData,
        },
      }));
      setweitageChartData(weitageSeries);
    } else {
      let weitageSeries = [
        {
          name: "Overall Target score",
          data: [0,0,0],
        },
        {
          name: "compliance Score",
          data: [0,0,0],
        },
        {
          name: "Non-compliance score",
          data: [0,0,0],
        },
      ];
      setweitageChartData(weitageSeries);
    }
  }, [checklistReportRes]);

  const handleChangeSwich = (e) => {
    setChecked(!checked);
  };

  return (
    <Card sx={{ height: "100%" }} className="card">
      <CardContent sx={{ p: 4 }} className="check-graph">
      <div style={{float:"right"}}>
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Checklists Status :</strong>
                            <p>This section displays the bar graph of all the 
                              checklists that we Created, Submitted, In Progress, 
                              Pending in the last seven days VS the number of checklists.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
        <Typography variant="p" component="div" className="heading">
          {checked ? "Checklist Compliance Status" : "  Checklists Status"}
        </Typography>
        <Typography
          variant="p"
          component="div"
          className="heading"
          direction="row"
          sx={{ float: "right" }}
          style={{ marginTop: "-33px" }}
        >
          <FormGroup>
            {/* <FormControlLabel
              value="start"
              control={<Switch color="warning" onChange={handleChangeSwich} />}
              label={checked ? "compliance score" : "Start"}
              labelPlacement="start"
            /> */}
          </FormGroup>
        </Typography>
        {checked === true ? (
          !weitageChartData.length ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoaderComponent play />
            </div>
          ) : (
            <Chart
              options={optionTwo}
              series={weitageChartData}
              type="bar"
              height={"458px"}
            />
          )
        ) : !chartData.length ? (
          <div
            style={{
              height: 'inherit',
              width: 'inherit',
              position: "relative"
            }}
          >
            <LoaderComponent play />
          </div>
        ) : (
          <Chart
            options={optionOne}
            series={chartData}
            type="bar"
            height={"458px"}
          />
        )}

        <Box
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "28px",
          }}
        >
          {checked === true ? (
            <Box sx={{ display: "flex" }} className="display">
              {/* <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Link
                    to="/checklist/submission"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Completion Score
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#039855",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box> */}
              {/* <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="/checklist/all-checklist"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Non-compliance score
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#BB251A",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box>
              <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                    justifyContent: "right",
                  }}
                >
                  <Link
                    to="/checklist/submission"
                    style={{ textDecoration: "none" }}
                    state={{ InProgress: "InProgress" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Overall Target score
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#F39A4C",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box> */}
            </Box>
          ) : (
            <Box sx={{ display: "flex" }} className="display">
              {/* <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Link
                    to="/checklist/submission"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Created
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#F39A4C",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box>
              <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="/checklist/all-checklist"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Submitted
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#039855",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box>
              <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                    justifyContent: "right",
                  }}
                >
                  <Link
                    to="/checklist/submission"
                    style={{ textDecoration: "none" }}
                    state={{ InProgress: "InProgress" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      InProgress
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#BB251A",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box>
              <Box sx={{ mx: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: "5px",
                    justifyContent: "right",
                  }}
                >
                  <Link
                    to="/checklist/submission"
                    style={{ textDecoration: "none" }}
                    state={{ Pending: "Pending" }}
                  >
                    <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Pending
                    </Typography>
                  </Link>
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "#D35400",
                    height: "4px",
                    borderRadius: "50px",
                  }}
                />
              </Box> */}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="display"
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
                pl: 1,
                mr: 5,
                justifyContent: "right",
              }}
            ></Stack>
            <FormControlLabel
              value="start"
              control={<Switch color="warning" onChange={handleChangeSwich} />}
              label={
                checked ? "View Completion Status" : " View Compliance Status"
              }
              labelPlacement="start"
            />
            <div style={{float:"right"}}>
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong> Checklists Compliance Status:</strong>
                            <p>To view the bar graph of compliance score for the last seven 
                              days you have to click on the toggle switch in the checklists status section.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            {/* <Link
              to="/checklist/all-checklist"
              variant="outlined"
              size="small"
              className="graph-button"
            >
              Show Table
            </Link> */}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ChecklistGraph;
