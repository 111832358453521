/** @format */

import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import POHeader from "./POHeader";
import { TrainingDetailsApi } from "../../../redux/actions/LmsAction";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import ReactExport from "react-export-excel-xlsx-fix";
import { ToastContainer } from "react-toastify";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LastPage as LastPageIcon,
} from "@material-ui/icons";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const LMSReportView = () => {
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const [ProgressData, setProgressData] = useState([]);
  const [searchedProgressData, setSearchedProgressData] = useState([]);
  const [monthRecord, setmonthRecord] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [cSVNamSubmissionsData, setCSVNamSubmissionsData] = React.useState([]);
  const [loader, setLoader] = useState(false);

  const allTrainingProgress = useSelector(
    (state) =>
      state.lms &&
      state.lms.detailsTrainee &&
      state.lms.detailsTrainee.data &&
      state.lms.detailsTrainee.data.data
  );
  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const isDataLoading = Loader && ProgressData.length === 0;
  const hasData = ProgressData.length > 0;

  useEffect(() => {
    const storedMonthRecord = localStorage.getItem("monthRecord");
    if (storedMonthRecord) {
      setmonthRecord(JSON.parse(storedMonthRecord));
    } else {
      setmonthRecord("OneDay");
    }
  }, []);

  console.log("monthRecord", monthRecord);
  useEffect(() => {
    if (allTrainingProgress) {
      setLoader(true);
      setProgressData(allTrainingProgress);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [allTrainingProgress]);

  useEffect(() => {
    if (allTrainingProgress) {
      const transformedData = allTrainingProgress.flatMap((item) =>
        item.assessment.map((assessmentItem) => ({
          ...item,
          assessmentItem,
        }))
      );
      const separateRows = transformedData.map((item) => ({
        ...item,
        assessment: [item.assessmentItem],
      }));
      setProgressData(separateRows);
    }
  }, [allTrainingProgress]);

  useEffect(() => {
    individualList();
  }, []);

  const individualList = (searchValue = "", monthRecord = "") => {
    if (monthRecord) {
      const id = localStorage.getItem("companyId");
      const trainingId = state?.TrainingId;
      const data = {
        url: `${BASE_URL}progress/${trainingId}?company_id=${id}&search=${searchValue}&filter=${monthRecord}&type=${"report"}`,
      };
      dispatch(TrainingDetailsApi(data));
    }
  };

  // console.log("LMSReportView", ProgressData);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      renderHeader: () => <strong>{"Name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.user?.name) {
          result.push(params?.row?.user?.name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const content = params.value || "";
        return (
          <Tooltip title={content} arrow>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "Store",
      headerName: "Store",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.store?.title) {
          result.push(params?.row?.store?.title);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const content = params.value || "";
        return (
          <Tooltip title={content} arrow>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "Role	",
      headerName: "Role",
      renderHeader: () => <strong>{"Role"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.role) {
          result.push(params?.row?.role);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const content = params.value || "";
        return (
          <Tooltip title={content} arrow>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "Training	",
      headerName: "Training",
      renderHeader: () => <strong>{"Training"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.trainingTitle) {
          result.push(params?.row?.trainingTitle);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
      renderCell: (params) => {
        const content = params.value || "";
        return (
          <Tooltip title={content} arrow>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const status = params?.row?.assessmentItem?.pass;
        return status ? (
          <Tooltip title={params?.row?.completedPercentage.toFixed(2)} arrow>
            <CheckCircle style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip title={params?.row?.completedPercentage.toFixed(2)} arrow>
            <Cancel style={{ color: "red" }} />
          </Tooltip>
        );
      },
      renderCell: (params) => {
        const status = params?.row?.assessmentItem?.pass;
        let result = "";
        if (params?.row?.assessmentItem?.submitTime) {
          result += `Completed :${params?.row?.completedPercentage.toFixed(
            2
          )}%`;
        } else {
          result += "Completed : -";
        }

        if (params?.row?.assessmentItem?.score) {
          result += `\nScore :${params?.row?.assessmentItem?.score}`;
        } else {
          result += "\nScore : -";
        }

        if (params?.row?.assessmentProgress) {
          result += `\nAssessment Progress :${params?.row?.assessmentProgress.toFixed(
            2
          )}%`;
        } else {
          result += "\nAssessment Progress : -";
        }

        return status ? (
          <Tooltip
            title={<span style={{ whiteSpace: "pre-line" }}>{result}</span>}
            arrow
          >
            <CheckCircle style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip
            title={<span style={{ whiteSpace: "pre-line" }}>{result}</span>}
            arrow
          >
            <Cancel style={{ color: "red" }} />
          </Tooltip>
        );
      },
    },
    // {
    //   field: "Score",
    //   headerName: "Score",
    //   renderHeader: () => <strong>{"Score"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     const scores = params?.row?.assessmentItem?.score;
    //     if (Array.isArray(scores)) {
    //       return scores.join(", ") || "0";
    //     } else {
    //       return scores || "0";
    //     }
    //   },
    //   renderCell: (params) => {
    //     const scores = params?.row?.assessmentItem?.score;
    //     const content = Array.isArray(scores)
    //       ? scores.join(", ") || "0"
    //       : scores || "0";
    //     return (
    //       <Tooltip title={content} arrow>
    //         <span>{content}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "Submit at	",
      headerName: "Submit At",
      renderHeader: () => <strong>{"Submit At"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = "";
        if (params?.row?.assessmentItem?.submitTime) {
          result = moment(params?.row?.assessmentItem?.submitTime).format(
            "MMMM D, YYYY hh:mm"
          );
        } else {
          result = "-";
        }
        return result;
      },
      renderCell: (params) => {
        let result = "";
        if (params?.row?.assessmentItem?.submitTime) {
          result = moment(params?.row?.assessmentItem?.submitTime).format(
            "MMMM D, YYYY hh:mm"
          );
        } else {
          result = "-";
        }
        return (
          <Tooltip title={result} arrow>
            <span>{result}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "Assessment Title",
      headerName: "Assessment Title",
      renderHeader: () => <strong>{"Assessment Title"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        const assessments = params?.row?.assessment;
        if (assessments && assessments.length > 0) {
          return assessments.map((assessment) => ({
            id: assessment._id,
            title: assessment.title,
          }));
        } else {
          return [{ id: "N/A", title: "N/A" }];
        }
      },
      renderCell: (params) => {
        const assessments = params.value;
        return (
          <div>
            {assessments.map((assessment) => (
              <div key={assessment.id}>
                <Tooltip title={assessment.title} arrow>
                  <span>{assessment.title}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        );
      },
    },

    // {
    //   field: "Assessment Progress",
    //   headerName: "Assessment Progress",
    //   renderHeader: () => <strong>{"Assessment Progress"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => {
    //     let result = [];
    //     if (params?.row?.assessmentProgress) {
    //       result.push(params?.row?.assessmentProgress.toFixed(2));
    //     } else {
    //       result = ["0.00"];
    //     }
    //     return result.join(", ") + "%";
    //   },
    //   renderCell: (params) => {
    //     const content = params.value || "";
    //     return (
    //       <Tooltip title={content} arrow>
    //         <span>{content}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "user name",
      headerName: "User Name",
      renderHeader: () => <strong>{"user name"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        var content = "N/A";
        params?.row?.assessmentItem.questions?.map((q) => {
          if (q.title.toLowerCase().trim() === "user name") {
            content = q.userAnswer;
          }
        });
        return content;
      },
      renderCell: (params) => {
        var content = "N/A";
        params?.row?.assessmentItem.questions?.map((q) => {
          if (q.title.toLowerCase().trim() === "user name") {
            content = q.userAnswer;
          }
        });
        return (
          <Tooltip title={content} arrow>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "man hours",
      headerName: "Man Hours",
      renderHeader: () => <strong>{"Man Hours"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        var content = "N/A";
        params?.row?.assessmentItem.questions?.map((q) => {
          if (q.title.toLowerCase().trim() === "man hours") {
            content = q.userAnswer;
          }
        });
        return content;
      },
      renderCell: (params) => {
        var content = "N/A";
        params?.row?.assessmentItem.questions?.map((q) => {
          if (q.title.toLowerCase().trim() === "man hours") {
            content = q.userAnswer;
          }
        });
        return (
          <Tooltip title={content} arrow>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
  ];

  const handleChangeSelect = (event) => {
    setmonthRecord(event.target.value);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchValue);
    }
  };

  useEffect(() => {
    individualList(searchValue, monthRecord);
  }, [monthRecord]);

  useEffect(() => {
    const getData = setTimeout(() => {
      const target = [];

      if (searchValue.trim()) {
        ProgressData.map((item) => {
          if (
            item.store.title
              .toLowerCase()
              .trim()
              .includes(searchValue.toLowerCase().trim()) ||
            item.user.name
              .toLowerCase()
              .trim()
              .includes(searchValue.toLowerCase().trim())
          ) {
            target.push(item);
          }

          item.assessment.map((assessment) => {
            if (
              assessment.title
                .toLowerCase()
                .trim()
                .includes(searchValue.toLowerCase().trim())
            ) {
              target.push(item);
            }
            return;
          });
          return;
        });
      }
      setpaginatedData([]);
      setSearchedProgressData(target);
    }, 2000);
    return () => clearTimeout(getData);
  }, [searchValue]);

  // console.log("ProgressData", ProgressData);
  const handleChangePage = (newPage) => {
    setpaginatedData([]);
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };
  const [paginatedData, setpaginatedData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [startRow, setstartRow] = useState(0);
  const [endRow, setendRow] = useState(0);
  const [totalItems, settotalItems] = useState(0);

  useEffect(() => {
    const total =
      (searchValue.trim() ? searchedProgressData : ProgressData)?.length || 0;
    const start = (currentPage - 1) * itemsPerPage;
    const end = Math.min(currentPage * itemsPerPage, total);
    settotalPages(Math.ceil(total / itemsPerPage));
    setstartRow(start);
    setendRow(end);
    settotalItems(total);

    if (searchValue.trim()) {
      setpaginatedData(searchedProgressData?.slice(start, end) || []);
    } else {
      setpaginatedData(ProgressData?.slice(start, end) || []);
    }
  }, [searchedProgressData, ProgressData, currentPage, itemsPerPage]);

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  useEffect(() => {
    let subArr = [];
    let columnListMan = [
      {
        value: "T NaME",
        widthPx: 140,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "S Name",
        widthPx: 100,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Total Users",
        widthPx: 110,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Man Hours",
        widthPx: 60,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Man Days",
        widthPx: 60,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
    ];
    let csvSubRowsMan = [];
    let columnList = [];
    let csvSubRows = [];
    let defaultColumn = [
      {
        value: "Training Name",
        widthPx: 140,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "User Name",
        widthPx: 100,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Store Name",
        widthPx: 110,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Role",
        widthPx: 60,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Assessment Title",
        widthPx: 140,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "(Pass/Fail)",
        widthPx: 60,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Score",
        widthPx: 50,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Submitted At",
        widthPx: 115,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
    ];

    columnList.push(...defaultColumn);
    let formattedSD = "";

    const getAllAssessmentId = [];
    const getAllManHours = {};
    const getAllStore = {};
    const getAllUsers = {};

    ProgressData &&
      ProgressData.map((item) => {
        item.assessment.map((assessment) => {
          getAllAssessmentId.push(assessment.assessmentId);
        });

        getAllStore[item.store._id] = item.store.title;

        if (getAllUsers[item.store._id]) {
          getAllUsers[item.store._id] += 1;
        } else {
          getAllUsers[item.store._id] = 1;
        }
      });
    var uniqueAssessmentID = getAllAssessmentId.filter(onlyUnique);

    uniqueAssessmentID.map((item) => {
      const dataSet = [];
      ProgressData &&
        ProgressData.map((training) => {
          let colRow = [];

          // const dataSet = [];

          training.assessment.map((assessment) => {
            if (assessment.assessmentId === item) {
              if (columnList.length === 8) {
                formattedSD =
                  assessment.title.length > 31
                    ? assessment.title.substr(0, 31)
                    : assessment.title;
                assessment.questions.map((question) => {
                  columnList.push({
                    value: question.title,
                    widthPx: 120,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                });
              }
              colRow.push(
                {
                  value: training.trainingTitle || "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: training.user.name || "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: training.store.title || "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: training.role || "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: assessment.title || "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: assessment.pass ? "Passed" : "Failed" || "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },
                {
                  value: 
                    assessment.score 
                      ? parseFloat(assessment.score).toFixed(2) 
                      : "0",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                },                                
                {
                  value:
                    `${moment(assessment.submitTime || "N/A").format("lll")}` ||
                    "",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "FFFF0000" },
                    },
                  },
                }
              );
              assessment.questions.map((question) => {
                // if (question.title.toLowerCase().trim() === "number") {
                if (question.title.toLowerCase().trim() === "man hours") {
                  if (getAllManHours[training.store._id]) {
                    getAllManHours[training.store._id] += parseInt(
                      question.userAnswer
                    );
                  } else {
                    getAllManHours[training.store._id] = parseInt(
                      question.userAnswer
                    );
                  }
                }
                if (question.type.key === "multi_select") {
                  colRow.push({
                    value:
                      question &&
                      question.userAnswer &&
                      question.userAnswer.length &&
                      question.userAnswer.map((ans) => ans.title).join(","),
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                if (question.type.key === "single_select") {
                  colRow.push({
                    value:
                      question &&
                      question.userAnswer &&
                      question.userAnswer.title,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                if (question.type.key === "file") {
                  colRow.push({
                    value:
                      question &&
                      question.userAnswer &&
                      question.userAnswer.length &&
                      question.userAnswer.map((ans) => ans.url).join(","),
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                if (
                  question.type.key === "text" ||
                  question.type.key === "date" ||
                  question.type.key === "datetime" ||
                  question.type.key === "number"
                ) {
                  colRow.push({
                    value: question && question.userAnswer,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
                if (question.type.key === "boolean") {
                  colRow.push({
                    value: question && question?.userAnswer?.title,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  });
                }
              });

              csvSubRows.push(colRow);
            }
          });
        });
      console.log("getAllManHours", getAllManHours);
      Object.keys(getAllStore).map((store) => {
        if (Object.keys(getAllStore).length !== csvSubRowsMan.length) {
          var days = Math.floor(getAllManHours[store] / 8);
          var hours = Math.floor(getAllManHours[store]) % 8;

          csvSubRowsMan.push([
            {
              value: ProgressData[0].trainingTitle || "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "FFFF0000" },
                },
              },
            },
            {
              value: `${getAllStore[store] || 0}` || "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "FFFF0000" },
                },
              },
            },
            {
              value: `${getAllUsers[store] || 0}` || "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "FFFF0000" },
                },
              },
            },
            {
              value: `${getAllManHours[store] || 0}` || "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "FFFF0000" },
                },
              },
            },
            {
              value:
                `${days || 0} ${days > 1 ? "Days" : "Day"} ${hours || 0} ${
                  hours > 1 ? "Hours" : "Hour"
                }` || "",
              style: {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "FFFF0000" },
                },
              },
            },
          ]);
        }
      });

      dataSet.push(
        { ySteps: 1, columns: columnListMan, data: csvSubRowsMan },
        { ySteps: 2, columns: columnList, data: csvSubRows }
      );
      columnList = [];
      csvSubRows = [];
      columnList.push(...defaultColumn);
      subArr.push({ formattedSD, dataSet });
    });

    if (ProgressData && ProgressData.length && subArr) {
      setCSVNamSubmissionsData(subArr);
    }
  }, [ProgressData]);
  console.log("paginatedData", paginatedData);
  return (
    <>
      <ToastContainer autoClose={1000} />
      <POHeader
        handleChangeSelect={handleChangeSelect}
        monthRecord={monthRecord}
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        searchValue={searchValue}
        csvData={cSVNamSubmissionsData}
      />

      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "80vh",
          mt: 1,
          p: 1,
          overflowX: "auto",
          overflowY: "scroll",
          borderRadius: "8px",
          px: 1,
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            margin: "5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: "8px",
          },
        }}
      >
        {/* {isDataLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Progressbar />
          </div> */}
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : paginatedData ? (
          <>
            <DataGrid
              disableColumnMenu
              rows={paginatedData}
              columns={columns}
              getRowId={(ProgressData) => ProgressData.assessmentItem._id}
              hideFooter={true}
              hideFooterPagination={true}
              className="grid-table"
            />
          </>
        ) : (
          "No Data Found"
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={2}
        marginBottom={1}
      >
        <Typography variant="subtitle2" style={{ marginRight: "10px" }}>
          Items per Page:
        </Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          value={itemsPerPage}
          onChange={handleChangeItemsPerPage}
        >
          {itemsPerPageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Typography variant="subtitle2" style={{ marginLeft: "10px" }}>
          {startRow + 1}-{Math.min(endRow, totalItems)} of {totalItems}
        </Typography>
        <Box marginLeft={2}>
          <Button
            variant="outlined"
            size="small"
            style={{ marginRight: 5 }}
            disabled={currentPage === 1}
            onClick={() => handleChangePage(1)}
          >
            <FirstPageIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ marginRight: 2 }}
            disabled={currentPage === 1}
            onClick={() => handleChangePage(currentPage - 1)}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={currentPage === totalPages}
            onClick={() => handleChangePage(currentPage + 1)}
          >
            <KeyboardArrowRightIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ marginLeft: 5 }}
            disabled={currentPage === totalPages}
            onClick={() => handleChangePage(totalPages)}
          >
            <LastPageIcon />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LMSReportView;
