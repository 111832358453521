import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Card, CardContent } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ViewHeader } from './Header';
import { Typography, Grid, Avatar, Chip } from '@mui/material';
import User from '../../../assets/icons/svg-icons/User.svg';

function createData( DueDate, Activity, Assigned ) {
    return { DueDate, Activity, Assigned };
}

const rows = [
    createData('Nov 12, 2020, 9:32:36 PM', 'Next appointment is scheduled on Nov 13, 2020, 4:32:00 PM', 'Oct 28, 2020, 3:10:41 PM' ),
    createData('Nov 12, 2020, 9:32:36 PM', 'Next appointment is scheduled on Nov 13, 2020, 4:32:00 PM', 'Oct 28, 2020, 3:10:41 PM' ),
    createData('Nov 12, 2020, 9:32:36 PM', 'Next appointment is scheduled on Nov 13, 2020, 4:32:00 PM', 'Oct 28, 2020, 3:10:41 PM' ),
    createData('Nov 12, 2020, 9:32:36 PM', 'Next appointment is scheduled on Nov 13, 2020, 4:32:00 PM', 'Oct 28, 2020, 3:10:41 PM' ),  
];

const CustomerDetails = () => {

    return (
        <Box>
            {/* Header of the page */}
            <ViewHeader />

            {/* Store Training List Table */}
            <Box sx={{
                bgcolor: '#F6F8FC', height: '80vh', mt: 1, p: 1, overflowX: 'hidden', overflowY: "scroll", borderRadius: '5px', px: 1,
                "&::-webkit-scrollbar": {
                    width: 15
                },
                "&::-webkit-scrollbar-track": {
                    padding: "12px 5px",
                    backgroundColor: "#CBD4E1",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#64748B",
                    borderRadius: '8px',
                }
            }} >

                <Grid container spacing={1}>
                    <Grid item xs={12} lg={4} sm={12} md={4}>
                        <Card sx={{ px: 1, mb: 2, py: 2 }}>
                            <CardContent>
                                <Table aria-label="simple table" className="details-table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Customer Name:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">Digital Retail Ltd.</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Customer ID:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">4567890</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Gender:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">Male</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={12} md={4}>
                        <Card sx={{ px: 1, mb: 2, py: 2 }}>
                            <CardContent>
                                <Table aria-label="simple table" className="details-table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Phone:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">+91-9123213213</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Email:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">Contact@mail.com</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Category:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">Office Cleaning</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={12} md={4}>
                        <Card sx={{ px: 1, mb: 2, pb: 10, pt: 2 }}>
                            <CardContent>
                                <Table aria-label="simple table" className="details-table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="detail-key">
                                                <Typography variant="p" className="detail-value">Description:</Typography>
                                            </TableCell>
                                            <TableCell className="detail-value">No Description</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant="p" component="h2" className='top-heading' align='left'>Assigned to:</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} sm={12} md={6}>
                        <Card sx={{ mb: 2 }} className='card'>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={3} sm={12} md={3} align="center">
                                        <Avatar className='assigned-avatar'><img src={User} alt="avatar" width="50px" /></Avatar>
                                    </Grid>
                                    <Grid item xs={12} lg={9} sm={12} md={9}>
                                        <Stack direction="column" align='left'>
                                            <Typography variant="p" component="h4">Pallavi+66 Kumari <Chip label="Self" variant="outlined" className='assigned-label' /></Typography>
                                            <Typography variant="p" component="p">12.9891583</Typography>
                                            <Typography variant="p" component="p">Assigned on: <Typography variant="p" component="b">Oct 28, 2020,3:10:41 PM</Typography></Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4} sm={12} md={6}>
                        <Card sx={{ mb: 2 }} className='card'>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={3} sm={12} md={3} align="center">
                                        <Avatar className='assigned-avatar'><img src={User} alt="avatar" width="50px" /></Avatar>
                                    </Grid>
                                    <Grid item xs={12} lg={9} sm={12} md={9}>
                                        <Stack direction="column" align='left'>
                                            <Typography variant="p" component="h4">Rama Krishna <Chip label="Delegated" variant="outlined" className='assigned-label' /></Typography>
                                            <Typography variant="p" component="p">12.9891583</Typography>
                                            <Typography variant="p" component="p">Assigned on: <Typography variant="p" component="b">Oct 28, 2020,3:10:41 PM</Typography></Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant="p" component="h2" className='top-heading' align='left'>Assigned to:</Typography>
                <TableContainer component={Paper}>
                    <Table size="large" aria-label="simple table" >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className='tableHead'>Due Date</TableCell>
                                <TableCell align="center" className='tableHead'>Activity</TableCell>
                                <TableCell align="center" className='tableHead'>Assigned</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="center">{row.DueDate}</TableCell>
                                    <TableCell align="center">{row.Activity}</TableCell>
                                    <TableCell align="center">{row.Assigned}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default CustomerDetails;