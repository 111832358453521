import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import { CREATE_LOG_ENTRY_REQUEST, CREATE_LOG_ENTRY_SUCCESS, CREATE_LOG_ENTRY_ERROR, VIEW_ALLLOG_ENTRY_REQUEST, VIEW_ALLLOG_ENTRY_SUCCESS, VIEW_ALLLOG_ENTRY_ERROR, DELETE_LOG_ENTRY_ERROR, DELETE_LOG_ENTRY_SUCCESS, DELETE_LOG_ENTRY_REQUEST, GET_LOG_ENTRY_SUBMISSION_REQUEST, GET_LOG_ENTRY_SUBMISSION_SUCCESS, GET_LOG_ENTRY_SUBMISSION_ERROR } from "../types/Types";

export const CreateLogEntryRequest = () => ({
    type: CREATE_LOG_ENTRY_REQUEST,
});
export const CreateLogEntrySuccess = (list) => ({
    type: CREATE_LOG_ENTRY_SUCCESS,
    payload: list,
});
export const CreateLogEntryFailed = (error) => {
    return {
        type: CREATE_LOG_ENTRY_ERROR,
        payload: error,
    };
};

export const EditLogEntryApi = (data, navigate) => (dispatch) => {
    try {
        dispatch(CreateLogEntryRequest());
        JwtAuthService.PutApiService(data).then((response) => {
            if (response?.status === 200) {
                dispatch(CreateLogEntrySuccess(response));

                navigate && navigate(response?.status);
                toast.success("Log-Entry successfully edited");
            } else {
                dispatch(CreateLogEntryFailed(response?.message));
                toast.error(response?.response?.data?.message);
            }
        });
    } catch (error) {
        dispatch(CreateLogEntryFailed(error));
        console.error("Something went Wrong", error);
        toast.error(error);
    }
};

export const CreateLogEntryApi = (data, navigate) => (dispatch) => {
    try {
        dispatch(CreateLogEntryRequest());
        JwtAuthService.PostApiService(data).then((response) => {
            if (response?.status === 200) {
                dispatch(CreateLogEntrySuccess(response));

                navigate(response?.status);
                toast.success("Log-Entry successfully created");
            } else {
                dispatch(CreateLogEntryFailed(response?.message));
                toast.error(response?.response?.data?.message);
            }
        });
    } catch (error) {
        dispatch(CreateLogEntryFailed(error));
        console.error("Something went Wrong", error);
        toast.error(error);
    }
};


export const ViewAllLogEntryListRequest = () => ({
    type: VIEW_ALLLOG_ENTRY_REQUEST,
});

export const ViewAllLogEntryListSuccess = (list) => ({
    type: VIEW_ALLLOG_ENTRY_SUCCESS,
    payload: list,
});

export const ViewAllLogEntryListFailed = (error) => ({
    type: VIEW_ALLLOG_ENTRY_ERROR,
    payload: error,
});

export const ViewAllLogEntryListApi = (data) => (dispatch) => {
    try {
        console.log("data view ====?", data);
        dispatch(ViewAllLogEntryListRequest(data));
        JwtAuthService.PostApiService(data).then((response) => {
            if (response?.status === 200) {
                dispatch(ViewAllLogEntryListSuccess(response));
            } else {
                dispatch(ViewAllLogEntryListFailed(`${response?.data?.message}`));
                toast.error(`${response?.data?.message}`);
            }
        });
    } catch (error) {
        dispatch(ViewAllLogEntryListFailed(error));
        console.error("Something went Wrong", error);
        // toast.error(error);
    }
};


export const DeleteLogEntryRequest = () => ({
    type: DELETE_LOG_ENTRY_REQUEST,
});

export const DeleteLogEntrySuccess = (list) => ({
    type: DELETE_LOG_ENTRY_SUCCESS,
    payload: list,
});

export const DeleteLogEntryError = (error) => ({
    type: DELETE_LOG_ENTRY_ERROR,
    payload: error,
});

export const DeleteLogEntryApi = (data, callBack) => (dispatch) => {
    try {
        dispatch(DeleteLogEntryRequest(data));
        JwtAuthService.DeleteApiService(data).then((response) => {
            console.log("response delete", response);
            if (response?.status === 200) {
                dispatch(DeleteLogEntrySuccess(response));
                toast.success("Log-Entry successfully deleted");
                callBack();
            } else {
                dispatch(DeleteLogEntryError(`${response?.data?.message}`));
                toast.error(`${response?.data?.message}`);
            }
        });
    } catch (error) {
        dispatch(DeleteLogEntryError(error));
        console.error("Something went Wrong", error);
        // toast.error(error);
    }
};

export const GetLogEntrySubmissionRequest = () => ({
    type: GET_LOG_ENTRY_SUBMISSION_REQUEST,
});

export const GetLogEntrySubmissionSuccess = (list) => ({
    type: GET_LOG_ENTRY_SUBMISSION_SUCCESS,
    payload: list,
});

export const GetLogEntrySubmissionError = (error) => ({
    type: GET_LOG_ENTRY_SUBMISSION_ERROR,
    payload: error,
});

export const GetLogEntrySubmissionApi = (data) => (dispatch) => {
    try {
        dispatch(GetLogEntrySubmissionRequest(data));
        JwtAuthService.PostApiService(data).then((response) => {
            console.log("response view log entry", response);
            if (response?.status === 200) {
                dispatch(GetLogEntrySubmissionSuccess(response));
            } else if (response?.response?.status === 404){
                dispatch(GetLogEntrySubmissionError(`No data found!`));
                toast.error(`No data found!`);
            } else {
                dispatch(GetLogEntrySubmissionError(`${error.response.data.error}`));
                toast.error(`${error.response.data.error}`);
            }
        });
    } catch (error) {
        dispatch(GetLogEntrySubmissionError(error));
    }
};
