/** @format */

import React from "react";
import { Box } from "@mui/material";
import AddQustionEdit from "../../../checklist/allForm/createFormTabs/AddQustionEdit";
import { useLocation } from "react-router-dom";

const EditAssessment = () => {
  const location = useLocation();
  const addnewEditasses = location?.state?.UpdateAssessment;
  const id = location?.state?.id;
  return (
    <Box>
      <AddQustionEdit EditAssessment={addnewEditasses} id={id} />
    </Box>
  );
};

export default EditAssessment;
