import JwtAuthService from "../apiConfig/ApiConfig";
import { toast } from "react-toastify";
import {
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_ERROR,ADD_GROUP_DELETE_REQUEST,ADD_GROUP_DELETE_SUCCESS,ADD_GROUP_DELETE_ERROR,ADD_GROUP_UPDATE_REQUEST,ADD_GROUP_UPDATE_SUCCESS,ADD_GROUP_UPDATE_ERROR
} from "../types/Types";

export const AddGroupStores = () => ({
    type: ADD_GROUP_REQUEST,
});

export const AddGroupStoresSuccess = (addGruop) => {
    return{
    type: ADD_GROUP_SUCCESS,
    payload: addGruop
}}

export const AddGroupStoresFailed = (error) => ({
    type: ADD_GROUP_ERROR,
    payload: error
})



export const AddGroupDelete = () => ({
    type: ADD_GROUP_DELETE_REQUEST,
});

export const AddGroupDeleteSuccess = (id) => {
    return{
    type: ADD_GROUP_DELETE_SUCCESS,
    payload: id
}}

export const AddGroupDeleteFailed = (error) => ({
    type: ADD_GROUP_DELETE_ERROR,
    payload: error
})


export const AddGroupUpdate = () => ({
  type: ADD_GROUP_UPDATE_REQUEST,
});

export const AddGroupUpdateSuccess = (id) => {
  return{
  type: ADD_GROUP_UPDATE_SUCCESS,
  payload: id
}}

export const AddGroupUpdataFailed = (error) => ({
  type: ADD_GROUP_UPDATE_ERROR,
  payload: error
})

export const addGroupData = (data) => {
    return (dispatch) => {
      dispatch(AddGroupStores());
  if(data){
    const addGroup = data;
    dispatch(AddGroupStoresSuccess(addGroup));
  }
    }
  }



  export const addGroupDataDelete = (dataid) => {
    return (dispatch) => {
      dispatch(AddGroupDelete());
  if(dataid){
    const id = dataid;
    dispatch(AddGroupDeleteSuccess(id));
  }
    }
  }


  export const addGroupDataUpdate = (dataid) => {
    return (dispatch) => {
      dispatch(AddGroupUpdate());
  if(dataid){
    const id = dataid;
    dispatch(AddGroupUpdateSuccess(id));
  }
    }
  }
