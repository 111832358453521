/** @format */

import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Modal,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Stack,
  Pagination,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { Link } from "material-ui-core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import moment from "moment";
import PunchClockOutlinedIcon from "@mui/icons-material/PunchClockOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { AuditorContext } from "../../../../context/auditorContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactApexChart from "react-apexcharts";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ChecklistDataFilterOnDateAction } from "../../../redux/actions/Checklist";

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 250,
  overflow: "scroll",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const ChecklistTypes = () => {
  const [series, setSeries] = useState([0]);
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const options = {
    chart: {
      height: 120,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 3,
            color: "#4caf50",
          },
        },
      },
    },
    labels: ["Cricket"],
    stroke: {
      lineCap: "round",
    },
    track: {
      background: "#f2f2f2",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ff9800"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
      },
    },
  };
  // Determine the color based on the series value
  if (series[0] < 50) {
    options.fill.gradient.gradientToColors = ["#ff9800"];
  }

  const [checklistType, setChecklistType] = useState(0);
  useEffect(() => {
    const storedIndex = localStorage.getItem("index");
    if (storedIndex !== null) {
      setChecklistType(parseInt(storedIndex));
    }
  }, []);
  const [upcomingList, setUpcomingList] = useState([]);
  const [remainingList, setRemainingList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [missedList, setMissedList] = useState([]);
  const [openMissedPopup, setOpenMissedPopup] = useState(false);
  const [selectedUpcomingChecklist, setSelectedUpcomingChecklist] =
    useState(null);
  const [selectedRemainingChecklist, setSelectedRemainingChecklist] =
    useState(null);
  const [selectedCompletedChecklist, setSelectedCompletedChecklist] =
    useState(null);
  const [selectedMissedChecklist, setSelectedMissedChecklist] = useState(null);
  const [openCompletedPopup, setOpenCompletedPopup] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openUpcomingPopup, setOpenUpcomingPopup] = useState(false);
  const [scoreList, setUpdatedScoreList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [countLoader, setCountLoader] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setChecklistType(newValue);
    // setLoader(true);
    setSearchQuery("");
    // setTimeout(() => {
    //   setLoader(false);
    // }, 1000);
  };
  console.log("scoreList", scoreList);
  const filteredUpcomingList = upcomingList.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredRemainingList = remainingList.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredCompletedList = completedList.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredMissedList = missedList.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [remainingItemsPerPage, setRemainingItemsPerPage] = useState(10);
  const [remainingCurrentPage, setRemainingCurrentPage] = useState(1);
  const totalRemainingPages = Math.ceil(
    filteredRemainingList.length / remainingItemsPerPage
  );

  useEffect(() => {
    setRemainingCurrentPage(1);
  }, [remainingItemsPerPage]);
  const totalPages = filteredRemainingList?.length;

  const handleRemainingPageChange = (page) => {
    if (page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }
    setRemainingCurrentPage(page);
  };

  const [completedItemsPerPage, setCompletedItemsPerPage] = useState(10);
  const [completedCurrentPage, setCompletedCurrentPage] = useState(1);
  const totalCompletedPages = Math.ceil(
    filteredCompletedList.length / completedItemsPerPage
  );
  const handleCompletedPageChange = (newPage) => {
    setCompletedCurrentPage(newPage);
  };

  function copy(e) {
    const el = document.createElement("input");
    el.value = e;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Copied!");
  }

  const [missedItemsPerPage, setMissedItemsPerPage] = useState(10);
  const [missedCurrentPage, setMissedCurrentPage] = useState(1);
  const totalMissedPages = Math.ceil(
    filteredMissedList.length / missedItemsPerPage
  );
  const handleMissedPageChange = (newPage) => {
    setMissedCurrentPage(newPage);
  };

  const [upcomingItemsPerPage, setUpcomingItemsPerPage] = useState(10);
  const [upcomingCurrentPage, setUpcomingCurrentPage] = useState(1);
  const totalUpcomingPages = Math.ceil(
    filteredUpcomingList.length / upcomingItemsPerPage
  );
  const handleUpcomingPageChange = (newPage) => {
    setUpcomingCurrentPage(newPage);
  };

  const {
    setcompanyId,
    setstoreId,
    storeId,
    companyId,
    setstoreTitle,
    storeTitle,
  } = useContext(AuditorContext);

  const [showLoader, setShowLoader] = useState(false);

  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  };

  const handleFilter = () => {
    setShowLoader(true);
    handleDateClick();
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    setOpenFilterModal(false);
  };

  const handleDateClick = () => {
    const id = localStorage.getItem("companyId");
    const selectedStore = JSON.parse(
      localStorage.getItem("auditorSelectedStore")
    );
    const requestData = {
      body: {
        storeId: {
          _id: selectedStore?._id,
          title: selectedStore?.title,
        },
      },
    };
    if (selectedStore?._id) {
      requestData.url = `${BASE_URL}formsAssignment/indexnew?timezone=india&date=${startDate}&dateFilter=true&company_id=${id}`;
    }
    dispatch(ChecklistDataFilterOnDateAction(requestData));
    if (filteredRecord) {
      setUpdatedScoreList(filteredRecord);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleViewClick = (submittedId) => {
    console.log("submittedId", submittedId);
    navigate(`/checklist-home/checklist-types/view`, {
      state: submittedId,
    });
  };

  // API calling for fatching updated data
  useEffect(() => {
    setShowLoader(true);
    const fetchData = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
        const token = localStorage.getItem("token");
        const res = await axios.get(
          `${BASE_URL}profile/me?company_id=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setStoreList(res.data.data.store);
        const selectedStore = localStorage.getItem("auditorSelectedStore");
        if (selectedStore) {
          const parseselectedStore = JSON.parse(selectedStore);
          setstoreId(parseselectedStore._id);
          setstoreTitle(parseselectedStore.title);
        } else {
          setstoreId(res.data.data.store[0]._id);
          setstoreTitle(res.data.data.store[0].title);
          localStorage.setItem(
            "auditorSelectedStore",
            JSON.stringify(res.data.data.store[0])
          );
        }
        // setShowLoader(false);
        setInitialLoad(false);
      } catch (error) {
        console.error(error);
        setShowLoader(false);
      }
    };

    fetchData();
  }, [initialLoad]);

  const storeChangeApi = () => {
    setShowLoader(true);
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");
    const selectedStore = JSON.parse(
      localStorage.getItem("auditorSelectedStore")
    );

    const fetchData = async () => {
      try {
        const res = await axios.post(
          `${BASE_URL}formsAssignment/indexnew?timezone=india&company_id=${companyId}`,
          {
            storeId: {
              _id: selectedStore._id,
              title: selectedStore.title,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUpdatedScoreList(res?.data?.data);
        setLoader(res?.data?.data);
        setShowLoader(false);
      } catch (error) {
        console.error(error);
        setShowLoader(false);
      }
    };

    fetchData();
  };

  useEffect(() => {
    if (storeId && storeList.length) {
      storeChangeApi();
    }
  }, [storeId, storeList]);
  // Api calling

  // useEffect(() => {
  //   const storedScoreList = localStorage.getItem("scoreList");
  //   if (storedScoreList) {
  //     const parsedScoreList = JSON.parse(storedScoreList);
  //     setUpdatedScoreList(parsedScoreList);
  //     setLoader(parsedScoreList);
  //   }
  // }, []);
  // console.log("updatedScoreList", scoreList);

  useEffect(() => {
    if (scoreList) {
      const calculateCategoryLists = () => {
        const upcoming = [];
        const remaining = [];
        const completed = [];
        const missed = [];

        scoreList.forEach((item) => {
          item.category?.forEach((categoryItem) => {
            switch (categoryItem.status) {
              case "upcoming":
                upcoming.push(categoryItem);
                break;
              case "pending":
                remaining.push(categoryItem);
                break;
              case "completed":
                completed.push(categoryItem);
                break;
              case "missed":
                missed.push(categoryItem);
                break;
              default:
                break;
            }
          });
        });
        setUpcomingList(upcoming);
        setRemainingList(remaining);
        setCompletedList(completed);
        setMissedList(missed);
      };
      calculateCategoryLists();
    }
  }, [scoreList]);
  // const filteredList = useMemo(() => {
  //   switch (checklistType) {
  //     case "Upcoming":
  //       return upcomingList.filter((item) =>
  //         item.title.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     case "Remaining":
  //       return remainingList.filter((item) =>
  //         item.title.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     case "Completed":
  //       return completedList.filter((item) =>
  //         item.title.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     case "Missed":
  //       return missedList.filter((item) =>
  //         item.title.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     default:
  //       return [];
  //   }
  // }, [
  //   checklistType,
  //   upcomingList,
  //   remainingList,
  //   completedList,
  //   missedList,
  //   searchQuery,
  // ]);

  // Pagination, handlePageChange, and other logic...

  <Tabs
    value={checklistType}
    onChange={handleChangeTab}
    textColor="#214d69"
    TabIndicatorProps={{
      sx: {
        backgroundColor: "#ef6b3b",
        color: "#214d69",
        textColor: "#214d69",
      },
    }}
  >
    <Tab label="Upcoming" value="Upcoming" />
    <Tab label="Remaining" value="Remaining" />
    <Tab label="Completed" value="Completed" />
    <Tab label="Missed" value="Missed" />
  </Tabs>;

  const getFrequencyText = (frequency) => {
    switch (frequency) {
      case 1:
        return "No frequency";
      case 2:
        return "Hourly";
      case 3:
        return "Daily";
      case 4:
        return "Weekly";
      case 5:
        return "Monthly";
      default:
        return "Unknown frequency";
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  const filteredRecord = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.filteredData &&
      state.checklist.filteredData?.data &&
      state.checklist.filteredData?.data?.data
  );

  console.log("filteredRecord", filteredRecord);

  const formatTime = (time) => {
    if (!time) return "";
    const formattedTime = moment(time, "HH:mm").format("hh:mm A");
    return formattedTime;
  };

  const handleSelectedRemaining = (item) => {
    const keys = Object.keys(localStorage);
    const filteredKey = keys.filter((e) => e.includes("answeredQuestions_"));
    localStorage.setItem("selectedRemainingData", JSON.stringify(item));
    localStorage.removeItem(filteredKey[0]);
    // setcurrentAssessment(item);
    navigate(`/checklist-home/checklist-types/checklist-forms`, {
      state: item,
    });
  };
  console.log("startDate", startDate);
  const handleLinkClick = (checklist) => {
    // navigate(`/auditor-home/my-trainings/my-trainings-view`, {
    //   state: checklist,
    // });
    setSelectedRemainingChecklist(checklist);
  };

  const handleUpcomingPopupOpen = (checklist) => {
    setSelectedUpcomingChecklist(checklist);
    setOpenUpcomingPopup(true);
  };
  const handleUpcomingPopupClose = () => {
    setOpenUpcomingPopup(false);
  };

  const handleCompletedPopupOpen = (checklist) => {
    setSelectedCompletedChecklist(checklist);
    setOpenCompletedPopup(true);
  };
  const handleCompletedPopupClose = () => {
    setOpenCompletedPopup(false);
  };

  const handlePopupOpen = (checklist) => {
    setSelectedMissedChecklist(checklist);
    setOpenMissedPopup(true);
  };
  const handlePopupClose = () => {
    setOpenMissedPopup(false);
  };

  return (
    <Box>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {/* <Typography
              variant="h6"
              component="h6"
              align="left"
              style={{
                padding: "4px",
                marginLeft: "10px",
                marginBottom: "0",
                fontSize: "1.2rem",
              }}
            >
              <p style={{ margin: "0" }}>Checklist Types</p>
            </Typography> */}

            <Stack
              direction="row"
              spacing={1}
              alignItems="left"
              justifyContent="flex-start"
              sx={{
                marginRight: "1px",
                marginLeft: "10px",
              }}
            >
              <Stack direction="row" alignItems="left">
                <Button
                  className="header-add cancle-hover"
                  style={{
                    height: "42px",
                    borderRadius: "8px",
                    padding: "12px 16px",
                  }}
                  onClick={(e) => setOpenFilterModal(true)}
                >
                  <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                  Filter
                </Button>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            container
            justifyContent="flex-end"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    textTransform: "uppercase",
                    padding: "4px",
                    marginBottom: "0",
                    fontSize: "1.2rem",
                    width: "100%",
                  }}
                >
                  {storeTitle}
                </Typography>
              </div>

              <FormControl fullWidth sx={{ width: "60%", marginRight: "8px" }}>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  placeholder="Search"
                  style={{
                    marginTop: "2px",
                    marginBottom: "2px",
                    height: "40px",
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Card>
      {showLoader ? (
        <ProgressbarTransparent play />
      ) : (
        <Box>
          <ToastContainer autoClose={3000} />

          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "75vh",
              mt: 1,
              p: 1,
              borderRadius: 1,
              overflowX: "auto",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                top: 0,
                zIndex: 1000,
                bgcolor: "#F6F8FC",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  borderBottom: 1,
                  borderColor: "divider",
                  justifyContent: "space-between",
                }}
              >
                <Tabs
                  value={checklistType}
                  onChange={handleChangeTab}
                  textColor="#214d69"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "#ef6b3b",
                      color: "#214d69",
                      textColor: "#214d69",
                    },
                  }}
                >
                  <Tab label="Upcoming" />
                  <Tab label="Remaining" />
                  <Tab label="Completed" />
                  <Tab label="Missed" />
                </Tabs>
                {/* 
                <FormControl fullWidth sx={{ mb: 1, width: "20%" }}>
                  <TextField
                    size="small"
                    fullWidth
                    id="outlined-start-adornment"
                    placeholder="Search, Checklist"
                    style={{
                      marginRight: "5px",
                      marginTop: "2px",
                      marginBottom: "2px",
                      height: "40px",
                    }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl> */}
                <span
                  style={{
                    fontStyle: "italic",
                    marginLeft: "20px",
                    marginTop: "7px",
                  }}
                >
                  Note: <span style={{ margin: "0 5px" }}>F = Form</span>
                  <span style={{ margin: "0 5px" }}>||</span>
                  <span style={{ margin: "0 5px" }}>Q = Question</span>
                </span>
              </Box>
            </Box>

            <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 64px)" }}>
              <Box
                sx={{
                  mt: 0,
                  ml: 5,
                  mr: 5,
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "2rem",
                }}
              >
                {checklistType === 0 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {filteredUpcomingList.length > 0 ? (
                        <>
                          <Grid container spacing={3}>
                            {filteredUpcomingList
                              .slice(
                                (upcomingCurrentPage - 1) *
                                  upcomingItemsPerPage,
                                upcomingCurrentPage * upcomingItemsPerPage
                              )
                              .map((upComingItem, index) => (
                                <Grid
                                  item
                                  key={index}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                >
                                  <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                      mt: 2,
                                      mr: 3,
                                      borderRadius: 4,
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                      mb: 5,
                                    }}
                                    onClick={() =>
                                      handleUpcomingPopupOpen(upComingItem)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      height: "220px",
                                      borderRadius: "40px",
                                    }}
                                  >
                                    {/* <div
                                      style={{
                                        border: "1px",
                                        borderRadius: "0px",
                                        color: "white",
                                        backgroundColor: "##ef6b3b",
                                      }}
                                    >
                                      Total Upcoming Checklist:{" "}
                                      {filteredUpcomingList?.length}
                                    </div> */}
                                    <Card key={index} variant="outlined">
                                      <CardContent style={{ display: "flex" }}>
                                        <div
                                          style={{ flex: 0.8, padding: "10px" }}
                                        >
                                          <Typography
                                            variant="h6"
                                            component="div"
                                            align="left"
                                          >
                                            <Link
                                              onClick={() =>
                                                handleLinkClick(
                                                  upComingItem._id
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "#214d69",
                                              }}
                                            >
                                              {upComingItem?.title}{" "}
                                              {upComingItem && (
                                                <span
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  [F:{" "}
                                                  {upComingItem.forms.length} ||
                                                  Q:{" "}
                                                  {upComingItem.forms.reduce(
                                                    (total, form) =>
                                                      total +
                                                      form.questions.length,
                                                    0
                                                  )}
                                                  ]
                                                </span>
                                              )}{" "}
                                            </Link>
                                          </Typography>
                                          <Divider sx={{ mt: 1 }} />
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <PunchClockOutlinedIcon
                                              sx={{ mr: 1 }}
                                            />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Frequency:{" "}
                                            </strong>
                                            {getFrequencyText(
                                              upComingItem?.frequency
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Start Time:{" "}
                                            </strong>
                                            {formatTime(
                                              upComingItem?.startTime
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              End Time:{" "}
                                            </strong>
                                            {formatTime(upComingItem?.endTime)}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            flex: 0.2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div style={{ position: "relative" }}>
                                            <ReactApexChart
                                              options={options}
                                              series={[
                                                isNaN(
                                                  upComingItem?.completePercentage
                                                )
                                                  ? 0
                                                  : Math.round(
                                                      upComingItem?.completePercentage
                                                    ),
                                              ]}
                                              type="radialBar"
                                              height={120}
                                            />
                                          </div>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              position: "fixed",
                              bottom: 0,
                              left: "50%",
                              transform: "translateX(-50%)",
                              zIndex: 1000, // Adjust as needed based on your layout
                              backgroundColor: "#fff", // Optional: Add background color
                              width: "100%", // Optional: Set width to full width
                              padding: "10px", // Optional: Add padding for better visibility
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              marginTop="20px"
                            >
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="body2"
                                  style={{ marginRight: "10px" }}
                                >
                                  Items per page:
                                </Typography>
                                <Select
                                  value={upcomingItemsPerPage}
                                  onChange={(e) =>
                                    setUpcomingItemsPerPage(
                                      parseInt(e.target.value)
                                    )
                                  }
                                  style={{ height: "30px" }}
                                >
                                  {[5, 10, 25].map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {`${
                                    (upcomingCurrentPage - 1) *
                                      upcomingItemsPerPage +
                                    1
                                  } - ${Math.min(
                                    upcomingCurrentPage * upcomingItemsPerPage,
                                    filteredUpcomingList.length
                                  )} of ${filteredUpcomingList.length}`}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton
                                  disabled={
                                    upcomingCurrentPage === 1 ||
                                    totalUpcomingPages === 0 ||
                                    upcomingItemsPerPage >
                                      filteredUpcomingList.length
                                  }
                                  onClick={() => handleUpcomingPageChange(1)}
                                >
                                  <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                  disabled={upcomingCurrentPage === 1}
                                  onClick={() =>
                                    handleUpcomingPageChange(
                                      upcomingCurrentPage - 1
                                    )
                                  }
                                >
                                  <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    upcomingCurrentPage ===
                                      totalUpcomingPages ||
                                    totalUpcomingPages === 0 ||
                                    upcomingCurrentPage *
                                      upcomingItemsPerPage >=
                                      filteredUpcomingList.length
                                  }
                                  onClick={() =>
                                    handleUpcomingPageChange(
                                      upcomingCurrentPage + 1
                                    )
                                  }
                                >
                                  <NavigateNextIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    upcomingCurrentPage ===
                                      totalUpcomingPages ||
                                    totalUpcomingPages === 0 ||
                                    upcomingCurrentPage *
                                      upcomingItemsPerPage >=
                                      filteredUpcomingList.length
                                  }
                                  onClick={() =>
                                    handleUpcomingPageChange(totalUpcomingPages)
                                  }
                                >
                                  <LastPageIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "100px",
                          }}
                        >
                          No upcoming checklist available, please check later...
                        </Typography>
                      )}

                      <Dialog
                        open={openUpcomingPopup}
                        onClose={handleUpcomingPopupClose}
                      >
                        <DialogTitle>Upcoming Checklist </DialogTitle>
                        <DialogContent>
                          <Typography>
                            {selectedUpcomingChecklist
                              ? `Checklist "${selectedUpcomingChecklist.title}" is upcoming.`
                              : ""}
                          </Typography>
                        </DialogContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                          }}
                        >
                          <Button
                            onClick={handleUpcomingPopupClose}
                            color="primary"
                          >
                            OK
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </>
                )}

                {checklistType === 1 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {filteredRemainingList.length > 0 ? (
                        <>
                          <Grid container spacing={3}>
                            {filteredRemainingList
                              .slice(
                                (remainingCurrentPage - 1) *
                                  remainingItemsPerPage,
                                remainingCurrentPage * remainingItemsPerPage
                              )
                              .map((remainingItem, index) => (
                                <Grid
                                  item
                                  key={index}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                >
                                  <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                      mt: 2,
                                      mr: 3,
                                      borderRadius: 4,
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                      mb: 5,
                                    }}
                                    onClick={() =>
                                      handleSelectedRemaining(remainingItem)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      height: "220px",
                                      borderRadius: "40px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: "1px",
                                        borderRadius: "0px",
                                        color: "white",
                                        backgroundColor: "#ef6b3b",
                                      }}
                                    >
                                      {/* Total Remaining Checklist:{" "}
                                      {filteredRemainingList?.length} */}
                                    </div>
                                    <Card key={index} variant="outlined">
                                      <CardContent style={{ display: "flex" }}>
                                        <div
                                          style={{ flex: 0.8, padding: "10px" }}
                                        >
                                          <Typography
                                            variant="h6"
                                            component="div"
                                            align="left"
                                          >
                                            <Link
                                              onClick={() =>
                                                handleLinkClick(
                                                  remainingItem._id
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "#214d69",
                                              }}
                                            >
                                              {remainingItem?.title}{" "}
                                              {remainingItem && (
                                                <span
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  [F:{" "}
                                                  {remainingItem.forms.length}{" "}
                                                  || Q:{" "}
                                                  {remainingItem.forms.reduce(
                                                    (total, form) =>
                                                      total +
                                                      form.questions.length,
                                                    0
                                                  )}
                                                  ]
                                                </span>
                                              )}{" "}
                                            </Link>
                                          </Typography>
                                          <Divider sx={{ mt: 1 }} />
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <PunchClockOutlinedIcon
                                              sx={{ mr: 1 }}
                                            />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Frequency:{" "}
                                            </strong>
                                            {getFrequencyText(
                                              remainingItem?.frequency
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Start Time:{" "}
                                            </strong>
                                            {formatTime(
                                              remainingItem?.startTime
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              End Time:{" "}
                                            </strong>
                                            {formatTime(remainingItem?.endTime)}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            flex: 0.2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div style={{ position: "relative" }}>
                                            <ReactApexChart
                                              options={options}
                                              series={[
                                                isNaN(
                                                  remainingItem?.completePercentage
                                                )
                                                  ? 0
                                                  : Math.round(
                                                      remainingItem?.completePercentage
                                                    ),
                                              ]}
                                              type="radialBar"
                                              height={120}
                                            />
                                          </div>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              marginTop="20px"
                            >
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="body2"
                                  style={{ marginRight: "10px" }}
                                >
                                  Items per page:
                                </Typography>
                                <Select
                                  value={remainingItemsPerPage}
                                  onChange={(e) =>
                                    setRemainingItemsPerPage(
                                      parseInt(e.target.value)
                                    )
                                  }
                                  style={{ height: "30px" }}
                                >
                                  {[5, 10, 25].map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {`${
                                    (remainingCurrentPage - 1) *
                                      remainingItemsPerPage +
                                    1
                                  } - ${Math.min(
                                    remainingCurrentPage *
                                      remainingItemsPerPage,
                                    filteredRemainingList.length
                                  )} of ${filteredRemainingList.length}`}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton
                                  disabled={
                                    remainingCurrentPage === 1 ||
                                    totalRemainingPages === 0 ||
                                    remainingItemsPerPage >
                                      filteredRemainingList.length
                                  }
                                  onClick={() => handleRemainingPageChange(1)}
                                >
                                  <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                  disabled={remainingCurrentPage === 1}
                                  onClick={() =>
                                    handleRemainingPageChange(
                                      remainingCurrentPage - 1
                                    )
                                  }
                                >
                                  <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    remainingCurrentPage ===
                                      totalRemainingPages ||
                                    totalRemainingPages === 0 ||
                                    remainingCurrentPage *
                                      remainingItemsPerPage >=
                                      filteredRemainingList.length
                                  }
                                  onClick={() =>
                                    handleRemainingPageChange(
                                      remainingCurrentPage + 1
                                    )
                                  }
                                >
                                  <NavigateNextIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    remainingCurrentPage ===
                                      totalRemainingPages ||
                                    totalRemainingPages === 0 ||
                                    remainingCurrentPage *
                                      remainingItemsPerPage >=
                                      filteredRemainingList.length
                                  }
                                  onClick={() =>
                                    handleRemainingPageChange(
                                      totalRemainingPages
                                    )
                                  }
                                >
                                  <LastPageIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "100px",
                          }}
                        >
                          No remaining checklist available, please check
                          later...
                        </Typography>
                      )}
                    </div>
                  </>
                )}

                {checklistType === 2 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {filteredCompletedList.length > 0 ? (
                        <>
                          <Grid container spacing={3}>
                            {filteredCompletedList
                              .slice(
                                (completedCurrentPage - 1) *
                                  completedItemsPerPage,
                                completedCurrentPage * completedItemsPerPage
                              )
                              .map((completedItem, index) => (
                                <Grid
                                  item
                                  key={index}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                >
                                  <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                      mt: 2,
                                      mr: 3,
                                      borderRadius: 4,
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                      mb: 5,
                                    }}
                                    onClick={() =>
                                      handleCompletedPopupOpen(completedItem)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      height: "220px",
                                      borderRadius: "40px",
                                    }}
                                  >
                                    {/* <div
                                      style={{
                                        border: "1px",
                                        borderRadius: "0px",
                                        color: "white",
                                        backgroundColor: "#ef6b3b",
                                      }}
                                    >
                                      Total Completed Checklist:{" "}
                                      {filteredCompletedList?.length}
                                    </div> */}
                                    <Card key={index} variant="outlined">
                                      <CardContent style={{ display: "flex" }}>
                                        <div
                                          style={{ flex: 0.8, padding: "10px" }}
                                        >
                                          <Typography
                                            variant="h6"
                                            component="div"
                                            align="left"
                                          >
                                            <Link
                                              onClick={() =>
                                                handleLinkClick(
                                                  completedItem._id
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "#214d69",
                                              }}
                                            >
                                              {completedItem?.title}{" "}
                                              {completedItem && (
                                                <span
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  [F:{" "}
                                                  {completedItem.forms.length}{" "}
                                                  || Q:{" "}
                                                  {completedItem.forms.reduce(
                                                    (total, form) =>
                                                      total +
                                                      form.questions.length,
                                                    0
                                                  )}
                                                  ]
                                                </span>
                                              )}{" "}
                                            </Link>
                                          </Typography>
                                          <Divider sx={{ mt: 1 }} />
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <PunchClockOutlinedIcon
                                              sx={{ mr: 1 }}
                                            />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Frequency:{" "}
                                            </strong>
                                            {getFrequencyText(
                                              completedItem?.frequency
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Start Time:{" "}
                                            </strong>
                                            {formatTime(
                                              completedItem?.startTime
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              End Time:{" "}
                                            </strong>
                                            {formatTime(completedItem?.endTime)}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            flex: 0.2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div style={{ position: "relative" }}>
                                            <ReactApexChart
                                              options={options}
                                              series={[
                                                isNaN(
                                                  completedItem?.completePercentage
                                                )
                                                  ? 0
                                                  : Math.round(
                                                      completedItem?.completePercentage
                                                    ),
                                              ]}
                                              type="radialBar"
                                              height={120}
                                            />
                                            <Box
                                              style={{
                                                display: "flex",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              {console.log(
                                                "completedItem",
                                                completedItem
                                              )}
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  let submittedId = "";
                                                  if (completedItem) {
                                                    submittedId =
                                                      completedItem?.submissionId;
                                                  }

                                                  if (submittedId) {
                                                    handleViewClick(
                                                      submittedId
                                                    );
                                                  }
                                                }}
                                                style={{
                                                  padding: "5px",
                                                  border: "1px solid",
                                                  minWidth: "25px",
                                                }}
                                              >
                                                <VisibilityIcon
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                />
                                              </Button>
                                              {completedItem?.pdfReport && (
                                                <Button
                                                  onClick={(e) => {
                                                    if (e && e.stopPropagation)
                                                      e.stopPropagation();
                                                    window.open(
                                                      completedItem?.pdfReport,
                                                      "_blank"
                                                    );
                                                  }}
                                                  style={{
                                                    padding: "5px",
                                                    border: "1px solid",
                                                    minWidth: "25px",
                                                  }}
                                                >
                                                  <DownloadIcon
                                                    style={{ fontSize: "16px" }}
                                                  />
                                                </Button>
                                              )}
                                              {completedItem?.pdfReport && (
                                                <Button
                                                  onClick={(e) => {
                                                    if (e && e.stopPropagation)
                                                      e.stopPropagation();

                                                    copy(
                                                      completedItem?.pdfReport
                                                    );
                                                  }}
                                                  style={{
                                                    padding: "5px",
                                                    border: "1px solid",
                                                    minWidth: "25px",
                                                  }}
                                                >
                                                  <ContentCopyIcon
                                                    style={{
                                                      fontSize: "16px",
                                                    }}
                                                  />
                                                </Button>
                                              )}
                                            </Box>
                                          </div>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              marginTop="20px"
                            >
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="body2"
                                  style={{ marginRight: "10px" }}
                                >
                                  Items per page:
                                </Typography>
                                <Select
                                  value={completedItemsPerPage}
                                  onChange={(e) =>
                                    setCompletedItemsPerPage(
                                      parseInt(e.target.value)
                                    )
                                  }
                                  style={{ height: "30px" }}
                                >
                                  {[5, 10, 25].map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {`${
                                    (completedCurrentPage - 1) *
                                      completedItemsPerPage +
                                    1
                                  } - ${Math.min(
                                    completedCurrentPage *
                                      completedItemsPerPage,
                                    filteredCompletedList.length
                                  )} of ${filteredCompletedList.length}`}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton
                                  disabled={
                                    completedCurrentPage === 1 ||
                                    totalCompletedPages === 0 ||
                                    completedItemsPerPage >
                                      filteredCompletedList.length
                                  }
                                  onClick={() => handleCompletedPageChange(1)}
                                >
                                  <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                  disabled={completedCurrentPage === 1}
                                  onClick={() =>
                                    handleCompletedPageChange(
                                      completedCurrentPage - 1
                                    )
                                  }
                                >
                                  <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    completedCurrentPage ===
                                      totalCompletedPages ||
                                    totalCompletedPages === 0 ||
                                    completedCurrentPage *
                                      completedItemsPerPage >=
                                      filteredCompletedList.length
                                  }
                                  onClick={() =>
                                    handleCompletedPageChange(
                                      completedCurrentPage + 1
                                    )
                                  }
                                >
                                  <NavigateNextIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    completedCurrentPage ===
                                      totalCompletedPages ||
                                    totalCompletedPages === 0 ||
                                    completedCurrentPage *
                                      completedItemsPerPage >=
                                      filteredCompletedList.length
                                  }
                                  onClick={() =>
                                    handleCompletedPageChange(
                                      totalCompletedPages
                                    )
                                  }
                                >
                                  <LastPageIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "100px",
                          }}
                        >
                          No completed checklist available, please check
                          later...
                        </Typography>
                      )}

                      <Dialog
                        open={openCompletedPopup}
                        onClose={handleCompletedPopupClose}
                      >
                        <DialogTitle>Completed Checklist</DialogTitle>
                        <DialogContent>
                          <Typography>
                            {selectedCompletedChecklist
                              ? `Checklist "${selectedCompletedChecklist.title}" is completed.`
                              : ""}
                          </Typography>
                        </DialogContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                          }}
                        >
                          <Button
                            onClick={handleCompletedPopupClose}
                            color="primary"
                          >
                            OK
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </>
                )}

                {checklistType === 3 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {filteredMissedList.length > 0 ? (
                        <>
                          <Grid container spacing={3}>
                            {filteredMissedList
                              .slice(
                                (missedCurrentPage - 1) * missedItemsPerPage,
                                missedCurrentPage * missedItemsPerPage
                              )
                              .map((missedItem, index) => (
                                <Grid
                                  item
                                  key={index}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                >
                                  <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                      mt: 2,
                                      mr: 3,
                                      borderRadius: 4,
                                      boxShadow:
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                      mb: 5,
                                    }}
                                    onClick={() => handlePopupOpen(missedItem)}
                                    style={{
                                      cursor: "pointer",
                                      height: "220px",
                                      borderRadius: "40px",
                                    }}
                                  >
                                    {/* <div
                                      style={{
                                        border: "1px",
                                        borderRadius: "0px",
                                        color: "white",
                                        backgroundColor: "#ef6b3b",
                                      }}
                                    >
                                      Total Missed Checklist:{" "}
                                      {filteredMissedList?.length}
                                    </div> */}
                                    <Card key={index} variant="outlined">
                                      <CardContent style={{ display: "flex" }}>
                                        <div
                                          style={{ flex: 0.8, padding: "10px" }}
                                        >
                                          <Typography
                                            variant="h6"
                                            component="div"
                                            align="left"
                                          >
                                            <Link
                                              onClick={() =>
                                                handleLinkClick(missedItem._id)
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "#214d69",
                                              }}
                                            >
                                              {missedItem?.title}{" "}
                                              {missedItem && (
                                                <span
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  [F: {missedItem.forms.length}{" "}
                                                  || Q:{" "}
                                                  {missedItem.forms.reduce(
                                                    (total, form) =>
                                                      total +
                                                      form.questions.length,
                                                    0
                                                  )}
                                                  ]
                                                </span>
                                              )}{" "}
                                            </Link>
                                          </Typography>
                                          <Divider sx={{ mt: 1 }} />
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <PunchClockOutlinedIcon
                                              sx={{ mr: 1 }}
                                            />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Frequency:{" "}
                                            </strong>
                                            {getFrequencyText(
                                              missedItem?.frequency
                                            )}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              Start Time:{" "}
                                            </strong>
                                            {formatTime(missedItem?.startTime)}
                                          </Typography>
                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{
                                              pt: 1,
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AccessTimeIcon sx={{ mr: 1 }} />
                                            <strong
                                              style={{ marginRight: "8px" }}
                                            >
                                              End Time:{" "}
                                            </strong>
                                            {formatTime(missedItem?.endTime)}
                                          </Typography>
                                        </div>
                                        <div
                                          style={{
                                            flex: 0.2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div style={{ position: "relative" }}>
                                            <ReactApexChart
                                              options={options}
                                              series={[
                                                isNaN(
                                                  missedItem?.completePercentage
                                                )
                                                  ? 0
                                                  : Math.round(
                                                      missedItem?.completePercentage
                                                    ),
                                              ]}
                                              type="radialBar"
                                              height={120}
                                            />
                                          </div>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Card>
                                </Grid>
                              ))}
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              marginTop="20px"
                            >
                              <Stack direction="row" alignItems="center">
                                <Typography
                                  variant="body2"
                                  style={{ marginRight: "10px" }}
                                >
                                  Items per page:
                                </Typography>
                                <Select
                                  value={missedItemsPerPage}
                                  onChange={(e) =>
                                    setMissedItemsPerPage(
                                      parseInt(e.target.value)
                                    )
                                  }
                                  style={{ height: "30px" }}
                                >
                                  {[5, 10, 25].map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {`${
                                    (missedCurrentPage - 1) *
                                      missedItemsPerPage +
                                    1
                                  } - ${Math.min(
                                    missedCurrentPage * missedItemsPerPage,
                                    filteredMissedList.length
                                  )} of ${filteredMissedList.length}`}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton
                                  disabled={
                                    missedCurrentPage === 1 ||
                                    totalMissedPages === 0 ||
                                    missedItemsPerPage >
                                      filteredMissedList.length
                                  }
                                  onClick={() => handleMissedPageChange(1)}
                                >
                                  <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                  disabled={missedCurrentPage === 1}
                                  onClick={() =>
                                    handleMissedPageChange(
                                      missedCurrentPage - 1
                                    )
                                  }
                                >
                                  <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    missedCurrentPage === totalMissedPages ||
                                    totalMissedPages === 0 ||
                                    missedCurrentPage * missedItemsPerPage >=
                                      filteredMissedList.length
                                  }
                                  onClick={() =>
                                    handleMissedPageChange(
                                      missedCurrentPage + 1
                                    )
                                  }
                                >
                                  <NavigateNextIcon />
                                </IconButton>
                                <IconButton
                                  disabled={
                                    missedCurrentPage === totalMissedPages ||
                                    totalMissedPages === 0 ||
                                    missedCurrentPage * missedItemsPerPage >=
                                      filteredMissedList.length
                                  }
                                  onClick={() =>
                                    handleMissedPageChange(totalMissedPages)
                                  }
                                >
                                  <LastPageIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "100px",
                          }}
                        >
                          No missed checklist available, please check later...
                        </Typography>
                      )}

                      <Dialog open={openMissedPopup} onClose={handlePopupClose}>
                        <DialogTitle>Missed Checklist </DialogTitle>
                        <DialogContent>
                          <Typography>
                            {selectedMissedChecklist
                              ? `Checklist "${selectedMissedChecklist.title}" is missed.`
                              : ""}
                          </Typography>
                        </DialogContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                          }}
                        >
                          <Button onClick={handlePopupClose} color="primary">
                            OK
                          </Button>
                        </div>
                      </Dialog>
                    </div>
                  </>
                )}
              </Box>
              <Modal
                open={openFilterModal}
                onClose={modalopenFilterhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={false}
              >
                <Box sx={{ ...downloadAllstyle, p: 0 }}>
                  <Box
                    sx={{
                      position: "sticky",
                      right: "15px",
                      top: "15px",
                      cursor: "pointer",
                      textAlign: "right",
                      padding: "0px 10px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box onClick={() => setOpenFilterModal(false)}>
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        pt: 2,
                        px: 4,
                      }}
                    >
                      <label>Date</label>
                      <span style={{ color: "red" }}>*</span>{" "}
                      <Box
                        sx={{ mb: 2, width: "100%" }}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            size="small"
                            value={startDate}
                            onChange={(newValue) => {
                              let dateSort = moment(newValue?.$d)
                                .format("YYYY-MM-DD")
                                .split("/")
                                .join("");
                              setStartDate(dateSort);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            className="date-pick date-pick-submision"
                            maxDate={new Date()}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      position: "sticky",
                      bottom: 0,
                      width: "100%",
                      gap: "30px",
                      px: 3,
                      pt: 1,
                      background: "#fff",
                    }}
                  >
                    <Button
                      className="cancle-button cancle-hover"
                      style={{
                        height: "46px",
                        padding: "12px 16px",
                        width: "47%",
                        background: "#fff",
                      }}
                      onClick={() => {
                        setStartDate(null);
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      className="header-add cancle-hover"
                      style={{
                        height: "46px",
                        padding: "12px 16px",
                        width: "47%",
                      }}
                      onClick={(e) => {
                        handleFilter();
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Modal>
              <div className="footer">
                <Box sx={{ mt: 0, ml: 3, mr: 3 }}>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    style={{ marginTop: "10px" }}
                  >
                    Copyright ©{" "}
                    <Link
                      href="https://www.hoperesearchgroup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      HOPE RESEARCH GROUP
                    </Link>
                    . All rights reserved.
                  </Typography>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChecklistTypes;
