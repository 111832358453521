/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Typography } from "material-ui-core";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PerformanceOverview from "../PerformanceOverview";
import SearchAppBar from "../../../checklist/tableHeader/SearchButton";
import ExcelFile from "react-export-excel-xlsx-fix/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel-xlsx-fix/dist/ExcelPlugin/elements/ExcelSheet";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const options = [
  { value: "OneDay", label: "Last One Day" },
  { value: "OneWeek", label: "Last One Week" },
  { value: "OneMonth", label: "Last One Month" },
];

const POHeader = (props) => {
  const { csvData } = props;
  const [searchText, setSearchText] = useState("");

  const handleBackClick = () => {
    window.history.back();
  };

  useEffect(() => {
    const monthRecord = localStorage.getItem("monthRecord");
    if (!monthRecord) {
      localStorage.setItem("monthRecord", JSON.stringify(props?.monthRecord));
    }
  }, []); // Only run once on component mount

  const handleChangeSelect = (event) => {
    const newValue = event.target.value;
    localStorage.setItem("monthRecord", JSON.stringify(newValue));
    // You can also update the state if needed
    // setMonthRecord(newValue);
    props?.handleChangeSelect(event);
  };

  return (
    <div>
      <Card className="header-card">
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <IconButton
              onClick={handleBackClick}
              sx={{ fontSize: "20px", color: "#333" }}
            >
              <ArrowBackIcon sx={{ fontSize: "24px" }} />
              <Typography variant="button" sx={{ marginLeft: "8px" }}>
                Back
              </Typography>
            </IconButton>
          </Grid>
          <Grid item xs={9} sm={10} md={4} lg={4}>
            <Typography
              align="left"
              variant="h6"
              component="h6"
              style={{ padding: "4px", marginLeft: "0%" }}
            >
              LMS Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              alignItems="center"
            >
              {csvData && csvData.length ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  <ExcelFile
                    element={
                      <Button
                        className="export-btn-lms-report header-add cancle-hover"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        Export Excel
                      </Button>
                    }
                    filename={"LMSRecords_" + Date.now()}
                  >
                    {csvData.map((csv, key) => (
                      <ExcelSheet
                        key={key}
                        dataSet={csv.dataSet}
                        name={csv.formattedSD}
                      />
                    ))}
                  </ExcelFile>
                </Box>
              ) : (
                ""
              )}
              <Box>
                <SearchAppBar
                  searchOnChange={props.searchOnChange}
                  handleKeyDown={props.handleKeyDown}
                  searchValue={props.searchValue}
                />
              </Box>
              {/* <FormControl
                sx={{ minWidth: 200 }}
                size="small"
                style={{ paddingRight: 10 }}
                className="location-storeinfo"
              >
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props?.monthRecord}
                  onChange={handleChangeSelect}
                  style={{ borderRadius: "8px", height: "42px" }}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="OneDay" defaultValue={"Last One Day"}>
                    Last One Day
                  </MenuItem>
                  <MenuItem value="OneWeek">Last One Week</MenuItem>
                  <MenuItem value="OneMonth">Last One Month</MenuItem>
                </Select>
              </FormControl> */}
              <FormControl
                sx={{ minWidth: 200 }}
                size="small"
                style={{ paddingRight: 10 }}
                className="location-storeinfo"
              >
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props?.monthRecord}
                  onChange={handleChangeSelect}
                  className="custom-select"
                  MenuProps={{
                    PaperProps: {
                      className: "custom-menu-paper", // Apply custom class to Paper component
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className="centered-menu-item" // Apply custom class for MenuItem
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ float: "right", marginRight: "13px" }}>
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>Advance Checklist Wise Report:</strong>
                      <p>
                        Status: Display's selected checklist completion status,
                        completion percentage & average compliance scores for
                        the selected user and stores.
                      </p>
                      <p>
                        Percentage: Display's selected checklist completion
                        percentage & average compliance scores for the selected
                        user and stores.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      {/* <PerformanceOverview searchText={searchText} /> */}
    </div>
  );
};

export default POHeader;
