import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Card,
  Menu,
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fab from "@material-ui/core/Fab";
import Blank from "../../../images/Blank.png";
import questiontype from "../../../checklist/allForm/createFormTabs/QuestionType";
import storemap from "./StoreMapping";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditQuestion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const listData = location.state;
  const [images, setImages] = useState();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fieldsValues, setFieldsValues] = useState({});
  const [defaultValues, setDefaultValues] = useState(
    listData?.type?.validations[0].answer
  );
  const [questionType, setQuestionType] = useState("");
  const [validationType, setValidationType] = useState("");
  const [dataRows, setDataRows] = useState([]);
  const [mainList, setMainList] = useState([]);
  const [groupQuestion, setGroupQuestion] = useState("");
  const [checkedValue, setCheckedValue] = useState(listData?.is_store);
  const [kpiChecked, setKpiChecked] = useState(listData?.is_kpi);
  const [dependencyValue, setDependencyValue] = useState(
    listData?.has_dependency
  );
  const [dependencyList, setDependencyList] = useState(
    listData?.dependency_list
  );
  const [storeMap, setStoreMap] = useState({});
  const [mainListType, setMainListType] = useState("");
  const [itemsValue, setItemsValue] = useState("");
  const [dependency, setDependency] = useState("");
  const [dependencyListSelect, setDependencyListSelect] = useState([]);
  const [questionDependencyList, setQuestionDependencyList] = useState([]);
  const [questionDependencys, setQuestionDependencys] = useState(
    listData?.dependency_list?.id
  );

  const open = Boolean(anchorEl);
  let LocalData = localStorage.getItem("questionsGroupOfDatamanager");
  let dataList = localStorage.getItem("dataListOfdata");
  let questionList = localStorage.getItem("questionsOfDatamanger");

  useEffect(() => {
    if (LocalData) {
      let newQuest = JSON.parse(LocalData);
      setDataRows(newQuest);
    }
  }, [LocalData]);

  useEffect(() => {
    if (dataList) {
      let newQuest = JSON.parse(dataList);
      setMainList(newQuest);
    }
  }, [dataList]);

  useEffect(() => {
    setFieldsValues({
      title: listData && listData.title,
      desc: listData && listData.desc,
      question_score: listData && listData.question_score,
    });
    setQuestionType(listData?.type?.title);
    setGroupQuestion(listData?.group);
    setMainListType(listData?.attached_list);
    setDependency(listData?.dependency_list?.id);
    setStoreMap(listData?.mapped_to);
  }, [listData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;

    setFieldsValues({
      ...fieldsValues,
      [name]: value,
    });
  };

  const handleDefault = (e) => {
    listData?.type?.validations.filter((item) => {
      if (
        (item.key == "default" && typeof item.answer == "string") ||
        item.answer === null
      ) {
        item.answer = e.target.value;
        setDefaultValues(e.target.value);
      }
    });
  };

  const handleQuestionType = (e) => {
    setQuestionType(e.target.value);
  };

  const handleValidation = (newList) => {
    setValidationType(newList);
  };

  let multiimageUrl =
    images &&
    images?.map((item) => {
      return item?.url;
    });

  const handleImageUpload = (e) => {
    const tempArr = [];

    [...e.target.files].forEach((file) => {
      tempArr.push({
        data: file,
        url: URL.createObjectURL(file),
      });
    });

    setImages(tempArr);
  };

  const handleGroupQuestion = (e) => {
    setGroupQuestion(e.target.value);
  };

  const handleStoreChecked = () => {
    setCheckedValue(!checkedValue);
    setStoreMap({});
  };
  const handleKpiChecked = () => {
    setKpiChecked(!kpiChecked);
  };

  const handleDependencyChecked = () => {
    setDependencyValue(!dependencyValue);
  };

  const handleMapStore = (e) => {
    setStoreMap(e.target.value);
  };

  const handleMainList = (e) => {
    setMainListType(e.target.value);
  };

  const handleDependency = (e) => {
    const newList = mainList?.filter((item, i) => item.id === e.target.value);
    let newStateupdate =
      newList &&
      newList.filter((items, i) => {
        setDependency(items.id), setDependencyList(items);
        newFunction(items);
      });
    return newStateupdate;
  };

  const newFunction = (list) => {
    if (questionList) {
      let newQuestList = JSON.parse(questionList);
      const newData = newQuestList?.filter(
        (item, i) => item?.attached_list === list?.id
      );
      setQuestionDependencyList(newData);
    }
  };

  const questionDependency = (e) => {
    setQuestionDependencys(e.target.value);
  };

  const handleListSelect = (e, index) => {
    const newList = dependencyList?.items?.filter((item, i) => {
      if (i === index) {
        item.selected_list = e.target.value;
      }
      return item;
    });
    setDependencyListSelect(newList);
  };

  const handleUpdate = () => {
    const dependencylist = {
      id: dependency,
      items: dependencyListSelect,
    };
    const allPayload = {
      attached_list: mainListType,
      dependency_list: dependencylist,
      dependency_list_question: listData.dependency_list_question,
      desc: fieldsValues.desc,
      group: groupQuestion,
      has_dependency: dependencyValue,
      has_list_dependency: listData.has_list_dependency,
      id: listData.id,
      is_kpi: kpiChecked,
      is_store: checkedValue,
      mapped_to: storeMap,
      title: fieldsValues.title,
      type: listData.type,
      question_score: fieldsValues.question_score,
    };
    let localData = localStorage.getItem("questionsOfDatamanger");
    let localParse = JSON.parse(localData);
    if (localParse) {
      const method = localParse?.map((val, i) => {
        if (val?.id == allPayload?.id) {
          return (localParse[i] = allPayload);
        }
        return val;
      });
      localStorage.setItem("questionsOfDatamanger", JSON.stringify(method));
      navigate("/questions");
    }
  };

  return (
    <>
      <Box sx={{ mb: 1 }} className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Link to="/questions" className="submenu-item">
                <Button className="cancle-button cancle-hover">
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>
              </Link>
              <Button
                className="header-add cancle-hover"
                onClick={handleUpdate}
              >
                <CheckOutlinedIcon sx={{ mr: 1 }} />
                Done
              </Button>
              <div style={{marginTop:"6px",marginRight:"8px"}}>
                      <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px" }}>
        <Container
          maxWidth="lg"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            p: 1,
            textAlign: "left",
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Title Images</label>
            <Box className="attachment-file">
              <input
                accept="image/*"
                className="file-upload"
                id="contained-button-file"
                multiple
                type="file"
                hidden
                onChange={handleImageUpload}
              />
              <label htmlFor="contained-button-file">
                <Fab component="span" className="store-file cancle-hover">
                  <AddOutlinedIcon />
                </Fab>
              </label>
              <Box>
                {multiimageUrl?.length > 0 ? (
                  multiimageUrl &&
                  multiimageUrl?.map((item) => {
                    return (
                      <img
                        className="file-preview"
                        src={item}
                        alt="attachment"
                      />
                    );
                  })
                ) : (
                  <Box>
                    <img
                      className="file-preview"
                      src={Blank}
                      alt="attachment"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Title</label>
            <TextField
              id="outlined-basic"
              name="title"
              onChange={handleChangeValue}
              value={fieldsValues.title}
              className="issueinput-fields"
              // defaultValue={listData.title}
              variant="outlined"
              size="small"
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Description</label>
            <TextField
              id="outlined-basic"
              className="issueinput-fields"
              variant="outlined"
              name="desc"
              onChange={handleChangeValue}
              value={fieldsValues.desc}
              // defaultValue={listData.desc}
              size="small"
              multiline
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Question Score</label>
            <TextField
              id="outlined-basic"
              className="issueinput-fields"
              variant="outlined"
              name="question_score"
              onChange={handleChangeValue}
              value={fieldsValues.question_score}
              size="small"
              multiline
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Select Question Type</label>
            <Select
              disabled
              size="small"
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "#e2e8f0",
                    "& .MuiMenuItem-root": {
                      padding: 2,
                    },
                    height: "200px",
                  },
                },
              }}
              name="type"
              value={questionType}
              onChange={handleQuestionType}
              renderValue={(selected) => {
                var type = typeof selected;
                return selected;
              }}
              sx={{ borderRadius: "8px" }}
            >
              {/* <MenuItem value={formik.values.questiontype}>
                  <em>{formik.values.questiontype}</em>
                </MenuItem> */}
              {questiontype?.map((option, i) => {
                return (
                  <MenuItem key={i} value={option} className="select-item">
                    {option.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {questionType === "Single Select" ||
          questionType === "Single Choice" ? (
            <Box>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Relevant List</label>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dependencyValue}
                      onChange={handleDependencyChecked}
                    />
                  }
                  label="Has other list dependency?"
                />
              </FormControl>
              {dependencyValue === true ? (
                <Box>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Dependency List</label>
                    <Select
                      size="small"
                      name="mainlist"
                      value={dependency}
                      onChange={handleDependency}
                      MenuProps={{
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        sx: {
                          height: "200px",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {mainList?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option.id}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Question For Dependency List</label>
                    <Select
                      size="small"
                      name="dependency_list"
                      value={questionDependencys}
                      onChange={questionDependency}
                      MenuProps={{
                        disableScrollLock: true,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        sx: {
                          height: "200px",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Select Question
                      </MenuItem>
                      {questionDependencyList?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option.title}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Box>
                    {dependencyList &&
                      dependencyList?.items &&
                      dependencyList?.items?.length > 0 &&
                      dependencyList?.items?.map((item, i) => {
                        return (
                          <Grid container spacing={1}>
                            <Grid item xs={1}>
                              <h5>{item?.title} : </h5>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth>
                                <Select
                                  size="small"
                                  name="mainlist"
                                  fullWidth
                                  value={item.selected_list}
                                  onChange={(e) => handleListSelect(e, i)}
                                  MenuProps={{
                                    disableScrollLock: true,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "center",
                                    },
                                    sx: {
                                      height: "200px",
                                    },
                                  }}
                                  sx={{ borderRadius: "8px" }}
                                >
                                  <MenuItem
                                    disabled
                                    value=""
                                    className="select-item"
                                  >
                                    Type
                                  </MenuItem>
                                  {mainList?.map((option, i) => {
                                    return (
                                      <MenuItem
                                        key={i}
                                        value={option.id}
                                        className="select-item"
                                      >
                                        {option.title}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Box>
                </Box>
              ) : (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Main List</label>
                  <Select
                    size="small"
                    name="mainlist"
                    value={mainListType}
                    onChange={handleMainList}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      sx: {
                        height: "200px",
                      },
                    }}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem disabled value="" className="select-item">
                      Type
                    </MenuItem>
                    {mainList?.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option.id}
                          className="select-item"
                        >
                          {option.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>
          ) : (
            ""
          )}
          <Box className="tab-box">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "#F6F8FC",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Validation"
                  {...a11yProps(0)}
                  className="tab-item"
                />
                {/* <Tab label="Rules" {...a11yProps(1)} className="tab-item" /> */}
                {/* <Tab
                  label="Weightage Score"
                  {...a11yProps(2)}
                  className="tab-item"
                /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {listData?.type?.validations?.map((items, i) => {
                return Array.isArray(items?.answer) ? (
                  <FormControl fullWidth>
                    <label>{items.title}</label>
                    <RadioGroup
                      row
                      value={itemsValue}
                      onChange={(e, value) => {
                        items?.answer?.forEach((row) => {
                          row.value = false;
                        });
                        const newList = items?.answer?.filter((item, i) => {
                          if (item.title === value) {
                            item.value = item.title === value ? true : false;
                            // item.value = item.title === value || item.value === true ? false : true;
                            setItemsValue(item.value);
                          }
                          return item;
                        });
                        handleValidation(newList);
                      }}
                      name="row-radio-buttons-group"
                      sx={{ p: 0 }}
                      className="radio-btn"
                    >
                      {Array.isArray(items?.answer)
                        ? items &&
                          items.answer?.length > 0 &&
                          items?.answer?.map((list, i) => {
                            return (
                              <div key={i}>
                                <FormControlLabel
                                  checked={list.value}
                                  value={list.title}
                                  control={<Radio size="small" />}
                                  label={list.title}
                                />
                              </div>
                            );
                          })
                        : ""}
                    </RadioGroup>
                  </FormControl>
                ) : items?.answer === null || items?.answer === "" ? (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>{items.title}</label>
                    <TextField
                      id="outlined-basic"
                      className="issueinput-fields"
                      name="defaultvalue"
                      onChange={(e) => handleDefault(e)}
                      value={defaultValues}
                      key={i}
                      defaultValue={defaultValues}
                      variant="outlined"
                      size="small"
                    />
                  </FormControl>
                ) : (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>{items.title}</label>
                    <TextField
                      id="outlined-basic"
                      name="defaultvalue"
                      onChange={(e) => handleDefault(e)}
                      value={defaultValues}
                      key={i}
                      defaultValue={defaultValues}
                      variant="outlined"
                      size="small"
                    />
                  </FormControl>
                );
              })}

              {/* (items?.answer === null ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField id="outlined-basic"
                  name="defaultvalue"
                  onChange={handleDefault}
                  value={defaultValues}
                  // defaultValue={listData.title}
                  variant="outlined" size="small" />
              </FormControl>
              ) : (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField id="outlined-basic"
                  name="defaultvalue"
                  onChange={handleDefault}
                  value={defaultValues}
                  key={items.answer}
                  defaultValue={items.answer}
                  variant="outlined" size="small" />
              </FormControl>
              )) */}

              {/* <FormControl fullWidth>
                <label>2. Record Audio</label>
                <RadioGroup row name="row-radio-buttons-group" sx={{ p: 0 }}>
                  <FormControlLabel
                    value="female"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <label>3. Take Image</label>
                <RadioGroup row name="row-radio-buttons-group" sx={{ p: 0 }}>
                  <FormControlLabel
                    value="female"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <label>4. Always allow Comment</label>
                <RadioGroup row name="row-radio-buttons-group" sx={{ p: 0 }}>
                  <FormControlLabel
                    value="female"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <label>5. Is image optional?</label>
                <RadioGroup row name="row-radio-buttons-group" sx={{ p: 0 }}>
                  <FormControlLabel
                    value="female"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <label>6. Is comment required?</label>
                <RadioGroup row name="row-radio-buttons-group" sx={{ p: 0 }}>
                  <FormControlLabel
                    value="female"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl> */}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                Rules Preset
              </Box>
              <Card sx={{ p: 1, mb: 1 }} className="card">
                <Grid container spacing={1} sx={{ textAlign: "left" }}>
                  <Grid item xs={12} sm={12} md={12} lg={11}>
                    <Stack direction="row" spacing={1} className="list-btn">
                      <Typography>When Answer is</Typography>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        className="select-btn"
                      >
                        Select Condition
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                      </Menu>
                      <Typography>to</Typography>
                      <Button
                        className="select-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        Select Answer
                      </Button>
                      <Typography>then allow comment.</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1}>
                    <Box sx={{ float: "right" }} className="plus-icon">
                      <AddOutlinedIcon sx={{ mr: 1 }} className="add-icon" />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ p: 1, mb: 1 }} className="card">
                <Grid container spacing={1} sx={{ textAlign: "left" }}>
                  <Grid item xs={12} sm={12} md={12} lg={11}>
                    <Stack direction="row" spacing={1} className="list-btn">
                      <Typography>When Answer is</Typography>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        className="select-btn"
                      >
                        Select Condition
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                      </Menu>
                      <Typography>to</Typography>
                      <Button
                        className="select-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        Select Answer
                      </Button>
                      <Typography>then create a issue with template</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1}>
                    <Box sx={{ float: "right" }} className="plus-icon">
                      <AddOutlinedIcon sx={{ mr: 1 }} className="add-icon" />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ p: 1 }} className="card">
                <Grid container spacing={1} sx={{ textAlign: "left" }}>
                  <Grid item xs={12} sm={12} md={12} lg={11}>
                    <Stack direction="row" spacing={1} className="list-btn">
                      <Typography>When Answer is</Typography>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        className="select-btn"
                      >
                        Select Condition
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                      </Menu>
                      <Typography>to</Typography>
                      <Button
                        className="select-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        Select Answer
                      </Button>
                      <Typography>then allow image.</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1}>
                    <Box sx={{ float: "right" }} className="plus-icon">
                      <AddOutlinedIcon sx={{ mr: 1 }} className="add-icon" />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>Yes</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>No</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>Acceptable</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    className="issueinput-fields"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>Not Acceptable</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>Discarded</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>Returned</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} className="marginbottom">
                <Grid item md={6} sx={12} sm={12} lg={2}>
                  <Typography>Not Applicable</Typography>
                </Grid>
                <Grid item md={6} sx={12} sm={12} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    className="issueinput-fields"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Box>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Select Question Group</label>
            <Select
              size="small"
              value={groupQuestion}
              onChange={handleGroupQuestion}
              sx={{ borderRadius: "8px" }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {dataRows?.map((option, i) => {
                return (
                  <MenuItem key={i} value={option.id} className="select-item">
                    {option.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Grid container spacing={1} className="display">
              <Grid item md={6} sx={12} sm={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={kpiChecked}
                      onChange={handleKpiChecked}
                    />
                  }
                  label="Is KPI Question?"
                />
              </Grid>
              <Grid item md={6} sx={12} sm={12} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedValue}
                      onChange={handleStoreChecked}
                    />
                  }
                  label="Is Store Question?"
                />
              </Grid>
            </Grid>
          </FormControl>
          {checkedValue === true ? (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Map this Question to</label>
              <Select
                size="small"
                sx={{ borderRadius: "8px" }}
                value={storeMap}
                onChange={handleMapStore}
                renderValue={(selected) => {
                  const valueType = typeof selected;
                  return selected && valueType == "string"
                    ? selected
                    : selected.title;
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {storemap?.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option} className="select-item">
                      {option.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
        </Container>
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </>
  );
};

export default EditQuestion;
