/** @format */

import {
  CHAT_ATTACHMENT_ERROR,
  CHAT_ATTACHMENT_REQUEST,
  CHAT_ATTACHMENT_SUCCESS,
  CHAT_HISTORY_ERROR,
  CHAT_HISTORY_REQUEST,
  CHAT_HISTORY_SUCCESS,
  CHAT_USER_ERROR,
  CHAT_USER_REQUEST,
  CHAT_USER_SUCCESS,
  DELETE_CHAT_NOTIFICATION_ERROR,
  DELETE_CHAT_NOTIFICATION_REQUEST,
  DELETE_CHAT_NOTIFICATION_SUCCESS,
  GET_CHAT_NOTIFICATION_ERROR,
  GET_CHAT_NOTIFICATION_REQUEST,
  GET_CHAT_NOTIFICATION_SUCCESS,
  GROUP_CHAT_EDIT_ERROR,
  GROUP_CHAT_EDIT_REQUEST,
  GROUP_CHAT_EDIT_SUCCESS,
  GROUP_CHAT_ERROR,
  GROUP_CHAT_REQUEST,
  GROUP_CHAT_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  attachedFile: [],
  chatUserList: [],
  chatHistory: [],
  chatGroupUserList: [],
  chatGroupUserEdit: [],
  deleteNotification: [],
  getChatNotification: [],
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_ATTACHMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHAT_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        attachedFile: action?.payload,
        loading: false,
      };
    }
    case CHAT_ATTACHMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CHAT_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHAT_USER_SUCCESS: {
      return {
        ...state,
        chatUserList: action?.payload,
        loading: false,
      };
    }
    case CHAT_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CHAT_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CHAT_HISTORY_SUCCESS: {
      return {
        ...state,
        chatHistory: action?.payload,
        loading: false,
      };
    }
    case CHAT_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case GROUP_CHAT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GROUP_CHAT_SUCCESS: {
      return {
        ...state,
        chatGroupUserList: action?.payload,
        loading: false,
      };
    }
    case GROUP_CHAT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case GROUP_CHAT_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GROUP_CHAT_EDIT_SUCCESS: {
      return {
        ...state,
        chatGroupUserEdit: action?.payload,
        loading: false,
      };
    }
    case GROUP_CHAT_EDIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case GET_CHAT_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case GET_CHAT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        getChatNotification: action?.payload,
        loading: false,
      };
    }
    case GET_CHAT_NOTIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case DELETE_CHAT_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_CHAT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        deleteNotification: action?.payload,
        loading: false,
      };
    }
    case DELETE_CHAT_NOTIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default ChatReducer;
