/** @format */

import {
  Box,
  Card,
  CardContent,
  Divider,
  TablePagination,
  Typography,
  Grid,
} from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Link } from "material-ui-core";
import React, { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  AllTrainingReportApi,
  CompletedCourseApi,
  OnGoingApi,
} from "../../../redux/actions/LmsAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Progressbar from "../../../GlobalProgress/Progressbar";
import ReactApexChart from "react-apexcharts";
import { AuditorContext } from "../../../../context/auditorContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ForwardIcon from "@mui/icons-material/Forward";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",

    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}></Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyTrainingIndex = () => {
  const [trainingType, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    console.log({ newValue });
  };

  console.log({ trainingType });

  const location = useLocation();
  let {
    storeId,
    storeTitle,
    companyId,
    currentAssessment,
    setcurrentAssessment,
    selectedStore,
  } = useContext(AuditorContext);
  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  useEffect(() => {
    if (storeId && storeTitle && companyId) {
    }
    console.log(storeId, "storeidcontext");
  }, []);
  const [series, setSeries] = useState([0]);
  const options = {
    chart: {
      height: 120,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 3,
            color: "#4caf50",
          },
        },
      },
    },
    labels: ["Cricket"],
    stroke: {
      lineCap: "round",
    },
    track: {
      background: "#f2f2f2",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ff9800"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
      },
    },
  };
  // Determine the color based on the series value
  if (series[0] < 50) {
    options.fill.gradient.gradientToColors = ["#ff9800"];
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allTrainingData, setAllTrainingData] = useState([]);
  const [onGoingCourseData, setOnGoingCourseData] = useState([]);
  const [completedCourseData, setcompletedCourseData] = useState([]);
  const [totalCount1, setTotalCount1] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [totalCount2, setTotalCount2] = useState("");
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [daysDiff, setDaysDiff] = useState("");

  const allTrainingList = useSelector(
    (state) =>
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.training
  );
  const totalpage = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.data &&
      state.lms.data.data &&
      state.lms.data.data.data &&
      state.lms.data.data.data.paginate
  );

  const onGoingDataList = useSelector(
    (state) =>
      state.lms &&
      state.lms.onGoingCourse &&
      state.lms.onGoingCourse?.data &&
      state.lms.onGoingCourse?.data?.data &&
      state.lms.onGoingCourse?.data?.data?.training
  );

  const onGoingTotalpage = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.onGoingCourse &&
      state.lms.onGoingCourse.data &&
      state.lms.onGoingCourse.data.data &&
      state.lms.onGoingCourse.data.data.paginate
  );

  const completedCourseDataList = useSelector(
    (state) =>
      state.lms &&
      state.lms.completedCourse &&
      state.lms.completedCourse?.data &&
      state.lms.completedCourse?.data?.data &&
      state.lms.completedCourse?.data?.data?.training
  );

  const completedTotalpage = useSelector(
    (state) =>
      state &&
      state.lms &&
      state.lms.completedCourse &&
      state.lms.completedCourse.data &&
      state.lms.completedCourse.data.data &&
      state.lms.completedCourse.data.data.paginate
  );

  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const paginationRequest = {
    body: {
      pagination: {
        page: page + 1,
        per_page: rowsPerPage,
      },
      archived: false,
      status: "nottaken",
    },
  };

  const paginationRequest1 = {
    body: {
      pagination: {
        page: page1 + 1,
        per_page: rowsPerPage1,
      },
      archived: false,
      status: "ongoing",
    },
  };

  const paginationRequest2 = {
    body: {
      pagination: {
        page: page2 + 1,
        per_page: rowsPerPage2,
      },
      archived: false,
      status: "completed",
    },
  };

  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("totalSteps");
    localStorage.removeItem("trainingId");
    localStorage.removeItem("selectedStep");
  }, []);

  useEffect(() => {
    if (totalpage) {
      setTotalCount(totalpage?.total_item);
    }
  }, [totalpage]);

  useEffect(() => {
    if (onGoingTotalpage) {
      setTotalCount1(onGoingTotalpage?.total_item);
    }
  }, [onGoingTotalpage]);

  useEffect(() => {
    if (completedTotalpage) {
      setTotalCount2(completedTotalpage?.total_item);
    }
  }, [completedTotalpage]);

  useEffect(() => {
    if (allTrainingList) {
      setAllTrainingData(allTrainingList);
    }
  }, [allTrainingList]);

  useEffect(() => {
    if (onGoingDataList) {
      setOnGoingCourseData(onGoingDataList);
    }
  }, [onGoingDataList]);

  useEffect(() => {
    if (completedCourseDataList) {
      setcompletedCourseData(completedCourseDataList);
    }
  }, [completedCourseDataList]);

  useEffect(() => {
    setPage(0);
    getApi(paginationRequest);
  }, [page, rowsPerPage, storeId, companyId]);

  useEffect(() => {
    setPage1(0);
    getApi1(paginationRequest1);
  }, [page1, rowsPerPage1, storeId, companyId]);

  useEffect(() => {
    setPage1(0);
    getApi2(paginationRequest2);
  }, [page2, rowsPerPage2, storeId, companyId]);

  useEffect(() => {
    allTrainingData.forEach((trainingItem) => {
      const startsAt = trainingItem?.starts_at;
      const expiresAt = trainingItem?.expires_at;
      let resp = null;
      if (startsAt && expiresAt) {
        const timeDiff = new Date(expiresAt) - new Date(startsAt);
        resp = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        setDaysDiff(resp);
      }
    });
  }, [allTrainingData]);

  const getApi = (paginationRequest) => {
    const url = `${BASE_URL}training/index?company_id=${companyId}`;
    if (storeId) {
      paginationRequest.url = `${url}&storeId=${storeId}`;
    } else {
      paginationRequest.url = url;
    }
    dispatch(AllTrainingReportApi(paginationRequest));
  };

  const getApi1 = (paginationRequest1) => {
    const url = `${BASE_URL}training/index?company_id=${companyId}`;
    if (storeId) {
      paginationRequest1.url = `${url}&storeId=${storeId}`;
    } else {
      paginationRequest1.url = url;
    }
    dispatch(OnGoingApi(paginationRequest1));
  };

  const getApi2 = (paginationRequest2) => {
    const url = `${BASE_URL}training/index?company_id=${companyId}`;
    if (storeId) {
      paginationRequest2.url = `${url}&storeId=${storeId}`;
    } else {
      paginationRequest2.url = url;
    }
    dispatch(CompletedCourseApi(paginationRequest2));
  };

  const handleSelectedAssessment = (item) => {
    console.log("item", item);
    localStorage.setItem("selectedAssessment", JSON.stringify(item));
    setcurrentAssessment(item);
    navigate(`/auditor-home/my-trainings/my-trainings-view`, {
      // state: item?._id,
      state: item,
    });
  };

  const handleLinkClick = (trainingItemId) => {
    // navigate(`/auditor-home/my-trainings/my-trainings-view`, {
    //   state: trainingItemId,
    // });
  };

  useEffect(() => {
    localStorage.removeItem("selectedAssessment");
  }, []);

  useEffect(() => {
    console.log(currentAssessment, "currentAssessmentFromContext");
  }, [handleSelectedAssessment]);

  console.log(selectedStore, "selectedStore");

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    paginationRequest.body.pagination.per_page = newRowsPerPage;
    getApi(paginationRequest);
  };

  const handleChangeRowsPerPage1 = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage1(newRowsPerPage);
    setPage1(0);
    paginationRequest1.body.pagination.per_page = newRowsPerPage;
    getApi1(paginationRequest1);
  };

  const handleChangeRowsPerPage2 = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage2(newRowsPerPage);
    setPage2(0);
    paginationRequest2.body.pagination.per_page = newRowsPerPage;
    getApi2(paginationRequest2);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  return (
    <>
      {Loader ? (
        <Progressbar />
      ) : (
        <Box>
          <ToastContainer autoClose={3000} />

          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              borderRadius: 1,
              overflowX: "auto",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
              },
            }}
          >
            {/* Ongoing Courses  */}
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: 1,
                  borderColor: "divider",
                  justifyContent: "space-between",
                }}
              >
                <Tabs
                  value={trainingType}
                  onChange={handleChangeTab}
                  textColor="#214d69"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "#ef6b3b",
                      color: "#214d69",
                      textColor: "#214d69",
                    },
                  }}
                >
                  <Tab label="New" {...a11yProps(0)} />
                  <Tab label="On going" {...a11yProps(1)} />
                  <Tab label="Completed" {...a11yProps(2)} />
                </Tabs>

                <Tabs textColor="#214d69">
                  <Tab label={storeTitle} />
                </Tabs>
              </Box>
              <TabPanel value={trainingType} index={0}>
                New
              </TabPanel>
              <TabPanel value={trainingType} index={1}>
                On going
              </TabPanel>

              <TabPanel value={trainingType} index={2}>
                Completed
              </TabPanel>
            </Box>
            <div className="content">
              <Box
                sx={{
                  mt: 0,
                  ml: 5,
                  mr: 5,
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "2rem",
                }}
              >
                {trainingType === 1 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {onGoingCourseData.length > 0 ? (
                        <Grid container spacing={3}>
                          {onGoingCourseData.map((trainingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  // width: "300px",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleSelectedAssessment(trainingItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "220px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#ef6b3b",
                                  }}
                                >
                                  Total Chapters: {trainingItem.steps.length} &
                                  Total Assessment:{" "}
                                  {trainingItem.assessment.length}
                                </div>
                                {console.log(trainingItem.steps.count)}
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(trainingItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      {trainingItem.name}
                                    </Link>
                                  </Typography>

                                  {/* ongoing changes here  */}
                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{ pt: 1 }}
                                  >
                                    <span>
                                      <strong>Summary: </strong>
                                      <Tooltip
                                        title={trainingItem.summary}
                                        placement="top"
                                      >
                                        <span>
                                          {trainingItem.summary.length > 46
                                            ? `${trainingItem.summary.substring(
                                                0,
                                                46
                                              )}...`
                                            : trainingItem.summary}
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </Typography>

                                  <Typography
                                    variant="body1"
                                    align="left"
                                    sx={{ mt: 0 }}
                                  >
                                    {trainingItem?.category ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "0px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              flexDirection: "column",
                                              display: "flex",
                                            }}
                                          >
                                            <span>
                                              <strong>Category: </strong>
                                              <Tooltip
                                                title={trainingItem.category}
                                                placement="top"
                                              >
                                                <span>
                                                  {trainingItem.category
                                                    .length > 8
                                                    ? `${trainingItem.category.substring(
                                                        0,
                                                        8
                                                      )}...`
                                                    : trainingItem.category}
                                                </span>
                                              </Tooltip>
                                            </span>
                                            <span style={{ display: "" }}>
                                              <strong>Sub Category: </strong>
                                              <Tooltip
                                                title={trainingItem.subCategory}
                                                placement="top"
                                              >
                                                <span>
                                                  {trainingItem.subCategory
                                                    .length > 7
                                                    ? `${trainingItem.subCategory.substring(
                                                        0,
                                                        7
                                                      )}...`
                                                    : trainingItem.subCategory}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </div>

                                          <Typography
                                            align="left"
                                            variant="body1"
                                            sx={{ mt: 0, display: "flex" }}
                                          >
                                            {trainingItem?.createdAt ? (
                                              <React.Fragment>
                                                <span
                                                  style={{
                                                    paddingBottom: 4,
                                                    paddingLeft: 2,
                                                  }}
                                                >
                                                  <AccessTimeSharpIcon
                                                    style={{ color: "#f55105" }}
                                                  />{" "}
                                                </span>
                                                {moment(
                                                  trainingItem?.createdAt
                                                ).format(
                                                  "YYYY/MM/DD HH:mm:ss"
                                                ) || (
                                                  <span
                                                    style={{
                                                      paddingBottom: 4,
                                                      paddingLeft: 2,
                                                    }}
                                                  >
                                                    <AccessTimeSharpIcon
                                                      style={{
                                                        color: "#f55105",
                                                      }}
                                                    />{" "}
                                                    N/A
                                                  </span>
                                                )}
                                              </React.Fragment>
                                            ) : (
                                              <span
                                                style={{
                                                  paddingBottom: 4,
                                                  paddingLeft: 2,
                                                }}
                                              >
                                                <AccessTimeSharpIcon
                                                  style={{ color: "#f55105" }}
                                                />{" "}
                                                N/A
                                              </span>
                                            )}
                                          </Typography>
                                        </div>
                                        <div
                                          id="chart"
                                          style={{
                                            marginLeft: "40px",
                                            position: "relative",
                                          }}
                                        >
                                          <ReactApexChart
                                            options={options}
                                            series={[
                                              Math.round(
                                                trainingItem?.completedPercentage
                                              ),
                                            ]}
                                            type="radialBar"
                                            height={120}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      "N/A"
                                    )}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No on going course available. Please check later...
                        </Typography>
                      )}
                    </div>

                    <div className="pagination">
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalCount1}
                        rowsPerPage={rowsPerPage1}
                        sx={{
                          width: { display: "flex", justifyContent: "center" },
                        }}
                        style={{
                          textAlign: "center",
                          background: "#214d69",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        page={page1}
                        SelectProps={{
                          inputProps: { "aria-label": "Items per Page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage1}
                        onRowsPerPageChange={handleChangeRowsPerPage1}
                        labelRowsPerPage="Items per Page"
                      />
                    </div>
                  </>
                )}

                {/* New Courses */}
                {trainingType === 0 && (
                  <>
                    <div className="content">
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {allTrainingData.length > 0 ? (
                        <Grid container spacing={3}>
                          {allTrainingData.map((trainingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleSelectedAssessment(trainingItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "170px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#ef6b3b",
                                  }}
                                >
                                  Total Chapters: {trainingItem.steps.length} &
                                  Total Assessment:{" "}
                                  {trainingItem.assessment.length}
                                </div>
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(trainingItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      {trainingItem.name}
                                    </Link>
                                  </Typography>
                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{ pt: 1 }}
                                  >
                                    <span>
                                      <strong>Summary: </strong>
                                      <Tooltip
                                        title={trainingItem.summary}
                                        placement="top"
                                      >
                                        <span>
                                          {trainingItem.summary.length > 46
                                            ? `${trainingItem.summary.substring(
                                                0,
                                                46
                                              )}...`
                                            : trainingItem.summary}
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </Typography>
                                  <div
                                    style={{ display: "flex", gap: "0.9rem" }}
                                  >
                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0 }}
                                    >
                                      <span>
                                        <strong>Category: </strong>
                                        <Tooltip
                                          title={trainingItem.category}
                                          placement="top"
                                        >
                                          <span>
                                            {trainingItem.category.length > 8
                                              ? `${trainingItem.category.substring(
                                                  0,
                                                  8
                                                )}...`
                                              : trainingItem.category}
                                          </span>
                                        </Tooltip>
                                      </span>
                                    </Typography>
                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0 }}
                                    >
                                      <span>
                                        <strong>Sub Category: </strong>
                                        <Tooltip
                                          title={trainingItem.subCategory}
                                          placement="top"
                                        >
                                          <span>
                                            {trainingItem.subCategory.length > 7
                                              ? `${trainingItem.subCategory.substring(
                                                  0,
                                                  7
                                                )}...`
                                              : trainingItem.subCategory}
                                          </span>
                                        </Tooltip>
                                      </span>
                                    </Typography>
                                    {/* </div> */}
                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0, display: "flex" }}
                                    >
                                      {trainingItem?.createdAt ? (
                                        <React.Fragment>
                                          <span
                                            style={{
                                              paddingBottom: 4,
                                              paddingLeft: 2,
                                            }}
                                          >
                                            <AccessTimeSharpIcon
                                              style={{ color: "#f55105" }}
                                            />{" "}
                                          </span>
                                          {moment(
                                            trainingItem?.createdAt
                                          ).format("YYYY/MM/DD HH:mm:ss") || (
                                            <span
                                              style={{
                                                paddingBottom: 4,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              <AccessTimeSharpIcon
                                                style={{ color: "#f55105" }}
                                              />{" "}
                                              N/A
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        <span
                                          style={{
                                            paddingBottom: 4,
                                            paddingLeft: 2,
                                          }}
                                        >
                                          <AccessTimeSharpIcon
                                            style={{ color: "#f55105" }}
                                          />{" "}
                                          N/A
                                        </span>
                                      )}
                                    </Typography>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        // Display text when no courses available
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No new course available. Please check later...
                        </Typography>
                      )}
                    </div>
                    {/* </Box> */}
                    <div className="pagination">
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        sx={{
                          width: { display: "flex", justifyContent: "center" },
                        }}
                        style={{
                          textAlign: "center",
                          background: "#214d69",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Items per Page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Items per Page"
                      />
                    </div>
                  </>
                )}
                {trainingType === 2 && (
                  <>
                    <div className="content">
                      {/* Completed Courses  */}
                      <Typography
                        variant="h5"
                        component="div"
                        align="left"
                        style={{ fontWeight: 400 }}
                      ></Typography>
                      {completedCourseData.length > 0 ? (
                        <Grid container spacing={3}>
                          {completedCourseData.map((trainingItem, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={6}>
                              <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                  mt: 2,
                                  mr: 3,
                                  borderRadius: 4,
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                  mb: 5,
                                }}
                                onClick={() =>
                                  handleSelectedAssessment(trainingItem)
                                }
                                style={{
                                  cursor: "pointer",
                                  height: "170px",
                                  borderRadius: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px",
                                    borderRadius: "0px",
                                    color: "white",
                                    backgroundColor: "#ef6b3b",
                                  }}
                                >
                                  Total Chapters: {trainingItem.steps.length} &
                                  Total Assessment:{" "}
                                  {trainingItem.assessment.length}
                                </div>
                                <CardContent>
                                  <Typography
                                    align="center"
                                    variant="body1"
                                    sx={{ mt: 0 }}
                                    className={
                                      trainingItem?.doBefore === 1
                                        ? classes.blinking
                                        : ""
                                    }
                                  >
                                    {trainingItem?.doBefore === 1
                                      ? "expires soon"
                                      : ""}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    align="left"
                                  >
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(trainingItem._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#214d69",
                                      }}
                                    >
                                      {trainingItem.name}
                                    </Link>
                                  </Typography>

                                  <Divider sx={{ mt: 1 }} />
                                  <Typography
                                    align="left"
                                    variant="body1"
                                    sx={{ pt: 1 }}
                                  >
                                    <span>
                                      <strong>Summary: </strong>
                                      <Tooltip
                                        title={trainingItem.summary}
                                        placement="top"
                                      >
                                        <span>
                                          {trainingItem.summary.length > 46
                                            ? `${trainingItem.summary.substring(
                                                0,
                                                46
                                              )}...`
                                            : trainingItem.summary}
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </Typography>
                                  <div
                                    style={{ display: "flex", gap: "0.9rem" }}
                                  >
                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0 }}
                                    >
                                      <span>
                                        <strong>Category: </strong>
                                        <Tooltip
                                          title={trainingItem.category}
                                          placement="top"
                                        >
                                          <span>
                                            {trainingItem.category.length > 8
                                              ? `${trainingItem.category.substring(
                                                  0,
                                                  8
                                                )}...`
                                              : trainingItem.category}
                                          </span>
                                        </Tooltip>
                                      </span>
                                    </Typography>

                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0 }}
                                    >
                                      <span>
                                        <strong>Sub Category: </strong>
                                        <Tooltip
                                          title={trainingItem.subCategory}
                                          placement="top"
                                        >
                                          <span>
                                            {trainingItem.subCategory.length > 7
                                              ? `${trainingItem.subCategory.substring(
                                                  0,
                                                  7
                                                )}...`
                                              : trainingItem.subCategory}
                                          </span>
                                        </Tooltip>
                                      </span>
                                    </Typography>

                                    <Typography
                                      align="left"
                                      variant="body1"
                                      sx={{ mt: 0, display: "flex" }}
                                    >
                                      {trainingItem?.createdAt ? (
                                        <React.Fragment>
                                          <span
                                            style={{
                                              paddingBottom: 4,
                                              paddingLeft: 2,
                                            }}
                                          >
                                            <AccessTimeSharpIcon
                                              style={{ color: "#f55105" }}
                                            />{" "}
                                          </span>
                                          {moment(
                                            trainingItem?.createdAt
                                          ).format("YYYY/MM/DD HH:mm:ss") || (
                                            <span
                                              style={{
                                                paddingBottom: 4,
                                                paddingLeft: 2,
                                              }}
                                            >
                                              <AccessTimeSharpIcon
                                                style={{ color: "#f55105" }}
                                              />{" "}
                                              N/A
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        <span
                                          style={{
                                            paddingBottom: 4,
                                            paddingLeft: 2,
                                          }}
                                        >
                                          <AccessTimeSharpIcon
                                            style={{ color: "#f55105" }}
                                          />{" "}
                                          N/A
                                        </span>
                                      )}
                                    </Typography>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        // Display text when no courses available
                        <Typography
                          align="center"
                          style={{
                            color: "#888",
                            fontWeight: 300,
                            marginTop: "20px",
                          }}
                        >
                          No completed course available. Please check later...
                        </Typography>
                      )}
                    </div>
                    {/* </Box> */}
                    <div className="pagination">
                      <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25]}
                        count={totalCount2}
                        rowsPerPage={rowsPerPage2}
                        sx={{
                          width: { display: "flex", justifyContent: "center" },
                        }}
                        style={{
                          textAlign: "center",
                          background: "#214d69",
                          color: "white",
                          borderRadius: "0px",
                        }}
                        page={page2}
                        SelectProps={{
                          inputProps: { "aria-label": "Items per Page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage2}
                        onRowsPerPageChange={handleChangeRowsPerPage2}
                        labelRowsPerPage="Items per Page"
                      />
                    </div>
                  </>
                )}
              </Box>
              {/* footer area started here */}
              <div className="footer">
                <Box sx={{ mt: 0, ml: 3, mr: 3 }}>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                    style={{ marginTop: "10px" }}
                  >
                    Copyright ©{" "}
                    <Link
                      href="https://www.hoperesearchgroup.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      HOPE RESEARCH GROUP
                    </Link>
                    . All rights reserved.
                  </Typography>
                </Box>
              </div>
            </div>
            {/* footer area ended here  */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MyTrainingIndex;
