import {
  NOTIFICATION_ERROR,
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  NotificationData: "",
};

const NotiFicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case NOTIFICATION_SUCCESS: {
      return {
        ...state,
        NotificationData: action?.payload,
        loading: false,
      };
    }
    case NOTIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default NotiFicationReducer;
