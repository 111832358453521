/** @format */

import React, { useState, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import TopPerformer from "./pages/TopPerformer";
import RecentAdded from "./pages/RecentAdded";
import UpcomingBirthday from "./pages/UpcomingBirthday";
import ChecklistPerformance from "./pages/ChecklistPerformance";
import CommonTable from "./pages/CommonTable";
import TrainingPerformance from "./pages/TrainingPerformance";
import AuditPerformance from "./pages/AuditPerformance";
import Header from "./pages/Header";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { UserTopFormerApi } from "../../redux/actions/User";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
const UserOverview = () => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState("");
  const [loader, setLoader] = useState(true);

  const data = {
    paramKey: "monthly",
  };
  const [performervalue, setPerformervalue] = useState("");

  const handleChange = (e) => {
    setPerformervalue(e.target.value);
  };
  // useEffect(() => {
  //   const companyId = localStorage.getItem("companyId");
  //   if (companyId) {
  //     data.url =
  //       BASE_URL +
  //       `/users/TopPerformer?company_id=${companyId}&UserPerformer=${performervalue}`;
  //     dispatch(UserTopFormerApi(data));
  //   }
  // }, [performervalue]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {
      setLoader(true);
      data.url =
        BASE_URL +
        `/users/TopPerformer?company_id=${companyId}&UserPerformer=${performervalue}`;
      dispatch(UserTopFormerApi(data));
    }
  }, [performervalue]);

  // Once the API call is completed, you can set the loader to false
  useEffect(() => {
    setLoader(false);
  }, [performervalue]);

  return (
    <Box>
      <Header value={performervalue} setSelectedData={handleChange} />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Container
          maxWidth="xl"
          sx={{
            bgcolor: "#F6F8FC",
            p: 1,
            pb: 5,
            mt: 2,
            borderRadius: 2,
            height: "80vh",
            overflowX: "hidden",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <TopPerformer />
            </Grid>
            <Grid item xs={12} md={4}>
              <RecentAdded />
            </Grid>
            {/* <Grid item xs={12} md={4}>
                        <UpcomingBirthday />
                    </Grid> */}
            <Grid item xs={12} md={8}></Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <ChecklistPerformance />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonTable />
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <TrainingPerformance />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonTable />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <AuditPerformance />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonTable />
          </Grid>
        </Grid> */}
        </Container>
      )}
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/" >HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default UserOverview;
