/** @format */

import {
  Box,
  Card,
  CardContent,
  Grid,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import {
  StoreWiseSingleUserChecklistGraphCountAction,
  StorewiseSingleUserChecklistReportAction,
  UserPerformanceCountAction,
} from "../../../redux/actions/Checklist";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import POHeader from "../Pages/POHeader";
import View from "../../../images/View.svg";
import ChartBar from "../../submissionTabs/ChartBar";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { ListSubroleApi } from "../../../redux/actions/Roles";

const defaultColumns = [
  {
    field: "NAME",
    headerName: "NAME",
    renderHeader: () => (
      <Tooltip title="NAME">
        <strong>{"NAME"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Name) {
        result.push(params?.row?.Name);
      } else {
        result = ["N/A"];
      }
      return result.join(", ");
    },
  },
  {
    field: "ASSIGNED",
    headerName: "ASSIGNED",
    renderHeader: () => (
      <Tooltip title="ASSIGNED">
        <strong>{"ASSIGNED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalcreated) {
        result.push(params?.row?.Totalcreated ? params?.row?.Totalcreated : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "COMPLETED",
    headerName: "COMPLETED",
    renderHeader: () => (
      <Tooltip title="COMPLETED">
        <strong>{"COMPLETED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalsubmit) {
        result.push(params?.row?.Totalsubmit ? params?.row?.Totalsubmit : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "MISSED",
    headerName: "MISSED",
    renderHeader: () => (
      <Tooltip title="MISSED">
        <strong>{"MISSED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalmissed) {
        result.push(params?.row?.Totalmissed ? params?.row?.Totalmissed : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
];

const UserPerformanceView = () => {
  const [series, setSeries] = useState([60]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Percent"],
  });

  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const storedMonthRecord = JSON.parse(localStorage.getItem("monthRecord"));
  const [monthRecord, setmonthRecord] = React.useState(storedMonthRecord);
  const [columns, setColumns] = React.useState([]);
  const [DynamicAllUser, setDynamicAllUser] = React.useState([]);
  const [GotStoreName, setGotStoreName] = React.useState("");
  const [GotUserName, setGotUserName] = React.useState("");
  const [GotUserNameFromSingleStore, setGotUserNameFromSingleStore] =
    React.useState("");
  const [subRole, setSubRole] = React.useState([]);

  // client side pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const UserPerformanceCountData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.StorewiseSingleUserChecklistReportResp &&
      state?.checklist?.StorewiseSingleUserChecklistReportResp?.data &&
      state?.checklist?.StorewiseSingleUserChecklistReportResp?.data?.data
  );

  const StorewiseSingleUserChecklistGraphCount = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.StoreWiseSingleUserChecklistGraphCountResp &&
      state?.checklist?.StoreWiseSingleUserChecklistGraphCountResp?.data &&
      state?.checklist?.StoreWiseSingleUserChecklistGraphCountResp?.data?.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    if (UserPerformanceCountData && UserPerformanceCountData.length > 0) {
      setDynamicAllUser(UserPerformanceCountData);
      let totlaColumns = [...defaultColumns];
      const index = 0;
      if (monthRecord == "OneDay") {
        const dateVal =
          UserPerformanceCountData[0].Submission &&
          UserPerformanceCountData[0].Submission.length &&
          UserPerformanceCountData[0].Submission[0].Date
            ? new Date(UserPerformanceCountData[0].Submission[0].Date)
            : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();
        const dayColumn = {
          field: "Submission",
          headerName: "Submission",
          renderHeader: () => (
            <Tooltip title={dateRes}>
              <strong>{dateRes}</strong>
            </Tooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let result = [];
            const dateValCount =
              params.row &&
              params.row.Submission &&
              params.row.Submission[0] &&
              params.row.Submission[0].submissionCount
                ? (params.row.Submission[index].submissionCount /
                    params.row.Submission[index].checklistCount) *
                  100
                : 0;
            result.push(Math.round(dateValCount) + "%");
            return result.join(", ");
          },
        };
        totlaColumns.push(dayColumn);
      } else if (monthRecord == "OneWeek") {
        if (
          UserPerformanceCountData[0].Submission &&
          UserPerformanceCountData[0].Submission.length
        ) {
          UserPerformanceCountData[0].Submission &&
            UserPerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes =
                dateVal
                  .toLocaleString("en-us", { weekday: "short" })
                  .toUpperCase() +
                " " +
                (dateVal.getDate() < 10
                  ? "0" + dateVal.getDate()
                  : dateVal.getDate()) +
                " " +
                dateVal
                  .toLocaleString("en-us", { month: "short" })
                  .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[index].submissionCount
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;
                  result.push(Math.round(dateValCount) + "%");
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      } else {
        if (
          UserPerformanceCountData[0].Submission &&
          UserPerformanceCountData[0].Submission.length
        ) {
          UserPerformanceCountData[0].Submission &&
            UserPerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes = dateVal
                .toLocaleString("en-us", {
                  month: "short",
                  year: "numeric",
                })
                .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[index].submissionCount
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;
                  result.push(Math.round(dateValCount) + "%");
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      }
      setColumns([...totlaColumns]);
    }
  }, [UserPerformanceCountData]);

  useEffect(() => {
    const RecivedStoreNameFromDashBoard =
      localStorage.getItem("StoreNameFromDashBoard") ||
      localStorage.getItem("StoreNameFromViewAllStoreList");
    setGotStoreName(RecivedStoreNameFromDashBoard);

    const RecivedUserNameFromDashBoard =
      localStorage.getItem("UserNameFromFromDashBoard") ||
      localStorage.getItem("UserNameFromViewAllUserList");

    setGotUserName(RecivedUserNameFromDashBoard);

    const RecivedUserNameFromSingleStoreViewPage = localStorage.getItem(
      "UserNameFromSingleStoreViewPage"
    );
    setGotUserNameFromSingleStore(RecivedUserNameFromSingleStoreViewPage);
  }, []);

  useEffect(() => {
    if (monthRecord && subRole) {
      const RecivedStoreId =
        localStorage.getItem("StoreIdFromViewAllStoreList") ||
        localStorage.getItem("StoreIdFromDashBoard");

      const ReceivedUserId =
        localStorage.getItem("UserIdFromSingleStoreViewPage") ||
        localStorage.getItem("UserIdFromFromDashBoard") ||
        localStorage.getItem("UserIdFromViewAllUserList");

      const id = localStorage.getItem("companyId");

      let url = BASE_URL;
      if (RecivedStoreId && ReceivedUserId) {
        url += `StorewiseSingleUserChecklistReport?company_id=${id}&Filter=${monthRecord}&StoreId=${RecivedStoreId}&UserId=${ReceivedUserId}&subRole=[${subRole}]`;
      } else {
        url += `StorewiseSingleUserChecklistReport?company_id=${id}&Filter=${monthRecord}&UserId=${ReceivedUserId}&subRole=[${subRole}]`;
      }
      const data = { url };
      dispatch(StorewiseSingleUserChecklistReportAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    if (monthRecord && subRole) {
      const RecivedStoreId =
        localStorage.getItem("StoreIdFromViewAllStoreList") ||
        localStorage.getItem("StoreIdFromDashBoard");

      const ReceivedUserId =
        localStorage.getItem("UserIdFromSingleStoreViewPage") ||
        localStorage.getItem("UserIdFromFromDashBoard") ||
        localStorage.getItem("UserIdFromViewAllUserList");

      const id = localStorage.getItem("companyId");

      let url = BASE_URL;
      if (RecivedStoreId && ReceivedUserId) {
        url += `StorewiseSingleUserChecklistGraphCount?company_id=${id}&Filter=${monthRecord}&StoreId=${RecivedStoreId}&UserId=${ReceivedUserId}&subRole=[${subRole}]`;
      } else {
        url += `StorewiseSingleUserChecklistGraphCount?company_id=${id}&Filter=${monthRecord}&UserId=${ReceivedUserId}&subRole=[${subRole}]`;
      }

      const data = { url };
      dispatch(StoreWiseSingleUserChecklistGraphCountAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  }, []);

  const handleChangeSelect = (event) => {
    setmonthRecord(event.target.value);
  };

  const renderCharts = () => {
    return StorewiseSingleUserChecklistGraphCount &&
      StorewiseSingleUserChecklistGraphCount?.length ? (
      StorewiseSingleUserChecklistGraphCount.map((res, sKey) => {
        // const percentage = (
        //   (res.submissionCount / res?.checklistCount) *
        //   100
        // ).toFixed(2);
        const percentage = Math.round(
          (res.submissionCount / res?.checklistCount) * 100
        );
        const formattedPercentage = isNaN(percentage) ? 0 : percentage;
        const dateVal = res.Date ? new Date(res.Date) : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();

        return (
          <Grid
            item
            xs={12}
            md={12 / StorewiseSingleUserChecklistGraphCount?.length}
            lg={12 / StorewiseSingleUserChecklistGraphCount?.length}
            key={sKey}
          >
            {/* <Card sx={{ px: 1 }} className="card">
              <CardContent>
                <Typography sx={{ lineHeight: "0px", mt: 1 }} component="b">
                  <strong>{dateRes}</strong>
                </Typography>
                <ReactApexChart
                  options={options}
                  series={[formattedPercentage]}
                  type="radialBar"
                  height={200}
                />
              </CardContent>
            </Card> */}
            <Card sx={{ px: 1 }} className="card">
              <CardContent
                sx={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {Loader && <CircularProgress />}
                {!Loader && (
                  <>
                    <Typography sx={{ lineHeight: "0px", mb: 1 }} component="b">
                      <strong>{dateRes}</strong>
                    </Typography>
                    <ReactApexChart
                      options={{
                        ...options,
                        dataLabels: {
                          style: {
                            fontSize: "14px",
                            colors: ["#333"],
                          },
                          offsetX: 0,
                          offsetY: 0,
                          background: {
                            enabled: true,
                            foreColor: "#fff",
                            padding: 5,
                            borderRadius: 2,
                            borderColor: "#333",
                          },
                        },
                      }}
                      series={[formattedPercentage]}
                      type="radialBar"
                      height={200}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })
    ) : (
      <Grid item xs={12} md={12} lg={12}>
        No Data Found For The Chart Here.
      </Grid>
    );
  };

  return (
    <div>
      <POHeader
        handleChangeSelect={handleChangeSelect}
        monthRecord={monthRecord}
      />
      <ToastContainer />

      {Loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-45px",
          }}
        >
          <Progressbar />
        </div>
      ) : UserPerformanceCountData ? (
        <Box>
          <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
            <Card
              sx={{
                height: "100%",
                marginBottom: "0.1%",
                textAlign: "left",
              }}
              className="card"
            >
              <Typography
                variant="body1"
                sx={{
                  marginLeft: "1%",
                }}
              >
                <label style={{ marginLeft: "1%" }}>
                  {GotStoreName && GotUserNameFromSingleStore
                    ? `Store: ${GotStoreName} / User: ${GotUserNameFromSingleStore}`
                    : `User: ${GotUserName || "N/A"}`}
                </label>
              </Typography>
            </Card>
            <Grid container spacing={2}>
              {renderCharts()}
            </Grid>
          </Card>

          <Card sx={{ marginTop: "1%" }} className="card">
            <CardContent>
              <Box
                sx={{
                  marginBottom: "2%",
                  // bgcolor: "#F6F8FC",
                  height: "63vh",
                  mt: 1,
                  p: 1,
                  borderRadius: "8px",
                  px: 1,
                  "&::-webkit-scrollbar": {
                    width: 15,
                  },
                  "&::-webkit-scrollbar-track": {
                    padding: "12px 5px",
                    backgroundColor: "#CBD4E1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#64748B",
                    borderRadius: "8px",
                  },
                  scrollbarWidth: "none",
                  "-ms-overflow-style": "none",
                }}
              >
                {Loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Progressbar />
                  </div>
                ) : DynamicAllUser ? (
                  <>
                    <DataGrid
                      rows={DynamicAllUser.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )}
                      columns={columns}
                      // getRowId={(DynamicAllUser) => DynamicAllUser?._id}
                      getRowId={(DynamicAllUser) => DynamicAllUser?.Name}
                      hideFooterPagination={true}
                      className="grid-table"
                      disableColumnMenu
                      disableSelectionOnClick={true}
                    />
                    <Box
                      mb={5}
                      sx={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={DynamicAllUser.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Box>
                  </>
                ) : (
                  "No Data Found"
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        "No Data Found"
      )}
    </div>
  );
};

export default UserPerformanceView;
