import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stepper,
  Typography,
  StepLabel,
  Step,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SignupApi, SignupFormApi, VerifyCodeApi } from "../../redux/actions/SignUp";
import FirstForm from "./StepsForm/FirstStep";
import SecondSteps from "./StepsForm/SecondSteps";
import ThirdForm from "./StepsForm/ThirdStep";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

const steps = ["Enter Email", "Verify Email", "Register"];

const SignUpForm = () => {

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [emails, setEmails] = useState('');

  const verifycodeStatus = useSelector(state => state && state.signUp && state.signUp.verifyCode && state.signUp.verifyCode.status);
  const emailStatus = useSelector(state => state && state.signUp && state.signUp.data && state.signUp.data.data && state.signUp.data.data.email);

  useEffect(() => {
    if (verifycodeStatus) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [verifycodeStatus])

  useEffect(() => {
    if (emailStatus) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [emailStatus])

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onSubmitForm = (values) => {
    setEmails(values?.email)
    if (values) {
      const allData = {
        body: values,
      }
      allData.url = BASE_URL + `auth/send/email/verification`
      dispatch(SignupApi(allData));
    }

  }
  const onSubmitVerify = (values) => {
    const emailcode = {
      'email': emails,
      'verificationCode': values
    }
    const allData = {
      body: emailcode,
    }
    allData.url = BASE_URL + `auth/verify/email/verification`
    dispatch(VerifyCodeApi(allData))
  }

  const onFinalSubmit = (values) => {
    const allDataForm = {
      'first_name': values.first_name,
      'last_name': values.last_name,
      'country_code': values.country_code,
      'contact': values.mobile_number,
      'email': emails,
      'password': values.password,
    }
    if (allDataForm) {
      const allData = {
        body: allDataForm,
      }
      allData.url = BASE_URL + `sessions/new`
      dispatch(SignupFormApi(allData))
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  return (
    <>
      <Box className="Login-form">
        <ToastContainer autoClose={3000} />
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card sx={{ p: 4, mb: 1 }} className="signup-form">
                <h1
                  style={{
                    textAlign: "center",
                    color: "#64748B",
                    marginBottom: "50px",
                  }}
                >
                  <b className="label-name">SignUp</b>
                </h1>

                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {activeStep === 0 ? (
                        <Box className="step1-box">
                          <FirstForm
                            handleBack={handleBack}
                            activeStep={activeStep}
                            steps={steps}
                            setActiveStep={setActiveStep}
                            onSubmitForm={onSubmitForm}
                          />
                        </Box>
                      ) : activeStep === 1 ? (
                        <Box className="step1-box">
                          <SecondSteps
                            handleBack={handleBack}
                            activeStep={activeStep}
                            steps={steps}
                            setActiveStep={setActiveStep}
                            onSubmitVerify={onSubmitVerify}
                          />
                        </Box>
                      ) : (
                        <Box className="step1-box">
                          <ThirdForm
                            handleBack={handleBack}
                            activeStep={activeStep}
                            steps={steps}
                            setActiveStep={setActiveStep}
                            onFinalSubmit={onFinalSubmit}
                          />
                        </Box>
                      )}
                    </React.Fragment>
                  )}
                </Box>
                <Box>
                  <Box className="login-futter">
                    <Box sx={{ m: 2 }}>
                      <Link to={"/"} className="forgot-link">
                        Log in
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SignUpForm;
