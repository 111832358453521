/** @format */

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Tooltip,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { AuditorContext } from "../../../../context/auditorContext";
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useRef } from "react";
import * as moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import DoneIcon from "@mui/icons-material/Done";

const ReviewDialog = () => {
  let { storeTitle } = useContext(AuditorContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRemainingData, setSelectedRemainingData] = useState(null);
  const [formData, setFormData] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [selfie, setSelfie] = useState(true);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const isValidDateTime = (value) =>
    moment(value, moment.ISO_8601, true).isValid();
  const isValidDate = (value) => moment(value, "YYYY-MM-DD", true).isValid();
  const isValidTime = (value) => moment(value, "HH:mm:ss", true).isValid();

  useEffect(() => {
    const formSelfie = localStorage.getItem("formSelfie");
    const storedData = localStorage.getItem("IndivisualFormData");
    const parsedData = JSON.parse(storedData);
    setSelectedRemainingData(parsedData);
    setSelfie(formSelfie);
  }, []);

  console.log("SubmitChecklist", selectedRemainingData);
  console.log("formData", formData);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const storedAnswers = localStorage.getItem(
      "answeredQuestions_" + selectedRemainingData?._id
    );
    const parsedAnswers = JSON.parse(storedAnswers);
    if (parsedAnswers) {
      setFormData(parsedAnswers);
    }
  }, [selectedRemainingData]);

  if (!formData) return null;
  return (
    <Box>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          // height: "75vh",
          mt: 1,
          p: 1,
          borderRadius: 1,
          overflowX: "auto",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <form>
            {formData?.length && formData?.map((question, questionIndex) => (
              <Card
                key={questionIndex}
                sx={{
                  p: 2,
                  mt: 2,
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  align="left"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <Typography
                    style={{
                      background: "#f26539",
                      padding: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span style={{ color: "white", fontWeight: "bold" }}>
                      Question. {questionIndex + 1}
                    </span>{" "}
                  </Typography>
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <strong>{question.title}</strong>{" "}
                      </span>
                      <Tooltip
                        title={question.title}
                        arrow
                        placement="right"
                        style={{
                          backgroundColor: "#f26539",
                          marginLeft: "0.5em",
                        }}
                      >
                        <span
                          style={{
                            cursor: "help",
                            color: "#f26539",
                          }}
                        >
                          <InfoIcon fontSize="small" />
                        </span>
                      </Tooltip>
                    </Box>
                    <Box>
                      <strong>Ans. </strong>
                      {typeof formData[questionIndex]?.currentAnswer?.answer
                        ?.value === "object"
                        ? JSON.stringify(
                            formData[questionIndex]?.currentAnswer?.answer
                              ?.value
                          )
                        : (() => {
                            const value =
                              formData[questionIndex]?.currentAnswer?.answer
                                ?.value;
                            const answerType =
                              formData[questionIndex]?.currentAnswer?.type;
                            console.log("answerType:", answerType);

                            switch (answerType) {
                              case "boolean":
                                return value.toString();
                              case "numeric":
                                return Number(value).toString();
                              case "text":
                                return value;
                              case "single_select":
                                return formData[questionIndex]?.currentAnswer
                                  ?.answer?.title;
                              case "multi_select":
                                return formData[
                                  questionIndex
                                ]?.currentAnswer?.answer
                                  .map((ans) => ans.title)
                                  .join(", ");
                              case "file":
                                const mediaName =
                                  formData[questionIndex]?.currentAnswer?.answer
                                    ?.answer;
                                return mediaName || value;
                              case "date":
                                return moment(value).format("MM/DD/YYYY");
                              case "datetime":
                                return (
                                  formData[questionIndex]?.currentAnswer?.answer
                                    ?.date +
                                  " " +
                                  formData[questionIndex]?.currentAnswer?.answer
                                    ?.time
                                );
                              case "time":
                                return value;
                              case "timer":
                                return value;
                              default:
                                return value;
                            }
                          })()}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      marginTop: "12px",
                      display: "grid",
                      flexWrap: "wrap",
                      gap: "12px",
                      gridTemplateColumns: "auto auto auto",
                    }}
                  >
                    {formData[questionIndex]?.image?.map((item) => (
                      <Box>
                        <img
                          src={item.path}
                          alt={item.fileName}
                          width={50}
                          height={50}
                          style={{
                            borderRadius: "12px",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Card>
            ))}
          </form>
        )}
      </Box>
    </Box>
  );
};

export default ReviewDialog;
