import React, { useEffect } from "react";
import { Box } from "@mui/material";
import AddAssessment from "./AddAssessment";
import { useSelector, useDispatch } from "react-redux";
import { AssessmentUpdateListApi } from "../../../redux/actions/LmsAction";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
const UpdateAssessment = () => {
  // const { id } = useParams();
  const location = useLocation();
  const id = location?.state?.id;
  const dispatch = useDispatch();
  const editData = useSelector(
    (state) =>
      state &&
      state.lms.assessmentUpdateData &&
      state.lms.assessmentUpdateData.data &&
      state.lms.assessmentUpdateData.data.data
  );

  useEffect(() => {
    if (id) {
      const companyId = localStorage.getItem("companyId");
      const data = {
        url: `${BASE_URL}assessment/${id}?company_id=${companyId}`,
      };
      dispatch(AssessmentUpdateListApi(data));
    }
  }, [id]);
  return (
    <Box>
      <AddAssessment
      id={id}
        assessmentUpdateData={editData}
        UpdateAssessment="UpdateAssessment"
      />
    </Box>
  );
};

export default UpdateAssessment;
