import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";

import {
  PAYMENT_ERROR,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_DONE_REQUEST,
  PAYMENT_DONE_SUCCESS,
  PAYMENT_DONE_ERROR,
  COMPANY_INFO_DETAIL_REQUEST,
  COMPANY_INFO_DETAIL_SUCCESS,
  COMPANY_INFO_DETAIL_ERROR,
  PAYMENT_HISTORY_LIST_ERROR,
  PAYMENT_HISTORY_LIST_REQUEST,
  PAYMENT_HISTORY_LIST_SUCCESS,
  PAYMENT_HISTORY_ERROR,
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
  MANUAL_PAYMENT_REQUEST,
  MANUAL_PAYMENT_SUCCESS,
  MANUAL_PAYMENT_ERROR,
} from "../types/Types";

export const PaymenHistoryRquest = () => ({
  type: PAYMENT_HISTORY_LIST_REQUEST,
});
export const PaymenHistorySuccess = (list) => {
  console.log("list", list);
  return {
    type: PAYMENT_HISTORY_LIST_SUCCESS,
    payload: list,
  };
};
export const PaymenHistoryFailed = (error) => ({
  type: PAYMENT_HISTORY_LIST_ERROR,
  payload: error,
});

export const PaymenHistoryApi = (data) => (dispatch) => {
  try {
    dispatch(PaymenHistoryRquest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        console.log("p resp", response);
        const data = response;
        dispatch(PaymenHistorySuccess(data));
      } else {
        dispatch(PaymenHistoryFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(PaymenHistoryFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const PaymentHistoryRquest = () => ({
  type: PAYMENT_HISTORY_REQUEST,
});
export const PaymentHistorySuccess = (list) => ({
  type: PAYMENT_HISTORY_SUCCESS,
  payload: list,
});
export const PaymentHistoryFailed = (error) => ({
  type: PAYMENT_HISTORY_ERROR,
  payload: error,
});

export const PaymentHistoryApi = (data) => (dispatch) => {
  try {
    dispatch(PaymentHistoryRquest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(PaymentHistorySuccess(data));
      } else {
        dispatch(PaymentHistoryFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(PaymentHistoryFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const PaymentRquest = () => ({
  type: PAYMENT_REQUEST,
});
export const PaymentSuccess = (list) => ({
  type: PAYMENT_SUCCESS,
  payload: list,
});
export const PaymentFailed = (error) => ({
  type: PAYMENT_ERROR,
  payload: error,
});

export const PaymentListApi = (data) => (dispatch) => {
  try {
    dispatch(PaymentRquest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(PaymentSuccess(data));
      } else {
        toast.error(response.response.data.message);
        dispatch(PaymentFailed(response.response.data.message));
      }
    });
  } catch (error) {
    toast.error(error.message);
    dispatch(PaymentFailed(error));
  }
};

export const PaymentDoneRquest = () => ({
  type: PAYMENT_DONE_REQUEST,
});
export const PaymentDoneSuccess = (list) => ({
  type: PAYMENT_DONE_SUCCESS,
  payload: list,
});
export const PaymentDoneFailed = (error) => ({
  type: PAYMENT_DONE_ERROR,
  payload: error,
});

export const PaymentSuccessListApi = (data) => (dispatch) => {
  try {
    dispatch(PaymentDoneRquest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(PaymentDoneSuccess(data));
      } else {
        dispatch(PaymentDoneFailed(response?.statusText));
      }
    });
  } catch (error) {
    console.log("error", error);
    dispatch(PaymentDoneFailed(error));
    toast.error(error.message);
  }
};

export const CompanyInfoDetailRquest = () => ({
  type: COMPANY_INFO_DETAIL_REQUEST,
});
export const CompanyInfoDetailSuccess = (list) => ({
  type: COMPANY_INFO_DETAIL_SUCCESS,
  payload: list,
});
export const CompanyInfoDetailFailed = (error) => ({
  type: COMPANY_INFO_DETAIL_ERROR,
  payload: error,
});

export const CompanyInfoDetailListApi = (data) => (dispatch) => {
  try {
    dispatch(CompanyInfoDetailRquest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response;
        dispatch(CompanyInfoDetailSuccess(data));
      } else {
        dispatch(CompanyInfoDetailFailed(response.response.data.message));
      }
    });
  } catch (error) {
    console.log("error", error);
    dispatch(CompanyInfoDetailFailed(error));
    toast.error(error.message);
  }
};

export const ManualPaymentRequest = () => ({
  type: MANUAL_PAYMENT_REQUEST,
});
export const ManualPaymentSuccess = (list) => ({
  type: MANUAL_PAYMENT_SUCCESS,
  payload: list,
});
export const ManualPaymentFailed = (error) => ({
  type: MANUAL_PAYMENT_ERROR,
  payload: error,
});

export const ManualPaymentApi = (data,callback) => (dispatch) => {
  try {
    dispatch(ManualPaymentRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        const data = response;
        dispatch(ManualPaymentSuccess(data));
        toast.success("Manual payment details added successfully");
        callback();
      } else {
        dispatch(ManualPaymentFailed(response?.statusText));
        toast.error("")
      }
    });
  } catch (error) {
    console.log("error", error);
    dispatch(ManualPaymentFailed(error));
    toast.error(error.message);
  }
};