import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, Box, Button, Chip, Divider, FormControl, Grid, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DoneIcon from "@mui/icons-material/Done";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Checkbox, FormControlLabel, Paper } from "@material-ui/core";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ReactExport from "react-export-excel-xlsx-fix";
import { ListUserChecklistApi } from "../redux/actions/User";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const filterAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ViewLogEntryHead = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const btnExportRef = useRef();
  const selectedLogEntry = localStorage.getItem("selectedLogEntry");
  const selectedLogEntryData = JSON.parse(selectedLogEntry);

  console.log("selectedLogEntryData", selectedLogEntryData);

  const [permission, setPermission] = useState({});
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [start_date, setstart_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [end_date, setend_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [filterStore, setFilterStore] = React.useState([]);
  const [filterUser, setFilterUser] = React.useState([]);
  const [subRole, setSubRole] = useState([]);
  const [questionsFilter, setQuestionFilter] = useState([]);
  const [questionsFilterValues, setQuestionsFilterValues] = useState({});
  const [exportLogSubmissionData, setExportLogSubmissionData] = useState([]);

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  const storeList = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.stores
  );

  const storeListBasedOnRole = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.storeData
  );

  const storeListBasedLoader = useSelector(
    (state) =>
      state?.user &&
      state?.user?.loading
  );

  const userListBasedOnStore = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.userIds
  )

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const logEntrySubmissionData = useSelector(
    (state) =>
      state.logEntry &&
      state.logEntry.logEntrySubmissionData &&
      state.logEntry.logEntrySubmissionData.data &&
      state.logEntry.logEntrySubmissionData.data.data &&
      state.logEntry.logEntrySubmissionData.data.data
  );

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  useEffect(() => {
    const filtableQues = selectedLogEntryData?.questions?.filter((question) => question?.allowFilter);
    setQuestionFilter(filtableQues || []);
  }, [logEntrySubmissionData]);

  console.log("logEntrySubmissionData", logEntrySubmissionData);
  const exportSubmission = () => {
    let subArr = [];

    let allColumnsList = [];
    let allCsvSubRows = [];
    const formattedSD = "Log Enntry Submission list";

    allColumnsList.push(
      {
        value: "S.no.",
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
          fill: {
            patternType: "solid",
            bgColor: { rgb: "FFFFAA00" },
            fgColor: { rgb: "FFFFAA00" },
          },
        },
      },
      {
        value: "User",
        widthPx: 100,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Role",
        widthPx: 100,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Store",
        widthPx: 100,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Updated At",
        widthPx: 200,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
    );

    logEntrySubmissionData?.logEntrySubmissions.map((item, index) => {
      const csvRow = [];

      csvRow.push(
        {
          value: `${index + 1 || "N/A"}`,
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.user?.name || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.user?.subRole || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.store?.title || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.updatedAt || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
      )

      item.questions.map((question) => {
        let result = "-";
        if (question.answer?.answer?.title) {
          result = `${question.answer?.answer?.title}`
        } else if (question.answer?.answer?.value) {
          result = `${question.answer?.answer?.value}`;
        } else if (question?.answer?.answer?.length) {
          result = question.answer.answer.map(ans => ans.title).join(',');
        } else if (question?.answer?.answer.date && question?.answer?.answer?.time) {
          result = `${question.answer.answer.date} ${question.answer.answer.time}`
        } else if (question?.answer?.answer?.answer?.length) {
          const mediaresult = question.answer.answer.answer.map(ans => `https://popprobe-saas.s3.us-west-2.amazonaws.com/${ans}`);
          result = mediaresult.join(',');
        }

        if (index === 0) {
          allColumnsList.push({
            value: question.title,
            widthPx: 200,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          });
        }

        csvRow.push({
          value: result || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        })
      });

      allCsvSubRows.push(csvRow);
    })

    const dataSet = [
      {
        columns: allColumnsList,
        data: allCsvSubRows,
      },
    ];
    console.log("dataSet", dataSet);
    subArr.push({ formattedSD, dataSet });

    if (subArr.length) {
      setExportLogSubmissionData(subArr);
      setTimeout(() => {
        btnExportRef.current.click();
      }, 1000);
    }
  }

  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  }

  const handleStoreClose = () => {
    if (filterStore && filterStore.length) {
      listUser(
        {
          body: {
            storeIds: filterStore.map((item) => item._id),
            userIds: [],
            subRole: subRole,
            startDate: new Date(),
            endDate: new Date(),
          },
        },
        "user"
      );
    }
  };

  const handleStoreChange = (event, newValue) => {
    if (newValue) {
      setFilterStore(newValue);
      setFilterUser([]);
    }
    if (!newValue.length) {
      listUser(
        {
          body: {
            storeIds: [],
            userIds: [],
            subRole: subRole,
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user"
      );
    }
  };


  const handleUserChange = (event, newValue) => {
    if (newValue) {
      setFilterUser(newValue);
    }
  };

  const listUser = (data, key) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `report/dropdown?company_id=${id}`;
    dispatch(ListUserChecklistApi(data, key));
  };

  useEffect(() => {
    listUser(
      {
        body: {
          storeIds: filterStore.map((item) => item._id),
          userIds: [],
          subRole: subRole,
          startDate: new Date(),
          endDate: new Date(),
        },
      },
      "user"
    );
  }, [subRole])


  const handleFilter = () => {
    // handleDateClick();
    // multiple choice
    let questionValue = []
    if (questionsFilterValues && Object.values(questionsFilterValues).length) {
      questionValue = [...Object.values(questionsFilterValues)];
      questionValue.map(item => {
        if (item.type === "multiple choice") {
          item.answer = item.answer.map(ans => { return { title: ans.title } })
        }
      })
    }
    const filterObject = {
      userId: filterUser,
      storeId: filterStore,
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      questions: questionValue,
    };

    localStorage.setItem("logEntryFilterData", JSON.stringify(filterObject));

    props.handleFilter(filterObject);
  };


  console.log("questionsFilterValues ===>", questionsFilterValues);

  return (
    <Box
      sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
      className="header-card"
    >
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        <Grid item xs={12} md={8} sm={12} lg={8}>
          <Box className="allform-head-time">
            <Typography sx={{ display: "flex", alignItems: "center", paddingLeft: { lg: 1 } }}>
              <small
                style={{
                  marginRight: "5px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                Created :{" "}
              </small>

              <b>{moment(selectedLogEntryData?.createdAt).format("lll")} &nbsp; </b>
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <small
                style={{
                  marginRight: "5px",
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                }}
              >
                Modified :{" "}
              </small>
              <b>{moment(selectedLogEntryData?.updatedAt).format("lll")}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sm={12} lg={4}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right", paddingRight: { lg: 1 } }}
            className="plus-icon"
          >
            {/* {permission?.checklist?.forms === true ? (
              <Link
                to="/log-entries/edit"
                className="submenu-item"
              >
                <Button
                  variant="contained"
                  // color="warning"
                  className="cancle-hover"
                  sx={{
                    height: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#F39A4C",
                    boxShadow: "none",
                  }}
                >
                  <BorderColorIcon sx={{ mr: 1 }} />
                  Edit
                </Button>
              </Link>
            ) : (
              ""
            )} */}

            {/* <Link to="/checklist/all-form" className="submenu-item"> */}
            <Button
              className="header-add cancle-hover"
              onClick={() => {
                setOpenFilterModal(true);
              }}
            >
              <FilterAltOutlinedIcon sx={{ mr: 1 }} />
              Filter
            </Button>
            <Stack
              direction="row"
              spacing={1}
              alignItems="left"
              justifyContent="flex-start"
              sx={{
                marginRight: "1px",
                marginLeft: "10px",
              }}
            >
              <Stack direction="row" alignItems="left">
                <Box>
                  <ExcelFile
                    element={
                      <Button
                        className="header-add cancle-hover"
                        style={{
                          whiteSpace: "nowrap",
                          width: "80%",
                          display: "none",
                        }}
                        ref={btnExportRef}
                      // onClick={() => getIssues()}
                      >
                        Export Excel
                        <FileDownloadIcon
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </Button>
                    }
                    filename={"PopprobeLogEntrySubmissionList_" + Date.now()}
                  >
                    {exportLogSubmissionData.map((csvData, key) => (
                      <ExcelSheet
                        key={key}
                        dataSet={csvData.dataSet}
                        name={csvData.formattedSD}
                      />
                    ))}
                  </ExcelFile>
                  <Button
                    className="header-add cancle-hover"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => exportSubmission()}
                  >
                    Export Excel
                    <FileDownloadIcon
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </Button>
                </Box>
              </Stack>
            </Stack>

            <Button

              className="header-add cancle-hover"
              onClick={() => {
                navigate("/log-entry");
                localStorage.removeItem("userId");
              }}
            >
              <DoneIcon sx={{ mr: 1 }} />
              Back
            </Button>
            {/* </Link> */}
            <div style={{ marginTop: "8px", marginRight: "5px" }}>
              <Tooltip
                title={
                  <div style={{ maxWidth: "300px" }}>
                    <strong>Log Entry Submission:</strong>
                    <p>
                      Display's Log Entry SUbmission data.
                    </p>
                  </div>
                }
                arrow
                interactive
              >
                <InfoIcon
                  style={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: "#D76C00",
                  }}
                />
              </Tooltip>
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Modal
        open={openFilterModal}
        onClose={modalopenFilterhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...filterAllstyle, p: 0 }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                pt: 2,
                px: 4,
              }}
            >
              <FormControl fullWidth sx={{ mb: 3 }}>
                <label>Store Location</label>

                <Autocomplete
                  options={
                    storeListBasedOnRole && storeListBasedOnRole.length ? storeListBasedOnRole : storeList && storeList.length ? storeList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select Store"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterStore}
                  onClose={handleStoreClose}
                  onChange={handleStoreChange}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterStore
                            ? filterStore.map(fs => fs._id).includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterStore
                            ? [...filterStore]
                            : [];
                          const selectedIndex =
                            newSelected.map(fs => fs._id).indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser([]);

                          setFilterStore(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1
                                  } more selected`}
                                label={`${value.length - 1
                                  } more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  // onClear={handleStoreClear}
                  multiple
                  disableCloseOnSelect
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterStore.length > 0 &&
                                filterStore.length ===
                                (storeListBasedOnRole.length ? storeListBasedOnRole.length : storeList.length)
                              ) {
                                handleStoreChange("", []);
                              } else {
                                handleStoreChange("", storeListBasedOnRole.length ? storeListBasedOnRole : storeList);
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterStore.length > 0 &&
                                  filterStore.length ===
                                  (storeListBasedOnRole.length ? storeListBasedOnRole.length : storeList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <label>User</label>

                <Autocomplete
                  options={
                    userListBasedOnStore && userListBasedOnStore.length ? userListBasedOnStore : userList && userList.length ? userList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select User"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterUser}
                  // loading={storeListBasedLoader}
                  loadingText={"Users Loading..."}
                  // onClose={handleUserClose}
                  onChange={handleUserChange}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterUser
                            ? filterUser.map(fs => fs._id).includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterUser
                            ? [...filterUser]
                            : [];
                          const selectedIndex =
                            newSelected.map(fs => fs._id).indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1
                                  } more selected`}
                                label={`${value.length - 1
                                  } more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  // onClear={handleUserClear}
                  multiple
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterUser.length > 0 &&
                                filterUser.length ===
                                (userListBasedOnStore && userListBasedOnStore.length ? userListBasedOnStore.length : userList.length)
                              ) {
                                handleUserChange("", []);
                              } else {
                                handleUserChange("", userListBasedOnStore && userListBasedOnStore.length ? userListBasedOnStore : userList);
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterUser.length > 0 &&
                                  filterUser.length ===
                                  (userListBasedOnStore && userListBasedOnStore.length ? userListBasedOnStore.length : userList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl>
              <label>Date</label>
              <Box
                sx={{ mb: 2 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={start_date}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setstart_date(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
                <Typography className="date-to">to</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="End Date"
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={end_date}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setend_date(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-pick date-pick-submision"
                    maxDate={new Date()}
                    minDate={start_date}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <Box
                  sx={{ mb: 2 }}
                >
                  <label>Questions: </label>
                </Box>

                {questionsFilter.map(question => {
                  if (question.type.key === "boolean") {
                    return <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>{question?.title}</label>

                      <Select
                        name="type"
                        value={questionsFilterValues?.[question.id]?.answer || 0}
                        onChange={(e) => {
                          if (e.target.value) {
                            setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: e.target.value, type: question?.type?.key } })
                          } else {
                            const questionValue = { ...questionsFilterValues };
                            console.log("questionValue", questionValue);

                            delete questionValue[question.id];
                            setQuestionsFilterValues({ ...questionValue })
                          }
                        }}
                        size="small"
                        MenuProps={MenuProps}
                        sx={{ borderRadius: "8px" }}
                        placeholder="Select type"
                      >
                        <MenuItem value={0}>Select Answer</MenuItem>
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  } else if (question.type.key === "number") {
                    return <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>{question?.title}</label>
                      <TextField
                        size="small"
                        fullWidth
                        id="outlined-start-adornment"
                        value={questionsFilterValues?.[question.id]?.answer || ''}
                        onChange={(e) => setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: e.target.value, type: question?.type?.key } })}
                        MenuProps={MenuProps}
                        sx={{
                          borderRadius: "8px",
                          textAlign: "left",
                          marginTop: "10px",
                        }}
                      />
                    </FormControl>
                  } else if (question.type.key === "text") {
                    return <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>{question?.title}</label>
                      <TextField
                        size="small"
                        fullWidth
                        id="outlined-start-adornment"
                        value={questionsFilterValues?.[question.id]?.answer || ""}
                        onChange={(e) => setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: e.target.value, type: question?.type?.key } })}
                        MenuProps={MenuProps}
                        sx={{
                          borderRadius: "8px",
                          textAlign: "left",
                          marginTop: "10px",
                        }}
                      />
                    </FormControl>
                  } else if (question.type.key === "single choice") {
                    return <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>{question?.title}</label>

                      <Select
                        name="type"
                        value={questionsFilterValues?.[question.id]?.answer || 0}
                        // onChange={(e) => setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: e.target.value, type: question?.type?.key } })}
                        onChange={(e) => {
                          if (e.target.value) {
                            setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: e.target.value, type: question?.type?.key } })
                          } else {
                            const questionValue = { ...questionsFilterValues };
                            console.log("questionValue", questionValue);

                            delete questionValue[question.id];
                            setQuestionsFilterValues({ ...questionValue })
                          }
                        }}
                        size="small"
                        MenuProps={MenuProps}
                        sx={{ borderRadius: "8px" }}
                        placeholder="Select type"
                      >
                        <MenuItem value={0}>Select Answer</MenuItem>
                        {
                          question.attachedlist && question.attachedlist.items && question.attachedlist.items.length && question.attachedlist.items.map(item => {
                            return <MenuItem value={item.title}>{item.title}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  } else if (question.type.key === "multiple choice") {
                    return <FormControl fullWidth sx={{ mb: 2 }}>
                      <label>{question?.title}</label>

                      <Autocomplete
                        options={
                          question.attachedlist && question.attachedlist.items && question.attachedlist.items.length ? question.attachedlist.items : []
                        }
                        getOptionLabel={(option) => option?.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            size="small"
                            placeholder="Select User"
                            sx={{ borderRadius: "8px" }}
                            onBlur={() => {
                              if (!params.inputProps.value) {
                              }
                            }}
                          />
                        )}
                        value={questionsFilterValues?.[question.id]?.answer || []}
                        loadingText={"Users Loading..."}
                        onChange={(event, newValue) => {
                          console.log("newValue", newValue);
                          setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: newValue, type: question?.type?.key } });
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {console.log("newValue option", option, questionsFilterValues?.[question.id]?.answer)}
                            <Checkbox
                              checked={
                                questionsFilterValues?.[question.id]?.answer
                                  ? questionsFilterValues?.[question.id]?.answer.map(fs => fs.id).includes(option.id)
                                  : false
                              }
                              sx={{ mr: 1 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const newSelected = questionsFilterValues?.[question.id]?.answer
                                  ? [...questionsFilterValues?.[question.id]?.answer]
                                  : [];
                                const selectedIndex =
                                  newSelected.map(fs => fs._id).indexOf(option._id);

                                if (selectedIndex === -1) {
                                  newSelected.push(option);
                                } else {
                                  newSelected.splice(selectedIndex, 1);
                                }
                                setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: newSelected, type: question?.type?.key } });

                                // setFilterUser(newSelected);
                              }}
                            />
                            {option.title}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            if (index === 0) {
                              return (
                                <>
                                  <Chip
                                    key={option.title}
                                    label={option.title}
                                    {...getTagProps({ index })}
                                    color="primary"
                                    sx={{ mr: 1, mb: 1 }}
                                  />
                                  {value.length > 1 ? (
                                    <Chip
                                      key={`${value.length - 1
                                        } more selected`}
                                      label={`${value.length - 1
                                        } more selected`}
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            }
                          })
                        }
                        clearable
                        multiple
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        PaperComponent={(paperProps) => {
                          const { children, ...restPaperProps } = paperProps;
                          return (
                            <Paper {...restPaperProps}>
                              <Box
                                onMouseDown={(e) => e.preventDefault()}
                                pl={3}
                                py={0.5}
                              >
                                <FormControlLabel
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      questionsFilterValues?.[question.id]?.answer.length > 0 &&
                                      questionsFilterValues?.[question.id]?.answer.length ===
                                      (question.attachedlist && question.attachedlist.items ? question.attachedlist.items.length : 0)
                                    ) {
                                      setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: [], type: question?.type?.key } });
                                    } else {
                                      setQuestionsFilterValues({ ...questionsFilterValues, [question.id]: { title: question?.title, answer: question.attachedlist.items, type: question?.type?.key } });
                                    }
                                  }}
                                  label="Select all"
                                  control={
                                    <Checkbox
                                      sx={{ mr: 1 }}
                                      id="select-all-checkbox"
                                      checked={
                                        questionsFilterValues?.[question.id]?.answer.length > 0 &&
                                        questionsFilterValues?.[question.id]?.answer.length ===
                                        (question.attachedlist && question.attachedlist.items ? question.attachedlist.items.length : 0)
                                      }
                                    />
                                  }
                                />
                              </Box>
                              <Divider />
                              {children}
                            </Paper>
                          );
                        }}
                      />
                    </FormControl>
                  }
                })}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              background: "#fff",
            }}
          >
            <Button
              className="cancle-button cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
                background: "#fff",
              }}
              onClick={() => {

                const dates = {
                  start_date: moment(new Date()).format("YYYY-MM-DD"),
                  end_date: moment(new Date()).format("YYYY-MM-DD"),
                };
                setstart_date(dates.start_date);
                setend_date(dates.end_date);
                setQuestionsFilterValues({});
                setFilterStore([]);
                setFilterUser([]);
                setQuestionsFilterValues({})
              }}
            >
              Clear
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
              }}
              onClick={() => {
                modalopenFilterhandleClose();
                handleFilter();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ViewLogEntryHead;
