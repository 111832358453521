import { SIGN_UP_ERROR, SIGN_UP_REQUEST, SIGN_UP_SUCCESS, VERIFICATION_ERROR, VERIFICATION_REQUEST, VERIFICATION_SUCCESS } from "../types/Types";

const initialState = {
    data: [],
    loading: false,
    error: '',
    verifyCode : ""
 };
 
 const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case SIGN_UP_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        }
        case SIGN_UP_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case VERIFICATION_REQUEST: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case VERIFICATION_SUCCESS: {
            return {
                ...state,
                verifyCode: action.payload,
                loading: false
            }
        }
        case VERIFICATION_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
 }

 export default signUpReducer;