/** @format */

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Progressbar from "../../../GlobalProgress/Progressbar";
import {
  StorewiseUserChecklistGraphCountAction,
  StorewiseUserChecklistReportAction,
} from "../../../redux/actions/Checklist";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import POHeader from "../Pages/POHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import View from "../../../images/View.svg";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import ViewHover from "../../../images/ViewHover.svg";
import { ListSubroleApi } from "../../../redux/actions/Roles";

const defaultColumns = [
  {
    field: "NAME",
    headerName: "NAME",
    renderHeader: () => (
      <Tooltip title="NAME">
        <strong>{"NAME"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Name) {
        result.push(params?.row?.Name);
      } else {
        result = ["N/A"];
      }
      return result.join(", ");
    },
  },
  {
    field: "ASSIGNED",
    headerName: "ASSIGNED",
    renderHeader: () => (
      <Tooltip title="ASSIGNED">
        <strong>{"ASSIGNED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalcreated) {
        result.push(params?.row?.Totalcreated ? params?.row?.Totalcreated : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "COMPLETED",
    headerName: "COMPLETED",
    renderHeader: () => (
      <Tooltip title="COMPLETED">
        <strong>{"COMPLETED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalsubmit) {
        result.push(params?.row?.Totalsubmit ? params?.row?.Totalsubmit : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "MISSED",
    headerName: "MISSED",
    renderHeader: () => (
      <Tooltip title="MISSED">
        <strong>{"MISSED"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Totalmissed) {
        result.push(params?.row?.Totalmissed ? params?.row?.Totalmissed : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
  {
    field: "LOCATION",
    headerName: "LOCATION",
    renderHeader: () => (
      <Tooltip title="LOCATION">
        <strong>{"LOCATION"}</strong>
      </Tooltip>
    ),
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => {
      let result = [];
      if (params?.row?.Location) {
        result.push(params?.row?.Location ? params?.row?.Location : 0);
      } else {
        result = ["0"];
      }
      return result.join(", ");
    },
  },
];

const SingleStoreViewPage = () => {
  const [series, setSeries] = React.useState([0]);
  const [options, setOptions] = React.useState({
    chart: {
      height: 350,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Percent"],
  });

  const location = useLocation();
  const { state } = location;
  const storedMonthRecord = JSON.parse(localStorage.getItem("monthRecord"));
  const [monthRecord, setmonthRecord] = React.useState(storedMonthRecord);
  const [DynamicTop5Store, setDynamicTop5Store] = React.useState([]);
  const [DynamicBottom5Store, setDynamicBottom5Store] = React.useState([]);
  const [DynamicAllUser, setDynamicAllUser] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const dispatch = useDispatch();
  const [hover, setHover] = React.useState({ View: false });
  const [indexHover, setIndexHover] = React.useState(0);
  const navigate = useNavigate();
  const [GotStoreName, setGotStoreName] = React.useState("");
  const [subRole, setSubRole] = React.useState([]);

  const [value, setValue] = React.useState("topPerformer");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickStoreView = (row) => {
    localStorage.setItem("UserIdFromSingleStoreViewPage", row?._id);
    localStorage.setItem("UserNameFromSingleStoreViewPage", row?.Name);
    navigate(
      "/checklist/checklist-report/store-performance-view/user-performance-view",
      {
        state: row,
      }
    );
  };

  const StorePerformanceCountData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.storeWiseUserChecklistReportResp &&
      state?.checklist?.storeWiseUserChecklistReportResp?.data &&
      state?.checklist?.storeWiseUserChecklistReportResp?.data?.data
  );

  const StorewiseUserChecklistGraphCount = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.StorewiseUserChecklistGraphCountResp &&
      state?.checklist?.StorewiseUserChecklistGraphCountResp?.data &&
      state?.checklist?.StorewiseUserChecklistGraphCountResp?.data?.data
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  useEffect(() => {
    if (StorePerformanceCountData && StorePerformanceCountData.length > 0) {
      setDynamicAllUser(StorePerformanceCountData);
      const topFiveStores = StorePerformanceCountData.sort(
        (a, b) => b.Totalsubmit - a.Totalsubmit
      ).slice(0, 5);
      setDynamicTop5Store(topFiveStores);
      const bottomFiveStores = StorePerformanceCountData.sort(
        (a, b) => a.Totalsubmit - b.Totalsubmit
      ).slice(0, 5);
      setDynamicBottom5Store(bottomFiveStores);

      let totlaColumns = [...defaultColumns];
      const index = 0;
      if (monthRecord == "OneDay") {
        const dateVal =
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length &&
          StorePerformanceCountData[0].Submission[0].Date
            ? new Date(StorePerformanceCountData[0].Submission[0].Date)
            : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();
        const dayColumn = {
          field: "Submission",
          headerName: "Submission",
          renderHeader: () => (
            <Tooltip title={dateRes}>
              <strong>{dateRes}</strong>
            </Tooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            let result = [];
            const dateValCount =
              params.row &&
              params.row.Submission &&
              params.row.Submission[0] &&
              params.row.Submission[0].submissionCount
                ? (params.row.Submission[index].submissionCount /
                    params.row.Submission[index].checklistCount) *
                  100
                : 0;
            result.push(Math.round(dateValCount) + "%");
            return result.join(", ");
          },
        };
        totlaColumns.push(dayColumn);
      } else if (monthRecord == "OneWeek") {
        if (
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length
        ) {
          StorePerformanceCountData[0].Submission &&
            StorePerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes =
                dateVal
                  .toLocaleString("en-us", { weekday: "short" })
                  .toUpperCase() +
                " " +
                (dateVal.getDate() < 10
                  ? "0" + dateVal.getDate()
                  : dateVal.getDate()) +
                " " +
                dateVal
                  .toLocaleString("en-us", { month: "short" })
                  .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[index].submissionCount
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;
                  let result = [];
                  result.push(Math.round(dateValCount) + "%");
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      } else {
        if (
          StorePerformanceCountData[0].Submission &&
          StorePerformanceCountData[0].Submission.length
        ) {
          StorePerformanceCountData[0].Submission &&
            StorePerformanceCountData[0].Submission.map((sDate, index) => {
              const dateVal = sDate.Date ? new Date(sDate.Date) : new Date();
              const dateRes = dateVal
                .toLocaleString("en-us", {
                  month: "short",
                  year: "numeric",
                })
                .toUpperCase();
              const dayColumn = {
                field: dateRes,
                headerName: dateRes,
                renderHeader: () => (
                  <Tooltip title={dateRes}>
                    <strong>{dateRes}</strong>
                  </Tooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => {
                  let result = [];
                  const dateValCount =
                    params.row &&
                    params.row.Submission &&
                    params.row.Submission[0] &&
                    params.row.Submission[index].submissionCount
                      ? (params.row.Submission[index].submissionCount /
                          params.row.Submission[index].checklistCount) *
                        100
                      : 0;
                  result.push(Math.round(dateValCount) + "%");
                  return result.join(", ");
                },
              };
              totlaColumns.push(dayColumn);
            });
        }
      }
      const actionColumn = {
        field: "ACTION",
        headerName: "ACTION",
        renderHeader: () => (
          <Tooltip title="ACTION">
            <strong>{"ACTION"}</strong>
          </Tooltip>
        ),
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  margin: "0px 3px 0px 3px",
                }}
                onClick={() => handleClickStoreView(params?.row)}
                onMouseOver={() => {
                  setHover({ View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ View: false });
                }}
              />
            </div>
          );
        },
      };
      totlaColumns.push(actionColumn);
      setColumns([...totlaColumns]);
    }
  }, [StorePerformanceCountData]);

  useEffect(() => {
    if (monthRecord) {
      const RecivedStoreNameFromDashBoard =
        localStorage.getItem("StoreNameFromDashBoard") ||
        localStorage.getItem("StoreNameFromViewAllStoreList");
      setGotStoreName(RecivedStoreNameFromDashBoard);
    }
  }, []);

  //Storewise UserChecklist Report
  useEffect(() => {
    if (monthRecord && subRole) {
      // const RecivedStoreId = state;
      const RecivedStoreId =
        localStorage.getItem("StoreIdFromDashBoard") ||
        localStorage.getItem("StoreIdFromViewAllStoreList");

      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `StorewiseUserChecklistReport?company_id=${id}&Filter=${monthRecord}&StoreId=${RecivedStoreId}&subRole=[${subRole}]`,
      };
      dispatch(StorewiseUserChecklistReportAction(data));
    }
  }, [monthRecord, subRole]);

  // Chart api call
  useEffect(() => {
    if (monthRecord && subRole) {
      const RecivedStoreId =
        localStorage.getItem("StoreIdFromDashBoard") ||
        localStorage.getItem("StoreIdFromViewAllStoreList");

      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `StorewiseUserChecklistGraphCount?company_id=${id}&StoreId=${RecivedStoreId}&Filter=${monthRecord}&subRole=[${subRole}]`,
      };
      dispatch(StorewiseUserChecklistGraphCountAction(data));
    }
  }, [monthRecord, subRole]);

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
    dispatch(ListSubroleApi(data));
  }, []);

  const handleChangeSelect = (event) => {
    setmonthRecord(event.target.value);
  };

  const renderCharts = () => {
    return StorewiseUserChecklistGraphCount &&
      StorewiseUserChecklistGraphCount?.length ? (
      StorewiseUserChecklistGraphCount.map((res, sKey) => {
        // const percentage = (
        //   (res.submissionCount / res?.checklistCount) *
        //   100
        // ).toFixed(2);
        const percentage = Math.round(
          (res.submissionCount / res?.checklistCount) * 100
        );
        const formattedPercentage = isNaN(percentage) ? 0 : percentage;
        const dateVal = res.Date ? new Date(res.Date) : new Date();
        const dateRes =
          dateVal.toLocaleString("en-us", { weekday: "short" }).toUpperCase() +
          " " +
          (dateVal.getDate() < 10
            ? "0" + dateVal.getDate()
            : dateVal.getDate()) +
          " " +
          dateVal.toLocaleString("en-us", { month: "short" }).toUpperCase();

        return (
          <Grid
            item
            xs={12}
            md={12 / StorewiseUserChecklistGraphCount?.length}
            lg={12 / StorewiseUserChecklistGraphCount?.length}
            key={sKey}
          >
            {/* <Card sx={{ px: 1 }} className="card">
              <CardContent>
                <Typography sx={{ lineHeight: "0px", mt: 1 }} component="b">
                  <strong>{dateRes}</strong>
                </Typography>
                <ReactApexChart
                  options={options}
                  series={[formattedPercentage]}
                  type="radialBar"
                  height={200}
                />
              </CardContent>
            </Card> */}
            <Card sx={{ px: 1 }} className="card">
              <CardContent
                sx={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {Loader && <CircularProgress />}
                {!Loader && (
                  <>
                    <Typography sx={{ lineHeight: "0px", mb: 1 }} component="b">
                      <strong>{dateRes}</strong>
                    </Typography>
                    <ReactApexChart
                      options={{
                        ...options,
                        dataLabels: {
                          style: {
                            fontSize: "14px",
                            colors: ["#333"],
                          },
                          offsetX: 0,
                          offsetY: 0,
                          background: {
                            enabled: true,
                            foreColor: "#fff",
                            padding: 5,
                            borderRadius: 2,
                            borderColor: "#333",
                          },
                        },
                      }}
                      series={[formattedPercentage]}
                      type="radialBar"
                      height={200}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })
    ) : (
      <Grid item xs={12} md={12} lg={12}>
        No Data Found For The Chart Here.
      </Grid>
    );
  };

  return (
    <div>
      <POHeader
        handleChangeSelect={handleChangeSelect}
        monthRecord={monthRecord}
      />
      <ToastContainer />
      {Loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-45px",
          }}
        >
          <Progressbar />
        </div>
      ) : StorewiseUserChecklistGraphCount ? (
        <Box>
          <Card sx={{ height: "100%", marginTop: "1%" }} className="card">
            <Card
              sx={{
                height: "100%",
                marginBottom: "0.1%",
                textAlign: "left",
              }}
              className="card"
            >
              <Typography
                variant="body1"
                sx={{
                  marginLeft: "1%",
                }}
              >
                <label style={{ marginLeft: "1%" }}>
                  Store: {GotStoreName ? GotStoreName : "N/A"}
                </label>
              </Typography>
            </Card>
            <Grid container spacing={2}>
              {renderCharts()}
            </Grid>
          </Card>

          <Card className="header-card" sx={{ mt: "1%" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="colomn-padding"
                sx={{ mt: 0.5 }}
              >
                {/* Code will be here  */}
                <Box
                  sx={{
                    width: "100%",
                    typography: "body1",
                    marginRight: "auto",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Top Performer" value="topPerformer" />
                        <Tab label="All Users" value="allUsers" />
                      </TabList>
                    </Box>
                    <TabPanel value="topPerformer">
                      <div style={{ marginTop: "1%" }}>
                        {/* Top 5 Store Performance started  */}
                        <Card sx={{ marginTop: "1%" }} className="card">
                          <Typography
                            align="left"
                            variant="h6"
                            component="h4"
                            style={{
                              padding: "4px",
                              marginLeft: "1%",
                              marginTop: "1%",
                            }}
                          >
                            Top 5 User Performance
                          </Typography>
                          <CardContent>
                            <Box
                              sx={{
                                marginBottom: "2%",
                                // bgcolor: "#F6F8FC",
                                height: "63vh",
                                mt: 1,
                                p: 1,
                                borderRadius: "8px",
                                px: 1,
                                "&::-webkit-scrollbar": {
                                  width: 15,
                                },
                                "&::-webkit-scrollbar-track": {
                                  padding: "12px 5px",
                                  backgroundColor: "#CBD4E1",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#64748B",
                                  borderRadius: "8px",
                                },
                                scrollbarWidth: "none",
                                "-ms-overflow-style": "none",
                              }}
                            >
                              {Loader ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "-45px",
                                  }}
                                >
                                  <Progressbar />
                                </div>
                              ) : DynamicTop5Store ? (
                                <>
                                  <DataGrid
                                    rows={DynamicTop5Store}
                                    columns={columns}
                                    getRowId={(DynamicTop5Store) =>
                                      DynamicTop5Store?._id
                                    }
                                    hideFooterPagination={true}
                                    className="grid-table"
                                    disableColumnMenu
                                    disableSelectionOnClick={true}
                                  />
                                </>
                              ) : (
                                "No Data Found"
                              )}
                            </Box>
                          </CardContent>
                        </Card>
                      </div>
                      {/* Top 5 Store Performance Ended */}

                      <Card
                        sx={{ height: "100%", marginTop: "1%" }}
                        className="card"
                      >
                        <Typography
                          align="left"
                          variant="h6"
                          component="h4"
                          style={{
                            padding: "4px",
                            marginLeft: "1%",
                            marginTop: "1%",
                          }}
                        >
                          Bottom 5 User Performance
                        </Typography>
                        <CardContent>
                          <Box
                            sx={{
                              marginBottom: "2%",
                              // bgcolor: "#F6F8FC",
                              height: "63vh",
                              mt: 1,
                              p: 1,
                              borderRadius: "8px",
                              px: 1,
                              "&::-webkit-scrollbar": {
                                width: 15,
                              },
                              "&::-webkit-scrollbar-track": {
                                padding: "12px 5px",
                                backgroundColor: "#CBD4E1",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#64748B",
                                borderRadius: "8px",
                              },
                              scrollbarWidth: "none",
                              "-ms-overflow-style": "none",
                            }}
                          >
                            {Loader ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "-45px",
                                }}
                              >
                                <Progressbar />
                              </div>
                            ) : DynamicBottom5Store ? (
                              <>
                                <DataGrid
                                  rows={DynamicBottom5Store}
                                  columns={columns}
                                  getRowId={(DynamicBottom5Store) =>
                                    DynamicBottom5Store?._id
                                  }
                                  hideFooterPagination={true}
                                  className="grid-table"
                                  disableColumnMenu
                                  disableSelectionOnClick={true}
                                />
                              </>
                            ) : (
                              "No Data Found"
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </TabPanel>
                    <TabPanel value="allUsers">
                      <Card sx={{ marginTop: "1%" }} className="card">
                        <CardContent>
                          <Box
                            sx={{
                              marginBottom: "2%",
                              // bgcolor: "#F6F8FC",
                              height: "63vh",
                              mt: 1,
                              p: 1,
                              borderRadius: "8px",
                              px: 1,
                              "&::-webkit-scrollbar": {
                                width: 15,
                              },
                              "&::-webkit-scrollbar-track": {
                                padding: "12px 5px",
                                backgroundColor: "#CBD4E1",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#64748B",
                                borderRadius: "8px",
                              },
                              scrollbarWidth: "none",
                              "-ms-overflow-style": "none",
                            }}
                          >
                            {Loader ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Progressbar />
                              </div>
                            ) : DynamicAllUser ? (
                              <>
                                <DataGrid
                                  rows={DynamicAllUser.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )}
                                  columns={columns}
                                  getRowId={(DynamicAllUser) =>
                                    DynamicAllUser?._id
                                  }
                                  hideFooterPagination={true}
                                  className="grid-table"
                                  disableColumnMenu
                                  disableSelectionOnClick={true}
                                />
                                <Box
                                  mb={5}
                                  sx={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={DynamicAllUser.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </Box>
                              </>
                            ) : (
                              "No Data Found"
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      ) : (
        "No Data Found"
      )}
    </div>
  );
};

export default SingleStoreViewPage;
