/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "@mui/material";
import { Box, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { AddSchedularApi } from "../../redux/actions/SchedularAction";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const frequencyOptions = [
  { value: 1, label: "No frequency" },
  { value: 2, label: "Daily" },
];

const jobTypeOptions = [{ value: 1, label: "App Notification" }];

const AddSchedular = (props) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [time, setTime] = useState(null);

  const createSchedularRes = useSelector(
    (state) =>
      state?.scheduler &&
      state?.scheduler?.schedularAdd &&
      state?.scheduler?.schedularAdd?.data &&
      state?.scheduler?.schedularAdd?.data?.data
  );

  const [formValues, setFormValues] = useState({
    title: "",
    frequency: "",
    jobType: "",
    jobDescription: "",
    starts_at: "",
    expires_at: "",
    date: "",
    time: null,
  });

  const handleDayLimit = (value) => {
    console.log("Selected Time:", value);
    setTime(value);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted with values:", formValues);

    const validationSchema1 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      jobType: Yup.string().required("Job Type is required"),
      jobDescription: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      expires_at: Yup.date()
        .required("Expire Date is required"),
      time: Yup.string().required("Time is required"),
    });

    const validationSchema2 = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .matches(
          /^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/,
          "Title should not contain numbers and can include special characters"
        ),
      jobType: Yup.string().required("Job Type is required"),
      jobDescription: Yup.string().matches(
        /^[^0-9]*$/,
        "Job Description should not contain numbers"
      ),
      frequency: Yup.number().required("Frequency is required"),
      starts_at: Yup.date().required("Start Date is required"),
      expires_at: Yup.date()
        .required("Expire Date is required")
        .min(
          Yup.ref("starts_at"),
          "Expire Date must be greater than or equal to Start Date"
        ),
      time: Yup.string().required("Time is required"),
    });

    try {
      if(formValues.frequency === 2) {
        await validationSchema2.validate(formValues, { abortEarly: false });
      } else {
        await validationSchema1.validate(formValues, { abortEarly: false });
      }

      const commonData = {
        title: formValues.title,
        jobType:
          jobTypeOptions.find((option) => option.value === formValues.jobType)
            ?.label || "",
        jobDescription: formValues.jobDescription,
      };

      let payload;
      let selectedTime = moment(formValues.time.$d).format("HH:mm");

      if (formValues.frequency === 2) {
        payload = {
          frequency: 2,
          time: selectedTime,
          starts_at: moment(formValues.starts_at).format("YYYY-MM-DD"),
          expires_at: moment(formValues.expires_at).format("YYYY-MM-DD"),
          ...commonData,
        };
      } else if (formValues.frequency === 1) {
        payload = {
          frequency: 1,
          date: moment(formValues.starts_at).format("YYYY-MM-DD"),
          time: moment(formValues.time.$d).format("HH:mm"),
          ...commonData,
        };

        // Include expires_at in date when frequency is 1
        if (formValues.expires_at) {
          payload.date = moment(formValues.expires_at).format("YYYY-MM-DD");
        }
      }

      const allData = {
        body: payload,
      };

      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `schedulerTasks/create?company_id=${id}`;
        dispatch(AddSchedularApi(allData));
      }

      // Clear form values
      setFormValues({
        title: "",
        frequency: "",
        jobType: "",
        jobDescription: "",
        starts_at: "",
        expires_at: "",
        date: "",
        time: null,
      });

      props.handleClose();
    } catch (error) {
      error.inner ? error.inner?.forEach((err) => {
        toast.error(err.message);
      }) : toast.error(error.message);
    }
  };

  useEffect(() => {
    if (createSchedularRes) {
      console.log("createSchedularRes has changed:", createSchedularRes);
    }
  }, [createSchedularRes]);

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Add Schedular
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit}>
          <ToastContainer autoClose={3000} />
          <DialogContent dividers>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <label className="rolemodel-lebel">
                  <b>Title</b>
                </label>
                <TextField
                  className="input-fields"
                  id="title"
                  variant="outlined"
                  name="title"
                  placeholder="Enter Title"
                  value={formValues.title}
                  onChange={handleFormChange}
                  fullWidth
                />
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Frequency</b>
                    </label>
                    <Select
                      id="frequency"
                      name="frequency"
                      variant="outlined"
                      value={formValues.frequency}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="" disabled>
                        Select Frequency
                      </MenuItem>
                      {frequencyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <label className="rolemodel-lebel">
                      <b>Job Type</b>
                    </label>
                    <Select
                      id="jobType"
                      name="jobType"
                      variant="outlined"
                      Value={formValues.jobType}
                      onChange={handleFormChange}
                    >
                      {jobTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rolemodel-lebel">
                    <b>Job Description</b>
                  </label>
                  <TextField
                    className="input-fields"
                    id="jobDescription"
                    variant="outlined"
                    name="jobDescription"
                    placeholder="Enter Job Description"
                    value={formValues.jobDescription}
                    onChange={handleFormChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <label className="rolemodel-lebel">
                    <b>Survey Period</b>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={5}>
                        {/* {formValues.frequency === 2 ? ( */}
                          <DatePicker
                            size="small"
                            value={
                              formValues.starts_at
                                ? moment(
                                    formValues.starts_at,
                                    "YYYY-MM-DD"
                                  ).toDate()
                                : null
                            }
                            onChange={(newValue) => {
                              let dateSort = moment(newValue?.$d).format(
                                "YYYY-MM-DD"
                              );
                              setStartDate(dateSort);
                              setFormValues({
                                ...formValues,
                                starts_at: dateSort,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            name="starts_at"
                            minDate={new Date()}
                            disabled={formValues.frequency === 1}
                          />
                        {/* ) : (
                          <TextField
                            size="small"
                            value={
                              formValues.starts_at
                                ? moment(
                                    formValues.starts_at,
                                    "YYYY-MM-DD"
                                  ).format("YYYY-MM-DD")
                                : ""
                            }
                            placeholder="Select Date"
                          />
                        )} */}
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Box sx={{ mx: 2 }}> To </Box>
                      </Grid>
                      <Grid item xs={6} md={5}>
                        <DatePicker
                          size="small"
                          value={
                            formValues.expires_at
                              ? moment(
                                  formValues.expires_at,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : null
                          }
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d).format(
                              "YYYY-MM-DD"
                            );
                            setEndDate(dateSort);
                            setFormValues({
                              ...formValues,
                              expires_at: dateSort,
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          name="expires_at"
                          minDate={new Date()}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <label className="rolemodel-lebel">
                    <b>Time</b>
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      clearable
                      clearText="Clear"
                      value={formValues.time}
                      onChange={(value) => {
                        handleDayLimit(value);
                        setFormValues({
                          ...formValues,
                          time: value,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...params}
                          placeholder={
                            formValues.time ? undefined : "Select Time"
                          }
                        />
                      )}
                      className="date-input"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              color="success"
              className="issue-modal-savebtn"
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default AddSchedular;
