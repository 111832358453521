/** @format */

import React, { useState } from "react";
import { useFormik } from "formik";
import AllFormQuestion from "../../../checklist/allForm/createFormTabs/AddQuestion";
import { useLocation } from "react-router-dom";

const AssessmentQuestion = () => {
  const [images, setImages] = useState();
  const location = useLocation();
  const AssessmentQuestion = location?.state?.addNewAssesment;
  const id = location?.state?.id;

  let multiimageUrl =
    images &&
    images?.map((item) => {
      return item?.url;
    });

  const initialValues = {
    someRequiredField: false,
  };

  const handleImageUpload = (e) => {
    const tempArr = [];

    [...e.target.files].forEach((file) => {
      tempArr.push({
        data: file,
        url: URL.createObjectURL(file),
      });
    });

    setImages(tempArr);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      isQuestionToInspection: false,
      score: "",
      category: "",
      someRequiredField: false,
    },
    onSubmit: (values) => {},
  });

  return (
    <>
      <AllFormQuestion
        AssessmentQuestion={"lms"}
        someRequiredField={formik.values.someRequiredField}
                applyValidation={formik.values.AssessmentQuestion !== "lms"}

        id={id}
      />
    </>
  );
};

export default AssessmentQuestion;
