/** @format */

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionViewApi } from "../../redux/actions/Checklist";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import User from "../../../assets/icons/svg-icons/User.svg";

const UserOutlet = ({ buttonsvalues }) => {
  const getChecklistDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionView &&
      state.checklist.submissionView.data &&
      state.checklist.submissionView.data.data
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  const [detailedData, setDetailedData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isImages, setIsImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const datalists =
    detailedData &&
    detailedData.forms &&
    detailedData.forms.filter((row, i) => i == buttonsvalues);

  useEffect(() => {
    setDetailedData(getChecklistDetails);
  }, [getChecklistDetails]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleLightbox = () => {
    document.getElementById("image")?.requestFullscreen();
  };

  const openImageViewer = useCallback((index, url) => {
    let imageUrl = [];
    imageUrl.push(url);
    setIsImages(imageUrl);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Box>
      {datalists &&
        datalists.map((row, index) => {
          return (
            <Box key={index} sx={{ width: "50px" }}>
              <img
                id="image"
                src={row.userImages?.url || User}
                onClick={() => openImageViewer(index, row.userImages?.url)}
                key={index}
                alt="user image"
                width="200"
              />
            </Box>
          );
        })}
      {isViewerOpen && (
        <ImageViewer
          src={isImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Box>
  );
};

export default UserOutlet;
