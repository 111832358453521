import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "material-ui-core";
import { Grid, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SelectRuleIssueModal = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    const department = JSON.parse(localStorage.getItem("departmentData"));
    const category = JSON.parse(localStorage.getItem("categoryData"));
    const store = JSON.parse(localStorage.getItem("storeData"));
    setDepartmentData(department);
    setCategoryData(category);
    if (store) {
      let arr = [];
      for (let item of store) {
        let obj = { title: item?.name, _id: item?._id };
        arr.push(obj);
      }
      setStoreData(arr);
    }
  }, []);
  function getFilteredList() {
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return [];
    }
    return categoryData.filter((item) => item.title === selectedCategory);
  }

  const filteredList = useMemo(getFilteredList, [
    selectedCategory,
    categoryData,
  ]);
const [subcatgory,setSubCatgory] = useState();
  useEffect(() => {
    if (filteredList) {
      let arrData1 ;
     for(let category of filteredList){
   arrData1 = category.subCategories
     }
     setSubCatgory(arrData1);
    }
  }, [filteredList]);
  
  const validationSchema = Yup.object({
    observation: Yup.string("Required").required("Required"),
    severity: Yup.string("Required").required("Required"),
    category: Yup.string("Required").required("Required"),
    subCategory: Yup.string("Required").required("Required"),
    issueStatus: Yup.string("Required").required("Required"),
    // department: Yup.array("Required").required("Required"),
    // store: Yup.object("Required").required("Required"),
    action: Yup.string("Required").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      observation: "",
      severity: "",
      category: "",
      subCategory: "",
      issueStatus: "",
      departments: [],
      store: {},
      action: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values,{resetForm}) => {
      if (values) {
        props.listData(values);
        props.handleClose();
        
        
      }
      //   props.onFinalSubmit(values);
      // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });
useEffect(()=>{
  if(props.formValues == ""){
    formik.resetForm();
  }
},[props.formValues])
  useEffect(() => {
    setSelectedCategory(formik.values.category);
  }, [formik.values.category]);
  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Add Sub Role
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <label className="rulesmodel-lebel">Issue</label>
                  {/* <TextField id="outlined-basic" size='small' variant="outlined" /> */}
                  <TextField
                    size="small"
                    id="name"
                    variant="outlined"
                    name="observation"
                    placeholder="observation"
                    value={formik?.values?.observation}
                    onChange={formik.handleChange}
                    error={formik.touched.observation && Boolean(formik.errors.issue)}
                    helperText={formik.touched.observation && formik.errors.observation}
                    fullWidth
                    className="date-input"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <label className="rulesmodel-lebel">Issue Severity</label>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    fullWidth
                    id="category"
                    name="severity"
                    value={formik.values.severity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.severity && Boolean(formik.errors.severity)
                    }
                    helperText={
                      formik.touched.severity && formik.errors.severity
                    }
                    style={{ borderRadius: "8px", height: "40px" }}
                  >
                    <MenuItem value="Critical" className="select-item">
                      Critical
                    </MenuItem>
                    <MenuItem value="High" className="select-item">
                      High
                    </MenuItem>
                    <MenuItem value="Low" className="select-item">
                      Low
                    </MenuItem>
                    <MenuItem value="Medium" className="select-item">
                      Medium
                    </MenuItem>
                    <MenuItem value="Noncritical" className="select-item">
                      Non-critical
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <label className="rulesmodel-lebel">Category</label>
                  <Select
                    size="small"
                    fullWidth
                    labelId="countrySelectLabel"
                    displayEmpty
                    style={{ borderRadius: "8px", height: "40px" }}
                    id="category"
                    name="category"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "300px",
                          width: "168px",
                          overflow: "auto",
                        },
                      },
                    }}
                  >
                    {categoryData &&
                      categoryData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option.title}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <label className="rulesmodel-lebel">Sub-Category</label>
                  <Select
                    size="small"
                    fullWidth
                    labelId="countrySelectLabel"
                    displayEmpty
                    style={{ borderRadius: "8px", height: "40px" }}
                    id="category"
                    name="subCategory"
                    value={formik.values.subCategory}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subCategory &&
                      Boolean(formik.errors.subCategory)
                    }
                    helperText={
                      formik.touched.subCategory && formik.errors.subCategory
                    }
                  >
                    {subcatgory &&
                      subcatgory?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option.title}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <label className="rulesmodel-lebel">Select Status</label>
                  <Select
                    size="small"
                    fullWidth
                    labelId="countrySelectLabel"
                    displayEmpty
                    style={{ borderRadius: "8px", height: "40px" }}
                    id="category"
                    name="issueStatus"
                    value={formik.values.issueStatus}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.issueStatus && Boolean(formik.errors.issueStatus)
                    }
                    helperText={formik.touched.issueStatus && formik.errors.issueStatus}
                  >
                    <MenuItem value="Closed" className="select-item">
                      Closed
                    </MenuItem>
                    <MenuItem value="In-progress" className="select-item">
                      In-progress
                    </MenuItem>
                    <MenuItem value="Resolved" className="select-item">
                      Resolved
                    </MenuItem>
                    <MenuItem value="Open" className="select-item">
                      Open
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <label className="rulesmodel-lebel">Select Department</label>
                  <Select
                    size="small"
                    fullWidth
                    multiple
                    labelId="countrySelectLabel"
                    displayEmpty
                    style={{ borderRadius: "8px", height: "40px" }}
                    id="category"
                    name="departments"
                    value={formik.values.departments}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.departments &&
                      Boolean(formik.errors.departments)
                    }
                    helperText={
                      formik.touched.departments && formik.errors.departments
                    }
                  >
                    {departmentData &&
                      departmentData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.deptName ?? option.deptName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <label className="rulesmodel-lebel">Select Store</label>
                  <Select
                    size="small"
                    fullWidth
                    displayEmpty
                    style={{
                      borderRadius: "8px",
                      height: "40px",
                    }}
                    name="store"
                    value={formik.values.store}
                    onChange={formik.handleChange}
                    error={formik.touched.store && Boolean(formik.errors.store)}
                    helperText={formik.touched.store && formik.errors.store}
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          height: "200px",
                          width: "168px",
                          overflow: "auto",
                        },
                      },
                    }}
                  >
                    {storeData &&
                      storeData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title ?? option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label className="rulesmodel-lebel">Action</label>
                  <TextField
                    size="small"
                    className="input-fields"
                    id="name"
                    variant="outlined"
                    name="action"
                    placeholder="Action"
                    value={formik?.values?.action}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.action && Boolean(formik.errors.action)
                    }
                    helperText={formik.touched.action && formik.errors.action}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              // onClick={props.handleClose}
              color="success"
              className="issue-modal-savebtn"
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={props.handleClose}
              className="issue-modal-cancelbtn"
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default SelectRuleIssueModal;
