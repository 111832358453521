/** @format */

import { Box } from "@mui/material";
import React from "react";

const CertificateIndex = () => {
  return (
    <>
      <Box>
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            borderRadius: 1,
            overflowX: "auto",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: 8,
            },
          }}
        >
          <h1>My Certificates !!!</h1>
        </Box>
      </Box>
    </>
  );
};

export default CertificateIndex;
