/** @format */

import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  InputAdornment,
  Checkbox,
  FormControl,
  FormLabel,
  Autocomplete,
  Chip,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import UserProfileMenu from "./UserProfileMenu";
import { useSelector, useDispatch } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import {
  ChatUserListApi,
  GroupChatUserListApi,
} from "../../redux/actions/ChatAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

const filterUniqueUsers = (users) => {
  const uniqueUserIds = new Set();
  return users.filter((user) => {
    if (uniqueUserIds.has(user._id)) {
      return false;
    } else {
      uniqueUserIds.add(user._id);
      return true;
    }
  });
};

const ChatSideBar = ({
  getDataProfileMe,
  handleSelectUser,
  onUserSelect,
  createGroupCallback,
  getUserChatFromSidebar,
  userListAPI,
  searchQuery,
  setSearchQuery,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [userListInCreateGroup, setUserListInCreateGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(300);
  const [inputValue, setInputValue] = React.useState("");

  const userList = useSelector(
    (state) => state.chat?.chatUserList?.data?.data || []
  );
  // console.log(searchQuery, "search", search);

  useEffect(() => {
    if (userListInCreateGroup) {
      getUserChatFromSidebar(userListInCreateGroup);
    }
  }, [userListInCreateGroup]);

  useEffect(() => {
    if (userList) {
      setUsers((prevUsers) => {
        if (page === 1) {
          return userList;
        }
        return [...prevUsers, ...userList];
      });
      setHasMore(userList.length > 0);
      setLoading(false);
    }
  }, [userList, page]);

  // const userListAPI = useCallback(() => {
  //   const companyId = localStorage.getItem("companyId");
  //   const data = {
  //     url: `${BASE_URL}chat-user?company_id=${companyId}&per_page=${perPage}&page=${page}&search=${searchQuery}`,
  //   };
  //   dispatch(ChatUserListApi(data));
  // }, [dispatch, page, perPage, searchQuery]);

  useEffect(() => {
    userListAPI();
  }, [userListAPI]);

  const handleSearch = (searchText) => {
    setSearchQuery(searchText);
    setPage(1);
    userListAPI();
  };

  const handleUserSelect = (user) => {
    setSelectedUsers(user);
  };

  const chatNewGroupUserList = useSelector(
    (state) => state.chat?.chatGroupUserList?.data?.data || []
  );

  const getNewUserListApi = useCallback(
    (currentPage, perPageCount) => {
      const companyId = localStorage.getItem("companyId");
      const data = {
        url: `${BASE_URL}group-chat-user?company_id=${companyId}&per_page=${perPageCount}&page=${currentPage}`,
      };
      dispatch(GroupChatUserListApi(data));
    },
    [dispatch]
  );

  useEffect(() => {
    getNewUserListApi(page, perPage);
  }, [page, perPage, getNewUserListApi]);

  useEffect(() => {
    if (chatNewGroupUserList) {
      setUserListInCreateGroup((prevUsers) => [
        ...prevUsers,
        ...chatNewGroupUserList,
      ]);
      setLoading(false);
    }
  }, [chatNewGroupUserList]);

  const handleProfileClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleNewChat = () => {
    setIsGroupDialogOpen(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setPage(1);
      userListAPI();
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleSearch(e.target.value);
    if (search) {
      userListAPI();
    }
  };

  const handleCloseGroupDialog = () => {
    setIsGroupDialogOpen(false);
    setGroupName("");
    setSelectedUsers([]);
    setAllUsersSelected(false);
  };

  const handleCreateGroup = () => {
    if (groupName.trim() === "") {
      alert("Please enter a group name.");
      return;
    }
    if (selectedUsers.length === 0) {
      alert("Please select at least one user.");
      return;
    }

    setIsGroupDialogOpen(false);
    createGroupCallback(groupName, selectedUsers);
    setGroupName("");
    setSelectedUsers([]);
    setAllUsersSelected(false);
    userListAPI();
  };

  const handleSelectAllUsers = (event) => {
    const checked = event.target.checked;
    const newSelectedUsers = checked
      ? filterUniqueUsers(userListCreateNewGroup)
      : [];
    setSelectedUsers(newSelectedUsers);
    setAllUsersSelected(checked);
  };

  const userListArray = Array.isArray(users?.room) ? users?.room : [];

  const canCreateGroup = groupName.trim() !== "" && selectedUsers.length > 0;

  const loadMoreUsers = () => {
    if (!loading && hasMore) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
      loadMoreUsers();
    }
  };

  const userListCreateNewGroup = Array.isArray(userListInCreateGroup)
    ? userListInCreateGroup
    : [];

  const searchedUsers = filterUniqueUsers(userListCreateNewGroup).filter(
    (user) => user.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  const extendedUserList = [
    { type: "selectAll", name: "Select All Users", _id: "selectAll" },
    ...searchedUsers,
  ];

  const filteredUsers = userListArray.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box className="sidebar">
      <Box
        className="sidebar-header"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ marginRight: 1 }}>
          {getDataProfileMe?.first_name
            ? getDataProfileMe.first_name[0].toUpperCase()
            : null}
          {getDataProfileMe?.last_name
            ? getDataProfileMe.last_name[0].toUpperCase()
            : null}
        </Avatar>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", textAlign: "left" }}
        >
          {`${getDataProfileMe?.first_name} ${getDataProfileMe?.last_name}`}
        </Typography>
      </Box>

      <UserProfileMenu anchorEl={anchorEl} handleClose={handleProfileClick} />
      <Box
        className="sidebar-search"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search users..."
          value={search}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Avatar>
          <Tooltip title="Create group">
            <IconButton onClick={handleNewChat}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Avatar>
      </Box>

      {/* <Box className="sidebar-navigation" onScroll={handleScroll}>
        <List>
          {userListArray.map((user, index) => {
            if (
              (user &&
                user.participants &&
                user.participants.length > 0 &&
                user?.createdBy?._id !== getDataProfileMe?._id) ||
              user.participants[0]?.userId !== getDataProfileMe?._id
            ) {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    handleSelectUser(user, users, selectedUsers);
                    onUserSelect(user);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      user.type === "room"
                        ? user.participants[0]?.name
                        : user.type === "group"
                        ? user.name
                        : user.name
                    }
                  />
                </ListItem>
              );
            } else if (user?._id !== getDataProfileMe?._id) {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    handleSelectUser(user, users, selectedUsers);
                    onUserSelect(user);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user?.name} />
                </ListItem>
              );
            }

            return null;
          })}
          {loading && <CircularProgress />}
        </List>
        ;
      </Box> */}
      <Box className="sidebar-navigation" onScroll={handleScroll}>
        <List>
          {filteredUsers.map((user, index) => {
            if (!getDataProfileMe || !getDataProfileMe._id) {
              return null;
            }

            const isParticipant = user?.participants?.length > 0;
            const createdByOtherUser =
              user?.createdBy?._id !== getDataProfileMe._id;
            const notLoggedInParticipant =
              Array.isArray(user?.participants) &&
              user?.participants[0]?.userId !== getDataProfileMe._id;
            const isNotLoggedInUser = user?._id !== getDataProfileMe._id;

            if (
              (isParticipant && createdByOtherUser && notLoggedInParticipant) ||
              isNotLoggedInUser
            ) {
              return (
                <ListItem
                  button
                  key={user._id} // Change index to user._id for better key uniqueness
                  onClick={() => {
                    handleSelectUser(user, users, selectedUsers);
                    onUserSelect(user);
                  }}
                  aria-hidden="false"
                >
                  <ListItemAvatar>
                    {user.type === "group" ? (
                      <Avatar>
                        <GroupIcon />
                      </Avatar>
                    ) : (
                      <Avatar>
                        <AccountCircleIcon />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      user.type === "room"
                        ? user.participants[0]?.userId === getDataProfileMe._id
                          ? user.createdBy?.name
                          : user.participants[0]?.name
                        : user.type === "group"
                        ? user.name
                        : user.name
                    }
                  />
                </ListItem>
              );
            }

            return null;
          })}
          {loading && <CircularProgress />}
        </List>
      </Box>

      <Dialog
        open={isGroupDialogOpen}
        onClose={handleCloseGroupDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create Group</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <FormControl fullWidth>
              <FormLabel component="legend">
                Group Name <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                placeholder="Group Name..."
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl fullWidth>
              <FormLabel component="legend">
                Select Users <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Autocomplete
                multiple
                options={extendedUserList}
                getOptionLabel={(user) =>
                  user.type === "selectAll" ? user.name : user?.name || ""
                }
                value={selectedUsers}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                  const selectAllIndex = newValue.findIndex(
                    (user) => user.type === "selectAll"
                  );
                  if (selectAllIndex !== -1) {
                    const allSelected =
                      newValue.length - 1 === searchedUsers.length;
                    setSelectedUsers(allSelected ? [] : searchedUsers);
                    setAllUsersSelected(!allSelected);
                  } else {
                    setSelectedUsers(filterUniqueUsers(newValue));
                  }
                }}
                renderTags={(selectedUsers, getTagProps) =>
                  selectedUsers.map((option, index) => (
                    <Chip
                      key={option._id}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search Users..."
                    label="Search Users"
                  />
                )}
                renderOption={(props, user) => {
                  if (user.type === "selectAll") {
                    return (
                      <li {...props} key={user._id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={allUsersSelected}
                              onChange={handleSelectAllUsers}
                              name={user.name}
                            />
                          }
                          label={user.name}
                        />
                      </li>
                    );
                  }
                  const isSelected = selectedUsers.some(
                    (selected) => selected?._id === user?._id
                  );
                  return (
                    <li {...props} key={user._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected}
                            onChange={() => {
                              const newSelectedUsers = isSelected
                                ? selectedUsers.filter(
                                    (selected) => selected?._id !== user?._id
                                  )
                                : [...selectedUsers, user];
                              setSelectedUsers(
                                filterUniqueUsers(newSelectedUsers)
                              );
                              setAllUsersSelected(
                                newSelectedUsers.length ===
                                  userListCreateNewGroup.length
                              );
                            }}
                            name={user?.name}
                          />
                        }
                        label={user?.name}
                      />
                    </li>
                  );
                }}
                ListboxProps={{
                  style: { maxHeight: "300px", overflowY: "auto" },
                }}
              />
              {loading && <CircularProgress />}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseGroupDialog}
            sx={{
              backgroundColor: "#ffd700",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#eec900",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateGroup}
            disabled={!canCreateGroup}
            style={{
              backgroundColor: canCreateGroup ? "#196773" : "#c0c0c0",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: canCreateGroup ? "#0d4f5c" : "#c0c0c0",
              },
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChatSideBar;
