import React from "react";
import { Stack, Box, Card, Grid, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Header = ({ setSelectedData, value }) => {
  return (
    <>
      <Box>
        <Card className="header-card">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={8}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: "center", gap: "10px" , ml:"2%"}}
              >
                <Typography variant="p">Sort by</Typography>
                <select
                  className="check-select select2"
                  onChange={(e) => setSelectedData(e)}
                  value={value}
                  defaultValue=""
                >
                  {/* <option value="">selected</option> */}
                  <option value="days">days</option>
                  <option value="weekly">weekly</option>
                  <option value="monthly">monthly</option>
                </select>
                <div style={{position:"absolute", right:"0",marginRight:"20px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default Header;
