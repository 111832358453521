import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "material-ui-core";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { EditCategotyApi } from "../../../redux/actions/DataManager";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditCategoryModel = (props) => {
  const dispatch = useDispatch();
  const [newFieldValue, setNewFieldValue] = useState({});
  const valuesEdit = props?.editvalue;

  useEffect(() => {
    setNewFieldValue({
      title: valuesEdit?.title,
    });
  }, [valuesEdit]);

  const validationSchema = Yup.object({
    title: Yup.string("Cannot update with same title").required(
      "Name is required"
    ),
  });

  const formik = useFormik({
    initialValues: newFieldValue || {
      title: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        title: values.title,
        createdAt: valuesEdit.createdAt,
        deleted: valuesEdit.deleted,
        id: valuesEdit.id,
        isEditing: false,
        isEditingSub: false,
        subCategories: valuesEdit.subCategories,
        updatedAt: valuesEdit.updatedAt,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `categories?company_id=${id}`;
        dispatch(EditCategotyApi(allData));
        props.handleClose();
        props.listapiupdate();
      }
    },
  });

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size-subcategory"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
        >
          <Typography variant="p" component="div" className="heading">
            Edit Category
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className="input-fields"
                    id="name"
                    size="small"
                    variant="outlined"
                    name="title"
                    placeholder="Enter Category Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              // onClick={props.handleClose}
              className="issue-modal-savebtn"
              color="success"
            >
              Update
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default EditCategoryModel;
