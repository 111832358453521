/** @format */

import React, { useState, useEffect } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchAppBar from "../tableHeader/SearchButton";
import { useDispatch } from "react-redux";
import { AllFormsResetSuccess } from "../../redux/actions/AllformStore";
import { AllFormArchivedListApi } from "../../redux/actions/Checklist";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import ChatgptFunction from "../allCheckList/ChatgptFunction";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import ChatgptFunction from "../allCheckList/ChatgptFunction";

const AllFormHead = ({
  searchOnChange,
  searchValue,
  handleKeyDown,
  setCheckedArchive,
  checkedArchive,
  totalitems,
  page,
  rowsPerPage,
  ArchivedListTotalItems,
  setPage,
  permission,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };

  const data = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      sort: {
        sort_as: "updatedAt",
        sort_by: "desc",
      },
      archived: false,
      search: "",
    },
  };

  const AllFromListArchivedApi = (data) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `ListArchivedForms?company_id=${id}`;
    dispatch(AllFormArchivedListApi(data));
  };
  useEffect(() => {
    if (checkedArchive === true) {
      data.body.pagination.page = 1;
      setPage(1);
      AllFromListArchivedApi(data);
    }
  }, [checkedArchive]);

  const handleCreateForm = () => {
    localStorage.removeItem("titleName");
    localStorage.removeItem("selectedReports");
    localStorage.removeItem("isSelfieRequired");
    localStorage.removeItem("questions");
    localStorage.removeItem("editdata");
    localStorage.removeItem("questiodata");
    dispatch(AllFormsResetSuccess());
    navigate("/checklist/all-form/add-form");
  };
  return (
    <>
      <Card
        className="header-card"
        sx={{ boxShadow: "none", borderRadius: "8px" }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={4} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent deactive-bg"
            >
              <Tab
                  sx={{ marginLeft:"6px",height:"42px", borderRadius:"8px" }}
                label="Active"
                icon={<p className="active-tabs-content">{totalitems}</p>}
                iconPosition="end"
                className={
                  checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => setCheckedArchive(false)}
              />
              <Tab
                  sx={{ marginRight: { lg: 1 },height:"42px", borderRadius:"8px",}}
                label="Archived"
                icon={
                  <p className="active-tabs-content">
                    {ArchivedListTotalItems}
                  </p>
                }
                iconPosition="end"
                className={
                  checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                onClick={() => setCheckedArchive(true)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} className="extra-div"></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Stack
              direction="row"
              sx={{ float: "right",height:"42px", borderRadius:"8px" }}
              className="create-new-form Assign-Checklist"
            >
              <SearchAppBar
                searchValue={searchValue}
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
              />
              {/* <Button
                className="header-add cancle-hover"
                style={{ marginRight: 5 }}
                onClick={() => setOpen(true)}
              >
                AI Category
              </Button> */}
              <ChatgptFunction open={open} setOpen={setOpen} />
              {/* <Link to="/checklist/all-form/add-form" className="submenu-item"> */}
              {permission?.checklist?.forms === true ? (
                <Button
                  sx={{ marginRight: { lg: 1 },height:"42px", borderRadius:"8px",marginTop:"3px" }}
                  className="header-add cancle-hover"
                  onClick={handleCreateForm}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Create New Form
                </Button>
                
              ) : (
                ""
              )}

              {/* </Link> */}
              <div style={{marginRight:"12px", marginTop:"10px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Forms:</strong>
                            <p>This section will display all the forms that are already created.
                            </p>
                            <strong>Created</strong>
                            <p>This section displays the date and time on which the form was created.</p>
                            <strong>Modified</strong>
                            <p>This section will display when the form was last modified.</p>
                            <strong>Questions</strong>
                            <p>This section will show the number of questions present in the form.</p>
                            <strong>REports:</strong>
                            <p>This section will show to whom the report will be sent when the form is submitted.</p>
                            <strong>Rule:</strong>
                            <p> This shows how many form rules are created for the particular form.
</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AllFormHead;
