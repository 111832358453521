/** @format */

import { Box, FormControl, Paper, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ForwardIcon from "@mui/icons-material/Forward";
import { useEffect } from "react";
import { useState } from "react";
import { AuditorContext } from "../../../../context/auditorContext";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  TDRVideoTypeApi,
  TDRVideoTypeApi1,
} from "../../../redux/actions/LmsAction";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PushPinIcon from "@mui/icons-material/PushPin";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const Disclaimer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const allTrainingData = location?.state;
  const navigate = useNavigate();

  const [numberofTraineeError, setNumberofTraineeError] = useState("");
  const [answers, setAnswers] = useState({});
  const [allTrainingData, setAllTrainingData] = useState({});
  const [assessmentDetailData, setAssessmentDetailData] = useState([]);
  const [assessmentId, setAssessmentId] = useState("");
  const [show, setShow] = useState(false);
  const [number, setNumber] = useState(1);

  let { storeId, storeTitle, companyId } = useContext(AuditorContext);
  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );

  const allTrainingProgress = useSelector(
    (state) =>
      state?.lms &&
      state?.lms?.trDetailsVideoTypeData1 &&
      state?.lms?.trDetailsVideoTypeData1?.data &&
      state?.lms?.trDetailsVideoTypeData1?.data?.data
  );

  const allTrainingProgress1 = useSelector(
    (state) =>
      state?.lms &&
      state?.lms?.trDetailsVideoTypeData &&
      state?.lms?.trDetailsVideoTypeData?.data &&
      state?.lms?.trDetailsVideoTypeData?.data?.data
  );

  useEffect(() => {
    localStorage.removeItem("attempt");
    localStorage.removeItem("currentAttempt");
  });

  useEffect(() => {
    setShow(assessmentDetailData !== null);
  }, [assessmentDetailData]);

  useEffect(() => {
    if (allTrainingData._id) {
      setAssessmentId(allTrainingData._id);
    }
  }, [allTrainingData]);

  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  // console.log("assessmentDetailData", assessmentDetailData);

  // useEffect(() => {
  //   const res =
  //     localStorage.getItem("selectedAssessment") ||
  //     location?.state?.data ||
  //     location?.state;
  //   if (res) {
  //     const parsedData = JSON.parse(res);
  //     setAllTrainingData(parsedData);
  //   }
  // }, []);

  useEffect(() => {
    const res =
      localStorage.getItem("selectedAssessment") ||
      location?.state?.data ||
      location?.state;
    if (typeof res === "string") {
      const parsedData = JSON.parse(res);
      setAllTrainingData(parsedData);
      console.log({parsedData: res, location})
    }else{
      setAllTrainingData(res);
    }
  }, []);

  useEffect(() => {
    if (allTrainingProgress) {
      setAssessmentDetailData(allTrainingProgress);
    }
  }, [allTrainingProgress]);

  useEffect(() => {
    if (assessmentId) {
      disclaimerApi();
    }
  }, [assessmentId]);

  const individualList = (paginationRequest) => {
    paginationRequest.url =
      BASE_URL + `training/details?company_id=${companyId}`;
    dispatch(TDRVideoTypeApi(paginationRequest));
  };

  const disclaimerApi = () => {
    const res =
      location?.state?.data ||
      location?.state ||
      localStorage.getItem("selectedAssessment");
    if (res) {
      const parsedData = typeof res === "string" ? JSON.parse(res) : res;
      const training_id = parsedData.training._id;
      const data = {
        url: `${BASE_URL}assessment/${assessmentId}?company_id=${companyId}&training_id=${training_id}`,
      };
      dispatch(TDRVideoTypeApi1(data));
      const paginationRequest = {
        body: {
          trainingId: training_id,
          storeId: storeId,
        },
      };

      if (location?.state?.from !== "training") {
        individualList(paginationRequest);
      }
    }
  };

  const handleStartButtonClick = (item) => {
    if (number <= 0) {
      return setNumberofTraineeError("please enter minimum 1 trainee");
    }
    if (allTrainingProgress?.repeated)
      localStorage.setItem(
        "attempt",
        allTrainingProgress?.repeated ? parseInt(number) : 0
      );
    localStorage.setItem(
      "currentAttempt",
      allTrainingProgress?.repeated ? 1 : 0
    );
    if (allTrainingProgress && allTrainingProgress?.repeated) {
      localStorage.setItem("repeatedAssessment", true);
    } else {
      localStorage.setItem("repeatedAssessment", false);
    }

    if (location?.state?.allTrainingData) {
      const totalSteps = location?.state?.allTrainingData?.steps?.map(
        (step) => step.id
      );
      localStorage.setItem("totalSteps", totalSteps.join(","));
      const trainingId = location?.state?.allTrainingData?._id;
      localStorage.setItem("trainingId", trainingId);
    }

    if (location?.state?.from === "training") {
      navigate(
        "/auditor-home/my-trainings/my-trainings-view/newTraining-image-type",
        {
          state: {
            allTrainingData: location?.state?.allTrainingData,
            from: "DESCLAIMER",
          },
        }
      );
    } else {
      navigate(
        "/auditor-home/my-assessments/disclaimer-page/submitAssessmentAuditor",
        {
          state: {
            item,
            show,
            allTrainingData: allTrainingProgress1,
          },
        }
      );
    }
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      {Loader && <ProgressbarTransparent play />}
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#F6F8FC",
          padding: 1,
          borderRadius: 1,
          overflowX: "auto",
          overflowY: "hidden",
          height: "85vh",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
          "@media (max-width: 1280px)": {
            height: "auto",
          },
        }}
      >
        {assessmentDetailData ? (
          <>
            <Typography
              // textAlign="left"
              // paddingLeft="20%"
              style={{
                color: assessmentDetailData?.title ? "inherit" : "gray",
                fontSize: "1.5rem",
                // fontWeight: "bold",
              }}
            >
              {assessmentDetailData?.title
                ? assessmentDetailData.title
                : "Name not available"}
            </Typography>

            <hr />
            <Box
              sx={{
                marginTop: 4,
                color: "gray",
                fontWeight: (theme) => theme.typography,
                textAlign: "left",
                // paddingLeft:"10%",
              }}
            >
              <Typography
                variant="h6"
                color={
                  assessmentDetailData?.summary
                    ? "textPrimary"
                    : "textSecondary"
                }
                sx={{
                  mt: 3,
                  textAlign: "left",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                  color: "gray",
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                }}
              >
                <span style={{ color: "black" }}> Summary</span>
              </Typography>
              <Typography sx={{ paddingLeft: "20%",paddingRight: "20%" }}>
                <span>
                  {assessmentDetailData?.summary
                    ? assessmentDetailData?.summary
                    : "Summary not available"}
                </span>
              </Typography>
              <Box
                sx={{
                  marginTop: 2,
                  color: "gray",
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                  textAlign: "left",
                  paddingLeft: "20%",
                  paddingRight: "20%",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    mt: 3,
                    color: "black",
                    textAlign: "left",
                    //paddingLeft:"20%"
                  }}
                >
                  <span>Before you begin</span>
                </Typography>
              </Box>
              <Typography sx={{ paddingLeft: "20%",paddingRight: "20%" }}>
                <span>
                  Each assessment must be completed in one session. Make sure
                  you have a stable internet connection and you finish the
                  assessment before exiting or closing the browser.
                </span>
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: 2,
                color: "gray",
                fontWeight: (theme) => theme.typography.fontWeightBold,
                textAlign: "left",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  mt: 3,
                  color: "black",
                  // paddingLeft:"20%",
                }}
              >
                <span>Overview</span>
              </Typography>
              <Typography sx={{ paddingLeft: "0%" }}>
                <span>
                  Answer 15-20 timed, multiple choice/ enter text questions.
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: 2,
                color: "black",
                fontWeight: (theme) => theme.typography.fontWeightBold,
                paddingLeft: "0%",
                textAlign: "left",
              }}
            >
              <Typography sx={{ paddingLeft: "20%",paddingRight: "20%", display: "flex" }}>
                <span style={{ paddingBottom: 3, paddingLeft: 0 }}>
                  <AccessTimeIcon style={{ color: '#f55105' }} /> <strong></strong>
                </span>
                <span sx={{ marginBottom: "0%" }} style={{ color: "gray" }}>
                  <strong>
                    {Math.round(assessmentDetailData?.duration / 60)}
                  </strong>{" "}
                  minutes duration.
                </span>
              </Typography>

              <Typography sx={{ paddingLeft: "20%",paddingRight: "20%", display: "flex" }}>
                <span style={{ paddingBottom: 3, paddingLeft: 0 }}>
                  <PushPinIcon fontSize="small" sx={{ marginLeft: "0" }} style={{ color: '#f55105' }} />
                </span>
                <span sx={{ marginBottom: "0%" }} style={{ color: "gray" }}>
                  <strong>{assessmentDetailData?.passing_score}</strong>{" "}
                  percentile required to get a certificate.
                </span>
              </Typography>
            </Box>
            {allTrainingProgress?.repeated && (
              <Box
                sx={{
                  marginTop: 1,
                  color: "black",
                  marginBottom: 5,
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                }}
              >
                <div>
                  <div>
                  <label className="label-name">
                    <b>Enter number of trainee</b>
                  </label>
                  </div>
                <FormControl
                  sx={{
                    width: "18%",
                  }}
                >
                  
                  <TextField
                    id="numberOfTrainee"
                    name="numberOfTrainee"
                    type="text"
                    autoComplete="off"
                    placeholder="Enter number of trainee"
                    value={number}
                    onChange={(e) => {
                      const reg = new RegExp("^[0-9]+$");
                      if (!e.target.value || reg.test(e.target.value)) {
                        setNumber(e.target.value);
                      }
                    }}
                    sx={{
                      padding: "0px",
                      
                    }}
                    error={numberofTraineeError}
                    helperText={numberofTraineeError}
                  />
                </FormControl>
                </div>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                marginBottom: 0,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<ForwardIcon />}
                onClick={() => handleStartButtonClick(assessmentDetailData)}
                style={{ cursor: "pointer" , marginBottom: 10 }}
              >
                Start
              </Button>
            </Box>
          </>
        ) : (
          <Typography
            variant="body1"
            align="left"
            paddingLeft="20%"
            color="textSecondary"
            paddingRight="20%"
          >
            Data not available.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Disclaimer;
