/** @format */

import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Typography, Card } from "@mui/material";
import ViewFormHead from "./ViewFormHead";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { ViewAllFormListApi } from "../../../redux/actions/Checklist";


const ViewAllForm = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = useState({});
  const dataList = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.viewFormList &&
      state.checklist.viewFormList.data &&
      state.checklist.viewFormList.data.data
  );

  useEffect(() => {
    handleViewList();
  }, []);

  useEffect(() => {
    if (dataList) {
      setListData(dataList);
    }
  }, [dataList]);

  const kpiData = [
    {
      key: "1",
      name: "KPI Title",
      KPIChannel: "Traditional",
      Weight: "27",
      Dataused: "Questions",
    },
    {
      key: "2",
      name: "KPI Title",
      KPIChannel: "Traditional",
      Weight: "27",
      Dataused: "Questions",
    },
  ];

  const handleViewList = () => {
    const id = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const useIdrow = JSON.parse(userId);
    const data = {
      url: BASE_URL + `checklists/${useIdrow._id}?company_id=${id}`,
    };
    dispatch(ViewAllFormListApi(data));
  };

  console.log("listData", listData);

  return (
    <>
      <ViewFormHead listData={listData} />
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card"
      >
        {" "}
        <Container>
          <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
            <Grid item xs={12}>
              <Typography sx={{ mb: 2 }} className="form-head">
                <b>Form Details</b>
              </Typography>
              <Card sx={{ mb: 2, boxShadow: "none", borderRadius: "8px" }}>
                <ol style={{ listStyle: "none" }} className="list-style">
                  <div className="list-form">
                    Title: <b>{listData?.title}</b>
                  </div>
                  <div className="list-form">
                    Report: <b>{listData?.reports?.join(", ")}</b>
                  </div>
                  <div className="list-form">
                    Selfie Required:{" "}
                    <b>{`${listData?.isSelfieRequired ? "Yes" : "No" || "No"
                      }`}</b>
                  </div>
                  <div className="list-form">
                    Inspection Category:{" "}
                    <b>{`${listData?.inspectionCategory
                      }`}</b>
                  </div>
                </ol>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mb: 2 }} className="form-head">
                <b>Form Question</b>
              </Typography>
              <div>
                {listData?.questions?.map((item, i) => (
                  <Card
                    sx={{ mb: 2, boxShadow: "none", borderRadius: "8px" }}
                    key={i}
                  >
                    <ol style={{ listStyle: "none" }} className="list-style">
                      <li className="list-form">
                        <b>
                          Q.{i + 1} {item?.title}
                        </b>
                      </li>
                      {/* <li className="list-form">
                        Type: <b>{item?.type?.title}</b>
                      </li>
                      <Box className="list-image-wrapper">
                        <Box className="list-images-form">
                          <li style={{ marginBottom: "10px" }}>
                            {item.titleImages && item.titleImages.length ? (
                              item.titleImages.map((img, index) => (
                                <img
                                  key={img.name}
                                  src={img.url}
                                  alt={img.name}
                                  style={{
                                    maxWidth: "60px",
                                    marginRight: "10px",
                                  }}
                                />
                              ))
                            ) : (
                              <span>No attached images are available</span>
                            )}
                          </li>
                        </Box>
                      </Box> */}

                      <div
                        className="list-form"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div>Type: <b>{item && item.type && item.type.title && `${item.type.title} `}</b> Other Content</div>

                        {item.titleImages && item.titleImages.length > 0 ? (
                          <Box
                            className="list-image-wrapper"
                            style={{ marginLeft: "30px" }}
                          >
                            <Box className="list-images-form">
                              <ul
                                style={{
                                  listStyle: "none",
                                  padding: 0,
                                  margin: 0,
                                  display: "flex",
                                }}
                              >
                                {item.titleImages.map((img, index) => (
                                  <li
                                    key={img.name}
                                    style={{
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <img
                                      src={img.location || img.url}
                                      alt={img.name}
                                      style={{
                                        maxWidth: "40px",
                                        maxHeight: "40px",
                                      }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          </Box>
                        ) : (
                          <span> No attached images are available</span>
                        )}
                      </div>
                      <div
                        className="list-form"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          isQuestionToInspection: <b>{item && item.
                            isQuestionToInspection && `${item.
                              isQuestionToInspection ? "Yes" : "No"} `}</b></div>
                      </div>

                      <Box className="validation-div">
                        Validations:
                        {(item?.type?.validations).map((items, i) => {
                          return (
                            <li key={i}>
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "required"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "required" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "audio"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "audio" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "image"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "image" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "video"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "video" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b className="video-validation">{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "comment"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "comment" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "comment_required"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "comment_required" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "image_optional"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "image_optional" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b>{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                              {items?.answer
                                ?.filter((data) => data?.value === true)
                                ?.map((datas, i) => {
                                  const answer = datas?.value === true;
                                  return (
                                    <>
                                      {answer ? (
                                        <Typography
                                          className={
                                            items.key === "video_optional"
                                              ? "Validations-box validation-font"
                                              : ""
                                          }
                                        >
                                          {items.key === "video_optional" ? (
                                            <>
                                              {items?.title} :{" "}
                                              <b>
                                                <b className="video-validation">{datas?.title}</b>
                                              </b>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  );
                                })}
                            </li>
                          );
                        })}
                      </Box>
                    </ol>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 4, mt: 1 }} />
          <Grid
            container
            spacing={1}
            sx={{ textAlign: "left", mt: 1, opacity: "40%" }}
          >
            <Grid item xs={12}>
              <Typography sx={{ mb: 2 }} className="form-head">
                <b>KPIs</b>
              </Typography>
              <div>
                {kpiData.map((item, i) => (
                  <Card sx={{ mb: 2 }} key={i}>
                    <ul style={{ listStyle: "none" }}>
                      <li className="list-form">
                        <b>
                          {item.key} {item.name}
                        </b>
                      </li>
                      <li className="list-form" style={{ display: "flex" }}>
                        <Box className="Validations-box">
                          <Typography sx={{ fontSize: "13px" }}>
                            KPI Channel:{" "}
                          </Typography>{" "}
                          <b>{item?.KPIChannel}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography sx={{ fontSize: "13px" }}>
                            Weight / Points:{" "}
                          </Typography>{" "}
                          <b>{item?.Weight}</b>
                        </Box>
                        <Box className="Validations-box">
                          <Typography sx={{ fontSize: "13px" }}>
                            Data used from:{" "}
                          </Typography>{" "}
                          <b>{item?.Dataused}</b>
                        </Box>
                      </li>
                    </ul>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 4, mt: 1 }} />
          <Grid container spacing={1} sx={{ textAlign: "left", mt: 1 }}>
            <Grid item xs={12}>
              <Typography sx={{ mb: 2 }} className="form-head">
                <b>Rules</b>
              </Typography>
              {listData &&
                listData?.rules &&
                listData?.rules?.map((items, i) => {
                  const templates = items?.template?.split(/[{,}]|<=|=>/);
                  return (
                    <Card sx={{ mb: 2, pt: 2, pb: 2 }} key={i}>
                      <Container>
                        <Box sx={{ display: "flex" }}>
                          {templates.map((template, index) => {
                            if (index % 2 === 0) {
                              return (
                                <Typography key={index}>{template}</Typography>
                              );
                            } else {
                              const title =
                                template === "category"
                                  ? `"${items.data?.category?.title ||
                                  items.data?.category
                                  }"`
                                  : template === "condition"
                                    ? `"${items.data?.condition?.title ||
                                    items.data?.condition
                                    }"`
                                    : template === "answer" ? `"${items.data && Array.isArray(
                                      items?.data?.answer
                                    ) ?
                                      items.data?.answer?.map(
                                        (data) => (
                                          <>{data.title}</>
                                        )
                                      ) : items.data?.answer?.title
                                        ? items.data?.answer?.title
                                        : items.data?.answer
                                      }
                                  "` : "";
                              return (
                                <Typography key={index}>
                                  &nbsp;<b>{title}</b>&nbsp;
                                </Typography>
                              );
                            }
                          })}
                        </Box>
                        <Divider sx={{ mb: 2, mt: 2 }} />
                        <ol>
                          {Array.isArray(items?.questions) &&
                            items.questions.map((question, j) => (
                              <li key={j}>{question?.title}</li>
                            ))}
                        </ol>
                        {items?.template?.split(/[{,}]|<=|=>/)[5] ===
                          "issueTemplate" ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {items?.conditionthird && (
                              <Typography>
                                &nbsp;<b>{items?.conditionthird}</b>&nbsp;
                              </Typography>
                            )}
                            <Typography>
                              <b>
                                Observation: {items?.data?.issueTemplate?.issue}
                              </b>
                            </Typography>
                            <Typography>
                              {items?.data?.issueTemplate?.category} {"=>"}{" "}
                              {items?.data?.issueTemplate?.subcategory}
                            </Typography>
                            <Typography>
                              IssuePriority:{" "}
                              <b>{items?.data?.issueTemplate?.severity}</b>
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Container>
                    </Card>
                  );
                })}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </>
  );
};

export default ViewAllForm;
